// -----------------------------------------------------------------------------------------------------
// @ You can use this file to import custom styles.
//
// @ Styles from this file will override anything from 'vendors.scss' file allowing customizations and
//  modifications of third party libraries.
// -----------------------------------------------------------------------------------------------------
@import "../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../node_modules/ag-grid-community/dist/styles/ag-theme-material.css";

@import "../../node_modules/ag-grid-community/src/styles/ag-grid.scss";
@import "../../node_modules/ag-grid-community/src/styles/ag-theme-material/sass/ag-theme-material-mixin.scss";

@import '../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-layouts/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-grids/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-treegrid/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-gantt/styles/material.css';
@import "../../node_modules/placeholder-loading/dist/css/placeholder-loading.min.css";

.ag-theme-material {
    @include ag-theme-material((
        odd-row-background-color: #eee
    ));
}
//RRS
@import "~mapbox-gl/dist/mapbox-gl.css";
.mat-sidenav-container, .mat-sidenav-content, .mat-tab-body-content {
    transform: none !important;
}

//rrs for snackbar
.mat-simple-snackbar-action{
  button {
    background: #ff6700 !important;
  }
}

.display-none {
  display: none !important;
}

.visibility-hidden {
  visibility: hidden !important;
}

.mat-dialog-container {
  padding: 0 !important;
}

.locked {
  color: #97a6ba !important;
}

.height-100p {
  height: 100%;
}
