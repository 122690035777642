/* This injects Tailwind's utility classes and any utility classes registered by plugins.
/* ----------------------------------------------------------------------------------------------------- */

.space-y-0 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(0px * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(0px * var(--space-y-reverse)) !important;
}

.space-x-0 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(0px * var(--space-x-reverse)) !important;
  margin-left: calc(0px * calc(1 - var(--space-x-reverse))) !important;
}

.space-y-1 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(0.25rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(0.25rem * var(--space-y-reverse)) !important;
}

.space-x-1 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(0.25rem * var(--space-x-reverse)) !important;
  margin-left: calc(0.25rem * calc(1 - var(--space-x-reverse))) !important;
}

.space-y-2 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(0.5rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(0.5rem * var(--space-y-reverse)) !important;
}

.space-x-2 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(0.5rem * var(--space-x-reverse)) !important;
  margin-left: calc(0.5rem * calc(1 - var(--space-x-reverse))) !important;
}

.space-y-3 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(0.75rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(0.75rem * var(--space-y-reverse)) !important;
}

.space-x-3 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(0.75rem * var(--space-x-reverse)) !important;
  margin-left: calc(0.75rem * calc(1 - var(--space-x-reverse))) !important;
}

.space-y-4 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(1rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(1rem * var(--space-y-reverse)) !important;
}

.space-x-4 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(1rem * var(--space-x-reverse)) !important;
  margin-left: calc(1rem * calc(1 - var(--space-x-reverse))) !important;
}

.space-y-5 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(1.25rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(1.25rem * var(--space-y-reverse)) !important;
}

.space-x-5 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(1.25rem * var(--space-x-reverse)) !important;
  margin-left: calc(1.25rem * calc(1 - var(--space-x-reverse))) !important;
}

.space-y-6 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(1.5rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(1.5rem * var(--space-y-reverse)) !important;
}

.space-x-6 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(1.5rem * var(--space-x-reverse)) !important;
  margin-left: calc(1.5rem * calc(1 - var(--space-x-reverse))) !important;
}

.space-y-8 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(2rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(2rem * var(--space-y-reverse)) !important;
}

.space-x-8 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(2rem * var(--space-x-reverse)) !important;
  margin-left: calc(2rem * calc(1 - var(--space-x-reverse))) !important;
}

.space-y-10 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(2.5rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(2.5rem * var(--space-y-reverse)) !important;
}

.space-x-10 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(2.5rem * var(--space-x-reverse)) !important;
  margin-left: calc(2.5rem * calc(1 - var(--space-x-reverse))) !important;
}

.space-y-12 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(3rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(3rem * var(--space-y-reverse)) !important;
}

.space-x-12 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(3rem * var(--space-x-reverse)) !important;
  margin-left: calc(3rem * calc(1 - var(--space-x-reverse))) !important;
}

.space-y-14 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(3.5rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(3.5rem * var(--space-y-reverse)) !important;
}

.space-x-14 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(3.5rem * var(--space-x-reverse)) !important;
  margin-left: calc(3.5rem * calc(1 - var(--space-x-reverse))) !important;
}

.space-y-16 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(4rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(4rem * var(--space-y-reverse)) !important;
}

.space-x-16 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(4rem * var(--space-x-reverse)) !important;
  margin-left: calc(4rem * calc(1 - var(--space-x-reverse))) !important;
}

.space-y-18 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(4.5rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(4.5rem * var(--space-y-reverse)) !important;
}

.space-x-18 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(4.5rem * var(--space-x-reverse)) !important;
  margin-left: calc(4.5rem * calc(1 - var(--space-x-reverse))) !important;
}

.space-y-20 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(5rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(5rem * var(--space-y-reverse)) !important;
}

.space-x-20 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(5rem * var(--space-x-reverse)) !important;
  margin-left: calc(5rem * calc(1 - var(--space-x-reverse))) !important;
}

.space-y-22 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(5.5rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(5.5rem * var(--space-y-reverse)) !important;
}

.space-x-22 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(5.5rem * var(--space-x-reverse)) !important;
  margin-left: calc(5.5rem * calc(1 - var(--space-x-reverse))) !important;
}

.space-y-24 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(6rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(6rem * var(--space-y-reverse)) !important;
}

.space-x-24 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(6rem * var(--space-x-reverse)) !important;
  margin-left: calc(6rem * calc(1 - var(--space-x-reverse))) !important;
}

.space-y-26 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(6.5rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(6.5rem * var(--space-y-reverse)) !important;
}

.space-x-26 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(6.5rem * var(--space-x-reverse)) !important;
  margin-left: calc(6.5rem * calc(1 - var(--space-x-reverse))) !important;
}

.space-y-28 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(7rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(7rem * var(--space-y-reverse)) !important;
}

.space-x-28 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(7rem * var(--space-x-reverse)) !important;
  margin-left: calc(7rem * calc(1 - var(--space-x-reverse))) !important;
}

.space-y-30 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(7.5rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(7.5rem * var(--space-y-reverse)) !important;
}

.space-x-30 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(7.5rem * var(--space-x-reverse)) !important;
  margin-left: calc(7.5rem * calc(1 - var(--space-x-reverse))) !important;
}

.space-y-32 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(8rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(8rem * var(--space-y-reverse)) !important;
}

.space-x-32 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(8rem * var(--space-x-reverse)) !important;
  margin-left: calc(8rem * calc(1 - var(--space-x-reverse))) !important;
}

.space-y-36 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(9rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(9rem * var(--space-y-reverse)) !important;
}

.space-x-36 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(9rem * var(--space-x-reverse)) !important;
  margin-left: calc(9rem * calc(1 - var(--space-x-reverse))) !important;
}

.space-y-40 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(10rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(10rem * var(--space-y-reverse)) !important;
}

.space-x-40 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(10rem * var(--space-x-reverse)) !important;
  margin-left: calc(10rem * calc(1 - var(--space-x-reverse))) !important;
}

.space-y-48 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(12rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(12rem * var(--space-y-reverse)) !important;
}

.space-x-48 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(12rem * var(--space-x-reverse)) !important;
  margin-left: calc(12rem * calc(1 - var(--space-x-reverse))) !important;
}

.space-y-56 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(14rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(14rem * var(--space-y-reverse)) !important;
}

.space-x-56 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(14rem * var(--space-x-reverse)) !important;
  margin-left: calc(14rem * calc(1 - var(--space-x-reverse))) !important;
}

.space-y-64 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(16rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(16rem * var(--space-y-reverse)) !important;
}

.space-x-64 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(16rem * var(--space-x-reverse)) !important;
  margin-left: calc(16rem * calc(1 - var(--space-x-reverse))) !important;
}

.space-y-px > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(1px * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(1px * var(--space-y-reverse)) !important;
}

.space-x-px > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(1px * var(--space-x-reverse)) !important;
  margin-left: calc(1px * calc(1 - var(--space-x-reverse))) !important;
}

.space-y-2px > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(2px * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(2px * var(--space-y-reverse)) !important;
}

.space-x-2px > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(2px * var(--space-x-reverse)) !important;
  margin-left: calc(2px * calc(1 - var(--space-x-reverse))) !important;
}

.-space-y-1 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(-0.25rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(-0.25rem * var(--space-y-reverse)) !important;
}

.-space-x-1 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(-0.25rem * var(--space-x-reverse)) !important;
  margin-left: calc(-0.25rem * calc(1 - var(--space-x-reverse))) !important;
}

.-space-y-2 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(-0.5rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(-0.5rem * var(--space-y-reverse)) !important;
}

.-space-x-2 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(-0.5rem * var(--space-x-reverse)) !important;
  margin-left: calc(-0.5rem * calc(1 - var(--space-x-reverse))) !important;
}

.-space-y-3 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(-0.75rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(-0.75rem * var(--space-y-reverse)) !important;
}

.-space-x-3 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(-0.75rem * var(--space-x-reverse)) !important;
  margin-left: calc(-0.75rem * calc(1 - var(--space-x-reverse))) !important;
}

.-space-y-4 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(-1rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(-1rem * var(--space-y-reverse)) !important;
}

.-space-x-4 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(-1rem * var(--space-x-reverse)) !important;
  margin-left: calc(-1rem * calc(1 - var(--space-x-reverse))) !important;
}

.-space-y-5 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(-1.25rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(-1.25rem * var(--space-y-reverse)) !important;
}

.-space-x-5 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(-1.25rem * var(--space-x-reverse)) !important;
  margin-left: calc(-1.25rem * calc(1 - var(--space-x-reverse))) !important;
}

.-space-y-6 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(-1.5rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(-1.5rem * var(--space-y-reverse)) !important;
}

.-space-x-6 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(-1.5rem * var(--space-x-reverse)) !important;
  margin-left: calc(-1.5rem * calc(1 - var(--space-x-reverse))) !important;
}

.-space-y-8 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(-2rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(-2rem * var(--space-y-reverse)) !important;
}

.-space-x-8 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(-2rem * var(--space-x-reverse)) !important;
  margin-left: calc(-2rem * calc(1 - var(--space-x-reverse))) !important;
}

.-space-y-10 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(-2.5rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(-2.5rem * var(--space-y-reverse)) !important;
}

.-space-x-10 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(-2.5rem * var(--space-x-reverse)) !important;
  margin-left: calc(-2.5rem * calc(1 - var(--space-x-reverse))) !important;
}

.-space-y-12 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(-3rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(-3rem * var(--space-y-reverse)) !important;
}

.-space-x-12 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(-3rem * var(--space-x-reverse)) !important;
  margin-left: calc(-3rem * calc(1 - var(--space-x-reverse))) !important;
}

.-space-y-14 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(-3.5rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(-3.5rem * var(--space-y-reverse)) !important;
}

.-space-x-14 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(-3.5rem * var(--space-x-reverse)) !important;
  margin-left: calc(-3.5rem * calc(1 - var(--space-x-reverse))) !important;
}

.-space-y-16 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(-4rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(-4rem * var(--space-y-reverse)) !important;
}

.-space-x-16 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(-4rem * var(--space-x-reverse)) !important;
  margin-left: calc(-4rem * calc(1 - var(--space-x-reverse))) !important;
}

.-space-y-18 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(-4.5rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(-4.5rem * var(--space-y-reverse)) !important;
}

.-space-x-18 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(-4.5rem * var(--space-x-reverse)) !important;
  margin-left: calc(-4.5rem * calc(1 - var(--space-x-reverse))) !important;
}

.-space-y-20 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(-5rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(-5rem * var(--space-y-reverse)) !important;
}

.-space-x-20 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(-5rem * var(--space-x-reverse)) !important;
  margin-left: calc(-5rem * calc(1 - var(--space-x-reverse))) !important;
}

.-space-y-22 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(-5.5rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(-5.5rem * var(--space-y-reverse)) !important;
}

.-space-x-22 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(-5.5rem * var(--space-x-reverse)) !important;
  margin-left: calc(-5.5rem * calc(1 - var(--space-x-reverse))) !important;
}

.-space-y-24 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(-6rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(-6rem * var(--space-y-reverse)) !important;
}

.-space-x-24 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(-6rem * var(--space-x-reverse)) !important;
  margin-left: calc(-6rem * calc(1 - var(--space-x-reverse))) !important;
}

.-space-y-26 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(-6.5rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(-6.5rem * var(--space-y-reverse)) !important;
}

.-space-x-26 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(-6.5rem * var(--space-x-reverse)) !important;
  margin-left: calc(-6.5rem * calc(1 - var(--space-x-reverse))) !important;
}

.-space-y-28 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(-7rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(-7rem * var(--space-y-reverse)) !important;
}

.-space-x-28 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(-7rem * var(--space-x-reverse)) !important;
  margin-left: calc(-7rem * calc(1 - var(--space-x-reverse))) !important;
}

.-space-y-30 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(-7.5rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(-7.5rem * var(--space-y-reverse)) !important;
}

.-space-x-30 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(-7.5rem * var(--space-x-reverse)) !important;
  margin-left: calc(-7.5rem * calc(1 - var(--space-x-reverse))) !important;
}

.-space-y-32 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(-8rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(-8rem * var(--space-y-reverse)) !important;
}

.-space-x-32 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(-8rem * var(--space-x-reverse)) !important;
  margin-left: calc(-8rem * calc(1 - var(--space-x-reverse))) !important;
}

.-space-y-36 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(-9rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(-9rem * var(--space-y-reverse)) !important;
}

.-space-x-36 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(-9rem * var(--space-x-reverse)) !important;
  margin-left: calc(-9rem * calc(1 - var(--space-x-reverse))) !important;
}

.-space-y-40 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(-10rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(-10rem * var(--space-y-reverse)) !important;
}

.-space-x-40 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(-10rem * var(--space-x-reverse)) !important;
  margin-left: calc(-10rem * calc(1 - var(--space-x-reverse))) !important;
}

.-space-y-48 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(-12rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(-12rem * var(--space-y-reverse)) !important;
}

.-space-x-48 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(-12rem * var(--space-x-reverse)) !important;
  margin-left: calc(-12rem * calc(1 - var(--space-x-reverse))) !important;
}

.-space-y-56 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(-14rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(-14rem * var(--space-y-reverse)) !important;
}

.-space-x-56 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(-14rem * var(--space-x-reverse)) !important;
  margin-left: calc(-14rem * calc(1 - var(--space-x-reverse))) !important;
}

.-space-y-64 > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(-16rem * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(-16rem * var(--space-y-reverse)) !important;
}

.-space-x-64 > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(-16rem * var(--space-x-reverse)) !important;
  margin-left: calc(-16rem * calc(1 - var(--space-x-reverse))) !important;
}

.-space-y-px > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(-1px * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(-1px * var(--space-y-reverse)) !important;
}

.-space-x-px > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(-1px * var(--space-x-reverse)) !important;
  margin-left: calc(-1px * calc(1 - var(--space-x-reverse))) !important;
}

.-space-y-2px > :not(template) ~ :not(template) {
  --space-y-reverse: 0 !important;
  margin-top: calc(-2px * calc(1 - var(--space-y-reverse))) !important;
  margin-bottom: calc(-2px * var(--space-y-reverse)) !important;
}

.-space-x-2px > :not(template) ~ :not(template) {
  --space-x-reverse: 0 !important;
  margin-right: calc(-2px * var(--space-x-reverse)) !important;
  margin-left: calc(-2px * calc(1 - var(--space-x-reverse))) !important;
}

.space-y-reverse > :not(template) ~ :not(template) {
  --space-y-reverse: 1 !important;
}

.space-x-reverse > :not(template) ~ :not(template) {
  --space-x-reverse: 1 !important;
}

.divide-y-0 > :not(template) ~ :not(template) {
  --divide-y-reverse: 0 !important;
  border-top-width: calc(0px * calc(1 - var(--divide-y-reverse))) !important;
  border-bottom-width: calc(0px * var(--divide-y-reverse)) !important;
}

.divide-x-0 > :not(template) ~ :not(template) {
  --divide-x-reverse: 0 !important;
  border-right-width: calc(0px * var(--divide-x-reverse)) !important;
  border-left-width: calc(0px * calc(1 - var(--divide-x-reverse))) !important;
}

.divide-y-2 > :not(template) ~ :not(template) {
  --divide-y-reverse: 0 !important;
  border-top-width: calc(2px * calc(1 - var(--divide-y-reverse))) !important;
  border-bottom-width: calc(2px * var(--divide-y-reverse)) !important;
}

.divide-x-2 > :not(template) ~ :not(template) {
  --divide-x-reverse: 0 !important;
  border-right-width: calc(2px * var(--divide-x-reverse)) !important;
  border-left-width: calc(2px * calc(1 - var(--divide-x-reverse))) !important;
}

.divide-y-4 > :not(template) ~ :not(template) {
  --divide-y-reverse: 0 !important;
  border-top-width: calc(4px * calc(1 - var(--divide-y-reverse))) !important;
  border-bottom-width: calc(4px * var(--divide-y-reverse)) !important;
}

.divide-x-4 > :not(template) ~ :not(template) {
  --divide-x-reverse: 0 !important;
  border-right-width: calc(4px * var(--divide-x-reverse)) !important;
  border-left-width: calc(4px * calc(1 - var(--divide-x-reverse))) !important;
}

.divide-y-8 > :not(template) ~ :not(template) {
  --divide-y-reverse: 0 !important;
  border-top-width: calc(8px * calc(1 - var(--divide-y-reverse))) !important;
  border-bottom-width: calc(8px * var(--divide-y-reverse)) !important;
}

.divide-x-8 > :not(template) ~ :not(template) {
  --divide-x-reverse: 0 !important;
  border-right-width: calc(8px * var(--divide-x-reverse)) !important;
  border-left-width: calc(8px * calc(1 - var(--divide-x-reverse))) !important;
}

.divide-y > :not(template) ~ :not(template) {
  --divide-y-reverse: 0 !important;
  border-top-width: calc(1px * calc(1 - var(--divide-y-reverse))) !important;
  border-bottom-width: calc(1px * var(--divide-y-reverse)) !important;
}

.divide-x > :not(template) ~ :not(template) {
  --divide-x-reverse: 0 !important;
  border-right-width: calc(1px * var(--divide-x-reverse)) !important;
  border-left-width: calc(1px * calc(1 - var(--divide-x-reverse))) !important;
}

.divide-y-reverse > :not(template) ~ :not(template) {
  --divide-y-reverse: 1 !important;
}

.divide-x-reverse > :not(template) ~ :not(template) {
  --divide-x-reverse: 1 !important;
}

.divide-current > :not(template) ~ :not(template) {
  border-color: currentColor !important;
}

.divide-transparent > :not(template) ~ :not(template) {
  border-color: transparent !important;
}

.divide-white > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #FFFFFF !important;
  border-color: rgba(255, 255, 255, var(--divide-opacity)) !important;
}

.divide-black > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #000000 !important;
  border-color: rgba(0, 0, 0, var(--divide-opacity)) !important;
}

.divide-gray-50 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #F9FAFB !important;
  border-color: rgba(249, 250, 251, var(--divide-opacity)) !important;
}

.divide-gray-100 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #F4F5F7 !important;
  border-color: rgba(244, 245, 247, var(--divide-opacity)) !important;
}

.divide-gray-200 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #E5E7EB !important;
  border-color: rgba(229, 231, 235, var(--divide-opacity)) !important;
}

.divide-gray-300 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #D2D6DC !important;
  border-color: rgba(210, 214, 220, var(--divide-opacity)) !important;
}

.divide-gray-400 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #9FA6B2 !important;
  border-color: rgba(159, 166, 178, var(--divide-opacity)) !important;
}

.divide-gray-500 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #6B7280 !important;
  border-color: rgba(107, 114, 128, var(--divide-opacity)) !important;
}

.divide-gray-600 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #4B5563 !important;
  border-color: rgba(75, 85, 99, var(--divide-opacity)) !important;
}

.divide-gray-700 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #374151 !important;
  border-color: rgba(55, 65, 81, var(--divide-opacity)) !important;
}

.divide-gray-800 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #252F3F !important;
  border-color: rgba(37, 47, 63, var(--divide-opacity)) !important;
}

.divide-gray-900 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #161E2E !important;
  border-color: rgba(22, 30, 46, var(--divide-opacity)) !important;
}

.divide-gray > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #6B7280 !important;
  border-color: rgba(107, 114, 128, var(--divide-opacity)) !important;
}

.divide-cool-gray-50 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #FBFDFE !important;
  border-color: rgba(251, 253, 254, var(--divide-opacity)) !important;
}

.divide-cool-gray-100 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #F1F5F9 !important;
  border-color: rgba(241, 245, 249, var(--divide-opacity)) !important;
}

.divide-cool-gray-200 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #E2E8F0 !important;
  border-color: rgba(226, 232, 240, var(--divide-opacity)) !important;
}

.divide-cool-gray-300 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #CFD8E3 !important;
  border-color: rgba(207, 216, 227, var(--divide-opacity)) !important;
}

.divide-cool-gray-400 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #97A6BA !important;
  border-color: rgba(151, 166, 186, var(--divide-opacity)) !important;
}

.divide-cool-gray-500 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #64748B !important;
  border-color: rgba(100, 116, 139, var(--divide-opacity)) !important;
}

.divide-cool-gray-600 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #475569 !important;
  border-color: rgba(71, 85, 105, var(--divide-opacity)) !important;
}

.divide-cool-gray-700 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #364152 !important;
  border-color: rgba(54, 65, 82, var(--divide-opacity)) !important;
}

.divide-cool-gray-800 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #27303F !important;
  border-color: rgba(39, 48, 63, var(--divide-opacity)) !important;
}

.divide-cool-gray-900 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #1A202E !important;
  border-color: rgba(26, 32, 46, var(--divide-opacity)) !important;
}

.divide-cool-gray > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #64748B !important;
  border-color: rgba(100, 116, 139, var(--divide-opacity)) !important;
}

.divide-red-50 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #FDF2F2 !important;
  border-color: rgba(253, 242, 242, var(--divide-opacity)) !important;
}

.divide-red-100 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #FDE8E8 !important;
  border-color: rgba(253, 232, 232, var(--divide-opacity)) !important;
}

.divide-red-200 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #FBD5D5 !important;
  border-color: rgba(251, 213, 213, var(--divide-opacity)) !important;
}

.divide-red-300 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #F8B4B4 !important;
  border-color: rgba(248, 180, 180, var(--divide-opacity)) !important;
}

.divide-red-400 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #F98080 !important;
  border-color: rgba(249, 128, 128, var(--divide-opacity)) !important;
}

.divide-red-500 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #F05252 !important;
  border-color: rgba(240, 82, 82, var(--divide-opacity)) !important;
}

.divide-red-600 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #E02424 !important;
  border-color: rgba(224, 36, 36, var(--divide-opacity)) !important;
}

.divide-red-700 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #C81E1E !important;
  border-color: rgba(200, 30, 30, var(--divide-opacity)) !important;
}

.divide-red-800 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #9B1C1C !important;
  border-color: rgba(155, 28, 28, var(--divide-opacity)) !important;
}

.divide-red-900 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #771D1D !important;
  border-color: rgba(119, 29, 29, var(--divide-opacity)) !important;
}

.divide-red > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #F05252 !important;
  border-color: rgba(240, 82, 82, var(--divide-opacity)) !important;
}

.divide-orange-50 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #FFF8F1 !important;
  border-color: rgba(255, 248, 241, var(--divide-opacity)) !important;
}

.divide-orange-100 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #FEECDC !important;
  border-color: rgba(254, 236, 220, var(--divide-opacity)) !important;
}

.divide-orange-200 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #FCD9BD !important;
  border-color: rgba(252, 217, 189, var(--divide-opacity)) !important;
}

.divide-orange-300 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #FDBA8C !important;
  border-color: rgba(253, 186, 140, var(--divide-opacity)) !important;
}

.divide-orange-400 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #FF8A4C !important;
  border-color: rgba(255, 138, 76, var(--divide-opacity)) !important;
}

.divide-orange-500 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #FF5A1F !important;
  border-color: rgba(255, 90, 31, var(--divide-opacity)) !important;
}

.divide-orange-600 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #ff6700 !important;
  border-color: rgba(255, 103, 0, var(--divide-opacity)) !important;
}

.divide-orange-700 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #B43403 !important;
  border-color: rgba(180, 52, 3, var(--divide-opacity)) !important;
}

.divide-orange-800 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #8A2C0D !important;
  border-color: rgba(138, 44, 13, var(--divide-opacity)) !important;
}

.divide-orange-900 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #771D1D !important;
  border-color: rgba(119, 29, 29, var(--divide-opacity)) !important;
}

.divide-orange > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #FF5A1F !important;
  border-color: rgba(255, 90, 31, var(--divide-opacity)) !important;
}

.divide-yellow-50 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #FDFDEA !important;
  border-color: rgba(253, 253, 234, var(--divide-opacity)) !important;
}

.divide-yellow-100 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #FDF6B2 !important;
  border-color: rgba(253, 246, 178, var(--divide-opacity)) !important;
}

.divide-yellow-200 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #FCE96A !important;
  border-color: rgba(252, 233, 106, var(--divide-opacity)) !important;
}

.divide-yellow-300 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #FACA15 !important;
  border-color: rgba(250, 202, 21, var(--divide-opacity)) !important;
}

.divide-yellow-400 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #E3A008 !important;
  border-color: rgba(227, 160, 8, var(--divide-opacity)) !important;
}

.divide-yellow-500 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #C27803 !important;
  border-color: rgba(194, 120, 3, var(--divide-opacity)) !important;
}

.divide-yellow-600 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #9F580A !important;
  border-color: rgba(159, 88, 10, var(--divide-opacity)) !important;
}

.divide-yellow-700 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #8E4B10 !important;
  border-color: rgba(142, 75, 16, var(--divide-opacity)) !important;
}

.divide-yellow-800 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #723B13 !important;
  border-color: rgba(114, 59, 19, var(--divide-opacity)) !important;
}

.divide-yellow-900 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #633112 !important;
  border-color: rgba(99, 49, 18, var(--divide-opacity)) !important;
}

.divide-yellow > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #C27803 !important;
  border-color: rgba(194, 120, 3, var(--divide-opacity)) !important;
}

.divide-green-50 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #F3FAF7 !important;
  border-color: rgba(243, 250, 247, var(--divide-opacity)) !important;
}

.divide-green-100 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #DEF7EC !important;
  border-color: rgba(222, 247, 236, var(--divide-opacity)) !important;
}

.divide-green-200 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #BCF0DA !important;
  border-color: rgba(188, 240, 218, var(--divide-opacity)) !important;
}

.divide-green-300 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #84E1BC !important;
  border-color: rgba(132, 225, 188, var(--divide-opacity)) !important;
}

.divide-green-400 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #31C48D !important;
  border-color: rgba(49, 196, 141, var(--divide-opacity)) !important;
}

.divide-green-500 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #0E9F6E !important;
  border-color: rgba(14, 159, 110, var(--divide-opacity)) !important;
}

.divide-green-600 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #057A55 !important;
  border-color: rgba(5, 122, 85, var(--divide-opacity)) !important;
}

.divide-green-700 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #046C4E !important;
  border-color: rgba(4, 108, 78, var(--divide-opacity)) !important;
}

.divide-green-800 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #03543F !important;
  border-color: rgba(3, 84, 63, var(--divide-opacity)) !important;
}

.divide-green-900 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #014737 !important;
  border-color: rgba(1, 71, 55, var(--divide-opacity)) !important;
}

.divide-green > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #0E9F6E !important;
  border-color: rgba(14, 159, 110, var(--divide-opacity)) !important;
}

.divide-teal-50 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #EDFAFA !important;
  border-color: rgba(237, 250, 250, var(--divide-opacity)) !important;
}

.divide-teal-100 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #D5F5F6 !important;
  border-color: rgba(213, 245, 246, var(--divide-opacity)) !important;
}

.divide-teal-200 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #AFECEF !important;
  border-color: rgba(175, 236, 239, var(--divide-opacity)) !important;
}

.divide-teal-300 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #7EDCE2 !important;
  border-color: rgba(126, 220, 226, var(--divide-opacity)) !important;
}

.divide-teal-400 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #16BDCA !important;
  border-color: rgba(22, 189, 202, var(--divide-opacity)) !important;
}

.divide-teal-500 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #0694A2 !important;
  border-color: rgba(6, 148, 162, var(--divide-opacity)) !important;
}

.divide-teal-600 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #047481 !important;
  border-color: rgba(4, 116, 129, var(--divide-opacity)) !important;
}

.divide-teal-700 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #036672 !important;
  border-color: rgba(3, 102, 114, var(--divide-opacity)) !important;
}

.divide-teal-800 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #05505C !important;
  border-color: rgba(5, 80, 92, var(--divide-opacity)) !important;
}

.divide-teal-900 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #014451 !important;
  border-color: rgba(1, 68, 81, var(--divide-opacity)) !important;
}

.divide-teal > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #0694A2 !important;
  border-color: rgba(6, 148, 162, var(--divide-opacity)) !important;
}

.divide-blue-50 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #EBF5FF !important;
  border-color: rgba(235, 245, 255, var(--divide-opacity)) !important;
}

.divide-blue-100 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #E1EFFE !important;
  border-color: rgba(225, 239, 254, var(--divide-opacity)) !important;
}

.divide-blue-200 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #C3DDFD !important;
  border-color: rgba(195, 221, 253, var(--divide-opacity)) !important;
}

.divide-blue-300 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #A4CAFE !important;
  border-color: rgba(164, 202, 254, var(--divide-opacity)) !important;
}

.divide-blue-400 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #76A9FA !important;
  border-color: rgba(118, 169, 250, var(--divide-opacity)) !important;
}

.divide-blue-500 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #3F83F8 !important;
  border-color: rgba(63, 131, 248, var(--divide-opacity)) !important;
}

.divide-blue-600 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #1C64F2 !important;
  border-color: rgba(28, 100, 242, var(--divide-opacity)) !important;
}

.divide-blue-700 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #1A56DB !important;
  border-color: rgba(26, 86, 219, var(--divide-opacity)) !important;
}

.divide-blue-800 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #1E429F !important;
  border-color: rgba(30, 66, 159, var(--divide-opacity)) !important;
}

.divide-blue-900 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #233876 !important;
  border-color: rgba(35, 56, 118, var(--divide-opacity)) !important;
}

.divide-blue > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #3F83F8 !important;
  border-color: rgba(63, 131, 248, var(--divide-opacity)) !important;
}

.divide-indigo-50 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #F0F5FF !important;
  border-color: rgba(240, 245, 255, var(--divide-opacity)) !important;
}

.divide-indigo-100 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #E5EDFF !important;
  border-color: rgba(229, 237, 255, var(--divide-opacity)) !important;
}

.divide-indigo-200 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #CDDBFE !important;
  border-color: rgba(205, 219, 254, var(--divide-opacity)) !important;
}

.divide-indigo-300 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #B4C6FC !important;
  border-color: rgba(180, 198, 252, var(--divide-opacity)) !important;
}

.divide-indigo-400 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #8DA2FB !important;
  border-color: rgba(141, 162, 251, var(--divide-opacity)) !important;
}

.divide-indigo-500 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #6875F5 !important;
  border-color: rgba(104, 117, 245, var(--divide-opacity)) !important;
}

.divide-indigo-600 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #5850EC !important;
  border-color: rgba(88, 80, 236, var(--divide-opacity)) !important;
}

.divide-indigo-700 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #5145CD !important;
  border-color: rgba(81, 69, 205, var(--divide-opacity)) !important;
}

.divide-indigo-800 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #42389D !important;
  border-color: rgba(66, 56, 157, var(--divide-opacity)) !important;
}

.divide-indigo-900 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #362F78 !important;
  border-color: rgba(54, 47, 120, var(--divide-opacity)) !important;
}

.divide-indigo > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #6875F5 !important;
  border-color: rgba(104, 117, 245, var(--divide-opacity)) !important;
}

.divide-purple-50 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #F6F5FF !important;
  border-color: rgba(246, 245, 255, var(--divide-opacity)) !important;
}

.divide-purple-100 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #EDEBFE !important;
  border-color: rgba(237, 235, 254, var(--divide-opacity)) !important;
}

.divide-purple-200 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #DCD7FE !important;
  border-color: rgba(220, 215, 254, var(--divide-opacity)) !important;
}

.divide-purple-300 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #CABFFD !important;
  border-color: rgba(202, 191, 253, var(--divide-opacity)) !important;
}

.divide-purple-400 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #AC94FA !important;
  border-color: rgba(172, 148, 250, var(--divide-opacity)) !important;
}

.divide-purple-500 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #9061F9 !important;
  border-color: rgba(144, 97, 249, var(--divide-opacity)) !important;
}

.divide-purple-600 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #7E3AF2 !important;
  border-color: rgba(126, 58, 242, var(--divide-opacity)) !important;
}

.divide-purple-700 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #6C2BD9 !important;
  border-color: rgba(108, 43, 217, var(--divide-opacity)) !important;
}

.divide-purple-800 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #5521B5 !important;
  border-color: rgba(85, 33, 181, var(--divide-opacity)) !important;
}

.divide-purple-900 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #4A1D96 !important;
  border-color: rgba(74, 29, 150, var(--divide-opacity)) !important;
}

.divide-purple > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #9061F9 !important;
  border-color: rgba(144, 97, 249, var(--divide-opacity)) !important;
}

.divide-pink-50 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #FDF2F8 !important;
  border-color: rgba(253, 242, 248, var(--divide-opacity)) !important;
}

.divide-pink-100 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #FCE8F3 !important;
  border-color: rgba(252, 232, 243, var(--divide-opacity)) !important;
}

.divide-pink-200 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #FAD1E8 !important;
  border-color: rgba(250, 209, 232, var(--divide-opacity)) !important;
}

.divide-pink-300 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #F8B4D9 !important;
  border-color: rgba(248, 180, 217, var(--divide-opacity)) !important;
}

.divide-pink-400 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #F17EB8 !important;
  border-color: rgba(241, 126, 184, var(--divide-opacity)) !important;
}

.divide-pink-500 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #E74694 !important;
  border-color: rgba(231, 70, 148, var(--divide-opacity)) !important;
}

.divide-pink-600 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #D61F69 !important;
  border-color: rgba(214, 31, 105, var(--divide-opacity)) !important;
}

.divide-pink-700 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #BF125D !important;
  border-color: rgba(191, 18, 93, var(--divide-opacity)) !important;
}

.divide-pink-800 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #99154B !important;
  border-color: rgba(153, 21, 75, var(--divide-opacity)) !important;
}

.divide-pink-900 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #751A3D !important;
  border-color: rgba(117, 26, 61, var(--divide-opacity)) !important;
}

.divide-pink > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
  border-color: #E74694 !important;
  border-color: rgba(231, 70, 148, var(--divide-opacity)) !important;
}

.divide-opacity-0 > :not(template) ~ :not(template) {
  --divide-opacity: 0 !important;
}

.divide-opacity-12 > :not(template) ~ :not(template) {
  --divide-opacity: 0.12 !important;
}

.divide-opacity-25 > :not(template) ~ :not(template) {
  --divide-opacity: 0.25 !important;
}

.divide-opacity-38 > :not(template) ~ :not(template) {
  --divide-opacity: 0.38 !important;
}

.divide-opacity-50 > :not(template) ~ :not(template) {
  --divide-opacity: 0.5 !important;
}

.divide-opacity-54 > :not(template) ~ :not(template) {
  --divide-opacity: 0.54 !important;
}

.divide-opacity-70 > :not(template) ~ :not(template) {
  --divide-opacity: 0.70 !important;
}

.divide-opacity-75 > :not(template) ~ :not(template) {
  --divide-opacity: 0.75 !important;
}

.divide-opacity-84 > :not(template) ~ :not(template) {
  --divide-opacity: 0.84 !important;
}

.divide-opacity-100 > :not(template) ~ :not(template) {
  --divide-opacity: 1 !important;
}

.sr-only {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border-width: 0 !important;
}

.not-sr-only {
  position: static !important;
  width: auto !important;
  height: auto !important;
  padding: 0 !important;
  margin: 0 !important;
  overflow: visible !important;
  clip: auto !important;
  white-space: normal !important;
}

.focus\:sr-only:focus {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border-width: 0 !important;
}

.focus\:not-sr-only:focus {
  position: static !important;
  width: auto !important;
  height: auto !important;
  padding: 0 !important;
  margin: 0 !important;
  overflow: visible !important;
  clip: auto !important;
  white-space: normal !important;
}

.appearance-none {
  -webkit-appearance: none !important;
     -moz-appearance: none !important;
          appearance: none !important;
}

.bg-fixed {
  background-attachment: fixed !important;
}

.bg-local {
  background-attachment: local !important;
}

.bg-scroll {
  background-attachment: scroll !important;
}

.bg-current {
  background-color: currentColor !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-white {
  --bg-opacity: 1 !important;
  background-color: #FFFFFF !important;
  background-color: rgba(255, 255, 255, var(--bg-opacity)) !important;
}

.bg-black {
  --bg-opacity: 1 !important;
  background-color: #000000 !important;
  background-color: rgba(0, 0, 0, var(--bg-opacity)) !important;
}

.bg-gray-50 {
  --bg-opacity: 1 !important;
  background-color: #F9FAFB !important;
  background-color: rgba(249, 250, 251, var(--bg-opacity)) !important;
}

.bg-gray-100 {
  --bg-opacity: 1 !important;
  background-color: #F4F5F7 !important;
  background-color: rgba(244, 245, 247, var(--bg-opacity)) !important;
}

.bg-gray-200 {
  --bg-opacity: 1 !important;
  background-color: #E5E7EB !important;
  background-color: rgba(229, 231, 235, var(--bg-opacity)) !important;
}

.bg-gray-300 {
  --bg-opacity: 1 !important;
  background-color: #D2D6DC !important;
  background-color: rgba(210, 214, 220, var(--bg-opacity)) !important;
}

.bg-gray-400 {
  --bg-opacity: 1 !important;
  background-color: #9FA6B2 !important;
  background-color: rgba(159, 166, 178, var(--bg-opacity)) !important;
}

.bg-gray-500 {
  --bg-opacity: 1 !important;
  background-color: #6B7280 !important;
  background-color: rgba(107, 114, 128, var(--bg-opacity)) !important;
}

.bg-gray-600 {
  --bg-opacity: 1 !important;
  background-color: #4B5563 !important;
  background-color: rgba(75, 85, 99, var(--bg-opacity)) !important;
}

.bg-gray-700 {
  --bg-opacity: 1 !important;
  background-color: #374151 !important;
  background-color: rgba(55, 65, 81, var(--bg-opacity)) !important;
}

.bg-gray-800 {
  --bg-opacity: 1 !important;
  background-color: #252F3F !important;
  background-color: rgba(37, 47, 63, var(--bg-opacity)) !important;
}

.bg-gray-900 {
  --bg-opacity: 1 !important;
  background-color: #161E2E !important;
  background-color: rgba(22, 30, 46, var(--bg-opacity)) !important;
}

.bg-gray {
  --bg-opacity: 1 !important;
  background-color: #6B7280 !important;
  background-color: rgba(107, 114, 128, var(--bg-opacity)) !important;
}

.bg-cool-gray-50 {
  --bg-opacity: 1 !important;
  background-color: #FBFDFE !important;
  background-color: rgba(251, 253, 254, var(--bg-opacity)) !important;
}

.bg-cool-gray-100 {
  --bg-opacity: 1 !important;
  background-color: #F1F5F9 !important;
  background-color: rgba(241, 245, 249, var(--bg-opacity)) !important;
}

.bg-cool-gray-200 {
  --bg-opacity: 1 !important;
  background-color: #E2E8F0 !important;
  background-color: rgba(226, 232, 240, var(--bg-opacity)) !important;
}

.bg-cool-gray-300 {
  --bg-opacity: 1 !important;
  background-color: #CFD8E3 !important;
  background-color: rgba(207, 216, 227, var(--bg-opacity)) !important;
}

.bg-cool-gray-400 {
  --bg-opacity: 1 !important;
  background-color: #97A6BA !important;
  background-color: rgba(151, 166, 186, var(--bg-opacity)) !important;
}

.bg-cool-gray-500 {
  --bg-opacity: 1 !important;
  background-color: #64748B !important;
  background-color: rgba(100, 116, 139, var(--bg-opacity)) !important;
}

.bg-cool-gray-600 {
  --bg-opacity: 1 !important;
  background-color: #475569 !important;
  background-color: rgba(71, 85, 105, var(--bg-opacity)) !important;
}

.bg-cool-gray-700 {
  --bg-opacity: 1 !important;
  background-color: #364152 !important;
  background-color: rgba(54, 65, 82, var(--bg-opacity)) !important;
}

.bg-cool-gray-800 {
  --bg-opacity: 1 !important;
  background-color: #27303F !important;
  background-color: rgba(39, 48, 63, var(--bg-opacity)) !important;
}

.bg-cool-gray-900 {
  --bg-opacity: 1 !important;
  background-color: #1A202E !important;
  background-color: rgba(26, 32, 46, var(--bg-opacity)) !important;
}

.bg-cool-gray {
  --bg-opacity: 1 !important;
  background-color: #64748B !important;
  background-color: rgba(100, 116, 139, var(--bg-opacity)) !important;
}

.bg-red-50 {
  --bg-opacity: 1 !important;
  background-color: #FDF2F2 !important;
  background-color: rgba(253, 242, 242, var(--bg-opacity)) !important;
}

.bg-red-100 {
  --bg-opacity: 1 !important;
  background-color: #FDE8E8 !important;
  background-color: rgba(253, 232, 232, var(--bg-opacity)) !important;
}

.bg-red-200 {
  --bg-opacity: 1 !important;
  background-color: #FBD5D5 !important;
  background-color: rgba(251, 213, 213, var(--bg-opacity)) !important;
}

.bg-red-300 {
  --bg-opacity: 1 !important;
  background-color: #F8B4B4 !important;
  background-color: rgba(248, 180, 180, var(--bg-opacity)) !important;
}

.bg-red-400 {
  --bg-opacity: 1 !important;
  background-color: #F98080 !important;
  background-color: rgba(249, 128, 128, var(--bg-opacity)) !important;
}

.bg-red-500 {
  --bg-opacity: 1 !important;
  background-color: #F05252 !important;
  background-color: rgba(240, 82, 82, var(--bg-opacity)) !important;
}

.bg-red-600 {
  --bg-opacity: 1 !important;
  background-color: #E02424 !important;
  background-color: rgba(224, 36, 36, var(--bg-opacity)) !important;
}

.bg-red-700 {
  --bg-opacity: 1 !important;
  background-color: #C81E1E !important;
  background-color: rgba(200, 30, 30, var(--bg-opacity)) !important;
}

.bg-red-800 {
  --bg-opacity: 1 !important;
  background-color: #9B1C1C !important;
  background-color: rgba(155, 28, 28, var(--bg-opacity)) !important;
}

.bg-red-900 {
  --bg-opacity: 1 !important;
  background-color: #771D1D !important;
  background-color: rgba(119, 29, 29, var(--bg-opacity)) !important;
}

.bg-red {
  --bg-opacity: 1 !important;
  background-color: #F05252 !important;
  background-color: rgba(240, 82, 82, var(--bg-opacity)) !important;
}

.bg-orange-50 {
  --bg-opacity: 1 !important;
  background-color: #FFF8F1 !important;
  background-color: rgba(255, 248, 241, var(--bg-opacity)) !important;
}

.bg-orange-100 {
  --bg-opacity: 1 !important;
  background-color: #FEECDC !important;
  background-color: rgba(254, 236, 220, var(--bg-opacity)) !important;
}

.bg-orange-200 {
  --bg-opacity: 1 !important;
  background-color: #FCD9BD !important;
  background-color: rgba(252, 217, 189, var(--bg-opacity)) !important;
}

.bg-orange-300 {
  --bg-opacity: 1 !important;
  background-color: #FDBA8C !important;
  background-color: rgba(253, 186, 140, var(--bg-opacity)) !important;
}

.bg-orange-400 {
  --bg-opacity: 1 !important;
  background-color: #FF8A4C !important;
  background-color: rgba(255, 138, 76, var(--bg-opacity)) !important;
}

.bg-orange-500 {
  --bg-opacity: 1 !important;
  background-color: #FF5A1F !important;
  background-color: rgba(255, 90, 31, var(--bg-opacity)) !important;
}

.bg-orange-600 {
  --bg-opacity: 1 !important;
  background-color: #ff6700 !important;
  background-color: rgba(255, 103, 0, var(--bg-opacity)) !important;
}

.bg-orange-700 {
  --bg-opacity: 1 !important;
  background-color: #B43403 !important;
  background-color: rgba(180, 52, 3, var(--bg-opacity)) !important;
}

.bg-orange-800 {
  --bg-opacity: 1 !important;
  background-color: #8A2C0D !important;
  background-color: rgba(138, 44, 13, var(--bg-opacity)) !important;
}

.bg-orange-900 {
  --bg-opacity: 1 !important;
  background-color: #771D1D !important;
  background-color: rgba(119, 29, 29, var(--bg-opacity)) !important;
}

.bg-orange {
  --bg-opacity: 1 !important;
  background-color: #FF5A1F !important;
  background-color: rgba(255, 90, 31, var(--bg-opacity)) !important;
}

.bg-yellow-50 {
  --bg-opacity: 1 !important;
  background-color: #FDFDEA !important;
  background-color: rgba(253, 253, 234, var(--bg-opacity)) !important;
}

.bg-yellow-100 {
  --bg-opacity: 1 !important;
  background-color: #FDF6B2 !important;
  background-color: rgba(253, 246, 178, var(--bg-opacity)) !important;
}

.bg-yellow-200 {
  --bg-opacity: 1 !important;
  background-color: #FCE96A !important;
  background-color: rgba(252, 233, 106, var(--bg-opacity)) !important;
}

.bg-yellow-300 {
  --bg-opacity: 1 !important;
  background-color: #FACA15 !important;
  background-color: rgba(250, 202, 21, var(--bg-opacity)) !important;
}

.bg-yellow-400 {
  --bg-opacity: 1 !important;
  background-color: #E3A008 !important;
  background-color: rgba(227, 160, 8, var(--bg-opacity)) !important;
}

.bg-yellow-500 {
  --bg-opacity: 1 !important;
  background-color: #C27803 !important;
  background-color: rgba(194, 120, 3, var(--bg-opacity)) !important;
}

.bg-yellow-600 {
  --bg-opacity: 1 !important;
  background-color: #9F580A !important;
  background-color: rgba(159, 88, 10, var(--bg-opacity)) !important;
}

.bg-yellow-700 {
  --bg-opacity: 1 !important;
  background-color: #8E4B10 !important;
  background-color: rgba(142, 75, 16, var(--bg-opacity)) !important;
}

.bg-yellow-800 {
  --bg-opacity: 1 !important;
  background-color: #723B13 !important;
  background-color: rgba(114, 59, 19, var(--bg-opacity)) !important;
}

.bg-yellow-900 {
  --bg-opacity: 1 !important;
  background-color: #633112 !important;
  background-color: rgba(99, 49, 18, var(--bg-opacity)) !important;
}

.bg-yellow {
  --bg-opacity: 1 !important;
  background-color: #C27803 !important;
  background-color: rgba(194, 120, 3, var(--bg-opacity)) !important;
}

.bg-green-50 {
  --bg-opacity: 1 !important;
  background-color: #F3FAF7 !important;
  background-color: rgba(243, 250, 247, var(--bg-opacity)) !important;
}

.bg-green-100 {
  --bg-opacity: 1 !important;
  background-color: #DEF7EC !important;
  background-color: rgba(222, 247, 236, var(--bg-opacity)) !important;
}

.bg-green-200 {
  --bg-opacity: 1 !important;
  background-color: #BCF0DA !important;
  background-color: rgba(188, 240, 218, var(--bg-opacity)) !important;
}

.bg-green-300 {
  --bg-opacity: 1 !important;
  background-color: #84E1BC !important;
  background-color: rgba(132, 225, 188, var(--bg-opacity)) !important;
}

.bg-green-400 {
  --bg-opacity: 1 !important;
  background-color: #31C48D !important;
  background-color: rgba(49, 196, 141, var(--bg-opacity)) !important;
}

.bg-green-500 {
  --bg-opacity: 1 !important;
  background-color: #0E9F6E !important;
  background-color: rgba(14, 159, 110, var(--bg-opacity)) !important;
}

.bg-green-600 {
  --bg-opacity: 1 !important;
  background-color: #057A55 !important;
  background-color: rgba(5, 122, 85, var(--bg-opacity)) !important;
}

.bg-green-700 {
  --bg-opacity: 1 !important;
  background-color: #046C4E !important;
  background-color: rgba(4, 108, 78, var(--bg-opacity)) !important;
}

.bg-green-800 {
  --bg-opacity: 1 !important;
  background-color: #03543F !important;
  background-color: rgba(3, 84, 63, var(--bg-opacity)) !important;
}

.bg-green-900 {
  --bg-opacity: 1 !important;
  background-color: #014737 !important;
  background-color: rgba(1, 71, 55, var(--bg-opacity)) !important;
}

.bg-green {
  --bg-opacity: 1 !important;
  background-color: #0E9F6E !important;
  background-color: rgba(14, 159, 110, var(--bg-opacity)) !important;
}

.bg-teal-50 {
  --bg-opacity: 1 !important;
  background-color: #EDFAFA !important;
  background-color: rgba(237, 250, 250, var(--bg-opacity)) !important;
}

.bg-teal-100 {
  --bg-opacity: 1 !important;
  background-color: #D5F5F6 !important;
  background-color: rgba(213, 245, 246, var(--bg-opacity)) !important;
}

.bg-teal-200 {
  --bg-opacity: 1 !important;
  background-color: #AFECEF !important;
  background-color: rgba(175, 236, 239, var(--bg-opacity)) !important;
}

.bg-teal-300 {
  --bg-opacity: 1 !important;
  background-color: #7EDCE2 !important;
  background-color: rgba(126, 220, 226, var(--bg-opacity)) !important;
}

.bg-teal-400 {
  --bg-opacity: 1 !important;
  background-color: #16BDCA !important;
  background-color: rgba(22, 189, 202, var(--bg-opacity)) !important;
}

.bg-teal-500 {
  --bg-opacity: 1 !important;
  background-color: #0694A2 !important;
  background-color: rgba(6, 148, 162, var(--bg-opacity)) !important;
}

.bg-teal-600 {
  --bg-opacity: 1 !important;
  background-color: #047481 !important;
  background-color: rgba(4, 116, 129, var(--bg-opacity)) !important;
}

.bg-teal-700 {
  --bg-opacity: 1 !important;
  background-color: #036672 !important;
  background-color: rgba(3, 102, 114, var(--bg-opacity)) !important;
}

.bg-teal-800 {
  --bg-opacity: 1 !important;
  background-color: #05505C !important;
  background-color: rgba(5, 80, 92, var(--bg-opacity)) !important;
}

.bg-teal-900 {
  --bg-opacity: 1 !important;
  background-color: #014451 !important;
  background-color: rgba(1, 68, 81, var(--bg-opacity)) !important;
}

.bg-teal {
  --bg-opacity: 1 !important;
  background-color: #0694A2 !important;
  background-color: rgba(6, 148, 162, var(--bg-opacity)) !important;
}

.bg-blue-50 {
  --bg-opacity: 1 !important;
  background-color: #EBF5FF !important;
  background-color: rgba(235, 245, 255, var(--bg-opacity)) !important;
}

.bg-blue-100 {
  --bg-opacity: 1 !important;
  background-color: #E1EFFE !important;
  background-color: rgba(225, 239, 254, var(--bg-opacity)) !important;
}

.bg-blue-200 {
  --bg-opacity: 1 !important;
  background-color: #C3DDFD !important;
  background-color: rgba(195, 221, 253, var(--bg-opacity)) !important;
}

.bg-blue-300 {
  --bg-opacity: 1 !important;
  background-color: #A4CAFE !important;
  background-color: rgba(164, 202, 254, var(--bg-opacity)) !important;
}

.bg-blue-400 {
  --bg-opacity: 1 !important;
  background-color: #76A9FA !important;
  background-color: rgba(118, 169, 250, var(--bg-opacity)) !important;
}

.bg-blue-500 {
  --bg-opacity: 1 !important;
  background-color: #3F83F8 !important;
  background-color: rgba(63, 131, 248, var(--bg-opacity)) !important;
}

.bg-blue-600 {
  --bg-opacity: 1 !important;
  background-color: #1C64F2 !important;
  background-color: rgba(28, 100, 242, var(--bg-opacity)) !important;
}

.bg-blue-700 {
  --bg-opacity: 1 !important;
  background-color: #1A56DB !important;
  background-color: rgba(26, 86, 219, var(--bg-opacity)) !important;
}

.bg-blue-800 {
  --bg-opacity: 1 !important;
  background-color: #1E429F !important;
  background-color: rgba(30, 66, 159, var(--bg-opacity)) !important;
}

.bg-blue-900 {
  --bg-opacity: 1 !important;
  background-color: #233876 !important;
  background-color: rgba(35, 56, 118, var(--bg-opacity)) !important;
}

.bg-blue {
  --bg-opacity: 1 !important;
  background-color: #3F83F8 !important;
  background-color: rgba(63, 131, 248, var(--bg-opacity)) !important;
}

.bg-indigo-50 {
  --bg-opacity: 1 !important;
  background-color: #F0F5FF !important;
  background-color: rgba(240, 245, 255, var(--bg-opacity)) !important;
}

.bg-indigo-100 {
  --bg-opacity: 1 !important;
  background-color: #E5EDFF !important;
  background-color: rgba(229, 237, 255, var(--bg-opacity)) !important;
}

.bg-indigo-200 {
  --bg-opacity: 1 !important;
  background-color: #CDDBFE !important;
  background-color: rgba(205, 219, 254, var(--bg-opacity)) !important;
}

.bg-indigo-300 {
  --bg-opacity: 1 !important;
  background-color: #B4C6FC !important;
  background-color: rgba(180, 198, 252, var(--bg-opacity)) !important;
}

.bg-indigo-400 {
  --bg-opacity: 1 !important;
  background-color: #8DA2FB !important;
  background-color: rgba(141, 162, 251, var(--bg-opacity)) !important;
}

.bg-indigo-500 {
  --bg-opacity: 1 !important;
  background-color: #6875F5 !important;
  background-color: rgba(104, 117, 245, var(--bg-opacity)) !important;
}

.bg-indigo-600 {
  --bg-opacity: 1 !important;
  background-color: #5850EC !important;
  background-color: rgba(88, 80, 236, var(--bg-opacity)) !important;
}

.bg-indigo-700 {
  --bg-opacity: 1 !important;
  background-color: #5145CD !important;
  background-color: rgba(81, 69, 205, var(--bg-opacity)) !important;
}

.bg-indigo-800 {
  --bg-opacity: 1 !important;
  background-color: #42389D !important;
  background-color: rgba(66, 56, 157, var(--bg-opacity)) !important;
}

.bg-indigo-900 {
  --bg-opacity: 1 !important;
  background-color: #362F78 !important;
  background-color: rgba(54, 47, 120, var(--bg-opacity)) !important;
}

.bg-indigo {
  --bg-opacity: 1 !important;
  background-color: #6875F5 !important;
  background-color: rgba(104, 117, 245, var(--bg-opacity)) !important;
}

.bg-purple-50 {
  --bg-opacity: 1 !important;
  background-color: #F6F5FF !important;
  background-color: rgba(246, 245, 255, var(--bg-opacity)) !important;
}

.bg-purple-100 {
  --bg-opacity: 1 !important;
  background-color: #EDEBFE !important;
  background-color: rgba(237, 235, 254, var(--bg-opacity)) !important;
}

.bg-purple-200 {
  --bg-opacity: 1 !important;
  background-color: #DCD7FE !important;
  background-color: rgba(220, 215, 254, var(--bg-opacity)) !important;
}

.bg-purple-300 {
  --bg-opacity: 1 !important;
  background-color: #CABFFD !important;
  background-color: rgba(202, 191, 253, var(--bg-opacity)) !important;
}

.bg-purple-400 {
  --bg-opacity: 1 !important;
  background-color: #AC94FA !important;
  background-color: rgba(172, 148, 250, var(--bg-opacity)) !important;
}

.bg-purple-500 {
  --bg-opacity: 1 !important;
  background-color: #9061F9 !important;
  background-color: rgba(144, 97, 249, var(--bg-opacity)) !important;
}

.bg-purple-600 {
  --bg-opacity: 1 !important;
  background-color: #7E3AF2 !important;
  background-color: rgba(126, 58, 242, var(--bg-opacity)) !important;
}

.bg-purple-700 {
  --bg-opacity: 1 !important;
  background-color: #6C2BD9 !important;
  background-color: rgba(108, 43, 217, var(--bg-opacity)) !important;
}

.bg-purple-800 {
  --bg-opacity: 1 !important;
  background-color: #5521B5 !important;
  background-color: rgba(85, 33, 181, var(--bg-opacity)) !important;
}

.bg-purple-900 {
  --bg-opacity: 1 !important;
  background-color: #4A1D96 !important;
  background-color: rgba(74, 29, 150, var(--bg-opacity)) !important;
}

.bg-purple {
  --bg-opacity: 1 !important;
  background-color: #9061F9 !important;
  background-color: rgba(144, 97, 249, var(--bg-opacity)) !important;
}

.bg-pink-50 {
  --bg-opacity: 1 !important;
  background-color: #FDF2F8 !important;
  background-color: rgba(253, 242, 248, var(--bg-opacity)) !important;
}

.bg-pink-100 {
  --bg-opacity: 1 !important;
  background-color: #FCE8F3 !important;
  background-color: rgba(252, 232, 243, var(--bg-opacity)) !important;
}

.bg-pink-200 {
  --bg-opacity: 1 !important;
  background-color: #FAD1E8 !important;
  background-color: rgba(250, 209, 232, var(--bg-opacity)) !important;
}

.bg-pink-300 {
  --bg-opacity: 1 !important;
  background-color: #F8B4D9 !important;
  background-color: rgba(248, 180, 217, var(--bg-opacity)) !important;
}

.bg-pink-400 {
  --bg-opacity: 1 !important;
  background-color: #F17EB8 !important;
  background-color: rgba(241, 126, 184, var(--bg-opacity)) !important;
}

.bg-pink-500 {
  --bg-opacity: 1 !important;
  background-color: #E74694 !important;
  background-color: rgba(231, 70, 148, var(--bg-opacity)) !important;
}

.bg-pink-600 {
  --bg-opacity: 1 !important;
  background-color: #D61F69 !important;
  background-color: rgba(214, 31, 105, var(--bg-opacity)) !important;
}

.bg-pink-700 {
  --bg-opacity: 1 !important;
  background-color: #BF125D !important;
  background-color: rgba(191, 18, 93, var(--bg-opacity)) !important;
}

.bg-pink-800 {
  --bg-opacity: 1 !important;
  background-color: #99154B !important;
  background-color: rgba(153, 21, 75, var(--bg-opacity)) !important;
}

.bg-pink-900 {
  --bg-opacity: 1 !important;
  background-color: #751A3D !important;
  background-color: rgba(117, 26, 61, var(--bg-opacity)) !important;
}

.bg-pink {
  --bg-opacity: 1 !important;
  background-color: #E74694 !important;
  background-color: rgba(231, 70, 148, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-current, [class*="theme-dark"] .dark\:bg-current, [class*="theme-light"].light\:bg-current, [class*="theme-light"] .light\:bg-current {
  background-color: currentColor !important;
}

[class*="theme-dark"].dark\:bg-transparent, [class*="theme-dark"] .dark\:bg-transparent, [class*="theme-light"].light\:bg-transparent, [class*="theme-light"] .light\:bg-transparent {
  background-color: transparent !important;
}

[class*="theme-dark"].dark\:bg-white, [class*="theme-dark"] .dark\:bg-white, [class*="theme-light"].light\:bg-white, [class*="theme-light"] .light\:bg-white {
  --bg-opacity: 1 !important;
  background-color: #FFFFFF !important;
  background-color: rgba(255, 255, 255, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-black, [class*="theme-dark"] .dark\:bg-black, [class*="theme-light"].light\:bg-black, [class*="theme-light"] .light\:bg-black {
  --bg-opacity: 1 !important;
  background-color: #000000 !important;
  background-color: rgba(0, 0, 0, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-gray-50, [class*="theme-dark"] .dark\:bg-gray-50, [class*="theme-light"].light\:bg-gray-50, [class*="theme-light"] .light\:bg-gray-50 {
  --bg-opacity: 1 !important;
  background-color: #F9FAFB !important;
  background-color: rgba(249, 250, 251, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-gray-100, [class*="theme-dark"] .dark\:bg-gray-100, [class*="theme-light"].light\:bg-gray-100, [class*="theme-light"] .light\:bg-gray-100 {
  --bg-opacity: 1 !important;
  background-color: #F4F5F7 !important;
  background-color: rgba(244, 245, 247, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-gray-200, [class*="theme-dark"] .dark\:bg-gray-200, [class*="theme-light"].light\:bg-gray-200, [class*="theme-light"] .light\:bg-gray-200 {
  --bg-opacity: 1 !important;
  background-color: #E5E7EB !important;
  background-color: rgba(229, 231, 235, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-gray-300, [class*="theme-dark"] .dark\:bg-gray-300, [class*="theme-light"].light\:bg-gray-300, [class*="theme-light"] .light\:bg-gray-300 {
  --bg-opacity: 1 !important;
  background-color: #D2D6DC !important;
  background-color: rgba(210, 214, 220, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-gray-400, [class*="theme-dark"] .dark\:bg-gray-400, [class*="theme-light"].light\:bg-gray-400, [class*="theme-light"] .light\:bg-gray-400 {
  --bg-opacity: 1 !important;
  background-color: #9FA6B2 !important;
  background-color: rgba(159, 166, 178, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-gray-500, [class*="theme-dark"] .dark\:bg-gray-500, [class*="theme-light"].light\:bg-gray-500, [class*="theme-light"] .light\:bg-gray-500 {
  --bg-opacity: 1 !important;
  background-color: #6B7280 !important;
  background-color: rgba(107, 114, 128, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-gray-600, [class*="theme-dark"] .dark\:bg-gray-600, [class*="theme-light"].light\:bg-gray-600, [class*="theme-light"] .light\:bg-gray-600 {
  --bg-opacity: 1 !important;
  background-color: #4B5563 !important;
  background-color: rgba(75, 85, 99, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-gray-700, [class*="theme-dark"] .dark\:bg-gray-700, [class*="theme-light"].light\:bg-gray-700, [class*="theme-light"] .light\:bg-gray-700 {
  --bg-opacity: 1 !important;
  background-color: #374151 !important;
  background-color: rgba(55, 65, 81, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-gray-800, [class*="theme-dark"] .dark\:bg-gray-800, [class*="theme-light"].light\:bg-gray-800, [class*="theme-light"] .light\:bg-gray-800 {
  --bg-opacity: 1 !important;
  background-color: #252F3F !important;
  background-color: rgba(37, 47, 63, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-gray-900, [class*="theme-dark"] .dark\:bg-gray-900, [class*="theme-light"].light\:bg-gray-900, [class*="theme-light"] .light\:bg-gray-900 {
  --bg-opacity: 1 !important;
  background-color: #161E2E !important;
  background-color: rgba(22, 30, 46, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-gray, [class*="theme-dark"] .dark\:bg-gray, [class*="theme-light"].light\:bg-gray, [class*="theme-light"] .light\:bg-gray {
  --bg-opacity: 1 !important;
  background-color: #6B7280 !important;
  background-color: rgba(107, 114, 128, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-cool-gray-50, [class*="theme-dark"] .dark\:bg-cool-gray-50, [class*="theme-light"].light\:bg-cool-gray-50, [class*="theme-light"] .light\:bg-cool-gray-50 {
  --bg-opacity: 1 !important;
  background-color: #FBFDFE !important;
  background-color: rgba(251, 253, 254, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-cool-gray-100, [class*="theme-dark"] .dark\:bg-cool-gray-100, [class*="theme-light"].light\:bg-cool-gray-100, [class*="theme-light"] .light\:bg-cool-gray-100 {
  --bg-opacity: 1 !important;
  background-color: #F1F5F9 !important;
  background-color: rgba(241, 245, 249, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-cool-gray-200, [class*="theme-dark"] .dark\:bg-cool-gray-200, [class*="theme-light"].light\:bg-cool-gray-200, [class*="theme-light"] .light\:bg-cool-gray-200 {
  --bg-opacity: 1 !important;
  background-color: #E2E8F0 !important;
  background-color: rgba(226, 232, 240, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-cool-gray-300, [class*="theme-dark"] .dark\:bg-cool-gray-300, [class*="theme-light"].light\:bg-cool-gray-300, [class*="theme-light"] .light\:bg-cool-gray-300 {
  --bg-opacity: 1 !important;
  background-color: #CFD8E3 !important;
  background-color: rgba(207, 216, 227, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-cool-gray-400, [class*="theme-dark"] .dark\:bg-cool-gray-400, [class*="theme-light"].light\:bg-cool-gray-400, [class*="theme-light"] .light\:bg-cool-gray-400 {
  --bg-opacity: 1 !important;
  background-color: #97A6BA !important;
  background-color: rgba(151, 166, 186, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-cool-gray-500, [class*="theme-dark"] .dark\:bg-cool-gray-500, [class*="theme-light"].light\:bg-cool-gray-500, [class*="theme-light"] .light\:bg-cool-gray-500 {
  --bg-opacity: 1 !important;
  background-color: #64748B !important;
  background-color: rgba(100, 116, 139, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-cool-gray-600, [class*="theme-dark"] .dark\:bg-cool-gray-600, [class*="theme-light"].light\:bg-cool-gray-600, [class*="theme-light"] .light\:bg-cool-gray-600 {
  --bg-opacity: 1 !important;
  background-color: #475569 !important;
  background-color: rgba(71, 85, 105, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-cool-gray-700, [class*="theme-dark"] .dark\:bg-cool-gray-700, [class*="theme-light"].light\:bg-cool-gray-700, [class*="theme-light"] .light\:bg-cool-gray-700 {
  --bg-opacity: 1 !important;
  background-color: #364152 !important;
  background-color: rgba(54, 65, 82, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-cool-gray-800, [class*="theme-dark"] .dark\:bg-cool-gray-800, [class*="theme-light"].light\:bg-cool-gray-800, [class*="theme-light"] .light\:bg-cool-gray-800 {
  --bg-opacity: 1 !important;
  background-color: #27303F !important;
  background-color: rgba(39, 48, 63, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-cool-gray-900, [class*="theme-dark"] .dark\:bg-cool-gray-900, [class*="theme-light"].light\:bg-cool-gray-900, [class*="theme-light"] .light\:bg-cool-gray-900 {
  --bg-opacity: 1 !important;
  background-color: #1A202E !important;
  background-color: rgba(26, 32, 46, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-cool-gray, [class*="theme-dark"] .dark\:bg-cool-gray, [class*="theme-light"].light\:bg-cool-gray, [class*="theme-light"] .light\:bg-cool-gray {
  --bg-opacity: 1 !important;
  background-color: #64748B !important;
  background-color: rgba(100, 116, 139, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-red-50, [class*="theme-dark"] .dark\:bg-red-50, [class*="theme-light"].light\:bg-red-50, [class*="theme-light"] .light\:bg-red-50 {
  --bg-opacity: 1 !important;
  background-color: #FDF2F2 !important;
  background-color: rgba(253, 242, 242, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-red-100, [class*="theme-dark"] .dark\:bg-red-100, [class*="theme-light"].light\:bg-red-100, [class*="theme-light"] .light\:bg-red-100 {
  --bg-opacity: 1 !important;
  background-color: #FDE8E8 !important;
  background-color: rgba(253, 232, 232, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-red-200, [class*="theme-dark"] .dark\:bg-red-200, [class*="theme-light"].light\:bg-red-200, [class*="theme-light"] .light\:bg-red-200 {
  --bg-opacity: 1 !important;
  background-color: #FBD5D5 !important;
  background-color: rgba(251, 213, 213, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-red-300, [class*="theme-dark"] .dark\:bg-red-300, [class*="theme-light"].light\:bg-red-300, [class*="theme-light"] .light\:bg-red-300 {
  --bg-opacity: 1 !important;
  background-color: #F8B4B4 !important;
  background-color: rgba(248, 180, 180, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-red-400, [class*="theme-dark"] .dark\:bg-red-400, [class*="theme-light"].light\:bg-red-400, [class*="theme-light"] .light\:bg-red-400 {
  --bg-opacity: 1 !important;
  background-color: #F98080 !important;
  background-color: rgba(249, 128, 128, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-red-500, [class*="theme-dark"] .dark\:bg-red-500, [class*="theme-light"].light\:bg-red-500, [class*="theme-light"] .light\:bg-red-500 {
  --bg-opacity: 1 !important;
  background-color: #F05252 !important;
  background-color: rgba(240, 82, 82, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-red-600, [class*="theme-dark"] .dark\:bg-red-600, [class*="theme-light"].light\:bg-red-600, [class*="theme-light"] .light\:bg-red-600 {
  --bg-opacity: 1 !important;
  background-color: #E02424 !important;
  background-color: rgba(224, 36, 36, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-red-700, [class*="theme-dark"] .dark\:bg-red-700, [class*="theme-light"].light\:bg-red-700, [class*="theme-light"] .light\:bg-red-700 {
  --bg-opacity: 1 !important;
  background-color: #C81E1E !important;
  background-color: rgba(200, 30, 30, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-red-800, [class*="theme-dark"] .dark\:bg-red-800, [class*="theme-light"].light\:bg-red-800, [class*="theme-light"] .light\:bg-red-800 {
  --bg-opacity: 1 !important;
  background-color: #9B1C1C !important;
  background-color: rgba(155, 28, 28, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-red-900, [class*="theme-dark"] .dark\:bg-red-900, [class*="theme-light"].light\:bg-red-900, [class*="theme-light"] .light\:bg-red-900 {
  --bg-opacity: 1 !important;
  background-color: #771D1D !important;
  background-color: rgba(119, 29, 29, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-red, [class*="theme-dark"] .dark\:bg-red, [class*="theme-light"].light\:bg-red, [class*="theme-light"] .light\:bg-red {
  --bg-opacity: 1 !important;
  background-color: #F05252 !important;
  background-color: rgba(240, 82, 82, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-orange-50, [class*="theme-dark"] .dark\:bg-orange-50, [class*="theme-light"].light\:bg-orange-50, [class*="theme-light"] .light\:bg-orange-50 {
  --bg-opacity: 1 !important;
  background-color: #FFF8F1 !important;
  background-color: rgba(255, 248, 241, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-orange-100, [class*="theme-dark"] .dark\:bg-orange-100, [class*="theme-light"].light\:bg-orange-100, [class*="theme-light"] .light\:bg-orange-100 {
  --bg-opacity: 1 !important;
  background-color: #FEECDC !important;
  background-color: rgba(254, 236, 220, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-orange-200, [class*="theme-dark"] .dark\:bg-orange-200, [class*="theme-light"].light\:bg-orange-200, [class*="theme-light"] .light\:bg-orange-200 {
  --bg-opacity: 1 !important;
  background-color: #FCD9BD !important;
  background-color: rgba(252, 217, 189, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-orange-300, [class*="theme-dark"] .dark\:bg-orange-300, [class*="theme-light"].light\:bg-orange-300, [class*="theme-light"] .light\:bg-orange-300 {
  --bg-opacity: 1 !important;
  background-color: #FDBA8C !important;
  background-color: rgba(253, 186, 140, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-orange-400, [class*="theme-dark"] .dark\:bg-orange-400, [class*="theme-light"].light\:bg-orange-400, [class*="theme-light"] .light\:bg-orange-400 {
  --bg-opacity: 1 !important;
  background-color: #FF8A4C !important;
  background-color: rgba(255, 138, 76, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-orange-500, [class*="theme-dark"] .dark\:bg-orange-500, [class*="theme-light"].light\:bg-orange-500, [class*="theme-light"] .light\:bg-orange-500 {
  --bg-opacity: 1 !important;
  background-color: #FF5A1F !important;
  background-color: rgba(255, 90, 31, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-orange-600, [class*="theme-dark"] .dark\:bg-orange-600, [class*="theme-light"].light\:bg-orange-600, [class*="theme-light"] .light\:bg-orange-600 {
  --bg-opacity: 1 !important;
  background-color: #ff6700 !important;
  background-color: rgba(255, 103, 0, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-orange-700, [class*="theme-dark"] .dark\:bg-orange-700, [class*="theme-light"].light\:bg-orange-700, [class*="theme-light"] .light\:bg-orange-700 {
  --bg-opacity: 1 !important;
  background-color: #B43403 !important;
  background-color: rgba(180, 52, 3, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-orange-800, [class*="theme-dark"] .dark\:bg-orange-800, [class*="theme-light"].light\:bg-orange-800, [class*="theme-light"] .light\:bg-orange-800 {
  --bg-opacity: 1 !important;
  background-color: #8A2C0D !important;
  background-color: rgba(138, 44, 13, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-orange-900, [class*="theme-dark"] .dark\:bg-orange-900, [class*="theme-light"].light\:bg-orange-900, [class*="theme-light"] .light\:bg-orange-900 {
  --bg-opacity: 1 !important;
  background-color: #771D1D !important;
  background-color: rgba(119, 29, 29, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-orange, [class*="theme-dark"] .dark\:bg-orange, [class*="theme-light"].light\:bg-orange, [class*="theme-light"] .light\:bg-orange {
  --bg-opacity: 1 !important;
  background-color: #FF5A1F !important;
  background-color: rgba(255, 90, 31, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-yellow-50, [class*="theme-dark"] .dark\:bg-yellow-50, [class*="theme-light"].light\:bg-yellow-50, [class*="theme-light"] .light\:bg-yellow-50 {
  --bg-opacity: 1 !important;
  background-color: #FDFDEA !important;
  background-color: rgba(253, 253, 234, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-yellow-100, [class*="theme-dark"] .dark\:bg-yellow-100, [class*="theme-light"].light\:bg-yellow-100, [class*="theme-light"] .light\:bg-yellow-100 {
  --bg-opacity: 1 !important;
  background-color: #FDF6B2 !important;
  background-color: rgba(253, 246, 178, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-yellow-200, [class*="theme-dark"] .dark\:bg-yellow-200, [class*="theme-light"].light\:bg-yellow-200, [class*="theme-light"] .light\:bg-yellow-200 {
  --bg-opacity: 1 !important;
  background-color: #FCE96A !important;
  background-color: rgba(252, 233, 106, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-yellow-300, [class*="theme-dark"] .dark\:bg-yellow-300, [class*="theme-light"].light\:bg-yellow-300, [class*="theme-light"] .light\:bg-yellow-300 {
  --bg-opacity: 1 !important;
  background-color: #FACA15 !important;
  background-color: rgba(250, 202, 21, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-yellow-400, [class*="theme-dark"] .dark\:bg-yellow-400, [class*="theme-light"].light\:bg-yellow-400, [class*="theme-light"] .light\:bg-yellow-400 {
  --bg-opacity: 1 !important;
  background-color: #E3A008 !important;
  background-color: rgba(227, 160, 8, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-yellow-500, [class*="theme-dark"] .dark\:bg-yellow-500, [class*="theme-light"].light\:bg-yellow-500, [class*="theme-light"] .light\:bg-yellow-500 {
  --bg-opacity: 1 !important;
  background-color: #C27803 !important;
  background-color: rgba(194, 120, 3, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-yellow-600, [class*="theme-dark"] .dark\:bg-yellow-600, [class*="theme-light"].light\:bg-yellow-600, [class*="theme-light"] .light\:bg-yellow-600 {
  --bg-opacity: 1 !important;
  background-color: #9F580A !important;
  background-color: rgba(159, 88, 10, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-yellow-700, [class*="theme-dark"] .dark\:bg-yellow-700, [class*="theme-light"].light\:bg-yellow-700, [class*="theme-light"] .light\:bg-yellow-700 {
  --bg-opacity: 1 !important;
  background-color: #8E4B10 !important;
  background-color: rgba(142, 75, 16, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-yellow-800, [class*="theme-dark"] .dark\:bg-yellow-800, [class*="theme-light"].light\:bg-yellow-800, [class*="theme-light"] .light\:bg-yellow-800 {
  --bg-opacity: 1 !important;
  background-color: #723B13 !important;
  background-color: rgba(114, 59, 19, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-yellow-900, [class*="theme-dark"] .dark\:bg-yellow-900, [class*="theme-light"].light\:bg-yellow-900, [class*="theme-light"] .light\:bg-yellow-900 {
  --bg-opacity: 1 !important;
  background-color: #633112 !important;
  background-color: rgba(99, 49, 18, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-yellow, [class*="theme-dark"] .dark\:bg-yellow, [class*="theme-light"].light\:bg-yellow, [class*="theme-light"] .light\:bg-yellow {
  --bg-opacity: 1 !important;
  background-color: #C27803 !important;
  background-color: rgba(194, 120, 3, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-green-50, [class*="theme-dark"] .dark\:bg-green-50, [class*="theme-light"].light\:bg-green-50, [class*="theme-light"] .light\:bg-green-50 {
  --bg-opacity: 1 !important;
  background-color: #F3FAF7 !important;
  background-color: rgba(243, 250, 247, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-green-100, [class*="theme-dark"] .dark\:bg-green-100, [class*="theme-light"].light\:bg-green-100, [class*="theme-light"] .light\:bg-green-100 {
  --bg-opacity: 1 !important;
  background-color: #DEF7EC !important;
  background-color: rgba(222, 247, 236, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-green-200, [class*="theme-dark"] .dark\:bg-green-200, [class*="theme-light"].light\:bg-green-200, [class*="theme-light"] .light\:bg-green-200 {
  --bg-opacity: 1 !important;
  background-color: #BCF0DA !important;
  background-color: rgba(188, 240, 218, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-green-300, [class*="theme-dark"] .dark\:bg-green-300, [class*="theme-light"].light\:bg-green-300, [class*="theme-light"] .light\:bg-green-300 {
  --bg-opacity: 1 !important;
  background-color: #84E1BC !important;
  background-color: rgba(132, 225, 188, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-green-400, [class*="theme-dark"] .dark\:bg-green-400, [class*="theme-light"].light\:bg-green-400, [class*="theme-light"] .light\:bg-green-400 {
  --bg-opacity: 1 !important;
  background-color: #31C48D !important;
  background-color: rgba(49, 196, 141, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-green-500, [class*="theme-dark"] .dark\:bg-green-500, [class*="theme-light"].light\:bg-green-500, [class*="theme-light"] .light\:bg-green-500 {
  --bg-opacity: 1 !important;
  background-color: #0E9F6E !important;
  background-color: rgba(14, 159, 110, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-green-600, [class*="theme-dark"] .dark\:bg-green-600, [class*="theme-light"].light\:bg-green-600, [class*="theme-light"] .light\:bg-green-600 {
  --bg-opacity: 1 !important;
  background-color: #057A55 !important;
  background-color: rgba(5, 122, 85, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-green-700, [class*="theme-dark"] .dark\:bg-green-700, [class*="theme-light"].light\:bg-green-700, [class*="theme-light"] .light\:bg-green-700 {
  --bg-opacity: 1 !important;
  background-color: #046C4E !important;
  background-color: rgba(4, 108, 78, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-green-800, [class*="theme-dark"] .dark\:bg-green-800, [class*="theme-light"].light\:bg-green-800, [class*="theme-light"] .light\:bg-green-800 {
  --bg-opacity: 1 !important;
  background-color: #03543F !important;
  background-color: rgba(3, 84, 63, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-green-900, [class*="theme-dark"] .dark\:bg-green-900, [class*="theme-light"].light\:bg-green-900, [class*="theme-light"] .light\:bg-green-900 {
  --bg-opacity: 1 !important;
  background-color: #014737 !important;
  background-color: rgba(1, 71, 55, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-green, [class*="theme-dark"] .dark\:bg-green, [class*="theme-light"].light\:bg-green, [class*="theme-light"] .light\:bg-green {
  --bg-opacity: 1 !important;
  background-color: #0E9F6E !important;
  background-color: rgba(14, 159, 110, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-teal-50, [class*="theme-dark"] .dark\:bg-teal-50, [class*="theme-light"].light\:bg-teal-50, [class*="theme-light"] .light\:bg-teal-50 {
  --bg-opacity: 1 !important;
  background-color: #EDFAFA !important;
  background-color: rgba(237, 250, 250, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-teal-100, [class*="theme-dark"] .dark\:bg-teal-100, [class*="theme-light"].light\:bg-teal-100, [class*="theme-light"] .light\:bg-teal-100 {
  --bg-opacity: 1 !important;
  background-color: #D5F5F6 !important;
  background-color: rgba(213, 245, 246, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-teal-200, [class*="theme-dark"] .dark\:bg-teal-200, [class*="theme-light"].light\:bg-teal-200, [class*="theme-light"] .light\:bg-teal-200 {
  --bg-opacity: 1 !important;
  background-color: #AFECEF !important;
  background-color: rgba(175, 236, 239, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-teal-300, [class*="theme-dark"] .dark\:bg-teal-300, [class*="theme-light"].light\:bg-teal-300, [class*="theme-light"] .light\:bg-teal-300 {
  --bg-opacity: 1 !important;
  background-color: #7EDCE2 !important;
  background-color: rgba(126, 220, 226, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-teal-400, [class*="theme-dark"] .dark\:bg-teal-400, [class*="theme-light"].light\:bg-teal-400, [class*="theme-light"] .light\:bg-teal-400 {
  --bg-opacity: 1 !important;
  background-color: #16BDCA !important;
  background-color: rgba(22, 189, 202, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-teal-500, [class*="theme-dark"] .dark\:bg-teal-500, [class*="theme-light"].light\:bg-teal-500, [class*="theme-light"] .light\:bg-teal-500 {
  --bg-opacity: 1 !important;
  background-color: #0694A2 !important;
  background-color: rgba(6, 148, 162, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-teal-600, [class*="theme-dark"] .dark\:bg-teal-600, [class*="theme-light"].light\:bg-teal-600, [class*="theme-light"] .light\:bg-teal-600 {
  --bg-opacity: 1 !important;
  background-color: #047481 !important;
  background-color: rgba(4, 116, 129, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-teal-700, [class*="theme-dark"] .dark\:bg-teal-700, [class*="theme-light"].light\:bg-teal-700, [class*="theme-light"] .light\:bg-teal-700 {
  --bg-opacity: 1 !important;
  background-color: #036672 !important;
  background-color: rgba(3, 102, 114, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-teal-800, [class*="theme-dark"] .dark\:bg-teal-800, [class*="theme-light"].light\:bg-teal-800, [class*="theme-light"] .light\:bg-teal-800 {
  --bg-opacity: 1 !important;
  background-color: #05505C !important;
  background-color: rgba(5, 80, 92, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-teal-900, [class*="theme-dark"] .dark\:bg-teal-900, [class*="theme-light"].light\:bg-teal-900, [class*="theme-light"] .light\:bg-teal-900 {
  --bg-opacity: 1 !important;
  background-color: #014451 !important;
  background-color: rgba(1, 68, 81, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-teal, [class*="theme-dark"] .dark\:bg-teal, [class*="theme-light"].light\:bg-teal, [class*="theme-light"] .light\:bg-teal {
  --bg-opacity: 1 !important;
  background-color: #0694A2 !important;
  background-color: rgba(6, 148, 162, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-blue-50, [class*="theme-dark"] .dark\:bg-blue-50, [class*="theme-light"].light\:bg-blue-50, [class*="theme-light"] .light\:bg-blue-50 {
  --bg-opacity: 1 !important;
  background-color: #EBF5FF !important;
  background-color: rgba(235, 245, 255, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-blue-100, [class*="theme-dark"] .dark\:bg-blue-100, [class*="theme-light"].light\:bg-blue-100, [class*="theme-light"] .light\:bg-blue-100 {
  --bg-opacity: 1 !important;
  background-color: #E1EFFE !important;
  background-color: rgba(225, 239, 254, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-blue-200, [class*="theme-dark"] .dark\:bg-blue-200, [class*="theme-light"].light\:bg-blue-200, [class*="theme-light"] .light\:bg-blue-200 {
  --bg-opacity: 1 !important;
  background-color: #C3DDFD !important;
  background-color: rgba(195, 221, 253, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-blue-300, [class*="theme-dark"] .dark\:bg-blue-300, [class*="theme-light"].light\:bg-blue-300, [class*="theme-light"] .light\:bg-blue-300 {
  --bg-opacity: 1 !important;
  background-color: #A4CAFE !important;
  background-color: rgba(164, 202, 254, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-blue-400, [class*="theme-dark"] .dark\:bg-blue-400, [class*="theme-light"].light\:bg-blue-400, [class*="theme-light"] .light\:bg-blue-400 {
  --bg-opacity: 1 !important;
  background-color: #76A9FA !important;
  background-color: rgba(118, 169, 250, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-blue-500, [class*="theme-dark"] .dark\:bg-blue-500, [class*="theme-light"].light\:bg-blue-500, [class*="theme-light"] .light\:bg-blue-500 {
  --bg-opacity: 1 !important;
  background-color: #3F83F8 !important;
  background-color: rgba(63, 131, 248, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-blue-600, [class*="theme-dark"] .dark\:bg-blue-600, [class*="theme-light"].light\:bg-blue-600, [class*="theme-light"] .light\:bg-blue-600 {
  --bg-opacity: 1 !important;
  background-color: #1C64F2 !important;
  background-color: rgba(28, 100, 242, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-blue-700, [class*="theme-dark"] .dark\:bg-blue-700, [class*="theme-light"].light\:bg-blue-700, [class*="theme-light"] .light\:bg-blue-700 {
  --bg-opacity: 1 !important;
  background-color: #1A56DB !important;
  background-color: rgba(26, 86, 219, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-blue-800, [class*="theme-dark"] .dark\:bg-blue-800, [class*="theme-light"].light\:bg-blue-800, [class*="theme-light"] .light\:bg-blue-800 {
  --bg-opacity: 1 !important;
  background-color: #1E429F !important;
  background-color: rgba(30, 66, 159, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-blue-900, [class*="theme-dark"] .dark\:bg-blue-900, [class*="theme-light"].light\:bg-blue-900, [class*="theme-light"] .light\:bg-blue-900 {
  --bg-opacity: 1 !important;
  background-color: #233876 !important;
  background-color: rgba(35, 56, 118, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-blue, [class*="theme-dark"] .dark\:bg-blue, [class*="theme-light"].light\:bg-blue, [class*="theme-light"] .light\:bg-blue {
  --bg-opacity: 1 !important;
  background-color: #3F83F8 !important;
  background-color: rgba(63, 131, 248, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-indigo-50, [class*="theme-dark"] .dark\:bg-indigo-50, [class*="theme-light"].light\:bg-indigo-50, [class*="theme-light"] .light\:bg-indigo-50 {
  --bg-opacity: 1 !important;
  background-color: #F0F5FF !important;
  background-color: rgba(240, 245, 255, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-indigo-100, [class*="theme-dark"] .dark\:bg-indigo-100, [class*="theme-light"].light\:bg-indigo-100, [class*="theme-light"] .light\:bg-indigo-100 {
  --bg-opacity: 1 !important;
  background-color: #E5EDFF !important;
  background-color: rgba(229, 237, 255, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-indigo-200, [class*="theme-dark"] .dark\:bg-indigo-200, [class*="theme-light"].light\:bg-indigo-200, [class*="theme-light"] .light\:bg-indigo-200 {
  --bg-opacity: 1 !important;
  background-color: #CDDBFE !important;
  background-color: rgba(205, 219, 254, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-indigo-300, [class*="theme-dark"] .dark\:bg-indigo-300, [class*="theme-light"].light\:bg-indigo-300, [class*="theme-light"] .light\:bg-indigo-300 {
  --bg-opacity: 1 !important;
  background-color: #B4C6FC !important;
  background-color: rgba(180, 198, 252, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-indigo-400, [class*="theme-dark"] .dark\:bg-indigo-400, [class*="theme-light"].light\:bg-indigo-400, [class*="theme-light"] .light\:bg-indigo-400 {
  --bg-opacity: 1 !important;
  background-color: #8DA2FB !important;
  background-color: rgba(141, 162, 251, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-indigo-500, [class*="theme-dark"] .dark\:bg-indigo-500, [class*="theme-light"].light\:bg-indigo-500, [class*="theme-light"] .light\:bg-indigo-500 {
  --bg-opacity: 1 !important;
  background-color: #6875F5 !important;
  background-color: rgba(104, 117, 245, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-indigo-600, [class*="theme-dark"] .dark\:bg-indigo-600, [class*="theme-light"].light\:bg-indigo-600, [class*="theme-light"] .light\:bg-indigo-600 {
  --bg-opacity: 1 !important;
  background-color: #5850EC !important;
  background-color: rgba(88, 80, 236, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-indigo-700, [class*="theme-dark"] .dark\:bg-indigo-700, [class*="theme-light"].light\:bg-indigo-700, [class*="theme-light"] .light\:bg-indigo-700 {
  --bg-opacity: 1 !important;
  background-color: #5145CD !important;
  background-color: rgba(81, 69, 205, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-indigo-800, [class*="theme-dark"] .dark\:bg-indigo-800, [class*="theme-light"].light\:bg-indigo-800, [class*="theme-light"] .light\:bg-indigo-800 {
  --bg-opacity: 1 !important;
  background-color: #42389D !important;
  background-color: rgba(66, 56, 157, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-indigo-900, [class*="theme-dark"] .dark\:bg-indigo-900, [class*="theme-light"].light\:bg-indigo-900, [class*="theme-light"] .light\:bg-indigo-900 {
  --bg-opacity: 1 !important;
  background-color: #362F78 !important;
  background-color: rgba(54, 47, 120, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-indigo, [class*="theme-dark"] .dark\:bg-indigo, [class*="theme-light"].light\:bg-indigo, [class*="theme-light"] .light\:bg-indigo {
  --bg-opacity: 1 !important;
  background-color: #6875F5 !important;
  background-color: rgba(104, 117, 245, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-purple-50, [class*="theme-dark"] .dark\:bg-purple-50, [class*="theme-light"].light\:bg-purple-50, [class*="theme-light"] .light\:bg-purple-50 {
  --bg-opacity: 1 !important;
  background-color: #F6F5FF !important;
  background-color: rgba(246, 245, 255, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-purple-100, [class*="theme-dark"] .dark\:bg-purple-100, [class*="theme-light"].light\:bg-purple-100, [class*="theme-light"] .light\:bg-purple-100 {
  --bg-opacity: 1 !important;
  background-color: #EDEBFE !important;
  background-color: rgba(237, 235, 254, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-purple-200, [class*="theme-dark"] .dark\:bg-purple-200, [class*="theme-light"].light\:bg-purple-200, [class*="theme-light"] .light\:bg-purple-200 {
  --bg-opacity: 1 !important;
  background-color: #DCD7FE !important;
  background-color: rgba(220, 215, 254, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-purple-300, [class*="theme-dark"] .dark\:bg-purple-300, [class*="theme-light"].light\:bg-purple-300, [class*="theme-light"] .light\:bg-purple-300 {
  --bg-opacity: 1 !important;
  background-color: #CABFFD !important;
  background-color: rgba(202, 191, 253, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-purple-400, [class*="theme-dark"] .dark\:bg-purple-400, [class*="theme-light"].light\:bg-purple-400, [class*="theme-light"] .light\:bg-purple-400 {
  --bg-opacity: 1 !important;
  background-color: #AC94FA !important;
  background-color: rgba(172, 148, 250, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-purple-500, [class*="theme-dark"] .dark\:bg-purple-500, [class*="theme-light"].light\:bg-purple-500, [class*="theme-light"] .light\:bg-purple-500 {
  --bg-opacity: 1 !important;
  background-color: #9061F9 !important;
  background-color: rgba(144, 97, 249, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-purple-600, [class*="theme-dark"] .dark\:bg-purple-600, [class*="theme-light"].light\:bg-purple-600, [class*="theme-light"] .light\:bg-purple-600 {
  --bg-opacity: 1 !important;
  background-color: #7E3AF2 !important;
  background-color: rgba(126, 58, 242, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-purple-700, [class*="theme-dark"] .dark\:bg-purple-700, [class*="theme-light"].light\:bg-purple-700, [class*="theme-light"] .light\:bg-purple-700 {
  --bg-opacity: 1 !important;
  background-color: #6C2BD9 !important;
  background-color: rgba(108, 43, 217, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-purple-800, [class*="theme-dark"] .dark\:bg-purple-800, [class*="theme-light"].light\:bg-purple-800, [class*="theme-light"] .light\:bg-purple-800 {
  --bg-opacity: 1 !important;
  background-color: #5521B5 !important;
  background-color: rgba(85, 33, 181, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-purple-900, [class*="theme-dark"] .dark\:bg-purple-900, [class*="theme-light"].light\:bg-purple-900, [class*="theme-light"] .light\:bg-purple-900 {
  --bg-opacity: 1 !important;
  background-color: #4A1D96 !important;
  background-color: rgba(74, 29, 150, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-purple, [class*="theme-dark"] .dark\:bg-purple, [class*="theme-light"].light\:bg-purple, [class*="theme-light"] .light\:bg-purple {
  --bg-opacity: 1 !important;
  background-color: #9061F9 !important;
  background-color: rgba(144, 97, 249, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-pink-50, [class*="theme-dark"] .dark\:bg-pink-50, [class*="theme-light"].light\:bg-pink-50, [class*="theme-light"] .light\:bg-pink-50 {
  --bg-opacity: 1 !important;
  background-color: #FDF2F8 !important;
  background-color: rgba(253, 242, 248, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-pink-100, [class*="theme-dark"] .dark\:bg-pink-100, [class*="theme-light"].light\:bg-pink-100, [class*="theme-light"] .light\:bg-pink-100 {
  --bg-opacity: 1 !important;
  background-color: #FCE8F3 !important;
  background-color: rgba(252, 232, 243, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-pink-200, [class*="theme-dark"] .dark\:bg-pink-200, [class*="theme-light"].light\:bg-pink-200, [class*="theme-light"] .light\:bg-pink-200 {
  --bg-opacity: 1 !important;
  background-color: #FAD1E8 !important;
  background-color: rgba(250, 209, 232, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-pink-300, [class*="theme-dark"] .dark\:bg-pink-300, [class*="theme-light"].light\:bg-pink-300, [class*="theme-light"] .light\:bg-pink-300 {
  --bg-opacity: 1 !important;
  background-color: #F8B4D9 !important;
  background-color: rgba(248, 180, 217, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-pink-400, [class*="theme-dark"] .dark\:bg-pink-400, [class*="theme-light"].light\:bg-pink-400, [class*="theme-light"] .light\:bg-pink-400 {
  --bg-opacity: 1 !important;
  background-color: #F17EB8 !important;
  background-color: rgba(241, 126, 184, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-pink-500, [class*="theme-dark"] .dark\:bg-pink-500, [class*="theme-light"].light\:bg-pink-500, [class*="theme-light"] .light\:bg-pink-500 {
  --bg-opacity: 1 !important;
  background-color: #E74694 !important;
  background-color: rgba(231, 70, 148, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-pink-600, [class*="theme-dark"] .dark\:bg-pink-600, [class*="theme-light"].light\:bg-pink-600, [class*="theme-light"] .light\:bg-pink-600 {
  --bg-opacity: 1 !important;
  background-color: #D61F69 !important;
  background-color: rgba(214, 31, 105, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-pink-700, [class*="theme-dark"] .dark\:bg-pink-700, [class*="theme-light"].light\:bg-pink-700, [class*="theme-light"] .light\:bg-pink-700 {
  --bg-opacity: 1 !important;
  background-color: #BF125D !important;
  background-color: rgba(191, 18, 93, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-pink-800, [class*="theme-dark"] .dark\:bg-pink-800, [class*="theme-light"].light\:bg-pink-800, [class*="theme-light"] .light\:bg-pink-800 {
  --bg-opacity: 1 !important;
  background-color: #99154B !important;
  background-color: rgba(153, 21, 75, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-pink-900, [class*="theme-dark"] .dark\:bg-pink-900, [class*="theme-light"].light\:bg-pink-900, [class*="theme-light"] .light\:bg-pink-900 {
  --bg-opacity: 1 !important;
  background-color: #751A3D !important;
  background-color: rgba(117, 26, 61, var(--bg-opacity)) !important;
}

[class*="theme-dark"].dark\:bg-pink, [class*="theme-dark"] .dark\:bg-pink, [class*="theme-light"].light\:bg-pink, [class*="theme-light"] .light\:bg-pink {
  --bg-opacity: 1 !important;
  background-color: #E74694 !important;
  background-color: rgba(231, 70, 148, var(--bg-opacity)) !important;
}

.bg-opacity-0 {
  --bg-opacity: 0 !important;
}

.bg-opacity-12 {
  --bg-opacity: 0.12 !important;
}

.bg-opacity-25 {
  --bg-opacity: 0.25 !important;
}

.bg-opacity-38 {
  --bg-opacity: 0.38 !important;
}

.bg-opacity-50 {
  --bg-opacity: 0.5 !important;
}

.bg-opacity-54 {
  --bg-opacity: 0.54 !important;
}

.bg-opacity-70 {
  --bg-opacity: 0.70 !important;
}

.bg-opacity-75 {
  --bg-opacity: 0.75 !important;
}

.bg-opacity-84 {
  --bg-opacity: 0.84 !important;
}

.bg-opacity-100 {
  --bg-opacity: 1 !important;
}

.hover\:bg-opacity-0:hover {
  --bg-opacity: 0 !important;
}

.hover\:bg-opacity-12:hover {
  --bg-opacity: 0.12 !important;
}

.hover\:bg-opacity-25:hover {
  --bg-opacity: 0.25 !important;
}

.hover\:bg-opacity-38:hover {
  --bg-opacity: 0.38 !important;
}

.hover\:bg-opacity-50:hover {
  --bg-opacity: 0.5 !important;
}

.hover\:bg-opacity-54:hover {
  --bg-opacity: 0.54 !important;
}

.hover\:bg-opacity-70:hover {
  --bg-opacity: 0.70 !important;
}

.hover\:bg-opacity-75:hover {
  --bg-opacity: 0.75 !important;
}

.hover\:bg-opacity-84:hover {
  --bg-opacity: 0.84 !important;
}

.hover\:bg-opacity-100:hover {
  --bg-opacity: 1 !important;
}

.focus\:bg-opacity-0:focus {
  --bg-opacity: 0 !important;
}

.focus\:bg-opacity-12:focus {
  --bg-opacity: 0.12 !important;
}

.focus\:bg-opacity-25:focus {
  --bg-opacity: 0.25 !important;
}

.focus\:bg-opacity-38:focus {
  --bg-opacity: 0.38 !important;
}

.focus\:bg-opacity-50:focus {
  --bg-opacity: 0.5 !important;
}

.focus\:bg-opacity-54:focus {
  --bg-opacity: 0.54 !important;
}

.focus\:bg-opacity-70:focus {
  --bg-opacity: 0.70 !important;
}

.focus\:bg-opacity-75:focus {
  --bg-opacity: 0.75 !important;
}

.focus\:bg-opacity-84:focus {
  --bg-opacity: 0.84 !important;
}

.focus\:bg-opacity-100:focus {
  --bg-opacity: 1 !important;
}

.bg-bottom {
  background-position: bottom !important;
}

.bg-center {
  background-position: center !important;
}

.bg-left {
  background-position: left !important;
}

.bg-left-bottom {
  background-position: left bottom !important;
}

.bg-left-top {
  background-position: left top !important;
}

.bg-right {
  background-position: right !important;
}

.bg-right-bottom {
  background-position: right bottom !important;
}

.bg-right-top {
  background-position: right top !important;
}

.bg-top {
  background-position: top !important;
}

.bg-repeat {
  background-repeat: repeat !important;
}

.bg-no-repeat {
  background-repeat: no-repeat !important;
}

.bg-repeat-x {
  background-repeat: repeat-x !important;
}

.bg-repeat-y {
  background-repeat: repeat-y !important;
}

.bg-repeat-round {
  background-repeat: round !important;
}

.bg-repeat-space {
  background-repeat: space !important;
}

.bg-auto {
  background-size: auto !important;
}

.bg-cover {
  background-size: cover !important;
}

.bg-contain {
  background-size: contain !important;
}

.border-collapse {
  border-collapse: collapse !important;
}

.border-separate {
  border-collapse: separate !important;
}

.border-current {
  border-color: currentColor !important;
}

.border-transparent {
  border-color: transparent !important;
}

.border-white {
  --border-opacity: 1 !important;
  border-color: #FFFFFF !important;
  border-color: rgba(255, 255, 255, var(--border-opacity)) !important;
}

.border-black {
  --border-opacity: 1 !important;
  border-color: #000000 !important;
  border-color: rgba(0, 0, 0, var(--border-opacity)) !important;
}

.border-gray-50 {
  --border-opacity: 1 !important;
  border-color: #F9FAFB !important;
  border-color: rgba(249, 250, 251, var(--border-opacity)) !important;
}

.border-gray-100 {
  --border-opacity: 1 !important;
  border-color: #F4F5F7 !important;
  border-color: rgba(244, 245, 247, var(--border-opacity)) !important;
}

.border-gray-200 {
  --border-opacity: 1 !important;
  border-color: #E5E7EB !important;
  border-color: rgba(229, 231, 235, var(--border-opacity)) !important;
}

.border-gray-300 {
  --border-opacity: 1 !important;
  border-color: #D2D6DC !important;
  border-color: rgba(210, 214, 220, var(--border-opacity)) !important;
}

.border-gray-400 {
  --border-opacity: 1 !important;
  border-color: #9FA6B2 !important;
  border-color: rgba(159, 166, 178, var(--border-opacity)) !important;
}

.border-gray-500 {
  --border-opacity: 1 !important;
  border-color: #6B7280 !important;
  border-color: rgba(107, 114, 128, var(--border-opacity)) !important;
}

.border-gray-600 {
  --border-opacity: 1 !important;
  border-color: #4B5563 !important;
  border-color: rgba(75, 85, 99, var(--border-opacity)) !important;
}

.border-gray-700 {
  --border-opacity: 1 !important;
  border-color: #374151 !important;
  border-color: rgba(55, 65, 81, var(--border-opacity)) !important;
}

.border-gray-800 {
  --border-opacity: 1 !important;
  border-color: #252F3F !important;
  border-color: rgba(37, 47, 63, var(--border-opacity)) !important;
}

.border-gray-900 {
  --border-opacity: 1 !important;
  border-color: #161E2E !important;
  border-color: rgba(22, 30, 46, var(--border-opacity)) !important;
}

.border-gray {
  --border-opacity: 1 !important;
  border-color: #6B7280 !important;
  border-color: rgba(107, 114, 128, var(--border-opacity)) !important;
}

.border-cool-gray-50 {
  --border-opacity: 1 !important;
  border-color: #FBFDFE !important;
  border-color: rgba(251, 253, 254, var(--border-opacity)) !important;
}

.border-cool-gray-100 {
  --border-opacity: 1 !important;
  border-color: #F1F5F9 !important;
  border-color: rgba(241, 245, 249, var(--border-opacity)) !important;
}

.border-cool-gray-200 {
  --border-opacity: 1 !important;
  border-color: #E2E8F0 !important;
  border-color: rgba(226, 232, 240, var(--border-opacity)) !important;
}

.border-cool-gray-300 {
  --border-opacity: 1 !important;
  border-color: #CFD8E3 !important;
  border-color: rgba(207, 216, 227, var(--border-opacity)) !important;
}

.border-cool-gray-400 {
  --border-opacity: 1 !important;
  border-color: #97A6BA !important;
  border-color: rgba(151, 166, 186, var(--border-opacity)) !important;
}

.border-cool-gray-500 {
  --border-opacity: 1 !important;
  border-color: #64748B !important;
  border-color: rgba(100, 116, 139, var(--border-opacity)) !important;
}

.border-cool-gray-600 {
  --border-opacity: 1 !important;
  border-color: #475569 !important;
  border-color: rgba(71, 85, 105, var(--border-opacity)) !important;
}

.border-cool-gray-700 {
  --border-opacity: 1 !important;
  border-color: #364152 !important;
  border-color: rgba(54, 65, 82, var(--border-opacity)) !important;
}

.border-cool-gray-800 {
  --border-opacity: 1 !important;
  border-color: #27303F !important;
  border-color: rgba(39, 48, 63, var(--border-opacity)) !important;
}

.border-cool-gray-900 {
  --border-opacity: 1 !important;
  border-color: #1A202E !important;
  border-color: rgba(26, 32, 46, var(--border-opacity)) !important;
}

.border-cool-gray {
  --border-opacity: 1 !important;
  border-color: #64748B !important;
  border-color: rgba(100, 116, 139, var(--border-opacity)) !important;
}

.border-red-50 {
  --border-opacity: 1 !important;
  border-color: #FDF2F2 !important;
  border-color: rgba(253, 242, 242, var(--border-opacity)) !important;
}

.border-red-100 {
  --border-opacity: 1 !important;
  border-color: #FDE8E8 !important;
  border-color: rgba(253, 232, 232, var(--border-opacity)) !important;
}

.border-red-200 {
  --border-opacity: 1 !important;
  border-color: #FBD5D5 !important;
  border-color: rgba(251, 213, 213, var(--border-opacity)) !important;
}

.border-red-300 {
  --border-opacity: 1 !important;
  border-color: #F8B4B4 !important;
  border-color: rgba(248, 180, 180, var(--border-opacity)) !important;
}

.border-red-400 {
  --border-opacity: 1 !important;
  border-color: #F98080 !important;
  border-color: rgba(249, 128, 128, var(--border-opacity)) !important;
}

.border-red-500 {
  --border-opacity: 1 !important;
  border-color: #F05252 !important;
  border-color: rgba(240, 82, 82, var(--border-opacity)) !important;
}

.border-red-600 {
  --border-opacity: 1 !important;
  border-color: #E02424 !important;
  border-color: rgba(224, 36, 36, var(--border-opacity)) !important;
}

.border-red-700 {
  --border-opacity: 1 !important;
  border-color: #C81E1E !important;
  border-color: rgba(200, 30, 30, var(--border-opacity)) !important;
}

.border-red-800 {
  --border-opacity: 1 !important;
  border-color: #9B1C1C !important;
  border-color: rgba(155, 28, 28, var(--border-opacity)) !important;
}

.border-red-900 {
  --border-opacity: 1 !important;
  border-color: #771D1D !important;
  border-color: rgba(119, 29, 29, var(--border-opacity)) !important;
}

.border-red {
  --border-opacity: 1 !important;
  border-color: #F05252 !important;
  border-color: rgba(240, 82, 82, var(--border-opacity)) !important;
}

.border-orange-50 {
  --border-opacity: 1 !important;
  border-color: #FFF8F1 !important;
  border-color: rgba(255, 248, 241, var(--border-opacity)) !important;
}

.border-orange-100 {
  --border-opacity: 1 !important;
  border-color: #FEECDC !important;
  border-color: rgba(254, 236, 220, var(--border-opacity)) !important;
}

.border-orange-200 {
  --border-opacity: 1 !important;
  border-color: #FCD9BD !important;
  border-color: rgba(252, 217, 189, var(--border-opacity)) !important;
}

.border-orange-300 {
  --border-opacity: 1 !important;
  border-color: #FDBA8C !important;
  border-color: rgba(253, 186, 140, var(--border-opacity)) !important;
}

.border-orange-400 {
  --border-opacity: 1 !important;
  border-color: #FF8A4C !important;
  border-color: rgba(255, 138, 76, var(--border-opacity)) !important;
}

.border-orange-500 {
  --border-opacity: 1 !important;
  border-color: #FF5A1F !important;
  border-color: rgba(255, 90, 31, var(--border-opacity)) !important;
}

.border-orange-600 {
  --border-opacity: 1 !important;
  border-color: #ff6700 !important;
  border-color: rgba(255, 103, 0, var(--border-opacity)) !important;
}

.border-orange-700 {
  --border-opacity: 1 !important;
  border-color: #B43403 !important;
  border-color: rgba(180, 52, 3, var(--border-opacity)) !important;
}

.border-orange-800 {
  --border-opacity: 1 !important;
  border-color: #8A2C0D !important;
  border-color: rgba(138, 44, 13, var(--border-opacity)) !important;
}

.border-orange-900 {
  --border-opacity: 1 !important;
  border-color: #771D1D !important;
  border-color: rgba(119, 29, 29, var(--border-opacity)) !important;
}

.border-orange {
  --border-opacity: 1 !important;
  border-color: #FF5A1F !important;
  border-color: rgba(255, 90, 31, var(--border-opacity)) !important;
}

.border-yellow-50 {
  --border-opacity: 1 !important;
  border-color: #FDFDEA !important;
  border-color: rgba(253, 253, 234, var(--border-opacity)) !important;
}

.border-yellow-100 {
  --border-opacity: 1 !important;
  border-color: #FDF6B2 !important;
  border-color: rgba(253, 246, 178, var(--border-opacity)) !important;
}

.border-yellow-200 {
  --border-opacity: 1 !important;
  border-color: #FCE96A !important;
  border-color: rgba(252, 233, 106, var(--border-opacity)) !important;
}

.border-yellow-300 {
  --border-opacity: 1 !important;
  border-color: #FACA15 !important;
  border-color: rgba(250, 202, 21, var(--border-opacity)) !important;
}

.border-yellow-400 {
  --border-opacity: 1 !important;
  border-color: #E3A008 !important;
  border-color: rgba(227, 160, 8, var(--border-opacity)) !important;
}

.border-yellow-500 {
  --border-opacity: 1 !important;
  border-color: #C27803 !important;
  border-color: rgba(194, 120, 3, var(--border-opacity)) !important;
}

.border-yellow-600 {
  --border-opacity: 1 !important;
  border-color: #9F580A !important;
  border-color: rgba(159, 88, 10, var(--border-opacity)) !important;
}

.border-yellow-700 {
  --border-opacity: 1 !important;
  border-color: #8E4B10 !important;
  border-color: rgba(142, 75, 16, var(--border-opacity)) !important;
}

.border-yellow-800 {
  --border-opacity: 1 !important;
  border-color: #723B13 !important;
  border-color: rgba(114, 59, 19, var(--border-opacity)) !important;
}

.border-yellow-900 {
  --border-opacity: 1 !important;
  border-color: #633112 !important;
  border-color: rgba(99, 49, 18, var(--border-opacity)) !important;
}

.border-yellow {
  --border-opacity: 1 !important;
  border-color: #C27803 !important;
  border-color: rgba(194, 120, 3, var(--border-opacity)) !important;
}

.border-green-50 {
  --border-opacity: 1 !important;
  border-color: #F3FAF7 !important;
  border-color: rgba(243, 250, 247, var(--border-opacity)) !important;
}

.border-green-100 {
  --border-opacity: 1 !important;
  border-color: #DEF7EC !important;
  border-color: rgba(222, 247, 236, var(--border-opacity)) !important;
}

.border-green-200 {
  --border-opacity: 1 !important;
  border-color: #BCF0DA !important;
  border-color: rgba(188, 240, 218, var(--border-opacity)) !important;
}

.border-green-300 {
  --border-opacity: 1 !important;
  border-color: #84E1BC !important;
  border-color: rgba(132, 225, 188, var(--border-opacity)) !important;
}

.border-green-400 {
  --border-opacity: 1 !important;
  border-color: #31C48D !important;
  border-color: rgba(49, 196, 141, var(--border-opacity)) !important;
}

.border-green-500 {
  --border-opacity: 1 !important;
  border-color: #0E9F6E !important;
  border-color: rgba(14, 159, 110, var(--border-opacity)) !important;
}

.border-green-600 {
  --border-opacity: 1 !important;
  border-color: #057A55 !important;
  border-color: rgba(5, 122, 85, var(--border-opacity)) !important;
}

.border-green-700 {
  --border-opacity: 1 !important;
  border-color: #046C4E !important;
  border-color: rgba(4, 108, 78, var(--border-opacity)) !important;
}

.border-green-800 {
  --border-opacity: 1 !important;
  border-color: #03543F !important;
  border-color: rgba(3, 84, 63, var(--border-opacity)) !important;
}

.border-green-900 {
  --border-opacity: 1 !important;
  border-color: #014737 !important;
  border-color: rgba(1, 71, 55, var(--border-opacity)) !important;
}

.border-green {
  --border-opacity: 1 !important;
  border-color: #0E9F6E !important;
  border-color: rgba(14, 159, 110, var(--border-opacity)) !important;
}

.border-teal-50 {
  --border-opacity: 1 !important;
  border-color: #EDFAFA !important;
  border-color: rgba(237, 250, 250, var(--border-opacity)) !important;
}

.border-teal-100 {
  --border-opacity: 1 !important;
  border-color: #D5F5F6 !important;
  border-color: rgba(213, 245, 246, var(--border-opacity)) !important;
}

.border-teal-200 {
  --border-opacity: 1 !important;
  border-color: #AFECEF !important;
  border-color: rgba(175, 236, 239, var(--border-opacity)) !important;
}

.border-teal-300 {
  --border-opacity: 1 !important;
  border-color: #7EDCE2 !important;
  border-color: rgba(126, 220, 226, var(--border-opacity)) !important;
}

.border-teal-400 {
  --border-opacity: 1 !important;
  border-color: #16BDCA !important;
  border-color: rgba(22, 189, 202, var(--border-opacity)) !important;
}

.border-teal-500 {
  --border-opacity: 1 !important;
  border-color: #0694A2 !important;
  border-color: rgba(6, 148, 162, var(--border-opacity)) !important;
}

.border-teal-600 {
  --border-opacity: 1 !important;
  border-color: #047481 !important;
  border-color: rgba(4, 116, 129, var(--border-opacity)) !important;
}

.border-teal-700 {
  --border-opacity: 1 !important;
  border-color: #036672 !important;
  border-color: rgba(3, 102, 114, var(--border-opacity)) !important;
}

.border-teal-800 {
  --border-opacity: 1 !important;
  border-color: #05505C !important;
  border-color: rgba(5, 80, 92, var(--border-opacity)) !important;
}

.border-teal-900 {
  --border-opacity: 1 !important;
  border-color: #014451 !important;
  border-color: rgba(1, 68, 81, var(--border-opacity)) !important;
}

.border-teal {
  --border-opacity: 1 !important;
  border-color: #0694A2 !important;
  border-color: rgba(6, 148, 162, var(--border-opacity)) !important;
}

.border-blue-50 {
  --border-opacity: 1 !important;
  border-color: #EBF5FF !important;
  border-color: rgba(235, 245, 255, var(--border-opacity)) !important;
}

.border-blue-100 {
  --border-opacity: 1 !important;
  border-color: #E1EFFE !important;
  border-color: rgba(225, 239, 254, var(--border-opacity)) !important;
}

.border-blue-200 {
  --border-opacity: 1 !important;
  border-color: #C3DDFD !important;
  border-color: rgba(195, 221, 253, var(--border-opacity)) !important;
}

.border-blue-300 {
  --border-opacity: 1 !important;
  border-color: #A4CAFE !important;
  border-color: rgba(164, 202, 254, var(--border-opacity)) !important;
}

.border-blue-400 {
  --border-opacity: 1 !important;
  border-color: #76A9FA !important;
  border-color: rgba(118, 169, 250, var(--border-opacity)) !important;
}

.border-blue-500 {
  --border-opacity: 1 !important;
  border-color: #3F83F8 !important;
  border-color: rgba(63, 131, 248, var(--border-opacity)) !important;
}

.border-blue-600 {
  --border-opacity: 1 !important;
  border-color: #1C64F2 !important;
  border-color: rgba(28, 100, 242, var(--border-opacity)) !important;
}

.border-blue-700 {
  --border-opacity: 1 !important;
  border-color: #1A56DB !important;
  border-color: rgba(26, 86, 219, var(--border-opacity)) !important;
}

.border-blue-800 {
  --border-opacity: 1 !important;
  border-color: #1E429F !important;
  border-color: rgba(30, 66, 159, var(--border-opacity)) !important;
}

.border-blue-900 {
  --border-opacity: 1 !important;
  border-color: #233876 !important;
  border-color: rgba(35, 56, 118, var(--border-opacity)) !important;
}

.border-blue {
  --border-opacity: 1 !important;
  border-color: #3F83F8 !important;
  border-color: rgba(63, 131, 248, var(--border-opacity)) !important;
}

.border-indigo-50 {
  --border-opacity: 1 !important;
  border-color: #F0F5FF !important;
  border-color: rgba(240, 245, 255, var(--border-opacity)) !important;
}

.border-indigo-100 {
  --border-opacity: 1 !important;
  border-color: #E5EDFF !important;
  border-color: rgba(229, 237, 255, var(--border-opacity)) !important;
}

.border-indigo-200 {
  --border-opacity: 1 !important;
  border-color: #CDDBFE !important;
  border-color: rgba(205, 219, 254, var(--border-opacity)) !important;
}

.border-indigo-300 {
  --border-opacity: 1 !important;
  border-color: #B4C6FC !important;
  border-color: rgba(180, 198, 252, var(--border-opacity)) !important;
}

.border-indigo-400 {
  --border-opacity: 1 !important;
  border-color: #8DA2FB !important;
  border-color: rgba(141, 162, 251, var(--border-opacity)) !important;
}

.border-indigo-500 {
  --border-opacity: 1 !important;
  border-color: #6875F5 !important;
  border-color: rgba(104, 117, 245, var(--border-opacity)) !important;
}

.border-indigo-600 {
  --border-opacity: 1 !important;
  border-color: #5850EC !important;
  border-color: rgba(88, 80, 236, var(--border-opacity)) !important;
}

.border-indigo-700 {
  --border-opacity: 1 !important;
  border-color: #5145CD !important;
  border-color: rgba(81, 69, 205, var(--border-opacity)) !important;
}

.border-indigo-800 {
  --border-opacity: 1 !important;
  border-color: #42389D !important;
  border-color: rgba(66, 56, 157, var(--border-opacity)) !important;
}

.border-indigo-900 {
  --border-opacity: 1 !important;
  border-color: #362F78 !important;
  border-color: rgba(54, 47, 120, var(--border-opacity)) !important;
}

.border-indigo {
  --border-opacity: 1 !important;
  border-color: #6875F5 !important;
  border-color: rgba(104, 117, 245, var(--border-opacity)) !important;
}

.border-purple-50 {
  --border-opacity: 1 !important;
  border-color: #F6F5FF !important;
  border-color: rgba(246, 245, 255, var(--border-opacity)) !important;
}

.border-purple-100 {
  --border-opacity: 1 !important;
  border-color: #EDEBFE !important;
  border-color: rgba(237, 235, 254, var(--border-opacity)) !important;
}

.border-purple-200 {
  --border-opacity: 1 !important;
  border-color: #DCD7FE !important;
  border-color: rgba(220, 215, 254, var(--border-opacity)) !important;
}

.border-purple-300 {
  --border-opacity: 1 !important;
  border-color: #CABFFD !important;
  border-color: rgba(202, 191, 253, var(--border-opacity)) !important;
}

.border-purple-400 {
  --border-opacity: 1 !important;
  border-color: #AC94FA !important;
  border-color: rgba(172, 148, 250, var(--border-opacity)) !important;
}

.border-purple-500 {
  --border-opacity: 1 !important;
  border-color: #9061F9 !important;
  border-color: rgba(144, 97, 249, var(--border-opacity)) !important;
}

.border-purple-600 {
  --border-opacity: 1 !important;
  border-color: #7E3AF2 !important;
  border-color: rgba(126, 58, 242, var(--border-opacity)) !important;
}

.border-purple-700 {
  --border-opacity: 1 !important;
  border-color: #6C2BD9 !important;
  border-color: rgba(108, 43, 217, var(--border-opacity)) !important;
}

.border-purple-800 {
  --border-opacity: 1 !important;
  border-color: #5521B5 !important;
  border-color: rgba(85, 33, 181, var(--border-opacity)) !important;
}

.border-purple-900 {
  --border-opacity: 1 !important;
  border-color: #4A1D96 !important;
  border-color: rgba(74, 29, 150, var(--border-opacity)) !important;
}

.border-purple {
  --border-opacity: 1 !important;
  border-color: #9061F9 !important;
  border-color: rgba(144, 97, 249, var(--border-opacity)) !important;
}

.border-pink-50 {
  --border-opacity: 1 !important;
  border-color: #FDF2F8 !important;
  border-color: rgba(253, 242, 248, var(--border-opacity)) !important;
}

.border-pink-100 {
  --border-opacity: 1 !important;
  border-color: #FCE8F3 !important;
  border-color: rgba(252, 232, 243, var(--border-opacity)) !important;
}

.border-pink-200 {
  --border-opacity: 1 !important;
  border-color: #FAD1E8 !important;
  border-color: rgba(250, 209, 232, var(--border-opacity)) !important;
}

.border-pink-300 {
  --border-opacity: 1 !important;
  border-color: #F8B4D9 !important;
  border-color: rgba(248, 180, 217, var(--border-opacity)) !important;
}

.border-pink-400 {
  --border-opacity: 1 !important;
  border-color: #F17EB8 !important;
  border-color: rgba(241, 126, 184, var(--border-opacity)) !important;
}

.border-pink-500 {
  --border-opacity: 1 !important;
  border-color: #E74694 !important;
  border-color: rgba(231, 70, 148, var(--border-opacity)) !important;
}

.border-pink-600 {
  --border-opacity: 1 !important;
  border-color: #D61F69 !important;
  border-color: rgba(214, 31, 105, var(--border-opacity)) !important;
}

.border-pink-700 {
  --border-opacity: 1 !important;
  border-color: #BF125D !important;
  border-color: rgba(191, 18, 93, var(--border-opacity)) !important;
}

.border-pink-800 {
  --border-opacity: 1 !important;
  border-color: #99154B !important;
  border-color: rgba(153, 21, 75, var(--border-opacity)) !important;
}

.border-pink-900 {
  --border-opacity: 1 !important;
  border-color: #751A3D !important;
  border-color: rgba(117, 26, 61, var(--border-opacity)) !important;
}

.border-pink {
  --border-opacity: 1 !important;
  border-color: #E74694 !important;
  border-color: rgba(231, 70, 148, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-current, [class*="theme-dark"] .dark\:border-current, [class*="theme-light"].light\:border-current, [class*="theme-light"] .light\:border-current {
  border-color: currentColor !important;
}

[class*="theme-dark"].dark\:border-transparent, [class*="theme-dark"] .dark\:border-transparent, [class*="theme-light"].light\:border-transparent, [class*="theme-light"] .light\:border-transparent {
  border-color: transparent !important;
}

[class*="theme-dark"].dark\:border-white, [class*="theme-dark"] .dark\:border-white, [class*="theme-light"].light\:border-white, [class*="theme-light"] .light\:border-white {
  --border-opacity: 1 !important;
  border-color: #FFFFFF !important;
  border-color: rgba(255, 255, 255, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-black, [class*="theme-dark"] .dark\:border-black, [class*="theme-light"].light\:border-black, [class*="theme-light"] .light\:border-black {
  --border-opacity: 1 !important;
  border-color: #000000 !important;
  border-color: rgba(0, 0, 0, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-gray-50, [class*="theme-dark"] .dark\:border-gray-50, [class*="theme-light"].light\:border-gray-50, [class*="theme-light"] .light\:border-gray-50 {
  --border-opacity: 1 !important;
  border-color: #F9FAFB !important;
  border-color: rgba(249, 250, 251, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-gray-100, [class*="theme-dark"] .dark\:border-gray-100, [class*="theme-light"].light\:border-gray-100, [class*="theme-light"] .light\:border-gray-100 {
  --border-opacity: 1 !important;
  border-color: #F4F5F7 !important;
  border-color: rgba(244, 245, 247, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-gray-200, [class*="theme-dark"] .dark\:border-gray-200, [class*="theme-light"].light\:border-gray-200, [class*="theme-light"] .light\:border-gray-200 {
  --border-opacity: 1 !important;
  border-color: #E5E7EB !important;
  border-color: rgba(229, 231, 235, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-gray-300, [class*="theme-dark"] .dark\:border-gray-300, [class*="theme-light"].light\:border-gray-300, [class*="theme-light"] .light\:border-gray-300 {
  --border-opacity: 1 !important;
  border-color: #D2D6DC !important;
  border-color: rgba(210, 214, 220, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-gray-400, [class*="theme-dark"] .dark\:border-gray-400, [class*="theme-light"].light\:border-gray-400, [class*="theme-light"] .light\:border-gray-400 {
  --border-opacity: 1 !important;
  border-color: #9FA6B2 !important;
  border-color: rgba(159, 166, 178, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-gray-500, [class*="theme-dark"] .dark\:border-gray-500, [class*="theme-light"].light\:border-gray-500, [class*="theme-light"] .light\:border-gray-500 {
  --border-opacity: 1 !important;
  border-color: #6B7280 !important;
  border-color: rgba(107, 114, 128, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-gray-600, [class*="theme-dark"] .dark\:border-gray-600, [class*="theme-light"].light\:border-gray-600, [class*="theme-light"] .light\:border-gray-600 {
  --border-opacity: 1 !important;
  border-color: #4B5563 !important;
  border-color: rgba(75, 85, 99, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-gray-700, [class*="theme-dark"] .dark\:border-gray-700, [class*="theme-light"].light\:border-gray-700, [class*="theme-light"] .light\:border-gray-700 {
  --border-opacity: 1 !important;
  border-color: #374151 !important;
  border-color: rgba(55, 65, 81, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-gray-800, [class*="theme-dark"] .dark\:border-gray-800, [class*="theme-light"].light\:border-gray-800, [class*="theme-light"] .light\:border-gray-800 {
  --border-opacity: 1 !important;
  border-color: #252F3F !important;
  border-color: rgba(37, 47, 63, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-gray-900, [class*="theme-dark"] .dark\:border-gray-900, [class*="theme-light"].light\:border-gray-900, [class*="theme-light"] .light\:border-gray-900 {
  --border-opacity: 1 !important;
  border-color: #161E2E !important;
  border-color: rgba(22, 30, 46, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-gray, [class*="theme-dark"] .dark\:border-gray, [class*="theme-light"].light\:border-gray, [class*="theme-light"] .light\:border-gray {
  --border-opacity: 1 !important;
  border-color: #6B7280 !important;
  border-color: rgba(107, 114, 128, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-cool-gray-50, [class*="theme-dark"] .dark\:border-cool-gray-50, [class*="theme-light"].light\:border-cool-gray-50, [class*="theme-light"] .light\:border-cool-gray-50 {
  --border-opacity: 1 !important;
  border-color: #FBFDFE !important;
  border-color: rgba(251, 253, 254, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-cool-gray-100, [class*="theme-dark"] .dark\:border-cool-gray-100, [class*="theme-light"].light\:border-cool-gray-100, [class*="theme-light"] .light\:border-cool-gray-100 {
  --border-opacity: 1 !important;
  border-color: #F1F5F9 !important;
  border-color: rgba(241, 245, 249, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-cool-gray-200, [class*="theme-dark"] .dark\:border-cool-gray-200, [class*="theme-light"].light\:border-cool-gray-200, [class*="theme-light"] .light\:border-cool-gray-200 {
  --border-opacity: 1 !important;
  border-color: #E2E8F0 !important;
  border-color: rgba(226, 232, 240, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-cool-gray-300, [class*="theme-dark"] .dark\:border-cool-gray-300, [class*="theme-light"].light\:border-cool-gray-300, [class*="theme-light"] .light\:border-cool-gray-300 {
  --border-opacity: 1 !important;
  border-color: #CFD8E3 !important;
  border-color: rgba(207, 216, 227, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-cool-gray-400, [class*="theme-dark"] .dark\:border-cool-gray-400, [class*="theme-light"].light\:border-cool-gray-400, [class*="theme-light"] .light\:border-cool-gray-400 {
  --border-opacity: 1 !important;
  border-color: #97A6BA !important;
  border-color: rgba(151, 166, 186, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-cool-gray-500, [class*="theme-dark"] .dark\:border-cool-gray-500, [class*="theme-light"].light\:border-cool-gray-500, [class*="theme-light"] .light\:border-cool-gray-500 {
  --border-opacity: 1 !important;
  border-color: #64748B !important;
  border-color: rgba(100, 116, 139, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-cool-gray-600, [class*="theme-dark"] .dark\:border-cool-gray-600, [class*="theme-light"].light\:border-cool-gray-600, [class*="theme-light"] .light\:border-cool-gray-600 {
  --border-opacity: 1 !important;
  border-color: #475569 !important;
  border-color: rgba(71, 85, 105, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-cool-gray-700, [class*="theme-dark"] .dark\:border-cool-gray-700, [class*="theme-light"].light\:border-cool-gray-700, [class*="theme-light"] .light\:border-cool-gray-700 {
  --border-opacity: 1 !important;
  border-color: #364152 !important;
  border-color: rgba(54, 65, 82, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-cool-gray-800, [class*="theme-dark"] .dark\:border-cool-gray-800, [class*="theme-light"].light\:border-cool-gray-800, [class*="theme-light"] .light\:border-cool-gray-800 {
  --border-opacity: 1 !important;
  border-color: #27303F !important;
  border-color: rgba(39, 48, 63, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-cool-gray-900, [class*="theme-dark"] .dark\:border-cool-gray-900, [class*="theme-light"].light\:border-cool-gray-900, [class*="theme-light"] .light\:border-cool-gray-900 {
  --border-opacity: 1 !important;
  border-color: #1A202E !important;
  border-color: rgba(26, 32, 46, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-cool-gray, [class*="theme-dark"] .dark\:border-cool-gray, [class*="theme-light"].light\:border-cool-gray, [class*="theme-light"] .light\:border-cool-gray {
  --border-opacity: 1 !important;
  border-color: #64748B !important;
  border-color: rgba(100, 116, 139, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-red-50, [class*="theme-dark"] .dark\:border-red-50, [class*="theme-light"].light\:border-red-50, [class*="theme-light"] .light\:border-red-50 {
  --border-opacity: 1 !important;
  border-color: #FDF2F2 !important;
  border-color: rgba(253, 242, 242, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-red-100, [class*="theme-dark"] .dark\:border-red-100, [class*="theme-light"].light\:border-red-100, [class*="theme-light"] .light\:border-red-100 {
  --border-opacity: 1 !important;
  border-color: #FDE8E8 !important;
  border-color: rgba(253, 232, 232, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-red-200, [class*="theme-dark"] .dark\:border-red-200, [class*="theme-light"].light\:border-red-200, [class*="theme-light"] .light\:border-red-200 {
  --border-opacity: 1 !important;
  border-color: #FBD5D5 !important;
  border-color: rgba(251, 213, 213, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-red-300, [class*="theme-dark"] .dark\:border-red-300, [class*="theme-light"].light\:border-red-300, [class*="theme-light"] .light\:border-red-300 {
  --border-opacity: 1 !important;
  border-color: #F8B4B4 !important;
  border-color: rgba(248, 180, 180, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-red-400, [class*="theme-dark"] .dark\:border-red-400, [class*="theme-light"].light\:border-red-400, [class*="theme-light"] .light\:border-red-400 {
  --border-opacity: 1 !important;
  border-color: #F98080 !important;
  border-color: rgba(249, 128, 128, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-red-500, [class*="theme-dark"] .dark\:border-red-500, [class*="theme-light"].light\:border-red-500, [class*="theme-light"] .light\:border-red-500 {
  --border-opacity: 1 !important;
  border-color: #F05252 !important;
  border-color: rgba(240, 82, 82, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-red-600, [class*="theme-dark"] .dark\:border-red-600, [class*="theme-light"].light\:border-red-600, [class*="theme-light"] .light\:border-red-600 {
  --border-opacity: 1 !important;
  border-color: #E02424 !important;
  border-color: rgba(224, 36, 36, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-red-700, [class*="theme-dark"] .dark\:border-red-700, [class*="theme-light"].light\:border-red-700, [class*="theme-light"] .light\:border-red-700 {
  --border-opacity: 1 !important;
  border-color: #C81E1E !important;
  border-color: rgba(200, 30, 30, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-red-800, [class*="theme-dark"] .dark\:border-red-800, [class*="theme-light"].light\:border-red-800, [class*="theme-light"] .light\:border-red-800 {
  --border-opacity: 1 !important;
  border-color: #9B1C1C !important;
  border-color: rgba(155, 28, 28, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-red-900, [class*="theme-dark"] .dark\:border-red-900, [class*="theme-light"].light\:border-red-900, [class*="theme-light"] .light\:border-red-900 {
  --border-opacity: 1 !important;
  border-color: #771D1D !important;
  border-color: rgba(119, 29, 29, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-red, [class*="theme-dark"] .dark\:border-red, [class*="theme-light"].light\:border-red, [class*="theme-light"] .light\:border-red {
  --border-opacity: 1 !important;
  border-color: #F05252 !important;
  border-color: rgba(240, 82, 82, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-orange-50, [class*="theme-dark"] .dark\:border-orange-50, [class*="theme-light"].light\:border-orange-50, [class*="theme-light"] .light\:border-orange-50 {
  --border-opacity: 1 !important;
  border-color: #FFF8F1 !important;
  border-color: rgba(255, 248, 241, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-orange-100, [class*="theme-dark"] .dark\:border-orange-100, [class*="theme-light"].light\:border-orange-100, [class*="theme-light"] .light\:border-orange-100 {
  --border-opacity: 1 !important;
  border-color: #FEECDC !important;
  border-color: rgba(254, 236, 220, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-orange-200, [class*="theme-dark"] .dark\:border-orange-200, [class*="theme-light"].light\:border-orange-200, [class*="theme-light"] .light\:border-orange-200 {
  --border-opacity: 1 !important;
  border-color: #FCD9BD !important;
  border-color: rgba(252, 217, 189, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-orange-300, [class*="theme-dark"] .dark\:border-orange-300, [class*="theme-light"].light\:border-orange-300, [class*="theme-light"] .light\:border-orange-300 {
  --border-opacity: 1 !important;
  border-color: #FDBA8C !important;
  border-color: rgba(253, 186, 140, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-orange-400, [class*="theme-dark"] .dark\:border-orange-400, [class*="theme-light"].light\:border-orange-400, [class*="theme-light"] .light\:border-orange-400 {
  --border-opacity: 1 !important;
  border-color: #FF8A4C !important;
  border-color: rgba(255, 138, 76, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-orange-500, [class*="theme-dark"] .dark\:border-orange-500, [class*="theme-light"].light\:border-orange-500, [class*="theme-light"] .light\:border-orange-500 {
  --border-opacity: 1 !important;
  border-color: #FF5A1F !important;
  border-color: rgba(255, 90, 31, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-orange-600, [class*="theme-dark"] .dark\:border-orange-600, [class*="theme-light"].light\:border-orange-600, [class*="theme-light"] .light\:border-orange-600 {
  --border-opacity: 1 !important;
  border-color: #ff6700 !important;
  border-color: rgba(255, 103, 0, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-orange-700, [class*="theme-dark"] .dark\:border-orange-700, [class*="theme-light"].light\:border-orange-700, [class*="theme-light"] .light\:border-orange-700 {
  --border-opacity: 1 !important;
  border-color: #B43403 !important;
  border-color: rgba(180, 52, 3, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-orange-800, [class*="theme-dark"] .dark\:border-orange-800, [class*="theme-light"].light\:border-orange-800, [class*="theme-light"] .light\:border-orange-800 {
  --border-opacity: 1 !important;
  border-color: #8A2C0D !important;
  border-color: rgba(138, 44, 13, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-orange-900, [class*="theme-dark"] .dark\:border-orange-900, [class*="theme-light"].light\:border-orange-900, [class*="theme-light"] .light\:border-orange-900 {
  --border-opacity: 1 !important;
  border-color: #771D1D !important;
  border-color: rgba(119, 29, 29, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-orange, [class*="theme-dark"] .dark\:border-orange, [class*="theme-light"].light\:border-orange, [class*="theme-light"] .light\:border-orange {
  --border-opacity: 1 !important;
  border-color: #FF5A1F !important;
  border-color: rgba(255, 90, 31, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-yellow-50, [class*="theme-dark"] .dark\:border-yellow-50, [class*="theme-light"].light\:border-yellow-50, [class*="theme-light"] .light\:border-yellow-50 {
  --border-opacity: 1 !important;
  border-color: #FDFDEA !important;
  border-color: rgba(253, 253, 234, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-yellow-100, [class*="theme-dark"] .dark\:border-yellow-100, [class*="theme-light"].light\:border-yellow-100, [class*="theme-light"] .light\:border-yellow-100 {
  --border-opacity: 1 !important;
  border-color: #FDF6B2 !important;
  border-color: rgba(253, 246, 178, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-yellow-200, [class*="theme-dark"] .dark\:border-yellow-200, [class*="theme-light"].light\:border-yellow-200, [class*="theme-light"] .light\:border-yellow-200 {
  --border-opacity: 1 !important;
  border-color: #FCE96A !important;
  border-color: rgba(252, 233, 106, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-yellow-300, [class*="theme-dark"] .dark\:border-yellow-300, [class*="theme-light"].light\:border-yellow-300, [class*="theme-light"] .light\:border-yellow-300 {
  --border-opacity: 1 !important;
  border-color: #FACA15 !important;
  border-color: rgba(250, 202, 21, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-yellow-400, [class*="theme-dark"] .dark\:border-yellow-400, [class*="theme-light"].light\:border-yellow-400, [class*="theme-light"] .light\:border-yellow-400 {
  --border-opacity: 1 !important;
  border-color: #E3A008 !important;
  border-color: rgba(227, 160, 8, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-yellow-500, [class*="theme-dark"] .dark\:border-yellow-500, [class*="theme-light"].light\:border-yellow-500, [class*="theme-light"] .light\:border-yellow-500 {
  --border-opacity: 1 !important;
  border-color: #C27803 !important;
  border-color: rgba(194, 120, 3, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-yellow-600, [class*="theme-dark"] .dark\:border-yellow-600, [class*="theme-light"].light\:border-yellow-600, [class*="theme-light"] .light\:border-yellow-600 {
  --border-opacity: 1 !important;
  border-color: #9F580A !important;
  border-color: rgba(159, 88, 10, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-yellow-700, [class*="theme-dark"] .dark\:border-yellow-700, [class*="theme-light"].light\:border-yellow-700, [class*="theme-light"] .light\:border-yellow-700 {
  --border-opacity: 1 !important;
  border-color: #8E4B10 !important;
  border-color: rgba(142, 75, 16, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-yellow-800, [class*="theme-dark"] .dark\:border-yellow-800, [class*="theme-light"].light\:border-yellow-800, [class*="theme-light"] .light\:border-yellow-800 {
  --border-opacity: 1 !important;
  border-color: #723B13 !important;
  border-color: rgba(114, 59, 19, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-yellow-900, [class*="theme-dark"] .dark\:border-yellow-900, [class*="theme-light"].light\:border-yellow-900, [class*="theme-light"] .light\:border-yellow-900 {
  --border-opacity: 1 !important;
  border-color: #633112 !important;
  border-color: rgba(99, 49, 18, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-yellow, [class*="theme-dark"] .dark\:border-yellow, [class*="theme-light"].light\:border-yellow, [class*="theme-light"] .light\:border-yellow {
  --border-opacity: 1 !important;
  border-color: #C27803 !important;
  border-color: rgba(194, 120, 3, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-green-50, [class*="theme-dark"] .dark\:border-green-50, [class*="theme-light"].light\:border-green-50, [class*="theme-light"] .light\:border-green-50 {
  --border-opacity: 1 !important;
  border-color: #F3FAF7 !important;
  border-color: rgba(243, 250, 247, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-green-100, [class*="theme-dark"] .dark\:border-green-100, [class*="theme-light"].light\:border-green-100, [class*="theme-light"] .light\:border-green-100 {
  --border-opacity: 1 !important;
  border-color: #DEF7EC !important;
  border-color: rgba(222, 247, 236, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-green-200, [class*="theme-dark"] .dark\:border-green-200, [class*="theme-light"].light\:border-green-200, [class*="theme-light"] .light\:border-green-200 {
  --border-opacity: 1 !important;
  border-color: #BCF0DA !important;
  border-color: rgba(188, 240, 218, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-green-300, [class*="theme-dark"] .dark\:border-green-300, [class*="theme-light"].light\:border-green-300, [class*="theme-light"] .light\:border-green-300 {
  --border-opacity: 1 !important;
  border-color: #84E1BC !important;
  border-color: rgba(132, 225, 188, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-green-400, [class*="theme-dark"] .dark\:border-green-400, [class*="theme-light"].light\:border-green-400, [class*="theme-light"] .light\:border-green-400 {
  --border-opacity: 1 !important;
  border-color: #31C48D !important;
  border-color: rgba(49, 196, 141, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-green-500, [class*="theme-dark"] .dark\:border-green-500, [class*="theme-light"].light\:border-green-500, [class*="theme-light"] .light\:border-green-500 {
  --border-opacity: 1 !important;
  border-color: #0E9F6E !important;
  border-color: rgba(14, 159, 110, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-green-600, [class*="theme-dark"] .dark\:border-green-600, [class*="theme-light"].light\:border-green-600, [class*="theme-light"] .light\:border-green-600 {
  --border-opacity: 1 !important;
  border-color: #057A55 !important;
  border-color: rgba(5, 122, 85, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-green-700, [class*="theme-dark"] .dark\:border-green-700, [class*="theme-light"].light\:border-green-700, [class*="theme-light"] .light\:border-green-700 {
  --border-opacity: 1 !important;
  border-color: #046C4E !important;
  border-color: rgba(4, 108, 78, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-green-800, [class*="theme-dark"] .dark\:border-green-800, [class*="theme-light"].light\:border-green-800, [class*="theme-light"] .light\:border-green-800 {
  --border-opacity: 1 !important;
  border-color: #03543F !important;
  border-color: rgba(3, 84, 63, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-green-900, [class*="theme-dark"] .dark\:border-green-900, [class*="theme-light"].light\:border-green-900, [class*="theme-light"] .light\:border-green-900 {
  --border-opacity: 1 !important;
  border-color: #014737 !important;
  border-color: rgba(1, 71, 55, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-green, [class*="theme-dark"] .dark\:border-green, [class*="theme-light"].light\:border-green, [class*="theme-light"] .light\:border-green {
  --border-opacity: 1 !important;
  border-color: #0E9F6E !important;
  border-color: rgba(14, 159, 110, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-teal-50, [class*="theme-dark"] .dark\:border-teal-50, [class*="theme-light"].light\:border-teal-50, [class*="theme-light"] .light\:border-teal-50 {
  --border-opacity: 1 !important;
  border-color: #EDFAFA !important;
  border-color: rgba(237, 250, 250, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-teal-100, [class*="theme-dark"] .dark\:border-teal-100, [class*="theme-light"].light\:border-teal-100, [class*="theme-light"] .light\:border-teal-100 {
  --border-opacity: 1 !important;
  border-color: #D5F5F6 !important;
  border-color: rgba(213, 245, 246, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-teal-200, [class*="theme-dark"] .dark\:border-teal-200, [class*="theme-light"].light\:border-teal-200, [class*="theme-light"] .light\:border-teal-200 {
  --border-opacity: 1 !important;
  border-color: #AFECEF !important;
  border-color: rgba(175, 236, 239, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-teal-300, [class*="theme-dark"] .dark\:border-teal-300, [class*="theme-light"].light\:border-teal-300, [class*="theme-light"] .light\:border-teal-300 {
  --border-opacity: 1 !important;
  border-color: #7EDCE2 !important;
  border-color: rgba(126, 220, 226, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-teal-400, [class*="theme-dark"] .dark\:border-teal-400, [class*="theme-light"].light\:border-teal-400, [class*="theme-light"] .light\:border-teal-400 {
  --border-opacity: 1 !important;
  border-color: #16BDCA !important;
  border-color: rgba(22, 189, 202, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-teal-500, [class*="theme-dark"] .dark\:border-teal-500, [class*="theme-light"].light\:border-teal-500, [class*="theme-light"] .light\:border-teal-500 {
  --border-opacity: 1 !important;
  border-color: #0694A2 !important;
  border-color: rgba(6, 148, 162, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-teal-600, [class*="theme-dark"] .dark\:border-teal-600, [class*="theme-light"].light\:border-teal-600, [class*="theme-light"] .light\:border-teal-600 {
  --border-opacity: 1 !important;
  border-color: #047481 !important;
  border-color: rgba(4, 116, 129, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-teal-700, [class*="theme-dark"] .dark\:border-teal-700, [class*="theme-light"].light\:border-teal-700, [class*="theme-light"] .light\:border-teal-700 {
  --border-opacity: 1 !important;
  border-color: #036672 !important;
  border-color: rgba(3, 102, 114, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-teal-800, [class*="theme-dark"] .dark\:border-teal-800, [class*="theme-light"].light\:border-teal-800, [class*="theme-light"] .light\:border-teal-800 {
  --border-opacity: 1 !important;
  border-color: #05505C !important;
  border-color: rgba(5, 80, 92, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-teal-900, [class*="theme-dark"] .dark\:border-teal-900, [class*="theme-light"].light\:border-teal-900, [class*="theme-light"] .light\:border-teal-900 {
  --border-opacity: 1 !important;
  border-color: #014451 !important;
  border-color: rgba(1, 68, 81, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-teal, [class*="theme-dark"] .dark\:border-teal, [class*="theme-light"].light\:border-teal, [class*="theme-light"] .light\:border-teal {
  --border-opacity: 1 !important;
  border-color: #0694A2 !important;
  border-color: rgba(6, 148, 162, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-blue-50, [class*="theme-dark"] .dark\:border-blue-50, [class*="theme-light"].light\:border-blue-50, [class*="theme-light"] .light\:border-blue-50 {
  --border-opacity: 1 !important;
  border-color: #EBF5FF !important;
  border-color: rgba(235, 245, 255, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-blue-100, [class*="theme-dark"] .dark\:border-blue-100, [class*="theme-light"].light\:border-blue-100, [class*="theme-light"] .light\:border-blue-100 {
  --border-opacity: 1 !important;
  border-color: #E1EFFE !important;
  border-color: rgba(225, 239, 254, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-blue-200, [class*="theme-dark"] .dark\:border-blue-200, [class*="theme-light"].light\:border-blue-200, [class*="theme-light"] .light\:border-blue-200 {
  --border-opacity: 1 !important;
  border-color: #C3DDFD !important;
  border-color: rgba(195, 221, 253, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-blue-300, [class*="theme-dark"] .dark\:border-blue-300, [class*="theme-light"].light\:border-blue-300, [class*="theme-light"] .light\:border-blue-300 {
  --border-opacity: 1 !important;
  border-color: #A4CAFE !important;
  border-color: rgba(164, 202, 254, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-blue-400, [class*="theme-dark"] .dark\:border-blue-400, [class*="theme-light"].light\:border-blue-400, [class*="theme-light"] .light\:border-blue-400 {
  --border-opacity: 1 !important;
  border-color: #76A9FA !important;
  border-color: rgba(118, 169, 250, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-blue-500, [class*="theme-dark"] .dark\:border-blue-500, [class*="theme-light"].light\:border-blue-500, [class*="theme-light"] .light\:border-blue-500 {
  --border-opacity: 1 !important;
  border-color: #3F83F8 !important;
  border-color: rgba(63, 131, 248, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-blue-600, [class*="theme-dark"] .dark\:border-blue-600, [class*="theme-light"].light\:border-blue-600, [class*="theme-light"] .light\:border-blue-600 {
  --border-opacity: 1 !important;
  border-color: #1C64F2 !important;
  border-color: rgba(28, 100, 242, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-blue-700, [class*="theme-dark"] .dark\:border-blue-700, [class*="theme-light"].light\:border-blue-700, [class*="theme-light"] .light\:border-blue-700 {
  --border-opacity: 1 !important;
  border-color: #1A56DB !important;
  border-color: rgba(26, 86, 219, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-blue-800, [class*="theme-dark"] .dark\:border-blue-800, [class*="theme-light"].light\:border-blue-800, [class*="theme-light"] .light\:border-blue-800 {
  --border-opacity: 1 !important;
  border-color: #1E429F !important;
  border-color: rgba(30, 66, 159, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-blue-900, [class*="theme-dark"] .dark\:border-blue-900, [class*="theme-light"].light\:border-blue-900, [class*="theme-light"] .light\:border-blue-900 {
  --border-opacity: 1 !important;
  border-color: #233876 !important;
  border-color: rgba(35, 56, 118, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-blue, [class*="theme-dark"] .dark\:border-blue, [class*="theme-light"].light\:border-blue, [class*="theme-light"] .light\:border-blue {
  --border-opacity: 1 !important;
  border-color: #3F83F8 !important;
  border-color: rgba(63, 131, 248, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-indigo-50, [class*="theme-dark"] .dark\:border-indigo-50, [class*="theme-light"].light\:border-indigo-50, [class*="theme-light"] .light\:border-indigo-50 {
  --border-opacity: 1 !important;
  border-color: #F0F5FF !important;
  border-color: rgba(240, 245, 255, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-indigo-100, [class*="theme-dark"] .dark\:border-indigo-100, [class*="theme-light"].light\:border-indigo-100, [class*="theme-light"] .light\:border-indigo-100 {
  --border-opacity: 1 !important;
  border-color: #E5EDFF !important;
  border-color: rgba(229, 237, 255, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-indigo-200, [class*="theme-dark"] .dark\:border-indigo-200, [class*="theme-light"].light\:border-indigo-200, [class*="theme-light"] .light\:border-indigo-200 {
  --border-opacity: 1 !important;
  border-color: #CDDBFE !important;
  border-color: rgba(205, 219, 254, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-indigo-300, [class*="theme-dark"] .dark\:border-indigo-300, [class*="theme-light"].light\:border-indigo-300, [class*="theme-light"] .light\:border-indigo-300 {
  --border-opacity: 1 !important;
  border-color: #B4C6FC !important;
  border-color: rgba(180, 198, 252, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-indigo-400, [class*="theme-dark"] .dark\:border-indigo-400, [class*="theme-light"].light\:border-indigo-400, [class*="theme-light"] .light\:border-indigo-400 {
  --border-opacity: 1 !important;
  border-color: #8DA2FB !important;
  border-color: rgba(141, 162, 251, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-indigo-500, [class*="theme-dark"] .dark\:border-indigo-500, [class*="theme-light"].light\:border-indigo-500, [class*="theme-light"] .light\:border-indigo-500 {
  --border-opacity: 1 !important;
  border-color: #6875F5 !important;
  border-color: rgba(104, 117, 245, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-indigo-600, [class*="theme-dark"] .dark\:border-indigo-600, [class*="theme-light"].light\:border-indigo-600, [class*="theme-light"] .light\:border-indigo-600 {
  --border-opacity: 1 !important;
  border-color: #5850EC !important;
  border-color: rgba(88, 80, 236, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-indigo-700, [class*="theme-dark"] .dark\:border-indigo-700, [class*="theme-light"].light\:border-indigo-700, [class*="theme-light"] .light\:border-indigo-700 {
  --border-opacity: 1 !important;
  border-color: #5145CD !important;
  border-color: rgba(81, 69, 205, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-indigo-800, [class*="theme-dark"] .dark\:border-indigo-800, [class*="theme-light"].light\:border-indigo-800, [class*="theme-light"] .light\:border-indigo-800 {
  --border-opacity: 1 !important;
  border-color: #42389D !important;
  border-color: rgba(66, 56, 157, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-indigo-900, [class*="theme-dark"] .dark\:border-indigo-900, [class*="theme-light"].light\:border-indigo-900, [class*="theme-light"] .light\:border-indigo-900 {
  --border-opacity: 1 !important;
  border-color: #362F78 !important;
  border-color: rgba(54, 47, 120, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-indigo, [class*="theme-dark"] .dark\:border-indigo, [class*="theme-light"].light\:border-indigo, [class*="theme-light"] .light\:border-indigo {
  --border-opacity: 1 !important;
  border-color: #6875F5 !important;
  border-color: rgba(104, 117, 245, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-purple-50, [class*="theme-dark"] .dark\:border-purple-50, [class*="theme-light"].light\:border-purple-50, [class*="theme-light"] .light\:border-purple-50 {
  --border-opacity: 1 !important;
  border-color: #F6F5FF !important;
  border-color: rgba(246, 245, 255, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-purple-100, [class*="theme-dark"] .dark\:border-purple-100, [class*="theme-light"].light\:border-purple-100, [class*="theme-light"] .light\:border-purple-100 {
  --border-opacity: 1 !important;
  border-color: #EDEBFE !important;
  border-color: rgba(237, 235, 254, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-purple-200, [class*="theme-dark"] .dark\:border-purple-200, [class*="theme-light"].light\:border-purple-200, [class*="theme-light"] .light\:border-purple-200 {
  --border-opacity: 1 !important;
  border-color: #DCD7FE !important;
  border-color: rgba(220, 215, 254, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-purple-300, [class*="theme-dark"] .dark\:border-purple-300, [class*="theme-light"].light\:border-purple-300, [class*="theme-light"] .light\:border-purple-300 {
  --border-opacity: 1 !important;
  border-color: #CABFFD !important;
  border-color: rgba(202, 191, 253, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-purple-400, [class*="theme-dark"] .dark\:border-purple-400, [class*="theme-light"].light\:border-purple-400, [class*="theme-light"] .light\:border-purple-400 {
  --border-opacity: 1 !important;
  border-color: #AC94FA !important;
  border-color: rgba(172, 148, 250, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-purple-500, [class*="theme-dark"] .dark\:border-purple-500, [class*="theme-light"].light\:border-purple-500, [class*="theme-light"] .light\:border-purple-500 {
  --border-opacity: 1 !important;
  border-color: #9061F9 !important;
  border-color: rgba(144, 97, 249, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-purple-600, [class*="theme-dark"] .dark\:border-purple-600, [class*="theme-light"].light\:border-purple-600, [class*="theme-light"] .light\:border-purple-600 {
  --border-opacity: 1 !important;
  border-color: #7E3AF2 !important;
  border-color: rgba(126, 58, 242, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-purple-700, [class*="theme-dark"] .dark\:border-purple-700, [class*="theme-light"].light\:border-purple-700, [class*="theme-light"] .light\:border-purple-700 {
  --border-opacity: 1 !important;
  border-color: #6C2BD9 !important;
  border-color: rgba(108, 43, 217, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-purple-800, [class*="theme-dark"] .dark\:border-purple-800, [class*="theme-light"].light\:border-purple-800, [class*="theme-light"] .light\:border-purple-800 {
  --border-opacity: 1 !important;
  border-color: #5521B5 !important;
  border-color: rgba(85, 33, 181, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-purple-900, [class*="theme-dark"] .dark\:border-purple-900, [class*="theme-light"].light\:border-purple-900, [class*="theme-light"] .light\:border-purple-900 {
  --border-opacity: 1 !important;
  border-color: #4A1D96 !important;
  border-color: rgba(74, 29, 150, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-purple, [class*="theme-dark"] .dark\:border-purple, [class*="theme-light"].light\:border-purple, [class*="theme-light"] .light\:border-purple {
  --border-opacity: 1 !important;
  border-color: #9061F9 !important;
  border-color: rgba(144, 97, 249, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-pink-50, [class*="theme-dark"] .dark\:border-pink-50, [class*="theme-light"].light\:border-pink-50, [class*="theme-light"] .light\:border-pink-50 {
  --border-opacity: 1 !important;
  border-color: #FDF2F8 !important;
  border-color: rgba(253, 242, 248, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-pink-100, [class*="theme-dark"] .dark\:border-pink-100, [class*="theme-light"].light\:border-pink-100, [class*="theme-light"] .light\:border-pink-100 {
  --border-opacity: 1 !important;
  border-color: #FCE8F3 !important;
  border-color: rgba(252, 232, 243, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-pink-200, [class*="theme-dark"] .dark\:border-pink-200, [class*="theme-light"].light\:border-pink-200, [class*="theme-light"] .light\:border-pink-200 {
  --border-opacity: 1 !important;
  border-color: #FAD1E8 !important;
  border-color: rgba(250, 209, 232, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-pink-300, [class*="theme-dark"] .dark\:border-pink-300, [class*="theme-light"].light\:border-pink-300, [class*="theme-light"] .light\:border-pink-300 {
  --border-opacity: 1 !important;
  border-color: #F8B4D9 !important;
  border-color: rgba(248, 180, 217, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-pink-400, [class*="theme-dark"] .dark\:border-pink-400, [class*="theme-light"].light\:border-pink-400, [class*="theme-light"] .light\:border-pink-400 {
  --border-opacity: 1 !important;
  border-color: #F17EB8 !important;
  border-color: rgba(241, 126, 184, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-pink-500, [class*="theme-dark"] .dark\:border-pink-500, [class*="theme-light"].light\:border-pink-500, [class*="theme-light"] .light\:border-pink-500 {
  --border-opacity: 1 !important;
  border-color: #E74694 !important;
  border-color: rgba(231, 70, 148, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-pink-600, [class*="theme-dark"] .dark\:border-pink-600, [class*="theme-light"].light\:border-pink-600, [class*="theme-light"] .light\:border-pink-600 {
  --border-opacity: 1 !important;
  border-color: #D61F69 !important;
  border-color: rgba(214, 31, 105, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-pink-700, [class*="theme-dark"] .dark\:border-pink-700, [class*="theme-light"].light\:border-pink-700, [class*="theme-light"] .light\:border-pink-700 {
  --border-opacity: 1 !important;
  border-color: #BF125D !important;
  border-color: rgba(191, 18, 93, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-pink-800, [class*="theme-dark"] .dark\:border-pink-800, [class*="theme-light"].light\:border-pink-800, [class*="theme-light"] .light\:border-pink-800 {
  --border-opacity: 1 !important;
  border-color: #99154B !important;
  border-color: rgba(153, 21, 75, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-pink-900, [class*="theme-dark"] .dark\:border-pink-900, [class*="theme-light"].light\:border-pink-900, [class*="theme-light"] .light\:border-pink-900 {
  --border-opacity: 1 !important;
  border-color: #751A3D !important;
  border-color: rgba(117, 26, 61, var(--border-opacity)) !important;
}

[class*="theme-dark"].dark\:border-pink, [class*="theme-dark"] .dark\:border-pink, [class*="theme-light"].light\:border-pink, [class*="theme-light"] .light\:border-pink {
  --border-opacity: 1 !important;
  border-color: #E74694 !important;
  border-color: rgba(231, 70, 148, var(--border-opacity)) !important;
}

.border-opacity-0 {
  --border-opacity: 0 !important;
}

.border-opacity-12 {
  --border-opacity: 0.12 !important;
}

.border-opacity-25 {
  --border-opacity: 0.25 !important;
}

.border-opacity-38 {
  --border-opacity: 0.38 !important;
}

.border-opacity-50 {
  --border-opacity: 0.5 !important;
}

.border-opacity-54 {
  --border-opacity: 0.54 !important;
}

.border-opacity-70 {
  --border-opacity: 0.70 !important;
}

.border-opacity-75 {
  --border-opacity: 0.75 !important;
}

.border-opacity-84 {
  --border-opacity: 0.84 !important;
}

.border-opacity-100 {
  --border-opacity: 1 !important;
}

.hover\:border-opacity-0:hover {
  --border-opacity: 0 !important;
}

.hover\:border-opacity-12:hover {
  --border-opacity: 0.12 !important;
}

.hover\:border-opacity-25:hover {
  --border-opacity: 0.25 !important;
}

.hover\:border-opacity-38:hover {
  --border-opacity: 0.38 !important;
}

.hover\:border-opacity-50:hover {
  --border-opacity: 0.5 !important;
}

.hover\:border-opacity-54:hover {
  --border-opacity: 0.54 !important;
}

.hover\:border-opacity-70:hover {
  --border-opacity: 0.70 !important;
}

.hover\:border-opacity-75:hover {
  --border-opacity: 0.75 !important;
}

.hover\:border-opacity-84:hover {
  --border-opacity: 0.84 !important;
}

.hover\:border-opacity-100:hover {
  --border-opacity: 1 !important;
}

.focus\:border-opacity-0:focus {
  --border-opacity: 0 !important;
}

.focus\:border-opacity-12:focus {
  --border-opacity: 0.12 !important;
}

.focus\:border-opacity-25:focus {
  --border-opacity: 0.25 !important;
}

.focus\:border-opacity-38:focus {
  --border-opacity: 0.38 !important;
}

.focus\:border-opacity-50:focus {
  --border-opacity: 0.5 !important;
}

.focus\:border-opacity-54:focus {
  --border-opacity: 0.54 !important;
}

.focus\:border-opacity-70:focus {
  --border-opacity: 0.70 !important;
}

.focus\:border-opacity-75:focus {
  --border-opacity: 0.75 !important;
}

.focus\:border-opacity-84:focus {
  --border-opacity: 0.84 !important;
}

.focus\:border-opacity-100:focus {
  --border-opacity: 1 !important;
}

.rounded-none {
  border-radius: 0 !important;
}

.rounded-sm {
  border-radius: 0.125rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-md {
  border-radius: 0.375rem !important;
}

.rounded-lg {
  border-radius: 0.5rem !important;
}

.rounded-full {
  border-radius: 9999px !important;
}

.rounded-t-none {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-r-none {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-b-none {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-l-none {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-t-sm {
  border-top-left-radius: 0.125rem !important;
  border-top-right-radius: 0.125rem !important;
}

.rounded-r-sm {
  border-top-right-radius: 0.125rem !important;
  border-bottom-right-radius: 0.125rem !important;
}

.rounded-b-sm {
  border-bottom-right-radius: 0.125rem !important;
  border-bottom-left-radius: 0.125rem !important;
}

.rounded-l-sm {
  border-top-left-radius: 0.125rem !important;
  border-bottom-left-radius: 0.125rem !important;
}

.rounded-t {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-r {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-b {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-l {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-t-md {
  border-top-left-radius: 0.375rem !important;
  border-top-right-radius: 0.375rem !important;
}

.rounded-r-md {
  border-top-right-radius: 0.375rem !important;
  border-bottom-right-radius: 0.375rem !important;
}

.rounded-b-md {
  border-bottom-right-radius: 0.375rem !important;
  border-bottom-left-radius: 0.375rem !important;
}

.rounded-l-md {
  border-top-left-radius: 0.375rem !important;
  border-bottom-left-radius: 0.375rem !important;
}

.rounded-t-lg {
  border-top-left-radius: 0.5rem !important;
  border-top-right-radius: 0.5rem !important;
}

.rounded-r-lg {
  border-top-right-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
}

.rounded-b-lg {
  border-bottom-right-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
}

.rounded-l-lg {
  border-top-left-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
}

.rounded-t-full {
  border-top-left-radius: 9999px !important;
  border-top-right-radius: 9999px !important;
}

.rounded-r-full {
  border-top-right-radius: 9999px !important;
  border-bottom-right-radius: 9999px !important;
}

.rounded-b-full {
  border-bottom-right-radius: 9999px !important;
  border-bottom-left-radius: 9999px !important;
}

.rounded-l-full {
  border-top-left-radius: 9999px !important;
  border-bottom-left-radius: 9999px !important;
}

.rounded-tl-none {
  border-top-left-radius: 0 !important;
}

.rounded-tr-none {
  border-top-right-radius: 0 !important;
}

.rounded-br-none {
  border-bottom-right-radius: 0 !important;
}

.rounded-bl-none {
  border-bottom-left-radius: 0 !important;
}

.rounded-tl-sm {
  border-top-left-radius: 0.125rem !important;
}

.rounded-tr-sm {
  border-top-right-radius: 0.125rem !important;
}

.rounded-br-sm {
  border-bottom-right-radius: 0.125rem !important;
}

.rounded-bl-sm {
  border-bottom-left-radius: 0.125rem !important;
}

.rounded-tl {
  border-top-left-radius: 0.25rem !important;
}

.rounded-tr {
  border-top-right-radius: 0.25rem !important;
}

.rounded-br {
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bl {
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-tl-md {
  border-top-left-radius: 0.375rem !important;
}

.rounded-tr-md {
  border-top-right-radius: 0.375rem !important;
}

.rounded-br-md {
  border-bottom-right-radius: 0.375rem !important;
}

.rounded-bl-md {
  border-bottom-left-radius: 0.375rem !important;
}

.rounded-tl-lg {
  border-top-left-radius: 0.5rem !important;
}

.rounded-tr-lg {
  border-top-right-radius: 0.5rem !important;
}

.rounded-br-lg {
  border-bottom-right-radius: 0.5rem !important;
}

.rounded-bl-lg {
  border-bottom-left-radius: 0.5rem !important;
}

.rounded-tl-full {
  border-top-left-radius: 9999px !important;
}

.rounded-tr-full {
  border-top-right-radius: 9999px !important;
}

.rounded-br-full {
  border-bottom-right-radius: 9999px !important;
}

.rounded-bl-full {
  border-bottom-left-radius: 9999px !important;
}

.border-solid {
  border-style: solid !important;
}

.border-dashed {
  border-style: dashed !important;
}

.border-dotted {
  border-style: dotted !important;
}

.border-double {
  border-style: double !important;
}

.border-none {
  border-style: none !important;
}

.border-0 {
  border-width: 0 !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-8 {
  border-width: 8px !important;
}

.border {
  border-width: 1px !important;
}

.border-t-0 {
  border-top-width: 0 !important;
}

.border-r-0 {
  border-right-width: 0 !important;
}

.border-b-0 {
  border-bottom-width: 0 !important;
}

.border-l-0 {
  border-left-width: 0 !important;
}

.border-t-2 {
  border-top-width: 2px !important;
}

.border-r-2 {
  border-right-width: 2px !important;
}

.border-b-2 {
  border-bottom-width: 2px !important;
}

.border-l-2 {
  border-left-width: 2px !important;
}

.border-t-4 {
  border-top-width: 4px !important;
}

.border-r-4 {
  border-right-width: 4px !important;
}

.border-b-4 {
  border-bottom-width: 4px !important;
}

.border-l-4 {
  border-left-width: 4px !important;
}

.border-t-8 {
  border-top-width: 8px !important;
}

.border-r-8 {
  border-right-width: 8px !important;
}

.border-b-8 {
  border-bottom-width: 8px !important;
}

.border-l-8 {
  border-left-width: 8px !important;
}

.border-t {
  border-top-width: 1px !important;
}

.border-r {
  border-right-width: 1px !important;
}

.border-b {
  border-bottom-width: 1px !important;
}

.border-l {
  border-left-width: 1px !important;
}

.first\:border-0:first-child {
  border-width: 0 !important;
}

.first\:border-2:first-child {
  border-width: 2px !important;
}

.first\:border-4:first-child {
  border-width: 4px !important;
}

.first\:border-8:first-child {
  border-width: 8px !important;
}

.first\:border:first-child {
  border-width: 1px !important;
}

.first\:border-t-0:first-child {
  border-top-width: 0 !important;
}

.first\:border-r-0:first-child {
  border-right-width: 0 !important;
}

.first\:border-b-0:first-child {
  border-bottom-width: 0 !important;
}

.first\:border-l-0:first-child {
  border-left-width: 0 !important;
}

.first\:border-t-2:first-child {
  border-top-width: 2px !important;
}

.first\:border-r-2:first-child {
  border-right-width: 2px !important;
}

.first\:border-b-2:first-child {
  border-bottom-width: 2px !important;
}

.first\:border-l-2:first-child {
  border-left-width: 2px !important;
}

.first\:border-t-4:first-child {
  border-top-width: 4px !important;
}

.first\:border-r-4:first-child {
  border-right-width: 4px !important;
}

.first\:border-b-4:first-child {
  border-bottom-width: 4px !important;
}

.first\:border-l-4:first-child {
  border-left-width: 4px !important;
}

.first\:border-t-8:first-child {
  border-top-width: 8px !important;
}

.first\:border-r-8:first-child {
  border-right-width: 8px !important;
}

.first\:border-b-8:first-child {
  border-bottom-width: 8px !important;
}

.first\:border-l-8:first-child {
  border-left-width: 8px !important;
}

.first\:border-t:first-child {
  border-top-width: 1px !important;
}

.first\:border-r:first-child {
  border-right-width: 1px !important;
}

.first\:border-b:first-child {
  border-bottom-width: 1px !important;
}

.first\:border-l:first-child {
  border-left-width: 1px !important;
}

.last\:border-0:last-child {
  border-width: 0 !important;
}

.last\:border-2:last-child {
  border-width: 2px !important;
}

.last\:border-4:last-child {
  border-width: 4px !important;
}

.last\:border-8:last-child {
  border-width: 8px !important;
}

.last\:border:last-child {
  border-width: 1px !important;
}

.last\:border-t-0:last-child {
  border-top-width: 0 !important;
}

.last\:border-r-0:last-child {
  border-right-width: 0 !important;
}

.last\:border-b-0:last-child {
  border-bottom-width: 0 !important;
}

.last\:border-l-0:last-child {
  border-left-width: 0 !important;
}

.last\:border-t-2:last-child {
  border-top-width: 2px !important;
}

.last\:border-r-2:last-child {
  border-right-width: 2px !important;
}

.last\:border-b-2:last-child {
  border-bottom-width: 2px !important;
}

.last\:border-l-2:last-child {
  border-left-width: 2px !important;
}

.last\:border-t-4:last-child {
  border-top-width: 4px !important;
}

.last\:border-r-4:last-child {
  border-right-width: 4px !important;
}

.last\:border-b-4:last-child {
  border-bottom-width: 4px !important;
}

.last\:border-l-4:last-child {
  border-left-width: 4px !important;
}

.last\:border-t-8:last-child {
  border-top-width: 8px !important;
}

.last\:border-r-8:last-child {
  border-right-width: 8px !important;
}

.last\:border-b-8:last-child {
  border-bottom-width: 8px !important;
}

.last\:border-l-8:last-child {
  border-left-width: 8px !important;
}

.last\:border-t:last-child {
  border-top-width: 1px !important;
}

.last\:border-r:last-child {
  border-right-width: 1px !important;
}

.last\:border-b:last-child {
  border-bottom-width: 1px !important;
}

.last\:border-l:last-child {
  border-left-width: 1px !important;
}

.box-border {
  box-sizing: border-box !important;
}

.box-content {
  box-sizing: content-box !important;
}

.cursor-auto {
  cursor: auto !important;
}

.cursor-default {
  cursor: default !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-wait {
  cursor: wait !important;
}

.cursor-text {
  cursor: text !important;
}

.cursor-move {
  cursor: move !important;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.block {
  display: block !important;
}

.inline-block {
  display: inline-block !important;
}

.inline {
  display: inline !important;
}

.flex {
  display: flex !important;
}

.inline-flex {
  display: inline-flex !important;
}

.table {
  display: table !important;
}

.table-caption {
  display: table-caption !important;
}

.table-cell {
  display: table-cell !important;
}

.table-column {
  display: table-column !important;
}

.table-column-group {
  display: table-column-group !important;
}

.table-footer-group {
  display: table-footer-group !important;
}

.table-header-group {
  display: table-header-group !important;
}

.table-row-group {
  display: table-row-group !important;
}

.table-row {
  display: table-row !important;
}

.flow-root {
  display: flow-root !important;
}

.grid {
  display: grid !important;
}

.inline-grid {
  display: inline-grid !important;
}

.hidden {
  display: none !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-col {
  flex-direction: column !important;
}

.flex-col-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.items-start {
  align-items: flex-start !important;
}

.items-end {
  align-items: flex-end !important;
}

.items-center {
  align-items: center !important;
}

.items-baseline {
  align-items: baseline !important;
}

.items-stretch {
  align-items: stretch !important;
}

.self-auto {
  align-self: auto !important;
}

.self-start {
  align-self: flex-start !important;
}

.self-end {
  align-self: flex-end !important;
}

.self-center {
  align-self: center !important;
}

.self-stretch {
  align-self: stretch !important;
}

.justify-start {
  justify-content: flex-start !important;
}

.justify-end {
  justify-content: flex-end !important;
}

.justify-center {
  justify-content: center !important;
}

.justify-between {
  justify-content: space-between !important;
}

.justify-around {
  justify-content: space-around !important;
}

.justify-evenly {
  justify-content: space-evenly !important;
}

.content-center {
  align-content: center !important;
}

.content-start {
  align-content: flex-start !important;
}

.content-end {
  align-content: flex-end !important;
}

.content-between {
  align-content: space-between !important;
}

.content-around {
  align-content: space-around !important;
}

.flex-0 {
  flex: 0 0 auto !important;
}

.flex-1 {
  flex: 1 1 0% !important;
}

.flex-auto {
  flex: 1 1 auto !important;
}

.flex-initial {
  flex: 0 1 auto !important;
}

.flex-none {
  flex: none !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink {
  flex-shrink: 1 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-6 {
  order: 6 !important;
}

.order-7 {
  order: 7 !important;
}

.order-8 {
  order: 8 !important;
}

.order-9 {
  order: 9 !important;
}

.order-10 {
  order: 10 !important;
}

.order-11 {
  order: 11 !important;
}

.order-12 {
  order: 12 !important;
}

.order-first {
  order: -9999 !important;
}

.order-last {
  order: 9999 !important;
}

.order-none {
  order: 0 !important;
}

.font-sans {
  font-family: Inter, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}

.font-serif {
  font-family: Georgia, Cambria, "Times New Roman", Times, serif !important;
}

.font-mono {
  font-family: "IBM Plex Mono", Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.font-thin {
  font-weight: 100 !important;
}

.font-extralight {
  font-weight: 200 !important;
}

.font-light {
  font-weight: 300 !important;
}

.font-normal {
  font-weight: 400 !important;
}

.font-medium {
  font-weight: 500 !important;
}

.font-semibold {
  font-weight: 600 !important;
}

.font-bold {
  font-weight: 700 !important;
}

.font-extrabold {
  font-weight: 800 !important;
}

.font-black {
  font-weight: 900 !important;
}

.h-0 {
  height: 0 !important;
}

.h-1 {
  height: 0.25rem !important;
}

.h-2 {
  height: 0.5rem !important;
}

.h-3 {
  height: 0.75rem !important;
}

.h-4 {
  height: 1rem !important;
}

.h-5 {
  height: 1.25rem !important;
}

.h-6 {
  height: 1.5rem !important;
}

.h-8 {
  height: 2rem !important;
}

.h-10 {
  height: 2.5rem !important;
}

.h-12 {
  height: 3rem !important;
}

.h-14 {
  height: 3.5rem !important;
}

.h-16 {
  height: 4rem !important;
}

.h-18 {
  height: 4.5rem !important;
}

.h-20 {
  height: 5rem !important;
}

.h-22 {
  height: 5.5rem !important;
}

.h-24 {
  height: 6rem !important;
}

.h-26 {
  height: 6.5rem !important;
}

.h-28 {
  height: 7rem !important;
}

.h-30 {
  height: 7.5rem !important;
}

.h-32 {
  height: 8rem !important;
}

.h-36 {
  height: 9rem !important;
}

.h-40 {
  height: 10rem !important;
}

.h-48 {
  height: 12rem !important;
}

.h-50 {
  height: 12.5rem !important;
}

.h-56 {
  height: 14rem !important;
}

.h-60 {
  height: 15rem !important;
}

.h-64 {
  height: 16rem !important;
}

.h-80 {
  height: 20rem !important;
}

.h-90 {
  height: 24rem !important;
}

.h-100 {
  height: 25rem !important;
}

.h-120 {
  height: 30rem !important;
}

.h-128 {
  height: 32rem !important;
}

.h-140 {
  height: 35rem !important;
}

.h-160 {
  height: 40rem !important;
}

.h-180 {
  height: 45rem !important;
}

.h-192 {
  height: 48rem !important;
}

.h-200 {
  height: 50rem !important;
}

.h-240 {
  height: 60rem !important;
}

.h-256 {
  height: 64rem !important;
}

.h-280 {
  height: 70rem !important;
}

.h-320 {
  height: 80rem !important;
}

.h-360 {
  height: 90rem !important;
}

.h-400 {
  height: 100rem !important;
}

.h-480 {
  height: 120rem !important;
}

.h-auto {
  height: auto !important;
}

.h-px {
  height: 1px !important;
}

.h-2px {
  height: 2px !important;
}

.h-full {
  height: 100% !important;
}

.h-screen {
  height: 100vh !important;
}

.h-1\/2 {
  height: 50% !important;
}

.h-1\/3 {
  height: 33.33333% !important;
}

.h-2\/3 {
  height: 66.66667% !important;
}

.h-1\/4 {
  height: 25% !important;
}

.h-2\/4 {
  height: 50% !important;
}

.h-3\/4 {
  height: 75% !important;
}

.h-1\/5 {
  height: 20% !important;
}

.h-2\/5 {
  height: 40% !important;
}

.h-3\/5 {
  height: 60% !important;
}

.h-4\/5 {
  height: 80% !important;
}

.h-1\/12 {
  height: 8.33333% !important;
}

.h-2\/12 {
  height: 16.66667% !important;
}

.h-3\/12 {
  height: 25% !important;
}

.h-4\/12 {
  height: 33.33333% !important;
}

.h-5\/12 {
  height: 41.66667% !important;
}

.h-6\/12 {
  height: 50% !important;
}

.h-7\/12 {
  height: 58.33333% !important;
}

.h-8\/12 {
  height: 66.66667% !important;
}

.h-9\/12 {
  height: 75% !important;
}

.h-10\/12 {
  height: 83.33333% !important;
}

.h-11\/12 {
  height: 91.66667% !important;
}

.text-xs {
  font-size: 0.625rem !important;
}

.text-sm {
  font-size: 0.75rem !important;
}

.text-md {
  font-size: 0.8125rem !important;
}

.text-base {
  font-size: 0.875rem !important;
}

.text-lg {
  font-size: 1rem !important;
}

.text-xl {
  font-size: 1.125rem !important;
}

.text-2xl {
  font-size: 1.25rem !important;
}

.text-3xl {
  font-size: 1.5rem !important;
}

.text-4xl {
  font-size: 2rem !important;
}

.text-5xl {
  font-size: 2.25rem !important;
}

.text-6xl {
  font-size: 2.5rem !important;
}

.text-7xl {
  font-size: 3rem !important;
}

.text-8xl {
  font-size: 4rem !important;
}

.text-9xl {
  font-size: 6rem !important;
}

.text-10xl {
  font-size: 8rem !important;
}

.leading-3 {
  line-height: .75rem !important;
}

.leading-4 {
  line-height: 1rem !important;
}

.leading-5 {
  line-height: 1.25rem !important;
}

.leading-6 {
  line-height: 1.5rem !important;
}

.leading-7 {
  line-height: 1.75rem !important;
}

.leading-8 {
  line-height: 2rem !important;
}

.leading-9 {
  line-height: 2.25rem !important;
}

.leading-10 {
  line-height: 2.5rem !important;
}

.leading-none {
  line-height: 1 !important;
}

.leading-tight {
  line-height: 1.25 !important;
}

.leading-snug {
  line-height: 1.375 !important;
}

.leading-normal {
  line-height: 1.5 !important;
}

.leading-relaxed {
  line-height: 1.625 !important;
}

.leading-loose {
  line-height: 2 !important;
}

.list-inside {
  list-style-position: inside !important;
}

.list-outside {
  list-style-position: outside !important;
}

.list-none {
  list-style-type: none !important;
}

.list-disc {
  list-style-type: disc !important;
}

.list-decimal {
  list-style-type: decimal !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 0.75rem !important;
}

.m-4 {
  margin: 1rem !important;
}

.m-5 {
  margin: 1.25rem !important;
}

.m-6 {
  margin: 1.5rem !important;
}

.m-8 {
  margin: 2rem !important;
}

.m-10 {
  margin: 2.5rem !important;
}

.m-12 {
  margin: 3rem !important;
}

.m-14 {
  margin: 3.5rem !important;
}

.m-16 {
  margin: 4rem !important;
}

.m-18 {
  margin: 4.5rem !important;
}

.m-20 {
  margin: 5rem !important;
}

.m-22 {
  margin: 5.5rem !important;
}

.m-24 {
  margin: 6rem !important;
}

.m-26 {
  margin: 6.5rem !important;
}

.m-28 {
  margin: 7rem !important;
}

.m-30 {
  margin: 7.5rem !important;
}

.m-32 {
  margin: 8rem !important;
}

.m-36 {
  margin: 9rem !important;
}

.m-40 {
  margin: 10rem !important;
}

.m-48 {
  margin: 12rem !important;
}

.m-56 {
  margin: 14rem !important;
}

.m-64 {
  margin: 16rem !important;
}

.m-auto {
  margin: auto !important;
}

.m-px {
  margin: 1px !important;
}

.m-2px {
  margin: 2px !important;
}

.-m-1 {
  margin: -0.25rem !important;
}

.-m-2 {
  margin: -0.5rem !important;
}

.-m-3 {
  margin: -0.75rem !important;
}

.-m-4 {
  margin: -1rem !important;
}

.-m-5 {
  margin: -1.25rem !important;
}

.-m-6 {
  margin: -1.5rem !important;
}

.-m-8 {
  margin: -2rem !important;
}

.-m-10 {
  margin: -2.5rem !important;
}

.-m-12 {
  margin: -3rem !important;
}

.-m-14 {
  margin: -3.5rem !important;
}

.-m-16 {
  margin: -4rem !important;
}

.-m-18 {
  margin: -4.5rem !important;
}

.-m-20 {
  margin: -5rem !important;
}

.-m-22 {
  margin: -5.5rem !important;
}

.-m-24 {
  margin: -6rem !important;
}

.-m-26 {
  margin: -6.5rem !important;
}

.-m-28 {
  margin: -7rem !important;
}

.-m-30 {
  margin: -7.5rem !important;
}

.-m-32 {
  margin: -8rem !important;
}

.-m-36 {
  margin: -9rem !important;
}

.-m-40 {
  margin: -10rem !important;
}

.-m-48 {
  margin: -12rem !important;
}

.-m-56 {
  margin: -14rem !important;
}

.-m-64 {
  margin: -16rem !important;
}

.-m-px {
  margin: -1px !important;
}

.-m-2px {
  margin: -2px !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.mx-1 {
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.mx-2 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}

.my-3 {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
}

.mx-3 {
  margin-left: 0.75rem !important;
  margin-right: 0.75rem !important;
}

.my-4 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.mx-4 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.my-5 {
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important;
}

.mx-5 {
  margin-left: 1.25rem !important;
  margin-right: 1.25rem !important;
}

.my-6 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.mx-6 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.my-8 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.mx-8 {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}

.my-10 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.mx-10 {
  margin-left: 2.5rem !important;
  margin-right: 2.5rem !important;
}

.my-12 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.mx-12 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.my-14 {
  margin-top: 3.5rem !important;
  margin-bottom: 3.5rem !important;
}

.mx-14 {
  margin-left: 3.5rem !important;
  margin-right: 3.5rem !important;
}

.my-16 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.mx-16 {
  margin-left: 4rem !important;
  margin-right: 4rem !important;
}

.my-18 {
  margin-top: 4.5rem !important;
  margin-bottom: 4.5rem !important;
}

.mx-18 {
  margin-left: 4.5rem !important;
  margin-right: 4.5rem !important;
}

.my-20 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}

.mx-20 {
  margin-left: 5rem !important;
  margin-right: 5rem !important;
}

.my-22 {
  margin-top: 5.5rem !important;
  margin-bottom: 5.5rem !important;
}

.mx-22 {
  margin-left: 5.5rem !important;
  margin-right: 5.5rem !important;
}

.my-24 {
  margin-top: 6rem !important;
  margin-bottom: 6rem !important;
}

.mx-24 {
  margin-left: 6rem !important;
  margin-right: 6rem !important;
}

.my-26 {
  margin-top: 6.5rem !important;
  margin-bottom: 6.5rem !important;
}

.mx-26 {
  margin-left: 6.5rem !important;
  margin-right: 6.5rem !important;
}

.my-28 {
  margin-top: 7rem !important;
  margin-bottom: 7rem !important;
}

.mx-28 {
  margin-left: 7rem !important;
  margin-right: 7rem !important;
}

.my-30 {
  margin-top: 7.5rem !important;
  margin-bottom: 7.5rem !important;
}

.mx-30 {
  margin-left: 7.5rem !important;
  margin-right: 7.5rem !important;
}

.my-32 {
  margin-top: 8rem !important;
  margin-bottom: 8rem !important;
}

.mx-32 {
  margin-left: 8rem !important;
  margin-right: 8rem !important;
}

.my-36 {
  margin-top: 9rem !important;
  margin-bottom: 9rem !important;
}

.mx-36 {
  margin-left: 9rem !important;
  margin-right: 9rem !important;
}

.my-40 {
  margin-top: 10rem !important;
  margin-bottom: 10rem !important;
}

.mx-40 {
  margin-left: 10rem !important;
  margin-right: 10rem !important;
}

.my-48 {
  margin-top: 12rem !important;
  margin-bottom: 12rem !important;
}

.mx-48 {
  margin-left: 12rem !important;
  margin-right: 12rem !important;
}

.my-56 {
  margin-top: 14rem !important;
  margin-bottom: 14rem !important;
}

.mx-56 {
  margin-left: 14rem !important;
  margin-right: 14rem !important;
}

.my-64 {
  margin-top: 16rem !important;
  margin-bottom: 16rem !important;
}

.mx-64 {
  margin-left: 16rem !important;
  margin-right: 16rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.my-px {
  margin-top: 1px !important;
  margin-bottom: 1px !important;
}

.mx-px {
  margin-left: 1px !important;
  margin-right: 1px !important;
}

.my-2px {
  margin-top: 2px !important;
  margin-bottom: 2px !important;
}

.mx-2px {
  margin-left: 2px !important;
  margin-right: 2px !important;
}

.-my-1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

.-mx-1 {
  margin-left: -0.25rem !important;
  margin-right: -0.25rem !important;
}

.-my-2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.-mx-2 {
  margin-left: -0.5rem !important;
  margin-right: -0.5rem !important;
}

.-my-3 {
  margin-top: -0.75rem !important;
  margin-bottom: -0.75rem !important;
}

.-mx-3 {
  margin-left: -0.75rem !important;
  margin-right: -0.75rem !important;
}

.-my-4 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.-mx-4 {
  margin-left: -1rem !important;
  margin-right: -1rem !important;
}

.-my-5 {
  margin-top: -1.25rem !important;
  margin-bottom: -1.25rem !important;
}

.-mx-5 {
  margin-left: -1.25rem !important;
  margin-right: -1.25rem !important;
}

.-my-6 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.-mx-6 {
  margin-left: -1.5rem !important;
  margin-right: -1.5rem !important;
}

.-my-8 {
  margin-top: -2rem !important;
  margin-bottom: -2rem !important;
}

.-mx-8 {
  margin-left: -2rem !important;
  margin-right: -2rem !important;
}

.-my-10 {
  margin-top: -2.5rem !important;
  margin-bottom: -2.5rem !important;
}

.-mx-10 {
  margin-left: -2.5rem !important;
  margin-right: -2.5rem !important;
}

.-my-12 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.-mx-12 {
  margin-left: -3rem !important;
  margin-right: -3rem !important;
}

.-my-14 {
  margin-top: -3.5rem !important;
  margin-bottom: -3.5rem !important;
}

.-mx-14 {
  margin-left: -3.5rem !important;
  margin-right: -3.5rem !important;
}

.-my-16 {
  margin-top: -4rem !important;
  margin-bottom: -4rem !important;
}

.-mx-16 {
  margin-left: -4rem !important;
  margin-right: -4rem !important;
}

.-my-18 {
  margin-top: -4.5rem !important;
  margin-bottom: -4.5rem !important;
}

.-mx-18 {
  margin-left: -4.5rem !important;
  margin-right: -4.5rem !important;
}

.-my-20 {
  margin-top: -5rem !important;
  margin-bottom: -5rem !important;
}

.-mx-20 {
  margin-left: -5rem !important;
  margin-right: -5rem !important;
}

.-my-22 {
  margin-top: -5.5rem !important;
  margin-bottom: -5.5rem !important;
}

.-mx-22 {
  margin-left: -5.5rem !important;
  margin-right: -5.5rem !important;
}

.-my-24 {
  margin-top: -6rem !important;
  margin-bottom: -6rem !important;
}

.-mx-24 {
  margin-left: -6rem !important;
  margin-right: -6rem !important;
}

.-my-26 {
  margin-top: -6.5rem !important;
  margin-bottom: -6.5rem !important;
}

.-mx-26 {
  margin-left: -6.5rem !important;
  margin-right: -6.5rem !important;
}

.-my-28 {
  margin-top: -7rem !important;
  margin-bottom: -7rem !important;
}

.-mx-28 {
  margin-left: -7rem !important;
  margin-right: -7rem !important;
}

.-my-30 {
  margin-top: -7.5rem !important;
  margin-bottom: -7.5rem !important;
}

.-mx-30 {
  margin-left: -7.5rem !important;
  margin-right: -7.5rem !important;
}

.-my-32 {
  margin-top: -8rem !important;
  margin-bottom: -8rem !important;
}

.-mx-32 {
  margin-left: -8rem !important;
  margin-right: -8rem !important;
}

.-my-36 {
  margin-top: -9rem !important;
  margin-bottom: -9rem !important;
}

.-mx-36 {
  margin-left: -9rem !important;
  margin-right: -9rem !important;
}

.-my-40 {
  margin-top: -10rem !important;
  margin-bottom: -10rem !important;
}

.-mx-40 {
  margin-left: -10rem !important;
  margin-right: -10rem !important;
}

.-my-48 {
  margin-top: -12rem !important;
  margin-bottom: -12rem !important;
}

.-mx-48 {
  margin-left: -12rem !important;
  margin-right: -12rem !important;
}

.-my-56 {
  margin-top: -14rem !important;
  margin-bottom: -14rem !important;
}

.-mx-56 {
  margin-left: -14rem !important;
  margin-right: -14rem !important;
}

.-my-64 {
  margin-top: -16rem !important;
  margin-bottom: -16rem !important;
}

.-mx-64 {
  margin-left: -16rem !important;
  margin-right: -16rem !important;
}

.-my-px {
  margin-top: -1px !important;
  margin-bottom: -1px !important;
}

.-mx-px {
  margin-left: -1px !important;
  margin-right: -1px !important;
}

.-my-2px {
  margin-top: -2px !important;
  margin-bottom: -2px !important;
}

.-mx-2px {
  margin-left: -2px !important;
  margin-right: -2px !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mr-1 {
  margin-right: 0.25rem !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1 {
  margin-left: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2 {
  margin-left: 0.5rem !important;
}

.mt-3 {
  margin-top: 0.75rem !important;
}

.mr-3 {
  margin-right: 0.75rem !important;
}

.mb-3 {
  margin-bottom: 0.75rem !important;
}

.ml-3 {
  margin-left: 0.75rem !important;
}

.mt-4 {
  margin-top: 1rem !important;
}

.mr-4 {
  margin-right: 1rem !important;
}

.mb-4 {
  margin-bottom: 1rem !important;
}

.ml-4 {
  margin-left: 1rem !important;
}

.mt-5 {
  margin-top: 1.25rem !important;
}

.mr-5 {
  margin-right: 1.25rem !important;
}

.mb-5 {
  margin-bottom: 1.25rem !important;
}

.ml-5 {
  margin-left: 1.25rem !important;
}

.mt-6 {
  margin-top: 1.5rem !important;
}

.mr-6 {
  margin-right: 1.5rem !important;
}

.mb-6 {
  margin-bottom: 1.5rem !important;
}

.ml-6 {
  margin-left: 1.5rem !important;
}

.mt-8 {
  margin-top: 2rem !important;
}

.mr-8 {
  margin-right: 2rem !important;
}

.mb-8 {
  margin-bottom: 2rem !important;
}

.ml-8 {
  margin-left: 2rem !important;
}

.mt-10 {
  margin-top: 2.5rem !important;
}

.mr-10 {
  margin-right: 2.5rem !important;
}

.mb-10 {
  margin-bottom: 2.5rem !important;
}

.ml-10 {
  margin-left: 2.5rem !important;
}

.mt-12 {
  margin-top: 3rem !important;
}

.mr-12 {
  margin-right: 3rem !important;
}

.mb-12 {
  margin-bottom: 3rem !important;
}

.ml-12 {
  margin-left: 3rem !important;
}

.mt-14 {
  margin-top: 3.5rem !important;
}

.mr-14 {
  margin-right: 3.5rem !important;
}

.mb-14 {
  margin-bottom: 3.5rem !important;
}

.ml-14 {
  margin-left: 3.5rem !important;
}

.mt-16 {
  margin-top: 4rem !important;
}

.mr-16 {
  margin-right: 4rem !important;
}

.mb-16 {
  margin-bottom: 4rem !important;
}

.ml-16 {
  margin-left: 4rem !important;
}

.mt-18 {
  margin-top: 4.5rem !important;
}

.mr-18 {
  margin-right: 4.5rem !important;
}

.mb-18 {
  margin-bottom: 4.5rem !important;
}

.ml-18 {
  margin-left: 4.5rem !important;
}

.mt-20 {
  margin-top: 5rem !important;
}

.mr-20 {
  margin-right: 5rem !important;
}

.mb-20 {
  margin-bottom: 5rem !important;
}

.ml-20 {
  margin-left: 5rem !important;
}

.mt-22 {
  margin-top: 5.5rem !important;
}

.mr-22 {
  margin-right: 5.5rem !important;
}

.mb-22 {
  margin-bottom: 5.5rem !important;
}

.ml-22 {
  margin-left: 5.5rem !important;
}

.mt-24 {
  margin-top: 6rem !important;
}

.mr-24 {
  margin-right: 6rem !important;
}

.mb-24 {
  margin-bottom: 6rem !important;
}

.ml-24 {
  margin-left: 6rem !important;
}

.mt-26 {
  margin-top: 6.5rem !important;
}

.mr-26 {
  margin-right: 6.5rem !important;
}

.mb-26 {
  margin-bottom: 6.5rem !important;
}

.ml-26 {
  margin-left: 6.5rem !important;
}

.mt-28 {
  margin-top: 7rem !important;
}

.mr-28 {
  margin-right: 7rem !important;
}

.mb-28 {
  margin-bottom: 7rem !important;
}

.ml-28 {
  margin-left: 7rem !important;
}

.mt-30 {
  margin-top: 7.5rem !important;
}

.mr-30 {
  margin-right: 7.5rem !important;
}

.mb-30 {
  margin-bottom: 7.5rem !important;
}

.ml-30 {
  margin-left: 7.5rem !important;
}

.mt-32 {
  margin-top: 8rem !important;
}

.mr-32 {
  margin-right: 8rem !important;
}

.mb-32 {
  margin-bottom: 8rem !important;
}

.ml-32 {
  margin-left: 8rem !important;
}

.mt-36 {
  margin-top: 9rem !important;
}

.mr-36 {
  margin-right: 9rem !important;
}

.mb-36 {
  margin-bottom: 9rem !important;
}

.ml-36 {
  margin-left: 9rem !important;
}

.mt-40 {
  margin-top: 10rem !important;
}

.mr-40 {
  margin-right: 10rem !important;
}

.mb-40 {
  margin-bottom: 10rem !important;
}

.ml-40 {
  margin-left: 10rem !important;
}

.mt-48 {
  margin-top: 12rem !important;
}

.mr-48 {
  margin-right: 12rem !important;
}

.mb-48 {
  margin-bottom: 12rem !important;
}

.ml-48 {
  margin-left: 12rem !important;
}

.mt-56 {
  margin-top: 14rem !important;
}

.mr-56 {
  margin-right: 14rem !important;
}

.mb-56 {
  margin-bottom: 14rem !important;
}

.ml-56 {
  margin-left: 14rem !important;
}

.mt-64 {
  margin-top: 16rem !important;
}

.mr-64 {
  margin-right: 16rem !important;
}

.mb-64 {
  margin-bottom: 16rem !important;
}

.ml-64 {
  margin-left: 16rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mt-px {
  margin-top: 1px !important;
}

.mr-px {
  margin-right: 1px !important;
}

.mb-px {
  margin-bottom: 1px !important;
}

.ml-px {
  margin-left: 1px !important;
}

.mt-2px {
  margin-top: 2px !important;
}

.mr-2px {
  margin-right: 2px !important;
}

.mb-2px {
  margin-bottom: 2px !important;
}

.ml-2px {
  margin-left: 2px !important;
}

.-mt-1 {
  margin-top: -0.25rem !important;
}

.-mr-1 {
  margin-right: -0.25rem !important;
}

.-mb-1 {
  margin-bottom: -0.25rem !important;
}

.-ml-1 {
  margin-left: -0.25rem !important;
}

.-mt-2 {
  margin-top: -0.5rem !important;
}

.-mr-2 {
  margin-right: -0.5rem !important;
}

.-mb-2 {
  margin-bottom: -0.5rem !important;
}

.-ml-2 {
  margin-left: -0.5rem !important;
}

.-mt-3 {
  margin-top: -0.75rem !important;
}

.-mr-3 {
  margin-right: -0.75rem !important;
}

.-mb-3 {
  margin-bottom: -0.75rem !important;
}

.-ml-3 {
  margin-left: -0.75rem !important;
}

.-mt-4 {
  margin-top: -1rem !important;
}

.-mr-4 {
  margin-right: -1rem !important;
}

.-mb-4 {
  margin-bottom: -1rem !important;
}

.-ml-4 {
  margin-left: -1rem !important;
}

.-mt-5 {
  margin-top: -1.25rem !important;
}

.-mr-5 {
  margin-right: -1.25rem !important;
}

.-mb-5 {
  margin-bottom: -1.25rem !important;
}

.-ml-5 {
  margin-left: -1.25rem !important;
}

.-mt-6 {
  margin-top: -1.5rem !important;
}

.-mr-6 {
  margin-right: -1.5rem !important;
}

.-mb-6 {
  margin-bottom: -1.5rem !important;
}

.-ml-6 {
  margin-left: -1.5rem !important;
}

.-mt-8 {
  margin-top: -2rem !important;
}

.-mr-8 {
  margin-right: -2rem !important;
}

.-mb-8 {
  margin-bottom: -2rem !important;
}

.-ml-8 {
  margin-left: -2rem !important;
}

.-mt-10 {
  margin-top: -2.5rem !important;
}

.-mr-10 {
  margin-right: -2.5rem !important;
}

.-mb-10 {
  margin-bottom: -2.5rem !important;
}

.-ml-10 {
  margin-left: -2.5rem !important;
}

.-mt-12 {
  margin-top: -3rem !important;
}

.-mr-12 {
  margin-right: -3rem !important;
}

.-mb-12 {
  margin-bottom: -3rem !important;
}

.-ml-12 {
  margin-left: -3rem !important;
}

.-mt-14 {
  margin-top: -3.5rem !important;
}

.-mr-14 {
  margin-right: -3.5rem !important;
}

.-mb-14 {
  margin-bottom: -3.5rem !important;
}

.-ml-14 {
  margin-left: -3.5rem !important;
}

.-mt-16 {
  margin-top: -4rem !important;
}

.-mr-16 {
  margin-right: -4rem !important;
}

.-mb-16 {
  margin-bottom: -4rem !important;
}

.-ml-16 {
  margin-left: -4rem !important;
}

.-mt-18 {
  margin-top: -4.5rem !important;
}

.-mr-18 {
  margin-right: -4.5rem !important;
}

.-mb-18 {
  margin-bottom: -4.5rem !important;
}

.-ml-18 {
  margin-left: -4.5rem !important;
}

.-mt-20 {
  margin-top: -5rem !important;
}

.-mr-20 {
  margin-right: -5rem !important;
}

.-mb-20 {
  margin-bottom: -5rem !important;
}

.-ml-20 {
  margin-left: -5rem !important;
}

.-mt-22 {
  margin-top: -5.5rem !important;
}

.-mr-22 {
  margin-right: -5.5rem !important;
}

.-mb-22 {
  margin-bottom: -5.5rem !important;
}

.-ml-22 {
  margin-left: -5.5rem !important;
}

.-mt-24 {
  margin-top: -6rem !important;
}

.-mr-24 {
  margin-right: -6rem !important;
}

.-mb-24 {
  margin-bottom: -6rem !important;
}

.-ml-24 {
  margin-left: -6rem !important;
}

.-mt-26 {
  margin-top: -6.5rem !important;
}

.-mr-26 {
  margin-right: -6.5rem !important;
}

.-mb-26 {
  margin-bottom: -6.5rem !important;
}

.-ml-26 {
  margin-left: -6.5rem !important;
}

.-mt-28 {
  margin-top: -7rem !important;
}

.-mr-28 {
  margin-right: -7rem !important;
}

.-mb-28 {
  margin-bottom: -7rem !important;
}

.-ml-28 {
  margin-left: -7rem !important;
}

.-mt-30 {
  margin-top: -7.5rem !important;
}

.-mr-30 {
  margin-right: -7.5rem !important;
}

.-mb-30 {
  margin-bottom: -7.5rem !important;
}

.-ml-30 {
  margin-left: -7.5rem !important;
}

.-mt-32 {
  margin-top: -8rem !important;
}

.-mr-32 {
  margin-right: -8rem !important;
}

.-mb-32 {
  margin-bottom: -8rem !important;
}

.-ml-32 {
  margin-left: -8rem !important;
}

.-mt-36 {
  margin-top: -9rem !important;
}

.-mr-36 {
  margin-right: -9rem !important;
}

.-mb-36 {
  margin-bottom: -9rem !important;
}

.-ml-36 {
  margin-left: -9rem !important;
}

.-mt-40 {
  margin-top: -10rem !important;
}

.-mr-40 {
  margin-right: -10rem !important;
}

.-mb-40 {
  margin-bottom: -10rem !important;
}

.-ml-40 {
  margin-left: -10rem !important;
}

.-mt-48 {
  margin-top: -12rem !important;
}

.-mr-48 {
  margin-right: -12rem !important;
}

.-mb-48 {
  margin-bottom: -12rem !important;
}

.-ml-48 {
  margin-left: -12rem !important;
}

.-mt-56 {
  margin-top: -14rem !important;
}

.-mr-56 {
  margin-right: -14rem !important;
}

.-mb-56 {
  margin-bottom: -14rem !important;
}

.-ml-56 {
  margin-left: -14rem !important;
}

.-mt-64 {
  margin-top: -16rem !important;
}

.-mr-64 {
  margin-right: -16rem !important;
}

.-mb-64 {
  margin-bottom: -16rem !important;
}

.-ml-64 {
  margin-left: -16rem !important;
}

.-mt-px {
  margin-top: -1px !important;
}

.-mr-px {
  margin-right: -1px !important;
}

.-mb-px {
  margin-bottom: -1px !important;
}

.-ml-px {
  margin-left: -1px !important;
}

.-mt-2px {
  margin-top: -2px !important;
}

.-mr-2px {
  margin-right: -2px !important;
}

.-mb-2px {
  margin-bottom: -2px !important;
}

.-ml-2px {
  margin-left: -2px !important;
}

.max-h-0 {
  max-height: 0 !important;
}

.max-h-1 {
  max-height: 0.25rem !important;
}

.max-h-2 {
  max-height: 0.5rem !important;
}

.max-h-3 {
  max-height: 0.75rem !important;
}

.max-h-4 {
  max-height: 1rem !important;
}

.max-h-5 {
  max-height: 1.25rem !important;
}

.max-h-6 {
  max-height: 1.5rem !important;
}

.max-h-8 {
  max-height: 2rem !important;
}

.max-h-10 {
  max-height: 2.5rem !important;
}

.max-h-12 {
  max-height: 3rem !important;
}

.max-h-14 {
  max-height: 3.5rem !important;
}

.max-h-16 {
  max-height: 4rem !important;
}

.max-h-18 {
  max-height: 4.5rem !important;
}

.max-h-20 {
  max-height: 5rem !important;
}

.max-h-22 {
  max-height: 5.5rem !important;
}

.max-h-24 {
  max-height: 6rem !important;
}

.max-h-26 {
  max-height: 6.5rem !important;
}

.max-h-28 {
  max-height: 7rem !important;
}

.max-h-30 {
  max-height: 7.5rem !important;
}

.max-h-32 {
  max-height: 8rem !important;
}

.max-h-36 {
  max-height: 9rem !important;
}

.max-h-40 {
  max-height: 10rem !important;
}

.max-h-48 {
  max-height: 12rem !important;
}

.max-h-50 {
  max-height: 12.5rem !important;
}

.max-h-56 {
  max-height: 14rem !important;
}

.max-h-60 {
  max-height: 15rem !important;
}

.max-h-64 {
  max-height: 16rem !important;
}

.max-h-80 {
  max-height: 20rem !important;
}

.max-h-90 {
  max-height: 24rem !important;
}

.max-h-100 {
  max-height: 25rem !important;
}

.max-h-120 {
  max-height: 30rem !important;
}

.max-h-128 {
  max-height: 32rem !important;
}

.max-h-140 {
  max-height: 35rem !important;
}

.max-h-160 {
  max-height: 40rem !important;
}

.max-h-180 {
  max-height: 45rem !important;
}

.max-h-192 {
  max-height: 48rem !important;
}

.max-h-200 {
  max-height: 50rem !important;
}

.max-h-240 {
  max-height: 60rem !important;
}

.max-h-256 {
  max-height: 64rem !important;
}

.max-h-280 {
  max-height: 70rem !important;
}

.max-h-320 {
  max-height: 80rem !important;
}

.max-h-360 {
  max-height: 90rem !important;
}

.max-h-400 {
  max-height: 100rem !important;
}

.max-h-480 {
  max-height: 120rem !important;
}

.max-h-full {
  max-height: 100% !important;
}

.max-h-screen {
  max-height: 100vh !important;
}

.max-h-none {
  max-height: none !important;
}

.max-h-px {
  max-height: 1px !important;
}

.max-h-2px {
  max-height: 2px !important;
}

.max-h-1\/2 {
  max-height: 50% !important;
}

.max-h-1\/3 {
  max-height: 33.33333% !important;
}

.max-h-2\/3 {
  max-height: 66.66667% !important;
}

.max-h-1\/4 {
  max-height: 25% !important;
}

.max-h-2\/4 {
  max-height: 50% !important;
}

.max-h-3\/4 {
  max-height: 75% !important;
}

.max-h-1\/5 {
  max-height: 20% !important;
}

.max-h-2\/5 {
  max-height: 40% !important;
}

.max-h-3\/5 {
  max-height: 60% !important;
}

.max-h-4\/5 {
  max-height: 80% !important;
}

.max-h-1\/12 {
  max-height: 8.33333% !important;
}

.max-h-2\/12 {
  max-height: 16.66667% !important;
}

.max-h-3\/12 {
  max-height: 25% !important;
}

.max-h-4\/12 {
  max-height: 33.33333% !important;
}

.max-h-5\/12 {
  max-height: 41.66667% !important;
}

.max-h-6\/12 {
  max-height: 50% !important;
}

.max-h-7\/12 {
  max-height: 58.33333% !important;
}

.max-h-8\/12 {
  max-height: 66.66667% !important;
}

.max-h-9\/12 {
  max-height: 75% !important;
}

.max-h-10\/12 {
  max-height: 83.33333% !important;
}

.max-h-11\/12 {
  max-height: 91.66667% !important;
}

.max-w-0 {
  max-width: 0 !important;
}

.max-w-1 {
  max-width: 0.25rem !important;
}

.max-w-2 {
  max-width: 0.5rem !important;
}

.max-w-3 {
  max-width: 0.75rem !important;
}

.max-w-4 {
  max-width: 1rem !important;
}

.max-w-5 {
  max-width: 1.25rem !important;
}

.max-w-6 {
  max-width: 1.5rem !important;
}

.max-w-8 {
  max-width: 2rem !important;
}

.max-w-10 {
  max-width: 2.5rem !important;
}

.max-w-12 {
  max-width: 3rem !important;
}

.max-w-14 {
  max-width: 3.5rem !important;
}

.max-w-16 {
  max-width: 4rem !important;
}

.max-w-18 {
  max-width: 4.5rem !important;
}

.max-w-20 {
  max-width: 5rem !important;
}

.max-w-22 {
  max-width: 5.5rem !important;
}

.max-w-24 {
  max-width: 6rem !important;
}

.max-w-26 {
  max-width: 6.5rem !important;
}

.max-w-28 {
  max-width: 7rem !important;
}

.max-w-30 {
  max-width: 7.5rem !important;
}

.max-w-32 {
  max-width: 8rem !important;
}

.max-w-36 {
  max-width: 9rem !important;
}

.max-w-40 {
  max-width: 10rem !important;
}

.max-w-48 {
  max-width: 12rem !important;
}

.max-w-50 {
  max-width: 12.5rem !important;
}

.max-w-56 {
  max-width: 14rem !important;
}

.max-w-60 {
  max-width: 15rem !important;
}

.max-w-64 {
  max-width: 16rem !important;
}

.max-w-80 {
  max-width: 20rem !important;
}

.max-w-90 {
  max-width: 24rem !important;
}

.max-w-100 {
  max-width: 25rem !important;
}

.max-w-120 {
  max-width: 30rem !important;
}

.max-w-128 {
  max-width: 32rem !important;
}

.max-w-140 {
  max-width: 35rem !important;
}

.max-w-160 {
  max-width: 40rem !important;
}

.max-w-180 {
  max-width: 45rem !important;
}

.max-w-192 {
  max-width: 48rem !important;
}

.max-w-200 {
  max-width: 50rem !important;
}

.max-w-240 {
  max-width: 60rem !important;
}

.max-w-256 {
  max-width: 64rem !important;
}

.max-w-280 {
  max-width: 70rem !important;
}

.max-w-320 {
  max-width: 80rem !important;
}

.max-w-360 {
  max-width: 90rem !important;
}

.max-w-400 {
  max-width: 100rem !important;
}

.max-w-480 {
  max-width: 120rem !important;
}

.max-w-none {
  max-width: none !important;
}

.max-w-xs {
  max-width: 20rem !important;
}

.max-w-sm {
  max-width: 24rem !important;
}

.max-w-md {
  max-width: 28rem !important;
}

.max-w-lg {
  max-width: 32rem !important;
}

.max-w-xl {
  max-width: 36rem !important;
}

.max-w-2xl {
  max-width: 42rem !important;
}

.max-w-3xl {
  max-width: 48rem !important;
}

.max-w-4xl {
  max-width: 56rem !important;
}

.max-w-5xl {
  max-width: 64rem !important;
}

.max-w-6xl {
  max-width: 72rem !important;
}

.max-w-full {
  max-width: 100% !important;
}

.max-w-screen {
  max-width: 100vw !important;
}

.max-w-px {
  max-width: 1px !important;
}

.max-w-2px {
  max-width: 2px !important;
}

.max-w-1\/2 {
  max-width: 50% !important;
}

.max-w-1\/3 {
  max-width: 33.33333% !important;
}

.max-w-2\/3 {
  max-width: 66.66667% !important;
}

.max-w-1\/4 {
  max-width: 25% !important;
}

.max-w-2\/4 {
  max-width: 50% !important;
}

.max-w-3\/4 {
  max-width: 75% !important;
}

.max-w-1\/5 {
  max-width: 20% !important;
}

.max-w-2\/5 {
  max-width: 40% !important;
}

.max-w-3\/5 {
  max-width: 60% !important;
}

.max-w-4\/5 {
  max-width: 80% !important;
}

.max-w-1\/12 {
  max-width: 8.33333% !important;
}

.max-w-2\/12 {
  max-width: 16.66667% !important;
}

.max-w-3\/12 {
  max-width: 25% !important;
}

.max-w-4\/12 {
  max-width: 33.33333% !important;
}

.max-w-5\/12 {
  max-width: 41.66667% !important;
}

.max-w-6\/12 {
  max-width: 50% !important;
}

.max-w-7\/12 {
  max-width: 58.33333% !important;
}

.max-w-8\/12 {
  max-width: 66.66667% !important;
}

.max-w-9\/12 {
  max-width: 75% !important;
}

.max-w-10\/12 {
  max-width: 83.33333% !important;
}

.max-w-11\/12 {
  max-width: 91.66667% !important;
}

.min-h-0 {
  min-height: 0 !important;
}

.min-h-1 {
  min-height: 0.25rem !important;
}

.min-h-2 {
  min-height: 0.5rem !important;
}

.min-h-3 {
  min-height: 0.75rem !important;
}

.min-h-4 {
  min-height: 1rem !important;
}

.min-h-5 {
  min-height: 1.25rem !important;
}

.min-h-6 {
  min-height: 1.5rem !important;
}

.min-h-8 {
  min-height: 2rem !important;
}

.min-h-10 {
  min-height: 2.5rem !important;
}

.min-h-12 {
  min-height: 3rem !important;
}

.min-h-14 {
  min-height: 3.5rem !important;
}

.min-h-16 {
  min-height: 4rem !important;
}

.min-h-18 {
  min-height: 4.5rem !important;
}

.min-h-20 {
  min-height: 5rem !important;
}

.min-h-22 {
  min-height: 5.5rem !important;
}

.min-h-24 {
  min-height: 6rem !important;
}

.min-h-26 {
  min-height: 6.5rem !important;
}

.min-h-28 {
  min-height: 7rem !important;
}

.min-h-30 {
  min-height: 7.5rem !important;
}

.min-h-32 {
  min-height: 8rem !important;
}

.min-h-36 {
  min-height: 9rem !important;
}

.min-h-40 {
  min-height: 10rem !important;
}

.min-h-48 {
  min-height: 12rem !important;
}

.min-h-50 {
  min-height: 12.5rem !important;
}

.min-h-56 {
  min-height: 14rem !important;
}

.min-h-60 {
  min-height: 15rem !important;
}

.min-h-64 {
  min-height: 16rem !important;
}

.min-h-80 {
  min-height: 20rem !important;
}

.min-h-90 {
  min-height: 24rem !important;
}

.min-h-100 {
  min-height: 25rem !important;
}

.min-h-120 {
  min-height: 30rem !important;
}

.min-h-128 {
  min-height: 32rem !important;
}

.min-h-140 {
  min-height: 35rem !important;
}

.min-h-160 {
  min-height: 40rem !important;
}

.min-h-180 {
  min-height: 45rem !important;
}

.min-h-192 {
  min-height: 48rem !important;
}

.min-h-200 {
  min-height: 50rem !important;
}

.min-h-240 {
  min-height: 60rem !important;
}

.min-h-256 {
  min-height: 64rem !important;
}

.min-h-280 {
  min-height: 70rem !important;
}

.min-h-320 {
  min-height: 80rem !important;
}

.min-h-360 {
  min-height: 90rem !important;
}

.min-h-400 {
  min-height: 100rem !important;
}

.min-h-480 {
  min-height: 120rem !important;
}

.min-h-full {
  min-height: 100% !important;
}

.min-h-screen {
  min-height: 100vh !important;
}

.min-h-px {
  min-height: 1px !important;
}

.min-h-2px {
  min-height: 2px !important;
}

.min-h-1\/2 {
  min-height: 50% !important;
}

.min-h-1\/3 {
  min-height: 33.33333% !important;
}

.min-h-2\/3 {
  min-height: 66.66667% !important;
}

.min-h-1\/4 {
  min-height: 25% !important;
}

.min-h-2\/4 {
  min-height: 50% !important;
}

.min-h-3\/4 {
  min-height: 75% !important;
}

.min-h-1\/5 {
  min-height: 20% !important;
}

.min-h-2\/5 {
  min-height: 40% !important;
}

.min-h-3\/5 {
  min-height: 60% !important;
}

.min-h-4\/5 {
  min-height: 80% !important;
}

.min-h-1\/12 {
  min-height: 8.33333% !important;
}

.min-h-2\/12 {
  min-height: 16.66667% !important;
}

.min-h-3\/12 {
  min-height: 25% !important;
}

.min-h-4\/12 {
  min-height: 33.33333% !important;
}

.min-h-5\/12 {
  min-height: 41.66667% !important;
}

.min-h-6\/12 {
  min-height: 50% !important;
}

.min-h-7\/12 {
  min-height: 58.33333% !important;
}

.min-h-8\/12 {
  min-height: 66.66667% !important;
}

.min-h-9\/12 {
  min-height: 75% !important;
}

.min-h-10\/12 {
  min-height: 83.33333% !important;
}

.min-h-11\/12 {
  min-height: 91.66667% !important;
}

.min-w-0 {
  min-width: 0 !important;
}

.min-w-1 {
  min-width: 0.25rem !important;
}

.min-w-2 {
  min-width: 0.5rem !important;
}

.min-w-3 {
  min-width: 0.75rem !important;
}

.min-w-4 {
  min-width: 1rem !important;
}

.min-w-5 {
  min-width: 1.25rem !important;
}

.min-w-6 {
  min-width: 1.5rem !important;
}

.min-w-8 {
  min-width: 2rem !important;
}

.min-w-10 {
  min-width: 2.5rem !important;
}

.min-w-12 {
  min-width: 3rem !important;
}

.min-w-14 {
  min-width: 3.5rem !important;
}

.min-w-16 {
  min-width: 4rem !important;
}

.min-w-18 {
  min-width: 4.5rem !important;
}

.min-w-20 {
  min-width: 5rem !important;
}

.min-w-22 {
  min-width: 5.5rem !important;
}

.min-w-24 {
  min-width: 6rem !important;
}

.min-w-26 {
  min-width: 6.5rem !important;
}

.min-w-28 {
  min-width: 7rem !important;
}

.min-w-30 {
  min-width: 7.5rem !important;
}

.min-w-32 {
  min-width: 8rem !important;
}

.min-w-36 {
  min-width: 9rem !important;
}

.min-w-40 {
  min-width: 10rem !important;
}

.min-w-48 {
  min-width: 12rem !important;
}

.min-w-50 {
  min-width: 12.5rem !important;
}

.min-w-56 {
  min-width: 14rem !important;
}

.min-w-60 {
  min-width: 15rem !important;
}

.min-w-64 {
  min-width: 16rem !important;
}

.min-w-80 {
  min-width: 20rem !important;
}

.min-w-90 {
  min-width: 24rem !important;
}

.min-w-100 {
  min-width: 25rem !important;
}

.min-w-120 {
  min-width: 30rem !important;
}

.min-w-128 {
  min-width: 32rem !important;
}

.min-w-140 {
  min-width: 35rem !important;
}

.min-w-160 {
  min-width: 40rem !important;
}

.min-w-180 {
  min-width: 45rem !important;
}

.min-w-192 {
  min-width: 48rem !important;
}

.min-w-200 {
  min-width: 50rem !important;
}

.min-w-240 {
  min-width: 60rem !important;
}

.min-w-256 {
  min-width: 64rem !important;
}

.min-w-280 {
  min-width: 70rem !important;
}

.min-w-320 {
  min-width: 80rem !important;
}

.min-w-360 {
  min-width: 90rem !important;
}

.min-w-400 {
  min-width: 100rem !important;
}

.min-w-480 {
  min-width: 120rem !important;
}

.min-w-full {
  min-width: 100% !important;
}

.min-w-screen {
  min-width: 100vw !important;
}

.min-w-px {
  min-width: 1px !important;
}

.min-w-2px {
  min-width: 2px !important;
}

.min-w-1\/2 {
  min-width: 50% !important;
}

.min-w-1\/3 {
  min-width: 33.33333% !important;
}

.min-w-2\/3 {
  min-width: 66.66667% !important;
}

.min-w-1\/4 {
  min-width: 25% !important;
}

.min-w-2\/4 {
  min-width: 50% !important;
}

.min-w-3\/4 {
  min-width: 75% !important;
}

.min-w-1\/5 {
  min-width: 20% !important;
}

.min-w-2\/5 {
  min-width: 40% !important;
}

.min-w-3\/5 {
  min-width: 60% !important;
}

.min-w-4\/5 {
  min-width: 80% !important;
}

.min-w-1\/12 {
  min-width: 8.33333% !important;
}

.min-w-2\/12 {
  min-width: 16.66667% !important;
}

.min-w-3\/12 {
  min-width: 25% !important;
}

.min-w-4\/12 {
  min-width: 33.33333% !important;
}

.min-w-5\/12 {
  min-width: 41.66667% !important;
}

.min-w-6\/12 {
  min-width: 50% !important;
}

.min-w-7\/12 {
  min-width: 58.33333% !important;
}

.min-w-8\/12 {
  min-width: 66.66667% !important;
}

.min-w-9\/12 {
  min-width: 75% !important;
}

.min-w-10\/12 {
  min-width: 83.33333% !important;
}

.min-w-11\/12 {
  min-width: 91.66667% !important;
}

.object-contain {
  -o-object-fit: contain !important;
     object-fit: contain !important;
}

.object-cover {
  -o-object-fit: cover !important;
     object-fit: cover !important;
}

.object-fill {
  -o-object-fit: fill !important;
     object-fit: fill !important;
}

.object-none {
  -o-object-fit: none !important;
     object-fit: none !important;
}

.object-scale-down {
  -o-object-fit: scale-down !important;
     object-fit: scale-down !important;
}

.object-bottom {
  -o-object-position: bottom !important;
     object-position: bottom !important;
}

.object-center {
  -o-object-position: center !important;
     object-position: center !important;
}

.object-left {
  -o-object-position: left !important;
     object-position: left !important;
}

.object-left-bottom {
  -o-object-position: left bottom !important;
     object-position: left bottom !important;
}

.object-left-top {
  -o-object-position: left top !important;
     object-position: left top !important;
}

.object-right {
  -o-object-position: right !important;
     object-position: right !important;
}

.object-right-bottom {
  -o-object-position: right bottom !important;
     object-position: right bottom !important;
}

.object-right-top {
  -o-object-position: right top !important;
     object-position: right top !important;
}

.object-top {
  -o-object-position: top !important;
     object-position: top !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-12 {
  opacity: 0.12 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-38 {
  opacity: 0.38 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-54 {
  opacity: 0.54 !important;
}

.opacity-70 {
  opacity: 0.70 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-84 {
  opacity: 0.84 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.hover\:opacity-0:hover {
  opacity: 0 !important;
}

.hover\:opacity-12:hover {
  opacity: 0.12 !important;
}

.hover\:opacity-25:hover {
  opacity: 0.25 !important;
}

.hover\:opacity-38:hover {
  opacity: 0.38 !important;
}

.hover\:opacity-50:hover {
  opacity: 0.5 !important;
}

.hover\:opacity-54:hover {
  opacity: 0.54 !important;
}

.hover\:opacity-70:hover {
  opacity: 0.70 !important;
}

.hover\:opacity-75:hover {
  opacity: 0.75 !important;
}

.hover\:opacity-84:hover {
  opacity: 0.84 !important;
}

.hover\:opacity-100:hover {
  opacity: 1 !important;
}

.focus\:opacity-0:focus {
  opacity: 0 !important;
}

.focus\:opacity-12:focus {
  opacity: 0.12 !important;
}

.focus\:opacity-25:focus {
  opacity: 0.25 !important;
}

.focus\:opacity-38:focus {
  opacity: 0.38 !important;
}

.focus\:opacity-50:focus {
  opacity: 0.5 !important;
}

.focus\:opacity-54:focus {
  opacity: 0.54 !important;
}

.focus\:opacity-70:focus {
  opacity: 0.70 !important;
}

.focus\:opacity-75:focus {
  opacity: 0.75 !important;
}

.focus\:opacity-84:focus {
  opacity: 0.84 !important;
}

.focus\:opacity-100:focus {
  opacity: 1 !important;
}

.outline-none {
  outline: 0 !important;
}

.focus\:outline-none:focus {
  outline: 0 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.scrolling-touch {
  -webkit-overflow-scrolling: touch !important;
}

.scrolling-auto {
  -webkit-overflow-scrolling: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 0.75rem !important;
}

.p-4 {
  padding: 1rem !important;
}

.p-5 {
  padding: 1.25rem !important;
}

.p-6 {
  padding: 1.5rem !important;
}

.p-8 {
  padding: 2rem !important;
}

.p-10 {
  padding: 2.5rem !important;
}

.p-12 {
  padding: 3rem !important;
}

.p-14 {
  padding: 3.5rem !important;
}

.p-16 {
  padding: 4rem !important;
}

.p-18 {
  padding: 4.5rem !important;
}

.p-20 {
  padding: 5rem !important;
}

.p-22 {
  padding: 5.5rem !important;
}

.p-24 {
  padding: 6rem !important;
}

.p-26 {
  padding: 6.5rem !important;
}

.p-28 {
  padding: 7rem !important;
}

.p-30 {
  padding: 7.5rem !important;
}

.p-32 {
  padding: 8rem !important;
}

.p-36 {
  padding: 9rem !important;
}

.p-40 {
  padding: 10rem !important;
}

.p-48 {
  padding: 12rem !important;
}

.p-56 {
  padding: 14rem !important;
}

.p-64 {
  padding: 16rem !important;
}

.p-px {
  padding: 1px !important;
}

.p-2px {
  padding: 2px !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.px-1 {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.px-2 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.py-3 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.px-3 {
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
}

.py-4 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.px-4 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.py-5 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}

.px-5 {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
}

.py-6 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.px-6 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.py-8 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.px-8 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.py-10 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.px-10 {
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
}

.py-12 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.px-12 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.py-14 {
  padding-top: 3.5rem !important;
  padding-bottom: 3.5rem !important;
}

.px-14 {
  padding-left: 3.5rem !important;
  padding-right: 3.5rem !important;
}

.py-16 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.px-16 {
  padding-left: 4rem !important;
  padding-right: 4rem !important;
}

.py-18 {
  padding-top: 4.5rem !important;
  padding-bottom: 4.5rem !important;
}

.px-18 {
  padding-left: 4.5rem !important;
  padding-right: 4.5rem !important;
}

.py-20 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.px-20 {
  padding-left: 5rem !important;
  padding-right: 5rem !important;
}

.py-22 {
  padding-top: 5.5rem !important;
  padding-bottom: 5.5rem !important;
}

.px-22 {
  padding-left: 5.5rem !important;
  padding-right: 5.5rem !important;
}

.py-24 {
  padding-top: 6rem !important;
  padding-bottom: 6rem !important;
}

.px-24 {
  padding-left: 6rem !important;
  padding-right: 6rem !important;
}

.py-26 {
  padding-top: 6.5rem !important;
  padding-bottom: 6.5rem !important;
}

.px-26 {
  padding-left: 6.5rem !important;
  padding-right: 6.5rem !important;
}

.py-28 {
  padding-top: 7rem !important;
  padding-bottom: 7rem !important;
}

.px-28 {
  padding-left: 7rem !important;
  padding-right: 7rem !important;
}

.py-30 {
  padding-top: 7.5rem !important;
  padding-bottom: 7.5rem !important;
}

.px-30 {
  padding-left: 7.5rem !important;
  padding-right: 7.5rem !important;
}

.py-32 {
  padding-top: 8rem !important;
  padding-bottom: 8rem !important;
}

.px-32 {
  padding-left: 8rem !important;
  padding-right: 8rem !important;
}

.py-36 {
  padding-top: 9rem !important;
  padding-bottom: 9rem !important;
}

.px-36 {
  padding-left: 9rem !important;
  padding-right: 9rem !important;
}

.py-40 {
  padding-top: 10rem !important;
  padding-bottom: 10rem !important;
}

.px-40 {
  padding-left: 10rem !important;
  padding-right: 10rem !important;
}

.py-48 {
  padding-top: 12rem !important;
  padding-bottom: 12rem !important;
}

.px-48 {
  padding-left: 12rem !important;
  padding-right: 12rem !important;
}

.py-56 {
  padding-top: 14rem !important;
  padding-bottom: 14rem !important;
}

.px-56 {
  padding-left: 14rem !important;
  padding-right: 14rem !important;
}

.py-64 {
  padding-top: 16rem !important;
  padding-bottom: 16rem !important;
}

.px-64 {
  padding-left: 16rem !important;
  padding-right: 16rem !important;
}

.py-px {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}

.px-px {
  padding-left: 1px !important;
  padding-right: 1px !important;
}

.py-2px {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.px-2px {
  padding-left: 2px !important;
  padding-right: 2px !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pr-1 {
  padding-right: 0.25rem !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1 {
  padding-left: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pr-2 {
  padding-right: 0.5rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2 {
  padding-left: 0.5rem !important;
}

.pt-3 {
  padding-top: 0.75rem !important;
}

.pr-3 {
  padding-right: 0.75rem !important;
}

.pb-3 {
  padding-bottom: 0.75rem !important;
}

.pl-3 {
  padding-left: 0.75rem !important;
}

.pt-4 {
  padding-top: 1rem !important;
}

.pr-4 {
  padding-right: 1rem !important;
}

.pb-4 {
  padding-bottom: 1rem !important;
}

.pl-4 {
  padding-left: 1rem !important;
}

.pt-5 {
  padding-top: 1.25rem !important;
}

.pr-5 {
  padding-right: 1.25rem !important;
}

.pb-5 {
  padding-bottom: 1.25rem !important;
}

.pl-5 {
  padding-left: 1.25rem !important;
}

.pt-6 {
  padding-top: 1.5rem !important;
}

.pr-6 {
  padding-right: 1.5rem !important;
}

.pb-6 {
  padding-bottom: 1.5rem !important;
}

.pl-6 {
  padding-left: 1.5rem !important;
}

.pt-8 {
  padding-top: 2rem !important;
}

.pr-8 {
  padding-right: 2rem !important;
}

.pb-8 {
  padding-bottom: 2rem !important;
}

.pl-8 {
  padding-left: 2rem !important;
}

.pt-10 {
  padding-top: 2.5rem !important;
}

.pr-10 {
  padding-right: 2.5rem !important;
}

.pb-10 {
  padding-bottom: 2.5rem !important;
}

.pl-10 {
  padding-left: 2.5rem !important;
}

.pt-12 {
  padding-top: 3rem !important;
}

.pr-12 {
  padding-right: 3rem !important;
}

.pb-12 {
  padding-bottom: 3rem !important;
}

.pl-12 {
  padding-left: 3rem !important;
}

.pt-14 {
  padding-top: 3.5rem !important;
}

.pr-14 {
  padding-right: 3.5rem !important;
}

.pb-14 {
  padding-bottom: 3.5rem !important;
}

.pl-14 {
  padding-left: 3.5rem !important;
}

.pt-16 {
  padding-top: 4rem !important;
}

.pr-16 {
  padding-right: 4rem !important;
}

.pb-16 {
  padding-bottom: 4rem !important;
}

.pl-16 {
  padding-left: 4rem !important;
}

.pt-18 {
  padding-top: 4.5rem !important;
}

.pr-18 {
  padding-right: 4.5rem !important;
}

.pb-18 {
  padding-bottom: 4.5rem !important;
}

.pl-18 {
  padding-left: 4.5rem !important;
}

.pt-20 {
  padding-top: 5rem !important;
}

.pr-20 {
  padding-right: 5rem !important;
}

.pb-20 {
  padding-bottom: 5rem !important;
}

.pl-20 {
  padding-left: 5rem !important;
}

.pt-22 {
  padding-top: 5.5rem !important;
}

.pr-22 {
  padding-right: 5.5rem !important;
}

.pb-22 {
  padding-bottom: 5.5rem !important;
}

.pl-22 {
  padding-left: 5.5rem !important;
}

.pt-24 {
  padding-top: 6rem !important;
}

.pr-24 {
  padding-right: 6rem !important;
}

.pb-24 {
  padding-bottom: 6rem !important;
}

.pl-24 {
  padding-left: 6rem !important;
}

.pt-26 {
  padding-top: 6.5rem !important;
}

.pr-26 {
  padding-right: 6.5rem !important;
}

.pb-26 {
  padding-bottom: 6.5rem !important;
}

.pl-26 {
  padding-left: 6.5rem !important;
}

.pt-28 {
  padding-top: 7rem !important;
}

.pr-28 {
  padding-right: 7rem !important;
}

.pb-28 {
  padding-bottom: 7rem !important;
}

.pl-28 {
  padding-left: 7rem !important;
}

.pt-30 {
  padding-top: 7.5rem !important;
}

.pr-30 {
  padding-right: 7.5rem !important;
}

.pb-30 {
  padding-bottom: 7.5rem !important;
}

.pl-30 {
  padding-left: 7.5rem !important;
}

.pt-32 {
  padding-top: 8rem !important;
}

.pr-32 {
  padding-right: 8rem !important;
}

.pb-32 {
  padding-bottom: 8rem !important;
}

.pl-32 {
  padding-left: 8rem !important;
}

.pt-36 {
  padding-top: 9rem !important;
}

.pr-36 {
  padding-right: 9rem !important;
}

.pb-36 {
  padding-bottom: 9rem !important;
}

.pl-36 {
  padding-left: 9rem !important;
}

.pt-40 {
  padding-top: 10rem !important;
}

.pr-40 {
  padding-right: 10rem !important;
}

.pb-40 {
  padding-bottom: 10rem !important;
}

.pl-40 {
  padding-left: 10rem !important;
}

.pt-48 {
  padding-top: 12rem !important;
}

.pr-48 {
  padding-right: 12rem !important;
}

.pb-48 {
  padding-bottom: 12rem !important;
}

.pl-48 {
  padding-left: 12rem !important;
}

.pt-56 {
  padding-top: 14rem !important;
}

.pr-56 {
  padding-right: 14rem !important;
}

.pb-56 {
  padding-bottom: 14rem !important;
}

.pl-56 {
  padding-left: 14rem !important;
}

.pt-64 {
  padding-top: 16rem !important;
}

.pr-64 {
  padding-right: 16rem !important;
}

.pb-64 {
  padding-bottom: 16rem !important;
}

.pl-64 {
  padding-left: 16rem !important;
}

.pt-px {
  padding-top: 1px !important;
}

.pr-px {
  padding-right: 1px !important;
}

.pb-px {
  padding-bottom: 1px !important;
}

.pl-px {
  padding-left: 1px !important;
}

.pt-2px {
  padding-top: 2px !important;
}

.pr-2px {
  padding-right: 2px !important;
}

.pb-2px {
  padding-bottom: 2px !important;
}

.pl-2px {
  padding-left: 2px !important;
}

.placeholder-opacity-0::-webkit-input-placeholder {
  --placeholder-opacity: 0 !important;
}

.placeholder-opacity-0::-moz-placeholder {
  --placeholder-opacity: 0 !important;
}

.placeholder-opacity-0::-ms-input-placeholder {
  --placeholder-opacity: 0 !important;
}

.placeholder-opacity-0::placeholder {
  --placeholder-opacity: 0 !important;
}

.placeholder-opacity-12::-webkit-input-placeholder {
  --placeholder-opacity: 0.12 !important;
}

.placeholder-opacity-12::-moz-placeholder {
  --placeholder-opacity: 0.12 !important;
}

.placeholder-opacity-12::-ms-input-placeholder {
  --placeholder-opacity: 0.12 !important;
}

.placeholder-opacity-12::placeholder {
  --placeholder-opacity: 0.12 !important;
}

.placeholder-opacity-25::-webkit-input-placeholder {
  --placeholder-opacity: 0.25 !important;
}

.placeholder-opacity-25::-moz-placeholder {
  --placeholder-opacity: 0.25 !important;
}

.placeholder-opacity-25::-ms-input-placeholder {
  --placeholder-opacity: 0.25 !important;
}

.placeholder-opacity-25::placeholder {
  --placeholder-opacity: 0.25 !important;
}

.placeholder-opacity-38::-webkit-input-placeholder {
  --placeholder-opacity: 0.38 !important;
}

.placeholder-opacity-38::-moz-placeholder {
  --placeholder-opacity: 0.38 !important;
}

.placeholder-opacity-38::-ms-input-placeholder {
  --placeholder-opacity: 0.38 !important;
}

.placeholder-opacity-38::placeholder {
  --placeholder-opacity: 0.38 !important;
}

.placeholder-opacity-50::-webkit-input-placeholder {
  --placeholder-opacity: 0.5 !important;
}

.placeholder-opacity-50::-moz-placeholder {
  --placeholder-opacity: 0.5 !important;
}

.placeholder-opacity-50::-ms-input-placeholder {
  --placeholder-opacity: 0.5 !important;
}

.placeholder-opacity-50::placeholder {
  --placeholder-opacity: 0.5 !important;
}

.placeholder-opacity-54::-webkit-input-placeholder {
  --placeholder-opacity: 0.54 !important;
}

.placeholder-opacity-54::-moz-placeholder {
  --placeholder-opacity: 0.54 !important;
}

.placeholder-opacity-54::-ms-input-placeholder {
  --placeholder-opacity: 0.54 !important;
}

.placeholder-opacity-54::placeholder {
  --placeholder-opacity: 0.54 !important;
}

.placeholder-opacity-70::-webkit-input-placeholder {
  --placeholder-opacity: 0.70 !important;
}

.placeholder-opacity-70::-moz-placeholder {
  --placeholder-opacity: 0.70 !important;
}

.placeholder-opacity-70::-ms-input-placeholder {
  --placeholder-opacity: 0.70 !important;
}

.placeholder-opacity-70::placeholder {
  --placeholder-opacity: 0.70 !important;
}

.placeholder-opacity-75::-webkit-input-placeholder {
  --placeholder-opacity: 0.75 !important;
}

.placeholder-opacity-75::-moz-placeholder {
  --placeholder-opacity: 0.75 !important;
}

.placeholder-opacity-75::-ms-input-placeholder {
  --placeholder-opacity: 0.75 !important;
}

.placeholder-opacity-75::placeholder {
  --placeholder-opacity: 0.75 !important;
}

.placeholder-opacity-84::-webkit-input-placeholder {
  --placeholder-opacity: 0.84 !important;
}

.placeholder-opacity-84::-moz-placeholder {
  --placeholder-opacity: 0.84 !important;
}

.placeholder-opacity-84::-ms-input-placeholder {
  --placeholder-opacity: 0.84 !important;
}

.placeholder-opacity-84::placeholder {
  --placeholder-opacity: 0.84 !important;
}

.placeholder-opacity-100::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
}

.placeholder-opacity-100::-moz-placeholder {
  --placeholder-opacity: 1 !important;
}

.placeholder-opacity-100::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
}

.placeholder-opacity-100::placeholder {
  --placeholder-opacity: 1 !important;
}

.focus\:placeholder-opacity-0:focus::-webkit-input-placeholder {
  --placeholder-opacity: 0 !important;
}

.focus\:placeholder-opacity-0:focus::-moz-placeholder {
  --placeholder-opacity: 0 !important;
}

.focus\:placeholder-opacity-0:focus::-ms-input-placeholder {
  --placeholder-opacity: 0 !important;
}

.focus\:placeholder-opacity-0:focus::placeholder {
  --placeholder-opacity: 0 !important;
}

.focus\:placeholder-opacity-12:focus::-webkit-input-placeholder {
  --placeholder-opacity: 0.12 !important;
}

.focus\:placeholder-opacity-12:focus::-moz-placeholder {
  --placeholder-opacity: 0.12 !important;
}

.focus\:placeholder-opacity-12:focus::-ms-input-placeholder {
  --placeholder-opacity: 0.12 !important;
}

.focus\:placeholder-opacity-12:focus::placeholder {
  --placeholder-opacity: 0.12 !important;
}

.focus\:placeholder-opacity-25:focus::-webkit-input-placeholder {
  --placeholder-opacity: 0.25 !important;
}

.focus\:placeholder-opacity-25:focus::-moz-placeholder {
  --placeholder-opacity: 0.25 !important;
}

.focus\:placeholder-opacity-25:focus::-ms-input-placeholder {
  --placeholder-opacity: 0.25 !important;
}

.focus\:placeholder-opacity-25:focus::placeholder {
  --placeholder-opacity: 0.25 !important;
}

.focus\:placeholder-opacity-38:focus::-webkit-input-placeholder {
  --placeholder-opacity: 0.38 !important;
}

.focus\:placeholder-opacity-38:focus::-moz-placeholder {
  --placeholder-opacity: 0.38 !important;
}

.focus\:placeholder-opacity-38:focus::-ms-input-placeholder {
  --placeholder-opacity: 0.38 !important;
}

.focus\:placeholder-opacity-38:focus::placeholder {
  --placeholder-opacity: 0.38 !important;
}

.focus\:placeholder-opacity-50:focus::-webkit-input-placeholder {
  --placeholder-opacity: 0.5 !important;
}

.focus\:placeholder-opacity-50:focus::-moz-placeholder {
  --placeholder-opacity: 0.5 !important;
}

.focus\:placeholder-opacity-50:focus::-ms-input-placeholder {
  --placeholder-opacity: 0.5 !important;
}

.focus\:placeholder-opacity-50:focus::placeholder {
  --placeholder-opacity: 0.5 !important;
}

.focus\:placeholder-opacity-54:focus::-webkit-input-placeholder {
  --placeholder-opacity: 0.54 !important;
}

.focus\:placeholder-opacity-54:focus::-moz-placeholder {
  --placeholder-opacity: 0.54 !important;
}

.focus\:placeholder-opacity-54:focus::-ms-input-placeholder {
  --placeholder-opacity: 0.54 !important;
}

.focus\:placeholder-opacity-54:focus::placeholder {
  --placeholder-opacity: 0.54 !important;
}

.focus\:placeholder-opacity-70:focus::-webkit-input-placeholder {
  --placeholder-opacity: 0.70 !important;
}

.focus\:placeholder-opacity-70:focus::-moz-placeholder {
  --placeholder-opacity: 0.70 !important;
}

.focus\:placeholder-opacity-70:focus::-ms-input-placeholder {
  --placeholder-opacity: 0.70 !important;
}

.focus\:placeholder-opacity-70:focus::placeholder {
  --placeholder-opacity: 0.70 !important;
}

.focus\:placeholder-opacity-75:focus::-webkit-input-placeholder {
  --placeholder-opacity: 0.75 !important;
}

.focus\:placeholder-opacity-75:focus::-moz-placeholder {
  --placeholder-opacity: 0.75 !important;
}

.focus\:placeholder-opacity-75:focus::-ms-input-placeholder {
  --placeholder-opacity: 0.75 !important;
}

.focus\:placeholder-opacity-75:focus::placeholder {
  --placeholder-opacity: 0.75 !important;
}

.focus\:placeholder-opacity-84:focus::-webkit-input-placeholder {
  --placeholder-opacity: 0.84 !important;
}

.focus\:placeholder-opacity-84:focus::-moz-placeholder {
  --placeholder-opacity: 0.84 !important;
}

.focus\:placeholder-opacity-84:focus::-ms-input-placeholder {
  --placeholder-opacity: 0.84 !important;
}

.focus\:placeholder-opacity-84:focus::placeholder {
  --placeholder-opacity: 0.84 !important;
}

.focus\:placeholder-opacity-100:focus::-webkit-input-placeholder {
  --placeholder-opacity: 1 !important;
}

.focus\:placeholder-opacity-100:focus::-moz-placeholder {
  --placeholder-opacity: 1 !important;
}

.focus\:placeholder-opacity-100:focus::-ms-input-placeholder {
  --placeholder-opacity: 1 !important;
}

.focus\:placeholder-opacity-100:focus::placeholder {
  --placeholder-opacity: 1 !important;
}

.pointer-events-none {
  pointer-events: none !important;
}

.pointer-events-auto {
  pointer-events: auto !important;
}

.static {
  position: static !important;
}

.fixed {
  position: fixed !important;
}

.absolute {
  position: absolute !important;
}

.relative {
  position: relative !important;
}

.sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.inset-0 {
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
}

.inset-auto {
  top: auto !important;
  right: auto !important;
  bottom: auto !important;
  left: auto !important;
}

.inset-y-0 {
  top: 0 !important;
  bottom: 0 !important;
}

.inset-x-0 {
  right: 0 !important;
  left: 0 !important;
}

.inset-y-auto {
  top: auto !important;
  bottom: auto !important;
}

.inset-x-auto {
  right: auto !important;
  left: auto !important;
}

.top-0 {
  top: 0 !important;
}

.right-0 {
  right: 0 !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.left-0 {
  left: 0 !important;
}

.top-auto {
  top: auto !important;
}

.right-auto {
  right: auto !important;
}

.bottom-auto {
  bottom: auto !important;
}

.left-auto {
  left: auto !important;
}

.resize-none {
  resize: none !important;
}

.resize-y {
  resize: vertical !important;
}

.resize-x {
  resize: horizontal !important;
}

.resize {
  resize: both !important;
}

.shadow-xs {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05) !important;
}

.shadow-sm {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
}

.shadow {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
}

.shadow-md {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
}

.shadow-lg {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
}

.shadow-xl {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
}

.shadow-2xl {
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
}

.shadow-inner {
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
}

.ring {
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.shadow-solid {
  box-shadow: 0 0 0 2px currentColor !important;
}

.hover\:shadow-xs:hover {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05) !important;
}

.hover\:shadow-sm:hover {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
}

.hover\:shadow:hover {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
}

.hover\:shadow-md:hover {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
}

.hover\:shadow-lg:hover {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
}

.hover\:shadow-xl:hover {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
}

.hover\:shadow-2xl:hover {
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
}

.hover\:shadow-inner:hover {
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
}

.hover\:ring:hover {
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
}

.hover\:shadow-none:hover {
  box-shadow: none !important;
}

.hover\:shadow-solid:hover {
  box-shadow: 0 0 0 2px currentColor !important;
}

.focus\:shadow-xs:focus {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05) !important;
}

.focus\:shadow-sm:focus {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
}

.focus\:shadow:focus {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
}

.focus\:shadow-md:focus {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
}

.focus\:shadow-lg:focus {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
}

.focus\:shadow-xl:focus {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
}

.focus\:shadow-2xl:focus {
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
}

.focus\:shadow-inner:focus {
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
}

.focus\:ring:focus {
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
}

.focus\:shadow-none:focus {
  box-shadow: none !important;
}

.focus\:shadow-solid:focus {
  box-shadow: 0 0 0 2px currentColor !important;
}

.fill-current {
  fill: currentColor !important;
}

.stroke-current {
  stroke: currentColor !important;
}

.stroke-0 {
  stroke-width: 0 !important;
}

.stroke-1 {
  stroke-width: 1 !important;
}

.stroke-2 {
  stroke-width: 2 !important;
}

.table-auto {
  table-layout: auto !important;
}

.table-fixed {
  table-layout: fixed !important;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-justify {
  text-align: justify !important;
}

.text-current {
  color: currentColor !important;
}

.text-transparent {
  color: transparent !important;
}

.text-white {
  --text-opacity: 1 !important;
  color: #FFFFFF !important;
  color: rgba(255, 255, 255, var(--text-opacity)) !important;
}

.text-black {
  --text-opacity: 1 !important;
  color: #000000 !important;
  color: rgba(0, 0, 0, var(--text-opacity)) !important;
}

.text-gray-50 {
  --text-opacity: 1 !important;
  color: #F9FAFB !important;
  color: rgba(249, 250, 251, var(--text-opacity)) !important;
}

.text-gray-100 {
  --text-opacity: 1 !important;
  color: #F4F5F7 !important;
  color: rgba(244, 245, 247, var(--text-opacity)) !important;
}

.text-gray-200 {
  --text-opacity: 1 !important;
  color: #E5E7EB !important;
  color: rgba(229, 231, 235, var(--text-opacity)) !important;
}

.text-gray-300 {
  --text-opacity: 1 !important;
  color: #D2D6DC !important;
  color: rgba(210, 214, 220, var(--text-opacity)) !important;
}

.text-gray-400 {
  --text-opacity: 1 !important;
  color: #9FA6B2 !important;
  color: rgba(159, 166, 178, var(--text-opacity)) !important;
}

.text-gray-500 {
  --text-opacity: 1 !important;
  color: #6B7280 !important;
  color: rgba(107, 114, 128, var(--text-opacity)) !important;
}

.text-gray-600 {
  --text-opacity: 1 !important;
  color: #4B5563 !important;
  color: rgba(75, 85, 99, var(--text-opacity)) !important;
}

.text-gray-700 {
  --text-opacity: 1 !important;
  color: #374151 !important;
  color: rgba(55, 65, 81, var(--text-opacity)) !important;
}

.text-gray-800 {
  --text-opacity: 1 !important;
  color: #252F3F !important;
  color: rgba(37, 47, 63, var(--text-opacity)) !important;
}

.text-gray-900 {
  --text-opacity: 1 !important;
  color: #161E2E !important;
  color: rgba(22, 30, 46, var(--text-opacity)) !important;
}

.text-gray {
  --text-opacity: 1 !important;
  color: #6B7280 !important;
  color: rgba(107, 114, 128, var(--text-opacity)) !important;
}

.text-cool-gray-50 {
  --text-opacity: 1 !important;
  color: #FBFDFE !important;
  color: rgba(251, 253, 254, var(--text-opacity)) !important;
}

.text-cool-gray-100 {
  --text-opacity: 1 !important;
  color: #F1F5F9 !important;
  color: rgba(241, 245, 249, var(--text-opacity)) !important;
}

.text-cool-gray-200 {
  --text-opacity: 1 !important;
  color: #E2E8F0 !important;
  color: rgba(226, 232, 240, var(--text-opacity)) !important;
}

.text-cool-gray-300 {
  --text-opacity: 1 !important;
  color: #CFD8E3 !important;
  color: rgba(207, 216, 227, var(--text-opacity)) !important;
}

.text-cool-gray-400 {
  --text-opacity: 1 !important;
  color: #97A6BA !important;
  color: rgba(151, 166, 186, var(--text-opacity)) !important;
}

.text-cool-gray-500 {
  --text-opacity: 1 !important;
  color: #64748B !important;
  color: rgba(100, 116, 139, var(--text-opacity)) !important;
}

.text-cool-gray-600 {
  --text-opacity: 1 !important;
  color: #475569 !important;
  color: rgba(71, 85, 105, var(--text-opacity)) !important;
}

.text-cool-gray-700 {
  --text-opacity: 1 !important;
  color: #364152 !important;
  color: rgba(54, 65, 82, var(--text-opacity)) !important;
}

.text-cool-gray-800 {
  --text-opacity: 1 !important;
  color: #27303F !important;
  color: rgba(39, 48, 63, var(--text-opacity)) !important;
}

.text-cool-gray-900 {
  --text-opacity: 1 !important;
  color: #1A202E !important;
  color: rgba(26, 32, 46, var(--text-opacity)) !important;
}

.text-cool-gray {
  --text-opacity: 1 !important;
  color: #64748B !important;
  color: rgba(100, 116, 139, var(--text-opacity)) !important;
}

.text-red-50 {
  --text-opacity: 1 !important;
  color: #FDF2F2 !important;
  color: rgba(253, 242, 242, var(--text-opacity)) !important;
}

.text-red-100 {
  --text-opacity: 1 !important;
  color: #FDE8E8 !important;
  color: rgba(253, 232, 232, var(--text-opacity)) !important;
}

.text-red-200 {
  --text-opacity: 1 !important;
  color: #FBD5D5 !important;
  color: rgba(251, 213, 213, var(--text-opacity)) !important;
}

.text-red-300 {
  --text-opacity: 1 !important;
  color: #F8B4B4 !important;
  color: rgba(248, 180, 180, var(--text-opacity)) !important;
}

.text-red-400 {
  --text-opacity: 1 !important;
  color: #F98080 !important;
  color: rgba(249, 128, 128, var(--text-opacity)) !important;
}

.text-red-500 {
  --text-opacity: 1 !important;
  color: #F05252 !important;
  color: rgba(240, 82, 82, var(--text-opacity)) !important;
}

.text-red-600 {
  --text-opacity: 1 !important;
  color: #E02424 !important;
  color: rgba(224, 36, 36, var(--text-opacity)) !important;
}

.text-red-700 {
  --text-opacity: 1 !important;
  color: #C81E1E !important;
  color: rgba(200, 30, 30, var(--text-opacity)) !important;
}

.text-red-800 {
  --text-opacity: 1 !important;
  color: #9B1C1C !important;
  color: rgba(155, 28, 28, var(--text-opacity)) !important;
}

.text-red-900 {
  --text-opacity: 1 !important;
  color: #771D1D !important;
  color: rgba(119, 29, 29, var(--text-opacity)) !important;
}

.text-red {
  --text-opacity: 1 !important;
  color: #F05252 !important;
  color: rgba(240, 82, 82, var(--text-opacity)) !important;
}

.text-orange-50 {
  --text-opacity: 1 !important;
  color: #FFF8F1 !important;
  color: rgba(255, 248, 241, var(--text-opacity)) !important;
}

.text-orange-100 {
  --text-opacity: 1 !important;
  color: #FEECDC !important;
  color: rgba(254, 236, 220, var(--text-opacity)) !important;
}

.text-orange-200 {
  --text-opacity: 1 !important;
  color: #FCD9BD !important;
  color: rgba(252, 217, 189, var(--text-opacity)) !important;
}

.text-orange-300 {
  --text-opacity: 1 !important;
  color: #FDBA8C !important;
  color: rgba(253, 186, 140, var(--text-opacity)) !important;
}

.text-orange-400 {
  --text-opacity: 1 !important;
  color: #FF8A4C !important;
  color: rgba(255, 138, 76, var(--text-opacity)) !important;
}

.text-orange-500 {
  --text-opacity: 1 !important;
  color: #FF5A1F !important;
  color: rgba(255, 90, 31, var(--text-opacity)) !important;
}

.text-orange-600 {
  --text-opacity: 1 !important;
  color: #ff6700 !important;
  color: rgba(255, 103, 0, var(--text-opacity)) !important;
}

.text-orange-700 {
  --text-opacity: 1 !important;
  color: #B43403 !important;
  color: rgba(180, 52, 3, var(--text-opacity)) !important;
}

.text-orange-800 {
  --text-opacity: 1 !important;
  color: #8A2C0D !important;
  color: rgba(138, 44, 13, var(--text-opacity)) !important;
}

.text-orange-900 {
  --text-opacity: 1 !important;
  color: #771D1D !important;
  color: rgba(119, 29, 29, var(--text-opacity)) !important;
}

.text-orange {
  --text-opacity: 1 !important;
  color: #FF5A1F !important;
  color: rgba(255, 90, 31, var(--text-opacity)) !important;
}

.text-yellow-50 {
  --text-opacity: 1 !important;
  color: #FDFDEA !important;
  color: rgba(253, 253, 234, var(--text-opacity)) !important;
}

.text-yellow-100 {
  --text-opacity: 1 !important;
  color: #FDF6B2 !important;
  color: rgba(253, 246, 178, var(--text-opacity)) !important;
}

.text-yellow-200 {
  --text-opacity: 1 !important;
  color: #FCE96A !important;
  color: rgba(252, 233, 106, var(--text-opacity)) !important;
}

.text-yellow-300 {
  --text-opacity: 1 !important;
  color: #FACA15 !important;
  color: rgba(250, 202, 21, var(--text-opacity)) !important;
}

.text-yellow-400 {
  --text-opacity: 1 !important;
  color: #E3A008 !important;
  color: rgba(227, 160, 8, var(--text-opacity)) !important;
}

.text-yellow-500 {
  --text-opacity: 1 !important;
  color: #C27803 !important;
  color: rgba(194, 120, 3, var(--text-opacity)) !important;
}

.text-yellow-600 {
  --text-opacity: 1 !important;
  color: #9F580A !important;
  color: rgba(159, 88, 10, var(--text-opacity)) !important;
}

.text-yellow-700 {
  --text-opacity: 1 !important;
  color: #8E4B10 !important;
  color: rgba(142, 75, 16, var(--text-opacity)) !important;
}

.text-yellow-800 {
  --text-opacity: 1 !important;
  color: #723B13 !important;
  color: rgba(114, 59, 19, var(--text-opacity)) !important;
}

.text-yellow-900 {
  --text-opacity: 1 !important;
  color: #633112 !important;
  color: rgba(99, 49, 18, var(--text-opacity)) !important;
}

.text-yellow {
  --text-opacity: 1 !important;
  color: #C27803 !important;
  color: rgba(194, 120, 3, var(--text-opacity)) !important;
}

.text-green-50 {
  --text-opacity: 1 !important;
  color: #F3FAF7 !important;
  color: rgba(243, 250, 247, var(--text-opacity)) !important;
}

.text-green-100 {
  --text-opacity: 1 !important;
  color: #DEF7EC !important;
  color: rgba(222, 247, 236, var(--text-opacity)) !important;
}

.text-green-200 {
  --text-opacity: 1 !important;
  color: #BCF0DA !important;
  color: rgba(188, 240, 218, var(--text-opacity)) !important;
}

.text-green-300 {
  --text-opacity: 1 !important;
  color: #84E1BC !important;
  color: rgba(132, 225, 188, var(--text-opacity)) !important;
}

.text-green-400 {
  --text-opacity: 1 !important;
  color: #31C48D !important;
  color: rgba(49, 196, 141, var(--text-opacity)) !important;
}

.text-green-500 {
  --text-opacity: 1 !important;
  color: #0E9F6E !important;
  color: rgba(14, 159, 110, var(--text-opacity)) !important;
}

.text-green-600 {
  --text-opacity: 1 !important;
  color: #057A55 !important;
  color: rgba(5, 122, 85, var(--text-opacity)) !important;
}

.text-green-700 {
  --text-opacity: 1 !important;
  color: #046C4E !important;
  color: rgba(4, 108, 78, var(--text-opacity)) !important;
}

.text-green-800 {
  --text-opacity: 1 !important;
  color: #03543F !important;
  color: rgba(3, 84, 63, var(--text-opacity)) !important;
}

.text-green-900 {
  --text-opacity: 1 !important;
  color: #014737 !important;
  color: rgba(1, 71, 55, var(--text-opacity)) !important;
}

.text-green {
  --text-opacity: 1 !important;
  color: #0E9F6E !important;
  color: rgba(14, 159, 110, var(--text-opacity)) !important;
}

.text-teal-50 {
  --text-opacity: 1 !important;
  color: #EDFAFA !important;
  color: rgba(237, 250, 250, var(--text-opacity)) !important;
}

.text-teal-100 {
  --text-opacity: 1 !important;
  color: #D5F5F6 !important;
  color: rgba(213, 245, 246, var(--text-opacity)) !important;
}

.text-teal-200 {
  --text-opacity: 1 !important;
  color: #AFECEF !important;
  color: rgba(175, 236, 239, var(--text-opacity)) !important;
}

.text-teal-300 {
  --text-opacity: 1 !important;
  color: #7EDCE2 !important;
  color: rgba(126, 220, 226, var(--text-opacity)) !important;
}

.text-teal-400 {
  --text-opacity: 1 !important;
  color: #16BDCA !important;
  color: rgba(22, 189, 202, var(--text-opacity)) !important;
}

.text-teal-500 {
  --text-opacity: 1 !important;
  color: #0694A2 !important;
  color: rgba(6, 148, 162, var(--text-opacity)) !important;
}

.text-teal-600 {
  --text-opacity: 1 !important;
  color: #047481 !important;
  color: rgba(4, 116, 129, var(--text-opacity)) !important;
}

.text-teal-700 {
  --text-opacity: 1 !important;
  color: #036672 !important;
  color: rgba(3, 102, 114, var(--text-opacity)) !important;
}

.text-teal-800 {
  --text-opacity: 1 !important;
  color: #05505C !important;
  color: rgba(5, 80, 92, var(--text-opacity)) !important;
}

.text-teal-900 {
  --text-opacity: 1 !important;
  color: #014451 !important;
  color: rgba(1, 68, 81, var(--text-opacity)) !important;
}

.text-teal {
  --text-opacity: 1 !important;
  color: #0694A2 !important;
  color: rgba(6, 148, 162, var(--text-opacity)) !important;
}

.text-blue-50 {
  --text-opacity: 1 !important;
  color: #EBF5FF !important;
  color: rgba(235, 245, 255, var(--text-opacity)) !important;
}

.text-blue-100 {
  --text-opacity: 1 !important;
  color: #E1EFFE !important;
  color: rgba(225, 239, 254, var(--text-opacity)) !important;
}

.text-blue-200 {
  --text-opacity: 1 !important;
  color: #C3DDFD !important;
  color: rgba(195, 221, 253, var(--text-opacity)) !important;
}

.text-blue-300 {
  --text-opacity: 1 !important;
  color: #A4CAFE !important;
  color: rgba(164, 202, 254, var(--text-opacity)) !important;
}

.text-blue-400 {
  --text-opacity: 1 !important;
  color: #76A9FA !important;
  color: rgba(118, 169, 250, var(--text-opacity)) !important;
}

.text-blue-500 {
  --text-opacity: 1 !important;
  color: #3F83F8 !important;
  color: rgba(63, 131, 248, var(--text-opacity)) !important;
}

.text-blue-600 {
  --text-opacity: 1 !important;
  color: #1C64F2 !important;
  color: rgba(28, 100, 242, var(--text-opacity)) !important;
}

.text-blue-700 {
  --text-opacity: 1 !important;
  color: #1A56DB !important;
  color: rgba(26, 86, 219, var(--text-opacity)) !important;
}

.text-blue-800 {
  --text-opacity: 1 !important;
  color: #1E429F !important;
  color: rgba(30, 66, 159, var(--text-opacity)) !important;
}

.text-blue-900 {
  --text-opacity: 1 !important;
  color: #233876 !important;
  color: rgba(35, 56, 118, var(--text-opacity)) !important;
}

.text-blue {
  --text-opacity: 1 !important;
  color: #3F83F8 !important;
  color: rgba(63, 131, 248, var(--text-opacity)) !important;
}

.text-indigo-50 {
  --text-opacity: 1 !important;
  color: #F0F5FF !important;
  color: rgba(240, 245, 255, var(--text-opacity)) !important;
}

.text-indigo-100 {
  --text-opacity: 1 !important;
  color: #E5EDFF !important;
  color: rgba(229, 237, 255, var(--text-opacity)) !important;
}

.text-indigo-200 {
  --text-opacity: 1 !important;
  color: #CDDBFE !important;
  color: rgba(205, 219, 254, var(--text-opacity)) !important;
}

.text-indigo-300 {
  --text-opacity: 1 !important;
  color: #B4C6FC !important;
  color: rgba(180, 198, 252, var(--text-opacity)) !important;
}

.text-indigo-400 {
  --text-opacity: 1 !important;
  color: #8DA2FB !important;
  color: rgba(141, 162, 251, var(--text-opacity)) !important;
}

.text-indigo-500 {
  --text-opacity: 1 !important;
  color: #6875F5 !important;
  color: rgba(104, 117, 245, var(--text-opacity)) !important;
}

.text-indigo-600 {
  --text-opacity: 1 !important;
  color: #5850EC !important;
  color: rgba(88, 80, 236, var(--text-opacity)) !important;
}

.text-indigo-700 {
  --text-opacity: 1 !important;
  color: #5145CD !important;
  color: rgba(81, 69, 205, var(--text-opacity)) !important;
}

.text-indigo-800 {
  --text-opacity: 1 !important;
  color: #42389D !important;
  color: rgba(66, 56, 157, var(--text-opacity)) !important;
}

.text-indigo-900 {
  --text-opacity: 1 !important;
  color: #362F78 !important;
  color: rgba(54, 47, 120, var(--text-opacity)) !important;
}

.text-indigo {
  --text-opacity: 1 !important;
  color: #6875F5 !important;
  color: rgba(104, 117, 245, var(--text-opacity)) !important;
}

.text-purple-50 {
  --text-opacity: 1 !important;
  color: #F6F5FF !important;
  color: rgba(246, 245, 255, var(--text-opacity)) !important;
}

.text-purple-100 {
  --text-opacity: 1 !important;
  color: #EDEBFE !important;
  color: rgba(237, 235, 254, var(--text-opacity)) !important;
}

.text-purple-200 {
  --text-opacity: 1 !important;
  color: #DCD7FE !important;
  color: rgba(220, 215, 254, var(--text-opacity)) !important;
}

.text-purple-300 {
  --text-opacity: 1 !important;
  color: #CABFFD !important;
  color: rgba(202, 191, 253, var(--text-opacity)) !important;
}

.text-purple-400 {
  --text-opacity: 1 !important;
  color: #AC94FA !important;
  color: rgba(172, 148, 250, var(--text-opacity)) !important;
}

.text-purple-500 {
  --text-opacity: 1 !important;
  color: #9061F9 !important;
  color: rgba(144, 97, 249, var(--text-opacity)) !important;
}

.text-purple-600 {
  --text-opacity: 1 !important;
  color: #7E3AF2 !important;
  color: rgba(126, 58, 242, var(--text-opacity)) !important;
}

.text-purple-700 {
  --text-opacity: 1 !important;
  color: #6C2BD9 !important;
  color: rgba(108, 43, 217, var(--text-opacity)) !important;
}

.text-purple-800 {
  --text-opacity: 1 !important;
  color: #5521B5 !important;
  color: rgba(85, 33, 181, var(--text-opacity)) !important;
}

.text-purple-900 {
  --text-opacity: 1 !important;
  color: #4A1D96 !important;
  color: rgba(74, 29, 150, var(--text-opacity)) !important;
}

.text-purple {
  --text-opacity: 1 !important;
  color: #9061F9 !important;
  color: rgba(144, 97, 249, var(--text-opacity)) !important;
}

.text-pink-50 {
  --text-opacity: 1 !important;
  color: #FDF2F8 !important;
  color: rgba(253, 242, 248, var(--text-opacity)) !important;
}

.text-pink-100 {
  --text-opacity: 1 !important;
  color: #FCE8F3 !important;
  color: rgba(252, 232, 243, var(--text-opacity)) !important;
}

.text-pink-200 {
  --text-opacity: 1 !important;
  color: #FAD1E8 !important;
  color: rgba(250, 209, 232, var(--text-opacity)) !important;
}

.text-pink-300 {
  --text-opacity: 1 !important;
  color: #F8B4D9 !important;
  color: rgba(248, 180, 217, var(--text-opacity)) !important;
}

.text-pink-400 {
  --text-opacity: 1 !important;
  color: #F17EB8 !important;
  color: rgba(241, 126, 184, var(--text-opacity)) !important;
}

.text-pink-500 {
  --text-opacity: 1 !important;
  color: #E74694 !important;
  color: rgba(231, 70, 148, var(--text-opacity)) !important;
}

.text-pink-600 {
  --text-opacity: 1 !important;
  color: #D61F69 !important;
  color: rgba(214, 31, 105, var(--text-opacity)) !important;
}

.text-pink-700 {
  --text-opacity: 1 !important;
  color: #BF125D !important;
  color: rgba(191, 18, 93, var(--text-opacity)) !important;
}

.text-pink-800 {
  --text-opacity: 1 !important;
  color: #99154B !important;
  color: rgba(153, 21, 75, var(--text-opacity)) !important;
}

.text-pink-900 {
  --text-opacity: 1 !important;
  color: #751A3D !important;
  color: rgba(117, 26, 61, var(--text-opacity)) !important;
}

.text-pink {
  --text-opacity: 1 !important;
  color: #E74694 !important;
  color: rgba(231, 70, 148, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-current, [class*="theme-dark"] .dark\:text-current, [class*="theme-light"].light\:text-current, [class*="theme-light"] .light\:text-current {
  color: currentColor !important;
}

[class*="theme-dark"].dark\:text-transparent, [class*="theme-dark"] .dark\:text-transparent, [class*="theme-light"].light\:text-transparent, [class*="theme-light"] .light\:text-transparent {
  color: transparent !important;
}

[class*="theme-dark"].dark\:text-white, [class*="theme-dark"] .dark\:text-white, [class*="theme-light"].light\:text-white, [class*="theme-light"] .light\:text-white {
  --text-opacity: 1 !important;
  color: #FFFFFF !important;
  color: rgba(255, 255, 255, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-black, [class*="theme-dark"] .dark\:text-black, [class*="theme-light"].light\:text-black, [class*="theme-light"] .light\:text-black {
  --text-opacity: 1 !important;
  color: #000000 !important;
  color: rgba(0, 0, 0, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-gray-50, [class*="theme-dark"] .dark\:text-gray-50, [class*="theme-light"].light\:text-gray-50, [class*="theme-light"] .light\:text-gray-50 {
  --text-opacity: 1 !important;
  color: #F9FAFB !important;
  color: rgba(249, 250, 251, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-gray-100, [class*="theme-dark"] .dark\:text-gray-100, [class*="theme-light"].light\:text-gray-100, [class*="theme-light"] .light\:text-gray-100 {
  --text-opacity: 1 !important;
  color: #F4F5F7 !important;
  color: rgba(244, 245, 247, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-gray-200, [class*="theme-dark"] .dark\:text-gray-200, [class*="theme-light"].light\:text-gray-200, [class*="theme-light"] .light\:text-gray-200 {
  --text-opacity: 1 !important;
  color: #E5E7EB !important;
  color: rgba(229, 231, 235, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-gray-300, [class*="theme-dark"] .dark\:text-gray-300, [class*="theme-light"].light\:text-gray-300, [class*="theme-light"] .light\:text-gray-300 {
  --text-opacity: 1 !important;
  color: #D2D6DC !important;
  color: rgba(210, 214, 220, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-gray-400, [class*="theme-dark"] .dark\:text-gray-400, [class*="theme-light"].light\:text-gray-400, [class*="theme-light"] .light\:text-gray-400 {
  --text-opacity: 1 !important;
  color: #9FA6B2 !important;
  color: rgba(159, 166, 178, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-gray-500, [class*="theme-dark"] .dark\:text-gray-500, [class*="theme-light"].light\:text-gray-500, [class*="theme-light"] .light\:text-gray-500 {
  --text-opacity: 1 !important;
  color: #6B7280 !important;
  color: rgba(107, 114, 128, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-gray-600, [class*="theme-dark"] .dark\:text-gray-600, [class*="theme-light"].light\:text-gray-600, [class*="theme-light"] .light\:text-gray-600 {
  --text-opacity: 1 !important;
  color: #4B5563 !important;
  color: rgba(75, 85, 99, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-gray-700, [class*="theme-dark"] .dark\:text-gray-700, [class*="theme-light"].light\:text-gray-700, [class*="theme-light"] .light\:text-gray-700 {
  --text-opacity: 1 !important;
  color: #374151 !important;
  color: rgba(55, 65, 81, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-gray-800, [class*="theme-dark"] .dark\:text-gray-800, [class*="theme-light"].light\:text-gray-800, [class*="theme-light"] .light\:text-gray-800 {
  --text-opacity: 1 !important;
  color: #252F3F !important;
  color: rgba(37, 47, 63, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-gray-900, [class*="theme-dark"] .dark\:text-gray-900, [class*="theme-light"].light\:text-gray-900, [class*="theme-light"] .light\:text-gray-900 {
  --text-opacity: 1 !important;
  color: #161E2E !important;
  color: rgba(22, 30, 46, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-gray, [class*="theme-dark"] .dark\:text-gray, [class*="theme-light"].light\:text-gray, [class*="theme-light"] .light\:text-gray {
  --text-opacity: 1 !important;
  color: #6B7280 !important;
  color: rgba(107, 114, 128, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-cool-gray-50, [class*="theme-dark"] .dark\:text-cool-gray-50, [class*="theme-light"].light\:text-cool-gray-50, [class*="theme-light"] .light\:text-cool-gray-50 {
  --text-opacity: 1 !important;
  color: #FBFDFE !important;
  color: rgba(251, 253, 254, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-cool-gray-100, [class*="theme-dark"] .dark\:text-cool-gray-100, [class*="theme-light"].light\:text-cool-gray-100, [class*="theme-light"] .light\:text-cool-gray-100 {
  --text-opacity: 1 !important;
  color: #F1F5F9 !important;
  color: rgba(241, 245, 249, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-cool-gray-200, [class*="theme-dark"] .dark\:text-cool-gray-200, [class*="theme-light"].light\:text-cool-gray-200, [class*="theme-light"] .light\:text-cool-gray-200 {
  --text-opacity: 1 !important;
  color: #E2E8F0 !important;
  color: rgba(226, 232, 240, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-cool-gray-300, [class*="theme-dark"] .dark\:text-cool-gray-300, [class*="theme-light"].light\:text-cool-gray-300, [class*="theme-light"] .light\:text-cool-gray-300 {
  --text-opacity: 1 !important;
  color: #CFD8E3 !important;
  color: rgba(207, 216, 227, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-cool-gray-400, [class*="theme-dark"] .dark\:text-cool-gray-400, [class*="theme-light"].light\:text-cool-gray-400, [class*="theme-light"] .light\:text-cool-gray-400 {
  --text-opacity: 1 !important;
  color: #97A6BA !important;
  color: rgba(151, 166, 186, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-cool-gray-500, [class*="theme-dark"] .dark\:text-cool-gray-500, [class*="theme-light"].light\:text-cool-gray-500, [class*="theme-light"] .light\:text-cool-gray-500 {
  --text-opacity: 1 !important;
  color: #64748B !important;
  color: rgba(100, 116, 139, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-cool-gray-600, [class*="theme-dark"] .dark\:text-cool-gray-600, [class*="theme-light"].light\:text-cool-gray-600, [class*="theme-light"] .light\:text-cool-gray-600 {
  --text-opacity: 1 !important;
  color: #475569 !important;
  color: rgba(71, 85, 105, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-cool-gray-700, [class*="theme-dark"] .dark\:text-cool-gray-700, [class*="theme-light"].light\:text-cool-gray-700, [class*="theme-light"] .light\:text-cool-gray-700 {
  --text-opacity: 1 !important;
  color: #364152 !important;
  color: rgba(54, 65, 82, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-cool-gray-800, [class*="theme-dark"] .dark\:text-cool-gray-800, [class*="theme-light"].light\:text-cool-gray-800, [class*="theme-light"] .light\:text-cool-gray-800 {
  --text-opacity: 1 !important;
  color: #27303F !important;
  color: rgba(39, 48, 63, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-cool-gray-900, [class*="theme-dark"] .dark\:text-cool-gray-900, [class*="theme-light"].light\:text-cool-gray-900, [class*="theme-light"] .light\:text-cool-gray-900 {
  --text-opacity: 1 !important;
  color: #1A202E !important;
  color: rgba(26, 32, 46, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-cool-gray, [class*="theme-dark"] .dark\:text-cool-gray, [class*="theme-light"].light\:text-cool-gray, [class*="theme-light"] .light\:text-cool-gray {
  --text-opacity: 1 !important;
  color: #64748B !important;
  color: rgba(100, 116, 139, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-red-50, [class*="theme-dark"] .dark\:text-red-50, [class*="theme-light"].light\:text-red-50, [class*="theme-light"] .light\:text-red-50 {
  --text-opacity: 1 !important;
  color: #FDF2F2 !important;
  color: rgba(253, 242, 242, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-red-100, [class*="theme-dark"] .dark\:text-red-100, [class*="theme-light"].light\:text-red-100, [class*="theme-light"] .light\:text-red-100 {
  --text-opacity: 1 !important;
  color: #FDE8E8 !important;
  color: rgba(253, 232, 232, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-red-200, [class*="theme-dark"] .dark\:text-red-200, [class*="theme-light"].light\:text-red-200, [class*="theme-light"] .light\:text-red-200 {
  --text-opacity: 1 !important;
  color: #FBD5D5 !important;
  color: rgba(251, 213, 213, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-red-300, [class*="theme-dark"] .dark\:text-red-300, [class*="theme-light"].light\:text-red-300, [class*="theme-light"] .light\:text-red-300 {
  --text-opacity: 1 !important;
  color: #F8B4B4 !important;
  color: rgba(248, 180, 180, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-red-400, [class*="theme-dark"] .dark\:text-red-400, [class*="theme-light"].light\:text-red-400, [class*="theme-light"] .light\:text-red-400 {
  --text-opacity: 1 !important;
  color: #F98080 !important;
  color: rgba(249, 128, 128, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-red-500, [class*="theme-dark"] .dark\:text-red-500, [class*="theme-light"].light\:text-red-500, [class*="theme-light"] .light\:text-red-500 {
  --text-opacity: 1 !important;
  color: #F05252 !important;
  color: rgba(240, 82, 82, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-red-600, [class*="theme-dark"] .dark\:text-red-600, [class*="theme-light"].light\:text-red-600, [class*="theme-light"] .light\:text-red-600 {
  --text-opacity: 1 !important;
  color: #E02424 !important;
  color: rgba(224, 36, 36, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-red-700, [class*="theme-dark"] .dark\:text-red-700, [class*="theme-light"].light\:text-red-700, [class*="theme-light"] .light\:text-red-700 {
  --text-opacity: 1 !important;
  color: #C81E1E !important;
  color: rgba(200, 30, 30, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-red-800, [class*="theme-dark"] .dark\:text-red-800, [class*="theme-light"].light\:text-red-800, [class*="theme-light"] .light\:text-red-800 {
  --text-opacity: 1 !important;
  color: #9B1C1C !important;
  color: rgba(155, 28, 28, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-red-900, [class*="theme-dark"] .dark\:text-red-900, [class*="theme-light"].light\:text-red-900, [class*="theme-light"] .light\:text-red-900 {
  --text-opacity: 1 !important;
  color: #771D1D !important;
  color: rgba(119, 29, 29, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-red, [class*="theme-dark"] .dark\:text-red, [class*="theme-light"].light\:text-red, [class*="theme-light"] .light\:text-red {
  --text-opacity: 1 !important;
  color: #F05252 !important;
  color: rgba(240, 82, 82, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-orange-50, [class*="theme-dark"] .dark\:text-orange-50, [class*="theme-light"].light\:text-orange-50, [class*="theme-light"] .light\:text-orange-50 {
  --text-opacity: 1 !important;
  color: #FFF8F1 !important;
  color: rgba(255, 248, 241, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-orange-100, [class*="theme-dark"] .dark\:text-orange-100, [class*="theme-light"].light\:text-orange-100, [class*="theme-light"] .light\:text-orange-100 {
  --text-opacity: 1 !important;
  color: #FEECDC !important;
  color: rgba(254, 236, 220, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-orange-200, [class*="theme-dark"] .dark\:text-orange-200, [class*="theme-light"].light\:text-orange-200, [class*="theme-light"] .light\:text-orange-200 {
  --text-opacity: 1 !important;
  color: #FCD9BD !important;
  color: rgba(252, 217, 189, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-orange-300, [class*="theme-dark"] .dark\:text-orange-300, [class*="theme-light"].light\:text-orange-300, [class*="theme-light"] .light\:text-orange-300 {
  --text-opacity: 1 !important;
  color: #FDBA8C !important;
  color: rgba(253, 186, 140, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-orange-400, [class*="theme-dark"] .dark\:text-orange-400, [class*="theme-light"].light\:text-orange-400, [class*="theme-light"] .light\:text-orange-400 {
  --text-opacity: 1 !important;
  color: #FF8A4C !important;
  color: rgba(255, 138, 76, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-orange-500, [class*="theme-dark"] .dark\:text-orange-500, [class*="theme-light"].light\:text-orange-500, [class*="theme-light"] .light\:text-orange-500 {
  --text-opacity: 1 !important;
  color: #FF5A1F !important;
  color: rgba(255, 90, 31, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-orange-600, [class*="theme-dark"] .dark\:text-orange-600, [class*="theme-light"].light\:text-orange-600, [class*="theme-light"] .light\:text-orange-600 {
  --text-opacity: 1 !important;
  color: #ff6700 !important;
  color: rgba(255, 103, 0, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-orange-700, [class*="theme-dark"] .dark\:text-orange-700, [class*="theme-light"].light\:text-orange-700, [class*="theme-light"] .light\:text-orange-700 {
  --text-opacity: 1 !important;
  color: #B43403 !important;
  color: rgba(180, 52, 3, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-orange-800, [class*="theme-dark"] .dark\:text-orange-800, [class*="theme-light"].light\:text-orange-800, [class*="theme-light"] .light\:text-orange-800 {
  --text-opacity: 1 !important;
  color: #8A2C0D !important;
  color: rgba(138, 44, 13, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-orange-900, [class*="theme-dark"] .dark\:text-orange-900, [class*="theme-light"].light\:text-orange-900, [class*="theme-light"] .light\:text-orange-900 {
  --text-opacity: 1 !important;
  color: #771D1D !important;
  color: rgba(119, 29, 29, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-orange, [class*="theme-dark"] .dark\:text-orange, [class*="theme-light"].light\:text-orange, [class*="theme-light"] .light\:text-orange {
  --text-opacity: 1 !important;
  color: #FF5A1F !important;
  color: rgba(255, 90, 31, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-yellow-50, [class*="theme-dark"] .dark\:text-yellow-50, [class*="theme-light"].light\:text-yellow-50, [class*="theme-light"] .light\:text-yellow-50 {
  --text-opacity: 1 !important;
  color: #FDFDEA !important;
  color: rgba(253, 253, 234, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-yellow-100, [class*="theme-dark"] .dark\:text-yellow-100, [class*="theme-light"].light\:text-yellow-100, [class*="theme-light"] .light\:text-yellow-100 {
  --text-opacity: 1 !important;
  color: #FDF6B2 !important;
  color: rgba(253, 246, 178, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-yellow-200, [class*="theme-dark"] .dark\:text-yellow-200, [class*="theme-light"].light\:text-yellow-200, [class*="theme-light"] .light\:text-yellow-200 {
  --text-opacity: 1 !important;
  color: #FCE96A !important;
  color: rgba(252, 233, 106, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-yellow-300, [class*="theme-dark"] .dark\:text-yellow-300, [class*="theme-light"].light\:text-yellow-300, [class*="theme-light"] .light\:text-yellow-300 {
  --text-opacity: 1 !important;
  color: #FACA15 !important;
  color: rgba(250, 202, 21, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-yellow-400, [class*="theme-dark"] .dark\:text-yellow-400, [class*="theme-light"].light\:text-yellow-400, [class*="theme-light"] .light\:text-yellow-400 {
  --text-opacity: 1 !important;
  color: #E3A008 !important;
  color: rgba(227, 160, 8, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-yellow-500, [class*="theme-dark"] .dark\:text-yellow-500, [class*="theme-light"].light\:text-yellow-500, [class*="theme-light"] .light\:text-yellow-500 {
  --text-opacity: 1 !important;
  color: #C27803 !important;
  color: rgba(194, 120, 3, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-yellow-600, [class*="theme-dark"] .dark\:text-yellow-600, [class*="theme-light"].light\:text-yellow-600, [class*="theme-light"] .light\:text-yellow-600 {
  --text-opacity: 1 !important;
  color: #9F580A !important;
  color: rgba(159, 88, 10, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-yellow-700, [class*="theme-dark"] .dark\:text-yellow-700, [class*="theme-light"].light\:text-yellow-700, [class*="theme-light"] .light\:text-yellow-700 {
  --text-opacity: 1 !important;
  color: #8E4B10 !important;
  color: rgba(142, 75, 16, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-yellow-800, [class*="theme-dark"] .dark\:text-yellow-800, [class*="theme-light"].light\:text-yellow-800, [class*="theme-light"] .light\:text-yellow-800 {
  --text-opacity: 1 !important;
  color: #723B13 !important;
  color: rgba(114, 59, 19, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-yellow-900, [class*="theme-dark"] .dark\:text-yellow-900, [class*="theme-light"].light\:text-yellow-900, [class*="theme-light"] .light\:text-yellow-900 {
  --text-opacity: 1 !important;
  color: #633112 !important;
  color: rgba(99, 49, 18, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-yellow, [class*="theme-dark"] .dark\:text-yellow, [class*="theme-light"].light\:text-yellow, [class*="theme-light"] .light\:text-yellow {
  --text-opacity: 1 !important;
  color: #C27803 !important;
  color: rgba(194, 120, 3, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-green-50, [class*="theme-dark"] .dark\:text-green-50, [class*="theme-light"].light\:text-green-50, [class*="theme-light"] .light\:text-green-50 {
  --text-opacity: 1 !important;
  color: #F3FAF7 !important;
  color: rgba(243, 250, 247, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-green-100, [class*="theme-dark"] .dark\:text-green-100, [class*="theme-light"].light\:text-green-100, [class*="theme-light"] .light\:text-green-100 {
  --text-opacity: 1 !important;
  color: #DEF7EC !important;
  color: rgba(222, 247, 236, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-green-200, [class*="theme-dark"] .dark\:text-green-200, [class*="theme-light"].light\:text-green-200, [class*="theme-light"] .light\:text-green-200 {
  --text-opacity: 1 !important;
  color: #BCF0DA !important;
  color: rgba(188, 240, 218, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-green-300, [class*="theme-dark"] .dark\:text-green-300, [class*="theme-light"].light\:text-green-300, [class*="theme-light"] .light\:text-green-300 {
  --text-opacity: 1 !important;
  color: #84E1BC !important;
  color: rgba(132, 225, 188, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-green-400, [class*="theme-dark"] .dark\:text-green-400, [class*="theme-light"].light\:text-green-400, [class*="theme-light"] .light\:text-green-400 {
  --text-opacity: 1 !important;
  color: #31C48D !important;
  color: rgba(49, 196, 141, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-green-500, [class*="theme-dark"] .dark\:text-green-500, [class*="theme-light"].light\:text-green-500, [class*="theme-light"] .light\:text-green-500 {
  --text-opacity: 1 !important;
  color: #0E9F6E !important;
  color: rgba(14, 159, 110, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-green-600, [class*="theme-dark"] .dark\:text-green-600, [class*="theme-light"].light\:text-green-600, [class*="theme-light"] .light\:text-green-600 {
  --text-opacity: 1 !important;
  color: #057A55 !important;
  color: rgba(5, 122, 85, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-green-700, [class*="theme-dark"] .dark\:text-green-700, [class*="theme-light"].light\:text-green-700, [class*="theme-light"] .light\:text-green-700 {
  --text-opacity: 1 !important;
  color: #046C4E !important;
  color: rgba(4, 108, 78, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-green-800, [class*="theme-dark"] .dark\:text-green-800, [class*="theme-light"].light\:text-green-800, [class*="theme-light"] .light\:text-green-800 {
  --text-opacity: 1 !important;
  color: #03543F !important;
  color: rgba(3, 84, 63, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-green-900, [class*="theme-dark"] .dark\:text-green-900, [class*="theme-light"].light\:text-green-900, [class*="theme-light"] .light\:text-green-900 {
  --text-opacity: 1 !important;
  color: #014737 !important;
  color: rgba(1, 71, 55, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-green, [class*="theme-dark"] .dark\:text-green, [class*="theme-light"].light\:text-green, [class*="theme-light"] .light\:text-green {
  --text-opacity: 1 !important;
  color: #0E9F6E !important;
  color: rgba(14, 159, 110, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-teal-50, [class*="theme-dark"] .dark\:text-teal-50, [class*="theme-light"].light\:text-teal-50, [class*="theme-light"] .light\:text-teal-50 {
  --text-opacity: 1 !important;
  color: #EDFAFA !important;
  color: rgba(237, 250, 250, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-teal-100, [class*="theme-dark"] .dark\:text-teal-100, [class*="theme-light"].light\:text-teal-100, [class*="theme-light"] .light\:text-teal-100 {
  --text-opacity: 1 !important;
  color: #D5F5F6 !important;
  color: rgba(213, 245, 246, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-teal-200, [class*="theme-dark"] .dark\:text-teal-200, [class*="theme-light"].light\:text-teal-200, [class*="theme-light"] .light\:text-teal-200 {
  --text-opacity: 1 !important;
  color: #AFECEF !important;
  color: rgba(175, 236, 239, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-teal-300, [class*="theme-dark"] .dark\:text-teal-300, [class*="theme-light"].light\:text-teal-300, [class*="theme-light"] .light\:text-teal-300 {
  --text-opacity: 1 !important;
  color: #7EDCE2 !important;
  color: rgba(126, 220, 226, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-teal-400, [class*="theme-dark"] .dark\:text-teal-400, [class*="theme-light"].light\:text-teal-400, [class*="theme-light"] .light\:text-teal-400 {
  --text-opacity: 1 !important;
  color: #16BDCA !important;
  color: rgba(22, 189, 202, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-teal-500, [class*="theme-dark"] .dark\:text-teal-500, [class*="theme-light"].light\:text-teal-500, [class*="theme-light"] .light\:text-teal-500 {
  --text-opacity: 1 !important;
  color: #0694A2 !important;
  color: rgba(6, 148, 162, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-teal-600, [class*="theme-dark"] .dark\:text-teal-600, [class*="theme-light"].light\:text-teal-600, [class*="theme-light"] .light\:text-teal-600 {
  --text-opacity: 1 !important;
  color: #047481 !important;
  color: rgba(4, 116, 129, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-teal-700, [class*="theme-dark"] .dark\:text-teal-700, [class*="theme-light"].light\:text-teal-700, [class*="theme-light"] .light\:text-teal-700 {
  --text-opacity: 1 !important;
  color: #036672 !important;
  color: rgba(3, 102, 114, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-teal-800, [class*="theme-dark"] .dark\:text-teal-800, [class*="theme-light"].light\:text-teal-800, [class*="theme-light"] .light\:text-teal-800 {
  --text-opacity: 1 !important;
  color: #05505C !important;
  color: rgba(5, 80, 92, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-teal-900, [class*="theme-dark"] .dark\:text-teal-900, [class*="theme-light"].light\:text-teal-900, [class*="theme-light"] .light\:text-teal-900 {
  --text-opacity: 1 !important;
  color: #014451 !important;
  color: rgba(1, 68, 81, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-teal, [class*="theme-dark"] .dark\:text-teal, [class*="theme-light"].light\:text-teal, [class*="theme-light"] .light\:text-teal {
  --text-opacity: 1 !important;
  color: #0694A2 !important;
  color: rgba(6, 148, 162, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-blue-50, [class*="theme-dark"] .dark\:text-blue-50, [class*="theme-light"].light\:text-blue-50, [class*="theme-light"] .light\:text-blue-50 {
  --text-opacity: 1 !important;
  color: #EBF5FF !important;
  color: rgba(235, 245, 255, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-blue-100, [class*="theme-dark"] .dark\:text-blue-100, [class*="theme-light"].light\:text-blue-100, [class*="theme-light"] .light\:text-blue-100 {
  --text-opacity: 1 !important;
  color: #E1EFFE !important;
  color: rgba(225, 239, 254, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-blue-200, [class*="theme-dark"] .dark\:text-blue-200, [class*="theme-light"].light\:text-blue-200, [class*="theme-light"] .light\:text-blue-200 {
  --text-opacity: 1 !important;
  color: #C3DDFD !important;
  color: rgba(195, 221, 253, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-blue-300, [class*="theme-dark"] .dark\:text-blue-300, [class*="theme-light"].light\:text-blue-300, [class*="theme-light"] .light\:text-blue-300 {
  --text-opacity: 1 !important;
  color: #A4CAFE !important;
  color: rgba(164, 202, 254, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-blue-400, [class*="theme-dark"] .dark\:text-blue-400, [class*="theme-light"].light\:text-blue-400, [class*="theme-light"] .light\:text-blue-400 {
  --text-opacity: 1 !important;
  color: #76A9FA !important;
  color: rgba(118, 169, 250, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-blue-500, [class*="theme-dark"] .dark\:text-blue-500, [class*="theme-light"].light\:text-blue-500, [class*="theme-light"] .light\:text-blue-500 {
  --text-opacity: 1 !important;
  color: #3F83F8 !important;
  color: rgba(63, 131, 248, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-blue-600, [class*="theme-dark"] .dark\:text-blue-600, [class*="theme-light"].light\:text-blue-600, [class*="theme-light"] .light\:text-blue-600 {
  --text-opacity: 1 !important;
  color: #1C64F2 !important;
  color: rgba(28, 100, 242, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-blue-700, [class*="theme-dark"] .dark\:text-blue-700, [class*="theme-light"].light\:text-blue-700, [class*="theme-light"] .light\:text-blue-700 {
  --text-opacity: 1 !important;
  color: #1A56DB !important;
  color: rgba(26, 86, 219, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-blue-800, [class*="theme-dark"] .dark\:text-blue-800, [class*="theme-light"].light\:text-blue-800, [class*="theme-light"] .light\:text-blue-800 {
  --text-opacity: 1 !important;
  color: #1E429F !important;
  color: rgba(30, 66, 159, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-blue-900, [class*="theme-dark"] .dark\:text-blue-900, [class*="theme-light"].light\:text-blue-900, [class*="theme-light"] .light\:text-blue-900 {
  --text-opacity: 1 !important;
  color: #233876 !important;
  color: rgba(35, 56, 118, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-blue, [class*="theme-dark"] .dark\:text-blue, [class*="theme-light"].light\:text-blue, [class*="theme-light"] .light\:text-blue {
  --text-opacity: 1 !important;
  color: #3F83F8 !important;
  color: rgba(63, 131, 248, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-indigo-50, [class*="theme-dark"] .dark\:text-indigo-50, [class*="theme-light"].light\:text-indigo-50, [class*="theme-light"] .light\:text-indigo-50 {
  --text-opacity: 1 !important;
  color: #F0F5FF !important;
  color: rgba(240, 245, 255, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-indigo-100, [class*="theme-dark"] .dark\:text-indigo-100, [class*="theme-light"].light\:text-indigo-100, [class*="theme-light"] .light\:text-indigo-100 {
  --text-opacity: 1 !important;
  color: #E5EDFF !important;
  color: rgba(229, 237, 255, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-indigo-200, [class*="theme-dark"] .dark\:text-indigo-200, [class*="theme-light"].light\:text-indigo-200, [class*="theme-light"] .light\:text-indigo-200 {
  --text-opacity: 1 !important;
  color: #CDDBFE !important;
  color: rgba(205, 219, 254, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-indigo-300, [class*="theme-dark"] .dark\:text-indigo-300, [class*="theme-light"].light\:text-indigo-300, [class*="theme-light"] .light\:text-indigo-300 {
  --text-opacity: 1 !important;
  color: #B4C6FC !important;
  color: rgba(180, 198, 252, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-indigo-400, [class*="theme-dark"] .dark\:text-indigo-400, [class*="theme-light"].light\:text-indigo-400, [class*="theme-light"] .light\:text-indigo-400 {
  --text-opacity: 1 !important;
  color: #8DA2FB !important;
  color: rgba(141, 162, 251, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-indigo-500, [class*="theme-dark"] .dark\:text-indigo-500, [class*="theme-light"].light\:text-indigo-500, [class*="theme-light"] .light\:text-indigo-500 {
  --text-opacity: 1 !important;
  color: #6875F5 !important;
  color: rgba(104, 117, 245, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-indigo-600, [class*="theme-dark"] .dark\:text-indigo-600, [class*="theme-light"].light\:text-indigo-600, [class*="theme-light"] .light\:text-indigo-600 {
  --text-opacity: 1 !important;
  color: #5850EC !important;
  color: rgba(88, 80, 236, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-indigo-700, [class*="theme-dark"] .dark\:text-indigo-700, [class*="theme-light"].light\:text-indigo-700, [class*="theme-light"] .light\:text-indigo-700 {
  --text-opacity: 1 !important;
  color: #5145CD !important;
  color: rgba(81, 69, 205, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-indigo-800, [class*="theme-dark"] .dark\:text-indigo-800, [class*="theme-light"].light\:text-indigo-800, [class*="theme-light"] .light\:text-indigo-800 {
  --text-opacity: 1 !important;
  color: #42389D !important;
  color: rgba(66, 56, 157, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-indigo-900, [class*="theme-dark"] .dark\:text-indigo-900, [class*="theme-light"].light\:text-indigo-900, [class*="theme-light"] .light\:text-indigo-900 {
  --text-opacity: 1 !important;
  color: #362F78 !important;
  color: rgba(54, 47, 120, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-indigo, [class*="theme-dark"] .dark\:text-indigo, [class*="theme-light"].light\:text-indigo, [class*="theme-light"] .light\:text-indigo {
  --text-opacity: 1 !important;
  color: #6875F5 !important;
  color: rgba(104, 117, 245, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-purple-50, [class*="theme-dark"] .dark\:text-purple-50, [class*="theme-light"].light\:text-purple-50, [class*="theme-light"] .light\:text-purple-50 {
  --text-opacity: 1 !important;
  color: #F6F5FF !important;
  color: rgba(246, 245, 255, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-purple-100, [class*="theme-dark"] .dark\:text-purple-100, [class*="theme-light"].light\:text-purple-100, [class*="theme-light"] .light\:text-purple-100 {
  --text-opacity: 1 !important;
  color: #EDEBFE !important;
  color: rgba(237, 235, 254, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-purple-200, [class*="theme-dark"] .dark\:text-purple-200, [class*="theme-light"].light\:text-purple-200, [class*="theme-light"] .light\:text-purple-200 {
  --text-opacity: 1 !important;
  color: #DCD7FE !important;
  color: rgba(220, 215, 254, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-purple-300, [class*="theme-dark"] .dark\:text-purple-300, [class*="theme-light"].light\:text-purple-300, [class*="theme-light"] .light\:text-purple-300 {
  --text-opacity: 1 !important;
  color: #CABFFD !important;
  color: rgba(202, 191, 253, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-purple-400, [class*="theme-dark"] .dark\:text-purple-400, [class*="theme-light"].light\:text-purple-400, [class*="theme-light"] .light\:text-purple-400 {
  --text-opacity: 1 !important;
  color: #AC94FA !important;
  color: rgba(172, 148, 250, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-purple-500, [class*="theme-dark"] .dark\:text-purple-500, [class*="theme-light"].light\:text-purple-500, [class*="theme-light"] .light\:text-purple-500 {
  --text-opacity: 1 !important;
  color: #9061F9 !important;
  color: rgba(144, 97, 249, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-purple-600, [class*="theme-dark"] .dark\:text-purple-600, [class*="theme-light"].light\:text-purple-600, [class*="theme-light"] .light\:text-purple-600 {
  --text-opacity: 1 !important;
  color: #7E3AF2 !important;
  color: rgba(126, 58, 242, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-purple-700, [class*="theme-dark"] .dark\:text-purple-700, [class*="theme-light"].light\:text-purple-700, [class*="theme-light"] .light\:text-purple-700 {
  --text-opacity: 1 !important;
  color: #6C2BD9 !important;
  color: rgba(108, 43, 217, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-purple-800, [class*="theme-dark"] .dark\:text-purple-800, [class*="theme-light"].light\:text-purple-800, [class*="theme-light"] .light\:text-purple-800 {
  --text-opacity: 1 !important;
  color: #5521B5 !important;
  color: rgba(85, 33, 181, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-purple-900, [class*="theme-dark"] .dark\:text-purple-900, [class*="theme-light"].light\:text-purple-900, [class*="theme-light"] .light\:text-purple-900 {
  --text-opacity: 1 !important;
  color: #4A1D96 !important;
  color: rgba(74, 29, 150, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-purple, [class*="theme-dark"] .dark\:text-purple, [class*="theme-light"].light\:text-purple, [class*="theme-light"] .light\:text-purple {
  --text-opacity: 1 !important;
  color: #9061F9 !important;
  color: rgba(144, 97, 249, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-pink-50, [class*="theme-dark"] .dark\:text-pink-50, [class*="theme-light"].light\:text-pink-50, [class*="theme-light"] .light\:text-pink-50 {
  --text-opacity: 1 !important;
  color: #FDF2F8 !important;
  color: rgba(253, 242, 248, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-pink-100, [class*="theme-dark"] .dark\:text-pink-100, [class*="theme-light"].light\:text-pink-100, [class*="theme-light"] .light\:text-pink-100 {
  --text-opacity: 1 !important;
  color: #FCE8F3 !important;
  color: rgba(252, 232, 243, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-pink-200, [class*="theme-dark"] .dark\:text-pink-200, [class*="theme-light"].light\:text-pink-200, [class*="theme-light"] .light\:text-pink-200 {
  --text-opacity: 1 !important;
  color: #FAD1E8 !important;
  color: rgba(250, 209, 232, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-pink-300, [class*="theme-dark"] .dark\:text-pink-300, [class*="theme-light"].light\:text-pink-300, [class*="theme-light"] .light\:text-pink-300 {
  --text-opacity: 1 !important;
  color: #F8B4D9 !important;
  color: rgba(248, 180, 217, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-pink-400, [class*="theme-dark"] .dark\:text-pink-400, [class*="theme-light"].light\:text-pink-400, [class*="theme-light"] .light\:text-pink-400 {
  --text-opacity: 1 !important;
  color: #F17EB8 !important;
  color: rgba(241, 126, 184, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-pink-500, [class*="theme-dark"] .dark\:text-pink-500, [class*="theme-light"].light\:text-pink-500, [class*="theme-light"] .light\:text-pink-500 {
  --text-opacity: 1 !important;
  color: #E74694 !important;
  color: rgba(231, 70, 148, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-pink-600, [class*="theme-dark"] .dark\:text-pink-600, [class*="theme-light"].light\:text-pink-600, [class*="theme-light"] .light\:text-pink-600 {
  --text-opacity: 1 !important;
  color: #D61F69 !important;
  color: rgba(214, 31, 105, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-pink-700, [class*="theme-dark"] .dark\:text-pink-700, [class*="theme-light"].light\:text-pink-700, [class*="theme-light"] .light\:text-pink-700 {
  --text-opacity: 1 !important;
  color: #BF125D !important;
  color: rgba(191, 18, 93, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-pink-800, [class*="theme-dark"] .dark\:text-pink-800, [class*="theme-light"].light\:text-pink-800, [class*="theme-light"] .light\:text-pink-800 {
  --text-opacity: 1 !important;
  color: #99154B !important;
  color: rgba(153, 21, 75, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-pink-900, [class*="theme-dark"] .dark\:text-pink-900, [class*="theme-light"].light\:text-pink-900, [class*="theme-light"] .light\:text-pink-900 {
  --text-opacity: 1 !important;
  color: #751A3D !important;
  color: rgba(117, 26, 61, var(--text-opacity)) !important;
}

[class*="theme-dark"].dark\:text-pink, [class*="theme-dark"] .dark\:text-pink, [class*="theme-light"].light\:text-pink, [class*="theme-light"] .light\:text-pink {
  --text-opacity: 1 !important;
  color: #E74694 !important;
  color: rgba(231, 70, 148, var(--text-opacity)) !important;
}

.text-opacity-0 {
  --text-opacity: 0 !important;
}

.text-opacity-12 {
  --text-opacity: 0.12 !important;
}

.text-opacity-25 {
  --text-opacity: 0.25 !important;
}

.text-opacity-38 {
  --text-opacity: 0.38 !important;
}

.text-opacity-50 {
  --text-opacity: 0.5 !important;
}

.text-opacity-54 {
  --text-opacity: 0.54 !important;
}

.text-opacity-70 {
  --text-opacity: 0.70 !important;
}

.text-opacity-75 {
  --text-opacity: 0.75 !important;
}

.text-opacity-84 {
  --text-opacity: 0.84 !important;
}

.text-opacity-100 {
  --text-opacity: 1 !important;
}

.hover\:text-opacity-0:hover {
  --text-opacity: 0 !important;
}

.hover\:text-opacity-12:hover {
  --text-opacity: 0.12 !important;
}

.hover\:text-opacity-25:hover {
  --text-opacity: 0.25 !important;
}

.hover\:text-opacity-38:hover {
  --text-opacity: 0.38 !important;
}

.hover\:text-opacity-50:hover {
  --text-opacity: 0.5 !important;
}

.hover\:text-opacity-54:hover {
  --text-opacity: 0.54 !important;
}

.hover\:text-opacity-70:hover {
  --text-opacity: 0.70 !important;
}

.hover\:text-opacity-75:hover {
  --text-opacity: 0.75 !important;
}

.hover\:text-opacity-84:hover {
  --text-opacity: 0.84 !important;
}

.hover\:text-opacity-100:hover {
  --text-opacity: 1 !important;
}

.focus\:text-opacity-0:focus {
  --text-opacity: 0 !important;
}

.focus\:text-opacity-12:focus {
  --text-opacity: 0.12 !important;
}

.focus\:text-opacity-25:focus {
  --text-opacity: 0.25 !important;
}

.focus\:text-opacity-38:focus {
  --text-opacity: 0.38 !important;
}

.focus\:text-opacity-50:focus {
  --text-opacity: 0.5 !important;
}

.focus\:text-opacity-54:focus {
  --text-opacity: 0.54 !important;
}

.focus\:text-opacity-70:focus {
  --text-opacity: 0.70 !important;
}

.focus\:text-opacity-75:focus {
  --text-opacity: 0.75 !important;
}

.focus\:text-opacity-84:focus {
  --text-opacity: 0.84 !important;
}

.focus\:text-opacity-100:focus {
  --text-opacity: 1 !important;
}

.italic {
  font-style: italic !important;
}

.not-italic {
  font-style: normal !important;
}

.uppercase {
  text-transform: uppercase !important;
}

.lowercase {
  text-transform: lowercase !important;
}

.capitalize {
  text-transform: capitalize !important;
}

.normal-case {
  text-transform: none !important;
}

.underline {
  text-decoration: underline !important;
}

.line-through {
  text-decoration: line-through !important;
}

.no-underline {
  text-decoration: none !important;
}

.hover\:underline:hover {
  text-decoration: underline !important;
}

.hover\:line-through:hover {
  text-decoration: line-through !important;
}

.hover\:no-underline:hover {
  text-decoration: none !important;
}

.focus\:underline:focus {
  text-decoration: underline !important;
}

.focus\:line-through:focus {
  text-decoration: line-through !important;
}

.focus\:no-underline:focus {
  text-decoration: none !important;
}

.antialiased {
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.subpixel-antialiased {
  -webkit-font-smoothing: auto !important;
  -moz-osx-font-smoothing: auto !important;
}

.tracking-tighter {
  letter-spacing: -0.05em !important;
}

.tracking-tight {
  letter-spacing: -0.025em !important;
}

.tracking-normal {
  letter-spacing: 0 !important;
}

.tracking-wide {
  letter-spacing: 0.025em !important;
}

.tracking-wider {
  letter-spacing: 0.05em !important;
}

.tracking-widest {
  letter-spacing: 0.1em !important;
}

.select-none {
  -webkit-user-select: none !important;
     -moz-user-select: none !important;
      -ms-user-select: none !important;
          user-select: none !important;
}

.select-text {
  -webkit-user-select: text !important;
     -moz-user-select: text !important;
      -ms-user-select: text !important;
          user-select: text !important;
}

.select-all {
  -webkit-user-select: all !important;
     -moz-user-select: all !important;
      -ms-user-select: all !important;
          user-select: all !important;
}

.select-auto {
  -webkit-user-select: auto !important;
     -moz-user-select: auto !important;
      -ms-user-select: auto !important;
          user-select: auto !important;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.whitespace-normal {
  white-space: normal !important;
}

.whitespace-nowrap {
  white-space: nowrap !important;
}

.whitespace-pre {
  white-space: pre !important;
}

.whitespace-pre-line {
  white-space: pre-line !important;
}

.whitespace-pre-wrap {
  white-space: pre-wrap !important;
}

.break-normal {
  overflow-wrap: normal !important;
  word-break: normal !important;
}

.break-words {
  overflow-wrap: break-word !important;
}

.break-all {
  word-break: break-all !important;
}

.truncate {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.w-0 {
  width: 0 !important;
}

.w-1 {
  width: 0.25rem !important;
}

.w-2 {
  width: 0.5rem !important;
}

.w-3 {
  width: 0.75rem !important;
}

.w-4 {
  width: 1rem !important;
}

.w-5 {
  width: 1.25rem !important;
}

.w-6 {
  width: 1.5rem !important;
}

.w-8 {
  width: 2rem !important;
}

.w-10 {
  width: 2.5rem !important;
}

.w-12 {
  width: 3rem !important;
}

.w-14 {
  width: 3.5rem !important;
}

.w-16 {
  width: 4rem !important;
}

.w-18 {
  width: 4.5rem !important;
}

.w-20 {
  width: 5rem !important;
}

.w-22 {
  width: 5.5rem !important;
}

.w-24 {
  width: 6rem !important;
}

.w-26 {
  width: 6.5rem !important;
}

.w-28 {
  width: 7rem !important;
}

.w-30 {
  width: 7.5rem !important;
}

.w-32 {
  width: 8rem !important;
}

.w-36 {
  width: 9rem !important;
}

.w-40 {
  width: 10rem !important;
}

.w-48 {
  width: 12rem !important;
}

.w-50 {
  width: 12.5rem !important;
}

.w-56 {
  width: 14rem !important;
}

.w-60 {
  width: 15rem !important;
}

.w-64 {
  width: 16rem !important;
}

.w-80 {
  width: 20rem !important;
}

.w-90 {
  width: 24rem !important;
}

.w-100 {
  width: 25rem !important;
}

.w-120 {
  width: 30rem !important;
}

.w-128 {
  width: 32rem !important;
}

.w-140 {
  width: 35rem !important;
}

.w-160 {
  width: 40rem !important;
}

.w-180 {
  width: 45rem !important;
}

.w-192 {
  width: 48rem !important;
}

.w-200 {
  width: 50rem !important;
}

.w-240 {
  width: 60rem !important;
}

.w-256 {
  width: 64rem !important;
}

.w-280 {
  width: 70rem !important;
}

.w-320 {
  width: 80rem !important;
}

.w-360 {
  width: 90rem !important;
}

.w-400 {
  width: 100rem !important;
}

.w-480 {
  width: 120rem !important;
}

.w-auto {
  width: auto !important;
}

.w-px {
  width: 1px !important;
}

.w-2px {
  width: 2px !important;
}

.w-1\/2 {
  width: 50% !important;
}

.w-1\/3 {
  width: 33.33333% !important;
}

.w-2\/3 {
  width: 66.66667% !important;
}

.w-1\/4 {
  width: 25% !important;
}

.w-2\/4 {
  width: 50% !important;
}

.w-3\/4 {
  width: 75% !important;
}

.w-1\/5 {
  width: 20% !important;
}

.w-2\/5 {
  width: 40% !important;
}

.w-3\/5 {
  width: 60% !important;
}

.w-4\/5 {
  width: 80% !important;
}

.w-1\/6 {
  width: 16.666667% !important;
}

.w-2\/6 {
  width: 33.333333% !important;
}

.w-3\/6 {
  width: 50% !important;
}

.w-4\/6 {
  width: 66.666667% !important;
}

.w-5\/6 {
  width: 83.333333% !important;
}

.w-1\/12 {
  width: 8.33333% !important;
}

.w-2\/12 {
  width: 16.66667% !important;
}

.w-3\/12 {
  width: 25% !important;
}

.w-4\/12 {
  width: 33.33333% !important;
}

.w-5\/12 {
  width: 41.66667% !important;
}

.w-6\/12 {
  width: 50% !important;
}

.w-7\/12 {
  width: 58.33333% !important;
}

.w-8\/12 {
  width: 66.66667% !important;
}

.w-9\/12 {
  width: 75% !important;
}

.w-10\/12 {
  width: 83.33333% !important;
}

.w-11\/12 {
  width: 91.66667% !important;
}

.w-full {
  width: 100% !important;
}

.w-screen {
  width: 100vw !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-10 {
  z-index: 10 !important;
}

.z-20 {
  z-index: 20 !important;
}

.z-30 {
  z-index: 30 !important;
}

.z-40 {
  z-index: 40 !important;
}

.z-50 {
  z-index: 50 !important;
}

.z-60 {
  z-index: 60 !important;
}

.z-70 {
  z-index: 70 !important;
}

.z-80 {
  z-index: 80 !important;
}

.z-90 {
  z-index: 90 !important;
}

.z-99 {
  z-index: 99 !important;
}

.z-999 {
  z-index: 999 !important;
}

.z-9999 {
  z-index: 9999 !important;
}

.z-99999 {
  z-index: 99999 !important;
}

.z-auto {
  z-index: auto !important;
}

.-z-1 {
  z-index: -1 !important;
}

.gap-0 {
  grid-gap: 0 !important;
  gap: 0 !important;
}

.gap-1 {
  grid-gap: 0.25rem !important;
  gap: 0.25rem !important;
}

.gap-2 {
  grid-gap: 0.5rem !important;
  gap: 0.5rem !important;
}

.gap-3 {
  grid-gap: 0.75rem !important;
  gap: 0.75rem !important;
}

.gap-4 {
  grid-gap: 1rem !important;
  gap: 1rem !important;
}

.gap-5 {
  grid-gap: 1.25rem !important;
  gap: 1.25rem !important;
}

.gap-6 {
  grid-gap: 1.5rem !important;
  gap: 1.5rem !important;
}

.gap-8 {
  grid-gap: 2rem !important;
  gap: 2rem !important;
}

.gap-10 {
  grid-gap: 2.5rem !important;
  gap: 2.5rem !important;
}

.gap-12 {
  grid-gap: 3rem !important;
  gap: 3rem !important;
}

.gap-14 {
  grid-gap: 3.5rem !important;
  gap: 3.5rem !important;
}

.gap-16 {
  grid-gap: 4rem !important;
  gap: 4rem !important;
}

.gap-18 {
  grid-gap: 4.5rem !important;
  gap: 4.5rem !important;
}

.gap-20 {
  grid-gap: 5rem !important;
  gap: 5rem !important;
}

.gap-22 {
  grid-gap: 5.5rem !important;
  gap: 5.5rem !important;
}

.gap-24 {
  grid-gap: 6rem !important;
  gap: 6rem !important;
}

.gap-26 {
  grid-gap: 6.5rem !important;
  gap: 6.5rem !important;
}

.gap-28 {
  grid-gap: 7rem !important;
  gap: 7rem !important;
}

.gap-30 {
  grid-gap: 7.5rem !important;
  gap: 7.5rem !important;
}

.gap-32 {
  grid-gap: 8rem !important;
  gap: 8rem !important;
}

.gap-36 {
  grid-gap: 9rem !important;
  gap: 9rem !important;
}

.gap-40 {
  grid-gap: 10rem !important;
  gap: 10rem !important;
}

.gap-48 {
  grid-gap: 12rem !important;
  gap: 12rem !important;
}

.gap-56 {
  grid-gap: 14rem !important;
  gap: 14rem !important;
}

.gap-64 {
  grid-gap: 16rem !important;
  gap: 16rem !important;
}

.gap-px {
  grid-gap: 1px !important;
  gap: 1px !important;
}

.gap-2px {
  grid-gap: 2px !important;
  gap: 2px !important;
}

.gap-x-0 {
  grid-column-gap: 0 !important;
  -moz-column-gap: 0 !important;
       column-gap: 0 !important;
}

.gap-x-1 {
  grid-column-gap: 0.25rem !important;
  -moz-column-gap: 0.25rem !important;
       column-gap: 0.25rem !important;
}

.gap-x-2 {
  grid-column-gap: 0.5rem !important;
  -moz-column-gap: 0.5rem !important;
       column-gap: 0.5rem !important;
}

.gap-x-3 {
  grid-column-gap: 0.75rem !important;
  -moz-column-gap: 0.75rem !important;
       column-gap: 0.75rem !important;
}

.gap-x-4 {
  grid-column-gap: 1rem !important;
  -moz-column-gap: 1rem !important;
       column-gap: 1rem !important;
}

.gap-x-5 {
  grid-column-gap: 1.25rem !important;
  -moz-column-gap: 1.25rem !important;
       column-gap: 1.25rem !important;
}

.gap-x-6 {
  grid-column-gap: 1.5rem !important;
  -moz-column-gap: 1.5rem !important;
       column-gap: 1.5rem !important;
}

.gap-x-8 {
  grid-column-gap: 2rem !important;
  -moz-column-gap: 2rem !important;
       column-gap: 2rem !important;
}

.gap-x-10 {
  grid-column-gap: 2.5rem !important;
  -moz-column-gap: 2.5rem !important;
       column-gap: 2.5rem !important;
}

.gap-x-12 {
  grid-column-gap: 3rem !important;
  -moz-column-gap: 3rem !important;
       column-gap: 3rem !important;
}

.gap-x-14 {
  grid-column-gap: 3.5rem !important;
  -moz-column-gap: 3.5rem !important;
       column-gap: 3.5rem !important;
}

.gap-x-16 {
  grid-column-gap: 4rem !important;
  -moz-column-gap: 4rem !important;
       column-gap: 4rem !important;
}

.gap-x-18 {
  grid-column-gap: 4.5rem !important;
  -moz-column-gap: 4.5rem !important;
       column-gap: 4.5rem !important;
}

.gap-x-20 {
  grid-column-gap: 5rem !important;
  -moz-column-gap: 5rem !important;
       column-gap: 5rem !important;
}

.gap-x-22 {
  grid-column-gap: 5.5rem !important;
  -moz-column-gap: 5.5rem !important;
       column-gap: 5.5rem !important;
}

.gap-x-24 {
  grid-column-gap: 6rem !important;
  -moz-column-gap: 6rem !important;
       column-gap: 6rem !important;
}

.gap-x-26 {
  grid-column-gap: 6.5rem !important;
  -moz-column-gap: 6.5rem !important;
       column-gap: 6.5rem !important;
}

.gap-x-28 {
  grid-column-gap: 7rem !important;
  -moz-column-gap: 7rem !important;
       column-gap: 7rem !important;
}

.gap-x-30 {
  grid-column-gap: 7.5rem !important;
  -moz-column-gap: 7.5rem !important;
       column-gap: 7.5rem !important;
}

.gap-x-32 {
  grid-column-gap: 8rem !important;
  -moz-column-gap: 8rem !important;
       column-gap: 8rem !important;
}

.gap-x-36 {
  grid-column-gap: 9rem !important;
  -moz-column-gap: 9rem !important;
       column-gap: 9rem !important;
}

.gap-x-40 {
  grid-column-gap: 10rem !important;
  -moz-column-gap: 10rem !important;
       column-gap: 10rem !important;
}

.gap-x-48 {
  grid-column-gap: 12rem !important;
  -moz-column-gap: 12rem !important;
       column-gap: 12rem !important;
}

.gap-x-56 {
  grid-column-gap: 14rem !important;
  -moz-column-gap: 14rem !important;
       column-gap: 14rem !important;
}

.gap-x-64 {
  grid-column-gap: 16rem !important;
  -moz-column-gap: 16rem !important;
       column-gap: 16rem !important;
}

.gap-x-px {
  grid-column-gap: 1px !important;
  -moz-column-gap: 1px !important;
       column-gap: 1px !important;
}

.gap-x-2px {
  grid-column-gap: 2px !important;
  -moz-column-gap: 2px !important;
       column-gap: 2px !important;
}

.gap-y-0 {
  grid-row-gap: 0 !important;
  row-gap: 0 !important;
}

.gap-y-1 {
  grid-row-gap: 0.25rem !important;
  row-gap: 0.25rem !important;
}

.gap-y-2 {
  grid-row-gap: 0.5rem !important;
  row-gap: 0.5rem !important;
}

.gap-y-3 {
  grid-row-gap: 0.75rem !important;
  row-gap: 0.75rem !important;
}

.gap-y-4 {
  grid-row-gap: 1rem !important;
  row-gap: 1rem !important;
}

.gap-y-5 {
  grid-row-gap: 1.25rem !important;
  row-gap: 1.25rem !important;
}

.gap-y-6 {
  grid-row-gap: 1.5rem !important;
  row-gap: 1.5rem !important;
}

.gap-y-8 {
  grid-row-gap: 2rem !important;
  row-gap: 2rem !important;
}

.gap-y-10 {
  grid-row-gap: 2.5rem !important;
  row-gap: 2.5rem !important;
}

.gap-y-12 {
  grid-row-gap: 3rem !important;
  row-gap: 3rem !important;
}

.gap-y-14 {
  grid-row-gap: 3.5rem !important;
  row-gap: 3.5rem !important;
}

.gap-y-16 {
  grid-row-gap: 4rem !important;
  row-gap: 4rem !important;
}

.gap-y-18 {
  grid-row-gap: 4.5rem !important;
  row-gap: 4.5rem !important;
}

.gap-y-20 {
  grid-row-gap: 5rem !important;
  row-gap: 5rem !important;
}

.gap-y-22 {
  grid-row-gap: 5.5rem !important;
  row-gap: 5.5rem !important;
}

.gap-y-24 {
  grid-row-gap: 6rem !important;
  row-gap: 6rem !important;
}

.gap-y-26 {
  grid-row-gap: 6.5rem !important;
  row-gap: 6.5rem !important;
}

.gap-y-28 {
  grid-row-gap: 7rem !important;
  row-gap: 7rem !important;
}

.gap-y-30 {
  grid-row-gap: 7.5rem !important;
  row-gap: 7.5rem !important;
}

.gap-y-32 {
  grid-row-gap: 8rem !important;
  row-gap: 8rem !important;
}

.gap-y-36 {
  grid-row-gap: 9rem !important;
  row-gap: 9rem !important;
}

.gap-y-40 {
  grid-row-gap: 10rem !important;
  row-gap: 10rem !important;
}

.gap-y-48 {
  grid-row-gap: 12rem !important;
  row-gap: 12rem !important;
}

.gap-y-56 {
  grid-row-gap: 14rem !important;
  row-gap: 14rem !important;
}

.gap-y-64 {
  grid-row-gap: 16rem !important;
  row-gap: 16rem !important;
}

.gap-y-px {
  grid-row-gap: 1px !important;
  row-gap: 1px !important;
}

.gap-y-2px {
  grid-row-gap: 2px !important;
  row-gap: 2px !important;
}

.grid-flow-row {
  grid-auto-flow: row !important;
}

.grid-flow-col {
  grid-auto-flow: column !important;
}

.grid-flow-row-dense {
  grid-auto-flow: row dense !important;
}

.grid-flow-col-dense {
  grid-auto-flow: column dense !important;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
}

.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr)) !important;
}

.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr)) !important;
}

.grid-cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr)) !important;
}

.grid-cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr)) !important;
}

.grid-cols-9 {
  grid-template-columns: repeat(9, minmax(0, 1fr)) !important;
}

.grid-cols-10 {
  grid-template-columns: repeat(10, minmax(0, 1fr)) !important;
}

.grid-cols-11 {
  grid-template-columns: repeat(11, minmax(0, 1fr)) !important;
}

.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr)) !important;
}

.grid-cols-none {
  grid-template-columns: none !important;
}

.col-auto {
  grid-column: auto !important;
}

.col-span-1 {
  grid-column: span 1 / span 1 !important;
}

.col-span-2 {
  grid-column: span 2 / span 2 !important;
}

.col-span-3 {
  grid-column: span 3 / span 3 !important;
}

.col-span-4 {
  grid-column: span 4 / span 4 !important;
}

.col-span-5 {
  grid-column: span 5 / span 5 !important;
}

.col-span-6 {
  grid-column: span 6 / span 6 !important;
}

.col-span-7 {
  grid-column: span 7 / span 7 !important;
}

.col-span-8 {
  grid-column: span 8 / span 8 !important;
}

.col-span-9 {
  grid-column: span 9 / span 9 !important;
}

.col-span-10 {
  grid-column: span 10 / span 10 !important;
}

.col-span-11 {
  grid-column: span 11 / span 11 !important;
}

.col-span-12 {
  grid-column: span 12 / span 12 !important;
}

.col-start-1 {
  grid-column-start: 1 !important;
}

.col-start-2 {
  grid-column-start: 2 !important;
}

.col-start-3 {
  grid-column-start: 3 !important;
}

.col-start-4 {
  grid-column-start: 4 !important;
}

.col-start-5 {
  grid-column-start: 5 !important;
}

.col-start-6 {
  grid-column-start: 6 !important;
}

.col-start-7 {
  grid-column-start: 7 !important;
}

.col-start-8 {
  grid-column-start: 8 !important;
}

.col-start-9 {
  grid-column-start: 9 !important;
}

.col-start-10 {
  grid-column-start: 10 !important;
}

.col-start-11 {
  grid-column-start: 11 !important;
}

.col-start-12 {
  grid-column-start: 12 !important;
}

.col-start-13 {
  grid-column-start: 13 !important;
}

.col-start-auto {
  grid-column-start: auto !important;
}

.col-end-1 {
  grid-column-end: 1 !important;
}

.col-end-2 {
  grid-column-end: 2 !important;
}

.col-end-3 {
  grid-column-end: 3 !important;
}

.col-end-4 {
  grid-column-end: 4 !important;
}

.col-end-5 {
  grid-column-end: 5 !important;
}

.col-end-6 {
  grid-column-end: 6 !important;
}

.col-end-7 {
  grid-column-end: 7 !important;
}

.col-end-8 {
  grid-column-end: 8 !important;
}

.col-end-9 {
  grid-column-end: 9 !important;
}

.col-end-10 {
  grid-column-end: 10 !important;
}

.col-end-11 {
  grid-column-end: 11 !important;
}

.col-end-12 {
  grid-column-end: 12 !important;
}

.col-end-13 {
  grid-column-end: 13 !important;
}

.col-end-auto {
  grid-column-end: auto !important;
}

.grid-rows-1 {
  grid-template-rows: repeat(1, minmax(0, 1fr)) !important;
}

.grid-rows-2 {
  grid-template-rows: repeat(2, minmax(0, 1fr)) !important;
}

.grid-rows-3 {
  grid-template-rows: repeat(3, minmax(0, 1fr)) !important;
}

.grid-rows-4 {
  grid-template-rows: repeat(4, minmax(0, 1fr)) !important;
}

.grid-rows-5 {
  grid-template-rows: repeat(5, minmax(0, 1fr)) !important;
}

.grid-rows-6 {
  grid-template-rows: repeat(6, minmax(0, 1fr)) !important;
}

.grid-rows-none {
  grid-template-rows: none !important;
}

.row-auto {
  grid-row: auto !important;
}

.row-span-1 {
  grid-row: span 1 / span 1 !important;
}

.row-span-2 {
  grid-row: span 2 / span 2 !important;
}

.row-span-3 {
  grid-row: span 3 / span 3 !important;
}

.row-span-4 {
  grid-row: span 4 / span 4 !important;
}

.row-span-5 {
  grid-row: span 5 / span 5 !important;
}

.row-span-6 {
  grid-row: span 6 / span 6 !important;
}

.row-start-1 {
  grid-row-start: 1 !important;
}

.row-start-2 {
  grid-row-start: 2 !important;
}

.row-start-3 {
  grid-row-start: 3 !important;
}

.row-start-4 {
  grid-row-start: 4 !important;
}

.row-start-5 {
  grid-row-start: 5 !important;
}

.row-start-6 {
  grid-row-start: 6 !important;
}

.row-start-7 {
  grid-row-start: 7 !important;
}

.row-start-auto {
  grid-row-start: auto !important;
}

.row-end-1 {
  grid-row-end: 1 !important;
}

.row-end-2 {
  grid-row-end: 2 !important;
}

.row-end-3 {
  grid-row-end: 3 !important;
}

.row-end-4 {
  grid-row-end: 4 !important;
}

.row-end-5 {
  grid-row-end: 5 !important;
}

.row-end-6 {
  grid-row-end: 6 !important;
}

.row-end-7 {
  grid-row-end: 7 !important;
}

.row-end-auto {
  grid-row-end: auto !important;
}

.transform {
  --transform-translate-x: 0 !important;
  --transform-translate-y: 0 !important;
  --transform-rotate: 0 !important;
  --transform-skew-x: 0 !important;
  --transform-skew-y: 0 !important;
  --transform-scale-x: 1 !important;
  --transform-scale-y: 1 !important;
  transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y)) !important;
}

.transform-none {
  transform: none !important;
}

.origin-center {
  transform-origin: center !important;
}

.origin-top {
  transform-origin: top !important;
}

.origin-top-right {
  transform-origin: top right !important;
}

.origin-right {
  transform-origin: right !important;
}

.origin-bottom-right {
  transform-origin: bottom right !important;
}

.origin-bottom {
  transform-origin: bottom !important;
}

.origin-bottom-left {
  transform-origin: bottom left !important;
}

.origin-left {
  transform-origin: left !important;
}

.origin-top-left {
  transform-origin: top left !important;
}

.scale-0 {
  --transform-scale-x: 0 !important;
  --transform-scale-y: 0 !important;
}

.scale-50 {
  --transform-scale-x: .5 !important;
  --transform-scale-y: .5 !important;
}

.scale-75 {
  --transform-scale-x: .75 !important;
  --transform-scale-y: .75 !important;
}

.scale-90 {
  --transform-scale-x: .9 !important;
  --transform-scale-y: .9 !important;
}

.scale-95 {
  --transform-scale-x: .95 !important;
  --transform-scale-y: .95 !important;
}

.scale-100 {
  --transform-scale-x: 1 !important;
  --transform-scale-y: 1 !important;
}

.scale-105 {
  --transform-scale-x: 1.05 !important;
  --transform-scale-y: 1.05 !important;
}

.scale-110 {
  --transform-scale-x: 1.1 !important;
  --transform-scale-y: 1.1 !important;
}

.scale-125 {
  --transform-scale-x: 1.25 !important;
  --transform-scale-y: 1.25 !important;
}

.scale-150 {
  --transform-scale-x: 1.5 !important;
  --transform-scale-y: 1.5 !important;
}

.scale-x-0 {
  --transform-scale-x: 0 !important;
}

.scale-x-50 {
  --transform-scale-x: .5 !important;
}

.scale-x-75 {
  --transform-scale-x: .75 !important;
}

.scale-x-90 {
  --transform-scale-x: .9 !important;
}

.scale-x-95 {
  --transform-scale-x: .95 !important;
}

.scale-x-100 {
  --transform-scale-x: 1 !important;
}

.scale-x-105 {
  --transform-scale-x: 1.05 !important;
}

.scale-x-110 {
  --transform-scale-x: 1.1 !important;
}

.scale-x-125 {
  --transform-scale-x: 1.25 !important;
}

.scale-x-150 {
  --transform-scale-x: 1.5 !important;
}

.scale-y-0 {
  --transform-scale-y: 0 !important;
}

.scale-y-50 {
  --transform-scale-y: .5 !important;
}

.scale-y-75 {
  --transform-scale-y: .75 !important;
}

.scale-y-90 {
  --transform-scale-y: .9 !important;
}

.scale-y-95 {
  --transform-scale-y: .95 !important;
}

.scale-y-100 {
  --transform-scale-y: 1 !important;
}

.scale-y-105 {
  --transform-scale-y: 1.05 !important;
}

.scale-y-110 {
  --transform-scale-y: 1.1 !important;
}

.scale-y-125 {
  --transform-scale-y: 1.25 !important;
}

.scale-y-150 {
  --transform-scale-y: 1.5 !important;
}

.rotate-0 {
  --transform-rotate: 0 !important;
}

.rotate-15 {
  --transform-rotate: 15deg !important;
}

.rotate-30 {
  --transform-rotate: 30deg !important;
}

.rotate-45 {
  --transform-rotate: 45deg !important;
}

.rotate-60 {
  --transform-rotate: 60deg !important;
}

.rotate-90 {
  --transform-rotate: 90deg !important;
}

.rotate-180 {
  --transform-rotate: 180deg !important;
}

.rotate-270 {
  --transform-rotate: 270deg !important;
}

.-rotate-180 {
  --transform-rotate: -180deg !important;
}

.-rotate-90 {
  --transform-rotate: -90deg !important;
}

.-rotate-45 {
  --transform-rotate: -45deg !important;
}

.-rotate-270 {
  --transform-rotate: 270deg !important;
}

.translate-x-0 {
  --transform-translate-x: 0 !important;
}

.translate-x-1 {
  --transform-translate-x: 0.25rem !important;
}

.translate-x-2 {
  --transform-translate-x: 0.5rem !important;
}

.translate-x-3 {
  --transform-translate-x: 0.75rem !important;
}

.translate-x-4 {
  --transform-translate-x: 1rem !important;
}

.translate-x-5 {
  --transform-translate-x: 1.25rem !important;
}

.translate-x-6 {
  --transform-translate-x: 1.5rem !important;
}

.translate-x-8 {
  --transform-translate-x: 2rem !important;
}

.translate-x-10 {
  --transform-translate-x: 2.5rem !important;
}

.translate-x-12 {
  --transform-translate-x: 3rem !important;
}

.translate-x-14 {
  --transform-translate-x: 3.5rem !important;
}

.translate-x-16 {
  --transform-translate-x: 4rem !important;
}

.translate-x-18 {
  --transform-translate-x: 4.5rem !important;
}

.translate-x-20 {
  --transform-translate-x: 5rem !important;
}

.translate-x-22 {
  --transform-translate-x: 5.5rem !important;
}

.translate-x-24 {
  --transform-translate-x: 6rem !important;
}

.translate-x-26 {
  --transform-translate-x: 6.5rem !important;
}

.translate-x-28 {
  --transform-translate-x: 7rem !important;
}

.translate-x-30 {
  --transform-translate-x: 7.5rem !important;
}

.translate-x-32 {
  --transform-translate-x: 8rem !important;
}

.translate-x-36 {
  --transform-translate-x: 9rem !important;
}

.translate-x-40 {
  --transform-translate-x: 10rem !important;
}

.translate-x-48 {
  --transform-translate-x: 12rem !important;
}

.translate-x-56 {
  --transform-translate-x: 14rem !important;
}

.translate-x-64 {
  --transform-translate-x: 16rem !important;
}

.translate-x-px {
  --transform-translate-x: 1px !important;
}

.translate-x-2px {
  --transform-translate-x: 2px !important;
}

.-translate-x-1 {
  --transform-translate-x: -0.25rem !important;
}

.-translate-x-2 {
  --transform-translate-x: -0.5rem !important;
}

.-translate-x-3 {
  --transform-translate-x: -0.75rem !important;
}

.-translate-x-4 {
  --transform-translate-x: -1rem !important;
}

.-translate-x-5 {
  --transform-translate-x: -1.25rem !important;
}

.-translate-x-6 {
  --transform-translate-x: -1.5rem !important;
}

.-translate-x-8 {
  --transform-translate-x: -2rem !important;
}

.-translate-x-10 {
  --transform-translate-x: -2.5rem !important;
}

.-translate-x-12 {
  --transform-translate-x: -3rem !important;
}

.-translate-x-14 {
  --transform-translate-x: -3.5rem !important;
}

.-translate-x-16 {
  --transform-translate-x: -4rem !important;
}

.-translate-x-18 {
  --transform-translate-x: -4.5rem !important;
}

.-translate-x-20 {
  --transform-translate-x: -5rem !important;
}

.-translate-x-22 {
  --transform-translate-x: -5.5rem !important;
}

.-translate-x-24 {
  --transform-translate-x: -6rem !important;
}

.-translate-x-26 {
  --transform-translate-x: -6.5rem !important;
}

.-translate-x-28 {
  --transform-translate-x: -7rem !important;
}

.-translate-x-30 {
  --transform-translate-x: -7.5rem !important;
}

.-translate-x-32 {
  --transform-translate-x: -8rem !important;
}

.-translate-x-36 {
  --transform-translate-x: -9rem !important;
}

.-translate-x-40 {
  --transform-translate-x: -10rem !important;
}

.-translate-x-48 {
  --transform-translate-x: -12rem !important;
}

.-translate-x-56 {
  --transform-translate-x: -14rem !important;
}

.-translate-x-64 {
  --transform-translate-x: -16rem !important;
}

.-translate-x-px {
  --transform-translate-x: -1px !important;
}

.-translate-x-2px {
  --transform-translate-x: -2px !important;
}

.-translate-x-full {
  --transform-translate-x: -100% !important;
}

.-translate-x-1\/2 {
  --transform-translate-x: -50% !important;
}

.translate-x-1\/2 {
  --transform-translate-x: 50% !important;
}

.translate-x-full {
  --transform-translate-x: 100% !important;
}

.translate-y-0 {
  --transform-translate-y: 0 !important;
}

.translate-y-1 {
  --transform-translate-y: 0.25rem !important;
}

.translate-y-2 {
  --transform-translate-y: 0.5rem !important;
}

.translate-y-3 {
  --transform-translate-y: 0.75rem !important;
}

.translate-y-4 {
  --transform-translate-y: 1rem !important;
}

.translate-y-5 {
  --transform-translate-y: 1.25rem !important;
}

.translate-y-6 {
  --transform-translate-y: 1.5rem !important;
}

.translate-y-8 {
  --transform-translate-y: 2rem !important;
}

.translate-y-10 {
  --transform-translate-y: 2.5rem !important;
}

.translate-y-12 {
  --transform-translate-y: 3rem !important;
}

.translate-y-14 {
  --transform-translate-y: 3.5rem !important;
}

.translate-y-16 {
  --transform-translate-y: 4rem !important;
}

.translate-y-18 {
  --transform-translate-y: 4.5rem !important;
}

.translate-y-20 {
  --transform-translate-y: 5rem !important;
}

.translate-y-22 {
  --transform-translate-y: 5.5rem !important;
}

.translate-y-24 {
  --transform-translate-y: 6rem !important;
}

.translate-y-26 {
  --transform-translate-y: 6.5rem !important;
}

.translate-y-28 {
  --transform-translate-y: 7rem !important;
}

.translate-y-30 {
  --transform-translate-y: 7.5rem !important;
}

.translate-y-32 {
  --transform-translate-y: 8rem !important;
}

.translate-y-36 {
  --transform-translate-y: 9rem !important;
}

.translate-y-40 {
  --transform-translate-y: 10rem !important;
}

.translate-y-48 {
  --transform-translate-y: 12rem !important;
}

.translate-y-56 {
  --transform-translate-y: 14rem !important;
}

.translate-y-64 {
  --transform-translate-y: 16rem !important;
}

.translate-y-px {
  --transform-translate-y: 1px !important;
}

.translate-y-2px {
  --transform-translate-y: 2px !important;
}

.-translate-y-1 {
  --transform-translate-y: -0.25rem !important;
}

.-translate-y-2 {
  --transform-translate-y: -0.5rem !important;
}

.-translate-y-3 {
  --transform-translate-y: -0.75rem !important;
}

.-translate-y-4 {
  --transform-translate-y: -1rem !important;
}

.-translate-y-5 {
  --transform-translate-y: -1.25rem !important;
}

.-translate-y-6 {
  --transform-translate-y: -1.5rem !important;
}

.-translate-y-8 {
  --transform-translate-y: -2rem !important;
}

.-translate-y-10 {
  --transform-translate-y: -2.5rem !important;
}

.-translate-y-12 {
  --transform-translate-y: -3rem !important;
}

.-translate-y-14 {
  --transform-translate-y: -3.5rem !important;
}

.-translate-y-16 {
  --transform-translate-y: -4rem !important;
}

.-translate-y-18 {
  --transform-translate-y: -4.5rem !important;
}

.-translate-y-20 {
  --transform-translate-y: -5rem !important;
}

.-translate-y-22 {
  --transform-translate-y: -5.5rem !important;
}

.-translate-y-24 {
  --transform-translate-y: -6rem !important;
}

.-translate-y-26 {
  --transform-translate-y: -6.5rem !important;
}

.-translate-y-28 {
  --transform-translate-y: -7rem !important;
}

.-translate-y-30 {
  --transform-translate-y: -7.5rem !important;
}

.-translate-y-32 {
  --transform-translate-y: -8rem !important;
}

.-translate-y-36 {
  --transform-translate-y: -9rem !important;
}

.-translate-y-40 {
  --transform-translate-y: -10rem !important;
}

.-translate-y-48 {
  --transform-translate-y: -12rem !important;
}

.-translate-y-56 {
  --transform-translate-y: -14rem !important;
}

.-translate-y-64 {
  --transform-translate-y: -16rem !important;
}

.-translate-y-px {
  --transform-translate-y: -1px !important;
}

.-translate-y-2px {
  --transform-translate-y: -2px !important;
}

.-translate-y-full {
  --transform-translate-y: -100% !important;
}

.-translate-y-1\/2 {
  --transform-translate-y: -50% !important;
}

.translate-y-1\/2 {
  --transform-translate-y: 50% !important;
}

.translate-y-full {
  --transform-translate-y: 100% !important;
}

.skew-x-0 {
  --transform-skew-x: 0 !important;
}

.skew-x-3 {
  --transform-skew-x: 3deg !important;
}

.skew-x-6 {
  --transform-skew-x: 6deg !important;
}

.skew-x-12 {
  --transform-skew-x: 12deg !important;
}

.-skew-x-12 {
  --transform-skew-x: -12deg !important;
}

.-skew-x-6 {
  --transform-skew-x: -6deg !important;
}

.-skew-x-3 {
  --transform-skew-x: -3deg !important;
}

.skew-y-0 {
  --transform-skew-y: 0 !important;
}

.skew-y-3 {
  --transform-skew-y: 3deg !important;
}

.skew-y-6 {
  --transform-skew-y: 6deg !important;
}

.skew-y-12 {
  --transform-skew-y: 12deg !important;
}

.-skew-y-12 {
  --transform-skew-y: -12deg !important;
}

.-skew-y-6 {
  --transform-skew-y: -6deg !important;
}

.-skew-y-3 {
  --transform-skew-y: -3deg !important;
}

.transition-none {
  transition-property: none !important;
}

.transition-all {
  transition-property: all !important;
}

.transition {
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform !important;
}

.transition-colors {
  transition-property: background-color, border-color, color, fill, stroke !important;
}

.transition-opacity {
  transition-property: opacity !important;
}

.transition-shadow {
  transition-property: box-shadow !important;
}

.transition-transform {
  transition-property: transform !important;
}

.ease-linear {
  transition-timing-function: linear !important;
}

.ease-in {
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1) !important;
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1) !important;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
}

.duration-75 {
  transition-duration: 75ms !important;
}

.duration-100 {
  transition-duration: 100ms !important;
}

.duration-150 {
  transition-duration: 150ms !important;
}

.duration-200 {
  transition-duration: 200ms !important;
}

.duration-300 {
  transition-duration: 300ms !important;
}

.duration-500 {
  transition-duration: 500ms !important;
}

.duration-700 {
  transition-duration: 700ms !important;
}

.duration-1000 {
  transition-duration: 1000ms !important;
}

.delay-75 {
  transition-delay: 75ms !important;
}

.delay-100 {
  transition-delay: 100ms !important;
}

.delay-150 {
  transition-delay: 150ms !important;
}

.delay-200 {
  transition-delay: 200ms !important;
}

.delay-300 {
  transition-delay: 300ms !important;
}

.delay-500 {
  transition-delay: 500ms !important;
}

.delay-700 {
  transition-delay: 700ms !important;
}

.delay-1000 {
  transition-delay: 1000ms !important;
}

.text-black-contrast {
  color: #FFFFFF !important;
}

.bg-black-contrast {
  background-color: #FFFFFF !important;
}

.text-white-contrast {
  color: #252F3F !important;
}

.bg-white-contrast {
  background-color: #252F3F !important;
}

.text-gray-50-contrast {
  color: #161E2E !important;
}

.bg-gray-50-contrast {
  background-color: #161E2E !important;
}

.text-gray-100-contrast {
  color: #161E2E !important;
}

.bg-gray-100-contrast {
  background-color: #161E2E !important;
}

.text-gray-200-contrast {
  color: #161E2E !important;
}

.bg-gray-200-contrast {
  background-color: #161E2E !important;
}

.text-gray-300-contrast {
  color: #161E2E !important;
}

.bg-gray-300-contrast {
  background-color: #161E2E !important;
}

.text-gray-400-contrast {
  color: #161E2E !important;
}

.bg-gray-400-contrast {
  background-color: #161E2E !important;
}

.text-gray-500-contrast {
  color: #161E2E !important;
}

.bg-gray-500-contrast {
  background-color: #161E2E !important;
}

.text-gray-600-contrast {
  color: #F9FAFB !important;
}

.bg-gray-600-contrast {
  background-color: #F9FAFB !important;
}

.text-gray-700-contrast {
  color: #F9FAFB !important;
}

.bg-gray-700-contrast {
  background-color: #F9FAFB !important;
}

.text-gray-800-contrast {
  color: #F9FAFB !important;
}

.bg-gray-800-contrast {
  background-color: #F9FAFB !important;
}

.text-gray-900-contrast {
  color: #F9FAFB !important;
}

.bg-gray-900-contrast {
  background-color: #F9FAFB !important;
}

.text-gray-contrast {
  color: #161E2E !important;
}

.bg-gray-contrast {
  background-color: #161E2E !important;
}

.text-cool-gray-50-contrast {
  color: #1A202E !important;
}

.bg-cool-gray-50-contrast {
  background-color: #1A202E !important;
}

.text-cool-gray-100-contrast {
  color: #1A202E !important;
}

.bg-cool-gray-100-contrast {
  background-color: #1A202E !important;
}

.text-cool-gray-200-contrast {
  color: #1A202E !important;
}

.bg-cool-gray-200-contrast {
  background-color: #1A202E !important;
}

.text-cool-gray-300-contrast {
  color: #1A202E !important;
}

.bg-cool-gray-300-contrast {
  background-color: #1A202E !important;
}

.text-cool-gray-400-contrast {
  color: #1A202E !important;
}

.bg-cool-gray-400-contrast {
  background-color: #1A202E !important;
}

.text-cool-gray-500-contrast {
  color: #1A202E !important;
}

.bg-cool-gray-500-contrast {
  background-color: #1A202E !important;
}

.text-cool-gray-600-contrast {
  color: #FBFDFE !important;
}

.bg-cool-gray-600-contrast {
  background-color: #FBFDFE !important;
}

.text-cool-gray-700-contrast {
  color: #FBFDFE !important;
}

.bg-cool-gray-700-contrast {
  background-color: #FBFDFE !important;
}

.text-cool-gray-800-contrast {
  color: #FBFDFE !important;
}

.bg-cool-gray-800-contrast {
  background-color: #FBFDFE !important;
}

.text-cool-gray-900-contrast {
  color: #FBFDFE !important;
}

.bg-cool-gray-900-contrast {
  background-color: #FBFDFE !important;
}

.text-cool-gray-contrast {
  color: #1A202E !important;
}

.bg-cool-gray-contrast {
  background-color: #1A202E !important;
}

.text-red-50-contrast {
  color: #771D1D !important;
}

.bg-red-50-contrast {
  background-color: #771D1D !important;
}

.text-red-100-contrast {
  color: #771D1D !important;
}

.bg-red-100-contrast {
  background-color: #771D1D !important;
}

.text-red-200-contrast {
  color: #771D1D !important;
}

.bg-red-200-contrast {
  background-color: #771D1D !important;
}

.text-red-300-contrast {
  color: #771D1D !important;
}

.bg-red-300-contrast {
  background-color: #771D1D !important;
}

.text-red-400-contrast {
  color: #771D1D !important;
}

.bg-red-400-contrast {
  background-color: #771D1D !important;
}

.text-red-500-contrast {
  color: #771D1D !important;
}

.bg-red-500-contrast {
  background-color: #771D1D !important;
}

.text-red-600-contrast {
  color: #FDF2F2 !important;
}

.bg-red-600-contrast {
  background-color: #FDF2F2 !important;
}

.text-red-700-contrast {
  color: #FDF2F2 !important;
}

.bg-red-700-contrast {
  background-color: #FDF2F2 !important;
}

.text-red-800-contrast {
  color: #FDF2F2 !important;
}

.bg-red-800-contrast {
  background-color: #FDF2F2 !important;
}

.text-red-900-contrast {
  color: #FDF2F2 !important;
}

.bg-red-900-contrast {
  background-color: #FDF2F2 !important;
}

.text-red-contrast {
  color: #771D1D !important;
}

.bg-red-contrast {
  background-color: #771D1D !important;
}

.text-orange-50-contrast {
  color: #771D1D !important;
}

.bg-orange-50-contrast {
  background-color: #771D1D !important;
}

.text-orange-100-contrast {
  color: #771D1D !important;
}

.bg-orange-100-contrast {
  background-color: #771D1D !important;
}

.text-orange-200-contrast {
  color: #771D1D !important;
}

.bg-orange-200-contrast {
  background-color: #771D1D !important;
}

.text-orange-300-contrast {
  color: #771D1D !important;
}

.bg-orange-300-contrast {
  background-color: #771D1D !important;
}

.text-orange-400-contrast {
  color: #771D1D !important;
}

.bg-orange-400-contrast {
  background-color: #771D1D !important;
}

.text-orange-500-contrast {
  color: #771D1D !important;
}

.bg-orange-500-contrast {
  background-color: #771D1D !important;
}

.text-orange-600-contrast {
  color: #FFF8F1 !important;
}

.bg-orange-600-contrast {
  background-color: #FFF8F1 !important;
}

.text-orange-700-contrast {
  color: #FFF8F1 !important;
}

.bg-orange-700-contrast {
  background-color: #FFF8F1 !important;
}

.text-orange-800-contrast {
  color: #FFF8F1 !important;
}

.bg-orange-800-contrast {
  background-color: #FFF8F1 !important;
}

.text-orange-900-contrast {
  color: #FFF8F1 !important;
}

.bg-orange-900-contrast {
  background-color: #FFF8F1 !important;
}

.text-orange-contrast {
  color: #771D1D !important;
}

.bg-orange-contrast {
  background-color: #771D1D !important;
}

.text-yellow-50-contrast {
  color: #633112 !important;
}

.bg-yellow-50-contrast {
  background-color: #633112 !important;
}

.text-yellow-100-contrast {
  color: #633112 !important;
}

.bg-yellow-100-contrast {
  background-color: #633112 !important;
}

.text-yellow-200-contrast {
  color: #633112 !important;
}

.bg-yellow-200-contrast {
  background-color: #633112 !important;
}

.text-yellow-300-contrast {
  color: #633112 !important;
}

.bg-yellow-300-contrast {
  background-color: #633112 !important;
}

.text-yellow-400-contrast {
  color: #633112 !important;
}

.bg-yellow-400-contrast {
  background-color: #633112 !important;
}

.text-yellow-500-contrast {
  color: #633112 !important;
}

.bg-yellow-500-contrast {
  background-color: #633112 !important;
}

.text-yellow-600-contrast {
  color: #FDFDEA !important;
}

.bg-yellow-600-contrast {
  background-color: #FDFDEA !important;
}

.text-yellow-700-contrast {
  color: #FDFDEA !important;
}

.bg-yellow-700-contrast {
  background-color: #FDFDEA !important;
}

.text-yellow-800-contrast {
  color: #FDFDEA !important;
}

.bg-yellow-800-contrast {
  background-color: #FDFDEA !important;
}

.text-yellow-900-contrast {
  color: #FDFDEA !important;
}

.bg-yellow-900-contrast {
  background-color: #FDFDEA !important;
}

.text-yellow-contrast {
  color: #633112 !important;
}

.bg-yellow-contrast {
  background-color: #633112 !important;
}

.text-green-50-contrast {
  color: #014737 !important;
}

.bg-green-50-contrast {
  background-color: #014737 !important;
}

.text-green-100-contrast {
  color: #014737 !important;
}

.bg-green-100-contrast {
  background-color: #014737 !important;
}

.text-green-200-contrast {
  color: #014737 !important;
}

.bg-green-200-contrast {
  background-color: #014737 !important;
}

.text-green-300-contrast {
  color: #014737 !important;
}

.bg-green-300-contrast {
  background-color: #014737 !important;
}

.text-green-400-contrast {
  color: #014737 !important;
}

.bg-green-400-contrast {
  background-color: #014737 !important;
}

.text-green-500-contrast {
  color: #F3FAF7 !important;
}

.bg-green-500-contrast {
  background-color: #F3FAF7 !important;
}

.text-green-600-contrast {
  color: #F3FAF7 !important;
}

.bg-green-600-contrast {
  background-color: #F3FAF7 !important;
}

.text-green-700-contrast {
  color: #F3FAF7 !important;
}

.bg-green-700-contrast {
  background-color: #F3FAF7 !important;
}

.text-green-800-contrast {
  color: #F3FAF7 !important;
}

.bg-green-800-contrast {
  background-color: #F3FAF7 !important;
}

.text-green-900-contrast {
  color: #F3FAF7 !important;
}

.bg-green-900-contrast {
  background-color: #F3FAF7 !important;
}

.text-green-contrast {
  color: #F3FAF7 !important;
}

.bg-green-contrast {
  background-color: #F3FAF7 !important;
}

.text-teal-50-contrast {
  color: #014451 !important;
}

.bg-teal-50-contrast {
  background-color: #014451 !important;
}

.text-teal-100-contrast {
  color: #014451 !important;
}

.bg-teal-100-contrast {
  background-color: #014451 !important;
}

.text-teal-200-contrast {
  color: #014451 !important;
}

.bg-teal-200-contrast {
  background-color: #014451 !important;
}

.text-teal-300-contrast {
  color: #014451 !important;
}

.bg-teal-300-contrast {
  background-color: #014451 !important;
}

.text-teal-400-contrast {
  color: #014451 !important;
}

.bg-teal-400-contrast {
  background-color: #014451 !important;
}

.text-teal-500-contrast {
  color: #EDFAFA !important;
}

.bg-teal-500-contrast {
  background-color: #EDFAFA !important;
}

.text-teal-600-contrast {
  color: #EDFAFA !important;
}

.bg-teal-600-contrast {
  background-color: #EDFAFA !important;
}

.text-teal-700-contrast {
  color: #EDFAFA !important;
}

.bg-teal-700-contrast {
  background-color: #EDFAFA !important;
}

.text-teal-800-contrast {
  color: #EDFAFA !important;
}

.bg-teal-800-contrast {
  background-color: #EDFAFA !important;
}

.text-teal-900-contrast {
  color: #EDFAFA !important;
}

.bg-teal-900-contrast {
  background-color: #EDFAFA !important;
}

.text-teal-contrast {
  color: #EDFAFA !important;
}

.bg-teal-contrast {
  background-color: #EDFAFA !important;
}

.text-blue-50-contrast {
  color: #233876 !important;
}

.bg-blue-50-contrast {
  background-color: #233876 !important;
}

.text-blue-100-contrast {
  color: #233876 !important;
}

.bg-blue-100-contrast {
  background-color: #233876 !important;
}

.text-blue-200-contrast {
  color: #233876 !important;
}

.bg-blue-200-contrast {
  background-color: #233876 !important;
}

.text-blue-300-contrast {
  color: #233876 !important;
}

.bg-blue-300-contrast {
  background-color: #233876 !important;
}

.text-blue-400-contrast {
  color: #233876 !important;
}

.bg-blue-400-contrast {
  background-color: #233876 !important;
}

.text-blue-500-contrast {
  color: #EBF5FF !important;
}

.bg-blue-500-contrast {
  background-color: #EBF5FF !important;
}

.text-blue-600-contrast {
  color: #EBF5FF !important;
}

.bg-blue-600-contrast {
  background-color: #EBF5FF !important;
}

.text-blue-700-contrast {
  color: #EBF5FF !important;
}

.bg-blue-700-contrast {
  background-color: #EBF5FF !important;
}

.text-blue-800-contrast {
  color: #EBF5FF !important;
}

.bg-blue-800-contrast {
  background-color: #EBF5FF !important;
}

.text-blue-900-contrast {
  color: #EBF5FF !important;
}

.bg-blue-900-contrast {
  background-color: #EBF5FF !important;
}

.text-blue-contrast {
  color: #EBF5FF !important;
}

.bg-blue-contrast {
  background-color: #EBF5FF !important;
}

.text-indigo-50-contrast {
  color: #362F78 !important;
}

.bg-indigo-50-contrast {
  background-color: #362F78 !important;
}

.text-indigo-100-contrast {
  color: #362F78 !important;
}

.bg-indigo-100-contrast {
  background-color: #362F78 !important;
}

.text-indigo-200-contrast {
  color: #362F78 !important;
}

.bg-indigo-200-contrast {
  background-color: #362F78 !important;
}

.text-indigo-300-contrast {
  color: #362F78 !important;
}

.bg-indigo-300-contrast {
  background-color: #362F78 !important;
}

.text-indigo-400-contrast {
  color: #362F78 !important;
}

.bg-indigo-400-contrast {
  background-color: #362F78 !important;
}

.text-indigo-500-contrast {
  color: #F0F5FF !important;
}

.bg-indigo-500-contrast {
  background-color: #F0F5FF !important;
}

.text-indigo-600-contrast {
  color: #F0F5FF !important;
}

.bg-indigo-600-contrast {
  background-color: #F0F5FF !important;
}

.text-indigo-700-contrast {
  color: #F0F5FF !important;
}

.bg-indigo-700-contrast {
  background-color: #F0F5FF !important;
}

.text-indigo-800-contrast {
  color: #F0F5FF !important;
}

.bg-indigo-800-contrast {
  background-color: #F0F5FF !important;
}

.text-indigo-900-contrast {
  color: #F0F5FF !important;
}

.bg-indigo-900-contrast {
  background-color: #F0F5FF !important;
}

.text-indigo-contrast {
  color: #F0F5FF !important;
}

.bg-indigo-contrast {
  background-color: #F0F5FF !important;
}

.text-purple-50-contrast {
  color: #4A1D96 !important;
}

.bg-purple-50-contrast {
  background-color: #4A1D96 !important;
}

.text-purple-100-contrast {
  color: #4A1D96 !important;
}

.bg-purple-100-contrast {
  background-color: #4A1D96 !important;
}

.text-purple-200-contrast {
  color: #4A1D96 !important;
}

.bg-purple-200-contrast {
  background-color: #4A1D96 !important;
}

.text-purple-300-contrast {
  color: #4A1D96 !important;
}

.bg-purple-300-contrast {
  background-color: #4A1D96 !important;
}

.text-purple-400-contrast {
  color: #4A1D96 !important;
}

.bg-purple-400-contrast {
  background-color: #4A1D96 !important;
}

.text-purple-500-contrast {
  color: #F6F5FF !important;
}

.bg-purple-500-contrast {
  background-color: #F6F5FF !important;
}

.text-purple-600-contrast {
  color: #F6F5FF !important;
}

.bg-purple-600-contrast {
  background-color: #F6F5FF !important;
}

.text-purple-700-contrast {
  color: #F6F5FF !important;
}

.bg-purple-700-contrast {
  background-color: #F6F5FF !important;
}

.text-purple-800-contrast {
  color: #F6F5FF !important;
}

.bg-purple-800-contrast {
  background-color: #F6F5FF !important;
}

.text-purple-900-contrast {
  color: #F6F5FF !important;
}

.bg-purple-900-contrast {
  background-color: #F6F5FF !important;
}

.text-purple-contrast {
  color: #F6F5FF !important;
}

.bg-purple-contrast {
  background-color: #F6F5FF !important;
}

.text-pink-50-contrast {
  color: #751A3D !important;
}

.bg-pink-50-contrast {
  background-color: #751A3D !important;
}

.text-pink-100-contrast {
  color: #751A3D !important;
}

.bg-pink-100-contrast {
  background-color: #751A3D !important;
}

.text-pink-200-contrast {
  color: #751A3D !important;
}

.bg-pink-200-contrast {
  background-color: #751A3D !important;
}

.text-pink-300-contrast {
  color: #751A3D !important;
}

.bg-pink-300-contrast {
  background-color: #751A3D !important;
}

.text-pink-400-contrast {
  color: #751A3D !important;
}

.bg-pink-400-contrast {
  background-color: #751A3D !important;
}

.text-pink-500-contrast {
  color: #FDF2F8 !important;
}

.bg-pink-500-contrast {
  background-color: #FDF2F8 !important;
}

.text-pink-600-contrast {
  color: #FDF2F8 !important;
}

.bg-pink-600-contrast {
  background-color: #FDF2F8 !important;
}

.text-pink-700-contrast {
  color: #FDF2F8 !important;
}

.bg-pink-700-contrast {
  background-color: #FDF2F8 !important;
}

.text-pink-800-contrast {
  color: #FDF2F8 !important;
}

.bg-pink-800-contrast {
  background-color: #FDF2F8 !important;
}

.text-pink-900-contrast {
  color: #FDF2F8 !important;
}

.bg-pink-900-contrast {
  background-color: #FDF2F8 !important;
}

.text-pink-contrast {
  color: #FDF2F8 !important;
}

.bg-pink-contrast {
  background-color: #FDF2F8 !important;
}

.white {
  background-color: #FFFFFF !important;
  color: #252F3F !important;
}

.white.mat-icon, .white .mat-icon {
  color: #252F3F !important;
}

.white.text-secondary, .white .text-secondary {
  color: rgba(#252F3F, 0.7) !important;
}

.white.text-hint, .white .text-hint, .white.text-disabled, .white .text-disabled {
  color: rgba(#252F3F, 0.38) !important;
}

.white.divider, .white .divider {
  color: rgba(#252F3F, 0.12) !important;
}

.text-white.text-secondary, .text-white .text-secondary {
  color: rgba(#FFFFFF, 0.7) !important;
}

.text-white.text-hint, .text-white .text-hint, .text-white.text-disabled, .text-white .text-disabled {
  color: rgba(#FFFFFF, 0.38) !important;
}

.text-white.divider, .text-white .divider {
  color: rgba(#FFFFFF, 0.12) !important;
}

.black {
  background-color: #000000 !important;
  color: #FFFFFF !important;
}

.black.mat-icon, .black .mat-icon {
  color: #FFFFFF !important;
}

.black.text-secondary, .black .text-secondary {
  color: rgba(#FFFFFF, 0.7) !important;
}

.black.text-hint, .black .text-hint, .black.text-disabled, .black .text-disabled {
  color: rgba(#FFFFFF, 0.38) !important;
}

.black.divider, .black .divider {
  color: rgba(#FFFFFF, 0.12) !important;
}

.text-black.text-secondary, .text-black .text-secondary {
  color: rgba(#000000, 0.7) !important;
}

.text-black.text-hint, .text-black .text-hint, .text-black.text-disabled, .text-black .text-disabled {
  color: rgba(#000000, 0.38) !important;
}

.text-black.divider, .text-black .divider {
  color: rgba(#000000, 0.12) !important;
}

.gray-50 {
  background-color: #F9FAFB !important;
  color: #161E2E !important;
}

.gray-50.mat-icon, .gray-50 .mat-icon {
  color: #161E2E !important;
}

.gray-50.text-secondary, .gray-50 .text-secondary {
  color: rgba(#161E2E, 0.7) !important;
}

.gray-50.text-hint, .gray-50 .text-hint, .gray-50.text-disabled, .gray-50 .text-disabled {
  color: rgba(#161E2E, 0.38) !important;
}

.gray-50.divider, .gray-50 .divider {
  color: rgba(#161E2E, 0.12) !important;
}

.text-gray-50.text-secondary, .text-gray-50 .text-secondary {
  color: rgba(#F9FAFB, 0.7) !important;
}

.text-gray-50.text-hint, .text-gray-50 .text-hint, .text-gray-50.text-disabled, .text-gray-50 .text-disabled {
  color: rgba(#F9FAFB, 0.38) !important;
}

.text-gray-50.divider, .text-gray-50 .divider {
  color: rgba(#F9FAFB, 0.12) !important;
}

.gray-100 {
  background-color: #F4F5F7 !important;
  color: #161E2E !important;
}

.gray-100.mat-icon, .gray-100 .mat-icon {
  color: #161E2E !important;
}

.gray-100.text-secondary, .gray-100 .text-secondary {
  color: rgba(#161E2E, 0.7) !important;
}

.gray-100.text-hint, .gray-100 .text-hint, .gray-100.text-disabled, .gray-100 .text-disabled {
  color: rgba(#161E2E, 0.38) !important;
}

.gray-100.divider, .gray-100 .divider {
  color: rgba(#161E2E, 0.12) !important;
}

.text-gray-100.text-secondary, .text-gray-100 .text-secondary {
  color: rgba(#F4F5F7, 0.7) !important;
}

.text-gray-100.text-hint, .text-gray-100 .text-hint, .text-gray-100.text-disabled, .text-gray-100 .text-disabled {
  color: rgba(#F4F5F7, 0.38) !important;
}

.text-gray-100.divider, .text-gray-100 .divider {
  color: rgba(#F4F5F7, 0.12) !important;
}

.gray-200 {
  background-color: #E5E7EB !important;
  color: #161E2E !important;
}

.gray-200.mat-icon, .gray-200 .mat-icon {
  color: #161E2E !important;
}

.gray-200.text-secondary, .gray-200 .text-secondary {
  color: rgba(#161E2E, 0.7) !important;
}

.gray-200.text-hint, .gray-200 .text-hint, .gray-200.text-disabled, .gray-200 .text-disabled {
  color: rgba(#161E2E, 0.38) !important;
}

.gray-200.divider, .gray-200 .divider {
  color: rgba(#161E2E, 0.12) !important;
}

.text-gray-200.text-secondary, .text-gray-200 .text-secondary {
  color: rgba(#E5E7EB, 0.7) !important;
}

.text-gray-200.text-hint, .text-gray-200 .text-hint, .text-gray-200.text-disabled, .text-gray-200 .text-disabled {
  color: rgba(#E5E7EB, 0.38) !important;
}

.text-gray-200.divider, .text-gray-200 .divider {
  color: rgba(#E5E7EB, 0.12) !important;
}

.gray-300 {
  background-color: #D2D6DC !important;
  color: #161E2E !important;
}

.gray-300.mat-icon, .gray-300 .mat-icon {
  color: #161E2E !important;
}

.gray-300.text-secondary, .gray-300 .text-secondary {
  color: rgba(#161E2E, 0.7) !important;
}

.gray-300.text-hint, .gray-300 .text-hint, .gray-300.text-disabled, .gray-300 .text-disabled {
  color: rgba(#161E2E, 0.38) !important;
}

.gray-300.divider, .gray-300 .divider {
  color: rgba(#161E2E, 0.12) !important;
}

.text-gray-300.text-secondary, .text-gray-300 .text-secondary {
  color: rgba(#D2D6DC, 0.7) !important;
}

.text-gray-300.text-hint, .text-gray-300 .text-hint, .text-gray-300.text-disabled, .text-gray-300 .text-disabled {
  color: rgba(#D2D6DC, 0.38) !important;
}

.text-gray-300.divider, .text-gray-300 .divider {
  color: rgba(#D2D6DC, 0.12) !important;
}

.gray-400 {
  background-color: #9FA6B2 !important;
  color: #161E2E !important;
}

.gray-400.mat-icon, .gray-400 .mat-icon {
  color: #161E2E !important;
}

.gray-400.text-secondary, .gray-400 .text-secondary {
  color: rgba(#161E2E, 0.7) !important;
}

.gray-400.text-hint, .gray-400 .text-hint, .gray-400.text-disabled, .gray-400 .text-disabled {
  color: rgba(#161E2E, 0.38) !important;
}

.gray-400.divider, .gray-400 .divider {
  color: rgba(#161E2E, 0.12) !important;
}

.text-gray-400.text-secondary, .text-gray-400 .text-secondary {
  color: rgba(#9FA6B2, 0.7) !important;
}

.text-gray-400.text-hint, .text-gray-400 .text-hint, .text-gray-400.text-disabled, .text-gray-400 .text-disabled {
  color: rgba(#9FA6B2, 0.38) !important;
}

.text-gray-400.divider, .text-gray-400 .divider {
  color: rgba(#9FA6B2, 0.12) !important;
}

.gray-500 {
  background-color: #6B7280 !important;
  color: #161E2E !important;
}

.gray-500.mat-icon, .gray-500 .mat-icon {
  color: #161E2E !important;
}

.gray-500.text-secondary, .gray-500 .text-secondary {
  color: rgba(#161E2E, 0.7) !important;
}

.gray-500.text-hint, .gray-500 .text-hint, .gray-500.text-disabled, .gray-500 .text-disabled {
  color: rgba(#161E2E, 0.38) !important;
}

.gray-500.divider, .gray-500 .divider {
  color: rgba(#161E2E, 0.12) !important;
}

.text-gray-500.text-secondary, .text-gray-500 .text-secondary {
  color: rgba(#6B7280, 0.7) !important;
}

.text-gray-500.text-hint, .text-gray-500 .text-hint, .text-gray-500.text-disabled, .text-gray-500 .text-disabled {
  color: rgba(#6B7280, 0.38) !important;
}

.text-gray-500.divider, .text-gray-500 .divider {
  color: rgba(#6B7280, 0.12) !important;
}

.gray-600 {
  background-color: #4B5563 !important;
  color: #F9FAFB !important;
}

.gray-600.mat-icon, .gray-600 .mat-icon {
  color: #F9FAFB !important;
}

.gray-600.text-secondary, .gray-600 .text-secondary {
  color: rgba(#F9FAFB, 0.7) !important;
}

.gray-600.text-hint, .gray-600 .text-hint, .gray-600.text-disabled, .gray-600 .text-disabled {
  color: rgba(#F9FAFB, 0.38) !important;
}

.gray-600.divider, .gray-600 .divider {
  color: rgba(#F9FAFB, 0.12) !important;
}

.text-gray-600.text-secondary, .text-gray-600 .text-secondary {
  color: rgba(#4B5563, 0.7) !important;
}

.text-gray-600.text-hint, .text-gray-600 .text-hint, .text-gray-600.text-disabled, .text-gray-600 .text-disabled {
  color: rgba(#4B5563, 0.38) !important;
}

.text-gray-600.divider, .text-gray-600 .divider {
  color: rgba(#4B5563, 0.12) !important;
}

.gray-700 {
  background-color: #374151 !important;
  color: #F9FAFB !important;
}

.gray-700.mat-icon, .gray-700 .mat-icon {
  color: #F9FAFB !important;
}

.gray-700.text-secondary, .gray-700 .text-secondary {
  color: rgba(#F9FAFB, 0.7) !important;
}

.gray-700.text-hint, .gray-700 .text-hint, .gray-700.text-disabled, .gray-700 .text-disabled {
  color: rgba(#F9FAFB, 0.38) !important;
}

.gray-700.divider, .gray-700 .divider {
  color: rgba(#F9FAFB, 0.12) !important;
}

.text-gray-700.text-secondary, .text-gray-700 .text-secondary {
  color: rgba(#374151, 0.7) !important;
}

.text-gray-700.text-hint, .text-gray-700 .text-hint, .text-gray-700.text-disabled, .text-gray-700 .text-disabled {
  color: rgba(#374151, 0.38) !important;
}

.text-gray-700.divider, .text-gray-700 .divider {
  color: rgba(#374151, 0.12) !important;
}

.gray-800 {
  background-color: #252F3F !important;
  color: #F9FAFB !important;
}

.gray-800.mat-icon, .gray-800 .mat-icon {
  color: #F9FAFB !important;
}

.gray-800.text-secondary, .gray-800 .text-secondary {
  color: rgba(#F9FAFB, 0.7) !important;
}

.gray-800.text-hint, .gray-800 .text-hint, .gray-800.text-disabled, .gray-800 .text-disabled {
  color: rgba(#F9FAFB, 0.38) !important;
}

.gray-800.divider, .gray-800 .divider {
  color: rgba(#F9FAFB, 0.12) !important;
}

.text-gray-800.text-secondary, .text-gray-800 .text-secondary {
  color: rgba(#252F3F, 0.7) !important;
}

.text-gray-800.text-hint, .text-gray-800 .text-hint, .text-gray-800.text-disabled, .text-gray-800 .text-disabled {
  color: rgba(#252F3F, 0.38) !important;
}

.text-gray-800.divider, .text-gray-800 .divider {
  color: rgba(#252F3F, 0.12) !important;
}

.gray-900 {
  background-color: #161E2E !important;
  color: #F9FAFB !important;
}

.gray-900.mat-icon, .gray-900 .mat-icon {
  color: #F9FAFB !important;
}

.gray-900.text-secondary, .gray-900 .text-secondary {
  color: rgba(#F9FAFB, 0.7) !important;
}

.gray-900.text-hint, .gray-900 .text-hint, .gray-900.text-disabled, .gray-900 .text-disabled {
  color: rgba(#F9FAFB, 0.38) !important;
}

.gray-900.divider, .gray-900 .divider {
  color: rgba(#F9FAFB, 0.12) !important;
}

.text-gray-900.text-secondary, .text-gray-900 .text-secondary {
  color: rgba(#161E2E, 0.7) !important;
}

.text-gray-900.text-hint, .text-gray-900 .text-hint, .text-gray-900.text-disabled, .text-gray-900 .text-disabled {
  color: rgba(#161E2E, 0.38) !important;
}

.text-gray-900.divider, .text-gray-900 .divider {
  color: rgba(#161E2E, 0.12) !important;
}

.gray {
  background-color: #6B7280 !important;
  color: #161E2E !important;
}

.gray.mat-icon, .gray .mat-icon {
  color: #161E2E !important;
}

.gray.text-secondary, .gray .text-secondary {
  color: rgba(#161E2E, 0.7) !important;
}

.gray.text-hint, .gray .text-hint, .gray.text-disabled, .gray .text-disabled {
  color: rgba(#161E2E, 0.38) !important;
}

.gray.divider, .gray .divider {
  color: rgba(#161E2E, 0.12) !important;
}

.text-gray.text-secondary, .text-gray .text-secondary {
  color: rgba(#6B7280, 0.7) !important;
}

.text-gray.text-hint, .text-gray .text-hint, .text-gray.text-disabled, .text-gray .text-disabled {
  color: rgba(#6B7280, 0.38) !important;
}

.text-gray.divider, .text-gray .divider {
  color: rgba(#6B7280, 0.12) !important;
}

.cool-gray-50 {
  background-color: #FBFDFE !important;
  color: #1A202E !important;
}

.cool-gray-50.mat-icon, .cool-gray-50 .mat-icon {
  color: #1A202E !important;
}

.cool-gray-50.text-secondary, .cool-gray-50 .text-secondary {
  color: rgba(#1A202E, 0.7) !important;
}

.cool-gray-50.text-hint, .cool-gray-50 .text-hint, .cool-gray-50.text-disabled, .cool-gray-50 .text-disabled {
  color: rgba(#1A202E, 0.38) !important;
}

.cool-gray-50.divider, .cool-gray-50 .divider {
  color: rgba(#1A202E, 0.12) !important;
}

.text-cool-gray-50.text-secondary, .text-cool-gray-50 .text-secondary {
  color: rgba(#FBFDFE, 0.7) !important;
}

.text-cool-gray-50.text-hint, .text-cool-gray-50 .text-hint, .text-cool-gray-50.text-disabled, .text-cool-gray-50 .text-disabled {
  color: rgba(#FBFDFE, 0.38) !important;
}

.text-cool-gray-50.divider, .text-cool-gray-50 .divider {
  color: rgba(#FBFDFE, 0.12) !important;
}

.cool-gray-100 {
  background-color: #F1F5F9 !important;
  color: #1A202E !important;
}

.cool-gray-100.mat-icon, .cool-gray-100 .mat-icon {
  color: #1A202E !important;
}

.cool-gray-100.text-secondary, .cool-gray-100 .text-secondary {
  color: rgba(#1A202E, 0.7) !important;
}

.cool-gray-100.text-hint, .cool-gray-100 .text-hint, .cool-gray-100.text-disabled, .cool-gray-100 .text-disabled {
  color: rgba(#1A202E, 0.38) !important;
}

.cool-gray-100.divider, .cool-gray-100 .divider {
  color: rgba(#1A202E, 0.12) !important;
}

.text-cool-gray-100.text-secondary, .text-cool-gray-100 .text-secondary {
  color: rgba(#F1F5F9, 0.7) !important;
}

.text-cool-gray-100.text-hint, .text-cool-gray-100 .text-hint, .text-cool-gray-100.text-disabled, .text-cool-gray-100 .text-disabled {
  color: rgba(#F1F5F9, 0.38) !important;
}

.text-cool-gray-100.divider, .text-cool-gray-100 .divider {
  color: rgba(#F1F5F9, 0.12) !important;
}

.cool-gray-200 {
  background-color: #E2E8F0 !important;
  color: #1A202E !important;
}

.cool-gray-200.mat-icon, .cool-gray-200 .mat-icon {
  color: #1A202E !important;
}

.cool-gray-200.text-secondary, .cool-gray-200 .text-secondary {
  color: rgba(#1A202E, 0.7) !important;
}

.cool-gray-200.text-hint, .cool-gray-200 .text-hint, .cool-gray-200.text-disabled, .cool-gray-200 .text-disabled {
  color: rgba(#1A202E, 0.38) !important;
}

.cool-gray-200.divider, .cool-gray-200 .divider {
  color: rgba(#1A202E, 0.12) !important;
}

.text-cool-gray-200.text-secondary, .text-cool-gray-200 .text-secondary {
  color: rgba(#E2E8F0, 0.7) !important;
}

.text-cool-gray-200.text-hint, .text-cool-gray-200 .text-hint, .text-cool-gray-200.text-disabled, .text-cool-gray-200 .text-disabled {
  color: rgba(#E2E8F0, 0.38) !important;
}

.text-cool-gray-200.divider, .text-cool-gray-200 .divider {
  color: rgba(#E2E8F0, 0.12) !important;
}

.cool-gray-300 {
  background-color: #CFD8E3 !important;
  color: #1A202E !important;
}

.cool-gray-300.mat-icon, .cool-gray-300 .mat-icon {
  color: #1A202E !important;
}

.cool-gray-300.text-secondary, .cool-gray-300 .text-secondary {
  color: rgba(#1A202E, 0.7) !important;
}

.cool-gray-300.text-hint, .cool-gray-300 .text-hint, .cool-gray-300.text-disabled, .cool-gray-300 .text-disabled {
  color: rgba(#1A202E, 0.38) !important;
}

.cool-gray-300.divider, .cool-gray-300 .divider {
  color: rgba(#1A202E, 0.12) !important;
}

.text-cool-gray-300.text-secondary, .text-cool-gray-300 .text-secondary {
  color: rgba(#CFD8E3, 0.7) !important;
}

.text-cool-gray-300.text-hint, .text-cool-gray-300 .text-hint, .text-cool-gray-300.text-disabled, .text-cool-gray-300 .text-disabled {
  color: rgba(#CFD8E3, 0.38) !important;
}

.text-cool-gray-300.divider, .text-cool-gray-300 .divider {
  color: rgba(#CFD8E3, 0.12) !important;
}

.cool-gray-400 {
  background-color: #97A6BA !important;
  color: #1A202E !important;
}

.cool-gray-400.mat-icon, .cool-gray-400 .mat-icon {
  color: #1A202E !important;
}

.cool-gray-400.text-secondary, .cool-gray-400 .text-secondary {
  color: rgba(#1A202E, 0.7) !important;
}

.cool-gray-400.text-hint, .cool-gray-400 .text-hint, .cool-gray-400.text-disabled, .cool-gray-400 .text-disabled {
  color: rgba(#1A202E, 0.38) !important;
}

.cool-gray-400.divider, .cool-gray-400 .divider {
  color: rgba(#1A202E, 0.12) !important;
}

.text-cool-gray-400.text-secondary, .text-cool-gray-400 .text-secondary {
  color: rgba(#97A6BA, 0.7) !important;
}

.text-cool-gray-400.text-hint, .text-cool-gray-400 .text-hint, .text-cool-gray-400.text-disabled, .text-cool-gray-400 .text-disabled {
  color: rgba(#97A6BA, 0.38) !important;
}

.text-cool-gray-400.divider, .text-cool-gray-400 .divider {
  color: rgba(#97A6BA, 0.12) !important;
}

.cool-gray-500 {
  background-color: #64748B !important;
  color: #1A202E !important;
}

.cool-gray-500.mat-icon, .cool-gray-500 .mat-icon {
  color: #1A202E !important;
}

.cool-gray-500.text-secondary, .cool-gray-500 .text-secondary {
  color: rgba(#1A202E, 0.7) !important;
}

.cool-gray-500.text-hint, .cool-gray-500 .text-hint, .cool-gray-500.text-disabled, .cool-gray-500 .text-disabled {
  color: rgba(#1A202E, 0.38) !important;
}

.cool-gray-500.divider, .cool-gray-500 .divider {
  color: rgba(#1A202E, 0.12) !important;
}

.text-cool-gray-500.text-secondary, .text-cool-gray-500 .text-secondary {
  color: rgba(#64748B, 0.7) !important;
}

.text-cool-gray-500.text-hint, .text-cool-gray-500 .text-hint, .text-cool-gray-500.text-disabled, .text-cool-gray-500 .text-disabled {
  color: rgba(#64748B, 0.38) !important;
}

.text-cool-gray-500.divider, .text-cool-gray-500 .divider {
  color: rgba(#64748B, 0.12) !important;
}

.cool-gray-600 {
  background-color: #475569 !important;
  color: #FBFDFE !important;
}

.cool-gray-600.mat-icon, .cool-gray-600 .mat-icon {
  color: #FBFDFE !important;
}

.cool-gray-600.text-secondary, .cool-gray-600 .text-secondary {
  color: rgba(#FBFDFE, 0.7) !important;
}

.cool-gray-600.text-hint, .cool-gray-600 .text-hint, .cool-gray-600.text-disabled, .cool-gray-600 .text-disabled {
  color: rgba(#FBFDFE, 0.38) !important;
}

.cool-gray-600.divider, .cool-gray-600 .divider {
  color: rgba(#FBFDFE, 0.12) !important;
}

.text-cool-gray-600.text-secondary, .text-cool-gray-600 .text-secondary {
  color: rgba(#475569, 0.7) !important;
}

.text-cool-gray-600.text-hint, .text-cool-gray-600 .text-hint, .text-cool-gray-600.text-disabled, .text-cool-gray-600 .text-disabled {
  color: rgba(#475569, 0.38) !important;
}

.text-cool-gray-600.divider, .text-cool-gray-600 .divider {
  color: rgba(#475569, 0.12) !important;
}

.cool-gray-700 {
  background-color: #364152 !important;
  color: #FBFDFE !important;
}

.cool-gray-700.mat-icon, .cool-gray-700 .mat-icon {
  color: #FBFDFE !important;
}

.cool-gray-700.text-secondary, .cool-gray-700 .text-secondary {
  color: rgba(#FBFDFE, 0.7) !important;
}

.cool-gray-700.text-hint, .cool-gray-700 .text-hint, .cool-gray-700.text-disabled, .cool-gray-700 .text-disabled {
  color: rgba(#FBFDFE, 0.38) !important;
}

.cool-gray-700.divider, .cool-gray-700 .divider {
  color: rgba(#FBFDFE, 0.12) !important;
}

.text-cool-gray-700.text-secondary, .text-cool-gray-700 .text-secondary {
  color: rgba(#364152, 0.7) !important;
}

.text-cool-gray-700.text-hint, .text-cool-gray-700 .text-hint, .text-cool-gray-700.text-disabled, .text-cool-gray-700 .text-disabled {
  color: rgba(#364152, 0.38) !important;
}

.text-cool-gray-700.divider, .text-cool-gray-700 .divider {
  color: rgba(#364152, 0.12) !important;
}

.cool-gray-800 {
  background-color: #27303F !important;
  color: #FBFDFE !important;
}

.cool-gray-800.mat-icon, .cool-gray-800 .mat-icon {
  color: #FBFDFE !important;
}

.cool-gray-800.text-secondary, .cool-gray-800 .text-secondary {
  color: rgba(#FBFDFE, 0.7) !important;
}

.cool-gray-800.text-hint, .cool-gray-800 .text-hint, .cool-gray-800.text-disabled, .cool-gray-800 .text-disabled {
  color: rgba(#FBFDFE, 0.38) !important;
}

.cool-gray-800.divider, .cool-gray-800 .divider {
  color: rgba(#FBFDFE, 0.12) !important;
}

.text-cool-gray-800.text-secondary, .text-cool-gray-800 .text-secondary {
  color: rgba(#27303F, 0.7) !important;
}

.text-cool-gray-800.text-hint, .text-cool-gray-800 .text-hint, .text-cool-gray-800.text-disabled, .text-cool-gray-800 .text-disabled {
  color: rgba(#27303F, 0.38) !important;
}

.text-cool-gray-800.divider, .text-cool-gray-800 .divider {
  color: rgba(#27303F, 0.12) !important;
}

.cool-gray-900 {
  background-color: #1A202E !important;
  color: #FBFDFE !important;
}

.cool-gray-900.mat-icon, .cool-gray-900 .mat-icon {
  color: #FBFDFE !important;
}

.cool-gray-900.text-secondary, .cool-gray-900 .text-secondary {
  color: rgba(#FBFDFE, 0.7) !important;
}

.cool-gray-900.text-hint, .cool-gray-900 .text-hint, .cool-gray-900.text-disabled, .cool-gray-900 .text-disabled {
  color: rgba(#FBFDFE, 0.38) !important;
}

.cool-gray-900.divider, .cool-gray-900 .divider {
  color: rgba(#FBFDFE, 0.12) !important;
}

.text-cool-gray-900.text-secondary, .text-cool-gray-900 .text-secondary {
  color: rgba(#1A202E, 0.7) !important;
}

.text-cool-gray-900.text-hint, .text-cool-gray-900 .text-hint, .text-cool-gray-900.text-disabled, .text-cool-gray-900 .text-disabled {
  color: rgba(#1A202E, 0.38) !important;
}

.text-cool-gray-900.divider, .text-cool-gray-900 .divider {
  color: rgba(#1A202E, 0.12) !important;
}

.cool-gray {
  background-color: #64748B !important;
  color: #1A202E !important;
}

.cool-gray.mat-icon, .cool-gray .mat-icon {
  color: #1A202E !important;
}

.cool-gray.text-secondary, .cool-gray .text-secondary {
  color: rgba(#1A202E, 0.7) !important;
}

.cool-gray.text-hint, .cool-gray .text-hint, .cool-gray.text-disabled, .cool-gray .text-disabled {
  color: rgba(#1A202E, 0.38) !important;
}

.cool-gray.divider, .cool-gray .divider {
  color: rgba(#1A202E, 0.12) !important;
}

.text-cool-gray.text-secondary, .text-cool-gray .text-secondary {
  color: rgba(#64748B, 0.7) !important;
}

.text-cool-gray.text-hint, .text-cool-gray .text-hint, .text-cool-gray.text-disabled, .text-cool-gray .text-disabled {
  color: rgba(#64748B, 0.38) !important;
}

.text-cool-gray.divider, .text-cool-gray .divider {
  color: rgba(#64748B, 0.12) !important;
}

.red-50 {
  background-color: #FDF2F2 !important;
  color: #771D1D !important;
}

.red-50.mat-icon, .red-50 .mat-icon {
  color: #771D1D !important;
}

.red-50.text-secondary, .red-50 .text-secondary {
  color: rgba(#771D1D, 0.7) !important;
}

.red-50.text-hint, .red-50 .text-hint, .red-50.text-disabled, .red-50 .text-disabled {
  color: rgba(#771D1D, 0.38) !important;
}

.red-50.divider, .red-50 .divider {
  color: rgba(#771D1D, 0.12) !important;
}

.text-red-50.text-secondary, .text-red-50 .text-secondary {
  color: rgba(#FDF2F2, 0.7) !important;
}

.text-red-50.text-hint, .text-red-50 .text-hint, .text-red-50.text-disabled, .text-red-50 .text-disabled {
  color: rgba(#FDF2F2, 0.38) !important;
}

.text-red-50.divider, .text-red-50 .divider {
  color: rgba(#FDF2F2, 0.12) !important;
}

.red-100 {
  background-color: #FDE8E8 !important;
  color: #771D1D !important;
}

.red-100.mat-icon, .red-100 .mat-icon {
  color: #771D1D !important;
}

.red-100.text-secondary, .red-100 .text-secondary {
  color: rgba(#771D1D, 0.7) !important;
}

.red-100.text-hint, .red-100 .text-hint, .red-100.text-disabled, .red-100 .text-disabled {
  color: rgba(#771D1D, 0.38) !important;
}

.red-100.divider, .red-100 .divider {
  color: rgba(#771D1D, 0.12) !important;
}

.text-red-100.text-secondary, .text-red-100 .text-secondary {
  color: rgba(#FDE8E8, 0.7) !important;
}

.text-red-100.text-hint, .text-red-100 .text-hint, .text-red-100.text-disabled, .text-red-100 .text-disabled {
  color: rgba(#FDE8E8, 0.38) !important;
}

.text-red-100.divider, .text-red-100 .divider {
  color: rgba(#FDE8E8, 0.12) !important;
}

.red-200 {
  background-color: #FBD5D5 !important;
  color: #771D1D !important;
}

.red-200.mat-icon, .red-200 .mat-icon {
  color: #771D1D !important;
}

.red-200.text-secondary, .red-200 .text-secondary {
  color: rgba(#771D1D, 0.7) !important;
}

.red-200.text-hint, .red-200 .text-hint, .red-200.text-disabled, .red-200 .text-disabled {
  color: rgba(#771D1D, 0.38) !important;
}

.red-200.divider, .red-200 .divider {
  color: rgba(#771D1D, 0.12) !important;
}

.text-red-200.text-secondary, .text-red-200 .text-secondary {
  color: rgba(#FBD5D5, 0.7) !important;
}

.text-red-200.text-hint, .text-red-200 .text-hint, .text-red-200.text-disabled, .text-red-200 .text-disabled {
  color: rgba(#FBD5D5, 0.38) !important;
}

.text-red-200.divider, .text-red-200 .divider {
  color: rgba(#FBD5D5, 0.12) !important;
}

.red-300 {
  background-color: #F8B4B4 !important;
  color: #771D1D !important;
}

.red-300.mat-icon, .red-300 .mat-icon {
  color: #771D1D !important;
}

.red-300.text-secondary, .red-300 .text-secondary {
  color: rgba(#771D1D, 0.7) !important;
}

.red-300.text-hint, .red-300 .text-hint, .red-300.text-disabled, .red-300 .text-disabled {
  color: rgba(#771D1D, 0.38) !important;
}

.red-300.divider, .red-300 .divider {
  color: rgba(#771D1D, 0.12) !important;
}

.text-red-300.text-secondary, .text-red-300 .text-secondary {
  color: rgba(#F8B4B4, 0.7) !important;
}

.text-red-300.text-hint, .text-red-300 .text-hint, .text-red-300.text-disabled, .text-red-300 .text-disabled {
  color: rgba(#F8B4B4, 0.38) !important;
}

.text-red-300.divider, .text-red-300 .divider {
  color: rgba(#F8B4B4, 0.12) !important;
}

.red-400 {
  background-color: #F98080 !important;
  color: #771D1D !important;
}

.red-400.mat-icon, .red-400 .mat-icon {
  color: #771D1D !important;
}

.red-400.text-secondary, .red-400 .text-secondary {
  color: rgba(#771D1D, 0.7) !important;
}

.red-400.text-hint, .red-400 .text-hint, .red-400.text-disabled, .red-400 .text-disabled {
  color: rgba(#771D1D, 0.38) !important;
}

.red-400.divider, .red-400 .divider {
  color: rgba(#771D1D, 0.12) !important;
}

.text-red-400.text-secondary, .text-red-400 .text-secondary {
  color: rgba(#F98080, 0.7) !important;
}

.text-red-400.text-hint, .text-red-400 .text-hint, .text-red-400.text-disabled, .text-red-400 .text-disabled {
  color: rgba(#F98080, 0.38) !important;
}

.text-red-400.divider, .text-red-400 .divider {
  color: rgba(#F98080, 0.12) !important;
}

.red-500 {
  background-color: #F05252 !important;
  color: #771D1D !important;
}

.red-500.mat-icon, .red-500 .mat-icon {
  color: #771D1D !important;
}

.red-500.text-secondary, .red-500 .text-secondary {
  color: rgba(#771D1D, 0.7) !important;
}

.red-500.text-hint, .red-500 .text-hint, .red-500.text-disabled, .red-500 .text-disabled {
  color: rgba(#771D1D, 0.38) !important;
}

.red-500.divider, .red-500 .divider {
  color: rgba(#771D1D, 0.12) !important;
}

.text-red-500.text-secondary, .text-red-500 .text-secondary {
  color: rgba(#F05252, 0.7) !important;
}

.text-red-500.text-hint, .text-red-500 .text-hint, .text-red-500.text-disabled, .text-red-500 .text-disabled {
  color: rgba(#F05252, 0.38) !important;
}

.text-red-500.divider, .text-red-500 .divider {
  color: rgba(#F05252, 0.12) !important;
}

.red-600 {
  background-color: #E02424 !important;
  color: #FDF2F2 !important;
}

.red-600.mat-icon, .red-600 .mat-icon {
  color: #FDF2F2 !important;
}

.red-600.text-secondary, .red-600 .text-secondary {
  color: rgba(#FDF2F2, 0.7) !important;
}

.red-600.text-hint, .red-600 .text-hint, .red-600.text-disabled, .red-600 .text-disabled {
  color: rgba(#FDF2F2, 0.38) !important;
}

.red-600.divider, .red-600 .divider {
  color: rgba(#FDF2F2, 0.12) !important;
}

.text-red-600.text-secondary, .text-red-600 .text-secondary {
  color: rgba(#E02424, 0.7) !important;
}

.text-red-600.text-hint, .text-red-600 .text-hint, .text-red-600.text-disabled, .text-red-600 .text-disabled {
  color: rgba(#E02424, 0.38) !important;
}

.text-red-600.divider, .text-red-600 .divider {
  color: rgba(#E02424, 0.12) !important;
}

.red-700 {
  background-color: #C81E1E !important;
  color: #FDF2F2 !important;
}

.red-700.mat-icon, .red-700 .mat-icon {
  color: #FDF2F2 !important;
}

.red-700.text-secondary, .red-700 .text-secondary {
  color: rgba(#FDF2F2, 0.7) !important;
}

.red-700.text-hint, .red-700 .text-hint, .red-700.text-disabled, .red-700 .text-disabled {
  color: rgba(#FDF2F2, 0.38) !important;
}

.red-700.divider, .red-700 .divider {
  color: rgba(#FDF2F2, 0.12) !important;
}

.text-red-700.text-secondary, .text-red-700 .text-secondary {
  color: rgba(#C81E1E, 0.7) !important;
}

.text-red-700.text-hint, .text-red-700 .text-hint, .text-red-700.text-disabled, .text-red-700 .text-disabled {
  color: rgba(#C81E1E, 0.38) !important;
}

.text-red-700.divider, .text-red-700 .divider {
  color: rgba(#C81E1E, 0.12) !important;
}

.red-800 {
  background-color: #9B1C1C !important;
  color: #FDF2F2 !important;
}

.red-800.mat-icon, .red-800 .mat-icon {
  color: #FDF2F2 !important;
}

.red-800.text-secondary, .red-800 .text-secondary {
  color: rgba(#FDF2F2, 0.7) !important;
}

.red-800.text-hint, .red-800 .text-hint, .red-800.text-disabled, .red-800 .text-disabled {
  color: rgba(#FDF2F2, 0.38) !important;
}

.red-800.divider, .red-800 .divider {
  color: rgba(#FDF2F2, 0.12) !important;
}

.text-red-800.text-secondary, .text-red-800 .text-secondary {
  color: rgba(#9B1C1C, 0.7) !important;
}

.text-red-800.text-hint, .text-red-800 .text-hint, .text-red-800.text-disabled, .text-red-800 .text-disabled {
  color: rgba(#9B1C1C, 0.38) !important;
}

.text-red-800.divider, .text-red-800 .divider {
  color: rgba(#9B1C1C, 0.12) !important;
}

.red-900 {
  background-color: #771D1D !important;
  color: #FDF2F2 !important;
}

.red-900.mat-icon, .red-900 .mat-icon {
  color: #FDF2F2 !important;
}

.red-900.text-secondary, .red-900 .text-secondary {
  color: rgba(#FDF2F2, 0.7) !important;
}

.red-900.text-hint, .red-900 .text-hint, .red-900.text-disabled, .red-900 .text-disabled {
  color: rgba(#FDF2F2, 0.38) !important;
}

.red-900.divider, .red-900 .divider {
  color: rgba(#FDF2F2, 0.12) !important;
}

.text-red-900.text-secondary, .text-red-900 .text-secondary {
  color: rgba(#771D1D, 0.7) !important;
}

.text-red-900.text-hint, .text-red-900 .text-hint, .text-red-900.text-disabled, .text-red-900 .text-disabled {
  color: rgba(#771D1D, 0.38) !important;
}

.text-red-900.divider, .text-red-900 .divider {
  color: rgba(#771D1D, 0.12) !important;
}

.red {
  background-color: #F05252 !important;
  color: #771D1D !important;
}

.red.mat-icon, .red .mat-icon {
  color: #771D1D !important;
}

.red.text-secondary, .red .text-secondary {
  color: rgba(#771D1D, 0.7) !important;
}

.red.text-hint, .red .text-hint, .red.text-disabled, .red .text-disabled {
  color: rgba(#771D1D, 0.38) !important;
}

.red.divider, .red .divider {
  color: rgba(#771D1D, 0.12) !important;
}

.text-red.text-secondary, .text-red .text-secondary {
  color: rgba(#F05252, 0.7) !important;
}

.text-red.text-hint, .text-red .text-hint, .text-red.text-disabled, .text-red .text-disabled {
  color: rgba(#F05252, 0.38) !important;
}

.text-red.divider, .text-red .divider {
  color: rgba(#F05252, 0.12) !important;
}

.orange-50 {
  background-color: #FFF8F1 !important;
  color: #771D1D !important;
}

.orange-50.mat-icon, .orange-50 .mat-icon {
  color: #771D1D !important;
}

.orange-50.text-secondary, .orange-50 .text-secondary {
  color: rgba(#771D1D, 0.7) !important;
}

.orange-50.text-hint, .orange-50 .text-hint, .orange-50.text-disabled, .orange-50 .text-disabled {
  color: rgba(#771D1D, 0.38) !important;
}

.orange-50.divider, .orange-50 .divider {
  color: rgba(#771D1D, 0.12) !important;
}

.text-orange-50.text-secondary, .text-orange-50 .text-secondary {
  color: rgba(#FFF8F1, 0.7) !important;
}

.text-orange-50.text-hint, .text-orange-50 .text-hint, .text-orange-50.text-disabled, .text-orange-50 .text-disabled {
  color: rgba(#FFF8F1, 0.38) !important;
}

.text-orange-50.divider, .text-orange-50 .divider {
  color: rgba(#FFF8F1, 0.12) !important;
}

.orange-100 {
  background-color: #FEECDC !important;
  color: #771D1D !important;
}

.orange-100.mat-icon, .orange-100 .mat-icon {
  color: #771D1D !important;
}

.orange-100.text-secondary, .orange-100 .text-secondary {
  color: rgba(#771D1D, 0.7) !important;
}

.orange-100.text-hint, .orange-100 .text-hint, .orange-100.text-disabled, .orange-100 .text-disabled {
  color: rgba(#771D1D, 0.38) !important;
}

.orange-100.divider, .orange-100 .divider {
  color: rgba(#771D1D, 0.12) !important;
}

.text-orange-100.text-secondary, .text-orange-100 .text-secondary {
  color: rgba(#FEECDC, 0.7) !important;
}

.text-orange-100.text-hint, .text-orange-100 .text-hint, .text-orange-100.text-disabled, .text-orange-100 .text-disabled {
  color: rgba(#FEECDC, 0.38) !important;
}

.text-orange-100.divider, .text-orange-100 .divider {
  color: rgba(#FEECDC, 0.12) !important;
}

.orange-200 {
  background-color: #FCD9BD !important;
  color: #771D1D !important;
}

.orange-200.mat-icon, .orange-200 .mat-icon {
  color: #771D1D !important;
}

.orange-200.text-secondary, .orange-200 .text-secondary {
  color: rgba(#771D1D, 0.7) !important;
}

.orange-200.text-hint, .orange-200 .text-hint, .orange-200.text-disabled, .orange-200 .text-disabled {
  color: rgba(#771D1D, 0.38) !important;
}

.orange-200.divider, .orange-200 .divider {
  color: rgba(#771D1D, 0.12) !important;
}

.text-orange-200.text-secondary, .text-orange-200 .text-secondary {
  color: rgba(#FCD9BD, 0.7) !important;
}

.text-orange-200.text-hint, .text-orange-200 .text-hint, .text-orange-200.text-disabled, .text-orange-200 .text-disabled {
  color: rgba(#FCD9BD, 0.38) !important;
}

.text-orange-200.divider, .text-orange-200 .divider {
  color: rgba(#FCD9BD, 0.12) !important;
}

.orange-300 {
  background-color: #FDBA8C !important;
  color: #771D1D !important;
}

.orange-300.mat-icon, .orange-300 .mat-icon {
  color: #771D1D !important;
}

.orange-300.text-secondary, .orange-300 .text-secondary {
  color: rgba(#771D1D, 0.7) !important;
}

.orange-300.text-hint, .orange-300 .text-hint, .orange-300.text-disabled, .orange-300 .text-disabled {
  color: rgba(#771D1D, 0.38) !important;
}

.orange-300.divider, .orange-300 .divider {
  color: rgba(#771D1D, 0.12) !important;
}

.text-orange-300.text-secondary, .text-orange-300 .text-secondary {
  color: rgba(#FDBA8C, 0.7) !important;
}

.text-orange-300.text-hint, .text-orange-300 .text-hint, .text-orange-300.text-disabled, .text-orange-300 .text-disabled {
  color: rgba(#FDBA8C, 0.38) !important;
}

.text-orange-300.divider, .text-orange-300 .divider {
  color: rgba(#FDBA8C, 0.12) !important;
}

.orange-400 {
  background-color: #FF8A4C !important;
  color: #771D1D !important;
}

.orange-400.mat-icon, .orange-400 .mat-icon {
  color: #771D1D !important;
}

.orange-400.text-secondary, .orange-400 .text-secondary {
  color: rgba(#771D1D, 0.7) !important;
}

.orange-400.text-hint, .orange-400 .text-hint, .orange-400.text-disabled, .orange-400 .text-disabled {
  color: rgba(#771D1D, 0.38) !important;
}

.orange-400.divider, .orange-400 .divider {
  color: rgba(#771D1D, 0.12) !important;
}

.text-orange-400.text-secondary, .text-orange-400 .text-secondary {
  color: rgba(#FF8A4C, 0.7) !important;
}

.text-orange-400.text-hint, .text-orange-400 .text-hint, .text-orange-400.text-disabled, .text-orange-400 .text-disabled {
  color: rgba(#FF8A4C, 0.38) !important;
}

.text-orange-400.divider, .text-orange-400 .divider {
  color: rgba(#FF8A4C, 0.12) !important;
}

.orange-500 {
  background-color: #FF5A1F !important;
  color: #771D1D !important;
}

.orange-500.mat-icon, .orange-500 .mat-icon {
  color: #771D1D !important;
}

.orange-500.text-secondary, .orange-500 .text-secondary {
  color: rgba(#771D1D, 0.7) !important;
}

.orange-500.text-hint, .orange-500 .text-hint, .orange-500.text-disabled, .orange-500 .text-disabled {
  color: rgba(#771D1D, 0.38) !important;
}

.orange-500.divider, .orange-500 .divider {
  color: rgba(#771D1D, 0.12) !important;
}

.text-orange-500.text-secondary, .text-orange-500 .text-secondary {
  color: rgba(#FF5A1F, 0.7) !important;
}

.text-orange-500.text-hint, .text-orange-500 .text-hint, .text-orange-500.text-disabled, .text-orange-500 .text-disabled {
  color: rgba(#FF5A1F, 0.38) !important;
}

.text-orange-500.divider, .text-orange-500 .divider {
  color: rgba(#FF5A1F, 0.12) !important;
}

.orange-600 {
  background-color: #ff6700 !important;
  color: #FFF8F1 !important;
}

.orange-600.mat-icon, .orange-600 .mat-icon {
  color: #FFF8F1 !important;
}

.orange-600.text-secondary, .orange-600 .text-secondary {
  color: rgba(#FFF8F1, 0.7) !important;
}

.orange-600.text-hint, .orange-600 .text-hint, .orange-600.text-disabled, .orange-600 .text-disabled {
  color: rgba(#FFF8F1, 0.38) !important;
}

.orange-600.divider, .orange-600 .divider {
  color: rgba(#FFF8F1, 0.12) !important;
}

.text-orange-600.text-secondary, .text-orange-600 .text-secondary {
  color: rgba(#ff6700, 0.7) !important;
}

.text-orange-600.text-hint, .text-orange-600 .text-hint, .text-orange-600.text-disabled, .text-orange-600 .text-disabled {
  color: rgba(#ff6700, 0.38) !important;
}

.text-orange-600.divider, .text-orange-600 .divider {
  color: rgba(#ff6700, 0.12) !important;
}

.orange-700 {
  background-color: #B43403 !important;
  color: #FFF8F1 !important;
}

.orange-700.mat-icon, .orange-700 .mat-icon {
  color: #FFF8F1 !important;
}

.orange-700.text-secondary, .orange-700 .text-secondary {
  color: rgba(#FFF8F1, 0.7) !important;
}

.orange-700.text-hint, .orange-700 .text-hint, .orange-700.text-disabled, .orange-700 .text-disabled {
  color: rgba(#FFF8F1, 0.38) !important;
}

.orange-700.divider, .orange-700 .divider {
  color: rgba(#FFF8F1, 0.12) !important;
}

.text-orange-700.text-secondary, .text-orange-700 .text-secondary {
  color: rgba(#B43403, 0.7) !important;
}

.text-orange-700.text-hint, .text-orange-700 .text-hint, .text-orange-700.text-disabled, .text-orange-700 .text-disabled {
  color: rgba(#B43403, 0.38) !important;
}

.text-orange-700.divider, .text-orange-700 .divider {
  color: rgba(#B43403, 0.12) !important;
}

.orange-800 {
  background-color: #8A2C0D !important;
  color: #FFF8F1 !important;
}

.orange-800.mat-icon, .orange-800 .mat-icon {
  color: #FFF8F1 !important;
}

.orange-800.text-secondary, .orange-800 .text-secondary {
  color: rgba(#FFF8F1, 0.7) !important;
}

.orange-800.text-hint, .orange-800 .text-hint, .orange-800.text-disabled, .orange-800 .text-disabled {
  color: rgba(#FFF8F1, 0.38) !important;
}

.orange-800.divider, .orange-800 .divider {
  color: rgba(#FFF8F1, 0.12) !important;
}

.text-orange-800.text-secondary, .text-orange-800 .text-secondary {
  color: rgba(#8A2C0D, 0.7) !important;
}

.text-orange-800.text-hint, .text-orange-800 .text-hint, .text-orange-800.text-disabled, .text-orange-800 .text-disabled {
  color: rgba(#8A2C0D, 0.38) !important;
}

.text-orange-800.divider, .text-orange-800 .divider {
  color: rgba(#8A2C0D, 0.12) !important;
}

.orange-900 {
  background-color: #771D1D !important;
  color: #FFF8F1 !important;
}

.orange-900.mat-icon, .orange-900 .mat-icon {
  color: #FFF8F1 !important;
}

.orange-900.text-secondary, .orange-900 .text-secondary {
  color: rgba(#FFF8F1, 0.7) !important;
}

.orange-900.text-hint, .orange-900 .text-hint, .orange-900.text-disabled, .orange-900 .text-disabled {
  color: rgba(#FFF8F1, 0.38) !important;
}

.orange-900.divider, .orange-900 .divider {
  color: rgba(#FFF8F1, 0.12) !important;
}

.text-orange-900.text-secondary, .text-orange-900 .text-secondary {
  color: rgba(#771D1D, 0.7) !important;
}

.text-orange-900.text-hint, .text-orange-900 .text-hint, .text-orange-900.text-disabled, .text-orange-900 .text-disabled {
  color: rgba(#771D1D, 0.38) !important;
}

.text-orange-900.divider, .text-orange-900 .divider {
  color: rgba(#771D1D, 0.12) !important;
}

.orange {
  background-color: #FF5A1F !important;
  color: #771D1D !important;
}

.orange.mat-icon, .orange .mat-icon {
  color: #771D1D !important;
}

.orange.text-secondary, .orange .text-secondary {
  color: rgba(#771D1D, 0.7) !important;
}

.orange.text-hint, .orange .text-hint, .orange.text-disabled, .orange .text-disabled {
  color: rgba(#771D1D, 0.38) !important;
}

.orange.divider, .orange .divider {
  color: rgba(#771D1D, 0.12) !important;
}

.text-orange.text-secondary, .text-orange .text-secondary {
  color: rgba(#FF5A1F, 0.7) !important;
}

.text-orange.text-hint, .text-orange .text-hint, .text-orange.text-disabled, .text-orange .text-disabled {
  color: rgba(#FF5A1F, 0.38) !important;
}

.text-orange.divider, .text-orange .divider {
  color: rgba(#FF5A1F, 0.12) !important;
}

.yellow-50 {
  background-color: #FDFDEA !important;
  color: #633112 !important;
}

.yellow-50.mat-icon, .yellow-50 .mat-icon {
  color: #633112 !important;
}

.yellow-50.text-secondary, .yellow-50 .text-secondary {
  color: rgba(#633112, 0.7) !important;
}

.yellow-50.text-hint, .yellow-50 .text-hint, .yellow-50.text-disabled, .yellow-50 .text-disabled {
  color: rgba(#633112, 0.38) !important;
}

.yellow-50.divider, .yellow-50 .divider {
  color: rgba(#633112, 0.12) !important;
}

.text-yellow-50.text-secondary, .text-yellow-50 .text-secondary {
  color: rgba(#FDFDEA, 0.7) !important;
}

.text-yellow-50.text-hint, .text-yellow-50 .text-hint, .text-yellow-50.text-disabled, .text-yellow-50 .text-disabled {
  color: rgba(#FDFDEA, 0.38) !important;
}

.text-yellow-50.divider, .text-yellow-50 .divider {
  color: rgba(#FDFDEA, 0.12) !important;
}

.yellow-100 {
  background-color: #FDF6B2 !important;
  color: #633112 !important;
}

.yellow-100.mat-icon, .yellow-100 .mat-icon {
  color: #633112 !important;
}

.yellow-100.text-secondary, .yellow-100 .text-secondary {
  color: rgba(#633112, 0.7) !important;
}

.yellow-100.text-hint, .yellow-100 .text-hint, .yellow-100.text-disabled, .yellow-100 .text-disabled {
  color: rgba(#633112, 0.38) !important;
}

.yellow-100.divider, .yellow-100 .divider {
  color: rgba(#633112, 0.12) !important;
}

.text-yellow-100.text-secondary, .text-yellow-100 .text-secondary {
  color: rgba(#FDF6B2, 0.7) !important;
}

.text-yellow-100.text-hint, .text-yellow-100 .text-hint, .text-yellow-100.text-disabled, .text-yellow-100 .text-disabled {
  color: rgba(#FDF6B2, 0.38) !important;
}

.text-yellow-100.divider, .text-yellow-100 .divider {
  color: rgba(#FDF6B2, 0.12) !important;
}

.yellow-200 {
  background-color: #FCE96A !important;
  color: #633112 !important;
}

.yellow-200.mat-icon, .yellow-200 .mat-icon {
  color: #633112 !important;
}

.yellow-200.text-secondary, .yellow-200 .text-secondary {
  color: rgba(#633112, 0.7) !important;
}

.yellow-200.text-hint, .yellow-200 .text-hint, .yellow-200.text-disabled, .yellow-200 .text-disabled {
  color: rgba(#633112, 0.38) !important;
}

.yellow-200.divider, .yellow-200 .divider {
  color: rgba(#633112, 0.12) !important;
}

.text-yellow-200.text-secondary, .text-yellow-200 .text-secondary {
  color: rgba(#FCE96A, 0.7) !important;
}

.text-yellow-200.text-hint, .text-yellow-200 .text-hint, .text-yellow-200.text-disabled, .text-yellow-200 .text-disabled {
  color: rgba(#FCE96A, 0.38) !important;
}

.text-yellow-200.divider, .text-yellow-200 .divider {
  color: rgba(#FCE96A, 0.12) !important;
}

.yellow-300 {
  background-color: #FACA15 !important;
  color: #633112 !important;
}

.yellow-300.mat-icon, .yellow-300 .mat-icon {
  color: #633112 !important;
}

.yellow-300.text-secondary, .yellow-300 .text-secondary {
  color: rgba(#633112, 0.7) !important;
}

.yellow-300.text-hint, .yellow-300 .text-hint, .yellow-300.text-disabled, .yellow-300 .text-disabled {
  color: rgba(#633112, 0.38) !important;
}

.yellow-300.divider, .yellow-300 .divider {
  color: rgba(#633112, 0.12) !important;
}

.text-yellow-300.text-secondary, .text-yellow-300 .text-secondary {
  color: rgba(#FACA15, 0.7) !important;
}

.text-yellow-300.text-hint, .text-yellow-300 .text-hint, .text-yellow-300.text-disabled, .text-yellow-300 .text-disabled {
  color: rgba(#FACA15, 0.38) !important;
}

.text-yellow-300.divider, .text-yellow-300 .divider {
  color: rgba(#FACA15, 0.12) !important;
}

.yellow-400 {
  background-color: #E3A008 !important;
  color: #633112 !important;
}

.yellow-400.mat-icon, .yellow-400 .mat-icon {
  color: #633112 !important;
}

.yellow-400.text-secondary, .yellow-400 .text-secondary {
  color: rgba(#633112, 0.7) !important;
}

.yellow-400.text-hint, .yellow-400 .text-hint, .yellow-400.text-disabled, .yellow-400 .text-disabled {
  color: rgba(#633112, 0.38) !important;
}

.yellow-400.divider, .yellow-400 .divider {
  color: rgba(#633112, 0.12) !important;
}

.text-yellow-400.text-secondary, .text-yellow-400 .text-secondary {
  color: rgba(#E3A008, 0.7) !important;
}

.text-yellow-400.text-hint, .text-yellow-400 .text-hint, .text-yellow-400.text-disabled, .text-yellow-400 .text-disabled {
  color: rgba(#E3A008, 0.38) !important;
}

.text-yellow-400.divider, .text-yellow-400 .divider {
  color: rgba(#E3A008, 0.12) !important;
}

.yellow-500 {
  background-color: #C27803 !important;
  color: #633112 !important;
}

.yellow-500.mat-icon, .yellow-500 .mat-icon {
  color: #633112 !important;
}

.yellow-500.text-secondary, .yellow-500 .text-secondary {
  color: rgba(#633112, 0.7) !important;
}

.yellow-500.text-hint, .yellow-500 .text-hint, .yellow-500.text-disabled, .yellow-500 .text-disabled {
  color: rgba(#633112, 0.38) !important;
}

.yellow-500.divider, .yellow-500 .divider {
  color: rgba(#633112, 0.12) !important;
}

.text-yellow-500.text-secondary, .text-yellow-500 .text-secondary {
  color: rgba(#C27803, 0.7) !important;
}

.text-yellow-500.text-hint, .text-yellow-500 .text-hint, .text-yellow-500.text-disabled, .text-yellow-500 .text-disabled {
  color: rgba(#C27803, 0.38) !important;
}

.text-yellow-500.divider, .text-yellow-500 .divider {
  color: rgba(#C27803, 0.12) !important;
}

.yellow-600 {
  background-color: #9F580A !important;
  color: #FDFDEA !important;
}

.yellow-600.mat-icon, .yellow-600 .mat-icon {
  color: #FDFDEA !important;
}

.yellow-600.text-secondary, .yellow-600 .text-secondary {
  color: rgba(#FDFDEA, 0.7) !important;
}

.yellow-600.text-hint, .yellow-600 .text-hint, .yellow-600.text-disabled, .yellow-600 .text-disabled {
  color: rgba(#FDFDEA, 0.38) !important;
}

.yellow-600.divider, .yellow-600 .divider {
  color: rgba(#FDFDEA, 0.12) !important;
}

.text-yellow-600.text-secondary, .text-yellow-600 .text-secondary {
  color: rgba(#9F580A, 0.7) !important;
}

.text-yellow-600.text-hint, .text-yellow-600 .text-hint, .text-yellow-600.text-disabled, .text-yellow-600 .text-disabled {
  color: rgba(#9F580A, 0.38) !important;
}

.text-yellow-600.divider, .text-yellow-600 .divider {
  color: rgba(#9F580A, 0.12) !important;
}

.yellow-700 {
  background-color: #8E4B10 !important;
  color: #FDFDEA !important;
}

.yellow-700.mat-icon, .yellow-700 .mat-icon {
  color: #FDFDEA !important;
}

.yellow-700.text-secondary, .yellow-700 .text-secondary {
  color: rgba(#FDFDEA, 0.7) !important;
}

.yellow-700.text-hint, .yellow-700 .text-hint, .yellow-700.text-disabled, .yellow-700 .text-disabled {
  color: rgba(#FDFDEA, 0.38) !important;
}

.yellow-700.divider, .yellow-700 .divider {
  color: rgba(#FDFDEA, 0.12) !important;
}

.text-yellow-700.text-secondary, .text-yellow-700 .text-secondary {
  color: rgba(#8E4B10, 0.7) !important;
}

.text-yellow-700.text-hint, .text-yellow-700 .text-hint, .text-yellow-700.text-disabled, .text-yellow-700 .text-disabled {
  color: rgba(#8E4B10, 0.38) !important;
}

.text-yellow-700.divider, .text-yellow-700 .divider {
  color: rgba(#8E4B10, 0.12) !important;
}

.yellow-800 {
  background-color: #723B13 !important;
  color: #FDFDEA !important;
}

.yellow-800.mat-icon, .yellow-800 .mat-icon {
  color: #FDFDEA !important;
}

.yellow-800.text-secondary, .yellow-800 .text-secondary {
  color: rgba(#FDFDEA, 0.7) !important;
}

.yellow-800.text-hint, .yellow-800 .text-hint, .yellow-800.text-disabled, .yellow-800 .text-disabled {
  color: rgba(#FDFDEA, 0.38) !important;
}

.yellow-800.divider, .yellow-800 .divider {
  color: rgba(#FDFDEA, 0.12) !important;
}

.text-yellow-800.text-secondary, .text-yellow-800 .text-secondary {
  color: rgba(#723B13, 0.7) !important;
}

.text-yellow-800.text-hint, .text-yellow-800 .text-hint, .text-yellow-800.text-disabled, .text-yellow-800 .text-disabled {
  color: rgba(#723B13, 0.38) !important;
}

.text-yellow-800.divider, .text-yellow-800 .divider {
  color: rgba(#723B13, 0.12) !important;
}

.yellow-900 {
  background-color: #633112 !important;
  color: #FDFDEA !important;
}

.yellow-900.mat-icon, .yellow-900 .mat-icon {
  color: #FDFDEA !important;
}

.yellow-900.text-secondary, .yellow-900 .text-secondary {
  color: rgba(#FDFDEA, 0.7) !important;
}

.yellow-900.text-hint, .yellow-900 .text-hint, .yellow-900.text-disabled, .yellow-900 .text-disabled {
  color: rgba(#FDFDEA, 0.38) !important;
}

.yellow-900.divider, .yellow-900 .divider {
  color: rgba(#FDFDEA, 0.12) !important;
}

.text-yellow-900.text-secondary, .text-yellow-900 .text-secondary {
  color: rgba(#633112, 0.7) !important;
}

.text-yellow-900.text-hint, .text-yellow-900 .text-hint, .text-yellow-900.text-disabled, .text-yellow-900 .text-disabled {
  color: rgba(#633112, 0.38) !important;
}

.text-yellow-900.divider, .text-yellow-900 .divider {
  color: rgba(#633112, 0.12) !important;
}

.yellow {
  background-color: #C27803 !important;
  color: #633112 !important;
}

.yellow.mat-icon, .yellow .mat-icon {
  color: #633112 !important;
}

.yellow.text-secondary, .yellow .text-secondary {
  color: rgba(#633112, 0.7) !important;
}

.yellow.text-hint, .yellow .text-hint, .yellow.text-disabled, .yellow .text-disabled {
  color: rgba(#633112, 0.38) !important;
}

.yellow.divider, .yellow .divider {
  color: rgba(#633112, 0.12) !important;
}

.text-yellow.text-secondary, .text-yellow .text-secondary {
  color: rgba(#C27803, 0.7) !important;
}

.text-yellow.text-hint, .text-yellow .text-hint, .text-yellow.text-disabled, .text-yellow .text-disabled {
  color: rgba(#C27803, 0.38) !important;
}

.text-yellow.divider, .text-yellow .divider {
  color: rgba(#C27803, 0.12) !important;
}

.green-50 {
  background-color: #F3FAF7 !important;
  color: #014737 !important;
}

.green-50.mat-icon, .green-50 .mat-icon {
  color: #014737 !important;
}

.green-50.text-secondary, .green-50 .text-secondary {
  color: rgba(#014737, 0.7) !important;
}

.green-50.text-hint, .green-50 .text-hint, .green-50.text-disabled, .green-50 .text-disabled {
  color: rgba(#014737, 0.38) !important;
}

.green-50.divider, .green-50 .divider {
  color: rgba(#014737, 0.12) !important;
}

.text-green-50.text-secondary, .text-green-50 .text-secondary {
  color: rgba(#F3FAF7, 0.7) !important;
}

.text-green-50.text-hint, .text-green-50 .text-hint, .text-green-50.text-disabled, .text-green-50 .text-disabled {
  color: rgba(#F3FAF7, 0.38) !important;
}

.text-green-50.divider, .text-green-50 .divider {
  color: rgba(#F3FAF7, 0.12) !important;
}

.green-100 {
  background-color: #DEF7EC !important;
  color: #014737 !important;
}

.green-100.mat-icon, .green-100 .mat-icon {
  color: #014737 !important;
}

.green-100.text-secondary, .green-100 .text-secondary {
  color: rgba(#014737, 0.7) !important;
}

.green-100.text-hint, .green-100 .text-hint, .green-100.text-disabled, .green-100 .text-disabled {
  color: rgba(#014737, 0.38) !important;
}

.green-100.divider, .green-100 .divider {
  color: rgba(#014737, 0.12) !important;
}

.text-green-100.text-secondary, .text-green-100 .text-secondary {
  color: rgba(#DEF7EC, 0.7) !important;
}

.text-green-100.text-hint, .text-green-100 .text-hint, .text-green-100.text-disabled, .text-green-100 .text-disabled {
  color: rgba(#DEF7EC, 0.38) !important;
}

.text-green-100.divider, .text-green-100 .divider {
  color: rgba(#DEF7EC, 0.12) !important;
}

.green-200 {
  background-color: #BCF0DA !important;
  color: #014737 !important;
}

.green-200.mat-icon, .green-200 .mat-icon {
  color: #014737 !important;
}

.green-200.text-secondary, .green-200 .text-secondary {
  color: rgba(#014737, 0.7) !important;
}

.green-200.text-hint, .green-200 .text-hint, .green-200.text-disabled, .green-200 .text-disabled {
  color: rgba(#014737, 0.38) !important;
}

.green-200.divider, .green-200 .divider {
  color: rgba(#014737, 0.12) !important;
}

.text-green-200.text-secondary, .text-green-200 .text-secondary {
  color: rgba(#BCF0DA, 0.7) !important;
}

.text-green-200.text-hint, .text-green-200 .text-hint, .text-green-200.text-disabled, .text-green-200 .text-disabled {
  color: rgba(#BCF0DA, 0.38) !important;
}

.text-green-200.divider, .text-green-200 .divider {
  color: rgba(#BCF0DA, 0.12) !important;
}

.green-300 {
  background-color: #84E1BC !important;
  color: #014737 !important;
}

.green-300.mat-icon, .green-300 .mat-icon {
  color: #014737 !important;
}

.green-300.text-secondary, .green-300 .text-secondary {
  color: rgba(#014737, 0.7) !important;
}

.green-300.text-hint, .green-300 .text-hint, .green-300.text-disabled, .green-300 .text-disabled {
  color: rgba(#014737, 0.38) !important;
}

.green-300.divider, .green-300 .divider {
  color: rgba(#014737, 0.12) !important;
}

.text-green-300.text-secondary, .text-green-300 .text-secondary {
  color: rgba(#84E1BC, 0.7) !important;
}

.text-green-300.text-hint, .text-green-300 .text-hint, .text-green-300.text-disabled, .text-green-300 .text-disabled {
  color: rgba(#84E1BC, 0.38) !important;
}

.text-green-300.divider, .text-green-300 .divider {
  color: rgba(#84E1BC, 0.12) !important;
}

.green-400 {
  background-color: #31C48D !important;
  color: #014737 !important;
}

.green-400.mat-icon, .green-400 .mat-icon {
  color: #014737 !important;
}

.green-400.text-secondary, .green-400 .text-secondary {
  color: rgba(#014737, 0.7) !important;
}

.green-400.text-hint, .green-400 .text-hint, .green-400.text-disabled, .green-400 .text-disabled {
  color: rgba(#014737, 0.38) !important;
}

.green-400.divider, .green-400 .divider {
  color: rgba(#014737, 0.12) !important;
}

.text-green-400.text-secondary, .text-green-400 .text-secondary {
  color: rgba(#31C48D, 0.7) !important;
}

.text-green-400.text-hint, .text-green-400 .text-hint, .text-green-400.text-disabled, .text-green-400 .text-disabled {
  color: rgba(#31C48D, 0.38) !important;
}

.text-green-400.divider, .text-green-400 .divider {
  color: rgba(#31C48D, 0.12) !important;
}

.green-500 {
  background-color: #0E9F6E !important;
  color: #F3FAF7 !important;
}

.green-500.mat-icon, .green-500 .mat-icon {
  color: #F3FAF7 !important;
}

.green-500.text-secondary, .green-500 .text-secondary {
  color: rgba(#F3FAF7, 0.7) !important;
}

.green-500.text-hint, .green-500 .text-hint, .green-500.text-disabled, .green-500 .text-disabled {
  color: rgba(#F3FAF7, 0.38) !important;
}

.green-500.divider, .green-500 .divider {
  color: rgba(#F3FAF7, 0.12) !important;
}

.text-green-500.text-secondary, .text-green-500 .text-secondary {
  color: rgba(#0E9F6E, 0.7) !important;
}

.text-green-500.text-hint, .text-green-500 .text-hint, .text-green-500.text-disabled, .text-green-500 .text-disabled {
  color: rgba(#0E9F6E, 0.38) !important;
}

.text-green-500.divider, .text-green-500 .divider {
  color: rgba(#0E9F6E, 0.12) !important;
}

.green-600 {
  background-color: #057A55 !important;
  color: #F3FAF7 !important;
}

.green-600.mat-icon, .green-600 .mat-icon {
  color: #F3FAF7 !important;
}

.green-600.text-secondary, .green-600 .text-secondary {
  color: rgba(#F3FAF7, 0.7) !important;
}

.green-600.text-hint, .green-600 .text-hint, .green-600.text-disabled, .green-600 .text-disabled {
  color: rgba(#F3FAF7, 0.38) !important;
}

.green-600.divider, .green-600 .divider {
  color: rgba(#F3FAF7, 0.12) !important;
}

.text-green-600.text-secondary, .text-green-600 .text-secondary {
  color: rgba(#057A55, 0.7) !important;
}

.text-green-600.text-hint, .text-green-600 .text-hint, .text-green-600.text-disabled, .text-green-600 .text-disabled {
  color: rgba(#057A55, 0.38) !important;
}

.text-green-600.divider, .text-green-600 .divider {
  color: rgba(#057A55, 0.12) !important;
}

.green-700 {
  background-color: #046C4E !important;
  color: #F3FAF7 !important;
}

.green-700.mat-icon, .green-700 .mat-icon {
  color: #F3FAF7 !important;
}

.green-700.text-secondary, .green-700 .text-secondary {
  color: rgba(#F3FAF7, 0.7) !important;
}

.green-700.text-hint, .green-700 .text-hint, .green-700.text-disabled, .green-700 .text-disabled {
  color: rgba(#F3FAF7, 0.38) !important;
}

.green-700.divider, .green-700 .divider {
  color: rgba(#F3FAF7, 0.12) !important;
}

.text-green-700.text-secondary, .text-green-700 .text-secondary {
  color: rgba(#046C4E, 0.7) !important;
}

.text-green-700.text-hint, .text-green-700 .text-hint, .text-green-700.text-disabled, .text-green-700 .text-disabled {
  color: rgba(#046C4E, 0.38) !important;
}

.text-green-700.divider, .text-green-700 .divider {
  color: rgba(#046C4E, 0.12) !important;
}

.green-800 {
  background-color: #03543F !important;
  color: #F3FAF7 !important;
}

.green-800.mat-icon, .green-800 .mat-icon {
  color: #F3FAF7 !important;
}

.green-800.text-secondary, .green-800 .text-secondary {
  color: rgba(#F3FAF7, 0.7) !important;
}

.green-800.text-hint, .green-800 .text-hint, .green-800.text-disabled, .green-800 .text-disabled {
  color: rgba(#F3FAF7, 0.38) !important;
}

.green-800.divider, .green-800 .divider {
  color: rgba(#F3FAF7, 0.12) !important;
}

.text-green-800.text-secondary, .text-green-800 .text-secondary {
  color: rgba(#03543F, 0.7) !important;
}

.text-green-800.text-hint, .text-green-800 .text-hint, .text-green-800.text-disabled, .text-green-800 .text-disabled {
  color: rgba(#03543F, 0.38) !important;
}

.text-green-800.divider, .text-green-800 .divider {
  color: rgba(#03543F, 0.12) !important;
}

.green-900 {
  background-color: #014737 !important;
  color: #F3FAF7 !important;
}

.green-900.mat-icon, .green-900 .mat-icon {
  color: #F3FAF7 !important;
}

.green-900.text-secondary, .green-900 .text-secondary {
  color: rgba(#F3FAF7, 0.7) !important;
}

.green-900.text-hint, .green-900 .text-hint, .green-900.text-disabled, .green-900 .text-disabled {
  color: rgba(#F3FAF7, 0.38) !important;
}

.green-900.divider, .green-900 .divider {
  color: rgba(#F3FAF7, 0.12) !important;
}

.text-green-900.text-secondary, .text-green-900 .text-secondary {
  color: rgba(#014737, 0.7) !important;
}

.text-green-900.text-hint, .text-green-900 .text-hint, .text-green-900.text-disabled, .text-green-900 .text-disabled {
  color: rgba(#014737, 0.38) !important;
}

.text-green-900.divider, .text-green-900 .divider {
  color: rgba(#014737, 0.12) !important;
}

.green {
  background-color: #0E9F6E !important;
  color: #F3FAF7 !important;
}

.green.mat-icon, .green .mat-icon {
  color: #F3FAF7 !important;
}

.green.text-secondary, .green .text-secondary {
  color: rgba(#F3FAF7, 0.7) !important;
}

.green.text-hint, .green .text-hint, .green.text-disabled, .green .text-disabled {
  color: rgba(#F3FAF7, 0.38) !important;
}

.green.divider, .green .divider {
  color: rgba(#F3FAF7, 0.12) !important;
}

.text-green.text-secondary, .text-green .text-secondary {
  color: rgba(#0E9F6E, 0.7) !important;
}

.text-green.text-hint, .text-green .text-hint, .text-green.text-disabled, .text-green .text-disabled {
  color: rgba(#0E9F6E, 0.38) !important;
}

.text-green.divider, .text-green .divider {
  color: rgba(#0E9F6E, 0.12) !important;
}

.teal-50 {
  background-color: #EDFAFA !important;
  color: #014451 !important;
}

.teal-50.mat-icon, .teal-50 .mat-icon {
  color: #014451 !important;
}

.teal-50.text-secondary, .teal-50 .text-secondary {
  color: rgba(#014451, 0.7) !important;
}

.teal-50.text-hint, .teal-50 .text-hint, .teal-50.text-disabled, .teal-50 .text-disabled {
  color: rgba(#014451, 0.38) !important;
}

.teal-50.divider, .teal-50 .divider {
  color: rgba(#014451, 0.12) !important;
}

.text-teal-50.text-secondary, .text-teal-50 .text-secondary {
  color: rgba(#EDFAFA, 0.7) !important;
}

.text-teal-50.text-hint, .text-teal-50 .text-hint, .text-teal-50.text-disabled, .text-teal-50 .text-disabled {
  color: rgba(#EDFAFA, 0.38) !important;
}

.text-teal-50.divider, .text-teal-50 .divider {
  color: rgba(#EDFAFA, 0.12) !important;
}

.teal-100 {
  background-color: #D5F5F6 !important;
  color: #014451 !important;
}

.teal-100.mat-icon, .teal-100 .mat-icon {
  color: #014451 !important;
}

.teal-100.text-secondary, .teal-100 .text-secondary {
  color: rgba(#014451, 0.7) !important;
}

.teal-100.text-hint, .teal-100 .text-hint, .teal-100.text-disabled, .teal-100 .text-disabled {
  color: rgba(#014451, 0.38) !important;
}

.teal-100.divider, .teal-100 .divider {
  color: rgba(#014451, 0.12) !important;
}

.text-teal-100.text-secondary, .text-teal-100 .text-secondary {
  color: rgba(#D5F5F6, 0.7) !important;
}

.text-teal-100.text-hint, .text-teal-100 .text-hint, .text-teal-100.text-disabled, .text-teal-100 .text-disabled {
  color: rgba(#D5F5F6, 0.38) !important;
}

.text-teal-100.divider, .text-teal-100 .divider {
  color: rgba(#D5F5F6, 0.12) !important;
}

.teal-200 {
  background-color: #AFECEF !important;
  color: #014451 !important;
}

.teal-200.mat-icon, .teal-200 .mat-icon {
  color: #014451 !important;
}

.teal-200.text-secondary, .teal-200 .text-secondary {
  color: rgba(#014451, 0.7) !important;
}

.teal-200.text-hint, .teal-200 .text-hint, .teal-200.text-disabled, .teal-200 .text-disabled {
  color: rgba(#014451, 0.38) !important;
}

.teal-200.divider, .teal-200 .divider {
  color: rgba(#014451, 0.12) !important;
}

.text-teal-200.text-secondary, .text-teal-200 .text-secondary {
  color: rgba(#AFECEF, 0.7) !important;
}

.text-teal-200.text-hint, .text-teal-200 .text-hint, .text-teal-200.text-disabled, .text-teal-200 .text-disabled {
  color: rgba(#AFECEF, 0.38) !important;
}

.text-teal-200.divider, .text-teal-200 .divider {
  color: rgba(#AFECEF, 0.12) !important;
}

.teal-300 {
  background-color: #7EDCE2 !important;
  color: #014451 !important;
}

.teal-300.mat-icon, .teal-300 .mat-icon {
  color: #014451 !important;
}

.teal-300.text-secondary, .teal-300 .text-secondary {
  color: rgba(#014451, 0.7) !important;
}

.teal-300.text-hint, .teal-300 .text-hint, .teal-300.text-disabled, .teal-300 .text-disabled {
  color: rgba(#014451, 0.38) !important;
}

.teal-300.divider, .teal-300 .divider {
  color: rgba(#014451, 0.12) !important;
}

.text-teal-300.text-secondary, .text-teal-300 .text-secondary {
  color: rgba(#7EDCE2, 0.7) !important;
}

.text-teal-300.text-hint, .text-teal-300 .text-hint, .text-teal-300.text-disabled, .text-teal-300 .text-disabled {
  color: rgba(#7EDCE2, 0.38) !important;
}

.text-teal-300.divider, .text-teal-300 .divider {
  color: rgba(#7EDCE2, 0.12) !important;
}

.teal-400 {
  background-color: #16BDCA !important;
  color: #014451 !important;
}

.teal-400.mat-icon, .teal-400 .mat-icon {
  color: #014451 !important;
}

.teal-400.text-secondary, .teal-400 .text-secondary {
  color: rgba(#014451, 0.7) !important;
}

.teal-400.text-hint, .teal-400 .text-hint, .teal-400.text-disabled, .teal-400 .text-disabled {
  color: rgba(#014451, 0.38) !important;
}

.teal-400.divider, .teal-400 .divider {
  color: rgba(#014451, 0.12) !important;
}

.text-teal-400.text-secondary, .text-teal-400 .text-secondary {
  color: rgba(#16BDCA, 0.7) !important;
}

.text-teal-400.text-hint, .text-teal-400 .text-hint, .text-teal-400.text-disabled, .text-teal-400 .text-disabled {
  color: rgba(#16BDCA, 0.38) !important;
}

.text-teal-400.divider, .text-teal-400 .divider {
  color: rgba(#16BDCA, 0.12) !important;
}

.teal-500 {
  background-color: #0694A2 !important;
  color: #EDFAFA !important;
}

.teal-500.mat-icon, .teal-500 .mat-icon {
  color: #EDFAFA !important;
}

.teal-500.text-secondary, .teal-500 .text-secondary {
  color: rgba(#EDFAFA, 0.7) !important;
}

.teal-500.text-hint, .teal-500 .text-hint, .teal-500.text-disabled, .teal-500 .text-disabled {
  color: rgba(#EDFAFA, 0.38) !important;
}

.teal-500.divider, .teal-500 .divider {
  color: rgba(#EDFAFA, 0.12) !important;
}

.text-teal-500.text-secondary, .text-teal-500 .text-secondary {
  color: rgba(#0694A2, 0.7) !important;
}

.text-teal-500.text-hint, .text-teal-500 .text-hint, .text-teal-500.text-disabled, .text-teal-500 .text-disabled {
  color: rgba(#0694A2, 0.38) !important;
}

.text-teal-500.divider, .text-teal-500 .divider {
  color: rgba(#0694A2, 0.12) !important;
}

.teal-600 {
  background-color: #047481 !important;
  color: #EDFAFA !important;
}

.teal-600.mat-icon, .teal-600 .mat-icon {
  color: #EDFAFA !important;
}

.teal-600.text-secondary, .teal-600 .text-secondary {
  color: rgba(#EDFAFA, 0.7) !important;
}

.teal-600.text-hint, .teal-600 .text-hint, .teal-600.text-disabled, .teal-600 .text-disabled {
  color: rgba(#EDFAFA, 0.38) !important;
}

.teal-600.divider, .teal-600 .divider {
  color: rgba(#EDFAFA, 0.12) !important;
}

.text-teal-600.text-secondary, .text-teal-600 .text-secondary {
  color: rgba(#047481, 0.7) !important;
}

.text-teal-600.text-hint, .text-teal-600 .text-hint, .text-teal-600.text-disabled, .text-teal-600 .text-disabled {
  color: rgba(#047481, 0.38) !important;
}

.text-teal-600.divider, .text-teal-600 .divider {
  color: rgba(#047481, 0.12) !important;
}

.teal-700 {
  background-color: #036672 !important;
  color: #EDFAFA !important;
}

.teal-700.mat-icon, .teal-700 .mat-icon {
  color: #EDFAFA !important;
}

.teal-700.text-secondary, .teal-700 .text-secondary {
  color: rgba(#EDFAFA, 0.7) !important;
}

.teal-700.text-hint, .teal-700 .text-hint, .teal-700.text-disabled, .teal-700 .text-disabled {
  color: rgba(#EDFAFA, 0.38) !important;
}

.teal-700.divider, .teal-700 .divider {
  color: rgba(#EDFAFA, 0.12) !important;
}

.text-teal-700.text-secondary, .text-teal-700 .text-secondary {
  color: rgba(#036672, 0.7) !important;
}

.text-teal-700.text-hint, .text-teal-700 .text-hint, .text-teal-700.text-disabled, .text-teal-700 .text-disabled {
  color: rgba(#036672, 0.38) !important;
}

.text-teal-700.divider, .text-teal-700 .divider {
  color: rgba(#036672, 0.12) !important;
}

.teal-800 {
  background-color: #05505C !important;
  color: #EDFAFA !important;
}

.teal-800.mat-icon, .teal-800 .mat-icon {
  color: #EDFAFA !important;
}

.teal-800.text-secondary, .teal-800 .text-secondary {
  color: rgba(#EDFAFA, 0.7) !important;
}

.teal-800.text-hint, .teal-800 .text-hint, .teal-800.text-disabled, .teal-800 .text-disabled {
  color: rgba(#EDFAFA, 0.38) !important;
}

.teal-800.divider, .teal-800 .divider {
  color: rgba(#EDFAFA, 0.12) !important;
}

.text-teal-800.text-secondary, .text-teal-800 .text-secondary {
  color: rgba(#05505C, 0.7) !important;
}

.text-teal-800.text-hint, .text-teal-800 .text-hint, .text-teal-800.text-disabled, .text-teal-800 .text-disabled {
  color: rgba(#05505C, 0.38) !important;
}

.text-teal-800.divider, .text-teal-800 .divider {
  color: rgba(#05505C, 0.12) !important;
}

.teal-900 {
  background-color: #014451 !important;
  color: #EDFAFA !important;
}

.teal-900.mat-icon, .teal-900 .mat-icon {
  color: #EDFAFA !important;
}

.teal-900.text-secondary, .teal-900 .text-secondary {
  color: rgba(#EDFAFA, 0.7) !important;
}

.teal-900.text-hint, .teal-900 .text-hint, .teal-900.text-disabled, .teal-900 .text-disabled {
  color: rgba(#EDFAFA, 0.38) !important;
}

.teal-900.divider, .teal-900 .divider {
  color: rgba(#EDFAFA, 0.12) !important;
}

.text-teal-900.text-secondary, .text-teal-900 .text-secondary {
  color: rgba(#014451, 0.7) !important;
}

.text-teal-900.text-hint, .text-teal-900 .text-hint, .text-teal-900.text-disabled, .text-teal-900 .text-disabled {
  color: rgba(#014451, 0.38) !important;
}

.text-teal-900.divider, .text-teal-900 .divider {
  color: rgba(#014451, 0.12) !important;
}

.teal {
  background-color: #0694A2 !important;
  color: #EDFAFA !important;
}

.teal.mat-icon, .teal .mat-icon {
  color: #EDFAFA !important;
}

.teal.text-secondary, .teal .text-secondary {
  color: rgba(#EDFAFA, 0.7) !important;
}

.teal.text-hint, .teal .text-hint, .teal.text-disabled, .teal .text-disabled {
  color: rgba(#EDFAFA, 0.38) !important;
}

.teal.divider, .teal .divider {
  color: rgba(#EDFAFA, 0.12) !important;
}

.text-teal.text-secondary, .text-teal .text-secondary {
  color: rgba(#0694A2, 0.7) !important;
}

.text-teal.text-hint, .text-teal .text-hint, .text-teal.text-disabled, .text-teal .text-disabled {
  color: rgba(#0694A2, 0.38) !important;
}

.text-teal.divider, .text-teal .divider {
  color: rgba(#0694A2, 0.12) !important;
}

.blue-50 {
  background-color: #EBF5FF !important;
  color: #233876 !important;
}

.blue-50.mat-icon, .blue-50 .mat-icon {
  color: #233876 !important;
}

.blue-50.text-secondary, .blue-50 .text-secondary {
  color: rgba(#233876, 0.7) !important;
}

.blue-50.text-hint, .blue-50 .text-hint, .blue-50.text-disabled, .blue-50 .text-disabled {
  color: rgba(#233876, 0.38) !important;
}

.blue-50.divider, .blue-50 .divider {
  color: rgba(#233876, 0.12) !important;
}

.text-blue-50.text-secondary, .text-blue-50 .text-secondary {
  color: rgba(#EBF5FF, 0.7) !important;
}

.text-blue-50.text-hint, .text-blue-50 .text-hint, .text-blue-50.text-disabled, .text-blue-50 .text-disabled {
  color: rgba(#EBF5FF, 0.38) !important;
}

.text-blue-50.divider, .text-blue-50 .divider {
  color: rgba(#EBF5FF, 0.12) !important;
}

.blue-100 {
  background-color: #E1EFFE !important;
  color: #233876 !important;
}

.blue-100.mat-icon, .blue-100 .mat-icon {
  color: #233876 !important;
}

.blue-100.text-secondary, .blue-100 .text-secondary {
  color: rgba(#233876, 0.7) !important;
}

.blue-100.text-hint, .blue-100 .text-hint, .blue-100.text-disabled, .blue-100 .text-disabled {
  color: rgba(#233876, 0.38) !important;
}

.blue-100.divider, .blue-100 .divider {
  color: rgba(#233876, 0.12) !important;
}

.text-blue-100.text-secondary, .text-blue-100 .text-secondary {
  color: rgba(#E1EFFE, 0.7) !important;
}

.text-blue-100.text-hint, .text-blue-100 .text-hint, .text-blue-100.text-disabled, .text-blue-100 .text-disabled {
  color: rgba(#E1EFFE, 0.38) !important;
}

.text-blue-100.divider, .text-blue-100 .divider {
  color: rgba(#E1EFFE, 0.12) !important;
}

.blue-200 {
  background-color: #C3DDFD !important;
  color: #233876 !important;
}

.blue-200.mat-icon, .blue-200 .mat-icon {
  color: #233876 !important;
}

.blue-200.text-secondary, .blue-200 .text-secondary {
  color: rgba(#233876, 0.7) !important;
}

.blue-200.text-hint, .blue-200 .text-hint, .blue-200.text-disabled, .blue-200 .text-disabled {
  color: rgba(#233876, 0.38) !important;
}

.blue-200.divider, .blue-200 .divider {
  color: rgba(#233876, 0.12) !important;
}

.text-blue-200.text-secondary, .text-blue-200 .text-secondary {
  color: rgba(#C3DDFD, 0.7) !important;
}

.text-blue-200.text-hint, .text-blue-200 .text-hint, .text-blue-200.text-disabled, .text-blue-200 .text-disabled {
  color: rgba(#C3DDFD, 0.38) !important;
}

.text-blue-200.divider, .text-blue-200 .divider {
  color: rgba(#C3DDFD, 0.12) !important;
}

.blue-300 {
  background-color: #A4CAFE !important;
  color: #233876 !important;
}

.blue-300.mat-icon, .blue-300 .mat-icon {
  color: #233876 !important;
}

.blue-300.text-secondary, .blue-300 .text-secondary {
  color: rgba(#233876, 0.7) !important;
}

.blue-300.text-hint, .blue-300 .text-hint, .blue-300.text-disabled, .blue-300 .text-disabled {
  color: rgba(#233876, 0.38) !important;
}

.blue-300.divider, .blue-300 .divider {
  color: rgba(#233876, 0.12) !important;
}

.text-blue-300.text-secondary, .text-blue-300 .text-secondary {
  color: rgba(#A4CAFE, 0.7) !important;
}

.text-blue-300.text-hint, .text-blue-300 .text-hint, .text-blue-300.text-disabled, .text-blue-300 .text-disabled {
  color: rgba(#A4CAFE, 0.38) !important;
}

.text-blue-300.divider, .text-blue-300 .divider {
  color: rgba(#A4CAFE, 0.12) !important;
}

.blue-400 {
  background-color: #76A9FA !important;
  color: #233876 !important;
}

.blue-400.mat-icon, .blue-400 .mat-icon {
  color: #233876 !important;
}

.blue-400.text-secondary, .blue-400 .text-secondary {
  color: rgba(#233876, 0.7) !important;
}

.blue-400.text-hint, .blue-400 .text-hint, .blue-400.text-disabled, .blue-400 .text-disabled {
  color: rgba(#233876, 0.38) !important;
}

.blue-400.divider, .blue-400 .divider {
  color: rgba(#233876, 0.12) !important;
}

.text-blue-400.text-secondary, .text-blue-400 .text-secondary {
  color: rgba(#76A9FA, 0.7) !important;
}

.text-blue-400.text-hint, .text-blue-400 .text-hint, .text-blue-400.text-disabled, .text-blue-400 .text-disabled {
  color: rgba(#76A9FA, 0.38) !important;
}

.text-blue-400.divider, .text-blue-400 .divider {
  color: rgba(#76A9FA, 0.12) !important;
}

.blue-500 {
  background-color: #3F83F8 !important;
  color: #EBF5FF !important;
}

.blue-500.mat-icon, .blue-500 .mat-icon {
  color: #EBF5FF !important;
}

.blue-500.text-secondary, .blue-500 .text-secondary {
  color: rgba(#EBF5FF, 0.7) !important;
}

.blue-500.text-hint, .blue-500 .text-hint, .blue-500.text-disabled, .blue-500 .text-disabled {
  color: rgba(#EBF5FF, 0.38) !important;
}

.blue-500.divider, .blue-500 .divider {
  color: rgba(#EBF5FF, 0.12) !important;
}

.text-blue-500.text-secondary, .text-blue-500 .text-secondary {
  color: rgba(#3F83F8, 0.7) !important;
}

.text-blue-500.text-hint, .text-blue-500 .text-hint, .text-blue-500.text-disabled, .text-blue-500 .text-disabled {
  color: rgba(#3F83F8, 0.38) !important;
}

.text-blue-500.divider, .text-blue-500 .divider {
  color: rgba(#3F83F8, 0.12) !important;
}

.blue-600 {
  background-color: #1C64F2 !important;
  color: #EBF5FF !important;
}

.blue-600.mat-icon, .blue-600 .mat-icon {
  color: #EBF5FF !important;
}

.blue-600.text-secondary, .blue-600 .text-secondary {
  color: rgba(#EBF5FF, 0.7) !important;
}

.blue-600.text-hint, .blue-600 .text-hint, .blue-600.text-disabled, .blue-600 .text-disabled {
  color: rgba(#EBF5FF, 0.38) !important;
}

.blue-600.divider, .blue-600 .divider {
  color: rgba(#EBF5FF, 0.12) !important;
}

.text-blue-600.text-secondary, .text-blue-600 .text-secondary {
  color: rgba(#1C64F2, 0.7) !important;
}

.text-blue-600.text-hint, .text-blue-600 .text-hint, .text-blue-600.text-disabled, .text-blue-600 .text-disabled {
  color: rgba(#1C64F2, 0.38) !important;
}

.text-blue-600.divider, .text-blue-600 .divider {
  color: rgba(#1C64F2, 0.12) !important;
}

.blue-700 {
  background-color: #1A56DB !important;
  color: #EBF5FF !important;
}

.blue-700.mat-icon, .blue-700 .mat-icon {
  color: #EBF5FF !important;
}

.blue-700.text-secondary, .blue-700 .text-secondary {
  color: rgba(#EBF5FF, 0.7) !important;
}

.blue-700.text-hint, .blue-700 .text-hint, .blue-700.text-disabled, .blue-700 .text-disabled {
  color: rgba(#EBF5FF, 0.38) !important;
}

.blue-700.divider, .blue-700 .divider {
  color: rgba(#EBF5FF, 0.12) !important;
}

.text-blue-700.text-secondary, .text-blue-700 .text-secondary {
  color: rgba(#1A56DB, 0.7) !important;
}

.text-blue-700.text-hint, .text-blue-700 .text-hint, .text-blue-700.text-disabled, .text-blue-700 .text-disabled {
  color: rgba(#1A56DB, 0.38) !important;
}

.text-blue-700.divider, .text-blue-700 .divider {
  color: rgba(#1A56DB, 0.12) !important;
}

.blue-800 {
  background-color: #1E429F !important;
  color: #EBF5FF !important;
}

.blue-800.mat-icon, .blue-800 .mat-icon {
  color: #EBF5FF !important;
}

.blue-800.text-secondary, .blue-800 .text-secondary {
  color: rgba(#EBF5FF, 0.7) !important;
}

.blue-800.text-hint, .blue-800 .text-hint, .blue-800.text-disabled, .blue-800 .text-disabled {
  color: rgba(#EBF5FF, 0.38) !important;
}

.blue-800.divider, .blue-800 .divider {
  color: rgba(#EBF5FF, 0.12) !important;
}

.text-blue-800.text-secondary, .text-blue-800 .text-secondary {
  color: rgba(#1E429F, 0.7) !important;
}

.text-blue-800.text-hint, .text-blue-800 .text-hint, .text-blue-800.text-disabled, .text-blue-800 .text-disabled {
  color: rgba(#1E429F, 0.38) !important;
}

.text-blue-800.divider, .text-blue-800 .divider {
  color: rgba(#1E429F, 0.12) !important;
}

.blue-900 {
  background-color: #233876 !important;
  color: #EBF5FF !important;
}

.blue-900.mat-icon, .blue-900 .mat-icon {
  color: #EBF5FF !important;
}

.blue-900.text-secondary, .blue-900 .text-secondary {
  color: rgba(#EBF5FF, 0.7) !important;
}

.blue-900.text-hint, .blue-900 .text-hint, .blue-900.text-disabled, .blue-900 .text-disabled {
  color: rgba(#EBF5FF, 0.38) !important;
}

.blue-900.divider, .blue-900 .divider {
  color: rgba(#EBF5FF, 0.12) !important;
}

.text-blue-900.text-secondary, .text-blue-900 .text-secondary {
  color: rgba(#233876, 0.7) !important;
}

.text-blue-900.text-hint, .text-blue-900 .text-hint, .text-blue-900.text-disabled, .text-blue-900 .text-disabled {
  color: rgba(#233876, 0.38) !important;
}

.text-blue-900.divider, .text-blue-900 .divider {
  color: rgba(#233876, 0.12) !important;
}

.blue {
  background-color: #3F83F8 !important;
  color: #EBF5FF !important;
}

.blue.mat-icon, .blue .mat-icon {
  color: #EBF5FF !important;
}

.blue.text-secondary, .blue .text-secondary {
  color: rgba(#EBF5FF, 0.7) !important;
}

.blue.text-hint, .blue .text-hint, .blue.text-disabled, .blue .text-disabled {
  color: rgba(#EBF5FF, 0.38) !important;
}

.blue.divider, .blue .divider {
  color: rgba(#EBF5FF, 0.12) !important;
}

.text-blue.text-secondary, .text-blue .text-secondary {
  color: rgba(#3F83F8, 0.7) !important;
}

.text-blue.text-hint, .text-blue .text-hint, .text-blue.text-disabled, .text-blue .text-disabled {
  color: rgba(#3F83F8, 0.38) !important;
}

.text-blue.divider, .text-blue .divider {
  color: rgba(#3F83F8, 0.12) !important;
}

.indigo-50 {
  background-color: #F0F5FF !important;
  color: #362F78 !important;
}

.indigo-50.mat-icon, .indigo-50 .mat-icon {
  color: #362F78 !important;
}

.indigo-50.text-secondary, .indigo-50 .text-secondary {
  color: rgba(#362F78, 0.7) !important;
}

.indigo-50.text-hint, .indigo-50 .text-hint, .indigo-50.text-disabled, .indigo-50 .text-disabled {
  color: rgba(#362F78, 0.38) !important;
}

.indigo-50.divider, .indigo-50 .divider {
  color: rgba(#362F78, 0.12) !important;
}

.text-indigo-50.text-secondary, .text-indigo-50 .text-secondary {
  color: rgba(#F0F5FF, 0.7) !important;
}

.text-indigo-50.text-hint, .text-indigo-50 .text-hint, .text-indigo-50.text-disabled, .text-indigo-50 .text-disabled {
  color: rgba(#F0F5FF, 0.38) !important;
}

.text-indigo-50.divider, .text-indigo-50 .divider {
  color: rgba(#F0F5FF, 0.12) !important;
}

.indigo-100 {
  background-color: #E5EDFF !important;
  color: #362F78 !important;
}

.indigo-100.mat-icon, .indigo-100 .mat-icon {
  color: #362F78 !important;
}

.indigo-100.text-secondary, .indigo-100 .text-secondary {
  color: rgba(#362F78, 0.7) !important;
}

.indigo-100.text-hint, .indigo-100 .text-hint, .indigo-100.text-disabled, .indigo-100 .text-disabled {
  color: rgba(#362F78, 0.38) !important;
}

.indigo-100.divider, .indigo-100 .divider {
  color: rgba(#362F78, 0.12) !important;
}

.text-indigo-100.text-secondary, .text-indigo-100 .text-secondary {
  color: rgba(#E5EDFF, 0.7) !important;
}

.text-indigo-100.text-hint, .text-indigo-100 .text-hint, .text-indigo-100.text-disabled, .text-indigo-100 .text-disabled {
  color: rgba(#E5EDFF, 0.38) !important;
}

.text-indigo-100.divider, .text-indigo-100 .divider {
  color: rgba(#E5EDFF, 0.12) !important;
}

.indigo-200 {
  background-color: #CDDBFE !important;
  color: #362F78 !important;
}

.indigo-200.mat-icon, .indigo-200 .mat-icon {
  color: #362F78 !important;
}

.indigo-200.text-secondary, .indigo-200 .text-secondary {
  color: rgba(#362F78, 0.7) !important;
}

.indigo-200.text-hint, .indigo-200 .text-hint, .indigo-200.text-disabled, .indigo-200 .text-disabled {
  color: rgba(#362F78, 0.38) !important;
}

.indigo-200.divider, .indigo-200 .divider {
  color: rgba(#362F78, 0.12) !important;
}

.text-indigo-200.text-secondary, .text-indigo-200 .text-secondary {
  color: rgba(#CDDBFE, 0.7) !important;
}

.text-indigo-200.text-hint, .text-indigo-200 .text-hint, .text-indigo-200.text-disabled, .text-indigo-200 .text-disabled {
  color: rgba(#CDDBFE, 0.38) !important;
}

.text-indigo-200.divider, .text-indigo-200 .divider {
  color: rgba(#CDDBFE, 0.12) !important;
}

.indigo-300 {
  background-color: #B4C6FC !important;
  color: #362F78 !important;
}

.indigo-300.mat-icon, .indigo-300 .mat-icon {
  color: #362F78 !important;
}

.indigo-300.text-secondary, .indigo-300 .text-secondary {
  color: rgba(#362F78, 0.7) !important;
}

.indigo-300.text-hint, .indigo-300 .text-hint, .indigo-300.text-disabled, .indigo-300 .text-disabled {
  color: rgba(#362F78, 0.38) !important;
}

.indigo-300.divider, .indigo-300 .divider {
  color: rgba(#362F78, 0.12) !important;
}

.text-indigo-300.text-secondary, .text-indigo-300 .text-secondary {
  color: rgba(#B4C6FC, 0.7) !important;
}

.text-indigo-300.text-hint, .text-indigo-300 .text-hint, .text-indigo-300.text-disabled, .text-indigo-300 .text-disabled {
  color: rgba(#B4C6FC, 0.38) !important;
}

.text-indigo-300.divider, .text-indigo-300 .divider {
  color: rgba(#B4C6FC, 0.12) !important;
}

.indigo-400 {
  background-color: #8DA2FB !important;
  color: #362F78 !important;
}

.indigo-400.mat-icon, .indigo-400 .mat-icon {
  color: #362F78 !important;
}

.indigo-400.text-secondary, .indigo-400 .text-secondary {
  color: rgba(#362F78, 0.7) !important;
}

.indigo-400.text-hint, .indigo-400 .text-hint, .indigo-400.text-disabled, .indigo-400 .text-disabled {
  color: rgba(#362F78, 0.38) !important;
}

.indigo-400.divider, .indigo-400 .divider {
  color: rgba(#362F78, 0.12) !important;
}

.text-indigo-400.text-secondary, .text-indigo-400 .text-secondary {
  color: rgba(#8DA2FB, 0.7) !important;
}

.text-indigo-400.text-hint, .text-indigo-400 .text-hint, .text-indigo-400.text-disabled, .text-indigo-400 .text-disabled {
  color: rgba(#8DA2FB, 0.38) !important;
}

.text-indigo-400.divider, .text-indigo-400 .divider {
  color: rgba(#8DA2FB, 0.12) !important;
}

.indigo-500 {
  background-color: #6875F5 !important;
  color: #F0F5FF !important;
}

.indigo-500.mat-icon, .indigo-500 .mat-icon {
  color: #F0F5FF !important;
}

.indigo-500.text-secondary, .indigo-500 .text-secondary {
  color: rgba(#F0F5FF, 0.7) !important;
}

.indigo-500.text-hint, .indigo-500 .text-hint, .indigo-500.text-disabled, .indigo-500 .text-disabled {
  color: rgba(#F0F5FF, 0.38) !important;
}

.indigo-500.divider, .indigo-500 .divider {
  color: rgba(#F0F5FF, 0.12) !important;
}

.text-indigo-500.text-secondary, .text-indigo-500 .text-secondary {
  color: rgba(#6875F5, 0.7) !important;
}

.text-indigo-500.text-hint, .text-indigo-500 .text-hint, .text-indigo-500.text-disabled, .text-indigo-500 .text-disabled {
  color: rgba(#6875F5, 0.38) !important;
}

.text-indigo-500.divider, .text-indigo-500 .divider {
  color: rgba(#6875F5, 0.12) !important;
}

.indigo-600 {
  background-color: #5850EC !important;
  color: #F0F5FF !important;
}

.indigo-600.mat-icon, .indigo-600 .mat-icon {
  color: #F0F5FF !important;
}

.indigo-600.text-secondary, .indigo-600 .text-secondary {
  color: rgba(#F0F5FF, 0.7) !important;
}

.indigo-600.text-hint, .indigo-600 .text-hint, .indigo-600.text-disabled, .indigo-600 .text-disabled {
  color: rgba(#F0F5FF, 0.38) !important;
}

.indigo-600.divider, .indigo-600 .divider {
  color: rgba(#F0F5FF, 0.12) !important;
}

.text-indigo-600.text-secondary, .text-indigo-600 .text-secondary {
  color: rgba(#5850EC, 0.7) !important;
}

.text-indigo-600.text-hint, .text-indigo-600 .text-hint, .text-indigo-600.text-disabled, .text-indigo-600 .text-disabled {
  color: rgba(#5850EC, 0.38) !important;
}

.text-indigo-600.divider, .text-indigo-600 .divider {
  color: rgba(#5850EC, 0.12) !important;
}

.indigo-700 {
  background-color: #5145CD !important;
  color: #F0F5FF !important;
}

.indigo-700.mat-icon, .indigo-700 .mat-icon {
  color: #F0F5FF !important;
}

.indigo-700.text-secondary, .indigo-700 .text-secondary {
  color: rgba(#F0F5FF, 0.7) !important;
}

.indigo-700.text-hint, .indigo-700 .text-hint, .indigo-700.text-disabled, .indigo-700 .text-disabled {
  color: rgba(#F0F5FF, 0.38) !important;
}

.indigo-700.divider, .indigo-700 .divider {
  color: rgba(#F0F5FF, 0.12) !important;
}

.text-indigo-700.text-secondary, .text-indigo-700 .text-secondary {
  color: rgba(#5145CD, 0.7) !important;
}

.text-indigo-700.text-hint, .text-indigo-700 .text-hint, .text-indigo-700.text-disabled, .text-indigo-700 .text-disabled {
  color: rgba(#5145CD, 0.38) !important;
}

.text-indigo-700.divider, .text-indigo-700 .divider {
  color: rgba(#5145CD, 0.12) !important;
}

.indigo-800 {
  background-color: #42389D !important;
  color: #F0F5FF !important;
}

.indigo-800.mat-icon, .indigo-800 .mat-icon {
  color: #F0F5FF !important;
}

.indigo-800.text-secondary, .indigo-800 .text-secondary {
  color: rgba(#F0F5FF, 0.7) !important;
}

.indigo-800.text-hint, .indigo-800 .text-hint, .indigo-800.text-disabled, .indigo-800 .text-disabled {
  color: rgba(#F0F5FF, 0.38) !important;
}

.indigo-800.divider, .indigo-800 .divider {
  color: rgba(#F0F5FF, 0.12) !important;
}

.text-indigo-800.text-secondary, .text-indigo-800 .text-secondary {
  color: rgba(#42389D, 0.7) !important;
}

.text-indigo-800.text-hint, .text-indigo-800 .text-hint, .text-indigo-800.text-disabled, .text-indigo-800 .text-disabled {
  color: rgba(#42389D, 0.38) !important;
}

.text-indigo-800.divider, .text-indigo-800 .divider {
  color: rgba(#42389D, 0.12) !important;
}

.indigo-900 {
  background-color: #362F78 !important;
  color: #F0F5FF !important;
}

.indigo-900.mat-icon, .indigo-900 .mat-icon {
  color: #F0F5FF !important;
}

.indigo-900.text-secondary, .indigo-900 .text-secondary {
  color: rgba(#F0F5FF, 0.7) !important;
}

.indigo-900.text-hint, .indigo-900 .text-hint, .indigo-900.text-disabled, .indigo-900 .text-disabled {
  color: rgba(#F0F5FF, 0.38) !important;
}

.indigo-900.divider, .indigo-900 .divider {
  color: rgba(#F0F5FF, 0.12) !important;
}

.text-indigo-900.text-secondary, .text-indigo-900 .text-secondary {
  color: rgba(#362F78, 0.7) !important;
}

.text-indigo-900.text-hint, .text-indigo-900 .text-hint, .text-indigo-900.text-disabled, .text-indigo-900 .text-disabled {
  color: rgba(#362F78, 0.38) !important;
}

.text-indigo-900.divider, .text-indigo-900 .divider {
  color: rgba(#362F78, 0.12) !important;
}

.indigo {
  background-color: #6875F5 !important;
  color: #F0F5FF !important;
}

.indigo.mat-icon, .indigo .mat-icon {
  color: #F0F5FF !important;
}

.indigo.text-secondary, .indigo .text-secondary {
  color: rgba(#F0F5FF, 0.7) !important;
}

.indigo.text-hint, .indigo .text-hint, .indigo.text-disabled, .indigo .text-disabled {
  color: rgba(#F0F5FF, 0.38) !important;
}

.indigo.divider, .indigo .divider {
  color: rgba(#F0F5FF, 0.12) !important;
}

.text-indigo.text-secondary, .text-indigo .text-secondary {
  color: rgba(#6875F5, 0.7) !important;
}

.text-indigo.text-hint, .text-indigo .text-hint, .text-indigo.text-disabled, .text-indigo .text-disabled {
  color: rgba(#6875F5, 0.38) !important;
}

.text-indigo.divider, .text-indigo .divider {
  color: rgba(#6875F5, 0.12) !important;
}

.purple-50 {
  background-color: #F6F5FF !important;
  color: #4A1D96 !important;
}

.purple-50.mat-icon, .purple-50 .mat-icon {
  color: #4A1D96 !important;
}

.purple-50.text-secondary, .purple-50 .text-secondary {
  color: rgba(#4A1D96, 0.7) !important;
}

.purple-50.text-hint, .purple-50 .text-hint, .purple-50.text-disabled, .purple-50 .text-disabled {
  color: rgba(#4A1D96, 0.38) !important;
}

.purple-50.divider, .purple-50 .divider {
  color: rgba(#4A1D96, 0.12) !important;
}

.text-purple-50.text-secondary, .text-purple-50 .text-secondary {
  color: rgba(#F6F5FF, 0.7) !important;
}

.text-purple-50.text-hint, .text-purple-50 .text-hint, .text-purple-50.text-disabled, .text-purple-50 .text-disabled {
  color: rgba(#F6F5FF, 0.38) !important;
}

.text-purple-50.divider, .text-purple-50 .divider {
  color: rgba(#F6F5FF, 0.12) !important;
}

.purple-100 {
  background-color: #EDEBFE !important;
  color: #4A1D96 !important;
}

.purple-100.mat-icon, .purple-100 .mat-icon {
  color: #4A1D96 !important;
}

.purple-100.text-secondary, .purple-100 .text-secondary {
  color: rgba(#4A1D96, 0.7) !important;
}

.purple-100.text-hint, .purple-100 .text-hint, .purple-100.text-disabled, .purple-100 .text-disabled {
  color: rgba(#4A1D96, 0.38) !important;
}

.purple-100.divider, .purple-100 .divider {
  color: rgba(#4A1D96, 0.12) !important;
}

.text-purple-100.text-secondary, .text-purple-100 .text-secondary {
  color: rgba(#EDEBFE, 0.7) !important;
}

.text-purple-100.text-hint, .text-purple-100 .text-hint, .text-purple-100.text-disabled, .text-purple-100 .text-disabled {
  color: rgba(#EDEBFE, 0.38) !important;
}

.text-purple-100.divider, .text-purple-100 .divider {
  color: rgba(#EDEBFE, 0.12) !important;
}

.purple-200 {
  background-color: #DCD7FE !important;
  color: #4A1D96 !important;
}

.purple-200.mat-icon, .purple-200 .mat-icon {
  color: #4A1D96 !important;
}

.purple-200.text-secondary, .purple-200 .text-secondary {
  color: rgba(#4A1D96, 0.7) !important;
}

.purple-200.text-hint, .purple-200 .text-hint, .purple-200.text-disabled, .purple-200 .text-disabled {
  color: rgba(#4A1D96, 0.38) !important;
}

.purple-200.divider, .purple-200 .divider {
  color: rgba(#4A1D96, 0.12) !important;
}

.text-purple-200.text-secondary, .text-purple-200 .text-secondary {
  color: rgba(#DCD7FE, 0.7) !important;
}

.text-purple-200.text-hint, .text-purple-200 .text-hint, .text-purple-200.text-disabled, .text-purple-200 .text-disabled {
  color: rgba(#DCD7FE, 0.38) !important;
}

.text-purple-200.divider, .text-purple-200 .divider {
  color: rgba(#DCD7FE, 0.12) !important;
}

.purple-300 {
  background-color: #CABFFD !important;
  color: #4A1D96 !important;
}

.purple-300.mat-icon, .purple-300 .mat-icon {
  color: #4A1D96 !important;
}

.purple-300.text-secondary, .purple-300 .text-secondary {
  color: rgba(#4A1D96, 0.7) !important;
}

.purple-300.text-hint, .purple-300 .text-hint, .purple-300.text-disabled, .purple-300 .text-disabled {
  color: rgba(#4A1D96, 0.38) !important;
}

.purple-300.divider, .purple-300 .divider {
  color: rgba(#4A1D96, 0.12) !important;
}

.text-purple-300.text-secondary, .text-purple-300 .text-secondary {
  color: rgba(#CABFFD, 0.7) !important;
}

.text-purple-300.text-hint, .text-purple-300 .text-hint, .text-purple-300.text-disabled, .text-purple-300 .text-disabled {
  color: rgba(#CABFFD, 0.38) !important;
}

.text-purple-300.divider, .text-purple-300 .divider {
  color: rgba(#CABFFD, 0.12) !important;
}

.purple-400 {
  background-color: #AC94FA !important;
  color: #4A1D96 !important;
}

.purple-400.mat-icon, .purple-400 .mat-icon {
  color: #4A1D96 !important;
}

.purple-400.text-secondary, .purple-400 .text-secondary {
  color: rgba(#4A1D96, 0.7) !important;
}

.purple-400.text-hint, .purple-400 .text-hint, .purple-400.text-disabled, .purple-400 .text-disabled {
  color: rgba(#4A1D96, 0.38) !important;
}

.purple-400.divider, .purple-400 .divider {
  color: rgba(#4A1D96, 0.12) !important;
}

.text-purple-400.text-secondary, .text-purple-400 .text-secondary {
  color: rgba(#AC94FA, 0.7) !important;
}

.text-purple-400.text-hint, .text-purple-400 .text-hint, .text-purple-400.text-disabled, .text-purple-400 .text-disabled {
  color: rgba(#AC94FA, 0.38) !important;
}

.text-purple-400.divider, .text-purple-400 .divider {
  color: rgba(#AC94FA, 0.12) !important;
}

.purple-500 {
  background-color: #9061F9 !important;
  color: #F6F5FF !important;
}

.purple-500.mat-icon, .purple-500 .mat-icon {
  color: #F6F5FF !important;
}

.purple-500.text-secondary, .purple-500 .text-secondary {
  color: rgba(#F6F5FF, 0.7) !important;
}

.purple-500.text-hint, .purple-500 .text-hint, .purple-500.text-disabled, .purple-500 .text-disabled {
  color: rgba(#F6F5FF, 0.38) !important;
}

.purple-500.divider, .purple-500 .divider {
  color: rgba(#F6F5FF, 0.12) !important;
}

.text-purple-500.text-secondary, .text-purple-500 .text-secondary {
  color: rgba(#9061F9, 0.7) !important;
}

.text-purple-500.text-hint, .text-purple-500 .text-hint, .text-purple-500.text-disabled, .text-purple-500 .text-disabled {
  color: rgba(#9061F9, 0.38) !important;
}

.text-purple-500.divider, .text-purple-500 .divider {
  color: rgba(#9061F9, 0.12) !important;
}

.purple-600 {
  background-color: #7E3AF2 !important;
  color: #F6F5FF !important;
}

.purple-600.mat-icon, .purple-600 .mat-icon {
  color: #F6F5FF !important;
}

.purple-600.text-secondary, .purple-600 .text-secondary {
  color: rgba(#F6F5FF, 0.7) !important;
}

.purple-600.text-hint, .purple-600 .text-hint, .purple-600.text-disabled, .purple-600 .text-disabled {
  color: rgba(#F6F5FF, 0.38) !important;
}

.purple-600.divider, .purple-600 .divider {
  color: rgba(#F6F5FF, 0.12) !important;
}

.text-purple-600.text-secondary, .text-purple-600 .text-secondary {
  color: rgba(#7E3AF2, 0.7) !important;
}

.text-purple-600.text-hint, .text-purple-600 .text-hint, .text-purple-600.text-disabled, .text-purple-600 .text-disabled {
  color: rgba(#7E3AF2, 0.38) !important;
}

.text-purple-600.divider, .text-purple-600 .divider {
  color: rgba(#7E3AF2, 0.12) !important;
}

.purple-700 {
  background-color: #6C2BD9 !important;
  color: #F6F5FF !important;
}

.purple-700.mat-icon, .purple-700 .mat-icon {
  color: #F6F5FF !important;
}

.purple-700.text-secondary, .purple-700 .text-secondary {
  color: rgba(#F6F5FF, 0.7) !important;
}

.purple-700.text-hint, .purple-700 .text-hint, .purple-700.text-disabled, .purple-700 .text-disabled {
  color: rgba(#F6F5FF, 0.38) !important;
}

.purple-700.divider, .purple-700 .divider {
  color: rgba(#F6F5FF, 0.12) !important;
}

.text-purple-700.text-secondary, .text-purple-700 .text-secondary {
  color: rgba(#6C2BD9, 0.7) !important;
}

.text-purple-700.text-hint, .text-purple-700 .text-hint, .text-purple-700.text-disabled, .text-purple-700 .text-disabled {
  color: rgba(#6C2BD9, 0.38) !important;
}

.text-purple-700.divider, .text-purple-700 .divider {
  color: rgba(#6C2BD9, 0.12) !important;
}

.purple-800 {
  background-color: #5521B5 !important;
  color: #F6F5FF !important;
}

.purple-800.mat-icon, .purple-800 .mat-icon {
  color: #F6F5FF !important;
}

.purple-800.text-secondary, .purple-800 .text-secondary {
  color: rgba(#F6F5FF, 0.7) !important;
}

.purple-800.text-hint, .purple-800 .text-hint, .purple-800.text-disabled, .purple-800 .text-disabled {
  color: rgba(#F6F5FF, 0.38) !important;
}

.purple-800.divider, .purple-800 .divider {
  color: rgba(#F6F5FF, 0.12) !important;
}

.text-purple-800.text-secondary, .text-purple-800 .text-secondary {
  color: rgba(#5521B5, 0.7) !important;
}

.text-purple-800.text-hint, .text-purple-800 .text-hint, .text-purple-800.text-disabled, .text-purple-800 .text-disabled {
  color: rgba(#5521B5, 0.38) !important;
}

.text-purple-800.divider, .text-purple-800 .divider {
  color: rgba(#5521B5, 0.12) !important;
}

.purple-900 {
  background-color: #4A1D96 !important;
  color: #F6F5FF !important;
}

.purple-900.mat-icon, .purple-900 .mat-icon {
  color: #F6F5FF !important;
}

.purple-900.text-secondary, .purple-900 .text-secondary {
  color: rgba(#F6F5FF, 0.7) !important;
}

.purple-900.text-hint, .purple-900 .text-hint, .purple-900.text-disabled, .purple-900 .text-disabled {
  color: rgba(#F6F5FF, 0.38) !important;
}

.purple-900.divider, .purple-900 .divider {
  color: rgba(#F6F5FF, 0.12) !important;
}

.text-purple-900.text-secondary, .text-purple-900 .text-secondary {
  color: rgba(#4A1D96, 0.7) !important;
}

.text-purple-900.text-hint, .text-purple-900 .text-hint, .text-purple-900.text-disabled, .text-purple-900 .text-disabled {
  color: rgba(#4A1D96, 0.38) !important;
}

.text-purple-900.divider, .text-purple-900 .divider {
  color: rgba(#4A1D96, 0.12) !important;
}

.purple {
  background-color: #9061F9 !important;
  color: #F6F5FF !important;
}

.purple.mat-icon, .purple .mat-icon {
  color: #F6F5FF !important;
}

.purple.text-secondary, .purple .text-secondary {
  color: rgba(#F6F5FF, 0.7) !important;
}

.purple.text-hint, .purple .text-hint, .purple.text-disabled, .purple .text-disabled {
  color: rgba(#F6F5FF, 0.38) !important;
}

.purple.divider, .purple .divider {
  color: rgba(#F6F5FF, 0.12) !important;
}

.text-purple.text-secondary, .text-purple .text-secondary {
  color: rgba(#9061F9, 0.7) !important;
}

.text-purple.text-hint, .text-purple .text-hint, .text-purple.text-disabled, .text-purple .text-disabled {
  color: rgba(#9061F9, 0.38) !important;
}

.text-purple.divider, .text-purple .divider {
  color: rgba(#9061F9, 0.12) !important;
}

.pink-50 {
  background-color: #FDF2F8 !important;
  color: #751A3D !important;
}

.pink-50.mat-icon, .pink-50 .mat-icon {
  color: #751A3D !important;
}

.pink-50.text-secondary, .pink-50 .text-secondary {
  color: rgba(#751A3D, 0.7) !important;
}

.pink-50.text-hint, .pink-50 .text-hint, .pink-50.text-disabled, .pink-50 .text-disabled {
  color: rgba(#751A3D, 0.38) !important;
}

.pink-50.divider, .pink-50 .divider {
  color: rgba(#751A3D, 0.12) !important;
}

.text-pink-50.text-secondary, .text-pink-50 .text-secondary {
  color: rgba(#FDF2F8, 0.7) !important;
}

.text-pink-50.text-hint, .text-pink-50 .text-hint, .text-pink-50.text-disabled, .text-pink-50 .text-disabled {
  color: rgba(#FDF2F8, 0.38) !important;
}

.text-pink-50.divider, .text-pink-50 .divider {
  color: rgba(#FDF2F8, 0.12) !important;
}

.pink-100 {
  background-color: #FCE8F3 !important;
  color: #751A3D !important;
}

.pink-100.mat-icon, .pink-100 .mat-icon {
  color: #751A3D !important;
}

.pink-100.text-secondary, .pink-100 .text-secondary {
  color: rgba(#751A3D, 0.7) !important;
}

.pink-100.text-hint, .pink-100 .text-hint, .pink-100.text-disabled, .pink-100 .text-disabled {
  color: rgba(#751A3D, 0.38) !important;
}

.pink-100.divider, .pink-100 .divider {
  color: rgba(#751A3D, 0.12) !important;
}

.text-pink-100.text-secondary, .text-pink-100 .text-secondary {
  color: rgba(#FCE8F3, 0.7) !important;
}

.text-pink-100.text-hint, .text-pink-100 .text-hint, .text-pink-100.text-disabled, .text-pink-100 .text-disabled {
  color: rgba(#FCE8F3, 0.38) !important;
}

.text-pink-100.divider, .text-pink-100 .divider {
  color: rgba(#FCE8F3, 0.12) !important;
}

.pink-200 {
  background-color: #FAD1E8 !important;
  color: #751A3D !important;
}

.pink-200.mat-icon, .pink-200 .mat-icon {
  color: #751A3D !important;
}

.pink-200.text-secondary, .pink-200 .text-secondary {
  color: rgba(#751A3D, 0.7) !important;
}

.pink-200.text-hint, .pink-200 .text-hint, .pink-200.text-disabled, .pink-200 .text-disabled {
  color: rgba(#751A3D, 0.38) !important;
}

.pink-200.divider, .pink-200 .divider {
  color: rgba(#751A3D, 0.12) !important;
}

.text-pink-200.text-secondary, .text-pink-200 .text-secondary {
  color: rgba(#FAD1E8, 0.7) !important;
}

.text-pink-200.text-hint, .text-pink-200 .text-hint, .text-pink-200.text-disabled, .text-pink-200 .text-disabled {
  color: rgba(#FAD1E8, 0.38) !important;
}

.text-pink-200.divider, .text-pink-200 .divider {
  color: rgba(#FAD1E8, 0.12) !important;
}

.pink-300 {
  background-color: #F8B4D9 !important;
  color: #751A3D !important;
}

.pink-300.mat-icon, .pink-300 .mat-icon {
  color: #751A3D !important;
}

.pink-300.text-secondary, .pink-300 .text-secondary {
  color: rgba(#751A3D, 0.7) !important;
}

.pink-300.text-hint, .pink-300 .text-hint, .pink-300.text-disabled, .pink-300 .text-disabled {
  color: rgba(#751A3D, 0.38) !important;
}

.pink-300.divider, .pink-300 .divider {
  color: rgba(#751A3D, 0.12) !important;
}

.text-pink-300.text-secondary, .text-pink-300 .text-secondary {
  color: rgba(#F8B4D9, 0.7) !important;
}

.text-pink-300.text-hint, .text-pink-300 .text-hint, .text-pink-300.text-disabled, .text-pink-300 .text-disabled {
  color: rgba(#F8B4D9, 0.38) !important;
}

.text-pink-300.divider, .text-pink-300 .divider {
  color: rgba(#F8B4D9, 0.12) !important;
}

.pink-400 {
  background-color: #F17EB8 !important;
  color: #751A3D !important;
}

.pink-400.mat-icon, .pink-400 .mat-icon {
  color: #751A3D !important;
}

.pink-400.text-secondary, .pink-400 .text-secondary {
  color: rgba(#751A3D, 0.7) !important;
}

.pink-400.text-hint, .pink-400 .text-hint, .pink-400.text-disabled, .pink-400 .text-disabled {
  color: rgba(#751A3D, 0.38) !important;
}

.pink-400.divider, .pink-400 .divider {
  color: rgba(#751A3D, 0.12) !important;
}

.text-pink-400.text-secondary, .text-pink-400 .text-secondary {
  color: rgba(#F17EB8, 0.7) !important;
}

.text-pink-400.text-hint, .text-pink-400 .text-hint, .text-pink-400.text-disabled, .text-pink-400 .text-disabled {
  color: rgba(#F17EB8, 0.38) !important;
}

.text-pink-400.divider, .text-pink-400 .divider {
  color: rgba(#F17EB8, 0.12) !important;
}

.pink-500 {
  background-color: #E74694 !important;
  color: #FDF2F8 !important;
}

.pink-500.mat-icon, .pink-500 .mat-icon {
  color: #FDF2F8 !important;
}

.pink-500.text-secondary, .pink-500 .text-secondary {
  color: rgba(#FDF2F8, 0.7) !important;
}

.pink-500.text-hint, .pink-500 .text-hint, .pink-500.text-disabled, .pink-500 .text-disabled {
  color: rgba(#FDF2F8, 0.38) !important;
}

.pink-500.divider, .pink-500 .divider {
  color: rgba(#FDF2F8, 0.12) !important;
}

.text-pink-500.text-secondary, .text-pink-500 .text-secondary {
  color: rgba(#E74694, 0.7) !important;
}

.text-pink-500.text-hint, .text-pink-500 .text-hint, .text-pink-500.text-disabled, .text-pink-500 .text-disabled {
  color: rgba(#E74694, 0.38) !important;
}

.text-pink-500.divider, .text-pink-500 .divider {
  color: rgba(#E74694, 0.12) !important;
}

.pink-600 {
  background-color: #D61F69 !important;
  color: #FDF2F8 !important;
}

.pink-600.mat-icon, .pink-600 .mat-icon {
  color: #FDF2F8 !important;
}

.pink-600.text-secondary, .pink-600 .text-secondary {
  color: rgba(#FDF2F8, 0.7) !important;
}

.pink-600.text-hint, .pink-600 .text-hint, .pink-600.text-disabled, .pink-600 .text-disabled {
  color: rgba(#FDF2F8, 0.38) !important;
}

.pink-600.divider, .pink-600 .divider {
  color: rgba(#FDF2F8, 0.12) !important;
}

.text-pink-600.text-secondary, .text-pink-600 .text-secondary {
  color: rgba(#D61F69, 0.7) !important;
}

.text-pink-600.text-hint, .text-pink-600 .text-hint, .text-pink-600.text-disabled, .text-pink-600 .text-disabled {
  color: rgba(#D61F69, 0.38) !important;
}

.text-pink-600.divider, .text-pink-600 .divider {
  color: rgba(#D61F69, 0.12) !important;
}

.pink-700 {
  background-color: #BF125D !important;
  color: #FDF2F8 !important;
}

.pink-700.mat-icon, .pink-700 .mat-icon {
  color: #FDF2F8 !important;
}

.pink-700.text-secondary, .pink-700 .text-secondary {
  color: rgba(#FDF2F8, 0.7) !important;
}

.pink-700.text-hint, .pink-700 .text-hint, .pink-700.text-disabled, .pink-700 .text-disabled {
  color: rgba(#FDF2F8, 0.38) !important;
}

.pink-700.divider, .pink-700 .divider {
  color: rgba(#FDF2F8, 0.12) !important;
}

.text-pink-700.text-secondary, .text-pink-700 .text-secondary {
  color: rgba(#BF125D, 0.7) !important;
}

.text-pink-700.text-hint, .text-pink-700 .text-hint, .text-pink-700.text-disabled, .text-pink-700 .text-disabled {
  color: rgba(#BF125D, 0.38) !important;
}

.text-pink-700.divider, .text-pink-700 .divider {
  color: rgba(#BF125D, 0.12) !important;
}

.pink-800 {
  background-color: #99154B !important;
  color: #FDF2F8 !important;
}

.pink-800.mat-icon, .pink-800 .mat-icon {
  color: #FDF2F8 !important;
}

.pink-800.text-secondary, .pink-800 .text-secondary {
  color: rgba(#FDF2F8, 0.7) !important;
}

.pink-800.text-hint, .pink-800 .text-hint, .pink-800.text-disabled, .pink-800 .text-disabled {
  color: rgba(#FDF2F8, 0.38) !important;
}

.pink-800.divider, .pink-800 .divider {
  color: rgba(#FDF2F8, 0.12) !important;
}

.text-pink-800.text-secondary, .text-pink-800 .text-secondary {
  color: rgba(#99154B, 0.7) !important;
}

.text-pink-800.text-hint, .text-pink-800 .text-hint, .text-pink-800.text-disabled, .text-pink-800 .text-disabled {
  color: rgba(#99154B, 0.38) !important;
}

.text-pink-800.divider, .text-pink-800 .divider {
  color: rgba(#99154B, 0.12) !important;
}

.pink-900 {
  background-color: #751A3D !important;
  color: #FDF2F8 !important;
}

.pink-900.mat-icon, .pink-900 .mat-icon {
  color: #FDF2F8 !important;
}

.pink-900.text-secondary, .pink-900 .text-secondary {
  color: rgba(#FDF2F8, 0.7) !important;
}

.pink-900.text-hint, .pink-900 .text-hint, .pink-900.text-disabled, .pink-900 .text-disabled {
  color: rgba(#FDF2F8, 0.38) !important;
}

.pink-900.divider, .pink-900 .divider {
  color: rgba(#FDF2F8, 0.12) !important;
}

.text-pink-900.text-secondary, .text-pink-900 .text-secondary {
  color: rgba(#751A3D, 0.7) !important;
}

.text-pink-900.text-hint, .text-pink-900 .text-hint, .text-pink-900.text-disabled, .text-pink-900 .text-disabled {
  color: rgba(#751A3D, 0.38) !important;
}

.text-pink-900.divider, .text-pink-900 .divider {
  color: rgba(#751A3D, 0.12) !important;
}

.pink {
  background-color: #E74694 !important;
  color: #FDF2F8 !important;
}

.pink.mat-icon, .pink .mat-icon {
  color: #FDF2F8 !important;
}

.pink.text-secondary, .pink .text-secondary {
  color: rgba(#FDF2F8, 0.7) !important;
}

.pink.text-hint, .pink .text-hint, .pink.text-disabled, .pink .text-disabled {
  color: rgba(#FDF2F8, 0.38) !important;
}

.pink.divider, .pink .divider {
  color: rgba(#FDF2F8, 0.12) !important;
}

.text-pink.text-secondary, .text-pink .text-secondary {
  color: rgba(#E74694, 0.7) !important;
}

.text-pink.text-hint, .text-pink .text-hint, .text-pink.text-disabled, .text-pink .text-disabled {
  color: rgba(#E74694, 0.38) !important;
}

.text-pink.divider, .text-pink .divider {
  color: rgba(#E74694, 0.12) !important;
}

.icon-current .mat-icon {
  color: currentColor !important;
}

.icon-transparent .mat-icon {
  color: transparent !important;
}

.icon-white .mat-icon {
  color: #FFFFFF !important;
}

.icon-black .mat-icon {
  color: #000000 !important;
}

.icon-gray-50 .mat-icon {
  color: #F9FAFB !important;
}

.icon-gray-100 .mat-icon {
  color: #F4F5F7 !important;
}

.icon-gray-200 .mat-icon {
  color: #E5E7EB !important;
}

.icon-gray-300 .mat-icon {
  color: #D2D6DC !important;
}

.icon-gray-400 .mat-icon {
  color: #9FA6B2 !important;
}

.icon-gray-500 .mat-icon {
  color: #6B7280 !important;
}

.icon-gray-600 .mat-icon {
  color: #4B5563 !important;
}

.icon-gray-700 .mat-icon {
  color: #374151 !important;
}

.icon-gray-800 .mat-icon {
  color: #252F3F !important;
}

.icon-gray-900 .mat-icon {
  color: #161E2E !important;
}

.icon-gray .mat-icon {
  color: #6B7280 !important;
}

.icon-cool-gray-50 .mat-icon {
  color: #FBFDFE !important;
}

.icon-cool-gray-100 .mat-icon {
  color: #F1F5F9 !important;
}

.icon-cool-gray-200 .mat-icon {
  color: #E2E8F0 !important;
}

.icon-cool-gray-300 .mat-icon {
  color: #CFD8E3 !important;
}

.icon-cool-gray-400 .mat-icon {
  color: #97A6BA !important;
}

.icon-cool-gray-500 .mat-icon {
  color: #64748B !important;
}

.icon-cool-gray-600 .mat-icon {
  color: #475569 !important;
}

.icon-cool-gray-700 .mat-icon {
  color: #364152 !important;
}

.icon-cool-gray-800 .mat-icon {
  color: #27303F !important;
}

.icon-cool-gray-900 .mat-icon {
  color: #1A202E !important;
}

.icon-cool-gray .mat-icon {
  color: #64748B !important;
}

.icon-red-50 .mat-icon {
  color: #FDF2F2 !important;
}

.icon-red-100 .mat-icon {
  color: #FDE8E8 !important;
}

.icon-red-200 .mat-icon {
  color: #FBD5D5 !important;
}

.icon-red-300 .mat-icon {
  color: #F8B4B4 !important;
}

.icon-red-400 .mat-icon {
  color: #F98080 !important;
}

.icon-red-500 .mat-icon {
  color: #F05252 !important;
}

.icon-red-600 .mat-icon {
  color: #E02424 !important;
}

.icon-red-700 .mat-icon {
  color: #C81E1E !important;
}

.icon-red-800 .mat-icon {
  color: #9B1C1C !important;
}

.icon-red-900 .mat-icon {
  color: #771D1D !important;
}

.icon-red .mat-icon {
  color: #F05252 !important;
}

.icon-orange-50 .mat-icon {
  color: #FFF8F1 !important;
}

.icon-orange-100 .mat-icon {
  color: #FEECDC !important;
}

.icon-orange-200 .mat-icon {
  color: #FCD9BD !important;
}

.icon-orange-300 .mat-icon {
  color: #FDBA8C !important;
}

.icon-orange-400 .mat-icon {
  color: #FF8A4C !important;
}

.icon-orange-500 .mat-icon {
  color: #FF5A1F !important;
}

.icon-orange-600 .mat-icon {
  color: #ff6700 !important;
}

.icon-orange-700 .mat-icon {
  color: #B43403 !important;
}

.icon-orange-800 .mat-icon {
  color: #8A2C0D !important;
}

.icon-orange-900 .mat-icon {
  color: #771D1D !important;
}

.icon-orange .mat-icon {
  color: #FF5A1F !important;
}

.icon-yellow-50 .mat-icon {
  color: #FDFDEA !important;
}

.icon-yellow-100 .mat-icon {
  color: #FDF6B2 !important;
}

.icon-yellow-200 .mat-icon {
  color: #FCE96A !important;
}

.icon-yellow-300 .mat-icon {
  color: #FACA15 !important;
}

.icon-yellow-400 .mat-icon {
  color: #E3A008 !important;
}

.icon-yellow-500 .mat-icon {
  color: #C27803 !important;
}

.icon-yellow-600 .mat-icon {
  color: #9F580A !important;
}

.icon-yellow-700 .mat-icon {
  color: #8E4B10 !important;
}

.icon-yellow-800 .mat-icon {
  color: #723B13 !important;
}

.icon-yellow-900 .mat-icon {
  color: #633112 !important;
}

.icon-yellow .mat-icon {
  color: #C27803 !important;
}

.icon-green-50 .mat-icon {
  color: #F3FAF7 !important;
}

.icon-green-100 .mat-icon {
  color: #DEF7EC !important;
}

.icon-green-200 .mat-icon {
  color: #BCF0DA !important;
}

.icon-green-300 .mat-icon {
  color: #84E1BC !important;
}

.icon-green-400 .mat-icon {
  color: #31C48D !important;
}

.icon-green-500 .mat-icon {
  color: #0E9F6E !important;
}

.icon-green-600 .mat-icon {
  color: #057A55 !important;
}

.icon-green-700 .mat-icon {
  color: #046C4E !important;
}

.icon-green-800 .mat-icon {
  color: #03543F !important;
}

.icon-green-900 .mat-icon {
  color: #014737 !important;
}

.icon-green .mat-icon {
  color: #0E9F6E !important;
}

.icon-teal-50 .mat-icon {
  color: #EDFAFA !important;
}

.icon-teal-100 .mat-icon {
  color: #D5F5F6 !important;
}

.icon-teal-200 .mat-icon {
  color: #AFECEF !important;
}

.icon-teal-300 .mat-icon {
  color: #7EDCE2 !important;
}

.icon-teal-400 .mat-icon {
  color: #16BDCA !important;
}

.icon-teal-500 .mat-icon {
  color: #0694A2 !important;
}

.icon-teal-600 .mat-icon {
  color: #047481 !important;
}

.icon-teal-700 .mat-icon {
  color: #036672 !important;
}

.icon-teal-800 .mat-icon {
  color: #05505C !important;
}

.icon-teal-900 .mat-icon {
  color: #014451 !important;
}

.icon-teal .mat-icon {
  color: #0694A2 !important;
}

.icon-blue-50 .mat-icon {
  color: #EBF5FF !important;
}

.icon-blue-100 .mat-icon {
  color: #E1EFFE !important;
}

.icon-blue-200 .mat-icon {
  color: #C3DDFD !important;
}

.icon-blue-300 .mat-icon {
  color: #A4CAFE !important;
}

.icon-blue-400 .mat-icon {
  color: #76A9FA !important;
}

.icon-blue-500 .mat-icon {
  color: #3F83F8 !important;
}

.icon-blue-600 .mat-icon {
  color: #1C64F2 !important;
}

.icon-blue-700 .mat-icon {
  color: #1A56DB !important;
}

.icon-blue-800 .mat-icon {
  color: #1E429F !important;
}

.icon-blue-900 .mat-icon {
  color: #233876 !important;
}

.icon-blue .mat-icon {
  color: #3F83F8 !important;
}

.icon-indigo-50 .mat-icon {
  color: #F0F5FF !important;
}

.icon-indigo-100 .mat-icon {
  color: #E5EDFF !important;
}

.icon-indigo-200 .mat-icon {
  color: #CDDBFE !important;
}

.icon-indigo-300 .mat-icon {
  color: #B4C6FC !important;
}

.icon-indigo-400 .mat-icon {
  color: #8DA2FB !important;
}

.icon-indigo-500 .mat-icon {
  color: #6875F5 !important;
}

.icon-indigo-600 .mat-icon {
  color: #5850EC !important;
}

.icon-indigo-700 .mat-icon {
  color: #5145CD !important;
}

.icon-indigo-800 .mat-icon {
  color: #42389D !important;
}

.icon-indigo-900 .mat-icon {
  color: #362F78 !important;
}

.icon-indigo .mat-icon {
  color: #6875F5 !important;
}

.icon-purple-50 .mat-icon {
  color: #F6F5FF !important;
}

.icon-purple-100 .mat-icon {
  color: #EDEBFE !important;
}

.icon-purple-200 .mat-icon {
  color: #DCD7FE !important;
}

.icon-purple-300 .mat-icon {
  color: #CABFFD !important;
}

.icon-purple-400 .mat-icon {
  color: #AC94FA !important;
}

.icon-purple-500 .mat-icon {
  color: #9061F9 !important;
}

.icon-purple-600 .mat-icon {
  color: #7E3AF2 !important;
}

.icon-purple-700 .mat-icon {
  color: #6C2BD9 !important;
}

.icon-purple-800 .mat-icon {
  color: #5521B5 !important;
}

.icon-purple-900 .mat-icon {
  color: #4A1D96 !important;
}

.icon-purple .mat-icon {
  color: #9061F9 !important;
}

.icon-pink-50 .mat-icon {
  color: #FDF2F8 !important;
}

.icon-pink-100 .mat-icon {
  color: #FCE8F3 !important;
}

.icon-pink-200 .mat-icon {
  color: #FAD1E8 !important;
}

.icon-pink-300 .mat-icon {
  color: #F8B4D9 !important;
}

.icon-pink-400 .mat-icon {
  color: #F17EB8 !important;
}

.icon-pink-500 .mat-icon {
  color: #E74694 !important;
}

.icon-pink-600 .mat-icon {
  color: #D61F69 !important;
}

.icon-pink-700 .mat-icon {
  color: #BF125D !important;
}

.icon-pink-800 .mat-icon {
  color: #99154B !important;
}

.icon-pink-900 .mat-icon {
  color: #751A3D !important;
}

.icon-pink .mat-icon {
  color: #E74694 !important;
}

.icon-size-12 {
  width: 12px !important;
  height: 12px !important;
  min-width: 12px !important;
  min-height: 12px !important;
  font-size: 12px !important;
  line-height: 12px !important;
}

.icon-size-12 svg {
  width: 12px !important;
  height: 12px !important;
}

.icon-size-14 {
  width: 14px !important;
  height: 14px !important;
  min-width: 14px !important;
  min-height: 14px !important;
  font-size: 14px !important;
  line-height: 14px !important;
}

.icon-size-14 svg {
  width: 14px !important;
  height: 14px !important;
}

.icon-size-16 {
  width: 16px !important;
  height: 16px !important;
  min-width: 16px !important;
  min-height: 16px !important;
  font-size: 16px !important;
  line-height: 16px !important;
}

.icon-size-16 svg {
  width: 16px !important;
  height: 16px !important;
}

.icon-size-18 {
  width: 18px !important;
  height: 18px !important;
  min-width: 18px !important;
  min-height: 18px !important;
  font-size: 18px !important;
  line-height: 18px !important;
}

.icon-size-18 svg {
  width: 18px !important;
  height: 18px !important;
}

.icon-size-20 {
  width: 20px !important;
  height: 20px !important;
  min-width: 20px !important;
  min-height: 20px !important;
  font-size: 20px !important;
  line-height: 20px !important;
}

.icon-size-20 svg {
  width: 20px !important;
  height: 20px !important;
}

.icon-size-24 {
  width: 24px !important;
  height: 24px !important;
  min-width: 24px !important;
  min-height: 24px !important;
  font-size: 24px !important;
  line-height: 24px !important;
}

.icon-size-24 svg {
  width: 24px !important;
  height: 24px !important;
}

.icon-size-32 {
  width: 32px !important;
  height: 32px !important;
  min-width: 32px !important;
  min-height: 32px !important;
  font-size: 32px !important;
  line-height: 32px !important;
}

.icon-size-32 svg {
  width: 32px !important;
  height: 32px !important;
}

.icon-size-40 {
  width: 40px !important;
  height: 40px !important;
  min-width: 40px !important;
  min-height: 40px !important;
  font-size: 40px !important;
  line-height: 40px !important;
}

.icon-size-40 svg {
  width: 40px !important;
  height: 40px !important;
}

.icon-size-48 {
  width: 48px !important;
  height: 48px !important;
  min-width: 48px !important;
  min-height: 48px !important;
  font-size: 48px !important;
  line-height: 48px !important;
}

.icon-size-48 svg {
  width: 48px !important;
  height: 48px !important;
}

.icon-size-56 {
  width: 56px !important;
  height: 56px !important;
  min-width: 56px !important;
  min-height: 56px !important;
  font-size: 56px !important;
  line-height: 56px !important;
}

.icon-size-56 svg {
  width: 56px !important;
  height: 56px !important;
}

.icon-size-64 {
  width: 64px !important;
  height: 64px !important;
  min-width: 64px !important;
  min-height: 64px !important;
  font-size: 64px !important;
  line-height: 64px !important;
}

.icon-size-64 svg {
  width: 64px !important;
  height: 64px !important;
}

.icon-size-72 {
  width: 72px !important;
  height: 72px !important;
  min-width: 72px !important;
  min-height: 72px !important;
  font-size: 72px !important;
  line-height: 72px !important;
}

.icon-size-72 svg {
  width: 72px !important;
  height: 72px !important;
}

.icon-size-80 {
  width: 80px !important;
  height: 80px !important;
  min-width: 80px !important;
  min-height: 80px !important;
  font-size: 80px !important;
  line-height: 80px !important;
}

.icon-size-80 svg {
  width: 80px !important;
  height: 80px !important;
}

.icon-size-88 {
  width: 88px !important;
  height: 88px !important;
  min-width: 88px !important;
  min-height: 88px !important;
  font-size: 88px !important;
  line-height: 88px !important;
}

.icon-size-88 svg {
  width: 88px !important;
  height: 88px !important;
}

.icon-size-96 {
  width: 96px !important;
  height: 96px !important;
  min-width: 96px !important;
  min-height: 96px !important;
  font-size: 96px !important;
  line-height: 96px !important;
}

.icon-size-96 svg {
  width: 96px !important;
  height: 96px !important;
}

.mirror {
  transform: scale(-1, 1) !important;
}

.mirror-vertical {
  transform: scale(1, -1) !important;
}

/* ----------------------------------------------------------------------------------------------------- */

/* Use custom @variant directives here to build them.
/* ----------------------------------------------------------------------------------------------------- */

/* ----------------------------------------------------------------------------------------------------- */

/*  Use this directive to control where Tailwind injects the responsive variations of each utility.
/*  If omitted, Tailwind will append these classes to the very end of your stylesheet by default.
/* ----------------------------------------------------------------------------------------------------- */

@media (min-width: 0) and (max-width: 599px) {
  .xs\:space-y-0 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(0px * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(0px * var(--space-y-reverse)) !important;
  }

  .xs\:space-x-0 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(0px * var(--space-x-reverse)) !important;
    margin-left: calc(0px * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(0.25rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(0.25rem * var(--space-y-reverse)) !important;
  }

  .xs\:space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(0.25rem * var(--space-x-reverse)) !important;
    margin-left: calc(0.25rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(0.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(0.5rem * var(--space-y-reverse)) !important;
  }

  .xs\:space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(0.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(0.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(0.75rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(0.75rem * var(--space-y-reverse)) !important;
  }

  .xs\:space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(0.75rem * var(--space-x-reverse)) !important;
    margin-left: calc(0.75rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(1rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(1rem * var(--space-y-reverse)) !important;
  }

  .xs\:space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(1rem * var(--space-x-reverse)) !important;
    margin-left: calc(1rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(1.25rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(1.25rem * var(--space-y-reverse)) !important;
  }

  .xs\:space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(1.25rem * var(--space-x-reverse)) !important;
    margin-left: calc(1.25rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(1.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(1.5rem * var(--space-y-reverse)) !important;
  }

  .xs\:space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(1.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(1.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:space-y-8 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(2rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(2rem * var(--space-y-reverse)) !important;
  }

  .xs\:space-x-8 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(2rem * var(--space-x-reverse)) !important;
    margin-left: calc(2rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:space-y-10 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(2.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(2.5rem * var(--space-y-reverse)) !important;
  }

  .xs\:space-x-10 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(2.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(2.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:space-y-12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(3rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(3rem * var(--space-y-reverse)) !important;
  }

  .xs\:space-x-12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(3rem * var(--space-x-reverse)) !important;
    margin-left: calc(3rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:space-y-14 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(3.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(3.5rem * var(--space-y-reverse)) !important;
  }

  .xs\:space-x-14 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(3.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(3.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:space-y-16 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(4rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(4rem * var(--space-y-reverse)) !important;
  }

  .xs\:space-x-16 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(4rem * var(--space-x-reverse)) !important;
    margin-left: calc(4rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:space-y-18 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(4.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(4.5rem * var(--space-y-reverse)) !important;
  }

  .xs\:space-x-18 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(4.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(4.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:space-y-20 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(5rem * var(--space-y-reverse)) !important;
  }

  .xs\:space-x-20 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(5rem * var(--space-x-reverse)) !important;
    margin-left: calc(5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:space-y-22 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(5.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(5.5rem * var(--space-y-reverse)) !important;
  }

  .xs\:space-x-22 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(5.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(5.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:space-y-24 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(6rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(6rem * var(--space-y-reverse)) !important;
  }

  .xs\:space-x-24 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(6rem * var(--space-x-reverse)) !important;
    margin-left: calc(6rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:space-y-26 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(6.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(6.5rem * var(--space-y-reverse)) !important;
  }

  .xs\:space-x-26 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(6.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(6.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:space-y-28 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(7rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(7rem * var(--space-y-reverse)) !important;
  }

  .xs\:space-x-28 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(7rem * var(--space-x-reverse)) !important;
    margin-left: calc(7rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:space-y-30 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(7.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(7.5rem * var(--space-y-reverse)) !important;
  }

  .xs\:space-x-30 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(7.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(7.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:space-y-32 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(8rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(8rem * var(--space-y-reverse)) !important;
  }

  .xs\:space-x-32 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(8rem * var(--space-x-reverse)) !important;
    margin-left: calc(8rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:space-y-36 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(9rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(9rem * var(--space-y-reverse)) !important;
  }

  .xs\:space-x-36 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(9rem * var(--space-x-reverse)) !important;
    margin-left: calc(9rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:space-y-40 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(10rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(10rem * var(--space-y-reverse)) !important;
  }

  .xs\:space-x-40 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(10rem * var(--space-x-reverse)) !important;
    margin-left: calc(10rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:space-y-48 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(12rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(12rem * var(--space-y-reverse)) !important;
  }

  .xs\:space-x-48 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(12rem * var(--space-x-reverse)) !important;
    margin-left: calc(12rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:space-y-56 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(14rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(14rem * var(--space-y-reverse)) !important;
  }

  .xs\:space-x-56 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(14rem * var(--space-x-reverse)) !important;
    margin-left: calc(14rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:space-y-64 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(16rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(16rem * var(--space-y-reverse)) !important;
  }

  .xs\:space-x-64 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(16rem * var(--space-x-reverse)) !important;
    margin-left: calc(16rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:space-y-px > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(1px * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(1px * var(--space-y-reverse)) !important;
  }

  .xs\:space-x-px > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(1px * var(--space-x-reverse)) !important;
    margin-left: calc(1px * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:space-y-2px > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(2px * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(2px * var(--space-y-reverse)) !important;
  }

  .xs\:space-x-2px > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(2px * var(--space-x-reverse)) !important;
    margin-left: calc(2px * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:-space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-0.25rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-0.25rem * var(--space-y-reverse)) !important;
  }

  .xs\:-space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-0.25rem * var(--space-x-reverse)) !important;
    margin-left: calc(-0.25rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:-space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-0.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-0.5rem * var(--space-y-reverse)) !important;
  }

  .xs\:-space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-0.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-0.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:-space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-0.75rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-0.75rem * var(--space-y-reverse)) !important;
  }

  .xs\:-space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-0.75rem * var(--space-x-reverse)) !important;
    margin-left: calc(-0.75rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:-space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-1rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-1rem * var(--space-y-reverse)) !important;
  }

  .xs\:-space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-1rem * var(--space-x-reverse)) !important;
    margin-left: calc(-1rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:-space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-1.25rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-1.25rem * var(--space-y-reverse)) !important;
  }

  .xs\:-space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-1.25rem * var(--space-x-reverse)) !important;
    margin-left: calc(-1.25rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:-space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-1.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-1.5rem * var(--space-y-reverse)) !important;
  }

  .xs\:-space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-1.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-1.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:-space-y-8 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-2rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-2rem * var(--space-y-reverse)) !important;
  }

  .xs\:-space-x-8 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-2rem * var(--space-x-reverse)) !important;
    margin-left: calc(-2rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:-space-y-10 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-2.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-2.5rem * var(--space-y-reverse)) !important;
  }

  .xs\:-space-x-10 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-2.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-2.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:-space-y-12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-3rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-3rem * var(--space-y-reverse)) !important;
  }

  .xs\:-space-x-12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-3rem * var(--space-x-reverse)) !important;
    margin-left: calc(-3rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:-space-y-14 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-3.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-3.5rem * var(--space-y-reverse)) !important;
  }

  .xs\:-space-x-14 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-3.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-3.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:-space-y-16 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-4rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-4rem * var(--space-y-reverse)) !important;
  }

  .xs\:-space-x-16 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-4rem * var(--space-x-reverse)) !important;
    margin-left: calc(-4rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:-space-y-18 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-4.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-4.5rem * var(--space-y-reverse)) !important;
  }

  .xs\:-space-x-18 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-4.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-4.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:-space-y-20 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-5rem * var(--space-y-reverse)) !important;
  }

  .xs\:-space-x-20 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:-space-y-22 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-5.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-5.5rem * var(--space-y-reverse)) !important;
  }

  .xs\:-space-x-22 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-5.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-5.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:-space-y-24 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-6rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-6rem * var(--space-y-reverse)) !important;
  }

  .xs\:-space-x-24 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-6rem * var(--space-x-reverse)) !important;
    margin-left: calc(-6rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:-space-y-26 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-6.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-6.5rem * var(--space-y-reverse)) !important;
  }

  .xs\:-space-x-26 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-6.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-6.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:-space-y-28 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-7rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-7rem * var(--space-y-reverse)) !important;
  }

  .xs\:-space-x-28 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-7rem * var(--space-x-reverse)) !important;
    margin-left: calc(-7rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:-space-y-30 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-7.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-7.5rem * var(--space-y-reverse)) !important;
  }

  .xs\:-space-x-30 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-7.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-7.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:-space-y-32 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-8rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-8rem * var(--space-y-reverse)) !important;
  }

  .xs\:-space-x-32 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-8rem * var(--space-x-reverse)) !important;
    margin-left: calc(-8rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:-space-y-36 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-9rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-9rem * var(--space-y-reverse)) !important;
  }

  .xs\:-space-x-36 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-9rem * var(--space-x-reverse)) !important;
    margin-left: calc(-9rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:-space-y-40 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-10rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-10rem * var(--space-y-reverse)) !important;
  }

  .xs\:-space-x-40 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-10rem * var(--space-x-reverse)) !important;
    margin-left: calc(-10rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:-space-y-48 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-12rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-12rem * var(--space-y-reverse)) !important;
  }

  .xs\:-space-x-48 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-12rem * var(--space-x-reverse)) !important;
    margin-left: calc(-12rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:-space-y-56 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-14rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-14rem * var(--space-y-reverse)) !important;
  }

  .xs\:-space-x-56 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-14rem * var(--space-x-reverse)) !important;
    margin-left: calc(-14rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:-space-y-64 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-16rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-16rem * var(--space-y-reverse)) !important;
  }

  .xs\:-space-x-64 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-16rem * var(--space-x-reverse)) !important;
    margin-left: calc(-16rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:-space-y-px > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-1px * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-1px * var(--space-y-reverse)) !important;
  }

  .xs\:-space-x-px > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-1px * var(--space-x-reverse)) !important;
    margin-left: calc(-1px * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:-space-y-2px > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-2px * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-2px * var(--space-y-reverse)) !important;
  }

  .xs\:-space-x-2px > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-2px * var(--space-x-reverse)) !important;
    margin-left: calc(-2px * calc(1 - var(--space-x-reverse))) !important;
  }

  .xs\:space-y-reverse > :not(template) ~ :not(template) {
    --space-y-reverse: 1 !important;
  }

  .xs\:space-x-reverse > :not(template) ~ :not(template) {
    --space-x-reverse: 1 !important;
  }

  .xs\:divide-y-0 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(0px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(0px * var(--divide-y-reverse)) !important;
  }

  .xs\:divide-x-0 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(0px * var(--divide-x-reverse)) !important;
    border-left-width: calc(0px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .xs\:divide-y-2 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(2px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(2px * var(--divide-y-reverse)) !important;
  }

  .xs\:divide-x-2 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(2px * var(--divide-x-reverse)) !important;
    border-left-width: calc(2px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .xs\:divide-y-4 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(4px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(4px * var(--divide-y-reverse)) !important;
  }

  .xs\:divide-x-4 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(4px * var(--divide-x-reverse)) !important;
    border-left-width: calc(4px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .xs\:divide-y-8 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(8px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(8px * var(--divide-y-reverse)) !important;
  }

  .xs\:divide-x-8 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(8px * var(--divide-x-reverse)) !important;
    border-left-width: calc(8px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .xs\:divide-y > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(1px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(1px * var(--divide-y-reverse)) !important;
  }

  .xs\:divide-x > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(1px * var(--divide-x-reverse)) !important;
    border-left-width: calc(1px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .xs\:divide-y-reverse > :not(template) ~ :not(template) {
    --divide-y-reverse: 1 !important;
  }

  .xs\:divide-x-reverse > :not(template) ~ :not(template) {
    --divide-x-reverse: 1 !important;
  }

  .xs\:divide-current > :not(template) ~ :not(template) {
    border-color: currentColor !important;
  }

  .xs\:divide-transparent > :not(template) ~ :not(template) {
    border-color: transparent !important;
  }

  .xs\:divide-white > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FFFFFF !important;
    border-color: rgba(255, 255, 255, var(--divide-opacity)) !important;
  }

  .xs\:divide-black > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #000000 !important;
    border-color: rgba(0, 0, 0, var(--divide-opacity)) !important;
  }

  .xs\:divide-gray-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F9FAFB !important;
    border-color: rgba(249, 250, 251, var(--divide-opacity)) !important;
  }

  .xs\:divide-gray-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F4F5F7 !important;
    border-color: rgba(244, 245, 247, var(--divide-opacity)) !important;
  }

  .xs\:divide-gray-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E5E7EB !important;
    border-color: rgba(229, 231, 235, var(--divide-opacity)) !important;
  }

  .xs\:divide-gray-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #D2D6DC !important;
    border-color: rgba(210, 214, 220, var(--divide-opacity)) !important;
  }

  .xs\:divide-gray-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9FA6B2 !important;
    border-color: rgba(159, 166, 178, var(--divide-opacity)) !important;
  }

  .xs\:divide-gray-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #6B7280 !important;
    border-color: rgba(107, 114, 128, var(--divide-opacity)) !important;
  }

  .xs\:divide-gray-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #4B5563 !important;
    border-color: rgba(75, 85, 99, var(--divide-opacity)) !important;
  }

  .xs\:divide-gray-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #374151 !important;
    border-color: rgba(55, 65, 81, var(--divide-opacity)) !important;
  }

  .xs\:divide-gray-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #252F3F !important;
    border-color: rgba(37, 47, 63, var(--divide-opacity)) !important;
  }

  .xs\:divide-gray-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #161E2E !important;
    border-color: rgba(22, 30, 46, var(--divide-opacity)) !important;
  }

  .xs\:divide-gray > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #6B7280 !important;
    border-color: rgba(107, 114, 128, var(--divide-opacity)) !important;
  }

  .xs\:divide-cool-gray-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FBFDFE !important;
    border-color: rgba(251, 253, 254, var(--divide-opacity)) !important;
  }

  .xs\:divide-cool-gray-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F1F5F9 !important;
    border-color: rgba(241, 245, 249, var(--divide-opacity)) !important;
  }

  .xs\:divide-cool-gray-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E2E8F0 !important;
    border-color: rgba(226, 232, 240, var(--divide-opacity)) !important;
  }

  .xs\:divide-cool-gray-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #CFD8E3 !important;
    border-color: rgba(207, 216, 227, var(--divide-opacity)) !important;
  }

  .xs\:divide-cool-gray-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #97A6BA !important;
    border-color: rgba(151, 166, 186, var(--divide-opacity)) !important;
  }

  .xs\:divide-cool-gray-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #64748B !important;
    border-color: rgba(100, 116, 139, var(--divide-opacity)) !important;
  }

  .xs\:divide-cool-gray-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #475569 !important;
    border-color: rgba(71, 85, 105, var(--divide-opacity)) !important;
  }

  .xs\:divide-cool-gray-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #364152 !important;
    border-color: rgba(54, 65, 82, var(--divide-opacity)) !important;
  }

  .xs\:divide-cool-gray-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #27303F !important;
    border-color: rgba(39, 48, 63, var(--divide-opacity)) !important;
  }

  .xs\:divide-cool-gray-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #1A202E !important;
    border-color: rgba(26, 32, 46, var(--divide-opacity)) !important;
  }

  .xs\:divide-cool-gray > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #64748B !important;
    border-color: rgba(100, 116, 139, var(--divide-opacity)) !important;
  }

  .xs\:divide-red-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FDF2F2 !important;
    border-color: rgba(253, 242, 242, var(--divide-opacity)) !important;
  }

  .xs\:divide-red-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FDE8E8 !important;
    border-color: rgba(253, 232, 232, var(--divide-opacity)) !important;
  }

  .xs\:divide-red-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FBD5D5 !important;
    border-color: rgba(251, 213, 213, var(--divide-opacity)) !important;
  }

  .xs\:divide-red-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F8B4B4 !important;
    border-color: rgba(248, 180, 180, var(--divide-opacity)) !important;
  }

  .xs\:divide-red-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F98080 !important;
    border-color: rgba(249, 128, 128, var(--divide-opacity)) !important;
  }

  .xs\:divide-red-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F05252 !important;
    border-color: rgba(240, 82, 82, var(--divide-opacity)) !important;
  }

  .xs\:divide-red-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E02424 !important;
    border-color: rgba(224, 36, 36, var(--divide-opacity)) !important;
  }

  .xs\:divide-red-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #C81E1E !important;
    border-color: rgba(200, 30, 30, var(--divide-opacity)) !important;
  }

  .xs\:divide-red-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9B1C1C !important;
    border-color: rgba(155, 28, 28, var(--divide-opacity)) !important;
  }

  .xs\:divide-red-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #771D1D !important;
    border-color: rgba(119, 29, 29, var(--divide-opacity)) !important;
  }

  .xs\:divide-red > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F05252 !important;
    border-color: rgba(240, 82, 82, var(--divide-opacity)) !important;
  }

  .xs\:divide-orange-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FFF8F1 !important;
    border-color: rgba(255, 248, 241, var(--divide-opacity)) !important;
  }

  .xs\:divide-orange-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FEECDC !important;
    border-color: rgba(254, 236, 220, var(--divide-opacity)) !important;
  }

  .xs\:divide-orange-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FCD9BD !important;
    border-color: rgba(252, 217, 189, var(--divide-opacity)) !important;
  }

  .xs\:divide-orange-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FDBA8C !important;
    border-color: rgba(253, 186, 140, var(--divide-opacity)) !important;
  }

  .xs\:divide-orange-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FF8A4C !important;
    border-color: rgba(255, 138, 76, var(--divide-opacity)) !important;
  }

  .xs\:divide-orange-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FF5A1F !important;
    border-color: rgba(255, 90, 31, var(--divide-opacity)) !important;
  }

  .xs\:divide-orange-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #ff6700 !important;
    border-color: rgba(255, 103, 0, var(--divide-opacity)) !important;
  }

  .xs\:divide-orange-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #B43403 !important;
    border-color: rgba(180, 52, 3, var(--divide-opacity)) !important;
  }

  .xs\:divide-orange-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #8A2C0D !important;
    border-color: rgba(138, 44, 13, var(--divide-opacity)) !important;
  }

  .xs\:divide-orange-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #771D1D !important;
    border-color: rgba(119, 29, 29, var(--divide-opacity)) !important;
  }

  .xs\:divide-orange > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FF5A1F !important;
    border-color: rgba(255, 90, 31, var(--divide-opacity)) !important;
  }

  .xs\:divide-yellow-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FDFDEA !important;
    border-color: rgba(253, 253, 234, var(--divide-opacity)) !important;
  }

  .xs\:divide-yellow-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FDF6B2 !important;
    border-color: rgba(253, 246, 178, var(--divide-opacity)) !important;
  }

  .xs\:divide-yellow-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FCE96A !important;
    border-color: rgba(252, 233, 106, var(--divide-opacity)) !important;
  }

  .xs\:divide-yellow-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FACA15 !important;
    border-color: rgba(250, 202, 21, var(--divide-opacity)) !important;
  }

  .xs\:divide-yellow-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E3A008 !important;
    border-color: rgba(227, 160, 8, var(--divide-opacity)) !important;
  }

  .xs\:divide-yellow-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #C27803 !important;
    border-color: rgba(194, 120, 3, var(--divide-opacity)) !important;
  }

  .xs\:divide-yellow-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9F580A !important;
    border-color: rgba(159, 88, 10, var(--divide-opacity)) !important;
  }

  .xs\:divide-yellow-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #8E4B10 !important;
    border-color: rgba(142, 75, 16, var(--divide-opacity)) !important;
  }

  .xs\:divide-yellow-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #723B13 !important;
    border-color: rgba(114, 59, 19, var(--divide-opacity)) !important;
  }

  .xs\:divide-yellow-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #633112 !important;
    border-color: rgba(99, 49, 18, var(--divide-opacity)) !important;
  }

  .xs\:divide-yellow > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #C27803 !important;
    border-color: rgba(194, 120, 3, var(--divide-opacity)) !important;
  }

  .xs\:divide-green-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F3FAF7 !important;
    border-color: rgba(243, 250, 247, var(--divide-opacity)) !important;
  }

  .xs\:divide-green-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #DEF7EC !important;
    border-color: rgba(222, 247, 236, var(--divide-opacity)) !important;
  }

  .xs\:divide-green-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #BCF0DA !important;
    border-color: rgba(188, 240, 218, var(--divide-opacity)) !important;
  }

  .xs\:divide-green-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #84E1BC !important;
    border-color: rgba(132, 225, 188, var(--divide-opacity)) !important;
  }

  .xs\:divide-green-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #31C48D !important;
    border-color: rgba(49, 196, 141, var(--divide-opacity)) !important;
  }

  .xs\:divide-green-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #0E9F6E !important;
    border-color: rgba(14, 159, 110, var(--divide-opacity)) !important;
  }

  .xs\:divide-green-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #057A55 !important;
    border-color: rgba(5, 122, 85, var(--divide-opacity)) !important;
  }

  .xs\:divide-green-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #046C4E !important;
    border-color: rgba(4, 108, 78, var(--divide-opacity)) !important;
  }

  .xs\:divide-green-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #03543F !important;
    border-color: rgba(3, 84, 63, var(--divide-opacity)) !important;
  }

  .xs\:divide-green-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #014737 !important;
    border-color: rgba(1, 71, 55, var(--divide-opacity)) !important;
  }

  .xs\:divide-green > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #0E9F6E !important;
    border-color: rgba(14, 159, 110, var(--divide-opacity)) !important;
  }

  .xs\:divide-teal-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #EDFAFA !important;
    border-color: rgba(237, 250, 250, var(--divide-opacity)) !important;
  }

  .xs\:divide-teal-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #D5F5F6 !important;
    border-color: rgba(213, 245, 246, var(--divide-opacity)) !important;
  }

  .xs\:divide-teal-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #AFECEF !important;
    border-color: rgba(175, 236, 239, var(--divide-opacity)) !important;
  }

  .xs\:divide-teal-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #7EDCE2 !important;
    border-color: rgba(126, 220, 226, var(--divide-opacity)) !important;
  }

  .xs\:divide-teal-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #16BDCA !important;
    border-color: rgba(22, 189, 202, var(--divide-opacity)) !important;
  }

  .xs\:divide-teal-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #0694A2 !important;
    border-color: rgba(6, 148, 162, var(--divide-opacity)) !important;
  }

  .xs\:divide-teal-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #047481 !important;
    border-color: rgba(4, 116, 129, var(--divide-opacity)) !important;
  }

  .xs\:divide-teal-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #036672 !important;
    border-color: rgba(3, 102, 114, var(--divide-opacity)) !important;
  }

  .xs\:divide-teal-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #05505C !important;
    border-color: rgba(5, 80, 92, var(--divide-opacity)) !important;
  }

  .xs\:divide-teal-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #014451 !important;
    border-color: rgba(1, 68, 81, var(--divide-opacity)) !important;
  }

  .xs\:divide-teal > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #0694A2 !important;
    border-color: rgba(6, 148, 162, var(--divide-opacity)) !important;
  }

  .xs\:divide-blue-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #EBF5FF !important;
    border-color: rgba(235, 245, 255, var(--divide-opacity)) !important;
  }

  .xs\:divide-blue-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E1EFFE !important;
    border-color: rgba(225, 239, 254, var(--divide-opacity)) !important;
  }

  .xs\:divide-blue-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #C3DDFD !important;
    border-color: rgba(195, 221, 253, var(--divide-opacity)) !important;
  }

  .xs\:divide-blue-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #A4CAFE !important;
    border-color: rgba(164, 202, 254, var(--divide-opacity)) !important;
  }

  .xs\:divide-blue-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #76A9FA !important;
    border-color: rgba(118, 169, 250, var(--divide-opacity)) !important;
  }

  .xs\:divide-blue-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #3F83F8 !important;
    border-color: rgba(63, 131, 248, var(--divide-opacity)) !important;
  }

  .xs\:divide-blue-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #1C64F2 !important;
    border-color: rgba(28, 100, 242, var(--divide-opacity)) !important;
  }

  .xs\:divide-blue-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #1A56DB !important;
    border-color: rgba(26, 86, 219, var(--divide-opacity)) !important;
  }

  .xs\:divide-blue-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #1E429F !important;
    border-color: rgba(30, 66, 159, var(--divide-opacity)) !important;
  }

  .xs\:divide-blue-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #233876 !important;
    border-color: rgba(35, 56, 118, var(--divide-opacity)) !important;
  }

  .xs\:divide-blue > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #3F83F8 !important;
    border-color: rgba(63, 131, 248, var(--divide-opacity)) !important;
  }

  .xs\:divide-indigo-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F0F5FF !important;
    border-color: rgba(240, 245, 255, var(--divide-opacity)) !important;
  }

  .xs\:divide-indigo-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E5EDFF !important;
    border-color: rgba(229, 237, 255, var(--divide-opacity)) !important;
  }

  .xs\:divide-indigo-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #CDDBFE !important;
    border-color: rgba(205, 219, 254, var(--divide-opacity)) !important;
  }

  .xs\:divide-indigo-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #B4C6FC !important;
    border-color: rgba(180, 198, 252, var(--divide-opacity)) !important;
  }

  .xs\:divide-indigo-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #8DA2FB !important;
    border-color: rgba(141, 162, 251, var(--divide-opacity)) !important;
  }

  .xs\:divide-indigo-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #6875F5 !important;
    border-color: rgba(104, 117, 245, var(--divide-opacity)) !important;
  }

  .xs\:divide-indigo-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #5850EC !important;
    border-color: rgba(88, 80, 236, var(--divide-opacity)) !important;
  }

  .xs\:divide-indigo-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #5145CD !important;
    border-color: rgba(81, 69, 205, var(--divide-opacity)) !important;
  }

  .xs\:divide-indigo-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #42389D !important;
    border-color: rgba(66, 56, 157, var(--divide-opacity)) !important;
  }

  .xs\:divide-indigo-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #362F78 !important;
    border-color: rgba(54, 47, 120, var(--divide-opacity)) !important;
  }

  .xs\:divide-indigo > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #6875F5 !important;
    border-color: rgba(104, 117, 245, var(--divide-opacity)) !important;
  }

  .xs\:divide-purple-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F6F5FF !important;
    border-color: rgba(246, 245, 255, var(--divide-opacity)) !important;
  }

  .xs\:divide-purple-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #EDEBFE !important;
    border-color: rgba(237, 235, 254, var(--divide-opacity)) !important;
  }

  .xs\:divide-purple-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #DCD7FE !important;
    border-color: rgba(220, 215, 254, var(--divide-opacity)) !important;
  }

  .xs\:divide-purple-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #CABFFD !important;
    border-color: rgba(202, 191, 253, var(--divide-opacity)) !important;
  }

  .xs\:divide-purple-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #AC94FA !important;
    border-color: rgba(172, 148, 250, var(--divide-opacity)) !important;
  }

  .xs\:divide-purple-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9061F9 !important;
    border-color: rgba(144, 97, 249, var(--divide-opacity)) !important;
  }

  .xs\:divide-purple-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #7E3AF2 !important;
    border-color: rgba(126, 58, 242, var(--divide-opacity)) !important;
  }

  .xs\:divide-purple-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #6C2BD9 !important;
    border-color: rgba(108, 43, 217, var(--divide-opacity)) !important;
  }

  .xs\:divide-purple-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #5521B5 !important;
    border-color: rgba(85, 33, 181, var(--divide-opacity)) !important;
  }

  .xs\:divide-purple-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #4A1D96 !important;
    border-color: rgba(74, 29, 150, var(--divide-opacity)) !important;
  }

  .xs\:divide-purple > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9061F9 !important;
    border-color: rgba(144, 97, 249, var(--divide-opacity)) !important;
  }

  .xs\:divide-pink-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FDF2F8 !important;
    border-color: rgba(253, 242, 248, var(--divide-opacity)) !important;
  }

  .xs\:divide-pink-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FCE8F3 !important;
    border-color: rgba(252, 232, 243, var(--divide-opacity)) !important;
  }

  .xs\:divide-pink-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FAD1E8 !important;
    border-color: rgba(250, 209, 232, var(--divide-opacity)) !important;
  }

  .xs\:divide-pink-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F8B4D9 !important;
    border-color: rgba(248, 180, 217, var(--divide-opacity)) !important;
  }

  .xs\:divide-pink-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F17EB8 !important;
    border-color: rgba(241, 126, 184, var(--divide-opacity)) !important;
  }

  .xs\:divide-pink-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E74694 !important;
    border-color: rgba(231, 70, 148, var(--divide-opacity)) !important;
  }

  .xs\:divide-pink-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #D61F69 !important;
    border-color: rgba(214, 31, 105, var(--divide-opacity)) !important;
  }

  .xs\:divide-pink-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #BF125D !important;
    border-color: rgba(191, 18, 93, var(--divide-opacity)) !important;
  }

  .xs\:divide-pink-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #99154B !important;
    border-color: rgba(153, 21, 75, var(--divide-opacity)) !important;
  }

  .xs\:divide-pink-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #751A3D !important;
    border-color: rgba(117, 26, 61, var(--divide-opacity)) !important;
  }

  .xs\:divide-pink > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E74694 !important;
    border-color: rgba(231, 70, 148, var(--divide-opacity)) !important;
  }

  .xs\:divide-opacity-0 > :not(template) ~ :not(template) {
    --divide-opacity: 0 !important;
  }

  .xs\:divide-opacity-12 > :not(template) ~ :not(template) {
    --divide-opacity: 0.12 !important;
  }

  .xs\:divide-opacity-25 > :not(template) ~ :not(template) {
    --divide-opacity: 0.25 !important;
  }

  .xs\:divide-opacity-38 > :not(template) ~ :not(template) {
    --divide-opacity: 0.38 !important;
  }

  .xs\:divide-opacity-50 > :not(template) ~ :not(template) {
    --divide-opacity: 0.5 !important;
  }

  .xs\:divide-opacity-54 > :not(template) ~ :not(template) {
    --divide-opacity: 0.54 !important;
  }

  .xs\:divide-opacity-70 > :not(template) ~ :not(template) {
    --divide-opacity: 0.70 !important;
  }

  .xs\:divide-opacity-75 > :not(template) ~ :not(template) {
    --divide-opacity: 0.75 !important;
  }

  .xs\:divide-opacity-84 > :not(template) ~ :not(template) {
    --divide-opacity: 0.84 !important;
  }

  .xs\:divide-opacity-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
  }

  .xs\:sr-only {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border-width: 0 !important;
  }

  .xs\:not-sr-only {
    position: static !important;
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: visible !important;
    clip: auto !important;
    white-space: normal !important;
  }

  .xs\:focus\:sr-only:focus {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border-width: 0 !important;
  }

  .xs\:focus\:not-sr-only:focus {
    position: static !important;
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: visible !important;
    clip: auto !important;
    white-space: normal !important;
  }

  .xs\:appearance-none {
    -webkit-appearance: none !important;
       -moz-appearance: none !important;
            appearance: none !important;
  }

  .xs\:bg-fixed {
    background-attachment: fixed !important;
  }

  .xs\:bg-local {
    background-attachment: local !important;
  }

  .xs\:bg-scroll {
    background-attachment: scroll !important;
  }

  .xs\:bg-opacity-0 {
    --bg-opacity: 0 !important;
  }

  .xs\:bg-opacity-12 {
    --bg-opacity: 0.12 !important;
  }

  .xs\:bg-opacity-25 {
    --bg-opacity: 0.25 !important;
  }

  .xs\:bg-opacity-38 {
    --bg-opacity: 0.38 !important;
  }

  .xs\:bg-opacity-50 {
    --bg-opacity: 0.5 !important;
  }

  .xs\:bg-opacity-54 {
    --bg-opacity: 0.54 !important;
  }

  .xs\:bg-opacity-70 {
    --bg-opacity: 0.70 !important;
  }

  .xs\:bg-opacity-75 {
    --bg-opacity: 0.75 !important;
  }

  .xs\:bg-opacity-84 {
    --bg-opacity: 0.84 !important;
  }

  .xs\:bg-opacity-100 {
    --bg-opacity: 1 !important;
  }

  .xs\:hover\:bg-opacity-0:hover {
    --bg-opacity: 0 !important;
  }

  .xs\:hover\:bg-opacity-12:hover {
    --bg-opacity: 0.12 !important;
  }

  .xs\:hover\:bg-opacity-25:hover {
    --bg-opacity: 0.25 !important;
  }

  .xs\:hover\:bg-opacity-38:hover {
    --bg-opacity: 0.38 !important;
  }

  .xs\:hover\:bg-opacity-50:hover {
    --bg-opacity: 0.5 !important;
  }

  .xs\:hover\:bg-opacity-54:hover {
    --bg-opacity: 0.54 !important;
  }

  .xs\:hover\:bg-opacity-70:hover {
    --bg-opacity: 0.70 !important;
  }

  .xs\:hover\:bg-opacity-75:hover {
    --bg-opacity: 0.75 !important;
  }

  .xs\:hover\:bg-opacity-84:hover {
    --bg-opacity: 0.84 !important;
  }

  .xs\:hover\:bg-opacity-100:hover {
    --bg-opacity: 1 !important;
  }

  .xs\:focus\:bg-opacity-0:focus {
    --bg-opacity: 0 !important;
  }

  .xs\:focus\:bg-opacity-12:focus {
    --bg-opacity: 0.12 !important;
  }

  .xs\:focus\:bg-opacity-25:focus {
    --bg-opacity: 0.25 !important;
  }

  .xs\:focus\:bg-opacity-38:focus {
    --bg-opacity: 0.38 !important;
  }

  .xs\:focus\:bg-opacity-50:focus {
    --bg-opacity: 0.5 !important;
  }

  .xs\:focus\:bg-opacity-54:focus {
    --bg-opacity: 0.54 !important;
  }

  .xs\:focus\:bg-opacity-70:focus {
    --bg-opacity: 0.70 !important;
  }

  .xs\:focus\:bg-opacity-75:focus {
    --bg-opacity: 0.75 !important;
  }

  .xs\:focus\:bg-opacity-84:focus {
    --bg-opacity: 0.84 !important;
  }

  .xs\:focus\:bg-opacity-100:focus {
    --bg-opacity: 1 !important;
  }

  .xs\:bg-bottom {
    background-position: bottom !important;
  }

  .xs\:bg-center {
    background-position: center !important;
  }

  .xs\:bg-left {
    background-position: left !important;
  }

  .xs\:bg-left-bottom {
    background-position: left bottom !important;
  }

  .xs\:bg-left-top {
    background-position: left top !important;
  }

  .xs\:bg-right {
    background-position: right !important;
  }

  .xs\:bg-right-bottom {
    background-position: right bottom !important;
  }

  .xs\:bg-right-top {
    background-position: right top !important;
  }

  .xs\:bg-top {
    background-position: top !important;
  }

  .xs\:bg-repeat {
    background-repeat: repeat !important;
  }

  .xs\:bg-no-repeat {
    background-repeat: no-repeat !important;
  }

  .xs\:bg-repeat-x {
    background-repeat: repeat-x !important;
  }

  .xs\:bg-repeat-y {
    background-repeat: repeat-y !important;
  }

  .xs\:bg-repeat-round {
    background-repeat: round !important;
  }

  .xs\:bg-repeat-space {
    background-repeat: space !important;
  }

  .xs\:bg-auto {
    background-size: auto !important;
  }

  .xs\:bg-cover {
    background-size: cover !important;
  }

  .xs\:bg-contain {
    background-size: contain !important;
  }

  .xs\:border-collapse {
    border-collapse: collapse !important;
  }

  .xs\:border-separate {
    border-collapse: separate !important;
  }

  .xs\:border-opacity-0 {
    --border-opacity: 0 !important;
  }

  .xs\:border-opacity-12 {
    --border-opacity: 0.12 !important;
  }

  .xs\:border-opacity-25 {
    --border-opacity: 0.25 !important;
  }

  .xs\:border-opacity-38 {
    --border-opacity: 0.38 !important;
  }

  .xs\:border-opacity-50 {
    --border-opacity: 0.5 !important;
  }

  .xs\:border-opacity-54 {
    --border-opacity: 0.54 !important;
  }

  .xs\:border-opacity-70 {
    --border-opacity: 0.70 !important;
  }

  .xs\:border-opacity-75 {
    --border-opacity: 0.75 !important;
  }

  .xs\:border-opacity-84 {
    --border-opacity: 0.84 !important;
  }

  .xs\:border-opacity-100 {
    --border-opacity: 1 !important;
  }

  .xs\:hover\:border-opacity-0:hover {
    --border-opacity: 0 !important;
  }

  .xs\:hover\:border-opacity-12:hover {
    --border-opacity: 0.12 !important;
  }

  .xs\:hover\:border-opacity-25:hover {
    --border-opacity: 0.25 !important;
  }

  .xs\:hover\:border-opacity-38:hover {
    --border-opacity: 0.38 !important;
  }

  .xs\:hover\:border-opacity-50:hover {
    --border-opacity: 0.5 !important;
  }

  .xs\:hover\:border-opacity-54:hover {
    --border-opacity: 0.54 !important;
  }

  .xs\:hover\:border-opacity-70:hover {
    --border-opacity: 0.70 !important;
  }

  .xs\:hover\:border-opacity-75:hover {
    --border-opacity: 0.75 !important;
  }

  .xs\:hover\:border-opacity-84:hover {
    --border-opacity: 0.84 !important;
  }

  .xs\:hover\:border-opacity-100:hover {
    --border-opacity: 1 !important;
  }

  .xs\:focus\:border-opacity-0:focus {
    --border-opacity: 0 !important;
  }

  .xs\:focus\:border-opacity-12:focus {
    --border-opacity: 0.12 !important;
  }

  .xs\:focus\:border-opacity-25:focus {
    --border-opacity: 0.25 !important;
  }

  .xs\:focus\:border-opacity-38:focus {
    --border-opacity: 0.38 !important;
  }

  .xs\:focus\:border-opacity-50:focus {
    --border-opacity: 0.5 !important;
  }

  .xs\:focus\:border-opacity-54:focus {
    --border-opacity: 0.54 !important;
  }

  .xs\:focus\:border-opacity-70:focus {
    --border-opacity: 0.70 !important;
  }

  .xs\:focus\:border-opacity-75:focus {
    --border-opacity: 0.75 !important;
  }

  .xs\:focus\:border-opacity-84:focus {
    --border-opacity: 0.84 !important;
  }

  .xs\:focus\:border-opacity-100:focus {
    --border-opacity: 1 !important;
  }

  .xs\:rounded-none {
    border-radius: 0 !important;
  }

  .xs\:rounded-sm {
    border-radius: 0.125rem !important;
  }

  .xs\:rounded {
    border-radius: 0.25rem !important;
  }

  .xs\:rounded-md {
    border-radius: 0.375rem !important;
  }

  .xs\:rounded-lg {
    border-radius: 0.5rem !important;
  }

  .xs\:rounded-full {
    border-radius: 9999px !important;
  }

  .xs\:rounded-t-none {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .xs\:rounded-r-none {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .xs\:rounded-b-none {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .xs\:rounded-l-none {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .xs\:rounded-t-sm {
    border-top-left-radius: 0.125rem !important;
    border-top-right-radius: 0.125rem !important;
  }

  .xs\:rounded-r-sm {
    border-top-right-radius: 0.125rem !important;
    border-bottom-right-radius: 0.125rem !important;
  }

  .xs\:rounded-b-sm {
    border-bottom-right-radius: 0.125rem !important;
    border-bottom-left-radius: 0.125rem !important;
  }

  .xs\:rounded-l-sm {
    border-top-left-radius: 0.125rem !important;
    border-bottom-left-radius: 0.125rem !important;
  }

  .xs\:rounded-t {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }

  .xs\:rounded-r {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }

  .xs\:rounded-b {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }

  .xs\:rounded-l {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }

  .xs\:rounded-t-md {
    border-top-left-radius: 0.375rem !important;
    border-top-right-radius: 0.375rem !important;
  }

  .xs\:rounded-r-md {
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }

  .xs\:rounded-b-md {
    border-bottom-right-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }

  .xs\:rounded-l-md {
    border-top-left-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }

  .xs\:rounded-t-lg {
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
  }

  .xs\:rounded-r-lg {
    border-top-right-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }

  .xs\:rounded-b-lg {
    border-bottom-right-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }

  .xs\:rounded-l-lg {
    border-top-left-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }

  .xs\:rounded-t-full {
    border-top-left-radius: 9999px !important;
    border-top-right-radius: 9999px !important;
  }

  .xs\:rounded-r-full {
    border-top-right-radius: 9999px !important;
    border-bottom-right-radius: 9999px !important;
  }

  .xs\:rounded-b-full {
    border-bottom-right-radius: 9999px !important;
    border-bottom-left-radius: 9999px !important;
  }

  .xs\:rounded-l-full {
    border-top-left-radius: 9999px !important;
    border-bottom-left-radius: 9999px !important;
  }

  .xs\:rounded-tl-none {
    border-top-left-radius: 0 !important;
  }

  .xs\:rounded-tr-none {
    border-top-right-radius: 0 !important;
  }

  .xs\:rounded-br-none {
    border-bottom-right-radius: 0 !important;
  }

  .xs\:rounded-bl-none {
    border-bottom-left-radius: 0 !important;
  }

  .xs\:rounded-tl-sm {
    border-top-left-radius: 0.125rem !important;
  }

  .xs\:rounded-tr-sm {
    border-top-right-radius: 0.125rem !important;
  }

  .xs\:rounded-br-sm {
    border-bottom-right-radius: 0.125rem !important;
  }

  .xs\:rounded-bl-sm {
    border-bottom-left-radius: 0.125rem !important;
  }

  .xs\:rounded-tl {
    border-top-left-radius: 0.25rem !important;
  }

  .xs\:rounded-tr {
    border-top-right-radius: 0.25rem !important;
  }

  .xs\:rounded-br {
    border-bottom-right-radius: 0.25rem !important;
  }

  .xs\:rounded-bl {
    border-bottom-left-radius: 0.25rem !important;
  }

  .xs\:rounded-tl-md {
    border-top-left-radius: 0.375rem !important;
  }

  .xs\:rounded-tr-md {
    border-top-right-radius: 0.375rem !important;
  }

  .xs\:rounded-br-md {
    border-bottom-right-radius: 0.375rem !important;
  }

  .xs\:rounded-bl-md {
    border-bottom-left-radius: 0.375rem !important;
  }

  .xs\:rounded-tl-lg {
    border-top-left-radius: 0.5rem !important;
  }

  .xs\:rounded-tr-lg {
    border-top-right-radius: 0.5rem !important;
  }

  .xs\:rounded-br-lg {
    border-bottom-right-radius: 0.5rem !important;
  }

  .xs\:rounded-bl-lg {
    border-bottom-left-radius: 0.5rem !important;
  }

  .xs\:rounded-tl-full {
    border-top-left-radius: 9999px !important;
  }

  .xs\:rounded-tr-full {
    border-top-right-radius: 9999px !important;
  }

  .xs\:rounded-br-full {
    border-bottom-right-radius: 9999px !important;
  }

  .xs\:rounded-bl-full {
    border-bottom-left-radius: 9999px !important;
  }

  .xs\:border-solid {
    border-style: solid !important;
  }

  .xs\:border-dashed {
    border-style: dashed !important;
  }

  .xs\:border-dotted {
    border-style: dotted !important;
  }

  .xs\:border-double {
    border-style: double !important;
  }

  .xs\:border-none {
    border-style: none !important;
  }

  .xs\:border-0 {
    border-width: 0 !important;
  }

  .xs\:border-2 {
    border-width: 2px !important;
  }

  .xs\:border-4 {
    border-width: 4px !important;
  }

  .xs\:border-8 {
    border-width: 8px !important;
  }

  .xs\:border {
    border-width: 1px !important;
  }

  .xs\:border-t-0 {
    border-top-width: 0 !important;
  }

  .xs\:border-r-0 {
    border-right-width: 0 !important;
  }

  .xs\:border-b-0 {
    border-bottom-width: 0 !important;
  }

  .xs\:border-l-0 {
    border-left-width: 0 !important;
  }

  .xs\:border-t-2 {
    border-top-width: 2px !important;
  }

  .xs\:border-r-2 {
    border-right-width: 2px !important;
  }

  .xs\:border-b-2 {
    border-bottom-width: 2px !important;
  }

  .xs\:border-l-2 {
    border-left-width: 2px !important;
  }

  .xs\:border-t-4 {
    border-top-width: 4px !important;
  }

  .xs\:border-r-4 {
    border-right-width: 4px !important;
  }

  .xs\:border-b-4 {
    border-bottom-width: 4px !important;
  }

  .xs\:border-l-4 {
    border-left-width: 4px !important;
  }

  .xs\:border-t-8 {
    border-top-width: 8px !important;
  }

  .xs\:border-r-8 {
    border-right-width: 8px !important;
  }

  .xs\:border-b-8 {
    border-bottom-width: 8px !important;
  }

  .xs\:border-l-8 {
    border-left-width: 8px !important;
  }

  .xs\:border-t {
    border-top-width: 1px !important;
  }

  .xs\:border-r {
    border-right-width: 1px !important;
  }

  .xs\:border-b {
    border-bottom-width: 1px !important;
  }

  .xs\:border-l {
    border-left-width: 1px !important;
  }

  .xs\:first\:border-0:first-child {
    border-width: 0 !important;
  }

  .xs\:first\:border-2:first-child {
    border-width: 2px !important;
  }

  .xs\:first\:border-4:first-child {
    border-width: 4px !important;
  }

  .xs\:first\:border-8:first-child {
    border-width: 8px !important;
  }

  .xs\:first\:border:first-child {
    border-width: 1px !important;
  }

  .xs\:first\:border-t-0:first-child {
    border-top-width: 0 !important;
  }

  .xs\:first\:border-r-0:first-child {
    border-right-width: 0 !important;
  }

  .xs\:first\:border-b-0:first-child {
    border-bottom-width: 0 !important;
  }

  .xs\:first\:border-l-0:first-child {
    border-left-width: 0 !important;
  }

  .xs\:first\:border-t-2:first-child {
    border-top-width: 2px !important;
  }

  .xs\:first\:border-r-2:first-child {
    border-right-width: 2px !important;
  }

  .xs\:first\:border-b-2:first-child {
    border-bottom-width: 2px !important;
  }

  .xs\:first\:border-l-2:first-child {
    border-left-width: 2px !important;
  }

  .xs\:first\:border-t-4:first-child {
    border-top-width: 4px !important;
  }

  .xs\:first\:border-r-4:first-child {
    border-right-width: 4px !important;
  }

  .xs\:first\:border-b-4:first-child {
    border-bottom-width: 4px !important;
  }

  .xs\:first\:border-l-4:first-child {
    border-left-width: 4px !important;
  }

  .xs\:first\:border-t-8:first-child {
    border-top-width: 8px !important;
  }

  .xs\:first\:border-r-8:first-child {
    border-right-width: 8px !important;
  }

  .xs\:first\:border-b-8:first-child {
    border-bottom-width: 8px !important;
  }

  .xs\:first\:border-l-8:first-child {
    border-left-width: 8px !important;
  }

  .xs\:first\:border-t:first-child {
    border-top-width: 1px !important;
  }

  .xs\:first\:border-r:first-child {
    border-right-width: 1px !important;
  }

  .xs\:first\:border-b:first-child {
    border-bottom-width: 1px !important;
  }

  .xs\:first\:border-l:first-child {
    border-left-width: 1px !important;
  }

  .xs\:last\:border-0:last-child {
    border-width: 0 !important;
  }

  .xs\:last\:border-2:last-child {
    border-width: 2px !important;
  }

  .xs\:last\:border-4:last-child {
    border-width: 4px !important;
  }

  .xs\:last\:border-8:last-child {
    border-width: 8px !important;
  }

  .xs\:last\:border:last-child {
    border-width: 1px !important;
  }

  .xs\:last\:border-t-0:last-child {
    border-top-width: 0 !important;
  }

  .xs\:last\:border-r-0:last-child {
    border-right-width: 0 !important;
  }

  .xs\:last\:border-b-0:last-child {
    border-bottom-width: 0 !important;
  }

  .xs\:last\:border-l-0:last-child {
    border-left-width: 0 !important;
  }

  .xs\:last\:border-t-2:last-child {
    border-top-width: 2px !important;
  }

  .xs\:last\:border-r-2:last-child {
    border-right-width: 2px !important;
  }

  .xs\:last\:border-b-2:last-child {
    border-bottom-width: 2px !important;
  }

  .xs\:last\:border-l-2:last-child {
    border-left-width: 2px !important;
  }

  .xs\:last\:border-t-4:last-child {
    border-top-width: 4px !important;
  }

  .xs\:last\:border-r-4:last-child {
    border-right-width: 4px !important;
  }

  .xs\:last\:border-b-4:last-child {
    border-bottom-width: 4px !important;
  }

  .xs\:last\:border-l-4:last-child {
    border-left-width: 4px !important;
  }

  .xs\:last\:border-t-8:last-child {
    border-top-width: 8px !important;
  }

  .xs\:last\:border-r-8:last-child {
    border-right-width: 8px !important;
  }

  .xs\:last\:border-b-8:last-child {
    border-bottom-width: 8px !important;
  }

  .xs\:last\:border-l-8:last-child {
    border-left-width: 8px !important;
  }

  .xs\:last\:border-t:last-child {
    border-top-width: 1px !important;
  }

  .xs\:last\:border-r:last-child {
    border-right-width: 1px !important;
  }

  .xs\:last\:border-b:last-child {
    border-bottom-width: 1px !important;
  }

  .xs\:last\:border-l:last-child {
    border-left-width: 1px !important;
  }

  .xs\:box-border {
    box-sizing: border-box !important;
  }

  .xs\:box-content {
    box-sizing: content-box !important;
  }

  .xs\:block {
    display: block !important;
  }

  .xs\:inline-block {
    display: inline-block !important;
  }

  .xs\:inline {
    display: inline !important;
  }

  .xs\:flex {
    display: flex !important;
  }

  .xs\:inline-flex {
    display: inline-flex !important;
  }

  .xs\:table {
    display: table !important;
  }

  .xs\:table-caption {
    display: table-caption !important;
  }

  .xs\:table-cell {
    display: table-cell !important;
  }

  .xs\:table-column {
    display: table-column !important;
  }

  .xs\:table-column-group {
    display: table-column-group !important;
  }

  .xs\:table-footer-group {
    display: table-footer-group !important;
  }

  .xs\:table-header-group {
    display: table-header-group !important;
  }

  .xs\:table-row-group {
    display: table-row-group !important;
  }

  .xs\:table-row {
    display: table-row !important;
  }

  .xs\:flow-root {
    display: flow-root !important;
  }

  .xs\:grid {
    display: grid !important;
  }

  .xs\:inline-grid {
    display: inline-grid !important;
  }

  .xs\:hidden {
    display: none !important;
  }

  .xs\:flex-row {
    flex-direction: row !important;
  }

  .xs\:flex-row-reverse {
    flex-direction: row-reverse !important;
  }

  .xs\:flex-col {
    flex-direction: column !important;
  }

  .xs\:flex-col-reverse {
    flex-direction: column-reverse !important;
  }

  .xs\:flex-wrap {
    flex-wrap: wrap !important;
  }

  .xs\:flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .xs\:flex-nowrap {
    flex-wrap: nowrap !important;
  }

  .xs\:items-start {
    align-items: flex-start !important;
  }

  .xs\:items-end {
    align-items: flex-end !important;
  }

  .xs\:items-center {
    align-items: center !important;
  }

  .xs\:items-baseline {
    align-items: baseline !important;
  }

  .xs\:items-stretch {
    align-items: stretch !important;
  }

  .xs\:self-auto {
    align-self: auto !important;
  }

  .xs\:self-start {
    align-self: flex-start !important;
  }

  .xs\:self-end {
    align-self: flex-end !important;
  }

  .xs\:self-center {
    align-self: center !important;
  }

  .xs\:self-stretch {
    align-self: stretch !important;
  }

  .xs\:justify-start {
    justify-content: flex-start !important;
  }

  .xs\:justify-end {
    justify-content: flex-end !important;
  }

  .xs\:justify-center {
    justify-content: center !important;
  }

  .xs\:justify-between {
    justify-content: space-between !important;
  }

  .xs\:justify-around {
    justify-content: space-around !important;
  }

  .xs\:justify-evenly {
    justify-content: space-evenly !important;
  }

  .xs\:content-center {
    align-content: center !important;
  }

  .xs\:content-start {
    align-content: flex-start !important;
  }

  .xs\:content-end {
    align-content: flex-end !important;
  }

  .xs\:content-between {
    align-content: space-between !important;
  }

  .xs\:content-around {
    align-content: space-around !important;
  }

  .xs\:flex-0 {
    flex: 0 0 auto !important;
  }

  .xs\:flex-1 {
    flex: 1 1 0% !important;
  }

  .xs\:flex-auto {
    flex: 1 1 auto !important;
  }

  .xs\:flex-initial {
    flex: 0 1 auto !important;
  }

  .xs\:flex-none {
    flex: none !important;
  }

  .xs\:flex-grow-0 {
    flex-grow: 0 !important;
  }

  .xs\:flex-grow {
    flex-grow: 1 !important;
  }

  .xs\:flex-shrink-0 {
    flex-shrink: 0 !important;
  }

  .xs\:flex-shrink {
    flex-shrink: 1 !important;
  }

  .xs\:order-1 {
    order: 1 !important;
  }

  .xs\:order-2 {
    order: 2 !important;
  }

  .xs\:order-3 {
    order: 3 !important;
  }

  .xs\:order-4 {
    order: 4 !important;
  }

  .xs\:order-5 {
    order: 5 !important;
  }

  .xs\:order-6 {
    order: 6 !important;
  }

  .xs\:order-7 {
    order: 7 !important;
  }

  .xs\:order-8 {
    order: 8 !important;
  }

  .xs\:order-9 {
    order: 9 !important;
  }

  .xs\:order-10 {
    order: 10 !important;
  }

  .xs\:order-11 {
    order: 11 !important;
  }

  .xs\:order-12 {
    order: 12 !important;
  }

  .xs\:order-first {
    order: -9999 !important;
  }

  .xs\:order-last {
    order: 9999 !important;
  }

  .xs\:order-none {
    order: 0 !important;
  }

  .xs\:font-thin {
    font-weight: 100 !important;
  }

  .xs\:font-extralight {
    font-weight: 200 !important;
  }

  .xs\:font-light {
    font-weight: 300 !important;
  }

  .xs\:font-normal {
    font-weight: 400 !important;
  }

  .xs\:font-medium {
    font-weight: 500 !important;
  }

  .xs\:font-semibold {
    font-weight: 600 !important;
  }

  .xs\:font-bold {
    font-weight: 700 !important;
  }

  .xs\:font-extrabold {
    font-weight: 800 !important;
  }

  .xs\:font-black {
    font-weight: 900 !important;
  }

  .xs\:h-0 {
    height: 0 !important;
  }

  .xs\:h-1 {
    height: 0.25rem !important;
  }

  .xs\:h-2 {
    height: 0.5rem !important;
  }

  .xs\:h-3 {
    height: 0.75rem !important;
  }

  .xs\:h-4 {
    height: 1rem !important;
  }

  .xs\:h-5 {
    height: 1.25rem !important;
  }

  .xs\:h-6 {
    height: 1.5rem !important;
  }

  .xs\:h-8 {
    height: 2rem !important;
  }

  .xs\:h-10 {
    height: 2.5rem !important;
  }

  .xs\:h-12 {
    height: 3rem !important;
  }

  .xs\:h-14 {
    height: 3.5rem !important;
  }

  .xs\:h-16 {
    height: 4rem !important;
  }

  .xs\:h-18 {
    height: 4.5rem !important;
  }

  .xs\:h-20 {
    height: 5rem !important;
  }

  .xs\:h-22 {
    height: 5.5rem !important;
  }

  .xs\:h-24 {
    height: 6rem !important;
  }

  .xs\:h-26 {
    height: 6.5rem !important;
  }

  .xs\:h-28 {
    height: 7rem !important;
  }

  .xs\:h-30 {
    height: 7.5rem !important;
  }

  .xs\:h-32 {
    height: 8rem !important;
  }

  .xs\:h-36 {
    height: 9rem !important;
  }

  .xs\:h-40 {
    height: 10rem !important;
  }

  .xs\:h-48 {
    height: 12rem !important;
  }

  .xs\:h-50 {
    height: 12.5rem !important;
  }

  .xs\:h-56 {
    height: 14rem !important;
  }

  .xs\:h-60 {
    height: 15rem !important;
  }

  .xs\:h-64 {
    height: 16rem !important;
  }

  .xs\:h-80 {
    height: 20rem !important;
  }

  .xs\:h-90 {
    height: 24rem !important;
  }

  .xs\:h-100 {
    height: 25rem !important;
  }

  .xs\:h-120 {
    height: 30rem !important;
  }

  .xs\:h-128 {
    height: 32rem !important;
  }

  .xs\:h-140 {
    height: 35rem !important;
  }

  .xs\:h-160 {
    height: 40rem !important;
  }

  .xs\:h-180 {
    height: 45rem !important;
  }

  .xs\:h-192 {
    height: 48rem !important;
  }

  .xs\:h-200 {
    height: 50rem !important;
  }

  .xs\:h-240 {
    height: 60rem !important;
  }

  .xs\:h-256 {
    height: 64rem !important;
  }

  .xs\:h-280 {
    height: 70rem !important;
  }

  .xs\:h-320 {
    height: 80rem !important;
  }

  .xs\:h-360 {
    height: 90rem !important;
  }

  .xs\:h-400 {
    height: 100rem !important;
  }

  .xs\:h-480 {
    height: 120rem !important;
  }

  .xs\:h-auto {
    height: auto !important;
  }

  .xs\:h-px {
    height: 1px !important;
  }

  .xs\:h-2px {
    height: 2px !important;
  }

  .xs\:h-full {
    height: 100% !important;
  }

  .xs\:h-screen {
    height: 100vh !important;
  }

  .xs\:h-1\/2 {
    height: 50% !important;
  }

  .xs\:h-1\/3 {
    height: 33.33333% !important;
  }

  .xs\:h-2\/3 {
    height: 66.66667% !important;
  }

  .xs\:h-1\/4 {
    height: 25% !important;
  }

  .xs\:h-2\/4 {
    height: 50% !important;
  }

  .xs\:h-3\/4 {
    height: 75% !important;
  }

  .xs\:h-1\/5 {
    height: 20% !important;
  }

  .xs\:h-2\/5 {
    height: 40% !important;
  }

  .xs\:h-3\/5 {
    height: 60% !important;
  }

  .xs\:h-4\/5 {
    height: 80% !important;
  }

  .xs\:h-1\/12 {
    height: 8.33333% !important;
  }

  .xs\:h-2\/12 {
    height: 16.66667% !important;
  }

  .xs\:h-3\/12 {
    height: 25% !important;
  }

  .xs\:h-4\/12 {
    height: 33.33333% !important;
  }

  .xs\:h-5\/12 {
    height: 41.66667% !important;
  }

  .xs\:h-6\/12 {
    height: 50% !important;
  }

  .xs\:h-7\/12 {
    height: 58.33333% !important;
  }

  .xs\:h-8\/12 {
    height: 66.66667% !important;
  }

  .xs\:h-9\/12 {
    height: 75% !important;
  }

  .xs\:h-10\/12 {
    height: 83.33333% !important;
  }

  .xs\:h-11\/12 {
    height: 91.66667% !important;
  }

  .xs\:text-xs {
    font-size: 0.625rem !important;
  }

  .xs\:text-sm {
    font-size: 0.75rem !important;
  }

  .xs\:text-md {
    font-size: 0.8125rem !important;
  }

  .xs\:text-base {
    font-size: 0.875rem !important;
  }

  .xs\:text-lg {
    font-size: 1rem !important;
  }

  .xs\:text-xl {
    font-size: 1.125rem !important;
  }

  .xs\:text-2xl {
    font-size: 1.25rem !important;
  }

  .xs\:text-3xl {
    font-size: 1.5rem !important;
  }

  .xs\:text-4xl {
    font-size: 2rem !important;
  }

  .xs\:text-5xl {
    font-size: 2.25rem !important;
  }

  .xs\:text-6xl {
    font-size: 2.5rem !important;
  }

  .xs\:text-7xl {
    font-size: 3rem !important;
  }

  .xs\:text-8xl {
    font-size: 4rem !important;
  }

  .xs\:text-9xl {
    font-size: 6rem !important;
  }

  .xs\:text-10xl {
    font-size: 8rem !important;
  }

  .xs\:leading-3 {
    line-height: .75rem !important;
  }

  .xs\:leading-4 {
    line-height: 1rem !important;
  }

  .xs\:leading-5 {
    line-height: 1.25rem !important;
  }

  .xs\:leading-6 {
    line-height: 1.5rem !important;
  }

  .xs\:leading-7 {
    line-height: 1.75rem !important;
  }

  .xs\:leading-8 {
    line-height: 2rem !important;
  }

  .xs\:leading-9 {
    line-height: 2.25rem !important;
  }

  .xs\:leading-10 {
    line-height: 2.5rem !important;
  }

  .xs\:leading-none {
    line-height: 1 !important;
  }

  .xs\:leading-tight {
    line-height: 1.25 !important;
  }

  .xs\:leading-snug {
    line-height: 1.375 !important;
  }

  .xs\:leading-normal {
    line-height: 1.5 !important;
  }

  .xs\:leading-relaxed {
    line-height: 1.625 !important;
  }

  .xs\:leading-loose {
    line-height: 2 !important;
  }

  .xs\:list-inside {
    list-style-position: inside !important;
  }

  .xs\:list-outside {
    list-style-position: outside !important;
  }

  .xs\:list-none {
    list-style-type: none !important;
  }

  .xs\:list-disc {
    list-style-type: disc !important;
  }

  .xs\:list-decimal {
    list-style-type: decimal !important;
  }

  .xs\:m-0 {
    margin: 0 !important;
  }

  .xs\:m-1 {
    margin: 0.25rem !important;
  }

  .xs\:m-2 {
    margin: 0.5rem !important;
  }

  .xs\:m-3 {
    margin: 0.75rem !important;
  }

  .xs\:m-4 {
    margin: 1rem !important;
  }

  .xs\:m-5 {
    margin: 1.25rem !important;
  }

  .xs\:m-6 {
    margin: 1.5rem !important;
  }

  .xs\:m-8 {
    margin: 2rem !important;
  }

  .xs\:m-10 {
    margin: 2.5rem !important;
  }

  .xs\:m-12 {
    margin: 3rem !important;
  }

  .xs\:m-14 {
    margin: 3.5rem !important;
  }

  .xs\:m-16 {
    margin: 4rem !important;
  }

  .xs\:m-18 {
    margin: 4.5rem !important;
  }

  .xs\:m-20 {
    margin: 5rem !important;
  }

  .xs\:m-22 {
    margin: 5.5rem !important;
  }

  .xs\:m-24 {
    margin: 6rem !important;
  }

  .xs\:m-26 {
    margin: 6.5rem !important;
  }

  .xs\:m-28 {
    margin: 7rem !important;
  }

  .xs\:m-30 {
    margin: 7.5rem !important;
  }

  .xs\:m-32 {
    margin: 8rem !important;
  }

  .xs\:m-36 {
    margin: 9rem !important;
  }

  .xs\:m-40 {
    margin: 10rem !important;
  }

  .xs\:m-48 {
    margin: 12rem !important;
  }

  .xs\:m-56 {
    margin: 14rem !important;
  }

  .xs\:m-64 {
    margin: 16rem !important;
  }

  .xs\:m-auto {
    margin: auto !important;
  }

  .xs\:m-px {
    margin: 1px !important;
  }

  .xs\:m-2px {
    margin: 2px !important;
  }

  .xs\:-m-1 {
    margin: -0.25rem !important;
  }

  .xs\:-m-2 {
    margin: -0.5rem !important;
  }

  .xs\:-m-3 {
    margin: -0.75rem !important;
  }

  .xs\:-m-4 {
    margin: -1rem !important;
  }

  .xs\:-m-5 {
    margin: -1.25rem !important;
  }

  .xs\:-m-6 {
    margin: -1.5rem !important;
  }

  .xs\:-m-8 {
    margin: -2rem !important;
  }

  .xs\:-m-10 {
    margin: -2.5rem !important;
  }

  .xs\:-m-12 {
    margin: -3rem !important;
  }

  .xs\:-m-14 {
    margin: -3.5rem !important;
  }

  .xs\:-m-16 {
    margin: -4rem !important;
  }

  .xs\:-m-18 {
    margin: -4.5rem !important;
  }

  .xs\:-m-20 {
    margin: -5rem !important;
  }

  .xs\:-m-22 {
    margin: -5.5rem !important;
  }

  .xs\:-m-24 {
    margin: -6rem !important;
  }

  .xs\:-m-26 {
    margin: -6.5rem !important;
  }

  .xs\:-m-28 {
    margin: -7rem !important;
  }

  .xs\:-m-30 {
    margin: -7.5rem !important;
  }

  .xs\:-m-32 {
    margin: -8rem !important;
  }

  .xs\:-m-36 {
    margin: -9rem !important;
  }

  .xs\:-m-40 {
    margin: -10rem !important;
  }

  .xs\:-m-48 {
    margin: -12rem !important;
  }

  .xs\:-m-56 {
    margin: -14rem !important;
  }

  .xs\:-m-64 {
    margin: -16rem !important;
  }

  .xs\:-m-px {
    margin: -1px !important;
  }

  .xs\:-m-2px {
    margin: -2px !important;
  }

  .xs\:my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .xs\:mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .xs\:my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .xs\:mx-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }

  .xs\:my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .xs\:mx-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .xs\:my-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .xs\:mx-3 {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
  }

  .xs\:my-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .xs\:mx-4 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .xs\:my-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .xs\:mx-5 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }

  .xs\:my-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .xs\:mx-6 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .xs\:my-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .xs\:mx-8 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .xs\:my-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .xs\:mx-10 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .xs\:my-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .xs\:mx-12 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .xs\:my-14 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .xs\:mx-14 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }

  .xs\:my-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .xs\:mx-16 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .xs\:my-18 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .xs\:mx-18 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }

  .xs\:my-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .xs\:mx-20 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .xs\:my-22 {
    margin-top: 5.5rem !important;
    margin-bottom: 5.5rem !important;
  }

  .xs\:mx-22 {
    margin-left: 5.5rem !important;
    margin-right: 5.5rem !important;
  }

  .xs\:my-24 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .xs\:mx-24 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }

  .xs\:my-26 {
    margin-top: 6.5rem !important;
    margin-bottom: 6.5rem !important;
  }

  .xs\:mx-26 {
    margin-left: 6.5rem !important;
    margin-right: 6.5rem !important;
  }

  .xs\:my-28 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }

  .xs\:mx-28 {
    margin-left: 7rem !important;
    margin-right: 7rem !important;
  }

  .xs\:my-30 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }

  .xs\:mx-30 {
    margin-left: 7.5rem !important;
    margin-right: 7.5rem !important;
  }

  .xs\:my-32 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .xs\:mx-32 {
    margin-left: 8rem !important;
    margin-right: 8rem !important;
  }

  .xs\:my-36 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }

  .xs\:mx-36 {
    margin-left: 9rem !important;
    margin-right: 9rem !important;
  }

  .xs\:my-40 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .xs\:mx-40 {
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }

  .xs\:my-48 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }

  .xs\:mx-48 {
    margin-left: 12rem !important;
    margin-right: 12rem !important;
  }

  .xs\:my-56 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }

  .xs\:mx-56 {
    margin-left: 14rem !important;
    margin-right: 14rem !important;
  }

  .xs\:my-64 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }

  .xs\:mx-64 {
    margin-left: 16rem !important;
    margin-right: 16rem !important;
  }

  .xs\:my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .xs\:mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .xs\:my-px {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  .xs\:mx-px {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }

  .xs\:my-2px {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }

  .xs\:mx-2px {
    margin-left: 2px !important;
    margin-right: 2px !important;
  }

  .xs\:-my-1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .xs\:-mx-1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }

  .xs\:-my-2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .xs\:-mx-2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }

  .xs\:-my-3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }

  .xs\:-mx-3 {
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important;
  }

  .xs\:-my-4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .xs\:-mx-4 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .xs\:-my-5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .xs\:-mx-5 {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }

  .xs\:-my-6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .xs\:-mx-6 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .xs\:-my-8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .xs\:-mx-8 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .xs\:-my-10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .xs\:-mx-10 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }

  .xs\:-my-12 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .xs\:-mx-12 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .xs\:-my-14 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .xs\:-mx-14 {
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }

  .xs\:-my-16 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .xs\:-mx-16 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .xs\:-my-18 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .xs\:-mx-18 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }

  .xs\:-my-20 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .xs\:-mx-20 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .xs\:-my-22 {
    margin-top: -5.5rem !important;
    margin-bottom: -5.5rem !important;
  }

  .xs\:-mx-22 {
    margin-left: -5.5rem !important;
    margin-right: -5.5rem !important;
  }

  .xs\:-my-24 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .xs\:-mx-24 {
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }

  .xs\:-my-26 {
    margin-top: -6.5rem !important;
    margin-bottom: -6.5rem !important;
  }

  .xs\:-mx-26 {
    margin-left: -6.5rem !important;
    margin-right: -6.5rem !important;
  }

  .xs\:-my-28 {
    margin-top: -7rem !important;
    margin-bottom: -7rem !important;
  }

  .xs\:-mx-28 {
    margin-left: -7rem !important;
    margin-right: -7rem !important;
  }

  .xs\:-my-30 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important;
  }

  .xs\:-mx-30 {
    margin-left: -7.5rem !important;
    margin-right: -7.5rem !important;
  }

  .xs\:-my-32 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .xs\:-mx-32 {
    margin-left: -8rem !important;
    margin-right: -8rem !important;
  }

  .xs\:-my-36 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }

  .xs\:-mx-36 {
    margin-left: -9rem !important;
    margin-right: -9rem !important;
  }

  .xs\:-my-40 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .xs\:-mx-40 {
    margin-left: -10rem !important;
    margin-right: -10rem !important;
  }

  .xs\:-my-48 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }

  .xs\:-mx-48 {
    margin-left: -12rem !important;
    margin-right: -12rem !important;
  }

  .xs\:-my-56 {
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }

  .xs\:-mx-56 {
    margin-left: -14rem !important;
    margin-right: -14rem !important;
  }

  .xs\:-my-64 {
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }

  .xs\:-mx-64 {
    margin-left: -16rem !important;
    margin-right: -16rem !important;
  }

  .xs\:-my-px {
    margin-top: -1px !important;
    margin-bottom: -1px !important;
  }

  .xs\:-mx-px {
    margin-left: -1px !important;
    margin-right: -1px !important;
  }

  .xs\:-my-2px {
    margin-top: -2px !important;
    margin-bottom: -2px !important;
  }

  .xs\:-mx-2px {
    margin-left: -2px !important;
    margin-right: -2px !important;
  }

  .xs\:mt-0 {
    margin-top: 0 !important;
  }

  .xs\:mr-0 {
    margin-right: 0 !important;
  }

  .xs\:mb-0 {
    margin-bottom: 0 !important;
  }

  .xs\:ml-0 {
    margin-left: 0 !important;
  }

  .xs\:mt-1 {
    margin-top: 0.25rem !important;
  }

  .xs\:mr-1 {
    margin-right: 0.25rem !important;
  }

  .xs\:mb-1 {
    margin-bottom: 0.25rem !important;
  }

  .xs\:ml-1 {
    margin-left: 0.25rem !important;
  }

  .xs\:mt-2 {
    margin-top: 0.5rem !important;
  }

  .xs\:mr-2 {
    margin-right: 0.5rem !important;
  }

  .xs\:mb-2 {
    margin-bottom: 0.5rem !important;
  }

  .xs\:ml-2 {
    margin-left: 0.5rem !important;
  }

  .xs\:mt-3 {
    margin-top: 0.75rem !important;
  }

  .xs\:mr-3 {
    margin-right: 0.75rem !important;
  }

  .xs\:mb-3 {
    margin-bottom: 0.75rem !important;
  }

  .xs\:ml-3 {
    margin-left: 0.75rem !important;
  }

  .xs\:mt-4 {
    margin-top: 1rem !important;
  }

  .xs\:mr-4 {
    margin-right: 1rem !important;
  }

  .xs\:mb-4 {
    margin-bottom: 1rem !important;
  }

  .xs\:ml-4 {
    margin-left: 1rem !important;
  }

  .xs\:mt-5 {
    margin-top: 1.25rem !important;
  }

  .xs\:mr-5 {
    margin-right: 1.25rem !important;
  }

  .xs\:mb-5 {
    margin-bottom: 1.25rem !important;
  }

  .xs\:ml-5 {
    margin-left: 1.25rem !important;
  }

  .xs\:mt-6 {
    margin-top: 1.5rem !important;
  }

  .xs\:mr-6 {
    margin-right: 1.5rem !important;
  }

  .xs\:mb-6 {
    margin-bottom: 1.5rem !important;
  }

  .xs\:ml-6 {
    margin-left: 1.5rem !important;
  }

  .xs\:mt-8 {
    margin-top: 2rem !important;
  }

  .xs\:mr-8 {
    margin-right: 2rem !important;
  }

  .xs\:mb-8 {
    margin-bottom: 2rem !important;
  }

  .xs\:ml-8 {
    margin-left: 2rem !important;
  }

  .xs\:mt-10 {
    margin-top: 2.5rem !important;
  }

  .xs\:mr-10 {
    margin-right: 2.5rem !important;
  }

  .xs\:mb-10 {
    margin-bottom: 2.5rem !important;
  }

  .xs\:ml-10 {
    margin-left: 2.5rem !important;
  }

  .xs\:mt-12 {
    margin-top: 3rem !important;
  }

  .xs\:mr-12 {
    margin-right: 3rem !important;
  }

  .xs\:mb-12 {
    margin-bottom: 3rem !important;
  }

  .xs\:ml-12 {
    margin-left: 3rem !important;
  }

  .xs\:mt-14 {
    margin-top: 3.5rem !important;
  }

  .xs\:mr-14 {
    margin-right: 3.5rem !important;
  }

  .xs\:mb-14 {
    margin-bottom: 3.5rem !important;
  }

  .xs\:ml-14 {
    margin-left: 3.5rem !important;
  }

  .xs\:mt-16 {
    margin-top: 4rem !important;
  }

  .xs\:mr-16 {
    margin-right: 4rem !important;
  }

  .xs\:mb-16 {
    margin-bottom: 4rem !important;
  }

  .xs\:ml-16 {
    margin-left: 4rem !important;
  }

  .xs\:mt-18 {
    margin-top: 4.5rem !important;
  }

  .xs\:mr-18 {
    margin-right: 4.5rem !important;
  }

  .xs\:mb-18 {
    margin-bottom: 4.5rem !important;
  }

  .xs\:ml-18 {
    margin-left: 4.5rem !important;
  }

  .xs\:mt-20 {
    margin-top: 5rem !important;
  }

  .xs\:mr-20 {
    margin-right: 5rem !important;
  }

  .xs\:mb-20 {
    margin-bottom: 5rem !important;
  }

  .xs\:ml-20 {
    margin-left: 5rem !important;
  }

  .xs\:mt-22 {
    margin-top: 5.5rem !important;
  }

  .xs\:mr-22 {
    margin-right: 5.5rem !important;
  }

  .xs\:mb-22 {
    margin-bottom: 5.5rem !important;
  }

  .xs\:ml-22 {
    margin-left: 5.5rem !important;
  }

  .xs\:mt-24 {
    margin-top: 6rem !important;
  }

  .xs\:mr-24 {
    margin-right: 6rem !important;
  }

  .xs\:mb-24 {
    margin-bottom: 6rem !important;
  }

  .xs\:ml-24 {
    margin-left: 6rem !important;
  }

  .xs\:mt-26 {
    margin-top: 6.5rem !important;
  }

  .xs\:mr-26 {
    margin-right: 6.5rem !important;
  }

  .xs\:mb-26 {
    margin-bottom: 6.5rem !important;
  }

  .xs\:ml-26 {
    margin-left: 6.5rem !important;
  }

  .xs\:mt-28 {
    margin-top: 7rem !important;
  }

  .xs\:mr-28 {
    margin-right: 7rem !important;
  }

  .xs\:mb-28 {
    margin-bottom: 7rem !important;
  }

  .xs\:ml-28 {
    margin-left: 7rem !important;
  }

  .xs\:mt-30 {
    margin-top: 7.5rem !important;
  }

  .xs\:mr-30 {
    margin-right: 7.5rem !important;
  }

  .xs\:mb-30 {
    margin-bottom: 7.5rem !important;
  }

  .xs\:ml-30 {
    margin-left: 7.5rem !important;
  }

  .xs\:mt-32 {
    margin-top: 8rem !important;
  }

  .xs\:mr-32 {
    margin-right: 8rem !important;
  }

  .xs\:mb-32 {
    margin-bottom: 8rem !important;
  }

  .xs\:ml-32 {
    margin-left: 8rem !important;
  }

  .xs\:mt-36 {
    margin-top: 9rem !important;
  }

  .xs\:mr-36 {
    margin-right: 9rem !important;
  }

  .xs\:mb-36 {
    margin-bottom: 9rem !important;
  }

  .xs\:ml-36 {
    margin-left: 9rem !important;
  }

  .xs\:mt-40 {
    margin-top: 10rem !important;
  }

  .xs\:mr-40 {
    margin-right: 10rem !important;
  }

  .xs\:mb-40 {
    margin-bottom: 10rem !important;
  }

  .xs\:ml-40 {
    margin-left: 10rem !important;
  }

  .xs\:mt-48 {
    margin-top: 12rem !important;
  }

  .xs\:mr-48 {
    margin-right: 12rem !important;
  }

  .xs\:mb-48 {
    margin-bottom: 12rem !important;
  }

  .xs\:ml-48 {
    margin-left: 12rem !important;
  }

  .xs\:mt-56 {
    margin-top: 14rem !important;
  }

  .xs\:mr-56 {
    margin-right: 14rem !important;
  }

  .xs\:mb-56 {
    margin-bottom: 14rem !important;
  }

  .xs\:ml-56 {
    margin-left: 14rem !important;
  }

  .xs\:mt-64 {
    margin-top: 16rem !important;
  }

  .xs\:mr-64 {
    margin-right: 16rem !important;
  }

  .xs\:mb-64 {
    margin-bottom: 16rem !important;
  }

  .xs\:ml-64 {
    margin-left: 16rem !important;
  }

  .xs\:mt-auto {
    margin-top: auto !important;
  }

  .xs\:mr-auto {
    margin-right: auto !important;
  }

  .xs\:mb-auto {
    margin-bottom: auto !important;
  }

  .xs\:ml-auto {
    margin-left: auto !important;
  }

  .xs\:mt-px {
    margin-top: 1px !important;
  }

  .xs\:mr-px {
    margin-right: 1px !important;
  }

  .xs\:mb-px {
    margin-bottom: 1px !important;
  }

  .xs\:ml-px {
    margin-left: 1px !important;
  }

  .xs\:mt-2px {
    margin-top: 2px !important;
  }

  .xs\:mr-2px {
    margin-right: 2px !important;
  }

  .xs\:mb-2px {
    margin-bottom: 2px !important;
  }

  .xs\:ml-2px {
    margin-left: 2px !important;
  }

  .xs\:-mt-1 {
    margin-top: -0.25rem !important;
  }

  .xs\:-mr-1 {
    margin-right: -0.25rem !important;
  }

  .xs\:-mb-1 {
    margin-bottom: -0.25rem !important;
  }

  .xs\:-ml-1 {
    margin-left: -0.25rem !important;
  }

  .xs\:-mt-2 {
    margin-top: -0.5rem !important;
  }

  .xs\:-mr-2 {
    margin-right: -0.5rem !important;
  }

  .xs\:-mb-2 {
    margin-bottom: -0.5rem !important;
  }

  .xs\:-ml-2 {
    margin-left: -0.5rem !important;
  }

  .xs\:-mt-3 {
    margin-top: -0.75rem !important;
  }

  .xs\:-mr-3 {
    margin-right: -0.75rem !important;
  }

  .xs\:-mb-3 {
    margin-bottom: -0.75rem !important;
  }

  .xs\:-ml-3 {
    margin-left: -0.75rem !important;
  }

  .xs\:-mt-4 {
    margin-top: -1rem !important;
  }

  .xs\:-mr-4 {
    margin-right: -1rem !important;
  }

  .xs\:-mb-4 {
    margin-bottom: -1rem !important;
  }

  .xs\:-ml-4 {
    margin-left: -1rem !important;
  }

  .xs\:-mt-5 {
    margin-top: -1.25rem !important;
  }

  .xs\:-mr-5 {
    margin-right: -1.25rem !important;
  }

  .xs\:-mb-5 {
    margin-bottom: -1.25rem !important;
  }

  .xs\:-ml-5 {
    margin-left: -1.25rem !important;
  }

  .xs\:-mt-6 {
    margin-top: -1.5rem !important;
  }

  .xs\:-mr-6 {
    margin-right: -1.5rem !important;
  }

  .xs\:-mb-6 {
    margin-bottom: -1.5rem !important;
  }

  .xs\:-ml-6 {
    margin-left: -1.5rem !important;
  }

  .xs\:-mt-8 {
    margin-top: -2rem !important;
  }

  .xs\:-mr-8 {
    margin-right: -2rem !important;
  }

  .xs\:-mb-8 {
    margin-bottom: -2rem !important;
  }

  .xs\:-ml-8 {
    margin-left: -2rem !important;
  }

  .xs\:-mt-10 {
    margin-top: -2.5rem !important;
  }

  .xs\:-mr-10 {
    margin-right: -2.5rem !important;
  }

  .xs\:-mb-10 {
    margin-bottom: -2.5rem !important;
  }

  .xs\:-ml-10 {
    margin-left: -2.5rem !important;
  }

  .xs\:-mt-12 {
    margin-top: -3rem !important;
  }

  .xs\:-mr-12 {
    margin-right: -3rem !important;
  }

  .xs\:-mb-12 {
    margin-bottom: -3rem !important;
  }

  .xs\:-ml-12 {
    margin-left: -3rem !important;
  }

  .xs\:-mt-14 {
    margin-top: -3.5rem !important;
  }

  .xs\:-mr-14 {
    margin-right: -3.5rem !important;
  }

  .xs\:-mb-14 {
    margin-bottom: -3.5rem !important;
  }

  .xs\:-ml-14 {
    margin-left: -3.5rem !important;
  }

  .xs\:-mt-16 {
    margin-top: -4rem !important;
  }

  .xs\:-mr-16 {
    margin-right: -4rem !important;
  }

  .xs\:-mb-16 {
    margin-bottom: -4rem !important;
  }

  .xs\:-ml-16 {
    margin-left: -4rem !important;
  }

  .xs\:-mt-18 {
    margin-top: -4.5rem !important;
  }

  .xs\:-mr-18 {
    margin-right: -4.5rem !important;
  }

  .xs\:-mb-18 {
    margin-bottom: -4.5rem !important;
  }

  .xs\:-ml-18 {
    margin-left: -4.5rem !important;
  }

  .xs\:-mt-20 {
    margin-top: -5rem !important;
  }

  .xs\:-mr-20 {
    margin-right: -5rem !important;
  }

  .xs\:-mb-20 {
    margin-bottom: -5rem !important;
  }

  .xs\:-ml-20 {
    margin-left: -5rem !important;
  }

  .xs\:-mt-22 {
    margin-top: -5.5rem !important;
  }

  .xs\:-mr-22 {
    margin-right: -5.5rem !important;
  }

  .xs\:-mb-22 {
    margin-bottom: -5.5rem !important;
  }

  .xs\:-ml-22 {
    margin-left: -5.5rem !important;
  }

  .xs\:-mt-24 {
    margin-top: -6rem !important;
  }

  .xs\:-mr-24 {
    margin-right: -6rem !important;
  }

  .xs\:-mb-24 {
    margin-bottom: -6rem !important;
  }

  .xs\:-ml-24 {
    margin-left: -6rem !important;
  }

  .xs\:-mt-26 {
    margin-top: -6.5rem !important;
  }

  .xs\:-mr-26 {
    margin-right: -6.5rem !important;
  }

  .xs\:-mb-26 {
    margin-bottom: -6.5rem !important;
  }

  .xs\:-ml-26 {
    margin-left: -6.5rem !important;
  }

  .xs\:-mt-28 {
    margin-top: -7rem !important;
  }

  .xs\:-mr-28 {
    margin-right: -7rem !important;
  }

  .xs\:-mb-28 {
    margin-bottom: -7rem !important;
  }

  .xs\:-ml-28 {
    margin-left: -7rem !important;
  }

  .xs\:-mt-30 {
    margin-top: -7.5rem !important;
  }

  .xs\:-mr-30 {
    margin-right: -7.5rem !important;
  }

  .xs\:-mb-30 {
    margin-bottom: -7.5rem !important;
  }

  .xs\:-ml-30 {
    margin-left: -7.5rem !important;
  }

  .xs\:-mt-32 {
    margin-top: -8rem !important;
  }

  .xs\:-mr-32 {
    margin-right: -8rem !important;
  }

  .xs\:-mb-32 {
    margin-bottom: -8rem !important;
  }

  .xs\:-ml-32 {
    margin-left: -8rem !important;
  }

  .xs\:-mt-36 {
    margin-top: -9rem !important;
  }

  .xs\:-mr-36 {
    margin-right: -9rem !important;
  }

  .xs\:-mb-36 {
    margin-bottom: -9rem !important;
  }

  .xs\:-ml-36 {
    margin-left: -9rem !important;
  }

  .xs\:-mt-40 {
    margin-top: -10rem !important;
  }

  .xs\:-mr-40 {
    margin-right: -10rem !important;
  }

  .xs\:-mb-40 {
    margin-bottom: -10rem !important;
  }

  .xs\:-ml-40 {
    margin-left: -10rem !important;
  }

  .xs\:-mt-48 {
    margin-top: -12rem !important;
  }

  .xs\:-mr-48 {
    margin-right: -12rem !important;
  }

  .xs\:-mb-48 {
    margin-bottom: -12rem !important;
  }

  .xs\:-ml-48 {
    margin-left: -12rem !important;
  }

  .xs\:-mt-56 {
    margin-top: -14rem !important;
  }

  .xs\:-mr-56 {
    margin-right: -14rem !important;
  }

  .xs\:-mb-56 {
    margin-bottom: -14rem !important;
  }

  .xs\:-ml-56 {
    margin-left: -14rem !important;
  }

  .xs\:-mt-64 {
    margin-top: -16rem !important;
  }

  .xs\:-mr-64 {
    margin-right: -16rem !important;
  }

  .xs\:-mb-64 {
    margin-bottom: -16rem !important;
  }

  .xs\:-ml-64 {
    margin-left: -16rem !important;
  }

  .xs\:-mt-px {
    margin-top: -1px !important;
  }

  .xs\:-mr-px {
    margin-right: -1px !important;
  }

  .xs\:-mb-px {
    margin-bottom: -1px !important;
  }

  .xs\:-ml-px {
    margin-left: -1px !important;
  }

  .xs\:-mt-2px {
    margin-top: -2px !important;
  }

  .xs\:-mr-2px {
    margin-right: -2px !important;
  }

  .xs\:-mb-2px {
    margin-bottom: -2px !important;
  }

  .xs\:-ml-2px {
    margin-left: -2px !important;
  }

  .xs\:max-h-0 {
    max-height: 0 !important;
  }

  .xs\:max-h-1 {
    max-height: 0.25rem !important;
  }

  .xs\:max-h-2 {
    max-height: 0.5rem !important;
  }

  .xs\:max-h-3 {
    max-height: 0.75rem !important;
  }

  .xs\:max-h-4 {
    max-height: 1rem !important;
  }

  .xs\:max-h-5 {
    max-height: 1.25rem !important;
  }

  .xs\:max-h-6 {
    max-height: 1.5rem !important;
  }

  .xs\:max-h-8 {
    max-height: 2rem !important;
  }

  .xs\:max-h-10 {
    max-height: 2.5rem !important;
  }

  .xs\:max-h-12 {
    max-height: 3rem !important;
  }

  .xs\:max-h-14 {
    max-height: 3.5rem !important;
  }

  .xs\:max-h-16 {
    max-height: 4rem !important;
  }

  .xs\:max-h-18 {
    max-height: 4.5rem !important;
  }

  .xs\:max-h-20 {
    max-height: 5rem !important;
  }

  .xs\:max-h-22 {
    max-height: 5.5rem !important;
  }

  .xs\:max-h-24 {
    max-height: 6rem !important;
  }

  .xs\:max-h-26 {
    max-height: 6.5rem !important;
  }

  .xs\:max-h-28 {
    max-height: 7rem !important;
  }

  .xs\:max-h-30 {
    max-height: 7.5rem !important;
  }

  .xs\:max-h-32 {
    max-height: 8rem !important;
  }

  .xs\:max-h-36 {
    max-height: 9rem !important;
  }

  .xs\:max-h-40 {
    max-height: 10rem !important;
  }

  .xs\:max-h-48 {
    max-height: 12rem !important;
  }

  .xs\:max-h-50 {
    max-height: 12.5rem !important;
  }

  .xs\:max-h-56 {
    max-height: 14rem !important;
  }

  .xs\:max-h-60 {
    max-height: 15rem !important;
  }

  .xs\:max-h-64 {
    max-height: 16rem !important;
  }

  .xs\:max-h-80 {
    max-height: 20rem !important;
  }

  .xs\:max-h-90 {
    max-height: 24rem !important;
  }

  .xs\:max-h-100 {
    max-height: 25rem !important;
  }

  .xs\:max-h-120 {
    max-height: 30rem !important;
  }

  .xs\:max-h-128 {
    max-height: 32rem !important;
  }

  .xs\:max-h-140 {
    max-height: 35rem !important;
  }

  .xs\:max-h-160 {
    max-height: 40rem !important;
  }

  .xs\:max-h-180 {
    max-height: 45rem !important;
  }

  .xs\:max-h-192 {
    max-height: 48rem !important;
  }

  .xs\:max-h-200 {
    max-height: 50rem !important;
  }

  .xs\:max-h-240 {
    max-height: 60rem !important;
  }

  .xs\:max-h-256 {
    max-height: 64rem !important;
  }

  .xs\:max-h-280 {
    max-height: 70rem !important;
  }

  .xs\:max-h-320 {
    max-height: 80rem !important;
  }

  .xs\:max-h-360 {
    max-height: 90rem !important;
  }

  .xs\:max-h-400 {
    max-height: 100rem !important;
  }

  .xs\:max-h-480 {
    max-height: 120rem !important;
  }

  .xs\:max-h-full {
    max-height: 100% !important;
  }

  .xs\:max-h-screen {
    max-height: 100vh !important;
  }

  .xs\:max-h-none {
    max-height: none !important;
  }

  .xs\:max-h-px {
    max-height: 1px !important;
  }

  .xs\:max-h-2px {
    max-height: 2px !important;
  }

  .xs\:max-h-1\/2 {
    max-height: 50% !important;
  }

  .xs\:max-h-1\/3 {
    max-height: 33.33333% !important;
  }

  .xs\:max-h-2\/3 {
    max-height: 66.66667% !important;
  }

  .xs\:max-h-1\/4 {
    max-height: 25% !important;
  }

  .xs\:max-h-2\/4 {
    max-height: 50% !important;
  }

  .xs\:max-h-3\/4 {
    max-height: 75% !important;
  }

  .xs\:max-h-1\/5 {
    max-height: 20% !important;
  }

  .xs\:max-h-2\/5 {
    max-height: 40% !important;
  }

  .xs\:max-h-3\/5 {
    max-height: 60% !important;
  }

  .xs\:max-h-4\/5 {
    max-height: 80% !important;
  }

  .xs\:max-h-1\/12 {
    max-height: 8.33333% !important;
  }

  .xs\:max-h-2\/12 {
    max-height: 16.66667% !important;
  }

  .xs\:max-h-3\/12 {
    max-height: 25% !important;
  }

  .xs\:max-h-4\/12 {
    max-height: 33.33333% !important;
  }

  .xs\:max-h-5\/12 {
    max-height: 41.66667% !important;
  }

  .xs\:max-h-6\/12 {
    max-height: 50% !important;
  }

  .xs\:max-h-7\/12 {
    max-height: 58.33333% !important;
  }

  .xs\:max-h-8\/12 {
    max-height: 66.66667% !important;
  }

  .xs\:max-h-9\/12 {
    max-height: 75% !important;
  }

  .xs\:max-h-10\/12 {
    max-height: 83.33333% !important;
  }

  .xs\:max-h-11\/12 {
    max-height: 91.66667% !important;
  }

  .xs\:max-w-0 {
    max-width: 0 !important;
  }

  .xs\:max-w-1 {
    max-width: 0.25rem !important;
  }

  .xs\:max-w-2 {
    max-width: 0.5rem !important;
  }

  .xs\:max-w-3 {
    max-width: 0.75rem !important;
  }

  .xs\:max-w-4 {
    max-width: 1rem !important;
  }

  .xs\:max-w-5 {
    max-width: 1.25rem !important;
  }

  .xs\:max-w-6 {
    max-width: 1.5rem !important;
  }

  .xs\:max-w-8 {
    max-width: 2rem !important;
  }

  .xs\:max-w-10 {
    max-width: 2.5rem !important;
  }

  .xs\:max-w-12 {
    max-width: 3rem !important;
  }

  .xs\:max-w-14 {
    max-width: 3.5rem !important;
  }

  .xs\:max-w-16 {
    max-width: 4rem !important;
  }

  .xs\:max-w-18 {
    max-width: 4.5rem !important;
  }

  .xs\:max-w-20 {
    max-width: 5rem !important;
  }

  .xs\:max-w-22 {
    max-width: 5.5rem !important;
  }

  .xs\:max-w-24 {
    max-width: 6rem !important;
  }

  .xs\:max-w-26 {
    max-width: 6.5rem !important;
  }

  .xs\:max-w-28 {
    max-width: 7rem !important;
  }

  .xs\:max-w-30 {
    max-width: 7.5rem !important;
  }

  .xs\:max-w-32 {
    max-width: 8rem !important;
  }

  .xs\:max-w-36 {
    max-width: 9rem !important;
  }

  .xs\:max-w-40 {
    max-width: 10rem !important;
  }

  .xs\:max-w-48 {
    max-width: 12rem !important;
  }

  .xs\:max-w-50 {
    max-width: 12.5rem !important;
  }

  .xs\:max-w-56 {
    max-width: 14rem !important;
  }

  .xs\:max-w-60 {
    max-width: 15rem !important;
  }

  .xs\:max-w-64 {
    max-width: 16rem !important;
  }

  .xs\:max-w-80 {
    max-width: 20rem !important;
  }

  .xs\:max-w-90 {
    max-width: 24rem !important;
  }

  .xs\:max-w-100 {
    max-width: 25rem !important;
  }

  .xs\:max-w-120 {
    max-width: 30rem !important;
  }

  .xs\:max-w-128 {
    max-width: 32rem !important;
  }

  .xs\:max-w-140 {
    max-width: 35rem !important;
  }

  .xs\:max-w-160 {
    max-width: 40rem !important;
  }

  .xs\:max-w-180 {
    max-width: 45rem !important;
  }

  .xs\:max-w-192 {
    max-width: 48rem !important;
  }

  .xs\:max-w-200 {
    max-width: 50rem !important;
  }

  .xs\:max-w-240 {
    max-width: 60rem !important;
  }

  .xs\:max-w-256 {
    max-width: 64rem !important;
  }

  .xs\:max-w-280 {
    max-width: 70rem !important;
  }

  .xs\:max-w-320 {
    max-width: 80rem !important;
  }

  .xs\:max-w-360 {
    max-width: 90rem !important;
  }

  .xs\:max-w-400 {
    max-width: 100rem !important;
  }

  .xs\:max-w-480 {
    max-width: 120rem !important;
  }

  .xs\:max-w-none {
    max-width: none !important;
  }

  .xs\:max-w-xs {
    max-width: 20rem !important;
  }

  .xs\:max-w-sm {
    max-width: 24rem !important;
  }

  .xs\:max-w-md {
    max-width: 28rem !important;
  }

  .xs\:max-w-lg {
    max-width: 32rem !important;
  }

  .xs\:max-w-xl {
    max-width: 36rem !important;
  }

  .xs\:max-w-2xl {
    max-width: 42rem !important;
  }

  .xs\:max-w-3xl {
    max-width: 48rem !important;
  }

  .xs\:max-w-4xl {
    max-width: 56rem !important;
  }

  .xs\:max-w-5xl {
    max-width: 64rem !important;
  }

  .xs\:max-w-6xl {
    max-width: 72rem !important;
  }

  .xs\:max-w-full {
    max-width: 100% !important;
  }

  .xs\:max-w-screen {
    max-width: 100vw !important;
  }

  .xs\:max-w-px {
    max-width: 1px !important;
  }

  .xs\:max-w-2px {
    max-width: 2px !important;
  }

  .xs\:max-w-1\/2 {
    max-width: 50% !important;
  }

  .xs\:max-w-1\/3 {
    max-width: 33.33333% !important;
  }

  .xs\:max-w-2\/3 {
    max-width: 66.66667% !important;
  }

  .xs\:max-w-1\/4 {
    max-width: 25% !important;
  }

  .xs\:max-w-2\/4 {
    max-width: 50% !important;
  }

  .xs\:max-w-3\/4 {
    max-width: 75% !important;
  }

  .xs\:max-w-1\/5 {
    max-width: 20% !important;
  }

  .xs\:max-w-2\/5 {
    max-width: 40% !important;
  }

  .xs\:max-w-3\/5 {
    max-width: 60% !important;
  }

  .xs\:max-w-4\/5 {
    max-width: 80% !important;
  }

  .xs\:max-w-1\/12 {
    max-width: 8.33333% !important;
  }

  .xs\:max-w-2\/12 {
    max-width: 16.66667% !important;
  }

  .xs\:max-w-3\/12 {
    max-width: 25% !important;
  }

  .xs\:max-w-4\/12 {
    max-width: 33.33333% !important;
  }

  .xs\:max-w-5\/12 {
    max-width: 41.66667% !important;
  }

  .xs\:max-w-6\/12 {
    max-width: 50% !important;
  }

  .xs\:max-w-7\/12 {
    max-width: 58.33333% !important;
  }

  .xs\:max-w-8\/12 {
    max-width: 66.66667% !important;
  }

  .xs\:max-w-9\/12 {
    max-width: 75% !important;
  }

  .xs\:max-w-10\/12 {
    max-width: 83.33333% !important;
  }

  .xs\:max-w-11\/12 {
    max-width: 91.66667% !important;
  }

  .xs\:min-h-0 {
    min-height: 0 !important;
  }

  .xs\:min-h-1 {
    min-height: 0.25rem !important;
  }

  .xs\:min-h-2 {
    min-height: 0.5rem !important;
  }

  .xs\:min-h-3 {
    min-height: 0.75rem !important;
  }

  .xs\:min-h-4 {
    min-height: 1rem !important;
  }

  .xs\:min-h-5 {
    min-height: 1.25rem !important;
  }

  .xs\:min-h-6 {
    min-height: 1.5rem !important;
  }

  .xs\:min-h-8 {
    min-height: 2rem !important;
  }

  .xs\:min-h-10 {
    min-height: 2.5rem !important;
  }

  .xs\:min-h-12 {
    min-height: 3rem !important;
  }

  .xs\:min-h-14 {
    min-height: 3.5rem !important;
  }

  .xs\:min-h-16 {
    min-height: 4rem !important;
  }

  .xs\:min-h-18 {
    min-height: 4.5rem !important;
  }

  .xs\:min-h-20 {
    min-height: 5rem !important;
  }

  .xs\:min-h-22 {
    min-height: 5.5rem !important;
  }

  .xs\:min-h-24 {
    min-height: 6rem !important;
  }

  .xs\:min-h-26 {
    min-height: 6.5rem !important;
  }

  .xs\:min-h-28 {
    min-height: 7rem !important;
  }

  .xs\:min-h-30 {
    min-height: 7.5rem !important;
  }

  .xs\:min-h-32 {
    min-height: 8rem !important;
  }

  .xs\:min-h-36 {
    min-height: 9rem !important;
  }

  .xs\:min-h-40 {
    min-height: 10rem !important;
  }

  .xs\:min-h-48 {
    min-height: 12rem !important;
  }

  .xs\:min-h-50 {
    min-height: 12.5rem !important;
  }

  .xs\:min-h-56 {
    min-height: 14rem !important;
  }

  .xs\:min-h-60 {
    min-height: 15rem !important;
  }

  .xs\:min-h-64 {
    min-height: 16rem !important;
  }

  .xs\:min-h-80 {
    min-height: 20rem !important;
  }

  .xs\:min-h-90 {
    min-height: 24rem !important;
  }

  .xs\:min-h-100 {
    min-height: 25rem !important;
  }

  .xs\:min-h-120 {
    min-height: 30rem !important;
  }

  .xs\:min-h-128 {
    min-height: 32rem !important;
  }

  .xs\:min-h-140 {
    min-height: 35rem !important;
  }

  .xs\:min-h-160 {
    min-height: 40rem !important;
  }

  .xs\:min-h-180 {
    min-height: 45rem !important;
  }

  .xs\:min-h-192 {
    min-height: 48rem !important;
  }

  .xs\:min-h-200 {
    min-height: 50rem !important;
  }

  .xs\:min-h-240 {
    min-height: 60rem !important;
  }

  .xs\:min-h-256 {
    min-height: 64rem !important;
  }

  .xs\:min-h-280 {
    min-height: 70rem !important;
  }

  .xs\:min-h-320 {
    min-height: 80rem !important;
  }

  .xs\:min-h-360 {
    min-height: 90rem !important;
  }

  .xs\:min-h-400 {
    min-height: 100rem !important;
  }

  .xs\:min-h-480 {
    min-height: 120rem !important;
  }

  .xs\:min-h-full {
    min-height: 100% !important;
  }

  .xs\:min-h-screen {
    min-height: 100vh !important;
  }

  .xs\:min-h-px {
    min-height: 1px !important;
  }

  .xs\:min-h-2px {
    min-height: 2px !important;
  }

  .xs\:min-h-1\/2 {
    min-height: 50% !important;
  }

  .xs\:min-h-1\/3 {
    min-height: 33.33333% !important;
  }

  .xs\:min-h-2\/3 {
    min-height: 66.66667% !important;
  }

  .xs\:min-h-1\/4 {
    min-height: 25% !important;
  }

  .xs\:min-h-2\/4 {
    min-height: 50% !important;
  }

  .xs\:min-h-3\/4 {
    min-height: 75% !important;
  }

  .xs\:min-h-1\/5 {
    min-height: 20% !important;
  }

  .xs\:min-h-2\/5 {
    min-height: 40% !important;
  }

  .xs\:min-h-3\/5 {
    min-height: 60% !important;
  }

  .xs\:min-h-4\/5 {
    min-height: 80% !important;
  }

  .xs\:min-h-1\/12 {
    min-height: 8.33333% !important;
  }

  .xs\:min-h-2\/12 {
    min-height: 16.66667% !important;
  }

  .xs\:min-h-3\/12 {
    min-height: 25% !important;
  }

  .xs\:min-h-4\/12 {
    min-height: 33.33333% !important;
  }

  .xs\:min-h-5\/12 {
    min-height: 41.66667% !important;
  }

  .xs\:min-h-6\/12 {
    min-height: 50% !important;
  }

  .xs\:min-h-7\/12 {
    min-height: 58.33333% !important;
  }

  .xs\:min-h-8\/12 {
    min-height: 66.66667% !important;
  }

  .xs\:min-h-9\/12 {
    min-height: 75% !important;
  }

  .xs\:min-h-10\/12 {
    min-height: 83.33333% !important;
  }

  .xs\:min-h-11\/12 {
    min-height: 91.66667% !important;
  }

  .xs\:min-w-0 {
    min-width: 0 !important;
  }

  .xs\:min-w-1 {
    min-width: 0.25rem !important;
  }

  .xs\:min-w-2 {
    min-width: 0.5rem !important;
  }

  .xs\:min-w-3 {
    min-width: 0.75rem !important;
  }

  .xs\:min-w-4 {
    min-width: 1rem !important;
  }

  .xs\:min-w-5 {
    min-width: 1.25rem !important;
  }

  .xs\:min-w-6 {
    min-width: 1.5rem !important;
  }

  .xs\:min-w-8 {
    min-width: 2rem !important;
  }

  .xs\:min-w-10 {
    min-width: 2.5rem !important;
  }

  .xs\:min-w-12 {
    min-width: 3rem !important;
  }

  .xs\:min-w-14 {
    min-width: 3.5rem !important;
  }

  .xs\:min-w-16 {
    min-width: 4rem !important;
  }

  .xs\:min-w-18 {
    min-width: 4.5rem !important;
  }

  .xs\:min-w-20 {
    min-width: 5rem !important;
  }

  .xs\:min-w-22 {
    min-width: 5.5rem !important;
  }

  .xs\:min-w-24 {
    min-width: 6rem !important;
  }

  .xs\:min-w-26 {
    min-width: 6.5rem !important;
  }

  .xs\:min-w-28 {
    min-width: 7rem !important;
  }

  .xs\:min-w-30 {
    min-width: 7.5rem !important;
  }

  .xs\:min-w-32 {
    min-width: 8rem !important;
  }

  .xs\:min-w-36 {
    min-width: 9rem !important;
  }

  .xs\:min-w-40 {
    min-width: 10rem !important;
  }

  .xs\:min-w-48 {
    min-width: 12rem !important;
  }

  .xs\:min-w-50 {
    min-width: 12.5rem !important;
  }

  .xs\:min-w-56 {
    min-width: 14rem !important;
  }

  .xs\:min-w-60 {
    min-width: 15rem !important;
  }

  .xs\:min-w-64 {
    min-width: 16rem !important;
  }

  .xs\:min-w-80 {
    min-width: 20rem !important;
  }

  .xs\:min-w-90 {
    min-width: 24rem !important;
  }

  .xs\:min-w-100 {
    min-width: 25rem !important;
  }

  .xs\:min-w-120 {
    min-width: 30rem !important;
  }

  .xs\:min-w-128 {
    min-width: 32rem !important;
  }

  .xs\:min-w-140 {
    min-width: 35rem !important;
  }

  .xs\:min-w-160 {
    min-width: 40rem !important;
  }

  .xs\:min-w-180 {
    min-width: 45rem !important;
  }

  .xs\:min-w-192 {
    min-width: 48rem !important;
  }

  .xs\:min-w-200 {
    min-width: 50rem !important;
  }

  .xs\:min-w-240 {
    min-width: 60rem !important;
  }

  .xs\:min-w-256 {
    min-width: 64rem !important;
  }

  .xs\:min-w-280 {
    min-width: 70rem !important;
  }

  .xs\:min-w-320 {
    min-width: 80rem !important;
  }

  .xs\:min-w-360 {
    min-width: 90rem !important;
  }

  .xs\:min-w-400 {
    min-width: 100rem !important;
  }

  .xs\:min-w-480 {
    min-width: 120rem !important;
  }

  .xs\:min-w-full {
    min-width: 100% !important;
  }

  .xs\:min-w-screen {
    min-width: 100vw !important;
  }

  .xs\:min-w-px {
    min-width: 1px !important;
  }

  .xs\:min-w-2px {
    min-width: 2px !important;
  }

  .xs\:min-w-1\/2 {
    min-width: 50% !important;
  }

  .xs\:min-w-1\/3 {
    min-width: 33.33333% !important;
  }

  .xs\:min-w-2\/3 {
    min-width: 66.66667% !important;
  }

  .xs\:min-w-1\/4 {
    min-width: 25% !important;
  }

  .xs\:min-w-2\/4 {
    min-width: 50% !important;
  }

  .xs\:min-w-3\/4 {
    min-width: 75% !important;
  }

  .xs\:min-w-1\/5 {
    min-width: 20% !important;
  }

  .xs\:min-w-2\/5 {
    min-width: 40% !important;
  }

  .xs\:min-w-3\/5 {
    min-width: 60% !important;
  }

  .xs\:min-w-4\/5 {
    min-width: 80% !important;
  }

  .xs\:min-w-1\/12 {
    min-width: 8.33333% !important;
  }

  .xs\:min-w-2\/12 {
    min-width: 16.66667% !important;
  }

  .xs\:min-w-3\/12 {
    min-width: 25% !important;
  }

  .xs\:min-w-4\/12 {
    min-width: 33.33333% !important;
  }

  .xs\:min-w-5\/12 {
    min-width: 41.66667% !important;
  }

  .xs\:min-w-6\/12 {
    min-width: 50% !important;
  }

  .xs\:min-w-7\/12 {
    min-width: 58.33333% !important;
  }

  .xs\:min-w-8\/12 {
    min-width: 66.66667% !important;
  }

  .xs\:min-w-9\/12 {
    min-width: 75% !important;
  }

  .xs\:min-w-10\/12 {
    min-width: 83.33333% !important;
  }

  .xs\:min-w-11\/12 {
    min-width: 91.66667% !important;
  }

  .xs\:object-contain {
    -o-object-fit: contain !important;
       object-fit: contain !important;
  }

  .xs\:object-cover {
    -o-object-fit: cover !important;
       object-fit: cover !important;
  }

  .xs\:object-fill {
    -o-object-fit: fill !important;
       object-fit: fill !important;
  }

  .xs\:object-none {
    -o-object-fit: none !important;
       object-fit: none !important;
  }

  .xs\:object-scale-down {
    -o-object-fit: scale-down !important;
       object-fit: scale-down !important;
  }

  .xs\:object-bottom {
    -o-object-position: bottom !important;
       object-position: bottom !important;
  }

  .xs\:object-center {
    -o-object-position: center !important;
       object-position: center !important;
  }

  .xs\:object-left {
    -o-object-position: left !important;
       object-position: left !important;
  }

  .xs\:object-left-bottom {
    -o-object-position: left bottom !important;
       object-position: left bottom !important;
  }

  .xs\:object-left-top {
    -o-object-position: left top !important;
       object-position: left top !important;
  }

  .xs\:object-right {
    -o-object-position: right !important;
       object-position: right !important;
  }

  .xs\:object-right-bottom {
    -o-object-position: right bottom !important;
       object-position: right bottom !important;
  }

  .xs\:object-right-top {
    -o-object-position: right top !important;
       object-position: right top !important;
  }

  .xs\:object-top {
    -o-object-position: top !important;
       object-position: top !important;
  }

  .xs\:opacity-0 {
    opacity: 0 !important;
  }

  .xs\:opacity-12 {
    opacity: 0.12 !important;
  }

  .xs\:opacity-25 {
    opacity: 0.25 !important;
  }

  .xs\:opacity-38 {
    opacity: 0.38 !important;
  }

  .xs\:opacity-50 {
    opacity: 0.5 !important;
  }

  .xs\:opacity-54 {
    opacity: 0.54 !important;
  }

  .xs\:opacity-70 {
    opacity: 0.70 !important;
  }

  .xs\:opacity-75 {
    opacity: 0.75 !important;
  }

  .xs\:opacity-84 {
    opacity: 0.84 !important;
  }

  .xs\:opacity-100 {
    opacity: 1 !important;
  }

  .xs\:hover\:opacity-0:hover {
    opacity: 0 !important;
  }

  .xs\:hover\:opacity-12:hover {
    opacity: 0.12 !important;
  }

  .xs\:hover\:opacity-25:hover {
    opacity: 0.25 !important;
  }

  .xs\:hover\:opacity-38:hover {
    opacity: 0.38 !important;
  }

  .xs\:hover\:opacity-50:hover {
    opacity: 0.5 !important;
  }

  .xs\:hover\:opacity-54:hover {
    opacity: 0.54 !important;
  }

  .xs\:hover\:opacity-70:hover {
    opacity: 0.70 !important;
  }

  .xs\:hover\:opacity-75:hover {
    opacity: 0.75 !important;
  }

  .xs\:hover\:opacity-84:hover {
    opacity: 0.84 !important;
  }

  .xs\:hover\:opacity-100:hover {
    opacity: 1 !important;
  }

  .xs\:focus\:opacity-0:focus {
    opacity: 0 !important;
  }

  .xs\:focus\:opacity-12:focus {
    opacity: 0.12 !important;
  }

  .xs\:focus\:opacity-25:focus {
    opacity: 0.25 !important;
  }

  .xs\:focus\:opacity-38:focus {
    opacity: 0.38 !important;
  }

  .xs\:focus\:opacity-50:focus {
    opacity: 0.5 !important;
  }

  .xs\:focus\:opacity-54:focus {
    opacity: 0.54 !important;
  }

  .xs\:focus\:opacity-70:focus {
    opacity: 0.70 !important;
  }

  .xs\:focus\:opacity-75:focus {
    opacity: 0.75 !important;
  }

  .xs\:focus\:opacity-84:focus {
    opacity: 0.84 !important;
  }

  .xs\:focus\:opacity-100:focus {
    opacity: 1 !important;
  }

  .xs\:outline-none {
    outline: 0 !important;
  }

  .xs\:focus\:outline-none:focus {
    outline: 0 !important;
  }

  .xs\:overflow-auto {
    overflow: auto !important;
  }

  .xs\:overflow-hidden {
    overflow: hidden !important;
  }

  .xs\:overflow-visible {
    overflow: visible !important;
  }

  .xs\:overflow-scroll {
    overflow: scroll !important;
  }

  .xs\:overflow-x-auto {
    overflow-x: auto !important;
  }

  .xs\:overflow-y-auto {
    overflow-y: auto !important;
  }

  .xs\:overflow-x-hidden {
    overflow-x: hidden !important;
  }

  .xs\:overflow-y-hidden {
    overflow-y: hidden !important;
  }

  .xs\:overflow-x-visible {
    overflow-x: visible !important;
  }

  .xs\:overflow-y-visible {
    overflow-y: visible !important;
  }

  .xs\:overflow-x-scroll {
    overflow-x: scroll !important;
  }

  .xs\:overflow-y-scroll {
    overflow-y: scroll !important;
  }

  .xs\:scrolling-touch {
    -webkit-overflow-scrolling: touch !important;
  }

  .xs\:scrolling-auto {
    -webkit-overflow-scrolling: auto !important;
  }

  .xs\:p-0 {
    padding: 0 !important;
  }

  .xs\:p-1 {
    padding: 0.25rem !important;
  }

  .xs\:p-2 {
    padding: 0.5rem !important;
  }

  .xs\:p-3 {
    padding: 0.75rem !important;
  }

  .xs\:p-4 {
    padding: 1rem !important;
  }

  .xs\:p-5 {
    padding: 1.25rem !important;
  }

  .xs\:p-6 {
    padding: 1.5rem !important;
  }

  .xs\:p-8 {
    padding: 2rem !important;
  }

  .xs\:p-10 {
    padding: 2.5rem !important;
  }

  .xs\:p-12 {
    padding: 3rem !important;
  }

  .xs\:p-14 {
    padding: 3.5rem !important;
  }

  .xs\:p-16 {
    padding: 4rem !important;
  }

  .xs\:p-18 {
    padding: 4.5rem !important;
  }

  .xs\:p-20 {
    padding: 5rem !important;
  }

  .xs\:p-22 {
    padding: 5.5rem !important;
  }

  .xs\:p-24 {
    padding: 6rem !important;
  }

  .xs\:p-26 {
    padding: 6.5rem !important;
  }

  .xs\:p-28 {
    padding: 7rem !important;
  }

  .xs\:p-30 {
    padding: 7.5rem !important;
  }

  .xs\:p-32 {
    padding: 8rem !important;
  }

  .xs\:p-36 {
    padding: 9rem !important;
  }

  .xs\:p-40 {
    padding: 10rem !important;
  }

  .xs\:p-48 {
    padding: 12rem !important;
  }

  .xs\:p-56 {
    padding: 14rem !important;
  }

  .xs\:p-64 {
    padding: 16rem !important;
  }

  .xs\:p-px {
    padding: 1px !important;
  }

  .xs\:p-2px {
    padding: 2px !important;
  }

  .xs\:py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .xs\:px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .xs\:py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .xs\:px-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }

  .xs\:py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .xs\:px-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .xs\:py-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .xs\:px-3 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }

  .xs\:py-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .xs\:px-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .xs\:py-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .xs\:px-5 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }

  .xs\:py-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .xs\:px-6 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .xs\:py-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .xs\:px-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .xs\:py-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .xs\:px-10 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .xs\:py-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .xs\:px-12 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .xs\:py-14 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .xs\:px-14 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }

  .xs\:py-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .xs\:px-16 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .xs\:py-18 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .xs\:px-18 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }

  .xs\:py-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .xs\:px-20 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .xs\:py-22 {
    padding-top: 5.5rem !important;
    padding-bottom: 5.5rem !important;
  }

  .xs\:px-22 {
    padding-left: 5.5rem !important;
    padding-right: 5.5rem !important;
  }

  .xs\:py-24 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .xs\:px-24 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }

  .xs\:py-26 {
    padding-top: 6.5rem !important;
    padding-bottom: 6.5rem !important;
  }

  .xs\:px-26 {
    padding-left: 6.5rem !important;
    padding-right: 6.5rem !important;
  }

  .xs\:py-28 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }

  .xs\:px-28 {
    padding-left: 7rem !important;
    padding-right: 7rem !important;
  }

  .xs\:py-30 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }

  .xs\:px-30 {
    padding-left: 7.5rem !important;
    padding-right: 7.5rem !important;
  }

  .xs\:py-32 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .xs\:px-32 {
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }

  .xs\:py-36 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }

  .xs\:px-36 {
    padding-left: 9rem !important;
    padding-right: 9rem !important;
  }

  .xs\:py-40 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .xs\:px-40 {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }

  .xs\:py-48 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }

  .xs\:px-48 {
    padding-left: 12rem !important;
    padding-right: 12rem !important;
  }

  .xs\:py-56 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }

  .xs\:px-56 {
    padding-left: 14rem !important;
    padding-right: 14rem !important;
  }

  .xs\:py-64 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }

  .xs\:px-64 {
    padding-left: 16rem !important;
    padding-right: 16rem !important;
  }

  .xs\:py-px {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }

  .xs\:px-px {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }

  .xs\:py-2px {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }

  .xs\:px-2px {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }

  .xs\:pt-0 {
    padding-top: 0 !important;
  }

  .xs\:pr-0 {
    padding-right: 0 !important;
  }

  .xs\:pb-0 {
    padding-bottom: 0 !important;
  }

  .xs\:pl-0 {
    padding-left: 0 !important;
  }

  .xs\:pt-1 {
    padding-top: 0.25rem !important;
  }

  .xs\:pr-1 {
    padding-right: 0.25rem !important;
  }

  .xs\:pb-1 {
    padding-bottom: 0.25rem !important;
  }

  .xs\:pl-1 {
    padding-left: 0.25rem !important;
  }

  .xs\:pt-2 {
    padding-top: 0.5rem !important;
  }

  .xs\:pr-2 {
    padding-right: 0.5rem !important;
  }

  .xs\:pb-2 {
    padding-bottom: 0.5rem !important;
  }

  .xs\:pl-2 {
    padding-left: 0.5rem !important;
  }

  .xs\:pt-3 {
    padding-top: 0.75rem !important;
  }

  .xs\:pr-3 {
    padding-right: 0.75rem !important;
  }

  .xs\:pb-3 {
    padding-bottom: 0.75rem !important;
  }

  .xs\:pl-3 {
    padding-left: 0.75rem !important;
  }

  .xs\:pt-4 {
    padding-top: 1rem !important;
  }

  .xs\:pr-4 {
    padding-right: 1rem !important;
  }

  .xs\:pb-4 {
    padding-bottom: 1rem !important;
  }

  .xs\:pl-4 {
    padding-left: 1rem !important;
  }

  .xs\:pt-5 {
    padding-top: 1.25rem !important;
  }

  .xs\:pr-5 {
    padding-right: 1.25rem !important;
  }

  .xs\:pb-5 {
    padding-bottom: 1.25rem !important;
  }

  .xs\:pl-5 {
    padding-left: 1.25rem !important;
  }

  .xs\:pt-6 {
    padding-top: 1.5rem !important;
  }

  .xs\:pr-6 {
    padding-right: 1.5rem !important;
  }

  .xs\:pb-6 {
    padding-bottom: 1.5rem !important;
  }

  .xs\:pl-6 {
    padding-left: 1.5rem !important;
  }

  .xs\:pt-8 {
    padding-top: 2rem !important;
  }

  .xs\:pr-8 {
    padding-right: 2rem !important;
  }

  .xs\:pb-8 {
    padding-bottom: 2rem !important;
  }

  .xs\:pl-8 {
    padding-left: 2rem !important;
  }

  .xs\:pt-10 {
    padding-top: 2.5rem !important;
  }

  .xs\:pr-10 {
    padding-right: 2.5rem !important;
  }

  .xs\:pb-10 {
    padding-bottom: 2.5rem !important;
  }

  .xs\:pl-10 {
    padding-left: 2.5rem !important;
  }

  .xs\:pt-12 {
    padding-top: 3rem !important;
  }

  .xs\:pr-12 {
    padding-right: 3rem !important;
  }

  .xs\:pb-12 {
    padding-bottom: 3rem !important;
  }

  .xs\:pl-12 {
    padding-left: 3rem !important;
  }

  .xs\:pt-14 {
    padding-top: 3.5rem !important;
  }

  .xs\:pr-14 {
    padding-right: 3.5rem !important;
  }

  .xs\:pb-14 {
    padding-bottom: 3.5rem !important;
  }

  .xs\:pl-14 {
    padding-left: 3.5rem !important;
  }

  .xs\:pt-16 {
    padding-top: 4rem !important;
  }

  .xs\:pr-16 {
    padding-right: 4rem !important;
  }

  .xs\:pb-16 {
    padding-bottom: 4rem !important;
  }

  .xs\:pl-16 {
    padding-left: 4rem !important;
  }

  .xs\:pt-18 {
    padding-top: 4.5rem !important;
  }

  .xs\:pr-18 {
    padding-right: 4.5rem !important;
  }

  .xs\:pb-18 {
    padding-bottom: 4.5rem !important;
  }

  .xs\:pl-18 {
    padding-left: 4.5rem !important;
  }

  .xs\:pt-20 {
    padding-top: 5rem !important;
  }

  .xs\:pr-20 {
    padding-right: 5rem !important;
  }

  .xs\:pb-20 {
    padding-bottom: 5rem !important;
  }

  .xs\:pl-20 {
    padding-left: 5rem !important;
  }

  .xs\:pt-22 {
    padding-top: 5.5rem !important;
  }

  .xs\:pr-22 {
    padding-right: 5.5rem !important;
  }

  .xs\:pb-22 {
    padding-bottom: 5.5rem !important;
  }

  .xs\:pl-22 {
    padding-left: 5.5rem !important;
  }

  .xs\:pt-24 {
    padding-top: 6rem !important;
  }

  .xs\:pr-24 {
    padding-right: 6rem !important;
  }

  .xs\:pb-24 {
    padding-bottom: 6rem !important;
  }

  .xs\:pl-24 {
    padding-left: 6rem !important;
  }

  .xs\:pt-26 {
    padding-top: 6.5rem !important;
  }

  .xs\:pr-26 {
    padding-right: 6.5rem !important;
  }

  .xs\:pb-26 {
    padding-bottom: 6.5rem !important;
  }

  .xs\:pl-26 {
    padding-left: 6.5rem !important;
  }

  .xs\:pt-28 {
    padding-top: 7rem !important;
  }

  .xs\:pr-28 {
    padding-right: 7rem !important;
  }

  .xs\:pb-28 {
    padding-bottom: 7rem !important;
  }

  .xs\:pl-28 {
    padding-left: 7rem !important;
  }

  .xs\:pt-30 {
    padding-top: 7.5rem !important;
  }

  .xs\:pr-30 {
    padding-right: 7.5rem !important;
  }

  .xs\:pb-30 {
    padding-bottom: 7.5rem !important;
  }

  .xs\:pl-30 {
    padding-left: 7.5rem !important;
  }

  .xs\:pt-32 {
    padding-top: 8rem !important;
  }

  .xs\:pr-32 {
    padding-right: 8rem !important;
  }

  .xs\:pb-32 {
    padding-bottom: 8rem !important;
  }

  .xs\:pl-32 {
    padding-left: 8rem !important;
  }

  .xs\:pt-36 {
    padding-top: 9rem !important;
  }

  .xs\:pr-36 {
    padding-right: 9rem !important;
  }

  .xs\:pb-36 {
    padding-bottom: 9rem !important;
  }

  .xs\:pl-36 {
    padding-left: 9rem !important;
  }

  .xs\:pt-40 {
    padding-top: 10rem !important;
  }

  .xs\:pr-40 {
    padding-right: 10rem !important;
  }

  .xs\:pb-40 {
    padding-bottom: 10rem !important;
  }

  .xs\:pl-40 {
    padding-left: 10rem !important;
  }

  .xs\:pt-48 {
    padding-top: 12rem !important;
  }

  .xs\:pr-48 {
    padding-right: 12rem !important;
  }

  .xs\:pb-48 {
    padding-bottom: 12rem !important;
  }

  .xs\:pl-48 {
    padding-left: 12rem !important;
  }

  .xs\:pt-56 {
    padding-top: 14rem !important;
  }

  .xs\:pr-56 {
    padding-right: 14rem !important;
  }

  .xs\:pb-56 {
    padding-bottom: 14rem !important;
  }

  .xs\:pl-56 {
    padding-left: 14rem !important;
  }

  .xs\:pt-64 {
    padding-top: 16rem !important;
  }

  .xs\:pr-64 {
    padding-right: 16rem !important;
  }

  .xs\:pb-64 {
    padding-bottom: 16rem !important;
  }

  .xs\:pl-64 {
    padding-left: 16rem !important;
  }

  .xs\:pt-px {
    padding-top: 1px !important;
  }

  .xs\:pr-px {
    padding-right: 1px !important;
  }

  .xs\:pb-px {
    padding-bottom: 1px !important;
  }

  .xs\:pl-px {
    padding-left: 1px !important;
  }

  .xs\:pt-2px {
    padding-top: 2px !important;
  }

  .xs\:pr-2px {
    padding-right: 2px !important;
  }

  .xs\:pb-2px {
    padding-bottom: 2px !important;
  }

  .xs\:pl-2px {
    padding-left: 2px !important;
  }

  .xs\:placeholder-opacity-0::-webkit-input-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .xs\:placeholder-opacity-0::-moz-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .xs\:placeholder-opacity-0::-ms-input-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .xs\:placeholder-opacity-0::placeholder {
    --placeholder-opacity: 0 !important;
  }

  .xs\:placeholder-opacity-12::-webkit-input-placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .xs\:placeholder-opacity-12::-moz-placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .xs\:placeholder-opacity-12::-ms-input-placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .xs\:placeholder-opacity-12::placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .xs\:placeholder-opacity-25::-webkit-input-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .xs\:placeholder-opacity-25::-moz-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .xs\:placeholder-opacity-25::-ms-input-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .xs\:placeholder-opacity-25::placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .xs\:placeholder-opacity-38::-webkit-input-placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .xs\:placeholder-opacity-38::-moz-placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .xs\:placeholder-opacity-38::-ms-input-placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .xs\:placeholder-opacity-38::placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .xs\:placeholder-opacity-50::-webkit-input-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .xs\:placeholder-opacity-50::-moz-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .xs\:placeholder-opacity-50::-ms-input-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .xs\:placeholder-opacity-50::placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .xs\:placeholder-opacity-54::-webkit-input-placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .xs\:placeholder-opacity-54::-moz-placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .xs\:placeholder-opacity-54::-ms-input-placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .xs\:placeholder-opacity-54::placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .xs\:placeholder-opacity-70::-webkit-input-placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .xs\:placeholder-opacity-70::-moz-placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .xs\:placeholder-opacity-70::-ms-input-placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .xs\:placeholder-opacity-70::placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .xs\:placeholder-opacity-75::-webkit-input-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .xs\:placeholder-opacity-75::-moz-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .xs\:placeholder-opacity-75::-ms-input-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .xs\:placeholder-opacity-75::placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .xs\:placeholder-opacity-84::-webkit-input-placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .xs\:placeholder-opacity-84::-moz-placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .xs\:placeholder-opacity-84::-ms-input-placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .xs\:placeholder-opacity-84::placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .xs\:placeholder-opacity-100::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .xs\:placeholder-opacity-100::-moz-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .xs\:placeholder-opacity-100::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .xs\:placeholder-opacity-100::placeholder {
    --placeholder-opacity: 1 !important;
  }

  .xs\:focus\:placeholder-opacity-0:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .xs\:focus\:placeholder-opacity-0:focus::-moz-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .xs\:focus\:placeholder-opacity-0:focus::-ms-input-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .xs\:focus\:placeholder-opacity-0:focus::placeholder {
    --placeholder-opacity: 0 !important;
  }

  .xs\:focus\:placeholder-opacity-12:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .xs\:focus\:placeholder-opacity-12:focus::-moz-placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .xs\:focus\:placeholder-opacity-12:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .xs\:focus\:placeholder-opacity-12:focus::placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .xs\:focus\:placeholder-opacity-25:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .xs\:focus\:placeholder-opacity-25:focus::-moz-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .xs\:focus\:placeholder-opacity-25:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .xs\:focus\:placeholder-opacity-25:focus::placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .xs\:focus\:placeholder-opacity-38:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .xs\:focus\:placeholder-opacity-38:focus::-moz-placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .xs\:focus\:placeholder-opacity-38:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .xs\:focus\:placeholder-opacity-38:focus::placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .xs\:focus\:placeholder-opacity-50:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .xs\:focus\:placeholder-opacity-50:focus::-moz-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .xs\:focus\:placeholder-opacity-50:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .xs\:focus\:placeholder-opacity-50:focus::placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .xs\:focus\:placeholder-opacity-54:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .xs\:focus\:placeholder-opacity-54:focus::-moz-placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .xs\:focus\:placeholder-opacity-54:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .xs\:focus\:placeholder-opacity-54:focus::placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .xs\:focus\:placeholder-opacity-70:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .xs\:focus\:placeholder-opacity-70:focus::-moz-placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .xs\:focus\:placeholder-opacity-70:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .xs\:focus\:placeholder-opacity-70:focus::placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .xs\:focus\:placeholder-opacity-75:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .xs\:focus\:placeholder-opacity-75:focus::-moz-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .xs\:focus\:placeholder-opacity-75:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .xs\:focus\:placeholder-opacity-75:focus::placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .xs\:focus\:placeholder-opacity-84:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .xs\:focus\:placeholder-opacity-84:focus::-moz-placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .xs\:focus\:placeholder-opacity-84:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .xs\:focus\:placeholder-opacity-84:focus::placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .xs\:focus\:placeholder-opacity-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .xs\:focus\:placeholder-opacity-100:focus::-moz-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .xs\:focus\:placeholder-opacity-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .xs\:focus\:placeholder-opacity-100:focus::placeholder {
    --placeholder-opacity: 1 !important;
  }

  .xs\:pointer-events-none {
    pointer-events: none !important;
  }

  .xs\:pointer-events-auto {
    pointer-events: auto !important;
  }

  .xs\:static {
    position: static !important;
  }

  .xs\:fixed {
    position: fixed !important;
  }

  .xs\:absolute {
    position: absolute !important;
  }

  .xs\:relative {
    position: relative !important;
  }

  .xs\:sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }

  .xs\:inset-0 {
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
  }

  .xs\:inset-auto {
    top: auto !important;
    right: auto !important;
    bottom: auto !important;
    left: auto !important;
  }

  .xs\:inset-y-0 {
    top: 0 !important;
    bottom: 0 !important;
  }

  .xs\:inset-x-0 {
    right: 0 !important;
    left: 0 !important;
  }

  .xs\:inset-y-auto {
    top: auto !important;
    bottom: auto !important;
  }

  .xs\:inset-x-auto {
    right: auto !important;
    left: auto !important;
  }

  .xs\:top-0 {
    top: 0 !important;
  }

  .xs\:right-0 {
    right: 0 !important;
  }

  .xs\:bottom-0 {
    bottom: 0 !important;
  }

  .xs\:left-0 {
    left: 0 !important;
  }

  .xs\:top-auto {
    top: auto !important;
  }

  .xs\:right-auto {
    right: auto !important;
  }

  .xs\:bottom-auto {
    bottom: auto !important;
  }

  .xs\:left-auto {
    left: auto !important;
  }

  .xs\:shadow-xs {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05) !important;
  }

  .xs\:shadow-sm {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  }

  .xs\:shadow {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .xs\:shadow-md {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  }

  .xs\:shadow-lg {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  }

  .xs\:shadow-xl {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
  }

  .xs\:shadow-2xl {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
  }

  .xs\:shadow-inner {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .xs\:ring {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }

  .xs\:shadow-none {
    box-shadow: none !important;
  }

  .xs\:shadow-solid {
    box-shadow: 0 0 0 2px currentColor !important;
  }

  .xs\:hover\:shadow-xs:hover {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05) !important;
  }

  .xs\:hover\:shadow-sm:hover {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  }

  .xs\:hover\:shadow:hover {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .xs\:hover\:shadow-md:hover {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  }

  .xs\:hover\:shadow-lg:hover {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  }

  .xs\:hover\:shadow-xl:hover {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
  }

  .xs\:hover\:shadow-2xl:hover {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
  }

  .xs\:hover\:shadow-inner:hover {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .xs\:hover\:ring:hover {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }

  .xs\:hover\:shadow-none:hover {
    box-shadow: none !important;
  }

  .xs\:hover\:shadow-solid:hover {
    box-shadow: 0 0 0 2px currentColor !important;
  }

  .xs\:focus\:shadow-xs:focus {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05) !important;
  }

  .xs\:focus\:shadow-sm:focus {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  }

  .xs\:focus\:shadow:focus {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .xs\:focus\:shadow-md:focus {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  }

  .xs\:focus\:shadow-lg:focus {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  }

  .xs\:focus\:shadow-xl:focus {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
  }

  .xs\:focus\:shadow-2xl:focus {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
  }

  .xs\:focus\:shadow-inner:focus {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .xs\:focus\:ring:focus {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }

  .xs\:focus\:shadow-none:focus {
    box-shadow: none !important;
  }

  .xs\:focus\:shadow-solid:focus {
    box-shadow: 0 0 0 2px currentColor !important;
  }

  .xs\:fill-current {
    fill: currentColor !important;
  }

  .xs\:stroke-current {
    stroke: currentColor !important;
  }

  .xs\:stroke-0 {
    stroke-width: 0 !important;
  }

  .xs\:stroke-1 {
    stroke-width: 1 !important;
  }

  .xs\:stroke-2 {
    stroke-width: 2 !important;
  }

  .xs\:table-auto {
    table-layout: auto !important;
  }

  .xs\:table-fixed {
    table-layout: fixed !important;
  }

  .xs\:text-left {
    text-align: left !important;
  }

  .xs\:text-center {
    text-align: center !important;
  }

  .xs\:text-right {
    text-align: right !important;
  }

  .xs\:text-justify {
    text-align: justify !important;
  }

  .xs\:text-opacity-0 {
    --text-opacity: 0 !important;
  }

  .xs\:text-opacity-12 {
    --text-opacity: 0.12 !important;
  }

  .xs\:text-opacity-25 {
    --text-opacity: 0.25 !important;
  }

  .xs\:text-opacity-38 {
    --text-opacity: 0.38 !important;
  }

  .xs\:text-opacity-50 {
    --text-opacity: 0.5 !important;
  }

  .xs\:text-opacity-54 {
    --text-opacity: 0.54 !important;
  }

  .xs\:text-opacity-70 {
    --text-opacity: 0.70 !important;
  }

  .xs\:text-opacity-75 {
    --text-opacity: 0.75 !important;
  }

  .xs\:text-opacity-84 {
    --text-opacity: 0.84 !important;
  }

  .xs\:text-opacity-100 {
    --text-opacity: 1 !important;
  }

  .xs\:hover\:text-opacity-0:hover {
    --text-opacity: 0 !important;
  }

  .xs\:hover\:text-opacity-12:hover {
    --text-opacity: 0.12 !important;
  }

  .xs\:hover\:text-opacity-25:hover {
    --text-opacity: 0.25 !important;
  }

  .xs\:hover\:text-opacity-38:hover {
    --text-opacity: 0.38 !important;
  }

  .xs\:hover\:text-opacity-50:hover {
    --text-opacity: 0.5 !important;
  }

  .xs\:hover\:text-opacity-54:hover {
    --text-opacity: 0.54 !important;
  }

  .xs\:hover\:text-opacity-70:hover {
    --text-opacity: 0.70 !important;
  }

  .xs\:hover\:text-opacity-75:hover {
    --text-opacity: 0.75 !important;
  }

  .xs\:hover\:text-opacity-84:hover {
    --text-opacity: 0.84 !important;
  }

  .xs\:hover\:text-opacity-100:hover {
    --text-opacity: 1 !important;
  }

  .xs\:focus\:text-opacity-0:focus {
    --text-opacity: 0 !important;
  }

  .xs\:focus\:text-opacity-12:focus {
    --text-opacity: 0.12 !important;
  }

  .xs\:focus\:text-opacity-25:focus {
    --text-opacity: 0.25 !important;
  }

  .xs\:focus\:text-opacity-38:focus {
    --text-opacity: 0.38 !important;
  }

  .xs\:focus\:text-opacity-50:focus {
    --text-opacity: 0.5 !important;
  }

  .xs\:focus\:text-opacity-54:focus {
    --text-opacity: 0.54 !important;
  }

  .xs\:focus\:text-opacity-70:focus {
    --text-opacity: 0.70 !important;
  }

  .xs\:focus\:text-opacity-75:focus {
    --text-opacity: 0.75 !important;
  }

  .xs\:focus\:text-opacity-84:focus {
    --text-opacity: 0.84 !important;
  }

  .xs\:focus\:text-opacity-100:focus {
    --text-opacity: 1 !important;
  }

  .xs\:italic {
    font-style: italic !important;
  }

  .xs\:not-italic {
    font-style: normal !important;
  }

  .xs\:uppercase {
    text-transform: uppercase !important;
  }

  .xs\:lowercase {
    text-transform: lowercase !important;
  }

  .xs\:capitalize {
    text-transform: capitalize !important;
  }

  .xs\:normal-case {
    text-transform: none !important;
  }

  .xs\:underline {
    text-decoration: underline !important;
  }

  .xs\:line-through {
    text-decoration: line-through !important;
  }

  .xs\:no-underline {
    text-decoration: none !important;
  }

  .xs\:hover\:underline:hover {
    text-decoration: underline !important;
  }

  .xs\:hover\:line-through:hover {
    text-decoration: line-through !important;
  }

  .xs\:hover\:no-underline:hover {
    text-decoration: none !important;
  }

  .xs\:focus\:underline:focus {
    text-decoration: underline !important;
  }

  .xs\:focus\:line-through:focus {
    text-decoration: line-through !important;
  }

  .xs\:focus\:no-underline:focus {
    text-decoration: none !important;
  }

  .xs\:tracking-tighter {
    letter-spacing: -0.05em !important;
  }

  .xs\:tracking-tight {
    letter-spacing: -0.025em !important;
  }

  .xs\:tracking-normal {
    letter-spacing: 0 !important;
  }

  .xs\:tracking-wide {
    letter-spacing: 0.025em !important;
  }

  .xs\:tracking-wider {
    letter-spacing: 0.05em !important;
  }

  .xs\:tracking-widest {
    letter-spacing: 0.1em !important;
  }

  .xs\:select-none {
    -webkit-user-select: none !important;
       -moz-user-select: none !important;
        -ms-user-select: none !important;
            user-select: none !important;
  }

  .xs\:select-text {
    -webkit-user-select: text !important;
       -moz-user-select: text !important;
        -ms-user-select: text !important;
            user-select: text !important;
  }

  .xs\:select-all {
    -webkit-user-select: all !important;
       -moz-user-select: all !important;
        -ms-user-select: all !important;
            user-select: all !important;
  }

  .xs\:select-auto {
    -webkit-user-select: auto !important;
       -moz-user-select: auto !important;
        -ms-user-select: auto !important;
            user-select: auto !important;
  }

  .xs\:align-baseline {
    vertical-align: baseline !important;
  }

  .xs\:align-top {
    vertical-align: top !important;
  }

  .xs\:align-middle {
    vertical-align: middle !important;
  }

  .xs\:align-bottom {
    vertical-align: bottom !important;
  }

  .xs\:align-text-top {
    vertical-align: text-top !important;
  }

  .xs\:align-text-bottom {
    vertical-align: text-bottom !important;
  }

  .xs\:visible {
    visibility: visible !important;
  }

  .xs\:invisible {
    visibility: hidden !important;
  }

  .xs\:whitespace-normal {
    white-space: normal !important;
  }

  .xs\:whitespace-nowrap {
    white-space: nowrap !important;
  }

  .xs\:whitespace-pre {
    white-space: pre !important;
  }

  .xs\:whitespace-pre-line {
    white-space: pre-line !important;
  }

  .xs\:whitespace-pre-wrap {
    white-space: pre-wrap !important;
  }

  .xs\:break-normal {
    overflow-wrap: normal !important;
    word-break: normal !important;
  }

  .xs\:break-words {
    overflow-wrap: break-word !important;
  }

  .xs\:break-all {
    word-break: break-all !important;
  }

  .xs\:truncate {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
  }

  .xs\:w-0 {
    width: 0 !important;
  }

  .xs\:w-1 {
    width: 0.25rem !important;
  }

  .xs\:w-2 {
    width: 0.5rem !important;
  }

  .xs\:w-3 {
    width: 0.75rem !important;
  }

  .xs\:w-4 {
    width: 1rem !important;
  }

  .xs\:w-5 {
    width: 1.25rem !important;
  }

  .xs\:w-6 {
    width: 1.5rem !important;
  }

  .xs\:w-8 {
    width: 2rem !important;
  }

  .xs\:w-10 {
    width: 2.5rem !important;
  }

  .xs\:w-12 {
    width: 3rem !important;
  }

  .xs\:w-14 {
    width: 3.5rem !important;
  }

  .xs\:w-16 {
    width: 4rem !important;
  }

  .xs\:w-18 {
    width: 4.5rem !important;
  }

  .xs\:w-20 {
    width: 5rem !important;
  }

  .xs\:w-22 {
    width: 5.5rem !important;
  }

  .xs\:w-24 {
    width: 6rem !important;
  }

  .xs\:w-26 {
    width: 6.5rem !important;
  }

  .xs\:w-28 {
    width: 7rem !important;
  }

  .xs\:w-30 {
    width: 7.5rem !important;
  }

  .xs\:w-32 {
    width: 8rem !important;
  }

  .xs\:w-36 {
    width: 9rem !important;
  }

  .xs\:w-40 {
    width: 10rem !important;
  }

  .xs\:w-48 {
    width: 12rem !important;
  }

  .xs\:w-50 {
    width: 12.5rem !important;
  }

  .xs\:w-56 {
    width: 14rem !important;
  }

  .xs\:w-60 {
    width: 15rem !important;
  }

  .xs\:w-64 {
    width: 16rem !important;
  }

  .xs\:w-80 {
    width: 20rem !important;
  }

  .xs\:w-90 {
    width: 24rem !important;
  }

  .xs\:w-100 {
    width: 25rem !important;
  }

  .xs\:w-120 {
    width: 30rem !important;
  }

  .xs\:w-128 {
    width: 32rem !important;
  }

  .xs\:w-140 {
    width: 35rem !important;
  }

  .xs\:w-160 {
    width: 40rem !important;
  }

  .xs\:w-180 {
    width: 45rem !important;
  }

  .xs\:w-192 {
    width: 48rem !important;
  }

  .xs\:w-200 {
    width: 50rem !important;
  }

  .xs\:w-240 {
    width: 60rem !important;
  }

  .xs\:w-256 {
    width: 64rem !important;
  }

  .xs\:w-280 {
    width: 70rem !important;
  }

  .xs\:w-320 {
    width: 80rem !important;
  }

  .xs\:w-360 {
    width: 90rem !important;
  }

  .xs\:w-400 {
    width: 100rem !important;
  }

  .xs\:w-480 {
    width: 120rem !important;
  }

  .xs\:w-auto {
    width: auto !important;
  }

  .xs\:w-px {
    width: 1px !important;
  }

  .xs\:w-2px {
    width: 2px !important;
  }

  .xs\:w-1\/2 {
    width: 50% !important;
  }

  .xs\:w-1\/3 {
    width: 33.33333% !important;
  }

  .xs\:w-2\/3 {
    width: 66.66667% !important;
  }

  .xs\:w-1\/4 {
    width: 25% !important;
  }

  .xs\:w-2\/4 {
    width: 50% !important;
  }

  .xs\:w-3\/4 {
    width: 75% !important;
  }

  .xs\:w-1\/5 {
    width: 20% !important;
  }

  .xs\:w-2\/5 {
    width: 40% !important;
  }

  .xs\:w-3\/5 {
    width: 60% !important;
  }

  .xs\:w-4\/5 {
    width: 80% !important;
  }

  .xs\:w-1\/6 {
    width: 16.666667% !important;
  }

  .xs\:w-2\/6 {
    width: 33.333333% !important;
  }

  .xs\:w-3\/6 {
    width: 50% !important;
  }

  .xs\:w-4\/6 {
    width: 66.666667% !important;
  }

  .xs\:w-5\/6 {
    width: 83.333333% !important;
  }

  .xs\:w-1\/12 {
    width: 8.33333% !important;
  }

  .xs\:w-2\/12 {
    width: 16.66667% !important;
  }

  .xs\:w-3\/12 {
    width: 25% !important;
  }

  .xs\:w-4\/12 {
    width: 33.33333% !important;
  }

  .xs\:w-5\/12 {
    width: 41.66667% !important;
  }

  .xs\:w-6\/12 {
    width: 50% !important;
  }

  .xs\:w-7\/12 {
    width: 58.33333% !important;
  }

  .xs\:w-8\/12 {
    width: 66.66667% !important;
  }

  .xs\:w-9\/12 {
    width: 75% !important;
  }

  .xs\:w-10\/12 {
    width: 83.33333% !important;
  }

  .xs\:w-11\/12 {
    width: 91.66667% !important;
  }

  .xs\:w-full {
    width: 100% !important;
  }

  .xs\:w-screen {
    width: 100vw !important;
  }

  .xs\:z-0 {
    z-index: 0 !important;
  }

  .xs\:z-10 {
    z-index: 10 !important;
  }

  .xs\:z-20 {
    z-index: 20 !important;
  }

  .xs\:z-30 {
    z-index: 30 !important;
  }

  .xs\:z-40 {
    z-index: 40 !important;
  }

  .xs\:z-50 {
    z-index: 50 !important;
  }

  .xs\:z-60 {
    z-index: 60 !important;
  }

  .xs\:z-70 {
    z-index: 70 !important;
  }

  .xs\:z-80 {
    z-index: 80 !important;
  }

  .xs\:z-90 {
    z-index: 90 !important;
  }

  .xs\:z-99 {
    z-index: 99 !important;
  }

  .xs\:z-999 {
    z-index: 999 !important;
  }

  .xs\:z-9999 {
    z-index: 9999 !important;
  }

  .xs\:z-99999 {
    z-index: 99999 !important;
  }

  .xs\:z-auto {
    z-index: auto !important;
  }

  .xs\:-z-1 {
    z-index: -1 !important;
  }

  .xs\:gap-0 {
    grid-gap: 0 !important;
    gap: 0 !important;
  }

  .xs\:gap-1 {
    grid-gap: 0.25rem !important;
    gap: 0.25rem !important;
  }

  .xs\:gap-2 {
    grid-gap: 0.5rem !important;
    gap: 0.5rem !important;
  }

  .xs\:gap-3 {
    grid-gap: 0.75rem !important;
    gap: 0.75rem !important;
  }

  .xs\:gap-4 {
    grid-gap: 1rem !important;
    gap: 1rem !important;
  }

  .xs\:gap-5 {
    grid-gap: 1.25rem !important;
    gap: 1.25rem !important;
  }

  .xs\:gap-6 {
    grid-gap: 1.5rem !important;
    gap: 1.5rem !important;
  }

  .xs\:gap-8 {
    grid-gap: 2rem !important;
    gap: 2rem !important;
  }

  .xs\:gap-10 {
    grid-gap: 2.5rem !important;
    gap: 2.5rem !important;
  }

  .xs\:gap-12 {
    grid-gap: 3rem !important;
    gap: 3rem !important;
  }

  .xs\:gap-14 {
    grid-gap: 3.5rem !important;
    gap: 3.5rem !important;
  }

  .xs\:gap-16 {
    grid-gap: 4rem !important;
    gap: 4rem !important;
  }

  .xs\:gap-18 {
    grid-gap: 4.5rem !important;
    gap: 4.5rem !important;
  }

  .xs\:gap-20 {
    grid-gap: 5rem !important;
    gap: 5rem !important;
  }

  .xs\:gap-22 {
    grid-gap: 5.5rem !important;
    gap: 5.5rem !important;
  }

  .xs\:gap-24 {
    grid-gap: 6rem !important;
    gap: 6rem !important;
  }

  .xs\:gap-26 {
    grid-gap: 6.5rem !important;
    gap: 6.5rem !important;
  }

  .xs\:gap-28 {
    grid-gap: 7rem !important;
    gap: 7rem !important;
  }

  .xs\:gap-30 {
    grid-gap: 7.5rem !important;
    gap: 7.5rem !important;
  }

  .xs\:gap-32 {
    grid-gap: 8rem !important;
    gap: 8rem !important;
  }

  .xs\:gap-36 {
    grid-gap: 9rem !important;
    gap: 9rem !important;
  }

  .xs\:gap-40 {
    grid-gap: 10rem !important;
    gap: 10rem !important;
  }

  .xs\:gap-48 {
    grid-gap: 12rem !important;
    gap: 12rem !important;
  }

  .xs\:gap-56 {
    grid-gap: 14rem !important;
    gap: 14rem !important;
  }

  .xs\:gap-64 {
    grid-gap: 16rem !important;
    gap: 16rem !important;
  }

  .xs\:gap-px {
    grid-gap: 1px !important;
    gap: 1px !important;
  }

  .xs\:gap-2px {
    grid-gap: 2px !important;
    gap: 2px !important;
  }

  .xs\:gap-x-0 {
    grid-column-gap: 0 !important;
    -moz-column-gap: 0 !important;
         column-gap: 0 !important;
  }

  .xs\:gap-x-1 {
    grid-column-gap: 0.25rem !important;
    -moz-column-gap: 0.25rem !important;
         column-gap: 0.25rem !important;
  }

  .xs\:gap-x-2 {
    grid-column-gap: 0.5rem !important;
    -moz-column-gap: 0.5rem !important;
         column-gap: 0.5rem !important;
  }

  .xs\:gap-x-3 {
    grid-column-gap: 0.75rem !important;
    -moz-column-gap: 0.75rem !important;
         column-gap: 0.75rem !important;
  }

  .xs\:gap-x-4 {
    grid-column-gap: 1rem !important;
    -moz-column-gap: 1rem !important;
         column-gap: 1rem !important;
  }

  .xs\:gap-x-5 {
    grid-column-gap: 1.25rem !important;
    -moz-column-gap: 1.25rem !important;
         column-gap: 1.25rem !important;
  }

  .xs\:gap-x-6 {
    grid-column-gap: 1.5rem !important;
    -moz-column-gap: 1.5rem !important;
         column-gap: 1.5rem !important;
  }

  .xs\:gap-x-8 {
    grid-column-gap: 2rem !important;
    -moz-column-gap: 2rem !important;
         column-gap: 2rem !important;
  }

  .xs\:gap-x-10 {
    grid-column-gap: 2.5rem !important;
    -moz-column-gap: 2.5rem !important;
         column-gap: 2.5rem !important;
  }

  .xs\:gap-x-12 {
    grid-column-gap: 3rem !important;
    -moz-column-gap: 3rem !important;
         column-gap: 3rem !important;
  }

  .xs\:gap-x-14 {
    grid-column-gap: 3.5rem !important;
    -moz-column-gap: 3.5rem !important;
         column-gap: 3.5rem !important;
  }

  .xs\:gap-x-16 {
    grid-column-gap: 4rem !important;
    -moz-column-gap: 4rem !important;
         column-gap: 4rem !important;
  }

  .xs\:gap-x-18 {
    grid-column-gap: 4.5rem !important;
    -moz-column-gap: 4.5rem !important;
         column-gap: 4.5rem !important;
  }

  .xs\:gap-x-20 {
    grid-column-gap: 5rem !important;
    -moz-column-gap: 5rem !important;
         column-gap: 5rem !important;
  }

  .xs\:gap-x-22 {
    grid-column-gap: 5.5rem !important;
    -moz-column-gap: 5.5rem !important;
         column-gap: 5.5rem !important;
  }

  .xs\:gap-x-24 {
    grid-column-gap: 6rem !important;
    -moz-column-gap: 6rem !important;
         column-gap: 6rem !important;
  }

  .xs\:gap-x-26 {
    grid-column-gap: 6.5rem !important;
    -moz-column-gap: 6.5rem !important;
         column-gap: 6.5rem !important;
  }

  .xs\:gap-x-28 {
    grid-column-gap: 7rem !important;
    -moz-column-gap: 7rem !important;
         column-gap: 7rem !important;
  }

  .xs\:gap-x-30 {
    grid-column-gap: 7.5rem !important;
    -moz-column-gap: 7.5rem !important;
         column-gap: 7.5rem !important;
  }

  .xs\:gap-x-32 {
    grid-column-gap: 8rem !important;
    -moz-column-gap: 8rem !important;
         column-gap: 8rem !important;
  }

  .xs\:gap-x-36 {
    grid-column-gap: 9rem !important;
    -moz-column-gap: 9rem !important;
         column-gap: 9rem !important;
  }

  .xs\:gap-x-40 {
    grid-column-gap: 10rem !important;
    -moz-column-gap: 10rem !important;
         column-gap: 10rem !important;
  }

  .xs\:gap-x-48 {
    grid-column-gap: 12rem !important;
    -moz-column-gap: 12rem !important;
         column-gap: 12rem !important;
  }

  .xs\:gap-x-56 {
    grid-column-gap: 14rem !important;
    -moz-column-gap: 14rem !important;
         column-gap: 14rem !important;
  }

  .xs\:gap-x-64 {
    grid-column-gap: 16rem !important;
    -moz-column-gap: 16rem !important;
         column-gap: 16rem !important;
  }

  .xs\:gap-x-px {
    grid-column-gap: 1px !important;
    -moz-column-gap: 1px !important;
         column-gap: 1px !important;
  }

  .xs\:gap-x-2px {
    grid-column-gap: 2px !important;
    -moz-column-gap: 2px !important;
         column-gap: 2px !important;
  }

  .xs\:gap-y-0 {
    grid-row-gap: 0 !important;
    row-gap: 0 !important;
  }

  .xs\:gap-y-1 {
    grid-row-gap: 0.25rem !important;
    row-gap: 0.25rem !important;
  }

  .xs\:gap-y-2 {
    grid-row-gap: 0.5rem !important;
    row-gap: 0.5rem !important;
  }

  .xs\:gap-y-3 {
    grid-row-gap: 0.75rem !important;
    row-gap: 0.75rem !important;
  }

  .xs\:gap-y-4 {
    grid-row-gap: 1rem !important;
    row-gap: 1rem !important;
  }

  .xs\:gap-y-5 {
    grid-row-gap: 1.25rem !important;
    row-gap: 1.25rem !important;
  }

  .xs\:gap-y-6 {
    grid-row-gap: 1.5rem !important;
    row-gap: 1.5rem !important;
  }

  .xs\:gap-y-8 {
    grid-row-gap: 2rem !important;
    row-gap: 2rem !important;
  }

  .xs\:gap-y-10 {
    grid-row-gap: 2.5rem !important;
    row-gap: 2.5rem !important;
  }

  .xs\:gap-y-12 {
    grid-row-gap: 3rem !important;
    row-gap: 3rem !important;
  }

  .xs\:gap-y-14 {
    grid-row-gap: 3.5rem !important;
    row-gap: 3.5rem !important;
  }

  .xs\:gap-y-16 {
    grid-row-gap: 4rem !important;
    row-gap: 4rem !important;
  }

  .xs\:gap-y-18 {
    grid-row-gap: 4.5rem !important;
    row-gap: 4.5rem !important;
  }

  .xs\:gap-y-20 {
    grid-row-gap: 5rem !important;
    row-gap: 5rem !important;
  }

  .xs\:gap-y-22 {
    grid-row-gap: 5.5rem !important;
    row-gap: 5.5rem !important;
  }

  .xs\:gap-y-24 {
    grid-row-gap: 6rem !important;
    row-gap: 6rem !important;
  }

  .xs\:gap-y-26 {
    grid-row-gap: 6.5rem !important;
    row-gap: 6.5rem !important;
  }

  .xs\:gap-y-28 {
    grid-row-gap: 7rem !important;
    row-gap: 7rem !important;
  }

  .xs\:gap-y-30 {
    grid-row-gap: 7.5rem !important;
    row-gap: 7.5rem !important;
  }

  .xs\:gap-y-32 {
    grid-row-gap: 8rem !important;
    row-gap: 8rem !important;
  }

  .xs\:gap-y-36 {
    grid-row-gap: 9rem !important;
    row-gap: 9rem !important;
  }

  .xs\:gap-y-40 {
    grid-row-gap: 10rem !important;
    row-gap: 10rem !important;
  }

  .xs\:gap-y-48 {
    grid-row-gap: 12rem !important;
    row-gap: 12rem !important;
  }

  .xs\:gap-y-56 {
    grid-row-gap: 14rem !important;
    row-gap: 14rem !important;
  }

  .xs\:gap-y-64 {
    grid-row-gap: 16rem !important;
    row-gap: 16rem !important;
  }

  .xs\:gap-y-px {
    grid-row-gap: 1px !important;
    row-gap: 1px !important;
  }

  .xs\:gap-y-2px {
    grid-row-gap: 2px !important;
    row-gap: 2px !important;
  }

  .xs\:grid-flow-row {
    grid-auto-flow: row !important;
  }

  .xs\:grid-flow-col {
    grid-auto-flow: column !important;
  }

  .xs\:grid-flow-row-dense {
    grid-auto-flow: row dense !important;
  }

  .xs\:grid-flow-col-dense {
    grid-auto-flow: column dense !important;
  }

  .xs\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }

  .xs\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }

  .xs\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
  }

  .xs\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
  }

  .xs\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr)) !important;
  }

  .xs\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr)) !important;
  }

  .xs\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr)) !important;
  }

  .xs\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr)) !important;
  }

  .xs\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr)) !important;
  }

  .xs\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr)) !important;
  }

  .xs\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr)) !important;
  }

  .xs\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr)) !important;
  }

  .xs\:grid-cols-none {
    grid-template-columns: none !important;
  }

  .xs\:col-auto {
    grid-column: auto !important;
  }

  .xs\:col-span-1 {
    grid-column: span 1 / span 1 !important;
  }

  .xs\:col-span-2 {
    grid-column: span 2 / span 2 !important;
  }

  .xs\:col-span-3 {
    grid-column: span 3 / span 3 !important;
  }

  .xs\:col-span-4 {
    grid-column: span 4 / span 4 !important;
  }

  .xs\:col-span-5 {
    grid-column: span 5 / span 5 !important;
  }

  .xs\:col-span-6 {
    grid-column: span 6 / span 6 !important;
  }

  .xs\:col-span-7 {
    grid-column: span 7 / span 7 !important;
  }

  .xs\:col-span-8 {
    grid-column: span 8 / span 8 !important;
  }

  .xs\:col-span-9 {
    grid-column: span 9 / span 9 !important;
  }

  .xs\:col-span-10 {
    grid-column: span 10 / span 10 !important;
  }

  .xs\:col-span-11 {
    grid-column: span 11 / span 11 !important;
  }

  .xs\:col-span-12 {
    grid-column: span 12 / span 12 !important;
  }

  .xs\:col-start-1 {
    grid-column-start: 1 !important;
  }

  .xs\:col-start-2 {
    grid-column-start: 2 !important;
  }

  .xs\:col-start-3 {
    grid-column-start: 3 !important;
  }

  .xs\:col-start-4 {
    grid-column-start: 4 !important;
  }

  .xs\:col-start-5 {
    grid-column-start: 5 !important;
  }

  .xs\:col-start-6 {
    grid-column-start: 6 !important;
  }

  .xs\:col-start-7 {
    grid-column-start: 7 !important;
  }

  .xs\:col-start-8 {
    grid-column-start: 8 !important;
  }

  .xs\:col-start-9 {
    grid-column-start: 9 !important;
  }

  .xs\:col-start-10 {
    grid-column-start: 10 !important;
  }

  .xs\:col-start-11 {
    grid-column-start: 11 !important;
  }

  .xs\:col-start-12 {
    grid-column-start: 12 !important;
  }

  .xs\:col-start-13 {
    grid-column-start: 13 !important;
  }

  .xs\:col-start-auto {
    grid-column-start: auto !important;
  }

  .xs\:col-end-1 {
    grid-column-end: 1 !important;
  }

  .xs\:col-end-2 {
    grid-column-end: 2 !important;
  }

  .xs\:col-end-3 {
    grid-column-end: 3 !important;
  }

  .xs\:col-end-4 {
    grid-column-end: 4 !important;
  }

  .xs\:col-end-5 {
    grid-column-end: 5 !important;
  }

  .xs\:col-end-6 {
    grid-column-end: 6 !important;
  }

  .xs\:col-end-7 {
    grid-column-end: 7 !important;
  }

  .xs\:col-end-8 {
    grid-column-end: 8 !important;
  }

  .xs\:col-end-9 {
    grid-column-end: 9 !important;
  }

  .xs\:col-end-10 {
    grid-column-end: 10 !important;
  }

  .xs\:col-end-11 {
    grid-column-end: 11 !important;
  }

  .xs\:col-end-12 {
    grid-column-end: 12 !important;
  }

  .xs\:col-end-13 {
    grid-column-end: 13 !important;
  }

  .xs\:col-end-auto {
    grid-column-end: auto !important;
  }

  .xs\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr)) !important;
  }

  .xs\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr)) !important;
  }

  .xs\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr)) !important;
  }

  .xs\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr)) !important;
  }

  .xs\:grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr)) !important;
  }

  .xs\:grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr)) !important;
  }

  .xs\:grid-rows-none {
    grid-template-rows: none !important;
  }

  .xs\:row-auto {
    grid-row: auto !important;
  }

  .xs\:row-span-1 {
    grid-row: span 1 / span 1 !important;
  }

  .xs\:row-span-2 {
    grid-row: span 2 / span 2 !important;
  }

  .xs\:row-span-3 {
    grid-row: span 3 / span 3 !important;
  }

  .xs\:row-span-4 {
    grid-row: span 4 / span 4 !important;
  }

  .xs\:row-span-5 {
    grid-row: span 5 / span 5 !important;
  }

  .xs\:row-span-6 {
    grid-row: span 6 / span 6 !important;
  }

  .xs\:row-start-1 {
    grid-row-start: 1 !important;
  }

  .xs\:row-start-2 {
    grid-row-start: 2 !important;
  }

  .xs\:row-start-3 {
    grid-row-start: 3 !important;
  }

  .xs\:row-start-4 {
    grid-row-start: 4 !important;
  }

  .xs\:row-start-5 {
    grid-row-start: 5 !important;
  }

  .xs\:row-start-6 {
    grid-row-start: 6 !important;
  }

  .xs\:row-start-7 {
    grid-row-start: 7 !important;
  }

  .xs\:row-start-auto {
    grid-row-start: auto !important;
  }

  .xs\:row-end-1 {
    grid-row-end: 1 !important;
  }

  .xs\:row-end-2 {
    grid-row-end: 2 !important;
  }

  .xs\:row-end-3 {
    grid-row-end: 3 !important;
  }

  .xs\:row-end-4 {
    grid-row-end: 4 !important;
  }

  .xs\:row-end-5 {
    grid-row-end: 5 !important;
  }

  .xs\:row-end-6 {
    grid-row-end: 6 !important;
  }

  .xs\:row-end-7 {
    grid-row-end: 7 !important;
  }

  .xs\:row-end-auto {
    grid-row-end: auto !important;
  }

  .xs\:transform {
    --transform-translate-x: 0 !important;
    --transform-translate-y: 0 !important;
    --transform-rotate: 0 !important;
    --transform-skew-x: 0 !important;
    --transform-skew-y: 0 !important;
    --transform-scale-x: 1 !important;
    --transform-scale-y: 1 !important;
    transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y)) !important;
  }

  .xs\:transform-none {
    transform: none !important;
  }

  .xs\:origin-center {
    transform-origin: center !important;
  }

  .xs\:origin-top {
    transform-origin: top !important;
  }

  .xs\:origin-top-right {
    transform-origin: top right !important;
  }

  .xs\:origin-right {
    transform-origin: right !important;
  }

  .xs\:origin-bottom-right {
    transform-origin: bottom right !important;
  }

  .xs\:origin-bottom {
    transform-origin: bottom !important;
  }

  .xs\:origin-bottom-left {
    transform-origin: bottom left !important;
  }

  .xs\:origin-left {
    transform-origin: left !important;
  }

  .xs\:origin-top-left {
    transform-origin: top left !important;
  }

  .xs\:icon-size-12 {
    width: 12px !important;
    height: 12px !important;
    min-width: 12px !important;
    min-height: 12px !important;
    font-size: 12px !important;
    line-height: 12px !important;
  }

  .xs\:icon-size-12 svg {
    width: 12px !important;
    height: 12px !important;
  }

  .xs\:icon-size-14 {
    width: 14px !important;
    height: 14px !important;
    min-width: 14px !important;
    min-height: 14px !important;
    font-size: 14px !important;
    line-height: 14px !important;
  }

  .xs\:icon-size-14 svg {
    width: 14px !important;
    height: 14px !important;
  }

  .xs\:icon-size-16 {
    width: 16px !important;
    height: 16px !important;
    min-width: 16px !important;
    min-height: 16px !important;
    font-size: 16px !important;
    line-height: 16px !important;
  }

  .xs\:icon-size-16 svg {
    width: 16px !important;
    height: 16px !important;
  }

  .xs\:icon-size-18 {
    width: 18px !important;
    height: 18px !important;
    min-width: 18px !important;
    min-height: 18px !important;
    font-size: 18px !important;
    line-height: 18px !important;
  }

  .xs\:icon-size-18 svg {
    width: 18px !important;
    height: 18px !important;
  }

  .xs\:icon-size-20 {
    width: 20px !important;
    height: 20px !important;
    min-width: 20px !important;
    min-height: 20px !important;
    font-size: 20px !important;
    line-height: 20px !important;
  }

  .xs\:icon-size-20 svg {
    width: 20px !important;
    height: 20px !important;
  }

  .xs\:icon-size-24 {
    width: 24px !important;
    height: 24px !important;
    min-width: 24px !important;
    min-height: 24px !important;
    font-size: 24px !important;
    line-height: 24px !important;
  }

  .xs\:icon-size-24 svg {
    width: 24px !important;
    height: 24px !important;
  }

  .xs\:icon-size-32 {
    width: 32px !important;
    height: 32px !important;
    min-width: 32px !important;
    min-height: 32px !important;
    font-size: 32px !important;
    line-height: 32px !important;
  }

  .xs\:icon-size-32 svg {
    width: 32px !important;
    height: 32px !important;
  }

  .xs\:icon-size-40 {
    width: 40px !important;
    height: 40px !important;
    min-width: 40px !important;
    min-height: 40px !important;
    font-size: 40px !important;
    line-height: 40px !important;
  }

  .xs\:icon-size-40 svg {
    width: 40px !important;
    height: 40px !important;
  }

  .xs\:icon-size-48 {
    width: 48px !important;
    height: 48px !important;
    min-width: 48px !important;
    min-height: 48px !important;
    font-size: 48px !important;
    line-height: 48px !important;
  }

  .xs\:icon-size-48 svg {
    width: 48px !important;
    height: 48px !important;
  }

  .xs\:icon-size-56 {
    width: 56px !important;
    height: 56px !important;
    min-width: 56px !important;
    min-height: 56px !important;
    font-size: 56px !important;
    line-height: 56px !important;
  }

  .xs\:icon-size-56 svg {
    width: 56px !important;
    height: 56px !important;
  }

  .xs\:icon-size-64 {
    width: 64px !important;
    height: 64px !important;
    min-width: 64px !important;
    min-height: 64px !important;
    font-size: 64px !important;
    line-height: 64px !important;
  }

  .xs\:icon-size-64 svg {
    width: 64px !important;
    height: 64px !important;
  }

  .xs\:icon-size-72 {
    width: 72px !important;
    height: 72px !important;
    min-width: 72px !important;
    min-height: 72px !important;
    font-size: 72px !important;
    line-height: 72px !important;
  }

  .xs\:icon-size-72 svg {
    width: 72px !important;
    height: 72px !important;
  }

  .xs\:icon-size-80 {
    width: 80px !important;
    height: 80px !important;
    min-width: 80px !important;
    min-height: 80px !important;
    font-size: 80px !important;
    line-height: 80px !important;
  }

  .xs\:icon-size-80 svg {
    width: 80px !important;
    height: 80px !important;
  }

  .xs\:icon-size-88 {
    width: 88px !important;
    height: 88px !important;
    min-width: 88px !important;
    min-height: 88px !important;
    font-size: 88px !important;
    line-height: 88px !important;
  }

  .xs\:icon-size-88 svg {
    width: 88px !important;
    height: 88px !important;
  }

  .xs\:icon-size-96 {
    width: 96px !important;
    height: 96px !important;
    min-width: 96px !important;
    min-height: 96px !important;
    font-size: 96px !important;
    line-height: 96px !important;
  }

  .xs\:icon-size-96 svg {
    width: 96px !important;
    height: 96px !important;
  }
}

@media (min-width: 600px) and (max-width: 959px) {
  .sm\:space-y-0 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(0px * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(0px * var(--space-y-reverse)) !important;
  }

  .sm\:space-x-0 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(0px * var(--space-x-reverse)) !important;
    margin-left: calc(0px * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(0.25rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(0.25rem * var(--space-y-reverse)) !important;
  }

  .sm\:space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(0.25rem * var(--space-x-reverse)) !important;
    margin-left: calc(0.25rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(0.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(0.5rem * var(--space-y-reverse)) !important;
  }

  .sm\:space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(0.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(0.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(0.75rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(0.75rem * var(--space-y-reverse)) !important;
  }

  .sm\:space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(0.75rem * var(--space-x-reverse)) !important;
    margin-left: calc(0.75rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(1rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(1rem * var(--space-y-reverse)) !important;
  }

  .sm\:space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(1rem * var(--space-x-reverse)) !important;
    margin-left: calc(1rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(1.25rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(1.25rem * var(--space-y-reverse)) !important;
  }

  .sm\:space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(1.25rem * var(--space-x-reverse)) !important;
    margin-left: calc(1.25rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(1.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(1.5rem * var(--space-y-reverse)) !important;
  }

  .sm\:space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(1.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(1.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:space-y-8 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(2rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(2rem * var(--space-y-reverse)) !important;
  }

  .sm\:space-x-8 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(2rem * var(--space-x-reverse)) !important;
    margin-left: calc(2rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:space-y-10 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(2.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(2.5rem * var(--space-y-reverse)) !important;
  }

  .sm\:space-x-10 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(2.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(2.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:space-y-12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(3rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(3rem * var(--space-y-reverse)) !important;
  }

  .sm\:space-x-12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(3rem * var(--space-x-reverse)) !important;
    margin-left: calc(3rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:space-y-14 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(3.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(3.5rem * var(--space-y-reverse)) !important;
  }

  .sm\:space-x-14 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(3.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(3.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:space-y-16 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(4rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(4rem * var(--space-y-reverse)) !important;
  }

  .sm\:space-x-16 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(4rem * var(--space-x-reverse)) !important;
    margin-left: calc(4rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:space-y-18 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(4.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(4.5rem * var(--space-y-reverse)) !important;
  }

  .sm\:space-x-18 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(4.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(4.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:space-y-20 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(5rem * var(--space-y-reverse)) !important;
  }

  .sm\:space-x-20 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(5rem * var(--space-x-reverse)) !important;
    margin-left: calc(5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:space-y-22 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(5.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(5.5rem * var(--space-y-reverse)) !important;
  }

  .sm\:space-x-22 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(5.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(5.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:space-y-24 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(6rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(6rem * var(--space-y-reverse)) !important;
  }

  .sm\:space-x-24 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(6rem * var(--space-x-reverse)) !important;
    margin-left: calc(6rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:space-y-26 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(6.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(6.5rem * var(--space-y-reverse)) !important;
  }

  .sm\:space-x-26 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(6.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(6.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:space-y-28 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(7rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(7rem * var(--space-y-reverse)) !important;
  }

  .sm\:space-x-28 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(7rem * var(--space-x-reverse)) !important;
    margin-left: calc(7rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:space-y-30 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(7.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(7.5rem * var(--space-y-reverse)) !important;
  }

  .sm\:space-x-30 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(7.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(7.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:space-y-32 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(8rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(8rem * var(--space-y-reverse)) !important;
  }

  .sm\:space-x-32 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(8rem * var(--space-x-reverse)) !important;
    margin-left: calc(8rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:space-y-36 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(9rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(9rem * var(--space-y-reverse)) !important;
  }

  .sm\:space-x-36 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(9rem * var(--space-x-reverse)) !important;
    margin-left: calc(9rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:space-y-40 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(10rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(10rem * var(--space-y-reverse)) !important;
  }

  .sm\:space-x-40 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(10rem * var(--space-x-reverse)) !important;
    margin-left: calc(10rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:space-y-48 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(12rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(12rem * var(--space-y-reverse)) !important;
  }

  .sm\:space-x-48 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(12rem * var(--space-x-reverse)) !important;
    margin-left: calc(12rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:space-y-56 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(14rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(14rem * var(--space-y-reverse)) !important;
  }

  .sm\:space-x-56 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(14rem * var(--space-x-reverse)) !important;
    margin-left: calc(14rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:space-y-64 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(16rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(16rem * var(--space-y-reverse)) !important;
  }

  .sm\:space-x-64 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(16rem * var(--space-x-reverse)) !important;
    margin-left: calc(16rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:space-y-px > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(1px * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(1px * var(--space-y-reverse)) !important;
  }

  .sm\:space-x-px > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(1px * var(--space-x-reverse)) !important;
    margin-left: calc(1px * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:space-y-2px > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(2px * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(2px * var(--space-y-reverse)) !important;
  }

  .sm\:space-x-2px > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(2px * var(--space-x-reverse)) !important;
    margin-left: calc(2px * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:-space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-0.25rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-0.25rem * var(--space-y-reverse)) !important;
  }

  .sm\:-space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-0.25rem * var(--space-x-reverse)) !important;
    margin-left: calc(-0.25rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:-space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-0.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-0.5rem * var(--space-y-reverse)) !important;
  }

  .sm\:-space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-0.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-0.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:-space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-0.75rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-0.75rem * var(--space-y-reverse)) !important;
  }

  .sm\:-space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-0.75rem * var(--space-x-reverse)) !important;
    margin-left: calc(-0.75rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:-space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-1rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-1rem * var(--space-y-reverse)) !important;
  }

  .sm\:-space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-1rem * var(--space-x-reverse)) !important;
    margin-left: calc(-1rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:-space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-1.25rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-1.25rem * var(--space-y-reverse)) !important;
  }

  .sm\:-space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-1.25rem * var(--space-x-reverse)) !important;
    margin-left: calc(-1.25rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:-space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-1.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-1.5rem * var(--space-y-reverse)) !important;
  }

  .sm\:-space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-1.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-1.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:-space-y-8 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-2rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-2rem * var(--space-y-reverse)) !important;
  }

  .sm\:-space-x-8 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-2rem * var(--space-x-reverse)) !important;
    margin-left: calc(-2rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:-space-y-10 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-2.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-2.5rem * var(--space-y-reverse)) !important;
  }

  .sm\:-space-x-10 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-2.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-2.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:-space-y-12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-3rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-3rem * var(--space-y-reverse)) !important;
  }

  .sm\:-space-x-12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-3rem * var(--space-x-reverse)) !important;
    margin-left: calc(-3rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:-space-y-14 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-3.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-3.5rem * var(--space-y-reverse)) !important;
  }

  .sm\:-space-x-14 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-3.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-3.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:-space-y-16 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-4rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-4rem * var(--space-y-reverse)) !important;
  }

  .sm\:-space-x-16 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-4rem * var(--space-x-reverse)) !important;
    margin-left: calc(-4rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:-space-y-18 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-4.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-4.5rem * var(--space-y-reverse)) !important;
  }

  .sm\:-space-x-18 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-4.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-4.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:-space-y-20 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-5rem * var(--space-y-reverse)) !important;
  }

  .sm\:-space-x-20 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:-space-y-22 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-5.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-5.5rem * var(--space-y-reverse)) !important;
  }

  .sm\:-space-x-22 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-5.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-5.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:-space-y-24 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-6rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-6rem * var(--space-y-reverse)) !important;
  }

  .sm\:-space-x-24 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-6rem * var(--space-x-reverse)) !important;
    margin-left: calc(-6rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:-space-y-26 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-6.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-6.5rem * var(--space-y-reverse)) !important;
  }

  .sm\:-space-x-26 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-6.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-6.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:-space-y-28 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-7rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-7rem * var(--space-y-reverse)) !important;
  }

  .sm\:-space-x-28 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-7rem * var(--space-x-reverse)) !important;
    margin-left: calc(-7rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:-space-y-30 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-7.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-7.5rem * var(--space-y-reverse)) !important;
  }

  .sm\:-space-x-30 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-7.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-7.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:-space-y-32 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-8rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-8rem * var(--space-y-reverse)) !important;
  }

  .sm\:-space-x-32 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-8rem * var(--space-x-reverse)) !important;
    margin-left: calc(-8rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:-space-y-36 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-9rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-9rem * var(--space-y-reverse)) !important;
  }

  .sm\:-space-x-36 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-9rem * var(--space-x-reverse)) !important;
    margin-left: calc(-9rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:-space-y-40 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-10rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-10rem * var(--space-y-reverse)) !important;
  }

  .sm\:-space-x-40 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-10rem * var(--space-x-reverse)) !important;
    margin-left: calc(-10rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:-space-y-48 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-12rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-12rem * var(--space-y-reverse)) !important;
  }

  .sm\:-space-x-48 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-12rem * var(--space-x-reverse)) !important;
    margin-left: calc(-12rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:-space-y-56 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-14rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-14rem * var(--space-y-reverse)) !important;
  }

  .sm\:-space-x-56 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-14rem * var(--space-x-reverse)) !important;
    margin-left: calc(-14rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:-space-y-64 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-16rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-16rem * var(--space-y-reverse)) !important;
  }

  .sm\:-space-x-64 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-16rem * var(--space-x-reverse)) !important;
    margin-left: calc(-16rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:-space-y-px > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-1px * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-1px * var(--space-y-reverse)) !important;
  }

  .sm\:-space-x-px > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-1px * var(--space-x-reverse)) !important;
    margin-left: calc(-1px * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:-space-y-2px > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-2px * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-2px * var(--space-y-reverse)) !important;
  }

  .sm\:-space-x-2px > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-2px * var(--space-x-reverse)) !important;
    margin-left: calc(-2px * calc(1 - var(--space-x-reverse))) !important;
  }

  .sm\:space-y-reverse > :not(template) ~ :not(template) {
    --space-y-reverse: 1 !important;
  }

  .sm\:space-x-reverse > :not(template) ~ :not(template) {
    --space-x-reverse: 1 !important;
  }

  .sm\:divide-y-0 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(0px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(0px * var(--divide-y-reverse)) !important;
  }

  .sm\:divide-x-0 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(0px * var(--divide-x-reverse)) !important;
    border-left-width: calc(0px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .sm\:divide-y-2 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(2px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(2px * var(--divide-y-reverse)) !important;
  }

  .sm\:divide-x-2 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(2px * var(--divide-x-reverse)) !important;
    border-left-width: calc(2px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .sm\:divide-y-4 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(4px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(4px * var(--divide-y-reverse)) !important;
  }

  .sm\:divide-x-4 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(4px * var(--divide-x-reverse)) !important;
    border-left-width: calc(4px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .sm\:divide-y-8 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(8px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(8px * var(--divide-y-reverse)) !important;
  }

  .sm\:divide-x-8 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(8px * var(--divide-x-reverse)) !important;
    border-left-width: calc(8px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .sm\:divide-y > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(1px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(1px * var(--divide-y-reverse)) !important;
  }

  .sm\:divide-x > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(1px * var(--divide-x-reverse)) !important;
    border-left-width: calc(1px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .sm\:divide-y-reverse > :not(template) ~ :not(template) {
    --divide-y-reverse: 1 !important;
  }

  .sm\:divide-x-reverse > :not(template) ~ :not(template) {
    --divide-x-reverse: 1 !important;
  }

  .sm\:divide-current > :not(template) ~ :not(template) {
    border-color: currentColor !important;
  }

  .sm\:divide-transparent > :not(template) ~ :not(template) {
    border-color: transparent !important;
  }

  .sm\:divide-white > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FFFFFF !important;
    border-color: rgba(255, 255, 255, var(--divide-opacity)) !important;
  }

  .sm\:divide-black > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #000000 !important;
    border-color: rgba(0, 0, 0, var(--divide-opacity)) !important;
  }

  .sm\:divide-gray-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F9FAFB !important;
    border-color: rgba(249, 250, 251, var(--divide-opacity)) !important;
  }

  .sm\:divide-gray-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F4F5F7 !important;
    border-color: rgba(244, 245, 247, var(--divide-opacity)) !important;
  }

  .sm\:divide-gray-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E5E7EB !important;
    border-color: rgba(229, 231, 235, var(--divide-opacity)) !important;
  }

  .sm\:divide-gray-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #D2D6DC !important;
    border-color: rgba(210, 214, 220, var(--divide-opacity)) !important;
  }

  .sm\:divide-gray-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9FA6B2 !important;
    border-color: rgba(159, 166, 178, var(--divide-opacity)) !important;
  }

  .sm\:divide-gray-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #6B7280 !important;
    border-color: rgba(107, 114, 128, var(--divide-opacity)) !important;
  }

  .sm\:divide-gray-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #4B5563 !important;
    border-color: rgba(75, 85, 99, var(--divide-opacity)) !important;
  }

  .sm\:divide-gray-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #374151 !important;
    border-color: rgba(55, 65, 81, var(--divide-opacity)) !important;
  }

  .sm\:divide-gray-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #252F3F !important;
    border-color: rgba(37, 47, 63, var(--divide-opacity)) !important;
  }

  .sm\:divide-gray-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #161E2E !important;
    border-color: rgba(22, 30, 46, var(--divide-opacity)) !important;
  }

  .sm\:divide-gray > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #6B7280 !important;
    border-color: rgba(107, 114, 128, var(--divide-opacity)) !important;
  }

  .sm\:divide-cool-gray-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FBFDFE !important;
    border-color: rgba(251, 253, 254, var(--divide-opacity)) !important;
  }

  .sm\:divide-cool-gray-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F1F5F9 !important;
    border-color: rgba(241, 245, 249, var(--divide-opacity)) !important;
  }

  .sm\:divide-cool-gray-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E2E8F0 !important;
    border-color: rgba(226, 232, 240, var(--divide-opacity)) !important;
  }

  .sm\:divide-cool-gray-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #CFD8E3 !important;
    border-color: rgba(207, 216, 227, var(--divide-opacity)) !important;
  }

  .sm\:divide-cool-gray-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #97A6BA !important;
    border-color: rgba(151, 166, 186, var(--divide-opacity)) !important;
  }

  .sm\:divide-cool-gray-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #64748B !important;
    border-color: rgba(100, 116, 139, var(--divide-opacity)) !important;
  }

  .sm\:divide-cool-gray-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #475569 !important;
    border-color: rgba(71, 85, 105, var(--divide-opacity)) !important;
  }

  .sm\:divide-cool-gray-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #364152 !important;
    border-color: rgba(54, 65, 82, var(--divide-opacity)) !important;
  }

  .sm\:divide-cool-gray-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #27303F !important;
    border-color: rgba(39, 48, 63, var(--divide-opacity)) !important;
  }

  .sm\:divide-cool-gray-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #1A202E !important;
    border-color: rgba(26, 32, 46, var(--divide-opacity)) !important;
  }

  .sm\:divide-cool-gray > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #64748B !important;
    border-color: rgba(100, 116, 139, var(--divide-opacity)) !important;
  }

  .sm\:divide-red-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FDF2F2 !important;
    border-color: rgba(253, 242, 242, var(--divide-opacity)) !important;
  }

  .sm\:divide-red-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FDE8E8 !important;
    border-color: rgba(253, 232, 232, var(--divide-opacity)) !important;
  }

  .sm\:divide-red-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FBD5D5 !important;
    border-color: rgba(251, 213, 213, var(--divide-opacity)) !important;
  }

  .sm\:divide-red-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F8B4B4 !important;
    border-color: rgba(248, 180, 180, var(--divide-opacity)) !important;
  }

  .sm\:divide-red-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F98080 !important;
    border-color: rgba(249, 128, 128, var(--divide-opacity)) !important;
  }

  .sm\:divide-red-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F05252 !important;
    border-color: rgba(240, 82, 82, var(--divide-opacity)) !important;
  }

  .sm\:divide-red-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E02424 !important;
    border-color: rgba(224, 36, 36, var(--divide-opacity)) !important;
  }

  .sm\:divide-red-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #C81E1E !important;
    border-color: rgba(200, 30, 30, var(--divide-opacity)) !important;
  }

  .sm\:divide-red-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9B1C1C !important;
    border-color: rgba(155, 28, 28, var(--divide-opacity)) !important;
  }

  .sm\:divide-red-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #771D1D !important;
    border-color: rgba(119, 29, 29, var(--divide-opacity)) !important;
  }

  .sm\:divide-red > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F05252 !important;
    border-color: rgba(240, 82, 82, var(--divide-opacity)) !important;
  }

  .sm\:divide-orange-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FFF8F1 !important;
    border-color: rgba(255, 248, 241, var(--divide-opacity)) !important;
  }

  .sm\:divide-orange-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FEECDC !important;
    border-color: rgba(254, 236, 220, var(--divide-opacity)) !important;
  }

  .sm\:divide-orange-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FCD9BD !important;
    border-color: rgba(252, 217, 189, var(--divide-opacity)) !important;
  }

  .sm\:divide-orange-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FDBA8C !important;
    border-color: rgba(253, 186, 140, var(--divide-opacity)) !important;
  }

  .sm\:divide-orange-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FF8A4C !important;
    border-color: rgba(255, 138, 76, var(--divide-opacity)) !important;
  }

  .sm\:divide-orange-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FF5A1F !important;
    border-color: rgba(255, 90, 31, var(--divide-opacity)) !important;
  }

  .sm\:divide-orange-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #ff6700 !important;
    border-color: rgba(255, 103, 0, var(--divide-opacity)) !important;
  }

  .sm\:divide-orange-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #B43403 !important;
    border-color: rgba(180, 52, 3, var(--divide-opacity)) !important;
  }

  .sm\:divide-orange-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #8A2C0D !important;
    border-color: rgba(138, 44, 13, var(--divide-opacity)) !important;
  }

  .sm\:divide-orange-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #771D1D !important;
    border-color: rgba(119, 29, 29, var(--divide-opacity)) !important;
  }

  .sm\:divide-orange > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FF5A1F !important;
    border-color: rgba(255, 90, 31, var(--divide-opacity)) !important;
  }

  .sm\:divide-yellow-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FDFDEA !important;
    border-color: rgba(253, 253, 234, var(--divide-opacity)) !important;
  }

  .sm\:divide-yellow-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FDF6B2 !important;
    border-color: rgba(253, 246, 178, var(--divide-opacity)) !important;
  }

  .sm\:divide-yellow-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FCE96A !important;
    border-color: rgba(252, 233, 106, var(--divide-opacity)) !important;
  }

  .sm\:divide-yellow-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FACA15 !important;
    border-color: rgba(250, 202, 21, var(--divide-opacity)) !important;
  }

  .sm\:divide-yellow-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E3A008 !important;
    border-color: rgba(227, 160, 8, var(--divide-opacity)) !important;
  }

  .sm\:divide-yellow-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #C27803 !important;
    border-color: rgba(194, 120, 3, var(--divide-opacity)) !important;
  }

  .sm\:divide-yellow-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9F580A !important;
    border-color: rgba(159, 88, 10, var(--divide-opacity)) !important;
  }

  .sm\:divide-yellow-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #8E4B10 !important;
    border-color: rgba(142, 75, 16, var(--divide-opacity)) !important;
  }

  .sm\:divide-yellow-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #723B13 !important;
    border-color: rgba(114, 59, 19, var(--divide-opacity)) !important;
  }

  .sm\:divide-yellow-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #633112 !important;
    border-color: rgba(99, 49, 18, var(--divide-opacity)) !important;
  }

  .sm\:divide-yellow > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #C27803 !important;
    border-color: rgba(194, 120, 3, var(--divide-opacity)) !important;
  }

  .sm\:divide-green-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F3FAF7 !important;
    border-color: rgba(243, 250, 247, var(--divide-opacity)) !important;
  }

  .sm\:divide-green-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #DEF7EC !important;
    border-color: rgba(222, 247, 236, var(--divide-opacity)) !important;
  }

  .sm\:divide-green-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #BCF0DA !important;
    border-color: rgba(188, 240, 218, var(--divide-opacity)) !important;
  }

  .sm\:divide-green-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #84E1BC !important;
    border-color: rgba(132, 225, 188, var(--divide-opacity)) !important;
  }

  .sm\:divide-green-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #31C48D !important;
    border-color: rgba(49, 196, 141, var(--divide-opacity)) !important;
  }

  .sm\:divide-green-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #0E9F6E !important;
    border-color: rgba(14, 159, 110, var(--divide-opacity)) !important;
  }

  .sm\:divide-green-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #057A55 !important;
    border-color: rgba(5, 122, 85, var(--divide-opacity)) !important;
  }

  .sm\:divide-green-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #046C4E !important;
    border-color: rgba(4, 108, 78, var(--divide-opacity)) !important;
  }

  .sm\:divide-green-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #03543F !important;
    border-color: rgba(3, 84, 63, var(--divide-opacity)) !important;
  }

  .sm\:divide-green-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #014737 !important;
    border-color: rgba(1, 71, 55, var(--divide-opacity)) !important;
  }

  .sm\:divide-green > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #0E9F6E !important;
    border-color: rgba(14, 159, 110, var(--divide-opacity)) !important;
  }

  .sm\:divide-teal-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #EDFAFA !important;
    border-color: rgba(237, 250, 250, var(--divide-opacity)) !important;
  }

  .sm\:divide-teal-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #D5F5F6 !important;
    border-color: rgba(213, 245, 246, var(--divide-opacity)) !important;
  }

  .sm\:divide-teal-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #AFECEF !important;
    border-color: rgba(175, 236, 239, var(--divide-opacity)) !important;
  }

  .sm\:divide-teal-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #7EDCE2 !important;
    border-color: rgba(126, 220, 226, var(--divide-opacity)) !important;
  }

  .sm\:divide-teal-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #16BDCA !important;
    border-color: rgba(22, 189, 202, var(--divide-opacity)) !important;
  }

  .sm\:divide-teal-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #0694A2 !important;
    border-color: rgba(6, 148, 162, var(--divide-opacity)) !important;
  }

  .sm\:divide-teal-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #047481 !important;
    border-color: rgba(4, 116, 129, var(--divide-opacity)) !important;
  }

  .sm\:divide-teal-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #036672 !important;
    border-color: rgba(3, 102, 114, var(--divide-opacity)) !important;
  }

  .sm\:divide-teal-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #05505C !important;
    border-color: rgba(5, 80, 92, var(--divide-opacity)) !important;
  }

  .sm\:divide-teal-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #014451 !important;
    border-color: rgba(1, 68, 81, var(--divide-opacity)) !important;
  }

  .sm\:divide-teal > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #0694A2 !important;
    border-color: rgba(6, 148, 162, var(--divide-opacity)) !important;
  }

  .sm\:divide-blue-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #EBF5FF !important;
    border-color: rgba(235, 245, 255, var(--divide-opacity)) !important;
  }

  .sm\:divide-blue-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E1EFFE !important;
    border-color: rgba(225, 239, 254, var(--divide-opacity)) !important;
  }

  .sm\:divide-blue-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #C3DDFD !important;
    border-color: rgba(195, 221, 253, var(--divide-opacity)) !important;
  }

  .sm\:divide-blue-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #A4CAFE !important;
    border-color: rgba(164, 202, 254, var(--divide-opacity)) !important;
  }

  .sm\:divide-blue-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #76A9FA !important;
    border-color: rgba(118, 169, 250, var(--divide-opacity)) !important;
  }

  .sm\:divide-blue-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #3F83F8 !important;
    border-color: rgba(63, 131, 248, var(--divide-opacity)) !important;
  }

  .sm\:divide-blue-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #1C64F2 !important;
    border-color: rgba(28, 100, 242, var(--divide-opacity)) !important;
  }

  .sm\:divide-blue-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #1A56DB !important;
    border-color: rgba(26, 86, 219, var(--divide-opacity)) !important;
  }

  .sm\:divide-blue-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #1E429F !important;
    border-color: rgba(30, 66, 159, var(--divide-opacity)) !important;
  }

  .sm\:divide-blue-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #233876 !important;
    border-color: rgba(35, 56, 118, var(--divide-opacity)) !important;
  }

  .sm\:divide-blue > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #3F83F8 !important;
    border-color: rgba(63, 131, 248, var(--divide-opacity)) !important;
  }

  .sm\:divide-indigo-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F0F5FF !important;
    border-color: rgba(240, 245, 255, var(--divide-opacity)) !important;
  }

  .sm\:divide-indigo-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E5EDFF !important;
    border-color: rgba(229, 237, 255, var(--divide-opacity)) !important;
  }

  .sm\:divide-indigo-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #CDDBFE !important;
    border-color: rgba(205, 219, 254, var(--divide-opacity)) !important;
  }

  .sm\:divide-indigo-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #B4C6FC !important;
    border-color: rgba(180, 198, 252, var(--divide-opacity)) !important;
  }

  .sm\:divide-indigo-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #8DA2FB !important;
    border-color: rgba(141, 162, 251, var(--divide-opacity)) !important;
  }

  .sm\:divide-indigo-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #6875F5 !important;
    border-color: rgba(104, 117, 245, var(--divide-opacity)) !important;
  }

  .sm\:divide-indigo-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #5850EC !important;
    border-color: rgba(88, 80, 236, var(--divide-opacity)) !important;
  }

  .sm\:divide-indigo-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #5145CD !important;
    border-color: rgba(81, 69, 205, var(--divide-opacity)) !important;
  }

  .sm\:divide-indigo-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #42389D !important;
    border-color: rgba(66, 56, 157, var(--divide-opacity)) !important;
  }

  .sm\:divide-indigo-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #362F78 !important;
    border-color: rgba(54, 47, 120, var(--divide-opacity)) !important;
  }

  .sm\:divide-indigo > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #6875F5 !important;
    border-color: rgba(104, 117, 245, var(--divide-opacity)) !important;
  }

  .sm\:divide-purple-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F6F5FF !important;
    border-color: rgba(246, 245, 255, var(--divide-opacity)) !important;
  }

  .sm\:divide-purple-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #EDEBFE !important;
    border-color: rgba(237, 235, 254, var(--divide-opacity)) !important;
  }

  .sm\:divide-purple-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #DCD7FE !important;
    border-color: rgba(220, 215, 254, var(--divide-opacity)) !important;
  }

  .sm\:divide-purple-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #CABFFD !important;
    border-color: rgba(202, 191, 253, var(--divide-opacity)) !important;
  }

  .sm\:divide-purple-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #AC94FA !important;
    border-color: rgba(172, 148, 250, var(--divide-opacity)) !important;
  }

  .sm\:divide-purple-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9061F9 !important;
    border-color: rgba(144, 97, 249, var(--divide-opacity)) !important;
  }

  .sm\:divide-purple-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #7E3AF2 !important;
    border-color: rgba(126, 58, 242, var(--divide-opacity)) !important;
  }

  .sm\:divide-purple-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #6C2BD9 !important;
    border-color: rgba(108, 43, 217, var(--divide-opacity)) !important;
  }

  .sm\:divide-purple-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #5521B5 !important;
    border-color: rgba(85, 33, 181, var(--divide-opacity)) !important;
  }

  .sm\:divide-purple-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #4A1D96 !important;
    border-color: rgba(74, 29, 150, var(--divide-opacity)) !important;
  }

  .sm\:divide-purple > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9061F9 !important;
    border-color: rgba(144, 97, 249, var(--divide-opacity)) !important;
  }

  .sm\:divide-pink-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FDF2F8 !important;
    border-color: rgba(253, 242, 248, var(--divide-opacity)) !important;
  }

  .sm\:divide-pink-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FCE8F3 !important;
    border-color: rgba(252, 232, 243, var(--divide-opacity)) !important;
  }

  .sm\:divide-pink-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FAD1E8 !important;
    border-color: rgba(250, 209, 232, var(--divide-opacity)) !important;
  }

  .sm\:divide-pink-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F8B4D9 !important;
    border-color: rgba(248, 180, 217, var(--divide-opacity)) !important;
  }

  .sm\:divide-pink-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F17EB8 !important;
    border-color: rgba(241, 126, 184, var(--divide-opacity)) !important;
  }

  .sm\:divide-pink-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E74694 !important;
    border-color: rgba(231, 70, 148, var(--divide-opacity)) !important;
  }

  .sm\:divide-pink-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #D61F69 !important;
    border-color: rgba(214, 31, 105, var(--divide-opacity)) !important;
  }

  .sm\:divide-pink-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #BF125D !important;
    border-color: rgba(191, 18, 93, var(--divide-opacity)) !important;
  }

  .sm\:divide-pink-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #99154B !important;
    border-color: rgba(153, 21, 75, var(--divide-opacity)) !important;
  }

  .sm\:divide-pink-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #751A3D !important;
    border-color: rgba(117, 26, 61, var(--divide-opacity)) !important;
  }

  .sm\:divide-pink > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E74694 !important;
    border-color: rgba(231, 70, 148, var(--divide-opacity)) !important;
  }

  .sm\:divide-opacity-0 > :not(template) ~ :not(template) {
    --divide-opacity: 0 !important;
  }

  .sm\:divide-opacity-12 > :not(template) ~ :not(template) {
    --divide-opacity: 0.12 !important;
  }

  .sm\:divide-opacity-25 > :not(template) ~ :not(template) {
    --divide-opacity: 0.25 !important;
  }

  .sm\:divide-opacity-38 > :not(template) ~ :not(template) {
    --divide-opacity: 0.38 !important;
  }

  .sm\:divide-opacity-50 > :not(template) ~ :not(template) {
    --divide-opacity: 0.5 !important;
  }

  .sm\:divide-opacity-54 > :not(template) ~ :not(template) {
    --divide-opacity: 0.54 !important;
  }

  .sm\:divide-opacity-70 > :not(template) ~ :not(template) {
    --divide-opacity: 0.70 !important;
  }

  .sm\:divide-opacity-75 > :not(template) ~ :not(template) {
    --divide-opacity: 0.75 !important;
  }

  .sm\:divide-opacity-84 > :not(template) ~ :not(template) {
    --divide-opacity: 0.84 !important;
  }

  .sm\:divide-opacity-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
  }

  .sm\:sr-only {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border-width: 0 !important;
  }

  .sm\:not-sr-only {
    position: static !important;
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: visible !important;
    clip: auto !important;
    white-space: normal !important;
  }

  .sm\:focus\:sr-only:focus {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border-width: 0 !important;
  }

  .sm\:focus\:not-sr-only:focus {
    position: static !important;
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: visible !important;
    clip: auto !important;
    white-space: normal !important;
  }

  .sm\:appearance-none {
    -webkit-appearance: none !important;
       -moz-appearance: none !important;
            appearance: none !important;
  }

  .sm\:bg-fixed {
    background-attachment: fixed !important;
  }

  .sm\:bg-local {
    background-attachment: local !important;
  }

  .sm\:bg-scroll {
    background-attachment: scroll !important;
  }

  .sm\:bg-opacity-0 {
    --bg-opacity: 0 !important;
  }

  .sm\:bg-opacity-12 {
    --bg-opacity: 0.12 !important;
  }

  .sm\:bg-opacity-25 {
    --bg-opacity: 0.25 !important;
  }

  .sm\:bg-opacity-38 {
    --bg-opacity: 0.38 !important;
  }

  .sm\:bg-opacity-50 {
    --bg-opacity: 0.5 !important;
  }

  .sm\:bg-opacity-54 {
    --bg-opacity: 0.54 !important;
  }

  .sm\:bg-opacity-70 {
    --bg-opacity: 0.70 !important;
  }

  .sm\:bg-opacity-75 {
    --bg-opacity: 0.75 !important;
  }

  .sm\:bg-opacity-84 {
    --bg-opacity: 0.84 !important;
  }

  .sm\:bg-opacity-100 {
    --bg-opacity: 1 !important;
  }

  .sm\:hover\:bg-opacity-0:hover {
    --bg-opacity: 0 !important;
  }

  .sm\:hover\:bg-opacity-12:hover {
    --bg-opacity: 0.12 !important;
  }

  .sm\:hover\:bg-opacity-25:hover {
    --bg-opacity: 0.25 !important;
  }

  .sm\:hover\:bg-opacity-38:hover {
    --bg-opacity: 0.38 !important;
  }

  .sm\:hover\:bg-opacity-50:hover {
    --bg-opacity: 0.5 !important;
  }

  .sm\:hover\:bg-opacity-54:hover {
    --bg-opacity: 0.54 !important;
  }

  .sm\:hover\:bg-opacity-70:hover {
    --bg-opacity: 0.70 !important;
  }

  .sm\:hover\:bg-opacity-75:hover {
    --bg-opacity: 0.75 !important;
  }

  .sm\:hover\:bg-opacity-84:hover {
    --bg-opacity: 0.84 !important;
  }

  .sm\:hover\:bg-opacity-100:hover {
    --bg-opacity: 1 !important;
  }

  .sm\:focus\:bg-opacity-0:focus {
    --bg-opacity: 0 !important;
  }

  .sm\:focus\:bg-opacity-12:focus {
    --bg-opacity: 0.12 !important;
  }

  .sm\:focus\:bg-opacity-25:focus {
    --bg-opacity: 0.25 !important;
  }

  .sm\:focus\:bg-opacity-38:focus {
    --bg-opacity: 0.38 !important;
  }

  .sm\:focus\:bg-opacity-50:focus {
    --bg-opacity: 0.5 !important;
  }

  .sm\:focus\:bg-opacity-54:focus {
    --bg-opacity: 0.54 !important;
  }

  .sm\:focus\:bg-opacity-70:focus {
    --bg-opacity: 0.70 !important;
  }

  .sm\:focus\:bg-opacity-75:focus {
    --bg-opacity: 0.75 !important;
  }

  .sm\:focus\:bg-opacity-84:focus {
    --bg-opacity: 0.84 !important;
  }

  .sm\:focus\:bg-opacity-100:focus {
    --bg-opacity: 1 !important;
  }

  .sm\:bg-bottom {
    background-position: bottom !important;
  }

  .sm\:bg-center {
    background-position: center !important;
  }

  .sm\:bg-left {
    background-position: left !important;
  }

  .sm\:bg-left-bottom {
    background-position: left bottom !important;
  }

  .sm\:bg-left-top {
    background-position: left top !important;
  }

  .sm\:bg-right {
    background-position: right !important;
  }

  .sm\:bg-right-bottom {
    background-position: right bottom !important;
  }

  .sm\:bg-right-top {
    background-position: right top !important;
  }

  .sm\:bg-top {
    background-position: top !important;
  }

  .sm\:bg-repeat {
    background-repeat: repeat !important;
  }

  .sm\:bg-no-repeat {
    background-repeat: no-repeat !important;
  }

  .sm\:bg-repeat-x {
    background-repeat: repeat-x !important;
  }

  .sm\:bg-repeat-y {
    background-repeat: repeat-y !important;
  }

  .sm\:bg-repeat-round {
    background-repeat: round !important;
  }

  .sm\:bg-repeat-space {
    background-repeat: space !important;
  }

  .sm\:bg-auto {
    background-size: auto !important;
  }

  .sm\:bg-cover {
    background-size: cover !important;
  }

  .sm\:bg-contain {
    background-size: contain !important;
  }

  .sm\:border-collapse {
    border-collapse: collapse !important;
  }

  .sm\:border-separate {
    border-collapse: separate !important;
  }

  .sm\:border-opacity-0 {
    --border-opacity: 0 !important;
  }

  .sm\:border-opacity-12 {
    --border-opacity: 0.12 !important;
  }

  .sm\:border-opacity-25 {
    --border-opacity: 0.25 !important;
  }

  .sm\:border-opacity-38 {
    --border-opacity: 0.38 !important;
  }

  .sm\:border-opacity-50 {
    --border-opacity: 0.5 !important;
  }

  .sm\:border-opacity-54 {
    --border-opacity: 0.54 !important;
  }

  .sm\:border-opacity-70 {
    --border-opacity: 0.70 !important;
  }

  .sm\:border-opacity-75 {
    --border-opacity: 0.75 !important;
  }

  .sm\:border-opacity-84 {
    --border-opacity: 0.84 !important;
  }

  .sm\:border-opacity-100 {
    --border-opacity: 1 !important;
  }

  .sm\:hover\:border-opacity-0:hover {
    --border-opacity: 0 !important;
  }

  .sm\:hover\:border-opacity-12:hover {
    --border-opacity: 0.12 !important;
  }

  .sm\:hover\:border-opacity-25:hover {
    --border-opacity: 0.25 !important;
  }

  .sm\:hover\:border-opacity-38:hover {
    --border-opacity: 0.38 !important;
  }

  .sm\:hover\:border-opacity-50:hover {
    --border-opacity: 0.5 !important;
  }

  .sm\:hover\:border-opacity-54:hover {
    --border-opacity: 0.54 !important;
  }

  .sm\:hover\:border-opacity-70:hover {
    --border-opacity: 0.70 !important;
  }

  .sm\:hover\:border-opacity-75:hover {
    --border-opacity: 0.75 !important;
  }

  .sm\:hover\:border-opacity-84:hover {
    --border-opacity: 0.84 !important;
  }

  .sm\:hover\:border-opacity-100:hover {
    --border-opacity: 1 !important;
  }

  .sm\:focus\:border-opacity-0:focus {
    --border-opacity: 0 !important;
  }

  .sm\:focus\:border-opacity-12:focus {
    --border-opacity: 0.12 !important;
  }

  .sm\:focus\:border-opacity-25:focus {
    --border-opacity: 0.25 !important;
  }

  .sm\:focus\:border-opacity-38:focus {
    --border-opacity: 0.38 !important;
  }

  .sm\:focus\:border-opacity-50:focus {
    --border-opacity: 0.5 !important;
  }

  .sm\:focus\:border-opacity-54:focus {
    --border-opacity: 0.54 !important;
  }

  .sm\:focus\:border-opacity-70:focus {
    --border-opacity: 0.70 !important;
  }

  .sm\:focus\:border-opacity-75:focus {
    --border-opacity: 0.75 !important;
  }

  .sm\:focus\:border-opacity-84:focus {
    --border-opacity: 0.84 !important;
  }

  .sm\:focus\:border-opacity-100:focus {
    --border-opacity: 1 !important;
  }

  .sm\:rounded-none {
    border-radius: 0 !important;
  }

  .sm\:rounded-sm {
    border-radius: 0.125rem !important;
  }

  .sm\:rounded {
    border-radius: 0.25rem !important;
  }

  .sm\:rounded-md {
    border-radius: 0.375rem !important;
  }

  .sm\:rounded-lg {
    border-radius: 0.5rem !important;
  }

  .sm\:rounded-full {
    border-radius: 9999px !important;
  }

  .sm\:rounded-t-none {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .sm\:rounded-r-none {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .sm\:rounded-b-none {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .sm\:rounded-l-none {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .sm\:rounded-t-sm {
    border-top-left-radius: 0.125rem !important;
    border-top-right-radius: 0.125rem !important;
  }

  .sm\:rounded-r-sm {
    border-top-right-radius: 0.125rem !important;
    border-bottom-right-radius: 0.125rem !important;
  }

  .sm\:rounded-b-sm {
    border-bottom-right-radius: 0.125rem !important;
    border-bottom-left-radius: 0.125rem !important;
  }

  .sm\:rounded-l-sm {
    border-top-left-radius: 0.125rem !important;
    border-bottom-left-radius: 0.125rem !important;
  }

  .sm\:rounded-t {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }

  .sm\:rounded-r {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }

  .sm\:rounded-b {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }

  .sm\:rounded-l {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }

  .sm\:rounded-t-md {
    border-top-left-radius: 0.375rem !important;
    border-top-right-radius: 0.375rem !important;
  }

  .sm\:rounded-r-md {
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }

  .sm\:rounded-b-md {
    border-bottom-right-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }

  .sm\:rounded-l-md {
    border-top-left-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }

  .sm\:rounded-t-lg {
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
  }

  .sm\:rounded-r-lg {
    border-top-right-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }

  .sm\:rounded-b-lg {
    border-bottom-right-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }

  .sm\:rounded-l-lg {
    border-top-left-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }

  .sm\:rounded-t-full {
    border-top-left-radius: 9999px !important;
    border-top-right-radius: 9999px !important;
  }

  .sm\:rounded-r-full {
    border-top-right-radius: 9999px !important;
    border-bottom-right-radius: 9999px !important;
  }

  .sm\:rounded-b-full {
    border-bottom-right-radius: 9999px !important;
    border-bottom-left-radius: 9999px !important;
  }

  .sm\:rounded-l-full {
    border-top-left-radius: 9999px !important;
    border-bottom-left-radius: 9999px !important;
  }

  .sm\:rounded-tl-none {
    border-top-left-radius: 0 !important;
  }

  .sm\:rounded-tr-none {
    border-top-right-radius: 0 !important;
  }

  .sm\:rounded-br-none {
    border-bottom-right-radius: 0 !important;
  }

  .sm\:rounded-bl-none {
    border-bottom-left-radius: 0 !important;
  }

  .sm\:rounded-tl-sm {
    border-top-left-radius: 0.125rem !important;
  }

  .sm\:rounded-tr-sm {
    border-top-right-radius: 0.125rem !important;
  }

  .sm\:rounded-br-sm {
    border-bottom-right-radius: 0.125rem !important;
  }

  .sm\:rounded-bl-sm {
    border-bottom-left-radius: 0.125rem !important;
  }

  .sm\:rounded-tl {
    border-top-left-radius: 0.25rem !important;
  }

  .sm\:rounded-tr {
    border-top-right-radius: 0.25rem !important;
  }

  .sm\:rounded-br {
    border-bottom-right-radius: 0.25rem !important;
  }

  .sm\:rounded-bl {
    border-bottom-left-radius: 0.25rem !important;
  }

  .sm\:rounded-tl-md {
    border-top-left-radius: 0.375rem !important;
  }

  .sm\:rounded-tr-md {
    border-top-right-radius: 0.375rem !important;
  }

  .sm\:rounded-br-md {
    border-bottom-right-radius: 0.375rem !important;
  }

  .sm\:rounded-bl-md {
    border-bottom-left-radius: 0.375rem !important;
  }

  .sm\:rounded-tl-lg {
    border-top-left-radius: 0.5rem !important;
  }

  .sm\:rounded-tr-lg {
    border-top-right-radius: 0.5rem !important;
  }

  .sm\:rounded-br-lg {
    border-bottom-right-radius: 0.5rem !important;
  }

  .sm\:rounded-bl-lg {
    border-bottom-left-radius: 0.5rem !important;
  }

  .sm\:rounded-tl-full {
    border-top-left-radius: 9999px !important;
  }

  .sm\:rounded-tr-full {
    border-top-right-radius: 9999px !important;
  }

  .sm\:rounded-br-full {
    border-bottom-right-radius: 9999px !important;
  }

  .sm\:rounded-bl-full {
    border-bottom-left-radius: 9999px !important;
  }

  .sm\:border-solid {
    border-style: solid !important;
  }

  .sm\:border-dashed {
    border-style: dashed !important;
  }

  .sm\:border-dotted {
    border-style: dotted !important;
  }

  .sm\:border-double {
    border-style: double !important;
  }

  .sm\:border-none {
    border-style: none !important;
  }

  .sm\:border-0 {
    border-width: 0 !important;
  }

  .sm\:border-2 {
    border-width: 2px !important;
  }

  .sm\:border-4 {
    border-width: 4px !important;
  }

  .sm\:border-8 {
    border-width: 8px !important;
  }

  .sm\:border {
    border-width: 1px !important;
  }

  .sm\:border-t-0 {
    border-top-width: 0 !important;
  }

  .sm\:border-r-0 {
    border-right-width: 0 !important;
  }

  .sm\:border-b-0 {
    border-bottom-width: 0 !important;
  }

  .sm\:border-l-0 {
    border-left-width: 0 !important;
  }

  .sm\:border-t-2 {
    border-top-width: 2px !important;
  }

  .sm\:border-r-2 {
    border-right-width: 2px !important;
  }

  .sm\:border-b-2 {
    border-bottom-width: 2px !important;
  }

  .sm\:border-l-2 {
    border-left-width: 2px !important;
  }

  .sm\:border-t-4 {
    border-top-width: 4px !important;
  }

  .sm\:border-r-4 {
    border-right-width: 4px !important;
  }

  .sm\:border-b-4 {
    border-bottom-width: 4px !important;
  }

  .sm\:border-l-4 {
    border-left-width: 4px !important;
  }

  .sm\:border-t-8 {
    border-top-width: 8px !important;
  }

  .sm\:border-r-8 {
    border-right-width: 8px !important;
  }

  .sm\:border-b-8 {
    border-bottom-width: 8px !important;
  }

  .sm\:border-l-8 {
    border-left-width: 8px !important;
  }

  .sm\:border-t {
    border-top-width: 1px !important;
  }

  .sm\:border-r {
    border-right-width: 1px !important;
  }

  .sm\:border-b {
    border-bottom-width: 1px !important;
  }

  .sm\:border-l {
    border-left-width: 1px !important;
  }

  .sm\:first\:border-0:first-child {
    border-width: 0 !important;
  }

  .sm\:first\:border-2:first-child {
    border-width: 2px !important;
  }

  .sm\:first\:border-4:first-child {
    border-width: 4px !important;
  }

  .sm\:first\:border-8:first-child {
    border-width: 8px !important;
  }

  .sm\:first\:border:first-child {
    border-width: 1px !important;
  }

  .sm\:first\:border-t-0:first-child {
    border-top-width: 0 !important;
  }

  .sm\:first\:border-r-0:first-child {
    border-right-width: 0 !important;
  }

  .sm\:first\:border-b-0:first-child {
    border-bottom-width: 0 !important;
  }

  .sm\:first\:border-l-0:first-child {
    border-left-width: 0 !important;
  }

  .sm\:first\:border-t-2:first-child {
    border-top-width: 2px !important;
  }

  .sm\:first\:border-r-2:first-child {
    border-right-width: 2px !important;
  }

  .sm\:first\:border-b-2:first-child {
    border-bottom-width: 2px !important;
  }

  .sm\:first\:border-l-2:first-child {
    border-left-width: 2px !important;
  }

  .sm\:first\:border-t-4:first-child {
    border-top-width: 4px !important;
  }

  .sm\:first\:border-r-4:first-child {
    border-right-width: 4px !important;
  }

  .sm\:first\:border-b-4:first-child {
    border-bottom-width: 4px !important;
  }

  .sm\:first\:border-l-4:first-child {
    border-left-width: 4px !important;
  }

  .sm\:first\:border-t-8:first-child {
    border-top-width: 8px !important;
  }

  .sm\:first\:border-r-8:first-child {
    border-right-width: 8px !important;
  }

  .sm\:first\:border-b-8:first-child {
    border-bottom-width: 8px !important;
  }

  .sm\:first\:border-l-8:first-child {
    border-left-width: 8px !important;
  }

  .sm\:first\:border-t:first-child {
    border-top-width: 1px !important;
  }

  .sm\:first\:border-r:first-child {
    border-right-width: 1px !important;
  }

  .sm\:first\:border-b:first-child {
    border-bottom-width: 1px !important;
  }

  .sm\:first\:border-l:first-child {
    border-left-width: 1px !important;
  }

  .sm\:last\:border-0:last-child {
    border-width: 0 !important;
  }

  .sm\:last\:border-2:last-child {
    border-width: 2px !important;
  }

  .sm\:last\:border-4:last-child {
    border-width: 4px !important;
  }

  .sm\:last\:border-8:last-child {
    border-width: 8px !important;
  }

  .sm\:last\:border:last-child {
    border-width: 1px !important;
  }

  .sm\:last\:border-t-0:last-child {
    border-top-width: 0 !important;
  }

  .sm\:last\:border-r-0:last-child {
    border-right-width: 0 !important;
  }

  .sm\:last\:border-b-0:last-child {
    border-bottom-width: 0 !important;
  }

  .sm\:last\:border-l-0:last-child {
    border-left-width: 0 !important;
  }

  .sm\:last\:border-t-2:last-child {
    border-top-width: 2px !important;
  }

  .sm\:last\:border-r-2:last-child {
    border-right-width: 2px !important;
  }

  .sm\:last\:border-b-2:last-child {
    border-bottom-width: 2px !important;
  }

  .sm\:last\:border-l-2:last-child {
    border-left-width: 2px !important;
  }

  .sm\:last\:border-t-4:last-child {
    border-top-width: 4px !important;
  }

  .sm\:last\:border-r-4:last-child {
    border-right-width: 4px !important;
  }

  .sm\:last\:border-b-4:last-child {
    border-bottom-width: 4px !important;
  }

  .sm\:last\:border-l-4:last-child {
    border-left-width: 4px !important;
  }

  .sm\:last\:border-t-8:last-child {
    border-top-width: 8px !important;
  }

  .sm\:last\:border-r-8:last-child {
    border-right-width: 8px !important;
  }

  .sm\:last\:border-b-8:last-child {
    border-bottom-width: 8px !important;
  }

  .sm\:last\:border-l-8:last-child {
    border-left-width: 8px !important;
  }

  .sm\:last\:border-t:last-child {
    border-top-width: 1px !important;
  }

  .sm\:last\:border-r:last-child {
    border-right-width: 1px !important;
  }

  .sm\:last\:border-b:last-child {
    border-bottom-width: 1px !important;
  }

  .sm\:last\:border-l:last-child {
    border-left-width: 1px !important;
  }

  .sm\:box-border {
    box-sizing: border-box !important;
  }

  .sm\:box-content {
    box-sizing: content-box !important;
  }

  .sm\:block {
    display: block !important;
  }

  .sm\:inline-block {
    display: inline-block !important;
  }

  .sm\:inline {
    display: inline !important;
  }

  .sm\:flex {
    display: flex !important;
  }

  .sm\:inline-flex {
    display: inline-flex !important;
  }

  .sm\:table {
    display: table !important;
  }

  .sm\:table-caption {
    display: table-caption !important;
  }

  .sm\:table-cell {
    display: table-cell !important;
  }

  .sm\:table-column {
    display: table-column !important;
  }

  .sm\:table-column-group {
    display: table-column-group !important;
  }

  .sm\:table-footer-group {
    display: table-footer-group !important;
  }

  .sm\:table-header-group {
    display: table-header-group !important;
  }

  .sm\:table-row-group {
    display: table-row-group !important;
  }

  .sm\:table-row {
    display: table-row !important;
  }

  .sm\:flow-root {
    display: flow-root !important;
  }

  .sm\:grid {
    display: grid !important;
  }

  .sm\:inline-grid {
    display: inline-grid !important;
  }

  .sm\:hidden {
    display: none !important;
  }

  .sm\:flex-row {
    flex-direction: row !important;
  }

  .sm\:flex-row-reverse {
    flex-direction: row-reverse !important;
  }

  .sm\:flex-col {
    flex-direction: column !important;
  }

  .sm\:flex-col-reverse {
    flex-direction: column-reverse !important;
  }

  .sm\:flex-wrap {
    flex-wrap: wrap !important;
  }

  .sm\:flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .sm\:flex-nowrap {
    flex-wrap: nowrap !important;
  }

  .sm\:items-start {
    align-items: flex-start !important;
  }

  .sm\:items-end {
    align-items: flex-end !important;
  }

  .sm\:items-center {
    align-items: center !important;
  }

  .sm\:items-baseline {
    align-items: baseline !important;
  }

  .sm\:items-stretch {
    align-items: stretch !important;
  }

  .sm\:self-auto {
    align-self: auto !important;
  }

  .sm\:self-start {
    align-self: flex-start !important;
  }

  .sm\:self-end {
    align-self: flex-end !important;
  }

  .sm\:self-center {
    align-self: center !important;
  }

  .sm\:self-stretch {
    align-self: stretch !important;
  }

  .sm\:justify-start {
    justify-content: flex-start !important;
  }

  .sm\:justify-end {
    justify-content: flex-end !important;
  }

  .sm\:justify-center {
    justify-content: center !important;
  }

  .sm\:justify-between {
    justify-content: space-between !important;
  }

  .sm\:justify-around {
    justify-content: space-around !important;
  }

  .sm\:justify-evenly {
    justify-content: space-evenly !important;
  }

  .sm\:content-center {
    align-content: center !important;
  }

  .sm\:content-start {
    align-content: flex-start !important;
  }

  .sm\:content-end {
    align-content: flex-end !important;
  }

  .sm\:content-between {
    align-content: space-between !important;
  }

  .sm\:content-around {
    align-content: space-around !important;
  }

  .sm\:flex-0 {
    flex: 0 0 auto !important;
  }

  .sm\:flex-1 {
    flex: 1 1 0% !important;
  }

  .sm\:flex-auto {
    flex: 1 1 auto !important;
  }

  .sm\:flex-initial {
    flex: 0 1 auto !important;
  }

  .sm\:flex-none {
    flex: none !important;
  }

  .sm\:flex-grow-0 {
    flex-grow: 0 !important;
  }

  .sm\:flex-grow {
    flex-grow: 1 !important;
  }

  .sm\:flex-shrink-0 {
    flex-shrink: 0 !important;
  }

  .sm\:flex-shrink {
    flex-shrink: 1 !important;
  }

  .sm\:order-1 {
    order: 1 !important;
  }

  .sm\:order-2 {
    order: 2 !important;
  }

  .sm\:order-3 {
    order: 3 !important;
  }

  .sm\:order-4 {
    order: 4 !important;
  }

  .sm\:order-5 {
    order: 5 !important;
  }

  .sm\:order-6 {
    order: 6 !important;
  }

  .sm\:order-7 {
    order: 7 !important;
  }

  .sm\:order-8 {
    order: 8 !important;
  }

  .sm\:order-9 {
    order: 9 !important;
  }

  .sm\:order-10 {
    order: 10 !important;
  }

  .sm\:order-11 {
    order: 11 !important;
  }

  .sm\:order-12 {
    order: 12 !important;
  }

  .sm\:order-first {
    order: -9999 !important;
  }

  .sm\:order-last {
    order: 9999 !important;
  }

  .sm\:order-none {
    order: 0 !important;
  }

  .sm\:font-thin {
    font-weight: 100 !important;
  }

  .sm\:font-extralight {
    font-weight: 200 !important;
  }

  .sm\:font-light {
    font-weight: 300 !important;
  }

  .sm\:font-normal {
    font-weight: 400 !important;
  }

  .sm\:font-medium {
    font-weight: 500 !important;
  }

  .sm\:font-semibold {
    font-weight: 600 !important;
  }

  .sm\:font-bold {
    font-weight: 700 !important;
  }

  .sm\:font-extrabold {
    font-weight: 800 !important;
  }

  .sm\:font-black {
    font-weight: 900 !important;
  }

  .sm\:h-0 {
    height: 0 !important;
  }

  .sm\:h-1 {
    height: 0.25rem !important;
  }

  .sm\:h-2 {
    height: 0.5rem !important;
  }

  .sm\:h-3 {
    height: 0.75rem !important;
  }

  .sm\:h-4 {
    height: 1rem !important;
  }

  .sm\:h-5 {
    height: 1.25rem !important;
  }

  .sm\:h-6 {
    height: 1.5rem !important;
  }

  .sm\:h-8 {
    height: 2rem !important;
  }

  .sm\:h-10 {
    height: 2.5rem !important;
  }

  .sm\:h-12 {
    height: 3rem !important;
  }

  .sm\:h-14 {
    height: 3.5rem !important;
  }

  .sm\:h-16 {
    height: 4rem !important;
  }

  .sm\:h-18 {
    height: 4.5rem !important;
  }

  .sm\:h-20 {
    height: 5rem !important;
  }

  .sm\:h-22 {
    height: 5.5rem !important;
  }

  .sm\:h-24 {
    height: 6rem !important;
  }

  .sm\:h-26 {
    height: 6.5rem !important;
  }

  .sm\:h-28 {
    height: 7rem !important;
  }

  .sm\:h-30 {
    height: 7.5rem !important;
  }

  .sm\:h-32 {
    height: 8rem !important;
  }

  .sm\:h-36 {
    height: 9rem !important;
  }

  .sm\:h-40 {
    height: 10rem !important;
  }

  .sm\:h-48 {
    height: 12rem !important;
  }

  .sm\:h-50 {
    height: 12.5rem !important;
  }

  .sm\:h-56 {
    height: 14rem !important;
  }

  .sm\:h-60 {
    height: 15rem !important;
  }

  .sm\:h-64 {
    height: 16rem !important;
  }

  .sm\:h-80 {
    height: 20rem !important;
  }

  .sm\:h-90 {
    height: 24rem !important;
  }

  .sm\:h-100 {
    height: 25rem !important;
  }

  .sm\:h-120 {
    height: 30rem !important;
  }

  .sm\:h-128 {
    height: 32rem !important;
  }

  .sm\:h-140 {
    height: 35rem !important;
  }

  .sm\:h-160 {
    height: 40rem !important;
  }

  .sm\:h-180 {
    height: 45rem !important;
  }

  .sm\:h-192 {
    height: 48rem !important;
  }

  .sm\:h-200 {
    height: 50rem !important;
  }

  .sm\:h-240 {
    height: 60rem !important;
  }

  .sm\:h-256 {
    height: 64rem !important;
  }

  .sm\:h-280 {
    height: 70rem !important;
  }

  .sm\:h-320 {
    height: 80rem !important;
  }

  .sm\:h-360 {
    height: 90rem !important;
  }

  .sm\:h-400 {
    height: 100rem !important;
  }

  .sm\:h-480 {
    height: 120rem !important;
  }

  .sm\:h-auto {
    height: auto !important;
  }

  .sm\:h-px {
    height: 1px !important;
  }

  .sm\:h-2px {
    height: 2px !important;
  }

  .sm\:h-full {
    height: 100% !important;
  }

  .sm\:h-screen {
    height: 100vh !important;
  }

  .sm\:h-1\/2 {
    height: 50% !important;
  }

  .sm\:h-1\/3 {
    height: 33.33333% !important;
  }

  .sm\:h-2\/3 {
    height: 66.66667% !important;
  }

  .sm\:h-1\/4 {
    height: 25% !important;
  }

  .sm\:h-2\/4 {
    height: 50% !important;
  }

  .sm\:h-3\/4 {
    height: 75% !important;
  }

  .sm\:h-1\/5 {
    height: 20% !important;
  }

  .sm\:h-2\/5 {
    height: 40% !important;
  }

  .sm\:h-3\/5 {
    height: 60% !important;
  }

  .sm\:h-4\/5 {
    height: 80% !important;
  }

  .sm\:h-1\/12 {
    height: 8.33333% !important;
  }

  .sm\:h-2\/12 {
    height: 16.66667% !important;
  }

  .sm\:h-3\/12 {
    height: 25% !important;
  }

  .sm\:h-4\/12 {
    height: 33.33333% !important;
  }

  .sm\:h-5\/12 {
    height: 41.66667% !important;
  }

  .sm\:h-6\/12 {
    height: 50% !important;
  }

  .sm\:h-7\/12 {
    height: 58.33333% !important;
  }

  .sm\:h-8\/12 {
    height: 66.66667% !important;
  }

  .sm\:h-9\/12 {
    height: 75% !important;
  }

  .sm\:h-10\/12 {
    height: 83.33333% !important;
  }

  .sm\:h-11\/12 {
    height: 91.66667% !important;
  }

  .sm\:text-xs {
    font-size: 0.625rem !important;
  }

  .sm\:text-sm {
    font-size: 0.75rem !important;
  }

  .sm\:text-md {
    font-size: 0.8125rem !important;
  }

  .sm\:text-base {
    font-size: 0.875rem !important;
  }

  .sm\:text-lg {
    font-size: 1rem !important;
  }

  .sm\:text-xl {
    font-size: 1.125rem !important;
  }

  .sm\:text-2xl {
    font-size: 1.25rem !important;
  }

  .sm\:text-3xl {
    font-size: 1.5rem !important;
  }

  .sm\:text-4xl {
    font-size: 2rem !important;
  }

  .sm\:text-5xl {
    font-size: 2.25rem !important;
  }

  .sm\:text-6xl {
    font-size: 2.5rem !important;
  }

  .sm\:text-7xl {
    font-size: 3rem !important;
  }

  .sm\:text-8xl {
    font-size: 4rem !important;
  }

  .sm\:text-9xl {
    font-size: 6rem !important;
  }

  .sm\:text-10xl {
    font-size: 8rem !important;
  }

  .sm\:leading-3 {
    line-height: .75rem !important;
  }

  .sm\:leading-4 {
    line-height: 1rem !important;
  }

  .sm\:leading-5 {
    line-height: 1.25rem !important;
  }

  .sm\:leading-6 {
    line-height: 1.5rem !important;
  }

  .sm\:leading-7 {
    line-height: 1.75rem !important;
  }

  .sm\:leading-8 {
    line-height: 2rem !important;
  }

  .sm\:leading-9 {
    line-height: 2.25rem !important;
  }

  .sm\:leading-10 {
    line-height: 2.5rem !important;
  }

  .sm\:leading-none {
    line-height: 1 !important;
  }

  .sm\:leading-tight {
    line-height: 1.25 !important;
  }

  .sm\:leading-snug {
    line-height: 1.375 !important;
  }

  .sm\:leading-normal {
    line-height: 1.5 !important;
  }

  .sm\:leading-relaxed {
    line-height: 1.625 !important;
  }

  .sm\:leading-loose {
    line-height: 2 !important;
  }

  .sm\:list-inside {
    list-style-position: inside !important;
  }

  .sm\:list-outside {
    list-style-position: outside !important;
  }

  .sm\:list-none {
    list-style-type: none !important;
  }

  .sm\:list-disc {
    list-style-type: disc !important;
  }

  .sm\:list-decimal {
    list-style-type: decimal !important;
  }

  .sm\:m-0 {
    margin: 0 !important;
  }

  .sm\:m-1 {
    margin: 0.25rem !important;
  }

  .sm\:m-2 {
    margin: 0.5rem !important;
  }

  .sm\:m-3 {
    margin: 0.75rem !important;
  }

  .sm\:m-4 {
    margin: 1rem !important;
  }

  .sm\:m-5 {
    margin: 1.25rem !important;
  }

  .sm\:m-6 {
    margin: 1.5rem !important;
  }

  .sm\:m-8 {
    margin: 2rem !important;
  }

  .sm\:m-10 {
    margin: 2.5rem !important;
  }

  .sm\:m-12 {
    margin: 3rem !important;
  }

  .sm\:m-14 {
    margin: 3.5rem !important;
  }

  .sm\:m-16 {
    margin: 4rem !important;
  }

  .sm\:m-18 {
    margin: 4.5rem !important;
  }

  .sm\:m-20 {
    margin: 5rem !important;
  }

  .sm\:m-22 {
    margin: 5.5rem !important;
  }

  .sm\:m-24 {
    margin: 6rem !important;
  }

  .sm\:m-26 {
    margin: 6.5rem !important;
  }

  .sm\:m-28 {
    margin: 7rem !important;
  }

  .sm\:m-30 {
    margin: 7.5rem !important;
  }

  .sm\:m-32 {
    margin: 8rem !important;
  }

  .sm\:m-36 {
    margin: 9rem !important;
  }

  .sm\:m-40 {
    margin: 10rem !important;
  }

  .sm\:m-48 {
    margin: 12rem !important;
  }

  .sm\:m-56 {
    margin: 14rem !important;
  }

  .sm\:m-64 {
    margin: 16rem !important;
  }

  .sm\:m-auto {
    margin: auto !important;
  }

  .sm\:m-px {
    margin: 1px !important;
  }

  .sm\:m-2px {
    margin: 2px !important;
  }

  .sm\:-m-1 {
    margin: -0.25rem !important;
  }

  .sm\:-m-2 {
    margin: -0.5rem !important;
  }

  .sm\:-m-3 {
    margin: -0.75rem !important;
  }

  .sm\:-m-4 {
    margin: -1rem !important;
  }

  .sm\:-m-5 {
    margin: -1.25rem !important;
  }

  .sm\:-m-6 {
    margin: -1.5rem !important;
  }

  .sm\:-m-8 {
    margin: -2rem !important;
  }

  .sm\:-m-10 {
    margin: -2.5rem !important;
  }

  .sm\:-m-12 {
    margin: -3rem !important;
  }

  .sm\:-m-14 {
    margin: -3.5rem !important;
  }

  .sm\:-m-16 {
    margin: -4rem !important;
  }

  .sm\:-m-18 {
    margin: -4.5rem !important;
  }

  .sm\:-m-20 {
    margin: -5rem !important;
  }

  .sm\:-m-22 {
    margin: -5.5rem !important;
  }

  .sm\:-m-24 {
    margin: -6rem !important;
  }

  .sm\:-m-26 {
    margin: -6.5rem !important;
  }

  .sm\:-m-28 {
    margin: -7rem !important;
  }

  .sm\:-m-30 {
    margin: -7.5rem !important;
  }

  .sm\:-m-32 {
    margin: -8rem !important;
  }

  .sm\:-m-36 {
    margin: -9rem !important;
  }

  .sm\:-m-40 {
    margin: -10rem !important;
  }

  .sm\:-m-48 {
    margin: -12rem !important;
  }

  .sm\:-m-56 {
    margin: -14rem !important;
  }

  .sm\:-m-64 {
    margin: -16rem !important;
  }

  .sm\:-m-px {
    margin: -1px !important;
  }

  .sm\:-m-2px {
    margin: -2px !important;
  }

  .sm\:my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .sm\:mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .sm\:my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .sm\:mx-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }

  .sm\:my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .sm\:mx-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .sm\:my-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .sm\:mx-3 {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
  }

  .sm\:my-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .sm\:mx-4 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .sm\:my-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .sm\:mx-5 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }

  .sm\:my-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .sm\:mx-6 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .sm\:my-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .sm\:mx-8 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .sm\:my-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .sm\:mx-10 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .sm\:my-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .sm\:mx-12 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .sm\:my-14 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .sm\:mx-14 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }

  .sm\:my-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .sm\:mx-16 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .sm\:my-18 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .sm\:mx-18 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }

  .sm\:my-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .sm\:mx-20 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .sm\:my-22 {
    margin-top: 5.5rem !important;
    margin-bottom: 5.5rem !important;
  }

  .sm\:mx-22 {
    margin-left: 5.5rem !important;
    margin-right: 5.5rem !important;
  }

  .sm\:my-24 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .sm\:mx-24 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }

  .sm\:my-26 {
    margin-top: 6.5rem !important;
    margin-bottom: 6.5rem !important;
  }

  .sm\:mx-26 {
    margin-left: 6.5rem !important;
    margin-right: 6.5rem !important;
  }

  .sm\:my-28 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }

  .sm\:mx-28 {
    margin-left: 7rem !important;
    margin-right: 7rem !important;
  }

  .sm\:my-30 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }

  .sm\:mx-30 {
    margin-left: 7.5rem !important;
    margin-right: 7.5rem !important;
  }

  .sm\:my-32 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .sm\:mx-32 {
    margin-left: 8rem !important;
    margin-right: 8rem !important;
  }

  .sm\:my-36 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }

  .sm\:mx-36 {
    margin-left: 9rem !important;
    margin-right: 9rem !important;
  }

  .sm\:my-40 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .sm\:mx-40 {
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }

  .sm\:my-48 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }

  .sm\:mx-48 {
    margin-left: 12rem !important;
    margin-right: 12rem !important;
  }

  .sm\:my-56 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }

  .sm\:mx-56 {
    margin-left: 14rem !important;
    margin-right: 14rem !important;
  }

  .sm\:my-64 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }

  .sm\:mx-64 {
    margin-left: 16rem !important;
    margin-right: 16rem !important;
  }

  .sm\:my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .sm\:mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .sm\:my-px {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  .sm\:mx-px {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }

  .sm\:my-2px {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }

  .sm\:mx-2px {
    margin-left: 2px !important;
    margin-right: 2px !important;
  }

  .sm\:-my-1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .sm\:-mx-1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }

  .sm\:-my-2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .sm\:-mx-2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }

  .sm\:-my-3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }

  .sm\:-mx-3 {
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important;
  }

  .sm\:-my-4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .sm\:-mx-4 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .sm\:-my-5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .sm\:-mx-5 {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }

  .sm\:-my-6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .sm\:-mx-6 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .sm\:-my-8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .sm\:-mx-8 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .sm\:-my-10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .sm\:-mx-10 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }

  .sm\:-my-12 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .sm\:-mx-12 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .sm\:-my-14 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .sm\:-mx-14 {
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }

  .sm\:-my-16 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .sm\:-mx-16 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .sm\:-my-18 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .sm\:-mx-18 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }

  .sm\:-my-20 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .sm\:-mx-20 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .sm\:-my-22 {
    margin-top: -5.5rem !important;
    margin-bottom: -5.5rem !important;
  }

  .sm\:-mx-22 {
    margin-left: -5.5rem !important;
    margin-right: -5.5rem !important;
  }

  .sm\:-my-24 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .sm\:-mx-24 {
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }

  .sm\:-my-26 {
    margin-top: -6.5rem !important;
    margin-bottom: -6.5rem !important;
  }

  .sm\:-mx-26 {
    margin-left: -6.5rem !important;
    margin-right: -6.5rem !important;
  }

  .sm\:-my-28 {
    margin-top: -7rem !important;
    margin-bottom: -7rem !important;
  }

  .sm\:-mx-28 {
    margin-left: -7rem !important;
    margin-right: -7rem !important;
  }

  .sm\:-my-30 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important;
  }

  .sm\:-mx-30 {
    margin-left: -7.5rem !important;
    margin-right: -7.5rem !important;
  }

  .sm\:-my-32 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .sm\:-mx-32 {
    margin-left: -8rem !important;
    margin-right: -8rem !important;
  }

  .sm\:-my-36 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }

  .sm\:-mx-36 {
    margin-left: -9rem !important;
    margin-right: -9rem !important;
  }

  .sm\:-my-40 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .sm\:-mx-40 {
    margin-left: -10rem !important;
    margin-right: -10rem !important;
  }

  .sm\:-my-48 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }

  .sm\:-mx-48 {
    margin-left: -12rem !important;
    margin-right: -12rem !important;
  }

  .sm\:-my-56 {
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }

  .sm\:-mx-56 {
    margin-left: -14rem !important;
    margin-right: -14rem !important;
  }

  .sm\:-my-64 {
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }

  .sm\:-mx-64 {
    margin-left: -16rem !important;
    margin-right: -16rem !important;
  }

  .sm\:-my-px {
    margin-top: -1px !important;
    margin-bottom: -1px !important;
  }

  .sm\:-mx-px {
    margin-left: -1px !important;
    margin-right: -1px !important;
  }

  .sm\:-my-2px {
    margin-top: -2px !important;
    margin-bottom: -2px !important;
  }

  .sm\:-mx-2px {
    margin-left: -2px !important;
    margin-right: -2px !important;
  }

  .sm\:mt-0 {
    margin-top: 0 !important;
  }

  .sm\:mr-0 {
    margin-right: 0 !important;
  }

  .sm\:mb-0 {
    margin-bottom: 0 !important;
  }

  .sm\:ml-0 {
    margin-left: 0 !important;
  }

  .sm\:mt-1 {
    margin-top: 0.25rem !important;
  }

  .sm\:mr-1 {
    margin-right: 0.25rem !important;
  }

  .sm\:mb-1 {
    margin-bottom: 0.25rem !important;
  }

  .sm\:ml-1 {
    margin-left: 0.25rem !important;
  }

  .sm\:mt-2 {
    margin-top: 0.5rem !important;
  }

  .sm\:mr-2 {
    margin-right: 0.5rem !important;
  }

  .sm\:mb-2 {
    margin-bottom: 0.5rem !important;
  }

  .sm\:ml-2 {
    margin-left: 0.5rem !important;
  }

  .sm\:mt-3 {
    margin-top: 0.75rem !important;
  }

  .sm\:mr-3 {
    margin-right: 0.75rem !important;
  }

  .sm\:mb-3 {
    margin-bottom: 0.75rem !important;
  }

  .sm\:ml-3 {
    margin-left: 0.75rem !important;
  }

  .sm\:mt-4 {
    margin-top: 1rem !important;
  }

  .sm\:mr-4 {
    margin-right: 1rem !important;
  }

  .sm\:mb-4 {
    margin-bottom: 1rem !important;
  }

  .sm\:ml-4 {
    margin-left: 1rem !important;
  }

  .sm\:mt-5 {
    margin-top: 1.25rem !important;
  }

  .sm\:mr-5 {
    margin-right: 1.25rem !important;
  }

  .sm\:mb-5 {
    margin-bottom: 1.25rem !important;
  }

  .sm\:ml-5 {
    margin-left: 1.25rem !important;
  }

  .sm\:mt-6 {
    margin-top: 1.5rem !important;
  }

  .sm\:mr-6 {
    margin-right: 1.5rem !important;
  }

  .sm\:mb-6 {
    margin-bottom: 1.5rem !important;
  }

  .sm\:ml-6 {
    margin-left: 1.5rem !important;
  }

  .sm\:mt-8 {
    margin-top: 2rem !important;
  }

  .sm\:mr-8 {
    margin-right: 2rem !important;
  }

  .sm\:mb-8 {
    margin-bottom: 2rem !important;
  }

  .sm\:ml-8 {
    margin-left: 2rem !important;
  }

  .sm\:mt-10 {
    margin-top: 2.5rem !important;
  }

  .sm\:mr-10 {
    margin-right: 2.5rem !important;
  }

  .sm\:mb-10 {
    margin-bottom: 2.5rem !important;
  }

  .sm\:ml-10 {
    margin-left: 2.5rem !important;
  }

  .sm\:mt-12 {
    margin-top: 3rem !important;
  }

  .sm\:mr-12 {
    margin-right: 3rem !important;
  }

  .sm\:mb-12 {
    margin-bottom: 3rem !important;
  }

  .sm\:ml-12 {
    margin-left: 3rem !important;
  }

  .sm\:mt-14 {
    margin-top: 3.5rem !important;
  }

  .sm\:mr-14 {
    margin-right: 3.5rem !important;
  }

  .sm\:mb-14 {
    margin-bottom: 3.5rem !important;
  }

  .sm\:ml-14 {
    margin-left: 3.5rem !important;
  }

  .sm\:mt-16 {
    margin-top: 4rem !important;
  }

  .sm\:mr-16 {
    margin-right: 4rem !important;
  }

  .sm\:mb-16 {
    margin-bottom: 4rem !important;
  }

  .sm\:ml-16 {
    margin-left: 4rem !important;
  }

  .sm\:mt-18 {
    margin-top: 4.5rem !important;
  }

  .sm\:mr-18 {
    margin-right: 4.5rem !important;
  }

  .sm\:mb-18 {
    margin-bottom: 4.5rem !important;
  }

  .sm\:ml-18 {
    margin-left: 4.5rem !important;
  }

  .sm\:mt-20 {
    margin-top: 5rem !important;
  }

  .sm\:mr-20 {
    margin-right: 5rem !important;
  }

  .sm\:mb-20 {
    margin-bottom: 5rem !important;
  }

  .sm\:ml-20 {
    margin-left: 5rem !important;
  }

  .sm\:mt-22 {
    margin-top: 5.5rem !important;
  }

  .sm\:mr-22 {
    margin-right: 5.5rem !important;
  }

  .sm\:mb-22 {
    margin-bottom: 5.5rem !important;
  }

  .sm\:ml-22 {
    margin-left: 5.5rem !important;
  }

  .sm\:mt-24 {
    margin-top: 6rem !important;
  }

  .sm\:mr-24 {
    margin-right: 6rem !important;
  }

  .sm\:mb-24 {
    margin-bottom: 6rem !important;
  }

  .sm\:ml-24 {
    margin-left: 6rem !important;
  }

  .sm\:mt-26 {
    margin-top: 6.5rem !important;
  }

  .sm\:mr-26 {
    margin-right: 6.5rem !important;
  }

  .sm\:mb-26 {
    margin-bottom: 6.5rem !important;
  }

  .sm\:ml-26 {
    margin-left: 6.5rem !important;
  }

  .sm\:mt-28 {
    margin-top: 7rem !important;
  }

  .sm\:mr-28 {
    margin-right: 7rem !important;
  }

  .sm\:mb-28 {
    margin-bottom: 7rem !important;
  }

  .sm\:ml-28 {
    margin-left: 7rem !important;
  }

  .sm\:mt-30 {
    margin-top: 7.5rem !important;
  }

  .sm\:mr-30 {
    margin-right: 7.5rem !important;
  }

  .sm\:mb-30 {
    margin-bottom: 7.5rem !important;
  }

  .sm\:ml-30 {
    margin-left: 7.5rem !important;
  }

  .sm\:mt-32 {
    margin-top: 8rem !important;
  }

  .sm\:mr-32 {
    margin-right: 8rem !important;
  }

  .sm\:mb-32 {
    margin-bottom: 8rem !important;
  }

  .sm\:ml-32 {
    margin-left: 8rem !important;
  }

  .sm\:mt-36 {
    margin-top: 9rem !important;
  }

  .sm\:mr-36 {
    margin-right: 9rem !important;
  }

  .sm\:mb-36 {
    margin-bottom: 9rem !important;
  }

  .sm\:ml-36 {
    margin-left: 9rem !important;
  }

  .sm\:mt-40 {
    margin-top: 10rem !important;
  }

  .sm\:mr-40 {
    margin-right: 10rem !important;
  }

  .sm\:mb-40 {
    margin-bottom: 10rem !important;
  }

  .sm\:ml-40 {
    margin-left: 10rem !important;
  }

  .sm\:mt-48 {
    margin-top: 12rem !important;
  }

  .sm\:mr-48 {
    margin-right: 12rem !important;
  }

  .sm\:mb-48 {
    margin-bottom: 12rem !important;
  }

  .sm\:ml-48 {
    margin-left: 12rem !important;
  }

  .sm\:mt-56 {
    margin-top: 14rem !important;
  }

  .sm\:mr-56 {
    margin-right: 14rem !important;
  }

  .sm\:mb-56 {
    margin-bottom: 14rem !important;
  }

  .sm\:ml-56 {
    margin-left: 14rem !important;
  }

  .sm\:mt-64 {
    margin-top: 16rem !important;
  }

  .sm\:mr-64 {
    margin-right: 16rem !important;
  }

  .sm\:mb-64 {
    margin-bottom: 16rem !important;
  }

  .sm\:ml-64 {
    margin-left: 16rem !important;
  }

  .sm\:mt-auto {
    margin-top: auto !important;
  }

  .sm\:mr-auto {
    margin-right: auto !important;
  }

  .sm\:mb-auto {
    margin-bottom: auto !important;
  }

  .sm\:ml-auto {
    margin-left: auto !important;
  }

  .sm\:mt-px {
    margin-top: 1px !important;
  }

  .sm\:mr-px {
    margin-right: 1px !important;
  }

  .sm\:mb-px {
    margin-bottom: 1px !important;
  }

  .sm\:ml-px {
    margin-left: 1px !important;
  }

  .sm\:mt-2px {
    margin-top: 2px !important;
  }

  .sm\:mr-2px {
    margin-right: 2px !important;
  }

  .sm\:mb-2px {
    margin-bottom: 2px !important;
  }

  .sm\:ml-2px {
    margin-left: 2px !important;
  }

  .sm\:-mt-1 {
    margin-top: -0.25rem !important;
  }

  .sm\:-mr-1 {
    margin-right: -0.25rem !important;
  }

  .sm\:-mb-1 {
    margin-bottom: -0.25rem !important;
  }

  .sm\:-ml-1 {
    margin-left: -0.25rem !important;
  }

  .sm\:-mt-2 {
    margin-top: -0.5rem !important;
  }

  .sm\:-mr-2 {
    margin-right: -0.5rem !important;
  }

  .sm\:-mb-2 {
    margin-bottom: -0.5rem !important;
  }

  .sm\:-ml-2 {
    margin-left: -0.5rem !important;
  }

  .sm\:-mt-3 {
    margin-top: -0.75rem !important;
  }

  .sm\:-mr-3 {
    margin-right: -0.75rem !important;
  }

  .sm\:-mb-3 {
    margin-bottom: -0.75rem !important;
  }

  .sm\:-ml-3 {
    margin-left: -0.75rem !important;
  }

  .sm\:-mt-4 {
    margin-top: -1rem !important;
  }

  .sm\:-mr-4 {
    margin-right: -1rem !important;
  }

  .sm\:-mb-4 {
    margin-bottom: -1rem !important;
  }

  .sm\:-ml-4 {
    margin-left: -1rem !important;
  }

  .sm\:-mt-5 {
    margin-top: -1.25rem !important;
  }

  .sm\:-mr-5 {
    margin-right: -1.25rem !important;
  }

  .sm\:-mb-5 {
    margin-bottom: -1.25rem !important;
  }

  .sm\:-ml-5 {
    margin-left: -1.25rem !important;
  }

  .sm\:-mt-6 {
    margin-top: -1.5rem !important;
  }

  .sm\:-mr-6 {
    margin-right: -1.5rem !important;
  }

  .sm\:-mb-6 {
    margin-bottom: -1.5rem !important;
  }

  .sm\:-ml-6 {
    margin-left: -1.5rem !important;
  }

  .sm\:-mt-8 {
    margin-top: -2rem !important;
  }

  .sm\:-mr-8 {
    margin-right: -2rem !important;
  }

  .sm\:-mb-8 {
    margin-bottom: -2rem !important;
  }

  .sm\:-ml-8 {
    margin-left: -2rem !important;
  }

  .sm\:-mt-10 {
    margin-top: -2.5rem !important;
  }

  .sm\:-mr-10 {
    margin-right: -2.5rem !important;
  }

  .sm\:-mb-10 {
    margin-bottom: -2.5rem !important;
  }

  .sm\:-ml-10 {
    margin-left: -2.5rem !important;
  }

  .sm\:-mt-12 {
    margin-top: -3rem !important;
  }

  .sm\:-mr-12 {
    margin-right: -3rem !important;
  }

  .sm\:-mb-12 {
    margin-bottom: -3rem !important;
  }

  .sm\:-ml-12 {
    margin-left: -3rem !important;
  }

  .sm\:-mt-14 {
    margin-top: -3.5rem !important;
  }

  .sm\:-mr-14 {
    margin-right: -3.5rem !important;
  }

  .sm\:-mb-14 {
    margin-bottom: -3.5rem !important;
  }

  .sm\:-ml-14 {
    margin-left: -3.5rem !important;
  }

  .sm\:-mt-16 {
    margin-top: -4rem !important;
  }

  .sm\:-mr-16 {
    margin-right: -4rem !important;
  }

  .sm\:-mb-16 {
    margin-bottom: -4rem !important;
  }

  .sm\:-ml-16 {
    margin-left: -4rem !important;
  }

  .sm\:-mt-18 {
    margin-top: -4.5rem !important;
  }

  .sm\:-mr-18 {
    margin-right: -4.5rem !important;
  }

  .sm\:-mb-18 {
    margin-bottom: -4.5rem !important;
  }

  .sm\:-ml-18 {
    margin-left: -4.5rem !important;
  }

  .sm\:-mt-20 {
    margin-top: -5rem !important;
  }

  .sm\:-mr-20 {
    margin-right: -5rem !important;
  }

  .sm\:-mb-20 {
    margin-bottom: -5rem !important;
  }

  .sm\:-ml-20 {
    margin-left: -5rem !important;
  }

  .sm\:-mt-22 {
    margin-top: -5.5rem !important;
  }

  .sm\:-mr-22 {
    margin-right: -5.5rem !important;
  }

  .sm\:-mb-22 {
    margin-bottom: -5.5rem !important;
  }

  .sm\:-ml-22 {
    margin-left: -5.5rem !important;
  }

  .sm\:-mt-24 {
    margin-top: -6rem !important;
  }

  .sm\:-mr-24 {
    margin-right: -6rem !important;
  }

  .sm\:-mb-24 {
    margin-bottom: -6rem !important;
  }

  .sm\:-ml-24 {
    margin-left: -6rem !important;
  }

  .sm\:-mt-26 {
    margin-top: -6.5rem !important;
  }

  .sm\:-mr-26 {
    margin-right: -6.5rem !important;
  }

  .sm\:-mb-26 {
    margin-bottom: -6.5rem !important;
  }

  .sm\:-ml-26 {
    margin-left: -6.5rem !important;
  }

  .sm\:-mt-28 {
    margin-top: -7rem !important;
  }

  .sm\:-mr-28 {
    margin-right: -7rem !important;
  }

  .sm\:-mb-28 {
    margin-bottom: -7rem !important;
  }

  .sm\:-ml-28 {
    margin-left: -7rem !important;
  }

  .sm\:-mt-30 {
    margin-top: -7.5rem !important;
  }

  .sm\:-mr-30 {
    margin-right: -7.5rem !important;
  }

  .sm\:-mb-30 {
    margin-bottom: -7.5rem !important;
  }

  .sm\:-ml-30 {
    margin-left: -7.5rem !important;
  }

  .sm\:-mt-32 {
    margin-top: -8rem !important;
  }

  .sm\:-mr-32 {
    margin-right: -8rem !important;
  }

  .sm\:-mb-32 {
    margin-bottom: -8rem !important;
  }

  .sm\:-ml-32 {
    margin-left: -8rem !important;
  }

  .sm\:-mt-36 {
    margin-top: -9rem !important;
  }

  .sm\:-mr-36 {
    margin-right: -9rem !important;
  }

  .sm\:-mb-36 {
    margin-bottom: -9rem !important;
  }

  .sm\:-ml-36 {
    margin-left: -9rem !important;
  }

  .sm\:-mt-40 {
    margin-top: -10rem !important;
  }

  .sm\:-mr-40 {
    margin-right: -10rem !important;
  }

  .sm\:-mb-40 {
    margin-bottom: -10rem !important;
  }

  .sm\:-ml-40 {
    margin-left: -10rem !important;
  }

  .sm\:-mt-48 {
    margin-top: -12rem !important;
  }

  .sm\:-mr-48 {
    margin-right: -12rem !important;
  }

  .sm\:-mb-48 {
    margin-bottom: -12rem !important;
  }

  .sm\:-ml-48 {
    margin-left: -12rem !important;
  }

  .sm\:-mt-56 {
    margin-top: -14rem !important;
  }

  .sm\:-mr-56 {
    margin-right: -14rem !important;
  }

  .sm\:-mb-56 {
    margin-bottom: -14rem !important;
  }

  .sm\:-ml-56 {
    margin-left: -14rem !important;
  }

  .sm\:-mt-64 {
    margin-top: -16rem !important;
  }

  .sm\:-mr-64 {
    margin-right: -16rem !important;
  }

  .sm\:-mb-64 {
    margin-bottom: -16rem !important;
  }

  .sm\:-ml-64 {
    margin-left: -16rem !important;
  }

  .sm\:-mt-px {
    margin-top: -1px !important;
  }

  .sm\:-mr-px {
    margin-right: -1px !important;
  }

  .sm\:-mb-px {
    margin-bottom: -1px !important;
  }

  .sm\:-ml-px {
    margin-left: -1px !important;
  }

  .sm\:-mt-2px {
    margin-top: -2px !important;
  }

  .sm\:-mr-2px {
    margin-right: -2px !important;
  }

  .sm\:-mb-2px {
    margin-bottom: -2px !important;
  }

  .sm\:-ml-2px {
    margin-left: -2px !important;
  }

  .sm\:max-h-0 {
    max-height: 0 !important;
  }

  .sm\:max-h-1 {
    max-height: 0.25rem !important;
  }

  .sm\:max-h-2 {
    max-height: 0.5rem !important;
  }

  .sm\:max-h-3 {
    max-height: 0.75rem !important;
  }

  .sm\:max-h-4 {
    max-height: 1rem !important;
  }

  .sm\:max-h-5 {
    max-height: 1.25rem !important;
  }

  .sm\:max-h-6 {
    max-height: 1.5rem !important;
  }

  .sm\:max-h-8 {
    max-height: 2rem !important;
  }

  .sm\:max-h-10 {
    max-height: 2.5rem !important;
  }

  .sm\:max-h-12 {
    max-height: 3rem !important;
  }

  .sm\:max-h-14 {
    max-height: 3.5rem !important;
  }

  .sm\:max-h-16 {
    max-height: 4rem !important;
  }

  .sm\:max-h-18 {
    max-height: 4.5rem !important;
  }

  .sm\:max-h-20 {
    max-height: 5rem !important;
  }

  .sm\:max-h-22 {
    max-height: 5.5rem !important;
  }

  .sm\:max-h-24 {
    max-height: 6rem !important;
  }

  .sm\:max-h-26 {
    max-height: 6.5rem !important;
  }

  .sm\:max-h-28 {
    max-height: 7rem !important;
  }

  .sm\:max-h-30 {
    max-height: 7.5rem !important;
  }

  .sm\:max-h-32 {
    max-height: 8rem !important;
  }

  .sm\:max-h-36 {
    max-height: 9rem !important;
  }

  .sm\:max-h-40 {
    max-height: 10rem !important;
  }

  .sm\:max-h-48 {
    max-height: 12rem !important;
  }

  .sm\:max-h-50 {
    max-height: 12.5rem !important;
  }

  .sm\:max-h-56 {
    max-height: 14rem !important;
  }

  .sm\:max-h-60 {
    max-height: 15rem !important;
  }

  .sm\:max-h-64 {
    max-height: 16rem !important;
  }

  .sm\:max-h-80 {
    max-height: 20rem !important;
  }

  .sm\:max-h-90 {
    max-height: 24rem !important;
  }

  .sm\:max-h-100 {
    max-height: 25rem !important;
  }

  .sm\:max-h-120 {
    max-height: 30rem !important;
  }

  .sm\:max-h-128 {
    max-height: 32rem !important;
  }

  .sm\:max-h-140 {
    max-height: 35rem !important;
  }

  .sm\:max-h-160 {
    max-height: 40rem !important;
  }

  .sm\:max-h-180 {
    max-height: 45rem !important;
  }

  .sm\:max-h-192 {
    max-height: 48rem !important;
  }

  .sm\:max-h-200 {
    max-height: 50rem !important;
  }

  .sm\:max-h-240 {
    max-height: 60rem !important;
  }

  .sm\:max-h-256 {
    max-height: 64rem !important;
  }

  .sm\:max-h-280 {
    max-height: 70rem !important;
  }

  .sm\:max-h-320 {
    max-height: 80rem !important;
  }

  .sm\:max-h-360 {
    max-height: 90rem !important;
  }

  .sm\:max-h-400 {
    max-height: 100rem !important;
  }

  .sm\:max-h-480 {
    max-height: 120rem !important;
  }

  .sm\:max-h-full {
    max-height: 100% !important;
  }

  .sm\:max-h-screen {
    max-height: 100vh !important;
  }

  .sm\:max-h-none {
    max-height: none !important;
  }

  .sm\:max-h-px {
    max-height: 1px !important;
  }

  .sm\:max-h-2px {
    max-height: 2px !important;
  }

  .sm\:max-h-1\/2 {
    max-height: 50% !important;
  }

  .sm\:max-h-1\/3 {
    max-height: 33.33333% !important;
  }

  .sm\:max-h-2\/3 {
    max-height: 66.66667% !important;
  }

  .sm\:max-h-1\/4 {
    max-height: 25% !important;
  }

  .sm\:max-h-2\/4 {
    max-height: 50% !important;
  }

  .sm\:max-h-3\/4 {
    max-height: 75% !important;
  }

  .sm\:max-h-1\/5 {
    max-height: 20% !important;
  }

  .sm\:max-h-2\/5 {
    max-height: 40% !important;
  }

  .sm\:max-h-3\/5 {
    max-height: 60% !important;
  }

  .sm\:max-h-4\/5 {
    max-height: 80% !important;
  }

  .sm\:max-h-1\/12 {
    max-height: 8.33333% !important;
  }

  .sm\:max-h-2\/12 {
    max-height: 16.66667% !important;
  }

  .sm\:max-h-3\/12 {
    max-height: 25% !important;
  }

  .sm\:max-h-4\/12 {
    max-height: 33.33333% !important;
  }

  .sm\:max-h-5\/12 {
    max-height: 41.66667% !important;
  }

  .sm\:max-h-6\/12 {
    max-height: 50% !important;
  }

  .sm\:max-h-7\/12 {
    max-height: 58.33333% !important;
  }

  .sm\:max-h-8\/12 {
    max-height: 66.66667% !important;
  }

  .sm\:max-h-9\/12 {
    max-height: 75% !important;
  }

  .sm\:max-h-10\/12 {
    max-height: 83.33333% !important;
  }

  .sm\:max-h-11\/12 {
    max-height: 91.66667% !important;
  }

  .sm\:max-w-0 {
    max-width: 0 !important;
  }

  .sm\:max-w-1 {
    max-width: 0.25rem !important;
  }

  .sm\:max-w-2 {
    max-width: 0.5rem !important;
  }

  .sm\:max-w-3 {
    max-width: 0.75rem !important;
  }

  .sm\:max-w-4 {
    max-width: 1rem !important;
  }

  .sm\:max-w-5 {
    max-width: 1.25rem !important;
  }

  .sm\:max-w-6 {
    max-width: 1.5rem !important;
  }

  .sm\:max-w-8 {
    max-width: 2rem !important;
  }

  .sm\:max-w-10 {
    max-width: 2.5rem !important;
  }

  .sm\:max-w-12 {
    max-width: 3rem !important;
  }

  .sm\:max-w-14 {
    max-width: 3.5rem !important;
  }

  .sm\:max-w-16 {
    max-width: 4rem !important;
  }

  .sm\:max-w-18 {
    max-width: 4.5rem !important;
  }

  .sm\:max-w-20 {
    max-width: 5rem !important;
  }

  .sm\:max-w-22 {
    max-width: 5.5rem !important;
  }

  .sm\:max-w-24 {
    max-width: 6rem !important;
  }

  .sm\:max-w-26 {
    max-width: 6.5rem !important;
  }

  .sm\:max-w-28 {
    max-width: 7rem !important;
  }

  .sm\:max-w-30 {
    max-width: 7.5rem !important;
  }

  .sm\:max-w-32 {
    max-width: 8rem !important;
  }

  .sm\:max-w-36 {
    max-width: 9rem !important;
  }

  .sm\:max-w-40 {
    max-width: 10rem !important;
  }

  .sm\:max-w-48 {
    max-width: 12rem !important;
  }

  .sm\:max-w-50 {
    max-width: 12.5rem !important;
  }

  .sm\:max-w-56 {
    max-width: 14rem !important;
  }

  .sm\:max-w-60 {
    max-width: 15rem !important;
  }

  .sm\:max-w-64 {
    max-width: 16rem !important;
  }

  .sm\:max-w-80 {
    max-width: 20rem !important;
  }

  .sm\:max-w-90 {
    max-width: 24rem !important;
  }

  .sm\:max-w-100 {
    max-width: 25rem !important;
  }

  .sm\:max-w-120 {
    max-width: 30rem !important;
  }

  .sm\:max-w-128 {
    max-width: 32rem !important;
  }

  .sm\:max-w-140 {
    max-width: 35rem !important;
  }

  .sm\:max-w-160 {
    max-width: 40rem !important;
  }

  .sm\:max-w-180 {
    max-width: 45rem !important;
  }

  .sm\:max-w-192 {
    max-width: 48rem !important;
  }

  .sm\:max-w-200 {
    max-width: 50rem !important;
  }

  .sm\:max-w-240 {
    max-width: 60rem !important;
  }

  .sm\:max-w-256 {
    max-width: 64rem !important;
  }

  .sm\:max-w-280 {
    max-width: 70rem !important;
  }

  .sm\:max-w-320 {
    max-width: 80rem !important;
  }

  .sm\:max-w-360 {
    max-width: 90rem !important;
  }

  .sm\:max-w-400 {
    max-width: 100rem !important;
  }

  .sm\:max-w-480 {
    max-width: 120rem !important;
  }

  .sm\:max-w-none {
    max-width: none !important;
  }

  .sm\:max-w-xs {
    max-width: 20rem !important;
  }

  .sm\:max-w-sm {
    max-width: 24rem !important;
  }

  .sm\:max-w-md {
    max-width: 28rem !important;
  }

  .sm\:max-w-lg {
    max-width: 32rem !important;
  }

  .sm\:max-w-xl {
    max-width: 36rem !important;
  }

  .sm\:max-w-2xl {
    max-width: 42rem !important;
  }

  .sm\:max-w-3xl {
    max-width: 48rem !important;
  }

  .sm\:max-w-4xl {
    max-width: 56rem !important;
  }

  .sm\:max-w-5xl {
    max-width: 64rem !important;
  }

  .sm\:max-w-6xl {
    max-width: 72rem !important;
  }

  .sm\:max-w-full {
    max-width: 100% !important;
  }

  .sm\:max-w-screen {
    max-width: 100vw !important;
  }

  .sm\:max-w-px {
    max-width: 1px !important;
  }

  .sm\:max-w-2px {
    max-width: 2px !important;
  }

  .sm\:max-w-1\/2 {
    max-width: 50% !important;
  }

  .sm\:max-w-1\/3 {
    max-width: 33.33333% !important;
  }

  .sm\:max-w-2\/3 {
    max-width: 66.66667% !important;
  }

  .sm\:max-w-1\/4 {
    max-width: 25% !important;
  }

  .sm\:max-w-2\/4 {
    max-width: 50% !important;
  }

  .sm\:max-w-3\/4 {
    max-width: 75% !important;
  }

  .sm\:max-w-1\/5 {
    max-width: 20% !important;
  }

  .sm\:max-w-2\/5 {
    max-width: 40% !important;
  }

  .sm\:max-w-3\/5 {
    max-width: 60% !important;
  }

  .sm\:max-w-4\/5 {
    max-width: 80% !important;
  }

  .sm\:max-w-1\/12 {
    max-width: 8.33333% !important;
  }

  .sm\:max-w-2\/12 {
    max-width: 16.66667% !important;
  }

  .sm\:max-w-3\/12 {
    max-width: 25% !important;
  }

  .sm\:max-w-4\/12 {
    max-width: 33.33333% !important;
  }

  .sm\:max-w-5\/12 {
    max-width: 41.66667% !important;
  }

  .sm\:max-w-6\/12 {
    max-width: 50% !important;
  }

  .sm\:max-w-7\/12 {
    max-width: 58.33333% !important;
  }

  .sm\:max-w-8\/12 {
    max-width: 66.66667% !important;
  }

  .sm\:max-w-9\/12 {
    max-width: 75% !important;
  }

  .sm\:max-w-10\/12 {
    max-width: 83.33333% !important;
  }

  .sm\:max-w-11\/12 {
    max-width: 91.66667% !important;
  }

  .sm\:min-h-0 {
    min-height: 0 !important;
  }

  .sm\:min-h-1 {
    min-height: 0.25rem !important;
  }

  .sm\:min-h-2 {
    min-height: 0.5rem !important;
  }

  .sm\:min-h-3 {
    min-height: 0.75rem !important;
  }

  .sm\:min-h-4 {
    min-height: 1rem !important;
  }

  .sm\:min-h-5 {
    min-height: 1.25rem !important;
  }

  .sm\:min-h-6 {
    min-height: 1.5rem !important;
  }

  .sm\:min-h-8 {
    min-height: 2rem !important;
  }

  .sm\:min-h-10 {
    min-height: 2.5rem !important;
  }

  .sm\:min-h-12 {
    min-height: 3rem !important;
  }

  .sm\:min-h-14 {
    min-height: 3.5rem !important;
  }

  .sm\:min-h-16 {
    min-height: 4rem !important;
  }

  .sm\:min-h-18 {
    min-height: 4.5rem !important;
  }

  .sm\:min-h-20 {
    min-height: 5rem !important;
  }

  .sm\:min-h-22 {
    min-height: 5.5rem !important;
  }

  .sm\:min-h-24 {
    min-height: 6rem !important;
  }

  .sm\:min-h-26 {
    min-height: 6.5rem !important;
  }

  .sm\:min-h-28 {
    min-height: 7rem !important;
  }

  .sm\:min-h-30 {
    min-height: 7.5rem !important;
  }

  .sm\:min-h-32 {
    min-height: 8rem !important;
  }

  .sm\:min-h-36 {
    min-height: 9rem !important;
  }

  .sm\:min-h-40 {
    min-height: 10rem !important;
  }

  .sm\:min-h-48 {
    min-height: 12rem !important;
  }

  .sm\:min-h-50 {
    min-height: 12.5rem !important;
  }

  .sm\:min-h-56 {
    min-height: 14rem !important;
  }

  .sm\:min-h-60 {
    min-height: 15rem !important;
  }

  .sm\:min-h-64 {
    min-height: 16rem !important;
  }

  .sm\:min-h-80 {
    min-height: 20rem !important;
  }

  .sm\:min-h-90 {
    min-height: 24rem !important;
  }

  .sm\:min-h-100 {
    min-height: 25rem !important;
  }

  .sm\:min-h-120 {
    min-height: 30rem !important;
  }

  .sm\:min-h-128 {
    min-height: 32rem !important;
  }

  .sm\:min-h-140 {
    min-height: 35rem !important;
  }

  .sm\:min-h-160 {
    min-height: 40rem !important;
  }

  .sm\:min-h-180 {
    min-height: 45rem !important;
  }

  .sm\:min-h-192 {
    min-height: 48rem !important;
  }

  .sm\:min-h-200 {
    min-height: 50rem !important;
  }

  .sm\:min-h-240 {
    min-height: 60rem !important;
  }

  .sm\:min-h-256 {
    min-height: 64rem !important;
  }

  .sm\:min-h-280 {
    min-height: 70rem !important;
  }

  .sm\:min-h-320 {
    min-height: 80rem !important;
  }

  .sm\:min-h-360 {
    min-height: 90rem !important;
  }

  .sm\:min-h-400 {
    min-height: 100rem !important;
  }

  .sm\:min-h-480 {
    min-height: 120rem !important;
  }

  .sm\:min-h-full {
    min-height: 100% !important;
  }

  .sm\:min-h-screen {
    min-height: 100vh !important;
  }

  .sm\:min-h-px {
    min-height: 1px !important;
  }

  .sm\:min-h-2px {
    min-height: 2px !important;
  }

  .sm\:min-h-1\/2 {
    min-height: 50% !important;
  }

  .sm\:min-h-1\/3 {
    min-height: 33.33333% !important;
  }

  .sm\:min-h-2\/3 {
    min-height: 66.66667% !important;
  }

  .sm\:min-h-1\/4 {
    min-height: 25% !important;
  }

  .sm\:min-h-2\/4 {
    min-height: 50% !important;
  }

  .sm\:min-h-3\/4 {
    min-height: 75% !important;
  }

  .sm\:min-h-1\/5 {
    min-height: 20% !important;
  }

  .sm\:min-h-2\/5 {
    min-height: 40% !important;
  }

  .sm\:min-h-3\/5 {
    min-height: 60% !important;
  }

  .sm\:min-h-4\/5 {
    min-height: 80% !important;
  }

  .sm\:min-h-1\/12 {
    min-height: 8.33333% !important;
  }

  .sm\:min-h-2\/12 {
    min-height: 16.66667% !important;
  }

  .sm\:min-h-3\/12 {
    min-height: 25% !important;
  }

  .sm\:min-h-4\/12 {
    min-height: 33.33333% !important;
  }

  .sm\:min-h-5\/12 {
    min-height: 41.66667% !important;
  }

  .sm\:min-h-6\/12 {
    min-height: 50% !important;
  }

  .sm\:min-h-7\/12 {
    min-height: 58.33333% !important;
  }

  .sm\:min-h-8\/12 {
    min-height: 66.66667% !important;
  }

  .sm\:min-h-9\/12 {
    min-height: 75% !important;
  }

  .sm\:min-h-10\/12 {
    min-height: 83.33333% !important;
  }

  .sm\:min-h-11\/12 {
    min-height: 91.66667% !important;
  }

  .sm\:min-w-0 {
    min-width: 0 !important;
  }

  .sm\:min-w-1 {
    min-width: 0.25rem !important;
  }

  .sm\:min-w-2 {
    min-width: 0.5rem !important;
  }

  .sm\:min-w-3 {
    min-width: 0.75rem !important;
  }

  .sm\:min-w-4 {
    min-width: 1rem !important;
  }

  .sm\:min-w-5 {
    min-width: 1.25rem !important;
  }

  .sm\:min-w-6 {
    min-width: 1.5rem !important;
  }

  .sm\:min-w-8 {
    min-width: 2rem !important;
  }

  .sm\:min-w-10 {
    min-width: 2.5rem !important;
  }

  .sm\:min-w-12 {
    min-width: 3rem !important;
  }

  .sm\:min-w-14 {
    min-width: 3.5rem !important;
  }

  .sm\:min-w-16 {
    min-width: 4rem !important;
  }

  .sm\:min-w-18 {
    min-width: 4.5rem !important;
  }

  .sm\:min-w-20 {
    min-width: 5rem !important;
  }

  .sm\:min-w-22 {
    min-width: 5.5rem !important;
  }

  .sm\:min-w-24 {
    min-width: 6rem !important;
  }

  .sm\:min-w-26 {
    min-width: 6.5rem !important;
  }

  .sm\:min-w-28 {
    min-width: 7rem !important;
  }

  .sm\:min-w-30 {
    min-width: 7.5rem !important;
  }

  .sm\:min-w-32 {
    min-width: 8rem !important;
  }

  .sm\:min-w-36 {
    min-width: 9rem !important;
  }

  .sm\:min-w-40 {
    min-width: 10rem !important;
  }

  .sm\:min-w-48 {
    min-width: 12rem !important;
  }

  .sm\:min-w-50 {
    min-width: 12.5rem !important;
  }

  .sm\:min-w-56 {
    min-width: 14rem !important;
  }

  .sm\:min-w-60 {
    min-width: 15rem !important;
  }

  .sm\:min-w-64 {
    min-width: 16rem !important;
  }

  .sm\:min-w-80 {
    min-width: 20rem !important;
  }

  .sm\:min-w-90 {
    min-width: 24rem !important;
  }

  .sm\:min-w-100 {
    min-width: 25rem !important;
  }

  .sm\:min-w-120 {
    min-width: 30rem !important;
  }

  .sm\:min-w-128 {
    min-width: 32rem !important;
  }

  .sm\:min-w-140 {
    min-width: 35rem !important;
  }

  .sm\:min-w-160 {
    min-width: 40rem !important;
  }

  .sm\:min-w-180 {
    min-width: 45rem !important;
  }

  .sm\:min-w-192 {
    min-width: 48rem !important;
  }

  .sm\:min-w-200 {
    min-width: 50rem !important;
  }

  .sm\:min-w-240 {
    min-width: 60rem !important;
  }

  .sm\:min-w-256 {
    min-width: 64rem !important;
  }

  .sm\:min-w-280 {
    min-width: 70rem !important;
  }

  .sm\:min-w-320 {
    min-width: 80rem !important;
  }

  .sm\:min-w-360 {
    min-width: 90rem !important;
  }

  .sm\:min-w-400 {
    min-width: 100rem !important;
  }

  .sm\:min-w-480 {
    min-width: 120rem !important;
  }

  .sm\:min-w-full {
    min-width: 100% !important;
  }

  .sm\:min-w-screen {
    min-width: 100vw !important;
  }

  .sm\:min-w-px {
    min-width: 1px !important;
  }

  .sm\:min-w-2px {
    min-width: 2px !important;
  }

  .sm\:min-w-1\/2 {
    min-width: 50% !important;
  }

  .sm\:min-w-1\/3 {
    min-width: 33.33333% !important;
  }

  .sm\:min-w-2\/3 {
    min-width: 66.66667% !important;
  }

  .sm\:min-w-1\/4 {
    min-width: 25% !important;
  }

  .sm\:min-w-2\/4 {
    min-width: 50% !important;
  }

  .sm\:min-w-3\/4 {
    min-width: 75% !important;
  }

  .sm\:min-w-1\/5 {
    min-width: 20% !important;
  }

  .sm\:min-w-2\/5 {
    min-width: 40% !important;
  }

  .sm\:min-w-3\/5 {
    min-width: 60% !important;
  }

  .sm\:min-w-4\/5 {
    min-width: 80% !important;
  }

  .sm\:min-w-1\/12 {
    min-width: 8.33333% !important;
  }

  .sm\:min-w-2\/12 {
    min-width: 16.66667% !important;
  }

  .sm\:min-w-3\/12 {
    min-width: 25% !important;
  }

  .sm\:min-w-4\/12 {
    min-width: 33.33333% !important;
  }

  .sm\:min-w-5\/12 {
    min-width: 41.66667% !important;
  }

  .sm\:min-w-6\/12 {
    min-width: 50% !important;
  }

  .sm\:min-w-7\/12 {
    min-width: 58.33333% !important;
  }

  .sm\:min-w-8\/12 {
    min-width: 66.66667% !important;
  }

  .sm\:min-w-9\/12 {
    min-width: 75% !important;
  }

  .sm\:min-w-10\/12 {
    min-width: 83.33333% !important;
  }

  .sm\:min-w-11\/12 {
    min-width: 91.66667% !important;
  }

  .sm\:object-contain {
    -o-object-fit: contain !important;
       object-fit: contain !important;
  }

  .sm\:object-cover {
    -o-object-fit: cover !important;
       object-fit: cover !important;
  }

  .sm\:object-fill {
    -o-object-fit: fill !important;
       object-fit: fill !important;
  }

  .sm\:object-none {
    -o-object-fit: none !important;
       object-fit: none !important;
  }

  .sm\:object-scale-down {
    -o-object-fit: scale-down !important;
       object-fit: scale-down !important;
  }

  .sm\:object-bottom {
    -o-object-position: bottom !important;
       object-position: bottom !important;
  }

  .sm\:object-center {
    -o-object-position: center !important;
       object-position: center !important;
  }

  .sm\:object-left {
    -o-object-position: left !important;
       object-position: left !important;
  }

  .sm\:object-left-bottom {
    -o-object-position: left bottom !important;
       object-position: left bottom !important;
  }

  .sm\:object-left-top {
    -o-object-position: left top !important;
       object-position: left top !important;
  }

  .sm\:object-right {
    -o-object-position: right !important;
       object-position: right !important;
  }

  .sm\:object-right-bottom {
    -o-object-position: right bottom !important;
       object-position: right bottom !important;
  }

  .sm\:object-right-top {
    -o-object-position: right top !important;
       object-position: right top !important;
  }

  .sm\:object-top {
    -o-object-position: top !important;
       object-position: top !important;
  }

  .sm\:opacity-0 {
    opacity: 0 !important;
  }

  .sm\:opacity-12 {
    opacity: 0.12 !important;
  }

  .sm\:opacity-25 {
    opacity: 0.25 !important;
  }

  .sm\:opacity-38 {
    opacity: 0.38 !important;
  }

  .sm\:opacity-50 {
    opacity: 0.5 !important;
  }

  .sm\:opacity-54 {
    opacity: 0.54 !important;
  }

  .sm\:opacity-70 {
    opacity: 0.70 !important;
  }

  .sm\:opacity-75 {
    opacity: 0.75 !important;
  }

  .sm\:opacity-84 {
    opacity: 0.84 !important;
  }

  .sm\:opacity-100 {
    opacity: 1 !important;
  }

  .sm\:hover\:opacity-0:hover {
    opacity: 0 !important;
  }

  .sm\:hover\:opacity-12:hover {
    opacity: 0.12 !important;
  }

  .sm\:hover\:opacity-25:hover {
    opacity: 0.25 !important;
  }

  .sm\:hover\:opacity-38:hover {
    opacity: 0.38 !important;
  }

  .sm\:hover\:opacity-50:hover {
    opacity: 0.5 !important;
  }

  .sm\:hover\:opacity-54:hover {
    opacity: 0.54 !important;
  }

  .sm\:hover\:opacity-70:hover {
    opacity: 0.70 !important;
  }

  .sm\:hover\:opacity-75:hover {
    opacity: 0.75 !important;
  }

  .sm\:hover\:opacity-84:hover {
    opacity: 0.84 !important;
  }

  .sm\:hover\:opacity-100:hover {
    opacity: 1 !important;
  }

  .sm\:focus\:opacity-0:focus {
    opacity: 0 !important;
  }

  .sm\:focus\:opacity-12:focus {
    opacity: 0.12 !important;
  }

  .sm\:focus\:opacity-25:focus {
    opacity: 0.25 !important;
  }

  .sm\:focus\:opacity-38:focus {
    opacity: 0.38 !important;
  }

  .sm\:focus\:opacity-50:focus {
    opacity: 0.5 !important;
  }

  .sm\:focus\:opacity-54:focus {
    opacity: 0.54 !important;
  }

  .sm\:focus\:opacity-70:focus {
    opacity: 0.70 !important;
  }

  .sm\:focus\:opacity-75:focus {
    opacity: 0.75 !important;
  }

  .sm\:focus\:opacity-84:focus {
    opacity: 0.84 !important;
  }

  .sm\:focus\:opacity-100:focus {
    opacity: 1 !important;
  }

  .sm\:outline-none {
    outline: 0 !important;
  }

  .sm\:focus\:outline-none:focus {
    outline: 0 !important;
  }

  .sm\:overflow-auto {
    overflow: auto !important;
  }

  .sm\:overflow-hidden {
    overflow: hidden !important;
  }

  .sm\:overflow-visible {
    overflow: visible !important;
  }

  .sm\:overflow-scroll {
    overflow: scroll !important;
  }

  .sm\:overflow-x-auto {
    overflow-x: auto !important;
  }

  .sm\:overflow-y-auto {
    overflow-y: auto !important;
  }

  .sm\:overflow-x-hidden {
    overflow-x: hidden !important;
  }

  .sm\:overflow-y-hidden {
    overflow-y: hidden !important;
  }

  .sm\:overflow-x-visible {
    overflow-x: visible !important;
  }

  .sm\:overflow-y-visible {
    overflow-y: visible !important;
  }

  .sm\:overflow-x-scroll {
    overflow-x: scroll !important;
  }

  .sm\:overflow-y-scroll {
    overflow-y: scroll !important;
  }

  .sm\:scrolling-touch {
    -webkit-overflow-scrolling: touch !important;
  }

  .sm\:scrolling-auto {
    -webkit-overflow-scrolling: auto !important;
  }

  .sm\:p-0 {
    padding: 0 !important;
  }

  .sm\:p-1 {
    padding: 0.25rem !important;
  }

  .sm\:p-2 {
    padding: 0.5rem !important;
  }

  .sm\:p-3 {
    padding: 0.75rem !important;
  }

  .sm\:p-4 {
    padding: 1rem !important;
  }

  .sm\:p-5 {
    padding: 1.25rem !important;
  }

  .sm\:p-6 {
    padding: 1.5rem !important;
  }

  .sm\:p-8 {
    padding: 2rem !important;
  }

  .sm\:p-10 {
    padding: 2.5rem !important;
  }

  .sm\:p-12 {
    padding: 3rem !important;
  }

  .sm\:p-14 {
    padding: 3.5rem !important;
  }

  .sm\:p-16 {
    padding: 4rem !important;
  }

  .sm\:p-18 {
    padding: 4.5rem !important;
  }

  .sm\:p-20 {
    padding: 5rem !important;
  }

  .sm\:p-22 {
    padding: 5.5rem !important;
  }

  .sm\:p-24 {
    padding: 6rem !important;
  }

  .sm\:p-26 {
    padding: 6.5rem !important;
  }

  .sm\:p-28 {
    padding: 7rem !important;
  }

  .sm\:p-30 {
    padding: 7.5rem !important;
  }

  .sm\:p-32 {
    padding: 8rem !important;
  }

  .sm\:p-36 {
    padding: 9rem !important;
  }

  .sm\:p-40 {
    padding: 10rem !important;
  }

  .sm\:p-48 {
    padding: 12rem !important;
  }

  .sm\:p-56 {
    padding: 14rem !important;
  }

  .sm\:p-64 {
    padding: 16rem !important;
  }

  .sm\:p-px {
    padding: 1px !important;
  }

  .sm\:p-2px {
    padding: 2px !important;
  }

  .sm\:py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .sm\:px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .sm\:py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .sm\:px-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }

  .sm\:py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .sm\:px-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .sm\:py-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .sm\:px-3 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }

  .sm\:py-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .sm\:px-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .sm\:py-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .sm\:px-5 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }

  .sm\:py-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .sm\:px-6 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .sm\:py-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .sm\:px-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .sm\:py-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .sm\:px-10 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .sm\:py-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .sm\:px-12 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .sm\:py-14 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .sm\:px-14 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }

  .sm\:py-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .sm\:px-16 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .sm\:py-18 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .sm\:px-18 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }

  .sm\:py-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .sm\:px-20 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .sm\:py-22 {
    padding-top: 5.5rem !important;
    padding-bottom: 5.5rem !important;
  }

  .sm\:px-22 {
    padding-left: 5.5rem !important;
    padding-right: 5.5rem !important;
  }

  .sm\:py-24 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .sm\:px-24 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }

  .sm\:py-26 {
    padding-top: 6.5rem !important;
    padding-bottom: 6.5rem !important;
  }

  .sm\:px-26 {
    padding-left: 6.5rem !important;
    padding-right: 6.5rem !important;
  }

  .sm\:py-28 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }

  .sm\:px-28 {
    padding-left: 7rem !important;
    padding-right: 7rem !important;
  }

  .sm\:py-30 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }

  .sm\:px-30 {
    padding-left: 7.5rem !important;
    padding-right: 7.5rem !important;
  }

  .sm\:py-32 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .sm\:px-32 {
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }

  .sm\:py-36 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }

  .sm\:px-36 {
    padding-left: 9rem !important;
    padding-right: 9rem !important;
  }

  .sm\:py-40 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .sm\:px-40 {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }

  .sm\:py-48 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }

  .sm\:px-48 {
    padding-left: 12rem !important;
    padding-right: 12rem !important;
  }

  .sm\:py-56 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }

  .sm\:px-56 {
    padding-left: 14rem !important;
    padding-right: 14rem !important;
  }

  .sm\:py-64 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }

  .sm\:px-64 {
    padding-left: 16rem !important;
    padding-right: 16rem !important;
  }

  .sm\:py-px {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }

  .sm\:px-px {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }

  .sm\:py-2px {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }

  .sm\:px-2px {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }

  .sm\:pt-0 {
    padding-top: 0 !important;
  }

  .sm\:pr-0 {
    padding-right: 0 !important;
  }

  .sm\:pb-0 {
    padding-bottom: 0 !important;
  }

  .sm\:pl-0 {
    padding-left: 0 !important;
  }

  .sm\:pt-1 {
    padding-top: 0.25rem !important;
  }

  .sm\:pr-1 {
    padding-right: 0.25rem !important;
  }

  .sm\:pb-1 {
    padding-bottom: 0.25rem !important;
  }

  .sm\:pl-1 {
    padding-left: 0.25rem !important;
  }

  .sm\:pt-2 {
    padding-top: 0.5rem !important;
  }

  .sm\:pr-2 {
    padding-right: 0.5rem !important;
  }

  .sm\:pb-2 {
    padding-bottom: 0.5rem !important;
  }

  .sm\:pl-2 {
    padding-left: 0.5rem !important;
  }

  .sm\:pt-3 {
    padding-top: 0.75rem !important;
  }

  .sm\:pr-3 {
    padding-right: 0.75rem !important;
  }

  .sm\:pb-3 {
    padding-bottom: 0.75rem !important;
  }

  .sm\:pl-3 {
    padding-left: 0.75rem !important;
  }

  .sm\:pt-4 {
    padding-top: 1rem !important;
  }

  .sm\:pr-4 {
    padding-right: 1rem !important;
  }

  .sm\:pb-4 {
    padding-bottom: 1rem !important;
  }

  .sm\:pl-4 {
    padding-left: 1rem !important;
  }

  .sm\:pt-5 {
    padding-top: 1.25rem !important;
  }

  .sm\:pr-5 {
    padding-right: 1.25rem !important;
  }

  .sm\:pb-5 {
    padding-bottom: 1.25rem !important;
  }

  .sm\:pl-5 {
    padding-left: 1.25rem !important;
  }

  .sm\:pt-6 {
    padding-top: 1.5rem !important;
  }

  .sm\:pr-6 {
    padding-right: 1.5rem !important;
  }

  .sm\:pb-6 {
    padding-bottom: 1.5rem !important;
  }

  .sm\:pl-6 {
    padding-left: 1.5rem !important;
  }

  .sm\:pt-8 {
    padding-top: 2rem !important;
  }

  .sm\:pr-8 {
    padding-right: 2rem !important;
  }

  .sm\:pb-8 {
    padding-bottom: 2rem !important;
  }

  .sm\:pl-8 {
    padding-left: 2rem !important;
  }

  .sm\:pt-10 {
    padding-top: 2.5rem !important;
  }

  .sm\:pr-10 {
    padding-right: 2.5rem !important;
  }

  .sm\:pb-10 {
    padding-bottom: 2.5rem !important;
  }

  .sm\:pl-10 {
    padding-left: 2.5rem !important;
  }

  .sm\:pt-12 {
    padding-top: 3rem !important;
  }

  .sm\:pr-12 {
    padding-right: 3rem !important;
  }

  .sm\:pb-12 {
    padding-bottom: 3rem !important;
  }

  .sm\:pl-12 {
    padding-left: 3rem !important;
  }

  .sm\:pt-14 {
    padding-top: 3.5rem !important;
  }

  .sm\:pr-14 {
    padding-right: 3.5rem !important;
  }

  .sm\:pb-14 {
    padding-bottom: 3.5rem !important;
  }

  .sm\:pl-14 {
    padding-left: 3.5rem !important;
  }

  .sm\:pt-16 {
    padding-top: 4rem !important;
  }

  .sm\:pr-16 {
    padding-right: 4rem !important;
  }

  .sm\:pb-16 {
    padding-bottom: 4rem !important;
  }

  .sm\:pl-16 {
    padding-left: 4rem !important;
  }

  .sm\:pt-18 {
    padding-top: 4.5rem !important;
  }

  .sm\:pr-18 {
    padding-right: 4.5rem !important;
  }

  .sm\:pb-18 {
    padding-bottom: 4.5rem !important;
  }

  .sm\:pl-18 {
    padding-left: 4.5rem !important;
  }

  .sm\:pt-20 {
    padding-top: 5rem !important;
  }

  .sm\:pr-20 {
    padding-right: 5rem !important;
  }

  .sm\:pb-20 {
    padding-bottom: 5rem !important;
  }

  .sm\:pl-20 {
    padding-left: 5rem !important;
  }

  .sm\:pt-22 {
    padding-top: 5.5rem !important;
  }

  .sm\:pr-22 {
    padding-right: 5.5rem !important;
  }

  .sm\:pb-22 {
    padding-bottom: 5.5rem !important;
  }

  .sm\:pl-22 {
    padding-left: 5.5rem !important;
  }

  .sm\:pt-24 {
    padding-top: 6rem !important;
  }

  .sm\:pr-24 {
    padding-right: 6rem !important;
  }

  .sm\:pb-24 {
    padding-bottom: 6rem !important;
  }

  .sm\:pl-24 {
    padding-left: 6rem !important;
  }

  .sm\:pt-26 {
    padding-top: 6.5rem !important;
  }

  .sm\:pr-26 {
    padding-right: 6.5rem !important;
  }

  .sm\:pb-26 {
    padding-bottom: 6.5rem !important;
  }

  .sm\:pl-26 {
    padding-left: 6.5rem !important;
  }

  .sm\:pt-28 {
    padding-top: 7rem !important;
  }

  .sm\:pr-28 {
    padding-right: 7rem !important;
  }

  .sm\:pb-28 {
    padding-bottom: 7rem !important;
  }

  .sm\:pl-28 {
    padding-left: 7rem !important;
  }

  .sm\:pt-30 {
    padding-top: 7.5rem !important;
  }

  .sm\:pr-30 {
    padding-right: 7.5rem !important;
  }

  .sm\:pb-30 {
    padding-bottom: 7.5rem !important;
  }

  .sm\:pl-30 {
    padding-left: 7.5rem !important;
  }

  .sm\:pt-32 {
    padding-top: 8rem !important;
  }

  .sm\:pr-32 {
    padding-right: 8rem !important;
  }

  .sm\:pb-32 {
    padding-bottom: 8rem !important;
  }

  .sm\:pl-32 {
    padding-left: 8rem !important;
  }

  .sm\:pt-36 {
    padding-top: 9rem !important;
  }

  .sm\:pr-36 {
    padding-right: 9rem !important;
  }

  .sm\:pb-36 {
    padding-bottom: 9rem !important;
  }

  .sm\:pl-36 {
    padding-left: 9rem !important;
  }

  .sm\:pt-40 {
    padding-top: 10rem !important;
  }

  .sm\:pr-40 {
    padding-right: 10rem !important;
  }

  .sm\:pb-40 {
    padding-bottom: 10rem !important;
  }

  .sm\:pl-40 {
    padding-left: 10rem !important;
  }

  .sm\:pt-48 {
    padding-top: 12rem !important;
  }

  .sm\:pr-48 {
    padding-right: 12rem !important;
  }

  .sm\:pb-48 {
    padding-bottom: 12rem !important;
  }

  .sm\:pl-48 {
    padding-left: 12rem !important;
  }

  .sm\:pt-56 {
    padding-top: 14rem !important;
  }

  .sm\:pr-56 {
    padding-right: 14rem !important;
  }

  .sm\:pb-56 {
    padding-bottom: 14rem !important;
  }

  .sm\:pl-56 {
    padding-left: 14rem !important;
  }

  .sm\:pt-64 {
    padding-top: 16rem !important;
  }

  .sm\:pr-64 {
    padding-right: 16rem !important;
  }

  .sm\:pb-64 {
    padding-bottom: 16rem !important;
  }

  .sm\:pl-64 {
    padding-left: 16rem !important;
  }

  .sm\:pt-px {
    padding-top: 1px !important;
  }

  .sm\:pr-px {
    padding-right: 1px !important;
  }

  .sm\:pb-px {
    padding-bottom: 1px !important;
  }

  .sm\:pl-px {
    padding-left: 1px !important;
  }

  .sm\:pt-2px {
    padding-top: 2px !important;
  }

  .sm\:pr-2px {
    padding-right: 2px !important;
  }

  .sm\:pb-2px {
    padding-bottom: 2px !important;
  }

  .sm\:pl-2px {
    padding-left: 2px !important;
  }

  .sm\:placeholder-opacity-0::-webkit-input-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .sm\:placeholder-opacity-0::-moz-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .sm\:placeholder-opacity-0::-ms-input-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .sm\:placeholder-opacity-0::placeholder {
    --placeholder-opacity: 0 !important;
  }

  .sm\:placeholder-opacity-12::-webkit-input-placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .sm\:placeholder-opacity-12::-moz-placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .sm\:placeholder-opacity-12::-ms-input-placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .sm\:placeholder-opacity-12::placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .sm\:placeholder-opacity-25::-webkit-input-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .sm\:placeholder-opacity-25::-moz-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .sm\:placeholder-opacity-25::-ms-input-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .sm\:placeholder-opacity-25::placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .sm\:placeholder-opacity-38::-webkit-input-placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .sm\:placeholder-opacity-38::-moz-placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .sm\:placeholder-opacity-38::-ms-input-placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .sm\:placeholder-opacity-38::placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .sm\:placeholder-opacity-50::-webkit-input-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .sm\:placeholder-opacity-50::-moz-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .sm\:placeholder-opacity-50::-ms-input-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .sm\:placeholder-opacity-50::placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .sm\:placeholder-opacity-54::-webkit-input-placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .sm\:placeholder-opacity-54::-moz-placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .sm\:placeholder-opacity-54::-ms-input-placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .sm\:placeholder-opacity-54::placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .sm\:placeholder-opacity-70::-webkit-input-placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .sm\:placeholder-opacity-70::-moz-placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .sm\:placeholder-opacity-70::-ms-input-placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .sm\:placeholder-opacity-70::placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .sm\:placeholder-opacity-75::-webkit-input-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .sm\:placeholder-opacity-75::-moz-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .sm\:placeholder-opacity-75::-ms-input-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .sm\:placeholder-opacity-75::placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .sm\:placeholder-opacity-84::-webkit-input-placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .sm\:placeholder-opacity-84::-moz-placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .sm\:placeholder-opacity-84::-ms-input-placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .sm\:placeholder-opacity-84::placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .sm\:placeholder-opacity-100::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .sm\:placeholder-opacity-100::-moz-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .sm\:placeholder-opacity-100::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .sm\:placeholder-opacity-100::placeholder {
    --placeholder-opacity: 1 !important;
  }

  .sm\:focus\:placeholder-opacity-0:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .sm\:focus\:placeholder-opacity-0:focus::-moz-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .sm\:focus\:placeholder-opacity-0:focus::-ms-input-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .sm\:focus\:placeholder-opacity-0:focus::placeholder {
    --placeholder-opacity: 0 !important;
  }

  .sm\:focus\:placeholder-opacity-12:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .sm\:focus\:placeholder-opacity-12:focus::-moz-placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .sm\:focus\:placeholder-opacity-12:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .sm\:focus\:placeholder-opacity-12:focus::placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .sm\:focus\:placeholder-opacity-25:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .sm\:focus\:placeholder-opacity-25:focus::-moz-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .sm\:focus\:placeholder-opacity-25:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .sm\:focus\:placeholder-opacity-25:focus::placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .sm\:focus\:placeholder-opacity-38:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .sm\:focus\:placeholder-opacity-38:focus::-moz-placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .sm\:focus\:placeholder-opacity-38:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .sm\:focus\:placeholder-opacity-38:focus::placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .sm\:focus\:placeholder-opacity-50:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .sm\:focus\:placeholder-opacity-50:focus::-moz-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .sm\:focus\:placeholder-opacity-50:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .sm\:focus\:placeholder-opacity-50:focus::placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .sm\:focus\:placeholder-opacity-54:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .sm\:focus\:placeholder-opacity-54:focus::-moz-placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .sm\:focus\:placeholder-opacity-54:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .sm\:focus\:placeholder-opacity-54:focus::placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .sm\:focus\:placeholder-opacity-70:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .sm\:focus\:placeholder-opacity-70:focus::-moz-placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .sm\:focus\:placeholder-opacity-70:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .sm\:focus\:placeholder-opacity-70:focus::placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .sm\:focus\:placeholder-opacity-75:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .sm\:focus\:placeholder-opacity-75:focus::-moz-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .sm\:focus\:placeholder-opacity-75:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .sm\:focus\:placeholder-opacity-75:focus::placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .sm\:focus\:placeholder-opacity-84:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .sm\:focus\:placeholder-opacity-84:focus::-moz-placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .sm\:focus\:placeholder-opacity-84:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .sm\:focus\:placeholder-opacity-84:focus::placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .sm\:focus\:placeholder-opacity-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .sm\:focus\:placeholder-opacity-100:focus::-moz-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .sm\:focus\:placeholder-opacity-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .sm\:focus\:placeholder-opacity-100:focus::placeholder {
    --placeholder-opacity: 1 !important;
  }

  .sm\:pointer-events-none {
    pointer-events: none !important;
  }

  .sm\:pointer-events-auto {
    pointer-events: auto !important;
  }

  .sm\:static {
    position: static !important;
  }

  .sm\:fixed {
    position: fixed !important;
  }

  .sm\:absolute {
    position: absolute !important;
  }

  .sm\:relative {
    position: relative !important;
  }

  .sm\:sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }

  .sm\:inset-0 {
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
  }

  .sm\:inset-auto {
    top: auto !important;
    right: auto !important;
    bottom: auto !important;
    left: auto !important;
  }

  .sm\:inset-y-0 {
    top: 0 !important;
    bottom: 0 !important;
  }

  .sm\:inset-x-0 {
    right: 0 !important;
    left: 0 !important;
  }

  .sm\:inset-y-auto {
    top: auto !important;
    bottom: auto !important;
  }

  .sm\:inset-x-auto {
    right: auto !important;
    left: auto !important;
  }

  .sm\:top-0 {
    top: 0 !important;
  }

  .sm\:right-0 {
    right: 0 !important;
  }

  .sm\:bottom-0 {
    bottom: 0 !important;
  }

  .sm\:left-0 {
    left: 0 !important;
  }

  .sm\:top-auto {
    top: auto !important;
  }

  .sm\:right-auto {
    right: auto !important;
  }

  .sm\:bottom-auto {
    bottom: auto !important;
  }

  .sm\:left-auto {
    left: auto !important;
  }

  .sm\:shadow-xs {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05) !important;
  }

  .sm\:shadow-sm {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  }

  .sm\:shadow {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .sm\:shadow-md {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  }

  .sm\:shadow-lg {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  }

  .sm\:shadow-xl {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
  }

  .sm\:shadow-2xl {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
  }

  .sm\:shadow-inner {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .sm\:ring {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }

  .sm\:shadow-none {
    box-shadow: none !important;
  }

  .sm\:shadow-solid {
    box-shadow: 0 0 0 2px currentColor !important;
  }

  .sm\:hover\:shadow-xs:hover {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05) !important;
  }

  .sm\:hover\:shadow-sm:hover {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  }

  .sm\:hover\:shadow:hover {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .sm\:hover\:shadow-md:hover {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  }

  .sm\:hover\:shadow-lg:hover {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  }

  .sm\:hover\:shadow-xl:hover {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
  }

  .sm\:hover\:shadow-2xl:hover {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
  }

  .sm\:hover\:shadow-inner:hover {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .sm\:hover\:ring:hover {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }

  .sm\:hover\:shadow-none:hover {
    box-shadow: none !important;
  }

  .sm\:hover\:shadow-solid:hover {
    box-shadow: 0 0 0 2px currentColor !important;
  }

  .sm\:focus\:shadow-xs:focus {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05) !important;
  }

  .sm\:focus\:shadow-sm:focus {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  }

  .sm\:focus\:shadow:focus {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .sm\:focus\:shadow-md:focus {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  }

  .sm\:focus\:shadow-lg:focus {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  }

  .sm\:focus\:shadow-xl:focus {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
  }

  .sm\:focus\:shadow-2xl:focus {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
  }

  .sm\:focus\:shadow-inner:focus {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .sm\:focus\:ring:focus {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }

  .sm\:focus\:shadow-none:focus {
    box-shadow: none !important;
  }

  .sm\:focus\:shadow-solid:focus {
    box-shadow: 0 0 0 2px currentColor !important;
  }

  .sm\:fill-current {
    fill: currentColor !important;
  }

  .sm\:stroke-current {
    stroke: currentColor !important;
  }

  .sm\:stroke-0 {
    stroke-width: 0 !important;
  }

  .sm\:stroke-1 {
    stroke-width: 1 !important;
  }

  .sm\:stroke-2 {
    stroke-width: 2 !important;
  }

  .sm\:table-auto {
    table-layout: auto !important;
  }

  .sm\:table-fixed {
    table-layout: fixed !important;
  }

  .sm\:text-left {
    text-align: left !important;
  }

  .sm\:text-center {
    text-align: center !important;
  }

  .sm\:text-right {
    text-align: right !important;
  }

  .sm\:text-justify {
    text-align: justify !important;
  }

  .sm\:text-opacity-0 {
    --text-opacity: 0 !important;
  }

  .sm\:text-opacity-12 {
    --text-opacity: 0.12 !important;
  }

  .sm\:text-opacity-25 {
    --text-opacity: 0.25 !important;
  }

  .sm\:text-opacity-38 {
    --text-opacity: 0.38 !important;
  }

  .sm\:text-opacity-50 {
    --text-opacity: 0.5 !important;
  }

  .sm\:text-opacity-54 {
    --text-opacity: 0.54 !important;
  }

  .sm\:text-opacity-70 {
    --text-opacity: 0.70 !important;
  }

  .sm\:text-opacity-75 {
    --text-opacity: 0.75 !important;
  }

  .sm\:text-opacity-84 {
    --text-opacity: 0.84 !important;
  }

  .sm\:text-opacity-100 {
    --text-opacity: 1 !important;
  }

  .sm\:hover\:text-opacity-0:hover {
    --text-opacity: 0 !important;
  }

  .sm\:hover\:text-opacity-12:hover {
    --text-opacity: 0.12 !important;
  }

  .sm\:hover\:text-opacity-25:hover {
    --text-opacity: 0.25 !important;
  }

  .sm\:hover\:text-opacity-38:hover {
    --text-opacity: 0.38 !important;
  }

  .sm\:hover\:text-opacity-50:hover {
    --text-opacity: 0.5 !important;
  }

  .sm\:hover\:text-opacity-54:hover {
    --text-opacity: 0.54 !important;
  }

  .sm\:hover\:text-opacity-70:hover {
    --text-opacity: 0.70 !important;
  }

  .sm\:hover\:text-opacity-75:hover {
    --text-opacity: 0.75 !important;
  }

  .sm\:hover\:text-opacity-84:hover {
    --text-opacity: 0.84 !important;
  }

  .sm\:hover\:text-opacity-100:hover {
    --text-opacity: 1 !important;
  }

  .sm\:focus\:text-opacity-0:focus {
    --text-opacity: 0 !important;
  }

  .sm\:focus\:text-opacity-12:focus {
    --text-opacity: 0.12 !important;
  }

  .sm\:focus\:text-opacity-25:focus {
    --text-opacity: 0.25 !important;
  }

  .sm\:focus\:text-opacity-38:focus {
    --text-opacity: 0.38 !important;
  }

  .sm\:focus\:text-opacity-50:focus {
    --text-opacity: 0.5 !important;
  }

  .sm\:focus\:text-opacity-54:focus {
    --text-opacity: 0.54 !important;
  }

  .sm\:focus\:text-opacity-70:focus {
    --text-opacity: 0.70 !important;
  }

  .sm\:focus\:text-opacity-75:focus {
    --text-opacity: 0.75 !important;
  }

  .sm\:focus\:text-opacity-84:focus {
    --text-opacity: 0.84 !important;
  }

  .sm\:focus\:text-opacity-100:focus {
    --text-opacity: 1 !important;
  }

  .sm\:italic {
    font-style: italic !important;
  }

  .sm\:not-italic {
    font-style: normal !important;
  }

  .sm\:uppercase {
    text-transform: uppercase !important;
  }

  .sm\:lowercase {
    text-transform: lowercase !important;
  }

  .sm\:capitalize {
    text-transform: capitalize !important;
  }

  .sm\:normal-case {
    text-transform: none !important;
  }

  .sm\:underline {
    text-decoration: underline !important;
  }

  .sm\:line-through {
    text-decoration: line-through !important;
  }

  .sm\:no-underline {
    text-decoration: none !important;
  }

  .sm\:hover\:underline:hover {
    text-decoration: underline !important;
  }

  .sm\:hover\:line-through:hover {
    text-decoration: line-through !important;
  }

  .sm\:hover\:no-underline:hover {
    text-decoration: none !important;
  }

  .sm\:focus\:underline:focus {
    text-decoration: underline !important;
  }

  .sm\:focus\:line-through:focus {
    text-decoration: line-through !important;
  }

  .sm\:focus\:no-underline:focus {
    text-decoration: none !important;
  }

  .sm\:tracking-tighter {
    letter-spacing: -0.05em !important;
  }

  .sm\:tracking-tight {
    letter-spacing: -0.025em !important;
  }

  .sm\:tracking-normal {
    letter-spacing: 0 !important;
  }

  .sm\:tracking-wide {
    letter-spacing: 0.025em !important;
  }

  .sm\:tracking-wider {
    letter-spacing: 0.05em !important;
  }

  .sm\:tracking-widest {
    letter-spacing: 0.1em !important;
  }

  .sm\:select-none {
    -webkit-user-select: none !important;
       -moz-user-select: none !important;
        -ms-user-select: none !important;
            user-select: none !important;
  }

  .sm\:select-text {
    -webkit-user-select: text !important;
       -moz-user-select: text !important;
        -ms-user-select: text !important;
            user-select: text !important;
  }

  .sm\:select-all {
    -webkit-user-select: all !important;
       -moz-user-select: all !important;
        -ms-user-select: all !important;
            user-select: all !important;
  }

  .sm\:select-auto {
    -webkit-user-select: auto !important;
       -moz-user-select: auto !important;
        -ms-user-select: auto !important;
            user-select: auto !important;
  }

  .sm\:align-baseline {
    vertical-align: baseline !important;
  }

  .sm\:align-top {
    vertical-align: top !important;
  }

  .sm\:align-middle {
    vertical-align: middle !important;
  }

  .sm\:align-bottom {
    vertical-align: bottom !important;
  }

  .sm\:align-text-top {
    vertical-align: text-top !important;
  }

  .sm\:align-text-bottom {
    vertical-align: text-bottom !important;
  }

  .sm\:visible {
    visibility: visible !important;
  }

  .sm\:invisible {
    visibility: hidden !important;
  }

  .sm\:whitespace-normal {
    white-space: normal !important;
  }

  .sm\:whitespace-nowrap {
    white-space: nowrap !important;
  }

  .sm\:whitespace-pre {
    white-space: pre !important;
  }

  .sm\:whitespace-pre-line {
    white-space: pre-line !important;
  }

  .sm\:whitespace-pre-wrap {
    white-space: pre-wrap !important;
  }

  .sm\:break-normal {
    overflow-wrap: normal !important;
    word-break: normal !important;
  }

  .sm\:break-words {
    overflow-wrap: break-word !important;
  }

  .sm\:break-all {
    word-break: break-all !important;
  }

  .sm\:truncate {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
  }

  .sm\:w-0 {
    width: 0 !important;
  }

  .sm\:w-1 {
    width: 0.25rem !important;
  }

  .sm\:w-2 {
    width: 0.5rem !important;
  }

  .sm\:w-3 {
    width: 0.75rem !important;
  }

  .sm\:w-4 {
    width: 1rem !important;
  }

  .sm\:w-5 {
    width: 1.25rem !important;
  }

  .sm\:w-6 {
    width: 1.5rem !important;
  }

  .sm\:w-8 {
    width: 2rem !important;
  }

  .sm\:w-10 {
    width: 2.5rem !important;
  }

  .sm\:w-12 {
    width: 3rem !important;
  }

  .sm\:w-14 {
    width: 3.5rem !important;
  }

  .sm\:w-16 {
    width: 4rem !important;
  }

  .sm\:w-18 {
    width: 4.5rem !important;
  }

  .sm\:w-20 {
    width: 5rem !important;
  }

  .sm\:w-22 {
    width: 5.5rem !important;
  }

  .sm\:w-24 {
    width: 6rem !important;
  }

  .sm\:w-26 {
    width: 6.5rem !important;
  }

  .sm\:w-28 {
    width: 7rem !important;
  }

  .sm\:w-30 {
    width: 7.5rem !important;
  }

  .sm\:w-32 {
    width: 8rem !important;
  }

  .sm\:w-36 {
    width: 9rem !important;
  }

  .sm\:w-40 {
    width: 10rem !important;
  }

  .sm\:w-48 {
    width: 12rem !important;
  }

  .sm\:w-50 {
    width: 12.5rem !important;
  }

  .sm\:w-56 {
    width: 14rem !important;
  }

  .sm\:w-60 {
    width: 15rem !important;
  }

  .sm\:w-64 {
    width: 16rem !important;
  }

  .sm\:w-80 {
    width: 20rem !important;
  }

  .sm\:w-90 {
    width: 24rem !important;
  }

  .sm\:w-100 {
    width: 25rem !important;
  }

  .sm\:w-120 {
    width: 30rem !important;
  }

  .sm\:w-128 {
    width: 32rem !important;
  }

  .sm\:w-140 {
    width: 35rem !important;
  }

  .sm\:w-160 {
    width: 40rem !important;
  }

  .sm\:w-180 {
    width: 45rem !important;
  }

  .sm\:w-192 {
    width: 48rem !important;
  }

  .sm\:w-200 {
    width: 50rem !important;
  }

  .sm\:w-240 {
    width: 60rem !important;
  }

  .sm\:w-256 {
    width: 64rem !important;
  }

  .sm\:w-280 {
    width: 70rem !important;
  }

  .sm\:w-320 {
    width: 80rem !important;
  }

  .sm\:w-360 {
    width: 90rem !important;
  }

  .sm\:w-400 {
    width: 100rem !important;
  }

  .sm\:w-480 {
    width: 120rem !important;
  }

  .sm\:w-auto {
    width: auto !important;
  }

  .sm\:w-px {
    width: 1px !important;
  }

  .sm\:w-2px {
    width: 2px !important;
  }

  .sm\:w-1\/2 {
    width: 50% !important;
  }

  .sm\:w-1\/3 {
    width: 33.33333% !important;
  }

  .sm\:w-2\/3 {
    width: 66.66667% !important;
  }

  .sm\:w-1\/4 {
    width: 25% !important;
  }

  .sm\:w-2\/4 {
    width: 50% !important;
  }

  .sm\:w-3\/4 {
    width: 75% !important;
  }

  .sm\:w-1\/5 {
    width: 20% !important;
  }

  .sm\:w-2\/5 {
    width: 40% !important;
  }

  .sm\:w-3\/5 {
    width: 60% !important;
  }

  .sm\:w-4\/5 {
    width: 80% !important;
  }

  .sm\:w-1\/6 {
    width: 16.666667% !important;
  }

  .sm\:w-2\/6 {
    width: 33.333333% !important;
  }

  .sm\:w-3\/6 {
    width: 50% !important;
  }

  .sm\:w-4\/6 {
    width: 66.666667% !important;
  }

  .sm\:w-5\/6 {
    width: 83.333333% !important;
  }

  .sm\:w-1\/12 {
    width: 8.33333% !important;
  }

  .sm\:w-2\/12 {
    width: 16.66667% !important;
  }

  .sm\:w-3\/12 {
    width: 25% !important;
  }

  .sm\:w-4\/12 {
    width: 33.33333% !important;
  }

  .sm\:w-5\/12 {
    width: 41.66667% !important;
  }

  .sm\:w-6\/12 {
    width: 50% !important;
  }

  .sm\:w-7\/12 {
    width: 58.33333% !important;
  }

  .sm\:w-8\/12 {
    width: 66.66667% !important;
  }

  .sm\:w-9\/12 {
    width: 75% !important;
  }

  .sm\:w-10\/12 {
    width: 83.33333% !important;
  }

  .sm\:w-11\/12 {
    width: 91.66667% !important;
  }

  .sm\:w-full {
    width: 100% !important;
  }

  .sm\:w-screen {
    width: 100vw !important;
  }

  .sm\:z-0 {
    z-index: 0 !important;
  }

  .sm\:z-10 {
    z-index: 10 !important;
  }

  .sm\:z-20 {
    z-index: 20 !important;
  }

  .sm\:z-30 {
    z-index: 30 !important;
  }

  .sm\:z-40 {
    z-index: 40 !important;
  }

  .sm\:z-50 {
    z-index: 50 !important;
  }

  .sm\:z-60 {
    z-index: 60 !important;
  }

  .sm\:z-70 {
    z-index: 70 !important;
  }

  .sm\:z-80 {
    z-index: 80 !important;
  }

  .sm\:z-90 {
    z-index: 90 !important;
  }

  .sm\:z-99 {
    z-index: 99 !important;
  }

  .sm\:z-999 {
    z-index: 999 !important;
  }

  .sm\:z-9999 {
    z-index: 9999 !important;
  }

  .sm\:z-99999 {
    z-index: 99999 !important;
  }

  .sm\:z-auto {
    z-index: auto !important;
  }

  .sm\:-z-1 {
    z-index: -1 !important;
  }

  .sm\:gap-0 {
    grid-gap: 0 !important;
    gap: 0 !important;
  }

  .sm\:gap-1 {
    grid-gap: 0.25rem !important;
    gap: 0.25rem !important;
  }

  .sm\:gap-2 {
    grid-gap: 0.5rem !important;
    gap: 0.5rem !important;
  }

  .sm\:gap-3 {
    grid-gap: 0.75rem !important;
    gap: 0.75rem !important;
  }

  .sm\:gap-4 {
    grid-gap: 1rem !important;
    gap: 1rem !important;
  }

  .sm\:gap-5 {
    grid-gap: 1.25rem !important;
    gap: 1.25rem !important;
  }

  .sm\:gap-6 {
    grid-gap: 1.5rem !important;
    gap: 1.5rem !important;
  }

  .sm\:gap-8 {
    grid-gap: 2rem !important;
    gap: 2rem !important;
  }

  .sm\:gap-10 {
    grid-gap: 2.5rem !important;
    gap: 2.5rem !important;
  }

  .sm\:gap-12 {
    grid-gap: 3rem !important;
    gap: 3rem !important;
  }

  .sm\:gap-14 {
    grid-gap: 3.5rem !important;
    gap: 3.5rem !important;
  }

  .sm\:gap-16 {
    grid-gap: 4rem !important;
    gap: 4rem !important;
  }

  .sm\:gap-18 {
    grid-gap: 4.5rem !important;
    gap: 4.5rem !important;
  }

  .sm\:gap-20 {
    grid-gap: 5rem !important;
    gap: 5rem !important;
  }

  .sm\:gap-22 {
    grid-gap: 5.5rem !important;
    gap: 5.5rem !important;
  }

  .sm\:gap-24 {
    grid-gap: 6rem !important;
    gap: 6rem !important;
  }

  .sm\:gap-26 {
    grid-gap: 6.5rem !important;
    gap: 6.5rem !important;
  }

  .sm\:gap-28 {
    grid-gap: 7rem !important;
    gap: 7rem !important;
  }

  .sm\:gap-30 {
    grid-gap: 7.5rem !important;
    gap: 7.5rem !important;
  }

  .sm\:gap-32 {
    grid-gap: 8rem !important;
    gap: 8rem !important;
  }

  .sm\:gap-36 {
    grid-gap: 9rem !important;
    gap: 9rem !important;
  }

  .sm\:gap-40 {
    grid-gap: 10rem !important;
    gap: 10rem !important;
  }

  .sm\:gap-48 {
    grid-gap: 12rem !important;
    gap: 12rem !important;
  }

  .sm\:gap-56 {
    grid-gap: 14rem !important;
    gap: 14rem !important;
  }

  .sm\:gap-64 {
    grid-gap: 16rem !important;
    gap: 16rem !important;
  }

  .sm\:gap-px {
    grid-gap: 1px !important;
    gap: 1px !important;
  }

  .sm\:gap-2px {
    grid-gap: 2px !important;
    gap: 2px !important;
  }

  .sm\:gap-x-0 {
    grid-column-gap: 0 !important;
    -moz-column-gap: 0 !important;
         column-gap: 0 !important;
  }

  .sm\:gap-x-1 {
    grid-column-gap: 0.25rem !important;
    -moz-column-gap: 0.25rem !important;
         column-gap: 0.25rem !important;
  }

  .sm\:gap-x-2 {
    grid-column-gap: 0.5rem !important;
    -moz-column-gap: 0.5rem !important;
         column-gap: 0.5rem !important;
  }

  .sm\:gap-x-3 {
    grid-column-gap: 0.75rem !important;
    -moz-column-gap: 0.75rem !important;
         column-gap: 0.75rem !important;
  }

  .sm\:gap-x-4 {
    grid-column-gap: 1rem !important;
    -moz-column-gap: 1rem !important;
         column-gap: 1rem !important;
  }

  .sm\:gap-x-5 {
    grid-column-gap: 1.25rem !important;
    -moz-column-gap: 1.25rem !important;
         column-gap: 1.25rem !important;
  }

  .sm\:gap-x-6 {
    grid-column-gap: 1.5rem !important;
    -moz-column-gap: 1.5rem !important;
         column-gap: 1.5rem !important;
  }

  .sm\:gap-x-8 {
    grid-column-gap: 2rem !important;
    -moz-column-gap: 2rem !important;
         column-gap: 2rem !important;
  }

  .sm\:gap-x-10 {
    grid-column-gap: 2.5rem !important;
    -moz-column-gap: 2.5rem !important;
         column-gap: 2.5rem !important;
  }

  .sm\:gap-x-12 {
    grid-column-gap: 3rem !important;
    -moz-column-gap: 3rem !important;
         column-gap: 3rem !important;
  }

  .sm\:gap-x-14 {
    grid-column-gap: 3.5rem !important;
    -moz-column-gap: 3.5rem !important;
         column-gap: 3.5rem !important;
  }

  .sm\:gap-x-16 {
    grid-column-gap: 4rem !important;
    -moz-column-gap: 4rem !important;
         column-gap: 4rem !important;
  }

  .sm\:gap-x-18 {
    grid-column-gap: 4.5rem !important;
    -moz-column-gap: 4.5rem !important;
         column-gap: 4.5rem !important;
  }

  .sm\:gap-x-20 {
    grid-column-gap: 5rem !important;
    -moz-column-gap: 5rem !important;
         column-gap: 5rem !important;
  }

  .sm\:gap-x-22 {
    grid-column-gap: 5.5rem !important;
    -moz-column-gap: 5.5rem !important;
         column-gap: 5.5rem !important;
  }

  .sm\:gap-x-24 {
    grid-column-gap: 6rem !important;
    -moz-column-gap: 6rem !important;
         column-gap: 6rem !important;
  }

  .sm\:gap-x-26 {
    grid-column-gap: 6.5rem !important;
    -moz-column-gap: 6.5rem !important;
         column-gap: 6.5rem !important;
  }

  .sm\:gap-x-28 {
    grid-column-gap: 7rem !important;
    -moz-column-gap: 7rem !important;
         column-gap: 7rem !important;
  }

  .sm\:gap-x-30 {
    grid-column-gap: 7.5rem !important;
    -moz-column-gap: 7.5rem !important;
         column-gap: 7.5rem !important;
  }

  .sm\:gap-x-32 {
    grid-column-gap: 8rem !important;
    -moz-column-gap: 8rem !important;
         column-gap: 8rem !important;
  }

  .sm\:gap-x-36 {
    grid-column-gap: 9rem !important;
    -moz-column-gap: 9rem !important;
         column-gap: 9rem !important;
  }

  .sm\:gap-x-40 {
    grid-column-gap: 10rem !important;
    -moz-column-gap: 10rem !important;
         column-gap: 10rem !important;
  }

  .sm\:gap-x-48 {
    grid-column-gap: 12rem !important;
    -moz-column-gap: 12rem !important;
         column-gap: 12rem !important;
  }

  .sm\:gap-x-56 {
    grid-column-gap: 14rem !important;
    -moz-column-gap: 14rem !important;
         column-gap: 14rem !important;
  }

  .sm\:gap-x-64 {
    grid-column-gap: 16rem !important;
    -moz-column-gap: 16rem !important;
         column-gap: 16rem !important;
  }

  .sm\:gap-x-px {
    grid-column-gap: 1px !important;
    -moz-column-gap: 1px !important;
         column-gap: 1px !important;
  }

  .sm\:gap-x-2px {
    grid-column-gap: 2px !important;
    -moz-column-gap: 2px !important;
         column-gap: 2px !important;
  }

  .sm\:gap-y-0 {
    grid-row-gap: 0 !important;
    row-gap: 0 !important;
  }

  .sm\:gap-y-1 {
    grid-row-gap: 0.25rem !important;
    row-gap: 0.25rem !important;
  }

  .sm\:gap-y-2 {
    grid-row-gap: 0.5rem !important;
    row-gap: 0.5rem !important;
  }

  .sm\:gap-y-3 {
    grid-row-gap: 0.75rem !important;
    row-gap: 0.75rem !important;
  }

  .sm\:gap-y-4 {
    grid-row-gap: 1rem !important;
    row-gap: 1rem !important;
  }

  .sm\:gap-y-5 {
    grid-row-gap: 1.25rem !important;
    row-gap: 1.25rem !important;
  }

  .sm\:gap-y-6 {
    grid-row-gap: 1.5rem !important;
    row-gap: 1.5rem !important;
  }

  .sm\:gap-y-8 {
    grid-row-gap: 2rem !important;
    row-gap: 2rem !important;
  }

  .sm\:gap-y-10 {
    grid-row-gap: 2.5rem !important;
    row-gap: 2.5rem !important;
  }

  .sm\:gap-y-12 {
    grid-row-gap: 3rem !important;
    row-gap: 3rem !important;
  }

  .sm\:gap-y-14 {
    grid-row-gap: 3.5rem !important;
    row-gap: 3.5rem !important;
  }

  .sm\:gap-y-16 {
    grid-row-gap: 4rem !important;
    row-gap: 4rem !important;
  }

  .sm\:gap-y-18 {
    grid-row-gap: 4.5rem !important;
    row-gap: 4.5rem !important;
  }

  .sm\:gap-y-20 {
    grid-row-gap: 5rem !important;
    row-gap: 5rem !important;
  }

  .sm\:gap-y-22 {
    grid-row-gap: 5.5rem !important;
    row-gap: 5.5rem !important;
  }

  .sm\:gap-y-24 {
    grid-row-gap: 6rem !important;
    row-gap: 6rem !important;
  }

  .sm\:gap-y-26 {
    grid-row-gap: 6.5rem !important;
    row-gap: 6.5rem !important;
  }

  .sm\:gap-y-28 {
    grid-row-gap: 7rem !important;
    row-gap: 7rem !important;
  }

  .sm\:gap-y-30 {
    grid-row-gap: 7.5rem !important;
    row-gap: 7.5rem !important;
  }

  .sm\:gap-y-32 {
    grid-row-gap: 8rem !important;
    row-gap: 8rem !important;
  }

  .sm\:gap-y-36 {
    grid-row-gap: 9rem !important;
    row-gap: 9rem !important;
  }

  .sm\:gap-y-40 {
    grid-row-gap: 10rem !important;
    row-gap: 10rem !important;
  }

  .sm\:gap-y-48 {
    grid-row-gap: 12rem !important;
    row-gap: 12rem !important;
  }

  .sm\:gap-y-56 {
    grid-row-gap: 14rem !important;
    row-gap: 14rem !important;
  }

  .sm\:gap-y-64 {
    grid-row-gap: 16rem !important;
    row-gap: 16rem !important;
  }

  .sm\:gap-y-px {
    grid-row-gap: 1px !important;
    row-gap: 1px !important;
  }

  .sm\:gap-y-2px {
    grid-row-gap: 2px !important;
    row-gap: 2px !important;
  }

  .sm\:grid-flow-row {
    grid-auto-flow: row !important;
  }

  .sm\:grid-flow-col {
    grid-auto-flow: column !important;
  }

  .sm\:grid-flow-row-dense {
    grid-auto-flow: row dense !important;
  }

  .sm\:grid-flow-col-dense {
    grid-auto-flow: column dense !important;
  }

  .sm\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }

  .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
  }

  .sm\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
  }

  .sm\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr)) !important;
  }

  .sm\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr)) !important;
  }

  .sm\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr)) !important;
  }

  .sm\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr)) !important;
  }

  .sm\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr)) !important;
  }

  .sm\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr)) !important;
  }

  .sm\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr)) !important;
  }

  .sm\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr)) !important;
  }

  .sm\:grid-cols-none {
    grid-template-columns: none !important;
  }

  .sm\:col-auto {
    grid-column: auto !important;
  }

  .sm\:col-span-1 {
    grid-column: span 1 / span 1 !important;
  }

  .sm\:col-span-2 {
    grid-column: span 2 / span 2 !important;
  }

  .sm\:col-span-3 {
    grid-column: span 3 / span 3 !important;
  }

  .sm\:col-span-4 {
    grid-column: span 4 / span 4 !important;
  }

  .sm\:col-span-5 {
    grid-column: span 5 / span 5 !important;
  }

  .sm\:col-span-6 {
    grid-column: span 6 / span 6 !important;
  }

  .sm\:col-span-7 {
    grid-column: span 7 / span 7 !important;
  }

  .sm\:col-span-8 {
    grid-column: span 8 / span 8 !important;
  }

  .sm\:col-span-9 {
    grid-column: span 9 / span 9 !important;
  }

  .sm\:col-span-10 {
    grid-column: span 10 / span 10 !important;
  }

  .sm\:col-span-11 {
    grid-column: span 11 / span 11 !important;
  }

  .sm\:col-span-12 {
    grid-column: span 12 / span 12 !important;
  }

  .sm\:col-start-1 {
    grid-column-start: 1 !important;
  }

  .sm\:col-start-2 {
    grid-column-start: 2 !important;
  }

  .sm\:col-start-3 {
    grid-column-start: 3 !important;
  }

  .sm\:col-start-4 {
    grid-column-start: 4 !important;
  }

  .sm\:col-start-5 {
    grid-column-start: 5 !important;
  }

  .sm\:col-start-6 {
    grid-column-start: 6 !important;
  }

  .sm\:col-start-7 {
    grid-column-start: 7 !important;
  }

  .sm\:col-start-8 {
    grid-column-start: 8 !important;
  }

  .sm\:col-start-9 {
    grid-column-start: 9 !important;
  }

  .sm\:col-start-10 {
    grid-column-start: 10 !important;
  }

  .sm\:col-start-11 {
    grid-column-start: 11 !important;
  }

  .sm\:col-start-12 {
    grid-column-start: 12 !important;
  }

  .sm\:col-start-13 {
    grid-column-start: 13 !important;
  }

  .sm\:col-start-auto {
    grid-column-start: auto !important;
  }

  .sm\:col-end-1 {
    grid-column-end: 1 !important;
  }

  .sm\:col-end-2 {
    grid-column-end: 2 !important;
  }

  .sm\:col-end-3 {
    grid-column-end: 3 !important;
  }

  .sm\:col-end-4 {
    grid-column-end: 4 !important;
  }

  .sm\:col-end-5 {
    grid-column-end: 5 !important;
  }

  .sm\:col-end-6 {
    grid-column-end: 6 !important;
  }

  .sm\:col-end-7 {
    grid-column-end: 7 !important;
  }

  .sm\:col-end-8 {
    grid-column-end: 8 !important;
  }

  .sm\:col-end-9 {
    grid-column-end: 9 !important;
  }

  .sm\:col-end-10 {
    grid-column-end: 10 !important;
  }

  .sm\:col-end-11 {
    grid-column-end: 11 !important;
  }

  .sm\:col-end-12 {
    grid-column-end: 12 !important;
  }

  .sm\:col-end-13 {
    grid-column-end: 13 !important;
  }

  .sm\:col-end-auto {
    grid-column-end: auto !important;
  }

  .sm\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr)) !important;
  }

  .sm\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr)) !important;
  }

  .sm\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr)) !important;
  }

  .sm\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr)) !important;
  }

  .sm\:grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr)) !important;
  }

  .sm\:grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr)) !important;
  }

  .sm\:grid-rows-none {
    grid-template-rows: none !important;
  }

  .sm\:row-auto {
    grid-row: auto !important;
  }

  .sm\:row-span-1 {
    grid-row: span 1 / span 1 !important;
  }

  .sm\:row-span-2 {
    grid-row: span 2 / span 2 !important;
  }

  .sm\:row-span-3 {
    grid-row: span 3 / span 3 !important;
  }

  .sm\:row-span-4 {
    grid-row: span 4 / span 4 !important;
  }

  .sm\:row-span-5 {
    grid-row: span 5 / span 5 !important;
  }

  .sm\:row-span-6 {
    grid-row: span 6 / span 6 !important;
  }

  .sm\:row-start-1 {
    grid-row-start: 1 !important;
  }

  .sm\:row-start-2 {
    grid-row-start: 2 !important;
  }

  .sm\:row-start-3 {
    grid-row-start: 3 !important;
  }

  .sm\:row-start-4 {
    grid-row-start: 4 !important;
  }

  .sm\:row-start-5 {
    grid-row-start: 5 !important;
  }

  .sm\:row-start-6 {
    grid-row-start: 6 !important;
  }

  .sm\:row-start-7 {
    grid-row-start: 7 !important;
  }

  .sm\:row-start-auto {
    grid-row-start: auto !important;
  }

  .sm\:row-end-1 {
    grid-row-end: 1 !important;
  }

  .sm\:row-end-2 {
    grid-row-end: 2 !important;
  }

  .sm\:row-end-3 {
    grid-row-end: 3 !important;
  }

  .sm\:row-end-4 {
    grid-row-end: 4 !important;
  }

  .sm\:row-end-5 {
    grid-row-end: 5 !important;
  }

  .sm\:row-end-6 {
    grid-row-end: 6 !important;
  }

  .sm\:row-end-7 {
    grid-row-end: 7 !important;
  }

  .sm\:row-end-auto {
    grid-row-end: auto !important;
  }

  .sm\:transform {
    --transform-translate-x: 0 !important;
    --transform-translate-y: 0 !important;
    --transform-rotate: 0 !important;
    --transform-skew-x: 0 !important;
    --transform-skew-y: 0 !important;
    --transform-scale-x: 1 !important;
    --transform-scale-y: 1 !important;
    transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y)) !important;
  }

  .sm\:transform-none {
    transform: none !important;
  }

  .sm\:origin-center {
    transform-origin: center !important;
  }

  .sm\:origin-top {
    transform-origin: top !important;
  }

  .sm\:origin-top-right {
    transform-origin: top right !important;
  }

  .sm\:origin-right {
    transform-origin: right !important;
  }

  .sm\:origin-bottom-right {
    transform-origin: bottom right !important;
  }

  .sm\:origin-bottom {
    transform-origin: bottom !important;
  }

  .sm\:origin-bottom-left {
    transform-origin: bottom left !important;
  }

  .sm\:origin-left {
    transform-origin: left !important;
  }

  .sm\:origin-top-left {
    transform-origin: top left !important;
  }

  .sm\:icon-size-12 {
    width: 12px !important;
    height: 12px !important;
    min-width: 12px !important;
    min-height: 12px !important;
    font-size: 12px !important;
    line-height: 12px !important;
  }

  .sm\:icon-size-12 svg {
    width: 12px !important;
    height: 12px !important;
  }

  .sm\:icon-size-14 {
    width: 14px !important;
    height: 14px !important;
    min-width: 14px !important;
    min-height: 14px !important;
    font-size: 14px !important;
    line-height: 14px !important;
  }

  .sm\:icon-size-14 svg {
    width: 14px !important;
    height: 14px !important;
  }

  .sm\:icon-size-16 {
    width: 16px !important;
    height: 16px !important;
    min-width: 16px !important;
    min-height: 16px !important;
    font-size: 16px !important;
    line-height: 16px !important;
  }

  .sm\:icon-size-16 svg {
    width: 16px !important;
    height: 16px !important;
  }

  .sm\:icon-size-18 {
    width: 18px !important;
    height: 18px !important;
    min-width: 18px !important;
    min-height: 18px !important;
    font-size: 18px !important;
    line-height: 18px !important;
  }

  .sm\:icon-size-18 svg {
    width: 18px !important;
    height: 18px !important;
  }

  .sm\:icon-size-20 {
    width: 20px !important;
    height: 20px !important;
    min-width: 20px !important;
    min-height: 20px !important;
    font-size: 20px !important;
    line-height: 20px !important;
  }

  .sm\:icon-size-20 svg {
    width: 20px !important;
    height: 20px !important;
  }

  .sm\:icon-size-24 {
    width: 24px !important;
    height: 24px !important;
    min-width: 24px !important;
    min-height: 24px !important;
    font-size: 24px !important;
    line-height: 24px !important;
  }

  .sm\:icon-size-24 svg {
    width: 24px !important;
    height: 24px !important;
  }

  .sm\:icon-size-32 {
    width: 32px !important;
    height: 32px !important;
    min-width: 32px !important;
    min-height: 32px !important;
    font-size: 32px !important;
    line-height: 32px !important;
  }

  .sm\:icon-size-32 svg {
    width: 32px !important;
    height: 32px !important;
  }

  .sm\:icon-size-40 {
    width: 40px !important;
    height: 40px !important;
    min-width: 40px !important;
    min-height: 40px !important;
    font-size: 40px !important;
    line-height: 40px !important;
  }

  .sm\:icon-size-40 svg {
    width: 40px !important;
    height: 40px !important;
  }

  .sm\:icon-size-48 {
    width: 48px !important;
    height: 48px !important;
    min-width: 48px !important;
    min-height: 48px !important;
    font-size: 48px !important;
    line-height: 48px !important;
  }

  .sm\:icon-size-48 svg {
    width: 48px !important;
    height: 48px !important;
  }

  .sm\:icon-size-56 {
    width: 56px !important;
    height: 56px !important;
    min-width: 56px !important;
    min-height: 56px !important;
    font-size: 56px !important;
    line-height: 56px !important;
  }

  .sm\:icon-size-56 svg {
    width: 56px !important;
    height: 56px !important;
  }

  .sm\:icon-size-64 {
    width: 64px !important;
    height: 64px !important;
    min-width: 64px !important;
    min-height: 64px !important;
    font-size: 64px !important;
    line-height: 64px !important;
  }

  .sm\:icon-size-64 svg {
    width: 64px !important;
    height: 64px !important;
  }

  .sm\:icon-size-72 {
    width: 72px !important;
    height: 72px !important;
    min-width: 72px !important;
    min-height: 72px !important;
    font-size: 72px !important;
    line-height: 72px !important;
  }

  .sm\:icon-size-72 svg {
    width: 72px !important;
    height: 72px !important;
  }

  .sm\:icon-size-80 {
    width: 80px !important;
    height: 80px !important;
    min-width: 80px !important;
    min-height: 80px !important;
    font-size: 80px !important;
    line-height: 80px !important;
  }

  .sm\:icon-size-80 svg {
    width: 80px !important;
    height: 80px !important;
  }

  .sm\:icon-size-88 {
    width: 88px !important;
    height: 88px !important;
    min-width: 88px !important;
    min-height: 88px !important;
    font-size: 88px !important;
    line-height: 88px !important;
  }

  .sm\:icon-size-88 svg {
    width: 88px !important;
    height: 88px !important;
  }

  .sm\:icon-size-96 {
    width: 96px !important;
    height: 96px !important;
    min-width: 96px !important;
    min-height: 96px !important;
    font-size: 96px !important;
    line-height: 96px !important;
  }

  .sm\:icon-size-96 svg {
    width: 96px !important;
    height: 96px !important;
  }
}

@media (min-width: 960px) and (max-width: 1279px) {
  .md\:space-y-0 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(0px * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(0px * var(--space-y-reverse)) !important;
  }

  .md\:space-x-0 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(0px * var(--space-x-reverse)) !important;
    margin-left: calc(0px * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(0.25rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(0.25rem * var(--space-y-reverse)) !important;
  }

  .md\:space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(0.25rem * var(--space-x-reverse)) !important;
    margin-left: calc(0.25rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(0.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(0.5rem * var(--space-y-reverse)) !important;
  }

  .md\:space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(0.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(0.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(0.75rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(0.75rem * var(--space-y-reverse)) !important;
  }

  .md\:space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(0.75rem * var(--space-x-reverse)) !important;
    margin-left: calc(0.75rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(1rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(1rem * var(--space-y-reverse)) !important;
  }

  .md\:space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(1rem * var(--space-x-reverse)) !important;
    margin-left: calc(1rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(1.25rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(1.25rem * var(--space-y-reverse)) !important;
  }

  .md\:space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(1.25rem * var(--space-x-reverse)) !important;
    margin-left: calc(1.25rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(1.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(1.5rem * var(--space-y-reverse)) !important;
  }

  .md\:space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(1.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(1.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:space-y-8 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(2rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(2rem * var(--space-y-reverse)) !important;
  }

  .md\:space-x-8 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(2rem * var(--space-x-reverse)) !important;
    margin-left: calc(2rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:space-y-10 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(2.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(2.5rem * var(--space-y-reverse)) !important;
  }

  .md\:space-x-10 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(2.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(2.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:space-y-12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(3rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(3rem * var(--space-y-reverse)) !important;
  }

  .md\:space-x-12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(3rem * var(--space-x-reverse)) !important;
    margin-left: calc(3rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:space-y-14 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(3.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(3.5rem * var(--space-y-reverse)) !important;
  }

  .md\:space-x-14 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(3.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(3.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:space-y-16 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(4rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(4rem * var(--space-y-reverse)) !important;
  }

  .md\:space-x-16 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(4rem * var(--space-x-reverse)) !important;
    margin-left: calc(4rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:space-y-18 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(4.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(4.5rem * var(--space-y-reverse)) !important;
  }

  .md\:space-x-18 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(4.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(4.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:space-y-20 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(5rem * var(--space-y-reverse)) !important;
  }

  .md\:space-x-20 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(5rem * var(--space-x-reverse)) !important;
    margin-left: calc(5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:space-y-22 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(5.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(5.5rem * var(--space-y-reverse)) !important;
  }

  .md\:space-x-22 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(5.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(5.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:space-y-24 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(6rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(6rem * var(--space-y-reverse)) !important;
  }

  .md\:space-x-24 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(6rem * var(--space-x-reverse)) !important;
    margin-left: calc(6rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:space-y-26 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(6.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(6.5rem * var(--space-y-reverse)) !important;
  }

  .md\:space-x-26 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(6.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(6.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:space-y-28 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(7rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(7rem * var(--space-y-reverse)) !important;
  }

  .md\:space-x-28 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(7rem * var(--space-x-reverse)) !important;
    margin-left: calc(7rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:space-y-30 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(7.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(7.5rem * var(--space-y-reverse)) !important;
  }

  .md\:space-x-30 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(7.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(7.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:space-y-32 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(8rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(8rem * var(--space-y-reverse)) !important;
  }

  .md\:space-x-32 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(8rem * var(--space-x-reverse)) !important;
    margin-left: calc(8rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:space-y-36 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(9rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(9rem * var(--space-y-reverse)) !important;
  }

  .md\:space-x-36 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(9rem * var(--space-x-reverse)) !important;
    margin-left: calc(9rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:space-y-40 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(10rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(10rem * var(--space-y-reverse)) !important;
  }

  .md\:space-x-40 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(10rem * var(--space-x-reverse)) !important;
    margin-left: calc(10rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:space-y-48 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(12rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(12rem * var(--space-y-reverse)) !important;
  }

  .md\:space-x-48 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(12rem * var(--space-x-reverse)) !important;
    margin-left: calc(12rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:space-y-56 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(14rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(14rem * var(--space-y-reverse)) !important;
  }

  .md\:space-x-56 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(14rem * var(--space-x-reverse)) !important;
    margin-left: calc(14rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:space-y-64 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(16rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(16rem * var(--space-y-reverse)) !important;
  }

  .md\:space-x-64 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(16rem * var(--space-x-reverse)) !important;
    margin-left: calc(16rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:space-y-px > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(1px * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(1px * var(--space-y-reverse)) !important;
  }

  .md\:space-x-px > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(1px * var(--space-x-reverse)) !important;
    margin-left: calc(1px * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:space-y-2px > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(2px * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(2px * var(--space-y-reverse)) !important;
  }

  .md\:space-x-2px > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(2px * var(--space-x-reverse)) !important;
    margin-left: calc(2px * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:-space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-0.25rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-0.25rem * var(--space-y-reverse)) !important;
  }

  .md\:-space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-0.25rem * var(--space-x-reverse)) !important;
    margin-left: calc(-0.25rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:-space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-0.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-0.5rem * var(--space-y-reverse)) !important;
  }

  .md\:-space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-0.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-0.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:-space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-0.75rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-0.75rem * var(--space-y-reverse)) !important;
  }

  .md\:-space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-0.75rem * var(--space-x-reverse)) !important;
    margin-left: calc(-0.75rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:-space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-1rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-1rem * var(--space-y-reverse)) !important;
  }

  .md\:-space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-1rem * var(--space-x-reverse)) !important;
    margin-left: calc(-1rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:-space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-1.25rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-1.25rem * var(--space-y-reverse)) !important;
  }

  .md\:-space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-1.25rem * var(--space-x-reverse)) !important;
    margin-left: calc(-1.25rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:-space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-1.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-1.5rem * var(--space-y-reverse)) !important;
  }

  .md\:-space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-1.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-1.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:-space-y-8 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-2rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-2rem * var(--space-y-reverse)) !important;
  }

  .md\:-space-x-8 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-2rem * var(--space-x-reverse)) !important;
    margin-left: calc(-2rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:-space-y-10 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-2.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-2.5rem * var(--space-y-reverse)) !important;
  }

  .md\:-space-x-10 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-2.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-2.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:-space-y-12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-3rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-3rem * var(--space-y-reverse)) !important;
  }

  .md\:-space-x-12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-3rem * var(--space-x-reverse)) !important;
    margin-left: calc(-3rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:-space-y-14 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-3.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-3.5rem * var(--space-y-reverse)) !important;
  }

  .md\:-space-x-14 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-3.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-3.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:-space-y-16 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-4rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-4rem * var(--space-y-reverse)) !important;
  }

  .md\:-space-x-16 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-4rem * var(--space-x-reverse)) !important;
    margin-left: calc(-4rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:-space-y-18 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-4.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-4.5rem * var(--space-y-reverse)) !important;
  }

  .md\:-space-x-18 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-4.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-4.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:-space-y-20 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-5rem * var(--space-y-reverse)) !important;
  }

  .md\:-space-x-20 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:-space-y-22 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-5.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-5.5rem * var(--space-y-reverse)) !important;
  }

  .md\:-space-x-22 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-5.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-5.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:-space-y-24 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-6rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-6rem * var(--space-y-reverse)) !important;
  }

  .md\:-space-x-24 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-6rem * var(--space-x-reverse)) !important;
    margin-left: calc(-6rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:-space-y-26 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-6.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-6.5rem * var(--space-y-reverse)) !important;
  }

  .md\:-space-x-26 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-6.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-6.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:-space-y-28 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-7rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-7rem * var(--space-y-reverse)) !important;
  }

  .md\:-space-x-28 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-7rem * var(--space-x-reverse)) !important;
    margin-left: calc(-7rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:-space-y-30 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-7.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-7.5rem * var(--space-y-reverse)) !important;
  }

  .md\:-space-x-30 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-7.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-7.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:-space-y-32 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-8rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-8rem * var(--space-y-reverse)) !important;
  }

  .md\:-space-x-32 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-8rem * var(--space-x-reverse)) !important;
    margin-left: calc(-8rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:-space-y-36 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-9rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-9rem * var(--space-y-reverse)) !important;
  }

  .md\:-space-x-36 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-9rem * var(--space-x-reverse)) !important;
    margin-left: calc(-9rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:-space-y-40 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-10rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-10rem * var(--space-y-reverse)) !important;
  }

  .md\:-space-x-40 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-10rem * var(--space-x-reverse)) !important;
    margin-left: calc(-10rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:-space-y-48 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-12rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-12rem * var(--space-y-reverse)) !important;
  }

  .md\:-space-x-48 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-12rem * var(--space-x-reverse)) !important;
    margin-left: calc(-12rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:-space-y-56 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-14rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-14rem * var(--space-y-reverse)) !important;
  }

  .md\:-space-x-56 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-14rem * var(--space-x-reverse)) !important;
    margin-left: calc(-14rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:-space-y-64 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-16rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-16rem * var(--space-y-reverse)) !important;
  }

  .md\:-space-x-64 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-16rem * var(--space-x-reverse)) !important;
    margin-left: calc(-16rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:-space-y-px > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-1px * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-1px * var(--space-y-reverse)) !important;
  }

  .md\:-space-x-px > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-1px * var(--space-x-reverse)) !important;
    margin-left: calc(-1px * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:-space-y-2px > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-2px * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-2px * var(--space-y-reverse)) !important;
  }

  .md\:-space-x-2px > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-2px * var(--space-x-reverse)) !important;
    margin-left: calc(-2px * calc(1 - var(--space-x-reverse))) !important;
  }

  .md\:space-y-reverse > :not(template) ~ :not(template) {
    --space-y-reverse: 1 !important;
  }

  .md\:space-x-reverse > :not(template) ~ :not(template) {
    --space-x-reverse: 1 !important;
  }

  .md\:divide-y-0 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(0px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(0px * var(--divide-y-reverse)) !important;
  }

  .md\:divide-x-0 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(0px * var(--divide-x-reverse)) !important;
    border-left-width: calc(0px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .md\:divide-y-2 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(2px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(2px * var(--divide-y-reverse)) !important;
  }

  .md\:divide-x-2 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(2px * var(--divide-x-reverse)) !important;
    border-left-width: calc(2px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .md\:divide-y-4 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(4px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(4px * var(--divide-y-reverse)) !important;
  }

  .md\:divide-x-4 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(4px * var(--divide-x-reverse)) !important;
    border-left-width: calc(4px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .md\:divide-y-8 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(8px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(8px * var(--divide-y-reverse)) !important;
  }

  .md\:divide-x-8 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(8px * var(--divide-x-reverse)) !important;
    border-left-width: calc(8px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .md\:divide-y > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(1px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(1px * var(--divide-y-reverse)) !important;
  }

  .md\:divide-x > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(1px * var(--divide-x-reverse)) !important;
    border-left-width: calc(1px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .md\:divide-y-reverse > :not(template) ~ :not(template) {
    --divide-y-reverse: 1 !important;
  }

  .md\:divide-x-reverse > :not(template) ~ :not(template) {
    --divide-x-reverse: 1 !important;
  }

  .md\:divide-current > :not(template) ~ :not(template) {
    border-color: currentColor !important;
  }

  .md\:divide-transparent > :not(template) ~ :not(template) {
    border-color: transparent !important;
  }

  .md\:divide-white > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FFFFFF !important;
    border-color: rgba(255, 255, 255, var(--divide-opacity)) !important;
  }

  .md\:divide-black > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #000000 !important;
    border-color: rgba(0, 0, 0, var(--divide-opacity)) !important;
  }

  .md\:divide-gray-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F9FAFB !important;
    border-color: rgba(249, 250, 251, var(--divide-opacity)) !important;
  }

  .md\:divide-gray-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F4F5F7 !important;
    border-color: rgba(244, 245, 247, var(--divide-opacity)) !important;
  }

  .md\:divide-gray-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E5E7EB !important;
    border-color: rgba(229, 231, 235, var(--divide-opacity)) !important;
  }

  .md\:divide-gray-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #D2D6DC !important;
    border-color: rgba(210, 214, 220, var(--divide-opacity)) !important;
  }

  .md\:divide-gray-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9FA6B2 !important;
    border-color: rgba(159, 166, 178, var(--divide-opacity)) !important;
  }

  .md\:divide-gray-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #6B7280 !important;
    border-color: rgba(107, 114, 128, var(--divide-opacity)) !important;
  }

  .md\:divide-gray-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #4B5563 !important;
    border-color: rgba(75, 85, 99, var(--divide-opacity)) !important;
  }

  .md\:divide-gray-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #374151 !important;
    border-color: rgba(55, 65, 81, var(--divide-opacity)) !important;
  }

  .md\:divide-gray-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #252F3F !important;
    border-color: rgba(37, 47, 63, var(--divide-opacity)) !important;
  }

  .md\:divide-gray-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #161E2E !important;
    border-color: rgba(22, 30, 46, var(--divide-opacity)) !important;
  }

  .md\:divide-gray > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #6B7280 !important;
    border-color: rgba(107, 114, 128, var(--divide-opacity)) !important;
  }

  .md\:divide-cool-gray-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FBFDFE !important;
    border-color: rgba(251, 253, 254, var(--divide-opacity)) !important;
  }

  .md\:divide-cool-gray-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F1F5F9 !important;
    border-color: rgba(241, 245, 249, var(--divide-opacity)) !important;
  }

  .md\:divide-cool-gray-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E2E8F0 !important;
    border-color: rgba(226, 232, 240, var(--divide-opacity)) !important;
  }

  .md\:divide-cool-gray-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #CFD8E3 !important;
    border-color: rgba(207, 216, 227, var(--divide-opacity)) !important;
  }

  .md\:divide-cool-gray-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #97A6BA !important;
    border-color: rgba(151, 166, 186, var(--divide-opacity)) !important;
  }

  .md\:divide-cool-gray-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #64748B !important;
    border-color: rgba(100, 116, 139, var(--divide-opacity)) !important;
  }

  .md\:divide-cool-gray-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #475569 !important;
    border-color: rgba(71, 85, 105, var(--divide-opacity)) !important;
  }

  .md\:divide-cool-gray-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #364152 !important;
    border-color: rgba(54, 65, 82, var(--divide-opacity)) !important;
  }

  .md\:divide-cool-gray-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #27303F !important;
    border-color: rgba(39, 48, 63, var(--divide-opacity)) !important;
  }

  .md\:divide-cool-gray-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #1A202E !important;
    border-color: rgba(26, 32, 46, var(--divide-opacity)) !important;
  }

  .md\:divide-cool-gray > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #64748B !important;
    border-color: rgba(100, 116, 139, var(--divide-opacity)) !important;
  }

  .md\:divide-red-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FDF2F2 !important;
    border-color: rgba(253, 242, 242, var(--divide-opacity)) !important;
  }

  .md\:divide-red-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FDE8E8 !important;
    border-color: rgba(253, 232, 232, var(--divide-opacity)) !important;
  }

  .md\:divide-red-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FBD5D5 !important;
    border-color: rgba(251, 213, 213, var(--divide-opacity)) !important;
  }

  .md\:divide-red-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F8B4B4 !important;
    border-color: rgba(248, 180, 180, var(--divide-opacity)) !important;
  }

  .md\:divide-red-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F98080 !important;
    border-color: rgba(249, 128, 128, var(--divide-opacity)) !important;
  }

  .md\:divide-red-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F05252 !important;
    border-color: rgba(240, 82, 82, var(--divide-opacity)) !important;
  }

  .md\:divide-red-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E02424 !important;
    border-color: rgba(224, 36, 36, var(--divide-opacity)) !important;
  }

  .md\:divide-red-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #C81E1E !important;
    border-color: rgba(200, 30, 30, var(--divide-opacity)) !important;
  }

  .md\:divide-red-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9B1C1C !important;
    border-color: rgba(155, 28, 28, var(--divide-opacity)) !important;
  }

  .md\:divide-red-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #771D1D !important;
    border-color: rgba(119, 29, 29, var(--divide-opacity)) !important;
  }

  .md\:divide-red > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F05252 !important;
    border-color: rgba(240, 82, 82, var(--divide-opacity)) !important;
  }

  .md\:divide-orange-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FFF8F1 !important;
    border-color: rgba(255, 248, 241, var(--divide-opacity)) !important;
  }

  .md\:divide-orange-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FEECDC !important;
    border-color: rgba(254, 236, 220, var(--divide-opacity)) !important;
  }

  .md\:divide-orange-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FCD9BD !important;
    border-color: rgba(252, 217, 189, var(--divide-opacity)) !important;
  }

  .md\:divide-orange-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FDBA8C !important;
    border-color: rgba(253, 186, 140, var(--divide-opacity)) !important;
  }

  .md\:divide-orange-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FF8A4C !important;
    border-color: rgba(255, 138, 76, var(--divide-opacity)) !important;
  }

  .md\:divide-orange-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FF5A1F !important;
    border-color: rgba(255, 90, 31, var(--divide-opacity)) !important;
  }

  .md\:divide-orange-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #ff6700 !important;
    border-color: rgba(255, 103, 0, var(--divide-opacity)) !important;
  }

  .md\:divide-orange-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #B43403 !important;
    border-color: rgba(180, 52, 3, var(--divide-opacity)) !important;
  }

  .md\:divide-orange-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #8A2C0D !important;
    border-color: rgba(138, 44, 13, var(--divide-opacity)) !important;
  }

  .md\:divide-orange-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #771D1D !important;
    border-color: rgba(119, 29, 29, var(--divide-opacity)) !important;
  }

  .md\:divide-orange > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FF5A1F !important;
    border-color: rgba(255, 90, 31, var(--divide-opacity)) !important;
  }

  .md\:divide-yellow-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FDFDEA !important;
    border-color: rgba(253, 253, 234, var(--divide-opacity)) !important;
  }

  .md\:divide-yellow-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FDF6B2 !important;
    border-color: rgba(253, 246, 178, var(--divide-opacity)) !important;
  }

  .md\:divide-yellow-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FCE96A !important;
    border-color: rgba(252, 233, 106, var(--divide-opacity)) !important;
  }

  .md\:divide-yellow-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FACA15 !important;
    border-color: rgba(250, 202, 21, var(--divide-opacity)) !important;
  }

  .md\:divide-yellow-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E3A008 !important;
    border-color: rgba(227, 160, 8, var(--divide-opacity)) !important;
  }

  .md\:divide-yellow-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #C27803 !important;
    border-color: rgba(194, 120, 3, var(--divide-opacity)) !important;
  }

  .md\:divide-yellow-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9F580A !important;
    border-color: rgba(159, 88, 10, var(--divide-opacity)) !important;
  }

  .md\:divide-yellow-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #8E4B10 !important;
    border-color: rgba(142, 75, 16, var(--divide-opacity)) !important;
  }

  .md\:divide-yellow-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #723B13 !important;
    border-color: rgba(114, 59, 19, var(--divide-opacity)) !important;
  }

  .md\:divide-yellow-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #633112 !important;
    border-color: rgba(99, 49, 18, var(--divide-opacity)) !important;
  }

  .md\:divide-yellow > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #C27803 !important;
    border-color: rgba(194, 120, 3, var(--divide-opacity)) !important;
  }

  .md\:divide-green-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F3FAF7 !important;
    border-color: rgba(243, 250, 247, var(--divide-opacity)) !important;
  }

  .md\:divide-green-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #DEF7EC !important;
    border-color: rgba(222, 247, 236, var(--divide-opacity)) !important;
  }

  .md\:divide-green-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #BCF0DA !important;
    border-color: rgba(188, 240, 218, var(--divide-opacity)) !important;
  }

  .md\:divide-green-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #84E1BC !important;
    border-color: rgba(132, 225, 188, var(--divide-opacity)) !important;
  }

  .md\:divide-green-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #31C48D !important;
    border-color: rgba(49, 196, 141, var(--divide-opacity)) !important;
  }

  .md\:divide-green-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #0E9F6E !important;
    border-color: rgba(14, 159, 110, var(--divide-opacity)) !important;
  }

  .md\:divide-green-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #057A55 !important;
    border-color: rgba(5, 122, 85, var(--divide-opacity)) !important;
  }

  .md\:divide-green-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #046C4E !important;
    border-color: rgba(4, 108, 78, var(--divide-opacity)) !important;
  }

  .md\:divide-green-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #03543F !important;
    border-color: rgba(3, 84, 63, var(--divide-opacity)) !important;
  }

  .md\:divide-green-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #014737 !important;
    border-color: rgba(1, 71, 55, var(--divide-opacity)) !important;
  }

  .md\:divide-green > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #0E9F6E !important;
    border-color: rgba(14, 159, 110, var(--divide-opacity)) !important;
  }

  .md\:divide-teal-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #EDFAFA !important;
    border-color: rgba(237, 250, 250, var(--divide-opacity)) !important;
  }

  .md\:divide-teal-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #D5F5F6 !important;
    border-color: rgba(213, 245, 246, var(--divide-opacity)) !important;
  }

  .md\:divide-teal-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #AFECEF !important;
    border-color: rgba(175, 236, 239, var(--divide-opacity)) !important;
  }

  .md\:divide-teal-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #7EDCE2 !important;
    border-color: rgba(126, 220, 226, var(--divide-opacity)) !important;
  }

  .md\:divide-teal-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #16BDCA !important;
    border-color: rgba(22, 189, 202, var(--divide-opacity)) !important;
  }

  .md\:divide-teal-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #0694A2 !important;
    border-color: rgba(6, 148, 162, var(--divide-opacity)) !important;
  }

  .md\:divide-teal-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #047481 !important;
    border-color: rgba(4, 116, 129, var(--divide-opacity)) !important;
  }

  .md\:divide-teal-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #036672 !important;
    border-color: rgba(3, 102, 114, var(--divide-opacity)) !important;
  }

  .md\:divide-teal-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #05505C !important;
    border-color: rgba(5, 80, 92, var(--divide-opacity)) !important;
  }

  .md\:divide-teal-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #014451 !important;
    border-color: rgba(1, 68, 81, var(--divide-opacity)) !important;
  }

  .md\:divide-teal > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #0694A2 !important;
    border-color: rgba(6, 148, 162, var(--divide-opacity)) !important;
  }

  .md\:divide-blue-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #EBF5FF !important;
    border-color: rgba(235, 245, 255, var(--divide-opacity)) !important;
  }

  .md\:divide-blue-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E1EFFE !important;
    border-color: rgba(225, 239, 254, var(--divide-opacity)) !important;
  }

  .md\:divide-blue-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #C3DDFD !important;
    border-color: rgba(195, 221, 253, var(--divide-opacity)) !important;
  }

  .md\:divide-blue-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #A4CAFE !important;
    border-color: rgba(164, 202, 254, var(--divide-opacity)) !important;
  }

  .md\:divide-blue-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #76A9FA !important;
    border-color: rgba(118, 169, 250, var(--divide-opacity)) !important;
  }

  .md\:divide-blue-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #3F83F8 !important;
    border-color: rgba(63, 131, 248, var(--divide-opacity)) !important;
  }

  .md\:divide-blue-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #1C64F2 !important;
    border-color: rgba(28, 100, 242, var(--divide-opacity)) !important;
  }

  .md\:divide-blue-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #1A56DB !important;
    border-color: rgba(26, 86, 219, var(--divide-opacity)) !important;
  }

  .md\:divide-blue-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #1E429F !important;
    border-color: rgba(30, 66, 159, var(--divide-opacity)) !important;
  }

  .md\:divide-blue-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #233876 !important;
    border-color: rgba(35, 56, 118, var(--divide-opacity)) !important;
  }

  .md\:divide-blue > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #3F83F8 !important;
    border-color: rgba(63, 131, 248, var(--divide-opacity)) !important;
  }

  .md\:divide-indigo-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F0F5FF !important;
    border-color: rgba(240, 245, 255, var(--divide-opacity)) !important;
  }

  .md\:divide-indigo-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E5EDFF !important;
    border-color: rgba(229, 237, 255, var(--divide-opacity)) !important;
  }

  .md\:divide-indigo-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #CDDBFE !important;
    border-color: rgba(205, 219, 254, var(--divide-opacity)) !important;
  }

  .md\:divide-indigo-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #B4C6FC !important;
    border-color: rgba(180, 198, 252, var(--divide-opacity)) !important;
  }

  .md\:divide-indigo-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #8DA2FB !important;
    border-color: rgba(141, 162, 251, var(--divide-opacity)) !important;
  }

  .md\:divide-indigo-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #6875F5 !important;
    border-color: rgba(104, 117, 245, var(--divide-opacity)) !important;
  }

  .md\:divide-indigo-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #5850EC !important;
    border-color: rgba(88, 80, 236, var(--divide-opacity)) !important;
  }

  .md\:divide-indigo-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #5145CD !important;
    border-color: rgba(81, 69, 205, var(--divide-opacity)) !important;
  }

  .md\:divide-indigo-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #42389D !important;
    border-color: rgba(66, 56, 157, var(--divide-opacity)) !important;
  }

  .md\:divide-indigo-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #362F78 !important;
    border-color: rgba(54, 47, 120, var(--divide-opacity)) !important;
  }

  .md\:divide-indigo > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #6875F5 !important;
    border-color: rgba(104, 117, 245, var(--divide-opacity)) !important;
  }

  .md\:divide-purple-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F6F5FF !important;
    border-color: rgba(246, 245, 255, var(--divide-opacity)) !important;
  }

  .md\:divide-purple-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #EDEBFE !important;
    border-color: rgba(237, 235, 254, var(--divide-opacity)) !important;
  }

  .md\:divide-purple-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #DCD7FE !important;
    border-color: rgba(220, 215, 254, var(--divide-opacity)) !important;
  }

  .md\:divide-purple-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #CABFFD !important;
    border-color: rgba(202, 191, 253, var(--divide-opacity)) !important;
  }

  .md\:divide-purple-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #AC94FA !important;
    border-color: rgba(172, 148, 250, var(--divide-opacity)) !important;
  }

  .md\:divide-purple-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9061F9 !important;
    border-color: rgba(144, 97, 249, var(--divide-opacity)) !important;
  }

  .md\:divide-purple-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #7E3AF2 !important;
    border-color: rgba(126, 58, 242, var(--divide-opacity)) !important;
  }

  .md\:divide-purple-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #6C2BD9 !important;
    border-color: rgba(108, 43, 217, var(--divide-opacity)) !important;
  }

  .md\:divide-purple-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #5521B5 !important;
    border-color: rgba(85, 33, 181, var(--divide-opacity)) !important;
  }

  .md\:divide-purple-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #4A1D96 !important;
    border-color: rgba(74, 29, 150, var(--divide-opacity)) !important;
  }

  .md\:divide-purple > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9061F9 !important;
    border-color: rgba(144, 97, 249, var(--divide-opacity)) !important;
  }

  .md\:divide-pink-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FDF2F8 !important;
    border-color: rgba(253, 242, 248, var(--divide-opacity)) !important;
  }

  .md\:divide-pink-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FCE8F3 !important;
    border-color: rgba(252, 232, 243, var(--divide-opacity)) !important;
  }

  .md\:divide-pink-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FAD1E8 !important;
    border-color: rgba(250, 209, 232, var(--divide-opacity)) !important;
  }

  .md\:divide-pink-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F8B4D9 !important;
    border-color: rgba(248, 180, 217, var(--divide-opacity)) !important;
  }

  .md\:divide-pink-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F17EB8 !important;
    border-color: rgba(241, 126, 184, var(--divide-opacity)) !important;
  }

  .md\:divide-pink-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E74694 !important;
    border-color: rgba(231, 70, 148, var(--divide-opacity)) !important;
  }

  .md\:divide-pink-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #D61F69 !important;
    border-color: rgba(214, 31, 105, var(--divide-opacity)) !important;
  }

  .md\:divide-pink-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #BF125D !important;
    border-color: rgba(191, 18, 93, var(--divide-opacity)) !important;
  }

  .md\:divide-pink-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #99154B !important;
    border-color: rgba(153, 21, 75, var(--divide-opacity)) !important;
  }

  .md\:divide-pink-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #751A3D !important;
    border-color: rgba(117, 26, 61, var(--divide-opacity)) !important;
  }

  .md\:divide-pink > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E74694 !important;
    border-color: rgba(231, 70, 148, var(--divide-opacity)) !important;
  }

  .md\:divide-opacity-0 > :not(template) ~ :not(template) {
    --divide-opacity: 0 !important;
  }

  .md\:divide-opacity-12 > :not(template) ~ :not(template) {
    --divide-opacity: 0.12 !important;
  }

  .md\:divide-opacity-25 > :not(template) ~ :not(template) {
    --divide-opacity: 0.25 !important;
  }

  .md\:divide-opacity-38 > :not(template) ~ :not(template) {
    --divide-opacity: 0.38 !important;
  }

  .md\:divide-opacity-50 > :not(template) ~ :not(template) {
    --divide-opacity: 0.5 !important;
  }

  .md\:divide-opacity-54 > :not(template) ~ :not(template) {
    --divide-opacity: 0.54 !important;
  }

  .md\:divide-opacity-70 > :not(template) ~ :not(template) {
    --divide-opacity: 0.70 !important;
  }

  .md\:divide-opacity-75 > :not(template) ~ :not(template) {
    --divide-opacity: 0.75 !important;
  }

  .md\:divide-opacity-84 > :not(template) ~ :not(template) {
    --divide-opacity: 0.84 !important;
  }

  .md\:divide-opacity-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
  }

  .md\:sr-only {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border-width: 0 !important;
  }

  .md\:not-sr-only {
    position: static !important;
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: visible !important;
    clip: auto !important;
    white-space: normal !important;
  }

  .md\:focus\:sr-only:focus {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border-width: 0 !important;
  }

  .md\:focus\:not-sr-only:focus {
    position: static !important;
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: visible !important;
    clip: auto !important;
    white-space: normal !important;
  }

  .md\:appearance-none {
    -webkit-appearance: none !important;
       -moz-appearance: none !important;
            appearance: none !important;
  }

  .md\:bg-fixed {
    background-attachment: fixed !important;
  }

  .md\:bg-local {
    background-attachment: local !important;
  }

  .md\:bg-scroll {
    background-attachment: scroll !important;
  }

  .md\:bg-opacity-0 {
    --bg-opacity: 0 !important;
  }

  .md\:bg-opacity-12 {
    --bg-opacity: 0.12 !important;
  }

  .md\:bg-opacity-25 {
    --bg-opacity: 0.25 !important;
  }

  .md\:bg-opacity-38 {
    --bg-opacity: 0.38 !important;
  }

  .md\:bg-opacity-50 {
    --bg-opacity: 0.5 !important;
  }

  .md\:bg-opacity-54 {
    --bg-opacity: 0.54 !important;
  }

  .md\:bg-opacity-70 {
    --bg-opacity: 0.70 !important;
  }

  .md\:bg-opacity-75 {
    --bg-opacity: 0.75 !important;
  }

  .md\:bg-opacity-84 {
    --bg-opacity: 0.84 !important;
  }

  .md\:bg-opacity-100 {
    --bg-opacity: 1 !important;
  }

  .md\:hover\:bg-opacity-0:hover {
    --bg-opacity: 0 !important;
  }

  .md\:hover\:bg-opacity-12:hover {
    --bg-opacity: 0.12 !important;
  }

  .md\:hover\:bg-opacity-25:hover {
    --bg-opacity: 0.25 !important;
  }

  .md\:hover\:bg-opacity-38:hover {
    --bg-opacity: 0.38 !important;
  }

  .md\:hover\:bg-opacity-50:hover {
    --bg-opacity: 0.5 !important;
  }

  .md\:hover\:bg-opacity-54:hover {
    --bg-opacity: 0.54 !important;
  }

  .md\:hover\:bg-opacity-70:hover {
    --bg-opacity: 0.70 !important;
  }

  .md\:hover\:bg-opacity-75:hover {
    --bg-opacity: 0.75 !important;
  }

  .md\:hover\:bg-opacity-84:hover {
    --bg-opacity: 0.84 !important;
  }

  .md\:hover\:bg-opacity-100:hover {
    --bg-opacity: 1 !important;
  }

  .md\:focus\:bg-opacity-0:focus {
    --bg-opacity: 0 !important;
  }

  .md\:focus\:bg-opacity-12:focus {
    --bg-opacity: 0.12 !important;
  }

  .md\:focus\:bg-opacity-25:focus {
    --bg-opacity: 0.25 !important;
  }

  .md\:focus\:bg-opacity-38:focus {
    --bg-opacity: 0.38 !important;
  }

  .md\:focus\:bg-opacity-50:focus {
    --bg-opacity: 0.5 !important;
  }

  .md\:focus\:bg-opacity-54:focus {
    --bg-opacity: 0.54 !important;
  }

  .md\:focus\:bg-opacity-70:focus {
    --bg-opacity: 0.70 !important;
  }

  .md\:focus\:bg-opacity-75:focus {
    --bg-opacity: 0.75 !important;
  }

  .md\:focus\:bg-opacity-84:focus {
    --bg-opacity: 0.84 !important;
  }

  .md\:focus\:bg-opacity-100:focus {
    --bg-opacity: 1 !important;
  }

  .md\:bg-bottom {
    background-position: bottom !important;
  }

  .md\:bg-center {
    background-position: center !important;
  }

  .md\:bg-left {
    background-position: left !important;
  }

  .md\:bg-left-bottom {
    background-position: left bottom !important;
  }

  .md\:bg-left-top {
    background-position: left top !important;
  }

  .md\:bg-right {
    background-position: right !important;
  }

  .md\:bg-right-bottom {
    background-position: right bottom !important;
  }

  .md\:bg-right-top {
    background-position: right top !important;
  }

  .md\:bg-top {
    background-position: top !important;
  }

  .md\:bg-repeat {
    background-repeat: repeat !important;
  }

  .md\:bg-no-repeat {
    background-repeat: no-repeat !important;
  }

  .md\:bg-repeat-x {
    background-repeat: repeat-x !important;
  }

  .md\:bg-repeat-y {
    background-repeat: repeat-y !important;
  }

  .md\:bg-repeat-round {
    background-repeat: round !important;
  }

  .md\:bg-repeat-space {
    background-repeat: space !important;
  }

  .md\:bg-auto {
    background-size: auto !important;
  }

  .md\:bg-cover {
    background-size: cover !important;
  }

  .md\:bg-contain {
    background-size: contain !important;
  }

  .md\:border-collapse {
    border-collapse: collapse !important;
  }

  .md\:border-separate {
    border-collapse: separate !important;
  }

  .md\:border-opacity-0 {
    --border-opacity: 0 !important;
  }

  .md\:border-opacity-12 {
    --border-opacity: 0.12 !important;
  }

  .md\:border-opacity-25 {
    --border-opacity: 0.25 !important;
  }

  .md\:border-opacity-38 {
    --border-opacity: 0.38 !important;
  }

  .md\:border-opacity-50 {
    --border-opacity: 0.5 !important;
  }

  .md\:border-opacity-54 {
    --border-opacity: 0.54 !important;
  }

  .md\:border-opacity-70 {
    --border-opacity: 0.70 !important;
  }

  .md\:border-opacity-75 {
    --border-opacity: 0.75 !important;
  }

  .md\:border-opacity-84 {
    --border-opacity: 0.84 !important;
  }

  .md\:border-opacity-100 {
    --border-opacity: 1 !important;
  }

  .md\:hover\:border-opacity-0:hover {
    --border-opacity: 0 !important;
  }

  .md\:hover\:border-opacity-12:hover {
    --border-opacity: 0.12 !important;
  }

  .md\:hover\:border-opacity-25:hover {
    --border-opacity: 0.25 !important;
  }

  .md\:hover\:border-opacity-38:hover {
    --border-opacity: 0.38 !important;
  }

  .md\:hover\:border-opacity-50:hover {
    --border-opacity: 0.5 !important;
  }

  .md\:hover\:border-opacity-54:hover {
    --border-opacity: 0.54 !important;
  }

  .md\:hover\:border-opacity-70:hover {
    --border-opacity: 0.70 !important;
  }

  .md\:hover\:border-opacity-75:hover {
    --border-opacity: 0.75 !important;
  }

  .md\:hover\:border-opacity-84:hover {
    --border-opacity: 0.84 !important;
  }

  .md\:hover\:border-opacity-100:hover {
    --border-opacity: 1 !important;
  }

  .md\:focus\:border-opacity-0:focus {
    --border-opacity: 0 !important;
  }

  .md\:focus\:border-opacity-12:focus {
    --border-opacity: 0.12 !important;
  }

  .md\:focus\:border-opacity-25:focus {
    --border-opacity: 0.25 !important;
  }

  .md\:focus\:border-opacity-38:focus {
    --border-opacity: 0.38 !important;
  }

  .md\:focus\:border-opacity-50:focus {
    --border-opacity: 0.5 !important;
  }

  .md\:focus\:border-opacity-54:focus {
    --border-opacity: 0.54 !important;
  }

  .md\:focus\:border-opacity-70:focus {
    --border-opacity: 0.70 !important;
  }

  .md\:focus\:border-opacity-75:focus {
    --border-opacity: 0.75 !important;
  }

  .md\:focus\:border-opacity-84:focus {
    --border-opacity: 0.84 !important;
  }

  .md\:focus\:border-opacity-100:focus {
    --border-opacity: 1 !important;
  }

  .md\:rounded-none {
    border-radius: 0 !important;
  }

  .md\:rounded-sm {
    border-radius: 0.125rem !important;
  }

  .md\:rounded {
    border-radius: 0.25rem !important;
  }

  .md\:rounded-md {
    border-radius: 0.375rem !important;
  }

  .md\:rounded-lg {
    border-radius: 0.5rem !important;
  }

  .md\:rounded-full {
    border-radius: 9999px !important;
  }

  .md\:rounded-t-none {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .md\:rounded-r-none {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .md\:rounded-b-none {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .md\:rounded-l-none {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .md\:rounded-t-sm {
    border-top-left-radius: 0.125rem !important;
    border-top-right-radius: 0.125rem !important;
  }

  .md\:rounded-r-sm {
    border-top-right-radius: 0.125rem !important;
    border-bottom-right-radius: 0.125rem !important;
  }

  .md\:rounded-b-sm {
    border-bottom-right-radius: 0.125rem !important;
    border-bottom-left-radius: 0.125rem !important;
  }

  .md\:rounded-l-sm {
    border-top-left-radius: 0.125rem !important;
    border-bottom-left-radius: 0.125rem !important;
  }

  .md\:rounded-t {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }

  .md\:rounded-r {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }

  .md\:rounded-b {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }

  .md\:rounded-l {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }

  .md\:rounded-t-md {
    border-top-left-radius: 0.375rem !important;
    border-top-right-radius: 0.375rem !important;
  }

  .md\:rounded-r-md {
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }

  .md\:rounded-b-md {
    border-bottom-right-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }

  .md\:rounded-l-md {
    border-top-left-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }

  .md\:rounded-t-lg {
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
  }

  .md\:rounded-r-lg {
    border-top-right-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }

  .md\:rounded-b-lg {
    border-bottom-right-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }

  .md\:rounded-l-lg {
    border-top-left-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }

  .md\:rounded-t-full {
    border-top-left-radius: 9999px !important;
    border-top-right-radius: 9999px !important;
  }

  .md\:rounded-r-full {
    border-top-right-radius: 9999px !important;
    border-bottom-right-radius: 9999px !important;
  }

  .md\:rounded-b-full {
    border-bottom-right-radius: 9999px !important;
    border-bottom-left-radius: 9999px !important;
  }

  .md\:rounded-l-full {
    border-top-left-radius: 9999px !important;
    border-bottom-left-radius: 9999px !important;
  }

  .md\:rounded-tl-none {
    border-top-left-radius: 0 !important;
  }

  .md\:rounded-tr-none {
    border-top-right-radius: 0 !important;
  }

  .md\:rounded-br-none {
    border-bottom-right-radius: 0 !important;
  }

  .md\:rounded-bl-none {
    border-bottom-left-radius: 0 !important;
  }

  .md\:rounded-tl-sm {
    border-top-left-radius: 0.125rem !important;
  }

  .md\:rounded-tr-sm {
    border-top-right-radius: 0.125rem !important;
  }

  .md\:rounded-br-sm {
    border-bottom-right-radius: 0.125rem !important;
  }

  .md\:rounded-bl-sm {
    border-bottom-left-radius: 0.125rem !important;
  }

  .md\:rounded-tl {
    border-top-left-radius: 0.25rem !important;
  }

  .md\:rounded-tr {
    border-top-right-radius: 0.25rem !important;
  }

  .md\:rounded-br {
    border-bottom-right-radius: 0.25rem !important;
  }

  .md\:rounded-bl {
    border-bottom-left-radius: 0.25rem !important;
  }

  .md\:rounded-tl-md {
    border-top-left-radius: 0.375rem !important;
  }

  .md\:rounded-tr-md {
    border-top-right-radius: 0.375rem !important;
  }

  .md\:rounded-br-md {
    border-bottom-right-radius: 0.375rem !important;
  }

  .md\:rounded-bl-md {
    border-bottom-left-radius: 0.375rem !important;
  }

  .md\:rounded-tl-lg {
    border-top-left-radius: 0.5rem !important;
  }

  .md\:rounded-tr-lg {
    border-top-right-radius: 0.5rem !important;
  }

  .md\:rounded-br-lg {
    border-bottom-right-radius: 0.5rem !important;
  }

  .md\:rounded-bl-lg {
    border-bottom-left-radius: 0.5rem !important;
  }

  .md\:rounded-tl-full {
    border-top-left-radius: 9999px !important;
  }

  .md\:rounded-tr-full {
    border-top-right-radius: 9999px !important;
  }

  .md\:rounded-br-full {
    border-bottom-right-radius: 9999px !important;
  }

  .md\:rounded-bl-full {
    border-bottom-left-radius: 9999px !important;
  }

  .md\:border-solid {
    border-style: solid !important;
  }

  .md\:border-dashed {
    border-style: dashed !important;
  }

  .md\:border-dotted {
    border-style: dotted !important;
  }

  .md\:border-double {
    border-style: double !important;
  }

  .md\:border-none {
    border-style: none !important;
  }

  .md\:border-0 {
    border-width: 0 !important;
  }

  .md\:border-2 {
    border-width: 2px !important;
  }

  .md\:border-4 {
    border-width: 4px !important;
  }

  .md\:border-8 {
    border-width: 8px !important;
  }

  .md\:border {
    border-width: 1px !important;
  }

  .md\:border-t-0 {
    border-top-width: 0 !important;
  }

  .md\:border-r-0 {
    border-right-width: 0 !important;
  }

  .md\:border-b-0 {
    border-bottom-width: 0 !important;
  }

  .md\:border-l-0 {
    border-left-width: 0 !important;
  }

  .md\:border-t-2 {
    border-top-width: 2px !important;
  }

  .md\:border-r-2 {
    border-right-width: 2px !important;
  }

  .md\:border-b-2 {
    border-bottom-width: 2px !important;
  }

  .md\:border-l-2 {
    border-left-width: 2px !important;
  }

  .md\:border-t-4 {
    border-top-width: 4px !important;
  }

  .md\:border-r-4 {
    border-right-width: 4px !important;
  }

  .md\:border-b-4 {
    border-bottom-width: 4px !important;
  }

  .md\:border-l-4 {
    border-left-width: 4px !important;
  }

  .md\:border-t-8 {
    border-top-width: 8px !important;
  }

  .md\:border-r-8 {
    border-right-width: 8px !important;
  }

  .md\:border-b-8 {
    border-bottom-width: 8px !important;
  }

  .md\:border-l-8 {
    border-left-width: 8px !important;
  }

  .md\:border-t {
    border-top-width: 1px !important;
  }

  .md\:border-r {
    border-right-width: 1px !important;
  }

  .md\:border-b {
    border-bottom-width: 1px !important;
  }

  .md\:border-l {
    border-left-width: 1px !important;
  }

  .md\:first\:border-0:first-child {
    border-width: 0 !important;
  }

  .md\:first\:border-2:first-child {
    border-width: 2px !important;
  }

  .md\:first\:border-4:first-child {
    border-width: 4px !important;
  }

  .md\:first\:border-8:first-child {
    border-width: 8px !important;
  }

  .md\:first\:border:first-child {
    border-width: 1px !important;
  }

  .md\:first\:border-t-0:first-child {
    border-top-width: 0 !important;
  }

  .md\:first\:border-r-0:first-child {
    border-right-width: 0 !important;
  }

  .md\:first\:border-b-0:first-child {
    border-bottom-width: 0 !important;
  }

  .md\:first\:border-l-0:first-child {
    border-left-width: 0 !important;
  }

  .md\:first\:border-t-2:first-child {
    border-top-width: 2px !important;
  }

  .md\:first\:border-r-2:first-child {
    border-right-width: 2px !important;
  }

  .md\:first\:border-b-2:first-child {
    border-bottom-width: 2px !important;
  }

  .md\:first\:border-l-2:first-child {
    border-left-width: 2px !important;
  }

  .md\:first\:border-t-4:first-child {
    border-top-width: 4px !important;
  }

  .md\:first\:border-r-4:first-child {
    border-right-width: 4px !important;
  }

  .md\:first\:border-b-4:first-child {
    border-bottom-width: 4px !important;
  }

  .md\:first\:border-l-4:first-child {
    border-left-width: 4px !important;
  }

  .md\:first\:border-t-8:first-child {
    border-top-width: 8px !important;
  }

  .md\:first\:border-r-8:first-child {
    border-right-width: 8px !important;
  }

  .md\:first\:border-b-8:first-child {
    border-bottom-width: 8px !important;
  }

  .md\:first\:border-l-8:first-child {
    border-left-width: 8px !important;
  }

  .md\:first\:border-t:first-child {
    border-top-width: 1px !important;
  }

  .md\:first\:border-r:first-child {
    border-right-width: 1px !important;
  }

  .md\:first\:border-b:first-child {
    border-bottom-width: 1px !important;
  }

  .md\:first\:border-l:first-child {
    border-left-width: 1px !important;
  }

  .md\:last\:border-0:last-child {
    border-width: 0 !important;
  }

  .md\:last\:border-2:last-child {
    border-width: 2px !important;
  }

  .md\:last\:border-4:last-child {
    border-width: 4px !important;
  }

  .md\:last\:border-8:last-child {
    border-width: 8px !important;
  }

  .md\:last\:border:last-child {
    border-width: 1px !important;
  }

  .md\:last\:border-t-0:last-child {
    border-top-width: 0 !important;
  }

  .md\:last\:border-r-0:last-child {
    border-right-width: 0 !important;
  }

  .md\:last\:border-b-0:last-child {
    border-bottom-width: 0 !important;
  }

  .md\:last\:border-l-0:last-child {
    border-left-width: 0 !important;
  }

  .md\:last\:border-t-2:last-child {
    border-top-width: 2px !important;
  }

  .md\:last\:border-r-2:last-child {
    border-right-width: 2px !important;
  }

  .md\:last\:border-b-2:last-child {
    border-bottom-width: 2px !important;
  }

  .md\:last\:border-l-2:last-child {
    border-left-width: 2px !important;
  }

  .md\:last\:border-t-4:last-child {
    border-top-width: 4px !important;
  }

  .md\:last\:border-r-4:last-child {
    border-right-width: 4px !important;
  }

  .md\:last\:border-b-4:last-child {
    border-bottom-width: 4px !important;
  }

  .md\:last\:border-l-4:last-child {
    border-left-width: 4px !important;
  }

  .md\:last\:border-t-8:last-child {
    border-top-width: 8px !important;
  }

  .md\:last\:border-r-8:last-child {
    border-right-width: 8px !important;
  }

  .md\:last\:border-b-8:last-child {
    border-bottom-width: 8px !important;
  }

  .md\:last\:border-l-8:last-child {
    border-left-width: 8px !important;
  }

  .md\:last\:border-t:last-child {
    border-top-width: 1px !important;
  }

  .md\:last\:border-r:last-child {
    border-right-width: 1px !important;
  }

  .md\:last\:border-b:last-child {
    border-bottom-width: 1px !important;
  }

  .md\:last\:border-l:last-child {
    border-left-width: 1px !important;
  }

  .md\:box-border {
    box-sizing: border-box !important;
  }

  .md\:box-content {
    box-sizing: content-box !important;
  }

  .md\:block {
    display: block !important;
  }

  .md\:inline-block {
    display: inline-block !important;
  }

  .md\:inline {
    display: inline !important;
  }

  .md\:flex {
    display: flex !important;
  }

  .md\:inline-flex {
    display: inline-flex !important;
  }

  .md\:table {
    display: table !important;
  }

  .md\:table-caption {
    display: table-caption !important;
  }

  .md\:table-cell {
    display: table-cell !important;
  }

  .md\:table-column {
    display: table-column !important;
  }

  .md\:table-column-group {
    display: table-column-group !important;
  }

  .md\:table-footer-group {
    display: table-footer-group !important;
  }

  .md\:table-header-group {
    display: table-header-group !important;
  }

  .md\:table-row-group {
    display: table-row-group !important;
  }

  .md\:table-row {
    display: table-row !important;
  }

  .md\:flow-root {
    display: flow-root !important;
  }

  .md\:grid {
    display: grid !important;
  }

  .md\:inline-grid {
    display: inline-grid !important;
  }

  .md\:hidden {
    display: none !important;
  }

  .md\:flex-row {
    flex-direction: row !important;
  }

  .md\:flex-row-reverse {
    flex-direction: row-reverse !important;
  }

  .md\:flex-col {
    flex-direction: column !important;
  }

  .md\:flex-col-reverse {
    flex-direction: column-reverse !important;
  }

  .md\:flex-wrap {
    flex-wrap: wrap !important;
  }

  .md\:flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .md\:flex-nowrap {
    flex-wrap: nowrap !important;
  }

  .md\:items-start {
    align-items: flex-start !important;
  }

  .md\:items-end {
    align-items: flex-end !important;
  }

  .md\:items-center {
    align-items: center !important;
  }

  .md\:items-baseline {
    align-items: baseline !important;
  }

  .md\:items-stretch {
    align-items: stretch !important;
  }

  .md\:self-auto {
    align-self: auto !important;
  }

  .md\:self-start {
    align-self: flex-start !important;
  }

  .md\:self-end {
    align-self: flex-end !important;
  }

  .md\:self-center {
    align-self: center !important;
  }

  .md\:self-stretch {
    align-self: stretch !important;
  }

  .md\:justify-start {
    justify-content: flex-start !important;
  }

  .md\:justify-end {
    justify-content: flex-end !important;
  }

  .md\:justify-center {
    justify-content: center !important;
  }

  .md\:justify-between {
    justify-content: space-between !important;
  }

  .md\:justify-around {
    justify-content: space-around !important;
  }

  .md\:justify-evenly {
    justify-content: space-evenly !important;
  }

  .md\:content-center {
    align-content: center !important;
  }

  .md\:content-start {
    align-content: flex-start !important;
  }

  .md\:content-end {
    align-content: flex-end !important;
  }

  .md\:content-between {
    align-content: space-between !important;
  }

  .md\:content-around {
    align-content: space-around !important;
  }

  .md\:flex-0 {
    flex: 0 0 auto !important;
  }

  .md\:flex-1 {
    flex: 1 1 0% !important;
  }

  .md\:flex-auto {
    flex: 1 1 auto !important;
  }

  .md\:flex-initial {
    flex: 0 1 auto !important;
  }

  .md\:flex-none {
    flex: none !important;
  }

  .md\:flex-grow-0 {
    flex-grow: 0 !important;
  }

  .md\:flex-grow {
    flex-grow: 1 !important;
  }

  .md\:flex-shrink-0 {
    flex-shrink: 0 !important;
  }

  .md\:flex-shrink {
    flex-shrink: 1 !important;
  }

  .md\:order-1 {
    order: 1 !important;
  }

  .md\:order-2 {
    order: 2 !important;
  }

  .md\:order-3 {
    order: 3 !important;
  }

  .md\:order-4 {
    order: 4 !important;
  }

  .md\:order-5 {
    order: 5 !important;
  }

  .md\:order-6 {
    order: 6 !important;
  }

  .md\:order-7 {
    order: 7 !important;
  }

  .md\:order-8 {
    order: 8 !important;
  }

  .md\:order-9 {
    order: 9 !important;
  }

  .md\:order-10 {
    order: 10 !important;
  }

  .md\:order-11 {
    order: 11 !important;
  }

  .md\:order-12 {
    order: 12 !important;
  }

  .md\:order-first {
    order: -9999 !important;
  }

  .md\:order-last {
    order: 9999 !important;
  }

  .md\:order-none {
    order: 0 !important;
  }

  .md\:font-thin {
    font-weight: 100 !important;
  }

  .md\:font-extralight {
    font-weight: 200 !important;
  }

  .md\:font-light {
    font-weight: 300 !important;
  }

  .md\:font-normal {
    font-weight: 400 !important;
  }

  .md\:font-medium {
    font-weight: 500 !important;
  }

  .md\:font-semibold {
    font-weight: 600 !important;
  }

  .md\:font-bold {
    font-weight: 700 !important;
  }

  .md\:font-extrabold {
    font-weight: 800 !important;
  }

  .md\:font-black {
    font-weight: 900 !important;
  }

  .md\:h-0 {
    height: 0 !important;
  }

  .md\:h-1 {
    height: 0.25rem !important;
  }

  .md\:h-2 {
    height: 0.5rem !important;
  }

  .md\:h-3 {
    height: 0.75rem !important;
  }

  .md\:h-4 {
    height: 1rem !important;
  }

  .md\:h-5 {
    height: 1.25rem !important;
  }

  .md\:h-6 {
    height: 1.5rem !important;
  }

  .md\:h-8 {
    height: 2rem !important;
  }

  .md\:h-10 {
    height: 2.5rem !important;
  }

  .md\:h-12 {
    height: 3rem !important;
  }

  .md\:h-14 {
    height: 3.5rem !important;
  }

  .md\:h-16 {
    height: 4rem !important;
  }

  .md\:h-18 {
    height: 4.5rem !important;
  }

  .md\:h-20 {
    height: 5rem !important;
  }

  .md\:h-22 {
    height: 5.5rem !important;
  }

  .md\:h-24 {
    height: 6rem !important;
  }

  .md\:h-26 {
    height: 6.5rem !important;
  }

  .md\:h-28 {
    height: 7rem !important;
  }

  .md\:h-30 {
    height: 7.5rem !important;
  }

  .md\:h-32 {
    height: 8rem !important;
  }

  .md\:h-36 {
    height: 9rem !important;
  }

  .md\:h-40 {
    height: 10rem !important;
  }

  .md\:h-48 {
    height: 12rem !important;
  }

  .md\:h-50 {
    height: 12.5rem !important;
  }

  .md\:h-56 {
    height: 14rem !important;
  }

  .md\:h-60 {
    height: 15rem !important;
  }

  .md\:h-64 {
    height: 16rem !important;
  }

  .md\:h-80 {
    height: 20rem !important;
  }

  .md\:h-90 {
    height: 24rem !important;
  }

  .md\:h-100 {
    height: 25rem !important;
  }

  .md\:h-120 {
    height: 30rem !important;
  }

  .md\:h-128 {
    height: 32rem !important;
  }

  .md\:h-140 {
    height: 35rem !important;
  }

  .md\:h-160 {
    height: 40rem !important;
  }

  .md\:h-180 {
    height: 45rem !important;
  }

  .md\:h-192 {
    height: 48rem !important;
  }

  .md\:h-200 {
    height: 50rem !important;
  }

  .md\:h-240 {
    height: 60rem !important;
  }

  .md\:h-256 {
    height: 64rem !important;
  }

  .md\:h-280 {
    height: 70rem !important;
  }

  .md\:h-320 {
    height: 80rem !important;
  }

  .md\:h-360 {
    height: 90rem !important;
  }

  .md\:h-400 {
    height: 100rem !important;
  }

  .md\:h-480 {
    height: 120rem !important;
  }

  .md\:h-auto {
    height: auto !important;
  }

  .md\:h-px {
    height: 1px !important;
  }

  .md\:h-2px {
    height: 2px !important;
  }

  .md\:h-full {
    height: 100% !important;
  }

  .md\:h-screen {
    height: 100vh !important;
  }

  .md\:h-1\/2 {
    height: 50% !important;
  }

  .md\:h-1\/3 {
    height: 33.33333% !important;
  }

  .md\:h-2\/3 {
    height: 66.66667% !important;
  }

  .md\:h-1\/4 {
    height: 25% !important;
  }

  .md\:h-2\/4 {
    height: 50% !important;
  }

  .md\:h-3\/4 {
    height: 75% !important;
  }

  .md\:h-1\/5 {
    height: 20% !important;
  }

  .md\:h-2\/5 {
    height: 40% !important;
  }

  .md\:h-3\/5 {
    height: 60% !important;
  }

  .md\:h-4\/5 {
    height: 80% !important;
  }

  .md\:h-1\/12 {
    height: 8.33333% !important;
  }

  .md\:h-2\/12 {
    height: 16.66667% !important;
  }

  .md\:h-3\/12 {
    height: 25% !important;
  }

  .md\:h-4\/12 {
    height: 33.33333% !important;
  }

  .md\:h-5\/12 {
    height: 41.66667% !important;
  }

  .md\:h-6\/12 {
    height: 50% !important;
  }

  .md\:h-7\/12 {
    height: 58.33333% !important;
  }

  .md\:h-8\/12 {
    height: 66.66667% !important;
  }

  .md\:h-9\/12 {
    height: 75% !important;
  }

  .md\:h-10\/12 {
    height: 83.33333% !important;
  }

  .md\:h-11\/12 {
    height: 91.66667% !important;
  }

  .md\:text-xs {
    font-size: 0.625rem !important;
  }

  .md\:text-sm {
    font-size: 0.75rem !important;
  }

  .md\:text-md {
    font-size: 0.8125rem !important;
  }

  .md\:text-base {
    font-size: 0.875rem !important;
  }

  .md\:text-lg {
    font-size: 1rem !important;
  }

  .md\:text-xl {
    font-size: 1.125rem !important;
  }

  .md\:text-2xl {
    font-size: 1.25rem !important;
  }

  .md\:text-3xl {
    font-size: 1.5rem !important;
  }

  .md\:text-4xl {
    font-size: 2rem !important;
  }

  .md\:text-5xl {
    font-size: 2.25rem !important;
  }

  .md\:text-6xl {
    font-size: 2.5rem !important;
  }

  .md\:text-7xl {
    font-size: 3rem !important;
  }

  .md\:text-8xl {
    font-size: 4rem !important;
  }

  .md\:text-9xl {
    font-size: 6rem !important;
  }

  .md\:text-10xl {
    font-size: 8rem !important;
  }

  .md\:leading-3 {
    line-height: .75rem !important;
  }

  .md\:leading-4 {
    line-height: 1rem !important;
  }

  .md\:leading-5 {
    line-height: 1.25rem !important;
  }

  .md\:leading-6 {
    line-height: 1.5rem !important;
  }

  .md\:leading-7 {
    line-height: 1.75rem !important;
  }

  .md\:leading-8 {
    line-height: 2rem !important;
  }

  .md\:leading-9 {
    line-height: 2.25rem !important;
  }

  .md\:leading-10 {
    line-height: 2.5rem !important;
  }

  .md\:leading-none {
    line-height: 1 !important;
  }

  .md\:leading-tight {
    line-height: 1.25 !important;
  }

  .md\:leading-snug {
    line-height: 1.375 !important;
  }

  .md\:leading-normal {
    line-height: 1.5 !important;
  }

  .md\:leading-relaxed {
    line-height: 1.625 !important;
  }

  .md\:leading-loose {
    line-height: 2 !important;
  }

  .md\:list-inside {
    list-style-position: inside !important;
  }

  .md\:list-outside {
    list-style-position: outside !important;
  }

  .md\:list-none {
    list-style-type: none !important;
  }

  .md\:list-disc {
    list-style-type: disc !important;
  }

  .md\:list-decimal {
    list-style-type: decimal !important;
  }

  .md\:m-0 {
    margin: 0 !important;
  }

  .md\:m-1 {
    margin: 0.25rem !important;
  }

  .md\:m-2 {
    margin: 0.5rem !important;
  }

  .md\:m-3 {
    margin: 0.75rem !important;
  }

  .md\:m-4 {
    margin: 1rem !important;
  }

  .md\:m-5 {
    margin: 1.25rem !important;
  }

  .md\:m-6 {
    margin: 1.5rem !important;
  }

  .md\:m-8 {
    margin: 2rem !important;
  }

  .md\:m-10 {
    margin: 2.5rem !important;
  }

  .md\:m-12 {
    margin: 3rem !important;
  }

  .md\:m-14 {
    margin: 3.5rem !important;
  }

  .md\:m-16 {
    margin: 4rem !important;
  }

  .md\:m-18 {
    margin: 4.5rem !important;
  }

  .md\:m-20 {
    margin: 5rem !important;
  }

  .md\:m-22 {
    margin: 5.5rem !important;
  }

  .md\:m-24 {
    margin: 6rem !important;
  }

  .md\:m-26 {
    margin: 6.5rem !important;
  }

  .md\:m-28 {
    margin: 7rem !important;
  }

  .md\:m-30 {
    margin: 7.5rem !important;
  }

  .md\:m-32 {
    margin: 8rem !important;
  }

  .md\:m-36 {
    margin: 9rem !important;
  }

  .md\:m-40 {
    margin: 10rem !important;
  }

  .md\:m-48 {
    margin: 12rem !important;
  }

  .md\:m-56 {
    margin: 14rem !important;
  }

  .md\:m-64 {
    margin: 16rem !important;
  }

  .md\:m-auto {
    margin: auto !important;
  }

  .md\:m-px {
    margin: 1px !important;
  }

  .md\:m-2px {
    margin: 2px !important;
  }

  .md\:-m-1 {
    margin: -0.25rem !important;
  }

  .md\:-m-2 {
    margin: -0.5rem !important;
  }

  .md\:-m-3 {
    margin: -0.75rem !important;
  }

  .md\:-m-4 {
    margin: -1rem !important;
  }

  .md\:-m-5 {
    margin: -1.25rem !important;
  }

  .md\:-m-6 {
    margin: -1.5rem !important;
  }

  .md\:-m-8 {
    margin: -2rem !important;
  }

  .md\:-m-10 {
    margin: -2.5rem !important;
  }

  .md\:-m-12 {
    margin: -3rem !important;
  }

  .md\:-m-14 {
    margin: -3.5rem !important;
  }

  .md\:-m-16 {
    margin: -4rem !important;
  }

  .md\:-m-18 {
    margin: -4.5rem !important;
  }

  .md\:-m-20 {
    margin: -5rem !important;
  }

  .md\:-m-22 {
    margin: -5.5rem !important;
  }

  .md\:-m-24 {
    margin: -6rem !important;
  }

  .md\:-m-26 {
    margin: -6.5rem !important;
  }

  .md\:-m-28 {
    margin: -7rem !important;
  }

  .md\:-m-30 {
    margin: -7.5rem !important;
  }

  .md\:-m-32 {
    margin: -8rem !important;
  }

  .md\:-m-36 {
    margin: -9rem !important;
  }

  .md\:-m-40 {
    margin: -10rem !important;
  }

  .md\:-m-48 {
    margin: -12rem !important;
  }

  .md\:-m-56 {
    margin: -14rem !important;
  }

  .md\:-m-64 {
    margin: -16rem !important;
  }

  .md\:-m-px {
    margin: -1px !important;
  }

  .md\:-m-2px {
    margin: -2px !important;
  }

  .md\:my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .md\:mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .md\:my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .md\:mx-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }

  .md\:my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .md\:mx-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .md\:my-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .md\:mx-3 {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
  }

  .md\:my-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .md\:mx-4 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .md\:my-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .md\:mx-5 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }

  .md\:my-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .md\:mx-6 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .md\:my-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .md\:mx-8 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .md\:my-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .md\:mx-10 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .md\:my-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .md\:mx-12 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .md\:my-14 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .md\:mx-14 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }

  .md\:my-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .md\:mx-16 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .md\:my-18 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .md\:mx-18 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }

  .md\:my-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .md\:mx-20 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .md\:my-22 {
    margin-top: 5.5rem !important;
    margin-bottom: 5.5rem !important;
  }

  .md\:mx-22 {
    margin-left: 5.5rem !important;
    margin-right: 5.5rem !important;
  }

  .md\:my-24 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .md\:mx-24 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }

  .md\:my-26 {
    margin-top: 6.5rem !important;
    margin-bottom: 6.5rem !important;
  }

  .md\:mx-26 {
    margin-left: 6.5rem !important;
    margin-right: 6.5rem !important;
  }

  .md\:my-28 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }

  .md\:mx-28 {
    margin-left: 7rem !important;
    margin-right: 7rem !important;
  }

  .md\:my-30 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }

  .md\:mx-30 {
    margin-left: 7.5rem !important;
    margin-right: 7.5rem !important;
  }

  .md\:my-32 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .md\:mx-32 {
    margin-left: 8rem !important;
    margin-right: 8rem !important;
  }

  .md\:my-36 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }

  .md\:mx-36 {
    margin-left: 9rem !important;
    margin-right: 9rem !important;
  }

  .md\:my-40 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .md\:mx-40 {
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }

  .md\:my-48 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }

  .md\:mx-48 {
    margin-left: 12rem !important;
    margin-right: 12rem !important;
  }

  .md\:my-56 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }

  .md\:mx-56 {
    margin-left: 14rem !important;
    margin-right: 14rem !important;
  }

  .md\:my-64 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }

  .md\:mx-64 {
    margin-left: 16rem !important;
    margin-right: 16rem !important;
  }

  .md\:my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .md\:mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .md\:my-px {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  .md\:mx-px {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }

  .md\:my-2px {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }

  .md\:mx-2px {
    margin-left: 2px !important;
    margin-right: 2px !important;
  }

  .md\:-my-1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .md\:-mx-1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }

  .md\:-my-2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .md\:-mx-2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }

  .md\:-my-3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }

  .md\:-mx-3 {
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important;
  }

  .md\:-my-4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .md\:-mx-4 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .md\:-my-5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .md\:-mx-5 {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }

  .md\:-my-6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .md\:-mx-6 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .md\:-my-8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .md\:-mx-8 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .md\:-my-10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .md\:-mx-10 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }

  .md\:-my-12 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .md\:-mx-12 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .md\:-my-14 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .md\:-mx-14 {
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }

  .md\:-my-16 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .md\:-mx-16 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .md\:-my-18 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .md\:-mx-18 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }

  .md\:-my-20 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .md\:-mx-20 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .md\:-my-22 {
    margin-top: -5.5rem !important;
    margin-bottom: -5.5rem !important;
  }

  .md\:-mx-22 {
    margin-left: -5.5rem !important;
    margin-right: -5.5rem !important;
  }

  .md\:-my-24 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .md\:-mx-24 {
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }

  .md\:-my-26 {
    margin-top: -6.5rem !important;
    margin-bottom: -6.5rem !important;
  }

  .md\:-mx-26 {
    margin-left: -6.5rem !important;
    margin-right: -6.5rem !important;
  }

  .md\:-my-28 {
    margin-top: -7rem !important;
    margin-bottom: -7rem !important;
  }

  .md\:-mx-28 {
    margin-left: -7rem !important;
    margin-right: -7rem !important;
  }

  .md\:-my-30 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important;
  }

  .md\:-mx-30 {
    margin-left: -7.5rem !important;
    margin-right: -7.5rem !important;
  }

  .md\:-my-32 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .md\:-mx-32 {
    margin-left: -8rem !important;
    margin-right: -8rem !important;
  }

  .md\:-my-36 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }

  .md\:-mx-36 {
    margin-left: -9rem !important;
    margin-right: -9rem !important;
  }

  .md\:-my-40 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .md\:-mx-40 {
    margin-left: -10rem !important;
    margin-right: -10rem !important;
  }

  .md\:-my-48 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }

  .md\:-mx-48 {
    margin-left: -12rem !important;
    margin-right: -12rem !important;
  }

  .md\:-my-56 {
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }

  .md\:-mx-56 {
    margin-left: -14rem !important;
    margin-right: -14rem !important;
  }

  .md\:-my-64 {
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }

  .md\:-mx-64 {
    margin-left: -16rem !important;
    margin-right: -16rem !important;
  }

  .md\:-my-px {
    margin-top: -1px !important;
    margin-bottom: -1px !important;
  }

  .md\:-mx-px {
    margin-left: -1px !important;
    margin-right: -1px !important;
  }

  .md\:-my-2px {
    margin-top: -2px !important;
    margin-bottom: -2px !important;
  }

  .md\:-mx-2px {
    margin-left: -2px !important;
    margin-right: -2px !important;
  }

  .md\:mt-0 {
    margin-top: 0 !important;
  }

  .md\:mr-0 {
    margin-right: 0 !important;
  }

  .md\:mb-0 {
    margin-bottom: 0 !important;
  }

  .md\:ml-0 {
    margin-left: 0 !important;
  }

  .md\:mt-1 {
    margin-top: 0.25rem !important;
  }

  .md\:mr-1 {
    margin-right: 0.25rem !important;
  }

  .md\:mb-1 {
    margin-bottom: 0.25rem !important;
  }

  .md\:ml-1 {
    margin-left: 0.25rem !important;
  }

  .md\:mt-2 {
    margin-top: 0.5rem !important;
  }

  .md\:mr-2 {
    margin-right: 0.5rem !important;
  }

  .md\:mb-2 {
    margin-bottom: 0.5rem !important;
  }

  .md\:ml-2 {
    margin-left: 0.5rem !important;
  }

  .md\:mt-3 {
    margin-top: 0.75rem !important;
  }

  .md\:mr-3 {
    margin-right: 0.75rem !important;
  }

  .md\:mb-3 {
    margin-bottom: 0.75rem !important;
  }

  .md\:ml-3 {
    margin-left: 0.75rem !important;
  }

  .md\:mt-4 {
    margin-top: 1rem !important;
  }

  .md\:mr-4 {
    margin-right: 1rem !important;
  }

  .md\:mb-4 {
    margin-bottom: 1rem !important;
  }

  .md\:ml-4 {
    margin-left: 1rem !important;
  }

  .md\:mt-5 {
    margin-top: 1.25rem !important;
  }

  .md\:mr-5 {
    margin-right: 1.25rem !important;
  }

  .md\:mb-5 {
    margin-bottom: 1.25rem !important;
  }

  .md\:ml-5 {
    margin-left: 1.25rem !important;
  }

  .md\:mt-6 {
    margin-top: 1.5rem !important;
  }

  .md\:mr-6 {
    margin-right: 1.5rem !important;
  }

  .md\:mb-6 {
    margin-bottom: 1.5rem !important;
  }

  .md\:ml-6 {
    margin-left: 1.5rem !important;
  }

  .md\:mt-8 {
    margin-top: 2rem !important;
  }

  .md\:mr-8 {
    margin-right: 2rem !important;
  }

  .md\:mb-8 {
    margin-bottom: 2rem !important;
  }

  .md\:ml-8 {
    margin-left: 2rem !important;
  }

  .md\:mt-10 {
    margin-top: 2.5rem !important;
  }

  .md\:mr-10 {
    margin-right: 2.5rem !important;
  }

  .md\:mb-10 {
    margin-bottom: 2.5rem !important;
  }

  .md\:ml-10 {
    margin-left: 2.5rem !important;
  }

  .md\:mt-12 {
    margin-top: 3rem !important;
  }

  .md\:mr-12 {
    margin-right: 3rem !important;
  }

  .md\:mb-12 {
    margin-bottom: 3rem !important;
  }

  .md\:ml-12 {
    margin-left: 3rem !important;
  }

  .md\:mt-14 {
    margin-top: 3.5rem !important;
  }

  .md\:mr-14 {
    margin-right: 3.5rem !important;
  }

  .md\:mb-14 {
    margin-bottom: 3.5rem !important;
  }

  .md\:ml-14 {
    margin-left: 3.5rem !important;
  }

  .md\:mt-16 {
    margin-top: 4rem !important;
  }

  .md\:mr-16 {
    margin-right: 4rem !important;
  }

  .md\:mb-16 {
    margin-bottom: 4rem !important;
  }

  .md\:ml-16 {
    margin-left: 4rem !important;
  }

  .md\:mt-18 {
    margin-top: 4.5rem !important;
  }

  .md\:mr-18 {
    margin-right: 4.5rem !important;
  }

  .md\:mb-18 {
    margin-bottom: 4.5rem !important;
  }

  .md\:ml-18 {
    margin-left: 4.5rem !important;
  }

  .md\:mt-20 {
    margin-top: 5rem !important;
  }

  .md\:mr-20 {
    margin-right: 5rem !important;
  }

  .md\:mb-20 {
    margin-bottom: 5rem !important;
  }

  .md\:ml-20 {
    margin-left: 5rem !important;
  }

  .md\:mt-22 {
    margin-top: 5.5rem !important;
  }

  .md\:mr-22 {
    margin-right: 5.5rem !important;
  }

  .md\:mb-22 {
    margin-bottom: 5.5rem !important;
  }

  .md\:ml-22 {
    margin-left: 5.5rem !important;
  }

  .md\:mt-24 {
    margin-top: 6rem !important;
  }

  .md\:mr-24 {
    margin-right: 6rem !important;
  }

  .md\:mb-24 {
    margin-bottom: 6rem !important;
  }

  .md\:ml-24 {
    margin-left: 6rem !important;
  }

  .md\:mt-26 {
    margin-top: 6.5rem !important;
  }

  .md\:mr-26 {
    margin-right: 6.5rem !important;
  }

  .md\:mb-26 {
    margin-bottom: 6.5rem !important;
  }

  .md\:ml-26 {
    margin-left: 6.5rem !important;
  }

  .md\:mt-28 {
    margin-top: 7rem !important;
  }

  .md\:mr-28 {
    margin-right: 7rem !important;
  }

  .md\:mb-28 {
    margin-bottom: 7rem !important;
  }

  .md\:ml-28 {
    margin-left: 7rem !important;
  }

  .md\:mt-30 {
    margin-top: 7.5rem !important;
  }

  .md\:mr-30 {
    margin-right: 7.5rem !important;
  }

  .md\:mb-30 {
    margin-bottom: 7.5rem !important;
  }

  .md\:ml-30 {
    margin-left: 7.5rem !important;
  }

  .md\:mt-32 {
    margin-top: 8rem !important;
  }

  .md\:mr-32 {
    margin-right: 8rem !important;
  }

  .md\:mb-32 {
    margin-bottom: 8rem !important;
  }

  .md\:ml-32 {
    margin-left: 8rem !important;
  }

  .md\:mt-36 {
    margin-top: 9rem !important;
  }

  .md\:mr-36 {
    margin-right: 9rem !important;
  }

  .md\:mb-36 {
    margin-bottom: 9rem !important;
  }

  .md\:ml-36 {
    margin-left: 9rem !important;
  }

  .md\:mt-40 {
    margin-top: 10rem !important;
  }

  .md\:mr-40 {
    margin-right: 10rem !important;
  }

  .md\:mb-40 {
    margin-bottom: 10rem !important;
  }

  .md\:ml-40 {
    margin-left: 10rem !important;
  }

  .md\:mt-48 {
    margin-top: 12rem !important;
  }

  .md\:mr-48 {
    margin-right: 12rem !important;
  }

  .md\:mb-48 {
    margin-bottom: 12rem !important;
  }

  .md\:ml-48 {
    margin-left: 12rem !important;
  }

  .md\:mt-56 {
    margin-top: 14rem !important;
  }

  .md\:mr-56 {
    margin-right: 14rem !important;
  }

  .md\:mb-56 {
    margin-bottom: 14rem !important;
  }

  .md\:ml-56 {
    margin-left: 14rem !important;
  }

  .md\:mt-64 {
    margin-top: 16rem !important;
  }

  .md\:mr-64 {
    margin-right: 16rem !important;
  }

  .md\:mb-64 {
    margin-bottom: 16rem !important;
  }

  .md\:ml-64 {
    margin-left: 16rem !important;
  }

  .md\:mt-auto {
    margin-top: auto !important;
  }

  .md\:mr-auto {
    margin-right: auto !important;
  }

  .md\:mb-auto {
    margin-bottom: auto !important;
  }

  .md\:ml-auto {
    margin-left: auto !important;
  }

  .md\:mt-px {
    margin-top: 1px !important;
  }

  .md\:mr-px {
    margin-right: 1px !important;
  }

  .md\:mb-px {
    margin-bottom: 1px !important;
  }

  .md\:ml-px {
    margin-left: 1px !important;
  }

  .md\:mt-2px {
    margin-top: 2px !important;
  }

  .md\:mr-2px {
    margin-right: 2px !important;
  }

  .md\:mb-2px {
    margin-bottom: 2px !important;
  }

  .md\:ml-2px {
    margin-left: 2px !important;
  }

  .md\:-mt-1 {
    margin-top: -0.25rem !important;
  }

  .md\:-mr-1 {
    margin-right: -0.25rem !important;
  }

  .md\:-mb-1 {
    margin-bottom: -0.25rem !important;
  }

  .md\:-ml-1 {
    margin-left: -0.25rem !important;
  }

  .md\:-mt-2 {
    margin-top: -0.5rem !important;
  }

  .md\:-mr-2 {
    margin-right: -0.5rem !important;
  }

  .md\:-mb-2 {
    margin-bottom: -0.5rem !important;
  }

  .md\:-ml-2 {
    margin-left: -0.5rem !important;
  }

  .md\:-mt-3 {
    margin-top: -0.75rem !important;
  }

  .md\:-mr-3 {
    margin-right: -0.75rem !important;
  }

  .md\:-mb-3 {
    margin-bottom: -0.75rem !important;
  }

  .md\:-ml-3 {
    margin-left: -0.75rem !important;
  }

  .md\:-mt-4 {
    margin-top: -1rem !important;
  }

  .md\:-mr-4 {
    margin-right: -1rem !important;
  }

  .md\:-mb-4 {
    margin-bottom: -1rem !important;
  }

  .md\:-ml-4 {
    margin-left: -1rem !important;
  }

  .md\:-mt-5 {
    margin-top: -1.25rem !important;
  }

  .md\:-mr-5 {
    margin-right: -1.25rem !important;
  }

  .md\:-mb-5 {
    margin-bottom: -1.25rem !important;
  }

  .md\:-ml-5 {
    margin-left: -1.25rem !important;
  }

  .md\:-mt-6 {
    margin-top: -1.5rem !important;
  }

  .md\:-mr-6 {
    margin-right: -1.5rem !important;
  }

  .md\:-mb-6 {
    margin-bottom: -1.5rem !important;
  }

  .md\:-ml-6 {
    margin-left: -1.5rem !important;
  }

  .md\:-mt-8 {
    margin-top: -2rem !important;
  }

  .md\:-mr-8 {
    margin-right: -2rem !important;
  }

  .md\:-mb-8 {
    margin-bottom: -2rem !important;
  }

  .md\:-ml-8 {
    margin-left: -2rem !important;
  }

  .md\:-mt-10 {
    margin-top: -2.5rem !important;
  }

  .md\:-mr-10 {
    margin-right: -2.5rem !important;
  }

  .md\:-mb-10 {
    margin-bottom: -2.5rem !important;
  }

  .md\:-ml-10 {
    margin-left: -2.5rem !important;
  }

  .md\:-mt-12 {
    margin-top: -3rem !important;
  }

  .md\:-mr-12 {
    margin-right: -3rem !important;
  }

  .md\:-mb-12 {
    margin-bottom: -3rem !important;
  }

  .md\:-ml-12 {
    margin-left: -3rem !important;
  }

  .md\:-mt-14 {
    margin-top: -3.5rem !important;
  }

  .md\:-mr-14 {
    margin-right: -3.5rem !important;
  }

  .md\:-mb-14 {
    margin-bottom: -3.5rem !important;
  }

  .md\:-ml-14 {
    margin-left: -3.5rem !important;
  }

  .md\:-mt-16 {
    margin-top: -4rem !important;
  }

  .md\:-mr-16 {
    margin-right: -4rem !important;
  }

  .md\:-mb-16 {
    margin-bottom: -4rem !important;
  }

  .md\:-ml-16 {
    margin-left: -4rem !important;
  }

  .md\:-mt-18 {
    margin-top: -4.5rem !important;
  }

  .md\:-mr-18 {
    margin-right: -4.5rem !important;
  }

  .md\:-mb-18 {
    margin-bottom: -4.5rem !important;
  }

  .md\:-ml-18 {
    margin-left: -4.5rem !important;
  }

  .md\:-mt-20 {
    margin-top: -5rem !important;
  }

  .md\:-mr-20 {
    margin-right: -5rem !important;
  }

  .md\:-mb-20 {
    margin-bottom: -5rem !important;
  }

  .md\:-ml-20 {
    margin-left: -5rem !important;
  }

  .md\:-mt-22 {
    margin-top: -5.5rem !important;
  }

  .md\:-mr-22 {
    margin-right: -5.5rem !important;
  }

  .md\:-mb-22 {
    margin-bottom: -5.5rem !important;
  }

  .md\:-ml-22 {
    margin-left: -5.5rem !important;
  }

  .md\:-mt-24 {
    margin-top: -6rem !important;
  }

  .md\:-mr-24 {
    margin-right: -6rem !important;
  }

  .md\:-mb-24 {
    margin-bottom: -6rem !important;
  }

  .md\:-ml-24 {
    margin-left: -6rem !important;
  }

  .md\:-mt-26 {
    margin-top: -6.5rem !important;
  }

  .md\:-mr-26 {
    margin-right: -6.5rem !important;
  }

  .md\:-mb-26 {
    margin-bottom: -6.5rem !important;
  }

  .md\:-ml-26 {
    margin-left: -6.5rem !important;
  }

  .md\:-mt-28 {
    margin-top: -7rem !important;
  }

  .md\:-mr-28 {
    margin-right: -7rem !important;
  }

  .md\:-mb-28 {
    margin-bottom: -7rem !important;
  }

  .md\:-ml-28 {
    margin-left: -7rem !important;
  }

  .md\:-mt-30 {
    margin-top: -7.5rem !important;
  }

  .md\:-mr-30 {
    margin-right: -7.5rem !important;
  }

  .md\:-mb-30 {
    margin-bottom: -7.5rem !important;
  }

  .md\:-ml-30 {
    margin-left: -7.5rem !important;
  }

  .md\:-mt-32 {
    margin-top: -8rem !important;
  }

  .md\:-mr-32 {
    margin-right: -8rem !important;
  }

  .md\:-mb-32 {
    margin-bottom: -8rem !important;
  }

  .md\:-ml-32 {
    margin-left: -8rem !important;
  }

  .md\:-mt-36 {
    margin-top: -9rem !important;
  }

  .md\:-mr-36 {
    margin-right: -9rem !important;
  }

  .md\:-mb-36 {
    margin-bottom: -9rem !important;
  }

  .md\:-ml-36 {
    margin-left: -9rem !important;
  }

  .md\:-mt-40 {
    margin-top: -10rem !important;
  }

  .md\:-mr-40 {
    margin-right: -10rem !important;
  }

  .md\:-mb-40 {
    margin-bottom: -10rem !important;
  }

  .md\:-ml-40 {
    margin-left: -10rem !important;
  }

  .md\:-mt-48 {
    margin-top: -12rem !important;
  }

  .md\:-mr-48 {
    margin-right: -12rem !important;
  }

  .md\:-mb-48 {
    margin-bottom: -12rem !important;
  }

  .md\:-ml-48 {
    margin-left: -12rem !important;
  }

  .md\:-mt-56 {
    margin-top: -14rem !important;
  }

  .md\:-mr-56 {
    margin-right: -14rem !important;
  }

  .md\:-mb-56 {
    margin-bottom: -14rem !important;
  }

  .md\:-ml-56 {
    margin-left: -14rem !important;
  }

  .md\:-mt-64 {
    margin-top: -16rem !important;
  }

  .md\:-mr-64 {
    margin-right: -16rem !important;
  }

  .md\:-mb-64 {
    margin-bottom: -16rem !important;
  }

  .md\:-ml-64 {
    margin-left: -16rem !important;
  }

  .md\:-mt-px {
    margin-top: -1px !important;
  }

  .md\:-mr-px {
    margin-right: -1px !important;
  }

  .md\:-mb-px {
    margin-bottom: -1px !important;
  }

  .md\:-ml-px {
    margin-left: -1px !important;
  }

  .md\:-mt-2px {
    margin-top: -2px !important;
  }

  .md\:-mr-2px {
    margin-right: -2px !important;
  }

  .md\:-mb-2px {
    margin-bottom: -2px !important;
  }

  .md\:-ml-2px {
    margin-left: -2px !important;
  }

  .md\:max-h-0 {
    max-height: 0 !important;
  }

  .md\:max-h-1 {
    max-height: 0.25rem !important;
  }

  .md\:max-h-2 {
    max-height: 0.5rem !important;
  }

  .md\:max-h-3 {
    max-height: 0.75rem !important;
  }

  .md\:max-h-4 {
    max-height: 1rem !important;
  }

  .md\:max-h-5 {
    max-height: 1.25rem !important;
  }

  .md\:max-h-6 {
    max-height: 1.5rem !important;
  }

  .md\:max-h-8 {
    max-height: 2rem !important;
  }

  .md\:max-h-10 {
    max-height: 2.5rem !important;
  }

  .md\:max-h-12 {
    max-height: 3rem !important;
  }

  .md\:max-h-14 {
    max-height: 3.5rem !important;
  }

  .md\:max-h-16 {
    max-height: 4rem !important;
  }

  .md\:max-h-18 {
    max-height: 4.5rem !important;
  }

  .md\:max-h-20 {
    max-height: 5rem !important;
  }

  .md\:max-h-22 {
    max-height: 5.5rem !important;
  }

  .md\:max-h-24 {
    max-height: 6rem !important;
  }

  .md\:max-h-26 {
    max-height: 6.5rem !important;
  }

  .md\:max-h-28 {
    max-height: 7rem !important;
  }

  .md\:max-h-30 {
    max-height: 7.5rem !important;
  }

  .md\:max-h-32 {
    max-height: 8rem !important;
  }

  .md\:max-h-36 {
    max-height: 9rem !important;
  }

  .md\:max-h-40 {
    max-height: 10rem !important;
  }

  .md\:max-h-48 {
    max-height: 12rem !important;
  }

  .md\:max-h-50 {
    max-height: 12.5rem !important;
  }

  .md\:max-h-56 {
    max-height: 14rem !important;
  }

  .md\:max-h-60 {
    max-height: 15rem !important;
  }

  .md\:max-h-64 {
    max-height: 16rem !important;
  }

  .md\:max-h-80 {
    max-height: 20rem !important;
  }

  .md\:max-h-90 {
    max-height: 24rem !important;
  }

  .md\:max-h-100 {
    max-height: 25rem !important;
  }

  .md\:max-h-120 {
    max-height: 30rem !important;
  }

  .md\:max-h-128 {
    max-height: 32rem !important;
  }

  .md\:max-h-140 {
    max-height: 35rem !important;
  }

  .md\:max-h-160 {
    max-height: 40rem !important;
  }

  .md\:max-h-180 {
    max-height: 45rem !important;
  }

  .md\:max-h-192 {
    max-height: 48rem !important;
  }

  .md\:max-h-200 {
    max-height: 50rem !important;
  }

  .md\:max-h-240 {
    max-height: 60rem !important;
  }

  .md\:max-h-256 {
    max-height: 64rem !important;
  }

  .md\:max-h-280 {
    max-height: 70rem !important;
  }

  .md\:max-h-320 {
    max-height: 80rem !important;
  }

  .md\:max-h-360 {
    max-height: 90rem !important;
  }

  .md\:max-h-400 {
    max-height: 100rem !important;
  }

  .md\:max-h-480 {
    max-height: 120rem !important;
  }

  .md\:max-h-full {
    max-height: 100% !important;
  }

  .md\:max-h-screen {
    max-height: 100vh !important;
  }

  .md\:max-h-none {
    max-height: none !important;
  }

  .md\:max-h-px {
    max-height: 1px !important;
  }

  .md\:max-h-2px {
    max-height: 2px !important;
  }

  .md\:max-h-1\/2 {
    max-height: 50% !important;
  }

  .md\:max-h-1\/3 {
    max-height: 33.33333% !important;
  }

  .md\:max-h-2\/3 {
    max-height: 66.66667% !important;
  }

  .md\:max-h-1\/4 {
    max-height: 25% !important;
  }

  .md\:max-h-2\/4 {
    max-height: 50% !important;
  }

  .md\:max-h-3\/4 {
    max-height: 75% !important;
  }

  .md\:max-h-1\/5 {
    max-height: 20% !important;
  }

  .md\:max-h-2\/5 {
    max-height: 40% !important;
  }

  .md\:max-h-3\/5 {
    max-height: 60% !important;
  }

  .md\:max-h-4\/5 {
    max-height: 80% !important;
  }

  .md\:max-h-1\/12 {
    max-height: 8.33333% !important;
  }

  .md\:max-h-2\/12 {
    max-height: 16.66667% !important;
  }

  .md\:max-h-3\/12 {
    max-height: 25% !important;
  }

  .md\:max-h-4\/12 {
    max-height: 33.33333% !important;
  }

  .md\:max-h-5\/12 {
    max-height: 41.66667% !important;
  }

  .md\:max-h-6\/12 {
    max-height: 50% !important;
  }

  .md\:max-h-7\/12 {
    max-height: 58.33333% !important;
  }

  .md\:max-h-8\/12 {
    max-height: 66.66667% !important;
  }

  .md\:max-h-9\/12 {
    max-height: 75% !important;
  }

  .md\:max-h-10\/12 {
    max-height: 83.33333% !important;
  }

  .md\:max-h-11\/12 {
    max-height: 91.66667% !important;
  }

  .md\:max-w-0 {
    max-width: 0 !important;
  }

  .md\:max-w-1 {
    max-width: 0.25rem !important;
  }

  .md\:max-w-2 {
    max-width: 0.5rem !important;
  }

  .md\:max-w-3 {
    max-width: 0.75rem !important;
  }

  .md\:max-w-4 {
    max-width: 1rem !important;
  }

  .md\:max-w-5 {
    max-width: 1.25rem !important;
  }

  .md\:max-w-6 {
    max-width: 1.5rem !important;
  }

  .md\:max-w-8 {
    max-width: 2rem !important;
  }

  .md\:max-w-10 {
    max-width: 2.5rem !important;
  }

  .md\:max-w-12 {
    max-width: 3rem !important;
  }

  .md\:max-w-14 {
    max-width: 3.5rem !important;
  }

  .md\:max-w-16 {
    max-width: 4rem !important;
  }

  .md\:max-w-18 {
    max-width: 4.5rem !important;
  }

  .md\:max-w-20 {
    max-width: 5rem !important;
  }

  .md\:max-w-22 {
    max-width: 5.5rem !important;
  }

  .md\:max-w-24 {
    max-width: 6rem !important;
  }

  .md\:max-w-26 {
    max-width: 6.5rem !important;
  }

  .md\:max-w-28 {
    max-width: 7rem !important;
  }

  .md\:max-w-30 {
    max-width: 7.5rem !important;
  }

  .md\:max-w-32 {
    max-width: 8rem !important;
  }

  .md\:max-w-36 {
    max-width: 9rem !important;
  }

  .md\:max-w-40 {
    max-width: 10rem !important;
  }

  .md\:max-w-48 {
    max-width: 12rem !important;
  }

  .md\:max-w-50 {
    max-width: 12.5rem !important;
  }

  .md\:max-w-56 {
    max-width: 14rem !important;
  }

  .md\:max-w-60 {
    max-width: 15rem !important;
  }

  .md\:max-w-64 {
    max-width: 16rem !important;
  }

  .md\:max-w-80 {
    max-width: 20rem !important;
  }

  .md\:max-w-90 {
    max-width: 24rem !important;
  }

  .md\:max-w-100 {
    max-width: 25rem !important;
  }

  .md\:max-w-120 {
    max-width: 30rem !important;
  }

  .md\:max-w-128 {
    max-width: 32rem !important;
  }

  .md\:max-w-140 {
    max-width: 35rem !important;
  }

  .md\:max-w-160 {
    max-width: 40rem !important;
  }

  .md\:max-w-180 {
    max-width: 45rem !important;
  }

  .md\:max-w-192 {
    max-width: 48rem !important;
  }

  .md\:max-w-200 {
    max-width: 50rem !important;
  }

  .md\:max-w-240 {
    max-width: 60rem !important;
  }

  .md\:max-w-256 {
    max-width: 64rem !important;
  }

  .md\:max-w-280 {
    max-width: 70rem !important;
  }

  .md\:max-w-320 {
    max-width: 80rem !important;
  }

  .md\:max-w-360 {
    max-width: 90rem !important;
  }

  .md\:max-w-400 {
    max-width: 100rem !important;
  }

  .md\:max-w-480 {
    max-width: 120rem !important;
  }

  .md\:max-w-none {
    max-width: none !important;
  }

  .md\:max-w-xs {
    max-width: 20rem !important;
  }

  .md\:max-w-sm {
    max-width: 24rem !important;
  }

  .md\:max-w-md {
    max-width: 28rem !important;
  }

  .md\:max-w-lg {
    max-width: 32rem !important;
  }

  .md\:max-w-xl {
    max-width: 36rem !important;
  }

  .md\:max-w-2xl {
    max-width: 42rem !important;
  }

  .md\:max-w-3xl {
    max-width: 48rem !important;
  }

  .md\:max-w-4xl {
    max-width: 56rem !important;
  }

  .md\:max-w-5xl {
    max-width: 64rem !important;
  }

  .md\:max-w-6xl {
    max-width: 72rem !important;
  }

  .md\:max-w-full {
    max-width: 100% !important;
  }

  .md\:max-w-screen {
    max-width: 100vw !important;
  }

  .md\:max-w-px {
    max-width: 1px !important;
  }

  .md\:max-w-2px {
    max-width: 2px !important;
  }

  .md\:max-w-1\/2 {
    max-width: 50% !important;
  }

  .md\:max-w-1\/3 {
    max-width: 33.33333% !important;
  }

  .md\:max-w-2\/3 {
    max-width: 66.66667% !important;
  }

  .md\:max-w-1\/4 {
    max-width: 25% !important;
  }

  .md\:max-w-2\/4 {
    max-width: 50% !important;
  }

  .md\:max-w-3\/4 {
    max-width: 75% !important;
  }

  .md\:max-w-1\/5 {
    max-width: 20% !important;
  }

  .md\:max-w-2\/5 {
    max-width: 40% !important;
  }

  .md\:max-w-3\/5 {
    max-width: 60% !important;
  }

  .md\:max-w-4\/5 {
    max-width: 80% !important;
  }

  .md\:max-w-1\/12 {
    max-width: 8.33333% !important;
  }

  .md\:max-w-2\/12 {
    max-width: 16.66667% !important;
  }

  .md\:max-w-3\/12 {
    max-width: 25% !important;
  }

  .md\:max-w-4\/12 {
    max-width: 33.33333% !important;
  }

  .md\:max-w-5\/12 {
    max-width: 41.66667% !important;
  }

  .md\:max-w-6\/12 {
    max-width: 50% !important;
  }

  .md\:max-w-7\/12 {
    max-width: 58.33333% !important;
  }

  .md\:max-w-8\/12 {
    max-width: 66.66667% !important;
  }

  .md\:max-w-9\/12 {
    max-width: 75% !important;
  }

  .md\:max-w-10\/12 {
    max-width: 83.33333% !important;
  }

  .md\:max-w-11\/12 {
    max-width: 91.66667% !important;
  }

  .md\:min-h-0 {
    min-height: 0 !important;
  }

  .md\:min-h-1 {
    min-height: 0.25rem !important;
  }

  .md\:min-h-2 {
    min-height: 0.5rem !important;
  }

  .md\:min-h-3 {
    min-height: 0.75rem !important;
  }

  .md\:min-h-4 {
    min-height: 1rem !important;
  }

  .md\:min-h-5 {
    min-height: 1.25rem !important;
  }

  .md\:min-h-6 {
    min-height: 1.5rem !important;
  }

  .md\:min-h-8 {
    min-height: 2rem !important;
  }

  .md\:min-h-10 {
    min-height: 2.5rem !important;
  }

  .md\:min-h-12 {
    min-height: 3rem !important;
  }

  .md\:min-h-14 {
    min-height: 3.5rem !important;
  }

  .md\:min-h-16 {
    min-height: 4rem !important;
  }

  .md\:min-h-18 {
    min-height: 4.5rem !important;
  }

  .md\:min-h-20 {
    min-height: 5rem !important;
  }

  .md\:min-h-22 {
    min-height: 5.5rem !important;
  }

  .md\:min-h-24 {
    min-height: 6rem !important;
  }

  .md\:min-h-26 {
    min-height: 6.5rem !important;
  }

  .md\:min-h-28 {
    min-height: 7rem !important;
  }

  .md\:min-h-30 {
    min-height: 7.5rem !important;
  }

  .md\:min-h-32 {
    min-height: 8rem !important;
  }

  .md\:min-h-36 {
    min-height: 9rem !important;
  }

  .md\:min-h-40 {
    min-height: 10rem !important;
  }

  .md\:min-h-48 {
    min-height: 12rem !important;
  }

  .md\:min-h-50 {
    min-height: 12.5rem !important;
  }

  .md\:min-h-56 {
    min-height: 14rem !important;
  }

  .md\:min-h-60 {
    min-height: 15rem !important;
  }

  .md\:min-h-64 {
    min-height: 16rem !important;
  }

  .md\:min-h-80 {
    min-height: 20rem !important;
  }

  .md\:min-h-90 {
    min-height: 24rem !important;
  }

  .md\:min-h-100 {
    min-height: 25rem !important;
  }

  .md\:min-h-120 {
    min-height: 30rem !important;
  }

  .md\:min-h-128 {
    min-height: 32rem !important;
  }

  .md\:min-h-140 {
    min-height: 35rem !important;
  }

  .md\:min-h-160 {
    min-height: 40rem !important;
  }

  .md\:min-h-180 {
    min-height: 45rem !important;
  }

  .md\:min-h-192 {
    min-height: 48rem !important;
  }

  .md\:min-h-200 {
    min-height: 50rem !important;
  }

  .md\:min-h-240 {
    min-height: 60rem !important;
  }

  .md\:min-h-256 {
    min-height: 64rem !important;
  }

  .md\:min-h-280 {
    min-height: 70rem !important;
  }

  .md\:min-h-320 {
    min-height: 80rem !important;
  }

  .md\:min-h-360 {
    min-height: 90rem !important;
  }

  .md\:min-h-400 {
    min-height: 100rem !important;
  }

  .md\:min-h-480 {
    min-height: 120rem !important;
  }

  .md\:min-h-full {
    min-height: 100% !important;
  }

  .md\:min-h-screen {
    min-height: 100vh !important;
  }

  .md\:min-h-px {
    min-height: 1px !important;
  }

  .md\:min-h-2px {
    min-height: 2px !important;
  }

  .md\:min-h-1\/2 {
    min-height: 50% !important;
  }

  .md\:min-h-1\/3 {
    min-height: 33.33333% !important;
  }

  .md\:min-h-2\/3 {
    min-height: 66.66667% !important;
  }

  .md\:min-h-1\/4 {
    min-height: 25% !important;
  }

  .md\:min-h-2\/4 {
    min-height: 50% !important;
  }

  .md\:min-h-3\/4 {
    min-height: 75% !important;
  }

  .md\:min-h-1\/5 {
    min-height: 20% !important;
  }

  .md\:min-h-2\/5 {
    min-height: 40% !important;
  }

  .md\:min-h-3\/5 {
    min-height: 60% !important;
  }

  .md\:min-h-4\/5 {
    min-height: 80% !important;
  }

  .md\:min-h-1\/12 {
    min-height: 8.33333% !important;
  }

  .md\:min-h-2\/12 {
    min-height: 16.66667% !important;
  }

  .md\:min-h-3\/12 {
    min-height: 25% !important;
  }

  .md\:min-h-4\/12 {
    min-height: 33.33333% !important;
  }

  .md\:min-h-5\/12 {
    min-height: 41.66667% !important;
  }

  .md\:min-h-6\/12 {
    min-height: 50% !important;
  }

  .md\:min-h-7\/12 {
    min-height: 58.33333% !important;
  }

  .md\:min-h-8\/12 {
    min-height: 66.66667% !important;
  }

  .md\:min-h-9\/12 {
    min-height: 75% !important;
  }

  .md\:min-h-10\/12 {
    min-height: 83.33333% !important;
  }

  .md\:min-h-11\/12 {
    min-height: 91.66667% !important;
  }

  .md\:min-w-0 {
    min-width: 0 !important;
  }

  .md\:min-w-1 {
    min-width: 0.25rem !important;
  }

  .md\:min-w-2 {
    min-width: 0.5rem !important;
  }

  .md\:min-w-3 {
    min-width: 0.75rem !important;
  }

  .md\:min-w-4 {
    min-width: 1rem !important;
  }

  .md\:min-w-5 {
    min-width: 1.25rem !important;
  }

  .md\:min-w-6 {
    min-width: 1.5rem !important;
  }

  .md\:min-w-8 {
    min-width: 2rem !important;
  }

  .md\:min-w-10 {
    min-width: 2.5rem !important;
  }

  .md\:min-w-12 {
    min-width: 3rem !important;
  }

  .md\:min-w-14 {
    min-width: 3.5rem !important;
  }

  .md\:min-w-16 {
    min-width: 4rem !important;
  }

  .md\:min-w-18 {
    min-width: 4.5rem !important;
  }

  .md\:min-w-20 {
    min-width: 5rem !important;
  }

  .md\:min-w-22 {
    min-width: 5.5rem !important;
  }

  .md\:min-w-24 {
    min-width: 6rem !important;
  }

  .md\:min-w-26 {
    min-width: 6.5rem !important;
  }

  .md\:min-w-28 {
    min-width: 7rem !important;
  }

  .md\:min-w-30 {
    min-width: 7.5rem !important;
  }

  .md\:min-w-32 {
    min-width: 8rem !important;
  }

  .md\:min-w-36 {
    min-width: 9rem !important;
  }

  .md\:min-w-40 {
    min-width: 10rem !important;
  }

  .md\:min-w-48 {
    min-width: 12rem !important;
  }

  .md\:min-w-50 {
    min-width: 12.5rem !important;
  }

  .md\:min-w-56 {
    min-width: 14rem !important;
  }

  .md\:min-w-60 {
    min-width: 15rem !important;
  }

  .md\:min-w-64 {
    min-width: 16rem !important;
  }

  .md\:min-w-80 {
    min-width: 20rem !important;
  }

  .md\:min-w-90 {
    min-width: 24rem !important;
  }

  .md\:min-w-100 {
    min-width: 25rem !important;
  }

  .md\:min-w-120 {
    min-width: 30rem !important;
  }

  .md\:min-w-128 {
    min-width: 32rem !important;
  }

  .md\:min-w-140 {
    min-width: 35rem !important;
  }

  .md\:min-w-160 {
    min-width: 40rem !important;
  }

  .md\:min-w-180 {
    min-width: 45rem !important;
  }

  .md\:min-w-192 {
    min-width: 48rem !important;
  }

  .md\:min-w-200 {
    min-width: 50rem !important;
  }

  .md\:min-w-240 {
    min-width: 60rem !important;
  }

  .md\:min-w-256 {
    min-width: 64rem !important;
  }

  .md\:min-w-280 {
    min-width: 70rem !important;
  }

  .md\:min-w-320 {
    min-width: 80rem !important;
  }

  .md\:min-w-360 {
    min-width: 90rem !important;
  }

  .md\:min-w-400 {
    min-width: 100rem !important;
  }

  .md\:min-w-480 {
    min-width: 120rem !important;
  }

  .md\:min-w-full {
    min-width: 100% !important;
  }

  .md\:min-w-screen {
    min-width: 100vw !important;
  }

  .md\:min-w-px {
    min-width: 1px !important;
  }

  .md\:min-w-2px {
    min-width: 2px !important;
  }

  .md\:min-w-1\/2 {
    min-width: 50% !important;
  }

  .md\:min-w-1\/3 {
    min-width: 33.33333% !important;
  }

  .md\:min-w-2\/3 {
    min-width: 66.66667% !important;
  }

  .md\:min-w-1\/4 {
    min-width: 25% !important;
  }

  .md\:min-w-2\/4 {
    min-width: 50% !important;
  }

  .md\:min-w-3\/4 {
    min-width: 75% !important;
  }

  .md\:min-w-1\/5 {
    min-width: 20% !important;
  }

  .md\:min-w-2\/5 {
    min-width: 40% !important;
  }

  .md\:min-w-3\/5 {
    min-width: 60% !important;
  }

  .md\:min-w-4\/5 {
    min-width: 80% !important;
  }

  .md\:min-w-1\/12 {
    min-width: 8.33333% !important;
  }

  .md\:min-w-2\/12 {
    min-width: 16.66667% !important;
  }

  .md\:min-w-3\/12 {
    min-width: 25% !important;
  }

  .md\:min-w-4\/12 {
    min-width: 33.33333% !important;
  }

  .md\:min-w-5\/12 {
    min-width: 41.66667% !important;
  }

  .md\:min-w-6\/12 {
    min-width: 50% !important;
  }

  .md\:min-w-7\/12 {
    min-width: 58.33333% !important;
  }

  .md\:min-w-8\/12 {
    min-width: 66.66667% !important;
  }

  .md\:min-w-9\/12 {
    min-width: 75% !important;
  }

  .md\:min-w-10\/12 {
    min-width: 83.33333% !important;
  }

  .md\:min-w-11\/12 {
    min-width: 91.66667% !important;
  }

  .md\:object-contain {
    -o-object-fit: contain !important;
       object-fit: contain !important;
  }

  .md\:object-cover {
    -o-object-fit: cover !important;
       object-fit: cover !important;
  }

  .md\:object-fill {
    -o-object-fit: fill !important;
       object-fit: fill !important;
  }

  .md\:object-none {
    -o-object-fit: none !important;
       object-fit: none !important;
  }

  .md\:object-scale-down {
    -o-object-fit: scale-down !important;
       object-fit: scale-down !important;
  }

  .md\:object-bottom {
    -o-object-position: bottom !important;
       object-position: bottom !important;
  }

  .md\:object-center {
    -o-object-position: center !important;
       object-position: center !important;
  }

  .md\:object-left {
    -o-object-position: left !important;
       object-position: left !important;
  }

  .md\:object-left-bottom {
    -o-object-position: left bottom !important;
       object-position: left bottom !important;
  }

  .md\:object-left-top {
    -o-object-position: left top !important;
       object-position: left top !important;
  }

  .md\:object-right {
    -o-object-position: right !important;
       object-position: right !important;
  }

  .md\:object-right-bottom {
    -o-object-position: right bottom !important;
       object-position: right bottom !important;
  }

  .md\:object-right-top {
    -o-object-position: right top !important;
       object-position: right top !important;
  }

  .md\:object-top {
    -o-object-position: top !important;
       object-position: top !important;
  }

  .md\:opacity-0 {
    opacity: 0 !important;
  }

  .md\:opacity-12 {
    opacity: 0.12 !important;
  }

  .md\:opacity-25 {
    opacity: 0.25 !important;
  }

  .md\:opacity-38 {
    opacity: 0.38 !important;
  }

  .md\:opacity-50 {
    opacity: 0.5 !important;
  }

  .md\:opacity-54 {
    opacity: 0.54 !important;
  }

  .md\:opacity-70 {
    opacity: 0.70 !important;
  }

  .md\:opacity-75 {
    opacity: 0.75 !important;
  }

  .md\:opacity-84 {
    opacity: 0.84 !important;
  }

  .md\:opacity-100 {
    opacity: 1 !important;
  }

  .md\:hover\:opacity-0:hover {
    opacity: 0 !important;
  }

  .md\:hover\:opacity-12:hover {
    opacity: 0.12 !important;
  }

  .md\:hover\:opacity-25:hover {
    opacity: 0.25 !important;
  }

  .md\:hover\:opacity-38:hover {
    opacity: 0.38 !important;
  }

  .md\:hover\:opacity-50:hover {
    opacity: 0.5 !important;
  }

  .md\:hover\:opacity-54:hover {
    opacity: 0.54 !important;
  }

  .md\:hover\:opacity-70:hover {
    opacity: 0.70 !important;
  }

  .md\:hover\:opacity-75:hover {
    opacity: 0.75 !important;
  }

  .md\:hover\:opacity-84:hover {
    opacity: 0.84 !important;
  }

  .md\:hover\:opacity-100:hover {
    opacity: 1 !important;
  }

  .md\:focus\:opacity-0:focus {
    opacity: 0 !important;
  }

  .md\:focus\:opacity-12:focus {
    opacity: 0.12 !important;
  }

  .md\:focus\:opacity-25:focus {
    opacity: 0.25 !important;
  }

  .md\:focus\:opacity-38:focus {
    opacity: 0.38 !important;
  }

  .md\:focus\:opacity-50:focus {
    opacity: 0.5 !important;
  }

  .md\:focus\:opacity-54:focus {
    opacity: 0.54 !important;
  }

  .md\:focus\:opacity-70:focus {
    opacity: 0.70 !important;
  }

  .md\:focus\:opacity-75:focus {
    opacity: 0.75 !important;
  }

  .md\:focus\:opacity-84:focus {
    opacity: 0.84 !important;
  }

  .md\:focus\:opacity-100:focus {
    opacity: 1 !important;
  }

  .md\:outline-none {
    outline: 0 !important;
  }

  .md\:focus\:outline-none:focus {
    outline: 0 !important;
  }

  .md\:overflow-auto {
    overflow: auto !important;
  }

  .md\:overflow-hidden {
    overflow: hidden !important;
  }

  .md\:overflow-visible {
    overflow: visible !important;
  }

  .md\:overflow-scroll {
    overflow: scroll !important;
  }

  .md\:overflow-x-auto {
    overflow-x: auto !important;
  }

  .md\:overflow-y-auto {
    overflow-y: auto !important;
  }

  .md\:overflow-x-hidden {
    overflow-x: hidden !important;
  }

  .md\:overflow-y-hidden {
    overflow-y: hidden !important;
  }

  .md\:overflow-x-visible {
    overflow-x: visible !important;
  }

  .md\:overflow-y-visible {
    overflow-y: visible !important;
  }

  .md\:overflow-x-scroll {
    overflow-x: scroll !important;
  }

  .md\:overflow-y-scroll {
    overflow-y: scroll !important;
  }

  .md\:scrolling-touch {
    -webkit-overflow-scrolling: touch !important;
  }

  .md\:scrolling-auto {
    -webkit-overflow-scrolling: auto !important;
  }

  .md\:p-0 {
    padding: 0 !important;
  }

  .md\:p-1 {
    padding: 0.25rem !important;
  }

  .md\:p-2 {
    padding: 0.5rem !important;
  }

  .md\:p-3 {
    padding: 0.75rem !important;
  }

  .md\:p-4 {
    padding: 1rem !important;
  }

  .md\:p-5 {
    padding: 1.25rem !important;
  }

  .md\:p-6 {
    padding: 1.5rem !important;
  }

  .md\:p-8 {
    padding: 2rem !important;
  }

  .md\:p-10 {
    padding: 2.5rem !important;
  }

  .md\:p-12 {
    padding: 3rem !important;
  }

  .md\:p-14 {
    padding: 3.5rem !important;
  }

  .md\:p-16 {
    padding: 4rem !important;
  }

  .md\:p-18 {
    padding: 4.5rem !important;
  }

  .md\:p-20 {
    padding: 5rem !important;
  }

  .md\:p-22 {
    padding: 5.5rem !important;
  }

  .md\:p-24 {
    padding: 6rem !important;
  }

  .md\:p-26 {
    padding: 6.5rem !important;
  }

  .md\:p-28 {
    padding: 7rem !important;
  }

  .md\:p-30 {
    padding: 7.5rem !important;
  }

  .md\:p-32 {
    padding: 8rem !important;
  }

  .md\:p-36 {
    padding: 9rem !important;
  }

  .md\:p-40 {
    padding: 10rem !important;
  }

  .md\:p-48 {
    padding: 12rem !important;
  }

  .md\:p-56 {
    padding: 14rem !important;
  }

  .md\:p-64 {
    padding: 16rem !important;
  }

  .md\:p-px {
    padding: 1px !important;
  }

  .md\:p-2px {
    padding: 2px !important;
  }

  .md\:py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .md\:px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .md\:py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .md\:px-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }

  .md\:py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .md\:px-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .md\:py-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .md\:px-3 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }

  .md\:py-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .md\:px-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .md\:py-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .md\:px-5 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }

  .md\:py-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .md\:px-6 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .md\:py-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .md\:px-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .md\:py-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .md\:px-10 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .md\:py-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .md\:px-12 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .md\:py-14 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .md\:px-14 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }

  .md\:py-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .md\:px-16 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .md\:py-18 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .md\:px-18 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }

  .md\:py-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .md\:px-20 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .md\:py-22 {
    padding-top: 5.5rem !important;
    padding-bottom: 5.5rem !important;
  }

  .md\:px-22 {
    padding-left: 5.5rem !important;
    padding-right: 5.5rem !important;
  }

  .md\:py-24 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .md\:px-24 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }

  .md\:py-26 {
    padding-top: 6.5rem !important;
    padding-bottom: 6.5rem !important;
  }

  .md\:px-26 {
    padding-left: 6.5rem !important;
    padding-right: 6.5rem !important;
  }

  .md\:py-28 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }

  .md\:px-28 {
    padding-left: 7rem !important;
    padding-right: 7rem !important;
  }

  .md\:py-30 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }

  .md\:px-30 {
    padding-left: 7.5rem !important;
    padding-right: 7.5rem !important;
  }

  .md\:py-32 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .md\:px-32 {
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }

  .md\:py-36 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }

  .md\:px-36 {
    padding-left: 9rem !important;
    padding-right: 9rem !important;
  }

  .md\:py-40 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .md\:px-40 {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }

  .md\:py-48 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }

  .md\:px-48 {
    padding-left: 12rem !important;
    padding-right: 12rem !important;
  }

  .md\:py-56 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }

  .md\:px-56 {
    padding-left: 14rem !important;
    padding-right: 14rem !important;
  }

  .md\:py-64 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }

  .md\:px-64 {
    padding-left: 16rem !important;
    padding-right: 16rem !important;
  }

  .md\:py-px {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }

  .md\:px-px {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }

  .md\:py-2px {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }

  .md\:px-2px {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }

  .md\:pt-0 {
    padding-top: 0 !important;
  }

  .md\:pr-0 {
    padding-right: 0 !important;
  }

  .md\:pb-0 {
    padding-bottom: 0 !important;
  }

  .md\:pl-0 {
    padding-left: 0 !important;
  }

  .md\:pt-1 {
    padding-top: 0.25rem !important;
  }

  .md\:pr-1 {
    padding-right: 0.25rem !important;
  }

  .md\:pb-1 {
    padding-bottom: 0.25rem !important;
  }

  .md\:pl-1 {
    padding-left: 0.25rem !important;
  }

  .md\:pt-2 {
    padding-top: 0.5rem !important;
  }

  .md\:pr-2 {
    padding-right: 0.5rem !important;
  }

  .md\:pb-2 {
    padding-bottom: 0.5rem !important;
  }

  .md\:pl-2 {
    padding-left: 0.5rem !important;
  }

  .md\:pt-3 {
    padding-top: 0.75rem !important;
  }

  .md\:pr-3 {
    padding-right: 0.75rem !important;
  }

  .md\:pb-3 {
    padding-bottom: 0.75rem !important;
  }

  .md\:pl-3 {
    padding-left: 0.75rem !important;
  }

  .md\:pt-4 {
    padding-top: 1rem !important;
  }

  .md\:pr-4 {
    padding-right: 1rem !important;
  }

  .md\:pb-4 {
    padding-bottom: 1rem !important;
  }

  .md\:pl-4 {
    padding-left: 1rem !important;
  }

  .md\:pt-5 {
    padding-top: 1.25rem !important;
  }

  .md\:pr-5 {
    padding-right: 1.25rem !important;
  }

  .md\:pb-5 {
    padding-bottom: 1.25rem !important;
  }

  .md\:pl-5 {
    padding-left: 1.25rem !important;
  }

  .md\:pt-6 {
    padding-top: 1.5rem !important;
  }

  .md\:pr-6 {
    padding-right: 1.5rem !important;
  }

  .md\:pb-6 {
    padding-bottom: 1.5rem !important;
  }

  .md\:pl-6 {
    padding-left: 1.5rem !important;
  }

  .md\:pt-8 {
    padding-top: 2rem !important;
  }

  .md\:pr-8 {
    padding-right: 2rem !important;
  }

  .md\:pb-8 {
    padding-bottom: 2rem !important;
  }

  .md\:pl-8 {
    padding-left: 2rem !important;
  }

  .md\:pt-10 {
    padding-top: 2.5rem !important;
  }

  .md\:pr-10 {
    padding-right: 2.5rem !important;
  }

  .md\:pb-10 {
    padding-bottom: 2.5rem !important;
  }

  .md\:pl-10 {
    padding-left: 2.5rem !important;
  }

  .md\:pt-12 {
    padding-top: 3rem !important;
  }

  .md\:pr-12 {
    padding-right: 3rem !important;
  }

  .md\:pb-12 {
    padding-bottom: 3rem !important;
  }

  .md\:pl-12 {
    padding-left: 3rem !important;
  }

  .md\:pt-14 {
    padding-top: 3.5rem !important;
  }

  .md\:pr-14 {
    padding-right: 3.5rem !important;
  }

  .md\:pb-14 {
    padding-bottom: 3.5rem !important;
  }

  .md\:pl-14 {
    padding-left: 3.5rem !important;
  }

  .md\:pt-16 {
    padding-top: 4rem !important;
  }

  .md\:pr-16 {
    padding-right: 4rem !important;
  }

  .md\:pb-16 {
    padding-bottom: 4rem !important;
  }

  .md\:pl-16 {
    padding-left: 4rem !important;
  }

  .md\:pt-18 {
    padding-top: 4.5rem !important;
  }

  .md\:pr-18 {
    padding-right: 4.5rem !important;
  }

  .md\:pb-18 {
    padding-bottom: 4.5rem !important;
  }

  .md\:pl-18 {
    padding-left: 4.5rem !important;
  }

  .md\:pt-20 {
    padding-top: 5rem !important;
  }

  .md\:pr-20 {
    padding-right: 5rem !important;
  }

  .md\:pb-20 {
    padding-bottom: 5rem !important;
  }

  .md\:pl-20 {
    padding-left: 5rem !important;
  }

  .md\:pt-22 {
    padding-top: 5.5rem !important;
  }

  .md\:pr-22 {
    padding-right: 5.5rem !important;
  }

  .md\:pb-22 {
    padding-bottom: 5.5rem !important;
  }

  .md\:pl-22 {
    padding-left: 5.5rem !important;
  }

  .md\:pt-24 {
    padding-top: 6rem !important;
  }

  .md\:pr-24 {
    padding-right: 6rem !important;
  }

  .md\:pb-24 {
    padding-bottom: 6rem !important;
  }

  .md\:pl-24 {
    padding-left: 6rem !important;
  }

  .md\:pt-26 {
    padding-top: 6.5rem !important;
  }

  .md\:pr-26 {
    padding-right: 6.5rem !important;
  }

  .md\:pb-26 {
    padding-bottom: 6.5rem !important;
  }

  .md\:pl-26 {
    padding-left: 6.5rem !important;
  }

  .md\:pt-28 {
    padding-top: 7rem !important;
  }

  .md\:pr-28 {
    padding-right: 7rem !important;
  }

  .md\:pb-28 {
    padding-bottom: 7rem !important;
  }

  .md\:pl-28 {
    padding-left: 7rem !important;
  }

  .md\:pt-30 {
    padding-top: 7.5rem !important;
  }

  .md\:pr-30 {
    padding-right: 7.5rem !important;
  }

  .md\:pb-30 {
    padding-bottom: 7.5rem !important;
  }

  .md\:pl-30 {
    padding-left: 7.5rem !important;
  }

  .md\:pt-32 {
    padding-top: 8rem !important;
  }

  .md\:pr-32 {
    padding-right: 8rem !important;
  }

  .md\:pb-32 {
    padding-bottom: 8rem !important;
  }

  .md\:pl-32 {
    padding-left: 8rem !important;
  }

  .md\:pt-36 {
    padding-top: 9rem !important;
  }

  .md\:pr-36 {
    padding-right: 9rem !important;
  }

  .md\:pb-36 {
    padding-bottom: 9rem !important;
  }

  .md\:pl-36 {
    padding-left: 9rem !important;
  }

  .md\:pt-40 {
    padding-top: 10rem !important;
  }

  .md\:pr-40 {
    padding-right: 10rem !important;
  }

  .md\:pb-40 {
    padding-bottom: 10rem !important;
  }

  .md\:pl-40 {
    padding-left: 10rem !important;
  }

  .md\:pt-48 {
    padding-top: 12rem !important;
  }

  .md\:pr-48 {
    padding-right: 12rem !important;
  }

  .md\:pb-48 {
    padding-bottom: 12rem !important;
  }

  .md\:pl-48 {
    padding-left: 12rem !important;
  }

  .md\:pt-56 {
    padding-top: 14rem !important;
  }

  .md\:pr-56 {
    padding-right: 14rem !important;
  }

  .md\:pb-56 {
    padding-bottom: 14rem !important;
  }

  .md\:pl-56 {
    padding-left: 14rem !important;
  }

  .md\:pt-64 {
    padding-top: 16rem !important;
  }

  .md\:pr-64 {
    padding-right: 16rem !important;
  }

  .md\:pb-64 {
    padding-bottom: 16rem !important;
  }

  .md\:pl-64 {
    padding-left: 16rem !important;
  }

  .md\:pt-px {
    padding-top: 1px !important;
  }

  .md\:pr-px {
    padding-right: 1px !important;
  }

  .md\:pb-px {
    padding-bottom: 1px !important;
  }

  .md\:pl-px {
    padding-left: 1px !important;
  }

  .md\:pt-2px {
    padding-top: 2px !important;
  }

  .md\:pr-2px {
    padding-right: 2px !important;
  }

  .md\:pb-2px {
    padding-bottom: 2px !important;
  }

  .md\:pl-2px {
    padding-left: 2px !important;
  }

  .md\:placeholder-opacity-0::-webkit-input-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .md\:placeholder-opacity-0::-moz-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .md\:placeholder-opacity-0::-ms-input-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .md\:placeholder-opacity-0::placeholder {
    --placeholder-opacity: 0 !important;
  }

  .md\:placeholder-opacity-12::-webkit-input-placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .md\:placeholder-opacity-12::-moz-placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .md\:placeholder-opacity-12::-ms-input-placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .md\:placeholder-opacity-12::placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .md\:placeholder-opacity-25::-webkit-input-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .md\:placeholder-opacity-25::-moz-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .md\:placeholder-opacity-25::-ms-input-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .md\:placeholder-opacity-25::placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .md\:placeholder-opacity-38::-webkit-input-placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .md\:placeholder-opacity-38::-moz-placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .md\:placeholder-opacity-38::-ms-input-placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .md\:placeholder-opacity-38::placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .md\:placeholder-opacity-50::-webkit-input-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .md\:placeholder-opacity-50::-moz-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .md\:placeholder-opacity-50::-ms-input-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .md\:placeholder-opacity-50::placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .md\:placeholder-opacity-54::-webkit-input-placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .md\:placeholder-opacity-54::-moz-placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .md\:placeholder-opacity-54::-ms-input-placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .md\:placeholder-opacity-54::placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .md\:placeholder-opacity-70::-webkit-input-placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .md\:placeholder-opacity-70::-moz-placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .md\:placeholder-opacity-70::-ms-input-placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .md\:placeholder-opacity-70::placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .md\:placeholder-opacity-75::-webkit-input-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .md\:placeholder-opacity-75::-moz-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .md\:placeholder-opacity-75::-ms-input-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .md\:placeholder-opacity-75::placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .md\:placeholder-opacity-84::-webkit-input-placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .md\:placeholder-opacity-84::-moz-placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .md\:placeholder-opacity-84::-ms-input-placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .md\:placeholder-opacity-84::placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .md\:placeholder-opacity-100::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .md\:placeholder-opacity-100::-moz-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .md\:placeholder-opacity-100::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .md\:placeholder-opacity-100::placeholder {
    --placeholder-opacity: 1 !important;
  }

  .md\:focus\:placeholder-opacity-0:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .md\:focus\:placeholder-opacity-0:focus::-moz-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .md\:focus\:placeholder-opacity-0:focus::-ms-input-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .md\:focus\:placeholder-opacity-0:focus::placeholder {
    --placeholder-opacity: 0 !important;
  }

  .md\:focus\:placeholder-opacity-12:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .md\:focus\:placeholder-opacity-12:focus::-moz-placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .md\:focus\:placeholder-opacity-12:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .md\:focus\:placeholder-opacity-12:focus::placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .md\:focus\:placeholder-opacity-25:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .md\:focus\:placeholder-opacity-25:focus::-moz-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .md\:focus\:placeholder-opacity-25:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .md\:focus\:placeholder-opacity-25:focus::placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .md\:focus\:placeholder-opacity-38:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .md\:focus\:placeholder-opacity-38:focus::-moz-placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .md\:focus\:placeholder-opacity-38:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .md\:focus\:placeholder-opacity-38:focus::placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .md\:focus\:placeholder-opacity-50:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .md\:focus\:placeholder-opacity-50:focus::-moz-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .md\:focus\:placeholder-opacity-50:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .md\:focus\:placeholder-opacity-50:focus::placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .md\:focus\:placeholder-opacity-54:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .md\:focus\:placeholder-opacity-54:focus::-moz-placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .md\:focus\:placeholder-opacity-54:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .md\:focus\:placeholder-opacity-54:focus::placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .md\:focus\:placeholder-opacity-70:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .md\:focus\:placeholder-opacity-70:focus::-moz-placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .md\:focus\:placeholder-opacity-70:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .md\:focus\:placeholder-opacity-70:focus::placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .md\:focus\:placeholder-opacity-75:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .md\:focus\:placeholder-opacity-75:focus::-moz-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .md\:focus\:placeholder-opacity-75:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .md\:focus\:placeholder-opacity-75:focus::placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .md\:focus\:placeholder-opacity-84:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .md\:focus\:placeholder-opacity-84:focus::-moz-placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .md\:focus\:placeholder-opacity-84:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .md\:focus\:placeholder-opacity-84:focus::placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .md\:focus\:placeholder-opacity-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .md\:focus\:placeholder-opacity-100:focus::-moz-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .md\:focus\:placeholder-opacity-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .md\:focus\:placeholder-opacity-100:focus::placeholder {
    --placeholder-opacity: 1 !important;
  }

  .md\:pointer-events-none {
    pointer-events: none !important;
  }

  .md\:pointer-events-auto {
    pointer-events: auto !important;
  }

  .md\:static {
    position: static !important;
  }

  .md\:fixed {
    position: fixed !important;
  }

  .md\:absolute {
    position: absolute !important;
  }

  .md\:relative {
    position: relative !important;
  }

  .md\:sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }

  .md\:inset-0 {
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
  }

  .md\:inset-auto {
    top: auto !important;
    right: auto !important;
    bottom: auto !important;
    left: auto !important;
  }

  .md\:inset-y-0 {
    top: 0 !important;
    bottom: 0 !important;
  }

  .md\:inset-x-0 {
    right: 0 !important;
    left: 0 !important;
  }

  .md\:inset-y-auto {
    top: auto !important;
    bottom: auto !important;
  }

  .md\:inset-x-auto {
    right: auto !important;
    left: auto !important;
  }

  .md\:top-0 {
    top: 0 !important;
  }

  .md\:right-0 {
    right: 0 !important;
  }

  .md\:bottom-0 {
    bottom: 0 !important;
  }

  .md\:left-0 {
    left: 0 !important;
  }

  .md\:top-auto {
    top: auto !important;
  }

  .md\:right-auto {
    right: auto !important;
  }

  .md\:bottom-auto {
    bottom: auto !important;
  }

  .md\:left-auto {
    left: auto !important;
  }

  .md\:shadow-xs {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05) !important;
  }

  .md\:shadow-sm {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  }

  .md\:shadow {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .md\:shadow-md {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  }

  .md\:shadow-lg {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  }

  .md\:shadow-xl {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
  }

  .md\:shadow-2xl {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
  }

  .md\:shadow-inner {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .md\:ring {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }

  .md\:shadow-none {
    box-shadow: none !important;
  }

  .md\:shadow-solid {
    box-shadow: 0 0 0 2px currentColor !important;
  }

  .md\:hover\:shadow-xs:hover {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05) !important;
  }

  .md\:hover\:shadow-sm:hover {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  }

  .md\:hover\:shadow:hover {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .md\:hover\:shadow-md:hover {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  }

  .md\:hover\:shadow-lg:hover {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  }

  .md\:hover\:shadow-xl:hover {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
  }

  .md\:hover\:shadow-2xl:hover {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
  }

  .md\:hover\:shadow-inner:hover {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .md\:hover\:ring:hover {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }

  .md\:hover\:shadow-none:hover {
    box-shadow: none !important;
  }

  .md\:hover\:shadow-solid:hover {
    box-shadow: 0 0 0 2px currentColor !important;
  }

  .md\:focus\:shadow-xs:focus {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05) !important;
  }

  .md\:focus\:shadow-sm:focus {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  }

  .md\:focus\:shadow:focus {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .md\:focus\:shadow-md:focus {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  }

  .md\:focus\:shadow-lg:focus {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  }

  .md\:focus\:shadow-xl:focus {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
  }

  .md\:focus\:shadow-2xl:focus {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
  }

  .md\:focus\:shadow-inner:focus {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .md\:focus\:ring:focus {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }

  .md\:focus\:shadow-none:focus {
    box-shadow: none !important;
  }

  .md\:focus\:shadow-solid:focus {
    box-shadow: 0 0 0 2px currentColor !important;
  }

  .md\:fill-current {
    fill: currentColor !important;
  }

  .md\:stroke-current {
    stroke: currentColor !important;
  }

  .md\:stroke-0 {
    stroke-width: 0 !important;
  }

  .md\:stroke-1 {
    stroke-width: 1 !important;
  }

  .md\:stroke-2 {
    stroke-width: 2 !important;
  }

  .md\:table-auto {
    table-layout: auto !important;
  }

  .md\:table-fixed {
    table-layout: fixed !important;
  }

  .md\:text-left {
    text-align: left !important;
  }

  .md\:text-center {
    text-align: center !important;
  }

  .md\:text-right {
    text-align: right !important;
  }

  .md\:text-justify {
    text-align: justify !important;
  }

  .md\:text-opacity-0 {
    --text-opacity: 0 !important;
  }

  .md\:text-opacity-12 {
    --text-opacity: 0.12 !important;
  }

  .md\:text-opacity-25 {
    --text-opacity: 0.25 !important;
  }

  .md\:text-opacity-38 {
    --text-opacity: 0.38 !important;
  }

  .md\:text-opacity-50 {
    --text-opacity: 0.5 !important;
  }

  .md\:text-opacity-54 {
    --text-opacity: 0.54 !important;
  }

  .md\:text-opacity-70 {
    --text-opacity: 0.70 !important;
  }

  .md\:text-opacity-75 {
    --text-opacity: 0.75 !important;
  }

  .md\:text-opacity-84 {
    --text-opacity: 0.84 !important;
  }

  .md\:text-opacity-100 {
    --text-opacity: 1 !important;
  }

  .md\:hover\:text-opacity-0:hover {
    --text-opacity: 0 !important;
  }

  .md\:hover\:text-opacity-12:hover {
    --text-opacity: 0.12 !important;
  }

  .md\:hover\:text-opacity-25:hover {
    --text-opacity: 0.25 !important;
  }

  .md\:hover\:text-opacity-38:hover {
    --text-opacity: 0.38 !important;
  }

  .md\:hover\:text-opacity-50:hover {
    --text-opacity: 0.5 !important;
  }

  .md\:hover\:text-opacity-54:hover {
    --text-opacity: 0.54 !important;
  }

  .md\:hover\:text-opacity-70:hover {
    --text-opacity: 0.70 !important;
  }

  .md\:hover\:text-opacity-75:hover {
    --text-opacity: 0.75 !important;
  }

  .md\:hover\:text-opacity-84:hover {
    --text-opacity: 0.84 !important;
  }

  .md\:hover\:text-opacity-100:hover {
    --text-opacity: 1 !important;
  }

  .md\:focus\:text-opacity-0:focus {
    --text-opacity: 0 !important;
  }

  .md\:focus\:text-opacity-12:focus {
    --text-opacity: 0.12 !important;
  }

  .md\:focus\:text-opacity-25:focus {
    --text-opacity: 0.25 !important;
  }

  .md\:focus\:text-opacity-38:focus {
    --text-opacity: 0.38 !important;
  }

  .md\:focus\:text-opacity-50:focus {
    --text-opacity: 0.5 !important;
  }

  .md\:focus\:text-opacity-54:focus {
    --text-opacity: 0.54 !important;
  }

  .md\:focus\:text-opacity-70:focus {
    --text-opacity: 0.70 !important;
  }

  .md\:focus\:text-opacity-75:focus {
    --text-opacity: 0.75 !important;
  }

  .md\:focus\:text-opacity-84:focus {
    --text-opacity: 0.84 !important;
  }

  .md\:focus\:text-opacity-100:focus {
    --text-opacity: 1 !important;
  }

  .md\:italic {
    font-style: italic !important;
  }

  .md\:not-italic {
    font-style: normal !important;
  }

  .md\:uppercase {
    text-transform: uppercase !important;
  }

  .md\:lowercase {
    text-transform: lowercase !important;
  }

  .md\:capitalize {
    text-transform: capitalize !important;
  }

  .md\:normal-case {
    text-transform: none !important;
  }

  .md\:underline {
    text-decoration: underline !important;
  }

  .md\:line-through {
    text-decoration: line-through !important;
  }

  .md\:no-underline {
    text-decoration: none !important;
  }

  .md\:hover\:underline:hover {
    text-decoration: underline !important;
  }

  .md\:hover\:line-through:hover {
    text-decoration: line-through !important;
  }

  .md\:hover\:no-underline:hover {
    text-decoration: none !important;
  }

  .md\:focus\:underline:focus {
    text-decoration: underline !important;
  }

  .md\:focus\:line-through:focus {
    text-decoration: line-through !important;
  }

  .md\:focus\:no-underline:focus {
    text-decoration: none !important;
  }

  .md\:tracking-tighter {
    letter-spacing: -0.05em !important;
  }

  .md\:tracking-tight {
    letter-spacing: -0.025em !important;
  }

  .md\:tracking-normal {
    letter-spacing: 0 !important;
  }

  .md\:tracking-wide {
    letter-spacing: 0.025em !important;
  }

  .md\:tracking-wider {
    letter-spacing: 0.05em !important;
  }

  .md\:tracking-widest {
    letter-spacing: 0.1em !important;
  }

  .md\:select-none {
    -webkit-user-select: none !important;
       -moz-user-select: none !important;
        -ms-user-select: none !important;
            user-select: none !important;
  }

  .md\:select-text {
    -webkit-user-select: text !important;
       -moz-user-select: text !important;
        -ms-user-select: text !important;
            user-select: text !important;
  }

  .md\:select-all {
    -webkit-user-select: all !important;
       -moz-user-select: all !important;
        -ms-user-select: all !important;
            user-select: all !important;
  }

  .md\:select-auto {
    -webkit-user-select: auto !important;
       -moz-user-select: auto !important;
        -ms-user-select: auto !important;
            user-select: auto !important;
  }

  .md\:align-baseline {
    vertical-align: baseline !important;
  }

  .md\:align-top {
    vertical-align: top !important;
  }

  .md\:align-middle {
    vertical-align: middle !important;
  }

  .md\:align-bottom {
    vertical-align: bottom !important;
  }

  .md\:align-text-top {
    vertical-align: text-top !important;
  }

  .md\:align-text-bottom {
    vertical-align: text-bottom !important;
  }

  .md\:visible {
    visibility: visible !important;
  }

  .md\:invisible {
    visibility: hidden !important;
  }

  .md\:whitespace-normal {
    white-space: normal !important;
  }

  .md\:whitespace-nowrap {
    white-space: nowrap !important;
  }

  .md\:whitespace-pre {
    white-space: pre !important;
  }

  .md\:whitespace-pre-line {
    white-space: pre-line !important;
  }

  .md\:whitespace-pre-wrap {
    white-space: pre-wrap !important;
  }

  .md\:break-normal {
    overflow-wrap: normal !important;
    word-break: normal !important;
  }

  .md\:break-words {
    overflow-wrap: break-word !important;
  }

  .md\:break-all {
    word-break: break-all !important;
  }

  .md\:truncate {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
  }

  .md\:w-0 {
    width: 0 !important;
  }

  .md\:w-1 {
    width: 0.25rem !important;
  }

  .md\:w-2 {
    width: 0.5rem !important;
  }

  .md\:w-3 {
    width: 0.75rem !important;
  }

  .md\:w-4 {
    width: 1rem !important;
  }

  .md\:w-5 {
    width: 1.25rem !important;
  }

  .md\:w-6 {
    width: 1.5rem !important;
  }

  .md\:w-8 {
    width: 2rem !important;
  }

  .md\:w-10 {
    width: 2.5rem !important;
  }

  .md\:w-12 {
    width: 3rem !important;
  }

  .md\:w-14 {
    width: 3.5rem !important;
  }

  .md\:w-16 {
    width: 4rem !important;
  }

  .md\:w-18 {
    width: 4.5rem !important;
  }

  .md\:w-20 {
    width: 5rem !important;
  }

  .md\:w-22 {
    width: 5.5rem !important;
  }

  .md\:w-24 {
    width: 6rem !important;
  }

  .md\:w-26 {
    width: 6.5rem !important;
  }

  .md\:w-28 {
    width: 7rem !important;
  }

  .md\:w-30 {
    width: 7.5rem !important;
  }

  .md\:w-32 {
    width: 8rem !important;
  }

  .md\:w-36 {
    width: 9rem !important;
  }

  .md\:w-40 {
    width: 10rem !important;
  }

  .md\:w-48 {
    width: 12rem !important;
  }

  .md\:w-50 {
    width: 12.5rem !important;
  }

  .md\:w-56 {
    width: 14rem !important;
  }

  .md\:w-60 {
    width: 15rem !important;
  }

  .md\:w-64 {
    width: 16rem !important;
  }

  .md\:w-80 {
    width: 20rem !important;
  }

  .md\:w-90 {
    width: 24rem !important;
  }

  .md\:w-100 {
    width: 25rem !important;
  }

  .md\:w-120 {
    width: 30rem !important;
  }

  .md\:w-128 {
    width: 32rem !important;
  }

  .md\:w-140 {
    width: 35rem !important;
  }

  .md\:w-160 {
    width: 40rem !important;
  }

  .md\:w-180 {
    width: 45rem !important;
  }

  .md\:w-192 {
    width: 48rem !important;
  }

  .md\:w-200 {
    width: 50rem !important;
  }

  .md\:w-240 {
    width: 60rem !important;
  }

  .md\:w-256 {
    width: 64rem !important;
  }

  .md\:w-280 {
    width: 70rem !important;
  }

  .md\:w-320 {
    width: 80rem !important;
  }

  .md\:w-360 {
    width: 90rem !important;
  }

  .md\:w-400 {
    width: 100rem !important;
  }

  .md\:w-480 {
    width: 120rem !important;
  }

  .md\:w-auto {
    width: auto !important;
  }

  .md\:w-px {
    width: 1px !important;
  }

  .md\:w-2px {
    width: 2px !important;
  }

  .md\:w-1\/2 {
    width: 50% !important;
  }

  .md\:w-1\/3 {
    width: 33.33333% !important;
  }

  .md\:w-2\/3 {
    width: 66.66667% !important;
  }

  .md\:w-1\/4 {
    width: 25% !important;
  }

  .md\:w-2\/4 {
    width: 50% !important;
  }

  .md\:w-3\/4 {
    width: 75% !important;
  }

  .md\:w-1\/5 {
    width: 20% !important;
  }

  .md\:w-2\/5 {
    width: 40% !important;
  }

  .md\:w-3\/5 {
    width: 60% !important;
  }

  .md\:w-4\/5 {
    width: 80% !important;
  }

  .md\:w-1\/6 {
    width: 16.666667% !important;
  }

  .md\:w-2\/6 {
    width: 33.333333% !important;
  }

  .md\:w-3\/6 {
    width: 50% !important;
  }

  .md\:w-4\/6 {
    width: 66.666667% !important;
  }

  .md\:w-5\/6 {
    width: 83.333333% !important;
  }

  .md\:w-1\/12 {
    width: 8.33333% !important;
  }

  .md\:w-2\/12 {
    width: 16.66667% !important;
  }

  .md\:w-3\/12 {
    width: 25% !important;
  }

  .md\:w-4\/12 {
    width: 33.33333% !important;
  }

  .md\:w-5\/12 {
    width: 41.66667% !important;
  }

  .md\:w-6\/12 {
    width: 50% !important;
  }

  .md\:w-7\/12 {
    width: 58.33333% !important;
  }

  .md\:w-8\/12 {
    width: 66.66667% !important;
  }

  .md\:w-9\/12 {
    width: 75% !important;
  }

  .md\:w-10\/12 {
    width: 83.33333% !important;
  }

  .md\:w-11\/12 {
    width: 91.66667% !important;
  }

  .md\:w-full {
    width: 100% !important;
  }

  .md\:w-screen {
    width: 100vw !important;
  }

  .md\:z-0 {
    z-index: 0 !important;
  }

  .md\:z-10 {
    z-index: 10 !important;
  }

  .md\:z-20 {
    z-index: 20 !important;
  }

  .md\:z-30 {
    z-index: 30 !important;
  }

  .md\:z-40 {
    z-index: 40 !important;
  }

  .md\:z-50 {
    z-index: 50 !important;
  }

  .md\:z-60 {
    z-index: 60 !important;
  }

  .md\:z-70 {
    z-index: 70 !important;
  }

  .md\:z-80 {
    z-index: 80 !important;
  }

  .md\:z-90 {
    z-index: 90 !important;
  }

  .md\:z-99 {
    z-index: 99 !important;
  }

  .md\:z-999 {
    z-index: 999 !important;
  }

  .md\:z-9999 {
    z-index: 9999 !important;
  }

  .md\:z-99999 {
    z-index: 99999 !important;
  }

  .md\:z-auto {
    z-index: auto !important;
  }

  .md\:-z-1 {
    z-index: -1 !important;
  }

  .md\:gap-0 {
    grid-gap: 0 !important;
    gap: 0 !important;
  }

  .md\:gap-1 {
    grid-gap: 0.25rem !important;
    gap: 0.25rem !important;
  }

  .md\:gap-2 {
    grid-gap: 0.5rem !important;
    gap: 0.5rem !important;
  }

  .md\:gap-3 {
    grid-gap: 0.75rem !important;
    gap: 0.75rem !important;
  }

  .md\:gap-4 {
    grid-gap: 1rem !important;
    gap: 1rem !important;
  }

  .md\:gap-5 {
    grid-gap: 1.25rem !important;
    gap: 1.25rem !important;
  }

  .md\:gap-6 {
    grid-gap: 1.5rem !important;
    gap: 1.5rem !important;
  }

  .md\:gap-8 {
    grid-gap: 2rem !important;
    gap: 2rem !important;
  }

  .md\:gap-10 {
    grid-gap: 2.5rem !important;
    gap: 2.5rem !important;
  }

  .md\:gap-12 {
    grid-gap: 3rem !important;
    gap: 3rem !important;
  }

  .md\:gap-14 {
    grid-gap: 3.5rem !important;
    gap: 3.5rem !important;
  }

  .md\:gap-16 {
    grid-gap: 4rem !important;
    gap: 4rem !important;
  }

  .md\:gap-18 {
    grid-gap: 4.5rem !important;
    gap: 4.5rem !important;
  }

  .md\:gap-20 {
    grid-gap: 5rem !important;
    gap: 5rem !important;
  }

  .md\:gap-22 {
    grid-gap: 5.5rem !important;
    gap: 5.5rem !important;
  }

  .md\:gap-24 {
    grid-gap: 6rem !important;
    gap: 6rem !important;
  }

  .md\:gap-26 {
    grid-gap: 6.5rem !important;
    gap: 6.5rem !important;
  }

  .md\:gap-28 {
    grid-gap: 7rem !important;
    gap: 7rem !important;
  }

  .md\:gap-30 {
    grid-gap: 7.5rem !important;
    gap: 7.5rem !important;
  }

  .md\:gap-32 {
    grid-gap: 8rem !important;
    gap: 8rem !important;
  }

  .md\:gap-36 {
    grid-gap: 9rem !important;
    gap: 9rem !important;
  }

  .md\:gap-40 {
    grid-gap: 10rem !important;
    gap: 10rem !important;
  }

  .md\:gap-48 {
    grid-gap: 12rem !important;
    gap: 12rem !important;
  }

  .md\:gap-56 {
    grid-gap: 14rem !important;
    gap: 14rem !important;
  }

  .md\:gap-64 {
    grid-gap: 16rem !important;
    gap: 16rem !important;
  }

  .md\:gap-px {
    grid-gap: 1px !important;
    gap: 1px !important;
  }

  .md\:gap-2px {
    grid-gap: 2px !important;
    gap: 2px !important;
  }

  .md\:gap-x-0 {
    grid-column-gap: 0 !important;
    -moz-column-gap: 0 !important;
         column-gap: 0 !important;
  }

  .md\:gap-x-1 {
    grid-column-gap: 0.25rem !important;
    -moz-column-gap: 0.25rem !important;
         column-gap: 0.25rem !important;
  }

  .md\:gap-x-2 {
    grid-column-gap: 0.5rem !important;
    -moz-column-gap: 0.5rem !important;
         column-gap: 0.5rem !important;
  }

  .md\:gap-x-3 {
    grid-column-gap: 0.75rem !important;
    -moz-column-gap: 0.75rem !important;
         column-gap: 0.75rem !important;
  }

  .md\:gap-x-4 {
    grid-column-gap: 1rem !important;
    -moz-column-gap: 1rem !important;
         column-gap: 1rem !important;
  }

  .md\:gap-x-5 {
    grid-column-gap: 1.25rem !important;
    -moz-column-gap: 1.25rem !important;
         column-gap: 1.25rem !important;
  }

  .md\:gap-x-6 {
    grid-column-gap: 1.5rem !important;
    -moz-column-gap: 1.5rem !important;
         column-gap: 1.5rem !important;
  }

  .md\:gap-x-8 {
    grid-column-gap: 2rem !important;
    -moz-column-gap: 2rem !important;
         column-gap: 2rem !important;
  }

  .md\:gap-x-10 {
    grid-column-gap: 2.5rem !important;
    -moz-column-gap: 2.5rem !important;
         column-gap: 2.5rem !important;
  }

  .md\:gap-x-12 {
    grid-column-gap: 3rem !important;
    -moz-column-gap: 3rem !important;
         column-gap: 3rem !important;
  }

  .md\:gap-x-14 {
    grid-column-gap: 3.5rem !important;
    -moz-column-gap: 3.5rem !important;
         column-gap: 3.5rem !important;
  }

  .md\:gap-x-16 {
    grid-column-gap: 4rem !important;
    -moz-column-gap: 4rem !important;
         column-gap: 4rem !important;
  }

  .md\:gap-x-18 {
    grid-column-gap: 4.5rem !important;
    -moz-column-gap: 4.5rem !important;
         column-gap: 4.5rem !important;
  }

  .md\:gap-x-20 {
    grid-column-gap: 5rem !important;
    -moz-column-gap: 5rem !important;
         column-gap: 5rem !important;
  }

  .md\:gap-x-22 {
    grid-column-gap: 5.5rem !important;
    -moz-column-gap: 5.5rem !important;
         column-gap: 5.5rem !important;
  }

  .md\:gap-x-24 {
    grid-column-gap: 6rem !important;
    -moz-column-gap: 6rem !important;
         column-gap: 6rem !important;
  }

  .md\:gap-x-26 {
    grid-column-gap: 6.5rem !important;
    -moz-column-gap: 6.5rem !important;
         column-gap: 6.5rem !important;
  }

  .md\:gap-x-28 {
    grid-column-gap: 7rem !important;
    -moz-column-gap: 7rem !important;
         column-gap: 7rem !important;
  }

  .md\:gap-x-30 {
    grid-column-gap: 7.5rem !important;
    -moz-column-gap: 7.5rem !important;
         column-gap: 7.5rem !important;
  }

  .md\:gap-x-32 {
    grid-column-gap: 8rem !important;
    -moz-column-gap: 8rem !important;
         column-gap: 8rem !important;
  }

  .md\:gap-x-36 {
    grid-column-gap: 9rem !important;
    -moz-column-gap: 9rem !important;
         column-gap: 9rem !important;
  }

  .md\:gap-x-40 {
    grid-column-gap: 10rem !important;
    -moz-column-gap: 10rem !important;
         column-gap: 10rem !important;
  }

  .md\:gap-x-48 {
    grid-column-gap: 12rem !important;
    -moz-column-gap: 12rem !important;
         column-gap: 12rem !important;
  }

  .md\:gap-x-56 {
    grid-column-gap: 14rem !important;
    -moz-column-gap: 14rem !important;
         column-gap: 14rem !important;
  }

  .md\:gap-x-64 {
    grid-column-gap: 16rem !important;
    -moz-column-gap: 16rem !important;
         column-gap: 16rem !important;
  }

  .md\:gap-x-px {
    grid-column-gap: 1px !important;
    -moz-column-gap: 1px !important;
         column-gap: 1px !important;
  }

  .md\:gap-x-2px {
    grid-column-gap: 2px !important;
    -moz-column-gap: 2px !important;
         column-gap: 2px !important;
  }

  .md\:gap-y-0 {
    grid-row-gap: 0 !important;
    row-gap: 0 !important;
  }

  .md\:gap-y-1 {
    grid-row-gap: 0.25rem !important;
    row-gap: 0.25rem !important;
  }

  .md\:gap-y-2 {
    grid-row-gap: 0.5rem !important;
    row-gap: 0.5rem !important;
  }

  .md\:gap-y-3 {
    grid-row-gap: 0.75rem !important;
    row-gap: 0.75rem !important;
  }

  .md\:gap-y-4 {
    grid-row-gap: 1rem !important;
    row-gap: 1rem !important;
  }

  .md\:gap-y-5 {
    grid-row-gap: 1.25rem !important;
    row-gap: 1.25rem !important;
  }

  .md\:gap-y-6 {
    grid-row-gap: 1.5rem !important;
    row-gap: 1.5rem !important;
  }

  .md\:gap-y-8 {
    grid-row-gap: 2rem !important;
    row-gap: 2rem !important;
  }

  .md\:gap-y-10 {
    grid-row-gap: 2.5rem !important;
    row-gap: 2.5rem !important;
  }

  .md\:gap-y-12 {
    grid-row-gap: 3rem !important;
    row-gap: 3rem !important;
  }

  .md\:gap-y-14 {
    grid-row-gap: 3.5rem !important;
    row-gap: 3.5rem !important;
  }

  .md\:gap-y-16 {
    grid-row-gap: 4rem !important;
    row-gap: 4rem !important;
  }

  .md\:gap-y-18 {
    grid-row-gap: 4.5rem !important;
    row-gap: 4.5rem !important;
  }

  .md\:gap-y-20 {
    grid-row-gap: 5rem !important;
    row-gap: 5rem !important;
  }

  .md\:gap-y-22 {
    grid-row-gap: 5.5rem !important;
    row-gap: 5.5rem !important;
  }

  .md\:gap-y-24 {
    grid-row-gap: 6rem !important;
    row-gap: 6rem !important;
  }

  .md\:gap-y-26 {
    grid-row-gap: 6.5rem !important;
    row-gap: 6.5rem !important;
  }

  .md\:gap-y-28 {
    grid-row-gap: 7rem !important;
    row-gap: 7rem !important;
  }

  .md\:gap-y-30 {
    grid-row-gap: 7.5rem !important;
    row-gap: 7.5rem !important;
  }

  .md\:gap-y-32 {
    grid-row-gap: 8rem !important;
    row-gap: 8rem !important;
  }

  .md\:gap-y-36 {
    grid-row-gap: 9rem !important;
    row-gap: 9rem !important;
  }

  .md\:gap-y-40 {
    grid-row-gap: 10rem !important;
    row-gap: 10rem !important;
  }

  .md\:gap-y-48 {
    grid-row-gap: 12rem !important;
    row-gap: 12rem !important;
  }

  .md\:gap-y-56 {
    grid-row-gap: 14rem !important;
    row-gap: 14rem !important;
  }

  .md\:gap-y-64 {
    grid-row-gap: 16rem !important;
    row-gap: 16rem !important;
  }

  .md\:gap-y-px {
    grid-row-gap: 1px !important;
    row-gap: 1px !important;
  }

  .md\:gap-y-2px {
    grid-row-gap: 2px !important;
    row-gap: 2px !important;
  }

  .md\:grid-flow-row {
    grid-auto-flow: row !important;
  }

  .md\:grid-flow-col {
    grid-auto-flow: column !important;
  }

  .md\:grid-flow-row-dense {
    grid-auto-flow: row dense !important;
  }

  .md\:grid-flow-col-dense {
    grid-auto-flow: column dense !important;
  }

  .md\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
  }

  .md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
  }

  .md\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr)) !important;
  }

  .md\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr)) !important;
  }

  .md\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr)) !important;
  }

  .md\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr)) !important;
  }

  .md\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr)) !important;
  }

  .md\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr)) !important;
  }

  .md\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr)) !important;
  }

  .md\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr)) !important;
  }

  .md\:grid-cols-none {
    grid-template-columns: none !important;
  }

  .md\:col-auto {
    grid-column: auto !important;
  }

  .md\:col-span-1 {
    grid-column: span 1 / span 1 !important;
  }

  .md\:col-span-2 {
    grid-column: span 2 / span 2 !important;
  }

  .md\:col-span-3 {
    grid-column: span 3 / span 3 !important;
  }

  .md\:col-span-4 {
    grid-column: span 4 / span 4 !important;
  }

  .md\:col-span-5 {
    grid-column: span 5 / span 5 !important;
  }

  .md\:col-span-6 {
    grid-column: span 6 / span 6 !important;
  }

  .md\:col-span-7 {
    grid-column: span 7 / span 7 !important;
  }

  .md\:col-span-8 {
    grid-column: span 8 / span 8 !important;
  }

  .md\:col-span-9 {
    grid-column: span 9 / span 9 !important;
  }

  .md\:col-span-10 {
    grid-column: span 10 / span 10 !important;
  }

  .md\:col-span-11 {
    grid-column: span 11 / span 11 !important;
  }

  .md\:col-span-12 {
    grid-column: span 12 / span 12 !important;
  }

  .md\:col-start-1 {
    grid-column-start: 1 !important;
  }

  .md\:col-start-2 {
    grid-column-start: 2 !important;
  }

  .md\:col-start-3 {
    grid-column-start: 3 !important;
  }

  .md\:col-start-4 {
    grid-column-start: 4 !important;
  }

  .md\:col-start-5 {
    grid-column-start: 5 !important;
  }

  .md\:col-start-6 {
    grid-column-start: 6 !important;
  }

  .md\:col-start-7 {
    grid-column-start: 7 !important;
  }

  .md\:col-start-8 {
    grid-column-start: 8 !important;
  }

  .md\:col-start-9 {
    grid-column-start: 9 !important;
  }

  .md\:col-start-10 {
    grid-column-start: 10 !important;
  }

  .md\:col-start-11 {
    grid-column-start: 11 !important;
  }

  .md\:col-start-12 {
    grid-column-start: 12 !important;
  }

  .md\:col-start-13 {
    grid-column-start: 13 !important;
  }

  .md\:col-start-auto {
    grid-column-start: auto !important;
  }

  .md\:col-end-1 {
    grid-column-end: 1 !important;
  }

  .md\:col-end-2 {
    grid-column-end: 2 !important;
  }

  .md\:col-end-3 {
    grid-column-end: 3 !important;
  }

  .md\:col-end-4 {
    grid-column-end: 4 !important;
  }

  .md\:col-end-5 {
    grid-column-end: 5 !important;
  }

  .md\:col-end-6 {
    grid-column-end: 6 !important;
  }

  .md\:col-end-7 {
    grid-column-end: 7 !important;
  }

  .md\:col-end-8 {
    grid-column-end: 8 !important;
  }

  .md\:col-end-9 {
    grid-column-end: 9 !important;
  }

  .md\:col-end-10 {
    grid-column-end: 10 !important;
  }

  .md\:col-end-11 {
    grid-column-end: 11 !important;
  }

  .md\:col-end-12 {
    grid-column-end: 12 !important;
  }

  .md\:col-end-13 {
    grid-column-end: 13 !important;
  }

  .md\:col-end-auto {
    grid-column-end: auto !important;
  }

  .md\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr)) !important;
  }

  .md\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr)) !important;
  }

  .md\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr)) !important;
  }

  .md\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr)) !important;
  }

  .md\:grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr)) !important;
  }

  .md\:grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr)) !important;
  }

  .md\:grid-rows-none {
    grid-template-rows: none !important;
  }

  .md\:row-auto {
    grid-row: auto !important;
  }

  .md\:row-span-1 {
    grid-row: span 1 / span 1 !important;
  }

  .md\:row-span-2 {
    grid-row: span 2 / span 2 !important;
  }

  .md\:row-span-3 {
    grid-row: span 3 / span 3 !important;
  }

  .md\:row-span-4 {
    grid-row: span 4 / span 4 !important;
  }

  .md\:row-span-5 {
    grid-row: span 5 / span 5 !important;
  }

  .md\:row-span-6 {
    grid-row: span 6 / span 6 !important;
  }

  .md\:row-start-1 {
    grid-row-start: 1 !important;
  }

  .md\:row-start-2 {
    grid-row-start: 2 !important;
  }

  .md\:row-start-3 {
    grid-row-start: 3 !important;
  }

  .md\:row-start-4 {
    grid-row-start: 4 !important;
  }

  .md\:row-start-5 {
    grid-row-start: 5 !important;
  }

  .md\:row-start-6 {
    grid-row-start: 6 !important;
  }

  .md\:row-start-7 {
    grid-row-start: 7 !important;
  }

  .md\:row-start-auto {
    grid-row-start: auto !important;
  }

  .md\:row-end-1 {
    grid-row-end: 1 !important;
  }

  .md\:row-end-2 {
    grid-row-end: 2 !important;
  }

  .md\:row-end-3 {
    grid-row-end: 3 !important;
  }

  .md\:row-end-4 {
    grid-row-end: 4 !important;
  }

  .md\:row-end-5 {
    grid-row-end: 5 !important;
  }

  .md\:row-end-6 {
    grid-row-end: 6 !important;
  }

  .md\:row-end-7 {
    grid-row-end: 7 !important;
  }

  .md\:row-end-auto {
    grid-row-end: auto !important;
  }

  .md\:transform {
    --transform-translate-x: 0 !important;
    --transform-translate-y: 0 !important;
    --transform-rotate: 0 !important;
    --transform-skew-x: 0 !important;
    --transform-skew-y: 0 !important;
    --transform-scale-x: 1 !important;
    --transform-scale-y: 1 !important;
    transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y)) !important;
  }

  .md\:transform-none {
    transform: none !important;
  }

  .md\:origin-center {
    transform-origin: center !important;
  }

  .md\:origin-top {
    transform-origin: top !important;
  }

  .md\:origin-top-right {
    transform-origin: top right !important;
  }

  .md\:origin-right {
    transform-origin: right !important;
  }

  .md\:origin-bottom-right {
    transform-origin: bottom right !important;
  }

  .md\:origin-bottom {
    transform-origin: bottom !important;
  }

  .md\:origin-bottom-left {
    transform-origin: bottom left !important;
  }

  .md\:origin-left {
    transform-origin: left !important;
  }

  .md\:origin-top-left {
    transform-origin: top left !important;
  }

  .md\:icon-size-12 {
    width: 12px !important;
    height: 12px !important;
    min-width: 12px !important;
    min-height: 12px !important;
    font-size: 12px !important;
    line-height: 12px !important;
  }

  .md\:icon-size-12 svg {
    width: 12px !important;
    height: 12px !important;
  }

  .md\:icon-size-14 {
    width: 14px !important;
    height: 14px !important;
    min-width: 14px !important;
    min-height: 14px !important;
    font-size: 14px !important;
    line-height: 14px !important;
  }

  .md\:icon-size-14 svg {
    width: 14px !important;
    height: 14px !important;
  }

  .md\:icon-size-16 {
    width: 16px !important;
    height: 16px !important;
    min-width: 16px !important;
    min-height: 16px !important;
    font-size: 16px !important;
    line-height: 16px !important;
  }

  .md\:icon-size-16 svg {
    width: 16px !important;
    height: 16px !important;
  }

  .md\:icon-size-18 {
    width: 18px !important;
    height: 18px !important;
    min-width: 18px !important;
    min-height: 18px !important;
    font-size: 18px !important;
    line-height: 18px !important;
  }

  .md\:icon-size-18 svg {
    width: 18px !important;
    height: 18px !important;
  }

  .md\:icon-size-20 {
    width: 20px !important;
    height: 20px !important;
    min-width: 20px !important;
    min-height: 20px !important;
    font-size: 20px !important;
    line-height: 20px !important;
  }

  .md\:icon-size-20 svg {
    width: 20px !important;
    height: 20px !important;
  }

  .md\:icon-size-24 {
    width: 24px !important;
    height: 24px !important;
    min-width: 24px !important;
    min-height: 24px !important;
    font-size: 24px !important;
    line-height: 24px !important;
  }

  .md\:icon-size-24 svg {
    width: 24px !important;
    height: 24px !important;
  }

  .md\:icon-size-32 {
    width: 32px !important;
    height: 32px !important;
    min-width: 32px !important;
    min-height: 32px !important;
    font-size: 32px !important;
    line-height: 32px !important;
  }

  .md\:icon-size-32 svg {
    width: 32px !important;
    height: 32px !important;
  }

  .md\:icon-size-40 {
    width: 40px !important;
    height: 40px !important;
    min-width: 40px !important;
    min-height: 40px !important;
    font-size: 40px !important;
    line-height: 40px !important;
  }

  .md\:icon-size-40 svg {
    width: 40px !important;
    height: 40px !important;
  }

  .md\:icon-size-48 {
    width: 48px !important;
    height: 48px !important;
    min-width: 48px !important;
    min-height: 48px !important;
    font-size: 48px !important;
    line-height: 48px !important;
  }

  .md\:icon-size-48 svg {
    width: 48px !important;
    height: 48px !important;
  }

  .md\:icon-size-56 {
    width: 56px !important;
    height: 56px !important;
    min-width: 56px !important;
    min-height: 56px !important;
    font-size: 56px !important;
    line-height: 56px !important;
  }

  .md\:icon-size-56 svg {
    width: 56px !important;
    height: 56px !important;
  }

  .md\:icon-size-64 {
    width: 64px !important;
    height: 64px !important;
    min-width: 64px !important;
    min-height: 64px !important;
    font-size: 64px !important;
    line-height: 64px !important;
  }

  .md\:icon-size-64 svg {
    width: 64px !important;
    height: 64px !important;
  }

  .md\:icon-size-72 {
    width: 72px !important;
    height: 72px !important;
    min-width: 72px !important;
    min-height: 72px !important;
    font-size: 72px !important;
    line-height: 72px !important;
  }

  .md\:icon-size-72 svg {
    width: 72px !important;
    height: 72px !important;
  }

  .md\:icon-size-80 {
    width: 80px !important;
    height: 80px !important;
    min-width: 80px !important;
    min-height: 80px !important;
    font-size: 80px !important;
    line-height: 80px !important;
  }

  .md\:icon-size-80 svg {
    width: 80px !important;
    height: 80px !important;
  }

  .md\:icon-size-88 {
    width: 88px !important;
    height: 88px !important;
    min-width: 88px !important;
    min-height: 88px !important;
    font-size: 88px !important;
    line-height: 88px !important;
  }

  .md\:icon-size-88 svg {
    width: 88px !important;
    height: 88px !important;
  }

  .md\:icon-size-96 {
    width: 96px !important;
    height: 96px !important;
    min-width: 96px !important;
    min-height: 96px !important;
    font-size: 96px !important;
    line-height: 96px !important;
  }

  .md\:icon-size-96 svg {
    width: 96px !important;
    height: 96px !important;
  }
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .lg\:space-y-0 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(0px * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(0px * var(--space-y-reverse)) !important;
  }

  .lg\:space-x-0 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(0px * var(--space-x-reverse)) !important;
    margin-left: calc(0px * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(0.25rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(0.25rem * var(--space-y-reverse)) !important;
  }

  .lg\:space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(0.25rem * var(--space-x-reverse)) !important;
    margin-left: calc(0.25rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(0.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(0.5rem * var(--space-y-reverse)) !important;
  }

  .lg\:space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(0.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(0.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(0.75rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(0.75rem * var(--space-y-reverse)) !important;
  }

  .lg\:space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(0.75rem * var(--space-x-reverse)) !important;
    margin-left: calc(0.75rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(1rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(1rem * var(--space-y-reverse)) !important;
  }

  .lg\:space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(1rem * var(--space-x-reverse)) !important;
    margin-left: calc(1rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(1.25rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(1.25rem * var(--space-y-reverse)) !important;
  }

  .lg\:space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(1.25rem * var(--space-x-reverse)) !important;
    margin-left: calc(1.25rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(1.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(1.5rem * var(--space-y-reverse)) !important;
  }

  .lg\:space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(1.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(1.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:space-y-8 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(2rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(2rem * var(--space-y-reverse)) !important;
  }

  .lg\:space-x-8 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(2rem * var(--space-x-reverse)) !important;
    margin-left: calc(2rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:space-y-10 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(2.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(2.5rem * var(--space-y-reverse)) !important;
  }

  .lg\:space-x-10 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(2.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(2.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:space-y-12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(3rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(3rem * var(--space-y-reverse)) !important;
  }

  .lg\:space-x-12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(3rem * var(--space-x-reverse)) !important;
    margin-left: calc(3rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:space-y-14 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(3.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(3.5rem * var(--space-y-reverse)) !important;
  }

  .lg\:space-x-14 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(3.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(3.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:space-y-16 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(4rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(4rem * var(--space-y-reverse)) !important;
  }

  .lg\:space-x-16 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(4rem * var(--space-x-reverse)) !important;
    margin-left: calc(4rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:space-y-18 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(4.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(4.5rem * var(--space-y-reverse)) !important;
  }

  .lg\:space-x-18 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(4.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(4.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:space-y-20 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(5rem * var(--space-y-reverse)) !important;
  }

  .lg\:space-x-20 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(5rem * var(--space-x-reverse)) !important;
    margin-left: calc(5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:space-y-22 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(5.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(5.5rem * var(--space-y-reverse)) !important;
  }

  .lg\:space-x-22 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(5.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(5.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:space-y-24 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(6rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(6rem * var(--space-y-reverse)) !important;
  }

  .lg\:space-x-24 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(6rem * var(--space-x-reverse)) !important;
    margin-left: calc(6rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:space-y-26 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(6.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(6.5rem * var(--space-y-reverse)) !important;
  }

  .lg\:space-x-26 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(6.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(6.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:space-y-28 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(7rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(7rem * var(--space-y-reverse)) !important;
  }

  .lg\:space-x-28 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(7rem * var(--space-x-reverse)) !important;
    margin-left: calc(7rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:space-y-30 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(7.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(7.5rem * var(--space-y-reverse)) !important;
  }

  .lg\:space-x-30 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(7.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(7.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:space-y-32 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(8rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(8rem * var(--space-y-reverse)) !important;
  }

  .lg\:space-x-32 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(8rem * var(--space-x-reverse)) !important;
    margin-left: calc(8rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:space-y-36 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(9rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(9rem * var(--space-y-reverse)) !important;
  }

  .lg\:space-x-36 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(9rem * var(--space-x-reverse)) !important;
    margin-left: calc(9rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:space-y-40 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(10rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(10rem * var(--space-y-reverse)) !important;
  }

  .lg\:space-x-40 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(10rem * var(--space-x-reverse)) !important;
    margin-left: calc(10rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:space-y-48 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(12rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(12rem * var(--space-y-reverse)) !important;
  }

  .lg\:space-x-48 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(12rem * var(--space-x-reverse)) !important;
    margin-left: calc(12rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:space-y-56 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(14rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(14rem * var(--space-y-reverse)) !important;
  }

  .lg\:space-x-56 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(14rem * var(--space-x-reverse)) !important;
    margin-left: calc(14rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:space-y-64 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(16rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(16rem * var(--space-y-reverse)) !important;
  }

  .lg\:space-x-64 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(16rem * var(--space-x-reverse)) !important;
    margin-left: calc(16rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:space-y-px > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(1px * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(1px * var(--space-y-reverse)) !important;
  }

  .lg\:space-x-px > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(1px * var(--space-x-reverse)) !important;
    margin-left: calc(1px * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:space-y-2px > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(2px * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(2px * var(--space-y-reverse)) !important;
  }

  .lg\:space-x-2px > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(2px * var(--space-x-reverse)) !important;
    margin-left: calc(2px * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:-space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-0.25rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-0.25rem * var(--space-y-reverse)) !important;
  }

  .lg\:-space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-0.25rem * var(--space-x-reverse)) !important;
    margin-left: calc(-0.25rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:-space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-0.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-0.5rem * var(--space-y-reverse)) !important;
  }

  .lg\:-space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-0.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-0.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:-space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-0.75rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-0.75rem * var(--space-y-reverse)) !important;
  }

  .lg\:-space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-0.75rem * var(--space-x-reverse)) !important;
    margin-left: calc(-0.75rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:-space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-1rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-1rem * var(--space-y-reverse)) !important;
  }

  .lg\:-space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-1rem * var(--space-x-reverse)) !important;
    margin-left: calc(-1rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:-space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-1.25rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-1.25rem * var(--space-y-reverse)) !important;
  }

  .lg\:-space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-1.25rem * var(--space-x-reverse)) !important;
    margin-left: calc(-1.25rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:-space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-1.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-1.5rem * var(--space-y-reverse)) !important;
  }

  .lg\:-space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-1.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-1.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:-space-y-8 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-2rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-2rem * var(--space-y-reverse)) !important;
  }

  .lg\:-space-x-8 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-2rem * var(--space-x-reverse)) !important;
    margin-left: calc(-2rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:-space-y-10 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-2.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-2.5rem * var(--space-y-reverse)) !important;
  }

  .lg\:-space-x-10 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-2.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-2.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:-space-y-12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-3rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-3rem * var(--space-y-reverse)) !important;
  }

  .lg\:-space-x-12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-3rem * var(--space-x-reverse)) !important;
    margin-left: calc(-3rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:-space-y-14 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-3.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-3.5rem * var(--space-y-reverse)) !important;
  }

  .lg\:-space-x-14 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-3.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-3.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:-space-y-16 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-4rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-4rem * var(--space-y-reverse)) !important;
  }

  .lg\:-space-x-16 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-4rem * var(--space-x-reverse)) !important;
    margin-left: calc(-4rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:-space-y-18 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-4.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-4.5rem * var(--space-y-reverse)) !important;
  }

  .lg\:-space-x-18 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-4.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-4.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:-space-y-20 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-5rem * var(--space-y-reverse)) !important;
  }

  .lg\:-space-x-20 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:-space-y-22 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-5.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-5.5rem * var(--space-y-reverse)) !important;
  }

  .lg\:-space-x-22 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-5.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-5.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:-space-y-24 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-6rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-6rem * var(--space-y-reverse)) !important;
  }

  .lg\:-space-x-24 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-6rem * var(--space-x-reverse)) !important;
    margin-left: calc(-6rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:-space-y-26 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-6.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-6.5rem * var(--space-y-reverse)) !important;
  }

  .lg\:-space-x-26 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-6.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-6.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:-space-y-28 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-7rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-7rem * var(--space-y-reverse)) !important;
  }

  .lg\:-space-x-28 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-7rem * var(--space-x-reverse)) !important;
    margin-left: calc(-7rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:-space-y-30 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-7.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-7.5rem * var(--space-y-reverse)) !important;
  }

  .lg\:-space-x-30 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-7.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-7.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:-space-y-32 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-8rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-8rem * var(--space-y-reverse)) !important;
  }

  .lg\:-space-x-32 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-8rem * var(--space-x-reverse)) !important;
    margin-left: calc(-8rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:-space-y-36 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-9rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-9rem * var(--space-y-reverse)) !important;
  }

  .lg\:-space-x-36 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-9rem * var(--space-x-reverse)) !important;
    margin-left: calc(-9rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:-space-y-40 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-10rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-10rem * var(--space-y-reverse)) !important;
  }

  .lg\:-space-x-40 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-10rem * var(--space-x-reverse)) !important;
    margin-left: calc(-10rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:-space-y-48 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-12rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-12rem * var(--space-y-reverse)) !important;
  }

  .lg\:-space-x-48 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-12rem * var(--space-x-reverse)) !important;
    margin-left: calc(-12rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:-space-y-56 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-14rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-14rem * var(--space-y-reverse)) !important;
  }

  .lg\:-space-x-56 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-14rem * var(--space-x-reverse)) !important;
    margin-left: calc(-14rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:-space-y-64 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-16rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-16rem * var(--space-y-reverse)) !important;
  }

  .lg\:-space-x-64 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-16rem * var(--space-x-reverse)) !important;
    margin-left: calc(-16rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:-space-y-px > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-1px * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-1px * var(--space-y-reverse)) !important;
  }

  .lg\:-space-x-px > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-1px * var(--space-x-reverse)) !important;
    margin-left: calc(-1px * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:-space-y-2px > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-2px * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-2px * var(--space-y-reverse)) !important;
  }

  .lg\:-space-x-2px > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-2px * var(--space-x-reverse)) !important;
    margin-left: calc(-2px * calc(1 - var(--space-x-reverse))) !important;
  }

  .lg\:space-y-reverse > :not(template) ~ :not(template) {
    --space-y-reverse: 1 !important;
  }

  .lg\:space-x-reverse > :not(template) ~ :not(template) {
    --space-x-reverse: 1 !important;
  }

  .lg\:divide-y-0 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(0px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(0px * var(--divide-y-reverse)) !important;
  }

  .lg\:divide-x-0 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(0px * var(--divide-x-reverse)) !important;
    border-left-width: calc(0px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .lg\:divide-y-2 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(2px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(2px * var(--divide-y-reverse)) !important;
  }

  .lg\:divide-x-2 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(2px * var(--divide-x-reverse)) !important;
    border-left-width: calc(2px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .lg\:divide-y-4 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(4px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(4px * var(--divide-y-reverse)) !important;
  }

  .lg\:divide-x-4 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(4px * var(--divide-x-reverse)) !important;
    border-left-width: calc(4px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .lg\:divide-y-8 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(8px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(8px * var(--divide-y-reverse)) !important;
  }

  .lg\:divide-x-8 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(8px * var(--divide-x-reverse)) !important;
    border-left-width: calc(8px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .lg\:divide-y > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(1px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(1px * var(--divide-y-reverse)) !important;
  }

  .lg\:divide-x > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(1px * var(--divide-x-reverse)) !important;
    border-left-width: calc(1px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .lg\:divide-y-reverse > :not(template) ~ :not(template) {
    --divide-y-reverse: 1 !important;
  }

  .lg\:divide-x-reverse > :not(template) ~ :not(template) {
    --divide-x-reverse: 1 !important;
  }

  .lg\:divide-current > :not(template) ~ :not(template) {
    border-color: currentColor !important;
  }

  .lg\:divide-transparent > :not(template) ~ :not(template) {
    border-color: transparent !important;
  }

  .lg\:divide-white > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FFFFFF !important;
    border-color: rgba(255, 255, 255, var(--divide-opacity)) !important;
  }

  .lg\:divide-black > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #000000 !important;
    border-color: rgba(0, 0, 0, var(--divide-opacity)) !important;
  }

  .lg\:divide-gray-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F9FAFB !important;
    border-color: rgba(249, 250, 251, var(--divide-opacity)) !important;
  }

  .lg\:divide-gray-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F4F5F7 !important;
    border-color: rgba(244, 245, 247, var(--divide-opacity)) !important;
  }

  .lg\:divide-gray-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E5E7EB !important;
    border-color: rgba(229, 231, 235, var(--divide-opacity)) !important;
  }

  .lg\:divide-gray-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #D2D6DC !important;
    border-color: rgba(210, 214, 220, var(--divide-opacity)) !important;
  }

  .lg\:divide-gray-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9FA6B2 !important;
    border-color: rgba(159, 166, 178, var(--divide-opacity)) !important;
  }

  .lg\:divide-gray-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #6B7280 !important;
    border-color: rgba(107, 114, 128, var(--divide-opacity)) !important;
  }

  .lg\:divide-gray-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #4B5563 !important;
    border-color: rgba(75, 85, 99, var(--divide-opacity)) !important;
  }

  .lg\:divide-gray-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #374151 !important;
    border-color: rgba(55, 65, 81, var(--divide-opacity)) !important;
  }

  .lg\:divide-gray-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #252F3F !important;
    border-color: rgba(37, 47, 63, var(--divide-opacity)) !important;
  }

  .lg\:divide-gray-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #161E2E !important;
    border-color: rgba(22, 30, 46, var(--divide-opacity)) !important;
  }

  .lg\:divide-gray > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #6B7280 !important;
    border-color: rgba(107, 114, 128, var(--divide-opacity)) !important;
  }

  .lg\:divide-cool-gray-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FBFDFE !important;
    border-color: rgba(251, 253, 254, var(--divide-opacity)) !important;
  }

  .lg\:divide-cool-gray-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F1F5F9 !important;
    border-color: rgba(241, 245, 249, var(--divide-opacity)) !important;
  }

  .lg\:divide-cool-gray-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E2E8F0 !important;
    border-color: rgba(226, 232, 240, var(--divide-opacity)) !important;
  }

  .lg\:divide-cool-gray-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #CFD8E3 !important;
    border-color: rgba(207, 216, 227, var(--divide-opacity)) !important;
  }

  .lg\:divide-cool-gray-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #97A6BA !important;
    border-color: rgba(151, 166, 186, var(--divide-opacity)) !important;
  }

  .lg\:divide-cool-gray-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #64748B !important;
    border-color: rgba(100, 116, 139, var(--divide-opacity)) !important;
  }

  .lg\:divide-cool-gray-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #475569 !important;
    border-color: rgba(71, 85, 105, var(--divide-opacity)) !important;
  }

  .lg\:divide-cool-gray-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #364152 !important;
    border-color: rgba(54, 65, 82, var(--divide-opacity)) !important;
  }

  .lg\:divide-cool-gray-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #27303F !important;
    border-color: rgba(39, 48, 63, var(--divide-opacity)) !important;
  }

  .lg\:divide-cool-gray-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #1A202E !important;
    border-color: rgba(26, 32, 46, var(--divide-opacity)) !important;
  }

  .lg\:divide-cool-gray > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #64748B !important;
    border-color: rgba(100, 116, 139, var(--divide-opacity)) !important;
  }

  .lg\:divide-red-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FDF2F2 !important;
    border-color: rgba(253, 242, 242, var(--divide-opacity)) !important;
  }

  .lg\:divide-red-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FDE8E8 !important;
    border-color: rgba(253, 232, 232, var(--divide-opacity)) !important;
  }

  .lg\:divide-red-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FBD5D5 !important;
    border-color: rgba(251, 213, 213, var(--divide-opacity)) !important;
  }

  .lg\:divide-red-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F8B4B4 !important;
    border-color: rgba(248, 180, 180, var(--divide-opacity)) !important;
  }

  .lg\:divide-red-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F98080 !important;
    border-color: rgba(249, 128, 128, var(--divide-opacity)) !important;
  }

  .lg\:divide-red-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F05252 !important;
    border-color: rgba(240, 82, 82, var(--divide-opacity)) !important;
  }

  .lg\:divide-red-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E02424 !important;
    border-color: rgba(224, 36, 36, var(--divide-opacity)) !important;
  }

  .lg\:divide-red-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #C81E1E !important;
    border-color: rgba(200, 30, 30, var(--divide-opacity)) !important;
  }

  .lg\:divide-red-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9B1C1C !important;
    border-color: rgba(155, 28, 28, var(--divide-opacity)) !important;
  }

  .lg\:divide-red-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #771D1D !important;
    border-color: rgba(119, 29, 29, var(--divide-opacity)) !important;
  }

  .lg\:divide-red > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F05252 !important;
    border-color: rgba(240, 82, 82, var(--divide-opacity)) !important;
  }

  .lg\:divide-orange-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FFF8F1 !important;
    border-color: rgba(255, 248, 241, var(--divide-opacity)) !important;
  }

  .lg\:divide-orange-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FEECDC !important;
    border-color: rgba(254, 236, 220, var(--divide-opacity)) !important;
  }

  .lg\:divide-orange-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FCD9BD !important;
    border-color: rgba(252, 217, 189, var(--divide-opacity)) !important;
  }

  .lg\:divide-orange-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FDBA8C !important;
    border-color: rgba(253, 186, 140, var(--divide-opacity)) !important;
  }

  .lg\:divide-orange-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FF8A4C !important;
    border-color: rgba(255, 138, 76, var(--divide-opacity)) !important;
  }

  .lg\:divide-orange-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FF5A1F !important;
    border-color: rgba(255, 90, 31, var(--divide-opacity)) !important;
  }

  .lg\:divide-orange-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #ff6700 !important;
    border-color: rgba(255, 103, 0, var(--divide-opacity)) !important;
  }

  .lg\:divide-orange-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #B43403 !important;
    border-color: rgba(180, 52, 3, var(--divide-opacity)) !important;
  }

  .lg\:divide-orange-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #8A2C0D !important;
    border-color: rgba(138, 44, 13, var(--divide-opacity)) !important;
  }

  .lg\:divide-orange-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #771D1D !important;
    border-color: rgba(119, 29, 29, var(--divide-opacity)) !important;
  }

  .lg\:divide-orange > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FF5A1F !important;
    border-color: rgba(255, 90, 31, var(--divide-opacity)) !important;
  }

  .lg\:divide-yellow-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FDFDEA !important;
    border-color: rgba(253, 253, 234, var(--divide-opacity)) !important;
  }

  .lg\:divide-yellow-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FDF6B2 !important;
    border-color: rgba(253, 246, 178, var(--divide-opacity)) !important;
  }

  .lg\:divide-yellow-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FCE96A !important;
    border-color: rgba(252, 233, 106, var(--divide-opacity)) !important;
  }

  .lg\:divide-yellow-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FACA15 !important;
    border-color: rgba(250, 202, 21, var(--divide-opacity)) !important;
  }

  .lg\:divide-yellow-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E3A008 !important;
    border-color: rgba(227, 160, 8, var(--divide-opacity)) !important;
  }

  .lg\:divide-yellow-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #C27803 !important;
    border-color: rgba(194, 120, 3, var(--divide-opacity)) !important;
  }

  .lg\:divide-yellow-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9F580A !important;
    border-color: rgba(159, 88, 10, var(--divide-opacity)) !important;
  }

  .lg\:divide-yellow-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #8E4B10 !important;
    border-color: rgba(142, 75, 16, var(--divide-opacity)) !important;
  }

  .lg\:divide-yellow-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #723B13 !important;
    border-color: rgba(114, 59, 19, var(--divide-opacity)) !important;
  }

  .lg\:divide-yellow-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #633112 !important;
    border-color: rgba(99, 49, 18, var(--divide-opacity)) !important;
  }

  .lg\:divide-yellow > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #C27803 !important;
    border-color: rgba(194, 120, 3, var(--divide-opacity)) !important;
  }

  .lg\:divide-green-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F3FAF7 !important;
    border-color: rgba(243, 250, 247, var(--divide-opacity)) !important;
  }

  .lg\:divide-green-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #DEF7EC !important;
    border-color: rgba(222, 247, 236, var(--divide-opacity)) !important;
  }

  .lg\:divide-green-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #BCF0DA !important;
    border-color: rgba(188, 240, 218, var(--divide-opacity)) !important;
  }

  .lg\:divide-green-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #84E1BC !important;
    border-color: rgba(132, 225, 188, var(--divide-opacity)) !important;
  }

  .lg\:divide-green-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #31C48D !important;
    border-color: rgba(49, 196, 141, var(--divide-opacity)) !important;
  }

  .lg\:divide-green-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #0E9F6E !important;
    border-color: rgba(14, 159, 110, var(--divide-opacity)) !important;
  }

  .lg\:divide-green-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #057A55 !important;
    border-color: rgba(5, 122, 85, var(--divide-opacity)) !important;
  }

  .lg\:divide-green-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #046C4E !important;
    border-color: rgba(4, 108, 78, var(--divide-opacity)) !important;
  }

  .lg\:divide-green-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #03543F !important;
    border-color: rgba(3, 84, 63, var(--divide-opacity)) !important;
  }

  .lg\:divide-green-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #014737 !important;
    border-color: rgba(1, 71, 55, var(--divide-opacity)) !important;
  }

  .lg\:divide-green > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #0E9F6E !important;
    border-color: rgba(14, 159, 110, var(--divide-opacity)) !important;
  }

  .lg\:divide-teal-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #EDFAFA !important;
    border-color: rgba(237, 250, 250, var(--divide-opacity)) !important;
  }

  .lg\:divide-teal-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #D5F5F6 !important;
    border-color: rgba(213, 245, 246, var(--divide-opacity)) !important;
  }

  .lg\:divide-teal-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #AFECEF !important;
    border-color: rgba(175, 236, 239, var(--divide-opacity)) !important;
  }

  .lg\:divide-teal-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #7EDCE2 !important;
    border-color: rgba(126, 220, 226, var(--divide-opacity)) !important;
  }

  .lg\:divide-teal-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #16BDCA !important;
    border-color: rgba(22, 189, 202, var(--divide-opacity)) !important;
  }

  .lg\:divide-teal-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #0694A2 !important;
    border-color: rgba(6, 148, 162, var(--divide-opacity)) !important;
  }

  .lg\:divide-teal-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #047481 !important;
    border-color: rgba(4, 116, 129, var(--divide-opacity)) !important;
  }

  .lg\:divide-teal-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #036672 !important;
    border-color: rgba(3, 102, 114, var(--divide-opacity)) !important;
  }

  .lg\:divide-teal-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #05505C !important;
    border-color: rgba(5, 80, 92, var(--divide-opacity)) !important;
  }

  .lg\:divide-teal-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #014451 !important;
    border-color: rgba(1, 68, 81, var(--divide-opacity)) !important;
  }

  .lg\:divide-teal > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #0694A2 !important;
    border-color: rgba(6, 148, 162, var(--divide-opacity)) !important;
  }

  .lg\:divide-blue-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #EBF5FF !important;
    border-color: rgba(235, 245, 255, var(--divide-opacity)) !important;
  }

  .lg\:divide-blue-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E1EFFE !important;
    border-color: rgba(225, 239, 254, var(--divide-opacity)) !important;
  }

  .lg\:divide-blue-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #C3DDFD !important;
    border-color: rgba(195, 221, 253, var(--divide-opacity)) !important;
  }

  .lg\:divide-blue-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #A4CAFE !important;
    border-color: rgba(164, 202, 254, var(--divide-opacity)) !important;
  }

  .lg\:divide-blue-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #76A9FA !important;
    border-color: rgba(118, 169, 250, var(--divide-opacity)) !important;
  }

  .lg\:divide-blue-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #3F83F8 !important;
    border-color: rgba(63, 131, 248, var(--divide-opacity)) !important;
  }

  .lg\:divide-blue-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #1C64F2 !important;
    border-color: rgba(28, 100, 242, var(--divide-opacity)) !important;
  }

  .lg\:divide-blue-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #1A56DB !important;
    border-color: rgba(26, 86, 219, var(--divide-opacity)) !important;
  }

  .lg\:divide-blue-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #1E429F !important;
    border-color: rgba(30, 66, 159, var(--divide-opacity)) !important;
  }

  .lg\:divide-blue-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #233876 !important;
    border-color: rgba(35, 56, 118, var(--divide-opacity)) !important;
  }

  .lg\:divide-blue > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #3F83F8 !important;
    border-color: rgba(63, 131, 248, var(--divide-opacity)) !important;
  }

  .lg\:divide-indigo-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F0F5FF !important;
    border-color: rgba(240, 245, 255, var(--divide-opacity)) !important;
  }

  .lg\:divide-indigo-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E5EDFF !important;
    border-color: rgba(229, 237, 255, var(--divide-opacity)) !important;
  }

  .lg\:divide-indigo-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #CDDBFE !important;
    border-color: rgba(205, 219, 254, var(--divide-opacity)) !important;
  }

  .lg\:divide-indigo-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #B4C6FC !important;
    border-color: rgba(180, 198, 252, var(--divide-opacity)) !important;
  }

  .lg\:divide-indigo-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #8DA2FB !important;
    border-color: rgba(141, 162, 251, var(--divide-opacity)) !important;
  }

  .lg\:divide-indigo-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #6875F5 !important;
    border-color: rgba(104, 117, 245, var(--divide-opacity)) !important;
  }

  .lg\:divide-indigo-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #5850EC !important;
    border-color: rgba(88, 80, 236, var(--divide-opacity)) !important;
  }

  .lg\:divide-indigo-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #5145CD !important;
    border-color: rgba(81, 69, 205, var(--divide-opacity)) !important;
  }

  .lg\:divide-indigo-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #42389D !important;
    border-color: rgba(66, 56, 157, var(--divide-opacity)) !important;
  }

  .lg\:divide-indigo-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #362F78 !important;
    border-color: rgba(54, 47, 120, var(--divide-opacity)) !important;
  }

  .lg\:divide-indigo > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #6875F5 !important;
    border-color: rgba(104, 117, 245, var(--divide-opacity)) !important;
  }

  .lg\:divide-purple-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F6F5FF !important;
    border-color: rgba(246, 245, 255, var(--divide-opacity)) !important;
  }

  .lg\:divide-purple-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #EDEBFE !important;
    border-color: rgba(237, 235, 254, var(--divide-opacity)) !important;
  }

  .lg\:divide-purple-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #DCD7FE !important;
    border-color: rgba(220, 215, 254, var(--divide-opacity)) !important;
  }

  .lg\:divide-purple-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #CABFFD !important;
    border-color: rgba(202, 191, 253, var(--divide-opacity)) !important;
  }

  .lg\:divide-purple-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #AC94FA !important;
    border-color: rgba(172, 148, 250, var(--divide-opacity)) !important;
  }

  .lg\:divide-purple-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9061F9 !important;
    border-color: rgba(144, 97, 249, var(--divide-opacity)) !important;
  }

  .lg\:divide-purple-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #7E3AF2 !important;
    border-color: rgba(126, 58, 242, var(--divide-opacity)) !important;
  }

  .lg\:divide-purple-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #6C2BD9 !important;
    border-color: rgba(108, 43, 217, var(--divide-opacity)) !important;
  }

  .lg\:divide-purple-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #5521B5 !important;
    border-color: rgba(85, 33, 181, var(--divide-opacity)) !important;
  }

  .lg\:divide-purple-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #4A1D96 !important;
    border-color: rgba(74, 29, 150, var(--divide-opacity)) !important;
  }

  .lg\:divide-purple > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9061F9 !important;
    border-color: rgba(144, 97, 249, var(--divide-opacity)) !important;
  }

  .lg\:divide-pink-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FDF2F8 !important;
    border-color: rgba(253, 242, 248, var(--divide-opacity)) !important;
  }

  .lg\:divide-pink-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FCE8F3 !important;
    border-color: rgba(252, 232, 243, var(--divide-opacity)) !important;
  }

  .lg\:divide-pink-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FAD1E8 !important;
    border-color: rgba(250, 209, 232, var(--divide-opacity)) !important;
  }

  .lg\:divide-pink-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F8B4D9 !important;
    border-color: rgba(248, 180, 217, var(--divide-opacity)) !important;
  }

  .lg\:divide-pink-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F17EB8 !important;
    border-color: rgba(241, 126, 184, var(--divide-opacity)) !important;
  }

  .lg\:divide-pink-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E74694 !important;
    border-color: rgba(231, 70, 148, var(--divide-opacity)) !important;
  }

  .lg\:divide-pink-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #D61F69 !important;
    border-color: rgba(214, 31, 105, var(--divide-opacity)) !important;
  }

  .lg\:divide-pink-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #BF125D !important;
    border-color: rgba(191, 18, 93, var(--divide-opacity)) !important;
  }

  .lg\:divide-pink-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #99154B !important;
    border-color: rgba(153, 21, 75, var(--divide-opacity)) !important;
  }

  .lg\:divide-pink-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #751A3D !important;
    border-color: rgba(117, 26, 61, var(--divide-opacity)) !important;
  }

  .lg\:divide-pink > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E74694 !important;
    border-color: rgba(231, 70, 148, var(--divide-opacity)) !important;
  }

  .lg\:divide-opacity-0 > :not(template) ~ :not(template) {
    --divide-opacity: 0 !important;
  }

  .lg\:divide-opacity-12 > :not(template) ~ :not(template) {
    --divide-opacity: 0.12 !important;
  }

  .lg\:divide-opacity-25 > :not(template) ~ :not(template) {
    --divide-opacity: 0.25 !important;
  }

  .lg\:divide-opacity-38 > :not(template) ~ :not(template) {
    --divide-opacity: 0.38 !important;
  }

  .lg\:divide-opacity-50 > :not(template) ~ :not(template) {
    --divide-opacity: 0.5 !important;
  }

  .lg\:divide-opacity-54 > :not(template) ~ :not(template) {
    --divide-opacity: 0.54 !important;
  }

  .lg\:divide-opacity-70 > :not(template) ~ :not(template) {
    --divide-opacity: 0.70 !important;
  }

  .lg\:divide-opacity-75 > :not(template) ~ :not(template) {
    --divide-opacity: 0.75 !important;
  }

  .lg\:divide-opacity-84 > :not(template) ~ :not(template) {
    --divide-opacity: 0.84 !important;
  }

  .lg\:divide-opacity-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
  }

  .lg\:sr-only {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border-width: 0 !important;
  }

  .lg\:not-sr-only {
    position: static !important;
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: visible !important;
    clip: auto !important;
    white-space: normal !important;
  }

  .lg\:focus\:sr-only:focus {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border-width: 0 !important;
  }

  .lg\:focus\:not-sr-only:focus {
    position: static !important;
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: visible !important;
    clip: auto !important;
    white-space: normal !important;
  }

  .lg\:appearance-none {
    -webkit-appearance: none !important;
       -moz-appearance: none !important;
            appearance: none !important;
  }

  .lg\:bg-fixed {
    background-attachment: fixed !important;
  }

  .lg\:bg-local {
    background-attachment: local !important;
  }

  .lg\:bg-scroll {
    background-attachment: scroll !important;
  }

  .lg\:bg-opacity-0 {
    --bg-opacity: 0 !important;
  }

  .lg\:bg-opacity-12 {
    --bg-opacity: 0.12 !important;
  }

  .lg\:bg-opacity-25 {
    --bg-opacity: 0.25 !important;
  }

  .lg\:bg-opacity-38 {
    --bg-opacity: 0.38 !important;
  }

  .lg\:bg-opacity-50 {
    --bg-opacity: 0.5 !important;
  }

  .lg\:bg-opacity-54 {
    --bg-opacity: 0.54 !important;
  }

  .lg\:bg-opacity-70 {
    --bg-opacity: 0.70 !important;
  }

  .lg\:bg-opacity-75 {
    --bg-opacity: 0.75 !important;
  }

  .lg\:bg-opacity-84 {
    --bg-opacity: 0.84 !important;
  }

  .lg\:bg-opacity-100 {
    --bg-opacity: 1 !important;
  }

  .lg\:hover\:bg-opacity-0:hover {
    --bg-opacity: 0 !important;
  }

  .lg\:hover\:bg-opacity-12:hover {
    --bg-opacity: 0.12 !important;
  }

  .lg\:hover\:bg-opacity-25:hover {
    --bg-opacity: 0.25 !important;
  }

  .lg\:hover\:bg-opacity-38:hover {
    --bg-opacity: 0.38 !important;
  }

  .lg\:hover\:bg-opacity-50:hover {
    --bg-opacity: 0.5 !important;
  }

  .lg\:hover\:bg-opacity-54:hover {
    --bg-opacity: 0.54 !important;
  }

  .lg\:hover\:bg-opacity-70:hover {
    --bg-opacity: 0.70 !important;
  }

  .lg\:hover\:bg-opacity-75:hover {
    --bg-opacity: 0.75 !important;
  }

  .lg\:hover\:bg-opacity-84:hover {
    --bg-opacity: 0.84 !important;
  }

  .lg\:hover\:bg-opacity-100:hover {
    --bg-opacity: 1 !important;
  }

  .lg\:focus\:bg-opacity-0:focus {
    --bg-opacity: 0 !important;
  }

  .lg\:focus\:bg-opacity-12:focus {
    --bg-opacity: 0.12 !important;
  }

  .lg\:focus\:bg-opacity-25:focus {
    --bg-opacity: 0.25 !important;
  }

  .lg\:focus\:bg-opacity-38:focus {
    --bg-opacity: 0.38 !important;
  }

  .lg\:focus\:bg-opacity-50:focus {
    --bg-opacity: 0.5 !important;
  }

  .lg\:focus\:bg-opacity-54:focus {
    --bg-opacity: 0.54 !important;
  }

  .lg\:focus\:bg-opacity-70:focus {
    --bg-opacity: 0.70 !important;
  }

  .lg\:focus\:bg-opacity-75:focus {
    --bg-opacity: 0.75 !important;
  }

  .lg\:focus\:bg-opacity-84:focus {
    --bg-opacity: 0.84 !important;
  }

  .lg\:focus\:bg-opacity-100:focus {
    --bg-opacity: 1 !important;
  }

  .lg\:bg-bottom {
    background-position: bottom !important;
  }

  .lg\:bg-center {
    background-position: center !important;
  }

  .lg\:bg-left {
    background-position: left !important;
  }

  .lg\:bg-left-bottom {
    background-position: left bottom !important;
  }

  .lg\:bg-left-top {
    background-position: left top !important;
  }

  .lg\:bg-right {
    background-position: right !important;
  }

  .lg\:bg-right-bottom {
    background-position: right bottom !important;
  }

  .lg\:bg-right-top {
    background-position: right top !important;
  }

  .lg\:bg-top {
    background-position: top !important;
  }

  .lg\:bg-repeat {
    background-repeat: repeat !important;
  }

  .lg\:bg-no-repeat {
    background-repeat: no-repeat !important;
  }

  .lg\:bg-repeat-x {
    background-repeat: repeat-x !important;
  }

  .lg\:bg-repeat-y {
    background-repeat: repeat-y !important;
  }

  .lg\:bg-repeat-round {
    background-repeat: round !important;
  }

  .lg\:bg-repeat-space {
    background-repeat: space !important;
  }

  .lg\:bg-auto {
    background-size: auto !important;
  }

  .lg\:bg-cover {
    background-size: cover !important;
  }

  .lg\:bg-contain {
    background-size: contain !important;
  }

  .lg\:border-collapse {
    border-collapse: collapse !important;
  }

  .lg\:border-separate {
    border-collapse: separate !important;
  }

  .lg\:border-opacity-0 {
    --border-opacity: 0 !important;
  }

  .lg\:border-opacity-12 {
    --border-opacity: 0.12 !important;
  }

  .lg\:border-opacity-25 {
    --border-opacity: 0.25 !important;
  }

  .lg\:border-opacity-38 {
    --border-opacity: 0.38 !important;
  }

  .lg\:border-opacity-50 {
    --border-opacity: 0.5 !important;
  }

  .lg\:border-opacity-54 {
    --border-opacity: 0.54 !important;
  }

  .lg\:border-opacity-70 {
    --border-opacity: 0.70 !important;
  }

  .lg\:border-opacity-75 {
    --border-opacity: 0.75 !important;
  }

  .lg\:border-opacity-84 {
    --border-opacity: 0.84 !important;
  }

  .lg\:border-opacity-100 {
    --border-opacity: 1 !important;
  }

  .lg\:hover\:border-opacity-0:hover {
    --border-opacity: 0 !important;
  }

  .lg\:hover\:border-opacity-12:hover {
    --border-opacity: 0.12 !important;
  }

  .lg\:hover\:border-opacity-25:hover {
    --border-opacity: 0.25 !important;
  }

  .lg\:hover\:border-opacity-38:hover {
    --border-opacity: 0.38 !important;
  }

  .lg\:hover\:border-opacity-50:hover {
    --border-opacity: 0.5 !important;
  }

  .lg\:hover\:border-opacity-54:hover {
    --border-opacity: 0.54 !important;
  }

  .lg\:hover\:border-opacity-70:hover {
    --border-opacity: 0.70 !important;
  }

  .lg\:hover\:border-opacity-75:hover {
    --border-opacity: 0.75 !important;
  }

  .lg\:hover\:border-opacity-84:hover {
    --border-opacity: 0.84 !important;
  }

  .lg\:hover\:border-opacity-100:hover {
    --border-opacity: 1 !important;
  }

  .lg\:focus\:border-opacity-0:focus {
    --border-opacity: 0 !important;
  }

  .lg\:focus\:border-opacity-12:focus {
    --border-opacity: 0.12 !important;
  }

  .lg\:focus\:border-opacity-25:focus {
    --border-opacity: 0.25 !important;
  }

  .lg\:focus\:border-opacity-38:focus {
    --border-opacity: 0.38 !important;
  }

  .lg\:focus\:border-opacity-50:focus {
    --border-opacity: 0.5 !important;
  }

  .lg\:focus\:border-opacity-54:focus {
    --border-opacity: 0.54 !important;
  }

  .lg\:focus\:border-opacity-70:focus {
    --border-opacity: 0.70 !important;
  }

  .lg\:focus\:border-opacity-75:focus {
    --border-opacity: 0.75 !important;
  }

  .lg\:focus\:border-opacity-84:focus {
    --border-opacity: 0.84 !important;
  }

  .lg\:focus\:border-opacity-100:focus {
    --border-opacity: 1 !important;
  }

  .lg\:rounded-none {
    border-radius: 0 !important;
  }

  .lg\:rounded-sm {
    border-radius: 0.125rem !important;
  }

  .lg\:rounded {
    border-radius: 0.25rem !important;
  }

  .lg\:rounded-md {
    border-radius: 0.375rem !important;
  }

  .lg\:rounded-lg {
    border-radius: 0.5rem !important;
  }

  .lg\:rounded-full {
    border-radius: 9999px !important;
  }

  .lg\:rounded-t-none {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .lg\:rounded-r-none {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .lg\:rounded-b-none {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .lg\:rounded-l-none {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .lg\:rounded-t-sm {
    border-top-left-radius: 0.125rem !important;
    border-top-right-radius: 0.125rem !important;
  }

  .lg\:rounded-r-sm {
    border-top-right-radius: 0.125rem !important;
    border-bottom-right-radius: 0.125rem !important;
  }

  .lg\:rounded-b-sm {
    border-bottom-right-radius: 0.125rem !important;
    border-bottom-left-radius: 0.125rem !important;
  }

  .lg\:rounded-l-sm {
    border-top-left-radius: 0.125rem !important;
    border-bottom-left-radius: 0.125rem !important;
  }

  .lg\:rounded-t {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }

  .lg\:rounded-r {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }

  .lg\:rounded-b {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }

  .lg\:rounded-l {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }

  .lg\:rounded-t-md {
    border-top-left-radius: 0.375rem !important;
    border-top-right-radius: 0.375rem !important;
  }

  .lg\:rounded-r-md {
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }

  .lg\:rounded-b-md {
    border-bottom-right-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }

  .lg\:rounded-l-md {
    border-top-left-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }

  .lg\:rounded-t-lg {
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
  }

  .lg\:rounded-r-lg {
    border-top-right-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }

  .lg\:rounded-b-lg {
    border-bottom-right-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }

  .lg\:rounded-l-lg {
    border-top-left-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }

  .lg\:rounded-t-full {
    border-top-left-radius: 9999px !important;
    border-top-right-radius: 9999px !important;
  }

  .lg\:rounded-r-full {
    border-top-right-radius: 9999px !important;
    border-bottom-right-radius: 9999px !important;
  }

  .lg\:rounded-b-full {
    border-bottom-right-radius: 9999px !important;
    border-bottom-left-radius: 9999px !important;
  }

  .lg\:rounded-l-full {
    border-top-left-radius: 9999px !important;
    border-bottom-left-radius: 9999px !important;
  }

  .lg\:rounded-tl-none {
    border-top-left-radius: 0 !important;
  }

  .lg\:rounded-tr-none {
    border-top-right-radius: 0 !important;
  }

  .lg\:rounded-br-none {
    border-bottom-right-radius: 0 !important;
  }

  .lg\:rounded-bl-none {
    border-bottom-left-radius: 0 !important;
  }

  .lg\:rounded-tl-sm {
    border-top-left-radius: 0.125rem !important;
  }

  .lg\:rounded-tr-sm {
    border-top-right-radius: 0.125rem !important;
  }

  .lg\:rounded-br-sm {
    border-bottom-right-radius: 0.125rem !important;
  }

  .lg\:rounded-bl-sm {
    border-bottom-left-radius: 0.125rem !important;
  }

  .lg\:rounded-tl {
    border-top-left-radius: 0.25rem !important;
  }

  .lg\:rounded-tr {
    border-top-right-radius: 0.25rem !important;
  }

  .lg\:rounded-br {
    border-bottom-right-radius: 0.25rem !important;
  }

  .lg\:rounded-bl {
    border-bottom-left-radius: 0.25rem !important;
  }

  .lg\:rounded-tl-md {
    border-top-left-radius: 0.375rem !important;
  }

  .lg\:rounded-tr-md {
    border-top-right-radius: 0.375rem !important;
  }

  .lg\:rounded-br-md {
    border-bottom-right-radius: 0.375rem !important;
  }

  .lg\:rounded-bl-md {
    border-bottom-left-radius: 0.375rem !important;
  }

  .lg\:rounded-tl-lg {
    border-top-left-radius: 0.5rem !important;
  }

  .lg\:rounded-tr-lg {
    border-top-right-radius: 0.5rem !important;
  }

  .lg\:rounded-br-lg {
    border-bottom-right-radius: 0.5rem !important;
  }

  .lg\:rounded-bl-lg {
    border-bottom-left-radius: 0.5rem !important;
  }

  .lg\:rounded-tl-full {
    border-top-left-radius: 9999px !important;
  }

  .lg\:rounded-tr-full {
    border-top-right-radius: 9999px !important;
  }

  .lg\:rounded-br-full {
    border-bottom-right-radius: 9999px !important;
  }

  .lg\:rounded-bl-full {
    border-bottom-left-radius: 9999px !important;
  }

  .lg\:border-solid {
    border-style: solid !important;
  }

  .lg\:border-dashed {
    border-style: dashed !important;
  }

  .lg\:border-dotted {
    border-style: dotted !important;
  }

  .lg\:border-double {
    border-style: double !important;
  }

  .lg\:border-none {
    border-style: none !important;
  }

  .lg\:border-0 {
    border-width: 0 !important;
  }

  .lg\:border-2 {
    border-width: 2px !important;
  }

  .lg\:border-4 {
    border-width: 4px !important;
  }

  .lg\:border-8 {
    border-width: 8px !important;
  }

  .lg\:border {
    border-width: 1px !important;
  }

  .lg\:border-t-0 {
    border-top-width: 0 !important;
  }

  .lg\:border-r-0 {
    border-right-width: 0 !important;
  }

  .lg\:border-b-0 {
    border-bottom-width: 0 !important;
  }

  .lg\:border-l-0 {
    border-left-width: 0 !important;
  }

  .lg\:border-t-2 {
    border-top-width: 2px !important;
  }

  .lg\:border-r-2 {
    border-right-width: 2px !important;
  }

  .lg\:border-b-2 {
    border-bottom-width: 2px !important;
  }

  .lg\:border-l-2 {
    border-left-width: 2px !important;
  }

  .lg\:border-t-4 {
    border-top-width: 4px !important;
  }

  .lg\:border-r-4 {
    border-right-width: 4px !important;
  }

  .lg\:border-b-4 {
    border-bottom-width: 4px !important;
  }

  .lg\:border-l-4 {
    border-left-width: 4px !important;
  }

  .lg\:border-t-8 {
    border-top-width: 8px !important;
  }

  .lg\:border-r-8 {
    border-right-width: 8px !important;
  }

  .lg\:border-b-8 {
    border-bottom-width: 8px !important;
  }

  .lg\:border-l-8 {
    border-left-width: 8px !important;
  }

  .lg\:border-t {
    border-top-width: 1px !important;
  }

  .lg\:border-r {
    border-right-width: 1px !important;
  }

  .lg\:border-b {
    border-bottom-width: 1px !important;
  }

  .lg\:border-l {
    border-left-width: 1px !important;
  }

  .lg\:first\:border-0:first-child {
    border-width: 0 !important;
  }

  .lg\:first\:border-2:first-child {
    border-width: 2px !important;
  }

  .lg\:first\:border-4:first-child {
    border-width: 4px !important;
  }

  .lg\:first\:border-8:first-child {
    border-width: 8px !important;
  }

  .lg\:first\:border:first-child {
    border-width: 1px !important;
  }

  .lg\:first\:border-t-0:first-child {
    border-top-width: 0 !important;
  }

  .lg\:first\:border-r-0:first-child {
    border-right-width: 0 !important;
  }

  .lg\:first\:border-b-0:first-child {
    border-bottom-width: 0 !important;
  }

  .lg\:first\:border-l-0:first-child {
    border-left-width: 0 !important;
  }

  .lg\:first\:border-t-2:first-child {
    border-top-width: 2px !important;
  }

  .lg\:first\:border-r-2:first-child {
    border-right-width: 2px !important;
  }

  .lg\:first\:border-b-2:first-child {
    border-bottom-width: 2px !important;
  }

  .lg\:first\:border-l-2:first-child {
    border-left-width: 2px !important;
  }

  .lg\:first\:border-t-4:first-child {
    border-top-width: 4px !important;
  }

  .lg\:first\:border-r-4:first-child {
    border-right-width: 4px !important;
  }

  .lg\:first\:border-b-4:first-child {
    border-bottom-width: 4px !important;
  }

  .lg\:first\:border-l-4:first-child {
    border-left-width: 4px !important;
  }

  .lg\:first\:border-t-8:first-child {
    border-top-width: 8px !important;
  }

  .lg\:first\:border-r-8:first-child {
    border-right-width: 8px !important;
  }

  .lg\:first\:border-b-8:first-child {
    border-bottom-width: 8px !important;
  }

  .lg\:first\:border-l-8:first-child {
    border-left-width: 8px !important;
  }

  .lg\:first\:border-t:first-child {
    border-top-width: 1px !important;
  }

  .lg\:first\:border-r:first-child {
    border-right-width: 1px !important;
  }

  .lg\:first\:border-b:first-child {
    border-bottom-width: 1px !important;
  }

  .lg\:first\:border-l:first-child {
    border-left-width: 1px !important;
  }

  .lg\:last\:border-0:last-child {
    border-width: 0 !important;
  }

  .lg\:last\:border-2:last-child {
    border-width: 2px !important;
  }

  .lg\:last\:border-4:last-child {
    border-width: 4px !important;
  }

  .lg\:last\:border-8:last-child {
    border-width: 8px !important;
  }

  .lg\:last\:border:last-child {
    border-width: 1px !important;
  }

  .lg\:last\:border-t-0:last-child {
    border-top-width: 0 !important;
  }

  .lg\:last\:border-r-0:last-child {
    border-right-width: 0 !important;
  }

  .lg\:last\:border-b-0:last-child {
    border-bottom-width: 0 !important;
  }

  .lg\:last\:border-l-0:last-child {
    border-left-width: 0 !important;
  }

  .lg\:last\:border-t-2:last-child {
    border-top-width: 2px !important;
  }

  .lg\:last\:border-r-2:last-child {
    border-right-width: 2px !important;
  }

  .lg\:last\:border-b-2:last-child {
    border-bottom-width: 2px !important;
  }

  .lg\:last\:border-l-2:last-child {
    border-left-width: 2px !important;
  }

  .lg\:last\:border-t-4:last-child {
    border-top-width: 4px !important;
  }

  .lg\:last\:border-r-4:last-child {
    border-right-width: 4px !important;
  }

  .lg\:last\:border-b-4:last-child {
    border-bottom-width: 4px !important;
  }

  .lg\:last\:border-l-4:last-child {
    border-left-width: 4px !important;
  }

  .lg\:last\:border-t-8:last-child {
    border-top-width: 8px !important;
  }

  .lg\:last\:border-r-8:last-child {
    border-right-width: 8px !important;
  }

  .lg\:last\:border-b-8:last-child {
    border-bottom-width: 8px !important;
  }

  .lg\:last\:border-l-8:last-child {
    border-left-width: 8px !important;
  }

  .lg\:last\:border-t:last-child {
    border-top-width: 1px !important;
  }

  .lg\:last\:border-r:last-child {
    border-right-width: 1px !important;
  }

  .lg\:last\:border-b:last-child {
    border-bottom-width: 1px !important;
  }

  .lg\:last\:border-l:last-child {
    border-left-width: 1px !important;
  }

  .lg\:box-border {
    box-sizing: border-box !important;
  }

  .lg\:box-content {
    box-sizing: content-box !important;
  }

  .lg\:block {
    display: block !important;
  }

  .lg\:inline-block {
    display: inline-block !important;
  }

  .lg\:inline {
    display: inline !important;
  }

  .lg\:flex {
    display: flex !important;
  }

  .lg\:inline-flex {
    display: inline-flex !important;
  }

  .lg\:table {
    display: table !important;
  }

  .lg\:table-caption {
    display: table-caption !important;
  }

  .lg\:table-cell {
    display: table-cell !important;
  }

  .lg\:table-column {
    display: table-column !important;
  }

  .lg\:table-column-group {
    display: table-column-group !important;
  }

  .lg\:table-footer-group {
    display: table-footer-group !important;
  }

  .lg\:table-header-group {
    display: table-header-group !important;
  }

  .lg\:table-row-group {
    display: table-row-group !important;
  }

  .lg\:table-row {
    display: table-row !important;
  }

  .lg\:flow-root {
    display: flow-root !important;
  }

  .lg\:grid {
    display: grid !important;
  }

  .lg\:inline-grid {
    display: inline-grid !important;
  }

  .lg\:hidden {
    display: none !important;
  }

  .lg\:flex-row {
    flex-direction: row !important;
  }

  .lg\:flex-row-reverse {
    flex-direction: row-reverse !important;
  }

  .lg\:flex-col {
    flex-direction: column !important;
  }

  .lg\:flex-col-reverse {
    flex-direction: column-reverse !important;
  }

  .lg\:flex-wrap {
    flex-wrap: wrap !important;
  }

  .lg\:flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .lg\:flex-nowrap {
    flex-wrap: nowrap !important;
  }

  .lg\:items-start {
    align-items: flex-start !important;
  }

  .lg\:items-end {
    align-items: flex-end !important;
  }

  .lg\:items-center {
    align-items: center !important;
  }

  .lg\:items-baseline {
    align-items: baseline !important;
  }

  .lg\:items-stretch {
    align-items: stretch !important;
  }

  .lg\:self-auto {
    align-self: auto !important;
  }

  .lg\:self-start {
    align-self: flex-start !important;
  }

  .lg\:self-end {
    align-self: flex-end !important;
  }

  .lg\:self-center {
    align-self: center !important;
  }

  .lg\:self-stretch {
    align-self: stretch !important;
  }

  .lg\:justify-start {
    justify-content: flex-start !important;
  }

  .lg\:justify-end {
    justify-content: flex-end !important;
  }

  .lg\:justify-center {
    justify-content: center !important;
  }

  .lg\:justify-between {
    justify-content: space-between !important;
  }

  .lg\:justify-around {
    justify-content: space-around !important;
  }

  .lg\:justify-evenly {
    justify-content: space-evenly !important;
  }

  .lg\:content-center {
    align-content: center !important;
  }

  .lg\:content-start {
    align-content: flex-start !important;
  }

  .lg\:content-end {
    align-content: flex-end !important;
  }

  .lg\:content-between {
    align-content: space-between !important;
  }

  .lg\:content-around {
    align-content: space-around !important;
  }

  .lg\:flex-0 {
    flex: 0 0 auto !important;
  }

  .lg\:flex-1 {
    flex: 1 1 0% !important;
  }

  .lg\:flex-auto {
    flex: 1 1 auto !important;
  }

  .lg\:flex-initial {
    flex: 0 1 auto !important;
  }

  .lg\:flex-none {
    flex: none !important;
  }

  .lg\:flex-grow-0 {
    flex-grow: 0 !important;
  }

  .lg\:flex-grow {
    flex-grow: 1 !important;
  }

  .lg\:flex-shrink-0 {
    flex-shrink: 0 !important;
  }

  .lg\:flex-shrink {
    flex-shrink: 1 !important;
  }

  .lg\:order-1 {
    order: 1 !important;
  }

  .lg\:order-2 {
    order: 2 !important;
  }

  .lg\:order-3 {
    order: 3 !important;
  }

  .lg\:order-4 {
    order: 4 !important;
  }

  .lg\:order-5 {
    order: 5 !important;
  }

  .lg\:order-6 {
    order: 6 !important;
  }

  .lg\:order-7 {
    order: 7 !important;
  }

  .lg\:order-8 {
    order: 8 !important;
  }

  .lg\:order-9 {
    order: 9 !important;
  }

  .lg\:order-10 {
    order: 10 !important;
  }

  .lg\:order-11 {
    order: 11 !important;
  }

  .lg\:order-12 {
    order: 12 !important;
  }

  .lg\:order-first {
    order: -9999 !important;
  }

  .lg\:order-last {
    order: 9999 !important;
  }

  .lg\:order-none {
    order: 0 !important;
  }

  .lg\:font-thin {
    font-weight: 100 !important;
  }

  .lg\:font-extralight {
    font-weight: 200 !important;
  }

  .lg\:font-light {
    font-weight: 300 !important;
  }

  .lg\:font-normal {
    font-weight: 400 !important;
  }

  .lg\:font-medium {
    font-weight: 500 !important;
  }

  .lg\:font-semibold {
    font-weight: 600 !important;
  }

  .lg\:font-bold {
    font-weight: 700 !important;
  }

  .lg\:font-extrabold {
    font-weight: 800 !important;
  }

  .lg\:font-black {
    font-weight: 900 !important;
  }

  .lg\:h-0 {
    height: 0 !important;
  }

  .lg\:h-1 {
    height: 0.25rem !important;
  }

  .lg\:h-2 {
    height: 0.5rem !important;
  }

  .lg\:h-3 {
    height: 0.75rem !important;
  }

  .lg\:h-4 {
    height: 1rem !important;
  }

  .lg\:h-5 {
    height: 1.25rem !important;
  }

  .lg\:h-6 {
    height: 1.5rem !important;
  }

  .lg\:h-8 {
    height: 2rem !important;
  }

  .lg\:h-10 {
    height: 2.5rem !important;
  }

  .lg\:h-12 {
    height: 3rem !important;
  }

  .lg\:h-14 {
    height: 3.5rem !important;
  }

  .lg\:h-16 {
    height: 4rem !important;
  }

  .lg\:h-18 {
    height: 4.5rem !important;
  }

  .lg\:h-20 {
    height: 5rem !important;
  }

  .lg\:h-22 {
    height: 5.5rem !important;
  }

  .lg\:h-24 {
    height: 6rem !important;
  }

  .lg\:h-26 {
    height: 6.5rem !important;
  }

  .lg\:h-28 {
    height: 7rem !important;
  }

  .lg\:h-30 {
    height: 7.5rem !important;
  }

  .lg\:h-32 {
    height: 8rem !important;
  }

  .lg\:h-36 {
    height: 9rem !important;
  }

  .lg\:h-40 {
    height: 10rem !important;
  }

  .lg\:h-48 {
    height: 12rem !important;
  }

  .lg\:h-50 {
    height: 12.5rem !important;
  }

  .lg\:h-56 {
    height: 14rem !important;
  }

  .lg\:h-60 {
    height: 15rem !important;
  }

  .lg\:h-64 {
    height: 16rem !important;
  }

  .lg\:h-80 {
    height: 20rem !important;
  }

  .lg\:h-90 {
    height: 24rem !important;
  }

  .lg\:h-100 {
    height: 25rem !important;
  }

  .lg\:h-120 {
    height: 30rem !important;
  }

  .lg\:h-128 {
    height: 32rem !important;
  }

  .lg\:h-140 {
    height: 35rem !important;
  }

  .lg\:h-160 {
    height: 40rem !important;
  }

  .lg\:h-180 {
    height: 45rem !important;
  }

  .lg\:h-192 {
    height: 48rem !important;
  }

  .lg\:h-200 {
    height: 50rem !important;
  }

  .lg\:h-240 {
    height: 60rem !important;
  }

  .lg\:h-256 {
    height: 64rem !important;
  }

  .lg\:h-280 {
    height: 70rem !important;
  }

  .lg\:h-320 {
    height: 80rem !important;
  }

  .lg\:h-360 {
    height: 90rem !important;
  }

  .lg\:h-400 {
    height: 100rem !important;
  }

  .lg\:h-480 {
    height: 120rem !important;
  }

  .lg\:h-auto {
    height: auto !important;
  }

  .lg\:h-px {
    height: 1px !important;
  }

  .lg\:h-2px {
    height: 2px !important;
  }

  .lg\:h-full {
    height: 100% !important;
  }

  .lg\:h-screen {
    height: 100vh !important;
  }

  .lg\:h-1\/2 {
    height: 50% !important;
  }

  .lg\:h-1\/3 {
    height: 33.33333% !important;
  }

  .lg\:h-2\/3 {
    height: 66.66667% !important;
  }

  .lg\:h-1\/4 {
    height: 25% !important;
  }

  .lg\:h-2\/4 {
    height: 50% !important;
  }

  .lg\:h-3\/4 {
    height: 75% !important;
  }

  .lg\:h-1\/5 {
    height: 20% !important;
  }

  .lg\:h-2\/5 {
    height: 40% !important;
  }

  .lg\:h-3\/5 {
    height: 60% !important;
  }

  .lg\:h-4\/5 {
    height: 80% !important;
  }

  .lg\:h-1\/12 {
    height: 8.33333% !important;
  }

  .lg\:h-2\/12 {
    height: 16.66667% !important;
  }

  .lg\:h-3\/12 {
    height: 25% !important;
  }

  .lg\:h-4\/12 {
    height: 33.33333% !important;
  }

  .lg\:h-5\/12 {
    height: 41.66667% !important;
  }

  .lg\:h-6\/12 {
    height: 50% !important;
  }

  .lg\:h-7\/12 {
    height: 58.33333% !important;
  }

  .lg\:h-8\/12 {
    height: 66.66667% !important;
  }

  .lg\:h-9\/12 {
    height: 75% !important;
  }

  .lg\:h-10\/12 {
    height: 83.33333% !important;
  }

  .lg\:h-11\/12 {
    height: 91.66667% !important;
  }

  .lg\:text-xs {
    font-size: 0.625rem !important;
  }

  .lg\:text-sm {
    font-size: 0.75rem !important;
  }

  .lg\:text-md {
    font-size: 0.8125rem !important;
  }

  .lg\:text-base {
    font-size: 0.875rem !important;
  }

  .lg\:text-lg {
    font-size: 1rem !important;
  }

  .lg\:text-xl {
    font-size: 1.125rem !important;
  }

  .lg\:text-2xl {
    font-size: 1.25rem !important;
  }

  .lg\:text-3xl {
    font-size: 1.5rem !important;
  }

  .lg\:text-4xl {
    font-size: 2rem !important;
  }

  .lg\:text-5xl {
    font-size: 2.25rem !important;
  }

  .lg\:text-6xl {
    font-size: 2.5rem !important;
  }

  .lg\:text-7xl {
    font-size: 3rem !important;
  }

  .lg\:text-8xl {
    font-size: 4rem !important;
  }

  .lg\:text-9xl {
    font-size: 6rem !important;
  }

  .lg\:text-10xl {
    font-size: 8rem !important;
  }

  .lg\:leading-3 {
    line-height: .75rem !important;
  }

  .lg\:leading-4 {
    line-height: 1rem !important;
  }

  .lg\:leading-5 {
    line-height: 1.25rem !important;
  }

  .lg\:leading-6 {
    line-height: 1.5rem !important;
  }

  .lg\:leading-7 {
    line-height: 1.75rem !important;
  }

  .lg\:leading-8 {
    line-height: 2rem !important;
  }

  .lg\:leading-9 {
    line-height: 2.25rem !important;
  }

  .lg\:leading-10 {
    line-height: 2.5rem !important;
  }

  .lg\:leading-none {
    line-height: 1 !important;
  }

  .lg\:leading-tight {
    line-height: 1.25 !important;
  }

  .lg\:leading-snug {
    line-height: 1.375 !important;
  }

  .lg\:leading-normal {
    line-height: 1.5 !important;
  }

  .lg\:leading-relaxed {
    line-height: 1.625 !important;
  }

  .lg\:leading-loose {
    line-height: 2 !important;
  }

  .lg\:list-inside {
    list-style-position: inside !important;
  }

  .lg\:list-outside {
    list-style-position: outside !important;
  }

  .lg\:list-none {
    list-style-type: none !important;
  }

  .lg\:list-disc {
    list-style-type: disc !important;
  }

  .lg\:list-decimal {
    list-style-type: decimal !important;
  }

  .lg\:m-0 {
    margin: 0 !important;
  }

  .lg\:m-1 {
    margin: 0.25rem !important;
  }

  .lg\:m-2 {
    margin: 0.5rem !important;
  }

  .lg\:m-3 {
    margin: 0.75rem !important;
  }

  .lg\:m-4 {
    margin: 1rem !important;
  }

  .lg\:m-5 {
    margin: 1.25rem !important;
  }

  .lg\:m-6 {
    margin: 1.5rem !important;
  }

  .lg\:m-8 {
    margin: 2rem !important;
  }

  .lg\:m-10 {
    margin: 2.5rem !important;
  }

  .lg\:m-12 {
    margin: 3rem !important;
  }

  .lg\:m-14 {
    margin: 3.5rem !important;
  }

  .lg\:m-16 {
    margin: 4rem !important;
  }

  .lg\:m-18 {
    margin: 4.5rem !important;
  }

  .lg\:m-20 {
    margin: 5rem !important;
  }

  .lg\:m-22 {
    margin: 5.5rem !important;
  }

  .lg\:m-24 {
    margin: 6rem !important;
  }

  .lg\:m-26 {
    margin: 6.5rem !important;
  }

  .lg\:m-28 {
    margin: 7rem !important;
  }

  .lg\:m-30 {
    margin: 7.5rem !important;
  }

  .lg\:m-32 {
    margin: 8rem !important;
  }

  .lg\:m-36 {
    margin: 9rem !important;
  }

  .lg\:m-40 {
    margin: 10rem !important;
  }

  .lg\:m-48 {
    margin: 12rem !important;
  }

  .lg\:m-56 {
    margin: 14rem !important;
  }

  .lg\:m-64 {
    margin: 16rem !important;
  }

  .lg\:m-auto {
    margin: auto !important;
  }

  .lg\:m-px {
    margin: 1px !important;
  }

  .lg\:m-2px {
    margin: 2px !important;
  }

  .lg\:-m-1 {
    margin: -0.25rem !important;
  }

  .lg\:-m-2 {
    margin: -0.5rem !important;
  }

  .lg\:-m-3 {
    margin: -0.75rem !important;
  }

  .lg\:-m-4 {
    margin: -1rem !important;
  }

  .lg\:-m-5 {
    margin: -1.25rem !important;
  }

  .lg\:-m-6 {
    margin: -1.5rem !important;
  }

  .lg\:-m-8 {
    margin: -2rem !important;
  }

  .lg\:-m-10 {
    margin: -2.5rem !important;
  }

  .lg\:-m-12 {
    margin: -3rem !important;
  }

  .lg\:-m-14 {
    margin: -3.5rem !important;
  }

  .lg\:-m-16 {
    margin: -4rem !important;
  }

  .lg\:-m-18 {
    margin: -4.5rem !important;
  }

  .lg\:-m-20 {
    margin: -5rem !important;
  }

  .lg\:-m-22 {
    margin: -5.5rem !important;
  }

  .lg\:-m-24 {
    margin: -6rem !important;
  }

  .lg\:-m-26 {
    margin: -6.5rem !important;
  }

  .lg\:-m-28 {
    margin: -7rem !important;
  }

  .lg\:-m-30 {
    margin: -7.5rem !important;
  }

  .lg\:-m-32 {
    margin: -8rem !important;
  }

  .lg\:-m-36 {
    margin: -9rem !important;
  }

  .lg\:-m-40 {
    margin: -10rem !important;
  }

  .lg\:-m-48 {
    margin: -12rem !important;
  }

  .lg\:-m-56 {
    margin: -14rem !important;
  }

  .lg\:-m-64 {
    margin: -16rem !important;
  }

  .lg\:-m-px {
    margin: -1px !important;
  }

  .lg\:-m-2px {
    margin: -2px !important;
  }

  .lg\:my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .lg\:mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .lg\:my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .lg\:mx-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }

  .lg\:my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .lg\:mx-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .lg\:my-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .lg\:mx-3 {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
  }

  .lg\:my-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .lg\:mx-4 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .lg\:my-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .lg\:mx-5 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }

  .lg\:my-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .lg\:mx-6 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .lg\:my-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .lg\:mx-8 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .lg\:my-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .lg\:mx-10 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .lg\:my-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .lg\:mx-12 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .lg\:my-14 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .lg\:mx-14 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }

  .lg\:my-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .lg\:mx-16 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .lg\:my-18 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .lg\:mx-18 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }

  .lg\:my-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .lg\:mx-20 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .lg\:my-22 {
    margin-top: 5.5rem !important;
    margin-bottom: 5.5rem !important;
  }

  .lg\:mx-22 {
    margin-left: 5.5rem !important;
    margin-right: 5.5rem !important;
  }

  .lg\:my-24 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .lg\:mx-24 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }

  .lg\:my-26 {
    margin-top: 6.5rem !important;
    margin-bottom: 6.5rem !important;
  }

  .lg\:mx-26 {
    margin-left: 6.5rem !important;
    margin-right: 6.5rem !important;
  }

  .lg\:my-28 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }

  .lg\:mx-28 {
    margin-left: 7rem !important;
    margin-right: 7rem !important;
  }

  .lg\:my-30 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }

  .lg\:mx-30 {
    margin-left: 7.5rem !important;
    margin-right: 7.5rem !important;
  }

  .lg\:my-32 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .lg\:mx-32 {
    margin-left: 8rem !important;
    margin-right: 8rem !important;
  }

  .lg\:my-36 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }

  .lg\:mx-36 {
    margin-left: 9rem !important;
    margin-right: 9rem !important;
  }

  .lg\:my-40 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .lg\:mx-40 {
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }

  .lg\:my-48 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }

  .lg\:mx-48 {
    margin-left: 12rem !important;
    margin-right: 12rem !important;
  }

  .lg\:my-56 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }

  .lg\:mx-56 {
    margin-left: 14rem !important;
    margin-right: 14rem !important;
  }

  .lg\:my-64 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }

  .lg\:mx-64 {
    margin-left: 16rem !important;
    margin-right: 16rem !important;
  }

  .lg\:my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .lg\:mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .lg\:my-px {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  .lg\:mx-px {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }

  .lg\:my-2px {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }

  .lg\:mx-2px {
    margin-left: 2px !important;
    margin-right: 2px !important;
  }

  .lg\:-my-1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .lg\:-mx-1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }

  .lg\:-my-2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .lg\:-mx-2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }

  .lg\:-my-3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }

  .lg\:-mx-3 {
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important;
  }

  .lg\:-my-4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .lg\:-mx-4 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .lg\:-my-5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .lg\:-mx-5 {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }

  .lg\:-my-6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .lg\:-mx-6 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .lg\:-my-8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .lg\:-mx-8 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .lg\:-my-10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .lg\:-mx-10 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }

  .lg\:-my-12 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .lg\:-mx-12 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .lg\:-my-14 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .lg\:-mx-14 {
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }

  .lg\:-my-16 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .lg\:-mx-16 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .lg\:-my-18 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .lg\:-mx-18 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }

  .lg\:-my-20 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .lg\:-mx-20 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .lg\:-my-22 {
    margin-top: -5.5rem !important;
    margin-bottom: -5.5rem !important;
  }

  .lg\:-mx-22 {
    margin-left: -5.5rem !important;
    margin-right: -5.5rem !important;
  }

  .lg\:-my-24 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .lg\:-mx-24 {
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }

  .lg\:-my-26 {
    margin-top: -6.5rem !important;
    margin-bottom: -6.5rem !important;
  }

  .lg\:-mx-26 {
    margin-left: -6.5rem !important;
    margin-right: -6.5rem !important;
  }

  .lg\:-my-28 {
    margin-top: -7rem !important;
    margin-bottom: -7rem !important;
  }

  .lg\:-mx-28 {
    margin-left: -7rem !important;
    margin-right: -7rem !important;
  }

  .lg\:-my-30 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important;
  }

  .lg\:-mx-30 {
    margin-left: -7.5rem !important;
    margin-right: -7.5rem !important;
  }

  .lg\:-my-32 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .lg\:-mx-32 {
    margin-left: -8rem !important;
    margin-right: -8rem !important;
  }

  .lg\:-my-36 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }

  .lg\:-mx-36 {
    margin-left: -9rem !important;
    margin-right: -9rem !important;
  }

  .lg\:-my-40 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .lg\:-mx-40 {
    margin-left: -10rem !important;
    margin-right: -10rem !important;
  }

  .lg\:-my-48 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }

  .lg\:-mx-48 {
    margin-left: -12rem !important;
    margin-right: -12rem !important;
  }

  .lg\:-my-56 {
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }

  .lg\:-mx-56 {
    margin-left: -14rem !important;
    margin-right: -14rem !important;
  }

  .lg\:-my-64 {
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }

  .lg\:-mx-64 {
    margin-left: -16rem !important;
    margin-right: -16rem !important;
  }

  .lg\:-my-px {
    margin-top: -1px !important;
    margin-bottom: -1px !important;
  }

  .lg\:-mx-px {
    margin-left: -1px !important;
    margin-right: -1px !important;
  }

  .lg\:-my-2px {
    margin-top: -2px !important;
    margin-bottom: -2px !important;
  }

  .lg\:-mx-2px {
    margin-left: -2px !important;
    margin-right: -2px !important;
  }

  .lg\:mt-0 {
    margin-top: 0 !important;
  }

  .lg\:mr-0 {
    margin-right: 0 !important;
  }

  .lg\:mb-0 {
    margin-bottom: 0 !important;
  }

  .lg\:ml-0 {
    margin-left: 0 !important;
  }

  .lg\:mt-1 {
    margin-top: 0.25rem !important;
  }

  .lg\:mr-1 {
    margin-right: 0.25rem !important;
  }

  .lg\:mb-1 {
    margin-bottom: 0.25rem !important;
  }

  .lg\:ml-1 {
    margin-left: 0.25rem !important;
  }

  .lg\:mt-2 {
    margin-top: 0.5rem !important;
  }

  .lg\:mr-2 {
    margin-right: 0.5rem !important;
  }

  .lg\:mb-2 {
    margin-bottom: 0.5rem !important;
  }

  .lg\:ml-2 {
    margin-left: 0.5rem !important;
  }

  .lg\:mt-3 {
    margin-top: 0.75rem !important;
  }

  .lg\:mr-3 {
    margin-right: 0.75rem !important;
  }

  .lg\:mb-3 {
    margin-bottom: 0.75rem !important;
  }

  .lg\:ml-3 {
    margin-left: 0.75rem !important;
  }

  .lg\:mt-4 {
    margin-top: 1rem !important;
  }

  .lg\:mr-4 {
    margin-right: 1rem !important;
  }

  .lg\:mb-4 {
    margin-bottom: 1rem !important;
  }

  .lg\:ml-4 {
    margin-left: 1rem !important;
  }

  .lg\:mt-5 {
    margin-top: 1.25rem !important;
  }

  .lg\:mr-5 {
    margin-right: 1.25rem !important;
  }

  .lg\:mb-5 {
    margin-bottom: 1.25rem !important;
  }

  .lg\:ml-5 {
    margin-left: 1.25rem !important;
  }

  .lg\:mt-6 {
    margin-top: 1.5rem !important;
  }

  .lg\:mr-6 {
    margin-right: 1.5rem !important;
  }

  .lg\:mb-6 {
    margin-bottom: 1.5rem !important;
  }

  .lg\:ml-6 {
    margin-left: 1.5rem !important;
  }

  .lg\:mt-8 {
    margin-top: 2rem !important;
  }

  .lg\:mr-8 {
    margin-right: 2rem !important;
  }

  .lg\:mb-8 {
    margin-bottom: 2rem !important;
  }

  .lg\:ml-8 {
    margin-left: 2rem !important;
  }

  .lg\:mt-10 {
    margin-top: 2.5rem !important;
  }

  .lg\:mr-10 {
    margin-right: 2.5rem !important;
  }

  .lg\:mb-10 {
    margin-bottom: 2.5rem !important;
  }

  .lg\:ml-10 {
    margin-left: 2.5rem !important;
  }

  .lg\:mt-12 {
    margin-top: 3rem !important;
  }

  .lg\:mr-12 {
    margin-right: 3rem !important;
  }

  .lg\:mb-12 {
    margin-bottom: 3rem !important;
  }

  .lg\:ml-12 {
    margin-left: 3rem !important;
  }

  .lg\:mt-14 {
    margin-top: 3.5rem !important;
  }

  .lg\:mr-14 {
    margin-right: 3.5rem !important;
  }

  .lg\:mb-14 {
    margin-bottom: 3.5rem !important;
  }

  .lg\:ml-14 {
    margin-left: 3.5rem !important;
  }

  .lg\:mt-16 {
    margin-top: 4rem !important;
  }

  .lg\:mr-16 {
    margin-right: 4rem !important;
  }

  .lg\:mb-16 {
    margin-bottom: 4rem !important;
  }

  .lg\:ml-16 {
    margin-left: 4rem !important;
  }

  .lg\:mt-18 {
    margin-top: 4.5rem !important;
  }

  .lg\:mr-18 {
    margin-right: 4.5rem !important;
  }

  .lg\:mb-18 {
    margin-bottom: 4.5rem !important;
  }

  .lg\:ml-18 {
    margin-left: 4.5rem !important;
  }

  .lg\:mt-20 {
    margin-top: 5rem !important;
  }

  .lg\:mr-20 {
    margin-right: 5rem !important;
  }

  .lg\:mb-20 {
    margin-bottom: 5rem !important;
  }

  .lg\:ml-20 {
    margin-left: 5rem !important;
  }

  .lg\:mt-22 {
    margin-top: 5.5rem !important;
  }

  .lg\:mr-22 {
    margin-right: 5.5rem !important;
  }

  .lg\:mb-22 {
    margin-bottom: 5.5rem !important;
  }

  .lg\:ml-22 {
    margin-left: 5.5rem !important;
  }

  .lg\:mt-24 {
    margin-top: 6rem !important;
  }

  .lg\:mr-24 {
    margin-right: 6rem !important;
  }

  .lg\:mb-24 {
    margin-bottom: 6rem !important;
  }

  .lg\:ml-24 {
    margin-left: 6rem !important;
  }

  .lg\:mt-26 {
    margin-top: 6.5rem !important;
  }

  .lg\:mr-26 {
    margin-right: 6.5rem !important;
  }

  .lg\:mb-26 {
    margin-bottom: 6.5rem !important;
  }

  .lg\:ml-26 {
    margin-left: 6.5rem !important;
  }

  .lg\:mt-28 {
    margin-top: 7rem !important;
  }

  .lg\:mr-28 {
    margin-right: 7rem !important;
  }

  .lg\:mb-28 {
    margin-bottom: 7rem !important;
  }

  .lg\:ml-28 {
    margin-left: 7rem !important;
  }

  .lg\:mt-30 {
    margin-top: 7.5rem !important;
  }

  .lg\:mr-30 {
    margin-right: 7.5rem !important;
  }

  .lg\:mb-30 {
    margin-bottom: 7.5rem !important;
  }

  .lg\:ml-30 {
    margin-left: 7.5rem !important;
  }

  .lg\:mt-32 {
    margin-top: 8rem !important;
  }

  .lg\:mr-32 {
    margin-right: 8rem !important;
  }

  .lg\:mb-32 {
    margin-bottom: 8rem !important;
  }

  .lg\:ml-32 {
    margin-left: 8rem !important;
  }

  .lg\:mt-36 {
    margin-top: 9rem !important;
  }

  .lg\:mr-36 {
    margin-right: 9rem !important;
  }

  .lg\:mb-36 {
    margin-bottom: 9rem !important;
  }

  .lg\:ml-36 {
    margin-left: 9rem !important;
  }

  .lg\:mt-40 {
    margin-top: 10rem !important;
  }

  .lg\:mr-40 {
    margin-right: 10rem !important;
  }

  .lg\:mb-40 {
    margin-bottom: 10rem !important;
  }

  .lg\:ml-40 {
    margin-left: 10rem !important;
  }

  .lg\:mt-48 {
    margin-top: 12rem !important;
  }

  .lg\:mr-48 {
    margin-right: 12rem !important;
  }

  .lg\:mb-48 {
    margin-bottom: 12rem !important;
  }

  .lg\:ml-48 {
    margin-left: 12rem !important;
  }

  .lg\:mt-56 {
    margin-top: 14rem !important;
  }

  .lg\:mr-56 {
    margin-right: 14rem !important;
  }

  .lg\:mb-56 {
    margin-bottom: 14rem !important;
  }

  .lg\:ml-56 {
    margin-left: 14rem !important;
  }

  .lg\:mt-64 {
    margin-top: 16rem !important;
  }

  .lg\:mr-64 {
    margin-right: 16rem !important;
  }

  .lg\:mb-64 {
    margin-bottom: 16rem !important;
  }

  .lg\:ml-64 {
    margin-left: 16rem !important;
  }

  .lg\:mt-auto {
    margin-top: auto !important;
  }

  .lg\:mr-auto {
    margin-right: auto !important;
  }

  .lg\:mb-auto {
    margin-bottom: auto !important;
  }

  .lg\:ml-auto {
    margin-left: auto !important;
  }

  .lg\:mt-px {
    margin-top: 1px !important;
  }

  .lg\:mr-px {
    margin-right: 1px !important;
  }

  .lg\:mb-px {
    margin-bottom: 1px !important;
  }

  .lg\:ml-px {
    margin-left: 1px !important;
  }

  .lg\:mt-2px {
    margin-top: 2px !important;
  }

  .lg\:mr-2px {
    margin-right: 2px !important;
  }

  .lg\:mb-2px {
    margin-bottom: 2px !important;
  }

  .lg\:ml-2px {
    margin-left: 2px !important;
  }

  .lg\:-mt-1 {
    margin-top: -0.25rem !important;
  }

  .lg\:-mr-1 {
    margin-right: -0.25rem !important;
  }

  .lg\:-mb-1 {
    margin-bottom: -0.25rem !important;
  }

  .lg\:-ml-1 {
    margin-left: -0.25rem !important;
  }

  .lg\:-mt-2 {
    margin-top: -0.5rem !important;
  }

  .lg\:-mr-2 {
    margin-right: -0.5rem !important;
  }

  .lg\:-mb-2 {
    margin-bottom: -0.5rem !important;
  }

  .lg\:-ml-2 {
    margin-left: -0.5rem !important;
  }

  .lg\:-mt-3 {
    margin-top: -0.75rem !important;
  }

  .lg\:-mr-3 {
    margin-right: -0.75rem !important;
  }

  .lg\:-mb-3 {
    margin-bottom: -0.75rem !important;
  }

  .lg\:-ml-3 {
    margin-left: -0.75rem !important;
  }

  .lg\:-mt-4 {
    margin-top: -1rem !important;
  }

  .lg\:-mr-4 {
    margin-right: -1rem !important;
  }

  .lg\:-mb-4 {
    margin-bottom: -1rem !important;
  }

  .lg\:-ml-4 {
    margin-left: -1rem !important;
  }

  .lg\:-mt-5 {
    margin-top: -1.25rem !important;
  }

  .lg\:-mr-5 {
    margin-right: -1.25rem !important;
  }

  .lg\:-mb-5 {
    margin-bottom: -1.25rem !important;
  }

  .lg\:-ml-5 {
    margin-left: -1.25rem !important;
  }

  .lg\:-mt-6 {
    margin-top: -1.5rem !important;
  }

  .lg\:-mr-6 {
    margin-right: -1.5rem !important;
  }

  .lg\:-mb-6 {
    margin-bottom: -1.5rem !important;
  }

  .lg\:-ml-6 {
    margin-left: -1.5rem !important;
  }

  .lg\:-mt-8 {
    margin-top: -2rem !important;
  }

  .lg\:-mr-8 {
    margin-right: -2rem !important;
  }

  .lg\:-mb-8 {
    margin-bottom: -2rem !important;
  }

  .lg\:-ml-8 {
    margin-left: -2rem !important;
  }

  .lg\:-mt-10 {
    margin-top: -2.5rem !important;
  }

  .lg\:-mr-10 {
    margin-right: -2.5rem !important;
  }

  .lg\:-mb-10 {
    margin-bottom: -2.5rem !important;
  }

  .lg\:-ml-10 {
    margin-left: -2.5rem !important;
  }

  .lg\:-mt-12 {
    margin-top: -3rem !important;
  }

  .lg\:-mr-12 {
    margin-right: -3rem !important;
  }

  .lg\:-mb-12 {
    margin-bottom: -3rem !important;
  }

  .lg\:-ml-12 {
    margin-left: -3rem !important;
  }

  .lg\:-mt-14 {
    margin-top: -3.5rem !important;
  }

  .lg\:-mr-14 {
    margin-right: -3.5rem !important;
  }

  .lg\:-mb-14 {
    margin-bottom: -3.5rem !important;
  }

  .lg\:-ml-14 {
    margin-left: -3.5rem !important;
  }

  .lg\:-mt-16 {
    margin-top: -4rem !important;
  }

  .lg\:-mr-16 {
    margin-right: -4rem !important;
  }

  .lg\:-mb-16 {
    margin-bottom: -4rem !important;
  }

  .lg\:-ml-16 {
    margin-left: -4rem !important;
  }

  .lg\:-mt-18 {
    margin-top: -4.5rem !important;
  }

  .lg\:-mr-18 {
    margin-right: -4.5rem !important;
  }

  .lg\:-mb-18 {
    margin-bottom: -4.5rem !important;
  }

  .lg\:-ml-18 {
    margin-left: -4.5rem !important;
  }

  .lg\:-mt-20 {
    margin-top: -5rem !important;
  }

  .lg\:-mr-20 {
    margin-right: -5rem !important;
  }

  .lg\:-mb-20 {
    margin-bottom: -5rem !important;
  }

  .lg\:-ml-20 {
    margin-left: -5rem !important;
  }

  .lg\:-mt-22 {
    margin-top: -5.5rem !important;
  }

  .lg\:-mr-22 {
    margin-right: -5.5rem !important;
  }

  .lg\:-mb-22 {
    margin-bottom: -5.5rem !important;
  }

  .lg\:-ml-22 {
    margin-left: -5.5rem !important;
  }

  .lg\:-mt-24 {
    margin-top: -6rem !important;
  }

  .lg\:-mr-24 {
    margin-right: -6rem !important;
  }

  .lg\:-mb-24 {
    margin-bottom: -6rem !important;
  }

  .lg\:-ml-24 {
    margin-left: -6rem !important;
  }

  .lg\:-mt-26 {
    margin-top: -6.5rem !important;
  }

  .lg\:-mr-26 {
    margin-right: -6.5rem !important;
  }

  .lg\:-mb-26 {
    margin-bottom: -6.5rem !important;
  }

  .lg\:-ml-26 {
    margin-left: -6.5rem !important;
  }

  .lg\:-mt-28 {
    margin-top: -7rem !important;
  }

  .lg\:-mr-28 {
    margin-right: -7rem !important;
  }

  .lg\:-mb-28 {
    margin-bottom: -7rem !important;
  }

  .lg\:-ml-28 {
    margin-left: -7rem !important;
  }

  .lg\:-mt-30 {
    margin-top: -7.5rem !important;
  }

  .lg\:-mr-30 {
    margin-right: -7.5rem !important;
  }

  .lg\:-mb-30 {
    margin-bottom: -7.5rem !important;
  }

  .lg\:-ml-30 {
    margin-left: -7.5rem !important;
  }

  .lg\:-mt-32 {
    margin-top: -8rem !important;
  }

  .lg\:-mr-32 {
    margin-right: -8rem !important;
  }

  .lg\:-mb-32 {
    margin-bottom: -8rem !important;
  }

  .lg\:-ml-32 {
    margin-left: -8rem !important;
  }

  .lg\:-mt-36 {
    margin-top: -9rem !important;
  }

  .lg\:-mr-36 {
    margin-right: -9rem !important;
  }

  .lg\:-mb-36 {
    margin-bottom: -9rem !important;
  }

  .lg\:-ml-36 {
    margin-left: -9rem !important;
  }

  .lg\:-mt-40 {
    margin-top: -10rem !important;
  }

  .lg\:-mr-40 {
    margin-right: -10rem !important;
  }

  .lg\:-mb-40 {
    margin-bottom: -10rem !important;
  }

  .lg\:-ml-40 {
    margin-left: -10rem !important;
  }

  .lg\:-mt-48 {
    margin-top: -12rem !important;
  }

  .lg\:-mr-48 {
    margin-right: -12rem !important;
  }

  .lg\:-mb-48 {
    margin-bottom: -12rem !important;
  }

  .lg\:-ml-48 {
    margin-left: -12rem !important;
  }

  .lg\:-mt-56 {
    margin-top: -14rem !important;
  }

  .lg\:-mr-56 {
    margin-right: -14rem !important;
  }

  .lg\:-mb-56 {
    margin-bottom: -14rem !important;
  }

  .lg\:-ml-56 {
    margin-left: -14rem !important;
  }

  .lg\:-mt-64 {
    margin-top: -16rem !important;
  }

  .lg\:-mr-64 {
    margin-right: -16rem !important;
  }

  .lg\:-mb-64 {
    margin-bottom: -16rem !important;
  }

  .lg\:-ml-64 {
    margin-left: -16rem !important;
  }

  .lg\:-mt-px {
    margin-top: -1px !important;
  }

  .lg\:-mr-px {
    margin-right: -1px !important;
  }

  .lg\:-mb-px {
    margin-bottom: -1px !important;
  }

  .lg\:-ml-px {
    margin-left: -1px !important;
  }

  .lg\:-mt-2px {
    margin-top: -2px !important;
  }

  .lg\:-mr-2px {
    margin-right: -2px !important;
  }

  .lg\:-mb-2px {
    margin-bottom: -2px !important;
  }

  .lg\:-ml-2px {
    margin-left: -2px !important;
  }

  .lg\:max-h-0 {
    max-height: 0 !important;
  }

  .lg\:max-h-1 {
    max-height: 0.25rem !important;
  }

  .lg\:max-h-2 {
    max-height: 0.5rem !important;
  }

  .lg\:max-h-3 {
    max-height: 0.75rem !important;
  }

  .lg\:max-h-4 {
    max-height: 1rem !important;
  }

  .lg\:max-h-5 {
    max-height: 1.25rem !important;
  }

  .lg\:max-h-6 {
    max-height: 1.5rem !important;
  }

  .lg\:max-h-8 {
    max-height: 2rem !important;
  }

  .lg\:max-h-10 {
    max-height: 2.5rem !important;
  }

  .lg\:max-h-12 {
    max-height: 3rem !important;
  }

  .lg\:max-h-14 {
    max-height: 3.5rem !important;
  }

  .lg\:max-h-16 {
    max-height: 4rem !important;
  }

  .lg\:max-h-18 {
    max-height: 4.5rem !important;
  }

  .lg\:max-h-20 {
    max-height: 5rem !important;
  }

  .lg\:max-h-22 {
    max-height: 5.5rem !important;
  }

  .lg\:max-h-24 {
    max-height: 6rem !important;
  }

  .lg\:max-h-26 {
    max-height: 6.5rem !important;
  }

  .lg\:max-h-28 {
    max-height: 7rem !important;
  }

  .lg\:max-h-30 {
    max-height: 7.5rem !important;
  }

  .lg\:max-h-32 {
    max-height: 8rem !important;
  }

  .lg\:max-h-36 {
    max-height: 9rem !important;
  }

  .lg\:max-h-40 {
    max-height: 10rem !important;
  }

  .lg\:max-h-48 {
    max-height: 12rem !important;
  }

  .lg\:max-h-50 {
    max-height: 12.5rem !important;
  }

  .lg\:max-h-56 {
    max-height: 14rem !important;
  }

  .lg\:max-h-60 {
    max-height: 15rem !important;
  }

  .lg\:max-h-64 {
    max-height: 16rem !important;
  }

  .lg\:max-h-80 {
    max-height: 20rem !important;
  }

  .lg\:max-h-90 {
    max-height: 24rem !important;
  }

  .lg\:max-h-100 {
    max-height: 25rem !important;
  }

  .lg\:max-h-120 {
    max-height: 30rem !important;
  }

  .lg\:max-h-128 {
    max-height: 32rem !important;
  }

  .lg\:max-h-140 {
    max-height: 35rem !important;
  }

  .lg\:max-h-160 {
    max-height: 40rem !important;
  }

  .lg\:max-h-180 {
    max-height: 45rem !important;
  }

  .lg\:max-h-192 {
    max-height: 48rem !important;
  }

  .lg\:max-h-200 {
    max-height: 50rem !important;
  }

  .lg\:max-h-240 {
    max-height: 60rem !important;
  }

  .lg\:max-h-256 {
    max-height: 64rem !important;
  }

  .lg\:max-h-280 {
    max-height: 70rem !important;
  }

  .lg\:max-h-320 {
    max-height: 80rem !important;
  }

  .lg\:max-h-360 {
    max-height: 90rem !important;
  }

  .lg\:max-h-400 {
    max-height: 100rem !important;
  }

  .lg\:max-h-480 {
    max-height: 120rem !important;
  }

  .lg\:max-h-full {
    max-height: 100% !important;
  }

  .lg\:max-h-screen {
    max-height: 100vh !important;
  }

  .lg\:max-h-none {
    max-height: none !important;
  }

  .lg\:max-h-px {
    max-height: 1px !important;
  }

  .lg\:max-h-2px {
    max-height: 2px !important;
  }

  .lg\:max-h-1\/2 {
    max-height: 50% !important;
  }

  .lg\:max-h-1\/3 {
    max-height: 33.33333% !important;
  }

  .lg\:max-h-2\/3 {
    max-height: 66.66667% !important;
  }

  .lg\:max-h-1\/4 {
    max-height: 25% !important;
  }

  .lg\:max-h-2\/4 {
    max-height: 50% !important;
  }

  .lg\:max-h-3\/4 {
    max-height: 75% !important;
  }

  .lg\:max-h-1\/5 {
    max-height: 20% !important;
  }

  .lg\:max-h-2\/5 {
    max-height: 40% !important;
  }

  .lg\:max-h-3\/5 {
    max-height: 60% !important;
  }

  .lg\:max-h-4\/5 {
    max-height: 80% !important;
  }

  .lg\:max-h-1\/12 {
    max-height: 8.33333% !important;
  }

  .lg\:max-h-2\/12 {
    max-height: 16.66667% !important;
  }

  .lg\:max-h-3\/12 {
    max-height: 25% !important;
  }

  .lg\:max-h-4\/12 {
    max-height: 33.33333% !important;
  }

  .lg\:max-h-5\/12 {
    max-height: 41.66667% !important;
  }

  .lg\:max-h-6\/12 {
    max-height: 50% !important;
  }

  .lg\:max-h-7\/12 {
    max-height: 58.33333% !important;
  }

  .lg\:max-h-8\/12 {
    max-height: 66.66667% !important;
  }

  .lg\:max-h-9\/12 {
    max-height: 75% !important;
  }

  .lg\:max-h-10\/12 {
    max-height: 83.33333% !important;
  }

  .lg\:max-h-11\/12 {
    max-height: 91.66667% !important;
  }

  .lg\:max-w-0 {
    max-width: 0 !important;
  }

  .lg\:max-w-1 {
    max-width: 0.25rem !important;
  }

  .lg\:max-w-2 {
    max-width: 0.5rem !important;
  }

  .lg\:max-w-3 {
    max-width: 0.75rem !important;
  }

  .lg\:max-w-4 {
    max-width: 1rem !important;
  }

  .lg\:max-w-5 {
    max-width: 1.25rem !important;
  }

  .lg\:max-w-6 {
    max-width: 1.5rem !important;
  }

  .lg\:max-w-8 {
    max-width: 2rem !important;
  }

  .lg\:max-w-10 {
    max-width: 2.5rem !important;
  }

  .lg\:max-w-12 {
    max-width: 3rem !important;
  }

  .lg\:max-w-14 {
    max-width: 3.5rem !important;
  }

  .lg\:max-w-16 {
    max-width: 4rem !important;
  }

  .lg\:max-w-18 {
    max-width: 4.5rem !important;
  }

  .lg\:max-w-20 {
    max-width: 5rem !important;
  }

  .lg\:max-w-22 {
    max-width: 5.5rem !important;
  }

  .lg\:max-w-24 {
    max-width: 6rem !important;
  }

  .lg\:max-w-26 {
    max-width: 6.5rem !important;
  }

  .lg\:max-w-28 {
    max-width: 7rem !important;
  }

  .lg\:max-w-30 {
    max-width: 7.5rem !important;
  }

  .lg\:max-w-32 {
    max-width: 8rem !important;
  }

  .lg\:max-w-36 {
    max-width: 9rem !important;
  }

  .lg\:max-w-40 {
    max-width: 10rem !important;
  }

  .lg\:max-w-48 {
    max-width: 12rem !important;
  }

  .lg\:max-w-50 {
    max-width: 12.5rem !important;
  }

  .lg\:max-w-56 {
    max-width: 14rem !important;
  }

  .lg\:max-w-60 {
    max-width: 15rem !important;
  }

  .lg\:max-w-64 {
    max-width: 16rem !important;
  }

  .lg\:max-w-80 {
    max-width: 20rem !important;
  }

  .lg\:max-w-90 {
    max-width: 24rem !important;
  }

  .lg\:max-w-100 {
    max-width: 25rem !important;
  }

  .lg\:max-w-120 {
    max-width: 30rem !important;
  }

  .lg\:max-w-128 {
    max-width: 32rem !important;
  }

  .lg\:max-w-140 {
    max-width: 35rem !important;
  }

  .lg\:max-w-160 {
    max-width: 40rem !important;
  }

  .lg\:max-w-180 {
    max-width: 45rem !important;
  }

  .lg\:max-w-192 {
    max-width: 48rem !important;
  }

  .lg\:max-w-200 {
    max-width: 50rem !important;
  }

  .lg\:max-w-240 {
    max-width: 60rem !important;
  }

  .lg\:max-w-256 {
    max-width: 64rem !important;
  }

  .lg\:max-w-280 {
    max-width: 70rem !important;
  }

  .lg\:max-w-320 {
    max-width: 80rem !important;
  }

  .lg\:max-w-360 {
    max-width: 90rem !important;
  }

  .lg\:max-w-400 {
    max-width: 100rem !important;
  }

  .lg\:max-w-480 {
    max-width: 120rem !important;
  }

  .lg\:max-w-none {
    max-width: none !important;
  }

  .lg\:max-w-xs {
    max-width: 20rem !important;
  }

  .lg\:max-w-sm {
    max-width: 24rem !important;
  }

  .lg\:max-w-md {
    max-width: 28rem !important;
  }

  .lg\:max-w-lg {
    max-width: 32rem !important;
  }

  .lg\:max-w-xl {
    max-width: 36rem !important;
  }

  .lg\:max-w-2xl {
    max-width: 42rem !important;
  }

  .lg\:max-w-3xl {
    max-width: 48rem !important;
  }

  .lg\:max-w-4xl {
    max-width: 56rem !important;
  }

  .lg\:max-w-5xl {
    max-width: 64rem !important;
  }

  .lg\:max-w-6xl {
    max-width: 72rem !important;
  }

  .lg\:max-w-full {
    max-width: 100% !important;
  }

  .lg\:max-w-screen {
    max-width: 100vw !important;
  }

  .lg\:max-w-px {
    max-width: 1px !important;
  }

  .lg\:max-w-2px {
    max-width: 2px !important;
  }

  .lg\:max-w-1\/2 {
    max-width: 50% !important;
  }

  .lg\:max-w-1\/3 {
    max-width: 33.33333% !important;
  }

  .lg\:max-w-2\/3 {
    max-width: 66.66667% !important;
  }

  .lg\:max-w-1\/4 {
    max-width: 25% !important;
  }

  .lg\:max-w-2\/4 {
    max-width: 50% !important;
  }

  .lg\:max-w-3\/4 {
    max-width: 75% !important;
  }

  .lg\:max-w-1\/5 {
    max-width: 20% !important;
  }

  .lg\:max-w-2\/5 {
    max-width: 40% !important;
  }

  .lg\:max-w-3\/5 {
    max-width: 60% !important;
  }

  .lg\:max-w-4\/5 {
    max-width: 80% !important;
  }

  .lg\:max-w-1\/12 {
    max-width: 8.33333% !important;
  }

  .lg\:max-w-2\/12 {
    max-width: 16.66667% !important;
  }

  .lg\:max-w-3\/12 {
    max-width: 25% !important;
  }

  .lg\:max-w-4\/12 {
    max-width: 33.33333% !important;
  }

  .lg\:max-w-5\/12 {
    max-width: 41.66667% !important;
  }

  .lg\:max-w-6\/12 {
    max-width: 50% !important;
  }

  .lg\:max-w-7\/12 {
    max-width: 58.33333% !important;
  }

  .lg\:max-w-8\/12 {
    max-width: 66.66667% !important;
  }

  .lg\:max-w-9\/12 {
    max-width: 75% !important;
  }

  .lg\:max-w-10\/12 {
    max-width: 83.33333% !important;
  }

  .lg\:max-w-11\/12 {
    max-width: 91.66667% !important;
  }

  .lg\:min-h-0 {
    min-height: 0 !important;
  }

  .lg\:min-h-1 {
    min-height: 0.25rem !important;
  }

  .lg\:min-h-2 {
    min-height: 0.5rem !important;
  }

  .lg\:min-h-3 {
    min-height: 0.75rem !important;
  }

  .lg\:min-h-4 {
    min-height: 1rem !important;
  }

  .lg\:min-h-5 {
    min-height: 1.25rem !important;
  }

  .lg\:min-h-6 {
    min-height: 1.5rem !important;
  }

  .lg\:min-h-8 {
    min-height: 2rem !important;
  }

  .lg\:min-h-10 {
    min-height: 2.5rem !important;
  }

  .lg\:min-h-12 {
    min-height: 3rem !important;
  }

  .lg\:min-h-14 {
    min-height: 3.5rem !important;
  }

  .lg\:min-h-16 {
    min-height: 4rem !important;
  }

  .lg\:min-h-18 {
    min-height: 4.5rem !important;
  }

  .lg\:min-h-20 {
    min-height: 5rem !important;
  }

  .lg\:min-h-22 {
    min-height: 5.5rem !important;
  }

  .lg\:min-h-24 {
    min-height: 6rem !important;
  }

  .lg\:min-h-26 {
    min-height: 6.5rem !important;
  }

  .lg\:min-h-28 {
    min-height: 7rem !important;
  }

  .lg\:min-h-30 {
    min-height: 7.5rem !important;
  }

  .lg\:min-h-32 {
    min-height: 8rem !important;
  }

  .lg\:min-h-36 {
    min-height: 9rem !important;
  }

  .lg\:min-h-40 {
    min-height: 10rem !important;
  }

  .lg\:min-h-48 {
    min-height: 12rem !important;
  }

  .lg\:min-h-50 {
    min-height: 12.5rem !important;
  }

  .lg\:min-h-56 {
    min-height: 14rem !important;
  }

  .lg\:min-h-60 {
    min-height: 15rem !important;
  }

  .lg\:min-h-64 {
    min-height: 16rem !important;
  }

  .lg\:min-h-80 {
    min-height: 20rem !important;
  }

  .lg\:min-h-90 {
    min-height: 24rem !important;
  }

  .lg\:min-h-100 {
    min-height: 25rem !important;
  }

  .lg\:min-h-120 {
    min-height: 30rem !important;
  }

  .lg\:min-h-128 {
    min-height: 32rem !important;
  }

  .lg\:min-h-140 {
    min-height: 35rem !important;
  }

  .lg\:min-h-160 {
    min-height: 40rem !important;
  }

  .lg\:min-h-180 {
    min-height: 45rem !important;
  }

  .lg\:min-h-192 {
    min-height: 48rem !important;
  }

  .lg\:min-h-200 {
    min-height: 50rem !important;
  }

  .lg\:min-h-240 {
    min-height: 60rem !important;
  }

  .lg\:min-h-256 {
    min-height: 64rem !important;
  }

  .lg\:min-h-280 {
    min-height: 70rem !important;
  }

  .lg\:min-h-320 {
    min-height: 80rem !important;
  }

  .lg\:min-h-360 {
    min-height: 90rem !important;
  }

  .lg\:min-h-400 {
    min-height: 100rem !important;
  }

  .lg\:min-h-480 {
    min-height: 120rem !important;
  }

  .lg\:min-h-full {
    min-height: 100% !important;
  }

  .lg\:min-h-screen {
    min-height: 100vh !important;
  }

  .lg\:min-h-px {
    min-height: 1px !important;
  }

  .lg\:min-h-2px {
    min-height: 2px !important;
  }

  .lg\:min-h-1\/2 {
    min-height: 50% !important;
  }

  .lg\:min-h-1\/3 {
    min-height: 33.33333% !important;
  }

  .lg\:min-h-2\/3 {
    min-height: 66.66667% !important;
  }

  .lg\:min-h-1\/4 {
    min-height: 25% !important;
  }

  .lg\:min-h-2\/4 {
    min-height: 50% !important;
  }

  .lg\:min-h-3\/4 {
    min-height: 75% !important;
  }

  .lg\:min-h-1\/5 {
    min-height: 20% !important;
  }

  .lg\:min-h-2\/5 {
    min-height: 40% !important;
  }

  .lg\:min-h-3\/5 {
    min-height: 60% !important;
  }

  .lg\:min-h-4\/5 {
    min-height: 80% !important;
  }

  .lg\:min-h-1\/12 {
    min-height: 8.33333% !important;
  }

  .lg\:min-h-2\/12 {
    min-height: 16.66667% !important;
  }

  .lg\:min-h-3\/12 {
    min-height: 25% !important;
  }

  .lg\:min-h-4\/12 {
    min-height: 33.33333% !important;
  }

  .lg\:min-h-5\/12 {
    min-height: 41.66667% !important;
  }

  .lg\:min-h-6\/12 {
    min-height: 50% !important;
  }

  .lg\:min-h-7\/12 {
    min-height: 58.33333% !important;
  }

  .lg\:min-h-8\/12 {
    min-height: 66.66667% !important;
  }

  .lg\:min-h-9\/12 {
    min-height: 75% !important;
  }

  .lg\:min-h-10\/12 {
    min-height: 83.33333% !important;
  }

  .lg\:min-h-11\/12 {
    min-height: 91.66667% !important;
  }

  .lg\:min-w-0 {
    min-width: 0 !important;
  }

  .lg\:min-w-1 {
    min-width: 0.25rem !important;
  }

  .lg\:min-w-2 {
    min-width: 0.5rem !important;
  }

  .lg\:min-w-3 {
    min-width: 0.75rem !important;
  }

  .lg\:min-w-4 {
    min-width: 1rem !important;
  }

  .lg\:min-w-5 {
    min-width: 1.25rem !important;
  }

  .lg\:min-w-6 {
    min-width: 1.5rem !important;
  }

  .lg\:min-w-8 {
    min-width: 2rem !important;
  }

  .lg\:min-w-10 {
    min-width: 2.5rem !important;
  }

  .lg\:min-w-12 {
    min-width: 3rem !important;
  }

  .lg\:min-w-14 {
    min-width: 3.5rem !important;
  }

  .lg\:min-w-16 {
    min-width: 4rem !important;
  }

  .lg\:min-w-18 {
    min-width: 4.5rem !important;
  }

  .lg\:min-w-20 {
    min-width: 5rem !important;
  }

  .lg\:min-w-22 {
    min-width: 5.5rem !important;
  }

  .lg\:min-w-24 {
    min-width: 6rem !important;
  }

  .lg\:min-w-26 {
    min-width: 6.5rem !important;
  }

  .lg\:min-w-28 {
    min-width: 7rem !important;
  }

  .lg\:min-w-30 {
    min-width: 7.5rem !important;
  }

  .lg\:min-w-32 {
    min-width: 8rem !important;
  }

  .lg\:min-w-36 {
    min-width: 9rem !important;
  }

  .lg\:min-w-40 {
    min-width: 10rem !important;
  }

  .lg\:min-w-48 {
    min-width: 12rem !important;
  }

  .lg\:min-w-50 {
    min-width: 12.5rem !important;
  }

  .lg\:min-w-56 {
    min-width: 14rem !important;
  }

  .lg\:min-w-60 {
    min-width: 15rem !important;
  }

  .lg\:min-w-64 {
    min-width: 16rem !important;
  }

  .lg\:min-w-80 {
    min-width: 20rem !important;
  }

  .lg\:min-w-90 {
    min-width: 24rem !important;
  }

  .lg\:min-w-100 {
    min-width: 25rem !important;
  }

  .lg\:min-w-120 {
    min-width: 30rem !important;
  }

  .lg\:min-w-128 {
    min-width: 32rem !important;
  }

  .lg\:min-w-140 {
    min-width: 35rem !important;
  }

  .lg\:min-w-160 {
    min-width: 40rem !important;
  }

  .lg\:min-w-180 {
    min-width: 45rem !important;
  }

  .lg\:min-w-192 {
    min-width: 48rem !important;
  }

  .lg\:min-w-200 {
    min-width: 50rem !important;
  }

  .lg\:min-w-240 {
    min-width: 60rem !important;
  }

  .lg\:min-w-256 {
    min-width: 64rem !important;
  }

  .lg\:min-w-280 {
    min-width: 70rem !important;
  }

  .lg\:min-w-320 {
    min-width: 80rem !important;
  }

  .lg\:min-w-360 {
    min-width: 90rem !important;
  }

  .lg\:min-w-400 {
    min-width: 100rem !important;
  }

  .lg\:min-w-480 {
    min-width: 120rem !important;
  }

  .lg\:min-w-full {
    min-width: 100% !important;
  }

  .lg\:min-w-screen {
    min-width: 100vw !important;
  }

  .lg\:min-w-px {
    min-width: 1px !important;
  }

  .lg\:min-w-2px {
    min-width: 2px !important;
  }

  .lg\:min-w-1\/2 {
    min-width: 50% !important;
  }

  .lg\:min-w-1\/3 {
    min-width: 33.33333% !important;
  }

  .lg\:min-w-2\/3 {
    min-width: 66.66667% !important;
  }

  .lg\:min-w-1\/4 {
    min-width: 25% !important;
  }

  .lg\:min-w-2\/4 {
    min-width: 50% !important;
  }

  .lg\:min-w-3\/4 {
    min-width: 75% !important;
  }

  .lg\:min-w-1\/5 {
    min-width: 20% !important;
  }

  .lg\:min-w-2\/5 {
    min-width: 40% !important;
  }

  .lg\:min-w-3\/5 {
    min-width: 60% !important;
  }

  .lg\:min-w-4\/5 {
    min-width: 80% !important;
  }

  .lg\:min-w-1\/12 {
    min-width: 8.33333% !important;
  }

  .lg\:min-w-2\/12 {
    min-width: 16.66667% !important;
  }

  .lg\:min-w-3\/12 {
    min-width: 25% !important;
  }

  .lg\:min-w-4\/12 {
    min-width: 33.33333% !important;
  }

  .lg\:min-w-5\/12 {
    min-width: 41.66667% !important;
  }

  .lg\:min-w-6\/12 {
    min-width: 50% !important;
  }

  .lg\:min-w-7\/12 {
    min-width: 58.33333% !important;
  }

  .lg\:min-w-8\/12 {
    min-width: 66.66667% !important;
  }

  .lg\:min-w-9\/12 {
    min-width: 75% !important;
  }

  .lg\:min-w-10\/12 {
    min-width: 83.33333% !important;
  }

  .lg\:min-w-11\/12 {
    min-width: 91.66667% !important;
  }

  .lg\:object-contain {
    -o-object-fit: contain !important;
       object-fit: contain !important;
  }

  .lg\:object-cover {
    -o-object-fit: cover !important;
       object-fit: cover !important;
  }

  .lg\:object-fill {
    -o-object-fit: fill !important;
       object-fit: fill !important;
  }

  .lg\:object-none {
    -o-object-fit: none !important;
       object-fit: none !important;
  }

  .lg\:object-scale-down {
    -o-object-fit: scale-down !important;
       object-fit: scale-down !important;
  }

  .lg\:object-bottom {
    -o-object-position: bottom !important;
       object-position: bottom !important;
  }

  .lg\:object-center {
    -o-object-position: center !important;
       object-position: center !important;
  }

  .lg\:object-left {
    -o-object-position: left !important;
       object-position: left !important;
  }

  .lg\:object-left-bottom {
    -o-object-position: left bottom !important;
       object-position: left bottom !important;
  }

  .lg\:object-left-top {
    -o-object-position: left top !important;
       object-position: left top !important;
  }

  .lg\:object-right {
    -o-object-position: right !important;
       object-position: right !important;
  }

  .lg\:object-right-bottom {
    -o-object-position: right bottom !important;
       object-position: right bottom !important;
  }

  .lg\:object-right-top {
    -o-object-position: right top !important;
       object-position: right top !important;
  }

  .lg\:object-top {
    -o-object-position: top !important;
       object-position: top !important;
  }

  .lg\:opacity-0 {
    opacity: 0 !important;
  }

  .lg\:opacity-12 {
    opacity: 0.12 !important;
  }

  .lg\:opacity-25 {
    opacity: 0.25 !important;
  }

  .lg\:opacity-38 {
    opacity: 0.38 !important;
  }

  .lg\:opacity-50 {
    opacity: 0.5 !important;
  }

  .lg\:opacity-54 {
    opacity: 0.54 !important;
  }

  .lg\:opacity-70 {
    opacity: 0.70 !important;
  }

  .lg\:opacity-75 {
    opacity: 0.75 !important;
  }

  .lg\:opacity-84 {
    opacity: 0.84 !important;
  }

  .lg\:opacity-100 {
    opacity: 1 !important;
  }

  .lg\:hover\:opacity-0:hover {
    opacity: 0 !important;
  }

  .lg\:hover\:opacity-12:hover {
    opacity: 0.12 !important;
  }

  .lg\:hover\:opacity-25:hover {
    opacity: 0.25 !important;
  }

  .lg\:hover\:opacity-38:hover {
    opacity: 0.38 !important;
  }

  .lg\:hover\:opacity-50:hover {
    opacity: 0.5 !important;
  }

  .lg\:hover\:opacity-54:hover {
    opacity: 0.54 !important;
  }

  .lg\:hover\:opacity-70:hover {
    opacity: 0.70 !important;
  }

  .lg\:hover\:opacity-75:hover {
    opacity: 0.75 !important;
  }

  .lg\:hover\:opacity-84:hover {
    opacity: 0.84 !important;
  }

  .lg\:hover\:opacity-100:hover {
    opacity: 1 !important;
  }

  .lg\:focus\:opacity-0:focus {
    opacity: 0 !important;
  }

  .lg\:focus\:opacity-12:focus {
    opacity: 0.12 !important;
  }

  .lg\:focus\:opacity-25:focus {
    opacity: 0.25 !important;
  }

  .lg\:focus\:opacity-38:focus {
    opacity: 0.38 !important;
  }

  .lg\:focus\:opacity-50:focus {
    opacity: 0.5 !important;
  }

  .lg\:focus\:opacity-54:focus {
    opacity: 0.54 !important;
  }

  .lg\:focus\:opacity-70:focus {
    opacity: 0.70 !important;
  }

  .lg\:focus\:opacity-75:focus {
    opacity: 0.75 !important;
  }

  .lg\:focus\:opacity-84:focus {
    opacity: 0.84 !important;
  }

  .lg\:focus\:opacity-100:focus {
    opacity: 1 !important;
  }

  .lg\:outline-none {
    outline: 0 !important;
  }

  .lg\:focus\:outline-none:focus {
    outline: 0 !important;
  }

  .lg\:overflow-auto {
    overflow: auto !important;
  }

  .lg\:overflow-hidden {
    overflow: hidden !important;
  }

  .lg\:overflow-visible {
    overflow: visible !important;
  }

  .lg\:overflow-scroll {
    overflow: scroll !important;
  }

  .lg\:overflow-x-auto {
    overflow-x: auto !important;
  }

  .lg\:overflow-y-auto {
    overflow-y: auto !important;
  }

  .lg\:overflow-x-hidden {
    overflow-x: hidden !important;
  }

  .lg\:overflow-y-hidden {
    overflow-y: hidden !important;
  }

  .lg\:overflow-x-visible {
    overflow-x: visible !important;
  }

  .lg\:overflow-y-visible {
    overflow-y: visible !important;
  }

  .lg\:overflow-x-scroll {
    overflow-x: scroll !important;
  }

  .lg\:overflow-y-scroll {
    overflow-y: scroll !important;
  }

  .lg\:scrolling-touch {
    -webkit-overflow-scrolling: touch !important;
  }

  .lg\:scrolling-auto {
    -webkit-overflow-scrolling: auto !important;
  }

  .lg\:p-0 {
    padding: 0 !important;
  }

  .lg\:p-1 {
    padding: 0.25rem !important;
  }

  .lg\:p-2 {
    padding: 0.5rem !important;
  }

  .lg\:p-3 {
    padding: 0.75rem !important;
  }

  .lg\:p-4 {
    padding: 1rem !important;
  }

  .lg\:p-5 {
    padding: 1.25rem !important;
  }

  .lg\:p-6 {
    padding: 1.5rem !important;
  }

  .lg\:p-8 {
    padding: 2rem !important;
  }

  .lg\:p-10 {
    padding: 2.5rem !important;
  }

  .lg\:p-12 {
    padding: 3rem !important;
  }

  .lg\:p-14 {
    padding: 3.5rem !important;
  }

  .lg\:p-16 {
    padding: 4rem !important;
  }

  .lg\:p-18 {
    padding: 4.5rem !important;
  }

  .lg\:p-20 {
    padding: 5rem !important;
  }

  .lg\:p-22 {
    padding: 5.5rem !important;
  }

  .lg\:p-24 {
    padding: 6rem !important;
  }

  .lg\:p-26 {
    padding: 6.5rem !important;
  }

  .lg\:p-28 {
    padding: 7rem !important;
  }

  .lg\:p-30 {
    padding: 7.5rem !important;
  }

  .lg\:p-32 {
    padding: 8rem !important;
  }

  .lg\:p-36 {
    padding: 9rem !important;
  }

  .lg\:p-40 {
    padding: 10rem !important;
  }

  .lg\:p-48 {
    padding: 12rem !important;
  }

  .lg\:p-56 {
    padding: 14rem !important;
  }

  .lg\:p-64 {
    padding: 16rem !important;
  }

  .lg\:p-px {
    padding: 1px !important;
  }

  .lg\:p-2px {
    padding: 2px !important;
  }

  .lg\:py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .lg\:px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .lg\:py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .lg\:px-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }

  .lg\:py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .lg\:px-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .lg\:py-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .lg\:px-3 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }

  .lg\:py-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .lg\:px-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .lg\:py-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .lg\:px-5 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }

  .lg\:py-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .lg\:px-6 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .lg\:py-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .lg\:px-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .lg\:py-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .lg\:px-10 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .lg\:py-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .lg\:px-12 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .lg\:py-14 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .lg\:px-14 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }

  .lg\:py-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .lg\:px-16 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .lg\:py-18 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .lg\:px-18 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }

  .lg\:py-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .lg\:px-20 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .lg\:py-22 {
    padding-top: 5.5rem !important;
    padding-bottom: 5.5rem !important;
  }

  .lg\:px-22 {
    padding-left: 5.5rem !important;
    padding-right: 5.5rem !important;
  }

  .lg\:py-24 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .lg\:px-24 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }

  .lg\:py-26 {
    padding-top: 6.5rem !important;
    padding-bottom: 6.5rem !important;
  }

  .lg\:px-26 {
    padding-left: 6.5rem !important;
    padding-right: 6.5rem !important;
  }

  .lg\:py-28 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }

  .lg\:px-28 {
    padding-left: 7rem !important;
    padding-right: 7rem !important;
  }

  .lg\:py-30 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }

  .lg\:px-30 {
    padding-left: 7.5rem !important;
    padding-right: 7.5rem !important;
  }

  .lg\:py-32 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .lg\:px-32 {
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }

  .lg\:py-36 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }

  .lg\:px-36 {
    padding-left: 9rem !important;
    padding-right: 9rem !important;
  }

  .lg\:py-40 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .lg\:px-40 {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }

  .lg\:py-48 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }

  .lg\:px-48 {
    padding-left: 12rem !important;
    padding-right: 12rem !important;
  }

  .lg\:py-56 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }

  .lg\:px-56 {
    padding-left: 14rem !important;
    padding-right: 14rem !important;
  }

  .lg\:py-64 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }

  .lg\:px-64 {
    padding-left: 16rem !important;
    padding-right: 16rem !important;
  }

  .lg\:py-px {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }

  .lg\:px-px {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }

  .lg\:py-2px {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }

  .lg\:px-2px {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }

  .lg\:pt-0 {
    padding-top: 0 !important;
  }

  .lg\:pr-0 {
    padding-right: 0 !important;
  }

  .lg\:pb-0 {
    padding-bottom: 0 !important;
  }

  .lg\:pl-0 {
    padding-left: 0 !important;
  }

  .lg\:pt-1 {
    padding-top: 0.25rem !important;
  }

  .lg\:pr-1 {
    padding-right: 0.25rem !important;
  }

  .lg\:pb-1 {
    padding-bottom: 0.25rem !important;
  }

  .lg\:pl-1 {
    padding-left: 0.25rem !important;
  }

  .lg\:pt-2 {
    padding-top: 0.5rem !important;
  }

  .lg\:pr-2 {
    padding-right: 0.5rem !important;
  }

  .lg\:pb-2 {
    padding-bottom: 0.5rem !important;
  }

  .lg\:pl-2 {
    padding-left: 0.5rem !important;
  }

  .lg\:pt-3 {
    padding-top: 0.75rem !important;
  }

  .lg\:pr-3 {
    padding-right: 0.75rem !important;
  }

  .lg\:pb-3 {
    padding-bottom: 0.75rem !important;
  }

  .lg\:pl-3 {
    padding-left: 0.75rem !important;
  }

  .lg\:pt-4 {
    padding-top: 1rem !important;
  }

  .lg\:pr-4 {
    padding-right: 1rem !important;
  }

  .lg\:pb-4 {
    padding-bottom: 1rem !important;
  }

  .lg\:pl-4 {
    padding-left: 1rem !important;
  }

  .lg\:pt-5 {
    padding-top: 1.25rem !important;
  }

  .lg\:pr-5 {
    padding-right: 1.25rem !important;
  }

  .lg\:pb-5 {
    padding-bottom: 1.25rem !important;
  }

  .lg\:pl-5 {
    padding-left: 1.25rem !important;
  }

  .lg\:pt-6 {
    padding-top: 1.5rem !important;
  }

  .lg\:pr-6 {
    padding-right: 1.5rem !important;
  }

  .lg\:pb-6 {
    padding-bottom: 1.5rem !important;
  }

  .lg\:pl-6 {
    padding-left: 1.5rem !important;
  }

  .lg\:pt-8 {
    padding-top: 2rem !important;
  }

  .lg\:pr-8 {
    padding-right: 2rem !important;
  }

  .lg\:pb-8 {
    padding-bottom: 2rem !important;
  }

  .lg\:pl-8 {
    padding-left: 2rem !important;
  }

  .lg\:pt-10 {
    padding-top: 2.5rem !important;
  }

  .lg\:pr-10 {
    padding-right: 2.5rem !important;
  }

  .lg\:pb-10 {
    padding-bottom: 2.5rem !important;
  }

  .lg\:pl-10 {
    padding-left: 2.5rem !important;
  }

  .lg\:pt-12 {
    padding-top: 3rem !important;
  }

  .lg\:pr-12 {
    padding-right: 3rem !important;
  }

  .lg\:pb-12 {
    padding-bottom: 3rem !important;
  }

  .lg\:pl-12 {
    padding-left: 3rem !important;
  }

  .lg\:pt-14 {
    padding-top: 3.5rem !important;
  }

  .lg\:pr-14 {
    padding-right: 3.5rem !important;
  }

  .lg\:pb-14 {
    padding-bottom: 3.5rem !important;
  }

  .lg\:pl-14 {
    padding-left: 3.5rem !important;
  }

  .lg\:pt-16 {
    padding-top: 4rem !important;
  }

  .lg\:pr-16 {
    padding-right: 4rem !important;
  }

  .lg\:pb-16 {
    padding-bottom: 4rem !important;
  }

  .lg\:pl-16 {
    padding-left: 4rem !important;
  }

  .lg\:pt-18 {
    padding-top: 4.5rem !important;
  }

  .lg\:pr-18 {
    padding-right: 4.5rem !important;
  }

  .lg\:pb-18 {
    padding-bottom: 4.5rem !important;
  }

  .lg\:pl-18 {
    padding-left: 4.5rem !important;
  }

  .lg\:pt-20 {
    padding-top: 5rem !important;
  }

  .lg\:pr-20 {
    padding-right: 5rem !important;
  }

  .lg\:pb-20 {
    padding-bottom: 5rem !important;
  }

  .lg\:pl-20 {
    padding-left: 5rem !important;
  }

  .lg\:pt-22 {
    padding-top: 5.5rem !important;
  }

  .lg\:pr-22 {
    padding-right: 5.5rem !important;
  }

  .lg\:pb-22 {
    padding-bottom: 5.5rem !important;
  }

  .lg\:pl-22 {
    padding-left: 5.5rem !important;
  }

  .lg\:pt-24 {
    padding-top: 6rem !important;
  }

  .lg\:pr-24 {
    padding-right: 6rem !important;
  }

  .lg\:pb-24 {
    padding-bottom: 6rem !important;
  }

  .lg\:pl-24 {
    padding-left: 6rem !important;
  }

  .lg\:pt-26 {
    padding-top: 6.5rem !important;
  }

  .lg\:pr-26 {
    padding-right: 6.5rem !important;
  }

  .lg\:pb-26 {
    padding-bottom: 6.5rem !important;
  }

  .lg\:pl-26 {
    padding-left: 6.5rem !important;
  }

  .lg\:pt-28 {
    padding-top: 7rem !important;
  }

  .lg\:pr-28 {
    padding-right: 7rem !important;
  }

  .lg\:pb-28 {
    padding-bottom: 7rem !important;
  }

  .lg\:pl-28 {
    padding-left: 7rem !important;
  }

  .lg\:pt-30 {
    padding-top: 7.5rem !important;
  }

  .lg\:pr-30 {
    padding-right: 7.5rem !important;
  }

  .lg\:pb-30 {
    padding-bottom: 7.5rem !important;
  }

  .lg\:pl-30 {
    padding-left: 7.5rem !important;
  }

  .lg\:pt-32 {
    padding-top: 8rem !important;
  }

  .lg\:pr-32 {
    padding-right: 8rem !important;
  }

  .lg\:pb-32 {
    padding-bottom: 8rem !important;
  }

  .lg\:pl-32 {
    padding-left: 8rem !important;
  }

  .lg\:pt-36 {
    padding-top: 9rem !important;
  }

  .lg\:pr-36 {
    padding-right: 9rem !important;
  }

  .lg\:pb-36 {
    padding-bottom: 9rem !important;
  }

  .lg\:pl-36 {
    padding-left: 9rem !important;
  }

  .lg\:pt-40 {
    padding-top: 10rem !important;
  }

  .lg\:pr-40 {
    padding-right: 10rem !important;
  }

  .lg\:pb-40 {
    padding-bottom: 10rem !important;
  }

  .lg\:pl-40 {
    padding-left: 10rem !important;
  }

  .lg\:pt-48 {
    padding-top: 12rem !important;
  }

  .lg\:pr-48 {
    padding-right: 12rem !important;
  }

  .lg\:pb-48 {
    padding-bottom: 12rem !important;
  }

  .lg\:pl-48 {
    padding-left: 12rem !important;
  }

  .lg\:pt-56 {
    padding-top: 14rem !important;
  }

  .lg\:pr-56 {
    padding-right: 14rem !important;
  }

  .lg\:pb-56 {
    padding-bottom: 14rem !important;
  }

  .lg\:pl-56 {
    padding-left: 14rem !important;
  }

  .lg\:pt-64 {
    padding-top: 16rem !important;
  }

  .lg\:pr-64 {
    padding-right: 16rem !important;
  }

  .lg\:pb-64 {
    padding-bottom: 16rem !important;
  }

  .lg\:pl-64 {
    padding-left: 16rem !important;
  }

  .lg\:pt-px {
    padding-top: 1px !important;
  }

  .lg\:pr-px {
    padding-right: 1px !important;
  }

  .lg\:pb-px {
    padding-bottom: 1px !important;
  }

  .lg\:pl-px {
    padding-left: 1px !important;
  }

  .lg\:pt-2px {
    padding-top: 2px !important;
  }

  .lg\:pr-2px {
    padding-right: 2px !important;
  }

  .lg\:pb-2px {
    padding-bottom: 2px !important;
  }

  .lg\:pl-2px {
    padding-left: 2px !important;
  }

  .lg\:placeholder-opacity-0::-webkit-input-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .lg\:placeholder-opacity-0::-moz-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .lg\:placeholder-opacity-0::-ms-input-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .lg\:placeholder-opacity-0::placeholder {
    --placeholder-opacity: 0 !important;
  }

  .lg\:placeholder-opacity-12::-webkit-input-placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .lg\:placeholder-opacity-12::-moz-placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .lg\:placeholder-opacity-12::-ms-input-placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .lg\:placeholder-opacity-12::placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .lg\:placeholder-opacity-25::-webkit-input-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .lg\:placeholder-opacity-25::-moz-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .lg\:placeholder-opacity-25::-ms-input-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .lg\:placeholder-opacity-25::placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .lg\:placeholder-opacity-38::-webkit-input-placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .lg\:placeholder-opacity-38::-moz-placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .lg\:placeholder-opacity-38::-ms-input-placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .lg\:placeholder-opacity-38::placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .lg\:placeholder-opacity-50::-webkit-input-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .lg\:placeholder-opacity-50::-moz-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .lg\:placeholder-opacity-50::-ms-input-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .lg\:placeholder-opacity-50::placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .lg\:placeholder-opacity-54::-webkit-input-placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .lg\:placeholder-opacity-54::-moz-placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .lg\:placeholder-opacity-54::-ms-input-placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .lg\:placeholder-opacity-54::placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .lg\:placeholder-opacity-70::-webkit-input-placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .lg\:placeholder-opacity-70::-moz-placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .lg\:placeholder-opacity-70::-ms-input-placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .lg\:placeholder-opacity-70::placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .lg\:placeholder-opacity-75::-webkit-input-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .lg\:placeholder-opacity-75::-moz-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .lg\:placeholder-opacity-75::-ms-input-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .lg\:placeholder-opacity-75::placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .lg\:placeholder-opacity-84::-webkit-input-placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .lg\:placeholder-opacity-84::-moz-placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .lg\:placeholder-opacity-84::-ms-input-placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .lg\:placeholder-opacity-84::placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .lg\:placeholder-opacity-100::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .lg\:placeholder-opacity-100::-moz-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .lg\:placeholder-opacity-100::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .lg\:placeholder-opacity-100::placeholder {
    --placeholder-opacity: 1 !important;
  }

  .lg\:focus\:placeholder-opacity-0:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .lg\:focus\:placeholder-opacity-0:focus::-moz-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .lg\:focus\:placeholder-opacity-0:focus::-ms-input-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .lg\:focus\:placeholder-opacity-0:focus::placeholder {
    --placeholder-opacity: 0 !important;
  }

  .lg\:focus\:placeholder-opacity-12:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .lg\:focus\:placeholder-opacity-12:focus::-moz-placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .lg\:focus\:placeholder-opacity-12:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .lg\:focus\:placeholder-opacity-12:focus::placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .lg\:focus\:placeholder-opacity-25:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .lg\:focus\:placeholder-opacity-25:focus::-moz-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .lg\:focus\:placeholder-opacity-25:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .lg\:focus\:placeholder-opacity-25:focus::placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .lg\:focus\:placeholder-opacity-38:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .lg\:focus\:placeholder-opacity-38:focus::-moz-placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .lg\:focus\:placeholder-opacity-38:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .lg\:focus\:placeholder-opacity-38:focus::placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .lg\:focus\:placeholder-opacity-50:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .lg\:focus\:placeholder-opacity-50:focus::-moz-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .lg\:focus\:placeholder-opacity-50:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .lg\:focus\:placeholder-opacity-50:focus::placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .lg\:focus\:placeholder-opacity-54:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .lg\:focus\:placeholder-opacity-54:focus::-moz-placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .lg\:focus\:placeholder-opacity-54:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .lg\:focus\:placeholder-opacity-54:focus::placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .lg\:focus\:placeholder-opacity-70:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .lg\:focus\:placeholder-opacity-70:focus::-moz-placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .lg\:focus\:placeholder-opacity-70:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .lg\:focus\:placeholder-opacity-70:focus::placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .lg\:focus\:placeholder-opacity-75:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .lg\:focus\:placeholder-opacity-75:focus::-moz-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .lg\:focus\:placeholder-opacity-75:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .lg\:focus\:placeholder-opacity-75:focus::placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .lg\:focus\:placeholder-opacity-84:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .lg\:focus\:placeholder-opacity-84:focus::-moz-placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .lg\:focus\:placeholder-opacity-84:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .lg\:focus\:placeholder-opacity-84:focus::placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .lg\:focus\:placeholder-opacity-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .lg\:focus\:placeholder-opacity-100:focus::-moz-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .lg\:focus\:placeholder-opacity-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .lg\:focus\:placeholder-opacity-100:focus::placeholder {
    --placeholder-opacity: 1 !important;
  }

  .lg\:pointer-events-none {
    pointer-events: none !important;
  }

  .lg\:pointer-events-auto {
    pointer-events: auto !important;
  }

  .lg\:static {
    position: static !important;
  }

  .lg\:fixed {
    position: fixed !important;
  }

  .lg\:absolute {
    position: absolute !important;
  }

  .lg\:relative {
    position: relative !important;
  }

  .lg\:sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }

  .lg\:inset-0 {
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
  }

  .lg\:inset-auto {
    top: auto !important;
    right: auto !important;
    bottom: auto !important;
    left: auto !important;
  }

  .lg\:inset-y-0 {
    top: 0 !important;
    bottom: 0 !important;
  }

  .lg\:inset-x-0 {
    right: 0 !important;
    left: 0 !important;
  }

  .lg\:inset-y-auto {
    top: auto !important;
    bottom: auto !important;
  }

  .lg\:inset-x-auto {
    right: auto !important;
    left: auto !important;
  }

  .lg\:top-0 {
    top: 0 !important;
  }

  .lg\:right-0 {
    right: 0 !important;
  }

  .lg\:bottom-0 {
    bottom: 0 !important;
  }

  .lg\:left-0 {
    left: 0 !important;
  }

  .lg\:top-auto {
    top: auto !important;
  }

  .lg\:right-auto {
    right: auto !important;
  }

  .lg\:bottom-auto {
    bottom: auto !important;
  }

  .lg\:left-auto {
    left: auto !important;
  }

  .lg\:shadow-xs {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05) !important;
  }

  .lg\:shadow-sm {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  }

  .lg\:shadow {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .lg\:shadow-md {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  }

  .lg\:shadow-lg {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  }

  .lg\:shadow-xl {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
  }

  .lg\:shadow-2xl {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
  }

  .lg\:shadow-inner {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .lg\:ring {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }

  .lg\:shadow-none {
    box-shadow: none !important;
  }

  .lg\:shadow-solid {
    box-shadow: 0 0 0 2px currentColor !important;
  }

  .lg\:hover\:shadow-xs:hover {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05) !important;
  }

  .lg\:hover\:shadow-sm:hover {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  }

  .lg\:hover\:shadow:hover {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .lg\:hover\:shadow-md:hover {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  }

  .lg\:hover\:shadow-lg:hover {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  }

  .lg\:hover\:shadow-xl:hover {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
  }

  .lg\:hover\:shadow-2xl:hover {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
  }

  .lg\:hover\:shadow-inner:hover {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .lg\:hover\:ring:hover {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }

  .lg\:hover\:shadow-none:hover {
    box-shadow: none !important;
  }

  .lg\:hover\:shadow-solid:hover {
    box-shadow: 0 0 0 2px currentColor !important;
  }

  .lg\:focus\:shadow-xs:focus {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05) !important;
  }

  .lg\:focus\:shadow-sm:focus {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  }

  .lg\:focus\:shadow:focus {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .lg\:focus\:shadow-md:focus {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  }

  .lg\:focus\:shadow-lg:focus {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  }

  .lg\:focus\:shadow-xl:focus {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
  }

  .lg\:focus\:shadow-2xl:focus {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
  }

  .lg\:focus\:shadow-inner:focus {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .lg\:focus\:ring:focus {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }

  .lg\:focus\:shadow-none:focus {
    box-shadow: none !important;
  }

  .lg\:focus\:shadow-solid:focus {
    box-shadow: 0 0 0 2px currentColor !important;
  }

  .lg\:fill-current {
    fill: currentColor !important;
  }

  .lg\:stroke-current {
    stroke: currentColor !important;
  }

  .lg\:stroke-0 {
    stroke-width: 0 !important;
  }

  .lg\:stroke-1 {
    stroke-width: 1 !important;
  }

  .lg\:stroke-2 {
    stroke-width: 2 !important;
  }

  .lg\:table-auto {
    table-layout: auto !important;
  }

  .lg\:table-fixed {
    table-layout: fixed !important;
  }

  .lg\:text-left {
    text-align: left !important;
  }

  .lg\:text-center {
    text-align: center !important;
  }

  .lg\:text-right {
    text-align: right !important;
  }

  .lg\:text-justify {
    text-align: justify !important;
  }

  .lg\:text-opacity-0 {
    --text-opacity: 0 !important;
  }

  .lg\:text-opacity-12 {
    --text-opacity: 0.12 !important;
  }

  .lg\:text-opacity-25 {
    --text-opacity: 0.25 !important;
  }

  .lg\:text-opacity-38 {
    --text-opacity: 0.38 !important;
  }

  .lg\:text-opacity-50 {
    --text-opacity: 0.5 !important;
  }

  .lg\:text-opacity-54 {
    --text-opacity: 0.54 !important;
  }

  .lg\:text-opacity-70 {
    --text-opacity: 0.70 !important;
  }

  .lg\:text-opacity-75 {
    --text-opacity: 0.75 !important;
  }

  .lg\:text-opacity-84 {
    --text-opacity: 0.84 !important;
  }

  .lg\:text-opacity-100 {
    --text-opacity: 1 !important;
  }

  .lg\:hover\:text-opacity-0:hover {
    --text-opacity: 0 !important;
  }

  .lg\:hover\:text-opacity-12:hover {
    --text-opacity: 0.12 !important;
  }

  .lg\:hover\:text-opacity-25:hover {
    --text-opacity: 0.25 !important;
  }

  .lg\:hover\:text-opacity-38:hover {
    --text-opacity: 0.38 !important;
  }

  .lg\:hover\:text-opacity-50:hover {
    --text-opacity: 0.5 !important;
  }

  .lg\:hover\:text-opacity-54:hover {
    --text-opacity: 0.54 !important;
  }

  .lg\:hover\:text-opacity-70:hover {
    --text-opacity: 0.70 !important;
  }

  .lg\:hover\:text-opacity-75:hover {
    --text-opacity: 0.75 !important;
  }

  .lg\:hover\:text-opacity-84:hover {
    --text-opacity: 0.84 !important;
  }

  .lg\:hover\:text-opacity-100:hover {
    --text-opacity: 1 !important;
  }

  .lg\:focus\:text-opacity-0:focus {
    --text-opacity: 0 !important;
  }

  .lg\:focus\:text-opacity-12:focus {
    --text-opacity: 0.12 !important;
  }

  .lg\:focus\:text-opacity-25:focus {
    --text-opacity: 0.25 !important;
  }

  .lg\:focus\:text-opacity-38:focus {
    --text-opacity: 0.38 !important;
  }

  .lg\:focus\:text-opacity-50:focus {
    --text-opacity: 0.5 !important;
  }

  .lg\:focus\:text-opacity-54:focus {
    --text-opacity: 0.54 !important;
  }

  .lg\:focus\:text-opacity-70:focus {
    --text-opacity: 0.70 !important;
  }

  .lg\:focus\:text-opacity-75:focus {
    --text-opacity: 0.75 !important;
  }

  .lg\:focus\:text-opacity-84:focus {
    --text-opacity: 0.84 !important;
  }

  .lg\:focus\:text-opacity-100:focus {
    --text-opacity: 1 !important;
  }

  .lg\:italic {
    font-style: italic !important;
  }

  .lg\:not-italic {
    font-style: normal !important;
  }

  .lg\:uppercase {
    text-transform: uppercase !important;
  }

  .lg\:lowercase {
    text-transform: lowercase !important;
  }

  .lg\:capitalize {
    text-transform: capitalize !important;
  }

  .lg\:normal-case {
    text-transform: none !important;
  }

  .lg\:underline {
    text-decoration: underline !important;
  }

  .lg\:line-through {
    text-decoration: line-through !important;
  }

  .lg\:no-underline {
    text-decoration: none !important;
  }

  .lg\:hover\:underline:hover {
    text-decoration: underline !important;
  }

  .lg\:hover\:line-through:hover {
    text-decoration: line-through !important;
  }

  .lg\:hover\:no-underline:hover {
    text-decoration: none !important;
  }

  .lg\:focus\:underline:focus {
    text-decoration: underline !important;
  }

  .lg\:focus\:line-through:focus {
    text-decoration: line-through !important;
  }

  .lg\:focus\:no-underline:focus {
    text-decoration: none !important;
  }

  .lg\:tracking-tighter {
    letter-spacing: -0.05em !important;
  }

  .lg\:tracking-tight {
    letter-spacing: -0.025em !important;
  }

  .lg\:tracking-normal {
    letter-spacing: 0 !important;
  }

  .lg\:tracking-wide {
    letter-spacing: 0.025em !important;
  }

  .lg\:tracking-wider {
    letter-spacing: 0.05em !important;
  }

  .lg\:tracking-widest {
    letter-spacing: 0.1em !important;
  }

  .lg\:select-none {
    -webkit-user-select: none !important;
       -moz-user-select: none !important;
        -ms-user-select: none !important;
            user-select: none !important;
  }

  .lg\:select-text {
    -webkit-user-select: text !important;
       -moz-user-select: text !important;
        -ms-user-select: text !important;
            user-select: text !important;
  }

  .lg\:select-all {
    -webkit-user-select: all !important;
       -moz-user-select: all !important;
        -ms-user-select: all !important;
            user-select: all !important;
  }

  .lg\:select-auto {
    -webkit-user-select: auto !important;
       -moz-user-select: auto !important;
        -ms-user-select: auto !important;
            user-select: auto !important;
  }

  .lg\:align-baseline {
    vertical-align: baseline !important;
  }

  .lg\:align-top {
    vertical-align: top !important;
  }

  .lg\:align-middle {
    vertical-align: middle !important;
  }

  .lg\:align-bottom {
    vertical-align: bottom !important;
  }

  .lg\:align-text-top {
    vertical-align: text-top !important;
  }

  .lg\:align-text-bottom {
    vertical-align: text-bottom !important;
  }

  .lg\:visible {
    visibility: visible !important;
  }

  .lg\:invisible {
    visibility: hidden !important;
  }

  .lg\:whitespace-normal {
    white-space: normal !important;
  }

  .lg\:whitespace-nowrap {
    white-space: nowrap !important;
  }

  .lg\:whitespace-pre {
    white-space: pre !important;
  }

  .lg\:whitespace-pre-line {
    white-space: pre-line !important;
  }

  .lg\:whitespace-pre-wrap {
    white-space: pre-wrap !important;
  }

  .lg\:break-normal {
    overflow-wrap: normal !important;
    word-break: normal !important;
  }

  .lg\:break-words {
    overflow-wrap: break-word !important;
  }

  .lg\:break-all {
    word-break: break-all !important;
  }

  .lg\:truncate {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
  }

  .lg\:w-0 {
    width: 0 !important;
  }

  .lg\:w-1 {
    width: 0.25rem !important;
  }

  .lg\:w-2 {
    width: 0.5rem !important;
  }

  .lg\:w-3 {
    width: 0.75rem !important;
  }

  .lg\:w-4 {
    width: 1rem !important;
  }

  .lg\:w-5 {
    width: 1.25rem !important;
  }

  .lg\:w-6 {
    width: 1.5rem !important;
  }

  .lg\:w-8 {
    width: 2rem !important;
  }

  .lg\:w-10 {
    width: 2.5rem !important;
  }

  .lg\:w-12 {
    width: 3rem !important;
  }

  .lg\:w-14 {
    width: 3.5rem !important;
  }

  .lg\:w-16 {
    width: 4rem !important;
  }

  .lg\:w-18 {
    width: 4.5rem !important;
  }

  .lg\:w-20 {
    width: 5rem !important;
  }

  .lg\:w-22 {
    width: 5.5rem !important;
  }

  .lg\:w-24 {
    width: 6rem !important;
  }

  .lg\:w-26 {
    width: 6.5rem !important;
  }

  .lg\:w-28 {
    width: 7rem !important;
  }

  .lg\:w-30 {
    width: 7.5rem !important;
  }

  .lg\:w-32 {
    width: 8rem !important;
  }

  .lg\:w-36 {
    width: 9rem !important;
  }

  .lg\:w-40 {
    width: 10rem !important;
  }

  .lg\:w-48 {
    width: 12rem !important;
  }

  .lg\:w-50 {
    width: 12.5rem !important;
  }

  .lg\:w-56 {
    width: 14rem !important;
  }

  .lg\:w-60 {
    width: 15rem !important;
  }

  .lg\:w-64 {
    width: 16rem !important;
  }

  .lg\:w-80 {
    width: 20rem !important;
  }

  .lg\:w-90 {
    width: 24rem !important;
  }

  .lg\:w-100 {
    width: 25rem !important;
  }

  .lg\:w-120 {
    width: 30rem !important;
  }

  .lg\:w-128 {
    width: 32rem !important;
  }

  .lg\:w-140 {
    width: 35rem !important;
  }

  .lg\:w-160 {
    width: 40rem !important;
  }

  .lg\:w-180 {
    width: 45rem !important;
  }

  .lg\:w-192 {
    width: 48rem !important;
  }

  .lg\:w-200 {
    width: 50rem !important;
  }

  .lg\:w-240 {
    width: 60rem !important;
  }

  .lg\:w-256 {
    width: 64rem !important;
  }

  .lg\:w-280 {
    width: 70rem !important;
  }

  .lg\:w-320 {
    width: 80rem !important;
  }

  .lg\:w-360 {
    width: 90rem !important;
  }

  .lg\:w-400 {
    width: 100rem !important;
  }

  .lg\:w-480 {
    width: 120rem !important;
  }

  .lg\:w-auto {
    width: auto !important;
  }

  .lg\:w-px {
    width: 1px !important;
  }

  .lg\:w-2px {
    width: 2px !important;
  }

  .lg\:w-1\/2 {
    width: 50% !important;
  }

  .lg\:w-1\/3 {
    width: 33.33333% !important;
  }

  .lg\:w-2\/3 {
    width: 66.66667% !important;
  }

  .lg\:w-1\/4 {
    width: 25% !important;
  }

  .lg\:w-2\/4 {
    width: 50% !important;
  }

  .lg\:w-3\/4 {
    width: 75% !important;
  }

  .lg\:w-1\/5 {
    width: 20% !important;
  }

  .lg\:w-2\/5 {
    width: 40% !important;
  }

  .lg\:w-3\/5 {
    width: 60% !important;
  }

  .lg\:w-4\/5 {
    width: 80% !important;
  }

  .lg\:w-1\/6 {
    width: 16.666667% !important;
  }

  .lg\:w-2\/6 {
    width: 33.333333% !important;
  }

  .lg\:w-3\/6 {
    width: 50% !important;
  }

  .lg\:w-4\/6 {
    width: 66.666667% !important;
  }

  .lg\:w-5\/6 {
    width: 83.333333% !important;
  }

  .lg\:w-1\/12 {
    width: 8.33333% !important;
  }

  .lg\:w-2\/12 {
    width: 16.66667% !important;
  }

  .lg\:w-3\/12 {
    width: 25% !important;
  }

  .lg\:w-4\/12 {
    width: 33.33333% !important;
  }

  .lg\:w-5\/12 {
    width: 41.66667% !important;
  }

  .lg\:w-6\/12 {
    width: 50% !important;
  }

  .lg\:w-7\/12 {
    width: 58.33333% !important;
  }

  .lg\:w-8\/12 {
    width: 66.66667% !important;
  }

  .lg\:w-9\/12 {
    width: 75% !important;
  }

  .lg\:w-10\/12 {
    width: 83.33333% !important;
  }

  .lg\:w-11\/12 {
    width: 91.66667% !important;
  }

  .lg\:w-full {
    width: 100% !important;
  }

  .lg\:w-screen {
    width: 100vw !important;
  }

  .lg\:z-0 {
    z-index: 0 !important;
  }

  .lg\:z-10 {
    z-index: 10 !important;
  }

  .lg\:z-20 {
    z-index: 20 !important;
  }

  .lg\:z-30 {
    z-index: 30 !important;
  }

  .lg\:z-40 {
    z-index: 40 !important;
  }

  .lg\:z-50 {
    z-index: 50 !important;
  }

  .lg\:z-60 {
    z-index: 60 !important;
  }

  .lg\:z-70 {
    z-index: 70 !important;
  }

  .lg\:z-80 {
    z-index: 80 !important;
  }

  .lg\:z-90 {
    z-index: 90 !important;
  }

  .lg\:z-99 {
    z-index: 99 !important;
  }

  .lg\:z-999 {
    z-index: 999 !important;
  }

  .lg\:z-9999 {
    z-index: 9999 !important;
  }

  .lg\:z-99999 {
    z-index: 99999 !important;
  }

  .lg\:z-auto {
    z-index: auto !important;
  }

  .lg\:-z-1 {
    z-index: -1 !important;
  }

  .lg\:gap-0 {
    grid-gap: 0 !important;
    gap: 0 !important;
  }

  .lg\:gap-1 {
    grid-gap: 0.25rem !important;
    gap: 0.25rem !important;
  }

  .lg\:gap-2 {
    grid-gap: 0.5rem !important;
    gap: 0.5rem !important;
  }

  .lg\:gap-3 {
    grid-gap: 0.75rem !important;
    gap: 0.75rem !important;
  }

  .lg\:gap-4 {
    grid-gap: 1rem !important;
    gap: 1rem !important;
  }

  .lg\:gap-5 {
    grid-gap: 1.25rem !important;
    gap: 1.25rem !important;
  }

  .lg\:gap-6 {
    grid-gap: 1.5rem !important;
    gap: 1.5rem !important;
  }

  .lg\:gap-8 {
    grid-gap: 2rem !important;
    gap: 2rem !important;
  }

  .lg\:gap-10 {
    grid-gap: 2.5rem !important;
    gap: 2.5rem !important;
  }

  .lg\:gap-12 {
    grid-gap: 3rem !important;
    gap: 3rem !important;
  }

  .lg\:gap-14 {
    grid-gap: 3.5rem !important;
    gap: 3.5rem !important;
  }

  .lg\:gap-16 {
    grid-gap: 4rem !important;
    gap: 4rem !important;
  }

  .lg\:gap-18 {
    grid-gap: 4.5rem !important;
    gap: 4.5rem !important;
  }

  .lg\:gap-20 {
    grid-gap: 5rem !important;
    gap: 5rem !important;
  }

  .lg\:gap-22 {
    grid-gap: 5.5rem !important;
    gap: 5.5rem !important;
  }

  .lg\:gap-24 {
    grid-gap: 6rem !important;
    gap: 6rem !important;
  }

  .lg\:gap-26 {
    grid-gap: 6.5rem !important;
    gap: 6.5rem !important;
  }

  .lg\:gap-28 {
    grid-gap: 7rem !important;
    gap: 7rem !important;
  }

  .lg\:gap-30 {
    grid-gap: 7.5rem !important;
    gap: 7.5rem !important;
  }

  .lg\:gap-32 {
    grid-gap: 8rem !important;
    gap: 8rem !important;
  }

  .lg\:gap-36 {
    grid-gap: 9rem !important;
    gap: 9rem !important;
  }

  .lg\:gap-40 {
    grid-gap: 10rem !important;
    gap: 10rem !important;
  }

  .lg\:gap-48 {
    grid-gap: 12rem !important;
    gap: 12rem !important;
  }

  .lg\:gap-56 {
    grid-gap: 14rem !important;
    gap: 14rem !important;
  }

  .lg\:gap-64 {
    grid-gap: 16rem !important;
    gap: 16rem !important;
  }

  .lg\:gap-px {
    grid-gap: 1px !important;
    gap: 1px !important;
  }

  .lg\:gap-2px {
    grid-gap: 2px !important;
    gap: 2px !important;
  }

  .lg\:gap-x-0 {
    grid-column-gap: 0 !important;
    -moz-column-gap: 0 !important;
         column-gap: 0 !important;
  }

  .lg\:gap-x-1 {
    grid-column-gap: 0.25rem !important;
    -moz-column-gap: 0.25rem !important;
         column-gap: 0.25rem !important;
  }

  .lg\:gap-x-2 {
    grid-column-gap: 0.5rem !important;
    -moz-column-gap: 0.5rem !important;
         column-gap: 0.5rem !important;
  }

  .lg\:gap-x-3 {
    grid-column-gap: 0.75rem !important;
    -moz-column-gap: 0.75rem !important;
         column-gap: 0.75rem !important;
  }

  .lg\:gap-x-4 {
    grid-column-gap: 1rem !important;
    -moz-column-gap: 1rem !important;
         column-gap: 1rem !important;
  }

  .lg\:gap-x-5 {
    grid-column-gap: 1.25rem !important;
    -moz-column-gap: 1.25rem !important;
         column-gap: 1.25rem !important;
  }

  .lg\:gap-x-6 {
    grid-column-gap: 1.5rem !important;
    -moz-column-gap: 1.5rem !important;
         column-gap: 1.5rem !important;
  }

  .lg\:gap-x-8 {
    grid-column-gap: 2rem !important;
    -moz-column-gap: 2rem !important;
         column-gap: 2rem !important;
  }

  .lg\:gap-x-10 {
    grid-column-gap: 2.5rem !important;
    -moz-column-gap: 2.5rem !important;
         column-gap: 2.5rem !important;
  }

  .lg\:gap-x-12 {
    grid-column-gap: 3rem !important;
    -moz-column-gap: 3rem !important;
         column-gap: 3rem !important;
  }

  .lg\:gap-x-14 {
    grid-column-gap: 3.5rem !important;
    -moz-column-gap: 3.5rem !important;
         column-gap: 3.5rem !important;
  }

  .lg\:gap-x-16 {
    grid-column-gap: 4rem !important;
    -moz-column-gap: 4rem !important;
         column-gap: 4rem !important;
  }

  .lg\:gap-x-18 {
    grid-column-gap: 4.5rem !important;
    -moz-column-gap: 4.5rem !important;
         column-gap: 4.5rem !important;
  }

  .lg\:gap-x-20 {
    grid-column-gap: 5rem !important;
    -moz-column-gap: 5rem !important;
         column-gap: 5rem !important;
  }

  .lg\:gap-x-22 {
    grid-column-gap: 5.5rem !important;
    -moz-column-gap: 5.5rem !important;
         column-gap: 5.5rem !important;
  }

  .lg\:gap-x-24 {
    grid-column-gap: 6rem !important;
    -moz-column-gap: 6rem !important;
         column-gap: 6rem !important;
  }

  .lg\:gap-x-26 {
    grid-column-gap: 6.5rem !important;
    -moz-column-gap: 6.5rem !important;
         column-gap: 6.5rem !important;
  }

  .lg\:gap-x-28 {
    grid-column-gap: 7rem !important;
    -moz-column-gap: 7rem !important;
         column-gap: 7rem !important;
  }

  .lg\:gap-x-30 {
    grid-column-gap: 7.5rem !important;
    -moz-column-gap: 7.5rem !important;
         column-gap: 7.5rem !important;
  }

  .lg\:gap-x-32 {
    grid-column-gap: 8rem !important;
    -moz-column-gap: 8rem !important;
         column-gap: 8rem !important;
  }

  .lg\:gap-x-36 {
    grid-column-gap: 9rem !important;
    -moz-column-gap: 9rem !important;
         column-gap: 9rem !important;
  }

  .lg\:gap-x-40 {
    grid-column-gap: 10rem !important;
    -moz-column-gap: 10rem !important;
         column-gap: 10rem !important;
  }

  .lg\:gap-x-48 {
    grid-column-gap: 12rem !important;
    -moz-column-gap: 12rem !important;
         column-gap: 12rem !important;
  }

  .lg\:gap-x-56 {
    grid-column-gap: 14rem !important;
    -moz-column-gap: 14rem !important;
         column-gap: 14rem !important;
  }

  .lg\:gap-x-64 {
    grid-column-gap: 16rem !important;
    -moz-column-gap: 16rem !important;
         column-gap: 16rem !important;
  }

  .lg\:gap-x-px {
    grid-column-gap: 1px !important;
    -moz-column-gap: 1px !important;
         column-gap: 1px !important;
  }

  .lg\:gap-x-2px {
    grid-column-gap: 2px !important;
    -moz-column-gap: 2px !important;
         column-gap: 2px !important;
  }

  .lg\:gap-y-0 {
    grid-row-gap: 0 !important;
    row-gap: 0 !important;
  }

  .lg\:gap-y-1 {
    grid-row-gap: 0.25rem !important;
    row-gap: 0.25rem !important;
  }

  .lg\:gap-y-2 {
    grid-row-gap: 0.5rem !important;
    row-gap: 0.5rem !important;
  }

  .lg\:gap-y-3 {
    grid-row-gap: 0.75rem !important;
    row-gap: 0.75rem !important;
  }

  .lg\:gap-y-4 {
    grid-row-gap: 1rem !important;
    row-gap: 1rem !important;
  }

  .lg\:gap-y-5 {
    grid-row-gap: 1.25rem !important;
    row-gap: 1.25rem !important;
  }

  .lg\:gap-y-6 {
    grid-row-gap: 1.5rem !important;
    row-gap: 1.5rem !important;
  }

  .lg\:gap-y-8 {
    grid-row-gap: 2rem !important;
    row-gap: 2rem !important;
  }

  .lg\:gap-y-10 {
    grid-row-gap: 2.5rem !important;
    row-gap: 2.5rem !important;
  }

  .lg\:gap-y-12 {
    grid-row-gap: 3rem !important;
    row-gap: 3rem !important;
  }

  .lg\:gap-y-14 {
    grid-row-gap: 3.5rem !important;
    row-gap: 3.5rem !important;
  }

  .lg\:gap-y-16 {
    grid-row-gap: 4rem !important;
    row-gap: 4rem !important;
  }

  .lg\:gap-y-18 {
    grid-row-gap: 4.5rem !important;
    row-gap: 4.5rem !important;
  }

  .lg\:gap-y-20 {
    grid-row-gap: 5rem !important;
    row-gap: 5rem !important;
  }

  .lg\:gap-y-22 {
    grid-row-gap: 5.5rem !important;
    row-gap: 5.5rem !important;
  }

  .lg\:gap-y-24 {
    grid-row-gap: 6rem !important;
    row-gap: 6rem !important;
  }

  .lg\:gap-y-26 {
    grid-row-gap: 6.5rem !important;
    row-gap: 6.5rem !important;
  }

  .lg\:gap-y-28 {
    grid-row-gap: 7rem !important;
    row-gap: 7rem !important;
  }

  .lg\:gap-y-30 {
    grid-row-gap: 7.5rem !important;
    row-gap: 7.5rem !important;
  }

  .lg\:gap-y-32 {
    grid-row-gap: 8rem !important;
    row-gap: 8rem !important;
  }

  .lg\:gap-y-36 {
    grid-row-gap: 9rem !important;
    row-gap: 9rem !important;
  }

  .lg\:gap-y-40 {
    grid-row-gap: 10rem !important;
    row-gap: 10rem !important;
  }

  .lg\:gap-y-48 {
    grid-row-gap: 12rem !important;
    row-gap: 12rem !important;
  }

  .lg\:gap-y-56 {
    grid-row-gap: 14rem !important;
    row-gap: 14rem !important;
  }

  .lg\:gap-y-64 {
    grid-row-gap: 16rem !important;
    row-gap: 16rem !important;
  }

  .lg\:gap-y-px {
    grid-row-gap: 1px !important;
    row-gap: 1px !important;
  }

  .lg\:gap-y-2px {
    grid-row-gap: 2px !important;
    row-gap: 2px !important;
  }

  .lg\:grid-flow-row {
    grid-auto-flow: row !important;
  }

  .lg\:grid-flow-col {
    grid-auto-flow: column !important;
  }

  .lg\:grid-flow-row-dense {
    grid-auto-flow: row dense !important;
  }

  .lg\:grid-flow-col-dense {
    grid-auto-flow: column dense !important;
  }

  .lg\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }

  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
  }

  .lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
  }

  .lg\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr)) !important;
  }

  .lg\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr)) !important;
  }

  .lg\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr)) !important;
  }

  .lg\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr)) !important;
  }

  .lg\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr)) !important;
  }

  .lg\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr)) !important;
  }

  .lg\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr)) !important;
  }

  .lg\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr)) !important;
  }

  .lg\:grid-cols-none {
    grid-template-columns: none !important;
  }

  .lg\:col-auto {
    grid-column: auto !important;
  }

  .lg\:col-span-1 {
    grid-column: span 1 / span 1 !important;
  }

  .lg\:col-span-2 {
    grid-column: span 2 / span 2 !important;
  }

  .lg\:col-span-3 {
    grid-column: span 3 / span 3 !important;
  }

  .lg\:col-span-4 {
    grid-column: span 4 / span 4 !important;
  }

  .lg\:col-span-5 {
    grid-column: span 5 / span 5 !important;
  }

  .lg\:col-span-6 {
    grid-column: span 6 / span 6 !important;
  }

  .lg\:col-span-7 {
    grid-column: span 7 / span 7 !important;
  }

  .lg\:col-span-8 {
    grid-column: span 8 / span 8 !important;
  }

  .lg\:col-span-9 {
    grid-column: span 9 / span 9 !important;
  }

  .lg\:col-span-10 {
    grid-column: span 10 / span 10 !important;
  }

  .lg\:col-span-11 {
    grid-column: span 11 / span 11 !important;
  }

  .lg\:col-span-12 {
    grid-column: span 12 / span 12 !important;
  }

  .lg\:col-start-1 {
    grid-column-start: 1 !important;
  }

  .lg\:col-start-2 {
    grid-column-start: 2 !important;
  }

  .lg\:col-start-3 {
    grid-column-start: 3 !important;
  }

  .lg\:col-start-4 {
    grid-column-start: 4 !important;
  }

  .lg\:col-start-5 {
    grid-column-start: 5 !important;
  }

  .lg\:col-start-6 {
    grid-column-start: 6 !important;
  }

  .lg\:col-start-7 {
    grid-column-start: 7 !important;
  }

  .lg\:col-start-8 {
    grid-column-start: 8 !important;
  }

  .lg\:col-start-9 {
    grid-column-start: 9 !important;
  }

  .lg\:col-start-10 {
    grid-column-start: 10 !important;
  }

  .lg\:col-start-11 {
    grid-column-start: 11 !important;
  }

  .lg\:col-start-12 {
    grid-column-start: 12 !important;
  }

  .lg\:col-start-13 {
    grid-column-start: 13 !important;
  }

  .lg\:col-start-auto {
    grid-column-start: auto !important;
  }

  .lg\:col-end-1 {
    grid-column-end: 1 !important;
  }

  .lg\:col-end-2 {
    grid-column-end: 2 !important;
  }

  .lg\:col-end-3 {
    grid-column-end: 3 !important;
  }

  .lg\:col-end-4 {
    grid-column-end: 4 !important;
  }

  .lg\:col-end-5 {
    grid-column-end: 5 !important;
  }

  .lg\:col-end-6 {
    grid-column-end: 6 !important;
  }

  .lg\:col-end-7 {
    grid-column-end: 7 !important;
  }

  .lg\:col-end-8 {
    grid-column-end: 8 !important;
  }

  .lg\:col-end-9 {
    grid-column-end: 9 !important;
  }

  .lg\:col-end-10 {
    grid-column-end: 10 !important;
  }

  .lg\:col-end-11 {
    grid-column-end: 11 !important;
  }

  .lg\:col-end-12 {
    grid-column-end: 12 !important;
  }

  .lg\:col-end-13 {
    grid-column-end: 13 !important;
  }

  .lg\:col-end-auto {
    grid-column-end: auto !important;
  }

  .lg\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr)) !important;
  }

  .lg\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr)) !important;
  }

  .lg\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr)) !important;
  }

  .lg\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr)) !important;
  }

  .lg\:grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr)) !important;
  }

  .lg\:grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr)) !important;
  }

  .lg\:grid-rows-none {
    grid-template-rows: none !important;
  }

  .lg\:row-auto {
    grid-row: auto !important;
  }

  .lg\:row-span-1 {
    grid-row: span 1 / span 1 !important;
  }

  .lg\:row-span-2 {
    grid-row: span 2 / span 2 !important;
  }

  .lg\:row-span-3 {
    grid-row: span 3 / span 3 !important;
  }

  .lg\:row-span-4 {
    grid-row: span 4 / span 4 !important;
  }

  .lg\:row-span-5 {
    grid-row: span 5 / span 5 !important;
  }

  .lg\:row-span-6 {
    grid-row: span 6 / span 6 !important;
  }

  .lg\:row-start-1 {
    grid-row-start: 1 !important;
  }

  .lg\:row-start-2 {
    grid-row-start: 2 !important;
  }

  .lg\:row-start-3 {
    grid-row-start: 3 !important;
  }

  .lg\:row-start-4 {
    grid-row-start: 4 !important;
  }

  .lg\:row-start-5 {
    grid-row-start: 5 !important;
  }

  .lg\:row-start-6 {
    grid-row-start: 6 !important;
  }

  .lg\:row-start-7 {
    grid-row-start: 7 !important;
  }

  .lg\:row-start-auto {
    grid-row-start: auto !important;
  }

  .lg\:row-end-1 {
    grid-row-end: 1 !important;
  }

  .lg\:row-end-2 {
    grid-row-end: 2 !important;
  }

  .lg\:row-end-3 {
    grid-row-end: 3 !important;
  }

  .lg\:row-end-4 {
    grid-row-end: 4 !important;
  }

  .lg\:row-end-5 {
    grid-row-end: 5 !important;
  }

  .lg\:row-end-6 {
    grid-row-end: 6 !important;
  }

  .lg\:row-end-7 {
    grid-row-end: 7 !important;
  }

  .lg\:row-end-auto {
    grid-row-end: auto !important;
  }

  .lg\:transform {
    --transform-translate-x: 0 !important;
    --transform-translate-y: 0 !important;
    --transform-rotate: 0 !important;
    --transform-skew-x: 0 !important;
    --transform-skew-y: 0 !important;
    --transform-scale-x: 1 !important;
    --transform-scale-y: 1 !important;
    transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y)) !important;
  }

  .lg\:transform-none {
    transform: none !important;
  }

  .lg\:origin-center {
    transform-origin: center !important;
  }

  .lg\:origin-top {
    transform-origin: top !important;
  }

  .lg\:origin-top-right {
    transform-origin: top right !important;
  }

  .lg\:origin-right {
    transform-origin: right !important;
  }

  .lg\:origin-bottom-right {
    transform-origin: bottom right !important;
  }

  .lg\:origin-bottom {
    transform-origin: bottom !important;
  }

  .lg\:origin-bottom-left {
    transform-origin: bottom left !important;
  }

  .lg\:origin-left {
    transform-origin: left !important;
  }

  .lg\:origin-top-left {
    transform-origin: top left !important;
  }

  .lg\:icon-size-12 {
    width: 12px !important;
    height: 12px !important;
    min-width: 12px !important;
    min-height: 12px !important;
    font-size: 12px !important;
    line-height: 12px !important;
  }

  .lg\:icon-size-12 svg {
    width: 12px !important;
    height: 12px !important;
  }

  .lg\:icon-size-14 {
    width: 14px !important;
    height: 14px !important;
    min-width: 14px !important;
    min-height: 14px !important;
    font-size: 14px !important;
    line-height: 14px !important;
  }

  .lg\:icon-size-14 svg {
    width: 14px !important;
    height: 14px !important;
  }

  .lg\:icon-size-16 {
    width: 16px !important;
    height: 16px !important;
    min-width: 16px !important;
    min-height: 16px !important;
    font-size: 16px !important;
    line-height: 16px !important;
  }

  .lg\:icon-size-16 svg {
    width: 16px !important;
    height: 16px !important;
  }

  .lg\:icon-size-18 {
    width: 18px !important;
    height: 18px !important;
    min-width: 18px !important;
    min-height: 18px !important;
    font-size: 18px !important;
    line-height: 18px !important;
  }

  .lg\:icon-size-18 svg {
    width: 18px !important;
    height: 18px !important;
  }

  .lg\:icon-size-20 {
    width: 20px !important;
    height: 20px !important;
    min-width: 20px !important;
    min-height: 20px !important;
    font-size: 20px !important;
    line-height: 20px !important;
  }

  .lg\:icon-size-20 svg {
    width: 20px !important;
    height: 20px !important;
  }

  .lg\:icon-size-24 {
    width: 24px !important;
    height: 24px !important;
    min-width: 24px !important;
    min-height: 24px !important;
    font-size: 24px !important;
    line-height: 24px !important;
  }

  .lg\:icon-size-24 svg {
    width: 24px !important;
    height: 24px !important;
  }

  .lg\:icon-size-32 {
    width: 32px !important;
    height: 32px !important;
    min-width: 32px !important;
    min-height: 32px !important;
    font-size: 32px !important;
    line-height: 32px !important;
  }

  .lg\:icon-size-32 svg {
    width: 32px !important;
    height: 32px !important;
  }

  .lg\:icon-size-40 {
    width: 40px !important;
    height: 40px !important;
    min-width: 40px !important;
    min-height: 40px !important;
    font-size: 40px !important;
    line-height: 40px !important;
  }

  .lg\:icon-size-40 svg {
    width: 40px !important;
    height: 40px !important;
  }

  .lg\:icon-size-48 {
    width: 48px !important;
    height: 48px !important;
    min-width: 48px !important;
    min-height: 48px !important;
    font-size: 48px !important;
    line-height: 48px !important;
  }

  .lg\:icon-size-48 svg {
    width: 48px !important;
    height: 48px !important;
  }

  .lg\:icon-size-56 {
    width: 56px !important;
    height: 56px !important;
    min-width: 56px !important;
    min-height: 56px !important;
    font-size: 56px !important;
    line-height: 56px !important;
  }

  .lg\:icon-size-56 svg {
    width: 56px !important;
    height: 56px !important;
  }

  .lg\:icon-size-64 {
    width: 64px !important;
    height: 64px !important;
    min-width: 64px !important;
    min-height: 64px !important;
    font-size: 64px !important;
    line-height: 64px !important;
  }

  .lg\:icon-size-64 svg {
    width: 64px !important;
    height: 64px !important;
  }

  .lg\:icon-size-72 {
    width: 72px !important;
    height: 72px !important;
    min-width: 72px !important;
    min-height: 72px !important;
    font-size: 72px !important;
    line-height: 72px !important;
  }

  .lg\:icon-size-72 svg {
    width: 72px !important;
    height: 72px !important;
  }

  .lg\:icon-size-80 {
    width: 80px !important;
    height: 80px !important;
    min-width: 80px !important;
    min-height: 80px !important;
    font-size: 80px !important;
    line-height: 80px !important;
  }

  .lg\:icon-size-80 svg {
    width: 80px !important;
    height: 80px !important;
  }

  .lg\:icon-size-88 {
    width: 88px !important;
    height: 88px !important;
    min-width: 88px !important;
    min-height: 88px !important;
    font-size: 88px !important;
    line-height: 88px !important;
  }

  .lg\:icon-size-88 svg {
    width: 88px !important;
    height: 88px !important;
  }

  .lg\:icon-size-96 {
    width: 96px !important;
    height: 96px !important;
    min-width: 96px !important;
    min-height: 96px !important;
    font-size: 96px !important;
    line-height: 96px !important;
  }

  .lg\:icon-size-96 svg {
    width: 96px !important;
    height: 96px !important;
  }
}

@media (min-width: 1440px) {
  .xl\:space-y-0 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(0px * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(0px * var(--space-y-reverse)) !important;
  }

  .xl\:space-x-0 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(0px * var(--space-x-reverse)) !important;
    margin-left: calc(0px * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(0.25rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(0.25rem * var(--space-y-reverse)) !important;
  }

  .xl\:space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(0.25rem * var(--space-x-reverse)) !important;
    margin-left: calc(0.25rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(0.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(0.5rem * var(--space-y-reverse)) !important;
  }

  .xl\:space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(0.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(0.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(0.75rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(0.75rem * var(--space-y-reverse)) !important;
  }

  .xl\:space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(0.75rem * var(--space-x-reverse)) !important;
    margin-left: calc(0.75rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(1rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(1rem * var(--space-y-reverse)) !important;
  }

  .xl\:space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(1rem * var(--space-x-reverse)) !important;
    margin-left: calc(1rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(1.25rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(1.25rem * var(--space-y-reverse)) !important;
  }

  .xl\:space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(1.25rem * var(--space-x-reverse)) !important;
    margin-left: calc(1.25rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(1.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(1.5rem * var(--space-y-reverse)) !important;
  }

  .xl\:space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(1.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(1.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:space-y-8 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(2rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(2rem * var(--space-y-reverse)) !important;
  }

  .xl\:space-x-8 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(2rem * var(--space-x-reverse)) !important;
    margin-left: calc(2rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:space-y-10 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(2.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(2.5rem * var(--space-y-reverse)) !important;
  }

  .xl\:space-x-10 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(2.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(2.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:space-y-12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(3rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(3rem * var(--space-y-reverse)) !important;
  }

  .xl\:space-x-12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(3rem * var(--space-x-reverse)) !important;
    margin-left: calc(3rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:space-y-14 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(3.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(3.5rem * var(--space-y-reverse)) !important;
  }

  .xl\:space-x-14 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(3.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(3.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:space-y-16 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(4rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(4rem * var(--space-y-reverse)) !important;
  }

  .xl\:space-x-16 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(4rem * var(--space-x-reverse)) !important;
    margin-left: calc(4rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:space-y-18 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(4.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(4.5rem * var(--space-y-reverse)) !important;
  }

  .xl\:space-x-18 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(4.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(4.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:space-y-20 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(5rem * var(--space-y-reverse)) !important;
  }

  .xl\:space-x-20 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(5rem * var(--space-x-reverse)) !important;
    margin-left: calc(5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:space-y-22 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(5.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(5.5rem * var(--space-y-reverse)) !important;
  }

  .xl\:space-x-22 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(5.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(5.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:space-y-24 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(6rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(6rem * var(--space-y-reverse)) !important;
  }

  .xl\:space-x-24 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(6rem * var(--space-x-reverse)) !important;
    margin-left: calc(6rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:space-y-26 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(6.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(6.5rem * var(--space-y-reverse)) !important;
  }

  .xl\:space-x-26 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(6.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(6.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:space-y-28 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(7rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(7rem * var(--space-y-reverse)) !important;
  }

  .xl\:space-x-28 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(7rem * var(--space-x-reverse)) !important;
    margin-left: calc(7rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:space-y-30 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(7.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(7.5rem * var(--space-y-reverse)) !important;
  }

  .xl\:space-x-30 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(7.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(7.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:space-y-32 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(8rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(8rem * var(--space-y-reverse)) !important;
  }

  .xl\:space-x-32 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(8rem * var(--space-x-reverse)) !important;
    margin-left: calc(8rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:space-y-36 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(9rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(9rem * var(--space-y-reverse)) !important;
  }

  .xl\:space-x-36 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(9rem * var(--space-x-reverse)) !important;
    margin-left: calc(9rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:space-y-40 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(10rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(10rem * var(--space-y-reverse)) !important;
  }

  .xl\:space-x-40 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(10rem * var(--space-x-reverse)) !important;
    margin-left: calc(10rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:space-y-48 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(12rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(12rem * var(--space-y-reverse)) !important;
  }

  .xl\:space-x-48 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(12rem * var(--space-x-reverse)) !important;
    margin-left: calc(12rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:space-y-56 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(14rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(14rem * var(--space-y-reverse)) !important;
  }

  .xl\:space-x-56 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(14rem * var(--space-x-reverse)) !important;
    margin-left: calc(14rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:space-y-64 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(16rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(16rem * var(--space-y-reverse)) !important;
  }

  .xl\:space-x-64 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(16rem * var(--space-x-reverse)) !important;
    margin-left: calc(16rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:space-y-px > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(1px * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(1px * var(--space-y-reverse)) !important;
  }

  .xl\:space-x-px > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(1px * var(--space-x-reverse)) !important;
    margin-left: calc(1px * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:space-y-2px > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(2px * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(2px * var(--space-y-reverse)) !important;
  }

  .xl\:space-x-2px > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(2px * var(--space-x-reverse)) !important;
    margin-left: calc(2px * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:-space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-0.25rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-0.25rem * var(--space-y-reverse)) !important;
  }

  .xl\:-space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-0.25rem * var(--space-x-reverse)) !important;
    margin-left: calc(-0.25rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:-space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-0.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-0.5rem * var(--space-y-reverse)) !important;
  }

  .xl\:-space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-0.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-0.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:-space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-0.75rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-0.75rem * var(--space-y-reverse)) !important;
  }

  .xl\:-space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-0.75rem * var(--space-x-reverse)) !important;
    margin-left: calc(-0.75rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:-space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-1rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-1rem * var(--space-y-reverse)) !important;
  }

  .xl\:-space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-1rem * var(--space-x-reverse)) !important;
    margin-left: calc(-1rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:-space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-1.25rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-1.25rem * var(--space-y-reverse)) !important;
  }

  .xl\:-space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-1.25rem * var(--space-x-reverse)) !important;
    margin-left: calc(-1.25rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:-space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-1.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-1.5rem * var(--space-y-reverse)) !important;
  }

  .xl\:-space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-1.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-1.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:-space-y-8 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-2rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-2rem * var(--space-y-reverse)) !important;
  }

  .xl\:-space-x-8 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-2rem * var(--space-x-reverse)) !important;
    margin-left: calc(-2rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:-space-y-10 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-2.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-2.5rem * var(--space-y-reverse)) !important;
  }

  .xl\:-space-x-10 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-2.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-2.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:-space-y-12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-3rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-3rem * var(--space-y-reverse)) !important;
  }

  .xl\:-space-x-12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-3rem * var(--space-x-reverse)) !important;
    margin-left: calc(-3rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:-space-y-14 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-3.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-3.5rem * var(--space-y-reverse)) !important;
  }

  .xl\:-space-x-14 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-3.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-3.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:-space-y-16 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-4rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-4rem * var(--space-y-reverse)) !important;
  }

  .xl\:-space-x-16 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-4rem * var(--space-x-reverse)) !important;
    margin-left: calc(-4rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:-space-y-18 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-4.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-4.5rem * var(--space-y-reverse)) !important;
  }

  .xl\:-space-x-18 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-4.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-4.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:-space-y-20 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-5rem * var(--space-y-reverse)) !important;
  }

  .xl\:-space-x-20 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:-space-y-22 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-5.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-5.5rem * var(--space-y-reverse)) !important;
  }

  .xl\:-space-x-22 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-5.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-5.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:-space-y-24 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-6rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-6rem * var(--space-y-reverse)) !important;
  }

  .xl\:-space-x-24 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-6rem * var(--space-x-reverse)) !important;
    margin-left: calc(-6rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:-space-y-26 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-6.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-6.5rem * var(--space-y-reverse)) !important;
  }

  .xl\:-space-x-26 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-6.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-6.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:-space-y-28 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-7rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-7rem * var(--space-y-reverse)) !important;
  }

  .xl\:-space-x-28 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-7rem * var(--space-x-reverse)) !important;
    margin-left: calc(-7rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:-space-y-30 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-7.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-7.5rem * var(--space-y-reverse)) !important;
  }

  .xl\:-space-x-30 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-7.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-7.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:-space-y-32 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-8rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-8rem * var(--space-y-reverse)) !important;
  }

  .xl\:-space-x-32 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-8rem * var(--space-x-reverse)) !important;
    margin-left: calc(-8rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:-space-y-36 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-9rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-9rem * var(--space-y-reverse)) !important;
  }

  .xl\:-space-x-36 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-9rem * var(--space-x-reverse)) !important;
    margin-left: calc(-9rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:-space-y-40 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-10rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-10rem * var(--space-y-reverse)) !important;
  }

  .xl\:-space-x-40 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-10rem * var(--space-x-reverse)) !important;
    margin-left: calc(-10rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:-space-y-48 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-12rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-12rem * var(--space-y-reverse)) !important;
  }

  .xl\:-space-x-48 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-12rem * var(--space-x-reverse)) !important;
    margin-left: calc(-12rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:-space-y-56 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-14rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-14rem * var(--space-y-reverse)) !important;
  }

  .xl\:-space-x-56 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-14rem * var(--space-x-reverse)) !important;
    margin-left: calc(-14rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:-space-y-64 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-16rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-16rem * var(--space-y-reverse)) !important;
  }

  .xl\:-space-x-64 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-16rem * var(--space-x-reverse)) !important;
    margin-left: calc(-16rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:-space-y-px > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-1px * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-1px * var(--space-y-reverse)) !important;
  }

  .xl\:-space-x-px > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-1px * var(--space-x-reverse)) !important;
    margin-left: calc(-1px * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:-space-y-2px > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-2px * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-2px * var(--space-y-reverse)) !important;
  }

  .xl\:-space-x-2px > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-2px * var(--space-x-reverse)) !important;
    margin-left: calc(-2px * calc(1 - var(--space-x-reverse))) !important;
  }

  .xl\:space-y-reverse > :not(template) ~ :not(template) {
    --space-y-reverse: 1 !important;
  }

  .xl\:space-x-reverse > :not(template) ~ :not(template) {
    --space-x-reverse: 1 !important;
  }

  .xl\:divide-y-0 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(0px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(0px * var(--divide-y-reverse)) !important;
  }

  .xl\:divide-x-0 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(0px * var(--divide-x-reverse)) !important;
    border-left-width: calc(0px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .xl\:divide-y-2 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(2px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(2px * var(--divide-y-reverse)) !important;
  }

  .xl\:divide-x-2 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(2px * var(--divide-x-reverse)) !important;
    border-left-width: calc(2px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .xl\:divide-y-4 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(4px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(4px * var(--divide-y-reverse)) !important;
  }

  .xl\:divide-x-4 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(4px * var(--divide-x-reverse)) !important;
    border-left-width: calc(4px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .xl\:divide-y-8 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(8px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(8px * var(--divide-y-reverse)) !important;
  }

  .xl\:divide-x-8 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(8px * var(--divide-x-reverse)) !important;
    border-left-width: calc(8px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .xl\:divide-y > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(1px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(1px * var(--divide-y-reverse)) !important;
  }

  .xl\:divide-x > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(1px * var(--divide-x-reverse)) !important;
    border-left-width: calc(1px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .xl\:divide-y-reverse > :not(template) ~ :not(template) {
    --divide-y-reverse: 1 !important;
  }

  .xl\:divide-x-reverse > :not(template) ~ :not(template) {
    --divide-x-reverse: 1 !important;
  }

  .xl\:divide-current > :not(template) ~ :not(template) {
    border-color: currentColor !important;
  }

  .xl\:divide-transparent > :not(template) ~ :not(template) {
    border-color: transparent !important;
  }

  .xl\:divide-white > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FFFFFF !important;
    border-color: rgba(255, 255, 255, var(--divide-opacity)) !important;
  }

  .xl\:divide-black > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #000000 !important;
    border-color: rgba(0, 0, 0, var(--divide-opacity)) !important;
  }

  .xl\:divide-gray-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F9FAFB !important;
    border-color: rgba(249, 250, 251, var(--divide-opacity)) !important;
  }

  .xl\:divide-gray-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F4F5F7 !important;
    border-color: rgba(244, 245, 247, var(--divide-opacity)) !important;
  }

  .xl\:divide-gray-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E5E7EB !important;
    border-color: rgba(229, 231, 235, var(--divide-opacity)) !important;
  }

  .xl\:divide-gray-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #D2D6DC !important;
    border-color: rgba(210, 214, 220, var(--divide-opacity)) !important;
  }

  .xl\:divide-gray-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9FA6B2 !important;
    border-color: rgba(159, 166, 178, var(--divide-opacity)) !important;
  }

  .xl\:divide-gray-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #6B7280 !important;
    border-color: rgba(107, 114, 128, var(--divide-opacity)) !important;
  }

  .xl\:divide-gray-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #4B5563 !important;
    border-color: rgba(75, 85, 99, var(--divide-opacity)) !important;
  }

  .xl\:divide-gray-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #374151 !important;
    border-color: rgba(55, 65, 81, var(--divide-opacity)) !important;
  }

  .xl\:divide-gray-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #252F3F !important;
    border-color: rgba(37, 47, 63, var(--divide-opacity)) !important;
  }

  .xl\:divide-gray-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #161E2E !important;
    border-color: rgba(22, 30, 46, var(--divide-opacity)) !important;
  }

  .xl\:divide-gray > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #6B7280 !important;
    border-color: rgba(107, 114, 128, var(--divide-opacity)) !important;
  }

  .xl\:divide-cool-gray-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FBFDFE !important;
    border-color: rgba(251, 253, 254, var(--divide-opacity)) !important;
  }

  .xl\:divide-cool-gray-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F1F5F9 !important;
    border-color: rgba(241, 245, 249, var(--divide-opacity)) !important;
  }

  .xl\:divide-cool-gray-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E2E8F0 !important;
    border-color: rgba(226, 232, 240, var(--divide-opacity)) !important;
  }

  .xl\:divide-cool-gray-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #CFD8E3 !important;
    border-color: rgba(207, 216, 227, var(--divide-opacity)) !important;
  }

  .xl\:divide-cool-gray-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #97A6BA !important;
    border-color: rgba(151, 166, 186, var(--divide-opacity)) !important;
  }

  .xl\:divide-cool-gray-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #64748B !important;
    border-color: rgba(100, 116, 139, var(--divide-opacity)) !important;
  }

  .xl\:divide-cool-gray-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #475569 !important;
    border-color: rgba(71, 85, 105, var(--divide-opacity)) !important;
  }

  .xl\:divide-cool-gray-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #364152 !important;
    border-color: rgba(54, 65, 82, var(--divide-opacity)) !important;
  }

  .xl\:divide-cool-gray-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #27303F !important;
    border-color: rgba(39, 48, 63, var(--divide-opacity)) !important;
  }

  .xl\:divide-cool-gray-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #1A202E !important;
    border-color: rgba(26, 32, 46, var(--divide-opacity)) !important;
  }

  .xl\:divide-cool-gray > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #64748B !important;
    border-color: rgba(100, 116, 139, var(--divide-opacity)) !important;
  }

  .xl\:divide-red-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FDF2F2 !important;
    border-color: rgba(253, 242, 242, var(--divide-opacity)) !important;
  }

  .xl\:divide-red-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FDE8E8 !important;
    border-color: rgba(253, 232, 232, var(--divide-opacity)) !important;
  }

  .xl\:divide-red-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FBD5D5 !important;
    border-color: rgba(251, 213, 213, var(--divide-opacity)) !important;
  }

  .xl\:divide-red-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F8B4B4 !important;
    border-color: rgba(248, 180, 180, var(--divide-opacity)) !important;
  }

  .xl\:divide-red-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F98080 !important;
    border-color: rgba(249, 128, 128, var(--divide-opacity)) !important;
  }

  .xl\:divide-red-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F05252 !important;
    border-color: rgba(240, 82, 82, var(--divide-opacity)) !important;
  }

  .xl\:divide-red-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E02424 !important;
    border-color: rgba(224, 36, 36, var(--divide-opacity)) !important;
  }

  .xl\:divide-red-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #C81E1E !important;
    border-color: rgba(200, 30, 30, var(--divide-opacity)) !important;
  }

  .xl\:divide-red-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9B1C1C !important;
    border-color: rgba(155, 28, 28, var(--divide-opacity)) !important;
  }

  .xl\:divide-red-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #771D1D !important;
    border-color: rgba(119, 29, 29, var(--divide-opacity)) !important;
  }

  .xl\:divide-red > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F05252 !important;
    border-color: rgba(240, 82, 82, var(--divide-opacity)) !important;
  }

  .xl\:divide-orange-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FFF8F1 !important;
    border-color: rgba(255, 248, 241, var(--divide-opacity)) !important;
  }

  .xl\:divide-orange-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FEECDC !important;
    border-color: rgba(254, 236, 220, var(--divide-opacity)) !important;
  }

  .xl\:divide-orange-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FCD9BD !important;
    border-color: rgba(252, 217, 189, var(--divide-opacity)) !important;
  }

  .xl\:divide-orange-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FDBA8C !important;
    border-color: rgba(253, 186, 140, var(--divide-opacity)) !important;
  }

  .xl\:divide-orange-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FF8A4C !important;
    border-color: rgba(255, 138, 76, var(--divide-opacity)) !important;
  }

  .xl\:divide-orange-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FF5A1F !important;
    border-color: rgba(255, 90, 31, var(--divide-opacity)) !important;
  }

  .xl\:divide-orange-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #ff6700 !important;
    border-color: rgba(255, 103, 0, var(--divide-opacity)) !important;
  }

  .xl\:divide-orange-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #B43403 !important;
    border-color: rgba(180, 52, 3, var(--divide-opacity)) !important;
  }

  .xl\:divide-orange-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #8A2C0D !important;
    border-color: rgba(138, 44, 13, var(--divide-opacity)) !important;
  }

  .xl\:divide-orange-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #771D1D !important;
    border-color: rgba(119, 29, 29, var(--divide-opacity)) !important;
  }

  .xl\:divide-orange > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FF5A1F !important;
    border-color: rgba(255, 90, 31, var(--divide-opacity)) !important;
  }

  .xl\:divide-yellow-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FDFDEA !important;
    border-color: rgba(253, 253, 234, var(--divide-opacity)) !important;
  }

  .xl\:divide-yellow-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FDF6B2 !important;
    border-color: rgba(253, 246, 178, var(--divide-opacity)) !important;
  }

  .xl\:divide-yellow-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FCE96A !important;
    border-color: rgba(252, 233, 106, var(--divide-opacity)) !important;
  }

  .xl\:divide-yellow-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FACA15 !important;
    border-color: rgba(250, 202, 21, var(--divide-opacity)) !important;
  }

  .xl\:divide-yellow-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E3A008 !important;
    border-color: rgba(227, 160, 8, var(--divide-opacity)) !important;
  }

  .xl\:divide-yellow-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #C27803 !important;
    border-color: rgba(194, 120, 3, var(--divide-opacity)) !important;
  }

  .xl\:divide-yellow-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9F580A !important;
    border-color: rgba(159, 88, 10, var(--divide-opacity)) !important;
  }

  .xl\:divide-yellow-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #8E4B10 !important;
    border-color: rgba(142, 75, 16, var(--divide-opacity)) !important;
  }

  .xl\:divide-yellow-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #723B13 !important;
    border-color: rgba(114, 59, 19, var(--divide-opacity)) !important;
  }

  .xl\:divide-yellow-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #633112 !important;
    border-color: rgba(99, 49, 18, var(--divide-opacity)) !important;
  }

  .xl\:divide-yellow > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #C27803 !important;
    border-color: rgba(194, 120, 3, var(--divide-opacity)) !important;
  }

  .xl\:divide-green-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F3FAF7 !important;
    border-color: rgba(243, 250, 247, var(--divide-opacity)) !important;
  }

  .xl\:divide-green-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #DEF7EC !important;
    border-color: rgba(222, 247, 236, var(--divide-opacity)) !important;
  }

  .xl\:divide-green-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #BCF0DA !important;
    border-color: rgba(188, 240, 218, var(--divide-opacity)) !important;
  }

  .xl\:divide-green-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #84E1BC !important;
    border-color: rgba(132, 225, 188, var(--divide-opacity)) !important;
  }

  .xl\:divide-green-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #31C48D !important;
    border-color: rgba(49, 196, 141, var(--divide-opacity)) !important;
  }

  .xl\:divide-green-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #0E9F6E !important;
    border-color: rgba(14, 159, 110, var(--divide-opacity)) !important;
  }

  .xl\:divide-green-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #057A55 !important;
    border-color: rgba(5, 122, 85, var(--divide-opacity)) !important;
  }

  .xl\:divide-green-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #046C4E !important;
    border-color: rgba(4, 108, 78, var(--divide-opacity)) !important;
  }

  .xl\:divide-green-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #03543F !important;
    border-color: rgba(3, 84, 63, var(--divide-opacity)) !important;
  }

  .xl\:divide-green-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #014737 !important;
    border-color: rgba(1, 71, 55, var(--divide-opacity)) !important;
  }

  .xl\:divide-green > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #0E9F6E !important;
    border-color: rgba(14, 159, 110, var(--divide-opacity)) !important;
  }

  .xl\:divide-teal-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #EDFAFA !important;
    border-color: rgba(237, 250, 250, var(--divide-opacity)) !important;
  }

  .xl\:divide-teal-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #D5F5F6 !important;
    border-color: rgba(213, 245, 246, var(--divide-opacity)) !important;
  }

  .xl\:divide-teal-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #AFECEF !important;
    border-color: rgba(175, 236, 239, var(--divide-opacity)) !important;
  }

  .xl\:divide-teal-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #7EDCE2 !important;
    border-color: rgba(126, 220, 226, var(--divide-opacity)) !important;
  }

  .xl\:divide-teal-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #16BDCA !important;
    border-color: rgba(22, 189, 202, var(--divide-opacity)) !important;
  }

  .xl\:divide-teal-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #0694A2 !important;
    border-color: rgba(6, 148, 162, var(--divide-opacity)) !important;
  }

  .xl\:divide-teal-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #047481 !important;
    border-color: rgba(4, 116, 129, var(--divide-opacity)) !important;
  }

  .xl\:divide-teal-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #036672 !important;
    border-color: rgba(3, 102, 114, var(--divide-opacity)) !important;
  }

  .xl\:divide-teal-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #05505C !important;
    border-color: rgba(5, 80, 92, var(--divide-opacity)) !important;
  }

  .xl\:divide-teal-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #014451 !important;
    border-color: rgba(1, 68, 81, var(--divide-opacity)) !important;
  }

  .xl\:divide-teal > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #0694A2 !important;
    border-color: rgba(6, 148, 162, var(--divide-opacity)) !important;
  }

  .xl\:divide-blue-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #EBF5FF !important;
    border-color: rgba(235, 245, 255, var(--divide-opacity)) !important;
  }

  .xl\:divide-blue-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E1EFFE !important;
    border-color: rgba(225, 239, 254, var(--divide-opacity)) !important;
  }

  .xl\:divide-blue-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #C3DDFD !important;
    border-color: rgba(195, 221, 253, var(--divide-opacity)) !important;
  }

  .xl\:divide-blue-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #A4CAFE !important;
    border-color: rgba(164, 202, 254, var(--divide-opacity)) !important;
  }

  .xl\:divide-blue-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #76A9FA !important;
    border-color: rgba(118, 169, 250, var(--divide-opacity)) !important;
  }

  .xl\:divide-blue-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #3F83F8 !important;
    border-color: rgba(63, 131, 248, var(--divide-opacity)) !important;
  }

  .xl\:divide-blue-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #1C64F2 !important;
    border-color: rgba(28, 100, 242, var(--divide-opacity)) !important;
  }

  .xl\:divide-blue-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #1A56DB !important;
    border-color: rgba(26, 86, 219, var(--divide-opacity)) !important;
  }

  .xl\:divide-blue-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #1E429F !important;
    border-color: rgba(30, 66, 159, var(--divide-opacity)) !important;
  }

  .xl\:divide-blue-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #233876 !important;
    border-color: rgba(35, 56, 118, var(--divide-opacity)) !important;
  }

  .xl\:divide-blue > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #3F83F8 !important;
    border-color: rgba(63, 131, 248, var(--divide-opacity)) !important;
  }

  .xl\:divide-indigo-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F0F5FF !important;
    border-color: rgba(240, 245, 255, var(--divide-opacity)) !important;
  }

  .xl\:divide-indigo-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E5EDFF !important;
    border-color: rgba(229, 237, 255, var(--divide-opacity)) !important;
  }

  .xl\:divide-indigo-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #CDDBFE !important;
    border-color: rgba(205, 219, 254, var(--divide-opacity)) !important;
  }

  .xl\:divide-indigo-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #B4C6FC !important;
    border-color: rgba(180, 198, 252, var(--divide-opacity)) !important;
  }

  .xl\:divide-indigo-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #8DA2FB !important;
    border-color: rgba(141, 162, 251, var(--divide-opacity)) !important;
  }

  .xl\:divide-indigo-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #6875F5 !important;
    border-color: rgba(104, 117, 245, var(--divide-opacity)) !important;
  }

  .xl\:divide-indigo-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #5850EC !important;
    border-color: rgba(88, 80, 236, var(--divide-opacity)) !important;
  }

  .xl\:divide-indigo-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #5145CD !important;
    border-color: rgba(81, 69, 205, var(--divide-opacity)) !important;
  }

  .xl\:divide-indigo-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #42389D !important;
    border-color: rgba(66, 56, 157, var(--divide-opacity)) !important;
  }

  .xl\:divide-indigo-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #362F78 !important;
    border-color: rgba(54, 47, 120, var(--divide-opacity)) !important;
  }

  .xl\:divide-indigo > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #6875F5 !important;
    border-color: rgba(104, 117, 245, var(--divide-opacity)) !important;
  }

  .xl\:divide-purple-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F6F5FF !important;
    border-color: rgba(246, 245, 255, var(--divide-opacity)) !important;
  }

  .xl\:divide-purple-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #EDEBFE !important;
    border-color: rgba(237, 235, 254, var(--divide-opacity)) !important;
  }

  .xl\:divide-purple-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #DCD7FE !important;
    border-color: rgba(220, 215, 254, var(--divide-opacity)) !important;
  }

  .xl\:divide-purple-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #CABFFD !important;
    border-color: rgba(202, 191, 253, var(--divide-opacity)) !important;
  }

  .xl\:divide-purple-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #AC94FA !important;
    border-color: rgba(172, 148, 250, var(--divide-opacity)) !important;
  }

  .xl\:divide-purple-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9061F9 !important;
    border-color: rgba(144, 97, 249, var(--divide-opacity)) !important;
  }

  .xl\:divide-purple-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #7E3AF2 !important;
    border-color: rgba(126, 58, 242, var(--divide-opacity)) !important;
  }

  .xl\:divide-purple-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #6C2BD9 !important;
    border-color: rgba(108, 43, 217, var(--divide-opacity)) !important;
  }

  .xl\:divide-purple-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #5521B5 !important;
    border-color: rgba(85, 33, 181, var(--divide-opacity)) !important;
  }

  .xl\:divide-purple-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #4A1D96 !important;
    border-color: rgba(74, 29, 150, var(--divide-opacity)) !important;
  }

  .xl\:divide-purple > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9061F9 !important;
    border-color: rgba(144, 97, 249, var(--divide-opacity)) !important;
  }

  .xl\:divide-pink-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FDF2F8 !important;
    border-color: rgba(253, 242, 248, var(--divide-opacity)) !important;
  }

  .xl\:divide-pink-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FCE8F3 !important;
    border-color: rgba(252, 232, 243, var(--divide-opacity)) !important;
  }

  .xl\:divide-pink-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FAD1E8 !important;
    border-color: rgba(250, 209, 232, var(--divide-opacity)) !important;
  }

  .xl\:divide-pink-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F8B4D9 !important;
    border-color: rgba(248, 180, 217, var(--divide-opacity)) !important;
  }

  .xl\:divide-pink-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F17EB8 !important;
    border-color: rgba(241, 126, 184, var(--divide-opacity)) !important;
  }

  .xl\:divide-pink-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E74694 !important;
    border-color: rgba(231, 70, 148, var(--divide-opacity)) !important;
  }

  .xl\:divide-pink-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #D61F69 !important;
    border-color: rgba(214, 31, 105, var(--divide-opacity)) !important;
  }

  .xl\:divide-pink-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #BF125D !important;
    border-color: rgba(191, 18, 93, var(--divide-opacity)) !important;
  }

  .xl\:divide-pink-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #99154B !important;
    border-color: rgba(153, 21, 75, var(--divide-opacity)) !important;
  }

  .xl\:divide-pink-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #751A3D !important;
    border-color: rgba(117, 26, 61, var(--divide-opacity)) !important;
  }

  .xl\:divide-pink > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E74694 !important;
    border-color: rgba(231, 70, 148, var(--divide-opacity)) !important;
  }

  .xl\:divide-opacity-0 > :not(template) ~ :not(template) {
    --divide-opacity: 0 !important;
  }

  .xl\:divide-opacity-12 > :not(template) ~ :not(template) {
    --divide-opacity: 0.12 !important;
  }

  .xl\:divide-opacity-25 > :not(template) ~ :not(template) {
    --divide-opacity: 0.25 !important;
  }

  .xl\:divide-opacity-38 > :not(template) ~ :not(template) {
    --divide-opacity: 0.38 !important;
  }

  .xl\:divide-opacity-50 > :not(template) ~ :not(template) {
    --divide-opacity: 0.5 !important;
  }

  .xl\:divide-opacity-54 > :not(template) ~ :not(template) {
    --divide-opacity: 0.54 !important;
  }

  .xl\:divide-opacity-70 > :not(template) ~ :not(template) {
    --divide-opacity: 0.70 !important;
  }

  .xl\:divide-opacity-75 > :not(template) ~ :not(template) {
    --divide-opacity: 0.75 !important;
  }

  .xl\:divide-opacity-84 > :not(template) ~ :not(template) {
    --divide-opacity: 0.84 !important;
  }

  .xl\:divide-opacity-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
  }

  .xl\:sr-only {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border-width: 0 !important;
  }

  .xl\:not-sr-only {
    position: static !important;
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: visible !important;
    clip: auto !important;
    white-space: normal !important;
  }

  .xl\:focus\:sr-only:focus {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border-width: 0 !important;
  }

  .xl\:focus\:not-sr-only:focus {
    position: static !important;
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: visible !important;
    clip: auto !important;
    white-space: normal !important;
  }

  .xl\:appearance-none {
    -webkit-appearance: none !important;
       -moz-appearance: none !important;
            appearance: none !important;
  }

  .xl\:bg-fixed {
    background-attachment: fixed !important;
  }

  .xl\:bg-local {
    background-attachment: local !important;
  }

  .xl\:bg-scroll {
    background-attachment: scroll !important;
  }

  .xl\:bg-opacity-0 {
    --bg-opacity: 0 !important;
  }

  .xl\:bg-opacity-12 {
    --bg-opacity: 0.12 !important;
  }

  .xl\:bg-opacity-25 {
    --bg-opacity: 0.25 !important;
  }

  .xl\:bg-opacity-38 {
    --bg-opacity: 0.38 !important;
  }

  .xl\:bg-opacity-50 {
    --bg-opacity: 0.5 !important;
  }

  .xl\:bg-opacity-54 {
    --bg-opacity: 0.54 !important;
  }

  .xl\:bg-opacity-70 {
    --bg-opacity: 0.70 !important;
  }

  .xl\:bg-opacity-75 {
    --bg-opacity: 0.75 !important;
  }

  .xl\:bg-opacity-84 {
    --bg-opacity: 0.84 !important;
  }

  .xl\:bg-opacity-100 {
    --bg-opacity: 1 !important;
  }

  .xl\:hover\:bg-opacity-0:hover {
    --bg-opacity: 0 !important;
  }

  .xl\:hover\:bg-opacity-12:hover {
    --bg-opacity: 0.12 !important;
  }

  .xl\:hover\:bg-opacity-25:hover {
    --bg-opacity: 0.25 !important;
  }

  .xl\:hover\:bg-opacity-38:hover {
    --bg-opacity: 0.38 !important;
  }

  .xl\:hover\:bg-opacity-50:hover {
    --bg-opacity: 0.5 !important;
  }

  .xl\:hover\:bg-opacity-54:hover {
    --bg-opacity: 0.54 !important;
  }

  .xl\:hover\:bg-opacity-70:hover {
    --bg-opacity: 0.70 !important;
  }

  .xl\:hover\:bg-opacity-75:hover {
    --bg-opacity: 0.75 !important;
  }

  .xl\:hover\:bg-opacity-84:hover {
    --bg-opacity: 0.84 !important;
  }

  .xl\:hover\:bg-opacity-100:hover {
    --bg-opacity: 1 !important;
  }

  .xl\:focus\:bg-opacity-0:focus {
    --bg-opacity: 0 !important;
  }

  .xl\:focus\:bg-opacity-12:focus {
    --bg-opacity: 0.12 !important;
  }

  .xl\:focus\:bg-opacity-25:focus {
    --bg-opacity: 0.25 !important;
  }

  .xl\:focus\:bg-opacity-38:focus {
    --bg-opacity: 0.38 !important;
  }

  .xl\:focus\:bg-opacity-50:focus {
    --bg-opacity: 0.5 !important;
  }

  .xl\:focus\:bg-opacity-54:focus {
    --bg-opacity: 0.54 !important;
  }

  .xl\:focus\:bg-opacity-70:focus {
    --bg-opacity: 0.70 !important;
  }

  .xl\:focus\:bg-opacity-75:focus {
    --bg-opacity: 0.75 !important;
  }

  .xl\:focus\:bg-opacity-84:focus {
    --bg-opacity: 0.84 !important;
  }

  .xl\:focus\:bg-opacity-100:focus {
    --bg-opacity: 1 !important;
  }

  .xl\:bg-bottom {
    background-position: bottom !important;
  }

  .xl\:bg-center {
    background-position: center !important;
  }

  .xl\:bg-left {
    background-position: left !important;
  }

  .xl\:bg-left-bottom {
    background-position: left bottom !important;
  }

  .xl\:bg-left-top {
    background-position: left top !important;
  }

  .xl\:bg-right {
    background-position: right !important;
  }

  .xl\:bg-right-bottom {
    background-position: right bottom !important;
  }

  .xl\:bg-right-top {
    background-position: right top !important;
  }

  .xl\:bg-top {
    background-position: top !important;
  }

  .xl\:bg-repeat {
    background-repeat: repeat !important;
  }

  .xl\:bg-no-repeat {
    background-repeat: no-repeat !important;
  }

  .xl\:bg-repeat-x {
    background-repeat: repeat-x !important;
  }

  .xl\:bg-repeat-y {
    background-repeat: repeat-y !important;
  }

  .xl\:bg-repeat-round {
    background-repeat: round !important;
  }

  .xl\:bg-repeat-space {
    background-repeat: space !important;
  }

  .xl\:bg-auto {
    background-size: auto !important;
  }

  .xl\:bg-cover {
    background-size: cover !important;
  }

  .xl\:bg-contain {
    background-size: contain !important;
  }

  .xl\:border-collapse {
    border-collapse: collapse !important;
  }

  .xl\:border-separate {
    border-collapse: separate !important;
  }

  .xl\:border-opacity-0 {
    --border-opacity: 0 !important;
  }

  .xl\:border-opacity-12 {
    --border-opacity: 0.12 !important;
  }

  .xl\:border-opacity-25 {
    --border-opacity: 0.25 !important;
  }

  .xl\:border-opacity-38 {
    --border-opacity: 0.38 !important;
  }

  .xl\:border-opacity-50 {
    --border-opacity: 0.5 !important;
  }

  .xl\:border-opacity-54 {
    --border-opacity: 0.54 !important;
  }

  .xl\:border-opacity-70 {
    --border-opacity: 0.70 !important;
  }

  .xl\:border-opacity-75 {
    --border-opacity: 0.75 !important;
  }

  .xl\:border-opacity-84 {
    --border-opacity: 0.84 !important;
  }

  .xl\:border-opacity-100 {
    --border-opacity: 1 !important;
  }

  .xl\:hover\:border-opacity-0:hover {
    --border-opacity: 0 !important;
  }

  .xl\:hover\:border-opacity-12:hover {
    --border-opacity: 0.12 !important;
  }

  .xl\:hover\:border-opacity-25:hover {
    --border-opacity: 0.25 !important;
  }

  .xl\:hover\:border-opacity-38:hover {
    --border-opacity: 0.38 !important;
  }

  .xl\:hover\:border-opacity-50:hover {
    --border-opacity: 0.5 !important;
  }

  .xl\:hover\:border-opacity-54:hover {
    --border-opacity: 0.54 !important;
  }

  .xl\:hover\:border-opacity-70:hover {
    --border-opacity: 0.70 !important;
  }

  .xl\:hover\:border-opacity-75:hover {
    --border-opacity: 0.75 !important;
  }

  .xl\:hover\:border-opacity-84:hover {
    --border-opacity: 0.84 !important;
  }

  .xl\:hover\:border-opacity-100:hover {
    --border-opacity: 1 !important;
  }

  .xl\:focus\:border-opacity-0:focus {
    --border-opacity: 0 !important;
  }

  .xl\:focus\:border-opacity-12:focus {
    --border-opacity: 0.12 !important;
  }

  .xl\:focus\:border-opacity-25:focus {
    --border-opacity: 0.25 !important;
  }

  .xl\:focus\:border-opacity-38:focus {
    --border-opacity: 0.38 !important;
  }

  .xl\:focus\:border-opacity-50:focus {
    --border-opacity: 0.5 !important;
  }

  .xl\:focus\:border-opacity-54:focus {
    --border-opacity: 0.54 !important;
  }

  .xl\:focus\:border-opacity-70:focus {
    --border-opacity: 0.70 !important;
  }

  .xl\:focus\:border-opacity-75:focus {
    --border-opacity: 0.75 !important;
  }

  .xl\:focus\:border-opacity-84:focus {
    --border-opacity: 0.84 !important;
  }

  .xl\:focus\:border-opacity-100:focus {
    --border-opacity: 1 !important;
  }

  .xl\:rounded-none {
    border-radius: 0 !important;
  }

  .xl\:rounded-sm {
    border-radius: 0.125rem !important;
  }

  .xl\:rounded {
    border-radius: 0.25rem !important;
  }

  .xl\:rounded-md {
    border-radius: 0.375rem !important;
  }

  .xl\:rounded-lg {
    border-radius: 0.5rem !important;
  }

  .xl\:rounded-full {
    border-radius: 9999px !important;
  }

  .xl\:rounded-t-none {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .xl\:rounded-r-none {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .xl\:rounded-b-none {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .xl\:rounded-l-none {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .xl\:rounded-t-sm {
    border-top-left-radius: 0.125rem !important;
    border-top-right-radius: 0.125rem !important;
  }

  .xl\:rounded-r-sm {
    border-top-right-radius: 0.125rem !important;
    border-bottom-right-radius: 0.125rem !important;
  }

  .xl\:rounded-b-sm {
    border-bottom-right-radius: 0.125rem !important;
    border-bottom-left-radius: 0.125rem !important;
  }

  .xl\:rounded-l-sm {
    border-top-left-radius: 0.125rem !important;
    border-bottom-left-radius: 0.125rem !important;
  }

  .xl\:rounded-t {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }

  .xl\:rounded-r {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }

  .xl\:rounded-b {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }

  .xl\:rounded-l {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }

  .xl\:rounded-t-md {
    border-top-left-radius: 0.375rem !important;
    border-top-right-radius: 0.375rem !important;
  }

  .xl\:rounded-r-md {
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }

  .xl\:rounded-b-md {
    border-bottom-right-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }

  .xl\:rounded-l-md {
    border-top-left-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }

  .xl\:rounded-t-lg {
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
  }

  .xl\:rounded-r-lg {
    border-top-right-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }

  .xl\:rounded-b-lg {
    border-bottom-right-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }

  .xl\:rounded-l-lg {
    border-top-left-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }

  .xl\:rounded-t-full {
    border-top-left-radius: 9999px !important;
    border-top-right-radius: 9999px !important;
  }

  .xl\:rounded-r-full {
    border-top-right-radius: 9999px !important;
    border-bottom-right-radius: 9999px !important;
  }

  .xl\:rounded-b-full {
    border-bottom-right-radius: 9999px !important;
    border-bottom-left-radius: 9999px !important;
  }

  .xl\:rounded-l-full {
    border-top-left-radius: 9999px !important;
    border-bottom-left-radius: 9999px !important;
  }

  .xl\:rounded-tl-none {
    border-top-left-radius: 0 !important;
  }

  .xl\:rounded-tr-none {
    border-top-right-radius: 0 !important;
  }

  .xl\:rounded-br-none {
    border-bottom-right-radius: 0 !important;
  }

  .xl\:rounded-bl-none {
    border-bottom-left-radius: 0 !important;
  }

  .xl\:rounded-tl-sm {
    border-top-left-radius: 0.125rem !important;
  }

  .xl\:rounded-tr-sm {
    border-top-right-radius: 0.125rem !important;
  }

  .xl\:rounded-br-sm {
    border-bottom-right-radius: 0.125rem !important;
  }

  .xl\:rounded-bl-sm {
    border-bottom-left-radius: 0.125rem !important;
  }

  .xl\:rounded-tl {
    border-top-left-radius: 0.25rem !important;
  }

  .xl\:rounded-tr {
    border-top-right-radius: 0.25rem !important;
  }

  .xl\:rounded-br {
    border-bottom-right-radius: 0.25rem !important;
  }

  .xl\:rounded-bl {
    border-bottom-left-radius: 0.25rem !important;
  }

  .xl\:rounded-tl-md {
    border-top-left-radius: 0.375rem !important;
  }

  .xl\:rounded-tr-md {
    border-top-right-radius: 0.375rem !important;
  }

  .xl\:rounded-br-md {
    border-bottom-right-radius: 0.375rem !important;
  }

  .xl\:rounded-bl-md {
    border-bottom-left-radius: 0.375rem !important;
  }

  .xl\:rounded-tl-lg {
    border-top-left-radius: 0.5rem !important;
  }

  .xl\:rounded-tr-lg {
    border-top-right-radius: 0.5rem !important;
  }

  .xl\:rounded-br-lg {
    border-bottom-right-radius: 0.5rem !important;
  }

  .xl\:rounded-bl-lg {
    border-bottom-left-radius: 0.5rem !important;
  }

  .xl\:rounded-tl-full {
    border-top-left-radius: 9999px !important;
  }

  .xl\:rounded-tr-full {
    border-top-right-radius: 9999px !important;
  }

  .xl\:rounded-br-full {
    border-bottom-right-radius: 9999px !important;
  }

  .xl\:rounded-bl-full {
    border-bottom-left-radius: 9999px !important;
  }

  .xl\:border-solid {
    border-style: solid !important;
  }

  .xl\:border-dashed {
    border-style: dashed !important;
  }

  .xl\:border-dotted {
    border-style: dotted !important;
  }

  .xl\:border-double {
    border-style: double !important;
  }

  .xl\:border-none {
    border-style: none !important;
  }

  .xl\:border-0 {
    border-width: 0 !important;
  }

  .xl\:border-2 {
    border-width: 2px !important;
  }

  .xl\:border-4 {
    border-width: 4px !important;
  }

  .xl\:border-8 {
    border-width: 8px !important;
  }

  .xl\:border {
    border-width: 1px !important;
  }

  .xl\:border-t-0 {
    border-top-width: 0 !important;
  }

  .xl\:border-r-0 {
    border-right-width: 0 !important;
  }

  .xl\:border-b-0 {
    border-bottom-width: 0 !important;
  }

  .xl\:border-l-0 {
    border-left-width: 0 !important;
  }

  .xl\:border-t-2 {
    border-top-width: 2px !important;
  }

  .xl\:border-r-2 {
    border-right-width: 2px !important;
  }

  .xl\:border-b-2 {
    border-bottom-width: 2px !important;
  }

  .xl\:border-l-2 {
    border-left-width: 2px !important;
  }

  .xl\:border-t-4 {
    border-top-width: 4px !important;
  }

  .xl\:border-r-4 {
    border-right-width: 4px !important;
  }

  .xl\:border-b-4 {
    border-bottom-width: 4px !important;
  }

  .xl\:border-l-4 {
    border-left-width: 4px !important;
  }

  .xl\:border-t-8 {
    border-top-width: 8px !important;
  }

  .xl\:border-r-8 {
    border-right-width: 8px !important;
  }

  .xl\:border-b-8 {
    border-bottom-width: 8px !important;
  }

  .xl\:border-l-8 {
    border-left-width: 8px !important;
  }

  .xl\:border-t {
    border-top-width: 1px !important;
  }

  .xl\:border-r {
    border-right-width: 1px !important;
  }

  .xl\:border-b {
    border-bottom-width: 1px !important;
  }

  .xl\:border-l {
    border-left-width: 1px !important;
  }

  .xl\:first\:border-0:first-child {
    border-width: 0 !important;
  }

  .xl\:first\:border-2:first-child {
    border-width: 2px !important;
  }

  .xl\:first\:border-4:first-child {
    border-width: 4px !important;
  }

  .xl\:first\:border-8:first-child {
    border-width: 8px !important;
  }

  .xl\:first\:border:first-child {
    border-width: 1px !important;
  }

  .xl\:first\:border-t-0:first-child {
    border-top-width: 0 !important;
  }

  .xl\:first\:border-r-0:first-child {
    border-right-width: 0 !important;
  }

  .xl\:first\:border-b-0:first-child {
    border-bottom-width: 0 !important;
  }

  .xl\:first\:border-l-0:first-child {
    border-left-width: 0 !important;
  }

  .xl\:first\:border-t-2:first-child {
    border-top-width: 2px !important;
  }

  .xl\:first\:border-r-2:first-child {
    border-right-width: 2px !important;
  }

  .xl\:first\:border-b-2:first-child {
    border-bottom-width: 2px !important;
  }

  .xl\:first\:border-l-2:first-child {
    border-left-width: 2px !important;
  }

  .xl\:first\:border-t-4:first-child {
    border-top-width: 4px !important;
  }

  .xl\:first\:border-r-4:first-child {
    border-right-width: 4px !important;
  }

  .xl\:first\:border-b-4:first-child {
    border-bottom-width: 4px !important;
  }

  .xl\:first\:border-l-4:first-child {
    border-left-width: 4px !important;
  }

  .xl\:first\:border-t-8:first-child {
    border-top-width: 8px !important;
  }

  .xl\:first\:border-r-8:first-child {
    border-right-width: 8px !important;
  }

  .xl\:first\:border-b-8:first-child {
    border-bottom-width: 8px !important;
  }

  .xl\:first\:border-l-8:first-child {
    border-left-width: 8px !important;
  }

  .xl\:first\:border-t:first-child {
    border-top-width: 1px !important;
  }

  .xl\:first\:border-r:first-child {
    border-right-width: 1px !important;
  }

  .xl\:first\:border-b:first-child {
    border-bottom-width: 1px !important;
  }

  .xl\:first\:border-l:first-child {
    border-left-width: 1px !important;
  }

  .xl\:last\:border-0:last-child {
    border-width: 0 !important;
  }

  .xl\:last\:border-2:last-child {
    border-width: 2px !important;
  }

  .xl\:last\:border-4:last-child {
    border-width: 4px !important;
  }

  .xl\:last\:border-8:last-child {
    border-width: 8px !important;
  }

  .xl\:last\:border:last-child {
    border-width: 1px !important;
  }

  .xl\:last\:border-t-0:last-child {
    border-top-width: 0 !important;
  }

  .xl\:last\:border-r-0:last-child {
    border-right-width: 0 !important;
  }

  .xl\:last\:border-b-0:last-child {
    border-bottom-width: 0 !important;
  }

  .xl\:last\:border-l-0:last-child {
    border-left-width: 0 !important;
  }

  .xl\:last\:border-t-2:last-child {
    border-top-width: 2px !important;
  }

  .xl\:last\:border-r-2:last-child {
    border-right-width: 2px !important;
  }

  .xl\:last\:border-b-2:last-child {
    border-bottom-width: 2px !important;
  }

  .xl\:last\:border-l-2:last-child {
    border-left-width: 2px !important;
  }

  .xl\:last\:border-t-4:last-child {
    border-top-width: 4px !important;
  }

  .xl\:last\:border-r-4:last-child {
    border-right-width: 4px !important;
  }

  .xl\:last\:border-b-4:last-child {
    border-bottom-width: 4px !important;
  }

  .xl\:last\:border-l-4:last-child {
    border-left-width: 4px !important;
  }

  .xl\:last\:border-t-8:last-child {
    border-top-width: 8px !important;
  }

  .xl\:last\:border-r-8:last-child {
    border-right-width: 8px !important;
  }

  .xl\:last\:border-b-8:last-child {
    border-bottom-width: 8px !important;
  }

  .xl\:last\:border-l-8:last-child {
    border-left-width: 8px !important;
  }

  .xl\:last\:border-t:last-child {
    border-top-width: 1px !important;
  }

  .xl\:last\:border-r:last-child {
    border-right-width: 1px !important;
  }

  .xl\:last\:border-b:last-child {
    border-bottom-width: 1px !important;
  }

  .xl\:last\:border-l:last-child {
    border-left-width: 1px !important;
  }

  .xl\:box-border {
    box-sizing: border-box !important;
  }

  .xl\:box-content {
    box-sizing: content-box !important;
  }

  .xl\:block {
    display: block !important;
  }

  .xl\:inline-block {
    display: inline-block !important;
  }

  .xl\:inline {
    display: inline !important;
  }

  .xl\:flex {
    display: flex !important;
  }

  .xl\:inline-flex {
    display: inline-flex !important;
  }

  .xl\:table {
    display: table !important;
  }

  .xl\:table-caption {
    display: table-caption !important;
  }

  .xl\:table-cell {
    display: table-cell !important;
  }

  .xl\:table-column {
    display: table-column !important;
  }

  .xl\:table-column-group {
    display: table-column-group !important;
  }

  .xl\:table-footer-group {
    display: table-footer-group !important;
  }

  .xl\:table-header-group {
    display: table-header-group !important;
  }

  .xl\:table-row-group {
    display: table-row-group !important;
  }

  .xl\:table-row {
    display: table-row !important;
  }

  .xl\:flow-root {
    display: flow-root !important;
  }

  .xl\:grid {
    display: grid !important;
  }

  .xl\:inline-grid {
    display: inline-grid !important;
  }

  .xl\:hidden {
    display: none !important;
  }

  .xl\:flex-row {
    flex-direction: row !important;
  }

  .xl\:flex-row-reverse {
    flex-direction: row-reverse !important;
  }

  .xl\:flex-col {
    flex-direction: column !important;
  }

  .xl\:flex-col-reverse {
    flex-direction: column-reverse !important;
  }

  .xl\:flex-wrap {
    flex-wrap: wrap !important;
  }

  .xl\:flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .xl\:flex-nowrap {
    flex-wrap: nowrap !important;
  }

  .xl\:items-start {
    align-items: flex-start !important;
  }

  .xl\:items-end {
    align-items: flex-end !important;
  }

  .xl\:items-center {
    align-items: center !important;
  }

  .xl\:items-baseline {
    align-items: baseline !important;
  }

  .xl\:items-stretch {
    align-items: stretch !important;
  }

  .xl\:self-auto {
    align-self: auto !important;
  }

  .xl\:self-start {
    align-self: flex-start !important;
  }

  .xl\:self-end {
    align-self: flex-end !important;
  }

  .xl\:self-center {
    align-self: center !important;
  }

  .xl\:self-stretch {
    align-self: stretch !important;
  }

  .xl\:justify-start {
    justify-content: flex-start !important;
  }

  .xl\:justify-end {
    justify-content: flex-end !important;
  }

  .xl\:justify-center {
    justify-content: center !important;
  }

  .xl\:justify-between {
    justify-content: space-between !important;
  }

  .xl\:justify-around {
    justify-content: space-around !important;
  }

  .xl\:justify-evenly {
    justify-content: space-evenly !important;
  }

  .xl\:content-center {
    align-content: center !important;
  }

  .xl\:content-start {
    align-content: flex-start !important;
  }

  .xl\:content-end {
    align-content: flex-end !important;
  }

  .xl\:content-between {
    align-content: space-between !important;
  }

  .xl\:content-around {
    align-content: space-around !important;
  }

  .xl\:flex-0 {
    flex: 0 0 auto !important;
  }

  .xl\:flex-1 {
    flex: 1 1 0% !important;
  }

  .xl\:flex-auto {
    flex: 1 1 auto !important;
  }

  .xl\:flex-initial {
    flex: 0 1 auto !important;
  }

  .xl\:flex-none {
    flex: none !important;
  }

  .xl\:flex-grow-0 {
    flex-grow: 0 !important;
  }

  .xl\:flex-grow {
    flex-grow: 1 !important;
  }

  .xl\:flex-shrink-0 {
    flex-shrink: 0 !important;
  }

  .xl\:flex-shrink {
    flex-shrink: 1 !important;
  }

  .xl\:order-1 {
    order: 1 !important;
  }

  .xl\:order-2 {
    order: 2 !important;
  }

  .xl\:order-3 {
    order: 3 !important;
  }

  .xl\:order-4 {
    order: 4 !important;
  }

  .xl\:order-5 {
    order: 5 !important;
  }

  .xl\:order-6 {
    order: 6 !important;
  }

  .xl\:order-7 {
    order: 7 !important;
  }

  .xl\:order-8 {
    order: 8 !important;
  }

  .xl\:order-9 {
    order: 9 !important;
  }

  .xl\:order-10 {
    order: 10 !important;
  }

  .xl\:order-11 {
    order: 11 !important;
  }

  .xl\:order-12 {
    order: 12 !important;
  }

  .xl\:order-first {
    order: -9999 !important;
  }

  .xl\:order-last {
    order: 9999 !important;
  }

  .xl\:order-none {
    order: 0 !important;
  }

  .xl\:font-thin {
    font-weight: 100 !important;
  }

  .xl\:font-extralight {
    font-weight: 200 !important;
  }

  .xl\:font-light {
    font-weight: 300 !important;
  }

  .xl\:font-normal {
    font-weight: 400 !important;
  }

  .xl\:font-medium {
    font-weight: 500 !important;
  }

  .xl\:font-semibold {
    font-weight: 600 !important;
  }

  .xl\:font-bold {
    font-weight: 700 !important;
  }

  .xl\:font-extrabold {
    font-weight: 800 !important;
  }

  .xl\:font-black {
    font-weight: 900 !important;
  }

  .xl\:h-0 {
    height: 0 !important;
  }

  .xl\:h-1 {
    height: 0.25rem !important;
  }

  .xl\:h-2 {
    height: 0.5rem !important;
  }

  .xl\:h-3 {
    height: 0.75rem !important;
  }

  .xl\:h-4 {
    height: 1rem !important;
  }

  .xl\:h-5 {
    height: 1.25rem !important;
  }

  .xl\:h-6 {
    height: 1.5rem !important;
  }

  .xl\:h-8 {
    height: 2rem !important;
  }

  .xl\:h-10 {
    height: 2.5rem !important;
  }

  .xl\:h-12 {
    height: 3rem !important;
  }

  .xl\:h-14 {
    height: 3.5rem !important;
  }

  .xl\:h-16 {
    height: 4rem !important;
  }

  .xl\:h-18 {
    height: 4.5rem !important;
  }

  .xl\:h-20 {
    height: 5rem !important;
  }

  .xl\:h-22 {
    height: 5.5rem !important;
  }

  .xl\:h-24 {
    height: 6rem !important;
  }

  .xl\:h-26 {
    height: 6.5rem !important;
  }

  .xl\:h-28 {
    height: 7rem !important;
  }

  .xl\:h-30 {
    height: 7.5rem !important;
  }

  .xl\:h-32 {
    height: 8rem !important;
  }

  .xl\:h-36 {
    height: 9rem !important;
  }

  .xl\:h-40 {
    height: 10rem !important;
  }

  .xl\:h-48 {
    height: 12rem !important;
  }

  .xl\:h-50 {
    height: 12.5rem !important;
  }

  .xl\:h-56 {
    height: 14rem !important;
  }

  .xl\:h-60 {
    height: 15rem !important;
  }

  .xl\:h-64 {
    height: 16rem !important;
  }

  .xl\:h-80 {
    height: 20rem !important;
  }

  .xl\:h-90 {
    height: 24rem !important;
  }

  .xl\:h-100 {
    height: 25rem !important;
  }

  .xl\:h-120 {
    height: 30rem !important;
  }

  .xl\:h-128 {
    height: 32rem !important;
  }

  .xl\:h-140 {
    height: 35rem !important;
  }

  .xl\:h-160 {
    height: 40rem !important;
  }

  .xl\:h-180 {
    height: 45rem !important;
  }

  .xl\:h-192 {
    height: 48rem !important;
  }

  .xl\:h-200 {
    height: 50rem !important;
  }

  .xl\:h-240 {
    height: 60rem !important;
  }

  .xl\:h-256 {
    height: 64rem !important;
  }

  .xl\:h-280 {
    height: 70rem !important;
  }

  .xl\:h-320 {
    height: 80rem !important;
  }

  .xl\:h-360 {
    height: 90rem !important;
  }

  .xl\:h-400 {
    height: 100rem !important;
  }

  .xl\:h-480 {
    height: 120rem !important;
  }

  .xl\:h-auto {
    height: auto !important;
  }

  .xl\:h-px {
    height: 1px !important;
  }

  .xl\:h-2px {
    height: 2px !important;
  }

  .xl\:h-full {
    height: 100% !important;
  }

  .xl\:h-screen {
    height: 100vh !important;
  }

  .xl\:h-1\/2 {
    height: 50% !important;
  }

  .xl\:h-1\/3 {
    height: 33.33333% !important;
  }

  .xl\:h-2\/3 {
    height: 66.66667% !important;
  }

  .xl\:h-1\/4 {
    height: 25% !important;
  }

  .xl\:h-2\/4 {
    height: 50% !important;
  }

  .xl\:h-3\/4 {
    height: 75% !important;
  }

  .xl\:h-1\/5 {
    height: 20% !important;
  }

  .xl\:h-2\/5 {
    height: 40% !important;
  }

  .xl\:h-3\/5 {
    height: 60% !important;
  }

  .xl\:h-4\/5 {
    height: 80% !important;
  }

  .xl\:h-1\/12 {
    height: 8.33333% !important;
  }

  .xl\:h-2\/12 {
    height: 16.66667% !important;
  }

  .xl\:h-3\/12 {
    height: 25% !important;
  }

  .xl\:h-4\/12 {
    height: 33.33333% !important;
  }

  .xl\:h-5\/12 {
    height: 41.66667% !important;
  }

  .xl\:h-6\/12 {
    height: 50% !important;
  }

  .xl\:h-7\/12 {
    height: 58.33333% !important;
  }

  .xl\:h-8\/12 {
    height: 66.66667% !important;
  }

  .xl\:h-9\/12 {
    height: 75% !important;
  }

  .xl\:h-10\/12 {
    height: 83.33333% !important;
  }

  .xl\:h-11\/12 {
    height: 91.66667% !important;
  }

  .xl\:text-xs {
    font-size: 0.625rem !important;
  }

  .xl\:text-sm {
    font-size: 0.75rem !important;
  }

  .xl\:text-md {
    font-size: 0.8125rem !important;
  }

  .xl\:text-base {
    font-size: 0.875rem !important;
  }

  .xl\:text-lg {
    font-size: 1rem !important;
  }

  .xl\:text-xl {
    font-size: 1.125rem !important;
  }

  .xl\:text-2xl {
    font-size: 1.25rem !important;
  }

  .xl\:text-3xl {
    font-size: 1.5rem !important;
  }

  .xl\:text-4xl {
    font-size: 2rem !important;
  }

  .xl\:text-5xl {
    font-size: 2.25rem !important;
  }

  .xl\:text-6xl {
    font-size: 2.5rem !important;
  }

  .xl\:text-7xl {
    font-size: 3rem !important;
  }

  .xl\:text-8xl {
    font-size: 4rem !important;
  }

  .xl\:text-9xl {
    font-size: 6rem !important;
  }

  .xl\:text-10xl {
    font-size: 8rem !important;
  }

  .xl\:leading-3 {
    line-height: .75rem !important;
  }

  .xl\:leading-4 {
    line-height: 1rem !important;
  }

  .xl\:leading-5 {
    line-height: 1.25rem !important;
  }

  .xl\:leading-6 {
    line-height: 1.5rem !important;
  }

  .xl\:leading-7 {
    line-height: 1.75rem !important;
  }

  .xl\:leading-8 {
    line-height: 2rem !important;
  }

  .xl\:leading-9 {
    line-height: 2.25rem !important;
  }

  .xl\:leading-10 {
    line-height: 2.5rem !important;
  }

  .xl\:leading-none {
    line-height: 1 !important;
  }

  .xl\:leading-tight {
    line-height: 1.25 !important;
  }

  .xl\:leading-snug {
    line-height: 1.375 !important;
  }

  .xl\:leading-normal {
    line-height: 1.5 !important;
  }

  .xl\:leading-relaxed {
    line-height: 1.625 !important;
  }

  .xl\:leading-loose {
    line-height: 2 !important;
  }

  .xl\:list-inside {
    list-style-position: inside !important;
  }

  .xl\:list-outside {
    list-style-position: outside !important;
  }

  .xl\:list-none {
    list-style-type: none !important;
  }

  .xl\:list-disc {
    list-style-type: disc !important;
  }

  .xl\:list-decimal {
    list-style-type: decimal !important;
  }

  .xl\:m-0 {
    margin: 0 !important;
  }

  .xl\:m-1 {
    margin: 0.25rem !important;
  }

  .xl\:m-2 {
    margin: 0.5rem !important;
  }

  .xl\:m-3 {
    margin: 0.75rem !important;
  }

  .xl\:m-4 {
    margin: 1rem !important;
  }

  .xl\:m-5 {
    margin: 1.25rem !important;
  }

  .xl\:m-6 {
    margin: 1.5rem !important;
  }

  .xl\:m-8 {
    margin: 2rem !important;
  }

  .xl\:m-10 {
    margin: 2.5rem !important;
  }

  .xl\:m-12 {
    margin: 3rem !important;
  }

  .xl\:m-14 {
    margin: 3.5rem !important;
  }

  .xl\:m-16 {
    margin: 4rem !important;
  }

  .xl\:m-18 {
    margin: 4.5rem !important;
  }

  .xl\:m-20 {
    margin: 5rem !important;
  }

  .xl\:m-22 {
    margin: 5.5rem !important;
  }

  .xl\:m-24 {
    margin: 6rem !important;
  }

  .xl\:m-26 {
    margin: 6.5rem !important;
  }

  .xl\:m-28 {
    margin: 7rem !important;
  }

  .xl\:m-30 {
    margin: 7.5rem !important;
  }

  .xl\:m-32 {
    margin: 8rem !important;
  }

  .xl\:m-36 {
    margin: 9rem !important;
  }

  .xl\:m-40 {
    margin: 10rem !important;
  }

  .xl\:m-48 {
    margin: 12rem !important;
  }

  .xl\:m-56 {
    margin: 14rem !important;
  }

  .xl\:m-64 {
    margin: 16rem !important;
  }

  .xl\:m-auto {
    margin: auto !important;
  }

  .xl\:m-px {
    margin: 1px !important;
  }

  .xl\:m-2px {
    margin: 2px !important;
  }

  .xl\:-m-1 {
    margin: -0.25rem !important;
  }

  .xl\:-m-2 {
    margin: -0.5rem !important;
  }

  .xl\:-m-3 {
    margin: -0.75rem !important;
  }

  .xl\:-m-4 {
    margin: -1rem !important;
  }

  .xl\:-m-5 {
    margin: -1.25rem !important;
  }

  .xl\:-m-6 {
    margin: -1.5rem !important;
  }

  .xl\:-m-8 {
    margin: -2rem !important;
  }

  .xl\:-m-10 {
    margin: -2.5rem !important;
  }

  .xl\:-m-12 {
    margin: -3rem !important;
  }

  .xl\:-m-14 {
    margin: -3.5rem !important;
  }

  .xl\:-m-16 {
    margin: -4rem !important;
  }

  .xl\:-m-18 {
    margin: -4.5rem !important;
  }

  .xl\:-m-20 {
    margin: -5rem !important;
  }

  .xl\:-m-22 {
    margin: -5.5rem !important;
  }

  .xl\:-m-24 {
    margin: -6rem !important;
  }

  .xl\:-m-26 {
    margin: -6.5rem !important;
  }

  .xl\:-m-28 {
    margin: -7rem !important;
  }

  .xl\:-m-30 {
    margin: -7.5rem !important;
  }

  .xl\:-m-32 {
    margin: -8rem !important;
  }

  .xl\:-m-36 {
    margin: -9rem !important;
  }

  .xl\:-m-40 {
    margin: -10rem !important;
  }

  .xl\:-m-48 {
    margin: -12rem !important;
  }

  .xl\:-m-56 {
    margin: -14rem !important;
  }

  .xl\:-m-64 {
    margin: -16rem !important;
  }

  .xl\:-m-px {
    margin: -1px !important;
  }

  .xl\:-m-2px {
    margin: -2px !important;
  }

  .xl\:my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .xl\:mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .xl\:my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .xl\:mx-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }

  .xl\:my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .xl\:mx-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .xl\:my-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .xl\:mx-3 {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
  }

  .xl\:my-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .xl\:mx-4 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .xl\:my-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .xl\:mx-5 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }

  .xl\:my-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .xl\:mx-6 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .xl\:my-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .xl\:mx-8 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .xl\:my-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .xl\:mx-10 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .xl\:my-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .xl\:mx-12 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .xl\:my-14 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .xl\:mx-14 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }

  .xl\:my-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .xl\:mx-16 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .xl\:my-18 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .xl\:mx-18 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }

  .xl\:my-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .xl\:mx-20 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .xl\:my-22 {
    margin-top: 5.5rem !important;
    margin-bottom: 5.5rem !important;
  }

  .xl\:mx-22 {
    margin-left: 5.5rem !important;
    margin-right: 5.5rem !important;
  }

  .xl\:my-24 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .xl\:mx-24 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }

  .xl\:my-26 {
    margin-top: 6.5rem !important;
    margin-bottom: 6.5rem !important;
  }

  .xl\:mx-26 {
    margin-left: 6.5rem !important;
    margin-right: 6.5rem !important;
  }

  .xl\:my-28 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }

  .xl\:mx-28 {
    margin-left: 7rem !important;
    margin-right: 7rem !important;
  }

  .xl\:my-30 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }

  .xl\:mx-30 {
    margin-left: 7.5rem !important;
    margin-right: 7.5rem !important;
  }

  .xl\:my-32 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .xl\:mx-32 {
    margin-left: 8rem !important;
    margin-right: 8rem !important;
  }

  .xl\:my-36 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }

  .xl\:mx-36 {
    margin-left: 9rem !important;
    margin-right: 9rem !important;
  }

  .xl\:my-40 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .xl\:mx-40 {
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }

  .xl\:my-48 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }

  .xl\:mx-48 {
    margin-left: 12rem !important;
    margin-right: 12rem !important;
  }

  .xl\:my-56 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }

  .xl\:mx-56 {
    margin-left: 14rem !important;
    margin-right: 14rem !important;
  }

  .xl\:my-64 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }

  .xl\:mx-64 {
    margin-left: 16rem !important;
    margin-right: 16rem !important;
  }

  .xl\:my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .xl\:mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .xl\:my-px {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  .xl\:mx-px {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }

  .xl\:my-2px {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }

  .xl\:mx-2px {
    margin-left: 2px !important;
    margin-right: 2px !important;
  }

  .xl\:-my-1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .xl\:-mx-1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }

  .xl\:-my-2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .xl\:-mx-2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }

  .xl\:-my-3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }

  .xl\:-mx-3 {
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important;
  }

  .xl\:-my-4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .xl\:-mx-4 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .xl\:-my-5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .xl\:-mx-5 {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }

  .xl\:-my-6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .xl\:-mx-6 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .xl\:-my-8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .xl\:-mx-8 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .xl\:-my-10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .xl\:-mx-10 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }

  .xl\:-my-12 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .xl\:-mx-12 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .xl\:-my-14 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .xl\:-mx-14 {
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }

  .xl\:-my-16 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .xl\:-mx-16 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .xl\:-my-18 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .xl\:-mx-18 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }

  .xl\:-my-20 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .xl\:-mx-20 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .xl\:-my-22 {
    margin-top: -5.5rem !important;
    margin-bottom: -5.5rem !important;
  }

  .xl\:-mx-22 {
    margin-left: -5.5rem !important;
    margin-right: -5.5rem !important;
  }

  .xl\:-my-24 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .xl\:-mx-24 {
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }

  .xl\:-my-26 {
    margin-top: -6.5rem !important;
    margin-bottom: -6.5rem !important;
  }

  .xl\:-mx-26 {
    margin-left: -6.5rem !important;
    margin-right: -6.5rem !important;
  }

  .xl\:-my-28 {
    margin-top: -7rem !important;
    margin-bottom: -7rem !important;
  }

  .xl\:-mx-28 {
    margin-left: -7rem !important;
    margin-right: -7rem !important;
  }

  .xl\:-my-30 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important;
  }

  .xl\:-mx-30 {
    margin-left: -7.5rem !important;
    margin-right: -7.5rem !important;
  }

  .xl\:-my-32 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .xl\:-mx-32 {
    margin-left: -8rem !important;
    margin-right: -8rem !important;
  }

  .xl\:-my-36 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }

  .xl\:-mx-36 {
    margin-left: -9rem !important;
    margin-right: -9rem !important;
  }

  .xl\:-my-40 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .xl\:-mx-40 {
    margin-left: -10rem !important;
    margin-right: -10rem !important;
  }

  .xl\:-my-48 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }

  .xl\:-mx-48 {
    margin-left: -12rem !important;
    margin-right: -12rem !important;
  }

  .xl\:-my-56 {
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }

  .xl\:-mx-56 {
    margin-left: -14rem !important;
    margin-right: -14rem !important;
  }

  .xl\:-my-64 {
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }

  .xl\:-mx-64 {
    margin-left: -16rem !important;
    margin-right: -16rem !important;
  }

  .xl\:-my-px {
    margin-top: -1px !important;
    margin-bottom: -1px !important;
  }

  .xl\:-mx-px {
    margin-left: -1px !important;
    margin-right: -1px !important;
  }

  .xl\:-my-2px {
    margin-top: -2px !important;
    margin-bottom: -2px !important;
  }

  .xl\:-mx-2px {
    margin-left: -2px !important;
    margin-right: -2px !important;
  }

  .xl\:mt-0 {
    margin-top: 0 !important;
  }

  .xl\:mr-0 {
    margin-right: 0 !important;
  }

  .xl\:mb-0 {
    margin-bottom: 0 !important;
  }

  .xl\:ml-0 {
    margin-left: 0 !important;
  }

  .xl\:mt-1 {
    margin-top: 0.25rem !important;
  }

  .xl\:mr-1 {
    margin-right: 0.25rem !important;
  }

  .xl\:mb-1 {
    margin-bottom: 0.25rem !important;
  }

  .xl\:ml-1 {
    margin-left: 0.25rem !important;
  }

  .xl\:mt-2 {
    margin-top: 0.5rem !important;
  }

  .xl\:mr-2 {
    margin-right: 0.5rem !important;
  }

  .xl\:mb-2 {
    margin-bottom: 0.5rem !important;
  }

  .xl\:ml-2 {
    margin-left: 0.5rem !important;
  }

  .xl\:mt-3 {
    margin-top: 0.75rem !important;
  }

  .xl\:mr-3 {
    margin-right: 0.75rem !important;
  }

  .xl\:mb-3 {
    margin-bottom: 0.75rem !important;
  }

  .xl\:ml-3 {
    margin-left: 0.75rem !important;
  }

  .xl\:mt-4 {
    margin-top: 1rem !important;
  }

  .xl\:mr-4 {
    margin-right: 1rem !important;
  }

  .xl\:mb-4 {
    margin-bottom: 1rem !important;
  }

  .xl\:ml-4 {
    margin-left: 1rem !important;
  }

  .xl\:mt-5 {
    margin-top: 1.25rem !important;
  }

  .xl\:mr-5 {
    margin-right: 1.25rem !important;
  }

  .xl\:mb-5 {
    margin-bottom: 1.25rem !important;
  }

  .xl\:ml-5 {
    margin-left: 1.25rem !important;
  }

  .xl\:mt-6 {
    margin-top: 1.5rem !important;
  }

  .xl\:mr-6 {
    margin-right: 1.5rem !important;
  }

  .xl\:mb-6 {
    margin-bottom: 1.5rem !important;
  }

  .xl\:ml-6 {
    margin-left: 1.5rem !important;
  }

  .xl\:mt-8 {
    margin-top: 2rem !important;
  }

  .xl\:mr-8 {
    margin-right: 2rem !important;
  }

  .xl\:mb-8 {
    margin-bottom: 2rem !important;
  }

  .xl\:ml-8 {
    margin-left: 2rem !important;
  }

  .xl\:mt-10 {
    margin-top: 2.5rem !important;
  }

  .xl\:mr-10 {
    margin-right: 2.5rem !important;
  }

  .xl\:mb-10 {
    margin-bottom: 2.5rem !important;
  }

  .xl\:ml-10 {
    margin-left: 2.5rem !important;
  }

  .xl\:mt-12 {
    margin-top: 3rem !important;
  }

  .xl\:mr-12 {
    margin-right: 3rem !important;
  }

  .xl\:mb-12 {
    margin-bottom: 3rem !important;
  }

  .xl\:ml-12 {
    margin-left: 3rem !important;
  }

  .xl\:mt-14 {
    margin-top: 3.5rem !important;
  }

  .xl\:mr-14 {
    margin-right: 3.5rem !important;
  }

  .xl\:mb-14 {
    margin-bottom: 3.5rem !important;
  }

  .xl\:ml-14 {
    margin-left: 3.5rem !important;
  }

  .xl\:mt-16 {
    margin-top: 4rem !important;
  }

  .xl\:mr-16 {
    margin-right: 4rem !important;
  }

  .xl\:mb-16 {
    margin-bottom: 4rem !important;
  }

  .xl\:ml-16 {
    margin-left: 4rem !important;
  }

  .xl\:mt-18 {
    margin-top: 4.5rem !important;
  }

  .xl\:mr-18 {
    margin-right: 4.5rem !important;
  }

  .xl\:mb-18 {
    margin-bottom: 4.5rem !important;
  }

  .xl\:ml-18 {
    margin-left: 4.5rem !important;
  }

  .xl\:mt-20 {
    margin-top: 5rem !important;
  }

  .xl\:mr-20 {
    margin-right: 5rem !important;
  }

  .xl\:mb-20 {
    margin-bottom: 5rem !important;
  }

  .xl\:ml-20 {
    margin-left: 5rem !important;
  }

  .xl\:mt-22 {
    margin-top: 5.5rem !important;
  }

  .xl\:mr-22 {
    margin-right: 5.5rem !important;
  }

  .xl\:mb-22 {
    margin-bottom: 5.5rem !important;
  }

  .xl\:ml-22 {
    margin-left: 5.5rem !important;
  }

  .xl\:mt-24 {
    margin-top: 6rem !important;
  }

  .xl\:mr-24 {
    margin-right: 6rem !important;
  }

  .xl\:mb-24 {
    margin-bottom: 6rem !important;
  }

  .xl\:ml-24 {
    margin-left: 6rem !important;
  }

  .xl\:mt-26 {
    margin-top: 6.5rem !important;
  }

  .xl\:mr-26 {
    margin-right: 6.5rem !important;
  }

  .xl\:mb-26 {
    margin-bottom: 6.5rem !important;
  }

  .xl\:ml-26 {
    margin-left: 6.5rem !important;
  }

  .xl\:mt-28 {
    margin-top: 7rem !important;
  }

  .xl\:mr-28 {
    margin-right: 7rem !important;
  }

  .xl\:mb-28 {
    margin-bottom: 7rem !important;
  }

  .xl\:ml-28 {
    margin-left: 7rem !important;
  }

  .xl\:mt-30 {
    margin-top: 7.5rem !important;
  }

  .xl\:mr-30 {
    margin-right: 7.5rem !important;
  }

  .xl\:mb-30 {
    margin-bottom: 7.5rem !important;
  }

  .xl\:ml-30 {
    margin-left: 7.5rem !important;
  }

  .xl\:mt-32 {
    margin-top: 8rem !important;
  }

  .xl\:mr-32 {
    margin-right: 8rem !important;
  }

  .xl\:mb-32 {
    margin-bottom: 8rem !important;
  }

  .xl\:ml-32 {
    margin-left: 8rem !important;
  }

  .xl\:mt-36 {
    margin-top: 9rem !important;
  }

  .xl\:mr-36 {
    margin-right: 9rem !important;
  }

  .xl\:mb-36 {
    margin-bottom: 9rem !important;
  }

  .xl\:ml-36 {
    margin-left: 9rem !important;
  }

  .xl\:mt-40 {
    margin-top: 10rem !important;
  }

  .xl\:mr-40 {
    margin-right: 10rem !important;
  }

  .xl\:mb-40 {
    margin-bottom: 10rem !important;
  }

  .xl\:ml-40 {
    margin-left: 10rem !important;
  }

  .xl\:mt-48 {
    margin-top: 12rem !important;
  }

  .xl\:mr-48 {
    margin-right: 12rem !important;
  }

  .xl\:mb-48 {
    margin-bottom: 12rem !important;
  }

  .xl\:ml-48 {
    margin-left: 12rem !important;
  }

  .xl\:mt-56 {
    margin-top: 14rem !important;
  }

  .xl\:mr-56 {
    margin-right: 14rem !important;
  }

  .xl\:mb-56 {
    margin-bottom: 14rem !important;
  }

  .xl\:ml-56 {
    margin-left: 14rem !important;
  }

  .xl\:mt-64 {
    margin-top: 16rem !important;
  }

  .xl\:mr-64 {
    margin-right: 16rem !important;
  }

  .xl\:mb-64 {
    margin-bottom: 16rem !important;
  }

  .xl\:ml-64 {
    margin-left: 16rem !important;
  }

  .xl\:mt-auto {
    margin-top: auto !important;
  }

  .xl\:mr-auto {
    margin-right: auto !important;
  }

  .xl\:mb-auto {
    margin-bottom: auto !important;
  }

  .xl\:ml-auto {
    margin-left: auto !important;
  }

  .xl\:mt-px {
    margin-top: 1px !important;
  }

  .xl\:mr-px {
    margin-right: 1px !important;
  }

  .xl\:mb-px {
    margin-bottom: 1px !important;
  }

  .xl\:ml-px {
    margin-left: 1px !important;
  }

  .xl\:mt-2px {
    margin-top: 2px !important;
  }

  .xl\:mr-2px {
    margin-right: 2px !important;
  }

  .xl\:mb-2px {
    margin-bottom: 2px !important;
  }

  .xl\:ml-2px {
    margin-left: 2px !important;
  }

  .xl\:-mt-1 {
    margin-top: -0.25rem !important;
  }

  .xl\:-mr-1 {
    margin-right: -0.25rem !important;
  }

  .xl\:-mb-1 {
    margin-bottom: -0.25rem !important;
  }

  .xl\:-ml-1 {
    margin-left: -0.25rem !important;
  }

  .xl\:-mt-2 {
    margin-top: -0.5rem !important;
  }

  .xl\:-mr-2 {
    margin-right: -0.5rem !important;
  }

  .xl\:-mb-2 {
    margin-bottom: -0.5rem !important;
  }

  .xl\:-ml-2 {
    margin-left: -0.5rem !important;
  }

  .xl\:-mt-3 {
    margin-top: -0.75rem !important;
  }

  .xl\:-mr-3 {
    margin-right: -0.75rem !important;
  }

  .xl\:-mb-3 {
    margin-bottom: -0.75rem !important;
  }

  .xl\:-ml-3 {
    margin-left: -0.75rem !important;
  }

  .xl\:-mt-4 {
    margin-top: -1rem !important;
  }

  .xl\:-mr-4 {
    margin-right: -1rem !important;
  }

  .xl\:-mb-4 {
    margin-bottom: -1rem !important;
  }

  .xl\:-ml-4 {
    margin-left: -1rem !important;
  }

  .xl\:-mt-5 {
    margin-top: -1.25rem !important;
  }

  .xl\:-mr-5 {
    margin-right: -1.25rem !important;
  }

  .xl\:-mb-5 {
    margin-bottom: -1.25rem !important;
  }

  .xl\:-ml-5 {
    margin-left: -1.25rem !important;
  }

  .xl\:-mt-6 {
    margin-top: -1.5rem !important;
  }

  .xl\:-mr-6 {
    margin-right: -1.5rem !important;
  }

  .xl\:-mb-6 {
    margin-bottom: -1.5rem !important;
  }

  .xl\:-ml-6 {
    margin-left: -1.5rem !important;
  }

  .xl\:-mt-8 {
    margin-top: -2rem !important;
  }

  .xl\:-mr-8 {
    margin-right: -2rem !important;
  }

  .xl\:-mb-8 {
    margin-bottom: -2rem !important;
  }

  .xl\:-ml-8 {
    margin-left: -2rem !important;
  }

  .xl\:-mt-10 {
    margin-top: -2.5rem !important;
  }

  .xl\:-mr-10 {
    margin-right: -2.5rem !important;
  }

  .xl\:-mb-10 {
    margin-bottom: -2.5rem !important;
  }

  .xl\:-ml-10 {
    margin-left: -2.5rem !important;
  }

  .xl\:-mt-12 {
    margin-top: -3rem !important;
  }

  .xl\:-mr-12 {
    margin-right: -3rem !important;
  }

  .xl\:-mb-12 {
    margin-bottom: -3rem !important;
  }

  .xl\:-ml-12 {
    margin-left: -3rem !important;
  }

  .xl\:-mt-14 {
    margin-top: -3.5rem !important;
  }

  .xl\:-mr-14 {
    margin-right: -3.5rem !important;
  }

  .xl\:-mb-14 {
    margin-bottom: -3.5rem !important;
  }

  .xl\:-ml-14 {
    margin-left: -3.5rem !important;
  }

  .xl\:-mt-16 {
    margin-top: -4rem !important;
  }

  .xl\:-mr-16 {
    margin-right: -4rem !important;
  }

  .xl\:-mb-16 {
    margin-bottom: -4rem !important;
  }

  .xl\:-ml-16 {
    margin-left: -4rem !important;
  }

  .xl\:-mt-18 {
    margin-top: -4.5rem !important;
  }

  .xl\:-mr-18 {
    margin-right: -4.5rem !important;
  }

  .xl\:-mb-18 {
    margin-bottom: -4.5rem !important;
  }

  .xl\:-ml-18 {
    margin-left: -4.5rem !important;
  }

  .xl\:-mt-20 {
    margin-top: -5rem !important;
  }

  .xl\:-mr-20 {
    margin-right: -5rem !important;
  }

  .xl\:-mb-20 {
    margin-bottom: -5rem !important;
  }

  .xl\:-ml-20 {
    margin-left: -5rem !important;
  }

  .xl\:-mt-22 {
    margin-top: -5.5rem !important;
  }

  .xl\:-mr-22 {
    margin-right: -5.5rem !important;
  }

  .xl\:-mb-22 {
    margin-bottom: -5.5rem !important;
  }

  .xl\:-ml-22 {
    margin-left: -5.5rem !important;
  }

  .xl\:-mt-24 {
    margin-top: -6rem !important;
  }

  .xl\:-mr-24 {
    margin-right: -6rem !important;
  }

  .xl\:-mb-24 {
    margin-bottom: -6rem !important;
  }

  .xl\:-ml-24 {
    margin-left: -6rem !important;
  }

  .xl\:-mt-26 {
    margin-top: -6.5rem !important;
  }

  .xl\:-mr-26 {
    margin-right: -6.5rem !important;
  }

  .xl\:-mb-26 {
    margin-bottom: -6.5rem !important;
  }

  .xl\:-ml-26 {
    margin-left: -6.5rem !important;
  }

  .xl\:-mt-28 {
    margin-top: -7rem !important;
  }

  .xl\:-mr-28 {
    margin-right: -7rem !important;
  }

  .xl\:-mb-28 {
    margin-bottom: -7rem !important;
  }

  .xl\:-ml-28 {
    margin-left: -7rem !important;
  }

  .xl\:-mt-30 {
    margin-top: -7.5rem !important;
  }

  .xl\:-mr-30 {
    margin-right: -7.5rem !important;
  }

  .xl\:-mb-30 {
    margin-bottom: -7.5rem !important;
  }

  .xl\:-ml-30 {
    margin-left: -7.5rem !important;
  }

  .xl\:-mt-32 {
    margin-top: -8rem !important;
  }

  .xl\:-mr-32 {
    margin-right: -8rem !important;
  }

  .xl\:-mb-32 {
    margin-bottom: -8rem !important;
  }

  .xl\:-ml-32 {
    margin-left: -8rem !important;
  }

  .xl\:-mt-36 {
    margin-top: -9rem !important;
  }

  .xl\:-mr-36 {
    margin-right: -9rem !important;
  }

  .xl\:-mb-36 {
    margin-bottom: -9rem !important;
  }

  .xl\:-ml-36 {
    margin-left: -9rem !important;
  }

  .xl\:-mt-40 {
    margin-top: -10rem !important;
  }

  .xl\:-mr-40 {
    margin-right: -10rem !important;
  }

  .xl\:-mb-40 {
    margin-bottom: -10rem !important;
  }

  .xl\:-ml-40 {
    margin-left: -10rem !important;
  }

  .xl\:-mt-48 {
    margin-top: -12rem !important;
  }

  .xl\:-mr-48 {
    margin-right: -12rem !important;
  }

  .xl\:-mb-48 {
    margin-bottom: -12rem !important;
  }

  .xl\:-ml-48 {
    margin-left: -12rem !important;
  }

  .xl\:-mt-56 {
    margin-top: -14rem !important;
  }

  .xl\:-mr-56 {
    margin-right: -14rem !important;
  }

  .xl\:-mb-56 {
    margin-bottom: -14rem !important;
  }

  .xl\:-ml-56 {
    margin-left: -14rem !important;
  }

  .xl\:-mt-64 {
    margin-top: -16rem !important;
  }

  .xl\:-mr-64 {
    margin-right: -16rem !important;
  }

  .xl\:-mb-64 {
    margin-bottom: -16rem !important;
  }

  .xl\:-ml-64 {
    margin-left: -16rem !important;
  }

  .xl\:-mt-px {
    margin-top: -1px !important;
  }

  .xl\:-mr-px {
    margin-right: -1px !important;
  }

  .xl\:-mb-px {
    margin-bottom: -1px !important;
  }

  .xl\:-ml-px {
    margin-left: -1px !important;
  }

  .xl\:-mt-2px {
    margin-top: -2px !important;
  }

  .xl\:-mr-2px {
    margin-right: -2px !important;
  }

  .xl\:-mb-2px {
    margin-bottom: -2px !important;
  }

  .xl\:-ml-2px {
    margin-left: -2px !important;
  }

  .xl\:max-h-0 {
    max-height: 0 !important;
  }

  .xl\:max-h-1 {
    max-height: 0.25rem !important;
  }

  .xl\:max-h-2 {
    max-height: 0.5rem !important;
  }

  .xl\:max-h-3 {
    max-height: 0.75rem !important;
  }

  .xl\:max-h-4 {
    max-height: 1rem !important;
  }

  .xl\:max-h-5 {
    max-height: 1.25rem !important;
  }

  .xl\:max-h-6 {
    max-height: 1.5rem !important;
  }

  .xl\:max-h-8 {
    max-height: 2rem !important;
  }

  .xl\:max-h-10 {
    max-height: 2.5rem !important;
  }

  .xl\:max-h-12 {
    max-height: 3rem !important;
  }

  .xl\:max-h-14 {
    max-height: 3.5rem !important;
  }

  .xl\:max-h-16 {
    max-height: 4rem !important;
  }

  .xl\:max-h-18 {
    max-height: 4.5rem !important;
  }

  .xl\:max-h-20 {
    max-height: 5rem !important;
  }

  .xl\:max-h-22 {
    max-height: 5.5rem !important;
  }

  .xl\:max-h-24 {
    max-height: 6rem !important;
  }

  .xl\:max-h-26 {
    max-height: 6.5rem !important;
  }

  .xl\:max-h-28 {
    max-height: 7rem !important;
  }

  .xl\:max-h-30 {
    max-height: 7.5rem !important;
  }

  .xl\:max-h-32 {
    max-height: 8rem !important;
  }

  .xl\:max-h-36 {
    max-height: 9rem !important;
  }

  .xl\:max-h-40 {
    max-height: 10rem !important;
  }

  .xl\:max-h-48 {
    max-height: 12rem !important;
  }

  .xl\:max-h-50 {
    max-height: 12.5rem !important;
  }

  .xl\:max-h-56 {
    max-height: 14rem !important;
  }

  .xl\:max-h-60 {
    max-height: 15rem !important;
  }

  .xl\:max-h-64 {
    max-height: 16rem !important;
  }

  .xl\:max-h-80 {
    max-height: 20rem !important;
  }

  .xl\:max-h-90 {
    max-height: 24rem !important;
  }

  .xl\:max-h-100 {
    max-height: 25rem !important;
  }

  .xl\:max-h-120 {
    max-height: 30rem !important;
  }

  .xl\:max-h-128 {
    max-height: 32rem !important;
  }

  .xl\:max-h-140 {
    max-height: 35rem !important;
  }

  .xl\:max-h-160 {
    max-height: 40rem !important;
  }

  .xl\:max-h-180 {
    max-height: 45rem !important;
  }

  .xl\:max-h-192 {
    max-height: 48rem !important;
  }

  .xl\:max-h-200 {
    max-height: 50rem !important;
  }

  .xl\:max-h-240 {
    max-height: 60rem !important;
  }

  .xl\:max-h-256 {
    max-height: 64rem !important;
  }

  .xl\:max-h-280 {
    max-height: 70rem !important;
  }

  .xl\:max-h-320 {
    max-height: 80rem !important;
  }

  .xl\:max-h-360 {
    max-height: 90rem !important;
  }

  .xl\:max-h-400 {
    max-height: 100rem !important;
  }

  .xl\:max-h-480 {
    max-height: 120rem !important;
  }

  .xl\:max-h-full {
    max-height: 100% !important;
  }

  .xl\:max-h-screen {
    max-height: 100vh !important;
  }

  .xl\:max-h-none {
    max-height: none !important;
  }

  .xl\:max-h-px {
    max-height: 1px !important;
  }

  .xl\:max-h-2px {
    max-height: 2px !important;
  }

  .xl\:max-h-1\/2 {
    max-height: 50% !important;
  }

  .xl\:max-h-1\/3 {
    max-height: 33.33333% !important;
  }

  .xl\:max-h-2\/3 {
    max-height: 66.66667% !important;
  }

  .xl\:max-h-1\/4 {
    max-height: 25% !important;
  }

  .xl\:max-h-2\/4 {
    max-height: 50% !important;
  }

  .xl\:max-h-3\/4 {
    max-height: 75% !important;
  }

  .xl\:max-h-1\/5 {
    max-height: 20% !important;
  }

  .xl\:max-h-2\/5 {
    max-height: 40% !important;
  }

  .xl\:max-h-3\/5 {
    max-height: 60% !important;
  }

  .xl\:max-h-4\/5 {
    max-height: 80% !important;
  }

  .xl\:max-h-1\/12 {
    max-height: 8.33333% !important;
  }

  .xl\:max-h-2\/12 {
    max-height: 16.66667% !important;
  }

  .xl\:max-h-3\/12 {
    max-height: 25% !important;
  }

  .xl\:max-h-4\/12 {
    max-height: 33.33333% !important;
  }

  .xl\:max-h-5\/12 {
    max-height: 41.66667% !important;
  }

  .xl\:max-h-6\/12 {
    max-height: 50% !important;
  }

  .xl\:max-h-7\/12 {
    max-height: 58.33333% !important;
  }

  .xl\:max-h-8\/12 {
    max-height: 66.66667% !important;
  }

  .xl\:max-h-9\/12 {
    max-height: 75% !important;
  }

  .xl\:max-h-10\/12 {
    max-height: 83.33333% !important;
  }

  .xl\:max-h-11\/12 {
    max-height: 91.66667% !important;
  }

  .xl\:max-w-0 {
    max-width: 0 !important;
  }

  .xl\:max-w-1 {
    max-width: 0.25rem !important;
  }

  .xl\:max-w-2 {
    max-width: 0.5rem !important;
  }

  .xl\:max-w-3 {
    max-width: 0.75rem !important;
  }

  .xl\:max-w-4 {
    max-width: 1rem !important;
  }

  .xl\:max-w-5 {
    max-width: 1.25rem !important;
  }

  .xl\:max-w-6 {
    max-width: 1.5rem !important;
  }

  .xl\:max-w-8 {
    max-width: 2rem !important;
  }

  .xl\:max-w-10 {
    max-width: 2.5rem !important;
  }

  .xl\:max-w-12 {
    max-width: 3rem !important;
  }

  .xl\:max-w-14 {
    max-width: 3.5rem !important;
  }

  .xl\:max-w-16 {
    max-width: 4rem !important;
  }

  .xl\:max-w-18 {
    max-width: 4.5rem !important;
  }

  .xl\:max-w-20 {
    max-width: 5rem !important;
  }

  .xl\:max-w-22 {
    max-width: 5.5rem !important;
  }

  .xl\:max-w-24 {
    max-width: 6rem !important;
  }

  .xl\:max-w-26 {
    max-width: 6.5rem !important;
  }

  .xl\:max-w-28 {
    max-width: 7rem !important;
  }

  .xl\:max-w-30 {
    max-width: 7.5rem !important;
  }

  .xl\:max-w-32 {
    max-width: 8rem !important;
  }

  .xl\:max-w-36 {
    max-width: 9rem !important;
  }

  .xl\:max-w-40 {
    max-width: 10rem !important;
  }

  .xl\:max-w-48 {
    max-width: 12rem !important;
  }

  .xl\:max-w-50 {
    max-width: 12.5rem !important;
  }

  .xl\:max-w-56 {
    max-width: 14rem !important;
  }

  .xl\:max-w-60 {
    max-width: 15rem !important;
  }

  .xl\:max-w-64 {
    max-width: 16rem !important;
  }

  .xl\:max-w-80 {
    max-width: 20rem !important;
  }

  .xl\:max-w-90 {
    max-width: 24rem !important;
  }

  .xl\:max-w-100 {
    max-width: 25rem !important;
  }

  .xl\:max-w-120 {
    max-width: 30rem !important;
  }

  .xl\:max-w-128 {
    max-width: 32rem !important;
  }

  .xl\:max-w-140 {
    max-width: 35rem !important;
  }

  .xl\:max-w-160 {
    max-width: 40rem !important;
  }

  .xl\:max-w-180 {
    max-width: 45rem !important;
  }

  .xl\:max-w-192 {
    max-width: 48rem !important;
  }

  .xl\:max-w-200 {
    max-width: 50rem !important;
  }

  .xl\:max-w-240 {
    max-width: 60rem !important;
  }

  .xl\:max-w-256 {
    max-width: 64rem !important;
  }

  .xl\:max-w-280 {
    max-width: 70rem !important;
  }

  .xl\:max-w-320 {
    max-width: 80rem !important;
  }

  .xl\:max-w-360 {
    max-width: 90rem !important;
  }

  .xl\:max-w-400 {
    max-width: 100rem !important;
  }

  .xl\:max-w-480 {
    max-width: 120rem !important;
  }

  .xl\:max-w-none {
    max-width: none !important;
  }

  .xl\:max-w-xs {
    max-width: 20rem !important;
  }

  .xl\:max-w-sm {
    max-width: 24rem !important;
  }

  .xl\:max-w-md {
    max-width: 28rem !important;
  }

  .xl\:max-w-lg {
    max-width: 32rem !important;
  }

  .xl\:max-w-xl {
    max-width: 36rem !important;
  }

  .xl\:max-w-2xl {
    max-width: 42rem !important;
  }

  .xl\:max-w-3xl {
    max-width: 48rem !important;
  }

  .xl\:max-w-4xl {
    max-width: 56rem !important;
  }

  .xl\:max-w-5xl {
    max-width: 64rem !important;
  }

  .xl\:max-w-6xl {
    max-width: 72rem !important;
  }

  .xl\:max-w-full {
    max-width: 100% !important;
  }

  .xl\:max-w-screen {
    max-width: 100vw !important;
  }

  .xl\:max-w-px {
    max-width: 1px !important;
  }

  .xl\:max-w-2px {
    max-width: 2px !important;
  }

  .xl\:max-w-1\/2 {
    max-width: 50% !important;
  }

  .xl\:max-w-1\/3 {
    max-width: 33.33333% !important;
  }

  .xl\:max-w-2\/3 {
    max-width: 66.66667% !important;
  }

  .xl\:max-w-1\/4 {
    max-width: 25% !important;
  }

  .xl\:max-w-2\/4 {
    max-width: 50% !important;
  }

  .xl\:max-w-3\/4 {
    max-width: 75% !important;
  }

  .xl\:max-w-1\/5 {
    max-width: 20% !important;
  }

  .xl\:max-w-2\/5 {
    max-width: 40% !important;
  }

  .xl\:max-w-3\/5 {
    max-width: 60% !important;
  }

  .xl\:max-w-4\/5 {
    max-width: 80% !important;
  }

  .xl\:max-w-1\/12 {
    max-width: 8.33333% !important;
  }

  .xl\:max-w-2\/12 {
    max-width: 16.66667% !important;
  }

  .xl\:max-w-3\/12 {
    max-width: 25% !important;
  }

  .xl\:max-w-4\/12 {
    max-width: 33.33333% !important;
  }

  .xl\:max-w-5\/12 {
    max-width: 41.66667% !important;
  }

  .xl\:max-w-6\/12 {
    max-width: 50% !important;
  }

  .xl\:max-w-7\/12 {
    max-width: 58.33333% !important;
  }

  .xl\:max-w-8\/12 {
    max-width: 66.66667% !important;
  }

  .xl\:max-w-9\/12 {
    max-width: 75% !important;
  }

  .xl\:max-w-10\/12 {
    max-width: 83.33333% !important;
  }

  .xl\:max-w-11\/12 {
    max-width: 91.66667% !important;
  }

  .xl\:min-h-0 {
    min-height: 0 !important;
  }

  .xl\:min-h-1 {
    min-height: 0.25rem !important;
  }

  .xl\:min-h-2 {
    min-height: 0.5rem !important;
  }

  .xl\:min-h-3 {
    min-height: 0.75rem !important;
  }

  .xl\:min-h-4 {
    min-height: 1rem !important;
  }

  .xl\:min-h-5 {
    min-height: 1.25rem !important;
  }

  .xl\:min-h-6 {
    min-height: 1.5rem !important;
  }

  .xl\:min-h-8 {
    min-height: 2rem !important;
  }

  .xl\:min-h-10 {
    min-height: 2.5rem !important;
  }

  .xl\:min-h-12 {
    min-height: 3rem !important;
  }

  .xl\:min-h-14 {
    min-height: 3.5rem !important;
  }

  .xl\:min-h-16 {
    min-height: 4rem !important;
  }

  .xl\:min-h-18 {
    min-height: 4.5rem !important;
  }

  .xl\:min-h-20 {
    min-height: 5rem !important;
  }

  .xl\:min-h-22 {
    min-height: 5.5rem !important;
  }

  .xl\:min-h-24 {
    min-height: 6rem !important;
  }

  .xl\:min-h-26 {
    min-height: 6.5rem !important;
  }

  .xl\:min-h-28 {
    min-height: 7rem !important;
  }

  .xl\:min-h-30 {
    min-height: 7.5rem !important;
  }

  .xl\:min-h-32 {
    min-height: 8rem !important;
  }

  .xl\:min-h-36 {
    min-height: 9rem !important;
  }

  .xl\:min-h-40 {
    min-height: 10rem !important;
  }

  .xl\:min-h-48 {
    min-height: 12rem !important;
  }

  .xl\:min-h-50 {
    min-height: 12.5rem !important;
  }

  .xl\:min-h-56 {
    min-height: 14rem !important;
  }

  .xl\:min-h-60 {
    min-height: 15rem !important;
  }

  .xl\:min-h-64 {
    min-height: 16rem !important;
  }

  .xl\:min-h-80 {
    min-height: 20rem !important;
  }

  .xl\:min-h-90 {
    min-height: 24rem !important;
  }

  .xl\:min-h-100 {
    min-height: 25rem !important;
  }

  .xl\:min-h-120 {
    min-height: 30rem !important;
  }

  .xl\:min-h-128 {
    min-height: 32rem !important;
  }

  .xl\:min-h-140 {
    min-height: 35rem !important;
  }

  .xl\:min-h-160 {
    min-height: 40rem !important;
  }

  .xl\:min-h-180 {
    min-height: 45rem !important;
  }

  .xl\:min-h-192 {
    min-height: 48rem !important;
  }

  .xl\:min-h-200 {
    min-height: 50rem !important;
  }

  .xl\:min-h-240 {
    min-height: 60rem !important;
  }

  .xl\:min-h-256 {
    min-height: 64rem !important;
  }

  .xl\:min-h-280 {
    min-height: 70rem !important;
  }

  .xl\:min-h-320 {
    min-height: 80rem !important;
  }

  .xl\:min-h-360 {
    min-height: 90rem !important;
  }

  .xl\:min-h-400 {
    min-height: 100rem !important;
  }

  .xl\:min-h-480 {
    min-height: 120rem !important;
  }

  .xl\:min-h-full {
    min-height: 100% !important;
  }

  .xl\:min-h-screen {
    min-height: 100vh !important;
  }

  .xl\:min-h-px {
    min-height: 1px !important;
  }

  .xl\:min-h-2px {
    min-height: 2px !important;
  }

  .xl\:min-h-1\/2 {
    min-height: 50% !important;
  }

  .xl\:min-h-1\/3 {
    min-height: 33.33333% !important;
  }

  .xl\:min-h-2\/3 {
    min-height: 66.66667% !important;
  }

  .xl\:min-h-1\/4 {
    min-height: 25% !important;
  }

  .xl\:min-h-2\/4 {
    min-height: 50% !important;
  }

  .xl\:min-h-3\/4 {
    min-height: 75% !important;
  }

  .xl\:min-h-1\/5 {
    min-height: 20% !important;
  }

  .xl\:min-h-2\/5 {
    min-height: 40% !important;
  }

  .xl\:min-h-3\/5 {
    min-height: 60% !important;
  }

  .xl\:min-h-4\/5 {
    min-height: 80% !important;
  }

  .xl\:min-h-1\/12 {
    min-height: 8.33333% !important;
  }

  .xl\:min-h-2\/12 {
    min-height: 16.66667% !important;
  }

  .xl\:min-h-3\/12 {
    min-height: 25% !important;
  }

  .xl\:min-h-4\/12 {
    min-height: 33.33333% !important;
  }

  .xl\:min-h-5\/12 {
    min-height: 41.66667% !important;
  }

  .xl\:min-h-6\/12 {
    min-height: 50% !important;
  }

  .xl\:min-h-7\/12 {
    min-height: 58.33333% !important;
  }

  .xl\:min-h-8\/12 {
    min-height: 66.66667% !important;
  }

  .xl\:min-h-9\/12 {
    min-height: 75% !important;
  }

  .xl\:min-h-10\/12 {
    min-height: 83.33333% !important;
  }

  .xl\:min-h-11\/12 {
    min-height: 91.66667% !important;
  }

  .xl\:min-w-0 {
    min-width: 0 !important;
  }

  .xl\:min-w-1 {
    min-width: 0.25rem !important;
  }

  .xl\:min-w-2 {
    min-width: 0.5rem !important;
  }

  .xl\:min-w-3 {
    min-width: 0.75rem !important;
  }

  .xl\:min-w-4 {
    min-width: 1rem !important;
  }

  .xl\:min-w-5 {
    min-width: 1.25rem !important;
  }

  .xl\:min-w-6 {
    min-width: 1.5rem !important;
  }

  .xl\:min-w-8 {
    min-width: 2rem !important;
  }

  .xl\:min-w-10 {
    min-width: 2.5rem !important;
  }

  .xl\:min-w-12 {
    min-width: 3rem !important;
  }

  .xl\:min-w-14 {
    min-width: 3.5rem !important;
  }

  .xl\:min-w-16 {
    min-width: 4rem !important;
  }

  .xl\:min-w-18 {
    min-width: 4.5rem !important;
  }

  .xl\:min-w-20 {
    min-width: 5rem !important;
  }

  .xl\:min-w-22 {
    min-width: 5.5rem !important;
  }

  .xl\:min-w-24 {
    min-width: 6rem !important;
  }

  .xl\:min-w-26 {
    min-width: 6.5rem !important;
  }

  .xl\:min-w-28 {
    min-width: 7rem !important;
  }

  .xl\:min-w-30 {
    min-width: 7.5rem !important;
  }

  .xl\:min-w-32 {
    min-width: 8rem !important;
  }

  .xl\:min-w-36 {
    min-width: 9rem !important;
  }

  .xl\:min-w-40 {
    min-width: 10rem !important;
  }

  .xl\:min-w-48 {
    min-width: 12rem !important;
  }

  .xl\:min-w-50 {
    min-width: 12.5rem !important;
  }

  .xl\:min-w-56 {
    min-width: 14rem !important;
  }

  .xl\:min-w-60 {
    min-width: 15rem !important;
  }

  .xl\:min-w-64 {
    min-width: 16rem !important;
  }

  .xl\:min-w-80 {
    min-width: 20rem !important;
  }

  .xl\:min-w-90 {
    min-width: 24rem !important;
  }

  .xl\:min-w-100 {
    min-width: 25rem !important;
  }

  .xl\:min-w-120 {
    min-width: 30rem !important;
  }

  .xl\:min-w-128 {
    min-width: 32rem !important;
  }

  .xl\:min-w-140 {
    min-width: 35rem !important;
  }

  .xl\:min-w-160 {
    min-width: 40rem !important;
  }

  .xl\:min-w-180 {
    min-width: 45rem !important;
  }

  .xl\:min-w-192 {
    min-width: 48rem !important;
  }

  .xl\:min-w-200 {
    min-width: 50rem !important;
  }

  .xl\:min-w-240 {
    min-width: 60rem !important;
  }

  .xl\:min-w-256 {
    min-width: 64rem !important;
  }

  .xl\:min-w-280 {
    min-width: 70rem !important;
  }

  .xl\:min-w-320 {
    min-width: 80rem !important;
  }

  .xl\:min-w-360 {
    min-width: 90rem !important;
  }

  .xl\:min-w-400 {
    min-width: 100rem !important;
  }

  .xl\:min-w-480 {
    min-width: 120rem !important;
  }

  .xl\:min-w-full {
    min-width: 100% !important;
  }

  .xl\:min-w-screen {
    min-width: 100vw !important;
  }

  .xl\:min-w-px {
    min-width: 1px !important;
  }

  .xl\:min-w-2px {
    min-width: 2px !important;
  }

  .xl\:min-w-1\/2 {
    min-width: 50% !important;
  }

  .xl\:min-w-1\/3 {
    min-width: 33.33333% !important;
  }

  .xl\:min-w-2\/3 {
    min-width: 66.66667% !important;
  }

  .xl\:min-w-1\/4 {
    min-width: 25% !important;
  }

  .xl\:min-w-2\/4 {
    min-width: 50% !important;
  }

  .xl\:min-w-3\/4 {
    min-width: 75% !important;
  }

  .xl\:min-w-1\/5 {
    min-width: 20% !important;
  }

  .xl\:min-w-2\/5 {
    min-width: 40% !important;
  }

  .xl\:min-w-3\/5 {
    min-width: 60% !important;
  }

  .xl\:min-w-4\/5 {
    min-width: 80% !important;
  }

  .xl\:min-w-1\/12 {
    min-width: 8.33333% !important;
  }

  .xl\:min-w-2\/12 {
    min-width: 16.66667% !important;
  }

  .xl\:min-w-3\/12 {
    min-width: 25% !important;
  }

  .xl\:min-w-4\/12 {
    min-width: 33.33333% !important;
  }

  .xl\:min-w-5\/12 {
    min-width: 41.66667% !important;
  }

  .xl\:min-w-6\/12 {
    min-width: 50% !important;
  }

  .xl\:min-w-7\/12 {
    min-width: 58.33333% !important;
  }

  .xl\:min-w-8\/12 {
    min-width: 66.66667% !important;
  }

  .xl\:min-w-9\/12 {
    min-width: 75% !important;
  }

  .xl\:min-w-10\/12 {
    min-width: 83.33333% !important;
  }

  .xl\:min-w-11\/12 {
    min-width: 91.66667% !important;
  }

  .xl\:object-contain {
    -o-object-fit: contain !important;
       object-fit: contain !important;
  }

  .xl\:object-cover {
    -o-object-fit: cover !important;
       object-fit: cover !important;
  }

  .xl\:object-fill {
    -o-object-fit: fill !important;
       object-fit: fill !important;
  }

  .xl\:object-none {
    -o-object-fit: none !important;
       object-fit: none !important;
  }

  .xl\:object-scale-down {
    -o-object-fit: scale-down !important;
       object-fit: scale-down !important;
  }

  .xl\:object-bottom {
    -o-object-position: bottom !important;
       object-position: bottom !important;
  }

  .xl\:object-center {
    -o-object-position: center !important;
       object-position: center !important;
  }

  .xl\:object-left {
    -o-object-position: left !important;
       object-position: left !important;
  }

  .xl\:object-left-bottom {
    -o-object-position: left bottom !important;
       object-position: left bottom !important;
  }

  .xl\:object-left-top {
    -o-object-position: left top !important;
       object-position: left top !important;
  }

  .xl\:object-right {
    -o-object-position: right !important;
       object-position: right !important;
  }

  .xl\:object-right-bottom {
    -o-object-position: right bottom !important;
       object-position: right bottom !important;
  }

  .xl\:object-right-top {
    -o-object-position: right top !important;
       object-position: right top !important;
  }

  .xl\:object-top {
    -o-object-position: top !important;
       object-position: top !important;
  }

  .xl\:opacity-0 {
    opacity: 0 !important;
  }

  .xl\:opacity-12 {
    opacity: 0.12 !important;
  }

  .xl\:opacity-25 {
    opacity: 0.25 !important;
  }

  .xl\:opacity-38 {
    opacity: 0.38 !important;
  }

  .xl\:opacity-50 {
    opacity: 0.5 !important;
  }

  .xl\:opacity-54 {
    opacity: 0.54 !important;
  }

  .xl\:opacity-70 {
    opacity: 0.70 !important;
  }

  .xl\:opacity-75 {
    opacity: 0.75 !important;
  }

  .xl\:opacity-84 {
    opacity: 0.84 !important;
  }

  .xl\:opacity-100 {
    opacity: 1 !important;
  }

  .xl\:hover\:opacity-0:hover {
    opacity: 0 !important;
  }

  .xl\:hover\:opacity-12:hover {
    opacity: 0.12 !important;
  }

  .xl\:hover\:opacity-25:hover {
    opacity: 0.25 !important;
  }

  .xl\:hover\:opacity-38:hover {
    opacity: 0.38 !important;
  }

  .xl\:hover\:opacity-50:hover {
    opacity: 0.5 !important;
  }

  .xl\:hover\:opacity-54:hover {
    opacity: 0.54 !important;
  }

  .xl\:hover\:opacity-70:hover {
    opacity: 0.70 !important;
  }

  .xl\:hover\:opacity-75:hover {
    opacity: 0.75 !important;
  }

  .xl\:hover\:opacity-84:hover {
    opacity: 0.84 !important;
  }

  .xl\:hover\:opacity-100:hover {
    opacity: 1 !important;
  }

  .xl\:focus\:opacity-0:focus {
    opacity: 0 !important;
  }

  .xl\:focus\:opacity-12:focus {
    opacity: 0.12 !important;
  }

  .xl\:focus\:opacity-25:focus {
    opacity: 0.25 !important;
  }

  .xl\:focus\:opacity-38:focus {
    opacity: 0.38 !important;
  }

  .xl\:focus\:opacity-50:focus {
    opacity: 0.5 !important;
  }

  .xl\:focus\:opacity-54:focus {
    opacity: 0.54 !important;
  }

  .xl\:focus\:opacity-70:focus {
    opacity: 0.70 !important;
  }

  .xl\:focus\:opacity-75:focus {
    opacity: 0.75 !important;
  }

  .xl\:focus\:opacity-84:focus {
    opacity: 0.84 !important;
  }

  .xl\:focus\:opacity-100:focus {
    opacity: 1 !important;
  }

  .xl\:outline-none {
    outline: 0 !important;
  }

  .xl\:focus\:outline-none:focus {
    outline: 0 !important;
  }

  .xl\:overflow-auto {
    overflow: auto !important;
  }

  .xl\:overflow-hidden {
    overflow: hidden !important;
  }

  .xl\:overflow-visible {
    overflow: visible !important;
  }

  .xl\:overflow-scroll {
    overflow: scroll !important;
  }

  .xl\:overflow-x-auto {
    overflow-x: auto !important;
  }

  .xl\:overflow-y-auto {
    overflow-y: auto !important;
  }

  .xl\:overflow-x-hidden {
    overflow-x: hidden !important;
  }

  .xl\:overflow-y-hidden {
    overflow-y: hidden !important;
  }

  .xl\:overflow-x-visible {
    overflow-x: visible !important;
  }

  .xl\:overflow-y-visible {
    overflow-y: visible !important;
  }

  .xl\:overflow-x-scroll {
    overflow-x: scroll !important;
  }

  .xl\:overflow-y-scroll {
    overflow-y: scroll !important;
  }

  .xl\:scrolling-touch {
    -webkit-overflow-scrolling: touch !important;
  }

  .xl\:scrolling-auto {
    -webkit-overflow-scrolling: auto !important;
  }

  .xl\:p-0 {
    padding: 0 !important;
  }

  .xl\:p-1 {
    padding: 0.25rem !important;
  }

  .xl\:p-2 {
    padding: 0.5rem !important;
  }

  .xl\:p-3 {
    padding: 0.75rem !important;
  }

  .xl\:p-4 {
    padding: 1rem !important;
  }

  .xl\:p-5 {
    padding: 1.25rem !important;
  }

  .xl\:p-6 {
    padding: 1.5rem !important;
  }

  .xl\:p-8 {
    padding: 2rem !important;
  }

  .xl\:p-10 {
    padding: 2.5rem !important;
  }

  .xl\:p-12 {
    padding: 3rem !important;
  }

  .xl\:p-14 {
    padding: 3.5rem !important;
  }

  .xl\:p-16 {
    padding: 4rem !important;
  }

  .xl\:p-18 {
    padding: 4.5rem !important;
  }

  .xl\:p-20 {
    padding: 5rem !important;
  }

  .xl\:p-22 {
    padding: 5.5rem !important;
  }

  .xl\:p-24 {
    padding: 6rem !important;
  }

  .xl\:p-26 {
    padding: 6.5rem !important;
  }

  .xl\:p-28 {
    padding: 7rem !important;
  }

  .xl\:p-30 {
    padding: 7.5rem !important;
  }

  .xl\:p-32 {
    padding: 8rem !important;
  }

  .xl\:p-36 {
    padding: 9rem !important;
  }

  .xl\:p-40 {
    padding: 10rem !important;
  }

  .xl\:p-48 {
    padding: 12rem !important;
  }

  .xl\:p-56 {
    padding: 14rem !important;
  }

  .xl\:p-64 {
    padding: 16rem !important;
  }

  .xl\:p-px {
    padding: 1px !important;
  }

  .xl\:p-2px {
    padding: 2px !important;
  }

  .xl\:py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .xl\:px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .xl\:py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .xl\:px-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }

  .xl\:py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .xl\:px-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .xl\:py-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .xl\:px-3 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }

  .xl\:py-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .xl\:px-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .xl\:py-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .xl\:px-5 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }

  .xl\:py-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .xl\:px-6 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .xl\:py-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .xl\:px-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .xl\:py-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .xl\:px-10 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .xl\:py-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .xl\:px-12 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .xl\:py-14 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .xl\:px-14 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }

  .xl\:py-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .xl\:px-16 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .xl\:py-18 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .xl\:px-18 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }

  .xl\:py-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .xl\:px-20 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .xl\:py-22 {
    padding-top: 5.5rem !important;
    padding-bottom: 5.5rem !important;
  }

  .xl\:px-22 {
    padding-left: 5.5rem !important;
    padding-right: 5.5rem !important;
  }

  .xl\:py-24 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .xl\:px-24 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }

  .xl\:py-26 {
    padding-top: 6.5rem !important;
    padding-bottom: 6.5rem !important;
  }

  .xl\:px-26 {
    padding-left: 6.5rem !important;
    padding-right: 6.5rem !important;
  }

  .xl\:py-28 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }

  .xl\:px-28 {
    padding-left: 7rem !important;
    padding-right: 7rem !important;
  }

  .xl\:py-30 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }

  .xl\:px-30 {
    padding-left: 7.5rem !important;
    padding-right: 7.5rem !important;
  }

  .xl\:py-32 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .xl\:px-32 {
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }

  .xl\:py-36 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }

  .xl\:px-36 {
    padding-left: 9rem !important;
    padding-right: 9rem !important;
  }

  .xl\:py-40 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .xl\:px-40 {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }

  .xl\:py-48 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }

  .xl\:px-48 {
    padding-left: 12rem !important;
    padding-right: 12rem !important;
  }

  .xl\:py-56 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }

  .xl\:px-56 {
    padding-left: 14rem !important;
    padding-right: 14rem !important;
  }

  .xl\:py-64 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }

  .xl\:px-64 {
    padding-left: 16rem !important;
    padding-right: 16rem !important;
  }

  .xl\:py-px {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }

  .xl\:px-px {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }

  .xl\:py-2px {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }

  .xl\:px-2px {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }

  .xl\:pt-0 {
    padding-top: 0 !important;
  }

  .xl\:pr-0 {
    padding-right: 0 !important;
  }

  .xl\:pb-0 {
    padding-bottom: 0 !important;
  }

  .xl\:pl-0 {
    padding-left: 0 !important;
  }

  .xl\:pt-1 {
    padding-top: 0.25rem !important;
  }

  .xl\:pr-1 {
    padding-right: 0.25rem !important;
  }

  .xl\:pb-1 {
    padding-bottom: 0.25rem !important;
  }

  .xl\:pl-1 {
    padding-left: 0.25rem !important;
  }

  .xl\:pt-2 {
    padding-top: 0.5rem !important;
  }

  .xl\:pr-2 {
    padding-right: 0.5rem !important;
  }

  .xl\:pb-2 {
    padding-bottom: 0.5rem !important;
  }

  .xl\:pl-2 {
    padding-left: 0.5rem !important;
  }

  .xl\:pt-3 {
    padding-top: 0.75rem !important;
  }

  .xl\:pr-3 {
    padding-right: 0.75rem !important;
  }

  .xl\:pb-3 {
    padding-bottom: 0.75rem !important;
  }

  .xl\:pl-3 {
    padding-left: 0.75rem !important;
  }

  .xl\:pt-4 {
    padding-top: 1rem !important;
  }

  .xl\:pr-4 {
    padding-right: 1rem !important;
  }

  .xl\:pb-4 {
    padding-bottom: 1rem !important;
  }

  .xl\:pl-4 {
    padding-left: 1rem !important;
  }

  .xl\:pt-5 {
    padding-top: 1.25rem !important;
  }

  .xl\:pr-5 {
    padding-right: 1.25rem !important;
  }

  .xl\:pb-5 {
    padding-bottom: 1.25rem !important;
  }

  .xl\:pl-5 {
    padding-left: 1.25rem !important;
  }

  .xl\:pt-6 {
    padding-top: 1.5rem !important;
  }

  .xl\:pr-6 {
    padding-right: 1.5rem !important;
  }

  .xl\:pb-6 {
    padding-bottom: 1.5rem !important;
  }

  .xl\:pl-6 {
    padding-left: 1.5rem !important;
  }

  .xl\:pt-8 {
    padding-top: 2rem !important;
  }

  .xl\:pr-8 {
    padding-right: 2rem !important;
  }

  .xl\:pb-8 {
    padding-bottom: 2rem !important;
  }

  .xl\:pl-8 {
    padding-left: 2rem !important;
  }

  .xl\:pt-10 {
    padding-top: 2.5rem !important;
  }

  .xl\:pr-10 {
    padding-right: 2.5rem !important;
  }

  .xl\:pb-10 {
    padding-bottom: 2.5rem !important;
  }

  .xl\:pl-10 {
    padding-left: 2.5rem !important;
  }

  .xl\:pt-12 {
    padding-top: 3rem !important;
  }

  .xl\:pr-12 {
    padding-right: 3rem !important;
  }

  .xl\:pb-12 {
    padding-bottom: 3rem !important;
  }

  .xl\:pl-12 {
    padding-left: 3rem !important;
  }

  .xl\:pt-14 {
    padding-top: 3.5rem !important;
  }

  .xl\:pr-14 {
    padding-right: 3.5rem !important;
  }

  .xl\:pb-14 {
    padding-bottom: 3.5rem !important;
  }

  .xl\:pl-14 {
    padding-left: 3.5rem !important;
  }

  .xl\:pt-16 {
    padding-top: 4rem !important;
  }

  .xl\:pr-16 {
    padding-right: 4rem !important;
  }

  .xl\:pb-16 {
    padding-bottom: 4rem !important;
  }

  .xl\:pl-16 {
    padding-left: 4rem !important;
  }

  .xl\:pt-18 {
    padding-top: 4.5rem !important;
  }

  .xl\:pr-18 {
    padding-right: 4.5rem !important;
  }

  .xl\:pb-18 {
    padding-bottom: 4.5rem !important;
  }

  .xl\:pl-18 {
    padding-left: 4.5rem !important;
  }

  .xl\:pt-20 {
    padding-top: 5rem !important;
  }

  .xl\:pr-20 {
    padding-right: 5rem !important;
  }

  .xl\:pb-20 {
    padding-bottom: 5rem !important;
  }

  .xl\:pl-20 {
    padding-left: 5rem !important;
  }

  .xl\:pt-22 {
    padding-top: 5.5rem !important;
  }

  .xl\:pr-22 {
    padding-right: 5.5rem !important;
  }

  .xl\:pb-22 {
    padding-bottom: 5.5rem !important;
  }

  .xl\:pl-22 {
    padding-left: 5.5rem !important;
  }

  .xl\:pt-24 {
    padding-top: 6rem !important;
  }

  .xl\:pr-24 {
    padding-right: 6rem !important;
  }

  .xl\:pb-24 {
    padding-bottom: 6rem !important;
  }

  .xl\:pl-24 {
    padding-left: 6rem !important;
  }

  .xl\:pt-26 {
    padding-top: 6.5rem !important;
  }

  .xl\:pr-26 {
    padding-right: 6.5rem !important;
  }

  .xl\:pb-26 {
    padding-bottom: 6.5rem !important;
  }

  .xl\:pl-26 {
    padding-left: 6.5rem !important;
  }

  .xl\:pt-28 {
    padding-top: 7rem !important;
  }

  .xl\:pr-28 {
    padding-right: 7rem !important;
  }

  .xl\:pb-28 {
    padding-bottom: 7rem !important;
  }

  .xl\:pl-28 {
    padding-left: 7rem !important;
  }

  .xl\:pt-30 {
    padding-top: 7.5rem !important;
  }

  .xl\:pr-30 {
    padding-right: 7.5rem !important;
  }

  .xl\:pb-30 {
    padding-bottom: 7.5rem !important;
  }

  .xl\:pl-30 {
    padding-left: 7.5rem !important;
  }

  .xl\:pt-32 {
    padding-top: 8rem !important;
  }

  .xl\:pr-32 {
    padding-right: 8rem !important;
  }

  .xl\:pb-32 {
    padding-bottom: 8rem !important;
  }

  .xl\:pl-32 {
    padding-left: 8rem !important;
  }

  .xl\:pt-36 {
    padding-top: 9rem !important;
  }

  .xl\:pr-36 {
    padding-right: 9rem !important;
  }

  .xl\:pb-36 {
    padding-bottom: 9rem !important;
  }

  .xl\:pl-36 {
    padding-left: 9rem !important;
  }

  .xl\:pt-40 {
    padding-top: 10rem !important;
  }

  .xl\:pr-40 {
    padding-right: 10rem !important;
  }

  .xl\:pb-40 {
    padding-bottom: 10rem !important;
  }

  .xl\:pl-40 {
    padding-left: 10rem !important;
  }

  .xl\:pt-48 {
    padding-top: 12rem !important;
  }

  .xl\:pr-48 {
    padding-right: 12rem !important;
  }

  .xl\:pb-48 {
    padding-bottom: 12rem !important;
  }

  .xl\:pl-48 {
    padding-left: 12rem !important;
  }

  .xl\:pt-56 {
    padding-top: 14rem !important;
  }

  .xl\:pr-56 {
    padding-right: 14rem !important;
  }

  .xl\:pb-56 {
    padding-bottom: 14rem !important;
  }

  .xl\:pl-56 {
    padding-left: 14rem !important;
  }

  .xl\:pt-64 {
    padding-top: 16rem !important;
  }

  .xl\:pr-64 {
    padding-right: 16rem !important;
  }

  .xl\:pb-64 {
    padding-bottom: 16rem !important;
  }

  .xl\:pl-64 {
    padding-left: 16rem !important;
  }

  .xl\:pt-px {
    padding-top: 1px !important;
  }

  .xl\:pr-px {
    padding-right: 1px !important;
  }

  .xl\:pb-px {
    padding-bottom: 1px !important;
  }

  .xl\:pl-px {
    padding-left: 1px !important;
  }

  .xl\:pt-2px {
    padding-top: 2px !important;
  }

  .xl\:pr-2px {
    padding-right: 2px !important;
  }

  .xl\:pb-2px {
    padding-bottom: 2px !important;
  }

  .xl\:pl-2px {
    padding-left: 2px !important;
  }

  .xl\:placeholder-opacity-0::-webkit-input-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .xl\:placeholder-opacity-0::-moz-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .xl\:placeholder-opacity-0::-ms-input-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .xl\:placeholder-opacity-0::placeholder {
    --placeholder-opacity: 0 !important;
  }

  .xl\:placeholder-opacity-12::-webkit-input-placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .xl\:placeholder-opacity-12::-moz-placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .xl\:placeholder-opacity-12::-ms-input-placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .xl\:placeholder-opacity-12::placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .xl\:placeholder-opacity-25::-webkit-input-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .xl\:placeholder-opacity-25::-moz-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .xl\:placeholder-opacity-25::-ms-input-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .xl\:placeholder-opacity-25::placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .xl\:placeholder-opacity-38::-webkit-input-placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .xl\:placeholder-opacity-38::-moz-placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .xl\:placeholder-opacity-38::-ms-input-placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .xl\:placeholder-opacity-38::placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .xl\:placeholder-opacity-50::-webkit-input-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .xl\:placeholder-opacity-50::-moz-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .xl\:placeholder-opacity-50::-ms-input-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .xl\:placeholder-opacity-50::placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .xl\:placeholder-opacity-54::-webkit-input-placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .xl\:placeholder-opacity-54::-moz-placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .xl\:placeholder-opacity-54::-ms-input-placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .xl\:placeholder-opacity-54::placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .xl\:placeholder-opacity-70::-webkit-input-placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .xl\:placeholder-opacity-70::-moz-placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .xl\:placeholder-opacity-70::-ms-input-placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .xl\:placeholder-opacity-70::placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .xl\:placeholder-opacity-75::-webkit-input-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .xl\:placeholder-opacity-75::-moz-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .xl\:placeholder-opacity-75::-ms-input-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .xl\:placeholder-opacity-75::placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .xl\:placeholder-opacity-84::-webkit-input-placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .xl\:placeholder-opacity-84::-moz-placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .xl\:placeholder-opacity-84::-ms-input-placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .xl\:placeholder-opacity-84::placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .xl\:placeholder-opacity-100::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .xl\:placeholder-opacity-100::-moz-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .xl\:placeholder-opacity-100::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .xl\:placeholder-opacity-100::placeholder {
    --placeholder-opacity: 1 !important;
  }

  .xl\:focus\:placeholder-opacity-0:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .xl\:focus\:placeholder-opacity-0:focus::-moz-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .xl\:focus\:placeholder-opacity-0:focus::-ms-input-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .xl\:focus\:placeholder-opacity-0:focus::placeholder {
    --placeholder-opacity: 0 !important;
  }

  .xl\:focus\:placeholder-opacity-12:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .xl\:focus\:placeholder-opacity-12:focus::-moz-placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .xl\:focus\:placeholder-opacity-12:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .xl\:focus\:placeholder-opacity-12:focus::placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .xl\:focus\:placeholder-opacity-25:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .xl\:focus\:placeholder-opacity-25:focus::-moz-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .xl\:focus\:placeholder-opacity-25:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .xl\:focus\:placeholder-opacity-25:focus::placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .xl\:focus\:placeholder-opacity-38:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .xl\:focus\:placeholder-opacity-38:focus::-moz-placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .xl\:focus\:placeholder-opacity-38:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .xl\:focus\:placeholder-opacity-38:focus::placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .xl\:focus\:placeholder-opacity-50:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .xl\:focus\:placeholder-opacity-50:focus::-moz-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .xl\:focus\:placeholder-opacity-50:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .xl\:focus\:placeholder-opacity-50:focus::placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .xl\:focus\:placeholder-opacity-54:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .xl\:focus\:placeholder-opacity-54:focus::-moz-placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .xl\:focus\:placeholder-opacity-54:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .xl\:focus\:placeholder-opacity-54:focus::placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .xl\:focus\:placeholder-opacity-70:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .xl\:focus\:placeholder-opacity-70:focus::-moz-placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .xl\:focus\:placeholder-opacity-70:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .xl\:focus\:placeholder-opacity-70:focus::placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .xl\:focus\:placeholder-opacity-75:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .xl\:focus\:placeholder-opacity-75:focus::-moz-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .xl\:focus\:placeholder-opacity-75:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .xl\:focus\:placeholder-opacity-75:focus::placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .xl\:focus\:placeholder-opacity-84:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .xl\:focus\:placeholder-opacity-84:focus::-moz-placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .xl\:focus\:placeholder-opacity-84:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .xl\:focus\:placeholder-opacity-84:focus::placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .xl\:focus\:placeholder-opacity-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .xl\:focus\:placeholder-opacity-100:focus::-moz-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .xl\:focus\:placeholder-opacity-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .xl\:focus\:placeholder-opacity-100:focus::placeholder {
    --placeholder-opacity: 1 !important;
  }

  .xl\:pointer-events-none {
    pointer-events: none !important;
  }

  .xl\:pointer-events-auto {
    pointer-events: auto !important;
  }

  .xl\:static {
    position: static !important;
  }

  .xl\:fixed {
    position: fixed !important;
  }

  .xl\:absolute {
    position: absolute !important;
  }

  .xl\:relative {
    position: relative !important;
  }

  .xl\:sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }

  .xl\:inset-0 {
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
  }

  .xl\:inset-auto {
    top: auto !important;
    right: auto !important;
    bottom: auto !important;
    left: auto !important;
  }

  .xl\:inset-y-0 {
    top: 0 !important;
    bottom: 0 !important;
  }

  .xl\:inset-x-0 {
    right: 0 !important;
    left: 0 !important;
  }

  .xl\:inset-y-auto {
    top: auto !important;
    bottom: auto !important;
  }

  .xl\:inset-x-auto {
    right: auto !important;
    left: auto !important;
  }

  .xl\:top-0 {
    top: 0 !important;
  }

  .xl\:right-0 {
    right: 0 !important;
  }

  .xl\:bottom-0 {
    bottom: 0 !important;
  }

  .xl\:left-0 {
    left: 0 !important;
  }

  .xl\:top-auto {
    top: auto !important;
  }

  .xl\:right-auto {
    right: auto !important;
  }

  .xl\:bottom-auto {
    bottom: auto !important;
  }

  .xl\:left-auto {
    left: auto !important;
  }

  .xl\:shadow-xs {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05) !important;
  }

  .xl\:shadow-sm {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  }

  .xl\:shadow {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .xl\:shadow-md {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  }

  .xl\:shadow-lg {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  }

  .xl\:shadow-xl {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
  }

  .xl\:shadow-2xl {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
  }

  .xl\:shadow-inner {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .xl\:ring {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }

  .xl\:shadow-none {
    box-shadow: none !important;
  }

  .xl\:shadow-solid {
    box-shadow: 0 0 0 2px currentColor !important;
  }

  .xl\:hover\:shadow-xs:hover {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05) !important;
  }

  .xl\:hover\:shadow-sm:hover {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  }

  .xl\:hover\:shadow:hover {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .xl\:hover\:shadow-md:hover {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  }

  .xl\:hover\:shadow-lg:hover {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  }

  .xl\:hover\:shadow-xl:hover {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
  }

  .xl\:hover\:shadow-2xl:hover {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
  }

  .xl\:hover\:shadow-inner:hover {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .xl\:hover\:ring:hover {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }

  .xl\:hover\:shadow-none:hover {
    box-shadow: none !important;
  }

  .xl\:hover\:shadow-solid:hover {
    box-shadow: 0 0 0 2px currentColor !important;
  }

  .xl\:focus\:shadow-xs:focus {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05) !important;
  }

  .xl\:focus\:shadow-sm:focus {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  }

  .xl\:focus\:shadow:focus {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .xl\:focus\:shadow-md:focus {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  }

  .xl\:focus\:shadow-lg:focus {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  }

  .xl\:focus\:shadow-xl:focus {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
  }

  .xl\:focus\:shadow-2xl:focus {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
  }

  .xl\:focus\:shadow-inner:focus {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .xl\:focus\:ring:focus {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }

  .xl\:focus\:shadow-none:focus {
    box-shadow: none !important;
  }

  .xl\:focus\:shadow-solid:focus {
    box-shadow: 0 0 0 2px currentColor !important;
  }

  .xl\:fill-current {
    fill: currentColor !important;
  }

  .xl\:stroke-current {
    stroke: currentColor !important;
  }

  .xl\:stroke-0 {
    stroke-width: 0 !important;
  }

  .xl\:stroke-1 {
    stroke-width: 1 !important;
  }

  .xl\:stroke-2 {
    stroke-width: 2 !important;
  }

  .xl\:table-auto {
    table-layout: auto !important;
  }

  .xl\:table-fixed {
    table-layout: fixed !important;
  }

  .xl\:text-left {
    text-align: left !important;
  }

  .xl\:text-center {
    text-align: center !important;
  }

  .xl\:text-right {
    text-align: right !important;
  }

  .xl\:text-justify {
    text-align: justify !important;
  }

  .xl\:text-opacity-0 {
    --text-opacity: 0 !important;
  }

  .xl\:text-opacity-12 {
    --text-opacity: 0.12 !important;
  }

  .xl\:text-opacity-25 {
    --text-opacity: 0.25 !important;
  }

  .xl\:text-opacity-38 {
    --text-opacity: 0.38 !important;
  }

  .xl\:text-opacity-50 {
    --text-opacity: 0.5 !important;
  }

  .xl\:text-opacity-54 {
    --text-opacity: 0.54 !important;
  }

  .xl\:text-opacity-70 {
    --text-opacity: 0.70 !important;
  }

  .xl\:text-opacity-75 {
    --text-opacity: 0.75 !important;
  }

  .xl\:text-opacity-84 {
    --text-opacity: 0.84 !important;
  }

  .xl\:text-opacity-100 {
    --text-opacity: 1 !important;
  }

  .xl\:hover\:text-opacity-0:hover {
    --text-opacity: 0 !important;
  }

  .xl\:hover\:text-opacity-12:hover {
    --text-opacity: 0.12 !important;
  }

  .xl\:hover\:text-opacity-25:hover {
    --text-opacity: 0.25 !important;
  }

  .xl\:hover\:text-opacity-38:hover {
    --text-opacity: 0.38 !important;
  }

  .xl\:hover\:text-opacity-50:hover {
    --text-opacity: 0.5 !important;
  }

  .xl\:hover\:text-opacity-54:hover {
    --text-opacity: 0.54 !important;
  }

  .xl\:hover\:text-opacity-70:hover {
    --text-opacity: 0.70 !important;
  }

  .xl\:hover\:text-opacity-75:hover {
    --text-opacity: 0.75 !important;
  }

  .xl\:hover\:text-opacity-84:hover {
    --text-opacity: 0.84 !important;
  }

  .xl\:hover\:text-opacity-100:hover {
    --text-opacity: 1 !important;
  }

  .xl\:focus\:text-opacity-0:focus {
    --text-opacity: 0 !important;
  }

  .xl\:focus\:text-opacity-12:focus {
    --text-opacity: 0.12 !important;
  }

  .xl\:focus\:text-opacity-25:focus {
    --text-opacity: 0.25 !important;
  }

  .xl\:focus\:text-opacity-38:focus {
    --text-opacity: 0.38 !important;
  }

  .xl\:focus\:text-opacity-50:focus {
    --text-opacity: 0.5 !important;
  }

  .xl\:focus\:text-opacity-54:focus {
    --text-opacity: 0.54 !important;
  }

  .xl\:focus\:text-opacity-70:focus {
    --text-opacity: 0.70 !important;
  }

  .xl\:focus\:text-opacity-75:focus {
    --text-opacity: 0.75 !important;
  }

  .xl\:focus\:text-opacity-84:focus {
    --text-opacity: 0.84 !important;
  }

  .xl\:focus\:text-opacity-100:focus {
    --text-opacity: 1 !important;
  }

  .xl\:italic {
    font-style: italic !important;
  }

  .xl\:not-italic {
    font-style: normal !important;
  }

  .xl\:uppercase {
    text-transform: uppercase !important;
  }

  .xl\:lowercase {
    text-transform: lowercase !important;
  }

  .xl\:capitalize {
    text-transform: capitalize !important;
  }

  .xl\:normal-case {
    text-transform: none !important;
  }

  .xl\:underline {
    text-decoration: underline !important;
  }

  .xl\:line-through {
    text-decoration: line-through !important;
  }

  .xl\:no-underline {
    text-decoration: none !important;
  }

  .xl\:hover\:underline:hover {
    text-decoration: underline !important;
  }

  .xl\:hover\:line-through:hover {
    text-decoration: line-through !important;
  }

  .xl\:hover\:no-underline:hover {
    text-decoration: none !important;
  }

  .xl\:focus\:underline:focus {
    text-decoration: underline !important;
  }

  .xl\:focus\:line-through:focus {
    text-decoration: line-through !important;
  }

  .xl\:focus\:no-underline:focus {
    text-decoration: none !important;
  }

  .xl\:tracking-tighter {
    letter-spacing: -0.05em !important;
  }

  .xl\:tracking-tight {
    letter-spacing: -0.025em !important;
  }

  .xl\:tracking-normal {
    letter-spacing: 0 !important;
  }

  .xl\:tracking-wide {
    letter-spacing: 0.025em !important;
  }

  .xl\:tracking-wider {
    letter-spacing: 0.05em !important;
  }

  .xl\:tracking-widest {
    letter-spacing: 0.1em !important;
  }

  .xl\:select-none {
    -webkit-user-select: none !important;
       -moz-user-select: none !important;
        -ms-user-select: none !important;
            user-select: none !important;
  }

  .xl\:select-text {
    -webkit-user-select: text !important;
       -moz-user-select: text !important;
        -ms-user-select: text !important;
            user-select: text !important;
  }

  .xl\:select-all {
    -webkit-user-select: all !important;
       -moz-user-select: all !important;
        -ms-user-select: all !important;
            user-select: all !important;
  }

  .xl\:select-auto {
    -webkit-user-select: auto !important;
       -moz-user-select: auto !important;
        -ms-user-select: auto !important;
            user-select: auto !important;
  }

  .xl\:align-baseline {
    vertical-align: baseline !important;
  }

  .xl\:align-top {
    vertical-align: top !important;
  }

  .xl\:align-middle {
    vertical-align: middle !important;
  }

  .xl\:align-bottom {
    vertical-align: bottom !important;
  }

  .xl\:align-text-top {
    vertical-align: text-top !important;
  }

  .xl\:align-text-bottom {
    vertical-align: text-bottom !important;
  }

  .xl\:visible {
    visibility: visible !important;
  }

  .xl\:invisible {
    visibility: hidden !important;
  }

  .xl\:whitespace-normal {
    white-space: normal !important;
  }

  .xl\:whitespace-nowrap {
    white-space: nowrap !important;
  }

  .xl\:whitespace-pre {
    white-space: pre !important;
  }

  .xl\:whitespace-pre-line {
    white-space: pre-line !important;
  }

  .xl\:whitespace-pre-wrap {
    white-space: pre-wrap !important;
  }

  .xl\:break-normal {
    overflow-wrap: normal !important;
    word-break: normal !important;
  }

  .xl\:break-words {
    overflow-wrap: break-word !important;
  }

  .xl\:break-all {
    word-break: break-all !important;
  }

  .xl\:truncate {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
  }

  .xl\:w-0 {
    width: 0 !important;
  }

  .xl\:w-1 {
    width: 0.25rem !important;
  }

  .xl\:w-2 {
    width: 0.5rem !important;
  }

  .xl\:w-3 {
    width: 0.75rem !important;
  }

  .xl\:w-4 {
    width: 1rem !important;
  }

  .xl\:w-5 {
    width: 1.25rem !important;
  }

  .xl\:w-6 {
    width: 1.5rem !important;
  }

  .xl\:w-8 {
    width: 2rem !important;
  }

  .xl\:w-10 {
    width: 2.5rem !important;
  }

  .xl\:w-12 {
    width: 3rem !important;
  }

  .xl\:w-14 {
    width: 3.5rem !important;
  }

  .xl\:w-16 {
    width: 4rem !important;
  }

  .xl\:w-18 {
    width: 4.5rem !important;
  }

  .xl\:w-20 {
    width: 5rem !important;
  }

  .xl\:w-22 {
    width: 5.5rem !important;
  }

  .xl\:w-24 {
    width: 6rem !important;
  }

  .xl\:w-26 {
    width: 6.5rem !important;
  }

  .xl\:w-28 {
    width: 7rem !important;
  }

  .xl\:w-30 {
    width: 7.5rem !important;
  }

  .xl\:w-32 {
    width: 8rem !important;
  }

  .xl\:w-36 {
    width: 9rem !important;
  }

  .xl\:w-40 {
    width: 10rem !important;
  }

  .xl\:w-48 {
    width: 12rem !important;
  }

  .xl\:w-50 {
    width: 12.5rem !important;
  }

  .xl\:w-56 {
    width: 14rem !important;
  }

  .xl\:w-60 {
    width: 15rem !important;
  }

  .xl\:w-64 {
    width: 16rem !important;
  }

  .xl\:w-80 {
    width: 20rem !important;
  }

  .xl\:w-90 {
    width: 24rem !important;
  }

  .xl\:w-100 {
    width: 25rem !important;
  }

  .xl\:w-120 {
    width: 30rem !important;
  }

  .xl\:w-128 {
    width: 32rem !important;
  }

  .xl\:w-140 {
    width: 35rem !important;
  }

  .xl\:w-160 {
    width: 40rem !important;
  }

  .xl\:w-180 {
    width: 45rem !important;
  }

  .xl\:w-192 {
    width: 48rem !important;
  }

  .xl\:w-200 {
    width: 50rem !important;
  }

  .xl\:w-240 {
    width: 60rem !important;
  }

  .xl\:w-256 {
    width: 64rem !important;
  }

  .xl\:w-280 {
    width: 70rem !important;
  }

  .xl\:w-320 {
    width: 80rem !important;
  }

  .xl\:w-360 {
    width: 90rem !important;
  }

  .xl\:w-400 {
    width: 100rem !important;
  }

  .xl\:w-480 {
    width: 120rem !important;
  }

  .xl\:w-auto {
    width: auto !important;
  }

  .xl\:w-px {
    width: 1px !important;
  }

  .xl\:w-2px {
    width: 2px !important;
  }

  .xl\:w-1\/2 {
    width: 50% !important;
  }

  .xl\:w-1\/3 {
    width: 33.33333% !important;
  }

  .xl\:w-2\/3 {
    width: 66.66667% !important;
  }

  .xl\:w-1\/4 {
    width: 25% !important;
  }

  .xl\:w-2\/4 {
    width: 50% !important;
  }

  .xl\:w-3\/4 {
    width: 75% !important;
  }

  .xl\:w-1\/5 {
    width: 20% !important;
  }

  .xl\:w-2\/5 {
    width: 40% !important;
  }

  .xl\:w-3\/5 {
    width: 60% !important;
  }

  .xl\:w-4\/5 {
    width: 80% !important;
  }

  .xl\:w-1\/6 {
    width: 16.666667% !important;
  }

  .xl\:w-2\/6 {
    width: 33.333333% !important;
  }

  .xl\:w-3\/6 {
    width: 50% !important;
  }

  .xl\:w-4\/6 {
    width: 66.666667% !important;
  }

  .xl\:w-5\/6 {
    width: 83.333333% !important;
  }

  .xl\:w-1\/12 {
    width: 8.33333% !important;
  }

  .xl\:w-2\/12 {
    width: 16.66667% !important;
  }

  .xl\:w-3\/12 {
    width: 25% !important;
  }

  .xl\:w-4\/12 {
    width: 33.33333% !important;
  }

  .xl\:w-5\/12 {
    width: 41.66667% !important;
  }

  .xl\:w-6\/12 {
    width: 50% !important;
  }

  .xl\:w-7\/12 {
    width: 58.33333% !important;
  }

  .xl\:w-8\/12 {
    width: 66.66667% !important;
  }

  .xl\:w-9\/12 {
    width: 75% !important;
  }

  .xl\:w-10\/12 {
    width: 83.33333% !important;
  }

  .xl\:w-11\/12 {
    width: 91.66667% !important;
  }

  .xl\:w-full {
    width: 100% !important;
  }

  .xl\:w-screen {
    width: 100vw !important;
  }

  .xl\:z-0 {
    z-index: 0 !important;
  }

  .xl\:z-10 {
    z-index: 10 !important;
  }

  .xl\:z-20 {
    z-index: 20 !important;
  }

  .xl\:z-30 {
    z-index: 30 !important;
  }

  .xl\:z-40 {
    z-index: 40 !important;
  }

  .xl\:z-50 {
    z-index: 50 !important;
  }

  .xl\:z-60 {
    z-index: 60 !important;
  }

  .xl\:z-70 {
    z-index: 70 !important;
  }

  .xl\:z-80 {
    z-index: 80 !important;
  }

  .xl\:z-90 {
    z-index: 90 !important;
  }

  .xl\:z-99 {
    z-index: 99 !important;
  }

  .xl\:z-999 {
    z-index: 999 !important;
  }

  .xl\:z-9999 {
    z-index: 9999 !important;
  }

  .xl\:z-99999 {
    z-index: 99999 !important;
  }

  .xl\:z-auto {
    z-index: auto !important;
  }

  .xl\:-z-1 {
    z-index: -1 !important;
  }

  .xl\:gap-0 {
    grid-gap: 0 !important;
    gap: 0 !important;
  }

  .xl\:gap-1 {
    grid-gap: 0.25rem !important;
    gap: 0.25rem !important;
  }

  .xl\:gap-2 {
    grid-gap: 0.5rem !important;
    gap: 0.5rem !important;
  }

  .xl\:gap-3 {
    grid-gap: 0.75rem !important;
    gap: 0.75rem !important;
  }

  .xl\:gap-4 {
    grid-gap: 1rem !important;
    gap: 1rem !important;
  }

  .xl\:gap-5 {
    grid-gap: 1.25rem !important;
    gap: 1.25rem !important;
  }

  .xl\:gap-6 {
    grid-gap: 1.5rem !important;
    gap: 1.5rem !important;
  }

  .xl\:gap-8 {
    grid-gap: 2rem !important;
    gap: 2rem !important;
  }

  .xl\:gap-10 {
    grid-gap: 2.5rem !important;
    gap: 2.5rem !important;
  }

  .xl\:gap-12 {
    grid-gap: 3rem !important;
    gap: 3rem !important;
  }

  .xl\:gap-14 {
    grid-gap: 3.5rem !important;
    gap: 3.5rem !important;
  }

  .xl\:gap-16 {
    grid-gap: 4rem !important;
    gap: 4rem !important;
  }

  .xl\:gap-18 {
    grid-gap: 4.5rem !important;
    gap: 4.5rem !important;
  }

  .xl\:gap-20 {
    grid-gap: 5rem !important;
    gap: 5rem !important;
  }

  .xl\:gap-22 {
    grid-gap: 5.5rem !important;
    gap: 5.5rem !important;
  }

  .xl\:gap-24 {
    grid-gap: 6rem !important;
    gap: 6rem !important;
  }

  .xl\:gap-26 {
    grid-gap: 6.5rem !important;
    gap: 6.5rem !important;
  }

  .xl\:gap-28 {
    grid-gap: 7rem !important;
    gap: 7rem !important;
  }

  .xl\:gap-30 {
    grid-gap: 7.5rem !important;
    gap: 7.5rem !important;
  }

  .xl\:gap-32 {
    grid-gap: 8rem !important;
    gap: 8rem !important;
  }

  .xl\:gap-36 {
    grid-gap: 9rem !important;
    gap: 9rem !important;
  }

  .xl\:gap-40 {
    grid-gap: 10rem !important;
    gap: 10rem !important;
  }

  .xl\:gap-48 {
    grid-gap: 12rem !important;
    gap: 12rem !important;
  }

  .xl\:gap-56 {
    grid-gap: 14rem !important;
    gap: 14rem !important;
  }

  .xl\:gap-64 {
    grid-gap: 16rem !important;
    gap: 16rem !important;
  }

  .xl\:gap-px {
    grid-gap: 1px !important;
    gap: 1px !important;
  }

  .xl\:gap-2px {
    grid-gap: 2px !important;
    gap: 2px !important;
  }

  .xl\:gap-x-0 {
    grid-column-gap: 0 !important;
    -moz-column-gap: 0 !important;
         column-gap: 0 !important;
  }

  .xl\:gap-x-1 {
    grid-column-gap: 0.25rem !important;
    -moz-column-gap: 0.25rem !important;
         column-gap: 0.25rem !important;
  }

  .xl\:gap-x-2 {
    grid-column-gap: 0.5rem !important;
    -moz-column-gap: 0.5rem !important;
         column-gap: 0.5rem !important;
  }

  .xl\:gap-x-3 {
    grid-column-gap: 0.75rem !important;
    -moz-column-gap: 0.75rem !important;
         column-gap: 0.75rem !important;
  }

  .xl\:gap-x-4 {
    grid-column-gap: 1rem !important;
    -moz-column-gap: 1rem !important;
         column-gap: 1rem !important;
  }

  .xl\:gap-x-5 {
    grid-column-gap: 1.25rem !important;
    -moz-column-gap: 1.25rem !important;
         column-gap: 1.25rem !important;
  }

  .xl\:gap-x-6 {
    grid-column-gap: 1.5rem !important;
    -moz-column-gap: 1.5rem !important;
         column-gap: 1.5rem !important;
  }

  .xl\:gap-x-8 {
    grid-column-gap: 2rem !important;
    -moz-column-gap: 2rem !important;
         column-gap: 2rem !important;
  }

  .xl\:gap-x-10 {
    grid-column-gap: 2.5rem !important;
    -moz-column-gap: 2.5rem !important;
         column-gap: 2.5rem !important;
  }

  .xl\:gap-x-12 {
    grid-column-gap: 3rem !important;
    -moz-column-gap: 3rem !important;
         column-gap: 3rem !important;
  }

  .xl\:gap-x-14 {
    grid-column-gap: 3.5rem !important;
    -moz-column-gap: 3.5rem !important;
         column-gap: 3.5rem !important;
  }

  .xl\:gap-x-16 {
    grid-column-gap: 4rem !important;
    -moz-column-gap: 4rem !important;
         column-gap: 4rem !important;
  }

  .xl\:gap-x-18 {
    grid-column-gap: 4.5rem !important;
    -moz-column-gap: 4.5rem !important;
         column-gap: 4.5rem !important;
  }

  .xl\:gap-x-20 {
    grid-column-gap: 5rem !important;
    -moz-column-gap: 5rem !important;
         column-gap: 5rem !important;
  }

  .xl\:gap-x-22 {
    grid-column-gap: 5.5rem !important;
    -moz-column-gap: 5.5rem !important;
         column-gap: 5.5rem !important;
  }

  .xl\:gap-x-24 {
    grid-column-gap: 6rem !important;
    -moz-column-gap: 6rem !important;
         column-gap: 6rem !important;
  }

  .xl\:gap-x-26 {
    grid-column-gap: 6.5rem !important;
    -moz-column-gap: 6.5rem !important;
         column-gap: 6.5rem !important;
  }

  .xl\:gap-x-28 {
    grid-column-gap: 7rem !important;
    -moz-column-gap: 7rem !important;
         column-gap: 7rem !important;
  }

  .xl\:gap-x-30 {
    grid-column-gap: 7.5rem !important;
    -moz-column-gap: 7.5rem !important;
         column-gap: 7.5rem !important;
  }

  .xl\:gap-x-32 {
    grid-column-gap: 8rem !important;
    -moz-column-gap: 8rem !important;
         column-gap: 8rem !important;
  }

  .xl\:gap-x-36 {
    grid-column-gap: 9rem !important;
    -moz-column-gap: 9rem !important;
         column-gap: 9rem !important;
  }

  .xl\:gap-x-40 {
    grid-column-gap: 10rem !important;
    -moz-column-gap: 10rem !important;
         column-gap: 10rem !important;
  }

  .xl\:gap-x-48 {
    grid-column-gap: 12rem !important;
    -moz-column-gap: 12rem !important;
         column-gap: 12rem !important;
  }

  .xl\:gap-x-56 {
    grid-column-gap: 14rem !important;
    -moz-column-gap: 14rem !important;
         column-gap: 14rem !important;
  }

  .xl\:gap-x-64 {
    grid-column-gap: 16rem !important;
    -moz-column-gap: 16rem !important;
         column-gap: 16rem !important;
  }

  .xl\:gap-x-px {
    grid-column-gap: 1px !important;
    -moz-column-gap: 1px !important;
         column-gap: 1px !important;
  }

  .xl\:gap-x-2px {
    grid-column-gap: 2px !important;
    -moz-column-gap: 2px !important;
         column-gap: 2px !important;
  }

  .xl\:gap-y-0 {
    grid-row-gap: 0 !important;
    row-gap: 0 !important;
  }

  .xl\:gap-y-1 {
    grid-row-gap: 0.25rem !important;
    row-gap: 0.25rem !important;
  }

  .xl\:gap-y-2 {
    grid-row-gap: 0.5rem !important;
    row-gap: 0.5rem !important;
  }

  .xl\:gap-y-3 {
    grid-row-gap: 0.75rem !important;
    row-gap: 0.75rem !important;
  }

  .xl\:gap-y-4 {
    grid-row-gap: 1rem !important;
    row-gap: 1rem !important;
  }

  .xl\:gap-y-5 {
    grid-row-gap: 1.25rem !important;
    row-gap: 1.25rem !important;
  }

  .xl\:gap-y-6 {
    grid-row-gap: 1.5rem !important;
    row-gap: 1.5rem !important;
  }

  .xl\:gap-y-8 {
    grid-row-gap: 2rem !important;
    row-gap: 2rem !important;
  }

  .xl\:gap-y-10 {
    grid-row-gap: 2.5rem !important;
    row-gap: 2.5rem !important;
  }

  .xl\:gap-y-12 {
    grid-row-gap: 3rem !important;
    row-gap: 3rem !important;
  }

  .xl\:gap-y-14 {
    grid-row-gap: 3.5rem !important;
    row-gap: 3.5rem !important;
  }

  .xl\:gap-y-16 {
    grid-row-gap: 4rem !important;
    row-gap: 4rem !important;
  }

  .xl\:gap-y-18 {
    grid-row-gap: 4.5rem !important;
    row-gap: 4.5rem !important;
  }

  .xl\:gap-y-20 {
    grid-row-gap: 5rem !important;
    row-gap: 5rem !important;
  }

  .xl\:gap-y-22 {
    grid-row-gap: 5.5rem !important;
    row-gap: 5.5rem !important;
  }

  .xl\:gap-y-24 {
    grid-row-gap: 6rem !important;
    row-gap: 6rem !important;
  }

  .xl\:gap-y-26 {
    grid-row-gap: 6.5rem !important;
    row-gap: 6.5rem !important;
  }

  .xl\:gap-y-28 {
    grid-row-gap: 7rem !important;
    row-gap: 7rem !important;
  }

  .xl\:gap-y-30 {
    grid-row-gap: 7.5rem !important;
    row-gap: 7.5rem !important;
  }

  .xl\:gap-y-32 {
    grid-row-gap: 8rem !important;
    row-gap: 8rem !important;
  }

  .xl\:gap-y-36 {
    grid-row-gap: 9rem !important;
    row-gap: 9rem !important;
  }

  .xl\:gap-y-40 {
    grid-row-gap: 10rem !important;
    row-gap: 10rem !important;
  }

  .xl\:gap-y-48 {
    grid-row-gap: 12rem !important;
    row-gap: 12rem !important;
  }

  .xl\:gap-y-56 {
    grid-row-gap: 14rem !important;
    row-gap: 14rem !important;
  }

  .xl\:gap-y-64 {
    grid-row-gap: 16rem !important;
    row-gap: 16rem !important;
  }

  .xl\:gap-y-px {
    grid-row-gap: 1px !important;
    row-gap: 1px !important;
  }

  .xl\:gap-y-2px {
    grid-row-gap: 2px !important;
    row-gap: 2px !important;
  }

  .xl\:grid-flow-row {
    grid-auto-flow: row !important;
  }

  .xl\:grid-flow-col {
    grid-auto-flow: column !important;
  }

  .xl\:grid-flow-row-dense {
    grid-auto-flow: row dense !important;
  }

  .xl\:grid-flow-col-dense {
    grid-auto-flow: column dense !important;
  }

  .xl\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }

  .xl\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }

  .xl\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
  }

  .xl\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
  }

  .xl\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr)) !important;
  }

  .xl\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr)) !important;
  }

  .xl\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr)) !important;
  }

  .xl\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr)) !important;
  }

  .xl\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr)) !important;
  }

  .xl\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr)) !important;
  }

  .xl\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr)) !important;
  }

  .xl\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr)) !important;
  }

  .xl\:grid-cols-none {
    grid-template-columns: none !important;
  }

  .xl\:col-auto {
    grid-column: auto !important;
  }

  .xl\:col-span-1 {
    grid-column: span 1 / span 1 !important;
  }

  .xl\:col-span-2 {
    grid-column: span 2 / span 2 !important;
  }

  .xl\:col-span-3 {
    grid-column: span 3 / span 3 !important;
  }

  .xl\:col-span-4 {
    grid-column: span 4 / span 4 !important;
  }

  .xl\:col-span-5 {
    grid-column: span 5 / span 5 !important;
  }

  .xl\:col-span-6 {
    grid-column: span 6 / span 6 !important;
  }

  .xl\:col-span-7 {
    grid-column: span 7 / span 7 !important;
  }

  .xl\:col-span-8 {
    grid-column: span 8 / span 8 !important;
  }

  .xl\:col-span-9 {
    grid-column: span 9 / span 9 !important;
  }

  .xl\:col-span-10 {
    grid-column: span 10 / span 10 !important;
  }

  .xl\:col-span-11 {
    grid-column: span 11 / span 11 !important;
  }

  .xl\:col-span-12 {
    grid-column: span 12 / span 12 !important;
  }

  .xl\:col-start-1 {
    grid-column-start: 1 !important;
  }

  .xl\:col-start-2 {
    grid-column-start: 2 !important;
  }

  .xl\:col-start-3 {
    grid-column-start: 3 !important;
  }

  .xl\:col-start-4 {
    grid-column-start: 4 !important;
  }

  .xl\:col-start-5 {
    grid-column-start: 5 !important;
  }

  .xl\:col-start-6 {
    grid-column-start: 6 !important;
  }

  .xl\:col-start-7 {
    grid-column-start: 7 !important;
  }

  .xl\:col-start-8 {
    grid-column-start: 8 !important;
  }

  .xl\:col-start-9 {
    grid-column-start: 9 !important;
  }

  .xl\:col-start-10 {
    grid-column-start: 10 !important;
  }

  .xl\:col-start-11 {
    grid-column-start: 11 !important;
  }

  .xl\:col-start-12 {
    grid-column-start: 12 !important;
  }

  .xl\:col-start-13 {
    grid-column-start: 13 !important;
  }

  .xl\:col-start-auto {
    grid-column-start: auto !important;
  }

  .xl\:col-end-1 {
    grid-column-end: 1 !important;
  }

  .xl\:col-end-2 {
    grid-column-end: 2 !important;
  }

  .xl\:col-end-3 {
    grid-column-end: 3 !important;
  }

  .xl\:col-end-4 {
    grid-column-end: 4 !important;
  }

  .xl\:col-end-5 {
    grid-column-end: 5 !important;
  }

  .xl\:col-end-6 {
    grid-column-end: 6 !important;
  }

  .xl\:col-end-7 {
    grid-column-end: 7 !important;
  }

  .xl\:col-end-8 {
    grid-column-end: 8 !important;
  }

  .xl\:col-end-9 {
    grid-column-end: 9 !important;
  }

  .xl\:col-end-10 {
    grid-column-end: 10 !important;
  }

  .xl\:col-end-11 {
    grid-column-end: 11 !important;
  }

  .xl\:col-end-12 {
    grid-column-end: 12 !important;
  }

  .xl\:col-end-13 {
    grid-column-end: 13 !important;
  }

  .xl\:col-end-auto {
    grid-column-end: auto !important;
  }

  .xl\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr)) !important;
  }

  .xl\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr)) !important;
  }

  .xl\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr)) !important;
  }

  .xl\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr)) !important;
  }

  .xl\:grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr)) !important;
  }

  .xl\:grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr)) !important;
  }

  .xl\:grid-rows-none {
    grid-template-rows: none !important;
  }

  .xl\:row-auto {
    grid-row: auto !important;
  }

  .xl\:row-span-1 {
    grid-row: span 1 / span 1 !important;
  }

  .xl\:row-span-2 {
    grid-row: span 2 / span 2 !important;
  }

  .xl\:row-span-3 {
    grid-row: span 3 / span 3 !important;
  }

  .xl\:row-span-4 {
    grid-row: span 4 / span 4 !important;
  }

  .xl\:row-span-5 {
    grid-row: span 5 / span 5 !important;
  }

  .xl\:row-span-6 {
    grid-row: span 6 / span 6 !important;
  }

  .xl\:row-start-1 {
    grid-row-start: 1 !important;
  }

  .xl\:row-start-2 {
    grid-row-start: 2 !important;
  }

  .xl\:row-start-3 {
    grid-row-start: 3 !important;
  }

  .xl\:row-start-4 {
    grid-row-start: 4 !important;
  }

  .xl\:row-start-5 {
    grid-row-start: 5 !important;
  }

  .xl\:row-start-6 {
    grid-row-start: 6 !important;
  }

  .xl\:row-start-7 {
    grid-row-start: 7 !important;
  }

  .xl\:row-start-auto {
    grid-row-start: auto !important;
  }

  .xl\:row-end-1 {
    grid-row-end: 1 !important;
  }

  .xl\:row-end-2 {
    grid-row-end: 2 !important;
  }

  .xl\:row-end-3 {
    grid-row-end: 3 !important;
  }

  .xl\:row-end-4 {
    grid-row-end: 4 !important;
  }

  .xl\:row-end-5 {
    grid-row-end: 5 !important;
  }

  .xl\:row-end-6 {
    grid-row-end: 6 !important;
  }

  .xl\:row-end-7 {
    grid-row-end: 7 !important;
  }

  .xl\:row-end-auto {
    grid-row-end: auto !important;
  }

  .xl\:transform {
    --transform-translate-x: 0 !important;
    --transform-translate-y: 0 !important;
    --transform-rotate: 0 !important;
    --transform-skew-x: 0 !important;
    --transform-skew-y: 0 !important;
    --transform-scale-x: 1 !important;
    --transform-scale-y: 1 !important;
    transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y)) !important;
  }

  .xl\:transform-none {
    transform: none !important;
  }

  .xl\:origin-center {
    transform-origin: center !important;
  }

  .xl\:origin-top {
    transform-origin: top !important;
  }

  .xl\:origin-top-right {
    transform-origin: top right !important;
  }

  .xl\:origin-right {
    transform-origin: right !important;
  }

  .xl\:origin-bottom-right {
    transform-origin: bottom right !important;
  }

  .xl\:origin-bottom {
    transform-origin: bottom !important;
  }

  .xl\:origin-bottom-left {
    transform-origin: bottom left !important;
  }

  .xl\:origin-left {
    transform-origin: left !important;
  }

  .xl\:origin-top-left {
    transform-origin: top left !important;
  }

  .xl\:icon-size-12 {
    width: 12px !important;
    height: 12px !important;
    min-width: 12px !important;
    min-height: 12px !important;
    font-size: 12px !important;
    line-height: 12px !important;
  }

  .xl\:icon-size-12 svg {
    width: 12px !important;
    height: 12px !important;
  }

  .xl\:icon-size-14 {
    width: 14px !important;
    height: 14px !important;
    min-width: 14px !important;
    min-height: 14px !important;
    font-size: 14px !important;
    line-height: 14px !important;
  }

  .xl\:icon-size-14 svg {
    width: 14px !important;
    height: 14px !important;
  }

  .xl\:icon-size-16 {
    width: 16px !important;
    height: 16px !important;
    min-width: 16px !important;
    min-height: 16px !important;
    font-size: 16px !important;
    line-height: 16px !important;
  }

  .xl\:icon-size-16 svg {
    width: 16px !important;
    height: 16px !important;
  }

  .xl\:icon-size-18 {
    width: 18px !important;
    height: 18px !important;
    min-width: 18px !important;
    min-height: 18px !important;
    font-size: 18px !important;
    line-height: 18px !important;
  }

  .xl\:icon-size-18 svg {
    width: 18px !important;
    height: 18px !important;
  }

  .xl\:icon-size-20 {
    width: 20px !important;
    height: 20px !important;
    min-width: 20px !important;
    min-height: 20px !important;
    font-size: 20px !important;
    line-height: 20px !important;
  }

  .xl\:icon-size-20 svg {
    width: 20px !important;
    height: 20px !important;
  }

  .xl\:icon-size-24 {
    width: 24px !important;
    height: 24px !important;
    min-width: 24px !important;
    min-height: 24px !important;
    font-size: 24px !important;
    line-height: 24px !important;
  }

  .xl\:icon-size-24 svg {
    width: 24px !important;
    height: 24px !important;
  }

  .xl\:icon-size-32 {
    width: 32px !important;
    height: 32px !important;
    min-width: 32px !important;
    min-height: 32px !important;
    font-size: 32px !important;
    line-height: 32px !important;
  }

  .xl\:icon-size-32 svg {
    width: 32px !important;
    height: 32px !important;
  }

  .xl\:icon-size-40 {
    width: 40px !important;
    height: 40px !important;
    min-width: 40px !important;
    min-height: 40px !important;
    font-size: 40px !important;
    line-height: 40px !important;
  }

  .xl\:icon-size-40 svg {
    width: 40px !important;
    height: 40px !important;
  }

  .xl\:icon-size-48 {
    width: 48px !important;
    height: 48px !important;
    min-width: 48px !important;
    min-height: 48px !important;
    font-size: 48px !important;
    line-height: 48px !important;
  }

  .xl\:icon-size-48 svg {
    width: 48px !important;
    height: 48px !important;
  }

  .xl\:icon-size-56 {
    width: 56px !important;
    height: 56px !important;
    min-width: 56px !important;
    min-height: 56px !important;
    font-size: 56px !important;
    line-height: 56px !important;
  }

  .xl\:icon-size-56 svg {
    width: 56px !important;
    height: 56px !important;
  }

  .xl\:icon-size-64 {
    width: 64px !important;
    height: 64px !important;
    min-width: 64px !important;
    min-height: 64px !important;
    font-size: 64px !important;
    line-height: 64px !important;
  }

  .xl\:icon-size-64 svg {
    width: 64px !important;
    height: 64px !important;
  }

  .xl\:icon-size-72 {
    width: 72px !important;
    height: 72px !important;
    min-width: 72px !important;
    min-height: 72px !important;
    font-size: 72px !important;
    line-height: 72px !important;
  }

  .xl\:icon-size-72 svg {
    width: 72px !important;
    height: 72px !important;
  }

  .xl\:icon-size-80 {
    width: 80px !important;
    height: 80px !important;
    min-width: 80px !important;
    min-height: 80px !important;
    font-size: 80px !important;
    line-height: 80px !important;
  }

  .xl\:icon-size-80 svg {
    width: 80px !important;
    height: 80px !important;
  }

  .xl\:icon-size-88 {
    width: 88px !important;
    height: 88px !important;
    min-width: 88px !important;
    min-height: 88px !important;
    font-size: 88px !important;
    line-height: 88px !important;
  }

  .xl\:icon-size-88 svg {
    width: 88px !important;
    height: 88px !important;
  }

  .xl\:icon-size-96 {
    width: 96px !important;
    height: 96px !important;
    min-width: 96px !important;
    min-height: 96px !important;
    font-size: 96px !important;
    line-height: 96px !important;
  }

  .xl\:icon-size-96 svg {
    width: 96px !important;
    height: 96px !important;
  }
}

@media (max-width: 959px) {
  .lt-md\:space-y-0 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(0px * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(0px * var(--space-y-reverse)) !important;
  }

  .lt-md\:space-x-0 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(0px * var(--space-x-reverse)) !important;
    margin-left: calc(0px * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-md\:space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(0.25rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(0.25rem * var(--space-y-reverse)) !important;
  }

  .lt-md\:space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(0.25rem * var(--space-x-reverse)) !important;
    margin-left: calc(0.25rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-md\:space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(0.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(0.5rem * var(--space-y-reverse)) !important;
  }

  .lt-md\:space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(0.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(0.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-md\:space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(0.75rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(0.75rem * var(--space-y-reverse)) !important;
  }

  .lt-md\:space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(0.75rem * var(--space-x-reverse)) !important;
    margin-left: calc(0.75rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-md\:space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(1rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(1rem * var(--space-y-reverse)) !important;
  }

  .lt-md\:space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(1rem * var(--space-x-reverse)) !important;
    margin-left: calc(1rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-md\:space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(1.25rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(1.25rem * var(--space-y-reverse)) !important;
  }

  .lt-md\:space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(1.25rem * var(--space-x-reverse)) !important;
    margin-left: calc(1.25rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-md\:space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(1.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(1.5rem * var(--space-y-reverse)) !important;
  }

  .lt-md\:space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(1.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(1.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-md\:space-y-8 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(2rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(2rem * var(--space-y-reverse)) !important;
  }

  .lt-md\:space-x-8 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(2rem * var(--space-x-reverse)) !important;
    margin-left: calc(2rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-md\:space-y-10 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(2.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(2.5rem * var(--space-y-reverse)) !important;
  }

  .lt-md\:space-x-10 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(2.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(2.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-md\:space-y-12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(3rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(3rem * var(--space-y-reverse)) !important;
  }

  .lt-md\:space-x-12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(3rem * var(--space-x-reverse)) !important;
    margin-left: calc(3rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-md\:space-y-14 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(3.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(3.5rem * var(--space-y-reverse)) !important;
  }

  .lt-md\:space-x-14 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(3.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(3.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-md\:space-y-16 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(4rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(4rem * var(--space-y-reverse)) !important;
  }

  .lt-md\:space-x-16 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(4rem * var(--space-x-reverse)) !important;
    margin-left: calc(4rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-md\:space-y-18 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(4.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(4.5rem * var(--space-y-reverse)) !important;
  }

  .lt-md\:space-x-18 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(4.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(4.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-md\:space-y-20 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(5rem * var(--space-y-reverse)) !important;
  }

  .lt-md\:space-x-20 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(5rem * var(--space-x-reverse)) !important;
    margin-left: calc(5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-md\:space-y-22 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(5.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(5.5rem * var(--space-y-reverse)) !important;
  }

  .lt-md\:space-x-22 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(5.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(5.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-md\:space-y-24 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(6rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(6rem * var(--space-y-reverse)) !important;
  }

  .lt-md\:space-x-24 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(6rem * var(--space-x-reverse)) !important;
    margin-left: calc(6rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-md\:space-y-26 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(6.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(6.5rem * var(--space-y-reverse)) !important;
  }

  .lt-md\:space-x-26 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(6.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(6.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-md\:space-y-28 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(7rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(7rem * var(--space-y-reverse)) !important;
  }

  .lt-md\:space-x-28 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(7rem * var(--space-x-reverse)) !important;
    margin-left: calc(7rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-md\:space-y-30 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(7.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(7.5rem * var(--space-y-reverse)) !important;
  }

  .lt-md\:space-x-30 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(7.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(7.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-md\:space-y-32 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(8rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(8rem * var(--space-y-reverse)) !important;
  }

  .lt-md\:space-x-32 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(8rem * var(--space-x-reverse)) !important;
    margin-left: calc(8rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-md\:space-y-36 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(9rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(9rem * var(--space-y-reverse)) !important;
  }

  .lt-md\:space-x-36 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(9rem * var(--space-x-reverse)) !important;
    margin-left: calc(9rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-md\:space-y-40 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(10rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(10rem * var(--space-y-reverse)) !important;
  }

  .lt-md\:space-x-40 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(10rem * var(--space-x-reverse)) !important;
    margin-left: calc(10rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-md\:space-y-48 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(12rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(12rem * var(--space-y-reverse)) !important;
  }

  .lt-md\:space-x-48 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(12rem * var(--space-x-reverse)) !important;
    margin-left: calc(12rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-md\:space-y-56 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(14rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(14rem * var(--space-y-reverse)) !important;
  }

  .lt-md\:space-x-56 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(14rem * var(--space-x-reverse)) !important;
    margin-left: calc(14rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-md\:space-y-64 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(16rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(16rem * var(--space-y-reverse)) !important;
  }

  .lt-md\:space-x-64 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(16rem * var(--space-x-reverse)) !important;
    margin-left: calc(16rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-md\:space-y-px > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(1px * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(1px * var(--space-y-reverse)) !important;
  }

  .lt-md\:space-x-px > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(1px * var(--space-x-reverse)) !important;
    margin-left: calc(1px * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-md\:space-y-2px > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(2px * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(2px * var(--space-y-reverse)) !important;
  }

  .lt-md\:space-x-2px > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(2px * var(--space-x-reverse)) !important;
    margin-left: calc(2px * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-md\:-space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-0.25rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-0.25rem * var(--space-y-reverse)) !important;
  }

  .lt-md\:-space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-0.25rem * var(--space-x-reverse)) !important;
    margin-left: calc(-0.25rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-md\:-space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-0.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-0.5rem * var(--space-y-reverse)) !important;
  }

  .lt-md\:-space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-0.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-0.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-md\:-space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-0.75rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-0.75rem * var(--space-y-reverse)) !important;
  }

  .lt-md\:-space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-0.75rem * var(--space-x-reverse)) !important;
    margin-left: calc(-0.75rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-md\:-space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-1rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-1rem * var(--space-y-reverse)) !important;
  }

  .lt-md\:-space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-1rem * var(--space-x-reverse)) !important;
    margin-left: calc(-1rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-md\:-space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-1.25rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-1.25rem * var(--space-y-reverse)) !important;
  }

  .lt-md\:-space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-1.25rem * var(--space-x-reverse)) !important;
    margin-left: calc(-1.25rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-md\:-space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-1.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-1.5rem * var(--space-y-reverse)) !important;
  }

  .lt-md\:-space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-1.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-1.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-md\:-space-y-8 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-2rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-2rem * var(--space-y-reverse)) !important;
  }

  .lt-md\:-space-x-8 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-2rem * var(--space-x-reverse)) !important;
    margin-left: calc(-2rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-md\:-space-y-10 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-2.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-2.5rem * var(--space-y-reverse)) !important;
  }

  .lt-md\:-space-x-10 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-2.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-2.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-md\:-space-y-12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-3rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-3rem * var(--space-y-reverse)) !important;
  }

  .lt-md\:-space-x-12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-3rem * var(--space-x-reverse)) !important;
    margin-left: calc(-3rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-md\:-space-y-14 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-3.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-3.5rem * var(--space-y-reverse)) !important;
  }

  .lt-md\:-space-x-14 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-3.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-3.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-md\:-space-y-16 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-4rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-4rem * var(--space-y-reverse)) !important;
  }

  .lt-md\:-space-x-16 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-4rem * var(--space-x-reverse)) !important;
    margin-left: calc(-4rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-md\:-space-y-18 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-4.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-4.5rem * var(--space-y-reverse)) !important;
  }

  .lt-md\:-space-x-18 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-4.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-4.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-md\:-space-y-20 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-5rem * var(--space-y-reverse)) !important;
  }

  .lt-md\:-space-x-20 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-md\:-space-y-22 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-5.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-5.5rem * var(--space-y-reverse)) !important;
  }

  .lt-md\:-space-x-22 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-5.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-5.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-md\:-space-y-24 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-6rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-6rem * var(--space-y-reverse)) !important;
  }

  .lt-md\:-space-x-24 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-6rem * var(--space-x-reverse)) !important;
    margin-left: calc(-6rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-md\:-space-y-26 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-6.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-6.5rem * var(--space-y-reverse)) !important;
  }

  .lt-md\:-space-x-26 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-6.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-6.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-md\:-space-y-28 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-7rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-7rem * var(--space-y-reverse)) !important;
  }

  .lt-md\:-space-x-28 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-7rem * var(--space-x-reverse)) !important;
    margin-left: calc(-7rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-md\:-space-y-30 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-7.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-7.5rem * var(--space-y-reverse)) !important;
  }

  .lt-md\:-space-x-30 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-7.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-7.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-md\:-space-y-32 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-8rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-8rem * var(--space-y-reverse)) !important;
  }

  .lt-md\:-space-x-32 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-8rem * var(--space-x-reverse)) !important;
    margin-left: calc(-8rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-md\:-space-y-36 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-9rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-9rem * var(--space-y-reverse)) !important;
  }

  .lt-md\:-space-x-36 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-9rem * var(--space-x-reverse)) !important;
    margin-left: calc(-9rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-md\:-space-y-40 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-10rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-10rem * var(--space-y-reverse)) !important;
  }

  .lt-md\:-space-x-40 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-10rem * var(--space-x-reverse)) !important;
    margin-left: calc(-10rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-md\:-space-y-48 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-12rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-12rem * var(--space-y-reverse)) !important;
  }

  .lt-md\:-space-x-48 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-12rem * var(--space-x-reverse)) !important;
    margin-left: calc(-12rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-md\:-space-y-56 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-14rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-14rem * var(--space-y-reverse)) !important;
  }

  .lt-md\:-space-x-56 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-14rem * var(--space-x-reverse)) !important;
    margin-left: calc(-14rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-md\:-space-y-64 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-16rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-16rem * var(--space-y-reverse)) !important;
  }

  .lt-md\:-space-x-64 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-16rem * var(--space-x-reverse)) !important;
    margin-left: calc(-16rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-md\:-space-y-px > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-1px * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-1px * var(--space-y-reverse)) !important;
  }

  .lt-md\:-space-x-px > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-1px * var(--space-x-reverse)) !important;
    margin-left: calc(-1px * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-md\:-space-y-2px > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-2px * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-2px * var(--space-y-reverse)) !important;
  }

  .lt-md\:-space-x-2px > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-2px * var(--space-x-reverse)) !important;
    margin-left: calc(-2px * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-md\:space-y-reverse > :not(template) ~ :not(template) {
    --space-y-reverse: 1 !important;
  }

  .lt-md\:space-x-reverse > :not(template) ~ :not(template) {
    --space-x-reverse: 1 !important;
  }

  .lt-md\:divide-y-0 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(0px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(0px * var(--divide-y-reverse)) !important;
  }

  .lt-md\:divide-x-0 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(0px * var(--divide-x-reverse)) !important;
    border-left-width: calc(0px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .lt-md\:divide-y-2 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(2px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(2px * var(--divide-y-reverse)) !important;
  }

  .lt-md\:divide-x-2 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(2px * var(--divide-x-reverse)) !important;
    border-left-width: calc(2px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .lt-md\:divide-y-4 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(4px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(4px * var(--divide-y-reverse)) !important;
  }

  .lt-md\:divide-x-4 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(4px * var(--divide-x-reverse)) !important;
    border-left-width: calc(4px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .lt-md\:divide-y-8 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(8px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(8px * var(--divide-y-reverse)) !important;
  }

  .lt-md\:divide-x-8 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(8px * var(--divide-x-reverse)) !important;
    border-left-width: calc(8px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .lt-md\:divide-y > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(1px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(1px * var(--divide-y-reverse)) !important;
  }

  .lt-md\:divide-x > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(1px * var(--divide-x-reverse)) !important;
    border-left-width: calc(1px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .lt-md\:divide-y-reverse > :not(template) ~ :not(template) {
    --divide-y-reverse: 1 !important;
  }

  .lt-md\:divide-x-reverse > :not(template) ~ :not(template) {
    --divide-x-reverse: 1 !important;
  }

  .lt-md\:divide-current > :not(template) ~ :not(template) {
    border-color: currentColor !important;
  }

  .lt-md\:divide-transparent > :not(template) ~ :not(template) {
    border-color: transparent !important;
  }

  .lt-md\:divide-white > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FFFFFF !important;
    border-color: rgba(255, 255, 255, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-black > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #000000 !important;
    border-color: rgba(0, 0, 0, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-gray-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F9FAFB !important;
    border-color: rgba(249, 250, 251, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-gray-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F4F5F7 !important;
    border-color: rgba(244, 245, 247, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-gray-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E5E7EB !important;
    border-color: rgba(229, 231, 235, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-gray-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #D2D6DC !important;
    border-color: rgba(210, 214, 220, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-gray-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9FA6B2 !important;
    border-color: rgba(159, 166, 178, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-gray-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #6B7280 !important;
    border-color: rgba(107, 114, 128, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-gray-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #4B5563 !important;
    border-color: rgba(75, 85, 99, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-gray-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #374151 !important;
    border-color: rgba(55, 65, 81, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-gray-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #252F3F !important;
    border-color: rgba(37, 47, 63, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-gray-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #161E2E !important;
    border-color: rgba(22, 30, 46, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-gray > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #6B7280 !important;
    border-color: rgba(107, 114, 128, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-cool-gray-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FBFDFE !important;
    border-color: rgba(251, 253, 254, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-cool-gray-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F1F5F9 !important;
    border-color: rgba(241, 245, 249, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-cool-gray-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E2E8F0 !important;
    border-color: rgba(226, 232, 240, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-cool-gray-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #CFD8E3 !important;
    border-color: rgba(207, 216, 227, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-cool-gray-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #97A6BA !important;
    border-color: rgba(151, 166, 186, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-cool-gray-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #64748B !important;
    border-color: rgba(100, 116, 139, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-cool-gray-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #475569 !important;
    border-color: rgba(71, 85, 105, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-cool-gray-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #364152 !important;
    border-color: rgba(54, 65, 82, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-cool-gray-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #27303F !important;
    border-color: rgba(39, 48, 63, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-cool-gray-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #1A202E !important;
    border-color: rgba(26, 32, 46, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-cool-gray > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #64748B !important;
    border-color: rgba(100, 116, 139, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-red-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FDF2F2 !important;
    border-color: rgba(253, 242, 242, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-red-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FDE8E8 !important;
    border-color: rgba(253, 232, 232, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-red-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FBD5D5 !important;
    border-color: rgba(251, 213, 213, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-red-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F8B4B4 !important;
    border-color: rgba(248, 180, 180, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-red-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F98080 !important;
    border-color: rgba(249, 128, 128, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-red-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F05252 !important;
    border-color: rgba(240, 82, 82, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-red-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E02424 !important;
    border-color: rgba(224, 36, 36, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-red-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #C81E1E !important;
    border-color: rgba(200, 30, 30, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-red-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9B1C1C !important;
    border-color: rgba(155, 28, 28, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-red-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #771D1D !important;
    border-color: rgba(119, 29, 29, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-red > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F05252 !important;
    border-color: rgba(240, 82, 82, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-orange-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FFF8F1 !important;
    border-color: rgba(255, 248, 241, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-orange-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FEECDC !important;
    border-color: rgba(254, 236, 220, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-orange-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FCD9BD !important;
    border-color: rgba(252, 217, 189, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-orange-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FDBA8C !important;
    border-color: rgba(253, 186, 140, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-orange-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FF8A4C !important;
    border-color: rgba(255, 138, 76, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-orange-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FF5A1F !important;
    border-color: rgba(255, 90, 31, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-orange-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #ff6700 !important;
    border-color: rgba(255, 103, 0, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-orange-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #B43403 !important;
    border-color: rgba(180, 52, 3, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-orange-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #8A2C0D !important;
    border-color: rgba(138, 44, 13, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-orange-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #771D1D !important;
    border-color: rgba(119, 29, 29, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-orange > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FF5A1F !important;
    border-color: rgba(255, 90, 31, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-yellow-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FDFDEA !important;
    border-color: rgba(253, 253, 234, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-yellow-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FDF6B2 !important;
    border-color: rgba(253, 246, 178, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-yellow-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FCE96A !important;
    border-color: rgba(252, 233, 106, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-yellow-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FACA15 !important;
    border-color: rgba(250, 202, 21, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-yellow-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E3A008 !important;
    border-color: rgba(227, 160, 8, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-yellow-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #C27803 !important;
    border-color: rgba(194, 120, 3, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-yellow-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9F580A !important;
    border-color: rgba(159, 88, 10, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-yellow-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #8E4B10 !important;
    border-color: rgba(142, 75, 16, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-yellow-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #723B13 !important;
    border-color: rgba(114, 59, 19, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-yellow-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #633112 !important;
    border-color: rgba(99, 49, 18, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-yellow > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #C27803 !important;
    border-color: rgba(194, 120, 3, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-green-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F3FAF7 !important;
    border-color: rgba(243, 250, 247, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-green-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #DEF7EC !important;
    border-color: rgba(222, 247, 236, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-green-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #BCF0DA !important;
    border-color: rgba(188, 240, 218, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-green-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #84E1BC !important;
    border-color: rgba(132, 225, 188, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-green-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #31C48D !important;
    border-color: rgba(49, 196, 141, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-green-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #0E9F6E !important;
    border-color: rgba(14, 159, 110, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-green-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #057A55 !important;
    border-color: rgba(5, 122, 85, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-green-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #046C4E !important;
    border-color: rgba(4, 108, 78, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-green-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #03543F !important;
    border-color: rgba(3, 84, 63, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-green-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #014737 !important;
    border-color: rgba(1, 71, 55, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-green > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #0E9F6E !important;
    border-color: rgba(14, 159, 110, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-teal-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #EDFAFA !important;
    border-color: rgba(237, 250, 250, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-teal-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #D5F5F6 !important;
    border-color: rgba(213, 245, 246, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-teal-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #AFECEF !important;
    border-color: rgba(175, 236, 239, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-teal-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #7EDCE2 !important;
    border-color: rgba(126, 220, 226, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-teal-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #16BDCA !important;
    border-color: rgba(22, 189, 202, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-teal-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #0694A2 !important;
    border-color: rgba(6, 148, 162, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-teal-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #047481 !important;
    border-color: rgba(4, 116, 129, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-teal-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #036672 !important;
    border-color: rgba(3, 102, 114, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-teal-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #05505C !important;
    border-color: rgba(5, 80, 92, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-teal-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #014451 !important;
    border-color: rgba(1, 68, 81, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-teal > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #0694A2 !important;
    border-color: rgba(6, 148, 162, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-blue-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #EBF5FF !important;
    border-color: rgba(235, 245, 255, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-blue-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E1EFFE !important;
    border-color: rgba(225, 239, 254, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-blue-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #C3DDFD !important;
    border-color: rgba(195, 221, 253, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-blue-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #A4CAFE !important;
    border-color: rgba(164, 202, 254, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-blue-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #76A9FA !important;
    border-color: rgba(118, 169, 250, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-blue-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #3F83F8 !important;
    border-color: rgba(63, 131, 248, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-blue-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #1C64F2 !important;
    border-color: rgba(28, 100, 242, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-blue-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #1A56DB !important;
    border-color: rgba(26, 86, 219, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-blue-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #1E429F !important;
    border-color: rgba(30, 66, 159, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-blue-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #233876 !important;
    border-color: rgba(35, 56, 118, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-blue > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #3F83F8 !important;
    border-color: rgba(63, 131, 248, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-indigo-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F0F5FF !important;
    border-color: rgba(240, 245, 255, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-indigo-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E5EDFF !important;
    border-color: rgba(229, 237, 255, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-indigo-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #CDDBFE !important;
    border-color: rgba(205, 219, 254, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-indigo-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #B4C6FC !important;
    border-color: rgba(180, 198, 252, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-indigo-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #8DA2FB !important;
    border-color: rgba(141, 162, 251, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-indigo-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #6875F5 !important;
    border-color: rgba(104, 117, 245, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-indigo-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #5850EC !important;
    border-color: rgba(88, 80, 236, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-indigo-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #5145CD !important;
    border-color: rgba(81, 69, 205, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-indigo-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #42389D !important;
    border-color: rgba(66, 56, 157, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-indigo-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #362F78 !important;
    border-color: rgba(54, 47, 120, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-indigo > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #6875F5 !important;
    border-color: rgba(104, 117, 245, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-purple-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F6F5FF !important;
    border-color: rgba(246, 245, 255, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-purple-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #EDEBFE !important;
    border-color: rgba(237, 235, 254, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-purple-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #DCD7FE !important;
    border-color: rgba(220, 215, 254, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-purple-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #CABFFD !important;
    border-color: rgba(202, 191, 253, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-purple-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #AC94FA !important;
    border-color: rgba(172, 148, 250, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-purple-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9061F9 !important;
    border-color: rgba(144, 97, 249, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-purple-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #7E3AF2 !important;
    border-color: rgba(126, 58, 242, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-purple-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #6C2BD9 !important;
    border-color: rgba(108, 43, 217, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-purple-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #5521B5 !important;
    border-color: rgba(85, 33, 181, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-purple-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #4A1D96 !important;
    border-color: rgba(74, 29, 150, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-purple > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9061F9 !important;
    border-color: rgba(144, 97, 249, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-pink-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FDF2F8 !important;
    border-color: rgba(253, 242, 248, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-pink-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FCE8F3 !important;
    border-color: rgba(252, 232, 243, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-pink-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FAD1E8 !important;
    border-color: rgba(250, 209, 232, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-pink-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F8B4D9 !important;
    border-color: rgba(248, 180, 217, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-pink-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F17EB8 !important;
    border-color: rgba(241, 126, 184, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-pink-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E74694 !important;
    border-color: rgba(231, 70, 148, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-pink-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #D61F69 !important;
    border-color: rgba(214, 31, 105, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-pink-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #BF125D !important;
    border-color: rgba(191, 18, 93, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-pink-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #99154B !important;
    border-color: rgba(153, 21, 75, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-pink-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #751A3D !important;
    border-color: rgba(117, 26, 61, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-pink > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E74694 !important;
    border-color: rgba(231, 70, 148, var(--divide-opacity)) !important;
  }

  .lt-md\:divide-opacity-0 > :not(template) ~ :not(template) {
    --divide-opacity: 0 !important;
  }

  .lt-md\:divide-opacity-12 > :not(template) ~ :not(template) {
    --divide-opacity: 0.12 !important;
  }

  .lt-md\:divide-opacity-25 > :not(template) ~ :not(template) {
    --divide-opacity: 0.25 !important;
  }

  .lt-md\:divide-opacity-38 > :not(template) ~ :not(template) {
    --divide-opacity: 0.38 !important;
  }

  .lt-md\:divide-opacity-50 > :not(template) ~ :not(template) {
    --divide-opacity: 0.5 !important;
  }

  .lt-md\:divide-opacity-54 > :not(template) ~ :not(template) {
    --divide-opacity: 0.54 !important;
  }

  .lt-md\:divide-opacity-70 > :not(template) ~ :not(template) {
    --divide-opacity: 0.70 !important;
  }

  .lt-md\:divide-opacity-75 > :not(template) ~ :not(template) {
    --divide-opacity: 0.75 !important;
  }

  .lt-md\:divide-opacity-84 > :not(template) ~ :not(template) {
    --divide-opacity: 0.84 !important;
  }

  .lt-md\:divide-opacity-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
  }

  .lt-md\:sr-only {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border-width: 0 !important;
  }

  .lt-md\:not-sr-only {
    position: static !important;
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: visible !important;
    clip: auto !important;
    white-space: normal !important;
  }

  .lt-md\:focus\:sr-only:focus {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border-width: 0 !important;
  }

  .lt-md\:focus\:not-sr-only:focus {
    position: static !important;
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: visible !important;
    clip: auto !important;
    white-space: normal !important;
  }

  .lt-md\:appearance-none {
    -webkit-appearance: none !important;
       -moz-appearance: none !important;
            appearance: none !important;
  }

  .lt-md\:bg-fixed {
    background-attachment: fixed !important;
  }

  .lt-md\:bg-local {
    background-attachment: local !important;
  }

  .lt-md\:bg-scroll {
    background-attachment: scroll !important;
  }

  .lt-md\:bg-opacity-0 {
    --bg-opacity: 0 !important;
  }

  .lt-md\:bg-opacity-12 {
    --bg-opacity: 0.12 !important;
  }

  .lt-md\:bg-opacity-25 {
    --bg-opacity: 0.25 !important;
  }

  .lt-md\:bg-opacity-38 {
    --bg-opacity: 0.38 !important;
  }

  .lt-md\:bg-opacity-50 {
    --bg-opacity: 0.5 !important;
  }

  .lt-md\:bg-opacity-54 {
    --bg-opacity: 0.54 !important;
  }

  .lt-md\:bg-opacity-70 {
    --bg-opacity: 0.70 !important;
  }

  .lt-md\:bg-opacity-75 {
    --bg-opacity: 0.75 !important;
  }

  .lt-md\:bg-opacity-84 {
    --bg-opacity: 0.84 !important;
  }

  .lt-md\:bg-opacity-100 {
    --bg-opacity: 1 !important;
  }

  .lt-md\:hover\:bg-opacity-0:hover {
    --bg-opacity: 0 !important;
  }

  .lt-md\:hover\:bg-opacity-12:hover {
    --bg-opacity: 0.12 !important;
  }

  .lt-md\:hover\:bg-opacity-25:hover {
    --bg-opacity: 0.25 !important;
  }

  .lt-md\:hover\:bg-opacity-38:hover {
    --bg-opacity: 0.38 !important;
  }

  .lt-md\:hover\:bg-opacity-50:hover {
    --bg-opacity: 0.5 !important;
  }

  .lt-md\:hover\:bg-opacity-54:hover {
    --bg-opacity: 0.54 !important;
  }

  .lt-md\:hover\:bg-opacity-70:hover {
    --bg-opacity: 0.70 !important;
  }

  .lt-md\:hover\:bg-opacity-75:hover {
    --bg-opacity: 0.75 !important;
  }

  .lt-md\:hover\:bg-opacity-84:hover {
    --bg-opacity: 0.84 !important;
  }

  .lt-md\:hover\:bg-opacity-100:hover {
    --bg-opacity: 1 !important;
  }

  .lt-md\:focus\:bg-opacity-0:focus {
    --bg-opacity: 0 !important;
  }

  .lt-md\:focus\:bg-opacity-12:focus {
    --bg-opacity: 0.12 !important;
  }

  .lt-md\:focus\:bg-opacity-25:focus {
    --bg-opacity: 0.25 !important;
  }

  .lt-md\:focus\:bg-opacity-38:focus {
    --bg-opacity: 0.38 !important;
  }

  .lt-md\:focus\:bg-opacity-50:focus {
    --bg-opacity: 0.5 !important;
  }

  .lt-md\:focus\:bg-opacity-54:focus {
    --bg-opacity: 0.54 !important;
  }

  .lt-md\:focus\:bg-opacity-70:focus {
    --bg-opacity: 0.70 !important;
  }

  .lt-md\:focus\:bg-opacity-75:focus {
    --bg-opacity: 0.75 !important;
  }

  .lt-md\:focus\:bg-opacity-84:focus {
    --bg-opacity: 0.84 !important;
  }

  .lt-md\:focus\:bg-opacity-100:focus {
    --bg-opacity: 1 !important;
  }

  .lt-md\:bg-bottom {
    background-position: bottom !important;
  }

  .lt-md\:bg-center {
    background-position: center !important;
  }

  .lt-md\:bg-left {
    background-position: left !important;
  }

  .lt-md\:bg-left-bottom {
    background-position: left bottom !important;
  }

  .lt-md\:bg-left-top {
    background-position: left top !important;
  }

  .lt-md\:bg-right {
    background-position: right !important;
  }

  .lt-md\:bg-right-bottom {
    background-position: right bottom !important;
  }

  .lt-md\:bg-right-top {
    background-position: right top !important;
  }

  .lt-md\:bg-top {
    background-position: top !important;
  }

  .lt-md\:bg-repeat {
    background-repeat: repeat !important;
  }

  .lt-md\:bg-no-repeat {
    background-repeat: no-repeat !important;
  }

  .lt-md\:bg-repeat-x {
    background-repeat: repeat-x !important;
  }

  .lt-md\:bg-repeat-y {
    background-repeat: repeat-y !important;
  }

  .lt-md\:bg-repeat-round {
    background-repeat: round !important;
  }

  .lt-md\:bg-repeat-space {
    background-repeat: space !important;
  }

  .lt-md\:bg-auto {
    background-size: auto !important;
  }

  .lt-md\:bg-cover {
    background-size: cover !important;
  }

  .lt-md\:bg-contain {
    background-size: contain !important;
  }

  .lt-md\:border-collapse {
    border-collapse: collapse !important;
  }

  .lt-md\:border-separate {
    border-collapse: separate !important;
  }

  .lt-md\:border-opacity-0 {
    --border-opacity: 0 !important;
  }

  .lt-md\:border-opacity-12 {
    --border-opacity: 0.12 !important;
  }

  .lt-md\:border-opacity-25 {
    --border-opacity: 0.25 !important;
  }

  .lt-md\:border-opacity-38 {
    --border-opacity: 0.38 !important;
  }

  .lt-md\:border-opacity-50 {
    --border-opacity: 0.5 !important;
  }

  .lt-md\:border-opacity-54 {
    --border-opacity: 0.54 !important;
  }

  .lt-md\:border-opacity-70 {
    --border-opacity: 0.70 !important;
  }

  .lt-md\:border-opacity-75 {
    --border-opacity: 0.75 !important;
  }

  .lt-md\:border-opacity-84 {
    --border-opacity: 0.84 !important;
  }

  .lt-md\:border-opacity-100 {
    --border-opacity: 1 !important;
  }

  .lt-md\:hover\:border-opacity-0:hover {
    --border-opacity: 0 !important;
  }

  .lt-md\:hover\:border-opacity-12:hover {
    --border-opacity: 0.12 !important;
  }

  .lt-md\:hover\:border-opacity-25:hover {
    --border-opacity: 0.25 !important;
  }

  .lt-md\:hover\:border-opacity-38:hover {
    --border-opacity: 0.38 !important;
  }

  .lt-md\:hover\:border-opacity-50:hover {
    --border-opacity: 0.5 !important;
  }

  .lt-md\:hover\:border-opacity-54:hover {
    --border-opacity: 0.54 !important;
  }

  .lt-md\:hover\:border-opacity-70:hover {
    --border-opacity: 0.70 !important;
  }

  .lt-md\:hover\:border-opacity-75:hover {
    --border-opacity: 0.75 !important;
  }

  .lt-md\:hover\:border-opacity-84:hover {
    --border-opacity: 0.84 !important;
  }

  .lt-md\:hover\:border-opacity-100:hover {
    --border-opacity: 1 !important;
  }

  .lt-md\:focus\:border-opacity-0:focus {
    --border-opacity: 0 !important;
  }

  .lt-md\:focus\:border-opacity-12:focus {
    --border-opacity: 0.12 !important;
  }

  .lt-md\:focus\:border-opacity-25:focus {
    --border-opacity: 0.25 !important;
  }

  .lt-md\:focus\:border-opacity-38:focus {
    --border-opacity: 0.38 !important;
  }

  .lt-md\:focus\:border-opacity-50:focus {
    --border-opacity: 0.5 !important;
  }

  .lt-md\:focus\:border-opacity-54:focus {
    --border-opacity: 0.54 !important;
  }

  .lt-md\:focus\:border-opacity-70:focus {
    --border-opacity: 0.70 !important;
  }

  .lt-md\:focus\:border-opacity-75:focus {
    --border-opacity: 0.75 !important;
  }

  .lt-md\:focus\:border-opacity-84:focus {
    --border-opacity: 0.84 !important;
  }

  .lt-md\:focus\:border-opacity-100:focus {
    --border-opacity: 1 !important;
  }

  .lt-md\:rounded-none {
    border-radius: 0 !important;
  }

  .lt-md\:rounded-sm {
    border-radius: 0.125rem !important;
  }

  .lt-md\:rounded {
    border-radius: 0.25rem !important;
  }

  .lt-md\:rounded-md {
    border-radius: 0.375rem !important;
  }

  .lt-md\:rounded-lg {
    border-radius: 0.5rem !important;
  }

  .lt-md\:rounded-full {
    border-radius: 9999px !important;
  }

  .lt-md\:rounded-t-none {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .lt-md\:rounded-r-none {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .lt-md\:rounded-b-none {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .lt-md\:rounded-l-none {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .lt-md\:rounded-t-sm {
    border-top-left-radius: 0.125rem !important;
    border-top-right-radius: 0.125rem !important;
  }

  .lt-md\:rounded-r-sm {
    border-top-right-radius: 0.125rem !important;
    border-bottom-right-radius: 0.125rem !important;
  }

  .lt-md\:rounded-b-sm {
    border-bottom-right-radius: 0.125rem !important;
    border-bottom-left-radius: 0.125rem !important;
  }

  .lt-md\:rounded-l-sm {
    border-top-left-radius: 0.125rem !important;
    border-bottom-left-radius: 0.125rem !important;
  }

  .lt-md\:rounded-t {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }

  .lt-md\:rounded-r {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }

  .lt-md\:rounded-b {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }

  .lt-md\:rounded-l {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }

  .lt-md\:rounded-t-md {
    border-top-left-radius: 0.375rem !important;
    border-top-right-radius: 0.375rem !important;
  }

  .lt-md\:rounded-r-md {
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }

  .lt-md\:rounded-b-md {
    border-bottom-right-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }

  .lt-md\:rounded-l-md {
    border-top-left-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }

  .lt-md\:rounded-t-lg {
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
  }

  .lt-md\:rounded-r-lg {
    border-top-right-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }

  .lt-md\:rounded-b-lg {
    border-bottom-right-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }

  .lt-md\:rounded-l-lg {
    border-top-left-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }

  .lt-md\:rounded-t-full {
    border-top-left-radius: 9999px !important;
    border-top-right-radius: 9999px !important;
  }

  .lt-md\:rounded-r-full {
    border-top-right-radius: 9999px !important;
    border-bottom-right-radius: 9999px !important;
  }

  .lt-md\:rounded-b-full {
    border-bottom-right-radius: 9999px !important;
    border-bottom-left-radius: 9999px !important;
  }

  .lt-md\:rounded-l-full {
    border-top-left-radius: 9999px !important;
    border-bottom-left-radius: 9999px !important;
  }

  .lt-md\:rounded-tl-none {
    border-top-left-radius: 0 !important;
  }

  .lt-md\:rounded-tr-none {
    border-top-right-radius: 0 !important;
  }

  .lt-md\:rounded-br-none {
    border-bottom-right-radius: 0 !important;
  }

  .lt-md\:rounded-bl-none {
    border-bottom-left-radius: 0 !important;
  }

  .lt-md\:rounded-tl-sm {
    border-top-left-radius: 0.125rem !important;
  }

  .lt-md\:rounded-tr-sm {
    border-top-right-radius: 0.125rem !important;
  }

  .lt-md\:rounded-br-sm {
    border-bottom-right-radius: 0.125rem !important;
  }

  .lt-md\:rounded-bl-sm {
    border-bottom-left-radius: 0.125rem !important;
  }

  .lt-md\:rounded-tl {
    border-top-left-radius: 0.25rem !important;
  }

  .lt-md\:rounded-tr {
    border-top-right-radius: 0.25rem !important;
  }

  .lt-md\:rounded-br {
    border-bottom-right-radius: 0.25rem !important;
  }

  .lt-md\:rounded-bl {
    border-bottom-left-radius: 0.25rem !important;
  }

  .lt-md\:rounded-tl-md {
    border-top-left-radius: 0.375rem !important;
  }

  .lt-md\:rounded-tr-md {
    border-top-right-radius: 0.375rem !important;
  }

  .lt-md\:rounded-br-md {
    border-bottom-right-radius: 0.375rem !important;
  }

  .lt-md\:rounded-bl-md {
    border-bottom-left-radius: 0.375rem !important;
  }

  .lt-md\:rounded-tl-lg {
    border-top-left-radius: 0.5rem !important;
  }

  .lt-md\:rounded-tr-lg {
    border-top-right-radius: 0.5rem !important;
  }

  .lt-md\:rounded-br-lg {
    border-bottom-right-radius: 0.5rem !important;
  }

  .lt-md\:rounded-bl-lg {
    border-bottom-left-radius: 0.5rem !important;
  }

  .lt-md\:rounded-tl-full {
    border-top-left-radius: 9999px !important;
  }

  .lt-md\:rounded-tr-full {
    border-top-right-radius: 9999px !important;
  }

  .lt-md\:rounded-br-full {
    border-bottom-right-radius: 9999px !important;
  }

  .lt-md\:rounded-bl-full {
    border-bottom-left-radius: 9999px !important;
  }

  .lt-md\:border-solid {
    border-style: solid !important;
  }

  .lt-md\:border-dashed {
    border-style: dashed !important;
  }

  .lt-md\:border-dotted {
    border-style: dotted !important;
  }

  .lt-md\:border-double {
    border-style: double !important;
  }

  .lt-md\:border-none {
    border-style: none !important;
  }

  .lt-md\:border-0 {
    border-width: 0 !important;
  }

  .lt-md\:border-2 {
    border-width: 2px !important;
  }

  .lt-md\:border-4 {
    border-width: 4px !important;
  }

  .lt-md\:border-8 {
    border-width: 8px !important;
  }

  .lt-md\:border {
    border-width: 1px !important;
  }

  .lt-md\:border-t-0 {
    border-top-width: 0 !important;
  }

  .lt-md\:border-r-0 {
    border-right-width: 0 !important;
  }

  .lt-md\:border-b-0 {
    border-bottom-width: 0 !important;
  }

  .lt-md\:border-l-0 {
    border-left-width: 0 !important;
  }

  .lt-md\:border-t-2 {
    border-top-width: 2px !important;
  }

  .lt-md\:border-r-2 {
    border-right-width: 2px !important;
  }

  .lt-md\:border-b-2 {
    border-bottom-width: 2px !important;
  }

  .lt-md\:border-l-2 {
    border-left-width: 2px !important;
  }

  .lt-md\:border-t-4 {
    border-top-width: 4px !important;
  }

  .lt-md\:border-r-4 {
    border-right-width: 4px !important;
  }

  .lt-md\:border-b-4 {
    border-bottom-width: 4px !important;
  }

  .lt-md\:border-l-4 {
    border-left-width: 4px !important;
  }

  .lt-md\:border-t-8 {
    border-top-width: 8px !important;
  }

  .lt-md\:border-r-8 {
    border-right-width: 8px !important;
  }

  .lt-md\:border-b-8 {
    border-bottom-width: 8px !important;
  }

  .lt-md\:border-l-8 {
    border-left-width: 8px !important;
  }

  .lt-md\:border-t {
    border-top-width: 1px !important;
  }

  .lt-md\:border-r {
    border-right-width: 1px !important;
  }

  .lt-md\:border-b {
    border-bottom-width: 1px !important;
  }

  .lt-md\:border-l {
    border-left-width: 1px !important;
  }

  .lt-md\:first\:border-0:first-child {
    border-width: 0 !important;
  }

  .lt-md\:first\:border-2:first-child {
    border-width: 2px !important;
  }

  .lt-md\:first\:border-4:first-child {
    border-width: 4px !important;
  }

  .lt-md\:first\:border-8:first-child {
    border-width: 8px !important;
  }

  .lt-md\:first\:border:first-child {
    border-width: 1px !important;
  }

  .lt-md\:first\:border-t-0:first-child {
    border-top-width: 0 !important;
  }

  .lt-md\:first\:border-r-0:first-child {
    border-right-width: 0 !important;
  }

  .lt-md\:first\:border-b-0:first-child {
    border-bottom-width: 0 !important;
  }

  .lt-md\:first\:border-l-0:first-child {
    border-left-width: 0 !important;
  }

  .lt-md\:first\:border-t-2:first-child {
    border-top-width: 2px !important;
  }

  .lt-md\:first\:border-r-2:first-child {
    border-right-width: 2px !important;
  }

  .lt-md\:first\:border-b-2:first-child {
    border-bottom-width: 2px !important;
  }

  .lt-md\:first\:border-l-2:first-child {
    border-left-width: 2px !important;
  }

  .lt-md\:first\:border-t-4:first-child {
    border-top-width: 4px !important;
  }

  .lt-md\:first\:border-r-4:first-child {
    border-right-width: 4px !important;
  }

  .lt-md\:first\:border-b-4:first-child {
    border-bottom-width: 4px !important;
  }

  .lt-md\:first\:border-l-4:first-child {
    border-left-width: 4px !important;
  }

  .lt-md\:first\:border-t-8:first-child {
    border-top-width: 8px !important;
  }

  .lt-md\:first\:border-r-8:first-child {
    border-right-width: 8px !important;
  }

  .lt-md\:first\:border-b-8:first-child {
    border-bottom-width: 8px !important;
  }

  .lt-md\:first\:border-l-8:first-child {
    border-left-width: 8px !important;
  }

  .lt-md\:first\:border-t:first-child {
    border-top-width: 1px !important;
  }

  .lt-md\:first\:border-r:first-child {
    border-right-width: 1px !important;
  }

  .lt-md\:first\:border-b:first-child {
    border-bottom-width: 1px !important;
  }

  .lt-md\:first\:border-l:first-child {
    border-left-width: 1px !important;
  }

  .lt-md\:last\:border-0:last-child {
    border-width: 0 !important;
  }

  .lt-md\:last\:border-2:last-child {
    border-width: 2px !important;
  }

  .lt-md\:last\:border-4:last-child {
    border-width: 4px !important;
  }

  .lt-md\:last\:border-8:last-child {
    border-width: 8px !important;
  }

  .lt-md\:last\:border:last-child {
    border-width: 1px !important;
  }

  .lt-md\:last\:border-t-0:last-child {
    border-top-width: 0 !important;
  }

  .lt-md\:last\:border-r-0:last-child {
    border-right-width: 0 !important;
  }

  .lt-md\:last\:border-b-0:last-child {
    border-bottom-width: 0 !important;
  }

  .lt-md\:last\:border-l-0:last-child {
    border-left-width: 0 !important;
  }

  .lt-md\:last\:border-t-2:last-child {
    border-top-width: 2px !important;
  }

  .lt-md\:last\:border-r-2:last-child {
    border-right-width: 2px !important;
  }

  .lt-md\:last\:border-b-2:last-child {
    border-bottom-width: 2px !important;
  }

  .lt-md\:last\:border-l-2:last-child {
    border-left-width: 2px !important;
  }

  .lt-md\:last\:border-t-4:last-child {
    border-top-width: 4px !important;
  }

  .lt-md\:last\:border-r-4:last-child {
    border-right-width: 4px !important;
  }

  .lt-md\:last\:border-b-4:last-child {
    border-bottom-width: 4px !important;
  }

  .lt-md\:last\:border-l-4:last-child {
    border-left-width: 4px !important;
  }

  .lt-md\:last\:border-t-8:last-child {
    border-top-width: 8px !important;
  }

  .lt-md\:last\:border-r-8:last-child {
    border-right-width: 8px !important;
  }

  .lt-md\:last\:border-b-8:last-child {
    border-bottom-width: 8px !important;
  }

  .lt-md\:last\:border-l-8:last-child {
    border-left-width: 8px !important;
  }

  .lt-md\:last\:border-t:last-child {
    border-top-width: 1px !important;
  }

  .lt-md\:last\:border-r:last-child {
    border-right-width: 1px !important;
  }

  .lt-md\:last\:border-b:last-child {
    border-bottom-width: 1px !important;
  }

  .lt-md\:last\:border-l:last-child {
    border-left-width: 1px !important;
  }

  .lt-md\:box-border {
    box-sizing: border-box !important;
  }

  .lt-md\:box-content {
    box-sizing: content-box !important;
  }

  .lt-md\:block {
    display: block !important;
  }

  .lt-md\:inline-block {
    display: inline-block !important;
  }

  .lt-md\:inline {
    display: inline !important;
  }

  .lt-md\:flex {
    display: flex !important;
  }

  .lt-md\:inline-flex {
    display: inline-flex !important;
  }

  .lt-md\:table {
    display: table !important;
  }

  .lt-md\:table-caption {
    display: table-caption !important;
  }

  .lt-md\:table-cell {
    display: table-cell !important;
  }

  .lt-md\:table-column {
    display: table-column !important;
  }

  .lt-md\:table-column-group {
    display: table-column-group !important;
  }

  .lt-md\:table-footer-group {
    display: table-footer-group !important;
  }

  .lt-md\:table-header-group {
    display: table-header-group !important;
  }

  .lt-md\:table-row-group {
    display: table-row-group !important;
  }

  .lt-md\:table-row {
    display: table-row !important;
  }

  .lt-md\:flow-root {
    display: flow-root !important;
  }

  .lt-md\:grid {
    display: grid !important;
  }

  .lt-md\:inline-grid {
    display: inline-grid !important;
  }

  .lt-md\:hidden {
    display: none !important;
  }

  .lt-md\:flex-row {
    flex-direction: row !important;
  }

  .lt-md\:flex-row-reverse {
    flex-direction: row-reverse !important;
  }

  .lt-md\:flex-col {
    flex-direction: column !important;
  }

  .lt-md\:flex-col-reverse {
    flex-direction: column-reverse !important;
  }

  .lt-md\:flex-wrap {
    flex-wrap: wrap !important;
  }

  .lt-md\:flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .lt-md\:flex-nowrap {
    flex-wrap: nowrap !important;
  }

  .lt-md\:items-start {
    align-items: flex-start !important;
  }

  .lt-md\:items-end {
    align-items: flex-end !important;
  }

  .lt-md\:items-center {
    align-items: center !important;
  }

  .lt-md\:items-baseline {
    align-items: baseline !important;
  }

  .lt-md\:items-stretch {
    align-items: stretch !important;
  }

  .lt-md\:self-auto {
    align-self: auto !important;
  }

  .lt-md\:self-start {
    align-self: flex-start !important;
  }

  .lt-md\:self-end {
    align-self: flex-end !important;
  }

  .lt-md\:self-center {
    align-self: center !important;
  }

  .lt-md\:self-stretch {
    align-self: stretch !important;
  }

  .lt-md\:justify-start {
    justify-content: flex-start !important;
  }

  .lt-md\:justify-end {
    justify-content: flex-end !important;
  }

  .lt-md\:justify-center {
    justify-content: center !important;
  }

  .lt-md\:justify-between {
    justify-content: space-between !important;
  }

  .lt-md\:justify-around {
    justify-content: space-around !important;
  }

  .lt-md\:justify-evenly {
    justify-content: space-evenly !important;
  }

  .lt-md\:content-center {
    align-content: center !important;
  }

  .lt-md\:content-start {
    align-content: flex-start !important;
  }

  .lt-md\:content-end {
    align-content: flex-end !important;
  }

  .lt-md\:content-between {
    align-content: space-between !important;
  }

  .lt-md\:content-around {
    align-content: space-around !important;
  }

  .lt-md\:flex-0 {
    flex: 0 0 auto !important;
  }

  .lt-md\:flex-1 {
    flex: 1 1 0% !important;
  }

  .lt-md\:flex-auto {
    flex: 1 1 auto !important;
  }

  .lt-md\:flex-initial {
    flex: 0 1 auto !important;
  }

  .lt-md\:flex-none {
    flex: none !important;
  }

  .lt-md\:flex-grow-0 {
    flex-grow: 0 !important;
  }

  .lt-md\:flex-grow {
    flex-grow: 1 !important;
  }

  .lt-md\:flex-shrink-0 {
    flex-shrink: 0 !important;
  }

  .lt-md\:flex-shrink {
    flex-shrink: 1 !important;
  }

  .lt-md\:order-1 {
    order: 1 !important;
  }

  .lt-md\:order-2 {
    order: 2 !important;
  }

  .lt-md\:order-3 {
    order: 3 !important;
  }

  .lt-md\:order-4 {
    order: 4 !important;
  }

  .lt-md\:order-5 {
    order: 5 !important;
  }

  .lt-md\:order-6 {
    order: 6 !important;
  }

  .lt-md\:order-7 {
    order: 7 !important;
  }

  .lt-md\:order-8 {
    order: 8 !important;
  }

  .lt-md\:order-9 {
    order: 9 !important;
  }

  .lt-md\:order-10 {
    order: 10 !important;
  }

  .lt-md\:order-11 {
    order: 11 !important;
  }

  .lt-md\:order-12 {
    order: 12 !important;
  }

  .lt-md\:order-first {
    order: -9999 !important;
  }

  .lt-md\:order-last {
    order: 9999 !important;
  }

  .lt-md\:order-none {
    order: 0 !important;
  }

  .lt-md\:font-thin {
    font-weight: 100 !important;
  }

  .lt-md\:font-extralight {
    font-weight: 200 !important;
  }

  .lt-md\:font-light {
    font-weight: 300 !important;
  }

  .lt-md\:font-normal {
    font-weight: 400 !important;
  }

  .lt-md\:font-medium {
    font-weight: 500 !important;
  }

  .lt-md\:font-semibold {
    font-weight: 600 !important;
  }

  .lt-md\:font-bold {
    font-weight: 700 !important;
  }

  .lt-md\:font-extrabold {
    font-weight: 800 !important;
  }

  .lt-md\:font-black {
    font-weight: 900 !important;
  }

  .lt-md\:h-0 {
    height: 0 !important;
  }

  .lt-md\:h-1 {
    height: 0.25rem !important;
  }

  .lt-md\:h-2 {
    height: 0.5rem !important;
  }

  .lt-md\:h-3 {
    height: 0.75rem !important;
  }

  .lt-md\:h-4 {
    height: 1rem !important;
  }

  .lt-md\:h-5 {
    height: 1.25rem !important;
  }

  .lt-md\:h-6 {
    height: 1.5rem !important;
  }

  .lt-md\:h-8 {
    height: 2rem !important;
  }

  .lt-md\:h-10 {
    height: 2.5rem !important;
  }

  .lt-md\:h-12 {
    height: 3rem !important;
  }

  .lt-md\:h-14 {
    height: 3.5rem !important;
  }

  .lt-md\:h-16 {
    height: 4rem !important;
  }

  .lt-md\:h-18 {
    height: 4.5rem !important;
  }

  .lt-md\:h-20 {
    height: 5rem !important;
  }

  .lt-md\:h-22 {
    height: 5.5rem !important;
  }

  .lt-md\:h-24 {
    height: 6rem !important;
  }

  .lt-md\:h-26 {
    height: 6.5rem !important;
  }

  .lt-md\:h-28 {
    height: 7rem !important;
  }

  .lt-md\:h-30 {
    height: 7.5rem !important;
  }

  .lt-md\:h-32 {
    height: 8rem !important;
  }

  .lt-md\:h-36 {
    height: 9rem !important;
  }

  .lt-md\:h-40 {
    height: 10rem !important;
  }

  .lt-md\:h-48 {
    height: 12rem !important;
  }

  .lt-md\:h-50 {
    height: 12.5rem !important;
  }

  .lt-md\:h-56 {
    height: 14rem !important;
  }

  .lt-md\:h-60 {
    height: 15rem !important;
  }

  .lt-md\:h-64 {
    height: 16rem !important;
  }

  .lt-md\:h-80 {
    height: 20rem !important;
  }

  .lt-md\:h-90 {
    height: 24rem !important;
  }

  .lt-md\:h-100 {
    height: 25rem !important;
  }

  .lt-md\:h-120 {
    height: 30rem !important;
  }

  .lt-md\:h-128 {
    height: 32rem !important;
  }

  .lt-md\:h-140 {
    height: 35rem !important;
  }

  .lt-md\:h-160 {
    height: 40rem !important;
  }

  .lt-md\:h-180 {
    height: 45rem !important;
  }

  .lt-md\:h-192 {
    height: 48rem !important;
  }

  .lt-md\:h-200 {
    height: 50rem !important;
  }

  .lt-md\:h-240 {
    height: 60rem !important;
  }

  .lt-md\:h-256 {
    height: 64rem !important;
  }

  .lt-md\:h-280 {
    height: 70rem !important;
  }

  .lt-md\:h-320 {
    height: 80rem !important;
  }

  .lt-md\:h-360 {
    height: 90rem !important;
  }

  .lt-md\:h-400 {
    height: 100rem !important;
  }

  .lt-md\:h-480 {
    height: 120rem !important;
  }

  .lt-md\:h-auto {
    height: auto !important;
  }

  .lt-md\:h-px {
    height: 1px !important;
  }

  .lt-md\:h-2px {
    height: 2px !important;
  }

  .lt-md\:h-full {
    height: 100% !important;
  }

  .lt-md\:h-screen {
    height: 100vh !important;
  }

  .lt-md\:h-1\/2 {
    height: 50% !important;
  }

  .lt-md\:h-1\/3 {
    height: 33.33333% !important;
  }

  .lt-md\:h-2\/3 {
    height: 66.66667% !important;
  }

  .lt-md\:h-1\/4 {
    height: 25% !important;
  }

  .lt-md\:h-2\/4 {
    height: 50% !important;
  }

  .lt-md\:h-3\/4 {
    height: 75% !important;
  }

  .lt-md\:h-1\/5 {
    height: 20% !important;
  }

  .lt-md\:h-2\/5 {
    height: 40% !important;
  }

  .lt-md\:h-3\/5 {
    height: 60% !important;
  }

  .lt-md\:h-4\/5 {
    height: 80% !important;
  }

  .lt-md\:h-1\/12 {
    height: 8.33333% !important;
  }

  .lt-md\:h-2\/12 {
    height: 16.66667% !important;
  }

  .lt-md\:h-3\/12 {
    height: 25% !important;
  }

  .lt-md\:h-4\/12 {
    height: 33.33333% !important;
  }

  .lt-md\:h-5\/12 {
    height: 41.66667% !important;
  }

  .lt-md\:h-6\/12 {
    height: 50% !important;
  }

  .lt-md\:h-7\/12 {
    height: 58.33333% !important;
  }

  .lt-md\:h-8\/12 {
    height: 66.66667% !important;
  }

  .lt-md\:h-9\/12 {
    height: 75% !important;
  }

  .lt-md\:h-10\/12 {
    height: 83.33333% !important;
  }

  .lt-md\:h-11\/12 {
    height: 91.66667% !important;
  }

  .lt-md\:text-xs {
    font-size: 0.625rem !important;
  }

  .lt-md\:text-sm {
    font-size: 0.75rem !important;
  }

  .lt-md\:text-md {
    font-size: 0.8125rem !important;
  }

  .lt-md\:text-base {
    font-size: 0.875rem !important;
  }

  .lt-md\:text-lg {
    font-size: 1rem !important;
  }

  .lt-md\:text-xl {
    font-size: 1.125rem !important;
  }

  .lt-md\:text-2xl {
    font-size: 1.25rem !important;
  }

  .lt-md\:text-3xl {
    font-size: 1.5rem !important;
  }

  .lt-md\:text-4xl {
    font-size: 2rem !important;
  }

  .lt-md\:text-5xl {
    font-size: 2.25rem !important;
  }

  .lt-md\:text-6xl {
    font-size: 2.5rem !important;
  }

  .lt-md\:text-7xl {
    font-size: 3rem !important;
  }

  .lt-md\:text-8xl {
    font-size: 4rem !important;
  }

  .lt-md\:text-9xl {
    font-size: 6rem !important;
  }

  .lt-md\:text-10xl {
    font-size: 8rem !important;
  }

  .lt-md\:leading-3 {
    line-height: .75rem !important;
  }

  .lt-md\:leading-4 {
    line-height: 1rem !important;
  }

  .lt-md\:leading-5 {
    line-height: 1.25rem !important;
  }

  .lt-md\:leading-6 {
    line-height: 1.5rem !important;
  }

  .lt-md\:leading-7 {
    line-height: 1.75rem !important;
  }

  .lt-md\:leading-8 {
    line-height: 2rem !important;
  }

  .lt-md\:leading-9 {
    line-height: 2.25rem !important;
  }

  .lt-md\:leading-10 {
    line-height: 2.5rem !important;
  }

  .lt-md\:leading-none {
    line-height: 1 !important;
  }

  .lt-md\:leading-tight {
    line-height: 1.25 !important;
  }

  .lt-md\:leading-snug {
    line-height: 1.375 !important;
  }

  .lt-md\:leading-normal {
    line-height: 1.5 !important;
  }

  .lt-md\:leading-relaxed {
    line-height: 1.625 !important;
  }

  .lt-md\:leading-loose {
    line-height: 2 !important;
  }

  .lt-md\:list-inside {
    list-style-position: inside !important;
  }

  .lt-md\:list-outside {
    list-style-position: outside !important;
  }

  .lt-md\:list-none {
    list-style-type: none !important;
  }

  .lt-md\:list-disc {
    list-style-type: disc !important;
  }

  .lt-md\:list-decimal {
    list-style-type: decimal !important;
  }

  .lt-md\:m-0 {
    margin: 0 !important;
  }

  .lt-md\:m-1 {
    margin: 0.25rem !important;
  }

  .lt-md\:m-2 {
    margin: 0.5rem !important;
  }

  .lt-md\:m-3 {
    margin: 0.75rem !important;
  }

  .lt-md\:m-4 {
    margin: 1rem !important;
  }

  .lt-md\:m-5 {
    margin: 1.25rem !important;
  }

  .lt-md\:m-6 {
    margin: 1.5rem !important;
  }

  .lt-md\:m-8 {
    margin: 2rem !important;
  }

  .lt-md\:m-10 {
    margin: 2.5rem !important;
  }

  .lt-md\:m-12 {
    margin: 3rem !important;
  }

  .lt-md\:m-14 {
    margin: 3.5rem !important;
  }

  .lt-md\:m-16 {
    margin: 4rem !important;
  }

  .lt-md\:m-18 {
    margin: 4.5rem !important;
  }

  .lt-md\:m-20 {
    margin: 5rem !important;
  }

  .lt-md\:m-22 {
    margin: 5.5rem !important;
  }

  .lt-md\:m-24 {
    margin: 6rem !important;
  }

  .lt-md\:m-26 {
    margin: 6.5rem !important;
  }

  .lt-md\:m-28 {
    margin: 7rem !important;
  }

  .lt-md\:m-30 {
    margin: 7.5rem !important;
  }

  .lt-md\:m-32 {
    margin: 8rem !important;
  }

  .lt-md\:m-36 {
    margin: 9rem !important;
  }

  .lt-md\:m-40 {
    margin: 10rem !important;
  }

  .lt-md\:m-48 {
    margin: 12rem !important;
  }

  .lt-md\:m-56 {
    margin: 14rem !important;
  }

  .lt-md\:m-64 {
    margin: 16rem !important;
  }

  .lt-md\:m-auto {
    margin: auto !important;
  }

  .lt-md\:m-px {
    margin: 1px !important;
  }

  .lt-md\:m-2px {
    margin: 2px !important;
  }

  .lt-md\:-m-1 {
    margin: -0.25rem !important;
  }

  .lt-md\:-m-2 {
    margin: -0.5rem !important;
  }

  .lt-md\:-m-3 {
    margin: -0.75rem !important;
  }

  .lt-md\:-m-4 {
    margin: -1rem !important;
  }

  .lt-md\:-m-5 {
    margin: -1.25rem !important;
  }

  .lt-md\:-m-6 {
    margin: -1.5rem !important;
  }

  .lt-md\:-m-8 {
    margin: -2rem !important;
  }

  .lt-md\:-m-10 {
    margin: -2.5rem !important;
  }

  .lt-md\:-m-12 {
    margin: -3rem !important;
  }

  .lt-md\:-m-14 {
    margin: -3.5rem !important;
  }

  .lt-md\:-m-16 {
    margin: -4rem !important;
  }

  .lt-md\:-m-18 {
    margin: -4.5rem !important;
  }

  .lt-md\:-m-20 {
    margin: -5rem !important;
  }

  .lt-md\:-m-22 {
    margin: -5.5rem !important;
  }

  .lt-md\:-m-24 {
    margin: -6rem !important;
  }

  .lt-md\:-m-26 {
    margin: -6.5rem !important;
  }

  .lt-md\:-m-28 {
    margin: -7rem !important;
  }

  .lt-md\:-m-30 {
    margin: -7.5rem !important;
  }

  .lt-md\:-m-32 {
    margin: -8rem !important;
  }

  .lt-md\:-m-36 {
    margin: -9rem !important;
  }

  .lt-md\:-m-40 {
    margin: -10rem !important;
  }

  .lt-md\:-m-48 {
    margin: -12rem !important;
  }

  .lt-md\:-m-56 {
    margin: -14rem !important;
  }

  .lt-md\:-m-64 {
    margin: -16rem !important;
  }

  .lt-md\:-m-px {
    margin: -1px !important;
  }

  .lt-md\:-m-2px {
    margin: -2px !important;
  }

  .lt-md\:my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .lt-md\:mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .lt-md\:my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .lt-md\:mx-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }

  .lt-md\:my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .lt-md\:mx-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .lt-md\:my-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .lt-md\:mx-3 {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
  }

  .lt-md\:my-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .lt-md\:mx-4 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .lt-md\:my-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .lt-md\:mx-5 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }

  .lt-md\:my-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .lt-md\:mx-6 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .lt-md\:my-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .lt-md\:mx-8 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .lt-md\:my-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .lt-md\:mx-10 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .lt-md\:my-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .lt-md\:mx-12 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .lt-md\:my-14 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .lt-md\:mx-14 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }

  .lt-md\:my-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .lt-md\:mx-16 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .lt-md\:my-18 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .lt-md\:mx-18 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }

  .lt-md\:my-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .lt-md\:mx-20 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .lt-md\:my-22 {
    margin-top: 5.5rem !important;
    margin-bottom: 5.5rem !important;
  }

  .lt-md\:mx-22 {
    margin-left: 5.5rem !important;
    margin-right: 5.5rem !important;
  }

  .lt-md\:my-24 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .lt-md\:mx-24 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }

  .lt-md\:my-26 {
    margin-top: 6.5rem !important;
    margin-bottom: 6.5rem !important;
  }

  .lt-md\:mx-26 {
    margin-left: 6.5rem !important;
    margin-right: 6.5rem !important;
  }

  .lt-md\:my-28 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }

  .lt-md\:mx-28 {
    margin-left: 7rem !important;
    margin-right: 7rem !important;
  }

  .lt-md\:my-30 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }

  .lt-md\:mx-30 {
    margin-left: 7.5rem !important;
    margin-right: 7.5rem !important;
  }

  .lt-md\:my-32 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .lt-md\:mx-32 {
    margin-left: 8rem !important;
    margin-right: 8rem !important;
  }

  .lt-md\:my-36 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }

  .lt-md\:mx-36 {
    margin-left: 9rem !important;
    margin-right: 9rem !important;
  }

  .lt-md\:my-40 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .lt-md\:mx-40 {
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }

  .lt-md\:my-48 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }

  .lt-md\:mx-48 {
    margin-left: 12rem !important;
    margin-right: 12rem !important;
  }

  .lt-md\:my-56 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }

  .lt-md\:mx-56 {
    margin-left: 14rem !important;
    margin-right: 14rem !important;
  }

  .lt-md\:my-64 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }

  .lt-md\:mx-64 {
    margin-left: 16rem !important;
    margin-right: 16rem !important;
  }

  .lt-md\:my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .lt-md\:mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .lt-md\:my-px {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  .lt-md\:mx-px {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }

  .lt-md\:my-2px {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }

  .lt-md\:mx-2px {
    margin-left: 2px !important;
    margin-right: 2px !important;
  }

  .lt-md\:-my-1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .lt-md\:-mx-1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }

  .lt-md\:-my-2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .lt-md\:-mx-2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }

  .lt-md\:-my-3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }

  .lt-md\:-mx-3 {
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important;
  }

  .lt-md\:-my-4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .lt-md\:-mx-4 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .lt-md\:-my-5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .lt-md\:-mx-5 {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }

  .lt-md\:-my-6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .lt-md\:-mx-6 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .lt-md\:-my-8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .lt-md\:-mx-8 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .lt-md\:-my-10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .lt-md\:-mx-10 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }

  .lt-md\:-my-12 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .lt-md\:-mx-12 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .lt-md\:-my-14 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .lt-md\:-mx-14 {
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }

  .lt-md\:-my-16 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .lt-md\:-mx-16 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .lt-md\:-my-18 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .lt-md\:-mx-18 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }

  .lt-md\:-my-20 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .lt-md\:-mx-20 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .lt-md\:-my-22 {
    margin-top: -5.5rem !important;
    margin-bottom: -5.5rem !important;
  }

  .lt-md\:-mx-22 {
    margin-left: -5.5rem !important;
    margin-right: -5.5rem !important;
  }

  .lt-md\:-my-24 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .lt-md\:-mx-24 {
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }

  .lt-md\:-my-26 {
    margin-top: -6.5rem !important;
    margin-bottom: -6.5rem !important;
  }

  .lt-md\:-mx-26 {
    margin-left: -6.5rem !important;
    margin-right: -6.5rem !important;
  }

  .lt-md\:-my-28 {
    margin-top: -7rem !important;
    margin-bottom: -7rem !important;
  }

  .lt-md\:-mx-28 {
    margin-left: -7rem !important;
    margin-right: -7rem !important;
  }

  .lt-md\:-my-30 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important;
  }

  .lt-md\:-mx-30 {
    margin-left: -7.5rem !important;
    margin-right: -7.5rem !important;
  }

  .lt-md\:-my-32 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .lt-md\:-mx-32 {
    margin-left: -8rem !important;
    margin-right: -8rem !important;
  }

  .lt-md\:-my-36 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }

  .lt-md\:-mx-36 {
    margin-left: -9rem !important;
    margin-right: -9rem !important;
  }

  .lt-md\:-my-40 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .lt-md\:-mx-40 {
    margin-left: -10rem !important;
    margin-right: -10rem !important;
  }

  .lt-md\:-my-48 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }

  .lt-md\:-mx-48 {
    margin-left: -12rem !important;
    margin-right: -12rem !important;
  }

  .lt-md\:-my-56 {
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }

  .lt-md\:-mx-56 {
    margin-left: -14rem !important;
    margin-right: -14rem !important;
  }

  .lt-md\:-my-64 {
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }

  .lt-md\:-mx-64 {
    margin-left: -16rem !important;
    margin-right: -16rem !important;
  }

  .lt-md\:-my-px {
    margin-top: -1px !important;
    margin-bottom: -1px !important;
  }

  .lt-md\:-mx-px {
    margin-left: -1px !important;
    margin-right: -1px !important;
  }

  .lt-md\:-my-2px {
    margin-top: -2px !important;
    margin-bottom: -2px !important;
  }

  .lt-md\:-mx-2px {
    margin-left: -2px !important;
    margin-right: -2px !important;
  }

  .lt-md\:mt-0 {
    margin-top: 0 !important;
  }

  .lt-md\:mr-0 {
    margin-right: 0 !important;
  }

  .lt-md\:mb-0 {
    margin-bottom: 0 !important;
  }

  .lt-md\:ml-0 {
    margin-left: 0 !important;
  }

  .lt-md\:mt-1 {
    margin-top: 0.25rem !important;
  }

  .lt-md\:mr-1 {
    margin-right: 0.25rem !important;
  }

  .lt-md\:mb-1 {
    margin-bottom: 0.25rem !important;
  }

  .lt-md\:ml-1 {
    margin-left: 0.25rem !important;
  }

  .lt-md\:mt-2 {
    margin-top: 0.5rem !important;
  }

  .lt-md\:mr-2 {
    margin-right: 0.5rem !important;
  }

  .lt-md\:mb-2 {
    margin-bottom: 0.5rem !important;
  }

  .lt-md\:ml-2 {
    margin-left: 0.5rem !important;
  }

  .lt-md\:mt-3 {
    margin-top: 0.75rem !important;
  }

  .lt-md\:mr-3 {
    margin-right: 0.75rem !important;
  }

  .lt-md\:mb-3 {
    margin-bottom: 0.75rem !important;
  }

  .lt-md\:ml-3 {
    margin-left: 0.75rem !important;
  }

  .lt-md\:mt-4 {
    margin-top: 1rem !important;
  }

  .lt-md\:mr-4 {
    margin-right: 1rem !important;
  }

  .lt-md\:mb-4 {
    margin-bottom: 1rem !important;
  }

  .lt-md\:ml-4 {
    margin-left: 1rem !important;
  }

  .lt-md\:mt-5 {
    margin-top: 1.25rem !important;
  }

  .lt-md\:mr-5 {
    margin-right: 1.25rem !important;
  }

  .lt-md\:mb-5 {
    margin-bottom: 1.25rem !important;
  }

  .lt-md\:ml-5 {
    margin-left: 1.25rem !important;
  }

  .lt-md\:mt-6 {
    margin-top: 1.5rem !important;
  }

  .lt-md\:mr-6 {
    margin-right: 1.5rem !important;
  }

  .lt-md\:mb-6 {
    margin-bottom: 1.5rem !important;
  }

  .lt-md\:ml-6 {
    margin-left: 1.5rem !important;
  }

  .lt-md\:mt-8 {
    margin-top: 2rem !important;
  }

  .lt-md\:mr-8 {
    margin-right: 2rem !important;
  }

  .lt-md\:mb-8 {
    margin-bottom: 2rem !important;
  }

  .lt-md\:ml-8 {
    margin-left: 2rem !important;
  }

  .lt-md\:mt-10 {
    margin-top: 2.5rem !important;
  }

  .lt-md\:mr-10 {
    margin-right: 2.5rem !important;
  }

  .lt-md\:mb-10 {
    margin-bottom: 2.5rem !important;
  }

  .lt-md\:ml-10 {
    margin-left: 2.5rem !important;
  }

  .lt-md\:mt-12 {
    margin-top: 3rem !important;
  }

  .lt-md\:mr-12 {
    margin-right: 3rem !important;
  }

  .lt-md\:mb-12 {
    margin-bottom: 3rem !important;
  }

  .lt-md\:ml-12 {
    margin-left: 3rem !important;
  }

  .lt-md\:mt-14 {
    margin-top: 3.5rem !important;
  }

  .lt-md\:mr-14 {
    margin-right: 3.5rem !important;
  }

  .lt-md\:mb-14 {
    margin-bottom: 3.5rem !important;
  }

  .lt-md\:ml-14 {
    margin-left: 3.5rem !important;
  }

  .lt-md\:mt-16 {
    margin-top: 4rem !important;
  }

  .lt-md\:mr-16 {
    margin-right: 4rem !important;
  }

  .lt-md\:mb-16 {
    margin-bottom: 4rem !important;
  }

  .lt-md\:ml-16 {
    margin-left: 4rem !important;
  }

  .lt-md\:mt-18 {
    margin-top: 4.5rem !important;
  }

  .lt-md\:mr-18 {
    margin-right: 4.5rem !important;
  }

  .lt-md\:mb-18 {
    margin-bottom: 4.5rem !important;
  }

  .lt-md\:ml-18 {
    margin-left: 4.5rem !important;
  }

  .lt-md\:mt-20 {
    margin-top: 5rem !important;
  }

  .lt-md\:mr-20 {
    margin-right: 5rem !important;
  }

  .lt-md\:mb-20 {
    margin-bottom: 5rem !important;
  }

  .lt-md\:ml-20 {
    margin-left: 5rem !important;
  }

  .lt-md\:mt-22 {
    margin-top: 5.5rem !important;
  }

  .lt-md\:mr-22 {
    margin-right: 5.5rem !important;
  }

  .lt-md\:mb-22 {
    margin-bottom: 5.5rem !important;
  }

  .lt-md\:ml-22 {
    margin-left: 5.5rem !important;
  }

  .lt-md\:mt-24 {
    margin-top: 6rem !important;
  }

  .lt-md\:mr-24 {
    margin-right: 6rem !important;
  }

  .lt-md\:mb-24 {
    margin-bottom: 6rem !important;
  }

  .lt-md\:ml-24 {
    margin-left: 6rem !important;
  }

  .lt-md\:mt-26 {
    margin-top: 6.5rem !important;
  }

  .lt-md\:mr-26 {
    margin-right: 6.5rem !important;
  }

  .lt-md\:mb-26 {
    margin-bottom: 6.5rem !important;
  }

  .lt-md\:ml-26 {
    margin-left: 6.5rem !important;
  }

  .lt-md\:mt-28 {
    margin-top: 7rem !important;
  }

  .lt-md\:mr-28 {
    margin-right: 7rem !important;
  }

  .lt-md\:mb-28 {
    margin-bottom: 7rem !important;
  }

  .lt-md\:ml-28 {
    margin-left: 7rem !important;
  }

  .lt-md\:mt-30 {
    margin-top: 7.5rem !important;
  }

  .lt-md\:mr-30 {
    margin-right: 7.5rem !important;
  }

  .lt-md\:mb-30 {
    margin-bottom: 7.5rem !important;
  }

  .lt-md\:ml-30 {
    margin-left: 7.5rem !important;
  }

  .lt-md\:mt-32 {
    margin-top: 8rem !important;
  }

  .lt-md\:mr-32 {
    margin-right: 8rem !important;
  }

  .lt-md\:mb-32 {
    margin-bottom: 8rem !important;
  }

  .lt-md\:ml-32 {
    margin-left: 8rem !important;
  }

  .lt-md\:mt-36 {
    margin-top: 9rem !important;
  }

  .lt-md\:mr-36 {
    margin-right: 9rem !important;
  }

  .lt-md\:mb-36 {
    margin-bottom: 9rem !important;
  }

  .lt-md\:ml-36 {
    margin-left: 9rem !important;
  }

  .lt-md\:mt-40 {
    margin-top: 10rem !important;
  }

  .lt-md\:mr-40 {
    margin-right: 10rem !important;
  }

  .lt-md\:mb-40 {
    margin-bottom: 10rem !important;
  }

  .lt-md\:ml-40 {
    margin-left: 10rem !important;
  }

  .lt-md\:mt-48 {
    margin-top: 12rem !important;
  }

  .lt-md\:mr-48 {
    margin-right: 12rem !important;
  }

  .lt-md\:mb-48 {
    margin-bottom: 12rem !important;
  }

  .lt-md\:ml-48 {
    margin-left: 12rem !important;
  }

  .lt-md\:mt-56 {
    margin-top: 14rem !important;
  }

  .lt-md\:mr-56 {
    margin-right: 14rem !important;
  }

  .lt-md\:mb-56 {
    margin-bottom: 14rem !important;
  }

  .lt-md\:ml-56 {
    margin-left: 14rem !important;
  }

  .lt-md\:mt-64 {
    margin-top: 16rem !important;
  }

  .lt-md\:mr-64 {
    margin-right: 16rem !important;
  }

  .lt-md\:mb-64 {
    margin-bottom: 16rem !important;
  }

  .lt-md\:ml-64 {
    margin-left: 16rem !important;
  }

  .lt-md\:mt-auto {
    margin-top: auto !important;
  }

  .lt-md\:mr-auto {
    margin-right: auto !important;
  }

  .lt-md\:mb-auto {
    margin-bottom: auto !important;
  }

  .lt-md\:ml-auto {
    margin-left: auto !important;
  }

  .lt-md\:mt-px {
    margin-top: 1px !important;
  }

  .lt-md\:mr-px {
    margin-right: 1px !important;
  }

  .lt-md\:mb-px {
    margin-bottom: 1px !important;
  }

  .lt-md\:ml-px {
    margin-left: 1px !important;
  }

  .lt-md\:mt-2px {
    margin-top: 2px !important;
  }

  .lt-md\:mr-2px {
    margin-right: 2px !important;
  }

  .lt-md\:mb-2px {
    margin-bottom: 2px !important;
  }

  .lt-md\:ml-2px {
    margin-left: 2px !important;
  }

  .lt-md\:-mt-1 {
    margin-top: -0.25rem !important;
  }

  .lt-md\:-mr-1 {
    margin-right: -0.25rem !important;
  }

  .lt-md\:-mb-1 {
    margin-bottom: -0.25rem !important;
  }

  .lt-md\:-ml-1 {
    margin-left: -0.25rem !important;
  }

  .lt-md\:-mt-2 {
    margin-top: -0.5rem !important;
  }

  .lt-md\:-mr-2 {
    margin-right: -0.5rem !important;
  }

  .lt-md\:-mb-2 {
    margin-bottom: -0.5rem !important;
  }

  .lt-md\:-ml-2 {
    margin-left: -0.5rem !important;
  }

  .lt-md\:-mt-3 {
    margin-top: -0.75rem !important;
  }

  .lt-md\:-mr-3 {
    margin-right: -0.75rem !important;
  }

  .lt-md\:-mb-3 {
    margin-bottom: -0.75rem !important;
  }

  .lt-md\:-ml-3 {
    margin-left: -0.75rem !important;
  }

  .lt-md\:-mt-4 {
    margin-top: -1rem !important;
  }

  .lt-md\:-mr-4 {
    margin-right: -1rem !important;
  }

  .lt-md\:-mb-4 {
    margin-bottom: -1rem !important;
  }

  .lt-md\:-ml-4 {
    margin-left: -1rem !important;
  }

  .lt-md\:-mt-5 {
    margin-top: -1.25rem !important;
  }

  .lt-md\:-mr-5 {
    margin-right: -1.25rem !important;
  }

  .lt-md\:-mb-5 {
    margin-bottom: -1.25rem !important;
  }

  .lt-md\:-ml-5 {
    margin-left: -1.25rem !important;
  }

  .lt-md\:-mt-6 {
    margin-top: -1.5rem !important;
  }

  .lt-md\:-mr-6 {
    margin-right: -1.5rem !important;
  }

  .lt-md\:-mb-6 {
    margin-bottom: -1.5rem !important;
  }

  .lt-md\:-ml-6 {
    margin-left: -1.5rem !important;
  }

  .lt-md\:-mt-8 {
    margin-top: -2rem !important;
  }

  .lt-md\:-mr-8 {
    margin-right: -2rem !important;
  }

  .lt-md\:-mb-8 {
    margin-bottom: -2rem !important;
  }

  .lt-md\:-ml-8 {
    margin-left: -2rem !important;
  }

  .lt-md\:-mt-10 {
    margin-top: -2.5rem !important;
  }

  .lt-md\:-mr-10 {
    margin-right: -2.5rem !important;
  }

  .lt-md\:-mb-10 {
    margin-bottom: -2.5rem !important;
  }

  .lt-md\:-ml-10 {
    margin-left: -2.5rem !important;
  }

  .lt-md\:-mt-12 {
    margin-top: -3rem !important;
  }

  .lt-md\:-mr-12 {
    margin-right: -3rem !important;
  }

  .lt-md\:-mb-12 {
    margin-bottom: -3rem !important;
  }

  .lt-md\:-ml-12 {
    margin-left: -3rem !important;
  }

  .lt-md\:-mt-14 {
    margin-top: -3.5rem !important;
  }

  .lt-md\:-mr-14 {
    margin-right: -3.5rem !important;
  }

  .lt-md\:-mb-14 {
    margin-bottom: -3.5rem !important;
  }

  .lt-md\:-ml-14 {
    margin-left: -3.5rem !important;
  }

  .lt-md\:-mt-16 {
    margin-top: -4rem !important;
  }

  .lt-md\:-mr-16 {
    margin-right: -4rem !important;
  }

  .lt-md\:-mb-16 {
    margin-bottom: -4rem !important;
  }

  .lt-md\:-ml-16 {
    margin-left: -4rem !important;
  }

  .lt-md\:-mt-18 {
    margin-top: -4.5rem !important;
  }

  .lt-md\:-mr-18 {
    margin-right: -4.5rem !important;
  }

  .lt-md\:-mb-18 {
    margin-bottom: -4.5rem !important;
  }

  .lt-md\:-ml-18 {
    margin-left: -4.5rem !important;
  }

  .lt-md\:-mt-20 {
    margin-top: -5rem !important;
  }

  .lt-md\:-mr-20 {
    margin-right: -5rem !important;
  }

  .lt-md\:-mb-20 {
    margin-bottom: -5rem !important;
  }

  .lt-md\:-ml-20 {
    margin-left: -5rem !important;
  }

  .lt-md\:-mt-22 {
    margin-top: -5.5rem !important;
  }

  .lt-md\:-mr-22 {
    margin-right: -5.5rem !important;
  }

  .lt-md\:-mb-22 {
    margin-bottom: -5.5rem !important;
  }

  .lt-md\:-ml-22 {
    margin-left: -5.5rem !important;
  }

  .lt-md\:-mt-24 {
    margin-top: -6rem !important;
  }

  .lt-md\:-mr-24 {
    margin-right: -6rem !important;
  }

  .lt-md\:-mb-24 {
    margin-bottom: -6rem !important;
  }

  .lt-md\:-ml-24 {
    margin-left: -6rem !important;
  }

  .lt-md\:-mt-26 {
    margin-top: -6.5rem !important;
  }

  .lt-md\:-mr-26 {
    margin-right: -6.5rem !important;
  }

  .lt-md\:-mb-26 {
    margin-bottom: -6.5rem !important;
  }

  .lt-md\:-ml-26 {
    margin-left: -6.5rem !important;
  }

  .lt-md\:-mt-28 {
    margin-top: -7rem !important;
  }

  .lt-md\:-mr-28 {
    margin-right: -7rem !important;
  }

  .lt-md\:-mb-28 {
    margin-bottom: -7rem !important;
  }

  .lt-md\:-ml-28 {
    margin-left: -7rem !important;
  }

  .lt-md\:-mt-30 {
    margin-top: -7.5rem !important;
  }

  .lt-md\:-mr-30 {
    margin-right: -7.5rem !important;
  }

  .lt-md\:-mb-30 {
    margin-bottom: -7.5rem !important;
  }

  .lt-md\:-ml-30 {
    margin-left: -7.5rem !important;
  }

  .lt-md\:-mt-32 {
    margin-top: -8rem !important;
  }

  .lt-md\:-mr-32 {
    margin-right: -8rem !important;
  }

  .lt-md\:-mb-32 {
    margin-bottom: -8rem !important;
  }

  .lt-md\:-ml-32 {
    margin-left: -8rem !important;
  }

  .lt-md\:-mt-36 {
    margin-top: -9rem !important;
  }

  .lt-md\:-mr-36 {
    margin-right: -9rem !important;
  }

  .lt-md\:-mb-36 {
    margin-bottom: -9rem !important;
  }

  .lt-md\:-ml-36 {
    margin-left: -9rem !important;
  }

  .lt-md\:-mt-40 {
    margin-top: -10rem !important;
  }

  .lt-md\:-mr-40 {
    margin-right: -10rem !important;
  }

  .lt-md\:-mb-40 {
    margin-bottom: -10rem !important;
  }

  .lt-md\:-ml-40 {
    margin-left: -10rem !important;
  }

  .lt-md\:-mt-48 {
    margin-top: -12rem !important;
  }

  .lt-md\:-mr-48 {
    margin-right: -12rem !important;
  }

  .lt-md\:-mb-48 {
    margin-bottom: -12rem !important;
  }

  .lt-md\:-ml-48 {
    margin-left: -12rem !important;
  }

  .lt-md\:-mt-56 {
    margin-top: -14rem !important;
  }

  .lt-md\:-mr-56 {
    margin-right: -14rem !important;
  }

  .lt-md\:-mb-56 {
    margin-bottom: -14rem !important;
  }

  .lt-md\:-ml-56 {
    margin-left: -14rem !important;
  }

  .lt-md\:-mt-64 {
    margin-top: -16rem !important;
  }

  .lt-md\:-mr-64 {
    margin-right: -16rem !important;
  }

  .lt-md\:-mb-64 {
    margin-bottom: -16rem !important;
  }

  .lt-md\:-ml-64 {
    margin-left: -16rem !important;
  }

  .lt-md\:-mt-px {
    margin-top: -1px !important;
  }

  .lt-md\:-mr-px {
    margin-right: -1px !important;
  }

  .lt-md\:-mb-px {
    margin-bottom: -1px !important;
  }

  .lt-md\:-ml-px {
    margin-left: -1px !important;
  }

  .lt-md\:-mt-2px {
    margin-top: -2px !important;
  }

  .lt-md\:-mr-2px {
    margin-right: -2px !important;
  }

  .lt-md\:-mb-2px {
    margin-bottom: -2px !important;
  }

  .lt-md\:-ml-2px {
    margin-left: -2px !important;
  }

  .lt-md\:max-h-0 {
    max-height: 0 !important;
  }

  .lt-md\:max-h-1 {
    max-height: 0.25rem !important;
  }

  .lt-md\:max-h-2 {
    max-height: 0.5rem !important;
  }

  .lt-md\:max-h-3 {
    max-height: 0.75rem !important;
  }

  .lt-md\:max-h-4 {
    max-height: 1rem !important;
  }

  .lt-md\:max-h-5 {
    max-height: 1.25rem !important;
  }

  .lt-md\:max-h-6 {
    max-height: 1.5rem !important;
  }

  .lt-md\:max-h-8 {
    max-height: 2rem !important;
  }

  .lt-md\:max-h-10 {
    max-height: 2.5rem !important;
  }

  .lt-md\:max-h-12 {
    max-height: 3rem !important;
  }

  .lt-md\:max-h-14 {
    max-height: 3.5rem !important;
  }

  .lt-md\:max-h-16 {
    max-height: 4rem !important;
  }

  .lt-md\:max-h-18 {
    max-height: 4.5rem !important;
  }

  .lt-md\:max-h-20 {
    max-height: 5rem !important;
  }

  .lt-md\:max-h-22 {
    max-height: 5.5rem !important;
  }

  .lt-md\:max-h-24 {
    max-height: 6rem !important;
  }

  .lt-md\:max-h-26 {
    max-height: 6.5rem !important;
  }

  .lt-md\:max-h-28 {
    max-height: 7rem !important;
  }

  .lt-md\:max-h-30 {
    max-height: 7.5rem !important;
  }

  .lt-md\:max-h-32 {
    max-height: 8rem !important;
  }

  .lt-md\:max-h-36 {
    max-height: 9rem !important;
  }

  .lt-md\:max-h-40 {
    max-height: 10rem !important;
  }

  .lt-md\:max-h-48 {
    max-height: 12rem !important;
  }

  .lt-md\:max-h-50 {
    max-height: 12.5rem !important;
  }

  .lt-md\:max-h-56 {
    max-height: 14rem !important;
  }

  .lt-md\:max-h-60 {
    max-height: 15rem !important;
  }

  .lt-md\:max-h-64 {
    max-height: 16rem !important;
  }

  .lt-md\:max-h-80 {
    max-height: 20rem !important;
  }

  .lt-md\:max-h-90 {
    max-height: 24rem !important;
  }

  .lt-md\:max-h-100 {
    max-height: 25rem !important;
  }

  .lt-md\:max-h-120 {
    max-height: 30rem !important;
  }

  .lt-md\:max-h-128 {
    max-height: 32rem !important;
  }

  .lt-md\:max-h-140 {
    max-height: 35rem !important;
  }

  .lt-md\:max-h-160 {
    max-height: 40rem !important;
  }

  .lt-md\:max-h-180 {
    max-height: 45rem !important;
  }

  .lt-md\:max-h-192 {
    max-height: 48rem !important;
  }

  .lt-md\:max-h-200 {
    max-height: 50rem !important;
  }

  .lt-md\:max-h-240 {
    max-height: 60rem !important;
  }

  .lt-md\:max-h-256 {
    max-height: 64rem !important;
  }

  .lt-md\:max-h-280 {
    max-height: 70rem !important;
  }

  .lt-md\:max-h-320 {
    max-height: 80rem !important;
  }

  .lt-md\:max-h-360 {
    max-height: 90rem !important;
  }

  .lt-md\:max-h-400 {
    max-height: 100rem !important;
  }

  .lt-md\:max-h-480 {
    max-height: 120rem !important;
  }

  .lt-md\:max-h-full {
    max-height: 100% !important;
  }

  .lt-md\:max-h-screen {
    max-height: 100vh !important;
  }

  .lt-md\:max-h-none {
    max-height: none !important;
  }

  .lt-md\:max-h-px {
    max-height: 1px !important;
  }

  .lt-md\:max-h-2px {
    max-height: 2px !important;
  }

  .lt-md\:max-h-1\/2 {
    max-height: 50% !important;
  }

  .lt-md\:max-h-1\/3 {
    max-height: 33.33333% !important;
  }

  .lt-md\:max-h-2\/3 {
    max-height: 66.66667% !important;
  }

  .lt-md\:max-h-1\/4 {
    max-height: 25% !important;
  }

  .lt-md\:max-h-2\/4 {
    max-height: 50% !important;
  }

  .lt-md\:max-h-3\/4 {
    max-height: 75% !important;
  }

  .lt-md\:max-h-1\/5 {
    max-height: 20% !important;
  }

  .lt-md\:max-h-2\/5 {
    max-height: 40% !important;
  }

  .lt-md\:max-h-3\/5 {
    max-height: 60% !important;
  }

  .lt-md\:max-h-4\/5 {
    max-height: 80% !important;
  }

  .lt-md\:max-h-1\/12 {
    max-height: 8.33333% !important;
  }

  .lt-md\:max-h-2\/12 {
    max-height: 16.66667% !important;
  }

  .lt-md\:max-h-3\/12 {
    max-height: 25% !important;
  }

  .lt-md\:max-h-4\/12 {
    max-height: 33.33333% !important;
  }

  .lt-md\:max-h-5\/12 {
    max-height: 41.66667% !important;
  }

  .lt-md\:max-h-6\/12 {
    max-height: 50% !important;
  }

  .lt-md\:max-h-7\/12 {
    max-height: 58.33333% !important;
  }

  .lt-md\:max-h-8\/12 {
    max-height: 66.66667% !important;
  }

  .lt-md\:max-h-9\/12 {
    max-height: 75% !important;
  }

  .lt-md\:max-h-10\/12 {
    max-height: 83.33333% !important;
  }

  .lt-md\:max-h-11\/12 {
    max-height: 91.66667% !important;
  }

  .lt-md\:max-w-0 {
    max-width: 0 !important;
  }

  .lt-md\:max-w-1 {
    max-width: 0.25rem !important;
  }

  .lt-md\:max-w-2 {
    max-width: 0.5rem !important;
  }

  .lt-md\:max-w-3 {
    max-width: 0.75rem !important;
  }

  .lt-md\:max-w-4 {
    max-width: 1rem !important;
  }

  .lt-md\:max-w-5 {
    max-width: 1.25rem !important;
  }

  .lt-md\:max-w-6 {
    max-width: 1.5rem !important;
  }

  .lt-md\:max-w-8 {
    max-width: 2rem !important;
  }

  .lt-md\:max-w-10 {
    max-width: 2.5rem !important;
  }

  .lt-md\:max-w-12 {
    max-width: 3rem !important;
  }

  .lt-md\:max-w-14 {
    max-width: 3.5rem !important;
  }

  .lt-md\:max-w-16 {
    max-width: 4rem !important;
  }

  .lt-md\:max-w-18 {
    max-width: 4.5rem !important;
  }

  .lt-md\:max-w-20 {
    max-width: 5rem !important;
  }

  .lt-md\:max-w-22 {
    max-width: 5.5rem !important;
  }

  .lt-md\:max-w-24 {
    max-width: 6rem !important;
  }

  .lt-md\:max-w-26 {
    max-width: 6.5rem !important;
  }

  .lt-md\:max-w-28 {
    max-width: 7rem !important;
  }

  .lt-md\:max-w-30 {
    max-width: 7.5rem !important;
  }

  .lt-md\:max-w-32 {
    max-width: 8rem !important;
  }

  .lt-md\:max-w-36 {
    max-width: 9rem !important;
  }

  .lt-md\:max-w-40 {
    max-width: 10rem !important;
  }

  .lt-md\:max-w-48 {
    max-width: 12rem !important;
  }

  .lt-md\:max-w-50 {
    max-width: 12.5rem !important;
  }

  .lt-md\:max-w-56 {
    max-width: 14rem !important;
  }

  .lt-md\:max-w-60 {
    max-width: 15rem !important;
  }

  .lt-md\:max-w-64 {
    max-width: 16rem !important;
  }

  .lt-md\:max-w-80 {
    max-width: 20rem !important;
  }

  .lt-md\:max-w-90 {
    max-width: 24rem !important;
  }

  .lt-md\:max-w-100 {
    max-width: 25rem !important;
  }

  .lt-md\:max-w-120 {
    max-width: 30rem !important;
  }

  .lt-md\:max-w-128 {
    max-width: 32rem !important;
  }

  .lt-md\:max-w-140 {
    max-width: 35rem !important;
  }

  .lt-md\:max-w-160 {
    max-width: 40rem !important;
  }

  .lt-md\:max-w-180 {
    max-width: 45rem !important;
  }

  .lt-md\:max-w-192 {
    max-width: 48rem !important;
  }

  .lt-md\:max-w-200 {
    max-width: 50rem !important;
  }

  .lt-md\:max-w-240 {
    max-width: 60rem !important;
  }

  .lt-md\:max-w-256 {
    max-width: 64rem !important;
  }

  .lt-md\:max-w-280 {
    max-width: 70rem !important;
  }

  .lt-md\:max-w-320 {
    max-width: 80rem !important;
  }

  .lt-md\:max-w-360 {
    max-width: 90rem !important;
  }

  .lt-md\:max-w-400 {
    max-width: 100rem !important;
  }

  .lt-md\:max-w-480 {
    max-width: 120rem !important;
  }

  .lt-md\:max-w-none {
    max-width: none !important;
  }

  .lt-md\:max-w-xs {
    max-width: 20rem !important;
  }

  .lt-md\:max-w-sm {
    max-width: 24rem !important;
  }

  .lt-md\:max-w-md {
    max-width: 28rem !important;
  }

  .lt-md\:max-w-lg {
    max-width: 32rem !important;
  }

  .lt-md\:max-w-xl {
    max-width: 36rem !important;
  }

  .lt-md\:max-w-2xl {
    max-width: 42rem !important;
  }

  .lt-md\:max-w-3xl {
    max-width: 48rem !important;
  }

  .lt-md\:max-w-4xl {
    max-width: 56rem !important;
  }

  .lt-md\:max-w-5xl {
    max-width: 64rem !important;
  }

  .lt-md\:max-w-6xl {
    max-width: 72rem !important;
  }

  .lt-md\:max-w-full {
    max-width: 100% !important;
  }

  .lt-md\:max-w-screen {
    max-width: 100vw !important;
  }

  .lt-md\:max-w-px {
    max-width: 1px !important;
  }

  .lt-md\:max-w-2px {
    max-width: 2px !important;
  }

  .lt-md\:max-w-1\/2 {
    max-width: 50% !important;
  }

  .lt-md\:max-w-1\/3 {
    max-width: 33.33333% !important;
  }

  .lt-md\:max-w-2\/3 {
    max-width: 66.66667% !important;
  }

  .lt-md\:max-w-1\/4 {
    max-width: 25% !important;
  }

  .lt-md\:max-w-2\/4 {
    max-width: 50% !important;
  }

  .lt-md\:max-w-3\/4 {
    max-width: 75% !important;
  }

  .lt-md\:max-w-1\/5 {
    max-width: 20% !important;
  }

  .lt-md\:max-w-2\/5 {
    max-width: 40% !important;
  }

  .lt-md\:max-w-3\/5 {
    max-width: 60% !important;
  }

  .lt-md\:max-w-4\/5 {
    max-width: 80% !important;
  }

  .lt-md\:max-w-1\/12 {
    max-width: 8.33333% !important;
  }

  .lt-md\:max-w-2\/12 {
    max-width: 16.66667% !important;
  }

  .lt-md\:max-w-3\/12 {
    max-width: 25% !important;
  }

  .lt-md\:max-w-4\/12 {
    max-width: 33.33333% !important;
  }

  .lt-md\:max-w-5\/12 {
    max-width: 41.66667% !important;
  }

  .lt-md\:max-w-6\/12 {
    max-width: 50% !important;
  }

  .lt-md\:max-w-7\/12 {
    max-width: 58.33333% !important;
  }

  .lt-md\:max-w-8\/12 {
    max-width: 66.66667% !important;
  }

  .lt-md\:max-w-9\/12 {
    max-width: 75% !important;
  }

  .lt-md\:max-w-10\/12 {
    max-width: 83.33333% !important;
  }

  .lt-md\:max-w-11\/12 {
    max-width: 91.66667% !important;
  }

  .lt-md\:min-h-0 {
    min-height: 0 !important;
  }

  .lt-md\:min-h-1 {
    min-height: 0.25rem !important;
  }

  .lt-md\:min-h-2 {
    min-height: 0.5rem !important;
  }

  .lt-md\:min-h-3 {
    min-height: 0.75rem !important;
  }

  .lt-md\:min-h-4 {
    min-height: 1rem !important;
  }

  .lt-md\:min-h-5 {
    min-height: 1.25rem !important;
  }

  .lt-md\:min-h-6 {
    min-height: 1.5rem !important;
  }

  .lt-md\:min-h-8 {
    min-height: 2rem !important;
  }

  .lt-md\:min-h-10 {
    min-height: 2.5rem !important;
  }

  .lt-md\:min-h-12 {
    min-height: 3rem !important;
  }

  .lt-md\:min-h-14 {
    min-height: 3.5rem !important;
  }

  .lt-md\:min-h-16 {
    min-height: 4rem !important;
  }

  .lt-md\:min-h-18 {
    min-height: 4.5rem !important;
  }

  .lt-md\:min-h-20 {
    min-height: 5rem !important;
  }

  .lt-md\:min-h-22 {
    min-height: 5.5rem !important;
  }

  .lt-md\:min-h-24 {
    min-height: 6rem !important;
  }

  .lt-md\:min-h-26 {
    min-height: 6.5rem !important;
  }

  .lt-md\:min-h-28 {
    min-height: 7rem !important;
  }

  .lt-md\:min-h-30 {
    min-height: 7.5rem !important;
  }

  .lt-md\:min-h-32 {
    min-height: 8rem !important;
  }

  .lt-md\:min-h-36 {
    min-height: 9rem !important;
  }

  .lt-md\:min-h-40 {
    min-height: 10rem !important;
  }

  .lt-md\:min-h-48 {
    min-height: 12rem !important;
  }

  .lt-md\:min-h-50 {
    min-height: 12.5rem !important;
  }

  .lt-md\:min-h-56 {
    min-height: 14rem !important;
  }

  .lt-md\:min-h-60 {
    min-height: 15rem !important;
  }

  .lt-md\:min-h-64 {
    min-height: 16rem !important;
  }

  .lt-md\:min-h-80 {
    min-height: 20rem !important;
  }

  .lt-md\:min-h-90 {
    min-height: 24rem !important;
  }

  .lt-md\:min-h-100 {
    min-height: 25rem !important;
  }

  .lt-md\:min-h-120 {
    min-height: 30rem !important;
  }

  .lt-md\:min-h-128 {
    min-height: 32rem !important;
  }

  .lt-md\:min-h-140 {
    min-height: 35rem !important;
  }

  .lt-md\:min-h-160 {
    min-height: 40rem !important;
  }

  .lt-md\:min-h-180 {
    min-height: 45rem !important;
  }

  .lt-md\:min-h-192 {
    min-height: 48rem !important;
  }

  .lt-md\:min-h-200 {
    min-height: 50rem !important;
  }

  .lt-md\:min-h-240 {
    min-height: 60rem !important;
  }

  .lt-md\:min-h-256 {
    min-height: 64rem !important;
  }

  .lt-md\:min-h-280 {
    min-height: 70rem !important;
  }

  .lt-md\:min-h-320 {
    min-height: 80rem !important;
  }

  .lt-md\:min-h-360 {
    min-height: 90rem !important;
  }

  .lt-md\:min-h-400 {
    min-height: 100rem !important;
  }

  .lt-md\:min-h-480 {
    min-height: 120rem !important;
  }

  .lt-md\:min-h-full {
    min-height: 100% !important;
  }

  .lt-md\:min-h-screen {
    min-height: 100vh !important;
  }

  .lt-md\:min-h-px {
    min-height: 1px !important;
  }

  .lt-md\:min-h-2px {
    min-height: 2px !important;
  }

  .lt-md\:min-h-1\/2 {
    min-height: 50% !important;
  }

  .lt-md\:min-h-1\/3 {
    min-height: 33.33333% !important;
  }

  .lt-md\:min-h-2\/3 {
    min-height: 66.66667% !important;
  }

  .lt-md\:min-h-1\/4 {
    min-height: 25% !important;
  }

  .lt-md\:min-h-2\/4 {
    min-height: 50% !important;
  }

  .lt-md\:min-h-3\/4 {
    min-height: 75% !important;
  }

  .lt-md\:min-h-1\/5 {
    min-height: 20% !important;
  }

  .lt-md\:min-h-2\/5 {
    min-height: 40% !important;
  }

  .lt-md\:min-h-3\/5 {
    min-height: 60% !important;
  }

  .lt-md\:min-h-4\/5 {
    min-height: 80% !important;
  }

  .lt-md\:min-h-1\/12 {
    min-height: 8.33333% !important;
  }

  .lt-md\:min-h-2\/12 {
    min-height: 16.66667% !important;
  }

  .lt-md\:min-h-3\/12 {
    min-height: 25% !important;
  }

  .lt-md\:min-h-4\/12 {
    min-height: 33.33333% !important;
  }

  .lt-md\:min-h-5\/12 {
    min-height: 41.66667% !important;
  }

  .lt-md\:min-h-6\/12 {
    min-height: 50% !important;
  }

  .lt-md\:min-h-7\/12 {
    min-height: 58.33333% !important;
  }

  .lt-md\:min-h-8\/12 {
    min-height: 66.66667% !important;
  }

  .lt-md\:min-h-9\/12 {
    min-height: 75% !important;
  }

  .lt-md\:min-h-10\/12 {
    min-height: 83.33333% !important;
  }

  .lt-md\:min-h-11\/12 {
    min-height: 91.66667% !important;
  }

  .lt-md\:min-w-0 {
    min-width: 0 !important;
  }

  .lt-md\:min-w-1 {
    min-width: 0.25rem !important;
  }

  .lt-md\:min-w-2 {
    min-width: 0.5rem !important;
  }

  .lt-md\:min-w-3 {
    min-width: 0.75rem !important;
  }

  .lt-md\:min-w-4 {
    min-width: 1rem !important;
  }

  .lt-md\:min-w-5 {
    min-width: 1.25rem !important;
  }

  .lt-md\:min-w-6 {
    min-width: 1.5rem !important;
  }

  .lt-md\:min-w-8 {
    min-width: 2rem !important;
  }

  .lt-md\:min-w-10 {
    min-width: 2.5rem !important;
  }

  .lt-md\:min-w-12 {
    min-width: 3rem !important;
  }

  .lt-md\:min-w-14 {
    min-width: 3.5rem !important;
  }

  .lt-md\:min-w-16 {
    min-width: 4rem !important;
  }

  .lt-md\:min-w-18 {
    min-width: 4.5rem !important;
  }

  .lt-md\:min-w-20 {
    min-width: 5rem !important;
  }

  .lt-md\:min-w-22 {
    min-width: 5.5rem !important;
  }

  .lt-md\:min-w-24 {
    min-width: 6rem !important;
  }

  .lt-md\:min-w-26 {
    min-width: 6.5rem !important;
  }

  .lt-md\:min-w-28 {
    min-width: 7rem !important;
  }

  .lt-md\:min-w-30 {
    min-width: 7.5rem !important;
  }

  .lt-md\:min-w-32 {
    min-width: 8rem !important;
  }

  .lt-md\:min-w-36 {
    min-width: 9rem !important;
  }

  .lt-md\:min-w-40 {
    min-width: 10rem !important;
  }

  .lt-md\:min-w-48 {
    min-width: 12rem !important;
  }

  .lt-md\:min-w-50 {
    min-width: 12.5rem !important;
  }

  .lt-md\:min-w-56 {
    min-width: 14rem !important;
  }

  .lt-md\:min-w-60 {
    min-width: 15rem !important;
  }

  .lt-md\:min-w-64 {
    min-width: 16rem !important;
  }

  .lt-md\:min-w-80 {
    min-width: 20rem !important;
  }

  .lt-md\:min-w-90 {
    min-width: 24rem !important;
  }

  .lt-md\:min-w-100 {
    min-width: 25rem !important;
  }

  .lt-md\:min-w-120 {
    min-width: 30rem !important;
  }

  .lt-md\:min-w-128 {
    min-width: 32rem !important;
  }

  .lt-md\:min-w-140 {
    min-width: 35rem !important;
  }

  .lt-md\:min-w-160 {
    min-width: 40rem !important;
  }

  .lt-md\:min-w-180 {
    min-width: 45rem !important;
  }

  .lt-md\:min-w-192 {
    min-width: 48rem !important;
  }

  .lt-md\:min-w-200 {
    min-width: 50rem !important;
  }

  .lt-md\:min-w-240 {
    min-width: 60rem !important;
  }

  .lt-md\:min-w-256 {
    min-width: 64rem !important;
  }

  .lt-md\:min-w-280 {
    min-width: 70rem !important;
  }

  .lt-md\:min-w-320 {
    min-width: 80rem !important;
  }

  .lt-md\:min-w-360 {
    min-width: 90rem !important;
  }

  .lt-md\:min-w-400 {
    min-width: 100rem !important;
  }

  .lt-md\:min-w-480 {
    min-width: 120rem !important;
  }

  .lt-md\:min-w-full {
    min-width: 100% !important;
  }

  .lt-md\:min-w-screen {
    min-width: 100vw !important;
  }

  .lt-md\:min-w-px {
    min-width: 1px !important;
  }

  .lt-md\:min-w-2px {
    min-width: 2px !important;
  }

  .lt-md\:min-w-1\/2 {
    min-width: 50% !important;
  }

  .lt-md\:min-w-1\/3 {
    min-width: 33.33333% !important;
  }

  .lt-md\:min-w-2\/3 {
    min-width: 66.66667% !important;
  }

  .lt-md\:min-w-1\/4 {
    min-width: 25% !important;
  }

  .lt-md\:min-w-2\/4 {
    min-width: 50% !important;
  }

  .lt-md\:min-w-3\/4 {
    min-width: 75% !important;
  }

  .lt-md\:min-w-1\/5 {
    min-width: 20% !important;
  }

  .lt-md\:min-w-2\/5 {
    min-width: 40% !important;
  }

  .lt-md\:min-w-3\/5 {
    min-width: 60% !important;
  }

  .lt-md\:min-w-4\/5 {
    min-width: 80% !important;
  }

  .lt-md\:min-w-1\/12 {
    min-width: 8.33333% !important;
  }

  .lt-md\:min-w-2\/12 {
    min-width: 16.66667% !important;
  }

  .lt-md\:min-w-3\/12 {
    min-width: 25% !important;
  }

  .lt-md\:min-w-4\/12 {
    min-width: 33.33333% !important;
  }

  .lt-md\:min-w-5\/12 {
    min-width: 41.66667% !important;
  }

  .lt-md\:min-w-6\/12 {
    min-width: 50% !important;
  }

  .lt-md\:min-w-7\/12 {
    min-width: 58.33333% !important;
  }

  .lt-md\:min-w-8\/12 {
    min-width: 66.66667% !important;
  }

  .lt-md\:min-w-9\/12 {
    min-width: 75% !important;
  }

  .lt-md\:min-w-10\/12 {
    min-width: 83.33333% !important;
  }

  .lt-md\:min-w-11\/12 {
    min-width: 91.66667% !important;
  }

  .lt-md\:object-contain {
    -o-object-fit: contain !important;
       object-fit: contain !important;
  }

  .lt-md\:object-cover {
    -o-object-fit: cover !important;
       object-fit: cover !important;
  }

  .lt-md\:object-fill {
    -o-object-fit: fill !important;
       object-fit: fill !important;
  }

  .lt-md\:object-none {
    -o-object-fit: none !important;
       object-fit: none !important;
  }

  .lt-md\:object-scale-down {
    -o-object-fit: scale-down !important;
       object-fit: scale-down !important;
  }

  .lt-md\:object-bottom {
    -o-object-position: bottom !important;
       object-position: bottom !important;
  }

  .lt-md\:object-center {
    -o-object-position: center !important;
       object-position: center !important;
  }

  .lt-md\:object-left {
    -o-object-position: left !important;
       object-position: left !important;
  }

  .lt-md\:object-left-bottom {
    -o-object-position: left bottom !important;
       object-position: left bottom !important;
  }

  .lt-md\:object-left-top {
    -o-object-position: left top !important;
       object-position: left top !important;
  }

  .lt-md\:object-right {
    -o-object-position: right !important;
       object-position: right !important;
  }

  .lt-md\:object-right-bottom {
    -o-object-position: right bottom !important;
       object-position: right bottom !important;
  }

  .lt-md\:object-right-top {
    -o-object-position: right top !important;
       object-position: right top !important;
  }

  .lt-md\:object-top {
    -o-object-position: top !important;
       object-position: top !important;
  }

  .lt-md\:opacity-0 {
    opacity: 0 !important;
  }

  .lt-md\:opacity-12 {
    opacity: 0.12 !important;
  }

  .lt-md\:opacity-25 {
    opacity: 0.25 !important;
  }

  .lt-md\:opacity-38 {
    opacity: 0.38 !important;
  }

  .lt-md\:opacity-50 {
    opacity: 0.5 !important;
  }

  .lt-md\:opacity-54 {
    opacity: 0.54 !important;
  }

  .lt-md\:opacity-70 {
    opacity: 0.70 !important;
  }

  .lt-md\:opacity-75 {
    opacity: 0.75 !important;
  }

  .lt-md\:opacity-84 {
    opacity: 0.84 !important;
  }

  .lt-md\:opacity-100 {
    opacity: 1 !important;
  }

  .lt-md\:hover\:opacity-0:hover {
    opacity: 0 !important;
  }

  .lt-md\:hover\:opacity-12:hover {
    opacity: 0.12 !important;
  }

  .lt-md\:hover\:opacity-25:hover {
    opacity: 0.25 !important;
  }

  .lt-md\:hover\:opacity-38:hover {
    opacity: 0.38 !important;
  }

  .lt-md\:hover\:opacity-50:hover {
    opacity: 0.5 !important;
  }

  .lt-md\:hover\:opacity-54:hover {
    opacity: 0.54 !important;
  }

  .lt-md\:hover\:opacity-70:hover {
    opacity: 0.70 !important;
  }

  .lt-md\:hover\:opacity-75:hover {
    opacity: 0.75 !important;
  }

  .lt-md\:hover\:opacity-84:hover {
    opacity: 0.84 !important;
  }

  .lt-md\:hover\:opacity-100:hover {
    opacity: 1 !important;
  }

  .lt-md\:focus\:opacity-0:focus {
    opacity: 0 !important;
  }

  .lt-md\:focus\:opacity-12:focus {
    opacity: 0.12 !important;
  }

  .lt-md\:focus\:opacity-25:focus {
    opacity: 0.25 !important;
  }

  .lt-md\:focus\:opacity-38:focus {
    opacity: 0.38 !important;
  }

  .lt-md\:focus\:opacity-50:focus {
    opacity: 0.5 !important;
  }

  .lt-md\:focus\:opacity-54:focus {
    opacity: 0.54 !important;
  }

  .lt-md\:focus\:opacity-70:focus {
    opacity: 0.70 !important;
  }

  .lt-md\:focus\:opacity-75:focus {
    opacity: 0.75 !important;
  }

  .lt-md\:focus\:opacity-84:focus {
    opacity: 0.84 !important;
  }

  .lt-md\:focus\:opacity-100:focus {
    opacity: 1 !important;
  }

  .lt-md\:outline-none {
    outline: 0 !important;
  }

  .lt-md\:focus\:outline-none:focus {
    outline: 0 !important;
  }

  .lt-md\:overflow-auto {
    overflow: auto !important;
  }

  .lt-md\:overflow-hidden {
    overflow: hidden !important;
  }

  .lt-md\:overflow-visible {
    overflow: visible !important;
  }

  .lt-md\:overflow-scroll {
    overflow: scroll !important;
  }

  .lt-md\:overflow-x-auto {
    overflow-x: auto !important;
  }

  .lt-md\:overflow-y-auto {
    overflow-y: auto !important;
  }

  .lt-md\:overflow-x-hidden {
    overflow-x: hidden !important;
  }

  .lt-md\:overflow-y-hidden {
    overflow-y: hidden !important;
  }

  .lt-md\:overflow-x-visible {
    overflow-x: visible !important;
  }

  .lt-md\:overflow-y-visible {
    overflow-y: visible !important;
  }

  .lt-md\:overflow-x-scroll {
    overflow-x: scroll !important;
  }

  .lt-md\:overflow-y-scroll {
    overflow-y: scroll !important;
  }

  .lt-md\:scrolling-touch {
    -webkit-overflow-scrolling: touch !important;
  }

  .lt-md\:scrolling-auto {
    -webkit-overflow-scrolling: auto !important;
  }

  .lt-md\:p-0 {
    padding: 0 !important;
  }

  .lt-md\:p-1 {
    padding: 0.25rem !important;
  }

  .lt-md\:p-2 {
    padding: 0.5rem !important;
  }

  .lt-md\:p-3 {
    padding: 0.75rem !important;
  }

  .lt-md\:p-4 {
    padding: 1rem !important;
  }

  .lt-md\:p-5 {
    padding: 1.25rem !important;
  }

  .lt-md\:p-6 {
    padding: 1.5rem !important;
  }

  .lt-md\:p-8 {
    padding: 2rem !important;
  }

  .lt-md\:p-10 {
    padding: 2.5rem !important;
  }

  .lt-md\:p-12 {
    padding: 3rem !important;
  }

  .lt-md\:p-14 {
    padding: 3.5rem !important;
  }

  .lt-md\:p-16 {
    padding: 4rem !important;
  }

  .lt-md\:p-18 {
    padding: 4.5rem !important;
  }

  .lt-md\:p-20 {
    padding: 5rem !important;
  }

  .lt-md\:p-22 {
    padding: 5.5rem !important;
  }

  .lt-md\:p-24 {
    padding: 6rem !important;
  }

  .lt-md\:p-26 {
    padding: 6.5rem !important;
  }

  .lt-md\:p-28 {
    padding: 7rem !important;
  }

  .lt-md\:p-30 {
    padding: 7.5rem !important;
  }

  .lt-md\:p-32 {
    padding: 8rem !important;
  }

  .lt-md\:p-36 {
    padding: 9rem !important;
  }

  .lt-md\:p-40 {
    padding: 10rem !important;
  }

  .lt-md\:p-48 {
    padding: 12rem !important;
  }

  .lt-md\:p-56 {
    padding: 14rem !important;
  }

  .lt-md\:p-64 {
    padding: 16rem !important;
  }

  .lt-md\:p-px {
    padding: 1px !important;
  }

  .lt-md\:p-2px {
    padding: 2px !important;
  }

  .lt-md\:py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .lt-md\:px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .lt-md\:py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .lt-md\:px-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }

  .lt-md\:py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .lt-md\:px-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .lt-md\:py-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .lt-md\:px-3 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }

  .lt-md\:py-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .lt-md\:px-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .lt-md\:py-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .lt-md\:px-5 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }

  .lt-md\:py-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .lt-md\:px-6 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .lt-md\:py-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .lt-md\:px-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .lt-md\:py-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .lt-md\:px-10 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .lt-md\:py-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .lt-md\:px-12 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .lt-md\:py-14 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .lt-md\:px-14 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }

  .lt-md\:py-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .lt-md\:px-16 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .lt-md\:py-18 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .lt-md\:px-18 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }

  .lt-md\:py-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .lt-md\:px-20 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .lt-md\:py-22 {
    padding-top: 5.5rem !important;
    padding-bottom: 5.5rem !important;
  }

  .lt-md\:px-22 {
    padding-left: 5.5rem !important;
    padding-right: 5.5rem !important;
  }

  .lt-md\:py-24 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .lt-md\:px-24 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }

  .lt-md\:py-26 {
    padding-top: 6.5rem !important;
    padding-bottom: 6.5rem !important;
  }

  .lt-md\:px-26 {
    padding-left: 6.5rem !important;
    padding-right: 6.5rem !important;
  }

  .lt-md\:py-28 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }

  .lt-md\:px-28 {
    padding-left: 7rem !important;
    padding-right: 7rem !important;
  }

  .lt-md\:py-30 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }

  .lt-md\:px-30 {
    padding-left: 7.5rem !important;
    padding-right: 7.5rem !important;
  }

  .lt-md\:py-32 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .lt-md\:px-32 {
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }

  .lt-md\:py-36 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }

  .lt-md\:px-36 {
    padding-left: 9rem !important;
    padding-right: 9rem !important;
  }

  .lt-md\:py-40 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .lt-md\:px-40 {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }

  .lt-md\:py-48 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }

  .lt-md\:px-48 {
    padding-left: 12rem !important;
    padding-right: 12rem !important;
  }

  .lt-md\:py-56 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }

  .lt-md\:px-56 {
    padding-left: 14rem !important;
    padding-right: 14rem !important;
  }

  .lt-md\:py-64 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }

  .lt-md\:px-64 {
    padding-left: 16rem !important;
    padding-right: 16rem !important;
  }

  .lt-md\:py-px {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }

  .lt-md\:px-px {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }

  .lt-md\:py-2px {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }

  .lt-md\:px-2px {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }

  .lt-md\:pt-0 {
    padding-top: 0 !important;
  }

  .lt-md\:pr-0 {
    padding-right: 0 !important;
  }

  .lt-md\:pb-0 {
    padding-bottom: 0 !important;
  }

  .lt-md\:pl-0 {
    padding-left: 0 !important;
  }

  .lt-md\:pt-1 {
    padding-top: 0.25rem !important;
  }

  .lt-md\:pr-1 {
    padding-right: 0.25rem !important;
  }

  .lt-md\:pb-1 {
    padding-bottom: 0.25rem !important;
  }

  .lt-md\:pl-1 {
    padding-left: 0.25rem !important;
  }

  .lt-md\:pt-2 {
    padding-top: 0.5rem !important;
  }

  .lt-md\:pr-2 {
    padding-right: 0.5rem !important;
  }

  .lt-md\:pb-2 {
    padding-bottom: 0.5rem !important;
  }

  .lt-md\:pl-2 {
    padding-left: 0.5rem !important;
  }

  .lt-md\:pt-3 {
    padding-top: 0.75rem !important;
  }

  .lt-md\:pr-3 {
    padding-right: 0.75rem !important;
  }

  .lt-md\:pb-3 {
    padding-bottom: 0.75rem !important;
  }

  .lt-md\:pl-3 {
    padding-left: 0.75rem !important;
  }

  .lt-md\:pt-4 {
    padding-top: 1rem !important;
  }

  .lt-md\:pr-4 {
    padding-right: 1rem !important;
  }

  .lt-md\:pb-4 {
    padding-bottom: 1rem !important;
  }

  .lt-md\:pl-4 {
    padding-left: 1rem !important;
  }

  .lt-md\:pt-5 {
    padding-top: 1.25rem !important;
  }

  .lt-md\:pr-5 {
    padding-right: 1.25rem !important;
  }

  .lt-md\:pb-5 {
    padding-bottom: 1.25rem !important;
  }

  .lt-md\:pl-5 {
    padding-left: 1.25rem !important;
  }

  .lt-md\:pt-6 {
    padding-top: 1.5rem !important;
  }

  .lt-md\:pr-6 {
    padding-right: 1.5rem !important;
  }

  .lt-md\:pb-6 {
    padding-bottom: 1.5rem !important;
  }

  .lt-md\:pl-6 {
    padding-left: 1.5rem !important;
  }

  .lt-md\:pt-8 {
    padding-top: 2rem !important;
  }

  .lt-md\:pr-8 {
    padding-right: 2rem !important;
  }

  .lt-md\:pb-8 {
    padding-bottom: 2rem !important;
  }

  .lt-md\:pl-8 {
    padding-left: 2rem !important;
  }

  .lt-md\:pt-10 {
    padding-top: 2.5rem !important;
  }

  .lt-md\:pr-10 {
    padding-right: 2.5rem !important;
  }

  .lt-md\:pb-10 {
    padding-bottom: 2.5rem !important;
  }

  .lt-md\:pl-10 {
    padding-left: 2.5rem !important;
  }

  .lt-md\:pt-12 {
    padding-top: 3rem !important;
  }

  .lt-md\:pr-12 {
    padding-right: 3rem !important;
  }

  .lt-md\:pb-12 {
    padding-bottom: 3rem !important;
  }

  .lt-md\:pl-12 {
    padding-left: 3rem !important;
  }

  .lt-md\:pt-14 {
    padding-top: 3.5rem !important;
  }

  .lt-md\:pr-14 {
    padding-right: 3.5rem !important;
  }

  .lt-md\:pb-14 {
    padding-bottom: 3.5rem !important;
  }

  .lt-md\:pl-14 {
    padding-left: 3.5rem !important;
  }

  .lt-md\:pt-16 {
    padding-top: 4rem !important;
  }

  .lt-md\:pr-16 {
    padding-right: 4rem !important;
  }

  .lt-md\:pb-16 {
    padding-bottom: 4rem !important;
  }

  .lt-md\:pl-16 {
    padding-left: 4rem !important;
  }

  .lt-md\:pt-18 {
    padding-top: 4.5rem !important;
  }

  .lt-md\:pr-18 {
    padding-right: 4.5rem !important;
  }

  .lt-md\:pb-18 {
    padding-bottom: 4.5rem !important;
  }

  .lt-md\:pl-18 {
    padding-left: 4.5rem !important;
  }

  .lt-md\:pt-20 {
    padding-top: 5rem !important;
  }

  .lt-md\:pr-20 {
    padding-right: 5rem !important;
  }

  .lt-md\:pb-20 {
    padding-bottom: 5rem !important;
  }

  .lt-md\:pl-20 {
    padding-left: 5rem !important;
  }

  .lt-md\:pt-22 {
    padding-top: 5.5rem !important;
  }

  .lt-md\:pr-22 {
    padding-right: 5.5rem !important;
  }

  .lt-md\:pb-22 {
    padding-bottom: 5.5rem !important;
  }

  .lt-md\:pl-22 {
    padding-left: 5.5rem !important;
  }

  .lt-md\:pt-24 {
    padding-top: 6rem !important;
  }

  .lt-md\:pr-24 {
    padding-right: 6rem !important;
  }

  .lt-md\:pb-24 {
    padding-bottom: 6rem !important;
  }

  .lt-md\:pl-24 {
    padding-left: 6rem !important;
  }

  .lt-md\:pt-26 {
    padding-top: 6.5rem !important;
  }

  .lt-md\:pr-26 {
    padding-right: 6.5rem !important;
  }

  .lt-md\:pb-26 {
    padding-bottom: 6.5rem !important;
  }

  .lt-md\:pl-26 {
    padding-left: 6.5rem !important;
  }

  .lt-md\:pt-28 {
    padding-top: 7rem !important;
  }

  .lt-md\:pr-28 {
    padding-right: 7rem !important;
  }

  .lt-md\:pb-28 {
    padding-bottom: 7rem !important;
  }

  .lt-md\:pl-28 {
    padding-left: 7rem !important;
  }

  .lt-md\:pt-30 {
    padding-top: 7.5rem !important;
  }

  .lt-md\:pr-30 {
    padding-right: 7.5rem !important;
  }

  .lt-md\:pb-30 {
    padding-bottom: 7.5rem !important;
  }

  .lt-md\:pl-30 {
    padding-left: 7.5rem !important;
  }

  .lt-md\:pt-32 {
    padding-top: 8rem !important;
  }

  .lt-md\:pr-32 {
    padding-right: 8rem !important;
  }

  .lt-md\:pb-32 {
    padding-bottom: 8rem !important;
  }

  .lt-md\:pl-32 {
    padding-left: 8rem !important;
  }

  .lt-md\:pt-36 {
    padding-top: 9rem !important;
  }

  .lt-md\:pr-36 {
    padding-right: 9rem !important;
  }

  .lt-md\:pb-36 {
    padding-bottom: 9rem !important;
  }

  .lt-md\:pl-36 {
    padding-left: 9rem !important;
  }

  .lt-md\:pt-40 {
    padding-top: 10rem !important;
  }

  .lt-md\:pr-40 {
    padding-right: 10rem !important;
  }

  .lt-md\:pb-40 {
    padding-bottom: 10rem !important;
  }

  .lt-md\:pl-40 {
    padding-left: 10rem !important;
  }

  .lt-md\:pt-48 {
    padding-top: 12rem !important;
  }

  .lt-md\:pr-48 {
    padding-right: 12rem !important;
  }

  .lt-md\:pb-48 {
    padding-bottom: 12rem !important;
  }

  .lt-md\:pl-48 {
    padding-left: 12rem !important;
  }

  .lt-md\:pt-56 {
    padding-top: 14rem !important;
  }

  .lt-md\:pr-56 {
    padding-right: 14rem !important;
  }

  .lt-md\:pb-56 {
    padding-bottom: 14rem !important;
  }

  .lt-md\:pl-56 {
    padding-left: 14rem !important;
  }

  .lt-md\:pt-64 {
    padding-top: 16rem !important;
  }

  .lt-md\:pr-64 {
    padding-right: 16rem !important;
  }

  .lt-md\:pb-64 {
    padding-bottom: 16rem !important;
  }

  .lt-md\:pl-64 {
    padding-left: 16rem !important;
  }

  .lt-md\:pt-px {
    padding-top: 1px !important;
  }

  .lt-md\:pr-px {
    padding-right: 1px !important;
  }

  .lt-md\:pb-px {
    padding-bottom: 1px !important;
  }

  .lt-md\:pl-px {
    padding-left: 1px !important;
  }

  .lt-md\:pt-2px {
    padding-top: 2px !important;
  }

  .lt-md\:pr-2px {
    padding-right: 2px !important;
  }

  .lt-md\:pb-2px {
    padding-bottom: 2px !important;
  }

  .lt-md\:pl-2px {
    padding-left: 2px !important;
  }

  .lt-md\:placeholder-opacity-0::-webkit-input-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .lt-md\:placeholder-opacity-0::-moz-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .lt-md\:placeholder-opacity-0::-ms-input-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .lt-md\:placeholder-opacity-0::placeholder {
    --placeholder-opacity: 0 !important;
  }

  .lt-md\:placeholder-opacity-12::-webkit-input-placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .lt-md\:placeholder-opacity-12::-moz-placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .lt-md\:placeholder-opacity-12::-ms-input-placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .lt-md\:placeholder-opacity-12::placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .lt-md\:placeholder-opacity-25::-webkit-input-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .lt-md\:placeholder-opacity-25::-moz-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .lt-md\:placeholder-opacity-25::-ms-input-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .lt-md\:placeholder-opacity-25::placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .lt-md\:placeholder-opacity-38::-webkit-input-placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .lt-md\:placeholder-opacity-38::-moz-placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .lt-md\:placeholder-opacity-38::-ms-input-placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .lt-md\:placeholder-opacity-38::placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .lt-md\:placeholder-opacity-50::-webkit-input-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .lt-md\:placeholder-opacity-50::-moz-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .lt-md\:placeholder-opacity-50::-ms-input-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .lt-md\:placeholder-opacity-50::placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .lt-md\:placeholder-opacity-54::-webkit-input-placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .lt-md\:placeholder-opacity-54::-moz-placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .lt-md\:placeholder-opacity-54::-ms-input-placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .lt-md\:placeholder-opacity-54::placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .lt-md\:placeholder-opacity-70::-webkit-input-placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .lt-md\:placeholder-opacity-70::-moz-placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .lt-md\:placeholder-opacity-70::-ms-input-placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .lt-md\:placeholder-opacity-70::placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .lt-md\:placeholder-opacity-75::-webkit-input-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .lt-md\:placeholder-opacity-75::-moz-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .lt-md\:placeholder-opacity-75::-ms-input-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .lt-md\:placeholder-opacity-75::placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .lt-md\:placeholder-opacity-84::-webkit-input-placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .lt-md\:placeholder-opacity-84::-moz-placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .lt-md\:placeholder-opacity-84::-ms-input-placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .lt-md\:placeholder-opacity-84::placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .lt-md\:placeholder-opacity-100::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .lt-md\:placeholder-opacity-100::-moz-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .lt-md\:placeholder-opacity-100::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .lt-md\:placeholder-opacity-100::placeholder {
    --placeholder-opacity: 1 !important;
  }

  .lt-md\:focus\:placeholder-opacity-0:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .lt-md\:focus\:placeholder-opacity-0:focus::-moz-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .lt-md\:focus\:placeholder-opacity-0:focus::-ms-input-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .lt-md\:focus\:placeholder-opacity-0:focus::placeholder {
    --placeholder-opacity: 0 !important;
  }

  .lt-md\:focus\:placeholder-opacity-12:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .lt-md\:focus\:placeholder-opacity-12:focus::-moz-placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .lt-md\:focus\:placeholder-opacity-12:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .lt-md\:focus\:placeholder-opacity-12:focus::placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .lt-md\:focus\:placeholder-opacity-25:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .lt-md\:focus\:placeholder-opacity-25:focus::-moz-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .lt-md\:focus\:placeholder-opacity-25:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .lt-md\:focus\:placeholder-opacity-25:focus::placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .lt-md\:focus\:placeholder-opacity-38:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .lt-md\:focus\:placeholder-opacity-38:focus::-moz-placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .lt-md\:focus\:placeholder-opacity-38:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .lt-md\:focus\:placeholder-opacity-38:focus::placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .lt-md\:focus\:placeholder-opacity-50:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .lt-md\:focus\:placeholder-opacity-50:focus::-moz-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .lt-md\:focus\:placeholder-opacity-50:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .lt-md\:focus\:placeholder-opacity-50:focus::placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .lt-md\:focus\:placeholder-opacity-54:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .lt-md\:focus\:placeholder-opacity-54:focus::-moz-placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .lt-md\:focus\:placeholder-opacity-54:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .lt-md\:focus\:placeholder-opacity-54:focus::placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .lt-md\:focus\:placeholder-opacity-70:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .lt-md\:focus\:placeholder-opacity-70:focus::-moz-placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .lt-md\:focus\:placeholder-opacity-70:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .lt-md\:focus\:placeholder-opacity-70:focus::placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .lt-md\:focus\:placeholder-opacity-75:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .lt-md\:focus\:placeholder-opacity-75:focus::-moz-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .lt-md\:focus\:placeholder-opacity-75:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .lt-md\:focus\:placeholder-opacity-75:focus::placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .lt-md\:focus\:placeholder-opacity-84:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .lt-md\:focus\:placeholder-opacity-84:focus::-moz-placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .lt-md\:focus\:placeholder-opacity-84:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .lt-md\:focus\:placeholder-opacity-84:focus::placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .lt-md\:focus\:placeholder-opacity-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .lt-md\:focus\:placeholder-opacity-100:focus::-moz-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .lt-md\:focus\:placeholder-opacity-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .lt-md\:focus\:placeholder-opacity-100:focus::placeholder {
    --placeholder-opacity: 1 !important;
  }

  .lt-md\:pointer-events-none {
    pointer-events: none !important;
  }

  .lt-md\:pointer-events-auto {
    pointer-events: auto !important;
  }

  .lt-md\:static {
    position: static !important;
  }

  .lt-md\:fixed {
    position: fixed !important;
  }

  .lt-md\:absolute {
    position: absolute !important;
  }

  .lt-md\:relative {
    position: relative !important;
  }

  .lt-md\:sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }

  .lt-md\:inset-0 {
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
  }

  .lt-md\:inset-auto {
    top: auto !important;
    right: auto !important;
    bottom: auto !important;
    left: auto !important;
  }

  .lt-md\:inset-y-0 {
    top: 0 !important;
    bottom: 0 !important;
  }

  .lt-md\:inset-x-0 {
    right: 0 !important;
    left: 0 !important;
  }

  .lt-md\:inset-y-auto {
    top: auto !important;
    bottom: auto !important;
  }

  .lt-md\:inset-x-auto {
    right: auto !important;
    left: auto !important;
  }

  .lt-md\:top-0 {
    top: 0 !important;
  }

  .lt-md\:right-0 {
    right: 0 !important;
  }

  .lt-md\:bottom-0 {
    bottom: 0 !important;
  }

  .lt-md\:left-0 {
    left: 0 !important;
  }

  .lt-md\:top-auto {
    top: auto !important;
  }

  .lt-md\:right-auto {
    right: auto !important;
  }

  .lt-md\:bottom-auto {
    bottom: auto !important;
  }

  .lt-md\:left-auto {
    left: auto !important;
  }

  .lt-md\:shadow-xs {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05) !important;
  }

  .lt-md\:shadow-sm {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  }

  .lt-md\:shadow {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .lt-md\:shadow-md {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  }

  .lt-md\:shadow-lg {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  }

  .lt-md\:shadow-xl {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
  }

  .lt-md\:shadow-2xl {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
  }

  .lt-md\:shadow-inner {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .lt-md\:ring {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }

  .lt-md\:shadow-none {
    box-shadow: none !important;
  }

  .lt-md\:shadow-solid {
    box-shadow: 0 0 0 2px currentColor !important;
  }

  .lt-md\:hover\:shadow-xs:hover {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05) !important;
  }

  .lt-md\:hover\:shadow-sm:hover {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  }

  .lt-md\:hover\:shadow:hover {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .lt-md\:hover\:shadow-md:hover {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  }

  .lt-md\:hover\:shadow-lg:hover {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  }

  .lt-md\:hover\:shadow-xl:hover {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
  }

  .lt-md\:hover\:shadow-2xl:hover {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
  }

  .lt-md\:hover\:shadow-inner:hover {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .lt-md\:hover\:ring:hover {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }

  .lt-md\:hover\:shadow-none:hover {
    box-shadow: none !important;
  }

  .lt-md\:hover\:shadow-solid:hover {
    box-shadow: 0 0 0 2px currentColor !important;
  }

  .lt-md\:focus\:shadow-xs:focus {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05) !important;
  }

  .lt-md\:focus\:shadow-sm:focus {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  }

  .lt-md\:focus\:shadow:focus {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .lt-md\:focus\:shadow-md:focus {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  }

  .lt-md\:focus\:shadow-lg:focus {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  }

  .lt-md\:focus\:shadow-xl:focus {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
  }

  .lt-md\:focus\:shadow-2xl:focus {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
  }

  .lt-md\:focus\:shadow-inner:focus {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .lt-md\:focus\:ring:focus {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }

  .lt-md\:focus\:shadow-none:focus {
    box-shadow: none !important;
  }

  .lt-md\:focus\:shadow-solid:focus {
    box-shadow: 0 0 0 2px currentColor !important;
  }

  .lt-md\:fill-current {
    fill: currentColor !important;
  }

  .lt-md\:stroke-current {
    stroke: currentColor !important;
  }

  .lt-md\:stroke-0 {
    stroke-width: 0 !important;
  }

  .lt-md\:stroke-1 {
    stroke-width: 1 !important;
  }

  .lt-md\:stroke-2 {
    stroke-width: 2 !important;
  }

  .lt-md\:table-auto {
    table-layout: auto !important;
  }

  .lt-md\:table-fixed {
    table-layout: fixed !important;
  }

  .lt-md\:text-left {
    text-align: left !important;
  }

  .lt-md\:text-center {
    text-align: center !important;
  }

  .lt-md\:text-right {
    text-align: right !important;
  }

  .lt-md\:text-justify {
    text-align: justify !important;
  }

  .lt-md\:text-opacity-0 {
    --text-opacity: 0 !important;
  }

  .lt-md\:text-opacity-12 {
    --text-opacity: 0.12 !important;
  }

  .lt-md\:text-opacity-25 {
    --text-opacity: 0.25 !important;
  }

  .lt-md\:text-opacity-38 {
    --text-opacity: 0.38 !important;
  }

  .lt-md\:text-opacity-50 {
    --text-opacity: 0.5 !important;
  }

  .lt-md\:text-opacity-54 {
    --text-opacity: 0.54 !important;
  }

  .lt-md\:text-opacity-70 {
    --text-opacity: 0.70 !important;
  }

  .lt-md\:text-opacity-75 {
    --text-opacity: 0.75 !important;
  }

  .lt-md\:text-opacity-84 {
    --text-opacity: 0.84 !important;
  }

  .lt-md\:text-opacity-100 {
    --text-opacity: 1 !important;
  }

  .lt-md\:hover\:text-opacity-0:hover {
    --text-opacity: 0 !important;
  }

  .lt-md\:hover\:text-opacity-12:hover {
    --text-opacity: 0.12 !important;
  }

  .lt-md\:hover\:text-opacity-25:hover {
    --text-opacity: 0.25 !important;
  }

  .lt-md\:hover\:text-opacity-38:hover {
    --text-opacity: 0.38 !important;
  }

  .lt-md\:hover\:text-opacity-50:hover {
    --text-opacity: 0.5 !important;
  }

  .lt-md\:hover\:text-opacity-54:hover {
    --text-opacity: 0.54 !important;
  }

  .lt-md\:hover\:text-opacity-70:hover {
    --text-opacity: 0.70 !important;
  }

  .lt-md\:hover\:text-opacity-75:hover {
    --text-opacity: 0.75 !important;
  }

  .lt-md\:hover\:text-opacity-84:hover {
    --text-opacity: 0.84 !important;
  }

  .lt-md\:hover\:text-opacity-100:hover {
    --text-opacity: 1 !important;
  }

  .lt-md\:focus\:text-opacity-0:focus {
    --text-opacity: 0 !important;
  }

  .lt-md\:focus\:text-opacity-12:focus {
    --text-opacity: 0.12 !important;
  }

  .lt-md\:focus\:text-opacity-25:focus {
    --text-opacity: 0.25 !important;
  }

  .lt-md\:focus\:text-opacity-38:focus {
    --text-opacity: 0.38 !important;
  }

  .lt-md\:focus\:text-opacity-50:focus {
    --text-opacity: 0.5 !important;
  }

  .lt-md\:focus\:text-opacity-54:focus {
    --text-opacity: 0.54 !important;
  }

  .lt-md\:focus\:text-opacity-70:focus {
    --text-opacity: 0.70 !important;
  }

  .lt-md\:focus\:text-opacity-75:focus {
    --text-opacity: 0.75 !important;
  }

  .lt-md\:focus\:text-opacity-84:focus {
    --text-opacity: 0.84 !important;
  }

  .lt-md\:focus\:text-opacity-100:focus {
    --text-opacity: 1 !important;
  }

  .lt-md\:italic {
    font-style: italic !important;
  }

  .lt-md\:not-italic {
    font-style: normal !important;
  }

  .lt-md\:uppercase {
    text-transform: uppercase !important;
  }

  .lt-md\:lowercase {
    text-transform: lowercase !important;
  }

  .lt-md\:capitalize {
    text-transform: capitalize !important;
  }

  .lt-md\:normal-case {
    text-transform: none !important;
  }

  .lt-md\:underline {
    text-decoration: underline !important;
  }

  .lt-md\:line-through {
    text-decoration: line-through !important;
  }

  .lt-md\:no-underline {
    text-decoration: none !important;
  }

  .lt-md\:hover\:underline:hover {
    text-decoration: underline !important;
  }

  .lt-md\:hover\:line-through:hover {
    text-decoration: line-through !important;
  }

  .lt-md\:hover\:no-underline:hover {
    text-decoration: none !important;
  }

  .lt-md\:focus\:underline:focus {
    text-decoration: underline !important;
  }

  .lt-md\:focus\:line-through:focus {
    text-decoration: line-through !important;
  }

  .lt-md\:focus\:no-underline:focus {
    text-decoration: none !important;
  }

  .lt-md\:tracking-tighter {
    letter-spacing: -0.05em !important;
  }

  .lt-md\:tracking-tight {
    letter-spacing: -0.025em !important;
  }

  .lt-md\:tracking-normal {
    letter-spacing: 0 !important;
  }

  .lt-md\:tracking-wide {
    letter-spacing: 0.025em !important;
  }

  .lt-md\:tracking-wider {
    letter-spacing: 0.05em !important;
  }

  .lt-md\:tracking-widest {
    letter-spacing: 0.1em !important;
  }

  .lt-md\:select-none {
    -webkit-user-select: none !important;
       -moz-user-select: none !important;
        -ms-user-select: none !important;
            user-select: none !important;
  }

  .lt-md\:select-text {
    -webkit-user-select: text !important;
       -moz-user-select: text !important;
        -ms-user-select: text !important;
            user-select: text !important;
  }

  .lt-md\:select-all {
    -webkit-user-select: all !important;
       -moz-user-select: all !important;
        -ms-user-select: all !important;
            user-select: all !important;
  }

  .lt-md\:select-auto {
    -webkit-user-select: auto !important;
       -moz-user-select: auto !important;
        -ms-user-select: auto !important;
            user-select: auto !important;
  }

  .lt-md\:align-baseline {
    vertical-align: baseline !important;
  }

  .lt-md\:align-top {
    vertical-align: top !important;
  }

  .lt-md\:align-middle {
    vertical-align: middle !important;
  }

  .lt-md\:align-bottom {
    vertical-align: bottom !important;
  }

  .lt-md\:align-text-top {
    vertical-align: text-top !important;
  }

  .lt-md\:align-text-bottom {
    vertical-align: text-bottom !important;
  }

  .lt-md\:visible {
    visibility: visible !important;
  }

  .lt-md\:invisible {
    visibility: hidden !important;
  }

  .lt-md\:whitespace-normal {
    white-space: normal !important;
  }

  .lt-md\:whitespace-nowrap {
    white-space: nowrap !important;
  }

  .lt-md\:whitespace-pre {
    white-space: pre !important;
  }

  .lt-md\:whitespace-pre-line {
    white-space: pre-line !important;
  }

  .lt-md\:whitespace-pre-wrap {
    white-space: pre-wrap !important;
  }

  .lt-md\:break-normal {
    overflow-wrap: normal !important;
    word-break: normal !important;
  }

  .lt-md\:break-words {
    overflow-wrap: break-word !important;
  }

  .lt-md\:break-all {
    word-break: break-all !important;
  }

  .lt-md\:truncate {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
  }

  .lt-md\:w-0 {
    width: 0 !important;
  }

  .lt-md\:w-1 {
    width: 0.25rem !important;
  }

  .lt-md\:w-2 {
    width: 0.5rem !important;
  }

  .lt-md\:w-3 {
    width: 0.75rem !important;
  }

  .lt-md\:w-4 {
    width: 1rem !important;
  }

  .lt-md\:w-5 {
    width: 1.25rem !important;
  }

  .lt-md\:w-6 {
    width: 1.5rem !important;
  }

  .lt-md\:w-8 {
    width: 2rem !important;
  }

  .lt-md\:w-10 {
    width: 2.5rem !important;
  }

  .lt-md\:w-12 {
    width: 3rem !important;
  }

  .lt-md\:w-14 {
    width: 3.5rem !important;
  }

  .lt-md\:w-16 {
    width: 4rem !important;
  }

  .lt-md\:w-18 {
    width: 4.5rem !important;
  }

  .lt-md\:w-20 {
    width: 5rem !important;
  }

  .lt-md\:w-22 {
    width: 5.5rem !important;
  }

  .lt-md\:w-24 {
    width: 6rem !important;
  }

  .lt-md\:w-26 {
    width: 6.5rem !important;
  }

  .lt-md\:w-28 {
    width: 7rem !important;
  }

  .lt-md\:w-30 {
    width: 7.5rem !important;
  }

  .lt-md\:w-32 {
    width: 8rem !important;
  }

  .lt-md\:w-36 {
    width: 9rem !important;
  }

  .lt-md\:w-40 {
    width: 10rem !important;
  }

  .lt-md\:w-48 {
    width: 12rem !important;
  }

  .lt-md\:w-50 {
    width: 12.5rem !important;
  }

  .lt-md\:w-56 {
    width: 14rem !important;
  }

  .lt-md\:w-60 {
    width: 15rem !important;
  }

  .lt-md\:w-64 {
    width: 16rem !important;
  }

  .lt-md\:w-80 {
    width: 20rem !important;
  }

  .lt-md\:w-90 {
    width: 24rem !important;
  }

  .lt-md\:w-100 {
    width: 25rem !important;
  }

  .lt-md\:w-120 {
    width: 30rem !important;
  }

  .lt-md\:w-128 {
    width: 32rem !important;
  }

  .lt-md\:w-140 {
    width: 35rem !important;
  }

  .lt-md\:w-160 {
    width: 40rem !important;
  }

  .lt-md\:w-180 {
    width: 45rem !important;
  }

  .lt-md\:w-192 {
    width: 48rem !important;
  }

  .lt-md\:w-200 {
    width: 50rem !important;
  }

  .lt-md\:w-240 {
    width: 60rem !important;
  }

  .lt-md\:w-256 {
    width: 64rem !important;
  }

  .lt-md\:w-280 {
    width: 70rem !important;
  }

  .lt-md\:w-320 {
    width: 80rem !important;
  }

  .lt-md\:w-360 {
    width: 90rem !important;
  }

  .lt-md\:w-400 {
    width: 100rem !important;
  }

  .lt-md\:w-480 {
    width: 120rem !important;
  }

  .lt-md\:w-auto {
    width: auto !important;
  }

  .lt-md\:w-px {
    width: 1px !important;
  }

  .lt-md\:w-2px {
    width: 2px !important;
  }

  .lt-md\:w-1\/2 {
    width: 50% !important;
  }

  .lt-md\:w-1\/3 {
    width: 33.33333% !important;
  }

  .lt-md\:w-2\/3 {
    width: 66.66667% !important;
  }

  .lt-md\:w-1\/4 {
    width: 25% !important;
  }

  .lt-md\:w-2\/4 {
    width: 50% !important;
  }

  .lt-md\:w-3\/4 {
    width: 75% !important;
  }

  .lt-md\:w-1\/5 {
    width: 20% !important;
  }

  .lt-md\:w-2\/5 {
    width: 40% !important;
  }

  .lt-md\:w-3\/5 {
    width: 60% !important;
  }

  .lt-md\:w-4\/5 {
    width: 80% !important;
  }

  .lt-md\:w-1\/6 {
    width: 16.666667% !important;
  }

  .lt-md\:w-2\/6 {
    width: 33.333333% !important;
  }

  .lt-md\:w-3\/6 {
    width: 50% !important;
  }

  .lt-md\:w-4\/6 {
    width: 66.666667% !important;
  }

  .lt-md\:w-5\/6 {
    width: 83.333333% !important;
  }

  .lt-md\:w-1\/12 {
    width: 8.33333% !important;
  }

  .lt-md\:w-2\/12 {
    width: 16.66667% !important;
  }

  .lt-md\:w-3\/12 {
    width: 25% !important;
  }

  .lt-md\:w-4\/12 {
    width: 33.33333% !important;
  }

  .lt-md\:w-5\/12 {
    width: 41.66667% !important;
  }

  .lt-md\:w-6\/12 {
    width: 50% !important;
  }

  .lt-md\:w-7\/12 {
    width: 58.33333% !important;
  }

  .lt-md\:w-8\/12 {
    width: 66.66667% !important;
  }

  .lt-md\:w-9\/12 {
    width: 75% !important;
  }

  .lt-md\:w-10\/12 {
    width: 83.33333% !important;
  }

  .lt-md\:w-11\/12 {
    width: 91.66667% !important;
  }

  .lt-md\:w-full {
    width: 100% !important;
  }

  .lt-md\:w-screen {
    width: 100vw !important;
  }

  .lt-md\:z-0 {
    z-index: 0 !important;
  }

  .lt-md\:z-10 {
    z-index: 10 !important;
  }

  .lt-md\:z-20 {
    z-index: 20 !important;
  }

  .lt-md\:z-30 {
    z-index: 30 !important;
  }

  .lt-md\:z-40 {
    z-index: 40 !important;
  }

  .lt-md\:z-50 {
    z-index: 50 !important;
  }

  .lt-md\:z-60 {
    z-index: 60 !important;
  }

  .lt-md\:z-70 {
    z-index: 70 !important;
  }

  .lt-md\:z-80 {
    z-index: 80 !important;
  }

  .lt-md\:z-90 {
    z-index: 90 !important;
  }

  .lt-md\:z-99 {
    z-index: 99 !important;
  }

  .lt-md\:z-999 {
    z-index: 999 !important;
  }

  .lt-md\:z-9999 {
    z-index: 9999 !important;
  }

  .lt-md\:z-99999 {
    z-index: 99999 !important;
  }

  .lt-md\:z-auto {
    z-index: auto !important;
  }

  .lt-md\:-z-1 {
    z-index: -1 !important;
  }

  .lt-md\:gap-0 {
    grid-gap: 0 !important;
    gap: 0 !important;
  }

  .lt-md\:gap-1 {
    grid-gap: 0.25rem !important;
    gap: 0.25rem !important;
  }

  .lt-md\:gap-2 {
    grid-gap: 0.5rem !important;
    gap: 0.5rem !important;
  }

  .lt-md\:gap-3 {
    grid-gap: 0.75rem !important;
    gap: 0.75rem !important;
  }

  .lt-md\:gap-4 {
    grid-gap: 1rem !important;
    gap: 1rem !important;
  }

  .lt-md\:gap-5 {
    grid-gap: 1.25rem !important;
    gap: 1.25rem !important;
  }

  .lt-md\:gap-6 {
    grid-gap: 1.5rem !important;
    gap: 1.5rem !important;
  }

  .lt-md\:gap-8 {
    grid-gap: 2rem !important;
    gap: 2rem !important;
  }

  .lt-md\:gap-10 {
    grid-gap: 2.5rem !important;
    gap: 2.5rem !important;
  }

  .lt-md\:gap-12 {
    grid-gap: 3rem !important;
    gap: 3rem !important;
  }

  .lt-md\:gap-14 {
    grid-gap: 3.5rem !important;
    gap: 3.5rem !important;
  }

  .lt-md\:gap-16 {
    grid-gap: 4rem !important;
    gap: 4rem !important;
  }

  .lt-md\:gap-18 {
    grid-gap: 4.5rem !important;
    gap: 4.5rem !important;
  }

  .lt-md\:gap-20 {
    grid-gap: 5rem !important;
    gap: 5rem !important;
  }

  .lt-md\:gap-22 {
    grid-gap: 5.5rem !important;
    gap: 5.5rem !important;
  }

  .lt-md\:gap-24 {
    grid-gap: 6rem !important;
    gap: 6rem !important;
  }

  .lt-md\:gap-26 {
    grid-gap: 6.5rem !important;
    gap: 6.5rem !important;
  }

  .lt-md\:gap-28 {
    grid-gap: 7rem !important;
    gap: 7rem !important;
  }

  .lt-md\:gap-30 {
    grid-gap: 7.5rem !important;
    gap: 7.5rem !important;
  }

  .lt-md\:gap-32 {
    grid-gap: 8rem !important;
    gap: 8rem !important;
  }

  .lt-md\:gap-36 {
    grid-gap: 9rem !important;
    gap: 9rem !important;
  }

  .lt-md\:gap-40 {
    grid-gap: 10rem !important;
    gap: 10rem !important;
  }

  .lt-md\:gap-48 {
    grid-gap: 12rem !important;
    gap: 12rem !important;
  }

  .lt-md\:gap-56 {
    grid-gap: 14rem !important;
    gap: 14rem !important;
  }

  .lt-md\:gap-64 {
    grid-gap: 16rem !important;
    gap: 16rem !important;
  }

  .lt-md\:gap-px {
    grid-gap: 1px !important;
    gap: 1px !important;
  }

  .lt-md\:gap-2px {
    grid-gap: 2px !important;
    gap: 2px !important;
  }

  .lt-md\:gap-x-0 {
    grid-column-gap: 0 !important;
    -moz-column-gap: 0 !important;
         column-gap: 0 !important;
  }

  .lt-md\:gap-x-1 {
    grid-column-gap: 0.25rem !important;
    -moz-column-gap: 0.25rem !important;
         column-gap: 0.25rem !important;
  }

  .lt-md\:gap-x-2 {
    grid-column-gap: 0.5rem !important;
    -moz-column-gap: 0.5rem !important;
         column-gap: 0.5rem !important;
  }

  .lt-md\:gap-x-3 {
    grid-column-gap: 0.75rem !important;
    -moz-column-gap: 0.75rem !important;
         column-gap: 0.75rem !important;
  }

  .lt-md\:gap-x-4 {
    grid-column-gap: 1rem !important;
    -moz-column-gap: 1rem !important;
         column-gap: 1rem !important;
  }

  .lt-md\:gap-x-5 {
    grid-column-gap: 1.25rem !important;
    -moz-column-gap: 1.25rem !important;
         column-gap: 1.25rem !important;
  }

  .lt-md\:gap-x-6 {
    grid-column-gap: 1.5rem !important;
    -moz-column-gap: 1.5rem !important;
         column-gap: 1.5rem !important;
  }

  .lt-md\:gap-x-8 {
    grid-column-gap: 2rem !important;
    -moz-column-gap: 2rem !important;
         column-gap: 2rem !important;
  }

  .lt-md\:gap-x-10 {
    grid-column-gap: 2.5rem !important;
    -moz-column-gap: 2.5rem !important;
         column-gap: 2.5rem !important;
  }

  .lt-md\:gap-x-12 {
    grid-column-gap: 3rem !important;
    -moz-column-gap: 3rem !important;
         column-gap: 3rem !important;
  }

  .lt-md\:gap-x-14 {
    grid-column-gap: 3.5rem !important;
    -moz-column-gap: 3.5rem !important;
         column-gap: 3.5rem !important;
  }

  .lt-md\:gap-x-16 {
    grid-column-gap: 4rem !important;
    -moz-column-gap: 4rem !important;
         column-gap: 4rem !important;
  }

  .lt-md\:gap-x-18 {
    grid-column-gap: 4.5rem !important;
    -moz-column-gap: 4.5rem !important;
         column-gap: 4.5rem !important;
  }

  .lt-md\:gap-x-20 {
    grid-column-gap: 5rem !important;
    -moz-column-gap: 5rem !important;
         column-gap: 5rem !important;
  }

  .lt-md\:gap-x-22 {
    grid-column-gap: 5.5rem !important;
    -moz-column-gap: 5.5rem !important;
         column-gap: 5.5rem !important;
  }

  .lt-md\:gap-x-24 {
    grid-column-gap: 6rem !important;
    -moz-column-gap: 6rem !important;
         column-gap: 6rem !important;
  }

  .lt-md\:gap-x-26 {
    grid-column-gap: 6.5rem !important;
    -moz-column-gap: 6.5rem !important;
         column-gap: 6.5rem !important;
  }

  .lt-md\:gap-x-28 {
    grid-column-gap: 7rem !important;
    -moz-column-gap: 7rem !important;
         column-gap: 7rem !important;
  }

  .lt-md\:gap-x-30 {
    grid-column-gap: 7.5rem !important;
    -moz-column-gap: 7.5rem !important;
         column-gap: 7.5rem !important;
  }

  .lt-md\:gap-x-32 {
    grid-column-gap: 8rem !important;
    -moz-column-gap: 8rem !important;
         column-gap: 8rem !important;
  }

  .lt-md\:gap-x-36 {
    grid-column-gap: 9rem !important;
    -moz-column-gap: 9rem !important;
         column-gap: 9rem !important;
  }

  .lt-md\:gap-x-40 {
    grid-column-gap: 10rem !important;
    -moz-column-gap: 10rem !important;
         column-gap: 10rem !important;
  }

  .lt-md\:gap-x-48 {
    grid-column-gap: 12rem !important;
    -moz-column-gap: 12rem !important;
         column-gap: 12rem !important;
  }

  .lt-md\:gap-x-56 {
    grid-column-gap: 14rem !important;
    -moz-column-gap: 14rem !important;
         column-gap: 14rem !important;
  }

  .lt-md\:gap-x-64 {
    grid-column-gap: 16rem !important;
    -moz-column-gap: 16rem !important;
         column-gap: 16rem !important;
  }

  .lt-md\:gap-x-px {
    grid-column-gap: 1px !important;
    -moz-column-gap: 1px !important;
         column-gap: 1px !important;
  }

  .lt-md\:gap-x-2px {
    grid-column-gap: 2px !important;
    -moz-column-gap: 2px !important;
         column-gap: 2px !important;
  }

  .lt-md\:gap-y-0 {
    grid-row-gap: 0 !important;
    row-gap: 0 !important;
  }

  .lt-md\:gap-y-1 {
    grid-row-gap: 0.25rem !important;
    row-gap: 0.25rem !important;
  }

  .lt-md\:gap-y-2 {
    grid-row-gap: 0.5rem !important;
    row-gap: 0.5rem !important;
  }

  .lt-md\:gap-y-3 {
    grid-row-gap: 0.75rem !important;
    row-gap: 0.75rem !important;
  }

  .lt-md\:gap-y-4 {
    grid-row-gap: 1rem !important;
    row-gap: 1rem !important;
  }

  .lt-md\:gap-y-5 {
    grid-row-gap: 1.25rem !important;
    row-gap: 1.25rem !important;
  }

  .lt-md\:gap-y-6 {
    grid-row-gap: 1.5rem !important;
    row-gap: 1.5rem !important;
  }

  .lt-md\:gap-y-8 {
    grid-row-gap: 2rem !important;
    row-gap: 2rem !important;
  }

  .lt-md\:gap-y-10 {
    grid-row-gap: 2.5rem !important;
    row-gap: 2.5rem !important;
  }

  .lt-md\:gap-y-12 {
    grid-row-gap: 3rem !important;
    row-gap: 3rem !important;
  }

  .lt-md\:gap-y-14 {
    grid-row-gap: 3.5rem !important;
    row-gap: 3.5rem !important;
  }

  .lt-md\:gap-y-16 {
    grid-row-gap: 4rem !important;
    row-gap: 4rem !important;
  }

  .lt-md\:gap-y-18 {
    grid-row-gap: 4.5rem !important;
    row-gap: 4.5rem !important;
  }

  .lt-md\:gap-y-20 {
    grid-row-gap: 5rem !important;
    row-gap: 5rem !important;
  }

  .lt-md\:gap-y-22 {
    grid-row-gap: 5.5rem !important;
    row-gap: 5.5rem !important;
  }

  .lt-md\:gap-y-24 {
    grid-row-gap: 6rem !important;
    row-gap: 6rem !important;
  }

  .lt-md\:gap-y-26 {
    grid-row-gap: 6.5rem !important;
    row-gap: 6.5rem !important;
  }

  .lt-md\:gap-y-28 {
    grid-row-gap: 7rem !important;
    row-gap: 7rem !important;
  }

  .lt-md\:gap-y-30 {
    grid-row-gap: 7.5rem !important;
    row-gap: 7.5rem !important;
  }

  .lt-md\:gap-y-32 {
    grid-row-gap: 8rem !important;
    row-gap: 8rem !important;
  }

  .lt-md\:gap-y-36 {
    grid-row-gap: 9rem !important;
    row-gap: 9rem !important;
  }

  .lt-md\:gap-y-40 {
    grid-row-gap: 10rem !important;
    row-gap: 10rem !important;
  }

  .lt-md\:gap-y-48 {
    grid-row-gap: 12rem !important;
    row-gap: 12rem !important;
  }

  .lt-md\:gap-y-56 {
    grid-row-gap: 14rem !important;
    row-gap: 14rem !important;
  }

  .lt-md\:gap-y-64 {
    grid-row-gap: 16rem !important;
    row-gap: 16rem !important;
  }

  .lt-md\:gap-y-px {
    grid-row-gap: 1px !important;
    row-gap: 1px !important;
  }

  .lt-md\:gap-y-2px {
    grid-row-gap: 2px !important;
    row-gap: 2px !important;
  }

  .lt-md\:grid-flow-row {
    grid-auto-flow: row !important;
  }

  .lt-md\:grid-flow-col {
    grid-auto-flow: column !important;
  }

  .lt-md\:grid-flow-row-dense {
    grid-auto-flow: row dense !important;
  }

  .lt-md\:grid-flow-col-dense {
    grid-auto-flow: column dense !important;
  }

  .lt-md\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }

  .lt-md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }

  .lt-md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
  }

  .lt-md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
  }

  .lt-md\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr)) !important;
  }

  .lt-md\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr)) !important;
  }

  .lt-md\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr)) !important;
  }

  .lt-md\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr)) !important;
  }

  .lt-md\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr)) !important;
  }

  .lt-md\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr)) !important;
  }

  .lt-md\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr)) !important;
  }

  .lt-md\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr)) !important;
  }

  .lt-md\:grid-cols-none {
    grid-template-columns: none !important;
  }

  .lt-md\:col-auto {
    grid-column: auto !important;
  }

  .lt-md\:col-span-1 {
    grid-column: span 1 / span 1 !important;
  }

  .lt-md\:col-span-2 {
    grid-column: span 2 / span 2 !important;
  }

  .lt-md\:col-span-3 {
    grid-column: span 3 / span 3 !important;
  }

  .lt-md\:col-span-4 {
    grid-column: span 4 / span 4 !important;
  }

  .lt-md\:col-span-5 {
    grid-column: span 5 / span 5 !important;
  }

  .lt-md\:col-span-6 {
    grid-column: span 6 / span 6 !important;
  }

  .lt-md\:col-span-7 {
    grid-column: span 7 / span 7 !important;
  }

  .lt-md\:col-span-8 {
    grid-column: span 8 / span 8 !important;
  }

  .lt-md\:col-span-9 {
    grid-column: span 9 / span 9 !important;
  }

  .lt-md\:col-span-10 {
    grid-column: span 10 / span 10 !important;
  }

  .lt-md\:col-span-11 {
    grid-column: span 11 / span 11 !important;
  }

  .lt-md\:col-span-12 {
    grid-column: span 12 / span 12 !important;
  }

  .lt-md\:col-start-1 {
    grid-column-start: 1 !important;
  }

  .lt-md\:col-start-2 {
    grid-column-start: 2 !important;
  }

  .lt-md\:col-start-3 {
    grid-column-start: 3 !important;
  }

  .lt-md\:col-start-4 {
    grid-column-start: 4 !important;
  }

  .lt-md\:col-start-5 {
    grid-column-start: 5 !important;
  }

  .lt-md\:col-start-6 {
    grid-column-start: 6 !important;
  }

  .lt-md\:col-start-7 {
    grid-column-start: 7 !important;
  }

  .lt-md\:col-start-8 {
    grid-column-start: 8 !important;
  }

  .lt-md\:col-start-9 {
    grid-column-start: 9 !important;
  }

  .lt-md\:col-start-10 {
    grid-column-start: 10 !important;
  }

  .lt-md\:col-start-11 {
    grid-column-start: 11 !important;
  }

  .lt-md\:col-start-12 {
    grid-column-start: 12 !important;
  }

  .lt-md\:col-start-13 {
    grid-column-start: 13 !important;
  }

  .lt-md\:col-start-auto {
    grid-column-start: auto !important;
  }

  .lt-md\:col-end-1 {
    grid-column-end: 1 !important;
  }

  .lt-md\:col-end-2 {
    grid-column-end: 2 !important;
  }

  .lt-md\:col-end-3 {
    grid-column-end: 3 !important;
  }

  .lt-md\:col-end-4 {
    grid-column-end: 4 !important;
  }

  .lt-md\:col-end-5 {
    grid-column-end: 5 !important;
  }

  .lt-md\:col-end-6 {
    grid-column-end: 6 !important;
  }

  .lt-md\:col-end-7 {
    grid-column-end: 7 !important;
  }

  .lt-md\:col-end-8 {
    grid-column-end: 8 !important;
  }

  .lt-md\:col-end-9 {
    grid-column-end: 9 !important;
  }

  .lt-md\:col-end-10 {
    grid-column-end: 10 !important;
  }

  .lt-md\:col-end-11 {
    grid-column-end: 11 !important;
  }

  .lt-md\:col-end-12 {
    grid-column-end: 12 !important;
  }

  .lt-md\:col-end-13 {
    grid-column-end: 13 !important;
  }

  .lt-md\:col-end-auto {
    grid-column-end: auto !important;
  }

  .lt-md\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr)) !important;
  }

  .lt-md\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr)) !important;
  }

  .lt-md\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr)) !important;
  }

  .lt-md\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr)) !important;
  }

  .lt-md\:grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr)) !important;
  }

  .lt-md\:grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr)) !important;
  }

  .lt-md\:grid-rows-none {
    grid-template-rows: none !important;
  }

  .lt-md\:row-auto {
    grid-row: auto !important;
  }

  .lt-md\:row-span-1 {
    grid-row: span 1 / span 1 !important;
  }

  .lt-md\:row-span-2 {
    grid-row: span 2 / span 2 !important;
  }

  .lt-md\:row-span-3 {
    grid-row: span 3 / span 3 !important;
  }

  .lt-md\:row-span-4 {
    grid-row: span 4 / span 4 !important;
  }

  .lt-md\:row-span-5 {
    grid-row: span 5 / span 5 !important;
  }

  .lt-md\:row-span-6 {
    grid-row: span 6 / span 6 !important;
  }

  .lt-md\:row-start-1 {
    grid-row-start: 1 !important;
  }

  .lt-md\:row-start-2 {
    grid-row-start: 2 !important;
  }

  .lt-md\:row-start-3 {
    grid-row-start: 3 !important;
  }

  .lt-md\:row-start-4 {
    grid-row-start: 4 !important;
  }

  .lt-md\:row-start-5 {
    grid-row-start: 5 !important;
  }

  .lt-md\:row-start-6 {
    grid-row-start: 6 !important;
  }

  .lt-md\:row-start-7 {
    grid-row-start: 7 !important;
  }

  .lt-md\:row-start-auto {
    grid-row-start: auto !important;
  }

  .lt-md\:row-end-1 {
    grid-row-end: 1 !important;
  }

  .lt-md\:row-end-2 {
    grid-row-end: 2 !important;
  }

  .lt-md\:row-end-3 {
    grid-row-end: 3 !important;
  }

  .lt-md\:row-end-4 {
    grid-row-end: 4 !important;
  }

  .lt-md\:row-end-5 {
    grid-row-end: 5 !important;
  }

  .lt-md\:row-end-6 {
    grid-row-end: 6 !important;
  }

  .lt-md\:row-end-7 {
    grid-row-end: 7 !important;
  }

  .lt-md\:row-end-auto {
    grid-row-end: auto !important;
  }

  .lt-md\:transform {
    --transform-translate-x: 0 !important;
    --transform-translate-y: 0 !important;
    --transform-rotate: 0 !important;
    --transform-skew-x: 0 !important;
    --transform-skew-y: 0 !important;
    --transform-scale-x: 1 !important;
    --transform-scale-y: 1 !important;
    transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y)) !important;
  }

  .lt-md\:transform-none {
    transform: none !important;
  }

  .lt-md\:origin-center {
    transform-origin: center !important;
  }

  .lt-md\:origin-top {
    transform-origin: top !important;
  }

  .lt-md\:origin-top-right {
    transform-origin: top right !important;
  }

  .lt-md\:origin-right {
    transform-origin: right !important;
  }

  .lt-md\:origin-bottom-right {
    transform-origin: bottom right !important;
  }

  .lt-md\:origin-bottom {
    transform-origin: bottom !important;
  }

  .lt-md\:origin-bottom-left {
    transform-origin: bottom left !important;
  }

  .lt-md\:origin-left {
    transform-origin: left !important;
  }

  .lt-md\:origin-top-left {
    transform-origin: top left !important;
  }

  .lt-md\:icon-size-12 {
    width: 12px !important;
    height: 12px !important;
    min-width: 12px !important;
    min-height: 12px !important;
    font-size: 12px !important;
    line-height: 12px !important;
  }

  .lt-md\:icon-size-12 svg {
    width: 12px !important;
    height: 12px !important;
  }

  .lt-md\:icon-size-14 {
    width: 14px !important;
    height: 14px !important;
    min-width: 14px !important;
    min-height: 14px !important;
    font-size: 14px !important;
    line-height: 14px !important;
  }

  .lt-md\:icon-size-14 svg {
    width: 14px !important;
    height: 14px !important;
  }

  .lt-md\:icon-size-16 {
    width: 16px !important;
    height: 16px !important;
    min-width: 16px !important;
    min-height: 16px !important;
    font-size: 16px !important;
    line-height: 16px !important;
  }

  .lt-md\:icon-size-16 svg {
    width: 16px !important;
    height: 16px !important;
  }

  .lt-md\:icon-size-18 {
    width: 18px !important;
    height: 18px !important;
    min-width: 18px !important;
    min-height: 18px !important;
    font-size: 18px !important;
    line-height: 18px !important;
  }

  .lt-md\:icon-size-18 svg {
    width: 18px !important;
    height: 18px !important;
  }

  .lt-md\:icon-size-20 {
    width: 20px !important;
    height: 20px !important;
    min-width: 20px !important;
    min-height: 20px !important;
    font-size: 20px !important;
    line-height: 20px !important;
  }

  .lt-md\:icon-size-20 svg {
    width: 20px !important;
    height: 20px !important;
  }

  .lt-md\:icon-size-24 {
    width: 24px !important;
    height: 24px !important;
    min-width: 24px !important;
    min-height: 24px !important;
    font-size: 24px !important;
    line-height: 24px !important;
  }

  .lt-md\:icon-size-24 svg {
    width: 24px !important;
    height: 24px !important;
  }

  .lt-md\:icon-size-32 {
    width: 32px !important;
    height: 32px !important;
    min-width: 32px !important;
    min-height: 32px !important;
    font-size: 32px !important;
    line-height: 32px !important;
  }

  .lt-md\:icon-size-32 svg {
    width: 32px !important;
    height: 32px !important;
  }

  .lt-md\:icon-size-40 {
    width: 40px !important;
    height: 40px !important;
    min-width: 40px !important;
    min-height: 40px !important;
    font-size: 40px !important;
    line-height: 40px !important;
  }

  .lt-md\:icon-size-40 svg {
    width: 40px !important;
    height: 40px !important;
  }

  .lt-md\:icon-size-48 {
    width: 48px !important;
    height: 48px !important;
    min-width: 48px !important;
    min-height: 48px !important;
    font-size: 48px !important;
    line-height: 48px !important;
  }

  .lt-md\:icon-size-48 svg {
    width: 48px !important;
    height: 48px !important;
  }

  .lt-md\:icon-size-56 {
    width: 56px !important;
    height: 56px !important;
    min-width: 56px !important;
    min-height: 56px !important;
    font-size: 56px !important;
    line-height: 56px !important;
  }

  .lt-md\:icon-size-56 svg {
    width: 56px !important;
    height: 56px !important;
  }

  .lt-md\:icon-size-64 {
    width: 64px !important;
    height: 64px !important;
    min-width: 64px !important;
    min-height: 64px !important;
    font-size: 64px !important;
    line-height: 64px !important;
  }

  .lt-md\:icon-size-64 svg {
    width: 64px !important;
    height: 64px !important;
  }

  .lt-md\:icon-size-72 {
    width: 72px !important;
    height: 72px !important;
    min-width: 72px !important;
    min-height: 72px !important;
    font-size: 72px !important;
    line-height: 72px !important;
  }

  .lt-md\:icon-size-72 svg {
    width: 72px !important;
    height: 72px !important;
  }

  .lt-md\:icon-size-80 {
    width: 80px !important;
    height: 80px !important;
    min-width: 80px !important;
    min-height: 80px !important;
    font-size: 80px !important;
    line-height: 80px !important;
  }

  .lt-md\:icon-size-80 svg {
    width: 80px !important;
    height: 80px !important;
  }

  .lt-md\:icon-size-88 {
    width: 88px !important;
    height: 88px !important;
    min-width: 88px !important;
    min-height: 88px !important;
    font-size: 88px !important;
    line-height: 88px !important;
  }

  .lt-md\:icon-size-88 svg {
    width: 88px !important;
    height: 88px !important;
  }

  .lt-md\:icon-size-96 {
    width: 96px !important;
    height: 96px !important;
    min-width: 96px !important;
    min-height: 96px !important;
    font-size: 96px !important;
    line-height: 96px !important;
  }

  .lt-md\:icon-size-96 svg {
    width: 96px !important;
    height: 96px !important;
  }
}

@media (max-width: 1279px) {
  .lt-lg\:space-y-0 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(0px * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(0px * var(--space-y-reverse)) !important;
  }

  .lt-lg\:space-x-0 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(0px * var(--space-x-reverse)) !important;
    margin-left: calc(0px * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-lg\:space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(0.25rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(0.25rem * var(--space-y-reverse)) !important;
  }

  .lt-lg\:space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(0.25rem * var(--space-x-reverse)) !important;
    margin-left: calc(0.25rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-lg\:space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(0.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(0.5rem * var(--space-y-reverse)) !important;
  }

  .lt-lg\:space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(0.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(0.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-lg\:space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(0.75rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(0.75rem * var(--space-y-reverse)) !important;
  }

  .lt-lg\:space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(0.75rem * var(--space-x-reverse)) !important;
    margin-left: calc(0.75rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-lg\:space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(1rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(1rem * var(--space-y-reverse)) !important;
  }

  .lt-lg\:space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(1rem * var(--space-x-reverse)) !important;
    margin-left: calc(1rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-lg\:space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(1.25rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(1.25rem * var(--space-y-reverse)) !important;
  }

  .lt-lg\:space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(1.25rem * var(--space-x-reverse)) !important;
    margin-left: calc(1.25rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-lg\:space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(1.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(1.5rem * var(--space-y-reverse)) !important;
  }

  .lt-lg\:space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(1.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(1.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-lg\:space-y-8 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(2rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(2rem * var(--space-y-reverse)) !important;
  }

  .lt-lg\:space-x-8 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(2rem * var(--space-x-reverse)) !important;
    margin-left: calc(2rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-lg\:space-y-10 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(2.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(2.5rem * var(--space-y-reverse)) !important;
  }

  .lt-lg\:space-x-10 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(2.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(2.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-lg\:space-y-12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(3rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(3rem * var(--space-y-reverse)) !important;
  }

  .lt-lg\:space-x-12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(3rem * var(--space-x-reverse)) !important;
    margin-left: calc(3rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-lg\:space-y-14 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(3.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(3.5rem * var(--space-y-reverse)) !important;
  }

  .lt-lg\:space-x-14 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(3.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(3.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-lg\:space-y-16 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(4rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(4rem * var(--space-y-reverse)) !important;
  }

  .lt-lg\:space-x-16 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(4rem * var(--space-x-reverse)) !important;
    margin-left: calc(4rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-lg\:space-y-18 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(4.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(4.5rem * var(--space-y-reverse)) !important;
  }

  .lt-lg\:space-x-18 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(4.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(4.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-lg\:space-y-20 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(5rem * var(--space-y-reverse)) !important;
  }

  .lt-lg\:space-x-20 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(5rem * var(--space-x-reverse)) !important;
    margin-left: calc(5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-lg\:space-y-22 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(5.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(5.5rem * var(--space-y-reverse)) !important;
  }

  .lt-lg\:space-x-22 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(5.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(5.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-lg\:space-y-24 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(6rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(6rem * var(--space-y-reverse)) !important;
  }

  .lt-lg\:space-x-24 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(6rem * var(--space-x-reverse)) !important;
    margin-left: calc(6rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-lg\:space-y-26 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(6.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(6.5rem * var(--space-y-reverse)) !important;
  }

  .lt-lg\:space-x-26 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(6.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(6.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-lg\:space-y-28 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(7rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(7rem * var(--space-y-reverse)) !important;
  }

  .lt-lg\:space-x-28 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(7rem * var(--space-x-reverse)) !important;
    margin-left: calc(7rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-lg\:space-y-30 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(7.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(7.5rem * var(--space-y-reverse)) !important;
  }

  .lt-lg\:space-x-30 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(7.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(7.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-lg\:space-y-32 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(8rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(8rem * var(--space-y-reverse)) !important;
  }

  .lt-lg\:space-x-32 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(8rem * var(--space-x-reverse)) !important;
    margin-left: calc(8rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-lg\:space-y-36 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(9rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(9rem * var(--space-y-reverse)) !important;
  }

  .lt-lg\:space-x-36 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(9rem * var(--space-x-reverse)) !important;
    margin-left: calc(9rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-lg\:space-y-40 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(10rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(10rem * var(--space-y-reverse)) !important;
  }

  .lt-lg\:space-x-40 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(10rem * var(--space-x-reverse)) !important;
    margin-left: calc(10rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-lg\:space-y-48 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(12rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(12rem * var(--space-y-reverse)) !important;
  }

  .lt-lg\:space-x-48 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(12rem * var(--space-x-reverse)) !important;
    margin-left: calc(12rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-lg\:space-y-56 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(14rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(14rem * var(--space-y-reverse)) !important;
  }

  .lt-lg\:space-x-56 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(14rem * var(--space-x-reverse)) !important;
    margin-left: calc(14rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-lg\:space-y-64 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(16rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(16rem * var(--space-y-reverse)) !important;
  }

  .lt-lg\:space-x-64 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(16rem * var(--space-x-reverse)) !important;
    margin-left: calc(16rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-lg\:space-y-px > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(1px * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(1px * var(--space-y-reverse)) !important;
  }

  .lt-lg\:space-x-px > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(1px * var(--space-x-reverse)) !important;
    margin-left: calc(1px * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-lg\:space-y-2px > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(2px * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(2px * var(--space-y-reverse)) !important;
  }

  .lt-lg\:space-x-2px > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(2px * var(--space-x-reverse)) !important;
    margin-left: calc(2px * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-lg\:-space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-0.25rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-0.25rem * var(--space-y-reverse)) !important;
  }

  .lt-lg\:-space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-0.25rem * var(--space-x-reverse)) !important;
    margin-left: calc(-0.25rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-lg\:-space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-0.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-0.5rem * var(--space-y-reverse)) !important;
  }

  .lt-lg\:-space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-0.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-0.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-lg\:-space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-0.75rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-0.75rem * var(--space-y-reverse)) !important;
  }

  .lt-lg\:-space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-0.75rem * var(--space-x-reverse)) !important;
    margin-left: calc(-0.75rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-lg\:-space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-1rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-1rem * var(--space-y-reverse)) !important;
  }

  .lt-lg\:-space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-1rem * var(--space-x-reverse)) !important;
    margin-left: calc(-1rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-lg\:-space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-1.25rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-1.25rem * var(--space-y-reverse)) !important;
  }

  .lt-lg\:-space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-1.25rem * var(--space-x-reverse)) !important;
    margin-left: calc(-1.25rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-lg\:-space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-1.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-1.5rem * var(--space-y-reverse)) !important;
  }

  .lt-lg\:-space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-1.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-1.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-lg\:-space-y-8 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-2rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-2rem * var(--space-y-reverse)) !important;
  }

  .lt-lg\:-space-x-8 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-2rem * var(--space-x-reverse)) !important;
    margin-left: calc(-2rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-lg\:-space-y-10 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-2.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-2.5rem * var(--space-y-reverse)) !important;
  }

  .lt-lg\:-space-x-10 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-2.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-2.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-lg\:-space-y-12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-3rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-3rem * var(--space-y-reverse)) !important;
  }

  .lt-lg\:-space-x-12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-3rem * var(--space-x-reverse)) !important;
    margin-left: calc(-3rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-lg\:-space-y-14 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-3.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-3.5rem * var(--space-y-reverse)) !important;
  }

  .lt-lg\:-space-x-14 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-3.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-3.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-lg\:-space-y-16 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-4rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-4rem * var(--space-y-reverse)) !important;
  }

  .lt-lg\:-space-x-16 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-4rem * var(--space-x-reverse)) !important;
    margin-left: calc(-4rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-lg\:-space-y-18 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-4.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-4.5rem * var(--space-y-reverse)) !important;
  }

  .lt-lg\:-space-x-18 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-4.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-4.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-lg\:-space-y-20 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-5rem * var(--space-y-reverse)) !important;
  }

  .lt-lg\:-space-x-20 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-lg\:-space-y-22 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-5.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-5.5rem * var(--space-y-reverse)) !important;
  }

  .lt-lg\:-space-x-22 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-5.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-5.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-lg\:-space-y-24 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-6rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-6rem * var(--space-y-reverse)) !important;
  }

  .lt-lg\:-space-x-24 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-6rem * var(--space-x-reverse)) !important;
    margin-left: calc(-6rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-lg\:-space-y-26 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-6.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-6.5rem * var(--space-y-reverse)) !important;
  }

  .lt-lg\:-space-x-26 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-6.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-6.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-lg\:-space-y-28 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-7rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-7rem * var(--space-y-reverse)) !important;
  }

  .lt-lg\:-space-x-28 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-7rem * var(--space-x-reverse)) !important;
    margin-left: calc(-7rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-lg\:-space-y-30 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-7.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-7.5rem * var(--space-y-reverse)) !important;
  }

  .lt-lg\:-space-x-30 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-7.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-7.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-lg\:-space-y-32 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-8rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-8rem * var(--space-y-reverse)) !important;
  }

  .lt-lg\:-space-x-32 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-8rem * var(--space-x-reverse)) !important;
    margin-left: calc(-8rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-lg\:-space-y-36 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-9rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-9rem * var(--space-y-reverse)) !important;
  }

  .lt-lg\:-space-x-36 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-9rem * var(--space-x-reverse)) !important;
    margin-left: calc(-9rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-lg\:-space-y-40 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-10rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-10rem * var(--space-y-reverse)) !important;
  }

  .lt-lg\:-space-x-40 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-10rem * var(--space-x-reverse)) !important;
    margin-left: calc(-10rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-lg\:-space-y-48 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-12rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-12rem * var(--space-y-reverse)) !important;
  }

  .lt-lg\:-space-x-48 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-12rem * var(--space-x-reverse)) !important;
    margin-left: calc(-12rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-lg\:-space-y-56 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-14rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-14rem * var(--space-y-reverse)) !important;
  }

  .lt-lg\:-space-x-56 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-14rem * var(--space-x-reverse)) !important;
    margin-left: calc(-14rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-lg\:-space-y-64 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-16rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-16rem * var(--space-y-reverse)) !important;
  }

  .lt-lg\:-space-x-64 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-16rem * var(--space-x-reverse)) !important;
    margin-left: calc(-16rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-lg\:-space-y-px > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-1px * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-1px * var(--space-y-reverse)) !important;
  }

  .lt-lg\:-space-x-px > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-1px * var(--space-x-reverse)) !important;
    margin-left: calc(-1px * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-lg\:-space-y-2px > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-2px * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-2px * var(--space-y-reverse)) !important;
  }

  .lt-lg\:-space-x-2px > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-2px * var(--space-x-reverse)) !important;
    margin-left: calc(-2px * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-lg\:space-y-reverse > :not(template) ~ :not(template) {
    --space-y-reverse: 1 !important;
  }

  .lt-lg\:space-x-reverse > :not(template) ~ :not(template) {
    --space-x-reverse: 1 !important;
  }

  .lt-lg\:divide-y-0 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(0px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(0px * var(--divide-y-reverse)) !important;
  }

  .lt-lg\:divide-x-0 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(0px * var(--divide-x-reverse)) !important;
    border-left-width: calc(0px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .lt-lg\:divide-y-2 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(2px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(2px * var(--divide-y-reverse)) !important;
  }

  .lt-lg\:divide-x-2 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(2px * var(--divide-x-reverse)) !important;
    border-left-width: calc(2px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .lt-lg\:divide-y-4 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(4px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(4px * var(--divide-y-reverse)) !important;
  }

  .lt-lg\:divide-x-4 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(4px * var(--divide-x-reverse)) !important;
    border-left-width: calc(4px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .lt-lg\:divide-y-8 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(8px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(8px * var(--divide-y-reverse)) !important;
  }

  .lt-lg\:divide-x-8 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(8px * var(--divide-x-reverse)) !important;
    border-left-width: calc(8px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .lt-lg\:divide-y > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(1px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(1px * var(--divide-y-reverse)) !important;
  }

  .lt-lg\:divide-x > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(1px * var(--divide-x-reverse)) !important;
    border-left-width: calc(1px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .lt-lg\:divide-y-reverse > :not(template) ~ :not(template) {
    --divide-y-reverse: 1 !important;
  }

  .lt-lg\:divide-x-reverse > :not(template) ~ :not(template) {
    --divide-x-reverse: 1 !important;
  }

  .lt-lg\:divide-current > :not(template) ~ :not(template) {
    border-color: currentColor !important;
  }

  .lt-lg\:divide-transparent > :not(template) ~ :not(template) {
    border-color: transparent !important;
  }

  .lt-lg\:divide-white > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FFFFFF !important;
    border-color: rgba(255, 255, 255, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-black > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #000000 !important;
    border-color: rgba(0, 0, 0, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-gray-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F9FAFB !important;
    border-color: rgba(249, 250, 251, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-gray-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F4F5F7 !important;
    border-color: rgba(244, 245, 247, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-gray-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E5E7EB !important;
    border-color: rgba(229, 231, 235, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-gray-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #D2D6DC !important;
    border-color: rgba(210, 214, 220, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-gray-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9FA6B2 !important;
    border-color: rgba(159, 166, 178, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-gray-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #6B7280 !important;
    border-color: rgba(107, 114, 128, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-gray-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #4B5563 !important;
    border-color: rgba(75, 85, 99, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-gray-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #374151 !important;
    border-color: rgba(55, 65, 81, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-gray-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #252F3F !important;
    border-color: rgba(37, 47, 63, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-gray-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #161E2E !important;
    border-color: rgba(22, 30, 46, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-gray > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #6B7280 !important;
    border-color: rgba(107, 114, 128, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-cool-gray-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FBFDFE !important;
    border-color: rgba(251, 253, 254, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-cool-gray-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F1F5F9 !important;
    border-color: rgba(241, 245, 249, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-cool-gray-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E2E8F0 !important;
    border-color: rgba(226, 232, 240, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-cool-gray-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #CFD8E3 !important;
    border-color: rgba(207, 216, 227, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-cool-gray-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #97A6BA !important;
    border-color: rgba(151, 166, 186, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-cool-gray-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #64748B !important;
    border-color: rgba(100, 116, 139, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-cool-gray-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #475569 !important;
    border-color: rgba(71, 85, 105, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-cool-gray-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #364152 !important;
    border-color: rgba(54, 65, 82, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-cool-gray-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #27303F !important;
    border-color: rgba(39, 48, 63, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-cool-gray-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #1A202E !important;
    border-color: rgba(26, 32, 46, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-cool-gray > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #64748B !important;
    border-color: rgba(100, 116, 139, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-red-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FDF2F2 !important;
    border-color: rgba(253, 242, 242, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-red-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FDE8E8 !important;
    border-color: rgba(253, 232, 232, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-red-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FBD5D5 !important;
    border-color: rgba(251, 213, 213, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-red-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F8B4B4 !important;
    border-color: rgba(248, 180, 180, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-red-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F98080 !important;
    border-color: rgba(249, 128, 128, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-red-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F05252 !important;
    border-color: rgba(240, 82, 82, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-red-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E02424 !important;
    border-color: rgba(224, 36, 36, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-red-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #C81E1E !important;
    border-color: rgba(200, 30, 30, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-red-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9B1C1C !important;
    border-color: rgba(155, 28, 28, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-red-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #771D1D !important;
    border-color: rgba(119, 29, 29, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-red > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F05252 !important;
    border-color: rgba(240, 82, 82, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-orange-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FFF8F1 !important;
    border-color: rgba(255, 248, 241, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-orange-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FEECDC !important;
    border-color: rgba(254, 236, 220, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-orange-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FCD9BD !important;
    border-color: rgba(252, 217, 189, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-orange-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FDBA8C !important;
    border-color: rgba(253, 186, 140, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-orange-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FF8A4C !important;
    border-color: rgba(255, 138, 76, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-orange-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FF5A1F !important;
    border-color: rgba(255, 90, 31, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-orange-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #ff6700 !important;
    border-color: rgba(255, 103, 0, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-orange-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #B43403 !important;
    border-color: rgba(180, 52, 3, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-orange-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #8A2C0D !important;
    border-color: rgba(138, 44, 13, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-orange-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #771D1D !important;
    border-color: rgba(119, 29, 29, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-orange > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FF5A1F !important;
    border-color: rgba(255, 90, 31, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-yellow-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FDFDEA !important;
    border-color: rgba(253, 253, 234, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-yellow-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FDF6B2 !important;
    border-color: rgba(253, 246, 178, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-yellow-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FCE96A !important;
    border-color: rgba(252, 233, 106, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-yellow-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FACA15 !important;
    border-color: rgba(250, 202, 21, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-yellow-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E3A008 !important;
    border-color: rgba(227, 160, 8, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-yellow-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #C27803 !important;
    border-color: rgba(194, 120, 3, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-yellow-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9F580A !important;
    border-color: rgba(159, 88, 10, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-yellow-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #8E4B10 !important;
    border-color: rgba(142, 75, 16, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-yellow-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #723B13 !important;
    border-color: rgba(114, 59, 19, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-yellow-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #633112 !important;
    border-color: rgba(99, 49, 18, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-yellow > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #C27803 !important;
    border-color: rgba(194, 120, 3, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-green-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F3FAF7 !important;
    border-color: rgba(243, 250, 247, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-green-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #DEF7EC !important;
    border-color: rgba(222, 247, 236, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-green-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #BCF0DA !important;
    border-color: rgba(188, 240, 218, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-green-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #84E1BC !important;
    border-color: rgba(132, 225, 188, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-green-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #31C48D !important;
    border-color: rgba(49, 196, 141, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-green-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #0E9F6E !important;
    border-color: rgba(14, 159, 110, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-green-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #057A55 !important;
    border-color: rgba(5, 122, 85, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-green-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #046C4E !important;
    border-color: rgba(4, 108, 78, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-green-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #03543F !important;
    border-color: rgba(3, 84, 63, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-green-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #014737 !important;
    border-color: rgba(1, 71, 55, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-green > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #0E9F6E !important;
    border-color: rgba(14, 159, 110, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-teal-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #EDFAFA !important;
    border-color: rgba(237, 250, 250, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-teal-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #D5F5F6 !important;
    border-color: rgba(213, 245, 246, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-teal-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #AFECEF !important;
    border-color: rgba(175, 236, 239, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-teal-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #7EDCE2 !important;
    border-color: rgba(126, 220, 226, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-teal-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #16BDCA !important;
    border-color: rgba(22, 189, 202, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-teal-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #0694A2 !important;
    border-color: rgba(6, 148, 162, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-teal-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #047481 !important;
    border-color: rgba(4, 116, 129, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-teal-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #036672 !important;
    border-color: rgba(3, 102, 114, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-teal-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #05505C !important;
    border-color: rgba(5, 80, 92, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-teal-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #014451 !important;
    border-color: rgba(1, 68, 81, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-teal > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #0694A2 !important;
    border-color: rgba(6, 148, 162, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-blue-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #EBF5FF !important;
    border-color: rgba(235, 245, 255, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-blue-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E1EFFE !important;
    border-color: rgba(225, 239, 254, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-blue-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #C3DDFD !important;
    border-color: rgba(195, 221, 253, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-blue-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #A4CAFE !important;
    border-color: rgba(164, 202, 254, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-blue-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #76A9FA !important;
    border-color: rgba(118, 169, 250, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-blue-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #3F83F8 !important;
    border-color: rgba(63, 131, 248, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-blue-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #1C64F2 !important;
    border-color: rgba(28, 100, 242, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-blue-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #1A56DB !important;
    border-color: rgba(26, 86, 219, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-blue-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #1E429F !important;
    border-color: rgba(30, 66, 159, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-blue-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #233876 !important;
    border-color: rgba(35, 56, 118, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-blue > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #3F83F8 !important;
    border-color: rgba(63, 131, 248, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-indigo-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F0F5FF !important;
    border-color: rgba(240, 245, 255, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-indigo-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E5EDFF !important;
    border-color: rgba(229, 237, 255, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-indigo-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #CDDBFE !important;
    border-color: rgba(205, 219, 254, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-indigo-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #B4C6FC !important;
    border-color: rgba(180, 198, 252, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-indigo-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #8DA2FB !important;
    border-color: rgba(141, 162, 251, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-indigo-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #6875F5 !important;
    border-color: rgba(104, 117, 245, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-indigo-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #5850EC !important;
    border-color: rgba(88, 80, 236, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-indigo-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #5145CD !important;
    border-color: rgba(81, 69, 205, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-indigo-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #42389D !important;
    border-color: rgba(66, 56, 157, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-indigo-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #362F78 !important;
    border-color: rgba(54, 47, 120, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-indigo > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #6875F5 !important;
    border-color: rgba(104, 117, 245, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-purple-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F6F5FF !important;
    border-color: rgba(246, 245, 255, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-purple-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #EDEBFE !important;
    border-color: rgba(237, 235, 254, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-purple-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #DCD7FE !important;
    border-color: rgba(220, 215, 254, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-purple-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #CABFFD !important;
    border-color: rgba(202, 191, 253, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-purple-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #AC94FA !important;
    border-color: rgba(172, 148, 250, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-purple-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9061F9 !important;
    border-color: rgba(144, 97, 249, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-purple-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #7E3AF2 !important;
    border-color: rgba(126, 58, 242, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-purple-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #6C2BD9 !important;
    border-color: rgba(108, 43, 217, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-purple-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #5521B5 !important;
    border-color: rgba(85, 33, 181, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-purple-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #4A1D96 !important;
    border-color: rgba(74, 29, 150, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-purple > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9061F9 !important;
    border-color: rgba(144, 97, 249, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-pink-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FDF2F8 !important;
    border-color: rgba(253, 242, 248, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-pink-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FCE8F3 !important;
    border-color: rgba(252, 232, 243, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-pink-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FAD1E8 !important;
    border-color: rgba(250, 209, 232, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-pink-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F8B4D9 !important;
    border-color: rgba(248, 180, 217, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-pink-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F17EB8 !important;
    border-color: rgba(241, 126, 184, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-pink-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E74694 !important;
    border-color: rgba(231, 70, 148, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-pink-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #D61F69 !important;
    border-color: rgba(214, 31, 105, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-pink-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #BF125D !important;
    border-color: rgba(191, 18, 93, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-pink-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #99154B !important;
    border-color: rgba(153, 21, 75, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-pink-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #751A3D !important;
    border-color: rgba(117, 26, 61, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-pink > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E74694 !important;
    border-color: rgba(231, 70, 148, var(--divide-opacity)) !important;
  }

  .lt-lg\:divide-opacity-0 > :not(template) ~ :not(template) {
    --divide-opacity: 0 !important;
  }

  .lt-lg\:divide-opacity-12 > :not(template) ~ :not(template) {
    --divide-opacity: 0.12 !important;
  }

  .lt-lg\:divide-opacity-25 > :not(template) ~ :not(template) {
    --divide-opacity: 0.25 !important;
  }

  .lt-lg\:divide-opacity-38 > :not(template) ~ :not(template) {
    --divide-opacity: 0.38 !important;
  }

  .lt-lg\:divide-opacity-50 > :not(template) ~ :not(template) {
    --divide-opacity: 0.5 !important;
  }

  .lt-lg\:divide-opacity-54 > :not(template) ~ :not(template) {
    --divide-opacity: 0.54 !important;
  }

  .lt-lg\:divide-opacity-70 > :not(template) ~ :not(template) {
    --divide-opacity: 0.70 !important;
  }

  .lt-lg\:divide-opacity-75 > :not(template) ~ :not(template) {
    --divide-opacity: 0.75 !important;
  }

  .lt-lg\:divide-opacity-84 > :not(template) ~ :not(template) {
    --divide-opacity: 0.84 !important;
  }

  .lt-lg\:divide-opacity-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
  }

  .lt-lg\:sr-only {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border-width: 0 !important;
  }

  .lt-lg\:not-sr-only {
    position: static !important;
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: visible !important;
    clip: auto !important;
    white-space: normal !important;
  }

  .lt-lg\:focus\:sr-only:focus {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border-width: 0 !important;
  }

  .lt-lg\:focus\:not-sr-only:focus {
    position: static !important;
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: visible !important;
    clip: auto !important;
    white-space: normal !important;
  }

  .lt-lg\:appearance-none {
    -webkit-appearance: none !important;
       -moz-appearance: none !important;
            appearance: none !important;
  }

  .lt-lg\:bg-fixed {
    background-attachment: fixed !important;
  }

  .lt-lg\:bg-local {
    background-attachment: local !important;
  }

  .lt-lg\:bg-scroll {
    background-attachment: scroll !important;
  }

  .lt-lg\:bg-opacity-0 {
    --bg-opacity: 0 !important;
  }

  .lt-lg\:bg-opacity-12 {
    --bg-opacity: 0.12 !important;
  }

  .lt-lg\:bg-opacity-25 {
    --bg-opacity: 0.25 !important;
  }

  .lt-lg\:bg-opacity-38 {
    --bg-opacity: 0.38 !important;
  }

  .lt-lg\:bg-opacity-50 {
    --bg-opacity: 0.5 !important;
  }

  .lt-lg\:bg-opacity-54 {
    --bg-opacity: 0.54 !important;
  }

  .lt-lg\:bg-opacity-70 {
    --bg-opacity: 0.70 !important;
  }

  .lt-lg\:bg-opacity-75 {
    --bg-opacity: 0.75 !important;
  }

  .lt-lg\:bg-opacity-84 {
    --bg-opacity: 0.84 !important;
  }

  .lt-lg\:bg-opacity-100 {
    --bg-opacity: 1 !important;
  }

  .lt-lg\:hover\:bg-opacity-0:hover {
    --bg-opacity: 0 !important;
  }

  .lt-lg\:hover\:bg-opacity-12:hover {
    --bg-opacity: 0.12 !important;
  }

  .lt-lg\:hover\:bg-opacity-25:hover {
    --bg-opacity: 0.25 !important;
  }

  .lt-lg\:hover\:bg-opacity-38:hover {
    --bg-opacity: 0.38 !important;
  }

  .lt-lg\:hover\:bg-opacity-50:hover {
    --bg-opacity: 0.5 !important;
  }

  .lt-lg\:hover\:bg-opacity-54:hover {
    --bg-opacity: 0.54 !important;
  }

  .lt-lg\:hover\:bg-opacity-70:hover {
    --bg-opacity: 0.70 !important;
  }

  .lt-lg\:hover\:bg-opacity-75:hover {
    --bg-opacity: 0.75 !important;
  }

  .lt-lg\:hover\:bg-opacity-84:hover {
    --bg-opacity: 0.84 !important;
  }

  .lt-lg\:hover\:bg-opacity-100:hover {
    --bg-opacity: 1 !important;
  }

  .lt-lg\:focus\:bg-opacity-0:focus {
    --bg-opacity: 0 !important;
  }

  .lt-lg\:focus\:bg-opacity-12:focus {
    --bg-opacity: 0.12 !important;
  }

  .lt-lg\:focus\:bg-opacity-25:focus {
    --bg-opacity: 0.25 !important;
  }

  .lt-lg\:focus\:bg-opacity-38:focus {
    --bg-opacity: 0.38 !important;
  }

  .lt-lg\:focus\:bg-opacity-50:focus {
    --bg-opacity: 0.5 !important;
  }

  .lt-lg\:focus\:bg-opacity-54:focus {
    --bg-opacity: 0.54 !important;
  }

  .lt-lg\:focus\:bg-opacity-70:focus {
    --bg-opacity: 0.70 !important;
  }

  .lt-lg\:focus\:bg-opacity-75:focus {
    --bg-opacity: 0.75 !important;
  }

  .lt-lg\:focus\:bg-opacity-84:focus {
    --bg-opacity: 0.84 !important;
  }

  .lt-lg\:focus\:bg-opacity-100:focus {
    --bg-opacity: 1 !important;
  }

  .lt-lg\:bg-bottom {
    background-position: bottom !important;
  }

  .lt-lg\:bg-center {
    background-position: center !important;
  }

  .lt-lg\:bg-left {
    background-position: left !important;
  }

  .lt-lg\:bg-left-bottom {
    background-position: left bottom !important;
  }

  .lt-lg\:bg-left-top {
    background-position: left top !important;
  }

  .lt-lg\:bg-right {
    background-position: right !important;
  }

  .lt-lg\:bg-right-bottom {
    background-position: right bottom !important;
  }

  .lt-lg\:bg-right-top {
    background-position: right top !important;
  }

  .lt-lg\:bg-top {
    background-position: top !important;
  }

  .lt-lg\:bg-repeat {
    background-repeat: repeat !important;
  }

  .lt-lg\:bg-no-repeat {
    background-repeat: no-repeat !important;
  }

  .lt-lg\:bg-repeat-x {
    background-repeat: repeat-x !important;
  }

  .lt-lg\:bg-repeat-y {
    background-repeat: repeat-y !important;
  }

  .lt-lg\:bg-repeat-round {
    background-repeat: round !important;
  }

  .lt-lg\:bg-repeat-space {
    background-repeat: space !important;
  }

  .lt-lg\:bg-auto {
    background-size: auto !important;
  }

  .lt-lg\:bg-cover {
    background-size: cover !important;
  }

  .lt-lg\:bg-contain {
    background-size: contain !important;
  }

  .lt-lg\:border-collapse {
    border-collapse: collapse !important;
  }

  .lt-lg\:border-separate {
    border-collapse: separate !important;
  }

  .lt-lg\:border-opacity-0 {
    --border-opacity: 0 !important;
  }

  .lt-lg\:border-opacity-12 {
    --border-opacity: 0.12 !important;
  }

  .lt-lg\:border-opacity-25 {
    --border-opacity: 0.25 !important;
  }

  .lt-lg\:border-opacity-38 {
    --border-opacity: 0.38 !important;
  }

  .lt-lg\:border-opacity-50 {
    --border-opacity: 0.5 !important;
  }

  .lt-lg\:border-opacity-54 {
    --border-opacity: 0.54 !important;
  }

  .lt-lg\:border-opacity-70 {
    --border-opacity: 0.70 !important;
  }

  .lt-lg\:border-opacity-75 {
    --border-opacity: 0.75 !important;
  }

  .lt-lg\:border-opacity-84 {
    --border-opacity: 0.84 !important;
  }

  .lt-lg\:border-opacity-100 {
    --border-opacity: 1 !important;
  }

  .lt-lg\:hover\:border-opacity-0:hover {
    --border-opacity: 0 !important;
  }

  .lt-lg\:hover\:border-opacity-12:hover {
    --border-opacity: 0.12 !important;
  }

  .lt-lg\:hover\:border-opacity-25:hover {
    --border-opacity: 0.25 !important;
  }

  .lt-lg\:hover\:border-opacity-38:hover {
    --border-opacity: 0.38 !important;
  }

  .lt-lg\:hover\:border-opacity-50:hover {
    --border-opacity: 0.5 !important;
  }

  .lt-lg\:hover\:border-opacity-54:hover {
    --border-opacity: 0.54 !important;
  }

  .lt-lg\:hover\:border-opacity-70:hover {
    --border-opacity: 0.70 !important;
  }

  .lt-lg\:hover\:border-opacity-75:hover {
    --border-opacity: 0.75 !important;
  }

  .lt-lg\:hover\:border-opacity-84:hover {
    --border-opacity: 0.84 !important;
  }

  .lt-lg\:hover\:border-opacity-100:hover {
    --border-opacity: 1 !important;
  }

  .lt-lg\:focus\:border-opacity-0:focus {
    --border-opacity: 0 !important;
  }

  .lt-lg\:focus\:border-opacity-12:focus {
    --border-opacity: 0.12 !important;
  }

  .lt-lg\:focus\:border-opacity-25:focus {
    --border-opacity: 0.25 !important;
  }

  .lt-lg\:focus\:border-opacity-38:focus {
    --border-opacity: 0.38 !important;
  }

  .lt-lg\:focus\:border-opacity-50:focus {
    --border-opacity: 0.5 !important;
  }

  .lt-lg\:focus\:border-opacity-54:focus {
    --border-opacity: 0.54 !important;
  }

  .lt-lg\:focus\:border-opacity-70:focus {
    --border-opacity: 0.70 !important;
  }

  .lt-lg\:focus\:border-opacity-75:focus {
    --border-opacity: 0.75 !important;
  }

  .lt-lg\:focus\:border-opacity-84:focus {
    --border-opacity: 0.84 !important;
  }

  .lt-lg\:focus\:border-opacity-100:focus {
    --border-opacity: 1 !important;
  }

  .lt-lg\:rounded-none {
    border-radius: 0 !important;
  }

  .lt-lg\:rounded-sm {
    border-radius: 0.125rem !important;
  }

  .lt-lg\:rounded {
    border-radius: 0.25rem !important;
  }

  .lt-lg\:rounded-md {
    border-radius: 0.375rem !important;
  }

  .lt-lg\:rounded-lg {
    border-radius: 0.5rem !important;
  }

  .lt-lg\:rounded-full {
    border-radius: 9999px !important;
  }

  .lt-lg\:rounded-t-none {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .lt-lg\:rounded-r-none {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .lt-lg\:rounded-b-none {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .lt-lg\:rounded-l-none {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .lt-lg\:rounded-t-sm {
    border-top-left-radius: 0.125rem !important;
    border-top-right-radius: 0.125rem !important;
  }

  .lt-lg\:rounded-r-sm {
    border-top-right-radius: 0.125rem !important;
    border-bottom-right-radius: 0.125rem !important;
  }

  .lt-lg\:rounded-b-sm {
    border-bottom-right-radius: 0.125rem !important;
    border-bottom-left-radius: 0.125rem !important;
  }

  .lt-lg\:rounded-l-sm {
    border-top-left-radius: 0.125rem !important;
    border-bottom-left-radius: 0.125rem !important;
  }

  .lt-lg\:rounded-t {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }

  .lt-lg\:rounded-r {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }

  .lt-lg\:rounded-b {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }

  .lt-lg\:rounded-l {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }

  .lt-lg\:rounded-t-md {
    border-top-left-radius: 0.375rem !important;
    border-top-right-radius: 0.375rem !important;
  }

  .lt-lg\:rounded-r-md {
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }

  .lt-lg\:rounded-b-md {
    border-bottom-right-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }

  .lt-lg\:rounded-l-md {
    border-top-left-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }

  .lt-lg\:rounded-t-lg {
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
  }

  .lt-lg\:rounded-r-lg {
    border-top-right-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }

  .lt-lg\:rounded-b-lg {
    border-bottom-right-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }

  .lt-lg\:rounded-l-lg {
    border-top-left-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }

  .lt-lg\:rounded-t-full {
    border-top-left-radius: 9999px !important;
    border-top-right-radius: 9999px !important;
  }

  .lt-lg\:rounded-r-full {
    border-top-right-radius: 9999px !important;
    border-bottom-right-radius: 9999px !important;
  }

  .lt-lg\:rounded-b-full {
    border-bottom-right-radius: 9999px !important;
    border-bottom-left-radius: 9999px !important;
  }

  .lt-lg\:rounded-l-full {
    border-top-left-radius: 9999px !important;
    border-bottom-left-radius: 9999px !important;
  }

  .lt-lg\:rounded-tl-none {
    border-top-left-radius: 0 !important;
  }

  .lt-lg\:rounded-tr-none {
    border-top-right-radius: 0 !important;
  }

  .lt-lg\:rounded-br-none {
    border-bottom-right-radius: 0 !important;
  }

  .lt-lg\:rounded-bl-none {
    border-bottom-left-radius: 0 !important;
  }

  .lt-lg\:rounded-tl-sm {
    border-top-left-radius: 0.125rem !important;
  }

  .lt-lg\:rounded-tr-sm {
    border-top-right-radius: 0.125rem !important;
  }

  .lt-lg\:rounded-br-sm {
    border-bottom-right-radius: 0.125rem !important;
  }

  .lt-lg\:rounded-bl-sm {
    border-bottom-left-radius: 0.125rem !important;
  }

  .lt-lg\:rounded-tl {
    border-top-left-radius: 0.25rem !important;
  }

  .lt-lg\:rounded-tr {
    border-top-right-radius: 0.25rem !important;
  }

  .lt-lg\:rounded-br {
    border-bottom-right-radius: 0.25rem !important;
  }

  .lt-lg\:rounded-bl {
    border-bottom-left-radius: 0.25rem !important;
  }

  .lt-lg\:rounded-tl-md {
    border-top-left-radius: 0.375rem !important;
  }

  .lt-lg\:rounded-tr-md {
    border-top-right-radius: 0.375rem !important;
  }

  .lt-lg\:rounded-br-md {
    border-bottom-right-radius: 0.375rem !important;
  }

  .lt-lg\:rounded-bl-md {
    border-bottom-left-radius: 0.375rem !important;
  }

  .lt-lg\:rounded-tl-lg {
    border-top-left-radius: 0.5rem !important;
  }

  .lt-lg\:rounded-tr-lg {
    border-top-right-radius: 0.5rem !important;
  }

  .lt-lg\:rounded-br-lg {
    border-bottom-right-radius: 0.5rem !important;
  }

  .lt-lg\:rounded-bl-lg {
    border-bottom-left-radius: 0.5rem !important;
  }

  .lt-lg\:rounded-tl-full {
    border-top-left-radius: 9999px !important;
  }

  .lt-lg\:rounded-tr-full {
    border-top-right-radius: 9999px !important;
  }

  .lt-lg\:rounded-br-full {
    border-bottom-right-radius: 9999px !important;
  }

  .lt-lg\:rounded-bl-full {
    border-bottom-left-radius: 9999px !important;
  }

  .lt-lg\:border-solid {
    border-style: solid !important;
  }

  .lt-lg\:border-dashed {
    border-style: dashed !important;
  }

  .lt-lg\:border-dotted {
    border-style: dotted !important;
  }

  .lt-lg\:border-double {
    border-style: double !important;
  }

  .lt-lg\:border-none {
    border-style: none !important;
  }

  .lt-lg\:border-0 {
    border-width: 0 !important;
  }

  .lt-lg\:border-2 {
    border-width: 2px !important;
  }

  .lt-lg\:border-4 {
    border-width: 4px !important;
  }

  .lt-lg\:border-8 {
    border-width: 8px !important;
  }

  .lt-lg\:border {
    border-width: 1px !important;
  }

  .lt-lg\:border-t-0 {
    border-top-width: 0 !important;
  }

  .lt-lg\:border-r-0 {
    border-right-width: 0 !important;
  }

  .lt-lg\:border-b-0 {
    border-bottom-width: 0 !important;
  }

  .lt-lg\:border-l-0 {
    border-left-width: 0 !important;
  }

  .lt-lg\:border-t-2 {
    border-top-width: 2px !important;
  }

  .lt-lg\:border-r-2 {
    border-right-width: 2px !important;
  }

  .lt-lg\:border-b-2 {
    border-bottom-width: 2px !important;
  }

  .lt-lg\:border-l-2 {
    border-left-width: 2px !important;
  }

  .lt-lg\:border-t-4 {
    border-top-width: 4px !important;
  }

  .lt-lg\:border-r-4 {
    border-right-width: 4px !important;
  }

  .lt-lg\:border-b-4 {
    border-bottom-width: 4px !important;
  }

  .lt-lg\:border-l-4 {
    border-left-width: 4px !important;
  }

  .lt-lg\:border-t-8 {
    border-top-width: 8px !important;
  }

  .lt-lg\:border-r-8 {
    border-right-width: 8px !important;
  }

  .lt-lg\:border-b-8 {
    border-bottom-width: 8px !important;
  }

  .lt-lg\:border-l-8 {
    border-left-width: 8px !important;
  }

  .lt-lg\:border-t {
    border-top-width: 1px !important;
  }

  .lt-lg\:border-r {
    border-right-width: 1px !important;
  }

  .lt-lg\:border-b {
    border-bottom-width: 1px !important;
  }

  .lt-lg\:border-l {
    border-left-width: 1px !important;
  }

  .lt-lg\:first\:border-0:first-child {
    border-width: 0 !important;
  }

  .lt-lg\:first\:border-2:first-child {
    border-width: 2px !important;
  }

  .lt-lg\:first\:border-4:first-child {
    border-width: 4px !important;
  }

  .lt-lg\:first\:border-8:first-child {
    border-width: 8px !important;
  }

  .lt-lg\:first\:border:first-child {
    border-width: 1px !important;
  }

  .lt-lg\:first\:border-t-0:first-child {
    border-top-width: 0 !important;
  }

  .lt-lg\:first\:border-r-0:first-child {
    border-right-width: 0 !important;
  }

  .lt-lg\:first\:border-b-0:first-child {
    border-bottom-width: 0 !important;
  }

  .lt-lg\:first\:border-l-0:first-child {
    border-left-width: 0 !important;
  }

  .lt-lg\:first\:border-t-2:first-child {
    border-top-width: 2px !important;
  }

  .lt-lg\:first\:border-r-2:first-child {
    border-right-width: 2px !important;
  }

  .lt-lg\:first\:border-b-2:first-child {
    border-bottom-width: 2px !important;
  }

  .lt-lg\:first\:border-l-2:first-child {
    border-left-width: 2px !important;
  }

  .lt-lg\:first\:border-t-4:first-child {
    border-top-width: 4px !important;
  }

  .lt-lg\:first\:border-r-4:first-child {
    border-right-width: 4px !important;
  }

  .lt-lg\:first\:border-b-4:first-child {
    border-bottom-width: 4px !important;
  }

  .lt-lg\:first\:border-l-4:first-child {
    border-left-width: 4px !important;
  }

  .lt-lg\:first\:border-t-8:first-child {
    border-top-width: 8px !important;
  }

  .lt-lg\:first\:border-r-8:first-child {
    border-right-width: 8px !important;
  }

  .lt-lg\:first\:border-b-8:first-child {
    border-bottom-width: 8px !important;
  }

  .lt-lg\:first\:border-l-8:first-child {
    border-left-width: 8px !important;
  }

  .lt-lg\:first\:border-t:first-child {
    border-top-width: 1px !important;
  }

  .lt-lg\:first\:border-r:first-child {
    border-right-width: 1px !important;
  }

  .lt-lg\:first\:border-b:first-child {
    border-bottom-width: 1px !important;
  }

  .lt-lg\:first\:border-l:first-child {
    border-left-width: 1px !important;
  }

  .lt-lg\:last\:border-0:last-child {
    border-width: 0 !important;
  }

  .lt-lg\:last\:border-2:last-child {
    border-width: 2px !important;
  }

  .lt-lg\:last\:border-4:last-child {
    border-width: 4px !important;
  }

  .lt-lg\:last\:border-8:last-child {
    border-width: 8px !important;
  }

  .lt-lg\:last\:border:last-child {
    border-width: 1px !important;
  }

  .lt-lg\:last\:border-t-0:last-child {
    border-top-width: 0 !important;
  }

  .lt-lg\:last\:border-r-0:last-child {
    border-right-width: 0 !important;
  }

  .lt-lg\:last\:border-b-0:last-child {
    border-bottom-width: 0 !important;
  }

  .lt-lg\:last\:border-l-0:last-child {
    border-left-width: 0 !important;
  }

  .lt-lg\:last\:border-t-2:last-child {
    border-top-width: 2px !important;
  }

  .lt-lg\:last\:border-r-2:last-child {
    border-right-width: 2px !important;
  }

  .lt-lg\:last\:border-b-2:last-child {
    border-bottom-width: 2px !important;
  }

  .lt-lg\:last\:border-l-2:last-child {
    border-left-width: 2px !important;
  }

  .lt-lg\:last\:border-t-4:last-child {
    border-top-width: 4px !important;
  }

  .lt-lg\:last\:border-r-4:last-child {
    border-right-width: 4px !important;
  }

  .lt-lg\:last\:border-b-4:last-child {
    border-bottom-width: 4px !important;
  }

  .lt-lg\:last\:border-l-4:last-child {
    border-left-width: 4px !important;
  }

  .lt-lg\:last\:border-t-8:last-child {
    border-top-width: 8px !important;
  }

  .lt-lg\:last\:border-r-8:last-child {
    border-right-width: 8px !important;
  }

  .lt-lg\:last\:border-b-8:last-child {
    border-bottom-width: 8px !important;
  }

  .lt-lg\:last\:border-l-8:last-child {
    border-left-width: 8px !important;
  }

  .lt-lg\:last\:border-t:last-child {
    border-top-width: 1px !important;
  }

  .lt-lg\:last\:border-r:last-child {
    border-right-width: 1px !important;
  }

  .lt-lg\:last\:border-b:last-child {
    border-bottom-width: 1px !important;
  }

  .lt-lg\:last\:border-l:last-child {
    border-left-width: 1px !important;
  }

  .lt-lg\:box-border {
    box-sizing: border-box !important;
  }

  .lt-lg\:box-content {
    box-sizing: content-box !important;
  }

  .lt-lg\:block {
    display: block !important;
  }

  .lt-lg\:inline-block {
    display: inline-block !important;
  }

  .lt-lg\:inline {
    display: inline !important;
  }

  .lt-lg\:flex {
    display: flex !important;
  }

  .lt-lg\:inline-flex {
    display: inline-flex !important;
  }

  .lt-lg\:table {
    display: table !important;
  }

  .lt-lg\:table-caption {
    display: table-caption !important;
  }

  .lt-lg\:table-cell {
    display: table-cell !important;
  }

  .lt-lg\:table-column {
    display: table-column !important;
  }

  .lt-lg\:table-column-group {
    display: table-column-group !important;
  }

  .lt-lg\:table-footer-group {
    display: table-footer-group !important;
  }

  .lt-lg\:table-header-group {
    display: table-header-group !important;
  }

  .lt-lg\:table-row-group {
    display: table-row-group !important;
  }

  .lt-lg\:table-row {
    display: table-row !important;
  }

  .lt-lg\:flow-root {
    display: flow-root !important;
  }

  .lt-lg\:grid {
    display: grid !important;
  }

  .lt-lg\:inline-grid {
    display: inline-grid !important;
  }

  .lt-lg\:hidden {
    display: none !important;
  }

  .lt-lg\:flex-row {
    flex-direction: row !important;
  }

  .lt-lg\:flex-row-reverse {
    flex-direction: row-reverse !important;
  }

  .lt-lg\:flex-col {
    flex-direction: column !important;
  }

  .lt-lg\:flex-col-reverse {
    flex-direction: column-reverse !important;
  }

  .lt-lg\:flex-wrap {
    flex-wrap: wrap !important;
  }

  .lt-lg\:flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .lt-lg\:flex-nowrap {
    flex-wrap: nowrap !important;
  }

  .lt-lg\:items-start {
    align-items: flex-start !important;
  }

  .lt-lg\:items-end {
    align-items: flex-end !important;
  }

  .lt-lg\:items-center {
    align-items: center !important;
  }

  .lt-lg\:items-baseline {
    align-items: baseline !important;
  }

  .lt-lg\:items-stretch {
    align-items: stretch !important;
  }

  .lt-lg\:self-auto {
    align-self: auto !important;
  }

  .lt-lg\:self-start {
    align-self: flex-start !important;
  }

  .lt-lg\:self-end {
    align-self: flex-end !important;
  }

  .lt-lg\:self-center {
    align-self: center !important;
  }

  .lt-lg\:self-stretch {
    align-self: stretch !important;
  }

  .lt-lg\:justify-start {
    justify-content: flex-start !important;
  }

  .lt-lg\:justify-end {
    justify-content: flex-end !important;
  }

  .lt-lg\:justify-center {
    justify-content: center !important;
  }

  .lt-lg\:justify-between {
    justify-content: space-between !important;
  }

  .lt-lg\:justify-around {
    justify-content: space-around !important;
  }

  .lt-lg\:justify-evenly {
    justify-content: space-evenly !important;
  }

  .lt-lg\:content-center {
    align-content: center !important;
  }

  .lt-lg\:content-start {
    align-content: flex-start !important;
  }

  .lt-lg\:content-end {
    align-content: flex-end !important;
  }

  .lt-lg\:content-between {
    align-content: space-between !important;
  }

  .lt-lg\:content-around {
    align-content: space-around !important;
  }

  .lt-lg\:flex-0 {
    flex: 0 0 auto !important;
  }

  .lt-lg\:flex-1 {
    flex: 1 1 0% !important;
  }

  .lt-lg\:flex-auto {
    flex: 1 1 auto !important;
  }

  .lt-lg\:flex-initial {
    flex: 0 1 auto !important;
  }

  .lt-lg\:flex-none {
    flex: none !important;
  }

  .lt-lg\:flex-grow-0 {
    flex-grow: 0 !important;
  }

  .lt-lg\:flex-grow {
    flex-grow: 1 !important;
  }

  .lt-lg\:flex-shrink-0 {
    flex-shrink: 0 !important;
  }

  .lt-lg\:flex-shrink {
    flex-shrink: 1 !important;
  }

  .lt-lg\:order-1 {
    order: 1 !important;
  }

  .lt-lg\:order-2 {
    order: 2 !important;
  }

  .lt-lg\:order-3 {
    order: 3 !important;
  }

  .lt-lg\:order-4 {
    order: 4 !important;
  }

  .lt-lg\:order-5 {
    order: 5 !important;
  }

  .lt-lg\:order-6 {
    order: 6 !important;
  }

  .lt-lg\:order-7 {
    order: 7 !important;
  }

  .lt-lg\:order-8 {
    order: 8 !important;
  }

  .lt-lg\:order-9 {
    order: 9 !important;
  }

  .lt-lg\:order-10 {
    order: 10 !important;
  }

  .lt-lg\:order-11 {
    order: 11 !important;
  }

  .lt-lg\:order-12 {
    order: 12 !important;
  }

  .lt-lg\:order-first {
    order: -9999 !important;
  }

  .lt-lg\:order-last {
    order: 9999 !important;
  }

  .lt-lg\:order-none {
    order: 0 !important;
  }

  .lt-lg\:font-thin {
    font-weight: 100 !important;
  }

  .lt-lg\:font-extralight {
    font-weight: 200 !important;
  }

  .lt-lg\:font-light {
    font-weight: 300 !important;
  }

  .lt-lg\:font-normal {
    font-weight: 400 !important;
  }

  .lt-lg\:font-medium {
    font-weight: 500 !important;
  }

  .lt-lg\:font-semibold {
    font-weight: 600 !important;
  }

  .lt-lg\:font-bold {
    font-weight: 700 !important;
  }

  .lt-lg\:font-extrabold {
    font-weight: 800 !important;
  }

  .lt-lg\:font-black {
    font-weight: 900 !important;
  }

  .lt-lg\:h-0 {
    height: 0 !important;
  }

  .lt-lg\:h-1 {
    height: 0.25rem !important;
  }

  .lt-lg\:h-2 {
    height: 0.5rem !important;
  }

  .lt-lg\:h-3 {
    height: 0.75rem !important;
  }

  .lt-lg\:h-4 {
    height: 1rem !important;
  }

  .lt-lg\:h-5 {
    height: 1.25rem !important;
  }

  .lt-lg\:h-6 {
    height: 1.5rem !important;
  }

  .lt-lg\:h-8 {
    height: 2rem !important;
  }

  .lt-lg\:h-10 {
    height: 2.5rem !important;
  }

  .lt-lg\:h-12 {
    height: 3rem !important;
  }

  .lt-lg\:h-14 {
    height: 3.5rem !important;
  }

  .lt-lg\:h-16 {
    height: 4rem !important;
  }

  .lt-lg\:h-18 {
    height: 4.5rem !important;
  }

  .lt-lg\:h-20 {
    height: 5rem !important;
  }

  .lt-lg\:h-22 {
    height: 5.5rem !important;
  }

  .lt-lg\:h-24 {
    height: 6rem !important;
  }

  .lt-lg\:h-26 {
    height: 6.5rem !important;
  }

  .lt-lg\:h-28 {
    height: 7rem !important;
  }

  .lt-lg\:h-30 {
    height: 7.5rem !important;
  }

  .lt-lg\:h-32 {
    height: 8rem !important;
  }

  .lt-lg\:h-36 {
    height: 9rem !important;
  }

  .lt-lg\:h-40 {
    height: 10rem !important;
  }

  .lt-lg\:h-48 {
    height: 12rem !important;
  }

  .lt-lg\:h-50 {
    height: 12.5rem !important;
  }

  .lt-lg\:h-56 {
    height: 14rem !important;
  }

  .lt-lg\:h-60 {
    height: 15rem !important;
  }

  .lt-lg\:h-64 {
    height: 16rem !important;
  }

  .lt-lg\:h-80 {
    height: 20rem !important;
  }

  .lt-lg\:h-90 {
    height: 24rem !important;
  }

  .lt-lg\:h-100 {
    height: 25rem !important;
  }

  .lt-lg\:h-120 {
    height: 30rem !important;
  }

  .lt-lg\:h-128 {
    height: 32rem !important;
  }

  .lt-lg\:h-140 {
    height: 35rem !important;
  }

  .lt-lg\:h-160 {
    height: 40rem !important;
  }

  .lt-lg\:h-180 {
    height: 45rem !important;
  }

  .lt-lg\:h-192 {
    height: 48rem !important;
  }

  .lt-lg\:h-200 {
    height: 50rem !important;
  }

  .lt-lg\:h-240 {
    height: 60rem !important;
  }

  .lt-lg\:h-256 {
    height: 64rem !important;
  }

  .lt-lg\:h-280 {
    height: 70rem !important;
  }

  .lt-lg\:h-320 {
    height: 80rem !important;
  }

  .lt-lg\:h-360 {
    height: 90rem !important;
  }

  .lt-lg\:h-400 {
    height: 100rem !important;
  }

  .lt-lg\:h-480 {
    height: 120rem !important;
  }

  .lt-lg\:h-auto {
    height: auto !important;
  }

  .lt-lg\:h-px {
    height: 1px !important;
  }

  .lt-lg\:h-2px {
    height: 2px !important;
  }

  .lt-lg\:h-full {
    height: 100% !important;
  }

  .lt-lg\:h-screen {
    height: 100vh !important;
  }

  .lt-lg\:h-1\/2 {
    height: 50% !important;
  }

  .lt-lg\:h-1\/3 {
    height: 33.33333% !important;
  }

  .lt-lg\:h-2\/3 {
    height: 66.66667% !important;
  }

  .lt-lg\:h-1\/4 {
    height: 25% !important;
  }

  .lt-lg\:h-2\/4 {
    height: 50% !important;
  }

  .lt-lg\:h-3\/4 {
    height: 75% !important;
  }

  .lt-lg\:h-1\/5 {
    height: 20% !important;
  }

  .lt-lg\:h-2\/5 {
    height: 40% !important;
  }

  .lt-lg\:h-3\/5 {
    height: 60% !important;
  }

  .lt-lg\:h-4\/5 {
    height: 80% !important;
  }

  .lt-lg\:h-1\/12 {
    height: 8.33333% !important;
  }

  .lt-lg\:h-2\/12 {
    height: 16.66667% !important;
  }

  .lt-lg\:h-3\/12 {
    height: 25% !important;
  }

  .lt-lg\:h-4\/12 {
    height: 33.33333% !important;
  }

  .lt-lg\:h-5\/12 {
    height: 41.66667% !important;
  }

  .lt-lg\:h-6\/12 {
    height: 50% !important;
  }

  .lt-lg\:h-7\/12 {
    height: 58.33333% !important;
  }

  .lt-lg\:h-8\/12 {
    height: 66.66667% !important;
  }

  .lt-lg\:h-9\/12 {
    height: 75% !important;
  }

  .lt-lg\:h-10\/12 {
    height: 83.33333% !important;
  }

  .lt-lg\:h-11\/12 {
    height: 91.66667% !important;
  }

  .lt-lg\:text-xs {
    font-size: 0.625rem !important;
  }

  .lt-lg\:text-sm {
    font-size: 0.75rem !important;
  }

  .lt-lg\:text-md {
    font-size: 0.8125rem !important;
  }

  .lt-lg\:text-base {
    font-size: 0.875rem !important;
  }

  .lt-lg\:text-lg {
    font-size: 1rem !important;
  }

  .lt-lg\:text-xl {
    font-size: 1.125rem !important;
  }

  .lt-lg\:text-2xl {
    font-size: 1.25rem !important;
  }

  .lt-lg\:text-3xl {
    font-size: 1.5rem !important;
  }

  .lt-lg\:text-4xl {
    font-size: 2rem !important;
  }

  .lt-lg\:text-5xl {
    font-size: 2.25rem !important;
  }

  .lt-lg\:text-6xl {
    font-size: 2.5rem !important;
  }

  .lt-lg\:text-7xl {
    font-size: 3rem !important;
  }

  .lt-lg\:text-8xl {
    font-size: 4rem !important;
  }

  .lt-lg\:text-9xl {
    font-size: 6rem !important;
  }

  .lt-lg\:text-10xl {
    font-size: 8rem !important;
  }

  .lt-lg\:leading-3 {
    line-height: .75rem !important;
  }

  .lt-lg\:leading-4 {
    line-height: 1rem !important;
  }

  .lt-lg\:leading-5 {
    line-height: 1.25rem !important;
  }

  .lt-lg\:leading-6 {
    line-height: 1.5rem !important;
  }

  .lt-lg\:leading-7 {
    line-height: 1.75rem !important;
  }

  .lt-lg\:leading-8 {
    line-height: 2rem !important;
  }

  .lt-lg\:leading-9 {
    line-height: 2.25rem !important;
  }

  .lt-lg\:leading-10 {
    line-height: 2.5rem !important;
  }

  .lt-lg\:leading-none {
    line-height: 1 !important;
  }

  .lt-lg\:leading-tight {
    line-height: 1.25 !important;
  }

  .lt-lg\:leading-snug {
    line-height: 1.375 !important;
  }

  .lt-lg\:leading-normal {
    line-height: 1.5 !important;
  }

  .lt-lg\:leading-relaxed {
    line-height: 1.625 !important;
  }

  .lt-lg\:leading-loose {
    line-height: 2 !important;
  }

  .lt-lg\:list-inside {
    list-style-position: inside !important;
  }

  .lt-lg\:list-outside {
    list-style-position: outside !important;
  }

  .lt-lg\:list-none {
    list-style-type: none !important;
  }

  .lt-lg\:list-disc {
    list-style-type: disc !important;
  }

  .lt-lg\:list-decimal {
    list-style-type: decimal !important;
  }

  .lt-lg\:m-0 {
    margin: 0 !important;
  }

  .lt-lg\:m-1 {
    margin: 0.25rem !important;
  }

  .lt-lg\:m-2 {
    margin: 0.5rem !important;
  }

  .lt-lg\:m-3 {
    margin: 0.75rem !important;
  }

  .lt-lg\:m-4 {
    margin: 1rem !important;
  }

  .lt-lg\:m-5 {
    margin: 1.25rem !important;
  }

  .lt-lg\:m-6 {
    margin: 1.5rem !important;
  }

  .lt-lg\:m-8 {
    margin: 2rem !important;
  }

  .lt-lg\:m-10 {
    margin: 2.5rem !important;
  }

  .lt-lg\:m-12 {
    margin: 3rem !important;
  }

  .lt-lg\:m-14 {
    margin: 3.5rem !important;
  }

  .lt-lg\:m-16 {
    margin: 4rem !important;
  }

  .lt-lg\:m-18 {
    margin: 4.5rem !important;
  }

  .lt-lg\:m-20 {
    margin: 5rem !important;
  }

  .lt-lg\:m-22 {
    margin: 5.5rem !important;
  }

  .lt-lg\:m-24 {
    margin: 6rem !important;
  }

  .lt-lg\:m-26 {
    margin: 6.5rem !important;
  }

  .lt-lg\:m-28 {
    margin: 7rem !important;
  }

  .lt-lg\:m-30 {
    margin: 7.5rem !important;
  }

  .lt-lg\:m-32 {
    margin: 8rem !important;
  }

  .lt-lg\:m-36 {
    margin: 9rem !important;
  }

  .lt-lg\:m-40 {
    margin: 10rem !important;
  }

  .lt-lg\:m-48 {
    margin: 12rem !important;
  }

  .lt-lg\:m-56 {
    margin: 14rem !important;
  }

  .lt-lg\:m-64 {
    margin: 16rem !important;
  }

  .lt-lg\:m-auto {
    margin: auto !important;
  }

  .lt-lg\:m-px {
    margin: 1px !important;
  }

  .lt-lg\:m-2px {
    margin: 2px !important;
  }

  .lt-lg\:-m-1 {
    margin: -0.25rem !important;
  }

  .lt-lg\:-m-2 {
    margin: -0.5rem !important;
  }

  .lt-lg\:-m-3 {
    margin: -0.75rem !important;
  }

  .lt-lg\:-m-4 {
    margin: -1rem !important;
  }

  .lt-lg\:-m-5 {
    margin: -1.25rem !important;
  }

  .lt-lg\:-m-6 {
    margin: -1.5rem !important;
  }

  .lt-lg\:-m-8 {
    margin: -2rem !important;
  }

  .lt-lg\:-m-10 {
    margin: -2.5rem !important;
  }

  .lt-lg\:-m-12 {
    margin: -3rem !important;
  }

  .lt-lg\:-m-14 {
    margin: -3.5rem !important;
  }

  .lt-lg\:-m-16 {
    margin: -4rem !important;
  }

  .lt-lg\:-m-18 {
    margin: -4.5rem !important;
  }

  .lt-lg\:-m-20 {
    margin: -5rem !important;
  }

  .lt-lg\:-m-22 {
    margin: -5.5rem !important;
  }

  .lt-lg\:-m-24 {
    margin: -6rem !important;
  }

  .lt-lg\:-m-26 {
    margin: -6.5rem !important;
  }

  .lt-lg\:-m-28 {
    margin: -7rem !important;
  }

  .lt-lg\:-m-30 {
    margin: -7.5rem !important;
  }

  .lt-lg\:-m-32 {
    margin: -8rem !important;
  }

  .lt-lg\:-m-36 {
    margin: -9rem !important;
  }

  .lt-lg\:-m-40 {
    margin: -10rem !important;
  }

  .lt-lg\:-m-48 {
    margin: -12rem !important;
  }

  .lt-lg\:-m-56 {
    margin: -14rem !important;
  }

  .lt-lg\:-m-64 {
    margin: -16rem !important;
  }

  .lt-lg\:-m-px {
    margin: -1px !important;
  }

  .lt-lg\:-m-2px {
    margin: -2px !important;
  }

  .lt-lg\:my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .lt-lg\:mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .lt-lg\:my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .lt-lg\:mx-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }

  .lt-lg\:my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .lt-lg\:mx-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .lt-lg\:my-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .lt-lg\:mx-3 {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
  }

  .lt-lg\:my-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .lt-lg\:mx-4 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .lt-lg\:my-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .lt-lg\:mx-5 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }

  .lt-lg\:my-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .lt-lg\:mx-6 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .lt-lg\:my-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .lt-lg\:mx-8 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .lt-lg\:my-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .lt-lg\:mx-10 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .lt-lg\:my-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .lt-lg\:mx-12 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .lt-lg\:my-14 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .lt-lg\:mx-14 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }

  .lt-lg\:my-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .lt-lg\:mx-16 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .lt-lg\:my-18 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .lt-lg\:mx-18 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }

  .lt-lg\:my-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .lt-lg\:mx-20 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .lt-lg\:my-22 {
    margin-top: 5.5rem !important;
    margin-bottom: 5.5rem !important;
  }

  .lt-lg\:mx-22 {
    margin-left: 5.5rem !important;
    margin-right: 5.5rem !important;
  }

  .lt-lg\:my-24 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .lt-lg\:mx-24 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }

  .lt-lg\:my-26 {
    margin-top: 6.5rem !important;
    margin-bottom: 6.5rem !important;
  }

  .lt-lg\:mx-26 {
    margin-left: 6.5rem !important;
    margin-right: 6.5rem !important;
  }

  .lt-lg\:my-28 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }

  .lt-lg\:mx-28 {
    margin-left: 7rem !important;
    margin-right: 7rem !important;
  }

  .lt-lg\:my-30 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }

  .lt-lg\:mx-30 {
    margin-left: 7.5rem !important;
    margin-right: 7.5rem !important;
  }

  .lt-lg\:my-32 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .lt-lg\:mx-32 {
    margin-left: 8rem !important;
    margin-right: 8rem !important;
  }

  .lt-lg\:my-36 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }

  .lt-lg\:mx-36 {
    margin-left: 9rem !important;
    margin-right: 9rem !important;
  }

  .lt-lg\:my-40 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .lt-lg\:mx-40 {
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }

  .lt-lg\:my-48 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }

  .lt-lg\:mx-48 {
    margin-left: 12rem !important;
    margin-right: 12rem !important;
  }

  .lt-lg\:my-56 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }

  .lt-lg\:mx-56 {
    margin-left: 14rem !important;
    margin-right: 14rem !important;
  }

  .lt-lg\:my-64 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }

  .lt-lg\:mx-64 {
    margin-left: 16rem !important;
    margin-right: 16rem !important;
  }

  .lt-lg\:my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .lt-lg\:mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .lt-lg\:my-px {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  .lt-lg\:mx-px {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }

  .lt-lg\:my-2px {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }

  .lt-lg\:mx-2px {
    margin-left: 2px !important;
    margin-right: 2px !important;
  }

  .lt-lg\:-my-1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .lt-lg\:-mx-1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }

  .lt-lg\:-my-2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .lt-lg\:-mx-2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }

  .lt-lg\:-my-3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }

  .lt-lg\:-mx-3 {
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important;
  }

  .lt-lg\:-my-4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .lt-lg\:-mx-4 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .lt-lg\:-my-5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .lt-lg\:-mx-5 {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }

  .lt-lg\:-my-6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .lt-lg\:-mx-6 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .lt-lg\:-my-8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .lt-lg\:-mx-8 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .lt-lg\:-my-10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .lt-lg\:-mx-10 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }

  .lt-lg\:-my-12 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .lt-lg\:-mx-12 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .lt-lg\:-my-14 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .lt-lg\:-mx-14 {
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }

  .lt-lg\:-my-16 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .lt-lg\:-mx-16 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .lt-lg\:-my-18 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .lt-lg\:-mx-18 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }

  .lt-lg\:-my-20 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .lt-lg\:-mx-20 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .lt-lg\:-my-22 {
    margin-top: -5.5rem !important;
    margin-bottom: -5.5rem !important;
  }

  .lt-lg\:-mx-22 {
    margin-left: -5.5rem !important;
    margin-right: -5.5rem !important;
  }

  .lt-lg\:-my-24 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .lt-lg\:-mx-24 {
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }

  .lt-lg\:-my-26 {
    margin-top: -6.5rem !important;
    margin-bottom: -6.5rem !important;
  }

  .lt-lg\:-mx-26 {
    margin-left: -6.5rem !important;
    margin-right: -6.5rem !important;
  }

  .lt-lg\:-my-28 {
    margin-top: -7rem !important;
    margin-bottom: -7rem !important;
  }

  .lt-lg\:-mx-28 {
    margin-left: -7rem !important;
    margin-right: -7rem !important;
  }

  .lt-lg\:-my-30 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important;
  }

  .lt-lg\:-mx-30 {
    margin-left: -7.5rem !important;
    margin-right: -7.5rem !important;
  }

  .lt-lg\:-my-32 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .lt-lg\:-mx-32 {
    margin-left: -8rem !important;
    margin-right: -8rem !important;
  }

  .lt-lg\:-my-36 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }

  .lt-lg\:-mx-36 {
    margin-left: -9rem !important;
    margin-right: -9rem !important;
  }

  .lt-lg\:-my-40 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .lt-lg\:-mx-40 {
    margin-left: -10rem !important;
    margin-right: -10rem !important;
  }

  .lt-lg\:-my-48 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }

  .lt-lg\:-mx-48 {
    margin-left: -12rem !important;
    margin-right: -12rem !important;
  }

  .lt-lg\:-my-56 {
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }

  .lt-lg\:-mx-56 {
    margin-left: -14rem !important;
    margin-right: -14rem !important;
  }

  .lt-lg\:-my-64 {
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }

  .lt-lg\:-mx-64 {
    margin-left: -16rem !important;
    margin-right: -16rem !important;
  }

  .lt-lg\:-my-px {
    margin-top: -1px !important;
    margin-bottom: -1px !important;
  }

  .lt-lg\:-mx-px {
    margin-left: -1px !important;
    margin-right: -1px !important;
  }

  .lt-lg\:-my-2px {
    margin-top: -2px !important;
    margin-bottom: -2px !important;
  }

  .lt-lg\:-mx-2px {
    margin-left: -2px !important;
    margin-right: -2px !important;
  }

  .lt-lg\:mt-0 {
    margin-top: 0 !important;
  }

  .lt-lg\:mr-0 {
    margin-right: 0 !important;
  }

  .lt-lg\:mb-0 {
    margin-bottom: 0 !important;
  }

  .lt-lg\:ml-0 {
    margin-left: 0 !important;
  }

  .lt-lg\:mt-1 {
    margin-top: 0.25rem !important;
  }

  .lt-lg\:mr-1 {
    margin-right: 0.25rem !important;
  }

  .lt-lg\:mb-1 {
    margin-bottom: 0.25rem !important;
  }

  .lt-lg\:ml-1 {
    margin-left: 0.25rem !important;
  }

  .lt-lg\:mt-2 {
    margin-top: 0.5rem !important;
  }

  .lt-lg\:mr-2 {
    margin-right: 0.5rem !important;
  }

  .lt-lg\:mb-2 {
    margin-bottom: 0.5rem !important;
  }

  .lt-lg\:ml-2 {
    margin-left: 0.5rem !important;
  }

  .lt-lg\:mt-3 {
    margin-top: 0.75rem !important;
  }

  .lt-lg\:mr-3 {
    margin-right: 0.75rem !important;
  }

  .lt-lg\:mb-3 {
    margin-bottom: 0.75rem !important;
  }

  .lt-lg\:ml-3 {
    margin-left: 0.75rem !important;
  }

  .lt-lg\:mt-4 {
    margin-top: 1rem !important;
  }

  .lt-lg\:mr-4 {
    margin-right: 1rem !important;
  }

  .lt-lg\:mb-4 {
    margin-bottom: 1rem !important;
  }

  .lt-lg\:ml-4 {
    margin-left: 1rem !important;
  }

  .lt-lg\:mt-5 {
    margin-top: 1.25rem !important;
  }

  .lt-lg\:mr-5 {
    margin-right: 1.25rem !important;
  }

  .lt-lg\:mb-5 {
    margin-bottom: 1.25rem !important;
  }

  .lt-lg\:ml-5 {
    margin-left: 1.25rem !important;
  }

  .lt-lg\:mt-6 {
    margin-top: 1.5rem !important;
  }

  .lt-lg\:mr-6 {
    margin-right: 1.5rem !important;
  }

  .lt-lg\:mb-6 {
    margin-bottom: 1.5rem !important;
  }

  .lt-lg\:ml-6 {
    margin-left: 1.5rem !important;
  }

  .lt-lg\:mt-8 {
    margin-top: 2rem !important;
  }

  .lt-lg\:mr-8 {
    margin-right: 2rem !important;
  }

  .lt-lg\:mb-8 {
    margin-bottom: 2rem !important;
  }

  .lt-lg\:ml-8 {
    margin-left: 2rem !important;
  }

  .lt-lg\:mt-10 {
    margin-top: 2.5rem !important;
  }

  .lt-lg\:mr-10 {
    margin-right: 2.5rem !important;
  }

  .lt-lg\:mb-10 {
    margin-bottom: 2.5rem !important;
  }

  .lt-lg\:ml-10 {
    margin-left: 2.5rem !important;
  }

  .lt-lg\:mt-12 {
    margin-top: 3rem !important;
  }

  .lt-lg\:mr-12 {
    margin-right: 3rem !important;
  }

  .lt-lg\:mb-12 {
    margin-bottom: 3rem !important;
  }

  .lt-lg\:ml-12 {
    margin-left: 3rem !important;
  }

  .lt-lg\:mt-14 {
    margin-top: 3.5rem !important;
  }

  .lt-lg\:mr-14 {
    margin-right: 3.5rem !important;
  }

  .lt-lg\:mb-14 {
    margin-bottom: 3.5rem !important;
  }

  .lt-lg\:ml-14 {
    margin-left: 3.5rem !important;
  }

  .lt-lg\:mt-16 {
    margin-top: 4rem !important;
  }

  .lt-lg\:mr-16 {
    margin-right: 4rem !important;
  }

  .lt-lg\:mb-16 {
    margin-bottom: 4rem !important;
  }

  .lt-lg\:ml-16 {
    margin-left: 4rem !important;
  }

  .lt-lg\:mt-18 {
    margin-top: 4.5rem !important;
  }

  .lt-lg\:mr-18 {
    margin-right: 4.5rem !important;
  }

  .lt-lg\:mb-18 {
    margin-bottom: 4.5rem !important;
  }

  .lt-lg\:ml-18 {
    margin-left: 4.5rem !important;
  }

  .lt-lg\:mt-20 {
    margin-top: 5rem !important;
  }

  .lt-lg\:mr-20 {
    margin-right: 5rem !important;
  }

  .lt-lg\:mb-20 {
    margin-bottom: 5rem !important;
  }

  .lt-lg\:ml-20 {
    margin-left: 5rem !important;
  }

  .lt-lg\:mt-22 {
    margin-top: 5.5rem !important;
  }

  .lt-lg\:mr-22 {
    margin-right: 5.5rem !important;
  }

  .lt-lg\:mb-22 {
    margin-bottom: 5.5rem !important;
  }

  .lt-lg\:ml-22 {
    margin-left: 5.5rem !important;
  }

  .lt-lg\:mt-24 {
    margin-top: 6rem !important;
  }

  .lt-lg\:mr-24 {
    margin-right: 6rem !important;
  }

  .lt-lg\:mb-24 {
    margin-bottom: 6rem !important;
  }

  .lt-lg\:ml-24 {
    margin-left: 6rem !important;
  }

  .lt-lg\:mt-26 {
    margin-top: 6.5rem !important;
  }

  .lt-lg\:mr-26 {
    margin-right: 6.5rem !important;
  }

  .lt-lg\:mb-26 {
    margin-bottom: 6.5rem !important;
  }

  .lt-lg\:ml-26 {
    margin-left: 6.5rem !important;
  }

  .lt-lg\:mt-28 {
    margin-top: 7rem !important;
  }

  .lt-lg\:mr-28 {
    margin-right: 7rem !important;
  }

  .lt-lg\:mb-28 {
    margin-bottom: 7rem !important;
  }

  .lt-lg\:ml-28 {
    margin-left: 7rem !important;
  }

  .lt-lg\:mt-30 {
    margin-top: 7.5rem !important;
  }

  .lt-lg\:mr-30 {
    margin-right: 7.5rem !important;
  }

  .lt-lg\:mb-30 {
    margin-bottom: 7.5rem !important;
  }

  .lt-lg\:ml-30 {
    margin-left: 7.5rem !important;
  }

  .lt-lg\:mt-32 {
    margin-top: 8rem !important;
  }

  .lt-lg\:mr-32 {
    margin-right: 8rem !important;
  }

  .lt-lg\:mb-32 {
    margin-bottom: 8rem !important;
  }

  .lt-lg\:ml-32 {
    margin-left: 8rem !important;
  }

  .lt-lg\:mt-36 {
    margin-top: 9rem !important;
  }

  .lt-lg\:mr-36 {
    margin-right: 9rem !important;
  }

  .lt-lg\:mb-36 {
    margin-bottom: 9rem !important;
  }

  .lt-lg\:ml-36 {
    margin-left: 9rem !important;
  }

  .lt-lg\:mt-40 {
    margin-top: 10rem !important;
  }

  .lt-lg\:mr-40 {
    margin-right: 10rem !important;
  }

  .lt-lg\:mb-40 {
    margin-bottom: 10rem !important;
  }

  .lt-lg\:ml-40 {
    margin-left: 10rem !important;
  }

  .lt-lg\:mt-48 {
    margin-top: 12rem !important;
  }

  .lt-lg\:mr-48 {
    margin-right: 12rem !important;
  }

  .lt-lg\:mb-48 {
    margin-bottom: 12rem !important;
  }

  .lt-lg\:ml-48 {
    margin-left: 12rem !important;
  }

  .lt-lg\:mt-56 {
    margin-top: 14rem !important;
  }

  .lt-lg\:mr-56 {
    margin-right: 14rem !important;
  }

  .lt-lg\:mb-56 {
    margin-bottom: 14rem !important;
  }

  .lt-lg\:ml-56 {
    margin-left: 14rem !important;
  }

  .lt-lg\:mt-64 {
    margin-top: 16rem !important;
  }

  .lt-lg\:mr-64 {
    margin-right: 16rem !important;
  }

  .lt-lg\:mb-64 {
    margin-bottom: 16rem !important;
  }

  .lt-lg\:ml-64 {
    margin-left: 16rem !important;
  }

  .lt-lg\:mt-auto {
    margin-top: auto !important;
  }

  .lt-lg\:mr-auto {
    margin-right: auto !important;
  }

  .lt-lg\:mb-auto {
    margin-bottom: auto !important;
  }

  .lt-lg\:ml-auto {
    margin-left: auto !important;
  }

  .lt-lg\:mt-px {
    margin-top: 1px !important;
  }

  .lt-lg\:mr-px {
    margin-right: 1px !important;
  }

  .lt-lg\:mb-px {
    margin-bottom: 1px !important;
  }

  .lt-lg\:ml-px {
    margin-left: 1px !important;
  }

  .lt-lg\:mt-2px {
    margin-top: 2px !important;
  }

  .lt-lg\:mr-2px {
    margin-right: 2px !important;
  }

  .lt-lg\:mb-2px {
    margin-bottom: 2px !important;
  }

  .lt-lg\:ml-2px {
    margin-left: 2px !important;
  }

  .lt-lg\:-mt-1 {
    margin-top: -0.25rem !important;
  }

  .lt-lg\:-mr-1 {
    margin-right: -0.25rem !important;
  }

  .lt-lg\:-mb-1 {
    margin-bottom: -0.25rem !important;
  }

  .lt-lg\:-ml-1 {
    margin-left: -0.25rem !important;
  }

  .lt-lg\:-mt-2 {
    margin-top: -0.5rem !important;
  }

  .lt-lg\:-mr-2 {
    margin-right: -0.5rem !important;
  }

  .lt-lg\:-mb-2 {
    margin-bottom: -0.5rem !important;
  }

  .lt-lg\:-ml-2 {
    margin-left: -0.5rem !important;
  }

  .lt-lg\:-mt-3 {
    margin-top: -0.75rem !important;
  }

  .lt-lg\:-mr-3 {
    margin-right: -0.75rem !important;
  }

  .lt-lg\:-mb-3 {
    margin-bottom: -0.75rem !important;
  }

  .lt-lg\:-ml-3 {
    margin-left: -0.75rem !important;
  }

  .lt-lg\:-mt-4 {
    margin-top: -1rem !important;
  }

  .lt-lg\:-mr-4 {
    margin-right: -1rem !important;
  }

  .lt-lg\:-mb-4 {
    margin-bottom: -1rem !important;
  }

  .lt-lg\:-ml-4 {
    margin-left: -1rem !important;
  }

  .lt-lg\:-mt-5 {
    margin-top: -1.25rem !important;
  }

  .lt-lg\:-mr-5 {
    margin-right: -1.25rem !important;
  }

  .lt-lg\:-mb-5 {
    margin-bottom: -1.25rem !important;
  }

  .lt-lg\:-ml-5 {
    margin-left: -1.25rem !important;
  }

  .lt-lg\:-mt-6 {
    margin-top: -1.5rem !important;
  }

  .lt-lg\:-mr-6 {
    margin-right: -1.5rem !important;
  }

  .lt-lg\:-mb-6 {
    margin-bottom: -1.5rem !important;
  }

  .lt-lg\:-ml-6 {
    margin-left: -1.5rem !important;
  }

  .lt-lg\:-mt-8 {
    margin-top: -2rem !important;
  }

  .lt-lg\:-mr-8 {
    margin-right: -2rem !important;
  }

  .lt-lg\:-mb-8 {
    margin-bottom: -2rem !important;
  }

  .lt-lg\:-ml-8 {
    margin-left: -2rem !important;
  }

  .lt-lg\:-mt-10 {
    margin-top: -2.5rem !important;
  }

  .lt-lg\:-mr-10 {
    margin-right: -2.5rem !important;
  }

  .lt-lg\:-mb-10 {
    margin-bottom: -2.5rem !important;
  }

  .lt-lg\:-ml-10 {
    margin-left: -2.5rem !important;
  }

  .lt-lg\:-mt-12 {
    margin-top: -3rem !important;
  }

  .lt-lg\:-mr-12 {
    margin-right: -3rem !important;
  }

  .lt-lg\:-mb-12 {
    margin-bottom: -3rem !important;
  }

  .lt-lg\:-ml-12 {
    margin-left: -3rem !important;
  }

  .lt-lg\:-mt-14 {
    margin-top: -3.5rem !important;
  }

  .lt-lg\:-mr-14 {
    margin-right: -3.5rem !important;
  }

  .lt-lg\:-mb-14 {
    margin-bottom: -3.5rem !important;
  }

  .lt-lg\:-ml-14 {
    margin-left: -3.5rem !important;
  }

  .lt-lg\:-mt-16 {
    margin-top: -4rem !important;
  }

  .lt-lg\:-mr-16 {
    margin-right: -4rem !important;
  }

  .lt-lg\:-mb-16 {
    margin-bottom: -4rem !important;
  }

  .lt-lg\:-ml-16 {
    margin-left: -4rem !important;
  }

  .lt-lg\:-mt-18 {
    margin-top: -4.5rem !important;
  }

  .lt-lg\:-mr-18 {
    margin-right: -4.5rem !important;
  }

  .lt-lg\:-mb-18 {
    margin-bottom: -4.5rem !important;
  }

  .lt-lg\:-ml-18 {
    margin-left: -4.5rem !important;
  }

  .lt-lg\:-mt-20 {
    margin-top: -5rem !important;
  }

  .lt-lg\:-mr-20 {
    margin-right: -5rem !important;
  }

  .lt-lg\:-mb-20 {
    margin-bottom: -5rem !important;
  }

  .lt-lg\:-ml-20 {
    margin-left: -5rem !important;
  }

  .lt-lg\:-mt-22 {
    margin-top: -5.5rem !important;
  }

  .lt-lg\:-mr-22 {
    margin-right: -5.5rem !important;
  }

  .lt-lg\:-mb-22 {
    margin-bottom: -5.5rem !important;
  }

  .lt-lg\:-ml-22 {
    margin-left: -5.5rem !important;
  }

  .lt-lg\:-mt-24 {
    margin-top: -6rem !important;
  }

  .lt-lg\:-mr-24 {
    margin-right: -6rem !important;
  }

  .lt-lg\:-mb-24 {
    margin-bottom: -6rem !important;
  }

  .lt-lg\:-ml-24 {
    margin-left: -6rem !important;
  }

  .lt-lg\:-mt-26 {
    margin-top: -6.5rem !important;
  }

  .lt-lg\:-mr-26 {
    margin-right: -6.5rem !important;
  }

  .lt-lg\:-mb-26 {
    margin-bottom: -6.5rem !important;
  }

  .lt-lg\:-ml-26 {
    margin-left: -6.5rem !important;
  }

  .lt-lg\:-mt-28 {
    margin-top: -7rem !important;
  }

  .lt-lg\:-mr-28 {
    margin-right: -7rem !important;
  }

  .lt-lg\:-mb-28 {
    margin-bottom: -7rem !important;
  }

  .lt-lg\:-ml-28 {
    margin-left: -7rem !important;
  }

  .lt-lg\:-mt-30 {
    margin-top: -7.5rem !important;
  }

  .lt-lg\:-mr-30 {
    margin-right: -7.5rem !important;
  }

  .lt-lg\:-mb-30 {
    margin-bottom: -7.5rem !important;
  }

  .lt-lg\:-ml-30 {
    margin-left: -7.5rem !important;
  }

  .lt-lg\:-mt-32 {
    margin-top: -8rem !important;
  }

  .lt-lg\:-mr-32 {
    margin-right: -8rem !important;
  }

  .lt-lg\:-mb-32 {
    margin-bottom: -8rem !important;
  }

  .lt-lg\:-ml-32 {
    margin-left: -8rem !important;
  }

  .lt-lg\:-mt-36 {
    margin-top: -9rem !important;
  }

  .lt-lg\:-mr-36 {
    margin-right: -9rem !important;
  }

  .lt-lg\:-mb-36 {
    margin-bottom: -9rem !important;
  }

  .lt-lg\:-ml-36 {
    margin-left: -9rem !important;
  }

  .lt-lg\:-mt-40 {
    margin-top: -10rem !important;
  }

  .lt-lg\:-mr-40 {
    margin-right: -10rem !important;
  }

  .lt-lg\:-mb-40 {
    margin-bottom: -10rem !important;
  }

  .lt-lg\:-ml-40 {
    margin-left: -10rem !important;
  }

  .lt-lg\:-mt-48 {
    margin-top: -12rem !important;
  }

  .lt-lg\:-mr-48 {
    margin-right: -12rem !important;
  }

  .lt-lg\:-mb-48 {
    margin-bottom: -12rem !important;
  }

  .lt-lg\:-ml-48 {
    margin-left: -12rem !important;
  }

  .lt-lg\:-mt-56 {
    margin-top: -14rem !important;
  }

  .lt-lg\:-mr-56 {
    margin-right: -14rem !important;
  }

  .lt-lg\:-mb-56 {
    margin-bottom: -14rem !important;
  }

  .lt-lg\:-ml-56 {
    margin-left: -14rem !important;
  }

  .lt-lg\:-mt-64 {
    margin-top: -16rem !important;
  }

  .lt-lg\:-mr-64 {
    margin-right: -16rem !important;
  }

  .lt-lg\:-mb-64 {
    margin-bottom: -16rem !important;
  }

  .lt-lg\:-ml-64 {
    margin-left: -16rem !important;
  }

  .lt-lg\:-mt-px {
    margin-top: -1px !important;
  }

  .lt-lg\:-mr-px {
    margin-right: -1px !important;
  }

  .lt-lg\:-mb-px {
    margin-bottom: -1px !important;
  }

  .lt-lg\:-ml-px {
    margin-left: -1px !important;
  }

  .lt-lg\:-mt-2px {
    margin-top: -2px !important;
  }

  .lt-lg\:-mr-2px {
    margin-right: -2px !important;
  }

  .lt-lg\:-mb-2px {
    margin-bottom: -2px !important;
  }

  .lt-lg\:-ml-2px {
    margin-left: -2px !important;
  }

  .lt-lg\:max-h-0 {
    max-height: 0 !important;
  }

  .lt-lg\:max-h-1 {
    max-height: 0.25rem !important;
  }

  .lt-lg\:max-h-2 {
    max-height: 0.5rem !important;
  }

  .lt-lg\:max-h-3 {
    max-height: 0.75rem !important;
  }

  .lt-lg\:max-h-4 {
    max-height: 1rem !important;
  }

  .lt-lg\:max-h-5 {
    max-height: 1.25rem !important;
  }

  .lt-lg\:max-h-6 {
    max-height: 1.5rem !important;
  }

  .lt-lg\:max-h-8 {
    max-height: 2rem !important;
  }

  .lt-lg\:max-h-10 {
    max-height: 2.5rem !important;
  }

  .lt-lg\:max-h-12 {
    max-height: 3rem !important;
  }

  .lt-lg\:max-h-14 {
    max-height: 3.5rem !important;
  }

  .lt-lg\:max-h-16 {
    max-height: 4rem !important;
  }

  .lt-lg\:max-h-18 {
    max-height: 4.5rem !important;
  }

  .lt-lg\:max-h-20 {
    max-height: 5rem !important;
  }

  .lt-lg\:max-h-22 {
    max-height: 5.5rem !important;
  }

  .lt-lg\:max-h-24 {
    max-height: 6rem !important;
  }

  .lt-lg\:max-h-26 {
    max-height: 6.5rem !important;
  }

  .lt-lg\:max-h-28 {
    max-height: 7rem !important;
  }

  .lt-lg\:max-h-30 {
    max-height: 7.5rem !important;
  }

  .lt-lg\:max-h-32 {
    max-height: 8rem !important;
  }

  .lt-lg\:max-h-36 {
    max-height: 9rem !important;
  }

  .lt-lg\:max-h-40 {
    max-height: 10rem !important;
  }

  .lt-lg\:max-h-48 {
    max-height: 12rem !important;
  }

  .lt-lg\:max-h-50 {
    max-height: 12.5rem !important;
  }

  .lt-lg\:max-h-56 {
    max-height: 14rem !important;
  }

  .lt-lg\:max-h-60 {
    max-height: 15rem !important;
  }

  .lt-lg\:max-h-64 {
    max-height: 16rem !important;
  }

  .lt-lg\:max-h-80 {
    max-height: 20rem !important;
  }

  .lt-lg\:max-h-90 {
    max-height: 24rem !important;
  }

  .lt-lg\:max-h-100 {
    max-height: 25rem !important;
  }

  .lt-lg\:max-h-120 {
    max-height: 30rem !important;
  }

  .lt-lg\:max-h-128 {
    max-height: 32rem !important;
  }

  .lt-lg\:max-h-140 {
    max-height: 35rem !important;
  }

  .lt-lg\:max-h-160 {
    max-height: 40rem !important;
  }

  .lt-lg\:max-h-180 {
    max-height: 45rem !important;
  }

  .lt-lg\:max-h-192 {
    max-height: 48rem !important;
  }

  .lt-lg\:max-h-200 {
    max-height: 50rem !important;
  }

  .lt-lg\:max-h-240 {
    max-height: 60rem !important;
  }

  .lt-lg\:max-h-256 {
    max-height: 64rem !important;
  }

  .lt-lg\:max-h-280 {
    max-height: 70rem !important;
  }

  .lt-lg\:max-h-320 {
    max-height: 80rem !important;
  }

  .lt-lg\:max-h-360 {
    max-height: 90rem !important;
  }

  .lt-lg\:max-h-400 {
    max-height: 100rem !important;
  }

  .lt-lg\:max-h-480 {
    max-height: 120rem !important;
  }

  .lt-lg\:max-h-full {
    max-height: 100% !important;
  }

  .lt-lg\:max-h-screen {
    max-height: 100vh !important;
  }

  .lt-lg\:max-h-none {
    max-height: none !important;
  }

  .lt-lg\:max-h-px {
    max-height: 1px !important;
  }

  .lt-lg\:max-h-2px {
    max-height: 2px !important;
  }

  .lt-lg\:max-h-1\/2 {
    max-height: 50% !important;
  }

  .lt-lg\:max-h-1\/3 {
    max-height: 33.33333% !important;
  }

  .lt-lg\:max-h-2\/3 {
    max-height: 66.66667% !important;
  }

  .lt-lg\:max-h-1\/4 {
    max-height: 25% !important;
  }

  .lt-lg\:max-h-2\/4 {
    max-height: 50% !important;
  }

  .lt-lg\:max-h-3\/4 {
    max-height: 75% !important;
  }

  .lt-lg\:max-h-1\/5 {
    max-height: 20% !important;
  }

  .lt-lg\:max-h-2\/5 {
    max-height: 40% !important;
  }

  .lt-lg\:max-h-3\/5 {
    max-height: 60% !important;
  }

  .lt-lg\:max-h-4\/5 {
    max-height: 80% !important;
  }

  .lt-lg\:max-h-1\/12 {
    max-height: 8.33333% !important;
  }

  .lt-lg\:max-h-2\/12 {
    max-height: 16.66667% !important;
  }

  .lt-lg\:max-h-3\/12 {
    max-height: 25% !important;
  }

  .lt-lg\:max-h-4\/12 {
    max-height: 33.33333% !important;
  }

  .lt-lg\:max-h-5\/12 {
    max-height: 41.66667% !important;
  }

  .lt-lg\:max-h-6\/12 {
    max-height: 50% !important;
  }

  .lt-lg\:max-h-7\/12 {
    max-height: 58.33333% !important;
  }

  .lt-lg\:max-h-8\/12 {
    max-height: 66.66667% !important;
  }

  .lt-lg\:max-h-9\/12 {
    max-height: 75% !important;
  }

  .lt-lg\:max-h-10\/12 {
    max-height: 83.33333% !important;
  }

  .lt-lg\:max-h-11\/12 {
    max-height: 91.66667% !important;
  }

  .lt-lg\:max-w-0 {
    max-width: 0 !important;
  }

  .lt-lg\:max-w-1 {
    max-width: 0.25rem !important;
  }

  .lt-lg\:max-w-2 {
    max-width: 0.5rem !important;
  }

  .lt-lg\:max-w-3 {
    max-width: 0.75rem !important;
  }

  .lt-lg\:max-w-4 {
    max-width: 1rem !important;
  }

  .lt-lg\:max-w-5 {
    max-width: 1.25rem !important;
  }

  .lt-lg\:max-w-6 {
    max-width: 1.5rem !important;
  }

  .lt-lg\:max-w-8 {
    max-width: 2rem !important;
  }

  .lt-lg\:max-w-10 {
    max-width: 2.5rem !important;
  }

  .lt-lg\:max-w-12 {
    max-width: 3rem !important;
  }

  .lt-lg\:max-w-14 {
    max-width: 3.5rem !important;
  }

  .lt-lg\:max-w-16 {
    max-width: 4rem !important;
  }

  .lt-lg\:max-w-18 {
    max-width: 4.5rem !important;
  }

  .lt-lg\:max-w-20 {
    max-width: 5rem !important;
  }

  .lt-lg\:max-w-22 {
    max-width: 5.5rem !important;
  }

  .lt-lg\:max-w-24 {
    max-width: 6rem !important;
  }

  .lt-lg\:max-w-26 {
    max-width: 6.5rem !important;
  }

  .lt-lg\:max-w-28 {
    max-width: 7rem !important;
  }

  .lt-lg\:max-w-30 {
    max-width: 7.5rem !important;
  }

  .lt-lg\:max-w-32 {
    max-width: 8rem !important;
  }

  .lt-lg\:max-w-36 {
    max-width: 9rem !important;
  }

  .lt-lg\:max-w-40 {
    max-width: 10rem !important;
  }

  .lt-lg\:max-w-48 {
    max-width: 12rem !important;
  }

  .lt-lg\:max-w-50 {
    max-width: 12.5rem !important;
  }

  .lt-lg\:max-w-56 {
    max-width: 14rem !important;
  }

  .lt-lg\:max-w-60 {
    max-width: 15rem !important;
  }

  .lt-lg\:max-w-64 {
    max-width: 16rem !important;
  }

  .lt-lg\:max-w-80 {
    max-width: 20rem !important;
  }

  .lt-lg\:max-w-90 {
    max-width: 24rem !important;
  }

  .lt-lg\:max-w-100 {
    max-width: 25rem !important;
  }

  .lt-lg\:max-w-120 {
    max-width: 30rem !important;
  }

  .lt-lg\:max-w-128 {
    max-width: 32rem !important;
  }

  .lt-lg\:max-w-140 {
    max-width: 35rem !important;
  }

  .lt-lg\:max-w-160 {
    max-width: 40rem !important;
  }

  .lt-lg\:max-w-180 {
    max-width: 45rem !important;
  }

  .lt-lg\:max-w-192 {
    max-width: 48rem !important;
  }

  .lt-lg\:max-w-200 {
    max-width: 50rem !important;
  }

  .lt-lg\:max-w-240 {
    max-width: 60rem !important;
  }

  .lt-lg\:max-w-256 {
    max-width: 64rem !important;
  }

  .lt-lg\:max-w-280 {
    max-width: 70rem !important;
  }

  .lt-lg\:max-w-320 {
    max-width: 80rem !important;
  }

  .lt-lg\:max-w-360 {
    max-width: 90rem !important;
  }

  .lt-lg\:max-w-400 {
    max-width: 100rem !important;
  }

  .lt-lg\:max-w-480 {
    max-width: 120rem !important;
  }

  .lt-lg\:max-w-none {
    max-width: none !important;
  }

  .lt-lg\:max-w-xs {
    max-width: 20rem !important;
  }

  .lt-lg\:max-w-sm {
    max-width: 24rem !important;
  }

  .lt-lg\:max-w-md {
    max-width: 28rem !important;
  }

  .lt-lg\:max-w-lg {
    max-width: 32rem !important;
  }

  .lt-lg\:max-w-xl {
    max-width: 36rem !important;
  }

  .lt-lg\:max-w-2xl {
    max-width: 42rem !important;
  }

  .lt-lg\:max-w-3xl {
    max-width: 48rem !important;
  }

  .lt-lg\:max-w-4xl {
    max-width: 56rem !important;
  }

  .lt-lg\:max-w-5xl {
    max-width: 64rem !important;
  }

  .lt-lg\:max-w-6xl {
    max-width: 72rem !important;
  }

  .lt-lg\:max-w-full {
    max-width: 100% !important;
  }

  .lt-lg\:max-w-screen {
    max-width: 100vw !important;
  }

  .lt-lg\:max-w-px {
    max-width: 1px !important;
  }

  .lt-lg\:max-w-2px {
    max-width: 2px !important;
  }

  .lt-lg\:max-w-1\/2 {
    max-width: 50% !important;
  }

  .lt-lg\:max-w-1\/3 {
    max-width: 33.33333% !important;
  }

  .lt-lg\:max-w-2\/3 {
    max-width: 66.66667% !important;
  }

  .lt-lg\:max-w-1\/4 {
    max-width: 25% !important;
  }

  .lt-lg\:max-w-2\/4 {
    max-width: 50% !important;
  }

  .lt-lg\:max-w-3\/4 {
    max-width: 75% !important;
  }

  .lt-lg\:max-w-1\/5 {
    max-width: 20% !important;
  }

  .lt-lg\:max-w-2\/5 {
    max-width: 40% !important;
  }

  .lt-lg\:max-w-3\/5 {
    max-width: 60% !important;
  }

  .lt-lg\:max-w-4\/5 {
    max-width: 80% !important;
  }

  .lt-lg\:max-w-1\/12 {
    max-width: 8.33333% !important;
  }

  .lt-lg\:max-w-2\/12 {
    max-width: 16.66667% !important;
  }

  .lt-lg\:max-w-3\/12 {
    max-width: 25% !important;
  }

  .lt-lg\:max-w-4\/12 {
    max-width: 33.33333% !important;
  }

  .lt-lg\:max-w-5\/12 {
    max-width: 41.66667% !important;
  }

  .lt-lg\:max-w-6\/12 {
    max-width: 50% !important;
  }

  .lt-lg\:max-w-7\/12 {
    max-width: 58.33333% !important;
  }

  .lt-lg\:max-w-8\/12 {
    max-width: 66.66667% !important;
  }

  .lt-lg\:max-w-9\/12 {
    max-width: 75% !important;
  }

  .lt-lg\:max-w-10\/12 {
    max-width: 83.33333% !important;
  }

  .lt-lg\:max-w-11\/12 {
    max-width: 91.66667% !important;
  }

  .lt-lg\:min-h-0 {
    min-height: 0 !important;
  }

  .lt-lg\:min-h-1 {
    min-height: 0.25rem !important;
  }

  .lt-lg\:min-h-2 {
    min-height: 0.5rem !important;
  }

  .lt-lg\:min-h-3 {
    min-height: 0.75rem !important;
  }

  .lt-lg\:min-h-4 {
    min-height: 1rem !important;
  }

  .lt-lg\:min-h-5 {
    min-height: 1.25rem !important;
  }

  .lt-lg\:min-h-6 {
    min-height: 1.5rem !important;
  }

  .lt-lg\:min-h-8 {
    min-height: 2rem !important;
  }

  .lt-lg\:min-h-10 {
    min-height: 2.5rem !important;
  }

  .lt-lg\:min-h-12 {
    min-height: 3rem !important;
  }

  .lt-lg\:min-h-14 {
    min-height: 3.5rem !important;
  }

  .lt-lg\:min-h-16 {
    min-height: 4rem !important;
  }

  .lt-lg\:min-h-18 {
    min-height: 4.5rem !important;
  }

  .lt-lg\:min-h-20 {
    min-height: 5rem !important;
  }

  .lt-lg\:min-h-22 {
    min-height: 5.5rem !important;
  }

  .lt-lg\:min-h-24 {
    min-height: 6rem !important;
  }

  .lt-lg\:min-h-26 {
    min-height: 6.5rem !important;
  }

  .lt-lg\:min-h-28 {
    min-height: 7rem !important;
  }

  .lt-lg\:min-h-30 {
    min-height: 7.5rem !important;
  }

  .lt-lg\:min-h-32 {
    min-height: 8rem !important;
  }

  .lt-lg\:min-h-36 {
    min-height: 9rem !important;
  }

  .lt-lg\:min-h-40 {
    min-height: 10rem !important;
  }

  .lt-lg\:min-h-48 {
    min-height: 12rem !important;
  }

  .lt-lg\:min-h-50 {
    min-height: 12.5rem !important;
  }

  .lt-lg\:min-h-56 {
    min-height: 14rem !important;
  }

  .lt-lg\:min-h-60 {
    min-height: 15rem !important;
  }

  .lt-lg\:min-h-64 {
    min-height: 16rem !important;
  }

  .lt-lg\:min-h-80 {
    min-height: 20rem !important;
  }

  .lt-lg\:min-h-90 {
    min-height: 24rem !important;
  }

  .lt-lg\:min-h-100 {
    min-height: 25rem !important;
  }

  .lt-lg\:min-h-120 {
    min-height: 30rem !important;
  }

  .lt-lg\:min-h-128 {
    min-height: 32rem !important;
  }

  .lt-lg\:min-h-140 {
    min-height: 35rem !important;
  }

  .lt-lg\:min-h-160 {
    min-height: 40rem !important;
  }

  .lt-lg\:min-h-180 {
    min-height: 45rem !important;
  }

  .lt-lg\:min-h-192 {
    min-height: 48rem !important;
  }

  .lt-lg\:min-h-200 {
    min-height: 50rem !important;
  }

  .lt-lg\:min-h-240 {
    min-height: 60rem !important;
  }

  .lt-lg\:min-h-256 {
    min-height: 64rem !important;
  }

  .lt-lg\:min-h-280 {
    min-height: 70rem !important;
  }

  .lt-lg\:min-h-320 {
    min-height: 80rem !important;
  }

  .lt-lg\:min-h-360 {
    min-height: 90rem !important;
  }

  .lt-lg\:min-h-400 {
    min-height: 100rem !important;
  }

  .lt-lg\:min-h-480 {
    min-height: 120rem !important;
  }

  .lt-lg\:min-h-full {
    min-height: 100% !important;
  }

  .lt-lg\:min-h-screen {
    min-height: 100vh !important;
  }

  .lt-lg\:min-h-px {
    min-height: 1px !important;
  }

  .lt-lg\:min-h-2px {
    min-height: 2px !important;
  }

  .lt-lg\:min-h-1\/2 {
    min-height: 50% !important;
  }

  .lt-lg\:min-h-1\/3 {
    min-height: 33.33333% !important;
  }

  .lt-lg\:min-h-2\/3 {
    min-height: 66.66667% !important;
  }

  .lt-lg\:min-h-1\/4 {
    min-height: 25% !important;
  }

  .lt-lg\:min-h-2\/4 {
    min-height: 50% !important;
  }

  .lt-lg\:min-h-3\/4 {
    min-height: 75% !important;
  }

  .lt-lg\:min-h-1\/5 {
    min-height: 20% !important;
  }

  .lt-lg\:min-h-2\/5 {
    min-height: 40% !important;
  }

  .lt-lg\:min-h-3\/5 {
    min-height: 60% !important;
  }

  .lt-lg\:min-h-4\/5 {
    min-height: 80% !important;
  }

  .lt-lg\:min-h-1\/12 {
    min-height: 8.33333% !important;
  }

  .lt-lg\:min-h-2\/12 {
    min-height: 16.66667% !important;
  }

  .lt-lg\:min-h-3\/12 {
    min-height: 25% !important;
  }

  .lt-lg\:min-h-4\/12 {
    min-height: 33.33333% !important;
  }

  .lt-lg\:min-h-5\/12 {
    min-height: 41.66667% !important;
  }

  .lt-lg\:min-h-6\/12 {
    min-height: 50% !important;
  }

  .lt-lg\:min-h-7\/12 {
    min-height: 58.33333% !important;
  }

  .lt-lg\:min-h-8\/12 {
    min-height: 66.66667% !important;
  }

  .lt-lg\:min-h-9\/12 {
    min-height: 75% !important;
  }

  .lt-lg\:min-h-10\/12 {
    min-height: 83.33333% !important;
  }

  .lt-lg\:min-h-11\/12 {
    min-height: 91.66667% !important;
  }

  .lt-lg\:min-w-0 {
    min-width: 0 !important;
  }

  .lt-lg\:min-w-1 {
    min-width: 0.25rem !important;
  }

  .lt-lg\:min-w-2 {
    min-width: 0.5rem !important;
  }

  .lt-lg\:min-w-3 {
    min-width: 0.75rem !important;
  }

  .lt-lg\:min-w-4 {
    min-width: 1rem !important;
  }

  .lt-lg\:min-w-5 {
    min-width: 1.25rem !important;
  }

  .lt-lg\:min-w-6 {
    min-width: 1.5rem !important;
  }

  .lt-lg\:min-w-8 {
    min-width: 2rem !important;
  }

  .lt-lg\:min-w-10 {
    min-width: 2.5rem !important;
  }

  .lt-lg\:min-w-12 {
    min-width: 3rem !important;
  }

  .lt-lg\:min-w-14 {
    min-width: 3.5rem !important;
  }

  .lt-lg\:min-w-16 {
    min-width: 4rem !important;
  }

  .lt-lg\:min-w-18 {
    min-width: 4.5rem !important;
  }

  .lt-lg\:min-w-20 {
    min-width: 5rem !important;
  }

  .lt-lg\:min-w-22 {
    min-width: 5.5rem !important;
  }

  .lt-lg\:min-w-24 {
    min-width: 6rem !important;
  }

  .lt-lg\:min-w-26 {
    min-width: 6.5rem !important;
  }

  .lt-lg\:min-w-28 {
    min-width: 7rem !important;
  }

  .lt-lg\:min-w-30 {
    min-width: 7.5rem !important;
  }

  .lt-lg\:min-w-32 {
    min-width: 8rem !important;
  }

  .lt-lg\:min-w-36 {
    min-width: 9rem !important;
  }

  .lt-lg\:min-w-40 {
    min-width: 10rem !important;
  }

  .lt-lg\:min-w-48 {
    min-width: 12rem !important;
  }

  .lt-lg\:min-w-50 {
    min-width: 12.5rem !important;
  }

  .lt-lg\:min-w-56 {
    min-width: 14rem !important;
  }

  .lt-lg\:min-w-60 {
    min-width: 15rem !important;
  }

  .lt-lg\:min-w-64 {
    min-width: 16rem !important;
  }

  .lt-lg\:min-w-80 {
    min-width: 20rem !important;
  }

  .lt-lg\:min-w-90 {
    min-width: 24rem !important;
  }

  .lt-lg\:min-w-100 {
    min-width: 25rem !important;
  }

  .lt-lg\:min-w-120 {
    min-width: 30rem !important;
  }

  .lt-lg\:min-w-128 {
    min-width: 32rem !important;
  }

  .lt-lg\:min-w-140 {
    min-width: 35rem !important;
  }

  .lt-lg\:min-w-160 {
    min-width: 40rem !important;
  }

  .lt-lg\:min-w-180 {
    min-width: 45rem !important;
  }

  .lt-lg\:min-w-192 {
    min-width: 48rem !important;
  }

  .lt-lg\:min-w-200 {
    min-width: 50rem !important;
  }

  .lt-lg\:min-w-240 {
    min-width: 60rem !important;
  }

  .lt-lg\:min-w-256 {
    min-width: 64rem !important;
  }

  .lt-lg\:min-w-280 {
    min-width: 70rem !important;
  }

  .lt-lg\:min-w-320 {
    min-width: 80rem !important;
  }

  .lt-lg\:min-w-360 {
    min-width: 90rem !important;
  }

  .lt-lg\:min-w-400 {
    min-width: 100rem !important;
  }

  .lt-lg\:min-w-480 {
    min-width: 120rem !important;
  }

  .lt-lg\:min-w-full {
    min-width: 100% !important;
  }

  .lt-lg\:min-w-screen {
    min-width: 100vw !important;
  }

  .lt-lg\:min-w-px {
    min-width: 1px !important;
  }

  .lt-lg\:min-w-2px {
    min-width: 2px !important;
  }

  .lt-lg\:min-w-1\/2 {
    min-width: 50% !important;
  }

  .lt-lg\:min-w-1\/3 {
    min-width: 33.33333% !important;
  }

  .lt-lg\:min-w-2\/3 {
    min-width: 66.66667% !important;
  }

  .lt-lg\:min-w-1\/4 {
    min-width: 25% !important;
  }

  .lt-lg\:min-w-2\/4 {
    min-width: 50% !important;
  }

  .lt-lg\:min-w-3\/4 {
    min-width: 75% !important;
  }

  .lt-lg\:min-w-1\/5 {
    min-width: 20% !important;
  }

  .lt-lg\:min-w-2\/5 {
    min-width: 40% !important;
  }

  .lt-lg\:min-w-3\/5 {
    min-width: 60% !important;
  }

  .lt-lg\:min-w-4\/5 {
    min-width: 80% !important;
  }

  .lt-lg\:min-w-1\/12 {
    min-width: 8.33333% !important;
  }

  .lt-lg\:min-w-2\/12 {
    min-width: 16.66667% !important;
  }

  .lt-lg\:min-w-3\/12 {
    min-width: 25% !important;
  }

  .lt-lg\:min-w-4\/12 {
    min-width: 33.33333% !important;
  }

  .lt-lg\:min-w-5\/12 {
    min-width: 41.66667% !important;
  }

  .lt-lg\:min-w-6\/12 {
    min-width: 50% !important;
  }

  .lt-lg\:min-w-7\/12 {
    min-width: 58.33333% !important;
  }

  .lt-lg\:min-w-8\/12 {
    min-width: 66.66667% !important;
  }

  .lt-lg\:min-w-9\/12 {
    min-width: 75% !important;
  }

  .lt-lg\:min-w-10\/12 {
    min-width: 83.33333% !important;
  }

  .lt-lg\:min-w-11\/12 {
    min-width: 91.66667% !important;
  }

  .lt-lg\:object-contain {
    -o-object-fit: contain !important;
       object-fit: contain !important;
  }

  .lt-lg\:object-cover {
    -o-object-fit: cover !important;
       object-fit: cover !important;
  }

  .lt-lg\:object-fill {
    -o-object-fit: fill !important;
       object-fit: fill !important;
  }

  .lt-lg\:object-none {
    -o-object-fit: none !important;
       object-fit: none !important;
  }

  .lt-lg\:object-scale-down {
    -o-object-fit: scale-down !important;
       object-fit: scale-down !important;
  }

  .lt-lg\:object-bottom {
    -o-object-position: bottom !important;
       object-position: bottom !important;
  }

  .lt-lg\:object-center {
    -o-object-position: center !important;
       object-position: center !important;
  }

  .lt-lg\:object-left {
    -o-object-position: left !important;
       object-position: left !important;
  }

  .lt-lg\:object-left-bottom {
    -o-object-position: left bottom !important;
       object-position: left bottom !important;
  }

  .lt-lg\:object-left-top {
    -o-object-position: left top !important;
       object-position: left top !important;
  }

  .lt-lg\:object-right {
    -o-object-position: right !important;
       object-position: right !important;
  }

  .lt-lg\:object-right-bottom {
    -o-object-position: right bottom !important;
       object-position: right bottom !important;
  }

  .lt-lg\:object-right-top {
    -o-object-position: right top !important;
       object-position: right top !important;
  }

  .lt-lg\:object-top {
    -o-object-position: top !important;
       object-position: top !important;
  }

  .lt-lg\:opacity-0 {
    opacity: 0 !important;
  }

  .lt-lg\:opacity-12 {
    opacity: 0.12 !important;
  }

  .lt-lg\:opacity-25 {
    opacity: 0.25 !important;
  }

  .lt-lg\:opacity-38 {
    opacity: 0.38 !important;
  }

  .lt-lg\:opacity-50 {
    opacity: 0.5 !important;
  }

  .lt-lg\:opacity-54 {
    opacity: 0.54 !important;
  }

  .lt-lg\:opacity-70 {
    opacity: 0.70 !important;
  }

  .lt-lg\:opacity-75 {
    opacity: 0.75 !important;
  }

  .lt-lg\:opacity-84 {
    opacity: 0.84 !important;
  }

  .lt-lg\:opacity-100 {
    opacity: 1 !important;
  }

  .lt-lg\:hover\:opacity-0:hover {
    opacity: 0 !important;
  }

  .lt-lg\:hover\:opacity-12:hover {
    opacity: 0.12 !important;
  }

  .lt-lg\:hover\:opacity-25:hover {
    opacity: 0.25 !important;
  }

  .lt-lg\:hover\:opacity-38:hover {
    opacity: 0.38 !important;
  }

  .lt-lg\:hover\:opacity-50:hover {
    opacity: 0.5 !important;
  }

  .lt-lg\:hover\:opacity-54:hover {
    opacity: 0.54 !important;
  }

  .lt-lg\:hover\:opacity-70:hover {
    opacity: 0.70 !important;
  }

  .lt-lg\:hover\:opacity-75:hover {
    opacity: 0.75 !important;
  }

  .lt-lg\:hover\:opacity-84:hover {
    opacity: 0.84 !important;
  }

  .lt-lg\:hover\:opacity-100:hover {
    opacity: 1 !important;
  }

  .lt-lg\:focus\:opacity-0:focus {
    opacity: 0 !important;
  }

  .lt-lg\:focus\:opacity-12:focus {
    opacity: 0.12 !important;
  }

  .lt-lg\:focus\:opacity-25:focus {
    opacity: 0.25 !important;
  }

  .lt-lg\:focus\:opacity-38:focus {
    opacity: 0.38 !important;
  }

  .lt-lg\:focus\:opacity-50:focus {
    opacity: 0.5 !important;
  }

  .lt-lg\:focus\:opacity-54:focus {
    opacity: 0.54 !important;
  }

  .lt-lg\:focus\:opacity-70:focus {
    opacity: 0.70 !important;
  }

  .lt-lg\:focus\:opacity-75:focus {
    opacity: 0.75 !important;
  }

  .lt-lg\:focus\:opacity-84:focus {
    opacity: 0.84 !important;
  }

  .lt-lg\:focus\:opacity-100:focus {
    opacity: 1 !important;
  }

  .lt-lg\:outline-none {
    outline: 0 !important;
  }

  .lt-lg\:focus\:outline-none:focus {
    outline: 0 !important;
  }

  .lt-lg\:overflow-auto {
    overflow: auto !important;
  }

  .lt-lg\:overflow-hidden {
    overflow: hidden !important;
  }

  .lt-lg\:overflow-visible {
    overflow: visible !important;
  }

  .lt-lg\:overflow-scroll {
    overflow: scroll !important;
  }

  .lt-lg\:overflow-x-auto {
    overflow-x: auto !important;
  }

  .lt-lg\:overflow-y-auto {
    overflow-y: auto !important;
  }

  .lt-lg\:overflow-x-hidden {
    overflow-x: hidden !important;
  }

  .lt-lg\:overflow-y-hidden {
    overflow-y: hidden !important;
  }

  .lt-lg\:overflow-x-visible {
    overflow-x: visible !important;
  }

  .lt-lg\:overflow-y-visible {
    overflow-y: visible !important;
  }

  .lt-lg\:overflow-x-scroll {
    overflow-x: scroll !important;
  }

  .lt-lg\:overflow-y-scroll {
    overflow-y: scroll !important;
  }

  .lt-lg\:scrolling-touch {
    -webkit-overflow-scrolling: touch !important;
  }

  .lt-lg\:scrolling-auto {
    -webkit-overflow-scrolling: auto !important;
  }

  .lt-lg\:p-0 {
    padding: 0 !important;
  }

  .lt-lg\:p-1 {
    padding: 0.25rem !important;
  }

  .lt-lg\:p-2 {
    padding: 0.5rem !important;
  }

  .lt-lg\:p-3 {
    padding: 0.75rem !important;
  }

  .lt-lg\:p-4 {
    padding: 1rem !important;
  }

  .lt-lg\:p-5 {
    padding: 1.25rem !important;
  }

  .lt-lg\:p-6 {
    padding: 1.5rem !important;
  }

  .lt-lg\:p-8 {
    padding: 2rem !important;
  }

  .lt-lg\:p-10 {
    padding: 2.5rem !important;
  }

  .lt-lg\:p-12 {
    padding: 3rem !important;
  }

  .lt-lg\:p-14 {
    padding: 3.5rem !important;
  }

  .lt-lg\:p-16 {
    padding: 4rem !important;
  }

  .lt-lg\:p-18 {
    padding: 4.5rem !important;
  }

  .lt-lg\:p-20 {
    padding: 5rem !important;
  }

  .lt-lg\:p-22 {
    padding: 5.5rem !important;
  }

  .lt-lg\:p-24 {
    padding: 6rem !important;
  }

  .lt-lg\:p-26 {
    padding: 6.5rem !important;
  }

  .lt-lg\:p-28 {
    padding: 7rem !important;
  }

  .lt-lg\:p-30 {
    padding: 7.5rem !important;
  }

  .lt-lg\:p-32 {
    padding: 8rem !important;
  }

  .lt-lg\:p-36 {
    padding: 9rem !important;
  }

  .lt-lg\:p-40 {
    padding: 10rem !important;
  }

  .lt-lg\:p-48 {
    padding: 12rem !important;
  }

  .lt-lg\:p-56 {
    padding: 14rem !important;
  }

  .lt-lg\:p-64 {
    padding: 16rem !important;
  }

  .lt-lg\:p-px {
    padding: 1px !important;
  }

  .lt-lg\:p-2px {
    padding: 2px !important;
  }

  .lt-lg\:py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .lt-lg\:px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .lt-lg\:py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .lt-lg\:px-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }

  .lt-lg\:py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .lt-lg\:px-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .lt-lg\:py-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .lt-lg\:px-3 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }

  .lt-lg\:py-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .lt-lg\:px-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .lt-lg\:py-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .lt-lg\:px-5 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }

  .lt-lg\:py-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .lt-lg\:px-6 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .lt-lg\:py-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .lt-lg\:px-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .lt-lg\:py-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .lt-lg\:px-10 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .lt-lg\:py-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .lt-lg\:px-12 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .lt-lg\:py-14 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .lt-lg\:px-14 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }

  .lt-lg\:py-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .lt-lg\:px-16 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .lt-lg\:py-18 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .lt-lg\:px-18 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }

  .lt-lg\:py-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .lt-lg\:px-20 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .lt-lg\:py-22 {
    padding-top: 5.5rem !important;
    padding-bottom: 5.5rem !important;
  }

  .lt-lg\:px-22 {
    padding-left: 5.5rem !important;
    padding-right: 5.5rem !important;
  }

  .lt-lg\:py-24 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .lt-lg\:px-24 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }

  .lt-lg\:py-26 {
    padding-top: 6.5rem !important;
    padding-bottom: 6.5rem !important;
  }

  .lt-lg\:px-26 {
    padding-left: 6.5rem !important;
    padding-right: 6.5rem !important;
  }

  .lt-lg\:py-28 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }

  .lt-lg\:px-28 {
    padding-left: 7rem !important;
    padding-right: 7rem !important;
  }

  .lt-lg\:py-30 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }

  .lt-lg\:px-30 {
    padding-left: 7.5rem !important;
    padding-right: 7.5rem !important;
  }

  .lt-lg\:py-32 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .lt-lg\:px-32 {
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }

  .lt-lg\:py-36 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }

  .lt-lg\:px-36 {
    padding-left: 9rem !important;
    padding-right: 9rem !important;
  }

  .lt-lg\:py-40 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .lt-lg\:px-40 {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }

  .lt-lg\:py-48 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }

  .lt-lg\:px-48 {
    padding-left: 12rem !important;
    padding-right: 12rem !important;
  }

  .lt-lg\:py-56 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }

  .lt-lg\:px-56 {
    padding-left: 14rem !important;
    padding-right: 14rem !important;
  }

  .lt-lg\:py-64 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }

  .lt-lg\:px-64 {
    padding-left: 16rem !important;
    padding-right: 16rem !important;
  }

  .lt-lg\:py-px {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }

  .lt-lg\:px-px {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }

  .lt-lg\:py-2px {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }

  .lt-lg\:px-2px {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }

  .lt-lg\:pt-0 {
    padding-top: 0 !important;
  }

  .lt-lg\:pr-0 {
    padding-right: 0 !important;
  }

  .lt-lg\:pb-0 {
    padding-bottom: 0 !important;
  }

  .lt-lg\:pl-0 {
    padding-left: 0 !important;
  }

  .lt-lg\:pt-1 {
    padding-top: 0.25rem !important;
  }

  .lt-lg\:pr-1 {
    padding-right: 0.25rem !important;
  }

  .lt-lg\:pb-1 {
    padding-bottom: 0.25rem !important;
  }

  .lt-lg\:pl-1 {
    padding-left: 0.25rem !important;
  }

  .lt-lg\:pt-2 {
    padding-top: 0.5rem !important;
  }

  .lt-lg\:pr-2 {
    padding-right: 0.5rem !important;
  }

  .lt-lg\:pb-2 {
    padding-bottom: 0.5rem !important;
  }

  .lt-lg\:pl-2 {
    padding-left: 0.5rem !important;
  }

  .lt-lg\:pt-3 {
    padding-top: 0.75rem !important;
  }

  .lt-lg\:pr-3 {
    padding-right: 0.75rem !important;
  }

  .lt-lg\:pb-3 {
    padding-bottom: 0.75rem !important;
  }

  .lt-lg\:pl-3 {
    padding-left: 0.75rem !important;
  }

  .lt-lg\:pt-4 {
    padding-top: 1rem !important;
  }

  .lt-lg\:pr-4 {
    padding-right: 1rem !important;
  }

  .lt-lg\:pb-4 {
    padding-bottom: 1rem !important;
  }

  .lt-lg\:pl-4 {
    padding-left: 1rem !important;
  }

  .lt-lg\:pt-5 {
    padding-top: 1.25rem !important;
  }

  .lt-lg\:pr-5 {
    padding-right: 1.25rem !important;
  }

  .lt-lg\:pb-5 {
    padding-bottom: 1.25rem !important;
  }

  .lt-lg\:pl-5 {
    padding-left: 1.25rem !important;
  }

  .lt-lg\:pt-6 {
    padding-top: 1.5rem !important;
  }

  .lt-lg\:pr-6 {
    padding-right: 1.5rem !important;
  }

  .lt-lg\:pb-6 {
    padding-bottom: 1.5rem !important;
  }

  .lt-lg\:pl-6 {
    padding-left: 1.5rem !important;
  }

  .lt-lg\:pt-8 {
    padding-top: 2rem !important;
  }

  .lt-lg\:pr-8 {
    padding-right: 2rem !important;
  }

  .lt-lg\:pb-8 {
    padding-bottom: 2rem !important;
  }

  .lt-lg\:pl-8 {
    padding-left: 2rem !important;
  }

  .lt-lg\:pt-10 {
    padding-top: 2.5rem !important;
  }

  .lt-lg\:pr-10 {
    padding-right: 2.5rem !important;
  }

  .lt-lg\:pb-10 {
    padding-bottom: 2.5rem !important;
  }

  .lt-lg\:pl-10 {
    padding-left: 2.5rem !important;
  }

  .lt-lg\:pt-12 {
    padding-top: 3rem !important;
  }

  .lt-lg\:pr-12 {
    padding-right: 3rem !important;
  }

  .lt-lg\:pb-12 {
    padding-bottom: 3rem !important;
  }

  .lt-lg\:pl-12 {
    padding-left: 3rem !important;
  }

  .lt-lg\:pt-14 {
    padding-top: 3.5rem !important;
  }

  .lt-lg\:pr-14 {
    padding-right: 3.5rem !important;
  }

  .lt-lg\:pb-14 {
    padding-bottom: 3.5rem !important;
  }

  .lt-lg\:pl-14 {
    padding-left: 3.5rem !important;
  }

  .lt-lg\:pt-16 {
    padding-top: 4rem !important;
  }

  .lt-lg\:pr-16 {
    padding-right: 4rem !important;
  }

  .lt-lg\:pb-16 {
    padding-bottom: 4rem !important;
  }

  .lt-lg\:pl-16 {
    padding-left: 4rem !important;
  }

  .lt-lg\:pt-18 {
    padding-top: 4.5rem !important;
  }

  .lt-lg\:pr-18 {
    padding-right: 4.5rem !important;
  }

  .lt-lg\:pb-18 {
    padding-bottom: 4.5rem !important;
  }

  .lt-lg\:pl-18 {
    padding-left: 4.5rem !important;
  }

  .lt-lg\:pt-20 {
    padding-top: 5rem !important;
  }

  .lt-lg\:pr-20 {
    padding-right: 5rem !important;
  }

  .lt-lg\:pb-20 {
    padding-bottom: 5rem !important;
  }

  .lt-lg\:pl-20 {
    padding-left: 5rem !important;
  }

  .lt-lg\:pt-22 {
    padding-top: 5.5rem !important;
  }

  .lt-lg\:pr-22 {
    padding-right: 5.5rem !important;
  }

  .lt-lg\:pb-22 {
    padding-bottom: 5.5rem !important;
  }

  .lt-lg\:pl-22 {
    padding-left: 5.5rem !important;
  }

  .lt-lg\:pt-24 {
    padding-top: 6rem !important;
  }

  .lt-lg\:pr-24 {
    padding-right: 6rem !important;
  }

  .lt-lg\:pb-24 {
    padding-bottom: 6rem !important;
  }

  .lt-lg\:pl-24 {
    padding-left: 6rem !important;
  }

  .lt-lg\:pt-26 {
    padding-top: 6.5rem !important;
  }

  .lt-lg\:pr-26 {
    padding-right: 6.5rem !important;
  }

  .lt-lg\:pb-26 {
    padding-bottom: 6.5rem !important;
  }

  .lt-lg\:pl-26 {
    padding-left: 6.5rem !important;
  }

  .lt-lg\:pt-28 {
    padding-top: 7rem !important;
  }

  .lt-lg\:pr-28 {
    padding-right: 7rem !important;
  }

  .lt-lg\:pb-28 {
    padding-bottom: 7rem !important;
  }

  .lt-lg\:pl-28 {
    padding-left: 7rem !important;
  }

  .lt-lg\:pt-30 {
    padding-top: 7.5rem !important;
  }

  .lt-lg\:pr-30 {
    padding-right: 7.5rem !important;
  }

  .lt-lg\:pb-30 {
    padding-bottom: 7.5rem !important;
  }

  .lt-lg\:pl-30 {
    padding-left: 7.5rem !important;
  }

  .lt-lg\:pt-32 {
    padding-top: 8rem !important;
  }

  .lt-lg\:pr-32 {
    padding-right: 8rem !important;
  }

  .lt-lg\:pb-32 {
    padding-bottom: 8rem !important;
  }

  .lt-lg\:pl-32 {
    padding-left: 8rem !important;
  }

  .lt-lg\:pt-36 {
    padding-top: 9rem !important;
  }

  .lt-lg\:pr-36 {
    padding-right: 9rem !important;
  }

  .lt-lg\:pb-36 {
    padding-bottom: 9rem !important;
  }

  .lt-lg\:pl-36 {
    padding-left: 9rem !important;
  }

  .lt-lg\:pt-40 {
    padding-top: 10rem !important;
  }

  .lt-lg\:pr-40 {
    padding-right: 10rem !important;
  }

  .lt-lg\:pb-40 {
    padding-bottom: 10rem !important;
  }

  .lt-lg\:pl-40 {
    padding-left: 10rem !important;
  }

  .lt-lg\:pt-48 {
    padding-top: 12rem !important;
  }

  .lt-lg\:pr-48 {
    padding-right: 12rem !important;
  }

  .lt-lg\:pb-48 {
    padding-bottom: 12rem !important;
  }

  .lt-lg\:pl-48 {
    padding-left: 12rem !important;
  }

  .lt-lg\:pt-56 {
    padding-top: 14rem !important;
  }

  .lt-lg\:pr-56 {
    padding-right: 14rem !important;
  }

  .lt-lg\:pb-56 {
    padding-bottom: 14rem !important;
  }

  .lt-lg\:pl-56 {
    padding-left: 14rem !important;
  }

  .lt-lg\:pt-64 {
    padding-top: 16rem !important;
  }

  .lt-lg\:pr-64 {
    padding-right: 16rem !important;
  }

  .lt-lg\:pb-64 {
    padding-bottom: 16rem !important;
  }

  .lt-lg\:pl-64 {
    padding-left: 16rem !important;
  }

  .lt-lg\:pt-px {
    padding-top: 1px !important;
  }

  .lt-lg\:pr-px {
    padding-right: 1px !important;
  }

  .lt-lg\:pb-px {
    padding-bottom: 1px !important;
  }

  .lt-lg\:pl-px {
    padding-left: 1px !important;
  }

  .lt-lg\:pt-2px {
    padding-top: 2px !important;
  }

  .lt-lg\:pr-2px {
    padding-right: 2px !important;
  }

  .lt-lg\:pb-2px {
    padding-bottom: 2px !important;
  }

  .lt-lg\:pl-2px {
    padding-left: 2px !important;
  }

  .lt-lg\:placeholder-opacity-0::-webkit-input-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .lt-lg\:placeholder-opacity-0::-moz-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .lt-lg\:placeholder-opacity-0::-ms-input-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .lt-lg\:placeholder-opacity-0::placeholder {
    --placeholder-opacity: 0 !important;
  }

  .lt-lg\:placeholder-opacity-12::-webkit-input-placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .lt-lg\:placeholder-opacity-12::-moz-placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .lt-lg\:placeholder-opacity-12::-ms-input-placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .lt-lg\:placeholder-opacity-12::placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .lt-lg\:placeholder-opacity-25::-webkit-input-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .lt-lg\:placeholder-opacity-25::-moz-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .lt-lg\:placeholder-opacity-25::-ms-input-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .lt-lg\:placeholder-opacity-25::placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .lt-lg\:placeholder-opacity-38::-webkit-input-placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .lt-lg\:placeholder-opacity-38::-moz-placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .lt-lg\:placeholder-opacity-38::-ms-input-placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .lt-lg\:placeholder-opacity-38::placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .lt-lg\:placeholder-opacity-50::-webkit-input-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .lt-lg\:placeholder-opacity-50::-moz-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .lt-lg\:placeholder-opacity-50::-ms-input-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .lt-lg\:placeholder-opacity-50::placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .lt-lg\:placeholder-opacity-54::-webkit-input-placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .lt-lg\:placeholder-opacity-54::-moz-placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .lt-lg\:placeholder-opacity-54::-ms-input-placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .lt-lg\:placeholder-opacity-54::placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .lt-lg\:placeholder-opacity-70::-webkit-input-placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .lt-lg\:placeholder-opacity-70::-moz-placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .lt-lg\:placeholder-opacity-70::-ms-input-placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .lt-lg\:placeholder-opacity-70::placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .lt-lg\:placeholder-opacity-75::-webkit-input-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .lt-lg\:placeholder-opacity-75::-moz-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .lt-lg\:placeholder-opacity-75::-ms-input-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .lt-lg\:placeholder-opacity-75::placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .lt-lg\:placeholder-opacity-84::-webkit-input-placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .lt-lg\:placeholder-opacity-84::-moz-placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .lt-lg\:placeholder-opacity-84::-ms-input-placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .lt-lg\:placeholder-opacity-84::placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .lt-lg\:placeholder-opacity-100::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .lt-lg\:placeholder-opacity-100::-moz-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .lt-lg\:placeholder-opacity-100::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .lt-lg\:placeholder-opacity-100::placeholder {
    --placeholder-opacity: 1 !important;
  }

  .lt-lg\:focus\:placeholder-opacity-0:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .lt-lg\:focus\:placeholder-opacity-0:focus::-moz-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .lt-lg\:focus\:placeholder-opacity-0:focus::-ms-input-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .lt-lg\:focus\:placeholder-opacity-0:focus::placeholder {
    --placeholder-opacity: 0 !important;
  }

  .lt-lg\:focus\:placeholder-opacity-12:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .lt-lg\:focus\:placeholder-opacity-12:focus::-moz-placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .lt-lg\:focus\:placeholder-opacity-12:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .lt-lg\:focus\:placeholder-opacity-12:focus::placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .lt-lg\:focus\:placeholder-opacity-25:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .lt-lg\:focus\:placeholder-opacity-25:focus::-moz-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .lt-lg\:focus\:placeholder-opacity-25:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .lt-lg\:focus\:placeholder-opacity-25:focus::placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .lt-lg\:focus\:placeholder-opacity-38:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .lt-lg\:focus\:placeholder-opacity-38:focus::-moz-placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .lt-lg\:focus\:placeholder-opacity-38:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .lt-lg\:focus\:placeholder-opacity-38:focus::placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .lt-lg\:focus\:placeholder-opacity-50:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .lt-lg\:focus\:placeholder-opacity-50:focus::-moz-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .lt-lg\:focus\:placeholder-opacity-50:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .lt-lg\:focus\:placeholder-opacity-50:focus::placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .lt-lg\:focus\:placeholder-opacity-54:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .lt-lg\:focus\:placeholder-opacity-54:focus::-moz-placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .lt-lg\:focus\:placeholder-opacity-54:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .lt-lg\:focus\:placeholder-opacity-54:focus::placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .lt-lg\:focus\:placeholder-opacity-70:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .lt-lg\:focus\:placeholder-opacity-70:focus::-moz-placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .lt-lg\:focus\:placeholder-opacity-70:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .lt-lg\:focus\:placeholder-opacity-70:focus::placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .lt-lg\:focus\:placeholder-opacity-75:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .lt-lg\:focus\:placeholder-opacity-75:focus::-moz-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .lt-lg\:focus\:placeholder-opacity-75:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .lt-lg\:focus\:placeholder-opacity-75:focus::placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .lt-lg\:focus\:placeholder-opacity-84:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .lt-lg\:focus\:placeholder-opacity-84:focus::-moz-placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .lt-lg\:focus\:placeholder-opacity-84:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .lt-lg\:focus\:placeholder-opacity-84:focus::placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .lt-lg\:focus\:placeholder-opacity-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .lt-lg\:focus\:placeholder-opacity-100:focus::-moz-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .lt-lg\:focus\:placeholder-opacity-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .lt-lg\:focus\:placeholder-opacity-100:focus::placeholder {
    --placeholder-opacity: 1 !important;
  }

  .lt-lg\:pointer-events-none {
    pointer-events: none !important;
  }

  .lt-lg\:pointer-events-auto {
    pointer-events: auto !important;
  }

  .lt-lg\:static {
    position: static !important;
  }

  .lt-lg\:fixed {
    position: fixed !important;
  }

  .lt-lg\:absolute {
    position: absolute !important;
  }

  .lt-lg\:relative {
    position: relative !important;
  }

  .lt-lg\:sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }

  .lt-lg\:inset-0 {
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
  }

  .lt-lg\:inset-auto {
    top: auto !important;
    right: auto !important;
    bottom: auto !important;
    left: auto !important;
  }

  .lt-lg\:inset-y-0 {
    top: 0 !important;
    bottom: 0 !important;
  }

  .lt-lg\:inset-x-0 {
    right: 0 !important;
    left: 0 !important;
  }

  .lt-lg\:inset-y-auto {
    top: auto !important;
    bottom: auto !important;
  }

  .lt-lg\:inset-x-auto {
    right: auto !important;
    left: auto !important;
  }

  .lt-lg\:top-0 {
    top: 0 !important;
  }

  .lt-lg\:right-0 {
    right: 0 !important;
  }

  .lt-lg\:bottom-0 {
    bottom: 0 !important;
  }

  .lt-lg\:left-0 {
    left: 0 !important;
  }

  .lt-lg\:top-auto {
    top: auto !important;
  }

  .lt-lg\:right-auto {
    right: auto !important;
  }

  .lt-lg\:bottom-auto {
    bottom: auto !important;
  }

  .lt-lg\:left-auto {
    left: auto !important;
  }

  .lt-lg\:shadow-xs {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05) !important;
  }

  .lt-lg\:shadow-sm {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  }

  .lt-lg\:shadow {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .lt-lg\:shadow-md {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  }

  .lt-lg\:shadow-lg {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  }

  .lt-lg\:shadow-xl {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
  }

  .lt-lg\:shadow-2xl {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
  }

  .lt-lg\:shadow-inner {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .lt-lg\:ring {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }

  .lt-lg\:shadow-none {
    box-shadow: none !important;
  }

  .lt-lg\:shadow-solid {
    box-shadow: 0 0 0 2px currentColor !important;
  }

  .lt-lg\:hover\:shadow-xs:hover {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05) !important;
  }

  .lt-lg\:hover\:shadow-sm:hover {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  }

  .lt-lg\:hover\:shadow:hover {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .lt-lg\:hover\:shadow-md:hover {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  }

  .lt-lg\:hover\:shadow-lg:hover {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  }

  .lt-lg\:hover\:shadow-xl:hover {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
  }

  .lt-lg\:hover\:shadow-2xl:hover {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
  }

  .lt-lg\:hover\:shadow-inner:hover {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .lt-lg\:hover\:ring:hover {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }

  .lt-lg\:hover\:shadow-none:hover {
    box-shadow: none !important;
  }

  .lt-lg\:hover\:shadow-solid:hover {
    box-shadow: 0 0 0 2px currentColor !important;
  }

  .lt-lg\:focus\:shadow-xs:focus {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05) !important;
  }

  .lt-lg\:focus\:shadow-sm:focus {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  }

  .lt-lg\:focus\:shadow:focus {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .lt-lg\:focus\:shadow-md:focus {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  }

  .lt-lg\:focus\:shadow-lg:focus {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  }

  .lt-lg\:focus\:shadow-xl:focus {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
  }

  .lt-lg\:focus\:shadow-2xl:focus {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
  }

  .lt-lg\:focus\:shadow-inner:focus {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .lt-lg\:focus\:ring:focus {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }

  .lt-lg\:focus\:shadow-none:focus {
    box-shadow: none !important;
  }

  .lt-lg\:focus\:shadow-solid:focus {
    box-shadow: 0 0 0 2px currentColor !important;
  }

  .lt-lg\:fill-current {
    fill: currentColor !important;
  }

  .lt-lg\:stroke-current {
    stroke: currentColor !important;
  }

  .lt-lg\:stroke-0 {
    stroke-width: 0 !important;
  }

  .lt-lg\:stroke-1 {
    stroke-width: 1 !important;
  }

  .lt-lg\:stroke-2 {
    stroke-width: 2 !important;
  }

  .lt-lg\:table-auto {
    table-layout: auto !important;
  }

  .lt-lg\:table-fixed {
    table-layout: fixed !important;
  }

  .lt-lg\:text-left {
    text-align: left !important;
  }

  .lt-lg\:text-center {
    text-align: center !important;
  }

  .lt-lg\:text-right {
    text-align: right !important;
  }

  .lt-lg\:text-justify {
    text-align: justify !important;
  }

  .lt-lg\:text-opacity-0 {
    --text-opacity: 0 !important;
  }

  .lt-lg\:text-opacity-12 {
    --text-opacity: 0.12 !important;
  }

  .lt-lg\:text-opacity-25 {
    --text-opacity: 0.25 !important;
  }

  .lt-lg\:text-opacity-38 {
    --text-opacity: 0.38 !important;
  }

  .lt-lg\:text-opacity-50 {
    --text-opacity: 0.5 !important;
  }

  .lt-lg\:text-opacity-54 {
    --text-opacity: 0.54 !important;
  }

  .lt-lg\:text-opacity-70 {
    --text-opacity: 0.70 !important;
  }

  .lt-lg\:text-opacity-75 {
    --text-opacity: 0.75 !important;
  }

  .lt-lg\:text-opacity-84 {
    --text-opacity: 0.84 !important;
  }

  .lt-lg\:text-opacity-100 {
    --text-opacity: 1 !important;
  }

  .lt-lg\:hover\:text-opacity-0:hover {
    --text-opacity: 0 !important;
  }

  .lt-lg\:hover\:text-opacity-12:hover {
    --text-opacity: 0.12 !important;
  }

  .lt-lg\:hover\:text-opacity-25:hover {
    --text-opacity: 0.25 !important;
  }

  .lt-lg\:hover\:text-opacity-38:hover {
    --text-opacity: 0.38 !important;
  }

  .lt-lg\:hover\:text-opacity-50:hover {
    --text-opacity: 0.5 !important;
  }

  .lt-lg\:hover\:text-opacity-54:hover {
    --text-opacity: 0.54 !important;
  }

  .lt-lg\:hover\:text-opacity-70:hover {
    --text-opacity: 0.70 !important;
  }

  .lt-lg\:hover\:text-opacity-75:hover {
    --text-opacity: 0.75 !important;
  }

  .lt-lg\:hover\:text-opacity-84:hover {
    --text-opacity: 0.84 !important;
  }

  .lt-lg\:hover\:text-opacity-100:hover {
    --text-opacity: 1 !important;
  }

  .lt-lg\:focus\:text-opacity-0:focus {
    --text-opacity: 0 !important;
  }

  .lt-lg\:focus\:text-opacity-12:focus {
    --text-opacity: 0.12 !important;
  }

  .lt-lg\:focus\:text-opacity-25:focus {
    --text-opacity: 0.25 !important;
  }

  .lt-lg\:focus\:text-opacity-38:focus {
    --text-opacity: 0.38 !important;
  }

  .lt-lg\:focus\:text-opacity-50:focus {
    --text-opacity: 0.5 !important;
  }

  .lt-lg\:focus\:text-opacity-54:focus {
    --text-opacity: 0.54 !important;
  }

  .lt-lg\:focus\:text-opacity-70:focus {
    --text-opacity: 0.70 !important;
  }

  .lt-lg\:focus\:text-opacity-75:focus {
    --text-opacity: 0.75 !important;
  }

  .lt-lg\:focus\:text-opacity-84:focus {
    --text-opacity: 0.84 !important;
  }

  .lt-lg\:focus\:text-opacity-100:focus {
    --text-opacity: 1 !important;
  }

  .lt-lg\:italic {
    font-style: italic !important;
  }

  .lt-lg\:not-italic {
    font-style: normal !important;
  }

  .lt-lg\:uppercase {
    text-transform: uppercase !important;
  }

  .lt-lg\:lowercase {
    text-transform: lowercase !important;
  }

  .lt-lg\:capitalize {
    text-transform: capitalize !important;
  }

  .lt-lg\:normal-case {
    text-transform: none !important;
  }

  .lt-lg\:underline {
    text-decoration: underline !important;
  }

  .lt-lg\:line-through {
    text-decoration: line-through !important;
  }

  .lt-lg\:no-underline {
    text-decoration: none !important;
  }

  .lt-lg\:hover\:underline:hover {
    text-decoration: underline !important;
  }

  .lt-lg\:hover\:line-through:hover {
    text-decoration: line-through !important;
  }

  .lt-lg\:hover\:no-underline:hover {
    text-decoration: none !important;
  }

  .lt-lg\:focus\:underline:focus {
    text-decoration: underline !important;
  }

  .lt-lg\:focus\:line-through:focus {
    text-decoration: line-through !important;
  }

  .lt-lg\:focus\:no-underline:focus {
    text-decoration: none !important;
  }

  .lt-lg\:tracking-tighter {
    letter-spacing: -0.05em !important;
  }

  .lt-lg\:tracking-tight {
    letter-spacing: -0.025em !important;
  }

  .lt-lg\:tracking-normal {
    letter-spacing: 0 !important;
  }

  .lt-lg\:tracking-wide {
    letter-spacing: 0.025em !important;
  }

  .lt-lg\:tracking-wider {
    letter-spacing: 0.05em !important;
  }

  .lt-lg\:tracking-widest {
    letter-spacing: 0.1em !important;
  }

  .lt-lg\:select-none {
    -webkit-user-select: none !important;
       -moz-user-select: none !important;
        -ms-user-select: none !important;
            user-select: none !important;
  }

  .lt-lg\:select-text {
    -webkit-user-select: text !important;
       -moz-user-select: text !important;
        -ms-user-select: text !important;
            user-select: text !important;
  }

  .lt-lg\:select-all {
    -webkit-user-select: all !important;
       -moz-user-select: all !important;
        -ms-user-select: all !important;
            user-select: all !important;
  }

  .lt-lg\:select-auto {
    -webkit-user-select: auto !important;
       -moz-user-select: auto !important;
        -ms-user-select: auto !important;
            user-select: auto !important;
  }

  .lt-lg\:align-baseline {
    vertical-align: baseline !important;
  }

  .lt-lg\:align-top {
    vertical-align: top !important;
  }

  .lt-lg\:align-middle {
    vertical-align: middle !important;
  }

  .lt-lg\:align-bottom {
    vertical-align: bottom !important;
  }

  .lt-lg\:align-text-top {
    vertical-align: text-top !important;
  }

  .lt-lg\:align-text-bottom {
    vertical-align: text-bottom !important;
  }

  .lt-lg\:visible {
    visibility: visible !important;
  }

  .lt-lg\:invisible {
    visibility: hidden !important;
  }

  .lt-lg\:whitespace-normal {
    white-space: normal !important;
  }

  .lt-lg\:whitespace-nowrap {
    white-space: nowrap !important;
  }

  .lt-lg\:whitespace-pre {
    white-space: pre !important;
  }

  .lt-lg\:whitespace-pre-line {
    white-space: pre-line !important;
  }

  .lt-lg\:whitespace-pre-wrap {
    white-space: pre-wrap !important;
  }

  .lt-lg\:break-normal {
    overflow-wrap: normal !important;
    word-break: normal !important;
  }

  .lt-lg\:break-words {
    overflow-wrap: break-word !important;
  }

  .lt-lg\:break-all {
    word-break: break-all !important;
  }

  .lt-lg\:truncate {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
  }

  .lt-lg\:w-0 {
    width: 0 !important;
  }

  .lt-lg\:w-1 {
    width: 0.25rem !important;
  }

  .lt-lg\:w-2 {
    width: 0.5rem !important;
  }

  .lt-lg\:w-3 {
    width: 0.75rem !important;
  }

  .lt-lg\:w-4 {
    width: 1rem !important;
  }

  .lt-lg\:w-5 {
    width: 1.25rem !important;
  }

  .lt-lg\:w-6 {
    width: 1.5rem !important;
  }

  .lt-lg\:w-8 {
    width: 2rem !important;
  }

  .lt-lg\:w-10 {
    width: 2.5rem !important;
  }

  .lt-lg\:w-12 {
    width: 3rem !important;
  }

  .lt-lg\:w-14 {
    width: 3.5rem !important;
  }

  .lt-lg\:w-16 {
    width: 4rem !important;
  }

  .lt-lg\:w-18 {
    width: 4.5rem !important;
  }

  .lt-lg\:w-20 {
    width: 5rem !important;
  }

  .lt-lg\:w-22 {
    width: 5.5rem !important;
  }

  .lt-lg\:w-24 {
    width: 6rem !important;
  }

  .lt-lg\:w-26 {
    width: 6.5rem !important;
  }

  .lt-lg\:w-28 {
    width: 7rem !important;
  }

  .lt-lg\:w-30 {
    width: 7.5rem !important;
  }

  .lt-lg\:w-32 {
    width: 8rem !important;
  }

  .lt-lg\:w-36 {
    width: 9rem !important;
  }

  .lt-lg\:w-40 {
    width: 10rem !important;
  }

  .lt-lg\:w-48 {
    width: 12rem !important;
  }

  .lt-lg\:w-50 {
    width: 12.5rem !important;
  }

  .lt-lg\:w-56 {
    width: 14rem !important;
  }

  .lt-lg\:w-60 {
    width: 15rem !important;
  }

  .lt-lg\:w-64 {
    width: 16rem !important;
  }

  .lt-lg\:w-80 {
    width: 20rem !important;
  }

  .lt-lg\:w-90 {
    width: 24rem !important;
  }

  .lt-lg\:w-100 {
    width: 25rem !important;
  }

  .lt-lg\:w-120 {
    width: 30rem !important;
  }

  .lt-lg\:w-128 {
    width: 32rem !important;
  }

  .lt-lg\:w-140 {
    width: 35rem !important;
  }

  .lt-lg\:w-160 {
    width: 40rem !important;
  }

  .lt-lg\:w-180 {
    width: 45rem !important;
  }

  .lt-lg\:w-192 {
    width: 48rem !important;
  }

  .lt-lg\:w-200 {
    width: 50rem !important;
  }

  .lt-lg\:w-240 {
    width: 60rem !important;
  }

  .lt-lg\:w-256 {
    width: 64rem !important;
  }

  .lt-lg\:w-280 {
    width: 70rem !important;
  }

  .lt-lg\:w-320 {
    width: 80rem !important;
  }

  .lt-lg\:w-360 {
    width: 90rem !important;
  }

  .lt-lg\:w-400 {
    width: 100rem !important;
  }

  .lt-lg\:w-480 {
    width: 120rem !important;
  }

  .lt-lg\:w-auto {
    width: auto !important;
  }

  .lt-lg\:w-px {
    width: 1px !important;
  }

  .lt-lg\:w-2px {
    width: 2px !important;
  }

  .lt-lg\:w-1\/2 {
    width: 50% !important;
  }

  .lt-lg\:w-1\/3 {
    width: 33.33333% !important;
  }

  .lt-lg\:w-2\/3 {
    width: 66.66667% !important;
  }

  .lt-lg\:w-1\/4 {
    width: 25% !important;
  }

  .lt-lg\:w-2\/4 {
    width: 50% !important;
  }

  .lt-lg\:w-3\/4 {
    width: 75% !important;
  }

  .lt-lg\:w-1\/5 {
    width: 20% !important;
  }

  .lt-lg\:w-2\/5 {
    width: 40% !important;
  }

  .lt-lg\:w-3\/5 {
    width: 60% !important;
  }

  .lt-lg\:w-4\/5 {
    width: 80% !important;
  }

  .lt-lg\:w-1\/6 {
    width: 16.666667% !important;
  }

  .lt-lg\:w-2\/6 {
    width: 33.333333% !important;
  }

  .lt-lg\:w-3\/6 {
    width: 50% !important;
  }

  .lt-lg\:w-4\/6 {
    width: 66.666667% !important;
  }

  .lt-lg\:w-5\/6 {
    width: 83.333333% !important;
  }

  .lt-lg\:w-1\/12 {
    width: 8.33333% !important;
  }

  .lt-lg\:w-2\/12 {
    width: 16.66667% !important;
  }

  .lt-lg\:w-3\/12 {
    width: 25% !important;
  }

  .lt-lg\:w-4\/12 {
    width: 33.33333% !important;
  }

  .lt-lg\:w-5\/12 {
    width: 41.66667% !important;
  }

  .lt-lg\:w-6\/12 {
    width: 50% !important;
  }

  .lt-lg\:w-7\/12 {
    width: 58.33333% !important;
  }

  .lt-lg\:w-8\/12 {
    width: 66.66667% !important;
  }

  .lt-lg\:w-9\/12 {
    width: 75% !important;
  }

  .lt-lg\:w-10\/12 {
    width: 83.33333% !important;
  }

  .lt-lg\:w-11\/12 {
    width: 91.66667% !important;
  }

  .lt-lg\:w-full {
    width: 100% !important;
  }

  .lt-lg\:w-screen {
    width: 100vw !important;
  }

  .lt-lg\:z-0 {
    z-index: 0 !important;
  }

  .lt-lg\:z-10 {
    z-index: 10 !important;
  }

  .lt-lg\:z-20 {
    z-index: 20 !important;
  }

  .lt-lg\:z-30 {
    z-index: 30 !important;
  }

  .lt-lg\:z-40 {
    z-index: 40 !important;
  }

  .lt-lg\:z-50 {
    z-index: 50 !important;
  }

  .lt-lg\:z-60 {
    z-index: 60 !important;
  }

  .lt-lg\:z-70 {
    z-index: 70 !important;
  }

  .lt-lg\:z-80 {
    z-index: 80 !important;
  }

  .lt-lg\:z-90 {
    z-index: 90 !important;
  }

  .lt-lg\:z-99 {
    z-index: 99 !important;
  }

  .lt-lg\:z-999 {
    z-index: 999 !important;
  }

  .lt-lg\:z-9999 {
    z-index: 9999 !important;
  }

  .lt-lg\:z-99999 {
    z-index: 99999 !important;
  }

  .lt-lg\:z-auto {
    z-index: auto !important;
  }

  .lt-lg\:-z-1 {
    z-index: -1 !important;
  }

  .lt-lg\:gap-0 {
    grid-gap: 0 !important;
    gap: 0 !important;
  }

  .lt-lg\:gap-1 {
    grid-gap: 0.25rem !important;
    gap: 0.25rem !important;
  }

  .lt-lg\:gap-2 {
    grid-gap: 0.5rem !important;
    gap: 0.5rem !important;
  }

  .lt-lg\:gap-3 {
    grid-gap: 0.75rem !important;
    gap: 0.75rem !important;
  }

  .lt-lg\:gap-4 {
    grid-gap: 1rem !important;
    gap: 1rem !important;
  }

  .lt-lg\:gap-5 {
    grid-gap: 1.25rem !important;
    gap: 1.25rem !important;
  }

  .lt-lg\:gap-6 {
    grid-gap: 1.5rem !important;
    gap: 1.5rem !important;
  }

  .lt-lg\:gap-8 {
    grid-gap: 2rem !important;
    gap: 2rem !important;
  }

  .lt-lg\:gap-10 {
    grid-gap: 2.5rem !important;
    gap: 2.5rem !important;
  }

  .lt-lg\:gap-12 {
    grid-gap: 3rem !important;
    gap: 3rem !important;
  }

  .lt-lg\:gap-14 {
    grid-gap: 3.5rem !important;
    gap: 3.5rem !important;
  }

  .lt-lg\:gap-16 {
    grid-gap: 4rem !important;
    gap: 4rem !important;
  }

  .lt-lg\:gap-18 {
    grid-gap: 4.5rem !important;
    gap: 4.5rem !important;
  }

  .lt-lg\:gap-20 {
    grid-gap: 5rem !important;
    gap: 5rem !important;
  }

  .lt-lg\:gap-22 {
    grid-gap: 5.5rem !important;
    gap: 5.5rem !important;
  }

  .lt-lg\:gap-24 {
    grid-gap: 6rem !important;
    gap: 6rem !important;
  }

  .lt-lg\:gap-26 {
    grid-gap: 6.5rem !important;
    gap: 6.5rem !important;
  }

  .lt-lg\:gap-28 {
    grid-gap: 7rem !important;
    gap: 7rem !important;
  }

  .lt-lg\:gap-30 {
    grid-gap: 7.5rem !important;
    gap: 7.5rem !important;
  }

  .lt-lg\:gap-32 {
    grid-gap: 8rem !important;
    gap: 8rem !important;
  }

  .lt-lg\:gap-36 {
    grid-gap: 9rem !important;
    gap: 9rem !important;
  }

  .lt-lg\:gap-40 {
    grid-gap: 10rem !important;
    gap: 10rem !important;
  }

  .lt-lg\:gap-48 {
    grid-gap: 12rem !important;
    gap: 12rem !important;
  }

  .lt-lg\:gap-56 {
    grid-gap: 14rem !important;
    gap: 14rem !important;
  }

  .lt-lg\:gap-64 {
    grid-gap: 16rem !important;
    gap: 16rem !important;
  }

  .lt-lg\:gap-px {
    grid-gap: 1px !important;
    gap: 1px !important;
  }

  .lt-lg\:gap-2px {
    grid-gap: 2px !important;
    gap: 2px !important;
  }

  .lt-lg\:gap-x-0 {
    grid-column-gap: 0 !important;
    -moz-column-gap: 0 !important;
         column-gap: 0 !important;
  }

  .lt-lg\:gap-x-1 {
    grid-column-gap: 0.25rem !important;
    -moz-column-gap: 0.25rem !important;
         column-gap: 0.25rem !important;
  }

  .lt-lg\:gap-x-2 {
    grid-column-gap: 0.5rem !important;
    -moz-column-gap: 0.5rem !important;
         column-gap: 0.5rem !important;
  }

  .lt-lg\:gap-x-3 {
    grid-column-gap: 0.75rem !important;
    -moz-column-gap: 0.75rem !important;
         column-gap: 0.75rem !important;
  }

  .lt-lg\:gap-x-4 {
    grid-column-gap: 1rem !important;
    -moz-column-gap: 1rem !important;
         column-gap: 1rem !important;
  }

  .lt-lg\:gap-x-5 {
    grid-column-gap: 1.25rem !important;
    -moz-column-gap: 1.25rem !important;
         column-gap: 1.25rem !important;
  }

  .lt-lg\:gap-x-6 {
    grid-column-gap: 1.5rem !important;
    -moz-column-gap: 1.5rem !important;
         column-gap: 1.5rem !important;
  }

  .lt-lg\:gap-x-8 {
    grid-column-gap: 2rem !important;
    -moz-column-gap: 2rem !important;
         column-gap: 2rem !important;
  }

  .lt-lg\:gap-x-10 {
    grid-column-gap: 2.5rem !important;
    -moz-column-gap: 2.5rem !important;
         column-gap: 2.5rem !important;
  }

  .lt-lg\:gap-x-12 {
    grid-column-gap: 3rem !important;
    -moz-column-gap: 3rem !important;
         column-gap: 3rem !important;
  }

  .lt-lg\:gap-x-14 {
    grid-column-gap: 3.5rem !important;
    -moz-column-gap: 3.5rem !important;
         column-gap: 3.5rem !important;
  }

  .lt-lg\:gap-x-16 {
    grid-column-gap: 4rem !important;
    -moz-column-gap: 4rem !important;
         column-gap: 4rem !important;
  }

  .lt-lg\:gap-x-18 {
    grid-column-gap: 4.5rem !important;
    -moz-column-gap: 4.5rem !important;
         column-gap: 4.5rem !important;
  }

  .lt-lg\:gap-x-20 {
    grid-column-gap: 5rem !important;
    -moz-column-gap: 5rem !important;
         column-gap: 5rem !important;
  }

  .lt-lg\:gap-x-22 {
    grid-column-gap: 5.5rem !important;
    -moz-column-gap: 5.5rem !important;
         column-gap: 5.5rem !important;
  }

  .lt-lg\:gap-x-24 {
    grid-column-gap: 6rem !important;
    -moz-column-gap: 6rem !important;
         column-gap: 6rem !important;
  }

  .lt-lg\:gap-x-26 {
    grid-column-gap: 6.5rem !important;
    -moz-column-gap: 6.5rem !important;
         column-gap: 6.5rem !important;
  }

  .lt-lg\:gap-x-28 {
    grid-column-gap: 7rem !important;
    -moz-column-gap: 7rem !important;
         column-gap: 7rem !important;
  }

  .lt-lg\:gap-x-30 {
    grid-column-gap: 7.5rem !important;
    -moz-column-gap: 7.5rem !important;
         column-gap: 7.5rem !important;
  }

  .lt-lg\:gap-x-32 {
    grid-column-gap: 8rem !important;
    -moz-column-gap: 8rem !important;
         column-gap: 8rem !important;
  }

  .lt-lg\:gap-x-36 {
    grid-column-gap: 9rem !important;
    -moz-column-gap: 9rem !important;
         column-gap: 9rem !important;
  }

  .lt-lg\:gap-x-40 {
    grid-column-gap: 10rem !important;
    -moz-column-gap: 10rem !important;
         column-gap: 10rem !important;
  }

  .lt-lg\:gap-x-48 {
    grid-column-gap: 12rem !important;
    -moz-column-gap: 12rem !important;
         column-gap: 12rem !important;
  }

  .lt-lg\:gap-x-56 {
    grid-column-gap: 14rem !important;
    -moz-column-gap: 14rem !important;
         column-gap: 14rem !important;
  }

  .lt-lg\:gap-x-64 {
    grid-column-gap: 16rem !important;
    -moz-column-gap: 16rem !important;
         column-gap: 16rem !important;
  }

  .lt-lg\:gap-x-px {
    grid-column-gap: 1px !important;
    -moz-column-gap: 1px !important;
         column-gap: 1px !important;
  }

  .lt-lg\:gap-x-2px {
    grid-column-gap: 2px !important;
    -moz-column-gap: 2px !important;
         column-gap: 2px !important;
  }

  .lt-lg\:gap-y-0 {
    grid-row-gap: 0 !important;
    row-gap: 0 !important;
  }

  .lt-lg\:gap-y-1 {
    grid-row-gap: 0.25rem !important;
    row-gap: 0.25rem !important;
  }

  .lt-lg\:gap-y-2 {
    grid-row-gap: 0.5rem !important;
    row-gap: 0.5rem !important;
  }

  .lt-lg\:gap-y-3 {
    grid-row-gap: 0.75rem !important;
    row-gap: 0.75rem !important;
  }

  .lt-lg\:gap-y-4 {
    grid-row-gap: 1rem !important;
    row-gap: 1rem !important;
  }

  .lt-lg\:gap-y-5 {
    grid-row-gap: 1.25rem !important;
    row-gap: 1.25rem !important;
  }

  .lt-lg\:gap-y-6 {
    grid-row-gap: 1.5rem !important;
    row-gap: 1.5rem !important;
  }

  .lt-lg\:gap-y-8 {
    grid-row-gap: 2rem !important;
    row-gap: 2rem !important;
  }

  .lt-lg\:gap-y-10 {
    grid-row-gap: 2.5rem !important;
    row-gap: 2.5rem !important;
  }

  .lt-lg\:gap-y-12 {
    grid-row-gap: 3rem !important;
    row-gap: 3rem !important;
  }

  .lt-lg\:gap-y-14 {
    grid-row-gap: 3.5rem !important;
    row-gap: 3.5rem !important;
  }

  .lt-lg\:gap-y-16 {
    grid-row-gap: 4rem !important;
    row-gap: 4rem !important;
  }

  .lt-lg\:gap-y-18 {
    grid-row-gap: 4.5rem !important;
    row-gap: 4.5rem !important;
  }

  .lt-lg\:gap-y-20 {
    grid-row-gap: 5rem !important;
    row-gap: 5rem !important;
  }

  .lt-lg\:gap-y-22 {
    grid-row-gap: 5.5rem !important;
    row-gap: 5.5rem !important;
  }

  .lt-lg\:gap-y-24 {
    grid-row-gap: 6rem !important;
    row-gap: 6rem !important;
  }

  .lt-lg\:gap-y-26 {
    grid-row-gap: 6.5rem !important;
    row-gap: 6.5rem !important;
  }

  .lt-lg\:gap-y-28 {
    grid-row-gap: 7rem !important;
    row-gap: 7rem !important;
  }

  .lt-lg\:gap-y-30 {
    grid-row-gap: 7.5rem !important;
    row-gap: 7.5rem !important;
  }

  .lt-lg\:gap-y-32 {
    grid-row-gap: 8rem !important;
    row-gap: 8rem !important;
  }

  .lt-lg\:gap-y-36 {
    grid-row-gap: 9rem !important;
    row-gap: 9rem !important;
  }

  .lt-lg\:gap-y-40 {
    grid-row-gap: 10rem !important;
    row-gap: 10rem !important;
  }

  .lt-lg\:gap-y-48 {
    grid-row-gap: 12rem !important;
    row-gap: 12rem !important;
  }

  .lt-lg\:gap-y-56 {
    grid-row-gap: 14rem !important;
    row-gap: 14rem !important;
  }

  .lt-lg\:gap-y-64 {
    grid-row-gap: 16rem !important;
    row-gap: 16rem !important;
  }

  .lt-lg\:gap-y-px {
    grid-row-gap: 1px !important;
    row-gap: 1px !important;
  }

  .lt-lg\:gap-y-2px {
    grid-row-gap: 2px !important;
    row-gap: 2px !important;
  }

  .lt-lg\:grid-flow-row {
    grid-auto-flow: row !important;
  }

  .lt-lg\:grid-flow-col {
    grid-auto-flow: column !important;
  }

  .lt-lg\:grid-flow-row-dense {
    grid-auto-flow: row dense !important;
  }

  .lt-lg\:grid-flow-col-dense {
    grid-auto-flow: column dense !important;
  }

  .lt-lg\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }

  .lt-lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }

  .lt-lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
  }

  .lt-lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
  }

  .lt-lg\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr)) !important;
  }

  .lt-lg\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr)) !important;
  }

  .lt-lg\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr)) !important;
  }

  .lt-lg\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr)) !important;
  }

  .lt-lg\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr)) !important;
  }

  .lt-lg\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr)) !important;
  }

  .lt-lg\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr)) !important;
  }

  .lt-lg\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr)) !important;
  }

  .lt-lg\:grid-cols-none {
    grid-template-columns: none !important;
  }

  .lt-lg\:col-auto {
    grid-column: auto !important;
  }

  .lt-lg\:col-span-1 {
    grid-column: span 1 / span 1 !important;
  }

  .lt-lg\:col-span-2 {
    grid-column: span 2 / span 2 !important;
  }

  .lt-lg\:col-span-3 {
    grid-column: span 3 / span 3 !important;
  }

  .lt-lg\:col-span-4 {
    grid-column: span 4 / span 4 !important;
  }

  .lt-lg\:col-span-5 {
    grid-column: span 5 / span 5 !important;
  }

  .lt-lg\:col-span-6 {
    grid-column: span 6 / span 6 !important;
  }

  .lt-lg\:col-span-7 {
    grid-column: span 7 / span 7 !important;
  }

  .lt-lg\:col-span-8 {
    grid-column: span 8 / span 8 !important;
  }

  .lt-lg\:col-span-9 {
    grid-column: span 9 / span 9 !important;
  }

  .lt-lg\:col-span-10 {
    grid-column: span 10 / span 10 !important;
  }

  .lt-lg\:col-span-11 {
    grid-column: span 11 / span 11 !important;
  }

  .lt-lg\:col-span-12 {
    grid-column: span 12 / span 12 !important;
  }

  .lt-lg\:col-start-1 {
    grid-column-start: 1 !important;
  }

  .lt-lg\:col-start-2 {
    grid-column-start: 2 !important;
  }

  .lt-lg\:col-start-3 {
    grid-column-start: 3 !important;
  }

  .lt-lg\:col-start-4 {
    grid-column-start: 4 !important;
  }

  .lt-lg\:col-start-5 {
    grid-column-start: 5 !important;
  }

  .lt-lg\:col-start-6 {
    grid-column-start: 6 !important;
  }

  .lt-lg\:col-start-7 {
    grid-column-start: 7 !important;
  }

  .lt-lg\:col-start-8 {
    grid-column-start: 8 !important;
  }

  .lt-lg\:col-start-9 {
    grid-column-start: 9 !important;
  }

  .lt-lg\:col-start-10 {
    grid-column-start: 10 !important;
  }

  .lt-lg\:col-start-11 {
    grid-column-start: 11 !important;
  }

  .lt-lg\:col-start-12 {
    grid-column-start: 12 !important;
  }

  .lt-lg\:col-start-13 {
    grid-column-start: 13 !important;
  }

  .lt-lg\:col-start-auto {
    grid-column-start: auto !important;
  }

  .lt-lg\:col-end-1 {
    grid-column-end: 1 !important;
  }

  .lt-lg\:col-end-2 {
    grid-column-end: 2 !important;
  }

  .lt-lg\:col-end-3 {
    grid-column-end: 3 !important;
  }

  .lt-lg\:col-end-4 {
    grid-column-end: 4 !important;
  }

  .lt-lg\:col-end-5 {
    grid-column-end: 5 !important;
  }

  .lt-lg\:col-end-6 {
    grid-column-end: 6 !important;
  }

  .lt-lg\:col-end-7 {
    grid-column-end: 7 !important;
  }

  .lt-lg\:col-end-8 {
    grid-column-end: 8 !important;
  }

  .lt-lg\:col-end-9 {
    grid-column-end: 9 !important;
  }

  .lt-lg\:col-end-10 {
    grid-column-end: 10 !important;
  }

  .lt-lg\:col-end-11 {
    grid-column-end: 11 !important;
  }

  .lt-lg\:col-end-12 {
    grid-column-end: 12 !important;
  }

  .lt-lg\:col-end-13 {
    grid-column-end: 13 !important;
  }

  .lt-lg\:col-end-auto {
    grid-column-end: auto !important;
  }

  .lt-lg\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr)) !important;
  }

  .lt-lg\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr)) !important;
  }

  .lt-lg\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr)) !important;
  }

  .lt-lg\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr)) !important;
  }

  .lt-lg\:grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr)) !important;
  }

  .lt-lg\:grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr)) !important;
  }

  .lt-lg\:grid-rows-none {
    grid-template-rows: none !important;
  }

  .lt-lg\:row-auto {
    grid-row: auto !important;
  }

  .lt-lg\:row-span-1 {
    grid-row: span 1 / span 1 !important;
  }

  .lt-lg\:row-span-2 {
    grid-row: span 2 / span 2 !important;
  }

  .lt-lg\:row-span-3 {
    grid-row: span 3 / span 3 !important;
  }

  .lt-lg\:row-span-4 {
    grid-row: span 4 / span 4 !important;
  }

  .lt-lg\:row-span-5 {
    grid-row: span 5 / span 5 !important;
  }

  .lt-lg\:row-span-6 {
    grid-row: span 6 / span 6 !important;
  }

  .lt-lg\:row-start-1 {
    grid-row-start: 1 !important;
  }

  .lt-lg\:row-start-2 {
    grid-row-start: 2 !important;
  }

  .lt-lg\:row-start-3 {
    grid-row-start: 3 !important;
  }

  .lt-lg\:row-start-4 {
    grid-row-start: 4 !important;
  }

  .lt-lg\:row-start-5 {
    grid-row-start: 5 !important;
  }

  .lt-lg\:row-start-6 {
    grid-row-start: 6 !important;
  }

  .lt-lg\:row-start-7 {
    grid-row-start: 7 !important;
  }

  .lt-lg\:row-start-auto {
    grid-row-start: auto !important;
  }

  .lt-lg\:row-end-1 {
    grid-row-end: 1 !important;
  }

  .lt-lg\:row-end-2 {
    grid-row-end: 2 !important;
  }

  .lt-lg\:row-end-3 {
    grid-row-end: 3 !important;
  }

  .lt-lg\:row-end-4 {
    grid-row-end: 4 !important;
  }

  .lt-lg\:row-end-5 {
    grid-row-end: 5 !important;
  }

  .lt-lg\:row-end-6 {
    grid-row-end: 6 !important;
  }

  .lt-lg\:row-end-7 {
    grid-row-end: 7 !important;
  }

  .lt-lg\:row-end-auto {
    grid-row-end: auto !important;
  }

  .lt-lg\:transform {
    --transform-translate-x: 0 !important;
    --transform-translate-y: 0 !important;
    --transform-rotate: 0 !important;
    --transform-skew-x: 0 !important;
    --transform-skew-y: 0 !important;
    --transform-scale-x: 1 !important;
    --transform-scale-y: 1 !important;
    transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y)) !important;
  }

  .lt-lg\:transform-none {
    transform: none !important;
  }

  .lt-lg\:origin-center {
    transform-origin: center !important;
  }

  .lt-lg\:origin-top {
    transform-origin: top !important;
  }

  .lt-lg\:origin-top-right {
    transform-origin: top right !important;
  }

  .lt-lg\:origin-right {
    transform-origin: right !important;
  }

  .lt-lg\:origin-bottom-right {
    transform-origin: bottom right !important;
  }

  .lt-lg\:origin-bottom {
    transform-origin: bottom !important;
  }

  .lt-lg\:origin-bottom-left {
    transform-origin: bottom left !important;
  }

  .lt-lg\:origin-left {
    transform-origin: left !important;
  }

  .lt-lg\:origin-top-left {
    transform-origin: top left !important;
  }

  .lt-lg\:icon-size-12 {
    width: 12px !important;
    height: 12px !important;
    min-width: 12px !important;
    min-height: 12px !important;
    font-size: 12px !important;
    line-height: 12px !important;
  }

  .lt-lg\:icon-size-12 svg {
    width: 12px !important;
    height: 12px !important;
  }

  .lt-lg\:icon-size-14 {
    width: 14px !important;
    height: 14px !important;
    min-width: 14px !important;
    min-height: 14px !important;
    font-size: 14px !important;
    line-height: 14px !important;
  }

  .lt-lg\:icon-size-14 svg {
    width: 14px !important;
    height: 14px !important;
  }

  .lt-lg\:icon-size-16 {
    width: 16px !important;
    height: 16px !important;
    min-width: 16px !important;
    min-height: 16px !important;
    font-size: 16px !important;
    line-height: 16px !important;
  }

  .lt-lg\:icon-size-16 svg {
    width: 16px !important;
    height: 16px !important;
  }

  .lt-lg\:icon-size-18 {
    width: 18px !important;
    height: 18px !important;
    min-width: 18px !important;
    min-height: 18px !important;
    font-size: 18px !important;
    line-height: 18px !important;
  }

  .lt-lg\:icon-size-18 svg {
    width: 18px !important;
    height: 18px !important;
  }

  .lt-lg\:icon-size-20 {
    width: 20px !important;
    height: 20px !important;
    min-width: 20px !important;
    min-height: 20px !important;
    font-size: 20px !important;
    line-height: 20px !important;
  }

  .lt-lg\:icon-size-20 svg {
    width: 20px !important;
    height: 20px !important;
  }

  .lt-lg\:icon-size-24 {
    width: 24px !important;
    height: 24px !important;
    min-width: 24px !important;
    min-height: 24px !important;
    font-size: 24px !important;
    line-height: 24px !important;
  }

  .lt-lg\:icon-size-24 svg {
    width: 24px !important;
    height: 24px !important;
  }

  .lt-lg\:icon-size-32 {
    width: 32px !important;
    height: 32px !important;
    min-width: 32px !important;
    min-height: 32px !important;
    font-size: 32px !important;
    line-height: 32px !important;
  }

  .lt-lg\:icon-size-32 svg {
    width: 32px !important;
    height: 32px !important;
  }

  .lt-lg\:icon-size-40 {
    width: 40px !important;
    height: 40px !important;
    min-width: 40px !important;
    min-height: 40px !important;
    font-size: 40px !important;
    line-height: 40px !important;
  }

  .lt-lg\:icon-size-40 svg {
    width: 40px !important;
    height: 40px !important;
  }

  .lt-lg\:icon-size-48 {
    width: 48px !important;
    height: 48px !important;
    min-width: 48px !important;
    min-height: 48px !important;
    font-size: 48px !important;
    line-height: 48px !important;
  }

  .lt-lg\:icon-size-48 svg {
    width: 48px !important;
    height: 48px !important;
  }

  .lt-lg\:icon-size-56 {
    width: 56px !important;
    height: 56px !important;
    min-width: 56px !important;
    min-height: 56px !important;
    font-size: 56px !important;
    line-height: 56px !important;
  }

  .lt-lg\:icon-size-56 svg {
    width: 56px !important;
    height: 56px !important;
  }

  .lt-lg\:icon-size-64 {
    width: 64px !important;
    height: 64px !important;
    min-width: 64px !important;
    min-height: 64px !important;
    font-size: 64px !important;
    line-height: 64px !important;
  }

  .lt-lg\:icon-size-64 svg {
    width: 64px !important;
    height: 64px !important;
  }

  .lt-lg\:icon-size-72 {
    width: 72px !important;
    height: 72px !important;
    min-width: 72px !important;
    min-height: 72px !important;
    font-size: 72px !important;
    line-height: 72px !important;
  }

  .lt-lg\:icon-size-72 svg {
    width: 72px !important;
    height: 72px !important;
  }

  .lt-lg\:icon-size-80 {
    width: 80px !important;
    height: 80px !important;
    min-width: 80px !important;
    min-height: 80px !important;
    font-size: 80px !important;
    line-height: 80px !important;
  }

  .lt-lg\:icon-size-80 svg {
    width: 80px !important;
    height: 80px !important;
  }

  .lt-lg\:icon-size-88 {
    width: 88px !important;
    height: 88px !important;
    min-width: 88px !important;
    min-height: 88px !important;
    font-size: 88px !important;
    line-height: 88px !important;
  }

  .lt-lg\:icon-size-88 svg {
    width: 88px !important;
    height: 88px !important;
  }

  .lt-lg\:icon-size-96 {
    width: 96px !important;
    height: 96px !important;
    min-width: 96px !important;
    min-height: 96px !important;
    font-size: 96px !important;
    line-height: 96px !important;
  }

  .lt-lg\:icon-size-96 svg {
    width: 96px !important;
    height: 96px !important;
  }
}

@media (max-width: 1439px) {
  .lt-xl\:space-y-0 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(0px * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(0px * var(--space-y-reverse)) !important;
  }

  .lt-xl\:space-x-0 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(0px * var(--space-x-reverse)) !important;
    margin-left: calc(0px * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-xl\:space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(0.25rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(0.25rem * var(--space-y-reverse)) !important;
  }

  .lt-xl\:space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(0.25rem * var(--space-x-reverse)) !important;
    margin-left: calc(0.25rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-xl\:space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(0.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(0.5rem * var(--space-y-reverse)) !important;
  }

  .lt-xl\:space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(0.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(0.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-xl\:space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(0.75rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(0.75rem * var(--space-y-reverse)) !important;
  }

  .lt-xl\:space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(0.75rem * var(--space-x-reverse)) !important;
    margin-left: calc(0.75rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-xl\:space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(1rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(1rem * var(--space-y-reverse)) !important;
  }

  .lt-xl\:space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(1rem * var(--space-x-reverse)) !important;
    margin-left: calc(1rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-xl\:space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(1.25rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(1.25rem * var(--space-y-reverse)) !important;
  }

  .lt-xl\:space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(1.25rem * var(--space-x-reverse)) !important;
    margin-left: calc(1.25rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-xl\:space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(1.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(1.5rem * var(--space-y-reverse)) !important;
  }

  .lt-xl\:space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(1.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(1.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-xl\:space-y-8 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(2rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(2rem * var(--space-y-reverse)) !important;
  }

  .lt-xl\:space-x-8 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(2rem * var(--space-x-reverse)) !important;
    margin-left: calc(2rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-xl\:space-y-10 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(2.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(2.5rem * var(--space-y-reverse)) !important;
  }

  .lt-xl\:space-x-10 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(2.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(2.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-xl\:space-y-12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(3rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(3rem * var(--space-y-reverse)) !important;
  }

  .lt-xl\:space-x-12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(3rem * var(--space-x-reverse)) !important;
    margin-left: calc(3rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-xl\:space-y-14 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(3.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(3.5rem * var(--space-y-reverse)) !important;
  }

  .lt-xl\:space-x-14 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(3.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(3.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-xl\:space-y-16 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(4rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(4rem * var(--space-y-reverse)) !important;
  }

  .lt-xl\:space-x-16 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(4rem * var(--space-x-reverse)) !important;
    margin-left: calc(4rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-xl\:space-y-18 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(4.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(4.5rem * var(--space-y-reverse)) !important;
  }

  .lt-xl\:space-x-18 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(4.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(4.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-xl\:space-y-20 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(5rem * var(--space-y-reverse)) !important;
  }

  .lt-xl\:space-x-20 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(5rem * var(--space-x-reverse)) !important;
    margin-left: calc(5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-xl\:space-y-22 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(5.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(5.5rem * var(--space-y-reverse)) !important;
  }

  .lt-xl\:space-x-22 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(5.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(5.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-xl\:space-y-24 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(6rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(6rem * var(--space-y-reverse)) !important;
  }

  .lt-xl\:space-x-24 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(6rem * var(--space-x-reverse)) !important;
    margin-left: calc(6rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-xl\:space-y-26 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(6.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(6.5rem * var(--space-y-reverse)) !important;
  }

  .lt-xl\:space-x-26 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(6.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(6.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-xl\:space-y-28 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(7rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(7rem * var(--space-y-reverse)) !important;
  }

  .lt-xl\:space-x-28 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(7rem * var(--space-x-reverse)) !important;
    margin-left: calc(7rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-xl\:space-y-30 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(7.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(7.5rem * var(--space-y-reverse)) !important;
  }

  .lt-xl\:space-x-30 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(7.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(7.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-xl\:space-y-32 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(8rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(8rem * var(--space-y-reverse)) !important;
  }

  .lt-xl\:space-x-32 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(8rem * var(--space-x-reverse)) !important;
    margin-left: calc(8rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-xl\:space-y-36 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(9rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(9rem * var(--space-y-reverse)) !important;
  }

  .lt-xl\:space-x-36 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(9rem * var(--space-x-reverse)) !important;
    margin-left: calc(9rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-xl\:space-y-40 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(10rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(10rem * var(--space-y-reverse)) !important;
  }

  .lt-xl\:space-x-40 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(10rem * var(--space-x-reverse)) !important;
    margin-left: calc(10rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-xl\:space-y-48 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(12rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(12rem * var(--space-y-reverse)) !important;
  }

  .lt-xl\:space-x-48 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(12rem * var(--space-x-reverse)) !important;
    margin-left: calc(12rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-xl\:space-y-56 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(14rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(14rem * var(--space-y-reverse)) !important;
  }

  .lt-xl\:space-x-56 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(14rem * var(--space-x-reverse)) !important;
    margin-left: calc(14rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-xl\:space-y-64 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(16rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(16rem * var(--space-y-reverse)) !important;
  }

  .lt-xl\:space-x-64 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(16rem * var(--space-x-reverse)) !important;
    margin-left: calc(16rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-xl\:space-y-px > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(1px * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(1px * var(--space-y-reverse)) !important;
  }

  .lt-xl\:space-x-px > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(1px * var(--space-x-reverse)) !important;
    margin-left: calc(1px * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-xl\:space-y-2px > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(2px * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(2px * var(--space-y-reverse)) !important;
  }

  .lt-xl\:space-x-2px > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(2px * var(--space-x-reverse)) !important;
    margin-left: calc(2px * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-xl\:-space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-0.25rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-0.25rem * var(--space-y-reverse)) !important;
  }

  .lt-xl\:-space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-0.25rem * var(--space-x-reverse)) !important;
    margin-left: calc(-0.25rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-xl\:-space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-0.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-0.5rem * var(--space-y-reverse)) !important;
  }

  .lt-xl\:-space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-0.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-0.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-xl\:-space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-0.75rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-0.75rem * var(--space-y-reverse)) !important;
  }

  .lt-xl\:-space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-0.75rem * var(--space-x-reverse)) !important;
    margin-left: calc(-0.75rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-xl\:-space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-1rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-1rem * var(--space-y-reverse)) !important;
  }

  .lt-xl\:-space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-1rem * var(--space-x-reverse)) !important;
    margin-left: calc(-1rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-xl\:-space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-1.25rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-1.25rem * var(--space-y-reverse)) !important;
  }

  .lt-xl\:-space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-1.25rem * var(--space-x-reverse)) !important;
    margin-left: calc(-1.25rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-xl\:-space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-1.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-1.5rem * var(--space-y-reverse)) !important;
  }

  .lt-xl\:-space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-1.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-1.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-xl\:-space-y-8 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-2rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-2rem * var(--space-y-reverse)) !important;
  }

  .lt-xl\:-space-x-8 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-2rem * var(--space-x-reverse)) !important;
    margin-left: calc(-2rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-xl\:-space-y-10 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-2.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-2.5rem * var(--space-y-reverse)) !important;
  }

  .lt-xl\:-space-x-10 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-2.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-2.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-xl\:-space-y-12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-3rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-3rem * var(--space-y-reverse)) !important;
  }

  .lt-xl\:-space-x-12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-3rem * var(--space-x-reverse)) !important;
    margin-left: calc(-3rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-xl\:-space-y-14 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-3.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-3.5rem * var(--space-y-reverse)) !important;
  }

  .lt-xl\:-space-x-14 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-3.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-3.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-xl\:-space-y-16 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-4rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-4rem * var(--space-y-reverse)) !important;
  }

  .lt-xl\:-space-x-16 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-4rem * var(--space-x-reverse)) !important;
    margin-left: calc(-4rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-xl\:-space-y-18 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-4.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-4.5rem * var(--space-y-reverse)) !important;
  }

  .lt-xl\:-space-x-18 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-4.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-4.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-xl\:-space-y-20 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-5rem * var(--space-y-reverse)) !important;
  }

  .lt-xl\:-space-x-20 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-xl\:-space-y-22 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-5.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-5.5rem * var(--space-y-reverse)) !important;
  }

  .lt-xl\:-space-x-22 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-5.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-5.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-xl\:-space-y-24 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-6rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-6rem * var(--space-y-reverse)) !important;
  }

  .lt-xl\:-space-x-24 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-6rem * var(--space-x-reverse)) !important;
    margin-left: calc(-6rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-xl\:-space-y-26 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-6.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-6.5rem * var(--space-y-reverse)) !important;
  }

  .lt-xl\:-space-x-26 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-6.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-6.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-xl\:-space-y-28 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-7rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-7rem * var(--space-y-reverse)) !important;
  }

  .lt-xl\:-space-x-28 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-7rem * var(--space-x-reverse)) !important;
    margin-left: calc(-7rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-xl\:-space-y-30 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-7.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-7.5rem * var(--space-y-reverse)) !important;
  }

  .lt-xl\:-space-x-30 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-7.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-7.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-xl\:-space-y-32 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-8rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-8rem * var(--space-y-reverse)) !important;
  }

  .lt-xl\:-space-x-32 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-8rem * var(--space-x-reverse)) !important;
    margin-left: calc(-8rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-xl\:-space-y-36 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-9rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-9rem * var(--space-y-reverse)) !important;
  }

  .lt-xl\:-space-x-36 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-9rem * var(--space-x-reverse)) !important;
    margin-left: calc(-9rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-xl\:-space-y-40 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-10rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-10rem * var(--space-y-reverse)) !important;
  }

  .lt-xl\:-space-x-40 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-10rem * var(--space-x-reverse)) !important;
    margin-left: calc(-10rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-xl\:-space-y-48 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-12rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-12rem * var(--space-y-reverse)) !important;
  }

  .lt-xl\:-space-x-48 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-12rem * var(--space-x-reverse)) !important;
    margin-left: calc(-12rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-xl\:-space-y-56 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-14rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-14rem * var(--space-y-reverse)) !important;
  }

  .lt-xl\:-space-x-56 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-14rem * var(--space-x-reverse)) !important;
    margin-left: calc(-14rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-xl\:-space-y-64 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-16rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-16rem * var(--space-y-reverse)) !important;
  }

  .lt-xl\:-space-x-64 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-16rem * var(--space-x-reverse)) !important;
    margin-left: calc(-16rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-xl\:-space-y-px > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-1px * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-1px * var(--space-y-reverse)) !important;
  }

  .lt-xl\:-space-x-px > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-1px * var(--space-x-reverse)) !important;
    margin-left: calc(-1px * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-xl\:-space-y-2px > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-2px * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-2px * var(--space-y-reverse)) !important;
  }

  .lt-xl\:-space-x-2px > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-2px * var(--space-x-reverse)) !important;
    margin-left: calc(-2px * calc(1 - var(--space-x-reverse))) !important;
  }

  .lt-xl\:space-y-reverse > :not(template) ~ :not(template) {
    --space-y-reverse: 1 !important;
  }

  .lt-xl\:space-x-reverse > :not(template) ~ :not(template) {
    --space-x-reverse: 1 !important;
  }

  .lt-xl\:divide-y-0 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(0px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(0px * var(--divide-y-reverse)) !important;
  }

  .lt-xl\:divide-x-0 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(0px * var(--divide-x-reverse)) !important;
    border-left-width: calc(0px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .lt-xl\:divide-y-2 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(2px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(2px * var(--divide-y-reverse)) !important;
  }

  .lt-xl\:divide-x-2 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(2px * var(--divide-x-reverse)) !important;
    border-left-width: calc(2px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .lt-xl\:divide-y-4 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(4px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(4px * var(--divide-y-reverse)) !important;
  }

  .lt-xl\:divide-x-4 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(4px * var(--divide-x-reverse)) !important;
    border-left-width: calc(4px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .lt-xl\:divide-y-8 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(8px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(8px * var(--divide-y-reverse)) !important;
  }

  .lt-xl\:divide-x-8 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(8px * var(--divide-x-reverse)) !important;
    border-left-width: calc(8px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .lt-xl\:divide-y > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(1px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(1px * var(--divide-y-reverse)) !important;
  }

  .lt-xl\:divide-x > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(1px * var(--divide-x-reverse)) !important;
    border-left-width: calc(1px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .lt-xl\:divide-y-reverse > :not(template) ~ :not(template) {
    --divide-y-reverse: 1 !important;
  }

  .lt-xl\:divide-x-reverse > :not(template) ~ :not(template) {
    --divide-x-reverse: 1 !important;
  }

  .lt-xl\:divide-current > :not(template) ~ :not(template) {
    border-color: currentColor !important;
  }

  .lt-xl\:divide-transparent > :not(template) ~ :not(template) {
    border-color: transparent !important;
  }

  .lt-xl\:divide-white > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FFFFFF !important;
    border-color: rgba(255, 255, 255, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-black > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #000000 !important;
    border-color: rgba(0, 0, 0, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-gray-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F9FAFB !important;
    border-color: rgba(249, 250, 251, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-gray-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F4F5F7 !important;
    border-color: rgba(244, 245, 247, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-gray-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E5E7EB !important;
    border-color: rgba(229, 231, 235, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-gray-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #D2D6DC !important;
    border-color: rgba(210, 214, 220, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-gray-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9FA6B2 !important;
    border-color: rgba(159, 166, 178, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-gray-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #6B7280 !important;
    border-color: rgba(107, 114, 128, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-gray-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #4B5563 !important;
    border-color: rgba(75, 85, 99, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-gray-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #374151 !important;
    border-color: rgba(55, 65, 81, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-gray-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #252F3F !important;
    border-color: rgba(37, 47, 63, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-gray-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #161E2E !important;
    border-color: rgba(22, 30, 46, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-gray > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #6B7280 !important;
    border-color: rgba(107, 114, 128, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-cool-gray-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FBFDFE !important;
    border-color: rgba(251, 253, 254, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-cool-gray-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F1F5F9 !important;
    border-color: rgba(241, 245, 249, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-cool-gray-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E2E8F0 !important;
    border-color: rgba(226, 232, 240, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-cool-gray-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #CFD8E3 !important;
    border-color: rgba(207, 216, 227, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-cool-gray-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #97A6BA !important;
    border-color: rgba(151, 166, 186, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-cool-gray-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #64748B !important;
    border-color: rgba(100, 116, 139, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-cool-gray-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #475569 !important;
    border-color: rgba(71, 85, 105, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-cool-gray-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #364152 !important;
    border-color: rgba(54, 65, 82, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-cool-gray-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #27303F !important;
    border-color: rgba(39, 48, 63, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-cool-gray-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #1A202E !important;
    border-color: rgba(26, 32, 46, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-cool-gray > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #64748B !important;
    border-color: rgba(100, 116, 139, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-red-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FDF2F2 !important;
    border-color: rgba(253, 242, 242, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-red-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FDE8E8 !important;
    border-color: rgba(253, 232, 232, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-red-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FBD5D5 !important;
    border-color: rgba(251, 213, 213, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-red-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F8B4B4 !important;
    border-color: rgba(248, 180, 180, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-red-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F98080 !important;
    border-color: rgba(249, 128, 128, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-red-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F05252 !important;
    border-color: rgba(240, 82, 82, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-red-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E02424 !important;
    border-color: rgba(224, 36, 36, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-red-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #C81E1E !important;
    border-color: rgba(200, 30, 30, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-red-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9B1C1C !important;
    border-color: rgba(155, 28, 28, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-red-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #771D1D !important;
    border-color: rgba(119, 29, 29, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-red > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F05252 !important;
    border-color: rgba(240, 82, 82, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-orange-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FFF8F1 !important;
    border-color: rgba(255, 248, 241, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-orange-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FEECDC !important;
    border-color: rgba(254, 236, 220, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-orange-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FCD9BD !important;
    border-color: rgba(252, 217, 189, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-orange-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FDBA8C !important;
    border-color: rgba(253, 186, 140, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-orange-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FF8A4C !important;
    border-color: rgba(255, 138, 76, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-orange-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FF5A1F !important;
    border-color: rgba(255, 90, 31, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-orange-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #ff6700 !important;
    border-color: rgba(255, 103, 0, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-orange-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #B43403 !important;
    border-color: rgba(180, 52, 3, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-orange-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #8A2C0D !important;
    border-color: rgba(138, 44, 13, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-orange-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #771D1D !important;
    border-color: rgba(119, 29, 29, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-orange > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FF5A1F !important;
    border-color: rgba(255, 90, 31, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-yellow-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FDFDEA !important;
    border-color: rgba(253, 253, 234, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-yellow-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FDF6B2 !important;
    border-color: rgba(253, 246, 178, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-yellow-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FCE96A !important;
    border-color: rgba(252, 233, 106, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-yellow-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FACA15 !important;
    border-color: rgba(250, 202, 21, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-yellow-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E3A008 !important;
    border-color: rgba(227, 160, 8, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-yellow-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #C27803 !important;
    border-color: rgba(194, 120, 3, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-yellow-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9F580A !important;
    border-color: rgba(159, 88, 10, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-yellow-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #8E4B10 !important;
    border-color: rgba(142, 75, 16, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-yellow-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #723B13 !important;
    border-color: rgba(114, 59, 19, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-yellow-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #633112 !important;
    border-color: rgba(99, 49, 18, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-yellow > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #C27803 !important;
    border-color: rgba(194, 120, 3, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-green-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F3FAF7 !important;
    border-color: rgba(243, 250, 247, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-green-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #DEF7EC !important;
    border-color: rgba(222, 247, 236, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-green-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #BCF0DA !important;
    border-color: rgba(188, 240, 218, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-green-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #84E1BC !important;
    border-color: rgba(132, 225, 188, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-green-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #31C48D !important;
    border-color: rgba(49, 196, 141, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-green-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #0E9F6E !important;
    border-color: rgba(14, 159, 110, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-green-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #057A55 !important;
    border-color: rgba(5, 122, 85, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-green-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #046C4E !important;
    border-color: rgba(4, 108, 78, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-green-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #03543F !important;
    border-color: rgba(3, 84, 63, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-green-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #014737 !important;
    border-color: rgba(1, 71, 55, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-green > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #0E9F6E !important;
    border-color: rgba(14, 159, 110, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-teal-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #EDFAFA !important;
    border-color: rgba(237, 250, 250, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-teal-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #D5F5F6 !important;
    border-color: rgba(213, 245, 246, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-teal-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #AFECEF !important;
    border-color: rgba(175, 236, 239, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-teal-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #7EDCE2 !important;
    border-color: rgba(126, 220, 226, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-teal-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #16BDCA !important;
    border-color: rgba(22, 189, 202, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-teal-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #0694A2 !important;
    border-color: rgba(6, 148, 162, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-teal-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #047481 !important;
    border-color: rgba(4, 116, 129, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-teal-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #036672 !important;
    border-color: rgba(3, 102, 114, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-teal-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #05505C !important;
    border-color: rgba(5, 80, 92, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-teal-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #014451 !important;
    border-color: rgba(1, 68, 81, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-teal > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #0694A2 !important;
    border-color: rgba(6, 148, 162, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-blue-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #EBF5FF !important;
    border-color: rgba(235, 245, 255, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-blue-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E1EFFE !important;
    border-color: rgba(225, 239, 254, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-blue-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #C3DDFD !important;
    border-color: rgba(195, 221, 253, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-blue-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #A4CAFE !important;
    border-color: rgba(164, 202, 254, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-blue-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #76A9FA !important;
    border-color: rgba(118, 169, 250, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-blue-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #3F83F8 !important;
    border-color: rgba(63, 131, 248, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-blue-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #1C64F2 !important;
    border-color: rgba(28, 100, 242, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-blue-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #1A56DB !important;
    border-color: rgba(26, 86, 219, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-blue-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #1E429F !important;
    border-color: rgba(30, 66, 159, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-blue-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #233876 !important;
    border-color: rgba(35, 56, 118, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-blue > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #3F83F8 !important;
    border-color: rgba(63, 131, 248, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-indigo-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F0F5FF !important;
    border-color: rgba(240, 245, 255, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-indigo-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E5EDFF !important;
    border-color: rgba(229, 237, 255, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-indigo-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #CDDBFE !important;
    border-color: rgba(205, 219, 254, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-indigo-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #B4C6FC !important;
    border-color: rgba(180, 198, 252, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-indigo-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #8DA2FB !important;
    border-color: rgba(141, 162, 251, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-indigo-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #6875F5 !important;
    border-color: rgba(104, 117, 245, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-indigo-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #5850EC !important;
    border-color: rgba(88, 80, 236, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-indigo-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #5145CD !important;
    border-color: rgba(81, 69, 205, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-indigo-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #42389D !important;
    border-color: rgba(66, 56, 157, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-indigo-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #362F78 !important;
    border-color: rgba(54, 47, 120, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-indigo > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #6875F5 !important;
    border-color: rgba(104, 117, 245, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-purple-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F6F5FF !important;
    border-color: rgba(246, 245, 255, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-purple-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #EDEBFE !important;
    border-color: rgba(237, 235, 254, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-purple-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #DCD7FE !important;
    border-color: rgba(220, 215, 254, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-purple-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #CABFFD !important;
    border-color: rgba(202, 191, 253, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-purple-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #AC94FA !important;
    border-color: rgba(172, 148, 250, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-purple-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9061F9 !important;
    border-color: rgba(144, 97, 249, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-purple-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #7E3AF2 !important;
    border-color: rgba(126, 58, 242, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-purple-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #6C2BD9 !important;
    border-color: rgba(108, 43, 217, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-purple-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #5521B5 !important;
    border-color: rgba(85, 33, 181, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-purple-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #4A1D96 !important;
    border-color: rgba(74, 29, 150, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-purple > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9061F9 !important;
    border-color: rgba(144, 97, 249, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-pink-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FDF2F8 !important;
    border-color: rgba(253, 242, 248, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-pink-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FCE8F3 !important;
    border-color: rgba(252, 232, 243, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-pink-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FAD1E8 !important;
    border-color: rgba(250, 209, 232, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-pink-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F8B4D9 !important;
    border-color: rgba(248, 180, 217, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-pink-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F17EB8 !important;
    border-color: rgba(241, 126, 184, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-pink-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E74694 !important;
    border-color: rgba(231, 70, 148, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-pink-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #D61F69 !important;
    border-color: rgba(214, 31, 105, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-pink-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #BF125D !important;
    border-color: rgba(191, 18, 93, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-pink-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #99154B !important;
    border-color: rgba(153, 21, 75, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-pink-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #751A3D !important;
    border-color: rgba(117, 26, 61, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-pink > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E74694 !important;
    border-color: rgba(231, 70, 148, var(--divide-opacity)) !important;
  }

  .lt-xl\:divide-opacity-0 > :not(template) ~ :not(template) {
    --divide-opacity: 0 !important;
  }

  .lt-xl\:divide-opacity-12 > :not(template) ~ :not(template) {
    --divide-opacity: 0.12 !important;
  }

  .lt-xl\:divide-opacity-25 > :not(template) ~ :not(template) {
    --divide-opacity: 0.25 !important;
  }

  .lt-xl\:divide-opacity-38 > :not(template) ~ :not(template) {
    --divide-opacity: 0.38 !important;
  }

  .lt-xl\:divide-opacity-50 > :not(template) ~ :not(template) {
    --divide-opacity: 0.5 !important;
  }

  .lt-xl\:divide-opacity-54 > :not(template) ~ :not(template) {
    --divide-opacity: 0.54 !important;
  }

  .lt-xl\:divide-opacity-70 > :not(template) ~ :not(template) {
    --divide-opacity: 0.70 !important;
  }

  .lt-xl\:divide-opacity-75 > :not(template) ~ :not(template) {
    --divide-opacity: 0.75 !important;
  }

  .lt-xl\:divide-opacity-84 > :not(template) ~ :not(template) {
    --divide-opacity: 0.84 !important;
  }

  .lt-xl\:divide-opacity-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
  }

  .lt-xl\:sr-only {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border-width: 0 !important;
  }

  .lt-xl\:not-sr-only {
    position: static !important;
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: visible !important;
    clip: auto !important;
    white-space: normal !important;
  }

  .lt-xl\:focus\:sr-only:focus {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border-width: 0 !important;
  }

  .lt-xl\:focus\:not-sr-only:focus {
    position: static !important;
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: visible !important;
    clip: auto !important;
    white-space: normal !important;
  }

  .lt-xl\:appearance-none {
    -webkit-appearance: none !important;
       -moz-appearance: none !important;
            appearance: none !important;
  }

  .lt-xl\:bg-fixed {
    background-attachment: fixed !important;
  }

  .lt-xl\:bg-local {
    background-attachment: local !important;
  }

  .lt-xl\:bg-scroll {
    background-attachment: scroll !important;
  }

  .lt-xl\:bg-opacity-0 {
    --bg-opacity: 0 !important;
  }

  .lt-xl\:bg-opacity-12 {
    --bg-opacity: 0.12 !important;
  }

  .lt-xl\:bg-opacity-25 {
    --bg-opacity: 0.25 !important;
  }

  .lt-xl\:bg-opacity-38 {
    --bg-opacity: 0.38 !important;
  }

  .lt-xl\:bg-opacity-50 {
    --bg-opacity: 0.5 !important;
  }

  .lt-xl\:bg-opacity-54 {
    --bg-opacity: 0.54 !important;
  }

  .lt-xl\:bg-opacity-70 {
    --bg-opacity: 0.70 !important;
  }

  .lt-xl\:bg-opacity-75 {
    --bg-opacity: 0.75 !important;
  }

  .lt-xl\:bg-opacity-84 {
    --bg-opacity: 0.84 !important;
  }

  .lt-xl\:bg-opacity-100 {
    --bg-opacity: 1 !important;
  }

  .lt-xl\:hover\:bg-opacity-0:hover {
    --bg-opacity: 0 !important;
  }

  .lt-xl\:hover\:bg-opacity-12:hover {
    --bg-opacity: 0.12 !important;
  }

  .lt-xl\:hover\:bg-opacity-25:hover {
    --bg-opacity: 0.25 !important;
  }

  .lt-xl\:hover\:bg-opacity-38:hover {
    --bg-opacity: 0.38 !important;
  }

  .lt-xl\:hover\:bg-opacity-50:hover {
    --bg-opacity: 0.5 !important;
  }

  .lt-xl\:hover\:bg-opacity-54:hover {
    --bg-opacity: 0.54 !important;
  }

  .lt-xl\:hover\:bg-opacity-70:hover {
    --bg-opacity: 0.70 !important;
  }

  .lt-xl\:hover\:bg-opacity-75:hover {
    --bg-opacity: 0.75 !important;
  }

  .lt-xl\:hover\:bg-opacity-84:hover {
    --bg-opacity: 0.84 !important;
  }

  .lt-xl\:hover\:bg-opacity-100:hover {
    --bg-opacity: 1 !important;
  }

  .lt-xl\:focus\:bg-opacity-0:focus {
    --bg-opacity: 0 !important;
  }

  .lt-xl\:focus\:bg-opacity-12:focus {
    --bg-opacity: 0.12 !important;
  }

  .lt-xl\:focus\:bg-opacity-25:focus {
    --bg-opacity: 0.25 !important;
  }

  .lt-xl\:focus\:bg-opacity-38:focus {
    --bg-opacity: 0.38 !important;
  }

  .lt-xl\:focus\:bg-opacity-50:focus {
    --bg-opacity: 0.5 !important;
  }

  .lt-xl\:focus\:bg-opacity-54:focus {
    --bg-opacity: 0.54 !important;
  }

  .lt-xl\:focus\:bg-opacity-70:focus {
    --bg-opacity: 0.70 !important;
  }

  .lt-xl\:focus\:bg-opacity-75:focus {
    --bg-opacity: 0.75 !important;
  }

  .lt-xl\:focus\:bg-opacity-84:focus {
    --bg-opacity: 0.84 !important;
  }

  .lt-xl\:focus\:bg-opacity-100:focus {
    --bg-opacity: 1 !important;
  }

  .lt-xl\:bg-bottom {
    background-position: bottom !important;
  }

  .lt-xl\:bg-center {
    background-position: center !important;
  }

  .lt-xl\:bg-left {
    background-position: left !important;
  }

  .lt-xl\:bg-left-bottom {
    background-position: left bottom !important;
  }

  .lt-xl\:bg-left-top {
    background-position: left top !important;
  }

  .lt-xl\:bg-right {
    background-position: right !important;
  }

  .lt-xl\:bg-right-bottom {
    background-position: right bottom !important;
  }

  .lt-xl\:bg-right-top {
    background-position: right top !important;
  }

  .lt-xl\:bg-top {
    background-position: top !important;
  }

  .lt-xl\:bg-repeat {
    background-repeat: repeat !important;
  }

  .lt-xl\:bg-no-repeat {
    background-repeat: no-repeat !important;
  }

  .lt-xl\:bg-repeat-x {
    background-repeat: repeat-x !important;
  }

  .lt-xl\:bg-repeat-y {
    background-repeat: repeat-y !important;
  }

  .lt-xl\:bg-repeat-round {
    background-repeat: round !important;
  }

  .lt-xl\:bg-repeat-space {
    background-repeat: space !important;
  }

  .lt-xl\:bg-auto {
    background-size: auto !important;
  }

  .lt-xl\:bg-cover {
    background-size: cover !important;
  }

  .lt-xl\:bg-contain {
    background-size: contain !important;
  }

  .lt-xl\:border-collapse {
    border-collapse: collapse !important;
  }

  .lt-xl\:border-separate {
    border-collapse: separate !important;
  }

  .lt-xl\:border-opacity-0 {
    --border-opacity: 0 !important;
  }

  .lt-xl\:border-opacity-12 {
    --border-opacity: 0.12 !important;
  }

  .lt-xl\:border-opacity-25 {
    --border-opacity: 0.25 !important;
  }

  .lt-xl\:border-opacity-38 {
    --border-opacity: 0.38 !important;
  }

  .lt-xl\:border-opacity-50 {
    --border-opacity: 0.5 !important;
  }

  .lt-xl\:border-opacity-54 {
    --border-opacity: 0.54 !important;
  }

  .lt-xl\:border-opacity-70 {
    --border-opacity: 0.70 !important;
  }

  .lt-xl\:border-opacity-75 {
    --border-opacity: 0.75 !important;
  }

  .lt-xl\:border-opacity-84 {
    --border-opacity: 0.84 !important;
  }

  .lt-xl\:border-opacity-100 {
    --border-opacity: 1 !important;
  }

  .lt-xl\:hover\:border-opacity-0:hover {
    --border-opacity: 0 !important;
  }

  .lt-xl\:hover\:border-opacity-12:hover {
    --border-opacity: 0.12 !important;
  }

  .lt-xl\:hover\:border-opacity-25:hover {
    --border-opacity: 0.25 !important;
  }

  .lt-xl\:hover\:border-opacity-38:hover {
    --border-opacity: 0.38 !important;
  }

  .lt-xl\:hover\:border-opacity-50:hover {
    --border-opacity: 0.5 !important;
  }

  .lt-xl\:hover\:border-opacity-54:hover {
    --border-opacity: 0.54 !important;
  }

  .lt-xl\:hover\:border-opacity-70:hover {
    --border-opacity: 0.70 !important;
  }

  .lt-xl\:hover\:border-opacity-75:hover {
    --border-opacity: 0.75 !important;
  }

  .lt-xl\:hover\:border-opacity-84:hover {
    --border-opacity: 0.84 !important;
  }

  .lt-xl\:hover\:border-opacity-100:hover {
    --border-opacity: 1 !important;
  }

  .lt-xl\:focus\:border-opacity-0:focus {
    --border-opacity: 0 !important;
  }

  .lt-xl\:focus\:border-opacity-12:focus {
    --border-opacity: 0.12 !important;
  }

  .lt-xl\:focus\:border-opacity-25:focus {
    --border-opacity: 0.25 !important;
  }

  .lt-xl\:focus\:border-opacity-38:focus {
    --border-opacity: 0.38 !important;
  }

  .lt-xl\:focus\:border-opacity-50:focus {
    --border-opacity: 0.5 !important;
  }

  .lt-xl\:focus\:border-opacity-54:focus {
    --border-opacity: 0.54 !important;
  }

  .lt-xl\:focus\:border-opacity-70:focus {
    --border-opacity: 0.70 !important;
  }

  .lt-xl\:focus\:border-opacity-75:focus {
    --border-opacity: 0.75 !important;
  }

  .lt-xl\:focus\:border-opacity-84:focus {
    --border-opacity: 0.84 !important;
  }

  .lt-xl\:focus\:border-opacity-100:focus {
    --border-opacity: 1 !important;
  }

  .lt-xl\:rounded-none {
    border-radius: 0 !important;
  }

  .lt-xl\:rounded-sm {
    border-radius: 0.125rem !important;
  }

  .lt-xl\:rounded {
    border-radius: 0.25rem !important;
  }

  .lt-xl\:rounded-md {
    border-radius: 0.375rem !important;
  }

  .lt-xl\:rounded-lg {
    border-radius: 0.5rem !important;
  }

  .lt-xl\:rounded-full {
    border-radius: 9999px !important;
  }

  .lt-xl\:rounded-t-none {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .lt-xl\:rounded-r-none {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .lt-xl\:rounded-b-none {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .lt-xl\:rounded-l-none {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .lt-xl\:rounded-t-sm {
    border-top-left-radius: 0.125rem !important;
    border-top-right-radius: 0.125rem !important;
  }

  .lt-xl\:rounded-r-sm {
    border-top-right-radius: 0.125rem !important;
    border-bottom-right-radius: 0.125rem !important;
  }

  .lt-xl\:rounded-b-sm {
    border-bottom-right-radius: 0.125rem !important;
    border-bottom-left-radius: 0.125rem !important;
  }

  .lt-xl\:rounded-l-sm {
    border-top-left-radius: 0.125rem !important;
    border-bottom-left-radius: 0.125rem !important;
  }

  .lt-xl\:rounded-t {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }

  .lt-xl\:rounded-r {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }

  .lt-xl\:rounded-b {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }

  .lt-xl\:rounded-l {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }

  .lt-xl\:rounded-t-md {
    border-top-left-radius: 0.375rem !important;
    border-top-right-radius: 0.375rem !important;
  }

  .lt-xl\:rounded-r-md {
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }

  .lt-xl\:rounded-b-md {
    border-bottom-right-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }

  .lt-xl\:rounded-l-md {
    border-top-left-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }

  .lt-xl\:rounded-t-lg {
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
  }

  .lt-xl\:rounded-r-lg {
    border-top-right-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }

  .lt-xl\:rounded-b-lg {
    border-bottom-right-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }

  .lt-xl\:rounded-l-lg {
    border-top-left-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }

  .lt-xl\:rounded-t-full {
    border-top-left-radius: 9999px !important;
    border-top-right-radius: 9999px !important;
  }

  .lt-xl\:rounded-r-full {
    border-top-right-radius: 9999px !important;
    border-bottom-right-radius: 9999px !important;
  }

  .lt-xl\:rounded-b-full {
    border-bottom-right-radius: 9999px !important;
    border-bottom-left-radius: 9999px !important;
  }

  .lt-xl\:rounded-l-full {
    border-top-left-radius: 9999px !important;
    border-bottom-left-radius: 9999px !important;
  }

  .lt-xl\:rounded-tl-none {
    border-top-left-radius: 0 !important;
  }

  .lt-xl\:rounded-tr-none {
    border-top-right-radius: 0 !important;
  }

  .lt-xl\:rounded-br-none {
    border-bottom-right-radius: 0 !important;
  }

  .lt-xl\:rounded-bl-none {
    border-bottom-left-radius: 0 !important;
  }

  .lt-xl\:rounded-tl-sm {
    border-top-left-radius: 0.125rem !important;
  }

  .lt-xl\:rounded-tr-sm {
    border-top-right-radius: 0.125rem !important;
  }

  .lt-xl\:rounded-br-sm {
    border-bottom-right-radius: 0.125rem !important;
  }

  .lt-xl\:rounded-bl-sm {
    border-bottom-left-radius: 0.125rem !important;
  }

  .lt-xl\:rounded-tl {
    border-top-left-radius: 0.25rem !important;
  }

  .lt-xl\:rounded-tr {
    border-top-right-radius: 0.25rem !important;
  }

  .lt-xl\:rounded-br {
    border-bottom-right-radius: 0.25rem !important;
  }

  .lt-xl\:rounded-bl {
    border-bottom-left-radius: 0.25rem !important;
  }

  .lt-xl\:rounded-tl-md {
    border-top-left-radius: 0.375rem !important;
  }

  .lt-xl\:rounded-tr-md {
    border-top-right-radius: 0.375rem !important;
  }

  .lt-xl\:rounded-br-md {
    border-bottom-right-radius: 0.375rem !important;
  }

  .lt-xl\:rounded-bl-md {
    border-bottom-left-radius: 0.375rem !important;
  }

  .lt-xl\:rounded-tl-lg {
    border-top-left-radius: 0.5rem !important;
  }

  .lt-xl\:rounded-tr-lg {
    border-top-right-radius: 0.5rem !important;
  }

  .lt-xl\:rounded-br-lg {
    border-bottom-right-radius: 0.5rem !important;
  }

  .lt-xl\:rounded-bl-lg {
    border-bottom-left-radius: 0.5rem !important;
  }

  .lt-xl\:rounded-tl-full {
    border-top-left-radius: 9999px !important;
  }

  .lt-xl\:rounded-tr-full {
    border-top-right-radius: 9999px !important;
  }

  .lt-xl\:rounded-br-full {
    border-bottom-right-radius: 9999px !important;
  }

  .lt-xl\:rounded-bl-full {
    border-bottom-left-radius: 9999px !important;
  }

  .lt-xl\:border-solid {
    border-style: solid !important;
  }

  .lt-xl\:border-dashed {
    border-style: dashed !important;
  }

  .lt-xl\:border-dotted {
    border-style: dotted !important;
  }

  .lt-xl\:border-double {
    border-style: double !important;
  }

  .lt-xl\:border-none {
    border-style: none !important;
  }

  .lt-xl\:border-0 {
    border-width: 0 !important;
  }

  .lt-xl\:border-2 {
    border-width: 2px !important;
  }

  .lt-xl\:border-4 {
    border-width: 4px !important;
  }

  .lt-xl\:border-8 {
    border-width: 8px !important;
  }

  .lt-xl\:border {
    border-width: 1px !important;
  }

  .lt-xl\:border-t-0 {
    border-top-width: 0 !important;
  }

  .lt-xl\:border-r-0 {
    border-right-width: 0 !important;
  }

  .lt-xl\:border-b-0 {
    border-bottom-width: 0 !important;
  }

  .lt-xl\:border-l-0 {
    border-left-width: 0 !important;
  }

  .lt-xl\:border-t-2 {
    border-top-width: 2px !important;
  }

  .lt-xl\:border-r-2 {
    border-right-width: 2px !important;
  }

  .lt-xl\:border-b-2 {
    border-bottom-width: 2px !important;
  }

  .lt-xl\:border-l-2 {
    border-left-width: 2px !important;
  }

  .lt-xl\:border-t-4 {
    border-top-width: 4px !important;
  }

  .lt-xl\:border-r-4 {
    border-right-width: 4px !important;
  }

  .lt-xl\:border-b-4 {
    border-bottom-width: 4px !important;
  }

  .lt-xl\:border-l-4 {
    border-left-width: 4px !important;
  }

  .lt-xl\:border-t-8 {
    border-top-width: 8px !important;
  }

  .lt-xl\:border-r-8 {
    border-right-width: 8px !important;
  }

  .lt-xl\:border-b-8 {
    border-bottom-width: 8px !important;
  }

  .lt-xl\:border-l-8 {
    border-left-width: 8px !important;
  }

  .lt-xl\:border-t {
    border-top-width: 1px !important;
  }

  .lt-xl\:border-r {
    border-right-width: 1px !important;
  }

  .lt-xl\:border-b {
    border-bottom-width: 1px !important;
  }

  .lt-xl\:border-l {
    border-left-width: 1px !important;
  }

  .lt-xl\:first\:border-0:first-child {
    border-width: 0 !important;
  }

  .lt-xl\:first\:border-2:first-child {
    border-width: 2px !important;
  }

  .lt-xl\:first\:border-4:first-child {
    border-width: 4px !important;
  }

  .lt-xl\:first\:border-8:first-child {
    border-width: 8px !important;
  }

  .lt-xl\:first\:border:first-child {
    border-width: 1px !important;
  }

  .lt-xl\:first\:border-t-0:first-child {
    border-top-width: 0 !important;
  }

  .lt-xl\:first\:border-r-0:first-child {
    border-right-width: 0 !important;
  }

  .lt-xl\:first\:border-b-0:first-child {
    border-bottom-width: 0 !important;
  }

  .lt-xl\:first\:border-l-0:first-child {
    border-left-width: 0 !important;
  }

  .lt-xl\:first\:border-t-2:first-child {
    border-top-width: 2px !important;
  }

  .lt-xl\:first\:border-r-2:first-child {
    border-right-width: 2px !important;
  }

  .lt-xl\:first\:border-b-2:first-child {
    border-bottom-width: 2px !important;
  }

  .lt-xl\:first\:border-l-2:first-child {
    border-left-width: 2px !important;
  }

  .lt-xl\:first\:border-t-4:first-child {
    border-top-width: 4px !important;
  }

  .lt-xl\:first\:border-r-4:first-child {
    border-right-width: 4px !important;
  }

  .lt-xl\:first\:border-b-4:first-child {
    border-bottom-width: 4px !important;
  }

  .lt-xl\:first\:border-l-4:first-child {
    border-left-width: 4px !important;
  }

  .lt-xl\:first\:border-t-8:first-child {
    border-top-width: 8px !important;
  }

  .lt-xl\:first\:border-r-8:first-child {
    border-right-width: 8px !important;
  }

  .lt-xl\:first\:border-b-8:first-child {
    border-bottom-width: 8px !important;
  }

  .lt-xl\:first\:border-l-8:first-child {
    border-left-width: 8px !important;
  }

  .lt-xl\:first\:border-t:first-child {
    border-top-width: 1px !important;
  }

  .lt-xl\:first\:border-r:first-child {
    border-right-width: 1px !important;
  }

  .lt-xl\:first\:border-b:first-child {
    border-bottom-width: 1px !important;
  }

  .lt-xl\:first\:border-l:first-child {
    border-left-width: 1px !important;
  }

  .lt-xl\:last\:border-0:last-child {
    border-width: 0 !important;
  }

  .lt-xl\:last\:border-2:last-child {
    border-width: 2px !important;
  }

  .lt-xl\:last\:border-4:last-child {
    border-width: 4px !important;
  }

  .lt-xl\:last\:border-8:last-child {
    border-width: 8px !important;
  }

  .lt-xl\:last\:border:last-child {
    border-width: 1px !important;
  }

  .lt-xl\:last\:border-t-0:last-child {
    border-top-width: 0 !important;
  }

  .lt-xl\:last\:border-r-0:last-child {
    border-right-width: 0 !important;
  }

  .lt-xl\:last\:border-b-0:last-child {
    border-bottom-width: 0 !important;
  }

  .lt-xl\:last\:border-l-0:last-child {
    border-left-width: 0 !important;
  }

  .lt-xl\:last\:border-t-2:last-child {
    border-top-width: 2px !important;
  }

  .lt-xl\:last\:border-r-2:last-child {
    border-right-width: 2px !important;
  }

  .lt-xl\:last\:border-b-2:last-child {
    border-bottom-width: 2px !important;
  }

  .lt-xl\:last\:border-l-2:last-child {
    border-left-width: 2px !important;
  }

  .lt-xl\:last\:border-t-4:last-child {
    border-top-width: 4px !important;
  }

  .lt-xl\:last\:border-r-4:last-child {
    border-right-width: 4px !important;
  }

  .lt-xl\:last\:border-b-4:last-child {
    border-bottom-width: 4px !important;
  }

  .lt-xl\:last\:border-l-4:last-child {
    border-left-width: 4px !important;
  }

  .lt-xl\:last\:border-t-8:last-child {
    border-top-width: 8px !important;
  }

  .lt-xl\:last\:border-r-8:last-child {
    border-right-width: 8px !important;
  }

  .lt-xl\:last\:border-b-8:last-child {
    border-bottom-width: 8px !important;
  }

  .lt-xl\:last\:border-l-8:last-child {
    border-left-width: 8px !important;
  }

  .lt-xl\:last\:border-t:last-child {
    border-top-width: 1px !important;
  }

  .lt-xl\:last\:border-r:last-child {
    border-right-width: 1px !important;
  }

  .lt-xl\:last\:border-b:last-child {
    border-bottom-width: 1px !important;
  }

  .lt-xl\:last\:border-l:last-child {
    border-left-width: 1px !important;
  }

  .lt-xl\:box-border {
    box-sizing: border-box !important;
  }

  .lt-xl\:box-content {
    box-sizing: content-box !important;
  }

  .lt-xl\:block {
    display: block !important;
  }

  .lt-xl\:inline-block {
    display: inline-block !important;
  }

  .lt-xl\:inline {
    display: inline !important;
  }

  .lt-xl\:flex {
    display: flex !important;
  }

  .lt-xl\:inline-flex {
    display: inline-flex !important;
  }

  .lt-xl\:table {
    display: table !important;
  }

  .lt-xl\:table-caption {
    display: table-caption !important;
  }

  .lt-xl\:table-cell {
    display: table-cell !important;
  }

  .lt-xl\:table-column {
    display: table-column !important;
  }

  .lt-xl\:table-column-group {
    display: table-column-group !important;
  }

  .lt-xl\:table-footer-group {
    display: table-footer-group !important;
  }

  .lt-xl\:table-header-group {
    display: table-header-group !important;
  }

  .lt-xl\:table-row-group {
    display: table-row-group !important;
  }

  .lt-xl\:table-row {
    display: table-row !important;
  }

  .lt-xl\:flow-root {
    display: flow-root !important;
  }

  .lt-xl\:grid {
    display: grid !important;
  }

  .lt-xl\:inline-grid {
    display: inline-grid !important;
  }

  .lt-xl\:hidden {
    display: none !important;
  }

  .lt-xl\:flex-row {
    flex-direction: row !important;
  }

  .lt-xl\:flex-row-reverse {
    flex-direction: row-reverse !important;
  }

  .lt-xl\:flex-col {
    flex-direction: column !important;
  }

  .lt-xl\:flex-col-reverse {
    flex-direction: column-reverse !important;
  }

  .lt-xl\:flex-wrap {
    flex-wrap: wrap !important;
  }

  .lt-xl\:flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .lt-xl\:flex-nowrap {
    flex-wrap: nowrap !important;
  }

  .lt-xl\:items-start {
    align-items: flex-start !important;
  }

  .lt-xl\:items-end {
    align-items: flex-end !important;
  }

  .lt-xl\:items-center {
    align-items: center !important;
  }

  .lt-xl\:items-baseline {
    align-items: baseline !important;
  }

  .lt-xl\:items-stretch {
    align-items: stretch !important;
  }

  .lt-xl\:self-auto {
    align-self: auto !important;
  }

  .lt-xl\:self-start {
    align-self: flex-start !important;
  }

  .lt-xl\:self-end {
    align-self: flex-end !important;
  }

  .lt-xl\:self-center {
    align-self: center !important;
  }

  .lt-xl\:self-stretch {
    align-self: stretch !important;
  }

  .lt-xl\:justify-start {
    justify-content: flex-start !important;
  }

  .lt-xl\:justify-end {
    justify-content: flex-end !important;
  }

  .lt-xl\:justify-center {
    justify-content: center !important;
  }

  .lt-xl\:justify-between {
    justify-content: space-between !important;
  }

  .lt-xl\:justify-around {
    justify-content: space-around !important;
  }

  .lt-xl\:justify-evenly {
    justify-content: space-evenly !important;
  }

  .lt-xl\:content-center {
    align-content: center !important;
  }

  .lt-xl\:content-start {
    align-content: flex-start !important;
  }

  .lt-xl\:content-end {
    align-content: flex-end !important;
  }

  .lt-xl\:content-between {
    align-content: space-between !important;
  }

  .lt-xl\:content-around {
    align-content: space-around !important;
  }

  .lt-xl\:flex-0 {
    flex: 0 0 auto !important;
  }

  .lt-xl\:flex-1 {
    flex: 1 1 0% !important;
  }

  .lt-xl\:flex-auto {
    flex: 1 1 auto !important;
  }

  .lt-xl\:flex-initial {
    flex: 0 1 auto !important;
  }

  .lt-xl\:flex-none {
    flex: none !important;
  }

  .lt-xl\:flex-grow-0 {
    flex-grow: 0 !important;
  }

  .lt-xl\:flex-grow {
    flex-grow: 1 !important;
  }

  .lt-xl\:flex-shrink-0 {
    flex-shrink: 0 !important;
  }

  .lt-xl\:flex-shrink {
    flex-shrink: 1 !important;
  }

  .lt-xl\:order-1 {
    order: 1 !important;
  }

  .lt-xl\:order-2 {
    order: 2 !important;
  }

  .lt-xl\:order-3 {
    order: 3 !important;
  }

  .lt-xl\:order-4 {
    order: 4 !important;
  }

  .lt-xl\:order-5 {
    order: 5 !important;
  }

  .lt-xl\:order-6 {
    order: 6 !important;
  }

  .lt-xl\:order-7 {
    order: 7 !important;
  }

  .lt-xl\:order-8 {
    order: 8 !important;
  }

  .lt-xl\:order-9 {
    order: 9 !important;
  }

  .lt-xl\:order-10 {
    order: 10 !important;
  }

  .lt-xl\:order-11 {
    order: 11 !important;
  }

  .lt-xl\:order-12 {
    order: 12 !important;
  }

  .lt-xl\:order-first {
    order: -9999 !important;
  }

  .lt-xl\:order-last {
    order: 9999 !important;
  }

  .lt-xl\:order-none {
    order: 0 !important;
  }

  .lt-xl\:font-thin {
    font-weight: 100 !important;
  }

  .lt-xl\:font-extralight {
    font-weight: 200 !important;
  }

  .lt-xl\:font-light {
    font-weight: 300 !important;
  }

  .lt-xl\:font-normal {
    font-weight: 400 !important;
  }

  .lt-xl\:font-medium {
    font-weight: 500 !important;
  }

  .lt-xl\:font-semibold {
    font-weight: 600 !important;
  }

  .lt-xl\:font-bold {
    font-weight: 700 !important;
  }

  .lt-xl\:font-extrabold {
    font-weight: 800 !important;
  }

  .lt-xl\:font-black {
    font-weight: 900 !important;
  }

  .lt-xl\:h-0 {
    height: 0 !important;
  }

  .lt-xl\:h-1 {
    height: 0.25rem !important;
  }

  .lt-xl\:h-2 {
    height: 0.5rem !important;
  }

  .lt-xl\:h-3 {
    height: 0.75rem !important;
  }

  .lt-xl\:h-4 {
    height: 1rem !important;
  }

  .lt-xl\:h-5 {
    height: 1.25rem !important;
  }

  .lt-xl\:h-6 {
    height: 1.5rem !important;
  }

  .lt-xl\:h-8 {
    height: 2rem !important;
  }

  .lt-xl\:h-10 {
    height: 2.5rem !important;
  }

  .lt-xl\:h-12 {
    height: 3rem !important;
  }

  .lt-xl\:h-14 {
    height: 3.5rem !important;
  }

  .lt-xl\:h-16 {
    height: 4rem !important;
  }

  .lt-xl\:h-18 {
    height: 4.5rem !important;
  }

  .lt-xl\:h-20 {
    height: 5rem !important;
  }

  .lt-xl\:h-22 {
    height: 5.5rem !important;
  }

  .lt-xl\:h-24 {
    height: 6rem !important;
  }

  .lt-xl\:h-26 {
    height: 6.5rem !important;
  }

  .lt-xl\:h-28 {
    height: 7rem !important;
  }

  .lt-xl\:h-30 {
    height: 7.5rem !important;
  }

  .lt-xl\:h-32 {
    height: 8rem !important;
  }

  .lt-xl\:h-36 {
    height: 9rem !important;
  }

  .lt-xl\:h-40 {
    height: 10rem !important;
  }

  .lt-xl\:h-48 {
    height: 12rem !important;
  }

  .lt-xl\:h-50 {
    height: 12.5rem !important;
  }

  .lt-xl\:h-56 {
    height: 14rem !important;
  }

  .lt-xl\:h-60 {
    height: 15rem !important;
  }

  .lt-xl\:h-64 {
    height: 16rem !important;
  }

  .lt-xl\:h-80 {
    height: 20rem !important;
  }

  .lt-xl\:h-90 {
    height: 24rem !important;
  }

  .lt-xl\:h-100 {
    height: 25rem !important;
  }

  .lt-xl\:h-120 {
    height: 30rem !important;
  }

  .lt-xl\:h-128 {
    height: 32rem !important;
  }

  .lt-xl\:h-140 {
    height: 35rem !important;
  }

  .lt-xl\:h-160 {
    height: 40rem !important;
  }

  .lt-xl\:h-180 {
    height: 45rem !important;
  }

  .lt-xl\:h-192 {
    height: 48rem !important;
  }

  .lt-xl\:h-200 {
    height: 50rem !important;
  }

  .lt-xl\:h-240 {
    height: 60rem !important;
  }

  .lt-xl\:h-256 {
    height: 64rem !important;
  }

  .lt-xl\:h-280 {
    height: 70rem !important;
  }

  .lt-xl\:h-320 {
    height: 80rem !important;
  }

  .lt-xl\:h-360 {
    height: 90rem !important;
  }

  .lt-xl\:h-400 {
    height: 100rem !important;
  }

  .lt-xl\:h-480 {
    height: 120rem !important;
  }

  .lt-xl\:h-auto {
    height: auto !important;
  }

  .lt-xl\:h-px {
    height: 1px !important;
  }

  .lt-xl\:h-2px {
    height: 2px !important;
  }

  .lt-xl\:h-full {
    height: 100% !important;
  }

  .lt-xl\:h-screen {
    height: 100vh !important;
  }

  .lt-xl\:h-1\/2 {
    height: 50% !important;
  }

  .lt-xl\:h-1\/3 {
    height: 33.33333% !important;
  }

  .lt-xl\:h-2\/3 {
    height: 66.66667% !important;
  }

  .lt-xl\:h-1\/4 {
    height: 25% !important;
  }

  .lt-xl\:h-2\/4 {
    height: 50% !important;
  }

  .lt-xl\:h-3\/4 {
    height: 75% !important;
  }

  .lt-xl\:h-1\/5 {
    height: 20% !important;
  }

  .lt-xl\:h-2\/5 {
    height: 40% !important;
  }

  .lt-xl\:h-3\/5 {
    height: 60% !important;
  }

  .lt-xl\:h-4\/5 {
    height: 80% !important;
  }

  .lt-xl\:h-1\/12 {
    height: 8.33333% !important;
  }

  .lt-xl\:h-2\/12 {
    height: 16.66667% !important;
  }

  .lt-xl\:h-3\/12 {
    height: 25% !important;
  }

  .lt-xl\:h-4\/12 {
    height: 33.33333% !important;
  }

  .lt-xl\:h-5\/12 {
    height: 41.66667% !important;
  }

  .lt-xl\:h-6\/12 {
    height: 50% !important;
  }

  .lt-xl\:h-7\/12 {
    height: 58.33333% !important;
  }

  .lt-xl\:h-8\/12 {
    height: 66.66667% !important;
  }

  .lt-xl\:h-9\/12 {
    height: 75% !important;
  }

  .lt-xl\:h-10\/12 {
    height: 83.33333% !important;
  }

  .lt-xl\:h-11\/12 {
    height: 91.66667% !important;
  }

  .lt-xl\:text-xs {
    font-size: 0.625rem !important;
  }

  .lt-xl\:text-sm {
    font-size: 0.75rem !important;
  }

  .lt-xl\:text-md {
    font-size: 0.8125rem !important;
  }

  .lt-xl\:text-base {
    font-size: 0.875rem !important;
  }

  .lt-xl\:text-lg {
    font-size: 1rem !important;
  }

  .lt-xl\:text-xl {
    font-size: 1.125rem !important;
  }

  .lt-xl\:text-2xl {
    font-size: 1.25rem !important;
  }

  .lt-xl\:text-3xl {
    font-size: 1.5rem !important;
  }

  .lt-xl\:text-4xl {
    font-size: 2rem !important;
  }

  .lt-xl\:text-5xl {
    font-size: 2.25rem !important;
  }

  .lt-xl\:text-6xl {
    font-size: 2.5rem !important;
  }

  .lt-xl\:text-7xl {
    font-size: 3rem !important;
  }

  .lt-xl\:text-8xl {
    font-size: 4rem !important;
  }

  .lt-xl\:text-9xl {
    font-size: 6rem !important;
  }

  .lt-xl\:text-10xl {
    font-size: 8rem !important;
  }

  .lt-xl\:leading-3 {
    line-height: .75rem !important;
  }

  .lt-xl\:leading-4 {
    line-height: 1rem !important;
  }

  .lt-xl\:leading-5 {
    line-height: 1.25rem !important;
  }

  .lt-xl\:leading-6 {
    line-height: 1.5rem !important;
  }

  .lt-xl\:leading-7 {
    line-height: 1.75rem !important;
  }

  .lt-xl\:leading-8 {
    line-height: 2rem !important;
  }

  .lt-xl\:leading-9 {
    line-height: 2.25rem !important;
  }

  .lt-xl\:leading-10 {
    line-height: 2.5rem !important;
  }

  .lt-xl\:leading-none {
    line-height: 1 !important;
  }

  .lt-xl\:leading-tight {
    line-height: 1.25 !important;
  }

  .lt-xl\:leading-snug {
    line-height: 1.375 !important;
  }

  .lt-xl\:leading-normal {
    line-height: 1.5 !important;
  }

  .lt-xl\:leading-relaxed {
    line-height: 1.625 !important;
  }

  .lt-xl\:leading-loose {
    line-height: 2 !important;
  }

  .lt-xl\:list-inside {
    list-style-position: inside !important;
  }

  .lt-xl\:list-outside {
    list-style-position: outside !important;
  }

  .lt-xl\:list-none {
    list-style-type: none !important;
  }

  .lt-xl\:list-disc {
    list-style-type: disc !important;
  }

  .lt-xl\:list-decimal {
    list-style-type: decimal !important;
  }

  .lt-xl\:m-0 {
    margin: 0 !important;
  }

  .lt-xl\:m-1 {
    margin: 0.25rem !important;
  }

  .lt-xl\:m-2 {
    margin: 0.5rem !important;
  }

  .lt-xl\:m-3 {
    margin: 0.75rem !important;
  }

  .lt-xl\:m-4 {
    margin: 1rem !important;
  }

  .lt-xl\:m-5 {
    margin: 1.25rem !important;
  }

  .lt-xl\:m-6 {
    margin: 1.5rem !important;
  }

  .lt-xl\:m-8 {
    margin: 2rem !important;
  }

  .lt-xl\:m-10 {
    margin: 2.5rem !important;
  }

  .lt-xl\:m-12 {
    margin: 3rem !important;
  }

  .lt-xl\:m-14 {
    margin: 3.5rem !important;
  }

  .lt-xl\:m-16 {
    margin: 4rem !important;
  }

  .lt-xl\:m-18 {
    margin: 4.5rem !important;
  }

  .lt-xl\:m-20 {
    margin: 5rem !important;
  }

  .lt-xl\:m-22 {
    margin: 5.5rem !important;
  }

  .lt-xl\:m-24 {
    margin: 6rem !important;
  }

  .lt-xl\:m-26 {
    margin: 6.5rem !important;
  }

  .lt-xl\:m-28 {
    margin: 7rem !important;
  }

  .lt-xl\:m-30 {
    margin: 7.5rem !important;
  }

  .lt-xl\:m-32 {
    margin: 8rem !important;
  }

  .lt-xl\:m-36 {
    margin: 9rem !important;
  }

  .lt-xl\:m-40 {
    margin: 10rem !important;
  }

  .lt-xl\:m-48 {
    margin: 12rem !important;
  }

  .lt-xl\:m-56 {
    margin: 14rem !important;
  }

  .lt-xl\:m-64 {
    margin: 16rem !important;
  }

  .lt-xl\:m-auto {
    margin: auto !important;
  }

  .lt-xl\:m-px {
    margin: 1px !important;
  }

  .lt-xl\:m-2px {
    margin: 2px !important;
  }

  .lt-xl\:-m-1 {
    margin: -0.25rem !important;
  }

  .lt-xl\:-m-2 {
    margin: -0.5rem !important;
  }

  .lt-xl\:-m-3 {
    margin: -0.75rem !important;
  }

  .lt-xl\:-m-4 {
    margin: -1rem !important;
  }

  .lt-xl\:-m-5 {
    margin: -1.25rem !important;
  }

  .lt-xl\:-m-6 {
    margin: -1.5rem !important;
  }

  .lt-xl\:-m-8 {
    margin: -2rem !important;
  }

  .lt-xl\:-m-10 {
    margin: -2.5rem !important;
  }

  .lt-xl\:-m-12 {
    margin: -3rem !important;
  }

  .lt-xl\:-m-14 {
    margin: -3.5rem !important;
  }

  .lt-xl\:-m-16 {
    margin: -4rem !important;
  }

  .lt-xl\:-m-18 {
    margin: -4.5rem !important;
  }

  .lt-xl\:-m-20 {
    margin: -5rem !important;
  }

  .lt-xl\:-m-22 {
    margin: -5.5rem !important;
  }

  .lt-xl\:-m-24 {
    margin: -6rem !important;
  }

  .lt-xl\:-m-26 {
    margin: -6.5rem !important;
  }

  .lt-xl\:-m-28 {
    margin: -7rem !important;
  }

  .lt-xl\:-m-30 {
    margin: -7.5rem !important;
  }

  .lt-xl\:-m-32 {
    margin: -8rem !important;
  }

  .lt-xl\:-m-36 {
    margin: -9rem !important;
  }

  .lt-xl\:-m-40 {
    margin: -10rem !important;
  }

  .lt-xl\:-m-48 {
    margin: -12rem !important;
  }

  .lt-xl\:-m-56 {
    margin: -14rem !important;
  }

  .lt-xl\:-m-64 {
    margin: -16rem !important;
  }

  .lt-xl\:-m-px {
    margin: -1px !important;
  }

  .lt-xl\:-m-2px {
    margin: -2px !important;
  }

  .lt-xl\:my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .lt-xl\:mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .lt-xl\:my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .lt-xl\:mx-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }

  .lt-xl\:my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .lt-xl\:mx-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .lt-xl\:my-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .lt-xl\:mx-3 {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
  }

  .lt-xl\:my-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .lt-xl\:mx-4 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .lt-xl\:my-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .lt-xl\:mx-5 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }

  .lt-xl\:my-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .lt-xl\:mx-6 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .lt-xl\:my-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .lt-xl\:mx-8 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .lt-xl\:my-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .lt-xl\:mx-10 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .lt-xl\:my-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .lt-xl\:mx-12 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .lt-xl\:my-14 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .lt-xl\:mx-14 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }

  .lt-xl\:my-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .lt-xl\:mx-16 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .lt-xl\:my-18 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .lt-xl\:mx-18 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }

  .lt-xl\:my-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .lt-xl\:mx-20 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .lt-xl\:my-22 {
    margin-top: 5.5rem !important;
    margin-bottom: 5.5rem !important;
  }

  .lt-xl\:mx-22 {
    margin-left: 5.5rem !important;
    margin-right: 5.5rem !important;
  }

  .lt-xl\:my-24 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .lt-xl\:mx-24 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }

  .lt-xl\:my-26 {
    margin-top: 6.5rem !important;
    margin-bottom: 6.5rem !important;
  }

  .lt-xl\:mx-26 {
    margin-left: 6.5rem !important;
    margin-right: 6.5rem !important;
  }

  .lt-xl\:my-28 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }

  .lt-xl\:mx-28 {
    margin-left: 7rem !important;
    margin-right: 7rem !important;
  }

  .lt-xl\:my-30 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }

  .lt-xl\:mx-30 {
    margin-left: 7.5rem !important;
    margin-right: 7.5rem !important;
  }

  .lt-xl\:my-32 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .lt-xl\:mx-32 {
    margin-left: 8rem !important;
    margin-right: 8rem !important;
  }

  .lt-xl\:my-36 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }

  .lt-xl\:mx-36 {
    margin-left: 9rem !important;
    margin-right: 9rem !important;
  }

  .lt-xl\:my-40 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .lt-xl\:mx-40 {
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }

  .lt-xl\:my-48 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }

  .lt-xl\:mx-48 {
    margin-left: 12rem !important;
    margin-right: 12rem !important;
  }

  .lt-xl\:my-56 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }

  .lt-xl\:mx-56 {
    margin-left: 14rem !important;
    margin-right: 14rem !important;
  }

  .lt-xl\:my-64 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }

  .lt-xl\:mx-64 {
    margin-left: 16rem !important;
    margin-right: 16rem !important;
  }

  .lt-xl\:my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .lt-xl\:mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .lt-xl\:my-px {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  .lt-xl\:mx-px {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }

  .lt-xl\:my-2px {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }

  .lt-xl\:mx-2px {
    margin-left: 2px !important;
    margin-right: 2px !important;
  }

  .lt-xl\:-my-1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .lt-xl\:-mx-1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }

  .lt-xl\:-my-2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .lt-xl\:-mx-2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }

  .lt-xl\:-my-3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }

  .lt-xl\:-mx-3 {
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important;
  }

  .lt-xl\:-my-4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .lt-xl\:-mx-4 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .lt-xl\:-my-5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .lt-xl\:-mx-5 {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }

  .lt-xl\:-my-6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .lt-xl\:-mx-6 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .lt-xl\:-my-8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .lt-xl\:-mx-8 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .lt-xl\:-my-10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .lt-xl\:-mx-10 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }

  .lt-xl\:-my-12 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .lt-xl\:-mx-12 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .lt-xl\:-my-14 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .lt-xl\:-mx-14 {
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }

  .lt-xl\:-my-16 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .lt-xl\:-mx-16 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .lt-xl\:-my-18 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .lt-xl\:-mx-18 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }

  .lt-xl\:-my-20 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .lt-xl\:-mx-20 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .lt-xl\:-my-22 {
    margin-top: -5.5rem !important;
    margin-bottom: -5.5rem !important;
  }

  .lt-xl\:-mx-22 {
    margin-left: -5.5rem !important;
    margin-right: -5.5rem !important;
  }

  .lt-xl\:-my-24 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .lt-xl\:-mx-24 {
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }

  .lt-xl\:-my-26 {
    margin-top: -6.5rem !important;
    margin-bottom: -6.5rem !important;
  }

  .lt-xl\:-mx-26 {
    margin-left: -6.5rem !important;
    margin-right: -6.5rem !important;
  }

  .lt-xl\:-my-28 {
    margin-top: -7rem !important;
    margin-bottom: -7rem !important;
  }

  .lt-xl\:-mx-28 {
    margin-left: -7rem !important;
    margin-right: -7rem !important;
  }

  .lt-xl\:-my-30 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important;
  }

  .lt-xl\:-mx-30 {
    margin-left: -7.5rem !important;
    margin-right: -7.5rem !important;
  }

  .lt-xl\:-my-32 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .lt-xl\:-mx-32 {
    margin-left: -8rem !important;
    margin-right: -8rem !important;
  }

  .lt-xl\:-my-36 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }

  .lt-xl\:-mx-36 {
    margin-left: -9rem !important;
    margin-right: -9rem !important;
  }

  .lt-xl\:-my-40 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .lt-xl\:-mx-40 {
    margin-left: -10rem !important;
    margin-right: -10rem !important;
  }

  .lt-xl\:-my-48 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }

  .lt-xl\:-mx-48 {
    margin-left: -12rem !important;
    margin-right: -12rem !important;
  }

  .lt-xl\:-my-56 {
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }

  .lt-xl\:-mx-56 {
    margin-left: -14rem !important;
    margin-right: -14rem !important;
  }

  .lt-xl\:-my-64 {
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }

  .lt-xl\:-mx-64 {
    margin-left: -16rem !important;
    margin-right: -16rem !important;
  }

  .lt-xl\:-my-px {
    margin-top: -1px !important;
    margin-bottom: -1px !important;
  }

  .lt-xl\:-mx-px {
    margin-left: -1px !important;
    margin-right: -1px !important;
  }

  .lt-xl\:-my-2px {
    margin-top: -2px !important;
    margin-bottom: -2px !important;
  }

  .lt-xl\:-mx-2px {
    margin-left: -2px !important;
    margin-right: -2px !important;
  }

  .lt-xl\:mt-0 {
    margin-top: 0 !important;
  }

  .lt-xl\:mr-0 {
    margin-right: 0 !important;
  }

  .lt-xl\:mb-0 {
    margin-bottom: 0 !important;
  }

  .lt-xl\:ml-0 {
    margin-left: 0 !important;
  }

  .lt-xl\:mt-1 {
    margin-top: 0.25rem !important;
  }

  .lt-xl\:mr-1 {
    margin-right: 0.25rem !important;
  }

  .lt-xl\:mb-1 {
    margin-bottom: 0.25rem !important;
  }

  .lt-xl\:ml-1 {
    margin-left: 0.25rem !important;
  }

  .lt-xl\:mt-2 {
    margin-top: 0.5rem !important;
  }

  .lt-xl\:mr-2 {
    margin-right: 0.5rem !important;
  }

  .lt-xl\:mb-2 {
    margin-bottom: 0.5rem !important;
  }

  .lt-xl\:ml-2 {
    margin-left: 0.5rem !important;
  }

  .lt-xl\:mt-3 {
    margin-top: 0.75rem !important;
  }

  .lt-xl\:mr-3 {
    margin-right: 0.75rem !important;
  }

  .lt-xl\:mb-3 {
    margin-bottom: 0.75rem !important;
  }

  .lt-xl\:ml-3 {
    margin-left: 0.75rem !important;
  }

  .lt-xl\:mt-4 {
    margin-top: 1rem !important;
  }

  .lt-xl\:mr-4 {
    margin-right: 1rem !important;
  }

  .lt-xl\:mb-4 {
    margin-bottom: 1rem !important;
  }

  .lt-xl\:ml-4 {
    margin-left: 1rem !important;
  }

  .lt-xl\:mt-5 {
    margin-top: 1.25rem !important;
  }

  .lt-xl\:mr-5 {
    margin-right: 1.25rem !important;
  }

  .lt-xl\:mb-5 {
    margin-bottom: 1.25rem !important;
  }

  .lt-xl\:ml-5 {
    margin-left: 1.25rem !important;
  }

  .lt-xl\:mt-6 {
    margin-top: 1.5rem !important;
  }

  .lt-xl\:mr-6 {
    margin-right: 1.5rem !important;
  }

  .lt-xl\:mb-6 {
    margin-bottom: 1.5rem !important;
  }

  .lt-xl\:ml-6 {
    margin-left: 1.5rem !important;
  }

  .lt-xl\:mt-8 {
    margin-top: 2rem !important;
  }

  .lt-xl\:mr-8 {
    margin-right: 2rem !important;
  }

  .lt-xl\:mb-8 {
    margin-bottom: 2rem !important;
  }

  .lt-xl\:ml-8 {
    margin-left: 2rem !important;
  }

  .lt-xl\:mt-10 {
    margin-top: 2.5rem !important;
  }

  .lt-xl\:mr-10 {
    margin-right: 2.5rem !important;
  }

  .lt-xl\:mb-10 {
    margin-bottom: 2.5rem !important;
  }

  .lt-xl\:ml-10 {
    margin-left: 2.5rem !important;
  }

  .lt-xl\:mt-12 {
    margin-top: 3rem !important;
  }

  .lt-xl\:mr-12 {
    margin-right: 3rem !important;
  }

  .lt-xl\:mb-12 {
    margin-bottom: 3rem !important;
  }

  .lt-xl\:ml-12 {
    margin-left: 3rem !important;
  }

  .lt-xl\:mt-14 {
    margin-top: 3.5rem !important;
  }

  .lt-xl\:mr-14 {
    margin-right: 3.5rem !important;
  }

  .lt-xl\:mb-14 {
    margin-bottom: 3.5rem !important;
  }

  .lt-xl\:ml-14 {
    margin-left: 3.5rem !important;
  }

  .lt-xl\:mt-16 {
    margin-top: 4rem !important;
  }

  .lt-xl\:mr-16 {
    margin-right: 4rem !important;
  }

  .lt-xl\:mb-16 {
    margin-bottom: 4rem !important;
  }

  .lt-xl\:ml-16 {
    margin-left: 4rem !important;
  }

  .lt-xl\:mt-18 {
    margin-top: 4.5rem !important;
  }

  .lt-xl\:mr-18 {
    margin-right: 4.5rem !important;
  }

  .lt-xl\:mb-18 {
    margin-bottom: 4.5rem !important;
  }

  .lt-xl\:ml-18 {
    margin-left: 4.5rem !important;
  }

  .lt-xl\:mt-20 {
    margin-top: 5rem !important;
  }

  .lt-xl\:mr-20 {
    margin-right: 5rem !important;
  }

  .lt-xl\:mb-20 {
    margin-bottom: 5rem !important;
  }

  .lt-xl\:ml-20 {
    margin-left: 5rem !important;
  }

  .lt-xl\:mt-22 {
    margin-top: 5.5rem !important;
  }

  .lt-xl\:mr-22 {
    margin-right: 5.5rem !important;
  }

  .lt-xl\:mb-22 {
    margin-bottom: 5.5rem !important;
  }

  .lt-xl\:ml-22 {
    margin-left: 5.5rem !important;
  }

  .lt-xl\:mt-24 {
    margin-top: 6rem !important;
  }

  .lt-xl\:mr-24 {
    margin-right: 6rem !important;
  }

  .lt-xl\:mb-24 {
    margin-bottom: 6rem !important;
  }

  .lt-xl\:ml-24 {
    margin-left: 6rem !important;
  }

  .lt-xl\:mt-26 {
    margin-top: 6.5rem !important;
  }

  .lt-xl\:mr-26 {
    margin-right: 6.5rem !important;
  }

  .lt-xl\:mb-26 {
    margin-bottom: 6.5rem !important;
  }

  .lt-xl\:ml-26 {
    margin-left: 6.5rem !important;
  }

  .lt-xl\:mt-28 {
    margin-top: 7rem !important;
  }

  .lt-xl\:mr-28 {
    margin-right: 7rem !important;
  }

  .lt-xl\:mb-28 {
    margin-bottom: 7rem !important;
  }

  .lt-xl\:ml-28 {
    margin-left: 7rem !important;
  }

  .lt-xl\:mt-30 {
    margin-top: 7.5rem !important;
  }

  .lt-xl\:mr-30 {
    margin-right: 7.5rem !important;
  }

  .lt-xl\:mb-30 {
    margin-bottom: 7.5rem !important;
  }

  .lt-xl\:ml-30 {
    margin-left: 7.5rem !important;
  }

  .lt-xl\:mt-32 {
    margin-top: 8rem !important;
  }

  .lt-xl\:mr-32 {
    margin-right: 8rem !important;
  }

  .lt-xl\:mb-32 {
    margin-bottom: 8rem !important;
  }

  .lt-xl\:ml-32 {
    margin-left: 8rem !important;
  }

  .lt-xl\:mt-36 {
    margin-top: 9rem !important;
  }

  .lt-xl\:mr-36 {
    margin-right: 9rem !important;
  }

  .lt-xl\:mb-36 {
    margin-bottom: 9rem !important;
  }

  .lt-xl\:ml-36 {
    margin-left: 9rem !important;
  }

  .lt-xl\:mt-40 {
    margin-top: 10rem !important;
  }

  .lt-xl\:mr-40 {
    margin-right: 10rem !important;
  }

  .lt-xl\:mb-40 {
    margin-bottom: 10rem !important;
  }

  .lt-xl\:ml-40 {
    margin-left: 10rem !important;
  }

  .lt-xl\:mt-48 {
    margin-top: 12rem !important;
  }

  .lt-xl\:mr-48 {
    margin-right: 12rem !important;
  }

  .lt-xl\:mb-48 {
    margin-bottom: 12rem !important;
  }

  .lt-xl\:ml-48 {
    margin-left: 12rem !important;
  }

  .lt-xl\:mt-56 {
    margin-top: 14rem !important;
  }

  .lt-xl\:mr-56 {
    margin-right: 14rem !important;
  }

  .lt-xl\:mb-56 {
    margin-bottom: 14rem !important;
  }

  .lt-xl\:ml-56 {
    margin-left: 14rem !important;
  }

  .lt-xl\:mt-64 {
    margin-top: 16rem !important;
  }

  .lt-xl\:mr-64 {
    margin-right: 16rem !important;
  }

  .lt-xl\:mb-64 {
    margin-bottom: 16rem !important;
  }

  .lt-xl\:ml-64 {
    margin-left: 16rem !important;
  }

  .lt-xl\:mt-auto {
    margin-top: auto !important;
  }

  .lt-xl\:mr-auto {
    margin-right: auto !important;
  }

  .lt-xl\:mb-auto {
    margin-bottom: auto !important;
  }

  .lt-xl\:ml-auto {
    margin-left: auto !important;
  }

  .lt-xl\:mt-px {
    margin-top: 1px !important;
  }

  .lt-xl\:mr-px {
    margin-right: 1px !important;
  }

  .lt-xl\:mb-px {
    margin-bottom: 1px !important;
  }

  .lt-xl\:ml-px {
    margin-left: 1px !important;
  }

  .lt-xl\:mt-2px {
    margin-top: 2px !important;
  }

  .lt-xl\:mr-2px {
    margin-right: 2px !important;
  }

  .lt-xl\:mb-2px {
    margin-bottom: 2px !important;
  }

  .lt-xl\:ml-2px {
    margin-left: 2px !important;
  }

  .lt-xl\:-mt-1 {
    margin-top: -0.25rem !important;
  }

  .lt-xl\:-mr-1 {
    margin-right: -0.25rem !important;
  }

  .lt-xl\:-mb-1 {
    margin-bottom: -0.25rem !important;
  }

  .lt-xl\:-ml-1 {
    margin-left: -0.25rem !important;
  }

  .lt-xl\:-mt-2 {
    margin-top: -0.5rem !important;
  }

  .lt-xl\:-mr-2 {
    margin-right: -0.5rem !important;
  }

  .lt-xl\:-mb-2 {
    margin-bottom: -0.5rem !important;
  }

  .lt-xl\:-ml-2 {
    margin-left: -0.5rem !important;
  }

  .lt-xl\:-mt-3 {
    margin-top: -0.75rem !important;
  }

  .lt-xl\:-mr-3 {
    margin-right: -0.75rem !important;
  }

  .lt-xl\:-mb-3 {
    margin-bottom: -0.75rem !important;
  }

  .lt-xl\:-ml-3 {
    margin-left: -0.75rem !important;
  }

  .lt-xl\:-mt-4 {
    margin-top: -1rem !important;
  }

  .lt-xl\:-mr-4 {
    margin-right: -1rem !important;
  }

  .lt-xl\:-mb-4 {
    margin-bottom: -1rem !important;
  }

  .lt-xl\:-ml-4 {
    margin-left: -1rem !important;
  }

  .lt-xl\:-mt-5 {
    margin-top: -1.25rem !important;
  }

  .lt-xl\:-mr-5 {
    margin-right: -1.25rem !important;
  }

  .lt-xl\:-mb-5 {
    margin-bottom: -1.25rem !important;
  }

  .lt-xl\:-ml-5 {
    margin-left: -1.25rem !important;
  }

  .lt-xl\:-mt-6 {
    margin-top: -1.5rem !important;
  }

  .lt-xl\:-mr-6 {
    margin-right: -1.5rem !important;
  }

  .lt-xl\:-mb-6 {
    margin-bottom: -1.5rem !important;
  }

  .lt-xl\:-ml-6 {
    margin-left: -1.5rem !important;
  }

  .lt-xl\:-mt-8 {
    margin-top: -2rem !important;
  }

  .lt-xl\:-mr-8 {
    margin-right: -2rem !important;
  }

  .lt-xl\:-mb-8 {
    margin-bottom: -2rem !important;
  }

  .lt-xl\:-ml-8 {
    margin-left: -2rem !important;
  }

  .lt-xl\:-mt-10 {
    margin-top: -2.5rem !important;
  }

  .lt-xl\:-mr-10 {
    margin-right: -2.5rem !important;
  }

  .lt-xl\:-mb-10 {
    margin-bottom: -2.5rem !important;
  }

  .lt-xl\:-ml-10 {
    margin-left: -2.5rem !important;
  }

  .lt-xl\:-mt-12 {
    margin-top: -3rem !important;
  }

  .lt-xl\:-mr-12 {
    margin-right: -3rem !important;
  }

  .lt-xl\:-mb-12 {
    margin-bottom: -3rem !important;
  }

  .lt-xl\:-ml-12 {
    margin-left: -3rem !important;
  }

  .lt-xl\:-mt-14 {
    margin-top: -3.5rem !important;
  }

  .lt-xl\:-mr-14 {
    margin-right: -3.5rem !important;
  }

  .lt-xl\:-mb-14 {
    margin-bottom: -3.5rem !important;
  }

  .lt-xl\:-ml-14 {
    margin-left: -3.5rem !important;
  }

  .lt-xl\:-mt-16 {
    margin-top: -4rem !important;
  }

  .lt-xl\:-mr-16 {
    margin-right: -4rem !important;
  }

  .lt-xl\:-mb-16 {
    margin-bottom: -4rem !important;
  }

  .lt-xl\:-ml-16 {
    margin-left: -4rem !important;
  }

  .lt-xl\:-mt-18 {
    margin-top: -4.5rem !important;
  }

  .lt-xl\:-mr-18 {
    margin-right: -4.5rem !important;
  }

  .lt-xl\:-mb-18 {
    margin-bottom: -4.5rem !important;
  }

  .lt-xl\:-ml-18 {
    margin-left: -4.5rem !important;
  }

  .lt-xl\:-mt-20 {
    margin-top: -5rem !important;
  }

  .lt-xl\:-mr-20 {
    margin-right: -5rem !important;
  }

  .lt-xl\:-mb-20 {
    margin-bottom: -5rem !important;
  }

  .lt-xl\:-ml-20 {
    margin-left: -5rem !important;
  }

  .lt-xl\:-mt-22 {
    margin-top: -5.5rem !important;
  }

  .lt-xl\:-mr-22 {
    margin-right: -5.5rem !important;
  }

  .lt-xl\:-mb-22 {
    margin-bottom: -5.5rem !important;
  }

  .lt-xl\:-ml-22 {
    margin-left: -5.5rem !important;
  }

  .lt-xl\:-mt-24 {
    margin-top: -6rem !important;
  }

  .lt-xl\:-mr-24 {
    margin-right: -6rem !important;
  }

  .lt-xl\:-mb-24 {
    margin-bottom: -6rem !important;
  }

  .lt-xl\:-ml-24 {
    margin-left: -6rem !important;
  }

  .lt-xl\:-mt-26 {
    margin-top: -6.5rem !important;
  }

  .lt-xl\:-mr-26 {
    margin-right: -6.5rem !important;
  }

  .lt-xl\:-mb-26 {
    margin-bottom: -6.5rem !important;
  }

  .lt-xl\:-ml-26 {
    margin-left: -6.5rem !important;
  }

  .lt-xl\:-mt-28 {
    margin-top: -7rem !important;
  }

  .lt-xl\:-mr-28 {
    margin-right: -7rem !important;
  }

  .lt-xl\:-mb-28 {
    margin-bottom: -7rem !important;
  }

  .lt-xl\:-ml-28 {
    margin-left: -7rem !important;
  }

  .lt-xl\:-mt-30 {
    margin-top: -7.5rem !important;
  }

  .lt-xl\:-mr-30 {
    margin-right: -7.5rem !important;
  }

  .lt-xl\:-mb-30 {
    margin-bottom: -7.5rem !important;
  }

  .lt-xl\:-ml-30 {
    margin-left: -7.5rem !important;
  }

  .lt-xl\:-mt-32 {
    margin-top: -8rem !important;
  }

  .lt-xl\:-mr-32 {
    margin-right: -8rem !important;
  }

  .lt-xl\:-mb-32 {
    margin-bottom: -8rem !important;
  }

  .lt-xl\:-ml-32 {
    margin-left: -8rem !important;
  }

  .lt-xl\:-mt-36 {
    margin-top: -9rem !important;
  }

  .lt-xl\:-mr-36 {
    margin-right: -9rem !important;
  }

  .lt-xl\:-mb-36 {
    margin-bottom: -9rem !important;
  }

  .lt-xl\:-ml-36 {
    margin-left: -9rem !important;
  }

  .lt-xl\:-mt-40 {
    margin-top: -10rem !important;
  }

  .lt-xl\:-mr-40 {
    margin-right: -10rem !important;
  }

  .lt-xl\:-mb-40 {
    margin-bottom: -10rem !important;
  }

  .lt-xl\:-ml-40 {
    margin-left: -10rem !important;
  }

  .lt-xl\:-mt-48 {
    margin-top: -12rem !important;
  }

  .lt-xl\:-mr-48 {
    margin-right: -12rem !important;
  }

  .lt-xl\:-mb-48 {
    margin-bottom: -12rem !important;
  }

  .lt-xl\:-ml-48 {
    margin-left: -12rem !important;
  }

  .lt-xl\:-mt-56 {
    margin-top: -14rem !important;
  }

  .lt-xl\:-mr-56 {
    margin-right: -14rem !important;
  }

  .lt-xl\:-mb-56 {
    margin-bottom: -14rem !important;
  }

  .lt-xl\:-ml-56 {
    margin-left: -14rem !important;
  }

  .lt-xl\:-mt-64 {
    margin-top: -16rem !important;
  }

  .lt-xl\:-mr-64 {
    margin-right: -16rem !important;
  }

  .lt-xl\:-mb-64 {
    margin-bottom: -16rem !important;
  }

  .lt-xl\:-ml-64 {
    margin-left: -16rem !important;
  }

  .lt-xl\:-mt-px {
    margin-top: -1px !important;
  }

  .lt-xl\:-mr-px {
    margin-right: -1px !important;
  }

  .lt-xl\:-mb-px {
    margin-bottom: -1px !important;
  }

  .lt-xl\:-ml-px {
    margin-left: -1px !important;
  }

  .lt-xl\:-mt-2px {
    margin-top: -2px !important;
  }

  .lt-xl\:-mr-2px {
    margin-right: -2px !important;
  }

  .lt-xl\:-mb-2px {
    margin-bottom: -2px !important;
  }

  .lt-xl\:-ml-2px {
    margin-left: -2px !important;
  }

  .lt-xl\:max-h-0 {
    max-height: 0 !important;
  }

  .lt-xl\:max-h-1 {
    max-height: 0.25rem !important;
  }

  .lt-xl\:max-h-2 {
    max-height: 0.5rem !important;
  }

  .lt-xl\:max-h-3 {
    max-height: 0.75rem !important;
  }

  .lt-xl\:max-h-4 {
    max-height: 1rem !important;
  }

  .lt-xl\:max-h-5 {
    max-height: 1.25rem !important;
  }

  .lt-xl\:max-h-6 {
    max-height: 1.5rem !important;
  }

  .lt-xl\:max-h-8 {
    max-height: 2rem !important;
  }

  .lt-xl\:max-h-10 {
    max-height: 2.5rem !important;
  }

  .lt-xl\:max-h-12 {
    max-height: 3rem !important;
  }

  .lt-xl\:max-h-14 {
    max-height: 3.5rem !important;
  }

  .lt-xl\:max-h-16 {
    max-height: 4rem !important;
  }

  .lt-xl\:max-h-18 {
    max-height: 4.5rem !important;
  }

  .lt-xl\:max-h-20 {
    max-height: 5rem !important;
  }

  .lt-xl\:max-h-22 {
    max-height: 5.5rem !important;
  }

  .lt-xl\:max-h-24 {
    max-height: 6rem !important;
  }

  .lt-xl\:max-h-26 {
    max-height: 6.5rem !important;
  }

  .lt-xl\:max-h-28 {
    max-height: 7rem !important;
  }

  .lt-xl\:max-h-30 {
    max-height: 7.5rem !important;
  }

  .lt-xl\:max-h-32 {
    max-height: 8rem !important;
  }

  .lt-xl\:max-h-36 {
    max-height: 9rem !important;
  }

  .lt-xl\:max-h-40 {
    max-height: 10rem !important;
  }

  .lt-xl\:max-h-48 {
    max-height: 12rem !important;
  }

  .lt-xl\:max-h-50 {
    max-height: 12.5rem !important;
  }

  .lt-xl\:max-h-56 {
    max-height: 14rem !important;
  }

  .lt-xl\:max-h-60 {
    max-height: 15rem !important;
  }

  .lt-xl\:max-h-64 {
    max-height: 16rem !important;
  }

  .lt-xl\:max-h-80 {
    max-height: 20rem !important;
  }

  .lt-xl\:max-h-90 {
    max-height: 24rem !important;
  }

  .lt-xl\:max-h-100 {
    max-height: 25rem !important;
  }

  .lt-xl\:max-h-120 {
    max-height: 30rem !important;
  }

  .lt-xl\:max-h-128 {
    max-height: 32rem !important;
  }

  .lt-xl\:max-h-140 {
    max-height: 35rem !important;
  }

  .lt-xl\:max-h-160 {
    max-height: 40rem !important;
  }

  .lt-xl\:max-h-180 {
    max-height: 45rem !important;
  }

  .lt-xl\:max-h-192 {
    max-height: 48rem !important;
  }

  .lt-xl\:max-h-200 {
    max-height: 50rem !important;
  }

  .lt-xl\:max-h-240 {
    max-height: 60rem !important;
  }

  .lt-xl\:max-h-256 {
    max-height: 64rem !important;
  }

  .lt-xl\:max-h-280 {
    max-height: 70rem !important;
  }

  .lt-xl\:max-h-320 {
    max-height: 80rem !important;
  }

  .lt-xl\:max-h-360 {
    max-height: 90rem !important;
  }

  .lt-xl\:max-h-400 {
    max-height: 100rem !important;
  }

  .lt-xl\:max-h-480 {
    max-height: 120rem !important;
  }

  .lt-xl\:max-h-full {
    max-height: 100% !important;
  }

  .lt-xl\:max-h-screen {
    max-height: 100vh !important;
  }

  .lt-xl\:max-h-none {
    max-height: none !important;
  }

  .lt-xl\:max-h-px {
    max-height: 1px !important;
  }

  .lt-xl\:max-h-2px {
    max-height: 2px !important;
  }

  .lt-xl\:max-h-1\/2 {
    max-height: 50% !important;
  }

  .lt-xl\:max-h-1\/3 {
    max-height: 33.33333% !important;
  }

  .lt-xl\:max-h-2\/3 {
    max-height: 66.66667% !important;
  }

  .lt-xl\:max-h-1\/4 {
    max-height: 25% !important;
  }

  .lt-xl\:max-h-2\/4 {
    max-height: 50% !important;
  }

  .lt-xl\:max-h-3\/4 {
    max-height: 75% !important;
  }

  .lt-xl\:max-h-1\/5 {
    max-height: 20% !important;
  }

  .lt-xl\:max-h-2\/5 {
    max-height: 40% !important;
  }

  .lt-xl\:max-h-3\/5 {
    max-height: 60% !important;
  }

  .lt-xl\:max-h-4\/5 {
    max-height: 80% !important;
  }

  .lt-xl\:max-h-1\/12 {
    max-height: 8.33333% !important;
  }

  .lt-xl\:max-h-2\/12 {
    max-height: 16.66667% !important;
  }

  .lt-xl\:max-h-3\/12 {
    max-height: 25% !important;
  }

  .lt-xl\:max-h-4\/12 {
    max-height: 33.33333% !important;
  }

  .lt-xl\:max-h-5\/12 {
    max-height: 41.66667% !important;
  }

  .lt-xl\:max-h-6\/12 {
    max-height: 50% !important;
  }

  .lt-xl\:max-h-7\/12 {
    max-height: 58.33333% !important;
  }

  .lt-xl\:max-h-8\/12 {
    max-height: 66.66667% !important;
  }

  .lt-xl\:max-h-9\/12 {
    max-height: 75% !important;
  }

  .lt-xl\:max-h-10\/12 {
    max-height: 83.33333% !important;
  }

  .lt-xl\:max-h-11\/12 {
    max-height: 91.66667% !important;
  }

  .lt-xl\:max-w-0 {
    max-width: 0 !important;
  }

  .lt-xl\:max-w-1 {
    max-width: 0.25rem !important;
  }

  .lt-xl\:max-w-2 {
    max-width: 0.5rem !important;
  }

  .lt-xl\:max-w-3 {
    max-width: 0.75rem !important;
  }

  .lt-xl\:max-w-4 {
    max-width: 1rem !important;
  }

  .lt-xl\:max-w-5 {
    max-width: 1.25rem !important;
  }

  .lt-xl\:max-w-6 {
    max-width: 1.5rem !important;
  }

  .lt-xl\:max-w-8 {
    max-width: 2rem !important;
  }

  .lt-xl\:max-w-10 {
    max-width: 2.5rem !important;
  }

  .lt-xl\:max-w-12 {
    max-width: 3rem !important;
  }

  .lt-xl\:max-w-14 {
    max-width: 3.5rem !important;
  }

  .lt-xl\:max-w-16 {
    max-width: 4rem !important;
  }

  .lt-xl\:max-w-18 {
    max-width: 4.5rem !important;
  }

  .lt-xl\:max-w-20 {
    max-width: 5rem !important;
  }

  .lt-xl\:max-w-22 {
    max-width: 5.5rem !important;
  }

  .lt-xl\:max-w-24 {
    max-width: 6rem !important;
  }

  .lt-xl\:max-w-26 {
    max-width: 6.5rem !important;
  }

  .lt-xl\:max-w-28 {
    max-width: 7rem !important;
  }

  .lt-xl\:max-w-30 {
    max-width: 7.5rem !important;
  }

  .lt-xl\:max-w-32 {
    max-width: 8rem !important;
  }

  .lt-xl\:max-w-36 {
    max-width: 9rem !important;
  }

  .lt-xl\:max-w-40 {
    max-width: 10rem !important;
  }

  .lt-xl\:max-w-48 {
    max-width: 12rem !important;
  }

  .lt-xl\:max-w-50 {
    max-width: 12.5rem !important;
  }

  .lt-xl\:max-w-56 {
    max-width: 14rem !important;
  }

  .lt-xl\:max-w-60 {
    max-width: 15rem !important;
  }

  .lt-xl\:max-w-64 {
    max-width: 16rem !important;
  }

  .lt-xl\:max-w-80 {
    max-width: 20rem !important;
  }

  .lt-xl\:max-w-90 {
    max-width: 24rem !important;
  }

  .lt-xl\:max-w-100 {
    max-width: 25rem !important;
  }

  .lt-xl\:max-w-120 {
    max-width: 30rem !important;
  }

  .lt-xl\:max-w-128 {
    max-width: 32rem !important;
  }

  .lt-xl\:max-w-140 {
    max-width: 35rem !important;
  }

  .lt-xl\:max-w-160 {
    max-width: 40rem !important;
  }

  .lt-xl\:max-w-180 {
    max-width: 45rem !important;
  }

  .lt-xl\:max-w-192 {
    max-width: 48rem !important;
  }

  .lt-xl\:max-w-200 {
    max-width: 50rem !important;
  }

  .lt-xl\:max-w-240 {
    max-width: 60rem !important;
  }

  .lt-xl\:max-w-256 {
    max-width: 64rem !important;
  }

  .lt-xl\:max-w-280 {
    max-width: 70rem !important;
  }

  .lt-xl\:max-w-320 {
    max-width: 80rem !important;
  }

  .lt-xl\:max-w-360 {
    max-width: 90rem !important;
  }

  .lt-xl\:max-w-400 {
    max-width: 100rem !important;
  }

  .lt-xl\:max-w-480 {
    max-width: 120rem !important;
  }

  .lt-xl\:max-w-none {
    max-width: none !important;
  }

  .lt-xl\:max-w-xs {
    max-width: 20rem !important;
  }

  .lt-xl\:max-w-sm {
    max-width: 24rem !important;
  }

  .lt-xl\:max-w-md {
    max-width: 28rem !important;
  }

  .lt-xl\:max-w-lg {
    max-width: 32rem !important;
  }

  .lt-xl\:max-w-xl {
    max-width: 36rem !important;
  }

  .lt-xl\:max-w-2xl {
    max-width: 42rem !important;
  }

  .lt-xl\:max-w-3xl {
    max-width: 48rem !important;
  }

  .lt-xl\:max-w-4xl {
    max-width: 56rem !important;
  }

  .lt-xl\:max-w-5xl {
    max-width: 64rem !important;
  }

  .lt-xl\:max-w-6xl {
    max-width: 72rem !important;
  }

  .lt-xl\:max-w-full {
    max-width: 100% !important;
  }

  .lt-xl\:max-w-screen {
    max-width: 100vw !important;
  }

  .lt-xl\:max-w-px {
    max-width: 1px !important;
  }

  .lt-xl\:max-w-2px {
    max-width: 2px !important;
  }

  .lt-xl\:max-w-1\/2 {
    max-width: 50% !important;
  }

  .lt-xl\:max-w-1\/3 {
    max-width: 33.33333% !important;
  }

  .lt-xl\:max-w-2\/3 {
    max-width: 66.66667% !important;
  }

  .lt-xl\:max-w-1\/4 {
    max-width: 25% !important;
  }

  .lt-xl\:max-w-2\/4 {
    max-width: 50% !important;
  }

  .lt-xl\:max-w-3\/4 {
    max-width: 75% !important;
  }

  .lt-xl\:max-w-1\/5 {
    max-width: 20% !important;
  }

  .lt-xl\:max-w-2\/5 {
    max-width: 40% !important;
  }

  .lt-xl\:max-w-3\/5 {
    max-width: 60% !important;
  }

  .lt-xl\:max-w-4\/5 {
    max-width: 80% !important;
  }

  .lt-xl\:max-w-1\/12 {
    max-width: 8.33333% !important;
  }

  .lt-xl\:max-w-2\/12 {
    max-width: 16.66667% !important;
  }

  .lt-xl\:max-w-3\/12 {
    max-width: 25% !important;
  }

  .lt-xl\:max-w-4\/12 {
    max-width: 33.33333% !important;
  }

  .lt-xl\:max-w-5\/12 {
    max-width: 41.66667% !important;
  }

  .lt-xl\:max-w-6\/12 {
    max-width: 50% !important;
  }

  .lt-xl\:max-w-7\/12 {
    max-width: 58.33333% !important;
  }

  .lt-xl\:max-w-8\/12 {
    max-width: 66.66667% !important;
  }

  .lt-xl\:max-w-9\/12 {
    max-width: 75% !important;
  }

  .lt-xl\:max-w-10\/12 {
    max-width: 83.33333% !important;
  }

  .lt-xl\:max-w-11\/12 {
    max-width: 91.66667% !important;
  }

  .lt-xl\:min-h-0 {
    min-height: 0 !important;
  }

  .lt-xl\:min-h-1 {
    min-height: 0.25rem !important;
  }

  .lt-xl\:min-h-2 {
    min-height: 0.5rem !important;
  }

  .lt-xl\:min-h-3 {
    min-height: 0.75rem !important;
  }

  .lt-xl\:min-h-4 {
    min-height: 1rem !important;
  }

  .lt-xl\:min-h-5 {
    min-height: 1.25rem !important;
  }

  .lt-xl\:min-h-6 {
    min-height: 1.5rem !important;
  }

  .lt-xl\:min-h-8 {
    min-height: 2rem !important;
  }

  .lt-xl\:min-h-10 {
    min-height: 2.5rem !important;
  }

  .lt-xl\:min-h-12 {
    min-height: 3rem !important;
  }

  .lt-xl\:min-h-14 {
    min-height: 3.5rem !important;
  }

  .lt-xl\:min-h-16 {
    min-height: 4rem !important;
  }

  .lt-xl\:min-h-18 {
    min-height: 4.5rem !important;
  }

  .lt-xl\:min-h-20 {
    min-height: 5rem !important;
  }

  .lt-xl\:min-h-22 {
    min-height: 5.5rem !important;
  }

  .lt-xl\:min-h-24 {
    min-height: 6rem !important;
  }

  .lt-xl\:min-h-26 {
    min-height: 6.5rem !important;
  }

  .lt-xl\:min-h-28 {
    min-height: 7rem !important;
  }

  .lt-xl\:min-h-30 {
    min-height: 7.5rem !important;
  }

  .lt-xl\:min-h-32 {
    min-height: 8rem !important;
  }

  .lt-xl\:min-h-36 {
    min-height: 9rem !important;
  }

  .lt-xl\:min-h-40 {
    min-height: 10rem !important;
  }

  .lt-xl\:min-h-48 {
    min-height: 12rem !important;
  }

  .lt-xl\:min-h-50 {
    min-height: 12.5rem !important;
  }

  .lt-xl\:min-h-56 {
    min-height: 14rem !important;
  }

  .lt-xl\:min-h-60 {
    min-height: 15rem !important;
  }

  .lt-xl\:min-h-64 {
    min-height: 16rem !important;
  }

  .lt-xl\:min-h-80 {
    min-height: 20rem !important;
  }

  .lt-xl\:min-h-90 {
    min-height: 24rem !important;
  }

  .lt-xl\:min-h-100 {
    min-height: 25rem !important;
  }

  .lt-xl\:min-h-120 {
    min-height: 30rem !important;
  }

  .lt-xl\:min-h-128 {
    min-height: 32rem !important;
  }

  .lt-xl\:min-h-140 {
    min-height: 35rem !important;
  }

  .lt-xl\:min-h-160 {
    min-height: 40rem !important;
  }

  .lt-xl\:min-h-180 {
    min-height: 45rem !important;
  }

  .lt-xl\:min-h-192 {
    min-height: 48rem !important;
  }

  .lt-xl\:min-h-200 {
    min-height: 50rem !important;
  }

  .lt-xl\:min-h-240 {
    min-height: 60rem !important;
  }

  .lt-xl\:min-h-256 {
    min-height: 64rem !important;
  }

  .lt-xl\:min-h-280 {
    min-height: 70rem !important;
  }

  .lt-xl\:min-h-320 {
    min-height: 80rem !important;
  }

  .lt-xl\:min-h-360 {
    min-height: 90rem !important;
  }

  .lt-xl\:min-h-400 {
    min-height: 100rem !important;
  }

  .lt-xl\:min-h-480 {
    min-height: 120rem !important;
  }

  .lt-xl\:min-h-full {
    min-height: 100% !important;
  }

  .lt-xl\:min-h-screen {
    min-height: 100vh !important;
  }

  .lt-xl\:min-h-px {
    min-height: 1px !important;
  }

  .lt-xl\:min-h-2px {
    min-height: 2px !important;
  }

  .lt-xl\:min-h-1\/2 {
    min-height: 50% !important;
  }

  .lt-xl\:min-h-1\/3 {
    min-height: 33.33333% !important;
  }

  .lt-xl\:min-h-2\/3 {
    min-height: 66.66667% !important;
  }

  .lt-xl\:min-h-1\/4 {
    min-height: 25% !important;
  }

  .lt-xl\:min-h-2\/4 {
    min-height: 50% !important;
  }

  .lt-xl\:min-h-3\/4 {
    min-height: 75% !important;
  }

  .lt-xl\:min-h-1\/5 {
    min-height: 20% !important;
  }

  .lt-xl\:min-h-2\/5 {
    min-height: 40% !important;
  }

  .lt-xl\:min-h-3\/5 {
    min-height: 60% !important;
  }

  .lt-xl\:min-h-4\/5 {
    min-height: 80% !important;
  }

  .lt-xl\:min-h-1\/12 {
    min-height: 8.33333% !important;
  }

  .lt-xl\:min-h-2\/12 {
    min-height: 16.66667% !important;
  }

  .lt-xl\:min-h-3\/12 {
    min-height: 25% !important;
  }

  .lt-xl\:min-h-4\/12 {
    min-height: 33.33333% !important;
  }

  .lt-xl\:min-h-5\/12 {
    min-height: 41.66667% !important;
  }

  .lt-xl\:min-h-6\/12 {
    min-height: 50% !important;
  }

  .lt-xl\:min-h-7\/12 {
    min-height: 58.33333% !important;
  }

  .lt-xl\:min-h-8\/12 {
    min-height: 66.66667% !important;
  }

  .lt-xl\:min-h-9\/12 {
    min-height: 75% !important;
  }

  .lt-xl\:min-h-10\/12 {
    min-height: 83.33333% !important;
  }

  .lt-xl\:min-h-11\/12 {
    min-height: 91.66667% !important;
  }

  .lt-xl\:min-w-0 {
    min-width: 0 !important;
  }

  .lt-xl\:min-w-1 {
    min-width: 0.25rem !important;
  }

  .lt-xl\:min-w-2 {
    min-width: 0.5rem !important;
  }

  .lt-xl\:min-w-3 {
    min-width: 0.75rem !important;
  }

  .lt-xl\:min-w-4 {
    min-width: 1rem !important;
  }

  .lt-xl\:min-w-5 {
    min-width: 1.25rem !important;
  }

  .lt-xl\:min-w-6 {
    min-width: 1.5rem !important;
  }

  .lt-xl\:min-w-8 {
    min-width: 2rem !important;
  }

  .lt-xl\:min-w-10 {
    min-width: 2.5rem !important;
  }

  .lt-xl\:min-w-12 {
    min-width: 3rem !important;
  }

  .lt-xl\:min-w-14 {
    min-width: 3.5rem !important;
  }

  .lt-xl\:min-w-16 {
    min-width: 4rem !important;
  }

  .lt-xl\:min-w-18 {
    min-width: 4.5rem !important;
  }

  .lt-xl\:min-w-20 {
    min-width: 5rem !important;
  }

  .lt-xl\:min-w-22 {
    min-width: 5.5rem !important;
  }

  .lt-xl\:min-w-24 {
    min-width: 6rem !important;
  }

  .lt-xl\:min-w-26 {
    min-width: 6.5rem !important;
  }

  .lt-xl\:min-w-28 {
    min-width: 7rem !important;
  }

  .lt-xl\:min-w-30 {
    min-width: 7.5rem !important;
  }

  .lt-xl\:min-w-32 {
    min-width: 8rem !important;
  }

  .lt-xl\:min-w-36 {
    min-width: 9rem !important;
  }

  .lt-xl\:min-w-40 {
    min-width: 10rem !important;
  }

  .lt-xl\:min-w-48 {
    min-width: 12rem !important;
  }

  .lt-xl\:min-w-50 {
    min-width: 12.5rem !important;
  }

  .lt-xl\:min-w-56 {
    min-width: 14rem !important;
  }

  .lt-xl\:min-w-60 {
    min-width: 15rem !important;
  }

  .lt-xl\:min-w-64 {
    min-width: 16rem !important;
  }

  .lt-xl\:min-w-80 {
    min-width: 20rem !important;
  }

  .lt-xl\:min-w-90 {
    min-width: 24rem !important;
  }

  .lt-xl\:min-w-100 {
    min-width: 25rem !important;
  }

  .lt-xl\:min-w-120 {
    min-width: 30rem !important;
  }

  .lt-xl\:min-w-128 {
    min-width: 32rem !important;
  }

  .lt-xl\:min-w-140 {
    min-width: 35rem !important;
  }

  .lt-xl\:min-w-160 {
    min-width: 40rem !important;
  }

  .lt-xl\:min-w-180 {
    min-width: 45rem !important;
  }

  .lt-xl\:min-w-192 {
    min-width: 48rem !important;
  }

  .lt-xl\:min-w-200 {
    min-width: 50rem !important;
  }

  .lt-xl\:min-w-240 {
    min-width: 60rem !important;
  }

  .lt-xl\:min-w-256 {
    min-width: 64rem !important;
  }

  .lt-xl\:min-w-280 {
    min-width: 70rem !important;
  }

  .lt-xl\:min-w-320 {
    min-width: 80rem !important;
  }

  .lt-xl\:min-w-360 {
    min-width: 90rem !important;
  }

  .lt-xl\:min-w-400 {
    min-width: 100rem !important;
  }

  .lt-xl\:min-w-480 {
    min-width: 120rem !important;
  }

  .lt-xl\:min-w-full {
    min-width: 100% !important;
  }

  .lt-xl\:min-w-screen {
    min-width: 100vw !important;
  }

  .lt-xl\:min-w-px {
    min-width: 1px !important;
  }

  .lt-xl\:min-w-2px {
    min-width: 2px !important;
  }

  .lt-xl\:min-w-1\/2 {
    min-width: 50% !important;
  }

  .lt-xl\:min-w-1\/3 {
    min-width: 33.33333% !important;
  }

  .lt-xl\:min-w-2\/3 {
    min-width: 66.66667% !important;
  }

  .lt-xl\:min-w-1\/4 {
    min-width: 25% !important;
  }

  .lt-xl\:min-w-2\/4 {
    min-width: 50% !important;
  }

  .lt-xl\:min-w-3\/4 {
    min-width: 75% !important;
  }

  .lt-xl\:min-w-1\/5 {
    min-width: 20% !important;
  }

  .lt-xl\:min-w-2\/5 {
    min-width: 40% !important;
  }

  .lt-xl\:min-w-3\/5 {
    min-width: 60% !important;
  }

  .lt-xl\:min-w-4\/5 {
    min-width: 80% !important;
  }

  .lt-xl\:min-w-1\/12 {
    min-width: 8.33333% !important;
  }

  .lt-xl\:min-w-2\/12 {
    min-width: 16.66667% !important;
  }

  .lt-xl\:min-w-3\/12 {
    min-width: 25% !important;
  }

  .lt-xl\:min-w-4\/12 {
    min-width: 33.33333% !important;
  }

  .lt-xl\:min-w-5\/12 {
    min-width: 41.66667% !important;
  }

  .lt-xl\:min-w-6\/12 {
    min-width: 50% !important;
  }

  .lt-xl\:min-w-7\/12 {
    min-width: 58.33333% !important;
  }

  .lt-xl\:min-w-8\/12 {
    min-width: 66.66667% !important;
  }

  .lt-xl\:min-w-9\/12 {
    min-width: 75% !important;
  }

  .lt-xl\:min-w-10\/12 {
    min-width: 83.33333% !important;
  }

  .lt-xl\:min-w-11\/12 {
    min-width: 91.66667% !important;
  }

  .lt-xl\:object-contain {
    -o-object-fit: contain !important;
       object-fit: contain !important;
  }

  .lt-xl\:object-cover {
    -o-object-fit: cover !important;
       object-fit: cover !important;
  }

  .lt-xl\:object-fill {
    -o-object-fit: fill !important;
       object-fit: fill !important;
  }

  .lt-xl\:object-none {
    -o-object-fit: none !important;
       object-fit: none !important;
  }

  .lt-xl\:object-scale-down {
    -o-object-fit: scale-down !important;
       object-fit: scale-down !important;
  }

  .lt-xl\:object-bottom {
    -o-object-position: bottom !important;
       object-position: bottom !important;
  }

  .lt-xl\:object-center {
    -o-object-position: center !important;
       object-position: center !important;
  }

  .lt-xl\:object-left {
    -o-object-position: left !important;
       object-position: left !important;
  }

  .lt-xl\:object-left-bottom {
    -o-object-position: left bottom !important;
       object-position: left bottom !important;
  }

  .lt-xl\:object-left-top {
    -o-object-position: left top !important;
       object-position: left top !important;
  }

  .lt-xl\:object-right {
    -o-object-position: right !important;
       object-position: right !important;
  }

  .lt-xl\:object-right-bottom {
    -o-object-position: right bottom !important;
       object-position: right bottom !important;
  }

  .lt-xl\:object-right-top {
    -o-object-position: right top !important;
       object-position: right top !important;
  }

  .lt-xl\:object-top {
    -o-object-position: top !important;
       object-position: top !important;
  }

  .lt-xl\:opacity-0 {
    opacity: 0 !important;
  }

  .lt-xl\:opacity-12 {
    opacity: 0.12 !important;
  }

  .lt-xl\:opacity-25 {
    opacity: 0.25 !important;
  }

  .lt-xl\:opacity-38 {
    opacity: 0.38 !important;
  }

  .lt-xl\:opacity-50 {
    opacity: 0.5 !important;
  }

  .lt-xl\:opacity-54 {
    opacity: 0.54 !important;
  }

  .lt-xl\:opacity-70 {
    opacity: 0.70 !important;
  }

  .lt-xl\:opacity-75 {
    opacity: 0.75 !important;
  }

  .lt-xl\:opacity-84 {
    opacity: 0.84 !important;
  }

  .lt-xl\:opacity-100 {
    opacity: 1 !important;
  }

  .lt-xl\:hover\:opacity-0:hover {
    opacity: 0 !important;
  }

  .lt-xl\:hover\:opacity-12:hover {
    opacity: 0.12 !important;
  }

  .lt-xl\:hover\:opacity-25:hover {
    opacity: 0.25 !important;
  }

  .lt-xl\:hover\:opacity-38:hover {
    opacity: 0.38 !important;
  }

  .lt-xl\:hover\:opacity-50:hover {
    opacity: 0.5 !important;
  }

  .lt-xl\:hover\:opacity-54:hover {
    opacity: 0.54 !important;
  }

  .lt-xl\:hover\:opacity-70:hover {
    opacity: 0.70 !important;
  }

  .lt-xl\:hover\:opacity-75:hover {
    opacity: 0.75 !important;
  }

  .lt-xl\:hover\:opacity-84:hover {
    opacity: 0.84 !important;
  }

  .lt-xl\:hover\:opacity-100:hover {
    opacity: 1 !important;
  }

  .lt-xl\:focus\:opacity-0:focus {
    opacity: 0 !important;
  }

  .lt-xl\:focus\:opacity-12:focus {
    opacity: 0.12 !important;
  }

  .lt-xl\:focus\:opacity-25:focus {
    opacity: 0.25 !important;
  }

  .lt-xl\:focus\:opacity-38:focus {
    opacity: 0.38 !important;
  }

  .lt-xl\:focus\:opacity-50:focus {
    opacity: 0.5 !important;
  }

  .lt-xl\:focus\:opacity-54:focus {
    opacity: 0.54 !important;
  }

  .lt-xl\:focus\:opacity-70:focus {
    opacity: 0.70 !important;
  }

  .lt-xl\:focus\:opacity-75:focus {
    opacity: 0.75 !important;
  }

  .lt-xl\:focus\:opacity-84:focus {
    opacity: 0.84 !important;
  }

  .lt-xl\:focus\:opacity-100:focus {
    opacity: 1 !important;
  }

  .lt-xl\:outline-none {
    outline: 0 !important;
  }

  .lt-xl\:focus\:outline-none:focus {
    outline: 0 !important;
  }

  .lt-xl\:overflow-auto {
    overflow: auto !important;
  }

  .lt-xl\:overflow-hidden {
    overflow: hidden !important;
  }

  .lt-xl\:overflow-visible {
    overflow: visible !important;
  }

  .lt-xl\:overflow-scroll {
    overflow: scroll !important;
  }

  .lt-xl\:overflow-x-auto {
    overflow-x: auto !important;
  }

  .lt-xl\:overflow-y-auto {
    overflow-y: auto !important;
  }

  .lt-xl\:overflow-x-hidden {
    overflow-x: hidden !important;
  }

  .lt-xl\:overflow-y-hidden {
    overflow-y: hidden !important;
  }

  .lt-xl\:overflow-x-visible {
    overflow-x: visible !important;
  }

  .lt-xl\:overflow-y-visible {
    overflow-y: visible !important;
  }

  .lt-xl\:overflow-x-scroll {
    overflow-x: scroll !important;
  }

  .lt-xl\:overflow-y-scroll {
    overflow-y: scroll !important;
  }

  .lt-xl\:scrolling-touch {
    -webkit-overflow-scrolling: touch !important;
  }

  .lt-xl\:scrolling-auto {
    -webkit-overflow-scrolling: auto !important;
  }

  .lt-xl\:p-0 {
    padding: 0 !important;
  }

  .lt-xl\:p-1 {
    padding: 0.25rem !important;
  }

  .lt-xl\:p-2 {
    padding: 0.5rem !important;
  }

  .lt-xl\:p-3 {
    padding: 0.75rem !important;
  }

  .lt-xl\:p-4 {
    padding: 1rem !important;
  }

  .lt-xl\:p-5 {
    padding: 1.25rem !important;
  }

  .lt-xl\:p-6 {
    padding: 1.5rem !important;
  }

  .lt-xl\:p-8 {
    padding: 2rem !important;
  }

  .lt-xl\:p-10 {
    padding: 2.5rem !important;
  }

  .lt-xl\:p-12 {
    padding: 3rem !important;
  }

  .lt-xl\:p-14 {
    padding: 3.5rem !important;
  }

  .lt-xl\:p-16 {
    padding: 4rem !important;
  }

  .lt-xl\:p-18 {
    padding: 4.5rem !important;
  }

  .lt-xl\:p-20 {
    padding: 5rem !important;
  }

  .lt-xl\:p-22 {
    padding: 5.5rem !important;
  }

  .lt-xl\:p-24 {
    padding: 6rem !important;
  }

  .lt-xl\:p-26 {
    padding: 6.5rem !important;
  }

  .lt-xl\:p-28 {
    padding: 7rem !important;
  }

  .lt-xl\:p-30 {
    padding: 7.5rem !important;
  }

  .lt-xl\:p-32 {
    padding: 8rem !important;
  }

  .lt-xl\:p-36 {
    padding: 9rem !important;
  }

  .lt-xl\:p-40 {
    padding: 10rem !important;
  }

  .lt-xl\:p-48 {
    padding: 12rem !important;
  }

  .lt-xl\:p-56 {
    padding: 14rem !important;
  }

  .lt-xl\:p-64 {
    padding: 16rem !important;
  }

  .lt-xl\:p-px {
    padding: 1px !important;
  }

  .lt-xl\:p-2px {
    padding: 2px !important;
  }

  .lt-xl\:py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .lt-xl\:px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .lt-xl\:py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .lt-xl\:px-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }

  .lt-xl\:py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .lt-xl\:px-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .lt-xl\:py-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .lt-xl\:px-3 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }

  .lt-xl\:py-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .lt-xl\:px-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .lt-xl\:py-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .lt-xl\:px-5 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }

  .lt-xl\:py-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .lt-xl\:px-6 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .lt-xl\:py-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .lt-xl\:px-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .lt-xl\:py-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .lt-xl\:px-10 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .lt-xl\:py-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .lt-xl\:px-12 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .lt-xl\:py-14 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .lt-xl\:px-14 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }

  .lt-xl\:py-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .lt-xl\:px-16 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .lt-xl\:py-18 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .lt-xl\:px-18 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }

  .lt-xl\:py-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .lt-xl\:px-20 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .lt-xl\:py-22 {
    padding-top: 5.5rem !important;
    padding-bottom: 5.5rem !important;
  }

  .lt-xl\:px-22 {
    padding-left: 5.5rem !important;
    padding-right: 5.5rem !important;
  }

  .lt-xl\:py-24 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .lt-xl\:px-24 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }

  .lt-xl\:py-26 {
    padding-top: 6.5rem !important;
    padding-bottom: 6.5rem !important;
  }

  .lt-xl\:px-26 {
    padding-left: 6.5rem !important;
    padding-right: 6.5rem !important;
  }

  .lt-xl\:py-28 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }

  .lt-xl\:px-28 {
    padding-left: 7rem !important;
    padding-right: 7rem !important;
  }

  .lt-xl\:py-30 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }

  .lt-xl\:px-30 {
    padding-left: 7.5rem !important;
    padding-right: 7.5rem !important;
  }

  .lt-xl\:py-32 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .lt-xl\:px-32 {
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }

  .lt-xl\:py-36 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }

  .lt-xl\:px-36 {
    padding-left: 9rem !important;
    padding-right: 9rem !important;
  }

  .lt-xl\:py-40 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .lt-xl\:px-40 {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }

  .lt-xl\:py-48 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }

  .lt-xl\:px-48 {
    padding-left: 12rem !important;
    padding-right: 12rem !important;
  }

  .lt-xl\:py-56 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }

  .lt-xl\:px-56 {
    padding-left: 14rem !important;
    padding-right: 14rem !important;
  }

  .lt-xl\:py-64 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }

  .lt-xl\:px-64 {
    padding-left: 16rem !important;
    padding-right: 16rem !important;
  }

  .lt-xl\:py-px {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }

  .lt-xl\:px-px {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }

  .lt-xl\:py-2px {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }

  .lt-xl\:px-2px {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }

  .lt-xl\:pt-0 {
    padding-top: 0 !important;
  }

  .lt-xl\:pr-0 {
    padding-right: 0 !important;
  }

  .lt-xl\:pb-0 {
    padding-bottom: 0 !important;
  }

  .lt-xl\:pl-0 {
    padding-left: 0 !important;
  }

  .lt-xl\:pt-1 {
    padding-top: 0.25rem !important;
  }

  .lt-xl\:pr-1 {
    padding-right: 0.25rem !important;
  }

  .lt-xl\:pb-1 {
    padding-bottom: 0.25rem !important;
  }

  .lt-xl\:pl-1 {
    padding-left: 0.25rem !important;
  }

  .lt-xl\:pt-2 {
    padding-top: 0.5rem !important;
  }

  .lt-xl\:pr-2 {
    padding-right: 0.5rem !important;
  }

  .lt-xl\:pb-2 {
    padding-bottom: 0.5rem !important;
  }

  .lt-xl\:pl-2 {
    padding-left: 0.5rem !important;
  }

  .lt-xl\:pt-3 {
    padding-top: 0.75rem !important;
  }

  .lt-xl\:pr-3 {
    padding-right: 0.75rem !important;
  }

  .lt-xl\:pb-3 {
    padding-bottom: 0.75rem !important;
  }

  .lt-xl\:pl-3 {
    padding-left: 0.75rem !important;
  }

  .lt-xl\:pt-4 {
    padding-top: 1rem !important;
  }

  .lt-xl\:pr-4 {
    padding-right: 1rem !important;
  }

  .lt-xl\:pb-4 {
    padding-bottom: 1rem !important;
  }

  .lt-xl\:pl-4 {
    padding-left: 1rem !important;
  }

  .lt-xl\:pt-5 {
    padding-top: 1.25rem !important;
  }

  .lt-xl\:pr-5 {
    padding-right: 1.25rem !important;
  }

  .lt-xl\:pb-5 {
    padding-bottom: 1.25rem !important;
  }

  .lt-xl\:pl-5 {
    padding-left: 1.25rem !important;
  }

  .lt-xl\:pt-6 {
    padding-top: 1.5rem !important;
  }

  .lt-xl\:pr-6 {
    padding-right: 1.5rem !important;
  }

  .lt-xl\:pb-6 {
    padding-bottom: 1.5rem !important;
  }

  .lt-xl\:pl-6 {
    padding-left: 1.5rem !important;
  }

  .lt-xl\:pt-8 {
    padding-top: 2rem !important;
  }

  .lt-xl\:pr-8 {
    padding-right: 2rem !important;
  }

  .lt-xl\:pb-8 {
    padding-bottom: 2rem !important;
  }

  .lt-xl\:pl-8 {
    padding-left: 2rem !important;
  }

  .lt-xl\:pt-10 {
    padding-top: 2.5rem !important;
  }

  .lt-xl\:pr-10 {
    padding-right: 2.5rem !important;
  }

  .lt-xl\:pb-10 {
    padding-bottom: 2.5rem !important;
  }

  .lt-xl\:pl-10 {
    padding-left: 2.5rem !important;
  }

  .lt-xl\:pt-12 {
    padding-top: 3rem !important;
  }

  .lt-xl\:pr-12 {
    padding-right: 3rem !important;
  }

  .lt-xl\:pb-12 {
    padding-bottom: 3rem !important;
  }

  .lt-xl\:pl-12 {
    padding-left: 3rem !important;
  }

  .lt-xl\:pt-14 {
    padding-top: 3.5rem !important;
  }

  .lt-xl\:pr-14 {
    padding-right: 3.5rem !important;
  }

  .lt-xl\:pb-14 {
    padding-bottom: 3.5rem !important;
  }

  .lt-xl\:pl-14 {
    padding-left: 3.5rem !important;
  }

  .lt-xl\:pt-16 {
    padding-top: 4rem !important;
  }

  .lt-xl\:pr-16 {
    padding-right: 4rem !important;
  }

  .lt-xl\:pb-16 {
    padding-bottom: 4rem !important;
  }

  .lt-xl\:pl-16 {
    padding-left: 4rem !important;
  }

  .lt-xl\:pt-18 {
    padding-top: 4.5rem !important;
  }

  .lt-xl\:pr-18 {
    padding-right: 4.5rem !important;
  }

  .lt-xl\:pb-18 {
    padding-bottom: 4.5rem !important;
  }

  .lt-xl\:pl-18 {
    padding-left: 4.5rem !important;
  }

  .lt-xl\:pt-20 {
    padding-top: 5rem !important;
  }

  .lt-xl\:pr-20 {
    padding-right: 5rem !important;
  }

  .lt-xl\:pb-20 {
    padding-bottom: 5rem !important;
  }

  .lt-xl\:pl-20 {
    padding-left: 5rem !important;
  }

  .lt-xl\:pt-22 {
    padding-top: 5.5rem !important;
  }

  .lt-xl\:pr-22 {
    padding-right: 5.5rem !important;
  }

  .lt-xl\:pb-22 {
    padding-bottom: 5.5rem !important;
  }

  .lt-xl\:pl-22 {
    padding-left: 5.5rem !important;
  }

  .lt-xl\:pt-24 {
    padding-top: 6rem !important;
  }

  .lt-xl\:pr-24 {
    padding-right: 6rem !important;
  }

  .lt-xl\:pb-24 {
    padding-bottom: 6rem !important;
  }

  .lt-xl\:pl-24 {
    padding-left: 6rem !important;
  }

  .lt-xl\:pt-26 {
    padding-top: 6.5rem !important;
  }

  .lt-xl\:pr-26 {
    padding-right: 6.5rem !important;
  }

  .lt-xl\:pb-26 {
    padding-bottom: 6.5rem !important;
  }

  .lt-xl\:pl-26 {
    padding-left: 6.5rem !important;
  }

  .lt-xl\:pt-28 {
    padding-top: 7rem !important;
  }

  .lt-xl\:pr-28 {
    padding-right: 7rem !important;
  }

  .lt-xl\:pb-28 {
    padding-bottom: 7rem !important;
  }

  .lt-xl\:pl-28 {
    padding-left: 7rem !important;
  }

  .lt-xl\:pt-30 {
    padding-top: 7.5rem !important;
  }

  .lt-xl\:pr-30 {
    padding-right: 7.5rem !important;
  }

  .lt-xl\:pb-30 {
    padding-bottom: 7.5rem !important;
  }

  .lt-xl\:pl-30 {
    padding-left: 7.5rem !important;
  }

  .lt-xl\:pt-32 {
    padding-top: 8rem !important;
  }

  .lt-xl\:pr-32 {
    padding-right: 8rem !important;
  }

  .lt-xl\:pb-32 {
    padding-bottom: 8rem !important;
  }

  .lt-xl\:pl-32 {
    padding-left: 8rem !important;
  }

  .lt-xl\:pt-36 {
    padding-top: 9rem !important;
  }

  .lt-xl\:pr-36 {
    padding-right: 9rem !important;
  }

  .lt-xl\:pb-36 {
    padding-bottom: 9rem !important;
  }

  .lt-xl\:pl-36 {
    padding-left: 9rem !important;
  }

  .lt-xl\:pt-40 {
    padding-top: 10rem !important;
  }

  .lt-xl\:pr-40 {
    padding-right: 10rem !important;
  }

  .lt-xl\:pb-40 {
    padding-bottom: 10rem !important;
  }

  .lt-xl\:pl-40 {
    padding-left: 10rem !important;
  }

  .lt-xl\:pt-48 {
    padding-top: 12rem !important;
  }

  .lt-xl\:pr-48 {
    padding-right: 12rem !important;
  }

  .lt-xl\:pb-48 {
    padding-bottom: 12rem !important;
  }

  .lt-xl\:pl-48 {
    padding-left: 12rem !important;
  }

  .lt-xl\:pt-56 {
    padding-top: 14rem !important;
  }

  .lt-xl\:pr-56 {
    padding-right: 14rem !important;
  }

  .lt-xl\:pb-56 {
    padding-bottom: 14rem !important;
  }

  .lt-xl\:pl-56 {
    padding-left: 14rem !important;
  }

  .lt-xl\:pt-64 {
    padding-top: 16rem !important;
  }

  .lt-xl\:pr-64 {
    padding-right: 16rem !important;
  }

  .lt-xl\:pb-64 {
    padding-bottom: 16rem !important;
  }

  .lt-xl\:pl-64 {
    padding-left: 16rem !important;
  }

  .lt-xl\:pt-px {
    padding-top: 1px !important;
  }

  .lt-xl\:pr-px {
    padding-right: 1px !important;
  }

  .lt-xl\:pb-px {
    padding-bottom: 1px !important;
  }

  .lt-xl\:pl-px {
    padding-left: 1px !important;
  }

  .lt-xl\:pt-2px {
    padding-top: 2px !important;
  }

  .lt-xl\:pr-2px {
    padding-right: 2px !important;
  }

  .lt-xl\:pb-2px {
    padding-bottom: 2px !important;
  }

  .lt-xl\:pl-2px {
    padding-left: 2px !important;
  }

  .lt-xl\:placeholder-opacity-0::-webkit-input-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .lt-xl\:placeholder-opacity-0::-moz-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .lt-xl\:placeholder-opacity-0::-ms-input-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .lt-xl\:placeholder-opacity-0::placeholder {
    --placeholder-opacity: 0 !important;
  }

  .lt-xl\:placeholder-opacity-12::-webkit-input-placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .lt-xl\:placeholder-opacity-12::-moz-placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .lt-xl\:placeholder-opacity-12::-ms-input-placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .lt-xl\:placeholder-opacity-12::placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .lt-xl\:placeholder-opacity-25::-webkit-input-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .lt-xl\:placeholder-opacity-25::-moz-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .lt-xl\:placeholder-opacity-25::-ms-input-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .lt-xl\:placeholder-opacity-25::placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .lt-xl\:placeholder-opacity-38::-webkit-input-placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .lt-xl\:placeholder-opacity-38::-moz-placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .lt-xl\:placeholder-opacity-38::-ms-input-placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .lt-xl\:placeholder-opacity-38::placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .lt-xl\:placeholder-opacity-50::-webkit-input-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .lt-xl\:placeholder-opacity-50::-moz-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .lt-xl\:placeholder-opacity-50::-ms-input-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .lt-xl\:placeholder-opacity-50::placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .lt-xl\:placeholder-opacity-54::-webkit-input-placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .lt-xl\:placeholder-opacity-54::-moz-placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .lt-xl\:placeholder-opacity-54::-ms-input-placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .lt-xl\:placeholder-opacity-54::placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .lt-xl\:placeholder-opacity-70::-webkit-input-placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .lt-xl\:placeholder-opacity-70::-moz-placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .lt-xl\:placeholder-opacity-70::-ms-input-placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .lt-xl\:placeholder-opacity-70::placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .lt-xl\:placeholder-opacity-75::-webkit-input-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .lt-xl\:placeholder-opacity-75::-moz-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .lt-xl\:placeholder-opacity-75::-ms-input-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .lt-xl\:placeholder-opacity-75::placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .lt-xl\:placeholder-opacity-84::-webkit-input-placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .lt-xl\:placeholder-opacity-84::-moz-placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .lt-xl\:placeholder-opacity-84::-ms-input-placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .lt-xl\:placeholder-opacity-84::placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .lt-xl\:placeholder-opacity-100::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .lt-xl\:placeholder-opacity-100::-moz-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .lt-xl\:placeholder-opacity-100::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .lt-xl\:placeholder-opacity-100::placeholder {
    --placeholder-opacity: 1 !important;
  }

  .lt-xl\:focus\:placeholder-opacity-0:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .lt-xl\:focus\:placeholder-opacity-0:focus::-moz-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .lt-xl\:focus\:placeholder-opacity-0:focus::-ms-input-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .lt-xl\:focus\:placeholder-opacity-0:focus::placeholder {
    --placeholder-opacity: 0 !important;
  }

  .lt-xl\:focus\:placeholder-opacity-12:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .lt-xl\:focus\:placeholder-opacity-12:focus::-moz-placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .lt-xl\:focus\:placeholder-opacity-12:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .lt-xl\:focus\:placeholder-opacity-12:focus::placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .lt-xl\:focus\:placeholder-opacity-25:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .lt-xl\:focus\:placeholder-opacity-25:focus::-moz-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .lt-xl\:focus\:placeholder-opacity-25:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .lt-xl\:focus\:placeholder-opacity-25:focus::placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .lt-xl\:focus\:placeholder-opacity-38:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .lt-xl\:focus\:placeholder-opacity-38:focus::-moz-placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .lt-xl\:focus\:placeholder-opacity-38:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .lt-xl\:focus\:placeholder-opacity-38:focus::placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .lt-xl\:focus\:placeholder-opacity-50:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .lt-xl\:focus\:placeholder-opacity-50:focus::-moz-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .lt-xl\:focus\:placeholder-opacity-50:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .lt-xl\:focus\:placeholder-opacity-50:focus::placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .lt-xl\:focus\:placeholder-opacity-54:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .lt-xl\:focus\:placeholder-opacity-54:focus::-moz-placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .lt-xl\:focus\:placeholder-opacity-54:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .lt-xl\:focus\:placeholder-opacity-54:focus::placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .lt-xl\:focus\:placeholder-opacity-70:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .lt-xl\:focus\:placeholder-opacity-70:focus::-moz-placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .lt-xl\:focus\:placeholder-opacity-70:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .lt-xl\:focus\:placeholder-opacity-70:focus::placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .lt-xl\:focus\:placeholder-opacity-75:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .lt-xl\:focus\:placeholder-opacity-75:focus::-moz-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .lt-xl\:focus\:placeholder-opacity-75:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .lt-xl\:focus\:placeholder-opacity-75:focus::placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .lt-xl\:focus\:placeholder-opacity-84:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .lt-xl\:focus\:placeholder-opacity-84:focus::-moz-placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .lt-xl\:focus\:placeholder-opacity-84:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .lt-xl\:focus\:placeholder-opacity-84:focus::placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .lt-xl\:focus\:placeholder-opacity-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .lt-xl\:focus\:placeholder-opacity-100:focus::-moz-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .lt-xl\:focus\:placeholder-opacity-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .lt-xl\:focus\:placeholder-opacity-100:focus::placeholder {
    --placeholder-opacity: 1 !important;
  }

  .lt-xl\:pointer-events-none {
    pointer-events: none !important;
  }

  .lt-xl\:pointer-events-auto {
    pointer-events: auto !important;
  }

  .lt-xl\:static {
    position: static !important;
  }

  .lt-xl\:fixed {
    position: fixed !important;
  }

  .lt-xl\:absolute {
    position: absolute !important;
  }

  .lt-xl\:relative {
    position: relative !important;
  }

  .lt-xl\:sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }

  .lt-xl\:inset-0 {
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
  }

  .lt-xl\:inset-auto {
    top: auto !important;
    right: auto !important;
    bottom: auto !important;
    left: auto !important;
  }

  .lt-xl\:inset-y-0 {
    top: 0 !important;
    bottom: 0 !important;
  }

  .lt-xl\:inset-x-0 {
    right: 0 !important;
    left: 0 !important;
  }

  .lt-xl\:inset-y-auto {
    top: auto !important;
    bottom: auto !important;
  }

  .lt-xl\:inset-x-auto {
    right: auto !important;
    left: auto !important;
  }

  .lt-xl\:top-0 {
    top: 0 !important;
  }

  .lt-xl\:right-0 {
    right: 0 !important;
  }

  .lt-xl\:bottom-0 {
    bottom: 0 !important;
  }

  .lt-xl\:left-0 {
    left: 0 !important;
  }

  .lt-xl\:top-auto {
    top: auto !important;
  }

  .lt-xl\:right-auto {
    right: auto !important;
  }

  .lt-xl\:bottom-auto {
    bottom: auto !important;
  }

  .lt-xl\:left-auto {
    left: auto !important;
  }

  .lt-xl\:shadow-xs {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05) !important;
  }

  .lt-xl\:shadow-sm {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  }

  .lt-xl\:shadow {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .lt-xl\:shadow-md {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  }

  .lt-xl\:shadow-lg {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  }

  .lt-xl\:shadow-xl {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
  }

  .lt-xl\:shadow-2xl {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
  }

  .lt-xl\:shadow-inner {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .lt-xl\:ring {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }

  .lt-xl\:shadow-none {
    box-shadow: none !important;
  }

  .lt-xl\:shadow-solid {
    box-shadow: 0 0 0 2px currentColor !important;
  }

  .lt-xl\:hover\:shadow-xs:hover {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05) !important;
  }

  .lt-xl\:hover\:shadow-sm:hover {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  }

  .lt-xl\:hover\:shadow:hover {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .lt-xl\:hover\:shadow-md:hover {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  }

  .lt-xl\:hover\:shadow-lg:hover {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  }

  .lt-xl\:hover\:shadow-xl:hover {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
  }

  .lt-xl\:hover\:shadow-2xl:hover {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
  }

  .lt-xl\:hover\:shadow-inner:hover {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .lt-xl\:hover\:ring:hover {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }

  .lt-xl\:hover\:shadow-none:hover {
    box-shadow: none !important;
  }

  .lt-xl\:hover\:shadow-solid:hover {
    box-shadow: 0 0 0 2px currentColor !important;
  }

  .lt-xl\:focus\:shadow-xs:focus {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05) !important;
  }

  .lt-xl\:focus\:shadow-sm:focus {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  }

  .lt-xl\:focus\:shadow:focus {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .lt-xl\:focus\:shadow-md:focus {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  }

  .lt-xl\:focus\:shadow-lg:focus {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  }

  .lt-xl\:focus\:shadow-xl:focus {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
  }

  .lt-xl\:focus\:shadow-2xl:focus {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
  }

  .lt-xl\:focus\:shadow-inner:focus {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .lt-xl\:focus\:ring:focus {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }

  .lt-xl\:focus\:shadow-none:focus {
    box-shadow: none !important;
  }

  .lt-xl\:focus\:shadow-solid:focus {
    box-shadow: 0 0 0 2px currentColor !important;
  }

  .lt-xl\:fill-current {
    fill: currentColor !important;
  }

  .lt-xl\:stroke-current {
    stroke: currentColor !important;
  }

  .lt-xl\:stroke-0 {
    stroke-width: 0 !important;
  }

  .lt-xl\:stroke-1 {
    stroke-width: 1 !important;
  }

  .lt-xl\:stroke-2 {
    stroke-width: 2 !important;
  }

  .lt-xl\:table-auto {
    table-layout: auto !important;
  }

  .lt-xl\:table-fixed {
    table-layout: fixed !important;
  }

  .lt-xl\:text-left {
    text-align: left !important;
  }

  .lt-xl\:text-center {
    text-align: center !important;
  }

  .lt-xl\:text-right {
    text-align: right !important;
  }

  .lt-xl\:text-justify {
    text-align: justify !important;
  }

  .lt-xl\:text-opacity-0 {
    --text-opacity: 0 !important;
  }

  .lt-xl\:text-opacity-12 {
    --text-opacity: 0.12 !important;
  }

  .lt-xl\:text-opacity-25 {
    --text-opacity: 0.25 !important;
  }

  .lt-xl\:text-opacity-38 {
    --text-opacity: 0.38 !important;
  }

  .lt-xl\:text-opacity-50 {
    --text-opacity: 0.5 !important;
  }

  .lt-xl\:text-opacity-54 {
    --text-opacity: 0.54 !important;
  }

  .lt-xl\:text-opacity-70 {
    --text-opacity: 0.70 !important;
  }

  .lt-xl\:text-opacity-75 {
    --text-opacity: 0.75 !important;
  }

  .lt-xl\:text-opacity-84 {
    --text-opacity: 0.84 !important;
  }

  .lt-xl\:text-opacity-100 {
    --text-opacity: 1 !important;
  }

  .lt-xl\:hover\:text-opacity-0:hover {
    --text-opacity: 0 !important;
  }

  .lt-xl\:hover\:text-opacity-12:hover {
    --text-opacity: 0.12 !important;
  }

  .lt-xl\:hover\:text-opacity-25:hover {
    --text-opacity: 0.25 !important;
  }

  .lt-xl\:hover\:text-opacity-38:hover {
    --text-opacity: 0.38 !important;
  }

  .lt-xl\:hover\:text-opacity-50:hover {
    --text-opacity: 0.5 !important;
  }

  .lt-xl\:hover\:text-opacity-54:hover {
    --text-opacity: 0.54 !important;
  }

  .lt-xl\:hover\:text-opacity-70:hover {
    --text-opacity: 0.70 !important;
  }

  .lt-xl\:hover\:text-opacity-75:hover {
    --text-opacity: 0.75 !important;
  }

  .lt-xl\:hover\:text-opacity-84:hover {
    --text-opacity: 0.84 !important;
  }

  .lt-xl\:hover\:text-opacity-100:hover {
    --text-opacity: 1 !important;
  }

  .lt-xl\:focus\:text-opacity-0:focus {
    --text-opacity: 0 !important;
  }

  .lt-xl\:focus\:text-opacity-12:focus {
    --text-opacity: 0.12 !important;
  }

  .lt-xl\:focus\:text-opacity-25:focus {
    --text-opacity: 0.25 !important;
  }

  .lt-xl\:focus\:text-opacity-38:focus {
    --text-opacity: 0.38 !important;
  }

  .lt-xl\:focus\:text-opacity-50:focus {
    --text-opacity: 0.5 !important;
  }

  .lt-xl\:focus\:text-opacity-54:focus {
    --text-opacity: 0.54 !important;
  }

  .lt-xl\:focus\:text-opacity-70:focus {
    --text-opacity: 0.70 !important;
  }

  .lt-xl\:focus\:text-opacity-75:focus {
    --text-opacity: 0.75 !important;
  }

  .lt-xl\:focus\:text-opacity-84:focus {
    --text-opacity: 0.84 !important;
  }

  .lt-xl\:focus\:text-opacity-100:focus {
    --text-opacity: 1 !important;
  }

  .lt-xl\:italic {
    font-style: italic !important;
  }

  .lt-xl\:not-italic {
    font-style: normal !important;
  }

  .lt-xl\:uppercase {
    text-transform: uppercase !important;
  }

  .lt-xl\:lowercase {
    text-transform: lowercase !important;
  }

  .lt-xl\:capitalize {
    text-transform: capitalize !important;
  }

  .lt-xl\:normal-case {
    text-transform: none !important;
  }

  .lt-xl\:underline {
    text-decoration: underline !important;
  }

  .lt-xl\:line-through {
    text-decoration: line-through !important;
  }

  .lt-xl\:no-underline {
    text-decoration: none !important;
  }

  .lt-xl\:hover\:underline:hover {
    text-decoration: underline !important;
  }

  .lt-xl\:hover\:line-through:hover {
    text-decoration: line-through !important;
  }

  .lt-xl\:hover\:no-underline:hover {
    text-decoration: none !important;
  }

  .lt-xl\:focus\:underline:focus {
    text-decoration: underline !important;
  }

  .lt-xl\:focus\:line-through:focus {
    text-decoration: line-through !important;
  }

  .lt-xl\:focus\:no-underline:focus {
    text-decoration: none !important;
  }

  .lt-xl\:tracking-tighter {
    letter-spacing: -0.05em !important;
  }

  .lt-xl\:tracking-tight {
    letter-spacing: -0.025em !important;
  }

  .lt-xl\:tracking-normal {
    letter-spacing: 0 !important;
  }

  .lt-xl\:tracking-wide {
    letter-spacing: 0.025em !important;
  }

  .lt-xl\:tracking-wider {
    letter-spacing: 0.05em !important;
  }

  .lt-xl\:tracking-widest {
    letter-spacing: 0.1em !important;
  }

  .lt-xl\:select-none {
    -webkit-user-select: none !important;
       -moz-user-select: none !important;
        -ms-user-select: none !important;
            user-select: none !important;
  }

  .lt-xl\:select-text {
    -webkit-user-select: text !important;
       -moz-user-select: text !important;
        -ms-user-select: text !important;
            user-select: text !important;
  }

  .lt-xl\:select-all {
    -webkit-user-select: all !important;
       -moz-user-select: all !important;
        -ms-user-select: all !important;
            user-select: all !important;
  }

  .lt-xl\:select-auto {
    -webkit-user-select: auto !important;
       -moz-user-select: auto !important;
        -ms-user-select: auto !important;
            user-select: auto !important;
  }

  .lt-xl\:align-baseline {
    vertical-align: baseline !important;
  }

  .lt-xl\:align-top {
    vertical-align: top !important;
  }

  .lt-xl\:align-middle {
    vertical-align: middle !important;
  }

  .lt-xl\:align-bottom {
    vertical-align: bottom !important;
  }

  .lt-xl\:align-text-top {
    vertical-align: text-top !important;
  }

  .lt-xl\:align-text-bottom {
    vertical-align: text-bottom !important;
  }

  .lt-xl\:visible {
    visibility: visible !important;
  }

  .lt-xl\:invisible {
    visibility: hidden !important;
  }

  .lt-xl\:whitespace-normal {
    white-space: normal !important;
  }

  .lt-xl\:whitespace-nowrap {
    white-space: nowrap !important;
  }

  .lt-xl\:whitespace-pre {
    white-space: pre !important;
  }

  .lt-xl\:whitespace-pre-line {
    white-space: pre-line !important;
  }

  .lt-xl\:whitespace-pre-wrap {
    white-space: pre-wrap !important;
  }

  .lt-xl\:break-normal {
    overflow-wrap: normal !important;
    word-break: normal !important;
  }

  .lt-xl\:break-words {
    overflow-wrap: break-word !important;
  }

  .lt-xl\:break-all {
    word-break: break-all !important;
  }

  .lt-xl\:truncate {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
  }

  .lt-xl\:w-0 {
    width: 0 !important;
  }

  .lt-xl\:w-1 {
    width: 0.25rem !important;
  }

  .lt-xl\:w-2 {
    width: 0.5rem !important;
  }

  .lt-xl\:w-3 {
    width: 0.75rem !important;
  }

  .lt-xl\:w-4 {
    width: 1rem !important;
  }

  .lt-xl\:w-5 {
    width: 1.25rem !important;
  }

  .lt-xl\:w-6 {
    width: 1.5rem !important;
  }

  .lt-xl\:w-8 {
    width: 2rem !important;
  }

  .lt-xl\:w-10 {
    width: 2.5rem !important;
  }

  .lt-xl\:w-12 {
    width: 3rem !important;
  }

  .lt-xl\:w-14 {
    width: 3.5rem !important;
  }

  .lt-xl\:w-16 {
    width: 4rem !important;
  }

  .lt-xl\:w-18 {
    width: 4.5rem !important;
  }

  .lt-xl\:w-20 {
    width: 5rem !important;
  }

  .lt-xl\:w-22 {
    width: 5.5rem !important;
  }

  .lt-xl\:w-24 {
    width: 6rem !important;
  }

  .lt-xl\:w-26 {
    width: 6.5rem !important;
  }

  .lt-xl\:w-28 {
    width: 7rem !important;
  }

  .lt-xl\:w-30 {
    width: 7.5rem !important;
  }

  .lt-xl\:w-32 {
    width: 8rem !important;
  }

  .lt-xl\:w-36 {
    width: 9rem !important;
  }

  .lt-xl\:w-40 {
    width: 10rem !important;
  }

  .lt-xl\:w-48 {
    width: 12rem !important;
  }

  .lt-xl\:w-50 {
    width: 12.5rem !important;
  }

  .lt-xl\:w-56 {
    width: 14rem !important;
  }

  .lt-xl\:w-60 {
    width: 15rem !important;
  }

  .lt-xl\:w-64 {
    width: 16rem !important;
  }

  .lt-xl\:w-80 {
    width: 20rem !important;
  }

  .lt-xl\:w-90 {
    width: 24rem !important;
  }

  .lt-xl\:w-100 {
    width: 25rem !important;
  }

  .lt-xl\:w-120 {
    width: 30rem !important;
  }

  .lt-xl\:w-128 {
    width: 32rem !important;
  }

  .lt-xl\:w-140 {
    width: 35rem !important;
  }

  .lt-xl\:w-160 {
    width: 40rem !important;
  }

  .lt-xl\:w-180 {
    width: 45rem !important;
  }

  .lt-xl\:w-192 {
    width: 48rem !important;
  }

  .lt-xl\:w-200 {
    width: 50rem !important;
  }

  .lt-xl\:w-240 {
    width: 60rem !important;
  }

  .lt-xl\:w-256 {
    width: 64rem !important;
  }

  .lt-xl\:w-280 {
    width: 70rem !important;
  }

  .lt-xl\:w-320 {
    width: 80rem !important;
  }

  .lt-xl\:w-360 {
    width: 90rem !important;
  }

  .lt-xl\:w-400 {
    width: 100rem !important;
  }

  .lt-xl\:w-480 {
    width: 120rem !important;
  }

  .lt-xl\:w-auto {
    width: auto !important;
  }

  .lt-xl\:w-px {
    width: 1px !important;
  }

  .lt-xl\:w-2px {
    width: 2px !important;
  }

  .lt-xl\:w-1\/2 {
    width: 50% !important;
  }

  .lt-xl\:w-1\/3 {
    width: 33.33333% !important;
  }

  .lt-xl\:w-2\/3 {
    width: 66.66667% !important;
  }

  .lt-xl\:w-1\/4 {
    width: 25% !important;
  }

  .lt-xl\:w-2\/4 {
    width: 50% !important;
  }

  .lt-xl\:w-3\/4 {
    width: 75% !important;
  }

  .lt-xl\:w-1\/5 {
    width: 20% !important;
  }

  .lt-xl\:w-2\/5 {
    width: 40% !important;
  }

  .lt-xl\:w-3\/5 {
    width: 60% !important;
  }

  .lt-xl\:w-4\/5 {
    width: 80% !important;
  }

  .lt-xl\:w-1\/6 {
    width: 16.666667% !important;
  }

  .lt-xl\:w-2\/6 {
    width: 33.333333% !important;
  }

  .lt-xl\:w-3\/6 {
    width: 50% !important;
  }

  .lt-xl\:w-4\/6 {
    width: 66.666667% !important;
  }

  .lt-xl\:w-5\/6 {
    width: 83.333333% !important;
  }

  .lt-xl\:w-1\/12 {
    width: 8.33333% !important;
  }

  .lt-xl\:w-2\/12 {
    width: 16.66667% !important;
  }

  .lt-xl\:w-3\/12 {
    width: 25% !important;
  }

  .lt-xl\:w-4\/12 {
    width: 33.33333% !important;
  }

  .lt-xl\:w-5\/12 {
    width: 41.66667% !important;
  }

  .lt-xl\:w-6\/12 {
    width: 50% !important;
  }

  .lt-xl\:w-7\/12 {
    width: 58.33333% !important;
  }

  .lt-xl\:w-8\/12 {
    width: 66.66667% !important;
  }

  .lt-xl\:w-9\/12 {
    width: 75% !important;
  }

  .lt-xl\:w-10\/12 {
    width: 83.33333% !important;
  }

  .lt-xl\:w-11\/12 {
    width: 91.66667% !important;
  }

  .lt-xl\:w-full {
    width: 100% !important;
  }

  .lt-xl\:w-screen {
    width: 100vw !important;
  }

  .lt-xl\:z-0 {
    z-index: 0 !important;
  }

  .lt-xl\:z-10 {
    z-index: 10 !important;
  }

  .lt-xl\:z-20 {
    z-index: 20 !important;
  }

  .lt-xl\:z-30 {
    z-index: 30 !important;
  }

  .lt-xl\:z-40 {
    z-index: 40 !important;
  }

  .lt-xl\:z-50 {
    z-index: 50 !important;
  }

  .lt-xl\:z-60 {
    z-index: 60 !important;
  }

  .lt-xl\:z-70 {
    z-index: 70 !important;
  }

  .lt-xl\:z-80 {
    z-index: 80 !important;
  }

  .lt-xl\:z-90 {
    z-index: 90 !important;
  }

  .lt-xl\:z-99 {
    z-index: 99 !important;
  }

  .lt-xl\:z-999 {
    z-index: 999 !important;
  }

  .lt-xl\:z-9999 {
    z-index: 9999 !important;
  }

  .lt-xl\:z-99999 {
    z-index: 99999 !important;
  }

  .lt-xl\:z-auto {
    z-index: auto !important;
  }

  .lt-xl\:-z-1 {
    z-index: -1 !important;
  }

  .lt-xl\:gap-0 {
    grid-gap: 0 !important;
    gap: 0 !important;
  }

  .lt-xl\:gap-1 {
    grid-gap: 0.25rem !important;
    gap: 0.25rem !important;
  }

  .lt-xl\:gap-2 {
    grid-gap: 0.5rem !important;
    gap: 0.5rem !important;
  }

  .lt-xl\:gap-3 {
    grid-gap: 0.75rem !important;
    gap: 0.75rem !important;
  }

  .lt-xl\:gap-4 {
    grid-gap: 1rem !important;
    gap: 1rem !important;
  }

  .lt-xl\:gap-5 {
    grid-gap: 1.25rem !important;
    gap: 1.25rem !important;
  }

  .lt-xl\:gap-6 {
    grid-gap: 1.5rem !important;
    gap: 1.5rem !important;
  }

  .lt-xl\:gap-8 {
    grid-gap: 2rem !important;
    gap: 2rem !important;
  }

  .lt-xl\:gap-10 {
    grid-gap: 2.5rem !important;
    gap: 2.5rem !important;
  }

  .lt-xl\:gap-12 {
    grid-gap: 3rem !important;
    gap: 3rem !important;
  }

  .lt-xl\:gap-14 {
    grid-gap: 3.5rem !important;
    gap: 3.5rem !important;
  }

  .lt-xl\:gap-16 {
    grid-gap: 4rem !important;
    gap: 4rem !important;
  }

  .lt-xl\:gap-18 {
    grid-gap: 4.5rem !important;
    gap: 4.5rem !important;
  }

  .lt-xl\:gap-20 {
    grid-gap: 5rem !important;
    gap: 5rem !important;
  }

  .lt-xl\:gap-22 {
    grid-gap: 5.5rem !important;
    gap: 5.5rem !important;
  }

  .lt-xl\:gap-24 {
    grid-gap: 6rem !important;
    gap: 6rem !important;
  }

  .lt-xl\:gap-26 {
    grid-gap: 6.5rem !important;
    gap: 6.5rem !important;
  }

  .lt-xl\:gap-28 {
    grid-gap: 7rem !important;
    gap: 7rem !important;
  }

  .lt-xl\:gap-30 {
    grid-gap: 7.5rem !important;
    gap: 7.5rem !important;
  }

  .lt-xl\:gap-32 {
    grid-gap: 8rem !important;
    gap: 8rem !important;
  }

  .lt-xl\:gap-36 {
    grid-gap: 9rem !important;
    gap: 9rem !important;
  }

  .lt-xl\:gap-40 {
    grid-gap: 10rem !important;
    gap: 10rem !important;
  }

  .lt-xl\:gap-48 {
    grid-gap: 12rem !important;
    gap: 12rem !important;
  }

  .lt-xl\:gap-56 {
    grid-gap: 14rem !important;
    gap: 14rem !important;
  }

  .lt-xl\:gap-64 {
    grid-gap: 16rem !important;
    gap: 16rem !important;
  }

  .lt-xl\:gap-px {
    grid-gap: 1px !important;
    gap: 1px !important;
  }

  .lt-xl\:gap-2px {
    grid-gap: 2px !important;
    gap: 2px !important;
  }

  .lt-xl\:gap-x-0 {
    grid-column-gap: 0 !important;
    -moz-column-gap: 0 !important;
         column-gap: 0 !important;
  }

  .lt-xl\:gap-x-1 {
    grid-column-gap: 0.25rem !important;
    -moz-column-gap: 0.25rem !important;
         column-gap: 0.25rem !important;
  }

  .lt-xl\:gap-x-2 {
    grid-column-gap: 0.5rem !important;
    -moz-column-gap: 0.5rem !important;
         column-gap: 0.5rem !important;
  }

  .lt-xl\:gap-x-3 {
    grid-column-gap: 0.75rem !important;
    -moz-column-gap: 0.75rem !important;
         column-gap: 0.75rem !important;
  }

  .lt-xl\:gap-x-4 {
    grid-column-gap: 1rem !important;
    -moz-column-gap: 1rem !important;
         column-gap: 1rem !important;
  }

  .lt-xl\:gap-x-5 {
    grid-column-gap: 1.25rem !important;
    -moz-column-gap: 1.25rem !important;
         column-gap: 1.25rem !important;
  }

  .lt-xl\:gap-x-6 {
    grid-column-gap: 1.5rem !important;
    -moz-column-gap: 1.5rem !important;
         column-gap: 1.5rem !important;
  }

  .lt-xl\:gap-x-8 {
    grid-column-gap: 2rem !important;
    -moz-column-gap: 2rem !important;
         column-gap: 2rem !important;
  }

  .lt-xl\:gap-x-10 {
    grid-column-gap: 2.5rem !important;
    -moz-column-gap: 2.5rem !important;
         column-gap: 2.5rem !important;
  }

  .lt-xl\:gap-x-12 {
    grid-column-gap: 3rem !important;
    -moz-column-gap: 3rem !important;
         column-gap: 3rem !important;
  }

  .lt-xl\:gap-x-14 {
    grid-column-gap: 3.5rem !important;
    -moz-column-gap: 3.5rem !important;
         column-gap: 3.5rem !important;
  }

  .lt-xl\:gap-x-16 {
    grid-column-gap: 4rem !important;
    -moz-column-gap: 4rem !important;
         column-gap: 4rem !important;
  }

  .lt-xl\:gap-x-18 {
    grid-column-gap: 4.5rem !important;
    -moz-column-gap: 4.5rem !important;
         column-gap: 4.5rem !important;
  }

  .lt-xl\:gap-x-20 {
    grid-column-gap: 5rem !important;
    -moz-column-gap: 5rem !important;
         column-gap: 5rem !important;
  }

  .lt-xl\:gap-x-22 {
    grid-column-gap: 5.5rem !important;
    -moz-column-gap: 5.5rem !important;
         column-gap: 5.5rem !important;
  }

  .lt-xl\:gap-x-24 {
    grid-column-gap: 6rem !important;
    -moz-column-gap: 6rem !important;
         column-gap: 6rem !important;
  }

  .lt-xl\:gap-x-26 {
    grid-column-gap: 6.5rem !important;
    -moz-column-gap: 6.5rem !important;
         column-gap: 6.5rem !important;
  }

  .lt-xl\:gap-x-28 {
    grid-column-gap: 7rem !important;
    -moz-column-gap: 7rem !important;
         column-gap: 7rem !important;
  }

  .lt-xl\:gap-x-30 {
    grid-column-gap: 7.5rem !important;
    -moz-column-gap: 7.5rem !important;
         column-gap: 7.5rem !important;
  }

  .lt-xl\:gap-x-32 {
    grid-column-gap: 8rem !important;
    -moz-column-gap: 8rem !important;
         column-gap: 8rem !important;
  }

  .lt-xl\:gap-x-36 {
    grid-column-gap: 9rem !important;
    -moz-column-gap: 9rem !important;
         column-gap: 9rem !important;
  }

  .lt-xl\:gap-x-40 {
    grid-column-gap: 10rem !important;
    -moz-column-gap: 10rem !important;
         column-gap: 10rem !important;
  }

  .lt-xl\:gap-x-48 {
    grid-column-gap: 12rem !important;
    -moz-column-gap: 12rem !important;
         column-gap: 12rem !important;
  }

  .lt-xl\:gap-x-56 {
    grid-column-gap: 14rem !important;
    -moz-column-gap: 14rem !important;
         column-gap: 14rem !important;
  }

  .lt-xl\:gap-x-64 {
    grid-column-gap: 16rem !important;
    -moz-column-gap: 16rem !important;
         column-gap: 16rem !important;
  }

  .lt-xl\:gap-x-px {
    grid-column-gap: 1px !important;
    -moz-column-gap: 1px !important;
         column-gap: 1px !important;
  }

  .lt-xl\:gap-x-2px {
    grid-column-gap: 2px !important;
    -moz-column-gap: 2px !important;
         column-gap: 2px !important;
  }

  .lt-xl\:gap-y-0 {
    grid-row-gap: 0 !important;
    row-gap: 0 !important;
  }

  .lt-xl\:gap-y-1 {
    grid-row-gap: 0.25rem !important;
    row-gap: 0.25rem !important;
  }

  .lt-xl\:gap-y-2 {
    grid-row-gap: 0.5rem !important;
    row-gap: 0.5rem !important;
  }

  .lt-xl\:gap-y-3 {
    grid-row-gap: 0.75rem !important;
    row-gap: 0.75rem !important;
  }

  .lt-xl\:gap-y-4 {
    grid-row-gap: 1rem !important;
    row-gap: 1rem !important;
  }

  .lt-xl\:gap-y-5 {
    grid-row-gap: 1.25rem !important;
    row-gap: 1.25rem !important;
  }

  .lt-xl\:gap-y-6 {
    grid-row-gap: 1.5rem !important;
    row-gap: 1.5rem !important;
  }

  .lt-xl\:gap-y-8 {
    grid-row-gap: 2rem !important;
    row-gap: 2rem !important;
  }

  .lt-xl\:gap-y-10 {
    grid-row-gap: 2.5rem !important;
    row-gap: 2.5rem !important;
  }

  .lt-xl\:gap-y-12 {
    grid-row-gap: 3rem !important;
    row-gap: 3rem !important;
  }

  .lt-xl\:gap-y-14 {
    grid-row-gap: 3.5rem !important;
    row-gap: 3.5rem !important;
  }

  .lt-xl\:gap-y-16 {
    grid-row-gap: 4rem !important;
    row-gap: 4rem !important;
  }

  .lt-xl\:gap-y-18 {
    grid-row-gap: 4.5rem !important;
    row-gap: 4.5rem !important;
  }

  .lt-xl\:gap-y-20 {
    grid-row-gap: 5rem !important;
    row-gap: 5rem !important;
  }

  .lt-xl\:gap-y-22 {
    grid-row-gap: 5.5rem !important;
    row-gap: 5.5rem !important;
  }

  .lt-xl\:gap-y-24 {
    grid-row-gap: 6rem !important;
    row-gap: 6rem !important;
  }

  .lt-xl\:gap-y-26 {
    grid-row-gap: 6.5rem !important;
    row-gap: 6.5rem !important;
  }

  .lt-xl\:gap-y-28 {
    grid-row-gap: 7rem !important;
    row-gap: 7rem !important;
  }

  .lt-xl\:gap-y-30 {
    grid-row-gap: 7.5rem !important;
    row-gap: 7.5rem !important;
  }

  .lt-xl\:gap-y-32 {
    grid-row-gap: 8rem !important;
    row-gap: 8rem !important;
  }

  .lt-xl\:gap-y-36 {
    grid-row-gap: 9rem !important;
    row-gap: 9rem !important;
  }

  .lt-xl\:gap-y-40 {
    grid-row-gap: 10rem !important;
    row-gap: 10rem !important;
  }

  .lt-xl\:gap-y-48 {
    grid-row-gap: 12rem !important;
    row-gap: 12rem !important;
  }

  .lt-xl\:gap-y-56 {
    grid-row-gap: 14rem !important;
    row-gap: 14rem !important;
  }

  .lt-xl\:gap-y-64 {
    grid-row-gap: 16rem !important;
    row-gap: 16rem !important;
  }

  .lt-xl\:gap-y-px {
    grid-row-gap: 1px !important;
    row-gap: 1px !important;
  }

  .lt-xl\:gap-y-2px {
    grid-row-gap: 2px !important;
    row-gap: 2px !important;
  }

  .lt-xl\:grid-flow-row {
    grid-auto-flow: row !important;
  }

  .lt-xl\:grid-flow-col {
    grid-auto-flow: column !important;
  }

  .lt-xl\:grid-flow-row-dense {
    grid-auto-flow: row dense !important;
  }

  .lt-xl\:grid-flow-col-dense {
    grid-auto-flow: column dense !important;
  }

  .lt-xl\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }

  .lt-xl\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }

  .lt-xl\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
  }

  .lt-xl\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
  }

  .lt-xl\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr)) !important;
  }

  .lt-xl\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr)) !important;
  }

  .lt-xl\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr)) !important;
  }

  .lt-xl\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr)) !important;
  }

  .lt-xl\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr)) !important;
  }

  .lt-xl\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr)) !important;
  }

  .lt-xl\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr)) !important;
  }

  .lt-xl\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr)) !important;
  }

  .lt-xl\:grid-cols-none {
    grid-template-columns: none !important;
  }

  .lt-xl\:col-auto {
    grid-column: auto !important;
  }

  .lt-xl\:col-span-1 {
    grid-column: span 1 / span 1 !important;
  }

  .lt-xl\:col-span-2 {
    grid-column: span 2 / span 2 !important;
  }

  .lt-xl\:col-span-3 {
    grid-column: span 3 / span 3 !important;
  }

  .lt-xl\:col-span-4 {
    grid-column: span 4 / span 4 !important;
  }

  .lt-xl\:col-span-5 {
    grid-column: span 5 / span 5 !important;
  }

  .lt-xl\:col-span-6 {
    grid-column: span 6 / span 6 !important;
  }

  .lt-xl\:col-span-7 {
    grid-column: span 7 / span 7 !important;
  }

  .lt-xl\:col-span-8 {
    grid-column: span 8 / span 8 !important;
  }

  .lt-xl\:col-span-9 {
    grid-column: span 9 / span 9 !important;
  }

  .lt-xl\:col-span-10 {
    grid-column: span 10 / span 10 !important;
  }

  .lt-xl\:col-span-11 {
    grid-column: span 11 / span 11 !important;
  }

  .lt-xl\:col-span-12 {
    grid-column: span 12 / span 12 !important;
  }

  .lt-xl\:col-start-1 {
    grid-column-start: 1 !important;
  }

  .lt-xl\:col-start-2 {
    grid-column-start: 2 !important;
  }

  .lt-xl\:col-start-3 {
    grid-column-start: 3 !important;
  }

  .lt-xl\:col-start-4 {
    grid-column-start: 4 !important;
  }

  .lt-xl\:col-start-5 {
    grid-column-start: 5 !important;
  }

  .lt-xl\:col-start-6 {
    grid-column-start: 6 !important;
  }

  .lt-xl\:col-start-7 {
    grid-column-start: 7 !important;
  }

  .lt-xl\:col-start-8 {
    grid-column-start: 8 !important;
  }

  .lt-xl\:col-start-9 {
    grid-column-start: 9 !important;
  }

  .lt-xl\:col-start-10 {
    grid-column-start: 10 !important;
  }

  .lt-xl\:col-start-11 {
    grid-column-start: 11 !important;
  }

  .lt-xl\:col-start-12 {
    grid-column-start: 12 !important;
  }

  .lt-xl\:col-start-13 {
    grid-column-start: 13 !important;
  }

  .lt-xl\:col-start-auto {
    grid-column-start: auto !important;
  }

  .lt-xl\:col-end-1 {
    grid-column-end: 1 !important;
  }

  .lt-xl\:col-end-2 {
    grid-column-end: 2 !important;
  }

  .lt-xl\:col-end-3 {
    grid-column-end: 3 !important;
  }

  .lt-xl\:col-end-4 {
    grid-column-end: 4 !important;
  }

  .lt-xl\:col-end-5 {
    grid-column-end: 5 !important;
  }

  .lt-xl\:col-end-6 {
    grid-column-end: 6 !important;
  }

  .lt-xl\:col-end-7 {
    grid-column-end: 7 !important;
  }

  .lt-xl\:col-end-8 {
    grid-column-end: 8 !important;
  }

  .lt-xl\:col-end-9 {
    grid-column-end: 9 !important;
  }

  .lt-xl\:col-end-10 {
    grid-column-end: 10 !important;
  }

  .lt-xl\:col-end-11 {
    grid-column-end: 11 !important;
  }

  .lt-xl\:col-end-12 {
    grid-column-end: 12 !important;
  }

  .lt-xl\:col-end-13 {
    grid-column-end: 13 !important;
  }

  .lt-xl\:col-end-auto {
    grid-column-end: auto !important;
  }

  .lt-xl\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr)) !important;
  }

  .lt-xl\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr)) !important;
  }

  .lt-xl\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr)) !important;
  }

  .lt-xl\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr)) !important;
  }

  .lt-xl\:grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr)) !important;
  }

  .lt-xl\:grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr)) !important;
  }

  .lt-xl\:grid-rows-none {
    grid-template-rows: none !important;
  }

  .lt-xl\:row-auto {
    grid-row: auto !important;
  }

  .lt-xl\:row-span-1 {
    grid-row: span 1 / span 1 !important;
  }

  .lt-xl\:row-span-2 {
    grid-row: span 2 / span 2 !important;
  }

  .lt-xl\:row-span-3 {
    grid-row: span 3 / span 3 !important;
  }

  .lt-xl\:row-span-4 {
    grid-row: span 4 / span 4 !important;
  }

  .lt-xl\:row-span-5 {
    grid-row: span 5 / span 5 !important;
  }

  .lt-xl\:row-span-6 {
    grid-row: span 6 / span 6 !important;
  }

  .lt-xl\:row-start-1 {
    grid-row-start: 1 !important;
  }

  .lt-xl\:row-start-2 {
    grid-row-start: 2 !important;
  }

  .lt-xl\:row-start-3 {
    grid-row-start: 3 !important;
  }

  .lt-xl\:row-start-4 {
    grid-row-start: 4 !important;
  }

  .lt-xl\:row-start-5 {
    grid-row-start: 5 !important;
  }

  .lt-xl\:row-start-6 {
    grid-row-start: 6 !important;
  }

  .lt-xl\:row-start-7 {
    grid-row-start: 7 !important;
  }

  .lt-xl\:row-start-auto {
    grid-row-start: auto !important;
  }

  .lt-xl\:row-end-1 {
    grid-row-end: 1 !important;
  }

  .lt-xl\:row-end-2 {
    grid-row-end: 2 !important;
  }

  .lt-xl\:row-end-3 {
    grid-row-end: 3 !important;
  }

  .lt-xl\:row-end-4 {
    grid-row-end: 4 !important;
  }

  .lt-xl\:row-end-5 {
    grid-row-end: 5 !important;
  }

  .lt-xl\:row-end-6 {
    grid-row-end: 6 !important;
  }

  .lt-xl\:row-end-7 {
    grid-row-end: 7 !important;
  }

  .lt-xl\:row-end-auto {
    grid-row-end: auto !important;
  }

  .lt-xl\:transform {
    --transform-translate-x: 0 !important;
    --transform-translate-y: 0 !important;
    --transform-rotate: 0 !important;
    --transform-skew-x: 0 !important;
    --transform-skew-y: 0 !important;
    --transform-scale-x: 1 !important;
    --transform-scale-y: 1 !important;
    transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y)) !important;
  }

  .lt-xl\:transform-none {
    transform: none !important;
  }

  .lt-xl\:origin-center {
    transform-origin: center !important;
  }

  .lt-xl\:origin-top {
    transform-origin: top !important;
  }

  .lt-xl\:origin-top-right {
    transform-origin: top right !important;
  }

  .lt-xl\:origin-right {
    transform-origin: right !important;
  }

  .lt-xl\:origin-bottom-right {
    transform-origin: bottom right !important;
  }

  .lt-xl\:origin-bottom {
    transform-origin: bottom !important;
  }

  .lt-xl\:origin-bottom-left {
    transform-origin: bottom left !important;
  }

  .lt-xl\:origin-left {
    transform-origin: left !important;
  }

  .lt-xl\:origin-top-left {
    transform-origin: top left !important;
  }

  .lt-xl\:icon-size-12 {
    width: 12px !important;
    height: 12px !important;
    min-width: 12px !important;
    min-height: 12px !important;
    font-size: 12px !important;
    line-height: 12px !important;
  }

  .lt-xl\:icon-size-12 svg {
    width: 12px !important;
    height: 12px !important;
  }

  .lt-xl\:icon-size-14 {
    width: 14px !important;
    height: 14px !important;
    min-width: 14px !important;
    min-height: 14px !important;
    font-size: 14px !important;
    line-height: 14px !important;
  }

  .lt-xl\:icon-size-14 svg {
    width: 14px !important;
    height: 14px !important;
  }

  .lt-xl\:icon-size-16 {
    width: 16px !important;
    height: 16px !important;
    min-width: 16px !important;
    min-height: 16px !important;
    font-size: 16px !important;
    line-height: 16px !important;
  }

  .lt-xl\:icon-size-16 svg {
    width: 16px !important;
    height: 16px !important;
  }

  .lt-xl\:icon-size-18 {
    width: 18px !important;
    height: 18px !important;
    min-width: 18px !important;
    min-height: 18px !important;
    font-size: 18px !important;
    line-height: 18px !important;
  }

  .lt-xl\:icon-size-18 svg {
    width: 18px !important;
    height: 18px !important;
  }

  .lt-xl\:icon-size-20 {
    width: 20px !important;
    height: 20px !important;
    min-width: 20px !important;
    min-height: 20px !important;
    font-size: 20px !important;
    line-height: 20px !important;
  }

  .lt-xl\:icon-size-20 svg {
    width: 20px !important;
    height: 20px !important;
  }

  .lt-xl\:icon-size-24 {
    width: 24px !important;
    height: 24px !important;
    min-width: 24px !important;
    min-height: 24px !important;
    font-size: 24px !important;
    line-height: 24px !important;
  }

  .lt-xl\:icon-size-24 svg {
    width: 24px !important;
    height: 24px !important;
  }

  .lt-xl\:icon-size-32 {
    width: 32px !important;
    height: 32px !important;
    min-width: 32px !important;
    min-height: 32px !important;
    font-size: 32px !important;
    line-height: 32px !important;
  }

  .lt-xl\:icon-size-32 svg {
    width: 32px !important;
    height: 32px !important;
  }

  .lt-xl\:icon-size-40 {
    width: 40px !important;
    height: 40px !important;
    min-width: 40px !important;
    min-height: 40px !important;
    font-size: 40px !important;
    line-height: 40px !important;
  }

  .lt-xl\:icon-size-40 svg {
    width: 40px !important;
    height: 40px !important;
  }

  .lt-xl\:icon-size-48 {
    width: 48px !important;
    height: 48px !important;
    min-width: 48px !important;
    min-height: 48px !important;
    font-size: 48px !important;
    line-height: 48px !important;
  }

  .lt-xl\:icon-size-48 svg {
    width: 48px !important;
    height: 48px !important;
  }

  .lt-xl\:icon-size-56 {
    width: 56px !important;
    height: 56px !important;
    min-width: 56px !important;
    min-height: 56px !important;
    font-size: 56px !important;
    line-height: 56px !important;
  }

  .lt-xl\:icon-size-56 svg {
    width: 56px !important;
    height: 56px !important;
  }

  .lt-xl\:icon-size-64 {
    width: 64px !important;
    height: 64px !important;
    min-width: 64px !important;
    min-height: 64px !important;
    font-size: 64px !important;
    line-height: 64px !important;
  }

  .lt-xl\:icon-size-64 svg {
    width: 64px !important;
    height: 64px !important;
  }

  .lt-xl\:icon-size-72 {
    width: 72px !important;
    height: 72px !important;
    min-width: 72px !important;
    min-height: 72px !important;
    font-size: 72px !important;
    line-height: 72px !important;
  }

  .lt-xl\:icon-size-72 svg {
    width: 72px !important;
    height: 72px !important;
  }

  .lt-xl\:icon-size-80 {
    width: 80px !important;
    height: 80px !important;
    min-width: 80px !important;
    min-height: 80px !important;
    font-size: 80px !important;
    line-height: 80px !important;
  }

  .lt-xl\:icon-size-80 svg {
    width: 80px !important;
    height: 80px !important;
  }

  .lt-xl\:icon-size-88 {
    width: 88px !important;
    height: 88px !important;
    min-width: 88px !important;
    min-height: 88px !important;
    font-size: 88px !important;
    line-height: 88px !important;
  }

  .lt-xl\:icon-size-88 svg {
    width: 88px !important;
    height: 88px !important;
  }

  .lt-xl\:icon-size-96 {
    width: 96px !important;
    height: 96px !important;
    min-width: 96px !important;
    min-height: 96px !important;
    font-size: 96px !important;
    line-height: 96px !important;
  }

  .lt-xl\:icon-size-96 svg {
    width: 96px !important;
    height: 96px !important;
  }
}

@media (min-width: 600px) {
  .gt-xs\:space-y-0 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(0px * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(0px * var(--space-y-reverse)) !important;
  }

  .gt-xs\:space-x-0 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(0px * var(--space-x-reverse)) !important;
    margin-left: calc(0px * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-xs\:space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(0.25rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(0.25rem * var(--space-y-reverse)) !important;
  }

  .gt-xs\:space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(0.25rem * var(--space-x-reverse)) !important;
    margin-left: calc(0.25rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-xs\:space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(0.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(0.5rem * var(--space-y-reverse)) !important;
  }

  .gt-xs\:space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(0.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(0.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-xs\:space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(0.75rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(0.75rem * var(--space-y-reverse)) !important;
  }

  .gt-xs\:space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(0.75rem * var(--space-x-reverse)) !important;
    margin-left: calc(0.75rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-xs\:space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(1rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(1rem * var(--space-y-reverse)) !important;
  }

  .gt-xs\:space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(1rem * var(--space-x-reverse)) !important;
    margin-left: calc(1rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-xs\:space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(1.25rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(1.25rem * var(--space-y-reverse)) !important;
  }

  .gt-xs\:space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(1.25rem * var(--space-x-reverse)) !important;
    margin-left: calc(1.25rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-xs\:space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(1.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(1.5rem * var(--space-y-reverse)) !important;
  }

  .gt-xs\:space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(1.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(1.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-xs\:space-y-8 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(2rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(2rem * var(--space-y-reverse)) !important;
  }

  .gt-xs\:space-x-8 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(2rem * var(--space-x-reverse)) !important;
    margin-left: calc(2rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-xs\:space-y-10 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(2.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(2.5rem * var(--space-y-reverse)) !important;
  }

  .gt-xs\:space-x-10 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(2.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(2.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-xs\:space-y-12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(3rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(3rem * var(--space-y-reverse)) !important;
  }

  .gt-xs\:space-x-12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(3rem * var(--space-x-reverse)) !important;
    margin-left: calc(3rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-xs\:space-y-14 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(3.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(3.5rem * var(--space-y-reverse)) !important;
  }

  .gt-xs\:space-x-14 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(3.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(3.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-xs\:space-y-16 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(4rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(4rem * var(--space-y-reverse)) !important;
  }

  .gt-xs\:space-x-16 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(4rem * var(--space-x-reverse)) !important;
    margin-left: calc(4rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-xs\:space-y-18 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(4.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(4.5rem * var(--space-y-reverse)) !important;
  }

  .gt-xs\:space-x-18 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(4.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(4.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-xs\:space-y-20 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(5rem * var(--space-y-reverse)) !important;
  }

  .gt-xs\:space-x-20 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(5rem * var(--space-x-reverse)) !important;
    margin-left: calc(5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-xs\:space-y-22 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(5.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(5.5rem * var(--space-y-reverse)) !important;
  }

  .gt-xs\:space-x-22 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(5.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(5.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-xs\:space-y-24 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(6rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(6rem * var(--space-y-reverse)) !important;
  }

  .gt-xs\:space-x-24 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(6rem * var(--space-x-reverse)) !important;
    margin-left: calc(6rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-xs\:space-y-26 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(6.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(6.5rem * var(--space-y-reverse)) !important;
  }

  .gt-xs\:space-x-26 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(6.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(6.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-xs\:space-y-28 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(7rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(7rem * var(--space-y-reverse)) !important;
  }

  .gt-xs\:space-x-28 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(7rem * var(--space-x-reverse)) !important;
    margin-left: calc(7rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-xs\:space-y-30 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(7.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(7.5rem * var(--space-y-reverse)) !important;
  }

  .gt-xs\:space-x-30 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(7.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(7.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-xs\:space-y-32 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(8rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(8rem * var(--space-y-reverse)) !important;
  }

  .gt-xs\:space-x-32 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(8rem * var(--space-x-reverse)) !important;
    margin-left: calc(8rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-xs\:space-y-36 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(9rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(9rem * var(--space-y-reverse)) !important;
  }

  .gt-xs\:space-x-36 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(9rem * var(--space-x-reverse)) !important;
    margin-left: calc(9rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-xs\:space-y-40 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(10rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(10rem * var(--space-y-reverse)) !important;
  }

  .gt-xs\:space-x-40 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(10rem * var(--space-x-reverse)) !important;
    margin-left: calc(10rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-xs\:space-y-48 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(12rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(12rem * var(--space-y-reverse)) !important;
  }

  .gt-xs\:space-x-48 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(12rem * var(--space-x-reverse)) !important;
    margin-left: calc(12rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-xs\:space-y-56 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(14rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(14rem * var(--space-y-reverse)) !important;
  }

  .gt-xs\:space-x-56 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(14rem * var(--space-x-reverse)) !important;
    margin-left: calc(14rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-xs\:space-y-64 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(16rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(16rem * var(--space-y-reverse)) !important;
  }

  .gt-xs\:space-x-64 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(16rem * var(--space-x-reverse)) !important;
    margin-left: calc(16rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-xs\:space-y-px > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(1px * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(1px * var(--space-y-reverse)) !important;
  }

  .gt-xs\:space-x-px > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(1px * var(--space-x-reverse)) !important;
    margin-left: calc(1px * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-xs\:space-y-2px > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(2px * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(2px * var(--space-y-reverse)) !important;
  }

  .gt-xs\:space-x-2px > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(2px * var(--space-x-reverse)) !important;
    margin-left: calc(2px * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-xs\:-space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-0.25rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-0.25rem * var(--space-y-reverse)) !important;
  }

  .gt-xs\:-space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-0.25rem * var(--space-x-reverse)) !important;
    margin-left: calc(-0.25rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-xs\:-space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-0.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-0.5rem * var(--space-y-reverse)) !important;
  }

  .gt-xs\:-space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-0.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-0.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-xs\:-space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-0.75rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-0.75rem * var(--space-y-reverse)) !important;
  }

  .gt-xs\:-space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-0.75rem * var(--space-x-reverse)) !important;
    margin-left: calc(-0.75rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-xs\:-space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-1rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-1rem * var(--space-y-reverse)) !important;
  }

  .gt-xs\:-space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-1rem * var(--space-x-reverse)) !important;
    margin-left: calc(-1rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-xs\:-space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-1.25rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-1.25rem * var(--space-y-reverse)) !important;
  }

  .gt-xs\:-space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-1.25rem * var(--space-x-reverse)) !important;
    margin-left: calc(-1.25rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-xs\:-space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-1.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-1.5rem * var(--space-y-reverse)) !important;
  }

  .gt-xs\:-space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-1.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-1.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-xs\:-space-y-8 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-2rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-2rem * var(--space-y-reverse)) !important;
  }

  .gt-xs\:-space-x-8 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-2rem * var(--space-x-reverse)) !important;
    margin-left: calc(-2rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-xs\:-space-y-10 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-2.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-2.5rem * var(--space-y-reverse)) !important;
  }

  .gt-xs\:-space-x-10 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-2.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-2.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-xs\:-space-y-12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-3rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-3rem * var(--space-y-reverse)) !important;
  }

  .gt-xs\:-space-x-12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-3rem * var(--space-x-reverse)) !important;
    margin-left: calc(-3rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-xs\:-space-y-14 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-3.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-3.5rem * var(--space-y-reverse)) !important;
  }

  .gt-xs\:-space-x-14 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-3.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-3.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-xs\:-space-y-16 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-4rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-4rem * var(--space-y-reverse)) !important;
  }

  .gt-xs\:-space-x-16 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-4rem * var(--space-x-reverse)) !important;
    margin-left: calc(-4rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-xs\:-space-y-18 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-4.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-4.5rem * var(--space-y-reverse)) !important;
  }

  .gt-xs\:-space-x-18 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-4.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-4.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-xs\:-space-y-20 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-5rem * var(--space-y-reverse)) !important;
  }

  .gt-xs\:-space-x-20 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-xs\:-space-y-22 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-5.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-5.5rem * var(--space-y-reverse)) !important;
  }

  .gt-xs\:-space-x-22 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-5.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-5.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-xs\:-space-y-24 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-6rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-6rem * var(--space-y-reverse)) !important;
  }

  .gt-xs\:-space-x-24 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-6rem * var(--space-x-reverse)) !important;
    margin-left: calc(-6rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-xs\:-space-y-26 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-6.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-6.5rem * var(--space-y-reverse)) !important;
  }

  .gt-xs\:-space-x-26 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-6.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-6.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-xs\:-space-y-28 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-7rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-7rem * var(--space-y-reverse)) !important;
  }

  .gt-xs\:-space-x-28 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-7rem * var(--space-x-reverse)) !important;
    margin-left: calc(-7rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-xs\:-space-y-30 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-7.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-7.5rem * var(--space-y-reverse)) !important;
  }

  .gt-xs\:-space-x-30 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-7.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-7.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-xs\:-space-y-32 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-8rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-8rem * var(--space-y-reverse)) !important;
  }

  .gt-xs\:-space-x-32 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-8rem * var(--space-x-reverse)) !important;
    margin-left: calc(-8rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-xs\:-space-y-36 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-9rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-9rem * var(--space-y-reverse)) !important;
  }

  .gt-xs\:-space-x-36 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-9rem * var(--space-x-reverse)) !important;
    margin-left: calc(-9rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-xs\:-space-y-40 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-10rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-10rem * var(--space-y-reverse)) !important;
  }

  .gt-xs\:-space-x-40 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-10rem * var(--space-x-reverse)) !important;
    margin-left: calc(-10rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-xs\:-space-y-48 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-12rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-12rem * var(--space-y-reverse)) !important;
  }

  .gt-xs\:-space-x-48 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-12rem * var(--space-x-reverse)) !important;
    margin-left: calc(-12rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-xs\:-space-y-56 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-14rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-14rem * var(--space-y-reverse)) !important;
  }

  .gt-xs\:-space-x-56 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-14rem * var(--space-x-reverse)) !important;
    margin-left: calc(-14rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-xs\:-space-y-64 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-16rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-16rem * var(--space-y-reverse)) !important;
  }

  .gt-xs\:-space-x-64 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-16rem * var(--space-x-reverse)) !important;
    margin-left: calc(-16rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-xs\:-space-y-px > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-1px * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-1px * var(--space-y-reverse)) !important;
  }

  .gt-xs\:-space-x-px > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-1px * var(--space-x-reverse)) !important;
    margin-left: calc(-1px * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-xs\:-space-y-2px > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-2px * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-2px * var(--space-y-reverse)) !important;
  }

  .gt-xs\:-space-x-2px > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-2px * var(--space-x-reverse)) !important;
    margin-left: calc(-2px * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-xs\:space-y-reverse > :not(template) ~ :not(template) {
    --space-y-reverse: 1 !important;
  }

  .gt-xs\:space-x-reverse > :not(template) ~ :not(template) {
    --space-x-reverse: 1 !important;
  }

  .gt-xs\:divide-y-0 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(0px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(0px * var(--divide-y-reverse)) !important;
  }

  .gt-xs\:divide-x-0 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(0px * var(--divide-x-reverse)) !important;
    border-left-width: calc(0px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .gt-xs\:divide-y-2 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(2px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(2px * var(--divide-y-reverse)) !important;
  }

  .gt-xs\:divide-x-2 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(2px * var(--divide-x-reverse)) !important;
    border-left-width: calc(2px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .gt-xs\:divide-y-4 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(4px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(4px * var(--divide-y-reverse)) !important;
  }

  .gt-xs\:divide-x-4 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(4px * var(--divide-x-reverse)) !important;
    border-left-width: calc(4px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .gt-xs\:divide-y-8 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(8px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(8px * var(--divide-y-reverse)) !important;
  }

  .gt-xs\:divide-x-8 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(8px * var(--divide-x-reverse)) !important;
    border-left-width: calc(8px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .gt-xs\:divide-y > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(1px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(1px * var(--divide-y-reverse)) !important;
  }

  .gt-xs\:divide-x > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(1px * var(--divide-x-reverse)) !important;
    border-left-width: calc(1px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .gt-xs\:divide-y-reverse > :not(template) ~ :not(template) {
    --divide-y-reverse: 1 !important;
  }

  .gt-xs\:divide-x-reverse > :not(template) ~ :not(template) {
    --divide-x-reverse: 1 !important;
  }

  .gt-xs\:divide-current > :not(template) ~ :not(template) {
    border-color: currentColor !important;
  }

  .gt-xs\:divide-transparent > :not(template) ~ :not(template) {
    border-color: transparent !important;
  }

  .gt-xs\:divide-white > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FFFFFF !important;
    border-color: rgba(255, 255, 255, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-black > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #000000 !important;
    border-color: rgba(0, 0, 0, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-gray-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F9FAFB !important;
    border-color: rgba(249, 250, 251, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-gray-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F4F5F7 !important;
    border-color: rgba(244, 245, 247, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-gray-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E5E7EB !important;
    border-color: rgba(229, 231, 235, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-gray-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #D2D6DC !important;
    border-color: rgba(210, 214, 220, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-gray-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9FA6B2 !important;
    border-color: rgba(159, 166, 178, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-gray-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #6B7280 !important;
    border-color: rgba(107, 114, 128, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-gray-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #4B5563 !important;
    border-color: rgba(75, 85, 99, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-gray-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #374151 !important;
    border-color: rgba(55, 65, 81, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-gray-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #252F3F !important;
    border-color: rgba(37, 47, 63, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-gray-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #161E2E !important;
    border-color: rgba(22, 30, 46, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-gray > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #6B7280 !important;
    border-color: rgba(107, 114, 128, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-cool-gray-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FBFDFE !important;
    border-color: rgba(251, 253, 254, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-cool-gray-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F1F5F9 !important;
    border-color: rgba(241, 245, 249, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-cool-gray-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E2E8F0 !important;
    border-color: rgba(226, 232, 240, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-cool-gray-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #CFD8E3 !important;
    border-color: rgba(207, 216, 227, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-cool-gray-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #97A6BA !important;
    border-color: rgba(151, 166, 186, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-cool-gray-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #64748B !important;
    border-color: rgba(100, 116, 139, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-cool-gray-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #475569 !important;
    border-color: rgba(71, 85, 105, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-cool-gray-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #364152 !important;
    border-color: rgba(54, 65, 82, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-cool-gray-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #27303F !important;
    border-color: rgba(39, 48, 63, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-cool-gray-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #1A202E !important;
    border-color: rgba(26, 32, 46, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-cool-gray > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #64748B !important;
    border-color: rgba(100, 116, 139, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-red-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FDF2F2 !important;
    border-color: rgba(253, 242, 242, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-red-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FDE8E8 !important;
    border-color: rgba(253, 232, 232, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-red-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FBD5D5 !important;
    border-color: rgba(251, 213, 213, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-red-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F8B4B4 !important;
    border-color: rgba(248, 180, 180, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-red-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F98080 !important;
    border-color: rgba(249, 128, 128, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-red-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F05252 !important;
    border-color: rgba(240, 82, 82, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-red-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E02424 !important;
    border-color: rgba(224, 36, 36, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-red-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #C81E1E !important;
    border-color: rgba(200, 30, 30, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-red-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9B1C1C !important;
    border-color: rgba(155, 28, 28, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-red-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #771D1D !important;
    border-color: rgba(119, 29, 29, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-red > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F05252 !important;
    border-color: rgba(240, 82, 82, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-orange-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FFF8F1 !important;
    border-color: rgba(255, 248, 241, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-orange-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FEECDC !important;
    border-color: rgba(254, 236, 220, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-orange-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FCD9BD !important;
    border-color: rgba(252, 217, 189, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-orange-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FDBA8C !important;
    border-color: rgba(253, 186, 140, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-orange-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FF8A4C !important;
    border-color: rgba(255, 138, 76, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-orange-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FF5A1F !important;
    border-color: rgba(255, 90, 31, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-orange-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #ff6700 !important;
    border-color: rgba(255, 103, 0, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-orange-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #B43403 !important;
    border-color: rgba(180, 52, 3, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-orange-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #8A2C0D !important;
    border-color: rgba(138, 44, 13, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-orange-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #771D1D !important;
    border-color: rgba(119, 29, 29, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-orange > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FF5A1F !important;
    border-color: rgba(255, 90, 31, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-yellow-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FDFDEA !important;
    border-color: rgba(253, 253, 234, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-yellow-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FDF6B2 !important;
    border-color: rgba(253, 246, 178, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-yellow-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FCE96A !important;
    border-color: rgba(252, 233, 106, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-yellow-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FACA15 !important;
    border-color: rgba(250, 202, 21, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-yellow-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E3A008 !important;
    border-color: rgba(227, 160, 8, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-yellow-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #C27803 !important;
    border-color: rgba(194, 120, 3, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-yellow-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9F580A !important;
    border-color: rgba(159, 88, 10, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-yellow-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #8E4B10 !important;
    border-color: rgba(142, 75, 16, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-yellow-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #723B13 !important;
    border-color: rgba(114, 59, 19, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-yellow-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #633112 !important;
    border-color: rgba(99, 49, 18, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-yellow > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #C27803 !important;
    border-color: rgba(194, 120, 3, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-green-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F3FAF7 !important;
    border-color: rgba(243, 250, 247, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-green-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #DEF7EC !important;
    border-color: rgba(222, 247, 236, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-green-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #BCF0DA !important;
    border-color: rgba(188, 240, 218, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-green-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #84E1BC !important;
    border-color: rgba(132, 225, 188, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-green-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #31C48D !important;
    border-color: rgba(49, 196, 141, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-green-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #0E9F6E !important;
    border-color: rgba(14, 159, 110, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-green-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #057A55 !important;
    border-color: rgba(5, 122, 85, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-green-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #046C4E !important;
    border-color: rgba(4, 108, 78, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-green-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #03543F !important;
    border-color: rgba(3, 84, 63, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-green-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #014737 !important;
    border-color: rgba(1, 71, 55, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-green > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #0E9F6E !important;
    border-color: rgba(14, 159, 110, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-teal-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #EDFAFA !important;
    border-color: rgba(237, 250, 250, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-teal-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #D5F5F6 !important;
    border-color: rgba(213, 245, 246, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-teal-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #AFECEF !important;
    border-color: rgba(175, 236, 239, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-teal-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #7EDCE2 !important;
    border-color: rgba(126, 220, 226, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-teal-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #16BDCA !important;
    border-color: rgba(22, 189, 202, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-teal-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #0694A2 !important;
    border-color: rgba(6, 148, 162, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-teal-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #047481 !important;
    border-color: rgba(4, 116, 129, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-teal-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #036672 !important;
    border-color: rgba(3, 102, 114, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-teal-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #05505C !important;
    border-color: rgba(5, 80, 92, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-teal-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #014451 !important;
    border-color: rgba(1, 68, 81, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-teal > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #0694A2 !important;
    border-color: rgba(6, 148, 162, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-blue-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #EBF5FF !important;
    border-color: rgba(235, 245, 255, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-blue-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E1EFFE !important;
    border-color: rgba(225, 239, 254, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-blue-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #C3DDFD !important;
    border-color: rgba(195, 221, 253, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-blue-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #A4CAFE !important;
    border-color: rgba(164, 202, 254, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-blue-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #76A9FA !important;
    border-color: rgba(118, 169, 250, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-blue-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #3F83F8 !important;
    border-color: rgba(63, 131, 248, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-blue-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #1C64F2 !important;
    border-color: rgba(28, 100, 242, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-blue-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #1A56DB !important;
    border-color: rgba(26, 86, 219, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-blue-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #1E429F !important;
    border-color: rgba(30, 66, 159, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-blue-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #233876 !important;
    border-color: rgba(35, 56, 118, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-blue > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #3F83F8 !important;
    border-color: rgba(63, 131, 248, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-indigo-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F0F5FF !important;
    border-color: rgba(240, 245, 255, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-indigo-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E5EDFF !important;
    border-color: rgba(229, 237, 255, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-indigo-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #CDDBFE !important;
    border-color: rgba(205, 219, 254, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-indigo-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #B4C6FC !important;
    border-color: rgba(180, 198, 252, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-indigo-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #8DA2FB !important;
    border-color: rgba(141, 162, 251, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-indigo-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #6875F5 !important;
    border-color: rgba(104, 117, 245, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-indigo-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #5850EC !important;
    border-color: rgba(88, 80, 236, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-indigo-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #5145CD !important;
    border-color: rgba(81, 69, 205, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-indigo-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #42389D !important;
    border-color: rgba(66, 56, 157, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-indigo-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #362F78 !important;
    border-color: rgba(54, 47, 120, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-indigo > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #6875F5 !important;
    border-color: rgba(104, 117, 245, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-purple-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F6F5FF !important;
    border-color: rgba(246, 245, 255, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-purple-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #EDEBFE !important;
    border-color: rgba(237, 235, 254, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-purple-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #DCD7FE !important;
    border-color: rgba(220, 215, 254, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-purple-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #CABFFD !important;
    border-color: rgba(202, 191, 253, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-purple-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #AC94FA !important;
    border-color: rgba(172, 148, 250, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-purple-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9061F9 !important;
    border-color: rgba(144, 97, 249, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-purple-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #7E3AF2 !important;
    border-color: rgba(126, 58, 242, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-purple-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #6C2BD9 !important;
    border-color: rgba(108, 43, 217, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-purple-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #5521B5 !important;
    border-color: rgba(85, 33, 181, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-purple-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #4A1D96 !important;
    border-color: rgba(74, 29, 150, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-purple > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9061F9 !important;
    border-color: rgba(144, 97, 249, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-pink-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FDF2F8 !important;
    border-color: rgba(253, 242, 248, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-pink-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FCE8F3 !important;
    border-color: rgba(252, 232, 243, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-pink-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FAD1E8 !important;
    border-color: rgba(250, 209, 232, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-pink-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F8B4D9 !important;
    border-color: rgba(248, 180, 217, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-pink-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F17EB8 !important;
    border-color: rgba(241, 126, 184, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-pink-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E74694 !important;
    border-color: rgba(231, 70, 148, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-pink-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #D61F69 !important;
    border-color: rgba(214, 31, 105, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-pink-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #BF125D !important;
    border-color: rgba(191, 18, 93, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-pink-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #99154B !important;
    border-color: rgba(153, 21, 75, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-pink-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #751A3D !important;
    border-color: rgba(117, 26, 61, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-pink > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E74694 !important;
    border-color: rgba(231, 70, 148, var(--divide-opacity)) !important;
  }

  .gt-xs\:divide-opacity-0 > :not(template) ~ :not(template) {
    --divide-opacity: 0 !important;
  }

  .gt-xs\:divide-opacity-12 > :not(template) ~ :not(template) {
    --divide-opacity: 0.12 !important;
  }

  .gt-xs\:divide-opacity-25 > :not(template) ~ :not(template) {
    --divide-opacity: 0.25 !important;
  }

  .gt-xs\:divide-opacity-38 > :not(template) ~ :not(template) {
    --divide-opacity: 0.38 !important;
  }

  .gt-xs\:divide-opacity-50 > :not(template) ~ :not(template) {
    --divide-opacity: 0.5 !important;
  }

  .gt-xs\:divide-opacity-54 > :not(template) ~ :not(template) {
    --divide-opacity: 0.54 !important;
  }

  .gt-xs\:divide-opacity-70 > :not(template) ~ :not(template) {
    --divide-opacity: 0.70 !important;
  }

  .gt-xs\:divide-opacity-75 > :not(template) ~ :not(template) {
    --divide-opacity: 0.75 !important;
  }

  .gt-xs\:divide-opacity-84 > :not(template) ~ :not(template) {
    --divide-opacity: 0.84 !important;
  }

  .gt-xs\:divide-opacity-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
  }

  .gt-xs\:sr-only {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border-width: 0 !important;
  }

  .gt-xs\:not-sr-only {
    position: static !important;
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: visible !important;
    clip: auto !important;
    white-space: normal !important;
  }

  .gt-xs\:focus\:sr-only:focus {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border-width: 0 !important;
  }

  .gt-xs\:focus\:not-sr-only:focus {
    position: static !important;
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: visible !important;
    clip: auto !important;
    white-space: normal !important;
  }

  .gt-xs\:appearance-none {
    -webkit-appearance: none !important;
       -moz-appearance: none !important;
            appearance: none !important;
  }

  .gt-xs\:bg-fixed {
    background-attachment: fixed !important;
  }

  .gt-xs\:bg-local {
    background-attachment: local !important;
  }

  .gt-xs\:bg-scroll {
    background-attachment: scroll !important;
  }

  .gt-xs\:bg-opacity-0 {
    --bg-opacity: 0 !important;
  }

  .gt-xs\:bg-opacity-12 {
    --bg-opacity: 0.12 !important;
  }

  .gt-xs\:bg-opacity-25 {
    --bg-opacity: 0.25 !important;
  }

  .gt-xs\:bg-opacity-38 {
    --bg-opacity: 0.38 !important;
  }

  .gt-xs\:bg-opacity-50 {
    --bg-opacity: 0.5 !important;
  }

  .gt-xs\:bg-opacity-54 {
    --bg-opacity: 0.54 !important;
  }

  .gt-xs\:bg-opacity-70 {
    --bg-opacity: 0.70 !important;
  }

  .gt-xs\:bg-opacity-75 {
    --bg-opacity: 0.75 !important;
  }

  .gt-xs\:bg-opacity-84 {
    --bg-opacity: 0.84 !important;
  }

  .gt-xs\:bg-opacity-100 {
    --bg-opacity: 1 !important;
  }

  .gt-xs\:hover\:bg-opacity-0:hover {
    --bg-opacity: 0 !important;
  }

  .gt-xs\:hover\:bg-opacity-12:hover {
    --bg-opacity: 0.12 !important;
  }

  .gt-xs\:hover\:bg-opacity-25:hover {
    --bg-opacity: 0.25 !important;
  }

  .gt-xs\:hover\:bg-opacity-38:hover {
    --bg-opacity: 0.38 !important;
  }

  .gt-xs\:hover\:bg-opacity-50:hover {
    --bg-opacity: 0.5 !important;
  }

  .gt-xs\:hover\:bg-opacity-54:hover {
    --bg-opacity: 0.54 !important;
  }

  .gt-xs\:hover\:bg-opacity-70:hover {
    --bg-opacity: 0.70 !important;
  }

  .gt-xs\:hover\:bg-opacity-75:hover {
    --bg-opacity: 0.75 !important;
  }

  .gt-xs\:hover\:bg-opacity-84:hover {
    --bg-opacity: 0.84 !important;
  }

  .gt-xs\:hover\:bg-opacity-100:hover {
    --bg-opacity: 1 !important;
  }

  .gt-xs\:focus\:bg-opacity-0:focus {
    --bg-opacity: 0 !important;
  }

  .gt-xs\:focus\:bg-opacity-12:focus {
    --bg-opacity: 0.12 !important;
  }

  .gt-xs\:focus\:bg-opacity-25:focus {
    --bg-opacity: 0.25 !important;
  }

  .gt-xs\:focus\:bg-opacity-38:focus {
    --bg-opacity: 0.38 !important;
  }

  .gt-xs\:focus\:bg-opacity-50:focus {
    --bg-opacity: 0.5 !important;
  }

  .gt-xs\:focus\:bg-opacity-54:focus {
    --bg-opacity: 0.54 !important;
  }

  .gt-xs\:focus\:bg-opacity-70:focus {
    --bg-opacity: 0.70 !important;
  }

  .gt-xs\:focus\:bg-opacity-75:focus {
    --bg-opacity: 0.75 !important;
  }

  .gt-xs\:focus\:bg-opacity-84:focus {
    --bg-opacity: 0.84 !important;
  }

  .gt-xs\:focus\:bg-opacity-100:focus {
    --bg-opacity: 1 !important;
  }

  .gt-xs\:bg-bottom {
    background-position: bottom !important;
  }

  .gt-xs\:bg-center {
    background-position: center !important;
  }

  .gt-xs\:bg-left {
    background-position: left !important;
  }

  .gt-xs\:bg-left-bottom {
    background-position: left bottom !important;
  }

  .gt-xs\:bg-left-top {
    background-position: left top !important;
  }

  .gt-xs\:bg-right {
    background-position: right !important;
  }

  .gt-xs\:bg-right-bottom {
    background-position: right bottom !important;
  }

  .gt-xs\:bg-right-top {
    background-position: right top !important;
  }

  .gt-xs\:bg-top {
    background-position: top !important;
  }

  .gt-xs\:bg-repeat {
    background-repeat: repeat !important;
  }

  .gt-xs\:bg-no-repeat {
    background-repeat: no-repeat !important;
  }

  .gt-xs\:bg-repeat-x {
    background-repeat: repeat-x !important;
  }

  .gt-xs\:bg-repeat-y {
    background-repeat: repeat-y !important;
  }

  .gt-xs\:bg-repeat-round {
    background-repeat: round !important;
  }

  .gt-xs\:bg-repeat-space {
    background-repeat: space !important;
  }

  .gt-xs\:bg-auto {
    background-size: auto !important;
  }

  .gt-xs\:bg-cover {
    background-size: cover !important;
  }

  .gt-xs\:bg-contain {
    background-size: contain !important;
  }

  .gt-xs\:border-collapse {
    border-collapse: collapse !important;
  }

  .gt-xs\:border-separate {
    border-collapse: separate !important;
  }

  .gt-xs\:border-opacity-0 {
    --border-opacity: 0 !important;
  }

  .gt-xs\:border-opacity-12 {
    --border-opacity: 0.12 !important;
  }

  .gt-xs\:border-opacity-25 {
    --border-opacity: 0.25 !important;
  }

  .gt-xs\:border-opacity-38 {
    --border-opacity: 0.38 !important;
  }

  .gt-xs\:border-opacity-50 {
    --border-opacity: 0.5 !important;
  }

  .gt-xs\:border-opacity-54 {
    --border-opacity: 0.54 !important;
  }

  .gt-xs\:border-opacity-70 {
    --border-opacity: 0.70 !important;
  }

  .gt-xs\:border-opacity-75 {
    --border-opacity: 0.75 !important;
  }

  .gt-xs\:border-opacity-84 {
    --border-opacity: 0.84 !important;
  }

  .gt-xs\:border-opacity-100 {
    --border-opacity: 1 !important;
  }

  .gt-xs\:hover\:border-opacity-0:hover {
    --border-opacity: 0 !important;
  }

  .gt-xs\:hover\:border-opacity-12:hover {
    --border-opacity: 0.12 !important;
  }

  .gt-xs\:hover\:border-opacity-25:hover {
    --border-opacity: 0.25 !important;
  }

  .gt-xs\:hover\:border-opacity-38:hover {
    --border-opacity: 0.38 !important;
  }

  .gt-xs\:hover\:border-opacity-50:hover {
    --border-opacity: 0.5 !important;
  }

  .gt-xs\:hover\:border-opacity-54:hover {
    --border-opacity: 0.54 !important;
  }

  .gt-xs\:hover\:border-opacity-70:hover {
    --border-opacity: 0.70 !important;
  }

  .gt-xs\:hover\:border-opacity-75:hover {
    --border-opacity: 0.75 !important;
  }

  .gt-xs\:hover\:border-opacity-84:hover {
    --border-opacity: 0.84 !important;
  }

  .gt-xs\:hover\:border-opacity-100:hover {
    --border-opacity: 1 !important;
  }

  .gt-xs\:focus\:border-opacity-0:focus {
    --border-opacity: 0 !important;
  }

  .gt-xs\:focus\:border-opacity-12:focus {
    --border-opacity: 0.12 !important;
  }

  .gt-xs\:focus\:border-opacity-25:focus {
    --border-opacity: 0.25 !important;
  }

  .gt-xs\:focus\:border-opacity-38:focus {
    --border-opacity: 0.38 !important;
  }

  .gt-xs\:focus\:border-opacity-50:focus {
    --border-opacity: 0.5 !important;
  }

  .gt-xs\:focus\:border-opacity-54:focus {
    --border-opacity: 0.54 !important;
  }

  .gt-xs\:focus\:border-opacity-70:focus {
    --border-opacity: 0.70 !important;
  }

  .gt-xs\:focus\:border-opacity-75:focus {
    --border-opacity: 0.75 !important;
  }

  .gt-xs\:focus\:border-opacity-84:focus {
    --border-opacity: 0.84 !important;
  }

  .gt-xs\:focus\:border-opacity-100:focus {
    --border-opacity: 1 !important;
  }

  .gt-xs\:rounded-none {
    border-radius: 0 !important;
  }

  .gt-xs\:rounded-sm {
    border-radius: 0.125rem !important;
  }

  .gt-xs\:rounded {
    border-radius: 0.25rem !important;
  }

  .gt-xs\:rounded-md {
    border-radius: 0.375rem !important;
  }

  .gt-xs\:rounded-lg {
    border-radius: 0.5rem !important;
  }

  .gt-xs\:rounded-full {
    border-radius: 9999px !important;
  }

  .gt-xs\:rounded-t-none {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .gt-xs\:rounded-r-none {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .gt-xs\:rounded-b-none {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .gt-xs\:rounded-l-none {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .gt-xs\:rounded-t-sm {
    border-top-left-radius: 0.125rem !important;
    border-top-right-radius: 0.125rem !important;
  }

  .gt-xs\:rounded-r-sm {
    border-top-right-radius: 0.125rem !important;
    border-bottom-right-radius: 0.125rem !important;
  }

  .gt-xs\:rounded-b-sm {
    border-bottom-right-radius: 0.125rem !important;
    border-bottom-left-radius: 0.125rem !important;
  }

  .gt-xs\:rounded-l-sm {
    border-top-left-radius: 0.125rem !important;
    border-bottom-left-radius: 0.125rem !important;
  }

  .gt-xs\:rounded-t {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }

  .gt-xs\:rounded-r {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }

  .gt-xs\:rounded-b {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }

  .gt-xs\:rounded-l {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }

  .gt-xs\:rounded-t-md {
    border-top-left-radius: 0.375rem !important;
    border-top-right-radius: 0.375rem !important;
  }

  .gt-xs\:rounded-r-md {
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }

  .gt-xs\:rounded-b-md {
    border-bottom-right-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }

  .gt-xs\:rounded-l-md {
    border-top-left-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }

  .gt-xs\:rounded-t-lg {
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
  }

  .gt-xs\:rounded-r-lg {
    border-top-right-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }

  .gt-xs\:rounded-b-lg {
    border-bottom-right-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }

  .gt-xs\:rounded-l-lg {
    border-top-left-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }

  .gt-xs\:rounded-t-full {
    border-top-left-radius: 9999px !important;
    border-top-right-radius: 9999px !important;
  }

  .gt-xs\:rounded-r-full {
    border-top-right-radius: 9999px !important;
    border-bottom-right-radius: 9999px !important;
  }

  .gt-xs\:rounded-b-full {
    border-bottom-right-radius: 9999px !important;
    border-bottom-left-radius: 9999px !important;
  }

  .gt-xs\:rounded-l-full {
    border-top-left-radius: 9999px !important;
    border-bottom-left-radius: 9999px !important;
  }

  .gt-xs\:rounded-tl-none {
    border-top-left-radius: 0 !important;
  }

  .gt-xs\:rounded-tr-none {
    border-top-right-radius: 0 !important;
  }

  .gt-xs\:rounded-br-none {
    border-bottom-right-radius: 0 !important;
  }

  .gt-xs\:rounded-bl-none {
    border-bottom-left-radius: 0 !important;
  }

  .gt-xs\:rounded-tl-sm {
    border-top-left-radius: 0.125rem !important;
  }

  .gt-xs\:rounded-tr-sm {
    border-top-right-radius: 0.125rem !important;
  }

  .gt-xs\:rounded-br-sm {
    border-bottom-right-radius: 0.125rem !important;
  }

  .gt-xs\:rounded-bl-sm {
    border-bottom-left-radius: 0.125rem !important;
  }

  .gt-xs\:rounded-tl {
    border-top-left-radius: 0.25rem !important;
  }

  .gt-xs\:rounded-tr {
    border-top-right-radius: 0.25rem !important;
  }

  .gt-xs\:rounded-br {
    border-bottom-right-radius: 0.25rem !important;
  }

  .gt-xs\:rounded-bl {
    border-bottom-left-radius: 0.25rem !important;
  }

  .gt-xs\:rounded-tl-md {
    border-top-left-radius: 0.375rem !important;
  }

  .gt-xs\:rounded-tr-md {
    border-top-right-radius: 0.375rem !important;
  }

  .gt-xs\:rounded-br-md {
    border-bottom-right-radius: 0.375rem !important;
  }

  .gt-xs\:rounded-bl-md {
    border-bottom-left-radius: 0.375rem !important;
  }

  .gt-xs\:rounded-tl-lg {
    border-top-left-radius: 0.5rem !important;
  }

  .gt-xs\:rounded-tr-lg {
    border-top-right-radius: 0.5rem !important;
  }

  .gt-xs\:rounded-br-lg {
    border-bottom-right-radius: 0.5rem !important;
  }

  .gt-xs\:rounded-bl-lg {
    border-bottom-left-radius: 0.5rem !important;
  }

  .gt-xs\:rounded-tl-full {
    border-top-left-radius: 9999px !important;
  }

  .gt-xs\:rounded-tr-full {
    border-top-right-radius: 9999px !important;
  }

  .gt-xs\:rounded-br-full {
    border-bottom-right-radius: 9999px !important;
  }

  .gt-xs\:rounded-bl-full {
    border-bottom-left-radius: 9999px !important;
  }

  .gt-xs\:border-solid {
    border-style: solid !important;
  }

  .gt-xs\:border-dashed {
    border-style: dashed !important;
  }

  .gt-xs\:border-dotted {
    border-style: dotted !important;
  }

  .gt-xs\:border-double {
    border-style: double !important;
  }

  .gt-xs\:border-none {
    border-style: none !important;
  }

  .gt-xs\:border-0 {
    border-width: 0 !important;
  }

  .gt-xs\:border-2 {
    border-width: 2px !important;
  }

  .gt-xs\:border-4 {
    border-width: 4px !important;
  }

  .gt-xs\:border-8 {
    border-width: 8px !important;
  }

  .gt-xs\:border {
    border-width: 1px !important;
  }

  .gt-xs\:border-t-0 {
    border-top-width: 0 !important;
  }

  .gt-xs\:border-r-0 {
    border-right-width: 0 !important;
  }

  .gt-xs\:border-b-0 {
    border-bottom-width: 0 !important;
  }

  .gt-xs\:border-l-0 {
    border-left-width: 0 !important;
  }

  .gt-xs\:border-t-2 {
    border-top-width: 2px !important;
  }

  .gt-xs\:border-r-2 {
    border-right-width: 2px !important;
  }

  .gt-xs\:border-b-2 {
    border-bottom-width: 2px !important;
  }

  .gt-xs\:border-l-2 {
    border-left-width: 2px !important;
  }

  .gt-xs\:border-t-4 {
    border-top-width: 4px !important;
  }

  .gt-xs\:border-r-4 {
    border-right-width: 4px !important;
  }

  .gt-xs\:border-b-4 {
    border-bottom-width: 4px !important;
  }

  .gt-xs\:border-l-4 {
    border-left-width: 4px !important;
  }

  .gt-xs\:border-t-8 {
    border-top-width: 8px !important;
  }

  .gt-xs\:border-r-8 {
    border-right-width: 8px !important;
  }

  .gt-xs\:border-b-8 {
    border-bottom-width: 8px !important;
  }

  .gt-xs\:border-l-8 {
    border-left-width: 8px !important;
  }

  .gt-xs\:border-t {
    border-top-width: 1px !important;
  }

  .gt-xs\:border-r {
    border-right-width: 1px !important;
  }

  .gt-xs\:border-b {
    border-bottom-width: 1px !important;
  }

  .gt-xs\:border-l {
    border-left-width: 1px !important;
  }

  .gt-xs\:first\:border-0:first-child {
    border-width: 0 !important;
  }

  .gt-xs\:first\:border-2:first-child {
    border-width: 2px !important;
  }

  .gt-xs\:first\:border-4:first-child {
    border-width: 4px !important;
  }

  .gt-xs\:first\:border-8:first-child {
    border-width: 8px !important;
  }

  .gt-xs\:first\:border:first-child {
    border-width: 1px !important;
  }

  .gt-xs\:first\:border-t-0:first-child {
    border-top-width: 0 !important;
  }

  .gt-xs\:first\:border-r-0:first-child {
    border-right-width: 0 !important;
  }

  .gt-xs\:first\:border-b-0:first-child {
    border-bottom-width: 0 !important;
  }

  .gt-xs\:first\:border-l-0:first-child {
    border-left-width: 0 !important;
  }

  .gt-xs\:first\:border-t-2:first-child {
    border-top-width: 2px !important;
  }

  .gt-xs\:first\:border-r-2:first-child {
    border-right-width: 2px !important;
  }

  .gt-xs\:first\:border-b-2:first-child {
    border-bottom-width: 2px !important;
  }

  .gt-xs\:first\:border-l-2:first-child {
    border-left-width: 2px !important;
  }

  .gt-xs\:first\:border-t-4:first-child {
    border-top-width: 4px !important;
  }

  .gt-xs\:first\:border-r-4:first-child {
    border-right-width: 4px !important;
  }

  .gt-xs\:first\:border-b-4:first-child {
    border-bottom-width: 4px !important;
  }

  .gt-xs\:first\:border-l-4:first-child {
    border-left-width: 4px !important;
  }

  .gt-xs\:first\:border-t-8:first-child {
    border-top-width: 8px !important;
  }

  .gt-xs\:first\:border-r-8:first-child {
    border-right-width: 8px !important;
  }

  .gt-xs\:first\:border-b-8:first-child {
    border-bottom-width: 8px !important;
  }

  .gt-xs\:first\:border-l-8:first-child {
    border-left-width: 8px !important;
  }

  .gt-xs\:first\:border-t:first-child {
    border-top-width: 1px !important;
  }

  .gt-xs\:first\:border-r:first-child {
    border-right-width: 1px !important;
  }

  .gt-xs\:first\:border-b:first-child {
    border-bottom-width: 1px !important;
  }

  .gt-xs\:first\:border-l:first-child {
    border-left-width: 1px !important;
  }

  .gt-xs\:last\:border-0:last-child {
    border-width: 0 !important;
  }

  .gt-xs\:last\:border-2:last-child {
    border-width: 2px !important;
  }

  .gt-xs\:last\:border-4:last-child {
    border-width: 4px !important;
  }

  .gt-xs\:last\:border-8:last-child {
    border-width: 8px !important;
  }

  .gt-xs\:last\:border:last-child {
    border-width: 1px !important;
  }

  .gt-xs\:last\:border-t-0:last-child {
    border-top-width: 0 !important;
  }

  .gt-xs\:last\:border-r-0:last-child {
    border-right-width: 0 !important;
  }

  .gt-xs\:last\:border-b-0:last-child {
    border-bottom-width: 0 !important;
  }

  .gt-xs\:last\:border-l-0:last-child {
    border-left-width: 0 !important;
  }

  .gt-xs\:last\:border-t-2:last-child {
    border-top-width: 2px !important;
  }

  .gt-xs\:last\:border-r-2:last-child {
    border-right-width: 2px !important;
  }

  .gt-xs\:last\:border-b-2:last-child {
    border-bottom-width: 2px !important;
  }

  .gt-xs\:last\:border-l-2:last-child {
    border-left-width: 2px !important;
  }

  .gt-xs\:last\:border-t-4:last-child {
    border-top-width: 4px !important;
  }

  .gt-xs\:last\:border-r-4:last-child {
    border-right-width: 4px !important;
  }

  .gt-xs\:last\:border-b-4:last-child {
    border-bottom-width: 4px !important;
  }

  .gt-xs\:last\:border-l-4:last-child {
    border-left-width: 4px !important;
  }

  .gt-xs\:last\:border-t-8:last-child {
    border-top-width: 8px !important;
  }

  .gt-xs\:last\:border-r-8:last-child {
    border-right-width: 8px !important;
  }

  .gt-xs\:last\:border-b-8:last-child {
    border-bottom-width: 8px !important;
  }

  .gt-xs\:last\:border-l-8:last-child {
    border-left-width: 8px !important;
  }

  .gt-xs\:last\:border-t:last-child {
    border-top-width: 1px !important;
  }

  .gt-xs\:last\:border-r:last-child {
    border-right-width: 1px !important;
  }

  .gt-xs\:last\:border-b:last-child {
    border-bottom-width: 1px !important;
  }

  .gt-xs\:last\:border-l:last-child {
    border-left-width: 1px !important;
  }

  .gt-xs\:box-border {
    box-sizing: border-box !important;
  }

  .gt-xs\:box-content {
    box-sizing: content-box !important;
  }

  .gt-xs\:block {
    display: block !important;
  }

  .gt-xs\:inline-block {
    display: inline-block !important;
  }

  .gt-xs\:inline {
    display: inline !important;
  }

  .gt-xs\:flex {
    display: flex !important;
  }

  .gt-xs\:inline-flex {
    display: inline-flex !important;
  }

  .gt-xs\:table {
    display: table !important;
  }

  .gt-xs\:table-caption {
    display: table-caption !important;
  }

  .gt-xs\:table-cell {
    display: table-cell !important;
  }

  .gt-xs\:table-column {
    display: table-column !important;
  }

  .gt-xs\:table-column-group {
    display: table-column-group !important;
  }

  .gt-xs\:table-footer-group {
    display: table-footer-group !important;
  }

  .gt-xs\:table-header-group {
    display: table-header-group !important;
  }

  .gt-xs\:table-row-group {
    display: table-row-group !important;
  }

  .gt-xs\:table-row {
    display: table-row !important;
  }

  .gt-xs\:flow-root {
    display: flow-root !important;
  }

  .gt-xs\:grid {
    display: grid !important;
  }

  .gt-xs\:inline-grid {
    display: inline-grid !important;
  }

  .gt-xs\:hidden {
    display: none !important;
  }

  .gt-xs\:flex-row {
    flex-direction: row !important;
  }

  .gt-xs\:flex-row-reverse {
    flex-direction: row-reverse !important;
  }

  .gt-xs\:flex-col {
    flex-direction: column !important;
  }

  .gt-xs\:flex-col-reverse {
    flex-direction: column-reverse !important;
  }

  .gt-xs\:flex-wrap {
    flex-wrap: wrap !important;
  }

  .gt-xs\:flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gt-xs\:flex-nowrap {
    flex-wrap: nowrap !important;
  }

  .gt-xs\:items-start {
    align-items: flex-start !important;
  }

  .gt-xs\:items-end {
    align-items: flex-end !important;
  }

  .gt-xs\:items-center {
    align-items: center !important;
  }

  .gt-xs\:items-baseline {
    align-items: baseline !important;
  }

  .gt-xs\:items-stretch {
    align-items: stretch !important;
  }

  .gt-xs\:self-auto {
    align-self: auto !important;
  }

  .gt-xs\:self-start {
    align-self: flex-start !important;
  }

  .gt-xs\:self-end {
    align-self: flex-end !important;
  }

  .gt-xs\:self-center {
    align-self: center !important;
  }

  .gt-xs\:self-stretch {
    align-self: stretch !important;
  }

  .gt-xs\:justify-start {
    justify-content: flex-start !important;
  }

  .gt-xs\:justify-end {
    justify-content: flex-end !important;
  }

  .gt-xs\:justify-center {
    justify-content: center !important;
  }

  .gt-xs\:justify-between {
    justify-content: space-between !important;
  }

  .gt-xs\:justify-around {
    justify-content: space-around !important;
  }

  .gt-xs\:justify-evenly {
    justify-content: space-evenly !important;
  }

  .gt-xs\:content-center {
    align-content: center !important;
  }

  .gt-xs\:content-start {
    align-content: flex-start !important;
  }

  .gt-xs\:content-end {
    align-content: flex-end !important;
  }

  .gt-xs\:content-between {
    align-content: space-between !important;
  }

  .gt-xs\:content-around {
    align-content: space-around !important;
  }

  .gt-xs\:flex-0 {
    flex: 0 0 auto !important;
  }

  .gt-xs\:flex-1 {
    flex: 1 1 0% !important;
  }

  .gt-xs\:flex-auto {
    flex: 1 1 auto !important;
  }

  .gt-xs\:flex-initial {
    flex: 0 1 auto !important;
  }

  .gt-xs\:flex-none {
    flex: none !important;
  }

  .gt-xs\:flex-grow-0 {
    flex-grow: 0 !important;
  }

  .gt-xs\:flex-grow {
    flex-grow: 1 !important;
  }

  .gt-xs\:flex-shrink-0 {
    flex-shrink: 0 !important;
  }

  .gt-xs\:flex-shrink {
    flex-shrink: 1 !important;
  }

  .gt-xs\:order-1 {
    order: 1 !important;
  }

  .gt-xs\:order-2 {
    order: 2 !important;
  }

  .gt-xs\:order-3 {
    order: 3 !important;
  }

  .gt-xs\:order-4 {
    order: 4 !important;
  }

  .gt-xs\:order-5 {
    order: 5 !important;
  }

  .gt-xs\:order-6 {
    order: 6 !important;
  }

  .gt-xs\:order-7 {
    order: 7 !important;
  }

  .gt-xs\:order-8 {
    order: 8 !important;
  }

  .gt-xs\:order-9 {
    order: 9 !important;
  }

  .gt-xs\:order-10 {
    order: 10 !important;
  }

  .gt-xs\:order-11 {
    order: 11 !important;
  }

  .gt-xs\:order-12 {
    order: 12 !important;
  }

  .gt-xs\:order-first {
    order: -9999 !important;
  }

  .gt-xs\:order-last {
    order: 9999 !important;
  }

  .gt-xs\:order-none {
    order: 0 !important;
  }

  .gt-xs\:font-thin {
    font-weight: 100 !important;
  }

  .gt-xs\:font-extralight {
    font-weight: 200 !important;
  }

  .gt-xs\:font-light {
    font-weight: 300 !important;
  }

  .gt-xs\:font-normal {
    font-weight: 400 !important;
  }

  .gt-xs\:font-medium {
    font-weight: 500 !important;
  }

  .gt-xs\:font-semibold {
    font-weight: 600 !important;
  }

  .gt-xs\:font-bold {
    font-weight: 700 !important;
  }

  .gt-xs\:font-extrabold {
    font-weight: 800 !important;
  }

  .gt-xs\:font-black {
    font-weight: 900 !important;
  }

  .gt-xs\:h-0 {
    height: 0 !important;
  }

  .gt-xs\:h-1 {
    height: 0.25rem !important;
  }

  .gt-xs\:h-2 {
    height: 0.5rem !important;
  }

  .gt-xs\:h-3 {
    height: 0.75rem !important;
  }

  .gt-xs\:h-4 {
    height: 1rem !important;
  }

  .gt-xs\:h-5 {
    height: 1.25rem !important;
  }

  .gt-xs\:h-6 {
    height: 1.5rem !important;
  }

  .gt-xs\:h-8 {
    height: 2rem !important;
  }

  .gt-xs\:h-10 {
    height: 2.5rem !important;
  }

  .gt-xs\:h-12 {
    height: 3rem !important;
  }

  .gt-xs\:h-14 {
    height: 3.5rem !important;
  }

  .gt-xs\:h-16 {
    height: 4rem !important;
  }

  .gt-xs\:h-18 {
    height: 4.5rem !important;
  }

  .gt-xs\:h-20 {
    height: 5rem !important;
  }

  .gt-xs\:h-22 {
    height: 5.5rem !important;
  }

  .gt-xs\:h-24 {
    height: 6rem !important;
  }

  .gt-xs\:h-26 {
    height: 6.5rem !important;
  }

  .gt-xs\:h-28 {
    height: 7rem !important;
  }

  .gt-xs\:h-30 {
    height: 7.5rem !important;
  }

  .gt-xs\:h-32 {
    height: 8rem !important;
  }

  .gt-xs\:h-36 {
    height: 9rem !important;
  }

  .gt-xs\:h-40 {
    height: 10rem !important;
  }

  .gt-xs\:h-48 {
    height: 12rem !important;
  }

  .gt-xs\:h-50 {
    height: 12.5rem !important;
  }

  .gt-xs\:h-56 {
    height: 14rem !important;
  }

  .gt-xs\:h-60 {
    height: 15rem !important;
  }

  .gt-xs\:h-64 {
    height: 16rem !important;
  }

  .gt-xs\:h-80 {
    height: 20rem !important;
  }

  .gt-xs\:h-90 {
    height: 24rem !important;
  }

  .gt-xs\:h-100 {
    height: 25rem !important;
  }

  .gt-xs\:h-120 {
    height: 30rem !important;
  }

  .gt-xs\:h-128 {
    height: 32rem !important;
  }

  .gt-xs\:h-140 {
    height: 35rem !important;
  }

  .gt-xs\:h-160 {
    height: 40rem !important;
  }

  .gt-xs\:h-180 {
    height: 45rem !important;
  }

  .gt-xs\:h-192 {
    height: 48rem !important;
  }

  .gt-xs\:h-200 {
    height: 50rem !important;
  }

  .gt-xs\:h-240 {
    height: 60rem !important;
  }

  .gt-xs\:h-256 {
    height: 64rem !important;
  }

  .gt-xs\:h-280 {
    height: 70rem !important;
  }

  .gt-xs\:h-320 {
    height: 80rem !important;
  }

  .gt-xs\:h-360 {
    height: 90rem !important;
  }

  .gt-xs\:h-400 {
    height: 100rem !important;
  }

  .gt-xs\:h-480 {
    height: 120rem !important;
  }

  .gt-xs\:h-auto {
    height: auto !important;
  }

  .gt-xs\:h-px {
    height: 1px !important;
  }

  .gt-xs\:h-2px {
    height: 2px !important;
  }

  .gt-xs\:h-full {
    height: 100% !important;
  }

  .gt-xs\:h-screen {
    height: 100vh !important;
  }

  .gt-xs\:h-1\/2 {
    height: 50% !important;
  }

  .gt-xs\:h-1\/3 {
    height: 33.33333% !important;
  }

  .gt-xs\:h-2\/3 {
    height: 66.66667% !important;
  }

  .gt-xs\:h-1\/4 {
    height: 25% !important;
  }

  .gt-xs\:h-2\/4 {
    height: 50% !important;
  }

  .gt-xs\:h-3\/4 {
    height: 75% !important;
  }

  .gt-xs\:h-1\/5 {
    height: 20% !important;
  }

  .gt-xs\:h-2\/5 {
    height: 40% !important;
  }

  .gt-xs\:h-3\/5 {
    height: 60% !important;
  }

  .gt-xs\:h-4\/5 {
    height: 80% !important;
  }

  .gt-xs\:h-1\/12 {
    height: 8.33333% !important;
  }

  .gt-xs\:h-2\/12 {
    height: 16.66667% !important;
  }

  .gt-xs\:h-3\/12 {
    height: 25% !important;
  }

  .gt-xs\:h-4\/12 {
    height: 33.33333% !important;
  }

  .gt-xs\:h-5\/12 {
    height: 41.66667% !important;
  }

  .gt-xs\:h-6\/12 {
    height: 50% !important;
  }

  .gt-xs\:h-7\/12 {
    height: 58.33333% !important;
  }

  .gt-xs\:h-8\/12 {
    height: 66.66667% !important;
  }

  .gt-xs\:h-9\/12 {
    height: 75% !important;
  }

  .gt-xs\:h-10\/12 {
    height: 83.33333% !important;
  }

  .gt-xs\:h-11\/12 {
    height: 91.66667% !important;
  }

  .gt-xs\:text-xs {
    font-size: 0.625rem !important;
  }

  .gt-xs\:text-sm {
    font-size: 0.75rem !important;
  }

  .gt-xs\:text-md {
    font-size: 0.8125rem !important;
  }

  .gt-xs\:text-base {
    font-size: 0.875rem !important;
  }

  .gt-xs\:text-lg {
    font-size: 1rem !important;
  }

  .gt-xs\:text-xl {
    font-size: 1.125rem !important;
  }

  .gt-xs\:text-2xl {
    font-size: 1.25rem !important;
  }

  .gt-xs\:text-3xl {
    font-size: 1.5rem !important;
  }

  .gt-xs\:text-4xl {
    font-size: 2rem !important;
  }

  .gt-xs\:text-5xl {
    font-size: 2.25rem !important;
  }

  .gt-xs\:text-6xl {
    font-size: 2.5rem !important;
  }

  .gt-xs\:text-7xl {
    font-size: 3rem !important;
  }

  .gt-xs\:text-8xl {
    font-size: 4rem !important;
  }

  .gt-xs\:text-9xl {
    font-size: 6rem !important;
  }

  .gt-xs\:text-10xl {
    font-size: 8rem !important;
  }

  .gt-xs\:leading-3 {
    line-height: .75rem !important;
  }

  .gt-xs\:leading-4 {
    line-height: 1rem !important;
  }

  .gt-xs\:leading-5 {
    line-height: 1.25rem !important;
  }

  .gt-xs\:leading-6 {
    line-height: 1.5rem !important;
  }

  .gt-xs\:leading-7 {
    line-height: 1.75rem !important;
  }

  .gt-xs\:leading-8 {
    line-height: 2rem !important;
  }

  .gt-xs\:leading-9 {
    line-height: 2.25rem !important;
  }

  .gt-xs\:leading-10 {
    line-height: 2.5rem !important;
  }

  .gt-xs\:leading-none {
    line-height: 1 !important;
  }

  .gt-xs\:leading-tight {
    line-height: 1.25 !important;
  }

  .gt-xs\:leading-snug {
    line-height: 1.375 !important;
  }

  .gt-xs\:leading-normal {
    line-height: 1.5 !important;
  }

  .gt-xs\:leading-relaxed {
    line-height: 1.625 !important;
  }

  .gt-xs\:leading-loose {
    line-height: 2 !important;
  }

  .gt-xs\:list-inside {
    list-style-position: inside !important;
  }

  .gt-xs\:list-outside {
    list-style-position: outside !important;
  }

  .gt-xs\:list-none {
    list-style-type: none !important;
  }

  .gt-xs\:list-disc {
    list-style-type: disc !important;
  }

  .gt-xs\:list-decimal {
    list-style-type: decimal !important;
  }

  .gt-xs\:m-0 {
    margin: 0 !important;
  }

  .gt-xs\:m-1 {
    margin: 0.25rem !important;
  }

  .gt-xs\:m-2 {
    margin: 0.5rem !important;
  }

  .gt-xs\:m-3 {
    margin: 0.75rem !important;
  }

  .gt-xs\:m-4 {
    margin: 1rem !important;
  }

  .gt-xs\:m-5 {
    margin: 1.25rem !important;
  }

  .gt-xs\:m-6 {
    margin: 1.5rem !important;
  }

  .gt-xs\:m-8 {
    margin: 2rem !important;
  }

  .gt-xs\:m-10 {
    margin: 2.5rem !important;
  }

  .gt-xs\:m-12 {
    margin: 3rem !important;
  }

  .gt-xs\:m-14 {
    margin: 3.5rem !important;
  }

  .gt-xs\:m-16 {
    margin: 4rem !important;
  }

  .gt-xs\:m-18 {
    margin: 4.5rem !important;
  }

  .gt-xs\:m-20 {
    margin: 5rem !important;
  }

  .gt-xs\:m-22 {
    margin: 5.5rem !important;
  }

  .gt-xs\:m-24 {
    margin: 6rem !important;
  }

  .gt-xs\:m-26 {
    margin: 6.5rem !important;
  }

  .gt-xs\:m-28 {
    margin: 7rem !important;
  }

  .gt-xs\:m-30 {
    margin: 7.5rem !important;
  }

  .gt-xs\:m-32 {
    margin: 8rem !important;
  }

  .gt-xs\:m-36 {
    margin: 9rem !important;
  }

  .gt-xs\:m-40 {
    margin: 10rem !important;
  }

  .gt-xs\:m-48 {
    margin: 12rem !important;
  }

  .gt-xs\:m-56 {
    margin: 14rem !important;
  }

  .gt-xs\:m-64 {
    margin: 16rem !important;
  }

  .gt-xs\:m-auto {
    margin: auto !important;
  }

  .gt-xs\:m-px {
    margin: 1px !important;
  }

  .gt-xs\:m-2px {
    margin: 2px !important;
  }

  .gt-xs\:-m-1 {
    margin: -0.25rem !important;
  }

  .gt-xs\:-m-2 {
    margin: -0.5rem !important;
  }

  .gt-xs\:-m-3 {
    margin: -0.75rem !important;
  }

  .gt-xs\:-m-4 {
    margin: -1rem !important;
  }

  .gt-xs\:-m-5 {
    margin: -1.25rem !important;
  }

  .gt-xs\:-m-6 {
    margin: -1.5rem !important;
  }

  .gt-xs\:-m-8 {
    margin: -2rem !important;
  }

  .gt-xs\:-m-10 {
    margin: -2.5rem !important;
  }

  .gt-xs\:-m-12 {
    margin: -3rem !important;
  }

  .gt-xs\:-m-14 {
    margin: -3.5rem !important;
  }

  .gt-xs\:-m-16 {
    margin: -4rem !important;
  }

  .gt-xs\:-m-18 {
    margin: -4.5rem !important;
  }

  .gt-xs\:-m-20 {
    margin: -5rem !important;
  }

  .gt-xs\:-m-22 {
    margin: -5.5rem !important;
  }

  .gt-xs\:-m-24 {
    margin: -6rem !important;
  }

  .gt-xs\:-m-26 {
    margin: -6.5rem !important;
  }

  .gt-xs\:-m-28 {
    margin: -7rem !important;
  }

  .gt-xs\:-m-30 {
    margin: -7.5rem !important;
  }

  .gt-xs\:-m-32 {
    margin: -8rem !important;
  }

  .gt-xs\:-m-36 {
    margin: -9rem !important;
  }

  .gt-xs\:-m-40 {
    margin: -10rem !important;
  }

  .gt-xs\:-m-48 {
    margin: -12rem !important;
  }

  .gt-xs\:-m-56 {
    margin: -14rem !important;
  }

  .gt-xs\:-m-64 {
    margin: -16rem !important;
  }

  .gt-xs\:-m-px {
    margin: -1px !important;
  }

  .gt-xs\:-m-2px {
    margin: -2px !important;
  }

  .gt-xs\:my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .gt-xs\:mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .gt-xs\:my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .gt-xs\:mx-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }

  .gt-xs\:my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .gt-xs\:mx-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .gt-xs\:my-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .gt-xs\:mx-3 {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
  }

  .gt-xs\:my-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .gt-xs\:mx-4 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .gt-xs\:my-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .gt-xs\:mx-5 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }

  .gt-xs\:my-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .gt-xs\:mx-6 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .gt-xs\:my-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .gt-xs\:mx-8 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .gt-xs\:my-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .gt-xs\:mx-10 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .gt-xs\:my-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .gt-xs\:mx-12 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .gt-xs\:my-14 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .gt-xs\:mx-14 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }

  .gt-xs\:my-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .gt-xs\:mx-16 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .gt-xs\:my-18 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .gt-xs\:mx-18 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }

  .gt-xs\:my-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .gt-xs\:mx-20 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .gt-xs\:my-22 {
    margin-top: 5.5rem !important;
    margin-bottom: 5.5rem !important;
  }

  .gt-xs\:mx-22 {
    margin-left: 5.5rem !important;
    margin-right: 5.5rem !important;
  }

  .gt-xs\:my-24 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .gt-xs\:mx-24 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }

  .gt-xs\:my-26 {
    margin-top: 6.5rem !important;
    margin-bottom: 6.5rem !important;
  }

  .gt-xs\:mx-26 {
    margin-left: 6.5rem !important;
    margin-right: 6.5rem !important;
  }

  .gt-xs\:my-28 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }

  .gt-xs\:mx-28 {
    margin-left: 7rem !important;
    margin-right: 7rem !important;
  }

  .gt-xs\:my-30 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }

  .gt-xs\:mx-30 {
    margin-left: 7.5rem !important;
    margin-right: 7.5rem !important;
  }

  .gt-xs\:my-32 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .gt-xs\:mx-32 {
    margin-left: 8rem !important;
    margin-right: 8rem !important;
  }

  .gt-xs\:my-36 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }

  .gt-xs\:mx-36 {
    margin-left: 9rem !important;
    margin-right: 9rem !important;
  }

  .gt-xs\:my-40 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .gt-xs\:mx-40 {
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }

  .gt-xs\:my-48 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }

  .gt-xs\:mx-48 {
    margin-left: 12rem !important;
    margin-right: 12rem !important;
  }

  .gt-xs\:my-56 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }

  .gt-xs\:mx-56 {
    margin-left: 14rem !important;
    margin-right: 14rem !important;
  }

  .gt-xs\:my-64 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }

  .gt-xs\:mx-64 {
    margin-left: 16rem !important;
    margin-right: 16rem !important;
  }

  .gt-xs\:my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .gt-xs\:mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .gt-xs\:my-px {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  .gt-xs\:mx-px {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }

  .gt-xs\:my-2px {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }

  .gt-xs\:mx-2px {
    margin-left: 2px !important;
    margin-right: 2px !important;
  }

  .gt-xs\:-my-1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .gt-xs\:-mx-1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }

  .gt-xs\:-my-2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .gt-xs\:-mx-2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }

  .gt-xs\:-my-3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }

  .gt-xs\:-mx-3 {
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important;
  }

  .gt-xs\:-my-4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .gt-xs\:-mx-4 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .gt-xs\:-my-5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .gt-xs\:-mx-5 {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }

  .gt-xs\:-my-6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .gt-xs\:-mx-6 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .gt-xs\:-my-8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .gt-xs\:-mx-8 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .gt-xs\:-my-10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .gt-xs\:-mx-10 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }

  .gt-xs\:-my-12 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .gt-xs\:-mx-12 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .gt-xs\:-my-14 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .gt-xs\:-mx-14 {
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }

  .gt-xs\:-my-16 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .gt-xs\:-mx-16 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .gt-xs\:-my-18 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .gt-xs\:-mx-18 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }

  .gt-xs\:-my-20 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .gt-xs\:-mx-20 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .gt-xs\:-my-22 {
    margin-top: -5.5rem !important;
    margin-bottom: -5.5rem !important;
  }

  .gt-xs\:-mx-22 {
    margin-left: -5.5rem !important;
    margin-right: -5.5rem !important;
  }

  .gt-xs\:-my-24 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .gt-xs\:-mx-24 {
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }

  .gt-xs\:-my-26 {
    margin-top: -6.5rem !important;
    margin-bottom: -6.5rem !important;
  }

  .gt-xs\:-mx-26 {
    margin-left: -6.5rem !important;
    margin-right: -6.5rem !important;
  }

  .gt-xs\:-my-28 {
    margin-top: -7rem !important;
    margin-bottom: -7rem !important;
  }

  .gt-xs\:-mx-28 {
    margin-left: -7rem !important;
    margin-right: -7rem !important;
  }

  .gt-xs\:-my-30 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important;
  }

  .gt-xs\:-mx-30 {
    margin-left: -7.5rem !important;
    margin-right: -7.5rem !important;
  }

  .gt-xs\:-my-32 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .gt-xs\:-mx-32 {
    margin-left: -8rem !important;
    margin-right: -8rem !important;
  }

  .gt-xs\:-my-36 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }

  .gt-xs\:-mx-36 {
    margin-left: -9rem !important;
    margin-right: -9rem !important;
  }

  .gt-xs\:-my-40 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .gt-xs\:-mx-40 {
    margin-left: -10rem !important;
    margin-right: -10rem !important;
  }

  .gt-xs\:-my-48 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }

  .gt-xs\:-mx-48 {
    margin-left: -12rem !important;
    margin-right: -12rem !important;
  }

  .gt-xs\:-my-56 {
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }

  .gt-xs\:-mx-56 {
    margin-left: -14rem !important;
    margin-right: -14rem !important;
  }

  .gt-xs\:-my-64 {
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }

  .gt-xs\:-mx-64 {
    margin-left: -16rem !important;
    margin-right: -16rem !important;
  }

  .gt-xs\:-my-px {
    margin-top: -1px !important;
    margin-bottom: -1px !important;
  }

  .gt-xs\:-mx-px {
    margin-left: -1px !important;
    margin-right: -1px !important;
  }

  .gt-xs\:-my-2px {
    margin-top: -2px !important;
    margin-bottom: -2px !important;
  }

  .gt-xs\:-mx-2px {
    margin-left: -2px !important;
    margin-right: -2px !important;
  }

  .gt-xs\:mt-0 {
    margin-top: 0 !important;
  }

  .gt-xs\:mr-0 {
    margin-right: 0 !important;
  }

  .gt-xs\:mb-0 {
    margin-bottom: 0 !important;
  }

  .gt-xs\:ml-0 {
    margin-left: 0 !important;
  }

  .gt-xs\:mt-1 {
    margin-top: 0.25rem !important;
  }

  .gt-xs\:mr-1 {
    margin-right: 0.25rem !important;
  }

  .gt-xs\:mb-1 {
    margin-bottom: 0.25rem !important;
  }

  .gt-xs\:ml-1 {
    margin-left: 0.25rem !important;
  }

  .gt-xs\:mt-2 {
    margin-top: 0.5rem !important;
  }

  .gt-xs\:mr-2 {
    margin-right: 0.5rem !important;
  }

  .gt-xs\:mb-2 {
    margin-bottom: 0.5rem !important;
  }

  .gt-xs\:ml-2 {
    margin-left: 0.5rem !important;
  }

  .gt-xs\:mt-3 {
    margin-top: 0.75rem !important;
  }

  .gt-xs\:mr-3 {
    margin-right: 0.75rem !important;
  }

  .gt-xs\:mb-3 {
    margin-bottom: 0.75rem !important;
  }

  .gt-xs\:ml-3 {
    margin-left: 0.75rem !important;
  }

  .gt-xs\:mt-4 {
    margin-top: 1rem !important;
  }

  .gt-xs\:mr-4 {
    margin-right: 1rem !important;
  }

  .gt-xs\:mb-4 {
    margin-bottom: 1rem !important;
  }

  .gt-xs\:ml-4 {
    margin-left: 1rem !important;
  }

  .gt-xs\:mt-5 {
    margin-top: 1.25rem !important;
  }

  .gt-xs\:mr-5 {
    margin-right: 1.25rem !important;
  }

  .gt-xs\:mb-5 {
    margin-bottom: 1.25rem !important;
  }

  .gt-xs\:ml-5 {
    margin-left: 1.25rem !important;
  }

  .gt-xs\:mt-6 {
    margin-top: 1.5rem !important;
  }

  .gt-xs\:mr-6 {
    margin-right: 1.5rem !important;
  }

  .gt-xs\:mb-6 {
    margin-bottom: 1.5rem !important;
  }

  .gt-xs\:ml-6 {
    margin-left: 1.5rem !important;
  }

  .gt-xs\:mt-8 {
    margin-top: 2rem !important;
  }

  .gt-xs\:mr-8 {
    margin-right: 2rem !important;
  }

  .gt-xs\:mb-8 {
    margin-bottom: 2rem !important;
  }

  .gt-xs\:ml-8 {
    margin-left: 2rem !important;
  }

  .gt-xs\:mt-10 {
    margin-top: 2.5rem !important;
  }

  .gt-xs\:mr-10 {
    margin-right: 2.5rem !important;
  }

  .gt-xs\:mb-10 {
    margin-bottom: 2.5rem !important;
  }

  .gt-xs\:ml-10 {
    margin-left: 2.5rem !important;
  }

  .gt-xs\:mt-12 {
    margin-top: 3rem !important;
  }

  .gt-xs\:mr-12 {
    margin-right: 3rem !important;
  }

  .gt-xs\:mb-12 {
    margin-bottom: 3rem !important;
  }

  .gt-xs\:ml-12 {
    margin-left: 3rem !important;
  }

  .gt-xs\:mt-14 {
    margin-top: 3.5rem !important;
  }

  .gt-xs\:mr-14 {
    margin-right: 3.5rem !important;
  }

  .gt-xs\:mb-14 {
    margin-bottom: 3.5rem !important;
  }

  .gt-xs\:ml-14 {
    margin-left: 3.5rem !important;
  }

  .gt-xs\:mt-16 {
    margin-top: 4rem !important;
  }

  .gt-xs\:mr-16 {
    margin-right: 4rem !important;
  }

  .gt-xs\:mb-16 {
    margin-bottom: 4rem !important;
  }

  .gt-xs\:ml-16 {
    margin-left: 4rem !important;
  }

  .gt-xs\:mt-18 {
    margin-top: 4.5rem !important;
  }

  .gt-xs\:mr-18 {
    margin-right: 4.5rem !important;
  }

  .gt-xs\:mb-18 {
    margin-bottom: 4.5rem !important;
  }

  .gt-xs\:ml-18 {
    margin-left: 4.5rem !important;
  }

  .gt-xs\:mt-20 {
    margin-top: 5rem !important;
  }

  .gt-xs\:mr-20 {
    margin-right: 5rem !important;
  }

  .gt-xs\:mb-20 {
    margin-bottom: 5rem !important;
  }

  .gt-xs\:ml-20 {
    margin-left: 5rem !important;
  }

  .gt-xs\:mt-22 {
    margin-top: 5.5rem !important;
  }

  .gt-xs\:mr-22 {
    margin-right: 5.5rem !important;
  }

  .gt-xs\:mb-22 {
    margin-bottom: 5.5rem !important;
  }

  .gt-xs\:ml-22 {
    margin-left: 5.5rem !important;
  }

  .gt-xs\:mt-24 {
    margin-top: 6rem !important;
  }

  .gt-xs\:mr-24 {
    margin-right: 6rem !important;
  }

  .gt-xs\:mb-24 {
    margin-bottom: 6rem !important;
  }

  .gt-xs\:ml-24 {
    margin-left: 6rem !important;
  }

  .gt-xs\:mt-26 {
    margin-top: 6.5rem !important;
  }

  .gt-xs\:mr-26 {
    margin-right: 6.5rem !important;
  }

  .gt-xs\:mb-26 {
    margin-bottom: 6.5rem !important;
  }

  .gt-xs\:ml-26 {
    margin-left: 6.5rem !important;
  }

  .gt-xs\:mt-28 {
    margin-top: 7rem !important;
  }

  .gt-xs\:mr-28 {
    margin-right: 7rem !important;
  }

  .gt-xs\:mb-28 {
    margin-bottom: 7rem !important;
  }

  .gt-xs\:ml-28 {
    margin-left: 7rem !important;
  }

  .gt-xs\:mt-30 {
    margin-top: 7.5rem !important;
  }

  .gt-xs\:mr-30 {
    margin-right: 7.5rem !important;
  }

  .gt-xs\:mb-30 {
    margin-bottom: 7.5rem !important;
  }

  .gt-xs\:ml-30 {
    margin-left: 7.5rem !important;
  }

  .gt-xs\:mt-32 {
    margin-top: 8rem !important;
  }

  .gt-xs\:mr-32 {
    margin-right: 8rem !important;
  }

  .gt-xs\:mb-32 {
    margin-bottom: 8rem !important;
  }

  .gt-xs\:ml-32 {
    margin-left: 8rem !important;
  }

  .gt-xs\:mt-36 {
    margin-top: 9rem !important;
  }

  .gt-xs\:mr-36 {
    margin-right: 9rem !important;
  }

  .gt-xs\:mb-36 {
    margin-bottom: 9rem !important;
  }

  .gt-xs\:ml-36 {
    margin-left: 9rem !important;
  }

  .gt-xs\:mt-40 {
    margin-top: 10rem !important;
  }

  .gt-xs\:mr-40 {
    margin-right: 10rem !important;
  }

  .gt-xs\:mb-40 {
    margin-bottom: 10rem !important;
  }

  .gt-xs\:ml-40 {
    margin-left: 10rem !important;
  }

  .gt-xs\:mt-48 {
    margin-top: 12rem !important;
  }

  .gt-xs\:mr-48 {
    margin-right: 12rem !important;
  }

  .gt-xs\:mb-48 {
    margin-bottom: 12rem !important;
  }

  .gt-xs\:ml-48 {
    margin-left: 12rem !important;
  }

  .gt-xs\:mt-56 {
    margin-top: 14rem !important;
  }

  .gt-xs\:mr-56 {
    margin-right: 14rem !important;
  }

  .gt-xs\:mb-56 {
    margin-bottom: 14rem !important;
  }

  .gt-xs\:ml-56 {
    margin-left: 14rem !important;
  }

  .gt-xs\:mt-64 {
    margin-top: 16rem !important;
  }

  .gt-xs\:mr-64 {
    margin-right: 16rem !important;
  }

  .gt-xs\:mb-64 {
    margin-bottom: 16rem !important;
  }

  .gt-xs\:ml-64 {
    margin-left: 16rem !important;
  }

  .gt-xs\:mt-auto {
    margin-top: auto !important;
  }

  .gt-xs\:mr-auto {
    margin-right: auto !important;
  }

  .gt-xs\:mb-auto {
    margin-bottom: auto !important;
  }

  .gt-xs\:ml-auto {
    margin-left: auto !important;
  }

  .gt-xs\:mt-px {
    margin-top: 1px !important;
  }

  .gt-xs\:mr-px {
    margin-right: 1px !important;
  }

  .gt-xs\:mb-px {
    margin-bottom: 1px !important;
  }

  .gt-xs\:ml-px {
    margin-left: 1px !important;
  }

  .gt-xs\:mt-2px {
    margin-top: 2px !important;
  }

  .gt-xs\:mr-2px {
    margin-right: 2px !important;
  }

  .gt-xs\:mb-2px {
    margin-bottom: 2px !important;
  }

  .gt-xs\:ml-2px {
    margin-left: 2px !important;
  }

  .gt-xs\:-mt-1 {
    margin-top: -0.25rem !important;
  }

  .gt-xs\:-mr-1 {
    margin-right: -0.25rem !important;
  }

  .gt-xs\:-mb-1 {
    margin-bottom: -0.25rem !important;
  }

  .gt-xs\:-ml-1 {
    margin-left: -0.25rem !important;
  }

  .gt-xs\:-mt-2 {
    margin-top: -0.5rem !important;
  }

  .gt-xs\:-mr-2 {
    margin-right: -0.5rem !important;
  }

  .gt-xs\:-mb-2 {
    margin-bottom: -0.5rem !important;
  }

  .gt-xs\:-ml-2 {
    margin-left: -0.5rem !important;
  }

  .gt-xs\:-mt-3 {
    margin-top: -0.75rem !important;
  }

  .gt-xs\:-mr-3 {
    margin-right: -0.75rem !important;
  }

  .gt-xs\:-mb-3 {
    margin-bottom: -0.75rem !important;
  }

  .gt-xs\:-ml-3 {
    margin-left: -0.75rem !important;
  }

  .gt-xs\:-mt-4 {
    margin-top: -1rem !important;
  }

  .gt-xs\:-mr-4 {
    margin-right: -1rem !important;
  }

  .gt-xs\:-mb-4 {
    margin-bottom: -1rem !important;
  }

  .gt-xs\:-ml-4 {
    margin-left: -1rem !important;
  }

  .gt-xs\:-mt-5 {
    margin-top: -1.25rem !important;
  }

  .gt-xs\:-mr-5 {
    margin-right: -1.25rem !important;
  }

  .gt-xs\:-mb-5 {
    margin-bottom: -1.25rem !important;
  }

  .gt-xs\:-ml-5 {
    margin-left: -1.25rem !important;
  }

  .gt-xs\:-mt-6 {
    margin-top: -1.5rem !important;
  }

  .gt-xs\:-mr-6 {
    margin-right: -1.5rem !important;
  }

  .gt-xs\:-mb-6 {
    margin-bottom: -1.5rem !important;
  }

  .gt-xs\:-ml-6 {
    margin-left: -1.5rem !important;
  }

  .gt-xs\:-mt-8 {
    margin-top: -2rem !important;
  }

  .gt-xs\:-mr-8 {
    margin-right: -2rem !important;
  }

  .gt-xs\:-mb-8 {
    margin-bottom: -2rem !important;
  }

  .gt-xs\:-ml-8 {
    margin-left: -2rem !important;
  }

  .gt-xs\:-mt-10 {
    margin-top: -2.5rem !important;
  }

  .gt-xs\:-mr-10 {
    margin-right: -2.5rem !important;
  }

  .gt-xs\:-mb-10 {
    margin-bottom: -2.5rem !important;
  }

  .gt-xs\:-ml-10 {
    margin-left: -2.5rem !important;
  }

  .gt-xs\:-mt-12 {
    margin-top: -3rem !important;
  }

  .gt-xs\:-mr-12 {
    margin-right: -3rem !important;
  }

  .gt-xs\:-mb-12 {
    margin-bottom: -3rem !important;
  }

  .gt-xs\:-ml-12 {
    margin-left: -3rem !important;
  }

  .gt-xs\:-mt-14 {
    margin-top: -3.5rem !important;
  }

  .gt-xs\:-mr-14 {
    margin-right: -3.5rem !important;
  }

  .gt-xs\:-mb-14 {
    margin-bottom: -3.5rem !important;
  }

  .gt-xs\:-ml-14 {
    margin-left: -3.5rem !important;
  }

  .gt-xs\:-mt-16 {
    margin-top: -4rem !important;
  }

  .gt-xs\:-mr-16 {
    margin-right: -4rem !important;
  }

  .gt-xs\:-mb-16 {
    margin-bottom: -4rem !important;
  }

  .gt-xs\:-ml-16 {
    margin-left: -4rem !important;
  }

  .gt-xs\:-mt-18 {
    margin-top: -4.5rem !important;
  }

  .gt-xs\:-mr-18 {
    margin-right: -4.5rem !important;
  }

  .gt-xs\:-mb-18 {
    margin-bottom: -4.5rem !important;
  }

  .gt-xs\:-ml-18 {
    margin-left: -4.5rem !important;
  }

  .gt-xs\:-mt-20 {
    margin-top: -5rem !important;
  }

  .gt-xs\:-mr-20 {
    margin-right: -5rem !important;
  }

  .gt-xs\:-mb-20 {
    margin-bottom: -5rem !important;
  }

  .gt-xs\:-ml-20 {
    margin-left: -5rem !important;
  }

  .gt-xs\:-mt-22 {
    margin-top: -5.5rem !important;
  }

  .gt-xs\:-mr-22 {
    margin-right: -5.5rem !important;
  }

  .gt-xs\:-mb-22 {
    margin-bottom: -5.5rem !important;
  }

  .gt-xs\:-ml-22 {
    margin-left: -5.5rem !important;
  }

  .gt-xs\:-mt-24 {
    margin-top: -6rem !important;
  }

  .gt-xs\:-mr-24 {
    margin-right: -6rem !important;
  }

  .gt-xs\:-mb-24 {
    margin-bottom: -6rem !important;
  }

  .gt-xs\:-ml-24 {
    margin-left: -6rem !important;
  }

  .gt-xs\:-mt-26 {
    margin-top: -6.5rem !important;
  }

  .gt-xs\:-mr-26 {
    margin-right: -6.5rem !important;
  }

  .gt-xs\:-mb-26 {
    margin-bottom: -6.5rem !important;
  }

  .gt-xs\:-ml-26 {
    margin-left: -6.5rem !important;
  }

  .gt-xs\:-mt-28 {
    margin-top: -7rem !important;
  }

  .gt-xs\:-mr-28 {
    margin-right: -7rem !important;
  }

  .gt-xs\:-mb-28 {
    margin-bottom: -7rem !important;
  }

  .gt-xs\:-ml-28 {
    margin-left: -7rem !important;
  }

  .gt-xs\:-mt-30 {
    margin-top: -7.5rem !important;
  }

  .gt-xs\:-mr-30 {
    margin-right: -7.5rem !important;
  }

  .gt-xs\:-mb-30 {
    margin-bottom: -7.5rem !important;
  }

  .gt-xs\:-ml-30 {
    margin-left: -7.5rem !important;
  }

  .gt-xs\:-mt-32 {
    margin-top: -8rem !important;
  }

  .gt-xs\:-mr-32 {
    margin-right: -8rem !important;
  }

  .gt-xs\:-mb-32 {
    margin-bottom: -8rem !important;
  }

  .gt-xs\:-ml-32 {
    margin-left: -8rem !important;
  }

  .gt-xs\:-mt-36 {
    margin-top: -9rem !important;
  }

  .gt-xs\:-mr-36 {
    margin-right: -9rem !important;
  }

  .gt-xs\:-mb-36 {
    margin-bottom: -9rem !important;
  }

  .gt-xs\:-ml-36 {
    margin-left: -9rem !important;
  }

  .gt-xs\:-mt-40 {
    margin-top: -10rem !important;
  }

  .gt-xs\:-mr-40 {
    margin-right: -10rem !important;
  }

  .gt-xs\:-mb-40 {
    margin-bottom: -10rem !important;
  }

  .gt-xs\:-ml-40 {
    margin-left: -10rem !important;
  }

  .gt-xs\:-mt-48 {
    margin-top: -12rem !important;
  }

  .gt-xs\:-mr-48 {
    margin-right: -12rem !important;
  }

  .gt-xs\:-mb-48 {
    margin-bottom: -12rem !important;
  }

  .gt-xs\:-ml-48 {
    margin-left: -12rem !important;
  }

  .gt-xs\:-mt-56 {
    margin-top: -14rem !important;
  }

  .gt-xs\:-mr-56 {
    margin-right: -14rem !important;
  }

  .gt-xs\:-mb-56 {
    margin-bottom: -14rem !important;
  }

  .gt-xs\:-ml-56 {
    margin-left: -14rem !important;
  }

  .gt-xs\:-mt-64 {
    margin-top: -16rem !important;
  }

  .gt-xs\:-mr-64 {
    margin-right: -16rem !important;
  }

  .gt-xs\:-mb-64 {
    margin-bottom: -16rem !important;
  }

  .gt-xs\:-ml-64 {
    margin-left: -16rem !important;
  }

  .gt-xs\:-mt-px {
    margin-top: -1px !important;
  }

  .gt-xs\:-mr-px {
    margin-right: -1px !important;
  }

  .gt-xs\:-mb-px {
    margin-bottom: -1px !important;
  }

  .gt-xs\:-ml-px {
    margin-left: -1px !important;
  }

  .gt-xs\:-mt-2px {
    margin-top: -2px !important;
  }

  .gt-xs\:-mr-2px {
    margin-right: -2px !important;
  }

  .gt-xs\:-mb-2px {
    margin-bottom: -2px !important;
  }

  .gt-xs\:-ml-2px {
    margin-left: -2px !important;
  }

  .gt-xs\:max-h-0 {
    max-height: 0 !important;
  }

  .gt-xs\:max-h-1 {
    max-height: 0.25rem !important;
  }

  .gt-xs\:max-h-2 {
    max-height: 0.5rem !important;
  }

  .gt-xs\:max-h-3 {
    max-height: 0.75rem !important;
  }

  .gt-xs\:max-h-4 {
    max-height: 1rem !important;
  }

  .gt-xs\:max-h-5 {
    max-height: 1.25rem !important;
  }

  .gt-xs\:max-h-6 {
    max-height: 1.5rem !important;
  }

  .gt-xs\:max-h-8 {
    max-height: 2rem !important;
  }

  .gt-xs\:max-h-10 {
    max-height: 2.5rem !important;
  }

  .gt-xs\:max-h-12 {
    max-height: 3rem !important;
  }

  .gt-xs\:max-h-14 {
    max-height: 3.5rem !important;
  }

  .gt-xs\:max-h-16 {
    max-height: 4rem !important;
  }

  .gt-xs\:max-h-18 {
    max-height: 4.5rem !important;
  }

  .gt-xs\:max-h-20 {
    max-height: 5rem !important;
  }

  .gt-xs\:max-h-22 {
    max-height: 5.5rem !important;
  }

  .gt-xs\:max-h-24 {
    max-height: 6rem !important;
  }

  .gt-xs\:max-h-26 {
    max-height: 6.5rem !important;
  }

  .gt-xs\:max-h-28 {
    max-height: 7rem !important;
  }

  .gt-xs\:max-h-30 {
    max-height: 7.5rem !important;
  }

  .gt-xs\:max-h-32 {
    max-height: 8rem !important;
  }

  .gt-xs\:max-h-36 {
    max-height: 9rem !important;
  }

  .gt-xs\:max-h-40 {
    max-height: 10rem !important;
  }

  .gt-xs\:max-h-48 {
    max-height: 12rem !important;
  }

  .gt-xs\:max-h-50 {
    max-height: 12.5rem !important;
  }

  .gt-xs\:max-h-56 {
    max-height: 14rem !important;
  }

  .gt-xs\:max-h-60 {
    max-height: 15rem !important;
  }

  .gt-xs\:max-h-64 {
    max-height: 16rem !important;
  }

  .gt-xs\:max-h-80 {
    max-height: 20rem !important;
  }

  .gt-xs\:max-h-90 {
    max-height: 24rem !important;
  }

  .gt-xs\:max-h-100 {
    max-height: 25rem !important;
  }

  .gt-xs\:max-h-120 {
    max-height: 30rem !important;
  }

  .gt-xs\:max-h-128 {
    max-height: 32rem !important;
  }

  .gt-xs\:max-h-140 {
    max-height: 35rem !important;
  }

  .gt-xs\:max-h-160 {
    max-height: 40rem !important;
  }

  .gt-xs\:max-h-180 {
    max-height: 45rem !important;
  }

  .gt-xs\:max-h-192 {
    max-height: 48rem !important;
  }

  .gt-xs\:max-h-200 {
    max-height: 50rem !important;
  }

  .gt-xs\:max-h-240 {
    max-height: 60rem !important;
  }

  .gt-xs\:max-h-256 {
    max-height: 64rem !important;
  }

  .gt-xs\:max-h-280 {
    max-height: 70rem !important;
  }

  .gt-xs\:max-h-320 {
    max-height: 80rem !important;
  }

  .gt-xs\:max-h-360 {
    max-height: 90rem !important;
  }

  .gt-xs\:max-h-400 {
    max-height: 100rem !important;
  }

  .gt-xs\:max-h-480 {
    max-height: 120rem !important;
  }

  .gt-xs\:max-h-full {
    max-height: 100% !important;
  }

  .gt-xs\:max-h-screen {
    max-height: 100vh !important;
  }

  .gt-xs\:max-h-none {
    max-height: none !important;
  }

  .gt-xs\:max-h-px {
    max-height: 1px !important;
  }

  .gt-xs\:max-h-2px {
    max-height: 2px !important;
  }

  .gt-xs\:max-h-1\/2 {
    max-height: 50% !important;
  }

  .gt-xs\:max-h-1\/3 {
    max-height: 33.33333% !important;
  }

  .gt-xs\:max-h-2\/3 {
    max-height: 66.66667% !important;
  }

  .gt-xs\:max-h-1\/4 {
    max-height: 25% !important;
  }

  .gt-xs\:max-h-2\/4 {
    max-height: 50% !important;
  }

  .gt-xs\:max-h-3\/4 {
    max-height: 75% !important;
  }

  .gt-xs\:max-h-1\/5 {
    max-height: 20% !important;
  }

  .gt-xs\:max-h-2\/5 {
    max-height: 40% !important;
  }

  .gt-xs\:max-h-3\/5 {
    max-height: 60% !important;
  }

  .gt-xs\:max-h-4\/5 {
    max-height: 80% !important;
  }

  .gt-xs\:max-h-1\/12 {
    max-height: 8.33333% !important;
  }

  .gt-xs\:max-h-2\/12 {
    max-height: 16.66667% !important;
  }

  .gt-xs\:max-h-3\/12 {
    max-height: 25% !important;
  }

  .gt-xs\:max-h-4\/12 {
    max-height: 33.33333% !important;
  }

  .gt-xs\:max-h-5\/12 {
    max-height: 41.66667% !important;
  }

  .gt-xs\:max-h-6\/12 {
    max-height: 50% !important;
  }

  .gt-xs\:max-h-7\/12 {
    max-height: 58.33333% !important;
  }

  .gt-xs\:max-h-8\/12 {
    max-height: 66.66667% !important;
  }

  .gt-xs\:max-h-9\/12 {
    max-height: 75% !important;
  }

  .gt-xs\:max-h-10\/12 {
    max-height: 83.33333% !important;
  }

  .gt-xs\:max-h-11\/12 {
    max-height: 91.66667% !important;
  }

  .gt-xs\:max-w-0 {
    max-width: 0 !important;
  }

  .gt-xs\:max-w-1 {
    max-width: 0.25rem !important;
  }

  .gt-xs\:max-w-2 {
    max-width: 0.5rem !important;
  }

  .gt-xs\:max-w-3 {
    max-width: 0.75rem !important;
  }

  .gt-xs\:max-w-4 {
    max-width: 1rem !important;
  }

  .gt-xs\:max-w-5 {
    max-width: 1.25rem !important;
  }

  .gt-xs\:max-w-6 {
    max-width: 1.5rem !important;
  }

  .gt-xs\:max-w-8 {
    max-width: 2rem !important;
  }

  .gt-xs\:max-w-10 {
    max-width: 2.5rem !important;
  }

  .gt-xs\:max-w-12 {
    max-width: 3rem !important;
  }

  .gt-xs\:max-w-14 {
    max-width: 3.5rem !important;
  }

  .gt-xs\:max-w-16 {
    max-width: 4rem !important;
  }

  .gt-xs\:max-w-18 {
    max-width: 4.5rem !important;
  }

  .gt-xs\:max-w-20 {
    max-width: 5rem !important;
  }

  .gt-xs\:max-w-22 {
    max-width: 5.5rem !important;
  }

  .gt-xs\:max-w-24 {
    max-width: 6rem !important;
  }

  .gt-xs\:max-w-26 {
    max-width: 6.5rem !important;
  }

  .gt-xs\:max-w-28 {
    max-width: 7rem !important;
  }

  .gt-xs\:max-w-30 {
    max-width: 7.5rem !important;
  }

  .gt-xs\:max-w-32 {
    max-width: 8rem !important;
  }

  .gt-xs\:max-w-36 {
    max-width: 9rem !important;
  }

  .gt-xs\:max-w-40 {
    max-width: 10rem !important;
  }

  .gt-xs\:max-w-48 {
    max-width: 12rem !important;
  }

  .gt-xs\:max-w-50 {
    max-width: 12.5rem !important;
  }

  .gt-xs\:max-w-56 {
    max-width: 14rem !important;
  }

  .gt-xs\:max-w-60 {
    max-width: 15rem !important;
  }

  .gt-xs\:max-w-64 {
    max-width: 16rem !important;
  }

  .gt-xs\:max-w-80 {
    max-width: 20rem !important;
  }

  .gt-xs\:max-w-90 {
    max-width: 24rem !important;
  }

  .gt-xs\:max-w-100 {
    max-width: 25rem !important;
  }

  .gt-xs\:max-w-120 {
    max-width: 30rem !important;
  }

  .gt-xs\:max-w-128 {
    max-width: 32rem !important;
  }

  .gt-xs\:max-w-140 {
    max-width: 35rem !important;
  }

  .gt-xs\:max-w-160 {
    max-width: 40rem !important;
  }

  .gt-xs\:max-w-180 {
    max-width: 45rem !important;
  }

  .gt-xs\:max-w-192 {
    max-width: 48rem !important;
  }

  .gt-xs\:max-w-200 {
    max-width: 50rem !important;
  }

  .gt-xs\:max-w-240 {
    max-width: 60rem !important;
  }

  .gt-xs\:max-w-256 {
    max-width: 64rem !important;
  }

  .gt-xs\:max-w-280 {
    max-width: 70rem !important;
  }

  .gt-xs\:max-w-320 {
    max-width: 80rem !important;
  }

  .gt-xs\:max-w-360 {
    max-width: 90rem !important;
  }

  .gt-xs\:max-w-400 {
    max-width: 100rem !important;
  }

  .gt-xs\:max-w-480 {
    max-width: 120rem !important;
  }

  .gt-xs\:max-w-none {
    max-width: none !important;
  }

  .gt-xs\:max-w-xs {
    max-width: 20rem !important;
  }

  .gt-xs\:max-w-sm {
    max-width: 24rem !important;
  }

  .gt-xs\:max-w-md {
    max-width: 28rem !important;
  }

  .gt-xs\:max-w-lg {
    max-width: 32rem !important;
  }

  .gt-xs\:max-w-xl {
    max-width: 36rem !important;
  }

  .gt-xs\:max-w-2xl {
    max-width: 42rem !important;
  }

  .gt-xs\:max-w-3xl {
    max-width: 48rem !important;
  }

  .gt-xs\:max-w-4xl {
    max-width: 56rem !important;
  }

  .gt-xs\:max-w-5xl {
    max-width: 64rem !important;
  }

  .gt-xs\:max-w-6xl {
    max-width: 72rem !important;
  }

  .gt-xs\:max-w-full {
    max-width: 100% !important;
  }

  .gt-xs\:max-w-screen {
    max-width: 100vw !important;
  }

  .gt-xs\:max-w-px {
    max-width: 1px !important;
  }

  .gt-xs\:max-w-2px {
    max-width: 2px !important;
  }

  .gt-xs\:max-w-1\/2 {
    max-width: 50% !important;
  }

  .gt-xs\:max-w-1\/3 {
    max-width: 33.33333% !important;
  }

  .gt-xs\:max-w-2\/3 {
    max-width: 66.66667% !important;
  }

  .gt-xs\:max-w-1\/4 {
    max-width: 25% !important;
  }

  .gt-xs\:max-w-2\/4 {
    max-width: 50% !important;
  }

  .gt-xs\:max-w-3\/4 {
    max-width: 75% !important;
  }

  .gt-xs\:max-w-1\/5 {
    max-width: 20% !important;
  }

  .gt-xs\:max-w-2\/5 {
    max-width: 40% !important;
  }

  .gt-xs\:max-w-3\/5 {
    max-width: 60% !important;
  }

  .gt-xs\:max-w-4\/5 {
    max-width: 80% !important;
  }

  .gt-xs\:max-w-1\/12 {
    max-width: 8.33333% !important;
  }

  .gt-xs\:max-w-2\/12 {
    max-width: 16.66667% !important;
  }

  .gt-xs\:max-w-3\/12 {
    max-width: 25% !important;
  }

  .gt-xs\:max-w-4\/12 {
    max-width: 33.33333% !important;
  }

  .gt-xs\:max-w-5\/12 {
    max-width: 41.66667% !important;
  }

  .gt-xs\:max-w-6\/12 {
    max-width: 50% !important;
  }

  .gt-xs\:max-w-7\/12 {
    max-width: 58.33333% !important;
  }

  .gt-xs\:max-w-8\/12 {
    max-width: 66.66667% !important;
  }

  .gt-xs\:max-w-9\/12 {
    max-width: 75% !important;
  }

  .gt-xs\:max-w-10\/12 {
    max-width: 83.33333% !important;
  }

  .gt-xs\:max-w-11\/12 {
    max-width: 91.66667% !important;
  }

  .gt-xs\:min-h-0 {
    min-height: 0 !important;
  }

  .gt-xs\:min-h-1 {
    min-height: 0.25rem !important;
  }

  .gt-xs\:min-h-2 {
    min-height: 0.5rem !important;
  }

  .gt-xs\:min-h-3 {
    min-height: 0.75rem !important;
  }

  .gt-xs\:min-h-4 {
    min-height: 1rem !important;
  }

  .gt-xs\:min-h-5 {
    min-height: 1.25rem !important;
  }

  .gt-xs\:min-h-6 {
    min-height: 1.5rem !important;
  }

  .gt-xs\:min-h-8 {
    min-height: 2rem !important;
  }

  .gt-xs\:min-h-10 {
    min-height: 2.5rem !important;
  }

  .gt-xs\:min-h-12 {
    min-height: 3rem !important;
  }

  .gt-xs\:min-h-14 {
    min-height: 3.5rem !important;
  }

  .gt-xs\:min-h-16 {
    min-height: 4rem !important;
  }

  .gt-xs\:min-h-18 {
    min-height: 4.5rem !important;
  }

  .gt-xs\:min-h-20 {
    min-height: 5rem !important;
  }

  .gt-xs\:min-h-22 {
    min-height: 5.5rem !important;
  }

  .gt-xs\:min-h-24 {
    min-height: 6rem !important;
  }

  .gt-xs\:min-h-26 {
    min-height: 6.5rem !important;
  }

  .gt-xs\:min-h-28 {
    min-height: 7rem !important;
  }

  .gt-xs\:min-h-30 {
    min-height: 7.5rem !important;
  }

  .gt-xs\:min-h-32 {
    min-height: 8rem !important;
  }

  .gt-xs\:min-h-36 {
    min-height: 9rem !important;
  }

  .gt-xs\:min-h-40 {
    min-height: 10rem !important;
  }

  .gt-xs\:min-h-48 {
    min-height: 12rem !important;
  }

  .gt-xs\:min-h-50 {
    min-height: 12.5rem !important;
  }

  .gt-xs\:min-h-56 {
    min-height: 14rem !important;
  }

  .gt-xs\:min-h-60 {
    min-height: 15rem !important;
  }

  .gt-xs\:min-h-64 {
    min-height: 16rem !important;
  }

  .gt-xs\:min-h-80 {
    min-height: 20rem !important;
  }

  .gt-xs\:min-h-90 {
    min-height: 24rem !important;
  }

  .gt-xs\:min-h-100 {
    min-height: 25rem !important;
  }

  .gt-xs\:min-h-120 {
    min-height: 30rem !important;
  }

  .gt-xs\:min-h-128 {
    min-height: 32rem !important;
  }

  .gt-xs\:min-h-140 {
    min-height: 35rem !important;
  }

  .gt-xs\:min-h-160 {
    min-height: 40rem !important;
  }

  .gt-xs\:min-h-180 {
    min-height: 45rem !important;
  }

  .gt-xs\:min-h-192 {
    min-height: 48rem !important;
  }

  .gt-xs\:min-h-200 {
    min-height: 50rem !important;
  }

  .gt-xs\:min-h-240 {
    min-height: 60rem !important;
  }

  .gt-xs\:min-h-256 {
    min-height: 64rem !important;
  }

  .gt-xs\:min-h-280 {
    min-height: 70rem !important;
  }

  .gt-xs\:min-h-320 {
    min-height: 80rem !important;
  }

  .gt-xs\:min-h-360 {
    min-height: 90rem !important;
  }

  .gt-xs\:min-h-400 {
    min-height: 100rem !important;
  }

  .gt-xs\:min-h-480 {
    min-height: 120rem !important;
  }

  .gt-xs\:min-h-full {
    min-height: 100% !important;
  }

  .gt-xs\:min-h-screen {
    min-height: 100vh !important;
  }

  .gt-xs\:min-h-px {
    min-height: 1px !important;
  }

  .gt-xs\:min-h-2px {
    min-height: 2px !important;
  }

  .gt-xs\:min-h-1\/2 {
    min-height: 50% !important;
  }

  .gt-xs\:min-h-1\/3 {
    min-height: 33.33333% !important;
  }

  .gt-xs\:min-h-2\/3 {
    min-height: 66.66667% !important;
  }

  .gt-xs\:min-h-1\/4 {
    min-height: 25% !important;
  }

  .gt-xs\:min-h-2\/4 {
    min-height: 50% !important;
  }

  .gt-xs\:min-h-3\/4 {
    min-height: 75% !important;
  }

  .gt-xs\:min-h-1\/5 {
    min-height: 20% !important;
  }

  .gt-xs\:min-h-2\/5 {
    min-height: 40% !important;
  }

  .gt-xs\:min-h-3\/5 {
    min-height: 60% !important;
  }

  .gt-xs\:min-h-4\/5 {
    min-height: 80% !important;
  }

  .gt-xs\:min-h-1\/12 {
    min-height: 8.33333% !important;
  }

  .gt-xs\:min-h-2\/12 {
    min-height: 16.66667% !important;
  }

  .gt-xs\:min-h-3\/12 {
    min-height: 25% !important;
  }

  .gt-xs\:min-h-4\/12 {
    min-height: 33.33333% !important;
  }

  .gt-xs\:min-h-5\/12 {
    min-height: 41.66667% !important;
  }

  .gt-xs\:min-h-6\/12 {
    min-height: 50% !important;
  }

  .gt-xs\:min-h-7\/12 {
    min-height: 58.33333% !important;
  }

  .gt-xs\:min-h-8\/12 {
    min-height: 66.66667% !important;
  }

  .gt-xs\:min-h-9\/12 {
    min-height: 75% !important;
  }

  .gt-xs\:min-h-10\/12 {
    min-height: 83.33333% !important;
  }

  .gt-xs\:min-h-11\/12 {
    min-height: 91.66667% !important;
  }

  .gt-xs\:min-w-0 {
    min-width: 0 !important;
  }

  .gt-xs\:min-w-1 {
    min-width: 0.25rem !important;
  }

  .gt-xs\:min-w-2 {
    min-width: 0.5rem !important;
  }

  .gt-xs\:min-w-3 {
    min-width: 0.75rem !important;
  }

  .gt-xs\:min-w-4 {
    min-width: 1rem !important;
  }

  .gt-xs\:min-w-5 {
    min-width: 1.25rem !important;
  }

  .gt-xs\:min-w-6 {
    min-width: 1.5rem !important;
  }

  .gt-xs\:min-w-8 {
    min-width: 2rem !important;
  }

  .gt-xs\:min-w-10 {
    min-width: 2.5rem !important;
  }

  .gt-xs\:min-w-12 {
    min-width: 3rem !important;
  }

  .gt-xs\:min-w-14 {
    min-width: 3.5rem !important;
  }

  .gt-xs\:min-w-16 {
    min-width: 4rem !important;
  }

  .gt-xs\:min-w-18 {
    min-width: 4.5rem !important;
  }

  .gt-xs\:min-w-20 {
    min-width: 5rem !important;
  }

  .gt-xs\:min-w-22 {
    min-width: 5.5rem !important;
  }

  .gt-xs\:min-w-24 {
    min-width: 6rem !important;
  }

  .gt-xs\:min-w-26 {
    min-width: 6.5rem !important;
  }

  .gt-xs\:min-w-28 {
    min-width: 7rem !important;
  }

  .gt-xs\:min-w-30 {
    min-width: 7.5rem !important;
  }

  .gt-xs\:min-w-32 {
    min-width: 8rem !important;
  }

  .gt-xs\:min-w-36 {
    min-width: 9rem !important;
  }

  .gt-xs\:min-w-40 {
    min-width: 10rem !important;
  }

  .gt-xs\:min-w-48 {
    min-width: 12rem !important;
  }

  .gt-xs\:min-w-50 {
    min-width: 12.5rem !important;
  }

  .gt-xs\:min-w-56 {
    min-width: 14rem !important;
  }

  .gt-xs\:min-w-60 {
    min-width: 15rem !important;
  }

  .gt-xs\:min-w-64 {
    min-width: 16rem !important;
  }

  .gt-xs\:min-w-80 {
    min-width: 20rem !important;
  }

  .gt-xs\:min-w-90 {
    min-width: 24rem !important;
  }

  .gt-xs\:min-w-100 {
    min-width: 25rem !important;
  }

  .gt-xs\:min-w-120 {
    min-width: 30rem !important;
  }

  .gt-xs\:min-w-128 {
    min-width: 32rem !important;
  }

  .gt-xs\:min-w-140 {
    min-width: 35rem !important;
  }

  .gt-xs\:min-w-160 {
    min-width: 40rem !important;
  }

  .gt-xs\:min-w-180 {
    min-width: 45rem !important;
  }

  .gt-xs\:min-w-192 {
    min-width: 48rem !important;
  }

  .gt-xs\:min-w-200 {
    min-width: 50rem !important;
  }

  .gt-xs\:min-w-240 {
    min-width: 60rem !important;
  }

  .gt-xs\:min-w-256 {
    min-width: 64rem !important;
  }

  .gt-xs\:min-w-280 {
    min-width: 70rem !important;
  }

  .gt-xs\:min-w-320 {
    min-width: 80rem !important;
  }

  .gt-xs\:min-w-360 {
    min-width: 90rem !important;
  }

  .gt-xs\:min-w-400 {
    min-width: 100rem !important;
  }

  .gt-xs\:min-w-480 {
    min-width: 120rem !important;
  }

  .gt-xs\:min-w-full {
    min-width: 100% !important;
  }

  .gt-xs\:min-w-screen {
    min-width: 100vw !important;
  }

  .gt-xs\:min-w-px {
    min-width: 1px !important;
  }

  .gt-xs\:min-w-2px {
    min-width: 2px !important;
  }

  .gt-xs\:min-w-1\/2 {
    min-width: 50% !important;
  }

  .gt-xs\:min-w-1\/3 {
    min-width: 33.33333% !important;
  }

  .gt-xs\:min-w-2\/3 {
    min-width: 66.66667% !important;
  }

  .gt-xs\:min-w-1\/4 {
    min-width: 25% !important;
  }

  .gt-xs\:min-w-2\/4 {
    min-width: 50% !important;
  }

  .gt-xs\:min-w-3\/4 {
    min-width: 75% !important;
  }

  .gt-xs\:min-w-1\/5 {
    min-width: 20% !important;
  }

  .gt-xs\:min-w-2\/5 {
    min-width: 40% !important;
  }

  .gt-xs\:min-w-3\/5 {
    min-width: 60% !important;
  }

  .gt-xs\:min-w-4\/5 {
    min-width: 80% !important;
  }

  .gt-xs\:min-w-1\/12 {
    min-width: 8.33333% !important;
  }

  .gt-xs\:min-w-2\/12 {
    min-width: 16.66667% !important;
  }

  .gt-xs\:min-w-3\/12 {
    min-width: 25% !important;
  }

  .gt-xs\:min-w-4\/12 {
    min-width: 33.33333% !important;
  }

  .gt-xs\:min-w-5\/12 {
    min-width: 41.66667% !important;
  }

  .gt-xs\:min-w-6\/12 {
    min-width: 50% !important;
  }

  .gt-xs\:min-w-7\/12 {
    min-width: 58.33333% !important;
  }

  .gt-xs\:min-w-8\/12 {
    min-width: 66.66667% !important;
  }

  .gt-xs\:min-w-9\/12 {
    min-width: 75% !important;
  }

  .gt-xs\:min-w-10\/12 {
    min-width: 83.33333% !important;
  }

  .gt-xs\:min-w-11\/12 {
    min-width: 91.66667% !important;
  }

  .gt-xs\:object-contain {
    -o-object-fit: contain !important;
       object-fit: contain !important;
  }

  .gt-xs\:object-cover {
    -o-object-fit: cover !important;
       object-fit: cover !important;
  }

  .gt-xs\:object-fill {
    -o-object-fit: fill !important;
       object-fit: fill !important;
  }

  .gt-xs\:object-none {
    -o-object-fit: none !important;
       object-fit: none !important;
  }

  .gt-xs\:object-scale-down {
    -o-object-fit: scale-down !important;
       object-fit: scale-down !important;
  }

  .gt-xs\:object-bottom {
    -o-object-position: bottom !important;
       object-position: bottom !important;
  }

  .gt-xs\:object-center {
    -o-object-position: center !important;
       object-position: center !important;
  }

  .gt-xs\:object-left {
    -o-object-position: left !important;
       object-position: left !important;
  }

  .gt-xs\:object-left-bottom {
    -o-object-position: left bottom !important;
       object-position: left bottom !important;
  }

  .gt-xs\:object-left-top {
    -o-object-position: left top !important;
       object-position: left top !important;
  }

  .gt-xs\:object-right {
    -o-object-position: right !important;
       object-position: right !important;
  }

  .gt-xs\:object-right-bottom {
    -o-object-position: right bottom !important;
       object-position: right bottom !important;
  }

  .gt-xs\:object-right-top {
    -o-object-position: right top !important;
       object-position: right top !important;
  }

  .gt-xs\:object-top {
    -o-object-position: top !important;
       object-position: top !important;
  }

  .gt-xs\:opacity-0 {
    opacity: 0 !important;
  }

  .gt-xs\:opacity-12 {
    opacity: 0.12 !important;
  }

  .gt-xs\:opacity-25 {
    opacity: 0.25 !important;
  }

  .gt-xs\:opacity-38 {
    opacity: 0.38 !important;
  }

  .gt-xs\:opacity-50 {
    opacity: 0.5 !important;
  }

  .gt-xs\:opacity-54 {
    opacity: 0.54 !important;
  }

  .gt-xs\:opacity-70 {
    opacity: 0.70 !important;
  }

  .gt-xs\:opacity-75 {
    opacity: 0.75 !important;
  }

  .gt-xs\:opacity-84 {
    opacity: 0.84 !important;
  }

  .gt-xs\:opacity-100 {
    opacity: 1 !important;
  }

  .gt-xs\:hover\:opacity-0:hover {
    opacity: 0 !important;
  }

  .gt-xs\:hover\:opacity-12:hover {
    opacity: 0.12 !important;
  }

  .gt-xs\:hover\:opacity-25:hover {
    opacity: 0.25 !important;
  }

  .gt-xs\:hover\:opacity-38:hover {
    opacity: 0.38 !important;
  }

  .gt-xs\:hover\:opacity-50:hover {
    opacity: 0.5 !important;
  }

  .gt-xs\:hover\:opacity-54:hover {
    opacity: 0.54 !important;
  }

  .gt-xs\:hover\:opacity-70:hover {
    opacity: 0.70 !important;
  }

  .gt-xs\:hover\:opacity-75:hover {
    opacity: 0.75 !important;
  }

  .gt-xs\:hover\:opacity-84:hover {
    opacity: 0.84 !important;
  }

  .gt-xs\:hover\:opacity-100:hover {
    opacity: 1 !important;
  }

  .gt-xs\:focus\:opacity-0:focus {
    opacity: 0 !important;
  }

  .gt-xs\:focus\:opacity-12:focus {
    opacity: 0.12 !important;
  }

  .gt-xs\:focus\:opacity-25:focus {
    opacity: 0.25 !important;
  }

  .gt-xs\:focus\:opacity-38:focus {
    opacity: 0.38 !important;
  }

  .gt-xs\:focus\:opacity-50:focus {
    opacity: 0.5 !important;
  }

  .gt-xs\:focus\:opacity-54:focus {
    opacity: 0.54 !important;
  }

  .gt-xs\:focus\:opacity-70:focus {
    opacity: 0.70 !important;
  }

  .gt-xs\:focus\:opacity-75:focus {
    opacity: 0.75 !important;
  }

  .gt-xs\:focus\:opacity-84:focus {
    opacity: 0.84 !important;
  }

  .gt-xs\:focus\:opacity-100:focus {
    opacity: 1 !important;
  }

  .gt-xs\:outline-none {
    outline: 0 !important;
  }

  .gt-xs\:focus\:outline-none:focus {
    outline: 0 !important;
  }

  .gt-xs\:overflow-auto {
    overflow: auto !important;
  }

  .gt-xs\:overflow-hidden {
    overflow: hidden !important;
  }

  .gt-xs\:overflow-visible {
    overflow: visible !important;
  }

  .gt-xs\:overflow-scroll {
    overflow: scroll !important;
  }

  .gt-xs\:overflow-x-auto {
    overflow-x: auto !important;
  }

  .gt-xs\:overflow-y-auto {
    overflow-y: auto !important;
  }

  .gt-xs\:overflow-x-hidden {
    overflow-x: hidden !important;
  }

  .gt-xs\:overflow-y-hidden {
    overflow-y: hidden !important;
  }

  .gt-xs\:overflow-x-visible {
    overflow-x: visible !important;
  }

  .gt-xs\:overflow-y-visible {
    overflow-y: visible !important;
  }

  .gt-xs\:overflow-x-scroll {
    overflow-x: scroll !important;
  }

  .gt-xs\:overflow-y-scroll {
    overflow-y: scroll !important;
  }

  .gt-xs\:scrolling-touch {
    -webkit-overflow-scrolling: touch !important;
  }

  .gt-xs\:scrolling-auto {
    -webkit-overflow-scrolling: auto !important;
  }

  .gt-xs\:p-0 {
    padding: 0 !important;
  }

  .gt-xs\:p-1 {
    padding: 0.25rem !important;
  }

  .gt-xs\:p-2 {
    padding: 0.5rem !important;
  }

  .gt-xs\:p-3 {
    padding: 0.75rem !important;
  }

  .gt-xs\:p-4 {
    padding: 1rem !important;
  }

  .gt-xs\:p-5 {
    padding: 1.25rem !important;
  }

  .gt-xs\:p-6 {
    padding: 1.5rem !important;
  }

  .gt-xs\:p-8 {
    padding: 2rem !important;
  }

  .gt-xs\:p-10 {
    padding: 2.5rem !important;
  }

  .gt-xs\:p-12 {
    padding: 3rem !important;
  }

  .gt-xs\:p-14 {
    padding: 3.5rem !important;
  }

  .gt-xs\:p-16 {
    padding: 4rem !important;
  }

  .gt-xs\:p-18 {
    padding: 4.5rem !important;
  }

  .gt-xs\:p-20 {
    padding: 5rem !important;
  }

  .gt-xs\:p-22 {
    padding: 5.5rem !important;
  }

  .gt-xs\:p-24 {
    padding: 6rem !important;
  }

  .gt-xs\:p-26 {
    padding: 6.5rem !important;
  }

  .gt-xs\:p-28 {
    padding: 7rem !important;
  }

  .gt-xs\:p-30 {
    padding: 7.5rem !important;
  }

  .gt-xs\:p-32 {
    padding: 8rem !important;
  }

  .gt-xs\:p-36 {
    padding: 9rem !important;
  }

  .gt-xs\:p-40 {
    padding: 10rem !important;
  }

  .gt-xs\:p-48 {
    padding: 12rem !important;
  }

  .gt-xs\:p-56 {
    padding: 14rem !important;
  }

  .gt-xs\:p-64 {
    padding: 16rem !important;
  }

  .gt-xs\:p-px {
    padding: 1px !important;
  }

  .gt-xs\:p-2px {
    padding: 2px !important;
  }

  .gt-xs\:py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .gt-xs\:px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .gt-xs\:py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .gt-xs\:px-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }

  .gt-xs\:py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .gt-xs\:px-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .gt-xs\:py-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .gt-xs\:px-3 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }

  .gt-xs\:py-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .gt-xs\:px-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .gt-xs\:py-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .gt-xs\:px-5 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }

  .gt-xs\:py-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .gt-xs\:px-6 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .gt-xs\:py-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .gt-xs\:px-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .gt-xs\:py-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .gt-xs\:px-10 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .gt-xs\:py-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .gt-xs\:px-12 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .gt-xs\:py-14 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .gt-xs\:px-14 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }

  .gt-xs\:py-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .gt-xs\:px-16 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .gt-xs\:py-18 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .gt-xs\:px-18 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }

  .gt-xs\:py-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .gt-xs\:px-20 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .gt-xs\:py-22 {
    padding-top: 5.5rem !important;
    padding-bottom: 5.5rem !important;
  }

  .gt-xs\:px-22 {
    padding-left: 5.5rem !important;
    padding-right: 5.5rem !important;
  }

  .gt-xs\:py-24 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .gt-xs\:px-24 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }

  .gt-xs\:py-26 {
    padding-top: 6.5rem !important;
    padding-bottom: 6.5rem !important;
  }

  .gt-xs\:px-26 {
    padding-left: 6.5rem !important;
    padding-right: 6.5rem !important;
  }

  .gt-xs\:py-28 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }

  .gt-xs\:px-28 {
    padding-left: 7rem !important;
    padding-right: 7rem !important;
  }

  .gt-xs\:py-30 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }

  .gt-xs\:px-30 {
    padding-left: 7.5rem !important;
    padding-right: 7.5rem !important;
  }

  .gt-xs\:py-32 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .gt-xs\:px-32 {
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }

  .gt-xs\:py-36 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }

  .gt-xs\:px-36 {
    padding-left: 9rem !important;
    padding-right: 9rem !important;
  }

  .gt-xs\:py-40 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .gt-xs\:px-40 {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }

  .gt-xs\:py-48 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }

  .gt-xs\:px-48 {
    padding-left: 12rem !important;
    padding-right: 12rem !important;
  }

  .gt-xs\:py-56 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }

  .gt-xs\:px-56 {
    padding-left: 14rem !important;
    padding-right: 14rem !important;
  }

  .gt-xs\:py-64 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }

  .gt-xs\:px-64 {
    padding-left: 16rem !important;
    padding-right: 16rem !important;
  }

  .gt-xs\:py-px {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }

  .gt-xs\:px-px {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }

  .gt-xs\:py-2px {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }

  .gt-xs\:px-2px {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }

  .gt-xs\:pt-0 {
    padding-top: 0 !important;
  }

  .gt-xs\:pr-0 {
    padding-right: 0 !important;
  }

  .gt-xs\:pb-0 {
    padding-bottom: 0 !important;
  }

  .gt-xs\:pl-0 {
    padding-left: 0 !important;
  }

  .gt-xs\:pt-1 {
    padding-top: 0.25rem !important;
  }

  .gt-xs\:pr-1 {
    padding-right: 0.25rem !important;
  }

  .gt-xs\:pb-1 {
    padding-bottom: 0.25rem !important;
  }

  .gt-xs\:pl-1 {
    padding-left: 0.25rem !important;
  }

  .gt-xs\:pt-2 {
    padding-top: 0.5rem !important;
  }

  .gt-xs\:pr-2 {
    padding-right: 0.5rem !important;
  }

  .gt-xs\:pb-2 {
    padding-bottom: 0.5rem !important;
  }

  .gt-xs\:pl-2 {
    padding-left: 0.5rem !important;
  }

  .gt-xs\:pt-3 {
    padding-top: 0.75rem !important;
  }

  .gt-xs\:pr-3 {
    padding-right: 0.75rem !important;
  }

  .gt-xs\:pb-3 {
    padding-bottom: 0.75rem !important;
  }

  .gt-xs\:pl-3 {
    padding-left: 0.75rem !important;
  }

  .gt-xs\:pt-4 {
    padding-top: 1rem !important;
  }

  .gt-xs\:pr-4 {
    padding-right: 1rem !important;
  }

  .gt-xs\:pb-4 {
    padding-bottom: 1rem !important;
  }

  .gt-xs\:pl-4 {
    padding-left: 1rem !important;
  }

  .gt-xs\:pt-5 {
    padding-top: 1.25rem !important;
  }

  .gt-xs\:pr-5 {
    padding-right: 1.25rem !important;
  }

  .gt-xs\:pb-5 {
    padding-bottom: 1.25rem !important;
  }

  .gt-xs\:pl-5 {
    padding-left: 1.25rem !important;
  }

  .gt-xs\:pt-6 {
    padding-top: 1.5rem !important;
  }

  .gt-xs\:pr-6 {
    padding-right: 1.5rem !important;
  }

  .gt-xs\:pb-6 {
    padding-bottom: 1.5rem !important;
  }

  .gt-xs\:pl-6 {
    padding-left: 1.5rem !important;
  }

  .gt-xs\:pt-8 {
    padding-top: 2rem !important;
  }

  .gt-xs\:pr-8 {
    padding-right: 2rem !important;
  }

  .gt-xs\:pb-8 {
    padding-bottom: 2rem !important;
  }

  .gt-xs\:pl-8 {
    padding-left: 2rem !important;
  }

  .gt-xs\:pt-10 {
    padding-top: 2.5rem !important;
  }

  .gt-xs\:pr-10 {
    padding-right: 2.5rem !important;
  }

  .gt-xs\:pb-10 {
    padding-bottom: 2.5rem !important;
  }

  .gt-xs\:pl-10 {
    padding-left: 2.5rem !important;
  }

  .gt-xs\:pt-12 {
    padding-top: 3rem !important;
  }

  .gt-xs\:pr-12 {
    padding-right: 3rem !important;
  }

  .gt-xs\:pb-12 {
    padding-bottom: 3rem !important;
  }

  .gt-xs\:pl-12 {
    padding-left: 3rem !important;
  }

  .gt-xs\:pt-14 {
    padding-top: 3.5rem !important;
  }

  .gt-xs\:pr-14 {
    padding-right: 3.5rem !important;
  }

  .gt-xs\:pb-14 {
    padding-bottom: 3.5rem !important;
  }

  .gt-xs\:pl-14 {
    padding-left: 3.5rem !important;
  }

  .gt-xs\:pt-16 {
    padding-top: 4rem !important;
  }

  .gt-xs\:pr-16 {
    padding-right: 4rem !important;
  }

  .gt-xs\:pb-16 {
    padding-bottom: 4rem !important;
  }

  .gt-xs\:pl-16 {
    padding-left: 4rem !important;
  }

  .gt-xs\:pt-18 {
    padding-top: 4.5rem !important;
  }

  .gt-xs\:pr-18 {
    padding-right: 4.5rem !important;
  }

  .gt-xs\:pb-18 {
    padding-bottom: 4.5rem !important;
  }

  .gt-xs\:pl-18 {
    padding-left: 4.5rem !important;
  }

  .gt-xs\:pt-20 {
    padding-top: 5rem !important;
  }

  .gt-xs\:pr-20 {
    padding-right: 5rem !important;
  }

  .gt-xs\:pb-20 {
    padding-bottom: 5rem !important;
  }

  .gt-xs\:pl-20 {
    padding-left: 5rem !important;
  }

  .gt-xs\:pt-22 {
    padding-top: 5.5rem !important;
  }

  .gt-xs\:pr-22 {
    padding-right: 5.5rem !important;
  }

  .gt-xs\:pb-22 {
    padding-bottom: 5.5rem !important;
  }

  .gt-xs\:pl-22 {
    padding-left: 5.5rem !important;
  }

  .gt-xs\:pt-24 {
    padding-top: 6rem !important;
  }

  .gt-xs\:pr-24 {
    padding-right: 6rem !important;
  }

  .gt-xs\:pb-24 {
    padding-bottom: 6rem !important;
  }

  .gt-xs\:pl-24 {
    padding-left: 6rem !important;
  }

  .gt-xs\:pt-26 {
    padding-top: 6.5rem !important;
  }

  .gt-xs\:pr-26 {
    padding-right: 6.5rem !important;
  }

  .gt-xs\:pb-26 {
    padding-bottom: 6.5rem !important;
  }

  .gt-xs\:pl-26 {
    padding-left: 6.5rem !important;
  }

  .gt-xs\:pt-28 {
    padding-top: 7rem !important;
  }

  .gt-xs\:pr-28 {
    padding-right: 7rem !important;
  }

  .gt-xs\:pb-28 {
    padding-bottom: 7rem !important;
  }

  .gt-xs\:pl-28 {
    padding-left: 7rem !important;
  }

  .gt-xs\:pt-30 {
    padding-top: 7.5rem !important;
  }

  .gt-xs\:pr-30 {
    padding-right: 7.5rem !important;
  }

  .gt-xs\:pb-30 {
    padding-bottom: 7.5rem !important;
  }

  .gt-xs\:pl-30 {
    padding-left: 7.5rem !important;
  }

  .gt-xs\:pt-32 {
    padding-top: 8rem !important;
  }

  .gt-xs\:pr-32 {
    padding-right: 8rem !important;
  }

  .gt-xs\:pb-32 {
    padding-bottom: 8rem !important;
  }

  .gt-xs\:pl-32 {
    padding-left: 8rem !important;
  }

  .gt-xs\:pt-36 {
    padding-top: 9rem !important;
  }

  .gt-xs\:pr-36 {
    padding-right: 9rem !important;
  }

  .gt-xs\:pb-36 {
    padding-bottom: 9rem !important;
  }

  .gt-xs\:pl-36 {
    padding-left: 9rem !important;
  }

  .gt-xs\:pt-40 {
    padding-top: 10rem !important;
  }

  .gt-xs\:pr-40 {
    padding-right: 10rem !important;
  }

  .gt-xs\:pb-40 {
    padding-bottom: 10rem !important;
  }

  .gt-xs\:pl-40 {
    padding-left: 10rem !important;
  }

  .gt-xs\:pt-48 {
    padding-top: 12rem !important;
  }

  .gt-xs\:pr-48 {
    padding-right: 12rem !important;
  }

  .gt-xs\:pb-48 {
    padding-bottom: 12rem !important;
  }

  .gt-xs\:pl-48 {
    padding-left: 12rem !important;
  }

  .gt-xs\:pt-56 {
    padding-top: 14rem !important;
  }

  .gt-xs\:pr-56 {
    padding-right: 14rem !important;
  }

  .gt-xs\:pb-56 {
    padding-bottom: 14rem !important;
  }

  .gt-xs\:pl-56 {
    padding-left: 14rem !important;
  }

  .gt-xs\:pt-64 {
    padding-top: 16rem !important;
  }

  .gt-xs\:pr-64 {
    padding-right: 16rem !important;
  }

  .gt-xs\:pb-64 {
    padding-bottom: 16rem !important;
  }

  .gt-xs\:pl-64 {
    padding-left: 16rem !important;
  }

  .gt-xs\:pt-px {
    padding-top: 1px !important;
  }

  .gt-xs\:pr-px {
    padding-right: 1px !important;
  }

  .gt-xs\:pb-px {
    padding-bottom: 1px !important;
  }

  .gt-xs\:pl-px {
    padding-left: 1px !important;
  }

  .gt-xs\:pt-2px {
    padding-top: 2px !important;
  }

  .gt-xs\:pr-2px {
    padding-right: 2px !important;
  }

  .gt-xs\:pb-2px {
    padding-bottom: 2px !important;
  }

  .gt-xs\:pl-2px {
    padding-left: 2px !important;
  }

  .gt-xs\:placeholder-opacity-0::-webkit-input-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .gt-xs\:placeholder-opacity-0::-moz-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .gt-xs\:placeholder-opacity-0::-ms-input-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .gt-xs\:placeholder-opacity-0::placeholder {
    --placeholder-opacity: 0 !important;
  }

  .gt-xs\:placeholder-opacity-12::-webkit-input-placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .gt-xs\:placeholder-opacity-12::-moz-placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .gt-xs\:placeholder-opacity-12::-ms-input-placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .gt-xs\:placeholder-opacity-12::placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .gt-xs\:placeholder-opacity-25::-webkit-input-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .gt-xs\:placeholder-opacity-25::-moz-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .gt-xs\:placeholder-opacity-25::-ms-input-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .gt-xs\:placeholder-opacity-25::placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .gt-xs\:placeholder-opacity-38::-webkit-input-placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .gt-xs\:placeholder-opacity-38::-moz-placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .gt-xs\:placeholder-opacity-38::-ms-input-placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .gt-xs\:placeholder-opacity-38::placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .gt-xs\:placeholder-opacity-50::-webkit-input-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .gt-xs\:placeholder-opacity-50::-moz-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .gt-xs\:placeholder-opacity-50::-ms-input-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .gt-xs\:placeholder-opacity-50::placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .gt-xs\:placeholder-opacity-54::-webkit-input-placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .gt-xs\:placeholder-opacity-54::-moz-placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .gt-xs\:placeholder-opacity-54::-ms-input-placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .gt-xs\:placeholder-opacity-54::placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .gt-xs\:placeholder-opacity-70::-webkit-input-placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .gt-xs\:placeholder-opacity-70::-moz-placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .gt-xs\:placeholder-opacity-70::-ms-input-placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .gt-xs\:placeholder-opacity-70::placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .gt-xs\:placeholder-opacity-75::-webkit-input-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .gt-xs\:placeholder-opacity-75::-moz-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .gt-xs\:placeholder-opacity-75::-ms-input-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .gt-xs\:placeholder-opacity-75::placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .gt-xs\:placeholder-opacity-84::-webkit-input-placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .gt-xs\:placeholder-opacity-84::-moz-placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .gt-xs\:placeholder-opacity-84::-ms-input-placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .gt-xs\:placeholder-opacity-84::placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .gt-xs\:placeholder-opacity-100::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .gt-xs\:placeholder-opacity-100::-moz-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .gt-xs\:placeholder-opacity-100::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .gt-xs\:placeholder-opacity-100::placeholder {
    --placeholder-opacity: 1 !important;
  }

  .gt-xs\:focus\:placeholder-opacity-0:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .gt-xs\:focus\:placeholder-opacity-0:focus::-moz-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .gt-xs\:focus\:placeholder-opacity-0:focus::-ms-input-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .gt-xs\:focus\:placeholder-opacity-0:focus::placeholder {
    --placeholder-opacity: 0 !important;
  }

  .gt-xs\:focus\:placeholder-opacity-12:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .gt-xs\:focus\:placeholder-opacity-12:focus::-moz-placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .gt-xs\:focus\:placeholder-opacity-12:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .gt-xs\:focus\:placeholder-opacity-12:focus::placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .gt-xs\:focus\:placeholder-opacity-25:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .gt-xs\:focus\:placeholder-opacity-25:focus::-moz-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .gt-xs\:focus\:placeholder-opacity-25:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .gt-xs\:focus\:placeholder-opacity-25:focus::placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .gt-xs\:focus\:placeholder-opacity-38:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .gt-xs\:focus\:placeholder-opacity-38:focus::-moz-placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .gt-xs\:focus\:placeholder-opacity-38:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .gt-xs\:focus\:placeholder-opacity-38:focus::placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .gt-xs\:focus\:placeholder-opacity-50:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .gt-xs\:focus\:placeholder-opacity-50:focus::-moz-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .gt-xs\:focus\:placeholder-opacity-50:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .gt-xs\:focus\:placeholder-opacity-50:focus::placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .gt-xs\:focus\:placeholder-opacity-54:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .gt-xs\:focus\:placeholder-opacity-54:focus::-moz-placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .gt-xs\:focus\:placeholder-opacity-54:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .gt-xs\:focus\:placeholder-opacity-54:focus::placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .gt-xs\:focus\:placeholder-opacity-70:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .gt-xs\:focus\:placeholder-opacity-70:focus::-moz-placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .gt-xs\:focus\:placeholder-opacity-70:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .gt-xs\:focus\:placeholder-opacity-70:focus::placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .gt-xs\:focus\:placeholder-opacity-75:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .gt-xs\:focus\:placeholder-opacity-75:focus::-moz-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .gt-xs\:focus\:placeholder-opacity-75:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .gt-xs\:focus\:placeholder-opacity-75:focus::placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .gt-xs\:focus\:placeholder-opacity-84:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .gt-xs\:focus\:placeholder-opacity-84:focus::-moz-placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .gt-xs\:focus\:placeholder-opacity-84:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .gt-xs\:focus\:placeholder-opacity-84:focus::placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .gt-xs\:focus\:placeholder-opacity-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .gt-xs\:focus\:placeholder-opacity-100:focus::-moz-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .gt-xs\:focus\:placeholder-opacity-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .gt-xs\:focus\:placeholder-opacity-100:focus::placeholder {
    --placeholder-opacity: 1 !important;
  }

  .gt-xs\:pointer-events-none {
    pointer-events: none !important;
  }

  .gt-xs\:pointer-events-auto {
    pointer-events: auto !important;
  }

  .gt-xs\:static {
    position: static !important;
  }

  .gt-xs\:fixed {
    position: fixed !important;
  }

  .gt-xs\:absolute {
    position: absolute !important;
  }

  .gt-xs\:relative {
    position: relative !important;
  }

  .gt-xs\:sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }

  .gt-xs\:inset-0 {
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
  }

  .gt-xs\:inset-auto {
    top: auto !important;
    right: auto !important;
    bottom: auto !important;
    left: auto !important;
  }

  .gt-xs\:inset-y-0 {
    top: 0 !important;
    bottom: 0 !important;
  }

  .gt-xs\:inset-x-0 {
    right: 0 !important;
    left: 0 !important;
  }

  .gt-xs\:inset-y-auto {
    top: auto !important;
    bottom: auto !important;
  }

  .gt-xs\:inset-x-auto {
    right: auto !important;
    left: auto !important;
  }

  .gt-xs\:top-0 {
    top: 0 !important;
  }

  .gt-xs\:right-0 {
    right: 0 !important;
  }

  .gt-xs\:bottom-0 {
    bottom: 0 !important;
  }

  .gt-xs\:left-0 {
    left: 0 !important;
  }

  .gt-xs\:top-auto {
    top: auto !important;
  }

  .gt-xs\:right-auto {
    right: auto !important;
  }

  .gt-xs\:bottom-auto {
    bottom: auto !important;
  }

  .gt-xs\:left-auto {
    left: auto !important;
  }

  .gt-xs\:shadow-xs {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05) !important;
  }

  .gt-xs\:shadow-sm {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  }

  .gt-xs\:shadow {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .gt-xs\:shadow-md {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  }

  .gt-xs\:shadow-lg {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  }

  .gt-xs\:shadow-xl {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
  }

  .gt-xs\:shadow-2xl {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
  }

  .gt-xs\:shadow-inner {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .gt-xs\:ring {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }

  .gt-xs\:shadow-none {
    box-shadow: none !important;
  }

  .gt-xs\:shadow-solid {
    box-shadow: 0 0 0 2px currentColor !important;
  }

  .gt-xs\:hover\:shadow-xs:hover {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05) !important;
  }

  .gt-xs\:hover\:shadow-sm:hover {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  }

  .gt-xs\:hover\:shadow:hover {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .gt-xs\:hover\:shadow-md:hover {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  }

  .gt-xs\:hover\:shadow-lg:hover {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  }

  .gt-xs\:hover\:shadow-xl:hover {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
  }

  .gt-xs\:hover\:shadow-2xl:hover {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
  }

  .gt-xs\:hover\:shadow-inner:hover {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .gt-xs\:hover\:ring:hover {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }

  .gt-xs\:hover\:shadow-none:hover {
    box-shadow: none !important;
  }

  .gt-xs\:hover\:shadow-solid:hover {
    box-shadow: 0 0 0 2px currentColor !important;
  }

  .gt-xs\:focus\:shadow-xs:focus {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05) !important;
  }

  .gt-xs\:focus\:shadow-sm:focus {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  }

  .gt-xs\:focus\:shadow:focus {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .gt-xs\:focus\:shadow-md:focus {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  }

  .gt-xs\:focus\:shadow-lg:focus {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  }

  .gt-xs\:focus\:shadow-xl:focus {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
  }

  .gt-xs\:focus\:shadow-2xl:focus {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
  }

  .gt-xs\:focus\:shadow-inner:focus {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .gt-xs\:focus\:ring:focus {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }

  .gt-xs\:focus\:shadow-none:focus {
    box-shadow: none !important;
  }

  .gt-xs\:focus\:shadow-solid:focus {
    box-shadow: 0 0 0 2px currentColor !important;
  }

  .gt-xs\:fill-current {
    fill: currentColor !important;
  }

  .gt-xs\:stroke-current {
    stroke: currentColor !important;
  }

  .gt-xs\:stroke-0 {
    stroke-width: 0 !important;
  }

  .gt-xs\:stroke-1 {
    stroke-width: 1 !important;
  }

  .gt-xs\:stroke-2 {
    stroke-width: 2 !important;
  }

  .gt-xs\:table-auto {
    table-layout: auto !important;
  }

  .gt-xs\:table-fixed {
    table-layout: fixed !important;
  }

  .gt-xs\:text-left {
    text-align: left !important;
  }

  .gt-xs\:text-center {
    text-align: center !important;
  }

  .gt-xs\:text-right {
    text-align: right !important;
  }

  .gt-xs\:text-justify {
    text-align: justify !important;
  }

  .gt-xs\:text-opacity-0 {
    --text-opacity: 0 !important;
  }

  .gt-xs\:text-opacity-12 {
    --text-opacity: 0.12 !important;
  }

  .gt-xs\:text-opacity-25 {
    --text-opacity: 0.25 !important;
  }

  .gt-xs\:text-opacity-38 {
    --text-opacity: 0.38 !important;
  }

  .gt-xs\:text-opacity-50 {
    --text-opacity: 0.5 !important;
  }

  .gt-xs\:text-opacity-54 {
    --text-opacity: 0.54 !important;
  }

  .gt-xs\:text-opacity-70 {
    --text-opacity: 0.70 !important;
  }

  .gt-xs\:text-opacity-75 {
    --text-opacity: 0.75 !important;
  }

  .gt-xs\:text-opacity-84 {
    --text-opacity: 0.84 !important;
  }

  .gt-xs\:text-opacity-100 {
    --text-opacity: 1 !important;
  }

  .gt-xs\:hover\:text-opacity-0:hover {
    --text-opacity: 0 !important;
  }

  .gt-xs\:hover\:text-opacity-12:hover {
    --text-opacity: 0.12 !important;
  }

  .gt-xs\:hover\:text-opacity-25:hover {
    --text-opacity: 0.25 !important;
  }

  .gt-xs\:hover\:text-opacity-38:hover {
    --text-opacity: 0.38 !important;
  }

  .gt-xs\:hover\:text-opacity-50:hover {
    --text-opacity: 0.5 !important;
  }

  .gt-xs\:hover\:text-opacity-54:hover {
    --text-opacity: 0.54 !important;
  }

  .gt-xs\:hover\:text-opacity-70:hover {
    --text-opacity: 0.70 !important;
  }

  .gt-xs\:hover\:text-opacity-75:hover {
    --text-opacity: 0.75 !important;
  }

  .gt-xs\:hover\:text-opacity-84:hover {
    --text-opacity: 0.84 !important;
  }

  .gt-xs\:hover\:text-opacity-100:hover {
    --text-opacity: 1 !important;
  }

  .gt-xs\:focus\:text-opacity-0:focus {
    --text-opacity: 0 !important;
  }

  .gt-xs\:focus\:text-opacity-12:focus {
    --text-opacity: 0.12 !important;
  }

  .gt-xs\:focus\:text-opacity-25:focus {
    --text-opacity: 0.25 !important;
  }

  .gt-xs\:focus\:text-opacity-38:focus {
    --text-opacity: 0.38 !important;
  }

  .gt-xs\:focus\:text-opacity-50:focus {
    --text-opacity: 0.5 !important;
  }

  .gt-xs\:focus\:text-opacity-54:focus {
    --text-opacity: 0.54 !important;
  }

  .gt-xs\:focus\:text-opacity-70:focus {
    --text-opacity: 0.70 !important;
  }

  .gt-xs\:focus\:text-opacity-75:focus {
    --text-opacity: 0.75 !important;
  }

  .gt-xs\:focus\:text-opacity-84:focus {
    --text-opacity: 0.84 !important;
  }

  .gt-xs\:focus\:text-opacity-100:focus {
    --text-opacity: 1 !important;
  }

  .gt-xs\:italic {
    font-style: italic !important;
  }

  .gt-xs\:not-italic {
    font-style: normal !important;
  }

  .gt-xs\:uppercase {
    text-transform: uppercase !important;
  }

  .gt-xs\:lowercase {
    text-transform: lowercase !important;
  }

  .gt-xs\:capitalize {
    text-transform: capitalize !important;
  }

  .gt-xs\:normal-case {
    text-transform: none !important;
  }

  .gt-xs\:underline {
    text-decoration: underline !important;
  }

  .gt-xs\:line-through {
    text-decoration: line-through !important;
  }

  .gt-xs\:no-underline {
    text-decoration: none !important;
  }

  .gt-xs\:hover\:underline:hover {
    text-decoration: underline !important;
  }

  .gt-xs\:hover\:line-through:hover {
    text-decoration: line-through !important;
  }

  .gt-xs\:hover\:no-underline:hover {
    text-decoration: none !important;
  }

  .gt-xs\:focus\:underline:focus {
    text-decoration: underline !important;
  }

  .gt-xs\:focus\:line-through:focus {
    text-decoration: line-through !important;
  }

  .gt-xs\:focus\:no-underline:focus {
    text-decoration: none !important;
  }

  .gt-xs\:tracking-tighter {
    letter-spacing: -0.05em !important;
  }

  .gt-xs\:tracking-tight {
    letter-spacing: -0.025em !important;
  }

  .gt-xs\:tracking-normal {
    letter-spacing: 0 !important;
  }

  .gt-xs\:tracking-wide {
    letter-spacing: 0.025em !important;
  }

  .gt-xs\:tracking-wider {
    letter-spacing: 0.05em !important;
  }

  .gt-xs\:tracking-widest {
    letter-spacing: 0.1em !important;
  }

  .gt-xs\:select-none {
    -webkit-user-select: none !important;
       -moz-user-select: none !important;
        -ms-user-select: none !important;
            user-select: none !important;
  }

  .gt-xs\:select-text {
    -webkit-user-select: text !important;
       -moz-user-select: text !important;
        -ms-user-select: text !important;
            user-select: text !important;
  }

  .gt-xs\:select-all {
    -webkit-user-select: all !important;
       -moz-user-select: all !important;
        -ms-user-select: all !important;
            user-select: all !important;
  }

  .gt-xs\:select-auto {
    -webkit-user-select: auto !important;
       -moz-user-select: auto !important;
        -ms-user-select: auto !important;
            user-select: auto !important;
  }

  .gt-xs\:align-baseline {
    vertical-align: baseline !important;
  }

  .gt-xs\:align-top {
    vertical-align: top !important;
  }

  .gt-xs\:align-middle {
    vertical-align: middle !important;
  }

  .gt-xs\:align-bottom {
    vertical-align: bottom !important;
  }

  .gt-xs\:align-text-top {
    vertical-align: text-top !important;
  }

  .gt-xs\:align-text-bottom {
    vertical-align: text-bottom !important;
  }

  .gt-xs\:visible {
    visibility: visible !important;
  }

  .gt-xs\:invisible {
    visibility: hidden !important;
  }

  .gt-xs\:whitespace-normal {
    white-space: normal !important;
  }

  .gt-xs\:whitespace-nowrap {
    white-space: nowrap !important;
  }

  .gt-xs\:whitespace-pre {
    white-space: pre !important;
  }

  .gt-xs\:whitespace-pre-line {
    white-space: pre-line !important;
  }

  .gt-xs\:whitespace-pre-wrap {
    white-space: pre-wrap !important;
  }

  .gt-xs\:break-normal {
    overflow-wrap: normal !important;
    word-break: normal !important;
  }

  .gt-xs\:break-words {
    overflow-wrap: break-word !important;
  }

  .gt-xs\:break-all {
    word-break: break-all !important;
  }

  .gt-xs\:truncate {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
  }

  .gt-xs\:w-0 {
    width: 0 !important;
  }

  .gt-xs\:w-1 {
    width: 0.25rem !important;
  }

  .gt-xs\:w-2 {
    width: 0.5rem !important;
  }

  .gt-xs\:w-3 {
    width: 0.75rem !important;
  }

  .gt-xs\:w-4 {
    width: 1rem !important;
  }

  .gt-xs\:w-5 {
    width: 1.25rem !important;
  }

  .gt-xs\:w-6 {
    width: 1.5rem !important;
  }

  .gt-xs\:w-8 {
    width: 2rem !important;
  }

  .gt-xs\:w-10 {
    width: 2.5rem !important;
  }

  .gt-xs\:w-12 {
    width: 3rem !important;
  }

  .gt-xs\:w-14 {
    width: 3.5rem !important;
  }

  .gt-xs\:w-16 {
    width: 4rem !important;
  }

  .gt-xs\:w-18 {
    width: 4.5rem !important;
  }

  .gt-xs\:w-20 {
    width: 5rem !important;
  }

  .gt-xs\:w-22 {
    width: 5.5rem !important;
  }

  .gt-xs\:w-24 {
    width: 6rem !important;
  }

  .gt-xs\:w-26 {
    width: 6.5rem !important;
  }

  .gt-xs\:w-28 {
    width: 7rem !important;
  }

  .gt-xs\:w-30 {
    width: 7.5rem !important;
  }

  .gt-xs\:w-32 {
    width: 8rem !important;
  }

  .gt-xs\:w-36 {
    width: 9rem !important;
  }

  .gt-xs\:w-40 {
    width: 10rem !important;
  }

  .gt-xs\:w-48 {
    width: 12rem !important;
  }

  .gt-xs\:w-50 {
    width: 12.5rem !important;
  }

  .gt-xs\:w-56 {
    width: 14rem !important;
  }

  .gt-xs\:w-60 {
    width: 15rem !important;
  }

  .gt-xs\:w-64 {
    width: 16rem !important;
  }

  .gt-xs\:w-80 {
    width: 20rem !important;
  }

  .gt-xs\:w-90 {
    width: 24rem !important;
  }

  .gt-xs\:w-100 {
    width: 25rem !important;
  }

  .gt-xs\:w-120 {
    width: 30rem !important;
  }

  .gt-xs\:w-128 {
    width: 32rem !important;
  }

  .gt-xs\:w-140 {
    width: 35rem !important;
  }

  .gt-xs\:w-160 {
    width: 40rem !important;
  }

  .gt-xs\:w-180 {
    width: 45rem !important;
  }

  .gt-xs\:w-192 {
    width: 48rem !important;
  }

  .gt-xs\:w-200 {
    width: 50rem !important;
  }

  .gt-xs\:w-240 {
    width: 60rem !important;
  }

  .gt-xs\:w-256 {
    width: 64rem !important;
  }

  .gt-xs\:w-280 {
    width: 70rem !important;
  }

  .gt-xs\:w-320 {
    width: 80rem !important;
  }

  .gt-xs\:w-360 {
    width: 90rem !important;
  }

  .gt-xs\:w-400 {
    width: 100rem !important;
  }

  .gt-xs\:w-480 {
    width: 120rem !important;
  }

  .gt-xs\:w-auto {
    width: auto !important;
  }

  .gt-xs\:w-px {
    width: 1px !important;
  }

  .gt-xs\:w-2px {
    width: 2px !important;
  }

  .gt-xs\:w-1\/2 {
    width: 50% !important;
  }

  .gt-xs\:w-1\/3 {
    width: 33.33333% !important;
  }

  .gt-xs\:w-2\/3 {
    width: 66.66667% !important;
  }

  .gt-xs\:w-1\/4 {
    width: 25% !important;
  }

  .gt-xs\:w-2\/4 {
    width: 50% !important;
  }

  .gt-xs\:w-3\/4 {
    width: 75% !important;
  }

  .gt-xs\:w-1\/5 {
    width: 20% !important;
  }

  .gt-xs\:w-2\/5 {
    width: 40% !important;
  }

  .gt-xs\:w-3\/5 {
    width: 60% !important;
  }

  .gt-xs\:w-4\/5 {
    width: 80% !important;
  }

  .gt-xs\:w-1\/6 {
    width: 16.666667% !important;
  }

  .gt-xs\:w-2\/6 {
    width: 33.333333% !important;
  }

  .gt-xs\:w-3\/6 {
    width: 50% !important;
  }

  .gt-xs\:w-4\/6 {
    width: 66.666667% !important;
  }

  .gt-xs\:w-5\/6 {
    width: 83.333333% !important;
  }

  .gt-xs\:w-1\/12 {
    width: 8.33333% !important;
  }

  .gt-xs\:w-2\/12 {
    width: 16.66667% !important;
  }

  .gt-xs\:w-3\/12 {
    width: 25% !important;
  }

  .gt-xs\:w-4\/12 {
    width: 33.33333% !important;
  }

  .gt-xs\:w-5\/12 {
    width: 41.66667% !important;
  }

  .gt-xs\:w-6\/12 {
    width: 50% !important;
  }

  .gt-xs\:w-7\/12 {
    width: 58.33333% !important;
  }

  .gt-xs\:w-8\/12 {
    width: 66.66667% !important;
  }

  .gt-xs\:w-9\/12 {
    width: 75% !important;
  }

  .gt-xs\:w-10\/12 {
    width: 83.33333% !important;
  }

  .gt-xs\:w-11\/12 {
    width: 91.66667% !important;
  }

  .gt-xs\:w-full {
    width: 100% !important;
  }

  .gt-xs\:w-screen {
    width: 100vw !important;
  }

  .gt-xs\:z-0 {
    z-index: 0 !important;
  }

  .gt-xs\:z-10 {
    z-index: 10 !important;
  }

  .gt-xs\:z-20 {
    z-index: 20 !important;
  }

  .gt-xs\:z-30 {
    z-index: 30 !important;
  }

  .gt-xs\:z-40 {
    z-index: 40 !important;
  }

  .gt-xs\:z-50 {
    z-index: 50 !important;
  }

  .gt-xs\:z-60 {
    z-index: 60 !important;
  }

  .gt-xs\:z-70 {
    z-index: 70 !important;
  }

  .gt-xs\:z-80 {
    z-index: 80 !important;
  }

  .gt-xs\:z-90 {
    z-index: 90 !important;
  }

  .gt-xs\:z-99 {
    z-index: 99 !important;
  }

  .gt-xs\:z-999 {
    z-index: 999 !important;
  }

  .gt-xs\:z-9999 {
    z-index: 9999 !important;
  }

  .gt-xs\:z-99999 {
    z-index: 99999 !important;
  }

  .gt-xs\:z-auto {
    z-index: auto !important;
  }

  .gt-xs\:-z-1 {
    z-index: -1 !important;
  }

  .gt-xs\:gap-0 {
    grid-gap: 0 !important;
    gap: 0 !important;
  }

  .gt-xs\:gap-1 {
    grid-gap: 0.25rem !important;
    gap: 0.25rem !important;
  }

  .gt-xs\:gap-2 {
    grid-gap: 0.5rem !important;
    gap: 0.5rem !important;
  }

  .gt-xs\:gap-3 {
    grid-gap: 0.75rem !important;
    gap: 0.75rem !important;
  }

  .gt-xs\:gap-4 {
    grid-gap: 1rem !important;
    gap: 1rem !important;
  }

  .gt-xs\:gap-5 {
    grid-gap: 1.25rem !important;
    gap: 1.25rem !important;
  }

  .gt-xs\:gap-6 {
    grid-gap: 1.5rem !important;
    gap: 1.5rem !important;
  }

  .gt-xs\:gap-8 {
    grid-gap: 2rem !important;
    gap: 2rem !important;
  }

  .gt-xs\:gap-10 {
    grid-gap: 2.5rem !important;
    gap: 2.5rem !important;
  }

  .gt-xs\:gap-12 {
    grid-gap: 3rem !important;
    gap: 3rem !important;
  }

  .gt-xs\:gap-14 {
    grid-gap: 3.5rem !important;
    gap: 3.5rem !important;
  }

  .gt-xs\:gap-16 {
    grid-gap: 4rem !important;
    gap: 4rem !important;
  }

  .gt-xs\:gap-18 {
    grid-gap: 4.5rem !important;
    gap: 4.5rem !important;
  }

  .gt-xs\:gap-20 {
    grid-gap: 5rem !important;
    gap: 5rem !important;
  }

  .gt-xs\:gap-22 {
    grid-gap: 5.5rem !important;
    gap: 5.5rem !important;
  }

  .gt-xs\:gap-24 {
    grid-gap: 6rem !important;
    gap: 6rem !important;
  }

  .gt-xs\:gap-26 {
    grid-gap: 6.5rem !important;
    gap: 6.5rem !important;
  }

  .gt-xs\:gap-28 {
    grid-gap: 7rem !important;
    gap: 7rem !important;
  }

  .gt-xs\:gap-30 {
    grid-gap: 7.5rem !important;
    gap: 7.5rem !important;
  }

  .gt-xs\:gap-32 {
    grid-gap: 8rem !important;
    gap: 8rem !important;
  }

  .gt-xs\:gap-36 {
    grid-gap: 9rem !important;
    gap: 9rem !important;
  }

  .gt-xs\:gap-40 {
    grid-gap: 10rem !important;
    gap: 10rem !important;
  }

  .gt-xs\:gap-48 {
    grid-gap: 12rem !important;
    gap: 12rem !important;
  }

  .gt-xs\:gap-56 {
    grid-gap: 14rem !important;
    gap: 14rem !important;
  }

  .gt-xs\:gap-64 {
    grid-gap: 16rem !important;
    gap: 16rem !important;
  }

  .gt-xs\:gap-px {
    grid-gap: 1px !important;
    gap: 1px !important;
  }

  .gt-xs\:gap-2px {
    grid-gap: 2px !important;
    gap: 2px !important;
  }

  .gt-xs\:gap-x-0 {
    grid-column-gap: 0 !important;
    -moz-column-gap: 0 !important;
         column-gap: 0 !important;
  }

  .gt-xs\:gap-x-1 {
    grid-column-gap: 0.25rem !important;
    -moz-column-gap: 0.25rem !important;
         column-gap: 0.25rem !important;
  }

  .gt-xs\:gap-x-2 {
    grid-column-gap: 0.5rem !important;
    -moz-column-gap: 0.5rem !important;
         column-gap: 0.5rem !important;
  }

  .gt-xs\:gap-x-3 {
    grid-column-gap: 0.75rem !important;
    -moz-column-gap: 0.75rem !important;
         column-gap: 0.75rem !important;
  }

  .gt-xs\:gap-x-4 {
    grid-column-gap: 1rem !important;
    -moz-column-gap: 1rem !important;
         column-gap: 1rem !important;
  }

  .gt-xs\:gap-x-5 {
    grid-column-gap: 1.25rem !important;
    -moz-column-gap: 1.25rem !important;
         column-gap: 1.25rem !important;
  }

  .gt-xs\:gap-x-6 {
    grid-column-gap: 1.5rem !important;
    -moz-column-gap: 1.5rem !important;
         column-gap: 1.5rem !important;
  }

  .gt-xs\:gap-x-8 {
    grid-column-gap: 2rem !important;
    -moz-column-gap: 2rem !important;
         column-gap: 2rem !important;
  }

  .gt-xs\:gap-x-10 {
    grid-column-gap: 2.5rem !important;
    -moz-column-gap: 2.5rem !important;
         column-gap: 2.5rem !important;
  }

  .gt-xs\:gap-x-12 {
    grid-column-gap: 3rem !important;
    -moz-column-gap: 3rem !important;
         column-gap: 3rem !important;
  }

  .gt-xs\:gap-x-14 {
    grid-column-gap: 3.5rem !important;
    -moz-column-gap: 3.5rem !important;
         column-gap: 3.5rem !important;
  }

  .gt-xs\:gap-x-16 {
    grid-column-gap: 4rem !important;
    -moz-column-gap: 4rem !important;
         column-gap: 4rem !important;
  }

  .gt-xs\:gap-x-18 {
    grid-column-gap: 4.5rem !important;
    -moz-column-gap: 4.5rem !important;
         column-gap: 4.5rem !important;
  }

  .gt-xs\:gap-x-20 {
    grid-column-gap: 5rem !important;
    -moz-column-gap: 5rem !important;
         column-gap: 5rem !important;
  }

  .gt-xs\:gap-x-22 {
    grid-column-gap: 5.5rem !important;
    -moz-column-gap: 5.5rem !important;
         column-gap: 5.5rem !important;
  }

  .gt-xs\:gap-x-24 {
    grid-column-gap: 6rem !important;
    -moz-column-gap: 6rem !important;
         column-gap: 6rem !important;
  }

  .gt-xs\:gap-x-26 {
    grid-column-gap: 6.5rem !important;
    -moz-column-gap: 6.5rem !important;
         column-gap: 6.5rem !important;
  }

  .gt-xs\:gap-x-28 {
    grid-column-gap: 7rem !important;
    -moz-column-gap: 7rem !important;
         column-gap: 7rem !important;
  }

  .gt-xs\:gap-x-30 {
    grid-column-gap: 7.5rem !important;
    -moz-column-gap: 7.5rem !important;
         column-gap: 7.5rem !important;
  }

  .gt-xs\:gap-x-32 {
    grid-column-gap: 8rem !important;
    -moz-column-gap: 8rem !important;
         column-gap: 8rem !important;
  }

  .gt-xs\:gap-x-36 {
    grid-column-gap: 9rem !important;
    -moz-column-gap: 9rem !important;
         column-gap: 9rem !important;
  }

  .gt-xs\:gap-x-40 {
    grid-column-gap: 10rem !important;
    -moz-column-gap: 10rem !important;
         column-gap: 10rem !important;
  }

  .gt-xs\:gap-x-48 {
    grid-column-gap: 12rem !important;
    -moz-column-gap: 12rem !important;
         column-gap: 12rem !important;
  }

  .gt-xs\:gap-x-56 {
    grid-column-gap: 14rem !important;
    -moz-column-gap: 14rem !important;
         column-gap: 14rem !important;
  }

  .gt-xs\:gap-x-64 {
    grid-column-gap: 16rem !important;
    -moz-column-gap: 16rem !important;
         column-gap: 16rem !important;
  }

  .gt-xs\:gap-x-px {
    grid-column-gap: 1px !important;
    -moz-column-gap: 1px !important;
         column-gap: 1px !important;
  }

  .gt-xs\:gap-x-2px {
    grid-column-gap: 2px !important;
    -moz-column-gap: 2px !important;
         column-gap: 2px !important;
  }

  .gt-xs\:gap-y-0 {
    grid-row-gap: 0 !important;
    row-gap: 0 !important;
  }

  .gt-xs\:gap-y-1 {
    grid-row-gap: 0.25rem !important;
    row-gap: 0.25rem !important;
  }

  .gt-xs\:gap-y-2 {
    grid-row-gap: 0.5rem !important;
    row-gap: 0.5rem !important;
  }

  .gt-xs\:gap-y-3 {
    grid-row-gap: 0.75rem !important;
    row-gap: 0.75rem !important;
  }

  .gt-xs\:gap-y-4 {
    grid-row-gap: 1rem !important;
    row-gap: 1rem !important;
  }

  .gt-xs\:gap-y-5 {
    grid-row-gap: 1.25rem !important;
    row-gap: 1.25rem !important;
  }

  .gt-xs\:gap-y-6 {
    grid-row-gap: 1.5rem !important;
    row-gap: 1.5rem !important;
  }

  .gt-xs\:gap-y-8 {
    grid-row-gap: 2rem !important;
    row-gap: 2rem !important;
  }

  .gt-xs\:gap-y-10 {
    grid-row-gap: 2.5rem !important;
    row-gap: 2.5rem !important;
  }

  .gt-xs\:gap-y-12 {
    grid-row-gap: 3rem !important;
    row-gap: 3rem !important;
  }

  .gt-xs\:gap-y-14 {
    grid-row-gap: 3.5rem !important;
    row-gap: 3.5rem !important;
  }

  .gt-xs\:gap-y-16 {
    grid-row-gap: 4rem !important;
    row-gap: 4rem !important;
  }

  .gt-xs\:gap-y-18 {
    grid-row-gap: 4.5rem !important;
    row-gap: 4.5rem !important;
  }

  .gt-xs\:gap-y-20 {
    grid-row-gap: 5rem !important;
    row-gap: 5rem !important;
  }

  .gt-xs\:gap-y-22 {
    grid-row-gap: 5.5rem !important;
    row-gap: 5.5rem !important;
  }

  .gt-xs\:gap-y-24 {
    grid-row-gap: 6rem !important;
    row-gap: 6rem !important;
  }

  .gt-xs\:gap-y-26 {
    grid-row-gap: 6.5rem !important;
    row-gap: 6.5rem !important;
  }

  .gt-xs\:gap-y-28 {
    grid-row-gap: 7rem !important;
    row-gap: 7rem !important;
  }

  .gt-xs\:gap-y-30 {
    grid-row-gap: 7.5rem !important;
    row-gap: 7.5rem !important;
  }

  .gt-xs\:gap-y-32 {
    grid-row-gap: 8rem !important;
    row-gap: 8rem !important;
  }

  .gt-xs\:gap-y-36 {
    grid-row-gap: 9rem !important;
    row-gap: 9rem !important;
  }

  .gt-xs\:gap-y-40 {
    grid-row-gap: 10rem !important;
    row-gap: 10rem !important;
  }

  .gt-xs\:gap-y-48 {
    grid-row-gap: 12rem !important;
    row-gap: 12rem !important;
  }

  .gt-xs\:gap-y-56 {
    grid-row-gap: 14rem !important;
    row-gap: 14rem !important;
  }

  .gt-xs\:gap-y-64 {
    grid-row-gap: 16rem !important;
    row-gap: 16rem !important;
  }

  .gt-xs\:gap-y-px {
    grid-row-gap: 1px !important;
    row-gap: 1px !important;
  }

  .gt-xs\:gap-y-2px {
    grid-row-gap: 2px !important;
    row-gap: 2px !important;
  }

  .gt-xs\:grid-flow-row {
    grid-auto-flow: row !important;
  }

  .gt-xs\:grid-flow-col {
    grid-auto-flow: column !important;
  }

  .gt-xs\:grid-flow-row-dense {
    grid-auto-flow: row dense !important;
  }

  .gt-xs\:grid-flow-col-dense {
    grid-auto-flow: column dense !important;
  }

  .gt-xs\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }

  .gt-xs\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }

  .gt-xs\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
  }

  .gt-xs\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
  }

  .gt-xs\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr)) !important;
  }

  .gt-xs\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr)) !important;
  }

  .gt-xs\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr)) !important;
  }

  .gt-xs\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr)) !important;
  }

  .gt-xs\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr)) !important;
  }

  .gt-xs\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr)) !important;
  }

  .gt-xs\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr)) !important;
  }

  .gt-xs\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr)) !important;
  }

  .gt-xs\:grid-cols-none {
    grid-template-columns: none !important;
  }

  .gt-xs\:col-auto {
    grid-column: auto !important;
  }

  .gt-xs\:col-span-1 {
    grid-column: span 1 / span 1 !important;
  }

  .gt-xs\:col-span-2 {
    grid-column: span 2 / span 2 !important;
  }

  .gt-xs\:col-span-3 {
    grid-column: span 3 / span 3 !important;
  }

  .gt-xs\:col-span-4 {
    grid-column: span 4 / span 4 !important;
  }

  .gt-xs\:col-span-5 {
    grid-column: span 5 / span 5 !important;
  }

  .gt-xs\:col-span-6 {
    grid-column: span 6 / span 6 !important;
  }

  .gt-xs\:col-span-7 {
    grid-column: span 7 / span 7 !important;
  }

  .gt-xs\:col-span-8 {
    grid-column: span 8 / span 8 !important;
  }

  .gt-xs\:col-span-9 {
    grid-column: span 9 / span 9 !important;
  }

  .gt-xs\:col-span-10 {
    grid-column: span 10 / span 10 !important;
  }

  .gt-xs\:col-span-11 {
    grid-column: span 11 / span 11 !important;
  }

  .gt-xs\:col-span-12 {
    grid-column: span 12 / span 12 !important;
  }

  .gt-xs\:col-start-1 {
    grid-column-start: 1 !important;
  }

  .gt-xs\:col-start-2 {
    grid-column-start: 2 !important;
  }

  .gt-xs\:col-start-3 {
    grid-column-start: 3 !important;
  }

  .gt-xs\:col-start-4 {
    grid-column-start: 4 !important;
  }

  .gt-xs\:col-start-5 {
    grid-column-start: 5 !important;
  }

  .gt-xs\:col-start-6 {
    grid-column-start: 6 !important;
  }

  .gt-xs\:col-start-7 {
    grid-column-start: 7 !important;
  }

  .gt-xs\:col-start-8 {
    grid-column-start: 8 !important;
  }

  .gt-xs\:col-start-9 {
    grid-column-start: 9 !important;
  }

  .gt-xs\:col-start-10 {
    grid-column-start: 10 !important;
  }

  .gt-xs\:col-start-11 {
    grid-column-start: 11 !important;
  }

  .gt-xs\:col-start-12 {
    grid-column-start: 12 !important;
  }

  .gt-xs\:col-start-13 {
    grid-column-start: 13 !important;
  }

  .gt-xs\:col-start-auto {
    grid-column-start: auto !important;
  }

  .gt-xs\:col-end-1 {
    grid-column-end: 1 !important;
  }

  .gt-xs\:col-end-2 {
    grid-column-end: 2 !important;
  }

  .gt-xs\:col-end-3 {
    grid-column-end: 3 !important;
  }

  .gt-xs\:col-end-4 {
    grid-column-end: 4 !important;
  }

  .gt-xs\:col-end-5 {
    grid-column-end: 5 !important;
  }

  .gt-xs\:col-end-6 {
    grid-column-end: 6 !important;
  }

  .gt-xs\:col-end-7 {
    grid-column-end: 7 !important;
  }

  .gt-xs\:col-end-8 {
    grid-column-end: 8 !important;
  }

  .gt-xs\:col-end-9 {
    grid-column-end: 9 !important;
  }

  .gt-xs\:col-end-10 {
    grid-column-end: 10 !important;
  }

  .gt-xs\:col-end-11 {
    grid-column-end: 11 !important;
  }

  .gt-xs\:col-end-12 {
    grid-column-end: 12 !important;
  }

  .gt-xs\:col-end-13 {
    grid-column-end: 13 !important;
  }

  .gt-xs\:col-end-auto {
    grid-column-end: auto !important;
  }

  .gt-xs\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr)) !important;
  }

  .gt-xs\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr)) !important;
  }

  .gt-xs\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr)) !important;
  }

  .gt-xs\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr)) !important;
  }

  .gt-xs\:grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr)) !important;
  }

  .gt-xs\:grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr)) !important;
  }

  .gt-xs\:grid-rows-none {
    grid-template-rows: none !important;
  }

  .gt-xs\:row-auto {
    grid-row: auto !important;
  }

  .gt-xs\:row-span-1 {
    grid-row: span 1 / span 1 !important;
  }

  .gt-xs\:row-span-2 {
    grid-row: span 2 / span 2 !important;
  }

  .gt-xs\:row-span-3 {
    grid-row: span 3 / span 3 !important;
  }

  .gt-xs\:row-span-4 {
    grid-row: span 4 / span 4 !important;
  }

  .gt-xs\:row-span-5 {
    grid-row: span 5 / span 5 !important;
  }

  .gt-xs\:row-span-6 {
    grid-row: span 6 / span 6 !important;
  }

  .gt-xs\:row-start-1 {
    grid-row-start: 1 !important;
  }

  .gt-xs\:row-start-2 {
    grid-row-start: 2 !important;
  }

  .gt-xs\:row-start-3 {
    grid-row-start: 3 !important;
  }

  .gt-xs\:row-start-4 {
    grid-row-start: 4 !important;
  }

  .gt-xs\:row-start-5 {
    grid-row-start: 5 !important;
  }

  .gt-xs\:row-start-6 {
    grid-row-start: 6 !important;
  }

  .gt-xs\:row-start-7 {
    grid-row-start: 7 !important;
  }

  .gt-xs\:row-start-auto {
    grid-row-start: auto !important;
  }

  .gt-xs\:row-end-1 {
    grid-row-end: 1 !important;
  }

  .gt-xs\:row-end-2 {
    grid-row-end: 2 !important;
  }

  .gt-xs\:row-end-3 {
    grid-row-end: 3 !important;
  }

  .gt-xs\:row-end-4 {
    grid-row-end: 4 !important;
  }

  .gt-xs\:row-end-5 {
    grid-row-end: 5 !important;
  }

  .gt-xs\:row-end-6 {
    grid-row-end: 6 !important;
  }

  .gt-xs\:row-end-7 {
    grid-row-end: 7 !important;
  }

  .gt-xs\:row-end-auto {
    grid-row-end: auto !important;
  }

  .gt-xs\:transform {
    --transform-translate-x: 0 !important;
    --transform-translate-y: 0 !important;
    --transform-rotate: 0 !important;
    --transform-skew-x: 0 !important;
    --transform-skew-y: 0 !important;
    --transform-scale-x: 1 !important;
    --transform-scale-y: 1 !important;
    transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y)) !important;
  }

  .gt-xs\:transform-none {
    transform: none !important;
  }

  .gt-xs\:origin-center {
    transform-origin: center !important;
  }

  .gt-xs\:origin-top {
    transform-origin: top !important;
  }

  .gt-xs\:origin-top-right {
    transform-origin: top right !important;
  }

  .gt-xs\:origin-right {
    transform-origin: right !important;
  }

  .gt-xs\:origin-bottom-right {
    transform-origin: bottom right !important;
  }

  .gt-xs\:origin-bottom {
    transform-origin: bottom !important;
  }

  .gt-xs\:origin-bottom-left {
    transform-origin: bottom left !important;
  }

  .gt-xs\:origin-left {
    transform-origin: left !important;
  }

  .gt-xs\:origin-top-left {
    transform-origin: top left !important;
  }

  .gt-xs\:icon-size-12 {
    width: 12px !important;
    height: 12px !important;
    min-width: 12px !important;
    min-height: 12px !important;
    font-size: 12px !important;
    line-height: 12px !important;
  }

  .gt-xs\:icon-size-12 svg {
    width: 12px !important;
    height: 12px !important;
  }

  .gt-xs\:icon-size-14 {
    width: 14px !important;
    height: 14px !important;
    min-width: 14px !important;
    min-height: 14px !important;
    font-size: 14px !important;
    line-height: 14px !important;
  }

  .gt-xs\:icon-size-14 svg {
    width: 14px !important;
    height: 14px !important;
  }

  .gt-xs\:icon-size-16 {
    width: 16px !important;
    height: 16px !important;
    min-width: 16px !important;
    min-height: 16px !important;
    font-size: 16px !important;
    line-height: 16px !important;
  }

  .gt-xs\:icon-size-16 svg {
    width: 16px !important;
    height: 16px !important;
  }

  .gt-xs\:icon-size-18 {
    width: 18px !important;
    height: 18px !important;
    min-width: 18px !important;
    min-height: 18px !important;
    font-size: 18px !important;
    line-height: 18px !important;
  }

  .gt-xs\:icon-size-18 svg {
    width: 18px !important;
    height: 18px !important;
  }

  .gt-xs\:icon-size-20 {
    width: 20px !important;
    height: 20px !important;
    min-width: 20px !important;
    min-height: 20px !important;
    font-size: 20px !important;
    line-height: 20px !important;
  }

  .gt-xs\:icon-size-20 svg {
    width: 20px !important;
    height: 20px !important;
  }

  .gt-xs\:icon-size-24 {
    width: 24px !important;
    height: 24px !important;
    min-width: 24px !important;
    min-height: 24px !important;
    font-size: 24px !important;
    line-height: 24px !important;
  }

  .gt-xs\:icon-size-24 svg {
    width: 24px !important;
    height: 24px !important;
  }

  .gt-xs\:icon-size-32 {
    width: 32px !important;
    height: 32px !important;
    min-width: 32px !important;
    min-height: 32px !important;
    font-size: 32px !important;
    line-height: 32px !important;
  }

  .gt-xs\:icon-size-32 svg {
    width: 32px !important;
    height: 32px !important;
  }

  .gt-xs\:icon-size-40 {
    width: 40px !important;
    height: 40px !important;
    min-width: 40px !important;
    min-height: 40px !important;
    font-size: 40px !important;
    line-height: 40px !important;
  }

  .gt-xs\:icon-size-40 svg {
    width: 40px !important;
    height: 40px !important;
  }

  .gt-xs\:icon-size-48 {
    width: 48px !important;
    height: 48px !important;
    min-width: 48px !important;
    min-height: 48px !important;
    font-size: 48px !important;
    line-height: 48px !important;
  }

  .gt-xs\:icon-size-48 svg {
    width: 48px !important;
    height: 48px !important;
  }

  .gt-xs\:icon-size-56 {
    width: 56px !important;
    height: 56px !important;
    min-width: 56px !important;
    min-height: 56px !important;
    font-size: 56px !important;
    line-height: 56px !important;
  }

  .gt-xs\:icon-size-56 svg {
    width: 56px !important;
    height: 56px !important;
  }

  .gt-xs\:icon-size-64 {
    width: 64px !important;
    height: 64px !important;
    min-width: 64px !important;
    min-height: 64px !important;
    font-size: 64px !important;
    line-height: 64px !important;
  }

  .gt-xs\:icon-size-64 svg {
    width: 64px !important;
    height: 64px !important;
  }

  .gt-xs\:icon-size-72 {
    width: 72px !important;
    height: 72px !important;
    min-width: 72px !important;
    min-height: 72px !important;
    font-size: 72px !important;
    line-height: 72px !important;
  }

  .gt-xs\:icon-size-72 svg {
    width: 72px !important;
    height: 72px !important;
  }

  .gt-xs\:icon-size-80 {
    width: 80px !important;
    height: 80px !important;
    min-width: 80px !important;
    min-height: 80px !important;
    font-size: 80px !important;
    line-height: 80px !important;
  }

  .gt-xs\:icon-size-80 svg {
    width: 80px !important;
    height: 80px !important;
  }

  .gt-xs\:icon-size-88 {
    width: 88px !important;
    height: 88px !important;
    min-width: 88px !important;
    min-height: 88px !important;
    font-size: 88px !important;
    line-height: 88px !important;
  }

  .gt-xs\:icon-size-88 svg {
    width: 88px !important;
    height: 88px !important;
  }

  .gt-xs\:icon-size-96 {
    width: 96px !important;
    height: 96px !important;
    min-width: 96px !important;
    min-height: 96px !important;
    font-size: 96px !important;
    line-height: 96px !important;
  }

  .gt-xs\:icon-size-96 svg {
    width: 96px !important;
    height: 96px !important;
  }
}

@media (min-width: 960px) {
  .gt-sm\:space-y-0 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(0px * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(0px * var(--space-y-reverse)) !important;
  }

  .gt-sm\:space-x-0 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(0px * var(--space-x-reverse)) !important;
    margin-left: calc(0px * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-sm\:space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(0.25rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(0.25rem * var(--space-y-reverse)) !important;
  }

  .gt-sm\:space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(0.25rem * var(--space-x-reverse)) !important;
    margin-left: calc(0.25rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-sm\:space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(0.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(0.5rem * var(--space-y-reverse)) !important;
  }

  .gt-sm\:space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(0.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(0.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-sm\:space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(0.75rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(0.75rem * var(--space-y-reverse)) !important;
  }

  .gt-sm\:space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(0.75rem * var(--space-x-reverse)) !important;
    margin-left: calc(0.75rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-sm\:space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(1rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(1rem * var(--space-y-reverse)) !important;
  }

  .gt-sm\:space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(1rem * var(--space-x-reverse)) !important;
    margin-left: calc(1rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-sm\:space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(1.25rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(1.25rem * var(--space-y-reverse)) !important;
  }

  .gt-sm\:space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(1.25rem * var(--space-x-reverse)) !important;
    margin-left: calc(1.25rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-sm\:space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(1.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(1.5rem * var(--space-y-reverse)) !important;
  }

  .gt-sm\:space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(1.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(1.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-sm\:space-y-8 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(2rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(2rem * var(--space-y-reverse)) !important;
  }

  .gt-sm\:space-x-8 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(2rem * var(--space-x-reverse)) !important;
    margin-left: calc(2rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-sm\:space-y-10 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(2.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(2.5rem * var(--space-y-reverse)) !important;
  }

  .gt-sm\:space-x-10 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(2.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(2.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-sm\:space-y-12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(3rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(3rem * var(--space-y-reverse)) !important;
  }

  .gt-sm\:space-x-12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(3rem * var(--space-x-reverse)) !important;
    margin-left: calc(3rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-sm\:space-y-14 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(3.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(3.5rem * var(--space-y-reverse)) !important;
  }

  .gt-sm\:space-x-14 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(3.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(3.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-sm\:space-y-16 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(4rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(4rem * var(--space-y-reverse)) !important;
  }

  .gt-sm\:space-x-16 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(4rem * var(--space-x-reverse)) !important;
    margin-left: calc(4rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-sm\:space-y-18 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(4.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(4.5rem * var(--space-y-reverse)) !important;
  }

  .gt-sm\:space-x-18 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(4.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(4.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-sm\:space-y-20 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(5rem * var(--space-y-reverse)) !important;
  }

  .gt-sm\:space-x-20 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(5rem * var(--space-x-reverse)) !important;
    margin-left: calc(5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-sm\:space-y-22 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(5.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(5.5rem * var(--space-y-reverse)) !important;
  }

  .gt-sm\:space-x-22 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(5.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(5.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-sm\:space-y-24 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(6rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(6rem * var(--space-y-reverse)) !important;
  }

  .gt-sm\:space-x-24 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(6rem * var(--space-x-reverse)) !important;
    margin-left: calc(6rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-sm\:space-y-26 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(6.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(6.5rem * var(--space-y-reverse)) !important;
  }

  .gt-sm\:space-x-26 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(6.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(6.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-sm\:space-y-28 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(7rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(7rem * var(--space-y-reverse)) !important;
  }

  .gt-sm\:space-x-28 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(7rem * var(--space-x-reverse)) !important;
    margin-left: calc(7rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-sm\:space-y-30 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(7.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(7.5rem * var(--space-y-reverse)) !important;
  }

  .gt-sm\:space-x-30 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(7.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(7.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-sm\:space-y-32 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(8rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(8rem * var(--space-y-reverse)) !important;
  }

  .gt-sm\:space-x-32 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(8rem * var(--space-x-reverse)) !important;
    margin-left: calc(8rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-sm\:space-y-36 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(9rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(9rem * var(--space-y-reverse)) !important;
  }

  .gt-sm\:space-x-36 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(9rem * var(--space-x-reverse)) !important;
    margin-left: calc(9rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-sm\:space-y-40 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(10rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(10rem * var(--space-y-reverse)) !important;
  }

  .gt-sm\:space-x-40 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(10rem * var(--space-x-reverse)) !important;
    margin-left: calc(10rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-sm\:space-y-48 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(12rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(12rem * var(--space-y-reverse)) !important;
  }

  .gt-sm\:space-x-48 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(12rem * var(--space-x-reverse)) !important;
    margin-left: calc(12rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-sm\:space-y-56 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(14rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(14rem * var(--space-y-reverse)) !important;
  }

  .gt-sm\:space-x-56 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(14rem * var(--space-x-reverse)) !important;
    margin-left: calc(14rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-sm\:space-y-64 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(16rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(16rem * var(--space-y-reverse)) !important;
  }

  .gt-sm\:space-x-64 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(16rem * var(--space-x-reverse)) !important;
    margin-left: calc(16rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-sm\:space-y-px > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(1px * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(1px * var(--space-y-reverse)) !important;
  }

  .gt-sm\:space-x-px > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(1px * var(--space-x-reverse)) !important;
    margin-left: calc(1px * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-sm\:space-y-2px > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(2px * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(2px * var(--space-y-reverse)) !important;
  }

  .gt-sm\:space-x-2px > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(2px * var(--space-x-reverse)) !important;
    margin-left: calc(2px * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-sm\:-space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-0.25rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-0.25rem * var(--space-y-reverse)) !important;
  }

  .gt-sm\:-space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-0.25rem * var(--space-x-reverse)) !important;
    margin-left: calc(-0.25rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-sm\:-space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-0.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-0.5rem * var(--space-y-reverse)) !important;
  }

  .gt-sm\:-space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-0.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-0.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-sm\:-space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-0.75rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-0.75rem * var(--space-y-reverse)) !important;
  }

  .gt-sm\:-space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-0.75rem * var(--space-x-reverse)) !important;
    margin-left: calc(-0.75rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-sm\:-space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-1rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-1rem * var(--space-y-reverse)) !important;
  }

  .gt-sm\:-space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-1rem * var(--space-x-reverse)) !important;
    margin-left: calc(-1rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-sm\:-space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-1.25rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-1.25rem * var(--space-y-reverse)) !important;
  }

  .gt-sm\:-space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-1.25rem * var(--space-x-reverse)) !important;
    margin-left: calc(-1.25rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-sm\:-space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-1.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-1.5rem * var(--space-y-reverse)) !important;
  }

  .gt-sm\:-space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-1.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-1.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-sm\:-space-y-8 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-2rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-2rem * var(--space-y-reverse)) !important;
  }

  .gt-sm\:-space-x-8 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-2rem * var(--space-x-reverse)) !important;
    margin-left: calc(-2rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-sm\:-space-y-10 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-2.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-2.5rem * var(--space-y-reverse)) !important;
  }

  .gt-sm\:-space-x-10 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-2.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-2.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-sm\:-space-y-12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-3rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-3rem * var(--space-y-reverse)) !important;
  }

  .gt-sm\:-space-x-12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-3rem * var(--space-x-reverse)) !important;
    margin-left: calc(-3rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-sm\:-space-y-14 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-3.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-3.5rem * var(--space-y-reverse)) !important;
  }

  .gt-sm\:-space-x-14 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-3.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-3.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-sm\:-space-y-16 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-4rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-4rem * var(--space-y-reverse)) !important;
  }

  .gt-sm\:-space-x-16 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-4rem * var(--space-x-reverse)) !important;
    margin-left: calc(-4rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-sm\:-space-y-18 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-4.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-4.5rem * var(--space-y-reverse)) !important;
  }

  .gt-sm\:-space-x-18 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-4.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-4.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-sm\:-space-y-20 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-5rem * var(--space-y-reverse)) !important;
  }

  .gt-sm\:-space-x-20 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-sm\:-space-y-22 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-5.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-5.5rem * var(--space-y-reverse)) !important;
  }

  .gt-sm\:-space-x-22 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-5.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-5.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-sm\:-space-y-24 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-6rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-6rem * var(--space-y-reverse)) !important;
  }

  .gt-sm\:-space-x-24 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-6rem * var(--space-x-reverse)) !important;
    margin-left: calc(-6rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-sm\:-space-y-26 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-6.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-6.5rem * var(--space-y-reverse)) !important;
  }

  .gt-sm\:-space-x-26 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-6.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-6.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-sm\:-space-y-28 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-7rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-7rem * var(--space-y-reverse)) !important;
  }

  .gt-sm\:-space-x-28 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-7rem * var(--space-x-reverse)) !important;
    margin-left: calc(-7rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-sm\:-space-y-30 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-7.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-7.5rem * var(--space-y-reverse)) !important;
  }

  .gt-sm\:-space-x-30 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-7.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-7.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-sm\:-space-y-32 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-8rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-8rem * var(--space-y-reverse)) !important;
  }

  .gt-sm\:-space-x-32 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-8rem * var(--space-x-reverse)) !important;
    margin-left: calc(-8rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-sm\:-space-y-36 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-9rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-9rem * var(--space-y-reverse)) !important;
  }

  .gt-sm\:-space-x-36 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-9rem * var(--space-x-reverse)) !important;
    margin-left: calc(-9rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-sm\:-space-y-40 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-10rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-10rem * var(--space-y-reverse)) !important;
  }

  .gt-sm\:-space-x-40 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-10rem * var(--space-x-reverse)) !important;
    margin-left: calc(-10rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-sm\:-space-y-48 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-12rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-12rem * var(--space-y-reverse)) !important;
  }

  .gt-sm\:-space-x-48 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-12rem * var(--space-x-reverse)) !important;
    margin-left: calc(-12rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-sm\:-space-y-56 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-14rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-14rem * var(--space-y-reverse)) !important;
  }

  .gt-sm\:-space-x-56 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-14rem * var(--space-x-reverse)) !important;
    margin-left: calc(-14rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-sm\:-space-y-64 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-16rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-16rem * var(--space-y-reverse)) !important;
  }

  .gt-sm\:-space-x-64 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-16rem * var(--space-x-reverse)) !important;
    margin-left: calc(-16rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-sm\:-space-y-px > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-1px * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-1px * var(--space-y-reverse)) !important;
  }

  .gt-sm\:-space-x-px > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-1px * var(--space-x-reverse)) !important;
    margin-left: calc(-1px * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-sm\:-space-y-2px > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-2px * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-2px * var(--space-y-reverse)) !important;
  }

  .gt-sm\:-space-x-2px > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-2px * var(--space-x-reverse)) !important;
    margin-left: calc(-2px * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-sm\:space-y-reverse > :not(template) ~ :not(template) {
    --space-y-reverse: 1 !important;
  }

  .gt-sm\:space-x-reverse > :not(template) ~ :not(template) {
    --space-x-reverse: 1 !important;
  }

  .gt-sm\:divide-y-0 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(0px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(0px * var(--divide-y-reverse)) !important;
  }

  .gt-sm\:divide-x-0 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(0px * var(--divide-x-reverse)) !important;
    border-left-width: calc(0px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .gt-sm\:divide-y-2 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(2px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(2px * var(--divide-y-reverse)) !important;
  }

  .gt-sm\:divide-x-2 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(2px * var(--divide-x-reverse)) !important;
    border-left-width: calc(2px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .gt-sm\:divide-y-4 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(4px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(4px * var(--divide-y-reverse)) !important;
  }

  .gt-sm\:divide-x-4 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(4px * var(--divide-x-reverse)) !important;
    border-left-width: calc(4px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .gt-sm\:divide-y-8 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(8px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(8px * var(--divide-y-reverse)) !important;
  }

  .gt-sm\:divide-x-8 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(8px * var(--divide-x-reverse)) !important;
    border-left-width: calc(8px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .gt-sm\:divide-y > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(1px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(1px * var(--divide-y-reverse)) !important;
  }

  .gt-sm\:divide-x > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(1px * var(--divide-x-reverse)) !important;
    border-left-width: calc(1px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .gt-sm\:divide-y-reverse > :not(template) ~ :not(template) {
    --divide-y-reverse: 1 !important;
  }

  .gt-sm\:divide-x-reverse > :not(template) ~ :not(template) {
    --divide-x-reverse: 1 !important;
  }

  .gt-sm\:divide-current > :not(template) ~ :not(template) {
    border-color: currentColor !important;
  }

  .gt-sm\:divide-transparent > :not(template) ~ :not(template) {
    border-color: transparent !important;
  }

  .gt-sm\:divide-white > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FFFFFF !important;
    border-color: rgba(255, 255, 255, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-black > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #000000 !important;
    border-color: rgba(0, 0, 0, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-gray-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F9FAFB !important;
    border-color: rgba(249, 250, 251, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-gray-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F4F5F7 !important;
    border-color: rgba(244, 245, 247, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-gray-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E5E7EB !important;
    border-color: rgba(229, 231, 235, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-gray-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #D2D6DC !important;
    border-color: rgba(210, 214, 220, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-gray-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9FA6B2 !important;
    border-color: rgba(159, 166, 178, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-gray-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #6B7280 !important;
    border-color: rgba(107, 114, 128, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-gray-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #4B5563 !important;
    border-color: rgba(75, 85, 99, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-gray-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #374151 !important;
    border-color: rgba(55, 65, 81, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-gray-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #252F3F !important;
    border-color: rgba(37, 47, 63, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-gray-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #161E2E !important;
    border-color: rgba(22, 30, 46, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-gray > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #6B7280 !important;
    border-color: rgba(107, 114, 128, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-cool-gray-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FBFDFE !important;
    border-color: rgba(251, 253, 254, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-cool-gray-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F1F5F9 !important;
    border-color: rgba(241, 245, 249, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-cool-gray-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E2E8F0 !important;
    border-color: rgba(226, 232, 240, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-cool-gray-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #CFD8E3 !important;
    border-color: rgba(207, 216, 227, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-cool-gray-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #97A6BA !important;
    border-color: rgba(151, 166, 186, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-cool-gray-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #64748B !important;
    border-color: rgba(100, 116, 139, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-cool-gray-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #475569 !important;
    border-color: rgba(71, 85, 105, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-cool-gray-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #364152 !important;
    border-color: rgba(54, 65, 82, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-cool-gray-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #27303F !important;
    border-color: rgba(39, 48, 63, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-cool-gray-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #1A202E !important;
    border-color: rgba(26, 32, 46, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-cool-gray > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #64748B !important;
    border-color: rgba(100, 116, 139, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-red-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FDF2F2 !important;
    border-color: rgba(253, 242, 242, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-red-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FDE8E8 !important;
    border-color: rgba(253, 232, 232, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-red-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FBD5D5 !important;
    border-color: rgba(251, 213, 213, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-red-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F8B4B4 !important;
    border-color: rgba(248, 180, 180, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-red-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F98080 !important;
    border-color: rgba(249, 128, 128, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-red-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F05252 !important;
    border-color: rgba(240, 82, 82, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-red-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E02424 !important;
    border-color: rgba(224, 36, 36, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-red-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #C81E1E !important;
    border-color: rgba(200, 30, 30, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-red-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9B1C1C !important;
    border-color: rgba(155, 28, 28, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-red-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #771D1D !important;
    border-color: rgba(119, 29, 29, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-red > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F05252 !important;
    border-color: rgba(240, 82, 82, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-orange-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FFF8F1 !important;
    border-color: rgba(255, 248, 241, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-orange-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FEECDC !important;
    border-color: rgba(254, 236, 220, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-orange-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FCD9BD !important;
    border-color: rgba(252, 217, 189, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-orange-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FDBA8C !important;
    border-color: rgba(253, 186, 140, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-orange-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FF8A4C !important;
    border-color: rgba(255, 138, 76, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-orange-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FF5A1F !important;
    border-color: rgba(255, 90, 31, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-orange-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #ff6700 !important;
    border-color: rgba(255, 103, 0, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-orange-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #B43403 !important;
    border-color: rgba(180, 52, 3, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-orange-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #8A2C0D !important;
    border-color: rgba(138, 44, 13, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-orange-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #771D1D !important;
    border-color: rgba(119, 29, 29, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-orange > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FF5A1F !important;
    border-color: rgba(255, 90, 31, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-yellow-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FDFDEA !important;
    border-color: rgba(253, 253, 234, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-yellow-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FDF6B2 !important;
    border-color: rgba(253, 246, 178, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-yellow-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FCE96A !important;
    border-color: rgba(252, 233, 106, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-yellow-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FACA15 !important;
    border-color: rgba(250, 202, 21, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-yellow-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E3A008 !important;
    border-color: rgba(227, 160, 8, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-yellow-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #C27803 !important;
    border-color: rgba(194, 120, 3, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-yellow-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9F580A !important;
    border-color: rgba(159, 88, 10, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-yellow-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #8E4B10 !important;
    border-color: rgba(142, 75, 16, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-yellow-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #723B13 !important;
    border-color: rgba(114, 59, 19, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-yellow-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #633112 !important;
    border-color: rgba(99, 49, 18, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-yellow > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #C27803 !important;
    border-color: rgba(194, 120, 3, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-green-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F3FAF7 !important;
    border-color: rgba(243, 250, 247, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-green-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #DEF7EC !important;
    border-color: rgba(222, 247, 236, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-green-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #BCF0DA !important;
    border-color: rgba(188, 240, 218, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-green-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #84E1BC !important;
    border-color: rgba(132, 225, 188, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-green-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #31C48D !important;
    border-color: rgba(49, 196, 141, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-green-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #0E9F6E !important;
    border-color: rgba(14, 159, 110, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-green-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #057A55 !important;
    border-color: rgba(5, 122, 85, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-green-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #046C4E !important;
    border-color: rgba(4, 108, 78, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-green-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #03543F !important;
    border-color: rgba(3, 84, 63, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-green-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #014737 !important;
    border-color: rgba(1, 71, 55, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-green > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #0E9F6E !important;
    border-color: rgba(14, 159, 110, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-teal-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #EDFAFA !important;
    border-color: rgba(237, 250, 250, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-teal-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #D5F5F6 !important;
    border-color: rgba(213, 245, 246, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-teal-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #AFECEF !important;
    border-color: rgba(175, 236, 239, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-teal-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #7EDCE2 !important;
    border-color: rgba(126, 220, 226, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-teal-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #16BDCA !important;
    border-color: rgba(22, 189, 202, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-teal-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #0694A2 !important;
    border-color: rgba(6, 148, 162, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-teal-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #047481 !important;
    border-color: rgba(4, 116, 129, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-teal-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #036672 !important;
    border-color: rgba(3, 102, 114, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-teal-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #05505C !important;
    border-color: rgba(5, 80, 92, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-teal-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #014451 !important;
    border-color: rgba(1, 68, 81, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-teal > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #0694A2 !important;
    border-color: rgba(6, 148, 162, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-blue-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #EBF5FF !important;
    border-color: rgba(235, 245, 255, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-blue-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E1EFFE !important;
    border-color: rgba(225, 239, 254, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-blue-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #C3DDFD !important;
    border-color: rgba(195, 221, 253, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-blue-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #A4CAFE !important;
    border-color: rgba(164, 202, 254, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-blue-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #76A9FA !important;
    border-color: rgba(118, 169, 250, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-blue-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #3F83F8 !important;
    border-color: rgba(63, 131, 248, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-blue-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #1C64F2 !important;
    border-color: rgba(28, 100, 242, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-blue-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #1A56DB !important;
    border-color: rgba(26, 86, 219, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-blue-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #1E429F !important;
    border-color: rgba(30, 66, 159, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-blue-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #233876 !important;
    border-color: rgba(35, 56, 118, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-blue > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #3F83F8 !important;
    border-color: rgba(63, 131, 248, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-indigo-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F0F5FF !important;
    border-color: rgba(240, 245, 255, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-indigo-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E5EDFF !important;
    border-color: rgba(229, 237, 255, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-indigo-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #CDDBFE !important;
    border-color: rgba(205, 219, 254, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-indigo-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #B4C6FC !important;
    border-color: rgba(180, 198, 252, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-indigo-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #8DA2FB !important;
    border-color: rgba(141, 162, 251, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-indigo-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #6875F5 !important;
    border-color: rgba(104, 117, 245, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-indigo-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #5850EC !important;
    border-color: rgba(88, 80, 236, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-indigo-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #5145CD !important;
    border-color: rgba(81, 69, 205, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-indigo-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #42389D !important;
    border-color: rgba(66, 56, 157, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-indigo-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #362F78 !important;
    border-color: rgba(54, 47, 120, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-indigo > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #6875F5 !important;
    border-color: rgba(104, 117, 245, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-purple-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F6F5FF !important;
    border-color: rgba(246, 245, 255, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-purple-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #EDEBFE !important;
    border-color: rgba(237, 235, 254, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-purple-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #DCD7FE !important;
    border-color: rgba(220, 215, 254, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-purple-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #CABFFD !important;
    border-color: rgba(202, 191, 253, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-purple-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #AC94FA !important;
    border-color: rgba(172, 148, 250, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-purple-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9061F9 !important;
    border-color: rgba(144, 97, 249, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-purple-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #7E3AF2 !important;
    border-color: rgba(126, 58, 242, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-purple-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #6C2BD9 !important;
    border-color: rgba(108, 43, 217, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-purple-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #5521B5 !important;
    border-color: rgba(85, 33, 181, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-purple-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #4A1D96 !important;
    border-color: rgba(74, 29, 150, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-purple > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9061F9 !important;
    border-color: rgba(144, 97, 249, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-pink-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FDF2F8 !important;
    border-color: rgba(253, 242, 248, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-pink-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FCE8F3 !important;
    border-color: rgba(252, 232, 243, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-pink-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FAD1E8 !important;
    border-color: rgba(250, 209, 232, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-pink-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F8B4D9 !important;
    border-color: rgba(248, 180, 217, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-pink-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F17EB8 !important;
    border-color: rgba(241, 126, 184, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-pink-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E74694 !important;
    border-color: rgba(231, 70, 148, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-pink-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #D61F69 !important;
    border-color: rgba(214, 31, 105, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-pink-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #BF125D !important;
    border-color: rgba(191, 18, 93, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-pink-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #99154B !important;
    border-color: rgba(153, 21, 75, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-pink-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #751A3D !important;
    border-color: rgba(117, 26, 61, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-pink > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E74694 !important;
    border-color: rgba(231, 70, 148, var(--divide-opacity)) !important;
  }

  .gt-sm\:divide-opacity-0 > :not(template) ~ :not(template) {
    --divide-opacity: 0 !important;
  }

  .gt-sm\:divide-opacity-12 > :not(template) ~ :not(template) {
    --divide-opacity: 0.12 !important;
  }

  .gt-sm\:divide-opacity-25 > :not(template) ~ :not(template) {
    --divide-opacity: 0.25 !important;
  }

  .gt-sm\:divide-opacity-38 > :not(template) ~ :not(template) {
    --divide-opacity: 0.38 !important;
  }

  .gt-sm\:divide-opacity-50 > :not(template) ~ :not(template) {
    --divide-opacity: 0.5 !important;
  }

  .gt-sm\:divide-opacity-54 > :not(template) ~ :not(template) {
    --divide-opacity: 0.54 !important;
  }

  .gt-sm\:divide-opacity-70 > :not(template) ~ :not(template) {
    --divide-opacity: 0.70 !important;
  }

  .gt-sm\:divide-opacity-75 > :not(template) ~ :not(template) {
    --divide-opacity: 0.75 !important;
  }

  .gt-sm\:divide-opacity-84 > :not(template) ~ :not(template) {
    --divide-opacity: 0.84 !important;
  }

  .gt-sm\:divide-opacity-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
  }

  .gt-sm\:sr-only {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border-width: 0 !important;
  }

  .gt-sm\:not-sr-only {
    position: static !important;
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: visible !important;
    clip: auto !important;
    white-space: normal !important;
  }

  .gt-sm\:focus\:sr-only:focus {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border-width: 0 !important;
  }

  .gt-sm\:focus\:not-sr-only:focus {
    position: static !important;
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: visible !important;
    clip: auto !important;
    white-space: normal !important;
  }

  .gt-sm\:appearance-none {
    -webkit-appearance: none !important;
       -moz-appearance: none !important;
            appearance: none !important;
  }

  .gt-sm\:bg-fixed {
    background-attachment: fixed !important;
  }

  .gt-sm\:bg-local {
    background-attachment: local !important;
  }

  .gt-sm\:bg-scroll {
    background-attachment: scroll !important;
  }

  .gt-sm\:bg-opacity-0 {
    --bg-opacity: 0 !important;
  }

  .gt-sm\:bg-opacity-12 {
    --bg-opacity: 0.12 !important;
  }

  .gt-sm\:bg-opacity-25 {
    --bg-opacity: 0.25 !important;
  }

  .gt-sm\:bg-opacity-38 {
    --bg-opacity: 0.38 !important;
  }

  .gt-sm\:bg-opacity-50 {
    --bg-opacity: 0.5 !important;
  }

  .gt-sm\:bg-opacity-54 {
    --bg-opacity: 0.54 !important;
  }

  .gt-sm\:bg-opacity-70 {
    --bg-opacity: 0.70 !important;
  }

  .gt-sm\:bg-opacity-75 {
    --bg-opacity: 0.75 !important;
  }

  .gt-sm\:bg-opacity-84 {
    --bg-opacity: 0.84 !important;
  }

  .gt-sm\:bg-opacity-100 {
    --bg-opacity: 1 !important;
  }

  .gt-sm\:hover\:bg-opacity-0:hover {
    --bg-opacity: 0 !important;
  }

  .gt-sm\:hover\:bg-opacity-12:hover {
    --bg-opacity: 0.12 !important;
  }

  .gt-sm\:hover\:bg-opacity-25:hover {
    --bg-opacity: 0.25 !important;
  }

  .gt-sm\:hover\:bg-opacity-38:hover {
    --bg-opacity: 0.38 !important;
  }

  .gt-sm\:hover\:bg-opacity-50:hover {
    --bg-opacity: 0.5 !important;
  }

  .gt-sm\:hover\:bg-opacity-54:hover {
    --bg-opacity: 0.54 !important;
  }

  .gt-sm\:hover\:bg-opacity-70:hover {
    --bg-opacity: 0.70 !important;
  }

  .gt-sm\:hover\:bg-opacity-75:hover {
    --bg-opacity: 0.75 !important;
  }

  .gt-sm\:hover\:bg-opacity-84:hover {
    --bg-opacity: 0.84 !important;
  }

  .gt-sm\:hover\:bg-opacity-100:hover {
    --bg-opacity: 1 !important;
  }

  .gt-sm\:focus\:bg-opacity-0:focus {
    --bg-opacity: 0 !important;
  }

  .gt-sm\:focus\:bg-opacity-12:focus {
    --bg-opacity: 0.12 !important;
  }

  .gt-sm\:focus\:bg-opacity-25:focus {
    --bg-opacity: 0.25 !important;
  }

  .gt-sm\:focus\:bg-opacity-38:focus {
    --bg-opacity: 0.38 !important;
  }

  .gt-sm\:focus\:bg-opacity-50:focus {
    --bg-opacity: 0.5 !important;
  }

  .gt-sm\:focus\:bg-opacity-54:focus {
    --bg-opacity: 0.54 !important;
  }

  .gt-sm\:focus\:bg-opacity-70:focus {
    --bg-opacity: 0.70 !important;
  }

  .gt-sm\:focus\:bg-opacity-75:focus {
    --bg-opacity: 0.75 !important;
  }

  .gt-sm\:focus\:bg-opacity-84:focus {
    --bg-opacity: 0.84 !important;
  }

  .gt-sm\:focus\:bg-opacity-100:focus {
    --bg-opacity: 1 !important;
  }

  .gt-sm\:bg-bottom {
    background-position: bottom !important;
  }

  .gt-sm\:bg-center {
    background-position: center !important;
  }

  .gt-sm\:bg-left {
    background-position: left !important;
  }

  .gt-sm\:bg-left-bottom {
    background-position: left bottom !important;
  }

  .gt-sm\:bg-left-top {
    background-position: left top !important;
  }

  .gt-sm\:bg-right {
    background-position: right !important;
  }

  .gt-sm\:bg-right-bottom {
    background-position: right bottom !important;
  }

  .gt-sm\:bg-right-top {
    background-position: right top !important;
  }

  .gt-sm\:bg-top {
    background-position: top !important;
  }

  .gt-sm\:bg-repeat {
    background-repeat: repeat !important;
  }

  .gt-sm\:bg-no-repeat {
    background-repeat: no-repeat !important;
  }

  .gt-sm\:bg-repeat-x {
    background-repeat: repeat-x !important;
  }

  .gt-sm\:bg-repeat-y {
    background-repeat: repeat-y !important;
  }

  .gt-sm\:bg-repeat-round {
    background-repeat: round !important;
  }

  .gt-sm\:bg-repeat-space {
    background-repeat: space !important;
  }

  .gt-sm\:bg-auto {
    background-size: auto !important;
  }

  .gt-sm\:bg-cover {
    background-size: cover !important;
  }

  .gt-sm\:bg-contain {
    background-size: contain !important;
  }

  .gt-sm\:border-collapse {
    border-collapse: collapse !important;
  }

  .gt-sm\:border-separate {
    border-collapse: separate !important;
  }

  .gt-sm\:border-opacity-0 {
    --border-opacity: 0 !important;
  }

  .gt-sm\:border-opacity-12 {
    --border-opacity: 0.12 !important;
  }

  .gt-sm\:border-opacity-25 {
    --border-opacity: 0.25 !important;
  }

  .gt-sm\:border-opacity-38 {
    --border-opacity: 0.38 !important;
  }

  .gt-sm\:border-opacity-50 {
    --border-opacity: 0.5 !important;
  }

  .gt-sm\:border-opacity-54 {
    --border-opacity: 0.54 !important;
  }

  .gt-sm\:border-opacity-70 {
    --border-opacity: 0.70 !important;
  }

  .gt-sm\:border-opacity-75 {
    --border-opacity: 0.75 !important;
  }

  .gt-sm\:border-opacity-84 {
    --border-opacity: 0.84 !important;
  }

  .gt-sm\:border-opacity-100 {
    --border-opacity: 1 !important;
  }

  .gt-sm\:hover\:border-opacity-0:hover {
    --border-opacity: 0 !important;
  }

  .gt-sm\:hover\:border-opacity-12:hover {
    --border-opacity: 0.12 !important;
  }

  .gt-sm\:hover\:border-opacity-25:hover {
    --border-opacity: 0.25 !important;
  }

  .gt-sm\:hover\:border-opacity-38:hover {
    --border-opacity: 0.38 !important;
  }

  .gt-sm\:hover\:border-opacity-50:hover {
    --border-opacity: 0.5 !important;
  }

  .gt-sm\:hover\:border-opacity-54:hover {
    --border-opacity: 0.54 !important;
  }

  .gt-sm\:hover\:border-opacity-70:hover {
    --border-opacity: 0.70 !important;
  }

  .gt-sm\:hover\:border-opacity-75:hover {
    --border-opacity: 0.75 !important;
  }

  .gt-sm\:hover\:border-opacity-84:hover {
    --border-opacity: 0.84 !important;
  }

  .gt-sm\:hover\:border-opacity-100:hover {
    --border-opacity: 1 !important;
  }

  .gt-sm\:focus\:border-opacity-0:focus {
    --border-opacity: 0 !important;
  }

  .gt-sm\:focus\:border-opacity-12:focus {
    --border-opacity: 0.12 !important;
  }

  .gt-sm\:focus\:border-opacity-25:focus {
    --border-opacity: 0.25 !important;
  }

  .gt-sm\:focus\:border-opacity-38:focus {
    --border-opacity: 0.38 !important;
  }

  .gt-sm\:focus\:border-opacity-50:focus {
    --border-opacity: 0.5 !important;
  }

  .gt-sm\:focus\:border-opacity-54:focus {
    --border-opacity: 0.54 !important;
  }

  .gt-sm\:focus\:border-opacity-70:focus {
    --border-opacity: 0.70 !important;
  }

  .gt-sm\:focus\:border-opacity-75:focus {
    --border-opacity: 0.75 !important;
  }

  .gt-sm\:focus\:border-opacity-84:focus {
    --border-opacity: 0.84 !important;
  }

  .gt-sm\:focus\:border-opacity-100:focus {
    --border-opacity: 1 !important;
  }

  .gt-sm\:rounded-none {
    border-radius: 0 !important;
  }

  .gt-sm\:rounded-sm {
    border-radius: 0.125rem !important;
  }

  .gt-sm\:rounded {
    border-radius: 0.25rem !important;
  }

  .gt-sm\:rounded-md {
    border-radius: 0.375rem !important;
  }

  .gt-sm\:rounded-lg {
    border-radius: 0.5rem !important;
  }

  .gt-sm\:rounded-full {
    border-radius: 9999px !important;
  }

  .gt-sm\:rounded-t-none {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .gt-sm\:rounded-r-none {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .gt-sm\:rounded-b-none {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .gt-sm\:rounded-l-none {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .gt-sm\:rounded-t-sm {
    border-top-left-radius: 0.125rem !important;
    border-top-right-radius: 0.125rem !important;
  }

  .gt-sm\:rounded-r-sm {
    border-top-right-radius: 0.125rem !important;
    border-bottom-right-radius: 0.125rem !important;
  }

  .gt-sm\:rounded-b-sm {
    border-bottom-right-radius: 0.125rem !important;
    border-bottom-left-radius: 0.125rem !important;
  }

  .gt-sm\:rounded-l-sm {
    border-top-left-radius: 0.125rem !important;
    border-bottom-left-radius: 0.125rem !important;
  }

  .gt-sm\:rounded-t {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }

  .gt-sm\:rounded-r {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }

  .gt-sm\:rounded-b {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }

  .gt-sm\:rounded-l {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }

  .gt-sm\:rounded-t-md {
    border-top-left-radius: 0.375rem !important;
    border-top-right-radius: 0.375rem !important;
  }

  .gt-sm\:rounded-r-md {
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }

  .gt-sm\:rounded-b-md {
    border-bottom-right-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }

  .gt-sm\:rounded-l-md {
    border-top-left-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }

  .gt-sm\:rounded-t-lg {
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
  }

  .gt-sm\:rounded-r-lg {
    border-top-right-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }

  .gt-sm\:rounded-b-lg {
    border-bottom-right-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }

  .gt-sm\:rounded-l-lg {
    border-top-left-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }

  .gt-sm\:rounded-t-full {
    border-top-left-radius: 9999px !important;
    border-top-right-radius: 9999px !important;
  }

  .gt-sm\:rounded-r-full {
    border-top-right-radius: 9999px !important;
    border-bottom-right-radius: 9999px !important;
  }

  .gt-sm\:rounded-b-full {
    border-bottom-right-radius: 9999px !important;
    border-bottom-left-radius: 9999px !important;
  }

  .gt-sm\:rounded-l-full {
    border-top-left-radius: 9999px !important;
    border-bottom-left-radius: 9999px !important;
  }

  .gt-sm\:rounded-tl-none {
    border-top-left-radius: 0 !important;
  }

  .gt-sm\:rounded-tr-none {
    border-top-right-radius: 0 !important;
  }

  .gt-sm\:rounded-br-none {
    border-bottom-right-radius: 0 !important;
  }

  .gt-sm\:rounded-bl-none {
    border-bottom-left-radius: 0 !important;
  }

  .gt-sm\:rounded-tl-sm {
    border-top-left-radius: 0.125rem !important;
  }

  .gt-sm\:rounded-tr-sm {
    border-top-right-radius: 0.125rem !important;
  }

  .gt-sm\:rounded-br-sm {
    border-bottom-right-radius: 0.125rem !important;
  }

  .gt-sm\:rounded-bl-sm {
    border-bottom-left-radius: 0.125rem !important;
  }

  .gt-sm\:rounded-tl {
    border-top-left-radius: 0.25rem !important;
  }

  .gt-sm\:rounded-tr {
    border-top-right-radius: 0.25rem !important;
  }

  .gt-sm\:rounded-br {
    border-bottom-right-radius: 0.25rem !important;
  }

  .gt-sm\:rounded-bl {
    border-bottom-left-radius: 0.25rem !important;
  }

  .gt-sm\:rounded-tl-md {
    border-top-left-radius: 0.375rem !important;
  }

  .gt-sm\:rounded-tr-md {
    border-top-right-radius: 0.375rem !important;
  }

  .gt-sm\:rounded-br-md {
    border-bottom-right-radius: 0.375rem !important;
  }

  .gt-sm\:rounded-bl-md {
    border-bottom-left-radius: 0.375rem !important;
  }

  .gt-sm\:rounded-tl-lg {
    border-top-left-radius: 0.5rem !important;
  }

  .gt-sm\:rounded-tr-lg {
    border-top-right-radius: 0.5rem !important;
  }

  .gt-sm\:rounded-br-lg {
    border-bottom-right-radius: 0.5rem !important;
  }

  .gt-sm\:rounded-bl-lg {
    border-bottom-left-radius: 0.5rem !important;
  }

  .gt-sm\:rounded-tl-full {
    border-top-left-radius: 9999px !important;
  }

  .gt-sm\:rounded-tr-full {
    border-top-right-radius: 9999px !important;
  }

  .gt-sm\:rounded-br-full {
    border-bottom-right-radius: 9999px !important;
  }

  .gt-sm\:rounded-bl-full {
    border-bottom-left-radius: 9999px !important;
  }

  .gt-sm\:border-solid {
    border-style: solid !important;
  }

  .gt-sm\:border-dashed {
    border-style: dashed !important;
  }

  .gt-sm\:border-dotted {
    border-style: dotted !important;
  }

  .gt-sm\:border-double {
    border-style: double !important;
  }

  .gt-sm\:border-none {
    border-style: none !important;
  }

  .gt-sm\:border-0 {
    border-width: 0 !important;
  }

  .gt-sm\:border-2 {
    border-width: 2px !important;
  }

  .gt-sm\:border-4 {
    border-width: 4px !important;
  }

  .gt-sm\:border-8 {
    border-width: 8px !important;
  }

  .gt-sm\:border {
    border-width: 1px !important;
  }

  .gt-sm\:border-t-0 {
    border-top-width: 0 !important;
  }

  .gt-sm\:border-r-0 {
    border-right-width: 0 !important;
  }

  .gt-sm\:border-b-0 {
    border-bottom-width: 0 !important;
  }

  .gt-sm\:border-l-0 {
    border-left-width: 0 !important;
  }

  .gt-sm\:border-t-2 {
    border-top-width: 2px !important;
  }

  .gt-sm\:border-r-2 {
    border-right-width: 2px !important;
  }

  .gt-sm\:border-b-2 {
    border-bottom-width: 2px !important;
  }

  .gt-sm\:border-l-2 {
    border-left-width: 2px !important;
  }

  .gt-sm\:border-t-4 {
    border-top-width: 4px !important;
  }

  .gt-sm\:border-r-4 {
    border-right-width: 4px !important;
  }

  .gt-sm\:border-b-4 {
    border-bottom-width: 4px !important;
  }

  .gt-sm\:border-l-4 {
    border-left-width: 4px !important;
  }

  .gt-sm\:border-t-8 {
    border-top-width: 8px !important;
  }

  .gt-sm\:border-r-8 {
    border-right-width: 8px !important;
  }

  .gt-sm\:border-b-8 {
    border-bottom-width: 8px !important;
  }

  .gt-sm\:border-l-8 {
    border-left-width: 8px !important;
  }

  .gt-sm\:border-t {
    border-top-width: 1px !important;
  }

  .gt-sm\:border-r {
    border-right-width: 1px !important;
  }

  .gt-sm\:border-b {
    border-bottom-width: 1px !important;
  }

  .gt-sm\:border-l {
    border-left-width: 1px !important;
  }

  .gt-sm\:first\:border-0:first-child {
    border-width: 0 !important;
  }

  .gt-sm\:first\:border-2:first-child {
    border-width: 2px !important;
  }

  .gt-sm\:first\:border-4:first-child {
    border-width: 4px !important;
  }

  .gt-sm\:first\:border-8:first-child {
    border-width: 8px !important;
  }

  .gt-sm\:first\:border:first-child {
    border-width: 1px !important;
  }

  .gt-sm\:first\:border-t-0:first-child {
    border-top-width: 0 !important;
  }

  .gt-sm\:first\:border-r-0:first-child {
    border-right-width: 0 !important;
  }

  .gt-sm\:first\:border-b-0:first-child {
    border-bottom-width: 0 !important;
  }

  .gt-sm\:first\:border-l-0:first-child {
    border-left-width: 0 !important;
  }

  .gt-sm\:first\:border-t-2:first-child {
    border-top-width: 2px !important;
  }

  .gt-sm\:first\:border-r-2:first-child {
    border-right-width: 2px !important;
  }

  .gt-sm\:first\:border-b-2:first-child {
    border-bottom-width: 2px !important;
  }

  .gt-sm\:first\:border-l-2:first-child {
    border-left-width: 2px !important;
  }

  .gt-sm\:first\:border-t-4:first-child {
    border-top-width: 4px !important;
  }

  .gt-sm\:first\:border-r-4:first-child {
    border-right-width: 4px !important;
  }

  .gt-sm\:first\:border-b-4:first-child {
    border-bottom-width: 4px !important;
  }

  .gt-sm\:first\:border-l-4:first-child {
    border-left-width: 4px !important;
  }

  .gt-sm\:first\:border-t-8:first-child {
    border-top-width: 8px !important;
  }

  .gt-sm\:first\:border-r-8:first-child {
    border-right-width: 8px !important;
  }

  .gt-sm\:first\:border-b-8:first-child {
    border-bottom-width: 8px !important;
  }

  .gt-sm\:first\:border-l-8:first-child {
    border-left-width: 8px !important;
  }

  .gt-sm\:first\:border-t:first-child {
    border-top-width: 1px !important;
  }

  .gt-sm\:first\:border-r:first-child {
    border-right-width: 1px !important;
  }

  .gt-sm\:first\:border-b:first-child {
    border-bottom-width: 1px !important;
  }

  .gt-sm\:first\:border-l:first-child {
    border-left-width: 1px !important;
  }

  .gt-sm\:last\:border-0:last-child {
    border-width: 0 !important;
  }

  .gt-sm\:last\:border-2:last-child {
    border-width: 2px !important;
  }

  .gt-sm\:last\:border-4:last-child {
    border-width: 4px !important;
  }

  .gt-sm\:last\:border-8:last-child {
    border-width: 8px !important;
  }

  .gt-sm\:last\:border:last-child {
    border-width: 1px !important;
  }

  .gt-sm\:last\:border-t-0:last-child {
    border-top-width: 0 !important;
  }

  .gt-sm\:last\:border-r-0:last-child {
    border-right-width: 0 !important;
  }

  .gt-sm\:last\:border-b-0:last-child {
    border-bottom-width: 0 !important;
  }

  .gt-sm\:last\:border-l-0:last-child {
    border-left-width: 0 !important;
  }

  .gt-sm\:last\:border-t-2:last-child {
    border-top-width: 2px !important;
  }

  .gt-sm\:last\:border-r-2:last-child {
    border-right-width: 2px !important;
  }

  .gt-sm\:last\:border-b-2:last-child {
    border-bottom-width: 2px !important;
  }

  .gt-sm\:last\:border-l-2:last-child {
    border-left-width: 2px !important;
  }

  .gt-sm\:last\:border-t-4:last-child {
    border-top-width: 4px !important;
  }

  .gt-sm\:last\:border-r-4:last-child {
    border-right-width: 4px !important;
  }

  .gt-sm\:last\:border-b-4:last-child {
    border-bottom-width: 4px !important;
  }

  .gt-sm\:last\:border-l-4:last-child {
    border-left-width: 4px !important;
  }

  .gt-sm\:last\:border-t-8:last-child {
    border-top-width: 8px !important;
  }

  .gt-sm\:last\:border-r-8:last-child {
    border-right-width: 8px !important;
  }

  .gt-sm\:last\:border-b-8:last-child {
    border-bottom-width: 8px !important;
  }

  .gt-sm\:last\:border-l-8:last-child {
    border-left-width: 8px !important;
  }

  .gt-sm\:last\:border-t:last-child {
    border-top-width: 1px !important;
  }

  .gt-sm\:last\:border-r:last-child {
    border-right-width: 1px !important;
  }

  .gt-sm\:last\:border-b:last-child {
    border-bottom-width: 1px !important;
  }

  .gt-sm\:last\:border-l:last-child {
    border-left-width: 1px !important;
  }

  .gt-sm\:box-border {
    box-sizing: border-box !important;
  }

  .gt-sm\:box-content {
    box-sizing: content-box !important;
  }

  .gt-sm\:block {
    display: block !important;
  }

  .gt-sm\:inline-block {
    display: inline-block !important;
  }

  .gt-sm\:inline {
    display: inline !important;
  }

  .gt-sm\:flex {
    display: flex !important;
  }

  .gt-sm\:inline-flex {
    display: inline-flex !important;
  }

  .gt-sm\:table {
    display: table !important;
  }

  .gt-sm\:table-caption {
    display: table-caption !important;
  }

  .gt-sm\:table-cell {
    display: table-cell !important;
  }

  .gt-sm\:table-column {
    display: table-column !important;
  }

  .gt-sm\:table-column-group {
    display: table-column-group !important;
  }

  .gt-sm\:table-footer-group {
    display: table-footer-group !important;
  }

  .gt-sm\:table-header-group {
    display: table-header-group !important;
  }

  .gt-sm\:table-row-group {
    display: table-row-group !important;
  }

  .gt-sm\:table-row {
    display: table-row !important;
  }

  .gt-sm\:flow-root {
    display: flow-root !important;
  }

  .gt-sm\:grid {
    display: grid !important;
  }

  .gt-sm\:inline-grid {
    display: inline-grid !important;
  }

  .gt-sm\:hidden {
    display: none !important;
  }

  .gt-sm\:flex-row {
    flex-direction: row !important;
  }

  .gt-sm\:flex-row-reverse {
    flex-direction: row-reverse !important;
  }

  .gt-sm\:flex-col {
    flex-direction: column !important;
  }

  .gt-sm\:flex-col-reverse {
    flex-direction: column-reverse !important;
  }

  .gt-sm\:flex-wrap {
    flex-wrap: wrap !important;
  }

  .gt-sm\:flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gt-sm\:flex-nowrap {
    flex-wrap: nowrap !important;
  }

  .gt-sm\:items-start {
    align-items: flex-start !important;
  }

  .gt-sm\:items-end {
    align-items: flex-end !important;
  }

  .gt-sm\:items-center {
    align-items: center !important;
  }

  .gt-sm\:items-baseline {
    align-items: baseline !important;
  }

  .gt-sm\:items-stretch {
    align-items: stretch !important;
  }

  .gt-sm\:self-auto {
    align-self: auto !important;
  }

  .gt-sm\:self-start {
    align-self: flex-start !important;
  }

  .gt-sm\:self-end {
    align-self: flex-end !important;
  }

  .gt-sm\:self-center {
    align-self: center !important;
  }

  .gt-sm\:self-stretch {
    align-self: stretch !important;
  }

  .gt-sm\:justify-start {
    justify-content: flex-start !important;
  }

  .gt-sm\:justify-end {
    justify-content: flex-end !important;
  }

  .gt-sm\:justify-center {
    justify-content: center !important;
  }

  .gt-sm\:justify-between {
    justify-content: space-between !important;
  }

  .gt-sm\:justify-around {
    justify-content: space-around !important;
  }

  .gt-sm\:justify-evenly {
    justify-content: space-evenly !important;
  }

  .gt-sm\:content-center {
    align-content: center !important;
  }

  .gt-sm\:content-start {
    align-content: flex-start !important;
  }

  .gt-sm\:content-end {
    align-content: flex-end !important;
  }

  .gt-sm\:content-between {
    align-content: space-between !important;
  }

  .gt-sm\:content-around {
    align-content: space-around !important;
  }

  .gt-sm\:flex-0 {
    flex: 0 0 auto !important;
  }

  .gt-sm\:flex-1 {
    flex: 1 1 0% !important;
  }

  .gt-sm\:flex-auto {
    flex: 1 1 auto !important;
  }

  .gt-sm\:flex-initial {
    flex: 0 1 auto !important;
  }

  .gt-sm\:flex-none {
    flex: none !important;
  }

  .gt-sm\:flex-grow-0 {
    flex-grow: 0 !important;
  }

  .gt-sm\:flex-grow {
    flex-grow: 1 !important;
  }

  .gt-sm\:flex-shrink-0 {
    flex-shrink: 0 !important;
  }

  .gt-sm\:flex-shrink {
    flex-shrink: 1 !important;
  }

  .gt-sm\:order-1 {
    order: 1 !important;
  }

  .gt-sm\:order-2 {
    order: 2 !important;
  }

  .gt-sm\:order-3 {
    order: 3 !important;
  }

  .gt-sm\:order-4 {
    order: 4 !important;
  }

  .gt-sm\:order-5 {
    order: 5 !important;
  }

  .gt-sm\:order-6 {
    order: 6 !important;
  }

  .gt-sm\:order-7 {
    order: 7 !important;
  }

  .gt-sm\:order-8 {
    order: 8 !important;
  }

  .gt-sm\:order-9 {
    order: 9 !important;
  }

  .gt-sm\:order-10 {
    order: 10 !important;
  }

  .gt-sm\:order-11 {
    order: 11 !important;
  }

  .gt-sm\:order-12 {
    order: 12 !important;
  }

  .gt-sm\:order-first {
    order: -9999 !important;
  }

  .gt-sm\:order-last {
    order: 9999 !important;
  }

  .gt-sm\:order-none {
    order: 0 !important;
  }

  .gt-sm\:font-thin {
    font-weight: 100 !important;
  }

  .gt-sm\:font-extralight {
    font-weight: 200 !important;
  }

  .gt-sm\:font-light {
    font-weight: 300 !important;
  }

  .gt-sm\:font-normal {
    font-weight: 400 !important;
  }

  .gt-sm\:font-medium {
    font-weight: 500 !important;
  }

  .gt-sm\:font-semibold {
    font-weight: 600 !important;
  }

  .gt-sm\:font-bold {
    font-weight: 700 !important;
  }

  .gt-sm\:font-extrabold {
    font-weight: 800 !important;
  }

  .gt-sm\:font-black {
    font-weight: 900 !important;
  }

  .gt-sm\:h-0 {
    height: 0 !important;
  }

  .gt-sm\:h-1 {
    height: 0.25rem !important;
  }

  .gt-sm\:h-2 {
    height: 0.5rem !important;
  }

  .gt-sm\:h-3 {
    height: 0.75rem !important;
  }

  .gt-sm\:h-4 {
    height: 1rem !important;
  }

  .gt-sm\:h-5 {
    height: 1.25rem !important;
  }

  .gt-sm\:h-6 {
    height: 1.5rem !important;
  }

  .gt-sm\:h-8 {
    height: 2rem !important;
  }

  .gt-sm\:h-10 {
    height: 2.5rem !important;
  }

  .gt-sm\:h-12 {
    height: 3rem !important;
  }

  .gt-sm\:h-14 {
    height: 3.5rem !important;
  }

  .gt-sm\:h-16 {
    height: 4rem !important;
  }

  .gt-sm\:h-18 {
    height: 4.5rem !important;
  }

  .gt-sm\:h-20 {
    height: 5rem !important;
  }

  .gt-sm\:h-22 {
    height: 5.5rem !important;
  }

  .gt-sm\:h-24 {
    height: 6rem !important;
  }

  .gt-sm\:h-26 {
    height: 6.5rem !important;
  }

  .gt-sm\:h-28 {
    height: 7rem !important;
  }

  .gt-sm\:h-30 {
    height: 7.5rem !important;
  }

  .gt-sm\:h-32 {
    height: 8rem !important;
  }

  .gt-sm\:h-36 {
    height: 9rem !important;
  }

  .gt-sm\:h-40 {
    height: 10rem !important;
  }

  .gt-sm\:h-48 {
    height: 12rem !important;
  }

  .gt-sm\:h-50 {
    height: 12.5rem !important;
  }

  .gt-sm\:h-56 {
    height: 14rem !important;
  }

  .gt-sm\:h-60 {
    height: 15rem !important;
  }

  .gt-sm\:h-64 {
    height: 16rem !important;
  }

  .gt-sm\:h-80 {
    height: 20rem !important;
  }

  .gt-sm\:h-90 {
    height: 24rem !important;
  }

  .gt-sm\:h-100 {
    height: 25rem !important;
  }

  .gt-sm\:h-120 {
    height: 30rem !important;
  }

  .gt-sm\:h-128 {
    height: 32rem !important;
  }

  .gt-sm\:h-140 {
    height: 35rem !important;
  }

  .gt-sm\:h-160 {
    height: 40rem !important;
  }

  .gt-sm\:h-180 {
    height: 45rem !important;
  }

  .gt-sm\:h-192 {
    height: 48rem !important;
  }

  .gt-sm\:h-200 {
    height: 50rem !important;
  }

  .gt-sm\:h-240 {
    height: 60rem !important;
  }

  .gt-sm\:h-256 {
    height: 64rem !important;
  }

  .gt-sm\:h-280 {
    height: 70rem !important;
  }

  .gt-sm\:h-320 {
    height: 80rem !important;
  }

  .gt-sm\:h-360 {
    height: 90rem !important;
  }

  .gt-sm\:h-400 {
    height: 100rem !important;
  }

  .gt-sm\:h-480 {
    height: 120rem !important;
  }

  .gt-sm\:h-auto {
    height: auto !important;
  }

  .gt-sm\:h-px {
    height: 1px !important;
  }

  .gt-sm\:h-2px {
    height: 2px !important;
  }

  .gt-sm\:h-full {
    height: 100% !important;
  }

  .gt-sm\:h-screen {
    height: 100vh !important;
  }

  .gt-sm\:h-1\/2 {
    height: 50% !important;
  }

  .gt-sm\:h-1\/3 {
    height: 33.33333% !important;
  }

  .gt-sm\:h-2\/3 {
    height: 66.66667% !important;
  }

  .gt-sm\:h-1\/4 {
    height: 25% !important;
  }

  .gt-sm\:h-2\/4 {
    height: 50% !important;
  }

  .gt-sm\:h-3\/4 {
    height: 75% !important;
  }

  .gt-sm\:h-1\/5 {
    height: 20% !important;
  }

  .gt-sm\:h-2\/5 {
    height: 40% !important;
  }

  .gt-sm\:h-3\/5 {
    height: 60% !important;
  }

  .gt-sm\:h-4\/5 {
    height: 80% !important;
  }

  .gt-sm\:h-1\/12 {
    height: 8.33333% !important;
  }

  .gt-sm\:h-2\/12 {
    height: 16.66667% !important;
  }

  .gt-sm\:h-3\/12 {
    height: 25% !important;
  }

  .gt-sm\:h-4\/12 {
    height: 33.33333% !important;
  }

  .gt-sm\:h-5\/12 {
    height: 41.66667% !important;
  }

  .gt-sm\:h-6\/12 {
    height: 50% !important;
  }

  .gt-sm\:h-7\/12 {
    height: 58.33333% !important;
  }

  .gt-sm\:h-8\/12 {
    height: 66.66667% !important;
  }

  .gt-sm\:h-9\/12 {
    height: 75% !important;
  }

  .gt-sm\:h-10\/12 {
    height: 83.33333% !important;
  }

  .gt-sm\:h-11\/12 {
    height: 91.66667% !important;
  }

  .gt-sm\:text-xs {
    font-size: 0.625rem !important;
  }

  .gt-sm\:text-sm {
    font-size: 0.75rem !important;
  }

  .gt-sm\:text-md {
    font-size: 0.8125rem !important;
  }

  .gt-sm\:text-base {
    font-size: 0.875rem !important;
  }

  .gt-sm\:text-lg {
    font-size: 1rem !important;
  }

  .gt-sm\:text-xl {
    font-size: 1.125rem !important;
  }

  .gt-sm\:text-2xl {
    font-size: 1.25rem !important;
  }

  .gt-sm\:text-3xl {
    font-size: 1.5rem !important;
  }

  .gt-sm\:text-4xl {
    font-size: 2rem !important;
  }

  .gt-sm\:text-5xl {
    font-size: 2.25rem !important;
  }

  .gt-sm\:text-6xl {
    font-size: 2.5rem !important;
  }

  .gt-sm\:text-7xl {
    font-size: 3rem !important;
  }

  .gt-sm\:text-8xl {
    font-size: 4rem !important;
  }

  .gt-sm\:text-9xl {
    font-size: 6rem !important;
  }

  .gt-sm\:text-10xl {
    font-size: 8rem !important;
  }

  .gt-sm\:leading-3 {
    line-height: .75rem !important;
  }

  .gt-sm\:leading-4 {
    line-height: 1rem !important;
  }

  .gt-sm\:leading-5 {
    line-height: 1.25rem !important;
  }

  .gt-sm\:leading-6 {
    line-height: 1.5rem !important;
  }

  .gt-sm\:leading-7 {
    line-height: 1.75rem !important;
  }

  .gt-sm\:leading-8 {
    line-height: 2rem !important;
  }

  .gt-sm\:leading-9 {
    line-height: 2.25rem !important;
  }

  .gt-sm\:leading-10 {
    line-height: 2.5rem !important;
  }

  .gt-sm\:leading-none {
    line-height: 1 !important;
  }

  .gt-sm\:leading-tight {
    line-height: 1.25 !important;
  }

  .gt-sm\:leading-snug {
    line-height: 1.375 !important;
  }

  .gt-sm\:leading-normal {
    line-height: 1.5 !important;
  }

  .gt-sm\:leading-relaxed {
    line-height: 1.625 !important;
  }

  .gt-sm\:leading-loose {
    line-height: 2 !important;
  }

  .gt-sm\:list-inside {
    list-style-position: inside !important;
  }

  .gt-sm\:list-outside {
    list-style-position: outside !important;
  }

  .gt-sm\:list-none {
    list-style-type: none !important;
  }

  .gt-sm\:list-disc {
    list-style-type: disc !important;
  }

  .gt-sm\:list-decimal {
    list-style-type: decimal !important;
  }

  .gt-sm\:m-0 {
    margin: 0 !important;
  }

  .gt-sm\:m-1 {
    margin: 0.25rem !important;
  }

  .gt-sm\:m-2 {
    margin: 0.5rem !important;
  }

  .gt-sm\:m-3 {
    margin: 0.75rem !important;
  }

  .gt-sm\:m-4 {
    margin: 1rem !important;
  }

  .gt-sm\:m-5 {
    margin: 1.25rem !important;
  }

  .gt-sm\:m-6 {
    margin: 1.5rem !important;
  }

  .gt-sm\:m-8 {
    margin: 2rem !important;
  }

  .gt-sm\:m-10 {
    margin: 2.5rem !important;
  }

  .gt-sm\:m-12 {
    margin: 3rem !important;
  }

  .gt-sm\:m-14 {
    margin: 3.5rem !important;
  }

  .gt-sm\:m-16 {
    margin: 4rem !important;
  }

  .gt-sm\:m-18 {
    margin: 4.5rem !important;
  }

  .gt-sm\:m-20 {
    margin: 5rem !important;
  }

  .gt-sm\:m-22 {
    margin: 5.5rem !important;
  }

  .gt-sm\:m-24 {
    margin: 6rem !important;
  }

  .gt-sm\:m-26 {
    margin: 6.5rem !important;
  }

  .gt-sm\:m-28 {
    margin: 7rem !important;
  }

  .gt-sm\:m-30 {
    margin: 7.5rem !important;
  }

  .gt-sm\:m-32 {
    margin: 8rem !important;
  }

  .gt-sm\:m-36 {
    margin: 9rem !important;
  }

  .gt-sm\:m-40 {
    margin: 10rem !important;
  }

  .gt-sm\:m-48 {
    margin: 12rem !important;
  }

  .gt-sm\:m-56 {
    margin: 14rem !important;
  }

  .gt-sm\:m-64 {
    margin: 16rem !important;
  }

  .gt-sm\:m-auto {
    margin: auto !important;
  }

  .gt-sm\:m-px {
    margin: 1px !important;
  }

  .gt-sm\:m-2px {
    margin: 2px !important;
  }

  .gt-sm\:-m-1 {
    margin: -0.25rem !important;
  }

  .gt-sm\:-m-2 {
    margin: -0.5rem !important;
  }

  .gt-sm\:-m-3 {
    margin: -0.75rem !important;
  }

  .gt-sm\:-m-4 {
    margin: -1rem !important;
  }

  .gt-sm\:-m-5 {
    margin: -1.25rem !important;
  }

  .gt-sm\:-m-6 {
    margin: -1.5rem !important;
  }

  .gt-sm\:-m-8 {
    margin: -2rem !important;
  }

  .gt-sm\:-m-10 {
    margin: -2.5rem !important;
  }

  .gt-sm\:-m-12 {
    margin: -3rem !important;
  }

  .gt-sm\:-m-14 {
    margin: -3.5rem !important;
  }

  .gt-sm\:-m-16 {
    margin: -4rem !important;
  }

  .gt-sm\:-m-18 {
    margin: -4.5rem !important;
  }

  .gt-sm\:-m-20 {
    margin: -5rem !important;
  }

  .gt-sm\:-m-22 {
    margin: -5.5rem !important;
  }

  .gt-sm\:-m-24 {
    margin: -6rem !important;
  }

  .gt-sm\:-m-26 {
    margin: -6.5rem !important;
  }

  .gt-sm\:-m-28 {
    margin: -7rem !important;
  }

  .gt-sm\:-m-30 {
    margin: -7.5rem !important;
  }

  .gt-sm\:-m-32 {
    margin: -8rem !important;
  }

  .gt-sm\:-m-36 {
    margin: -9rem !important;
  }

  .gt-sm\:-m-40 {
    margin: -10rem !important;
  }

  .gt-sm\:-m-48 {
    margin: -12rem !important;
  }

  .gt-sm\:-m-56 {
    margin: -14rem !important;
  }

  .gt-sm\:-m-64 {
    margin: -16rem !important;
  }

  .gt-sm\:-m-px {
    margin: -1px !important;
  }

  .gt-sm\:-m-2px {
    margin: -2px !important;
  }

  .gt-sm\:my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .gt-sm\:mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .gt-sm\:my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .gt-sm\:mx-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }

  .gt-sm\:my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .gt-sm\:mx-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .gt-sm\:my-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .gt-sm\:mx-3 {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
  }

  .gt-sm\:my-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .gt-sm\:mx-4 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .gt-sm\:my-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .gt-sm\:mx-5 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }

  .gt-sm\:my-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .gt-sm\:mx-6 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .gt-sm\:my-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .gt-sm\:mx-8 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .gt-sm\:my-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .gt-sm\:mx-10 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .gt-sm\:my-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .gt-sm\:mx-12 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .gt-sm\:my-14 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .gt-sm\:mx-14 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }

  .gt-sm\:my-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .gt-sm\:mx-16 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .gt-sm\:my-18 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .gt-sm\:mx-18 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }

  .gt-sm\:my-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .gt-sm\:mx-20 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .gt-sm\:my-22 {
    margin-top: 5.5rem !important;
    margin-bottom: 5.5rem !important;
  }

  .gt-sm\:mx-22 {
    margin-left: 5.5rem !important;
    margin-right: 5.5rem !important;
  }

  .gt-sm\:my-24 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .gt-sm\:mx-24 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }

  .gt-sm\:my-26 {
    margin-top: 6.5rem !important;
    margin-bottom: 6.5rem !important;
  }

  .gt-sm\:mx-26 {
    margin-left: 6.5rem !important;
    margin-right: 6.5rem !important;
  }

  .gt-sm\:my-28 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }

  .gt-sm\:mx-28 {
    margin-left: 7rem !important;
    margin-right: 7rem !important;
  }

  .gt-sm\:my-30 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }

  .gt-sm\:mx-30 {
    margin-left: 7.5rem !important;
    margin-right: 7.5rem !important;
  }

  .gt-sm\:my-32 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .gt-sm\:mx-32 {
    margin-left: 8rem !important;
    margin-right: 8rem !important;
  }

  .gt-sm\:my-36 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }

  .gt-sm\:mx-36 {
    margin-left: 9rem !important;
    margin-right: 9rem !important;
  }

  .gt-sm\:my-40 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .gt-sm\:mx-40 {
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }

  .gt-sm\:my-48 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }

  .gt-sm\:mx-48 {
    margin-left: 12rem !important;
    margin-right: 12rem !important;
  }

  .gt-sm\:my-56 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }

  .gt-sm\:mx-56 {
    margin-left: 14rem !important;
    margin-right: 14rem !important;
  }

  .gt-sm\:my-64 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }

  .gt-sm\:mx-64 {
    margin-left: 16rem !important;
    margin-right: 16rem !important;
  }

  .gt-sm\:my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .gt-sm\:mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .gt-sm\:my-px {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  .gt-sm\:mx-px {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }

  .gt-sm\:my-2px {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }

  .gt-sm\:mx-2px {
    margin-left: 2px !important;
    margin-right: 2px !important;
  }

  .gt-sm\:-my-1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .gt-sm\:-mx-1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }

  .gt-sm\:-my-2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .gt-sm\:-mx-2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }

  .gt-sm\:-my-3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }

  .gt-sm\:-mx-3 {
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important;
  }

  .gt-sm\:-my-4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .gt-sm\:-mx-4 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .gt-sm\:-my-5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .gt-sm\:-mx-5 {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }

  .gt-sm\:-my-6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .gt-sm\:-mx-6 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .gt-sm\:-my-8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .gt-sm\:-mx-8 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .gt-sm\:-my-10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .gt-sm\:-mx-10 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }

  .gt-sm\:-my-12 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .gt-sm\:-mx-12 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .gt-sm\:-my-14 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .gt-sm\:-mx-14 {
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }

  .gt-sm\:-my-16 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .gt-sm\:-mx-16 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .gt-sm\:-my-18 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .gt-sm\:-mx-18 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }

  .gt-sm\:-my-20 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .gt-sm\:-mx-20 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .gt-sm\:-my-22 {
    margin-top: -5.5rem !important;
    margin-bottom: -5.5rem !important;
  }

  .gt-sm\:-mx-22 {
    margin-left: -5.5rem !important;
    margin-right: -5.5rem !important;
  }

  .gt-sm\:-my-24 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .gt-sm\:-mx-24 {
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }

  .gt-sm\:-my-26 {
    margin-top: -6.5rem !important;
    margin-bottom: -6.5rem !important;
  }

  .gt-sm\:-mx-26 {
    margin-left: -6.5rem !important;
    margin-right: -6.5rem !important;
  }

  .gt-sm\:-my-28 {
    margin-top: -7rem !important;
    margin-bottom: -7rem !important;
  }

  .gt-sm\:-mx-28 {
    margin-left: -7rem !important;
    margin-right: -7rem !important;
  }

  .gt-sm\:-my-30 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important;
  }

  .gt-sm\:-mx-30 {
    margin-left: -7.5rem !important;
    margin-right: -7.5rem !important;
  }

  .gt-sm\:-my-32 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .gt-sm\:-mx-32 {
    margin-left: -8rem !important;
    margin-right: -8rem !important;
  }

  .gt-sm\:-my-36 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }

  .gt-sm\:-mx-36 {
    margin-left: -9rem !important;
    margin-right: -9rem !important;
  }

  .gt-sm\:-my-40 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .gt-sm\:-mx-40 {
    margin-left: -10rem !important;
    margin-right: -10rem !important;
  }

  .gt-sm\:-my-48 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }

  .gt-sm\:-mx-48 {
    margin-left: -12rem !important;
    margin-right: -12rem !important;
  }

  .gt-sm\:-my-56 {
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }

  .gt-sm\:-mx-56 {
    margin-left: -14rem !important;
    margin-right: -14rem !important;
  }

  .gt-sm\:-my-64 {
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }

  .gt-sm\:-mx-64 {
    margin-left: -16rem !important;
    margin-right: -16rem !important;
  }

  .gt-sm\:-my-px {
    margin-top: -1px !important;
    margin-bottom: -1px !important;
  }

  .gt-sm\:-mx-px {
    margin-left: -1px !important;
    margin-right: -1px !important;
  }

  .gt-sm\:-my-2px {
    margin-top: -2px !important;
    margin-bottom: -2px !important;
  }

  .gt-sm\:-mx-2px {
    margin-left: -2px !important;
    margin-right: -2px !important;
  }

  .gt-sm\:mt-0 {
    margin-top: 0 !important;
  }

  .gt-sm\:mr-0 {
    margin-right: 0 !important;
  }

  .gt-sm\:mb-0 {
    margin-bottom: 0 !important;
  }

  .gt-sm\:ml-0 {
    margin-left: 0 !important;
  }

  .gt-sm\:mt-1 {
    margin-top: 0.25rem !important;
  }

  .gt-sm\:mr-1 {
    margin-right: 0.25rem !important;
  }

  .gt-sm\:mb-1 {
    margin-bottom: 0.25rem !important;
  }

  .gt-sm\:ml-1 {
    margin-left: 0.25rem !important;
  }

  .gt-sm\:mt-2 {
    margin-top: 0.5rem !important;
  }

  .gt-sm\:mr-2 {
    margin-right: 0.5rem !important;
  }

  .gt-sm\:mb-2 {
    margin-bottom: 0.5rem !important;
  }

  .gt-sm\:ml-2 {
    margin-left: 0.5rem !important;
  }

  .gt-sm\:mt-3 {
    margin-top: 0.75rem !important;
  }

  .gt-sm\:mr-3 {
    margin-right: 0.75rem !important;
  }

  .gt-sm\:mb-3 {
    margin-bottom: 0.75rem !important;
  }

  .gt-sm\:ml-3 {
    margin-left: 0.75rem !important;
  }

  .gt-sm\:mt-4 {
    margin-top: 1rem !important;
  }

  .gt-sm\:mr-4 {
    margin-right: 1rem !important;
  }

  .gt-sm\:mb-4 {
    margin-bottom: 1rem !important;
  }

  .gt-sm\:ml-4 {
    margin-left: 1rem !important;
  }

  .gt-sm\:mt-5 {
    margin-top: 1.25rem !important;
  }

  .gt-sm\:mr-5 {
    margin-right: 1.25rem !important;
  }

  .gt-sm\:mb-5 {
    margin-bottom: 1.25rem !important;
  }

  .gt-sm\:ml-5 {
    margin-left: 1.25rem !important;
  }

  .gt-sm\:mt-6 {
    margin-top: 1.5rem !important;
  }

  .gt-sm\:mr-6 {
    margin-right: 1.5rem !important;
  }

  .gt-sm\:mb-6 {
    margin-bottom: 1.5rem !important;
  }

  .gt-sm\:ml-6 {
    margin-left: 1.5rem !important;
  }

  .gt-sm\:mt-8 {
    margin-top: 2rem !important;
  }

  .gt-sm\:mr-8 {
    margin-right: 2rem !important;
  }

  .gt-sm\:mb-8 {
    margin-bottom: 2rem !important;
  }

  .gt-sm\:ml-8 {
    margin-left: 2rem !important;
  }

  .gt-sm\:mt-10 {
    margin-top: 2.5rem !important;
  }

  .gt-sm\:mr-10 {
    margin-right: 2.5rem !important;
  }

  .gt-sm\:mb-10 {
    margin-bottom: 2.5rem !important;
  }

  .gt-sm\:ml-10 {
    margin-left: 2.5rem !important;
  }

  .gt-sm\:mt-12 {
    margin-top: 3rem !important;
  }

  .gt-sm\:mr-12 {
    margin-right: 3rem !important;
  }

  .gt-sm\:mb-12 {
    margin-bottom: 3rem !important;
  }

  .gt-sm\:ml-12 {
    margin-left: 3rem !important;
  }

  .gt-sm\:mt-14 {
    margin-top: 3.5rem !important;
  }

  .gt-sm\:mr-14 {
    margin-right: 3.5rem !important;
  }

  .gt-sm\:mb-14 {
    margin-bottom: 3.5rem !important;
  }

  .gt-sm\:ml-14 {
    margin-left: 3.5rem !important;
  }

  .gt-sm\:mt-16 {
    margin-top: 4rem !important;
  }

  .gt-sm\:mr-16 {
    margin-right: 4rem !important;
  }

  .gt-sm\:mb-16 {
    margin-bottom: 4rem !important;
  }

  .gt-sm\:ml-16 {
    margin-left: 4rem !important;
  }

  .gt-sm\:mt-18 {
    margin-top: 4.5rem !important;
  }

  .gt-sm\:mr-18 {
    margin-right: 4.5rem !important;
  }

  .gt-sm\:mb-18 {
    margin-bottom: 4.5rem !important;
  }

  .gt-sm\:ml-18 {
    margin-left: 4.5rem !important;
  }

  .gt-sm\:mt-20 {
    margin-top: 5rem !important;
  }

  .gt-sm\:mr-20 {
    margin-right: 5rem !important;
  }

  .gt-sm\:mb-20 {
    margin-bottom: 5rem !important;
  }

  .gt-sm\:ml-20 {
    margin-left: 5rem !important;
  }

  .gt-sm\:mt-22 {
    margin-top: 5.5rem !important;
  }

  .gt-sm\:mr-22 {
    margin-right: 5.5rem !important;
  }

  .gt-sm\:mb-22 {
    margin-bottom: 5.5rem !important;
  }

  .gt-sm\:ml-22 {
    margin-left: 5.5rem !important;
  }

  .gt-sm\:mt-24 {
    margin-top: 6rem !important;
  }

  .gt-sm\:mr-24 {
    margin-right: 6rem !important;
  }

  .gt-sm\:mb-24 {
    margin-bottom: 6rem !important;
  }

  .gt-sm\:ml-24 {
    margin-left: 6rem !important;
  }

  .gt-sm\:mt-26 {
    margin-top: 6.5rem !important;
  }

  .gt-sm\:mr-26 {
    margin-right: 6.5rem !important;
  }

  .gt-sm\:mb-26 {
    margin-bottom: 6.5rem !important;
  }

  .gt-sm\:ml-26 {
    margin-left: 6.5rem !important;
  }

  .gt-sm\:mt-28 {
    margin-top: 7rem !important;
  }

  .gt-sm\:mr-28 {
    margin-right: 7rem !important;
  }

  .gt-sm\:mb-28 {
    margin-bottom: 7rem !important;
  }

  .gt-sm\:ml-28 {
    margin-left: 7rem !important;
  }

  .gt-sm\:mt-30 {
    margin-top: 7.5rem !important;
  }

  .gt-sm\:mr-30 {
    margin-right: 7.5rem !important;
  }

  .gt-sm\:mb-30 {
    margin-bottom: 7.5rem !important;
  }

  .gt-sm\:ml-30 {
    margin-left: 7.5rem !important;
  }

  .gt-sm\:mt-32 {
    margin-top: 8rem !important;
  }

  .gt-sm\:mr-32 {
    margin-right: 8rem !important;
  }

  .gt-sm\:mb-32 {
    margin-bottom: 8rem !important;
  }

  .gt-sm\:ml-32 {
    margin-left: 8rem !important;
  }

  .gt-sm\:mt-36 {
    margin-top: 9rem !important;
  }

  .gt-sm\:mr-36 {
    margin-right: 9rem !important;
  }

  .gt-sm\:mb-36 {
    margin-bottom: 9rem !important;
  }

  .gt-sm\:ml-36 {
    margin-left: 9rem !important;
  }

  .gt-sm\:mt-40 {
    margin-top: 10rem !important;
  }

  .gt-sm\:mr-40 {
    margin-right: 10rem !important;
  }

  .gt-sm\:mb-40 {
    margin-bottom: 10rem !important;
  }

  .gt-sm\:ml-40 {
    margin-left: 10rem !important;
  }

  .gt-sm\:mt-48 {
    margin-top: 12rem !important;
  }

  .gt-sm\:mr-48 {
    margin-right: 12rem !important;
  }

  .gt-sm\:mb-48 {
    margin-bottom: 12rem !important;
  }

  .gt-sm\:ml-48 {
    margin-left: 12rem !important;
  }

  .gt-sm\:mt-56 {
    margin-top: 14rem !important;
  }

  .gt-sm\:mr-56 {
    margin-right: 14rem !important;
  }

  .gt-sm\:mb-56 {
    margin-bottom: 14rem !important;
  }

  .gt-sm\:ml-56 {
    margin-left: 14rem !important;
  }

  .gt-sm\:mt-64 {
    margin-top: 16rem !important;
  }

  .gt-sm\:mr-64 {
    margin-right: 16rem !important;
  }

  .gt-sm\:mb-64 {
    margin-bottom: 16rem !important;
  }

  .gt-sm\:ml-64 {
    margin-left: 16rem !important;
  }

  .gt-sm\:mt-auto {
    margin-top: auto !important;
  }

  .gt-sm\:mr-auto {
    margin-right: auto !important;
  }

  .gt-sm\:mb-auto {
    margin-bottom: auto !important;
  }

  .gt-sm\:ml-auto {
    margin-left: auto !important;
  }

  .gt-sm\:mt-px {
    margin-top: 1px !important;
  }

  .gt-sm\:mr-px {
    margin-right: 1px !important;
  }

  .gt-sm\:mb-px {
    margin-bottom: 1px !important;
  }

  .gt-sm\:ml-px {
    margin-left: 1px !important;
  }

  .gt-sm\:mt-2px {
    margin-top: 2px !important;
  }

  .gt-sm\:mr-2px {
    margin-right: 2px !important;
  }

  .gt-sm\:mb-2px {
    margin-bottom: 2px !important;
  }

  .gt-sm\:ml-2px {
    margin-left: 2px !important;
  }

  .gt-sm\:-mt-1 {
    margin-top: -0.25rem !important;
  }

  .gt-sm\:-mr-1 {
    margin-right: -0.25rem !important;
  }

  .gt-sm\:-mb-1 {
    margin-bottom: -0.25rem !important;
  }

  .gt-sm\:-ml-1 {
    margin-left: -0.25rem !important;
  }

  .gt-sm\:-mt-2 {
    margin-top: -0.5rem !important;
  }

  .gt-sm\:-mr-2 {
    margin-right: -0.5rem !important;
  }

  .gt-sm\:-mb-2 {
    margin-bottom: -0.5rem !important;
  }

  .gt-sm\:-ml-2 {
    margin-left: -0.5rem !important;
  }

  .gt-sm\:-mt-3 {
    margin-top: -0.75rem !important;
  }

  .gt-sm\:-mr-3 {
    margin-right: -0.75rem !important;
  }

  .gt-sm\:-mb-3 {
    margin-bottom: -0.75rem !important;
  }

  .gt-sm\:-ml-3 {
    margin-left: -0.75rem !important;
  }

  .gt-sm\:-mt-4 {
    margin-top: -1rem !important;
  }

  .gt-sm\:-mr-4 {
    margin-right: -1rem !important;
  }

  .gt-sm\:-mb-4 {
    margin-bottom: -1rem !important;
  }

  .gt-sm\:-ml-4 {
    margin-left: -1rem !important;
  }

  .gt-sm\:-mt-5 {
    margin-top: -1.25rem !important;
  }

  .gt-sm\:-mr-5 {
    margin-right: -1.25rem !important;
  }

  .gt-sm\:-mb-5 {
    margin-bottom: -1.25rem !important;
  }

  .gt-sm\:-ml-5 {
    margin-left: -1.25rem !important;
  }

  .gt-sm\:-mt-6 {
    margin-top: -1.5rem !important;
  }

  .gt-sm\:-mr-6 {
    margin-right: -1.5rem !important;
  }

  .gt-sm\:-mb-6 {
    margin-bottom: -1.5rem !important;
  }

  .gt-sm\:-ml-6 {
    margin-left: -1.5rem !important;
  }

  .gt-sm\:-mt-8 {
    margin-top: -2rem !important;
  }

  .gt-sm\:-mr-8 {
    margin-right: -2rem !important;
  }

  .gt-sm\:-mb-8 {
    margin-bottom: -2rem !important;
  }

  .gt-sm\:-ml-8 {
    margin-left: -2rem !important;
  }

  .gt-sm\:-mt-10 {
    margin-top: -2.5rem !important;
  }

  .gt-sm\:-mr-10 {
    margin-right: -2.5rem !important;
  }

  .gt-sm\:-mb-10 {
    margin-bottom: -2.5rem !important;
  }

  .gt-sm\:-ml-10 {
    margin-left: -2.5rem !important;
  }

  .gt-sm\:-mt-12 {
    margin-top: -3rem !important;
  }

  .gt-sm\:-mr-12 {
    margin-right: -3rem !important;
  }

  .gt-sm\:-mb-12 {
    margin-bottom: -3rem !important;
  }

  .gt-sm\:-ml-12 {
    margin-left: -3rem !important;
  }

  .gt-sm\:-mt-14 {
    margin-top: -3.5rem !important;
  }

  .gt-sm\:-mr-14 {
    margin-right: -3.5rem !important;
  }

  .gt-sm\:-mb-14 {
    margin-bottom: -3.5rem !important;
  }

  .gt-sm\:-ml-14 {
    margin-left: -3.5rem !important;
  }

  .gt-sm\:-mt-16 {
    margin-top: -4rem !important;
  }

  .gt-sm\:-mr-16 {
    margin-right: -4rem !important;
  }

  .gt-sm\:-mb-16 {
    margin-bottom: -4rem !important;
  }

  .gt-sm\:-ml-16 {
    margin-left: -4rem !important;
  }

  .gt-sm\:-mt-18 {
    margin-top: -4.5rem !important;
  }

  .gt-sm\:-mr-18 {
    margin-right: -4.5rem !important;
  }

  .gt-sm\:-mb-18 {
    margin-bottom: -4.5rem !important;
  }

  .gt-sm\:-ml-18 {
    margin-left: -4.5rem !important;
  }

  .gt-sm\:-mt-20 {
    margin-top: -5rem !important;
  }

  .gt-sm\:-mr-20 {
    margin-right: -5rem !important;
  }

  .gt-sm\:-mb-20 {
    margin-bottom: -5rem !important;
  }

  .gt-sm\:-ml-20 {
    margin-left: -5rem !important;
  }

  .gt-sm\:-mt-22 {
    margin-top: -5.5rem !important;
  }

  .gt-sm\:-mr-22 {
    margin-right: -5.5rem !important;
  }

  .gt-sm\:-mb-22 {
    margin-bottom: -5.5rem !important;
  }

  .gt-sm\:-ml-22 {
    margin-left: -5.5rem !important;
  }

  .gt-sm\:-mt-24 {
    margin-top: -6rem !important;
  }

  .gt-sm\:-mr-24 {
    margin-right: -6rem !important;
  }

  .gt-sm\:-mb-24 {
    margin-bottom: -6rem !important;
  }

  .gt-sm\:-ml-24 {
    margin-left: -6rem !important;
  }

  .gt-sm\:-mt-26 {
    margin-top: -6.5rem !important;
  }

  .gt-sm\:-mr-26 {
    margin-right: -6.5rem !important;
  }

  .gt-sm\:-mb-26 {
    margin-bottom: -6.5rem !important;
  }

  .gt-sm\:-ml-26 {
    margin-left: -6.5rem !important;
  }

  .gt-sm\:-mt-28 {
    margin-top: -7rem !important;
  }

  .gt-sm\:-mr-28 {
    margin-right: -7rem !important;
  }

  .gt-sm\:-mb-28 {
    margin-bottom: -7rem !important;
  }

  .gt-sm\:-ml-28 {
    margin-left: -7rem !important;
  }

  .gt-sm\:-mt-30 {
    margin-top: -7.5rem !important;
  }

  .gt-sm\:-mr-30 {
    margin-right: -7.5rem !important;
  }

  .gt-sm\:-mb-30 {
    margin-bottom: -7.5rem !important;
  }

  .gt-sm\:-ml-30 {
    margin-left: -7.5rem !important;
  }

  .gt-sm\:-mt-32 {
    margin-top: -8rem !important;
  }

  .gt-sm\:-mr-32 {
    margin-right: -8rem !important;
  }

  .gt-sm\:-mb-32 {
    margin-bottom: -8rem !important;
  }

  .gt-sm\:-ml-32 {
    margin-left: -8rem !important;
  }

  .gt-sm\:-mt-36 {
    margin-top: -9rem !important;
  }

  .gt-sm\:-mr-36 {
    margin-right: -9rem !important;
  }

  .gt-sm\:-mb-36 {
    margin-bottom: -9rem !important;
  }

  .gt-sm\:-ml-36 {
    margin-left: -9rem !important;
  }

  .gt-sm\:-mt-40 {
    margin-top: -10rem !important;
  }

  .gt-sm\:-mr-40 {
    margin-right: -10rem !important;
  }

  .gt-sm\:-mb-40 {
    margin-bottom: -10rem !important;
  }

  .gt-sm\:-ml-40 {
    margin-left: -10rem !important;
  }

  .gt-sm\:-mt-48 {
    margin-top: -12rem !important;
  }

  .gt-sm\:-mr-48 {
    margin-right: -12rem !important;
  }

  .gt-sm\:-mb-48 {
    margin-bottom: -12rem !important;
  }

  .gt-sm\:-ml-48 {
    margin-left: -12rem !important;
  }

  .gt-sm\:-mt-56 {
    margin-top: -14rem !important;
  }

  .gt-sm\:-mr-56 {
    margin-right: -14rem !important;
  }

  .gt-sm\:-mb-56 {
    margin-bottom: -14rem !important;
  }

  .gt-sm\:-ml-56 {
    margin-left: -14rem !important;
  }

  .gt-sm\:-mt-64 {
    margin-top: -16rem !important;
  }

  .gt-sm\:-mr-64 {
    margin-right: -16rem !important;
  }

  .gt-sm\:-mb-64 {
    margin-bottom: -16rem !important;
  }

  .gt-sm\:-ml-64 {
    margin-left: -16rem !important;
  }

  .gt-sm\:-mt-px {
    margin-top: -1px !important;
  }

  .gt-sm\:-mr-px {
    margin-right: -1px !important;
  }

  .gt-sm\:-mb-px {
    margin-bottom: -1px !important;
  }

  .gt-sm\:-ml-px {
    margin-left: -1px !important;
  }

  .gt-sm\:-mt-2px {
    margin-top: -2px !important;
  }

  .gt-sm\:-mr-2px {
    margin-right: -2px !important;
  }

  .gt-sm\:-mb-2px {
    margin-bottom: -2px !important;
  }

  .gt-sm\:-ml-2px {
    margin-left: -2px !important;
  }

  .gt-sm\:max-h-0 {
    max-height: 0 !important;
  }

  .gt-sm\:max-h-1 {
    max-height: 0.25rem !important;
  }

  .gt-sm\:max-h-2 {
    max-height: 0.5rem !important;
  }

  .gt-sm\:max-h-3 {
    max-height: 0.75rem !important;
  }

  .gt-sm\:max-h-4 {
    max-height: 1rem !important;
  }

  .gt-sm\:max-h-5 {
    max-height: 1.25rem !important;
  }

  .gt-sm\:max-h-6 {
    max-height: 1.5rem !important;
  }

  .gt-sm\:max-h-8 {
    max-height: 2rem !important;
  }

  .gt-sm\:max-h-10 {
    max-height: 2.5rem !important;
  }

  .gt-sm\:max-h-12 {
    max-height: 3rem !important;
  }

  .gt-sm\:max-h-14 {
    max-height: 3.5rem !important;
  }

  .gt-sm\:max-h-16 {
    max-height: 4rem !important;
  }

  .gt-sm\:max-h-18 {
    max-height: 4.5rem !important;
  }

  .gt-sm\:max-h-20 {
    max-height: 5rem !important;
  }

  .gt-sm\:max-h-22 {
    max-height: 5.5rem !important;
  }

  .gt-sm\:max-h-24 {
    max-height: 6rem !important;
  }

  .gt-sm\:max-h-26 {
    max-height: 6.5rem !important;
  }

  .gt-sm\:max-h-28 {
    max-height: 7rem !important;
  }

  .gt-sm\:max-h-30 {
    max-height: 7.5rem !important;
  }

  .gt-sm\:max-h-32 {
    max-height: 8rem !important;
  }

  .gt-sm\:max-h-36 {
    max-height: 9rem !important;
  }

  .gt-sm\:max-h-40 {
    max-height: 10rem !important;
  }

  .gt-sm\:max-h-48 {
    max-height: 12rem !important;
  }

  .gt-sm\:max-h-50 {
    max-height: 12.5rem !important;
  }

  .gt-sm\:max-h-56 {
    max-height: 14rem !important;
  }

  .gt-sm\:max-h-60 {
    max-height: 15rem !important;
  }

  .gt-sm\:max-h-64 {
    max-height: 16rem !important;
  }

  .gt-sm\:max-h-80 {
    max-height: 20rem !important;
  }

  .gt-sm\:max-h-90 {
    max-height: 24rem !important;
  }

  .gt-sm\:max-h-100 {
    max-height: 25rem !important;
  }

  .gt-sm\:max-h-120 {
    max-height: 30rem !important;
  }

  .gt-sm\:max-h-128 {
    max-height: 32rem !important;
  }

  .gt-sm\:max-h-140 {
    max-height: 35rem !important;
  }

  .gt-sm\:max-h-160 {
    max-height: 40rem !important;
  }

  .gt-sm\:max-h-180 {
    max-height: 45rem !important;
  }

  .gt-sm\:max-h-192 {
    max-height: 48rem !important;
  }

  .gt-sm\:max-h-200 {
    max-height: 50rem !important;
  }

  .gt-sm\:max-h-240 {
    max-height: 60rem !important;
  }

  .gt-sm\:max-h-256 {
    max-height: 64rem !important;
  }

  .gt-sm\:max-h-280 {
    max-height: 70rem !important;
  }

  .gt-sm\:max-h-320 {
    max-height: 80rem !important;
  }

  .gt-sm\:max-h-360 {
    max-height: 90rem !important;
  }

  .gt-sm\:max-h-400 {
    max-height: 100rem !important;
  }

  .gt-sm\:max-h-480 {
    max-height: 120rem !important;
  }

  .gt-sm\:max-h-full {
    max-height: 100% !important;
  }

  .gt-sm\:max-h-screen {
    max-height: 100vh !important;
  }

  .gt-sm\:max-h-none {
    max-height: none !important;
  }

  .gt-sm\:max-h-px {
    max-height: 1px !important;
  }

  .gt-sm\:max-h-2px {
    max-height: 2px !important;
  }

  .gt-sm\:max-h-1\/2 {
    max-height: 50% !important;
  }

  .gt-sm\:max-h-1\/3 {
    max-height: 33.33333% !important;
  }

  .gt-sm\:max-h-2\/3 {
    max-height: 66.66667% !important;
  }

  .gt-sm\:max-h-1\/4 {
    max-height: 25% !important;
  }

  .gt-sm\:max-h-2\/4 {
    max-height: 50% !important;
  }

  .gt-sm\:max-h-3\/4 {
    max-height: 75% !important;
  }

  .gt-sm\:max-h-1\/5 {
    max-height: 20% !important;
  }

  .gt-sm\:max-h-2\/5 {
    max-height: 40% !important;
  }

  .gt-sm\:max-h-3\/5 {
    max-height: 60% !important;
  }

  .gt-sm\:max-h-4\/5 {
    max-height: 80% !important;
  }

  .gt-sm\:max-h-1\/12 {
    max-height: 8.33333% !important;
  }

  .gt-sm\:max-h-2\/12 {
    max-height: 16.66667% !important;
  }

  .gt-sm\:max-h-3\/12 {
    max-height: 25% !important;
  }

  .gt-sm\:max-h-4\/12 {
    max-height: 33.33333% !important;
  }

  .gt-sm\:max-h-5\/12 {
    max-height: 41.66667% !important;
  }

  .gt-sm\:max-h-6\/12 {
    max-height: 50% !important;
  }

  .gt-sm\:max-h-7\/12 {
    max-height: 58.33333% !important;
  }

  .gt-sm\:max-h-8\/12 {
    max-height: 66.66667% !important;
  }

  .gt-sm\:max-h-9\/12 {
    max-height: 75% !important;
  }

  .gt-sm\:max-h-10\/12 {
    max-height: 83.33333% !important;
  }

  .gt-sm\:max-h-11\/12 {
    max-height: 91.66667% !important;
  }

  .gt-sm\:max-w-0 {
    max-width: 0 !important;
  }

  .gt-sm\:max-w-1 {
    max-width: 0.25rem !important;
  }

  .gt-sm\:max-w-2 {
    max-width: 0.5rem !important;
  }

  .gt-sm\:max-w-3 {
    max-width: 0.75rem !important;
  }

  .gt-sm\:max-w-4 {
    max-width: 1rem !important;
  }

  .gt-sm\:max-w-5 {
    max-width: 1.25rem !important;
  }

  .gt-sm\:max-w-6 {
    max-width: 1.5rem !important;
  }

  .gt-sm\:max-w-8 {
    max-width: 2rem !important;
  }

  .gt-sm\:max-w-10 {
    max-width: 2.5rem !important;
  }

  .gt-sm\:max-w-12 {
    max-width: 3rem !important;
  }

  .gt-sm\:max-w-14 {
    max-width: 3.5rem !important;
  }

  .gt-sm\:max-w-16 {
    max-width: 4rem !important;
  }

  .gt-sm\:max-w-18 {
    max-width: 4.5rem !important;
  }

  .gt-sm\:max-w-20 {
    max-width: 5rem !important;
  }

  .gt-sm\:max-w-22 {
    max-width: 5.5rem !important;
  }

  .gt-sm\:max-w-24 {
    max-width: 6rem !important;
  }

  .gt-sm\:max-w-26 {
    max-width: 6.5rem !important;
  }

  .gt-sm\:max-w-28 {
    max-width: 7rem !important;
  }

  .gt-sm\:max-w-30 {
    max-width: 7.5rem !important;
  }

  .gt-sm\:max-w-32 {
    max-width: 8rem !important;
  }

  .gt-sm\:max-w-36 {
    max-width: 9rem !important;
  }

  .gt-sm\:max-w-40 {
    max-width: 10rem !important;
  }

  .gt-sm\:max-w-48 {
    max-width: 12rem !important;
  }

  .gt-sm\:max-w-50 {
    max-width: 12.5rem !important;
  }

  .gt-sm\:max-w-56 {
    max-width: 14rem !important;
  }

  .gt-sm\:max-w-60 {
    max-width: 15rem !important;
  }

  .gt-sm\:max-w-64 {
    max-width: 16rem !important;
  }

  .gt-sm\:max-w-80 {
    max-width: 20rem !important;
  }

  .gt-sm\:max-w-90 {
    max-width: 24rem !important;
  }

  .gt-sm\:max-w-100 {
    max-width: 25rem !important;
  }

  .gt-sm\:max-w-120 {
    max-width: 30rem !important;
  }

  .gt-sm\:max-w-128 {
    max-width: 32rem !important;
  }

  .gt-sm\:max-w-140 {
    max-width: 35rem !important;
  }

  .gt-sm\:max-w-160 {
    max-width: 40rem !important;
  }

  .gt-sm\:max-w-180 {
    max-width: 45rem !important;
  }

  .gt-sm\:max-w-192 {
    max-width: 48rem !important;
  }

  .gt-sm\:max-w-200 {
    max-width: 50rem !important;
  }

  .gt-sm\:max-w-240 {
    max-width: 60rem !important;
  }

  .gt-sm\:max-w-256 {
    max-width: 64rem !important;
  }

  .gt-sm\:max-w-280 {
    max-width: 70rem !important;
  }

  .gt-sm\:max-w-320 {
    max-width: 80rem !important;
  }

  .gt-sm\:max-w-360 {
    max-width: 90rem !important;
  }

  .gt-sm\:max-w-400 {
    max-width: 100rem !important;
  }

  .gt-sm\:max-w-480 {
    max-width: 120rem !important;
  }

  .gt-sm\:max-w-none {
    max-width: none !important;
  }

  .gt-sm\:max-w-xs {
    max-width: 20rem !important;
  }

  .gt-sm\:max-w-sm {
    max-width: 24rem !important;
  }

  .gt-sm\:max-w-md {
    max-width: 28rem !important;
  }

  .gt-sm\:max-w-lg {
    max-width: 32rem !important;
  }

  .gt-sm\:max-w-xl {
    max-width: 36rem !important;
  }

  .gt-sm\:max-w-2xl {
    max-width: 42rem !important;
  }

  .gt-sm\:max-w-3xl {
    max-width: 48rem !important;
  }

  .gt-sm\:max-w-4xl {
    max-width: 56rem !important;
  }

  .gt-sm\:max-w-5xl {
    max-width: 64rem !important;
  }

  .gt-sm\:max-w-6xl {
    max-width: 72rem !important;
  }

  .gt-sm\:max-w-full {
    max-width: 100% !important;
  }

  .gt-sm\:max-w-screen {
    max-width: 100vw !important;
  }

  .gt-sm\:max-w-px {
    max-width: 1px !important;
  }

  .gt-sm\:max-w-2px {
    max-width: 2px !important;
  }

  .gt-sm\:max-w-1\/2 {
    max-width: 50% !important;
  }

  .gt-sm\:max-w-1\/3 {
    max-width: 33.33333% !important;
  }

  .gt-sm\:max-w-2\/3 {
    max-width: 66.66667% !important;
  }

  .gt-sm\:max-w-1\/4 {
    max-width: 25% !important;
  }

  .gt-sm\:max-w-2\/4 {
    max-width: 50% !important;
  }

  .gt-sm\:max-w-3\/4 {
    max-width: 75% !important;
  }

  .gt-sm\:max-w-1\/5 {
    max-width: 20% !important;
  }

  .gt-sm\:max-w-2\/5 {
    max-width: 40% !important;
  }

  .gt-sm\:max-w-3\/5 {
    max-width: 60% !important;
  }

  .gt-sm\:max-w-4\/5 {
    max-width: 80% !important;
  }

  .gt-sm\:max-w-1\/12 {
    max-width: 8.33333% !important;
  }

  .gt-sm\:max-w-2\/12 {
    max-width: 16.66667% !important;
  }

  .gt-sm\:max-w-3\/12 {
    max-width: 25% !important;
  }

  .gt-sm\:max-w-4\/12 {
    max-width: 33.33333% !important;
  }

  .gt-sm\:max-w-5\/12 {
    max-width: 41.66667% !important;
  }

  .gt-sm\:max-w-6\/12 {
    max-width: 50% !important;
  }

  .gt-sm\:max-w-7\/12 {
    max-width: 58.33333% !important;
  }

  .gt-sm\:max-w-8\/12 {
    max-width: 66.66667% !important;
  }

  .gt-sm\:max-w-9\/12 {
    max-width: 75% !important;
  }

  .gt-sm\:max-w-10\/12 {
    max-width: 83.33333% !important;
  }

  .gt-sm\:max-w-11\/12 {
    max-width: 91.66667% !important;
  }

  .gt-sm\:min-h-0 {
    min-height: 0 !important;
  }

  .gt-sm\:min-h-1 {
    min-height: 0.25rem !important;
  }

  .gt-sm\:min-h-2 {
    min-height: 0.5rem !important;
  }

  .gt-sm\:min-h-3 {
    min-height: 0.75rem !important;
  }

  .gt-sm\:min-h-4 {
    min-height: 1rem !important;
  }

  .gt-sm\:min-h-5 {
    min-height: 1.25rem !important;
  }

  .gt-sm\:min-h-6 {
    min-height: 1.5rem !important;
  }

  .gt-sm\:min-h-8 {
    min-height: 2rem !important;
  }

  .gt-sm\:min-h-10 {
    min-height: 2.5rem !important;
  }

  .gt-sm\:min-h-12 {
    min-height: 3rem !important;
  }

  .gt-sm\:min-h-14 {
    min-height: 3.5rem !important;
  }

  .gt-sm\:min-h-16 {
    min-height: 4rem !important;
  }

  .gt-sm\:min-h-18 {
    min-height: 4.5rem !important;
  }

  .gt-sm\:min-h-20 {
    min-height: 5rem !important;
  }

  .gt-sm\:min-h-22 {
    min-height: 5.5rem !important;
  }

  .gt-sm\:min-h-24 {
    min-height: 6rem !important;
  }

  .gt-sm\:min-h-26 {
    min-height: 6.5rem !important;
  }

  .gt-sm\:min-h-28 {
    min-height: 7rem !important;
  }

  .gt-sm\:min-h-30 {
    min-height: 7.5rem !important;
  }

  .gt-sm\:min-h-32 {
    min-height: 8rem !important;
  }

  .gt-sm\:min-h-36 {
    min-height: 9rem !important;
  }

  .gt-sm\:min-h-40 {
    min-height: 10rem !important;
  }

  .gt-sm\:min-h-48 {
    min-height: 12rem !important;
  }

  .gt-sm\:min-h-50 {
    min-height: 12.5rem !important;
  }

  .gt-sm\:min-h-56 {
    min-height: 14rem !important;
  }

  .gt-sm\:min-h-60 {
    min-height: 15rem !important;
  }

  .gt-sm\:min-h-64 {
    min-height: 16rem !important;
  }

  .gt-sm\:min-h-80 {
    min-height: 20rem !important;
  }

  .gt-sm\:min-h-90 {
    min-height: 24rem !important;
  }

  .gt-sm\:min-h-100 {
    min-height: 25rem !important;
  }

  .gt-sm\:min-h-120 {
    min-height: 30rem !important;
  }

  .gt-sm\:min-h-128 {
    min-height: 32rem !important;
  }

  .gt-sm\:min-h-140 {
    min-height: 35rem !important;
  }

  .gt-sm\:min-h-160 {
    min-height: 40rem !important;
  }

  .gt-sm\:min-h-180 {
    min-height: 45rem !important;
  }

  .gt-sm\:min-h-192 {
    min-height: 48rem !important;
  }

  .gt-sm\:min-h-200 {
    min-height: 50rem !important;
  }

  .gt-sm\:min-h-240 {
    min-height: 60rem !important;
  }

  .gt-sm\:min-h-256 {
    min-height: 64rem !important;
  }

  .gt-sm\:min-h-280 {
    min-height: 70rem !important;
  }

  .gt-sm\:min-h-320 {
    min-height: 80rem !important;
  }

  .gt-sm\:min-h-360 {
    min-height: 90rem !important;
  }

  .gt-sm\:min-h-400 {
    min-height: 100rem !important;
  }

  .gt-sm\:min-h-480 {
    min-height: 120rem !important;
  }

  .gt-sm\:min-h-full {
    min-height: 100% !important;
  }

  .gt-sm\:min-h-screen {
    min-height: 100vh !important;
  }

  .gt-sm\:min-h-px {
    min-height: 1px !important;
  }

  .gt-sm\:min-h-2px {
    min-height: 2px !important;
  }

  .gt-sm\:min-h-1\/2 {
    min-height: 50% !important;
  }

  .gt-sm\:min-h-1\/3 {
    min-height: 33.33333% !important;
  }

  .gt-sm\:min-h-2\/3 {
    min-height: 66.66667% !important;
  }

  .gt-sm\:min-h-1\/4 {
    min-height: 25% !important;
  }

  .gt-sm\:min-h-2\/4 {
    min-height: 50% !important;
  }

  .gt-sm\:min-h-3\/4 {
    min-height: 75% !important;
  }

  .gt-sm\:min-h-1\/5 {
    min-height: 20% !important;
  }

  .gt-sm\:min-h-2\/5 {
    min-height: 40% !important;
  }

  .gt-sm\:min-h-3\/5 {
    min-height: 60% !important;
  }

  .gt-sm\:min-h-4\/5 {
    min-height: 80% !important;
  }

  .gt-sm\:min-h-1\/12 {
    min-height: 8.33333% !important;
  }

  .gt-sm\:min-h-2\/12 {
    min-height: 16.66667% !important;
  }

  .gt-sm\:min-h-3\/12 {
    min-height: 25% !important;
  }

  .gt-sm\:min-h-4\/12 {
    min-height: 33.33333% !important;
  }

  .gt-sm\:min-h-5\/12 {
    min-height: 41.66667% !important;
  }

  .gt-sm\:min-h-6\/12 {
    min-height: 50% !important;
  }

  .gt-sm\:min-h-7\/12 {
    min-height: 58.33333% !important;
  }

  .gt-sm\:min-h-8\/12 {
    min-height: 66.66667% !important;
  }

  .gt-sm\:min-h-9\/12 {
    min-height: 75% !important;
  }

  .gt-sm\:min-h-10\/12 {
    min-height: 83.33333% !important;
  }

  .gt-sm\:min-h-11\/12 {
    min-height: 91.66667% !important;
  }

  .gt-sm\:min-w-0 {
    min-width: 0 !important;
  }

  .gt-sm\:min-w-1 {
    min-width: 0.25rem !important;
  }

  .gt-sm\:min-w-2 {
    min-width: 0.5rem !important;
  }

  .gt-sm\:min-w-3 {
    min-width: 0.75rem !important;
  }

  .gt-sm\:min-w-4 {
    min-width: 1rem !important;
  }

  .gt-sm\:min-w-5 {
    min-width: 1.25rem !important;
  }

  .gt-sm\:min-w-6 {
    min-width: 1.5rem !important;
  }

  .gt-sm\:min-w-8 {
    min-width: 2rem !important;
  }

  .gt-sm\:min-w-10 {
    min-width: 2.5rem !important;
  }

  .gt-sm\:min-w-12 {
    min-width: 3rem !important;
  }

  .gt-sm\:min-w-14 {
    min-width: 3.5rem !important;
  }

  .gt-sm\:min-w-16 {
    min-width: 4rem !important;
  }

  .gt-sm\:min-w-18 {
    min-width: 4.5rem !important;
  }

  .gt-sm\:min-w-20 {
    min-width: 5rem !important;
  }

  .gt-sm\:min-w-22 {
    min-width: 5.5rem !important;
  }

  .gt-sm\:min-w-24 {
    min-width: 6rem !important;
  }

  .gt-sm\:min-w-26 {
    min-width: 6.5rem !important;
  }

  .gt-sm\:min-w-28 {
    min-width: 7rem !important;
  }

  .gt-sm\:min-w-30 {
    min-width: 7.5rem !important;
  }

  .gt-sm\:min-w-32 {
    min-width: 8rem !important;
  }

  .gt-sm\:min-w-36 {
    min-width: 9rem !important;
  }

  .gt-sm\:min-w-40 {
    min-width: 10rem !important;
  }

  .gt-sm\:min-w-48 {
    min-width: 12rem !important;
  }

  .gt-sm\:min-w-50 {
    min-width: 12.5rem !important;
  }

  .gt-sm\:min-w-56 {
    min-width: 14rem !important;
  }

  .gt-sm\:min-w-60 {
    min-width: 15rem !important;
  }

  .gt-sm\:min-w-64 {
    min-width: 16rem !important;
  }

  .gt-sm\:min-w-80 {
    min-width: 20rem !important;
  }

  .gt-sm\:min-w-90 {
    min-width: 24rem !important;
  }

  .gt-sm\:min-w-100 {
    min-width: 25rem !important;
  }

  .gt-sm\:min-w-120 {
    min-width: 30rem !important;
  }

  .gt-sm\:min-w-128 {
    min-width: 32rem !important;
  }

  .gt-sm\:min-w-140 {
    min-width: 35rem !important;
  }

  .gt-sm\:min-w-160 {
    min-width: 40rem !important;
  }

  .gt-sm\:min-w-180 {
    min-width: 45rem !important;
  }

  .gt-sm\:min-w-192 {
    min-width: 48rem !important;
  }

  .gt-sm\:min-w-200 {
    min-width: 50rem !important;
  }

  .gt-sm\:min-w-240 {
    min-width: 60rem !important;
  }

  .gt-sm\:min-w-256 {
    min-width: 64rem !important;
  }

  .gt-sm\:min-w-280 {
    min-width: 70rem !important;
  }

  .gt-sm\:min-w-320 {
    min-width: 80rem !important;
  }

  .gt-sm\:min-w-360 {
    min-width: 90rem !important;
  }

  .gt-sm\:min-w-400 {
    min-width: 100rem !important;
  }

  .gt-sm\:min-w-480 {
    min-width: 120rem !important;
  }

  .gt-sm\:min-w-full {
    min-width: 100% !important;
  }

  .gt-sm\:min-w-screen {
    min-width: 100vw !important;
  }

  .gt-sm\:min-w-px {
    min-width: 1px !important;
  }

  .gt-sm\:min-w-2px {
    min-width: 2px !important;
  }

  .gt-sm\:min-w-1\/2 {
    min-width: 50% !important;
  }

  .gt-sm\:min-w-1\/3 {
    min-width: 33.33333% !important;
  }

  .gt-sm\:min-w-2\/3 {
    min-width: 66.66667% !important;
  }

  .gt-sm\:min-w-1\/4 {
    min-width: 25% !important;
  }

  .gt-sm\:min-w-2\/4 {
    min-width: 50% !important;
  }

  .gt-sm\:min-w-3\/4 {
    min-width: 75% !important;
  }

  .gt-sm\:min-w-1\/5 {
    min-width: 20% !important;
  }

  .gt-sm\:min-w-2\/5 {
    min-width: 40% !important;
  }

  .gt-sm\:min-w-3\/5 {
    min-width: 60% !important;
  }

  .gt-sm\:min-w-4\/5 {
    min-width: 80% !important;
  }

  .gt-sm\:min-w-1\/12 {
    min-width: 8.33333% !important;
  }

  .gt-sm\:min-w-2\/12 {
    min-width: 16.66667% !important;
  }

  .gt-sm\:min-w-3\/12 {
    min-width: 25% !important;
  }

  .gt-sm\:min-w-4\/12 {
    min-width: 33.33333% !important;
  }

  .gt-sm\:min-w-5\/12 {
    min-width: 41.66667% !important;
  }

  .gt-sm\:min-w-6\/12 {
    min-width: 50% !important;
  }

  .gt-sm\:min-w-7\/12 {
    min-width: 58.33333% !important;
  }

  .gt-sm\:min-w-8\/12 {
    min-width: 66.66667% !important;
  }

  .gt-sm\:min-w-9\/12 {
    min-width: 75% !important;
  }

  .gt-sm\:min-w-10\/12 {
    min-width: 83.33333% !important;
  }

  .gt-sm\:min-w-11\/12 {
    min-width: 91.66667% !important;
  }

  .gt-sm\:object-contain {
    -o-object-fit: contain !important;
       object-fit: contain !important;
  }

  .gt-sm\:object-cover {
    -o-object-fit: cover !important;
       object-fit: cover !important;
  }

  .gt-sm\:object-fill {
    -o-object-fit: fill !important;
       object-fit: fill !important;
  }

  .gt-sm\:object-none {
    -o-object-fit: none !important;
       object-fit: none !important;
  }

  .gt-sm\:object-scale-down {
    -o-object-fit: scale-down !important;
       object-fit: scale-down !important;
  }

  .gt-sm\:object-bottom {
    -o-object-position: bottom !important;
       object-position: bottom !important;
  }

  .gt-sm\:object-center {
    -o-object-position: center !important;
       object-position: center !important;
  }

  .gt-sm\:object-left {
    -o-object-position: left !important;
       object-position: left !important;
  }

  .gt-sm\:object-left-bottom {
    -o-object-position: left bottom !important;
       object-position: left bottom !important;
  }

  .gt-sm\:object-left-top {
    -o-object-position: left top !important;
       object-position: left top !important;
  }

  .gt-sm\:object-right {
    -o-object-position: right !important;
       object-position: right !important;
  }

  .gt-sm\:object-right-bottom {
    -o-object-position: right bottom !important;
       object-position: right bottom !important;
  }

  .gt-sm\:object-right-top {
    -o-object-position: right top !important;
       object-position: right top !important;
  }

  .gt-sm\:object-top {
    -o-object-position: top !important;
       object-position: top !important;
  }

  .gt-sm\:opacity-0 {
    opacity: 0 !important;
  }

  .gt-sm\:opacity-12 {
    opacity: 0.12 !important;
  }

  .gt-sm\:opacity-25 {
    opacity: 0.25 !important;
  }

  .gt-sm\:opacity-38 {
    opacity: 0.38 !important;
  }

  .gt-sm\:opacity-50 {
    opacity: 0.5 !important;
  }

  .gt-sm\:opacity-54 {
    opacity: 0.54 !important;
  }

  .gt-sm\:opacity-70 {
    opacity: 0.70 !important;
  }

  .gt-sm\:opacity-75 {
    opacity: 0.75 !important;
  }

  .gt-sm\:opacity-84 {
    opacity: 0.84 !important;
  }

  .gt-sm\:opacity-100 {
    opacity: 1 !important;
  }

  .gt-sm\:hover\:opacity-0:hover {
    opacity: 0 !important;
  }

  .gt-sm\:hover\:opacity-12:hover {
    opacity: 0.12 !important;
  }

  .gt-sm\:hover\:opacity-25:hover {
    opacity: 0.25 !important;
  }

  .gt-sm\:hover\:opacity-38:hover {
    opacity: 0.38 !important;
  }

  .gt-sm\:hover\:opacity-50:hover {
    opacity: 0.5 !important;
  }

  .gt-sm\:hover\:opacity-54:hover {
    opacity: 0.54 !important;
  }

  .gt-sm\:hover\:opacity-70:hover {
    opacity: 0.70 !important;
  }

  .gt-sm\:hover\:opacity-75:hover {
    opacity: 0.75 !important;
  }

  .gt-sm\:hover\:opacity-84:hover {
    opacity: 0.84 !important;
  }

  .gt-sm\:hover\:opacity-100:hover {
    opacity: 1 !important;
  }

  .gt-sm\:focus\:opacity-0:focus {
    opacity: 0 !important;
  }

  .gt-sm\:focus\:opacity-12:focus {
    opacity: 0.12 !important;
  }

  .gt-sm\:focus\:opacity-25:focus {
    opacity: 0.25 !important;
  }

  .gt-sm\:focus\:opacity-38:focus {
    opacity: 0.38 !important;
  }

  .gt-sm\:focus\:opacity-50:focus {
    opacity: 0.5 !important;
  }

  .gt-sm\:focus\:opacity-54:focus {
    opacity: 0.54 !important;
  }

  .gt-sm\:focus\:opacity-70:focus {
    opacity: 0.70 !important;
  }

  .gt-sm\:focus\:opacity-75:focus {
    opacity: 0.75 !important;
  }

  .gt-sm\:focus\:opacity-84:focus {
    opacity: 0.84 !important;
  }

  .gt-sm\:focus\:opacity-100:focus {
    opacity: 1 !important;
  }

  .gt-sm\:outline-none {
    outline: 0 !important;
  }

  .gt-sm\:focus\:outline-none:focus {
    outline: 0 !important;
  }

  .gt-sm\:overflow-auto {
    overflow: auto !important;
  }

  .gt-sm\:overflow-hidden {
    overflow: hidden !important;
  }

  .gt-sm\:overflow-visible {
    overflow: visible !important;
  }

  .gt-sm\:overflow-scroll {
    overflow: scroll !important;
  }

  .gt-sm\:overflow-x-auto {
    overflow-x: auto !important;
  }

  .gt-sm\:overflow-y-auto {
    overflow-y: auto !important;
  }

  .gt-sm\:overflow-x-hidden {
    overflow-x: hidden !important;
  }

  .gt-sm\:overflow-y-hidden {
    overflow-y: hidden !important;
  }

  .gt-sm\:overflow-x-visible {
    overflow-x: visible !important;
  }

  .gt-sm\:overflow-y-visible {
    overflow-y: visible !important;
  }

  .gt-sm\:overflow-x-scroll {
    overflow-x: scroll !important;
  }

  .gt-sm\:overflow-y-scroll {
    overflow-y: scroll !important;
  }

  .gt-sm\:scrolling-touch {
    -webkit-overflow-scrolling: touch !important;
  }

  .gt-sm\:scrolling-auto {
    -webkit-overflow-scrolling: auto !important;
  }

  .gt-sm\:p-0 {
    padding: 0 !important;
  }

  .gt-sm\:p-1 {
    padding: 0.25rem !important;
  }

  .gt-sm\:p-2 {
    padding: 0.5rem !important;
  }

  .gt-sm\:p-3 {
    padding: 0.75rem !important;
  }

  .gt-sm\:p-4 {
    padding: 1rem !important;
  }

  .gt-sm\:p-5 {
    padding: 1.25rem !important;
  }

  .gt-sm\:p-6 {
    padding: 1.5rem !important;
  }

  .gt-sm\:p-8 {
    padding: 2rem !important;
  }

  .gt-sm\:p-10 {
    padding: 2.5rem !important;
  }

  .gt-sm\:p-12 {
    padding: 3rem !important;
  }

  .gt-sm\:p-14 {
    padding: 3.5rem !important;
  }

  .gt-sm\:p-16 {
    padding: 4rem !important;
  }

  .gt-sm\:p-18 {
    padding: 4.5rem !important;
  }

  .gt-sm\:p-20 {
    padding: 5rem !important;
  }

  .gt-sm\:p-22 {
    padding: 5.5rem !important;
  }

  .gt-sm\:p-24 {
    padding: 6rem !important;
  }

  .gt-sm\:p-26 {
    padding: 6.5rem !important;
  }

  .gt-sm\:p-28 {
    padding: 7rem !important;
  }

  .gt-sm\:p-30 {
    padding: 7.5rem !important;
  }

  .gt-sm\:p-32 {
    padding: 8rem !important;
  }

  .gt-sm\:p-36 {
    padding: 9rem !important;
  }

  .gt-sm\:p-40 {
    padding: 10rem !important;
  }

  .gt-sm\:p-48 {
    padding: 12rem !important;
  }

  .gt-sm\:p-56 {
    padding: 14rem !important;
  }

  .gt-sm\:p-64 {
    padding: 16rem !important;
  }

  .gt-sm\:p-px {
    padding: 1px !important;
  }

  .gt-sm\:p-2px {
    padding: 2px !important;
  }

  .gt-sm\:py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .gt-sm\:px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .gt-sm\:py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .gt-sm\:px-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }

  .gt-sm\:py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .gt-sm\:px-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .gt-sm\:py-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .gt-sm\:px-3 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }

  .gt-sm\:py-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .gt-sm\:px-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .gt-sm\:py-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .gt-sm\:px-5 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }

  .gt-sm\:py-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .gt-sm\:px-6 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .gt-sm\:py-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .gt-sm\:px-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .gt-sm\:py-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .gt-sm\:px-10 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .gt-sm\:py-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .gt-sm\:px-12 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .gt-sm\:py-14 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .gt-sm\:px-14 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }

  .gt-sm\:py-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .gt-sm\:px-16 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .gt-sm\:py-18 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .gt-sm\:px-18 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }

  .gt-sm\:py-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .gt-sm\:px-20 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .gt-sm\:py-22 {
    padding-top: 5.5rem !important;
    padding-bottom: 5.5rem !important;
  }

  .gt-sm\:px-22 {
    padding-left: 5.5rem !important;
    padding-right: 5.5rem !important;
  }

  .gt-sm\:py-24 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .gt-sm\:px-24 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }

  .gt-sm\:py-26 {
    padding-top: 6.5rem !important;
    padding-bottom: 6.5rem !important;
  }

  .gt-sm\:px-26 {
    padding-left: 6.5rem !important;
    padding-right: 6.5rem !important;
  }

  .gt-sm\:py-28 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }

  .gt-sm\:px-28 {
    padding-left: 7rem !important;
    padding-right: 7rem !important;
  }

  .gt-sm\:py-30 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }

  .gt-sm\:px-30 {
    padding-left: 7.5rem !important;
    padding-right: 7.5rem !important;
  }

  .gt-sm\:py-32 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .gt-sm\:px-32 {
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }

  .gt-sm\:py-36 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }

  .gt-sm\:px-36 {
    padding-left: 9rem !important;
    padding-right: 9rem !important;
  }

  .gt-sm\:py-40 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .gt-sm\:px-40 {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }

  .gt-sm\:py-48 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }

  .gt-sm\:px-48 {
    padding-left: 12rem !important;
    padding-right: 12rem !important;
  }

  .gt-sm\:py-56 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }

  .gt-sm\:px-56 {
    padding-left: 14rem !important;
    padding-right: 14rem !important;
  }

  .gt-sm\:py-64 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }

  .gt-sm\:px-64 {
    padding-left: 16rem !important;
    padding-right: 16rem !important;
  }

  .gt-sm\:py-px {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }

  .gt-sm\:px-px {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }

  .gt-sm\:py-2px {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }

  .gt-sm\:px-2px {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }

  .gt-sm\:pt-0 {
    padding-top: 0 !important;
  }

  .gt-sm\:pr-0 {
    padding-right: 0 !important;
  }

  .gt-sm\:pb-0 {
    padding-bottom: 0 !important;
  }

  .gt-sm\:pl-0 {
    padding-left: 0 !important;
  }

  .gt-sm\:pt-1 {
    padding-top: 0.25rem !important;
  }

  .gt-sm\:pr-1 {
    padding-right: 0.25rem !important;
  }

  .gt-sm\:pb-1 {
    padding-bottom: 0.25rem !important;
  }

  .gt-sm\:pl-1 {
    padding-left: 0.25rem !important;
  }

  .gt-sm\:pt-2 {
    padding-top: 0.5rem !important;
  }

  .gt-sm\:pr-2 {
    padding-right: 0.5rem !important;
  }

  .gt-sm\:pb-2 {
    padding-bottom: 0.5rem !important;
  }

  .gt-sm\:pl-2 {
    padding-left: 0.5rem !important;
  }

  .gt-sm\:pt-3 {
    padding-top: 0.75rem !important;
  }

  .gt-sm\:pr-3 {
    padding-right: 0.75rem !important;
  }

  .gt-sm\:pb-3 {
    padding-bottom: 0.75rem !important;
  }

  .gt-sm\:pl-3 {
    padding-left: 0.75rem !important;
  }

  .gt-sm\:pt-4 {
    padding-top: 1rem !important;
  }

  .gt-sm\:pr-4 {
    padding-right: 1rem !important;
  }

  .gt-sm\:pb-4 {
    padding-bottom: 1rem !important;
  }

  .gt-sm\:pl-4 {
    padding-left: 1rem !important;
  }

  .gt-sm\:pt-5 {
    padding-top: 1.25rem !important;
  }

  .gt-sm\:pr-5 {
    padding-right: 1.25rem !important;
  }

  .gt-sm\:pb-5 {
    padding-bottom: 1.25rem !important;
  }

  .gt-sm\:pl-5 {
    padding-left: 1.25rem !important;
  }

  .gt-sm\:pt-6 {
    padding-top: 1.5rem !important;
  }

  .gt-sm\:pr-6 {
    padding-right: 1.5rem !important;
  }

  .gt-sm\:pb-6 {
    padding-bottom: 1.5rem !important;
  }

  .gt-sm\:pl-6 {
    padding-left: 1.5rem !important;
  }

  .gt-sm\:pt-8 {
    padding-top: 2rem !important;
  }

  .gt-sm\:pr-8 {
    padding-right: 2rem !important;
  }

  .gt-sm\:pb-8 {
    padding-bottom: 2rem !important;
  }

  .gt-sm\:pl-8 {
    padding-left: 2rem !important;
  }

  .gt-sm\:pt-10 {
    padding-top: 2.5rem !important;
  }

  .gt-sm\:pr-10 {
    padding-right: 2.5rem !important;
  }

  .gt-sm\:pb-10 {
    padding-bottom: 2.5rem !important;
  }

  .gt-sm\:pl-10 {
    padding-left: 2.5rem !important;
  }

  .gt-sm\:pt-12 {
    padding-top: 3rem !important;
  }

  .gt-sm\:pr-12 {
    padding-right: 3rem !important;
  }

  .gt-sm\:pb-12 {
    padding-bottom: 3rem !important;
  }

  .gt-sm\:pl-12 {
    padding-left: 3rem !important;
  }

  .gt-sm\:pt-14 {
    padding-top: 3.5rem !important;
  }

  .gt-sm\:pr-14 {
    padding-right: 3.5rem !important;
  }

  .gt-sm\:pb-14 {
    padding-bottom: 3.5rem !important;
  }

  .gt-sm\:pl-14 {
    padding-left: 3.5rem !important;
  }

  .gt-sm\:pt-16 {
    padding-top: 4rem !important;
  }

  .gt-sm\:pr-16 {
    padding-right: 4rem !important;
  }

  .gt-sm\:pb-16 {
    padding-bottom: 4rem !important;
  }

  .gt-sm\:pl-16 {
    padding-left: 4rem !important;
  }

  .gt-sm\:pt-18 {
    padding-top: 4.5rem !important;
  }

  .gt-sm\:pr-18 {
    padding-right: 4.5rem !important;
  }

  .gt-sm\:pb-18 {
    padding-bottom: 4.5rem !important;
  }

  .gt-sm\:pl-18 {
    padding-left: 4.5rem !important;
  }

  .gt-sm\:pt-20 {
    padding-top: 5rem !important;
  }

  .gt-sm\:pr-20 {
    padding-right: 5rem !important;
  }

  .gt-sm\:pb-20 {
    padding-bottom: 5rem !important;
  }

  .gt-sm\:pl-20 {
    padding-left: 5rem !important;
  }

  .gt-sm\:pt-22 {
    padding-top: 5.5rem !important;
  }

  .gt-sm\:pr-22 {
    padding-right: 5.5rem !important;
  }

  .gt-sm\:pb-22 {
    padding-bottom: 5.5rem !important;
  }

  .gt-sm\:pl-22 {
    padding-left: 5.5rem !important;
  }

  .gt-sm\:pt-24 {
    padding-top: 6rem !important;
  }

  .gt-sm\:pr-24 {
    padding-right: 6rem !important;
  }

  .gt-sm\:pb-24 {
    padding-bottom: 6rem !important;
  }

  .gt-sm\:pl-24 {
    padding-left: 6rem !important;
  }

  .gt-sm\:pt-26 {
    padding-top: 6.5rem !important;
  }

  .gt-sm\:pr-26 {
    padding-right: 6.5rem !important;
  }

  .gt-sm\:pb-26 {
    padding-bottom: 6.5rem !important;
  }

  .gt-sm\:pl-26 {
    padding-left: 6.5rem !important;
  }

  .gt-sm\:pt-28 {
    padding-top: 7rem !important;
  }

  .gt-sm\:pr-28 {
    padding-right: 7rem !important;
  }

  .gt-sm\:pb-28 {
    padding-bottom: 7rem !important;
  }

  .gt-sm\:pl-28 {
    padding-left: 7rem !important;
  }

  .gt-sm\:pt-30 {
    padding-top: 7.5rem !important;
  }

  .gt-sm\:pr-30 {
    padding-right: 7.5rem !important;
  }

  .gt-sm\:pb-30 {
    padding-bottom: 7.5rem !important;
  }

  .gt-sm\:pl-30 {
    padding-left: 7.5rem !important;
  }

  .gt-sm\:pt-32 {
    padding-top: 8rem !important;
  }

  .gt-sm\:pr-32 {
    padding-right: 8rem !important;
  }

  .gt-sm\:pb-32 {
    padding-bottom: 8rem !important;
  }

  .gt-sm\:pl-32 {
    padding-left: 8rem !important;
  }

  .gt-sm\:pt-36 {
    padding-top: 9rem !important;
  }

  .gt-sm\:pr-36 {
    padding-right: 9rem !important;
  }

  .gt-sm\:pb-36 {
    padding-bottom: 9rem !important;
  }

  .gt-sm\:pl-36 {
    padding-left: 9rem !important;
  }

  .gt-sm\:pt-40 {
    padding-top: 10rem !important;
  }

  .gt-sm\:pr-40 {
    padding-right: 10rem !important;
  }

  .gt-sm\:pb-40 {
    padding-bottom: 10rem !important;
  }

  .gt-sm\:pl-40 {
    padding-left: 10rem !important;
  }

  .gt-sm\:pt-48 {
    padding-top: 12rem !important;
  }

  .gt-sm\:pr-48 {
    padding-right: 12rem !important;
  }

  .gt-sm\:pb-48 {
    padding-bottom: 12rem !important;
  }

  .gt-sm\:pl-48 {
    padding-left: 12rem !important;
  }

  .gt-sm\:pt-56 {
    padding-top: 14rem !important;
  }

  .gt-sm\:pr-56 {
    padding-right: 14rem !important;
  }

  .gt-sm\:pb-56 {
    padding-bottom: 14rem !important;
  }

  .gt-sm\:pl-56 {
    padding-left: 14rem !important;
  }

  .gt-sm\:pt-64 {
    padding-top: 16rem !important;
  }

  .gt-sm\:pr-64 {
    padding-right: 16rem !important;
  }

  .gt-sm\:pb-64 {
    padding-bottom: 16rem !important;
  }

  .gt-sm\:pl-64 {
    padding-left: 16rem !important;
  }

  .gt-sm\:pt-px {
    padding-top: 1px !important;
  }

  .gt-sm\:pr-px {
    padding-right: 1px !important;
  }

  .gt-sm\:pb-px {
    padding-bottom: 1px !important;
  }

  .gt-sm\:pl-px {
    padding-left: 1px !important;
  }

  .gt-sm\:pt-2px {
    padding-top: 2px !important;
  }

  .gt-sm\:pr-2px {
    padding-right: 2px !important;
  }

  .gt-sm\:pb-2px {
    padding-bottom: 2px !important;
  }

  .gt-sm\:pl-2px {
    padding-left: 2px !important;
  }

  .gt-sm\:placeholder-opacity-0::-webkit-input-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .gt-sm\:placeholder-opacity-0::-moz-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .gt-sm\:placeholder-opacity-0::-ms-input-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .gt-sm\:placeholder-opacity-0::placeholder {
    --placeholder-opacity: 0 !important;
  }

  .gt-sm\:placeholder-opacity-12::-webkit-input-placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .gt-sm\:placeholder-opacity-12::-moz-placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .gt-sm\:placeholder-opacity-12::-ms-input-placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .gt-sm\:placeholder-opacity-12::placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .gt-sm\:placeholder-opacity-25::-webkit-input-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .gt-sm\:placeholder-opacity-25::-moz-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .gt-sm\:placeholder-opacity-25::-ms-input-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .gt-sm\:placeholder-opacity-25::placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .gt-sm\:placeholder-opacity-38::-webkit-input-placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .gt-sm\:placeholder-opacity-38::-moz-placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .gt-sm\:placeholder-opacity-38::-ms-input-placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .gt-sm\:placeholder-opacity-38::placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .gt-sm\:placeholder-opacity-50::-webkit-input-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .gt-sm\:placeholder-opacity-50::-moz-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .gt-sm\:placeholder-opacity-50::-ms-input-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .gt-sm\:placeholder-opacity-50::placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .gt-sm\:placeholder-opacity-54::-webkit-input-placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .gt-sm\:placeholder-opacity-54::-moz-placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .gt-sm\:placeholder-opacity-54::-ms-input-placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .gt-sm\:placeholder-opacity-54::placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .gt-sm\:placeholder-opacity-70::-webkit-input-placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .gt-sm\:placeholder-opacity-70::-moz-placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .gt-sm\:placeholder-opacity-70::-ms-input-placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .gt-sm\:placeholder-opacity-70::placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .gt-sm\:placeholder-opacity-75::-webkit-input-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .gt-sm\:placeholder-opacity-75::-moz-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .gt-sm\:placeholder-opacity-75::-ms-input-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .gt-sm\:placeholder-opacity-75::placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .gt-sm\:placeholder-opacity-84::-webkit-input-placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .gt-sm\:placeholder-opacity-84::-moz-placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .gt-sm\:placeholder-opacity-84::-ms-input-placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .gt-sm\:placeholder-opacity-84::placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .gt-sm\:placeholder-opacity-100::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .gt-sm\:placeholder-opacity-100::-moz-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .gt-sm\:placeholder-opacity-100::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .gt-sm\:placeholder-opacity-100::placeholder {
    --placeholder-opacity: 1 !important;
  }

  .gt-sm\:focus\:placeholder-opacity-0:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .gt-sm\:focus\:placeholder-opacity-0:focus::-moz-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .gt-sm\:focus\:placeholder-opacity-0:focus::-ms-input-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .gt-sm\:focus\:placeholder-opacity-0:focus::placeholder {
    --placeholder-opacity: 0 !important;
  }

  .gt-sm\:focus\:placeholder-opacity-12:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .gt-sm\:focus\:placeholder-opacity-12:focus::-moz-placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .gt-sm\:focus\:placeholder-opacity-12:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .gt-sm\:focus\:placeholder-opacity-12:focus::placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .gt-sm\:focus\:placeholder-opacity-25:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .gt-sm\:focus\:placeholder-opacity-25:focus::-moz-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .gt-sm\:focus\:placeholder-opacity-25:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .gt-sm\:focus\:placeholder-opacity-25:focus::placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .gt-sm\:focus\:placeholder-opacity-38:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .gt-sm\:focus\:placeholder-opacity-38:focus::-moz-placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .gt-sm\:focus\:placeholder-opacity-38:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .gt-sm\:focus\:placeholder-opacity-38:focus::placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .gt-sm\:focus\:placeholder-opacity-50:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .gt-sm\:focus\:placeholder-opacity-50:focus::-moz-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .gt-sm\:focus\:placeholder-opacity-50:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .gt-sm\:focus\:placeholder-opacity-50:focus::placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .gt-sm\:focus\:placeholder-opacity-54:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .gt-sm\:focus\:placeholder-opacity-54:focus::-moz-placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .gt-sm\:focus\:placeholder-opacity-54:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .gt-sm\:focus\:placeholder-opacity-54:focus::placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .gt-sm\:focus\:placeholder-opacity-70:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .gt-sm\:focus\:placeholder-opacity-70:focus::-moz-placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .gt-sm\:focus\:placeholder-opacity-70:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .gt-sm\:focus\:placeholder-opacity-70:focus::placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .gt-sm\:focus\:placeholder-opacity-75:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .gt-sm\:focus\:placeholder-opacity-75:focus::-moz-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .gt-sm\:focus\:placeholder-opacity-75:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .gt-sm\:focus\:placeholder-opacity-75:focus::placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .gt-sm\:focus\:placeholder-opacity-84:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .gt-sm\:focus\:placeholder-opacity-84:focus::-moz-placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .gt-sm\:focus\:placeholder-opacity-84:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .gt-sm\:focus\:placeholder-opacity-84:focus::placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .gt-sm\:focus\:placeholder-opacity-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .gt-sm\:focus\:placeholder-opacity-100:focus::-moz-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .gt-sm\:focus\:placeholder-opacity-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .gt-sm\:focus\:placeholder-opacity-100:focus::placeholder {
    --placeholder-opacity: 1 !important;
  }

  .gt-sm\:pointer-events-none {
    pointer-events: none !important;
  }

  .gt-sm\:pointer-events-auto {
    pointer-events: auto !important;
  }

  .gt-sm\:static {
    position: static !important;
  }

  .gt-sm\:fixed {
    position: fixed !important;
  }

  .gt-sm\:absolute {
    position: absolute !important;
  }

  .gt-sm\:relative {
    position: relative !important;
  }

  .gt-sm\:sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }

  .gt-sm\:inset-0 {
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
  }

  .gt-sm\:inset-auto {
    top: auto !important;
    right: auto !important;
    bottom: auto !important;
    left: auto !important;
  }

  .gt-sm\:inset-y-0 {
    top: 0 !important;
    bottom: 0 !important;
  }

  .gt-sm\:inset-x-0 {
    right: 0 !important;
    left: 0 !important;
  }

  .gt-sm\:inset-y-auto {
    top: auto !important;
    bottom: auto !important;
  }

  .gt-sm\:inset-x-auto {
    right: auto !important;
    left: auto !important;
  }

  .gt-sm\:top-0 {
    top: 0 !important;
  }

  .gt-sm\:right-0 {
    right: 0 !important;
  }

  .gt-sm\:bottom-0 {
    bottom: 0 !important;
  }

  .gt-sm\:left-0 {
    left: 0 !important;
  }

  .gt-sm\:top-auto {
    top: auto !important;
  }

  .gt-sm\:right-auto {
    right: auto !important;
  }

  .gt-sm\:bottom-auto {
    bottom: auto !important;
  }

  .gt-sm\:left-auto {
    left: auto !important;
  }

  .gt-sm\:shadow-xs {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05) !important;
  }

  .gt-sm\:shadow-sm {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  }

  .gt-sm\:shadow {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .gt-sm\:shadow-md {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  }

  .gt-sm\:shadow-lg {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  }

  .gt-sm\:shadow-xl {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
  }

  .gt-sm\:shadow-2xl {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
  }

  .gt-sm\:shadow-inner {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .gt-sm\:ring {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }

  .gt-sm\:shadow-none {
    box-shadow: none !important;
  }

  .gt-sm\:shadow-solid {
    box-shadow: 0 0 0 2px currentColor !important;
  }

  .gt-sm\:hover\:shadow-xs:hover {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05) !important;
  }

  .gt-sm\:hover\:shadow-sm:hover {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  }

  .gt-sm\:hover\:shadow:hover {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .gt-sm\:hover\:shadow-md:hover {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  }

  .gt-sm\:hover\:shadow-lg:hover {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  }

  .gt-sm\:hover\:shadow-xl:hover {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
  }

  .gt-sm\:hover\:shadow-2xl:hover {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
  }

  .gt-sm\:hover\:shadow-inner:hover {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .gt-sm\:hover\:ring:hover {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }

  .gt-sm\:hover\:shadow-none:hover {
    box-shadow: none !important;
  }

  .gt-sm\:hover\:shadow-solid:hover {
    box-shadow: 0 0 0 2px currentColor !important;
  }

  .gt-sm\:focus\:shadow-xs:focus {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05) !important;
  }

  .gt-sm\:focus\:shadow-sm:focus {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  }

  .gt-sm\:focus\:shadow:focus {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .gt-sm\:focus\:shadow-md:focus {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  }

  .gt-sm\:focus\:shadow-lg:focus {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  }

  .gt-sm\:focus\:shadow-xl:focus {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
  }

  .gt-sm\:focus\:shadow-2xl:focus {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
  }

  .gt-sm\:focus\:shadow-inner:focus {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .gt-sm\:focus\:ring:focus {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }

  .gt-sm\:focus\:shadow-none:focus {
    box-shadow: none !important;
  }

  .gt-sm\:focus\:shadow-solid:focus {
    box-shadow: 0 0 0 2px currentColor !important;
  }

  .gt-sm\:fill-current {
    fill: currentColor !important;
  }

  .gt-sm\:stroke-current {
    stroke: currentColor !important;
  }

  .gt-sm\:stroke-0 {
    stroke-width: 0 !important;
  }

  .gt-sm\:stroke-1 {
    stroke-width: 1 !important;
  }

  .gt-sm\:stroke-2 {
    stroke-width: 2 !important;
  }

  .gt-sm\:table-auto {
    table-layout: auto !important;
  }

  .gt-sm\:table-fixed {
    table-layout: fixed !important;
  }

  .gt-sm\:text-left {
    text-align: left !important;
  }

  .gt-sm\:text-center {
    text-align: center !important;
  }

  .gt-sm\:text-right {
    text-align: right !important;
  }

  .gt-sm\:text-justify {
    text-align: justify !important;
  }

  .gt-sm\:text-opacity-0 {
    --text-opacity: 0 !important;
  }

  .gt-sm\:text-opacity-12 {
    --text-opacity: 0.12 !important;
  }

  .gt-sm\:text-opacity-25 {
    --text-opacity: 0.25 !important;
  }

  .gt-sm\:text-opacity-38 {
    --text-opacity: 0.38 !important;
  }

  .gt-sm\:text-opacity-50 {
    --text-opacity: 0.5 !important;
  }

  .gt-sm\:text-opacity-54 {
    --text-opacity: 0.54 !important;
  }

  .gt-sm\:text-opacity-70 {
    --text-opacity: 0.70 !important;
  }

  .gt-sm\:text-opacity-75 {
    --text-opacity: 0.75 !important;
  }

  .gt-sm\:text-opacity-84 {
    --text-opacity: 0.84 !important;
  }

  .gt-sm\:text-opacity-100 {
    --text-opacity: 1 !important;
  }

  .gt-sm\:hover\:text-opacity-0:hover {
    --text-opacity: 0 !important;
  }

  .gt-sm\:hover\:text-opacity-12:hover {
    --text-opacity: 0.12 !important;
  }

  .gt-sm\:hover\:text-opacity-25:hover {
    --text-opacity: 0.25 !important;
  }

  .gt-sm\:hover\:text-opacity-38:hover {
    --text-opacity: 0.38 !important;
  }

  .gt-sm\:hover\:text-opacity-50:hover {
    --text-opacity: 0.5 !important;
  }

  .gt-sm\:hover\:text-opacity-54:hover {
    --text-opacity: 0.54 !important;
  }

  .gt-sm\:hover\:text-opacity-70:hover {
    --text-opacity: 0.70 !important;
  }

  .gt-sm\:hover\:text-opacity-75:hover {
    --text-opacity: 0.75 !important;
  }

  .gt-sm\:hover\:text-opacity-84:hover {
    --text-opacity: 0.84 !important;
  }

  .gt-sm\:hover\:text-opacity-100:hover {
    --text-opacity: 1 !important;
  }

  .gt-sm\:focus\:text-opacity-0:focus {
    --text-opacity: 0 !important;
  }

  .gt-sm\:focus\:text-opacity-12:focus {
    --text-opacity: 0.12 !important;
  }

  .gt-sm\:focus\:text-opacity-25:focus {
    --text-opacity: 0.25 !important;
  }

  .gt-sm\:focus\:text-opacity-38:focus {
    --text-opacity: 0.38 !important;
  }

  .gt-sm\:focus\:text-opacity-50:focus {
    --text-opacity: 0.5 !important;
  }

  .gt-sm\:focus\:text-opacity-54:focus {
    --text-opacity: 0.54 !important;
  }

  .gt-sm\:focus\:text-opacity-70:focus {
    --text-opacity: 0.70 !important;
  }

  .gt-sm\:focus\:text-opacity-75:focus {
    --text-opacity: 0.75 !important;
  }

  .gt-sm\:focus\:text-opacity-84:focus {
    --text-opacity: 0.84 !important;
  }

  .gt-sm\:focus\:text-opacity-100:focus {
    --text-opacity: 1 !important;
  }

  .gt-sm\:italic {
    font-style: italic !important;
  }

  .gt-sm\:not-italic {
    font-style: normal !important;
  }

  .gt-sm\:uppercase {
    text-transform: uppercase !important;
  }

  .gt-sm\:lowercase {
    text-transform: lowercase !important;
  }

  .gt-sm\:capitalize {
    text-transform: capitalize !important;
  }

  .gt-sm\:normal-case {
    text-transform: none !important;
  }

  .gt-sm\:underline {
    text-decoration: underline !important;
  }

  .gt-sm\:line-through {
    text-decoration: line-through !important;
  }

  .gt-sm\:no-underline {
    text-decoration: none !important;
  }

  .gt-sm\:hover\:underline:hover {
    text-decoration: underline !important;
  }

  .gt-sm\:hover\:line-through:hover {
    text-decoration: line-through !important;
  }

  .gt-sm\:hover\:no-underline:hover {
    text-decoration: none !important;
  }

  .gt-sm\:focus\:underline:focus {
    text-decoration: underline !important;
  }

  .gt-sm\:focus\:line-through:focus {
    text-decoration: line-through !important;
  }

  .gt-sm\:focus\:no-underline:focus {
    text-decoration: none !important;
  }

  .gt-sm\:tracking-tighter {
    letter-spacing: -0.05em !important;
  }

  .gt-sm\:tracking-tight {
    letter-spacing: -0.025em !important;
  }

  .gt-sm\:tracking-normal {
    letter-spacing: 0 !important;
  }

  .gt-sm\:tracking-wide {
    letter-spacing: 0.025em !important;
  }

  .gt-sm\:tracking-wider {
    letter-spacing: 0.05em !important;
  }

  .gt-sm\:tracking-widest {
    letter-spacing: 0.1em !important;
  }

  .gt-sm\:select-none {
    -webkit-user-select: none !important;
       -moz-user-select: none !important;
        -ms-user-select: none !important;
            user-select: none !important;
  }

  .gt-sm\:select-text {
    -webkit-user-select: text !important;
       -moz-user-select: text !important;
        -ms-user-select: text !important;
            user-select: text !important;
  }

  .gt-sm\:select-all {
    -webkit-user-select: all !important;
       -moz-user-select: all !important;
        -ms-user-select: all !important;
            user-select: all !important;
  }

  .gt-sm\:select-auto {
    -webkit-user-select: auto !important;
       -moz-user-select: auto !important;
        -ms-user-select: auto !important;
            user-select: auto !important;
  }

  .gt-sm\:align-baseline {
    vertical-align: baseline !important;
  }

  .gt-sm\:align-top {
    vertical-align: top !important;
  }

  .gt-sm\:align-middle {
    vertical-align: middle !important;
  }

  .gt-sm\:align-bottom {
    vertical-align: bottom !important;
  }

  .gt-sm\:align-text-top {
    vertical-align: text-top !important;
  }

  .gt-sm\:align-text-bottom {
    vertical-align: text-bottom !important;
  }

  .gt-sm\:visible {
    visibility: visible !important;
  }

  .gt-sm\:invisible {
    visibility: hidden !important;
  }

  .gt-sm\:whitespace-normal {
    white-space: normal !important;
  }

  .gt-sm\:whitespace-nowrap {
    white-space: nowrap !important;
  }

  .gt-sm\:whitespace-pre {
    white-space: pre !important;
  }

  .gt-sm\:whitespace-pre-line {
    white-space: pre-line !important;
  }

  .gt-sm\:whitespace-pre-wrap {
    white-space: pre-wrap !important;
  }

  .gt-sm\:break-normal {
    overflow-wrap: normal !important;
    word-break: normal !important;
  }

  .gt-sm\:break-words {
    overflow-wrap: break-word !important;
  }

  .gt-sm\:break-all {
    word-break: break-all !important;
  }

  .gt-sm\:truncate {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
  }

  .gt-sm\:w-0 {
    width: 0 !important;
  }

  .gt-sm\:w-1 {
    width: 0.25rem !important;
  }

  .gt-sm\:w-2 {
    width: 0.5rem !important;
  }

  .gt-sm\:w-3 {
    width: 0.75rem !important;
  }

  .gt-sm\:w-4 {
    width: 1rem !important;
  }

  .gt-sm\:w-5 {
    width: 1.25rem !important;
  }

  .gt-sm\:w-6 {
    width: 1.5rem !important;
  }

  .gt-sm\:w-8 {
    width: 2rem !important;
  }

  .gt-sm\:w-10 {
    width: 2.5rem !important;
  }

  .gt-sm\:w-12 {
    width: 3rem !important;
  }

  .gt-sm\:w-14 {
    width: 3.5rem !important;
  }

  .gt-sm\:w-16 {
    width: 4rem !important;
  }

  .gt-sm\:w-18 {
    width: 4.5rem !important;
  }

  .gt-sm\:w-20 {
    width: 5rem !important;
  }

  .gt-sm\:w-22 {
    width: 5.5rem !important;
  }

  .gt-sm\:w-24 {
    width: 6rem !important;
  }

  .gt-sm\:w-26 {
    width: 6.5rem !important;
  }

  .gt-sm\:w-28 {
    width: 7rem !important;
  }

  .gt-sm\:w-30 {
    width: 7.5rem !important;
  }

  .gt-sm\:w-32 {
    width: 8rem !important;
  }

  .gt-sm\:w-36 {
    width: 9rem !important;
  }

  .gt-sm\:w-40 {
    width: 10rem !important;
  }

  .gt-sm\:w-48 {
    width: 12rem !important;
  }

  .gt-sm\:w-50 {
    width: 12.5rem !important;
  }

  .gt-sm\:w-56 {
    width: 14rem !important;
  }

  .gt-sm\:w-60 {
    width: 15rem !important;
  }

  .gt-sm\:w-64 {
    width: 16rem !important;
  }

  .gt-sm\:w-80 {
    width: 20rem !important;
  }

  .gt-sm\:w-90 {
    width: 24rem !important;
  }

  .gt-sm\:w-100 {
    width: 25rem !important;
  }

  .gt-sm\:w-120 {
    width: 30rem !important;
  }

  .gt-sm\:w-128 {
    width: 32rem !important;
  }

  .gt-sm\:w-140 {
    width: 35rem !important;
  }

  .gt-sm\:w-160 {
    width: 40rem !important;
  }

  .gt-sm\:w-180 {
    width: 45rem !important;
  }

  .gt-sm\:w-192 {
    width: 48rem !important;
  }

  .gt-sm\:w-200 {
    width: 50rem !important;
  }

  .gt-sm\:w-240 {
    width: 60rem !important;
  }

  .gt-sm\:w-256 {
    width: 64rem !important;
  }

  .gt-sm\:w-280 {
    width: 70rem !important;
  }

  .gt-sm\:w-320 {
    width: 80rem !important;
  }

  .gt-sm\:w-360 {
    width: 90rem !important;
  }

  .gt-sm\:w-400 {
    width: 100rem !important;
  }

  .gt-sm\:w-480 {
    width: 120rem !important;
  }

  .gt-sm\:w-auto {
    width: auto !important;
  }

  .gt-sm\:w-px {
    width: 1px !important;
  }

  .gt-sm\:w-2px {
    width: 2px !important;
  }

  .gt-sm\:w-1\/2 {
    width: 50% !important;
  }

  .gt-sm\:w-1\/3 {
    width: 33.33333% !important;
  }

  .gt-sm\:w-2\/3 {
    width: 66.66667% !important;
  }

  .gt-sm\:w-1\/4 {
    width: 25% !important;
  }

  .gt-sm\:w-2\/4 {
    width: 50% !important;
  }

  .gt-sm\:w-3\/4 {
    width: 75% !important;
  }

  .gt-sm\:w-1\/5 {
    width: 20% !important;
  }

  .gt-sm\:w-2\/5 {
    width: 40% !important;
  }

  .gt-sm\:w-3\/5 {
    width: 60% !important;
  }

  .gt-sm\:w-4\/5 {
    width: 80% !important;
  }

  .gt-sm\:w-1\/6 {
    width: 16.666667% !important;
  }

  .gt-sm\:w-2\/6 {
    width: 33.333333% !important;
  }

  .gt-sm\:w-3\/6 {
    width: 50% !important;
  }

  .gt-sm\:w-4\/6 {
    width: 66.666667% !important;
  }

  .gt-sm\:w-5\/6 {
    width: 83.333333% !important;
  }

  .gt-sm\:w-1\/12 {
    width: 8.33333% !important;
  }

  .gt-sm\:w-2\/12 {
    width: 16.66667% !important;
  }

  .gt-sm\:w-3\/12 {
    width: 25% !important;
  }

  .gt-sm\:w-4\/12 {
    width: 33.33333% !important;
  }

  .gt-sm\:w-5\/12 {
    width: 41.66667% !important;
  }

  .gt-sm\:w-6\/12 {
    width: 50% !important;
  }

  .gt-sm\:w-7\/12 {
    width: 58.33333% !important;
  }

  .gt-sm\:w-8\/12 {
    width: 66.66667% !important;
  }

  .gt-sm\:w-9\/12 {
    width: 75% !important;
  }

  .gt-sm\:w-10\/12 {
    width: 83.33333% !important;
  }

  .gt-sm\:w-11\/12 {
    width: 91.66667% !important;
  }

  .gt-sm\:w-full {
    width: 100% !important;
  }

  .gt-sm\:w-screen {
    width: 100vw !important;
  }

  .gt-sm\:z-0 {
    z-index: 0 !important;
  }

  .gt-sm\:z-10 {
    z-index: 10 !important;
  }

  .gt-sm\:z-20 {
    z-index: 20 !important;
  }

  .gt-sm\:z-30 {
    z-index: 30 !important;
  }

  .gt-sm\:z-40 {
    z-index: 40 !important;
  }

  .gt-sm\:z-50 {
    z-index: 50 !important;
  }

  .gt-sm\:z-60 {
    z-index: 60 !important;
  }

  .gt-sm\:z-70 {
    z-index: 70 !important;
  }

  .gt-sm\:z-80 {
    z-index: 80 !important;
  }

  .gt-sm\:z-90 {
    z-index: 90 !important;
  }

  .gt-sm\:z-99 {
    z-index: 99 !important;
  }

  .gt-sm\:z-999 {
    z-index: 999 !important;
  }

  .gt-sm\:z-9999 {
    z-index: 9999 !important;
  }

  .gt-sm\:z-99999 {
    z-index: 99999 !important;
  }

  .gt-sm\:z-auto {
    z-index: auto !important;
  }

  .gt-sm\:-z-1 {
    z-index: -1 !important;
  }

  .gt-sm\:gap-0 {
    grid-gap: 0 !important;
    gap: 0 !important;
  }

  .gt-sm\:gap-1 {
    grid-gap: 0.25rem !important;
    gap: 0.25rem !important;
  }

  .gt-sm\:gap-2 {
    grid-gap: 0.5rem !important;
    gap: 0.5rem !important;
  }

  .gt-sm\:gap-3 {
    grid-gap: 0.75rem !important;
    gap: 0.75rem !important;
  }

  .gt-sm\:gap-4 {
    grid-gap: 1rem !important;
    gap: 1rem !important;
  }

  .gt-sm\:gap-5 {
    grid-gap: 1.25rem !important;
    gap: 1.25rem !important;
  }

  .gt-sm\:gap-6 {
    grid-gap: 1.5rem !important;
    gap: 1.5rem !important;
  }

  .gt-sm\:gap-8 {
    grid-gap: 2rem !important;
    gap: 2rem !important;
  }

  .gt-sm\:gap-10 {
    grid-gap: 2.5rem !important;
    gap: 2.5rem !important;
  }

  .gt-sm\:gap-12 {
    grid-gap: 3rem !important;
    gap: 3rem !important;
  }

  .gt-sm\:gap-14 {
    grid-gap: 3.5rem !important;
    gap: 3.5rem !important;
  }

  .gt-sm\:gap-16 {
    grid-gap: 4rem !important;
    gap: 4rem !important;
  }

  .gt-sm\:gap-18 {
    grid-gap: 4.5rem !important;
    gap: 4.5rem !important;
  }

  .gt-sm\:gap-20 {
    grid-gap: 5rem !important;
    gap: 5rem !important;
  }

  .gt-sm\:gap-22 {
    grid-gap: 5.5rem !important;
    gap: 5.5rem !important;
  }

  .gt-sm\:gap-24 {
    grid-gap: 6rem !important;
    gap: 6rem !important;
  }

  .gt-sm\:gap-26 {
    grid-gap: 6.5rem !important;
    gap: 6.5rem !important;
  }

  .gt-sm\:gap-28 {
    grid-gap: 7rem !important;
    gap: 7rem !important;
  }

  .gt-sm\:gap-30 {
    grid-gap: 7.5rem !important;
    gap: 7.5rem !important;
  }

  .gt-sm\:gap-32 {
    grid-gap: 8rem !important;
    gap: 8rem !important;
  }

  .gt-sm\:gap-36 {
    grid-gap: 9rem !important;
    gap: 9rem !important;
  }

  .gt-sm\:gap-40 {
    grid-gap: 10rem !important;
    gap: 10rem !important;
  }

  .gt-sm\:gap-48 {
    grid-gap: 12rem !important;
    gap: 12rem !important;
  }

  .gt-sm\:gap-56 {
    grid-gap: 14rem !important;
    gap: 14rem !important;
  }

  .gt-sm\:gap-64 {
    grid-gap: 16rem !important;
    gap: 16rem !important;
  }

  .gt-sm\:gap-px {
    grid-gap: 1px !important;
    gap: 1px !important;
  }

  .gt-sm\:gap-2px {
    grid-gap: 2px !important;
    gap: 2px !important;
  }

  .gt-sm\:gap-x-0 {
    grid-column-gap: 0 !important;
    -moz-column-gap: 0 !important;
         column-gap: 0 !important;
  }

  .gt-sm\:gap-x-1 {
    grid-column-gap: 0.25rem !important;
    -moz-column-gap: 0.25rem !important;
         column-gap: 0.25rem !important;
  }

  .gt-sm\:gap-x-2 {
    grid-column-gap: 0.5rem !important;
    -moz-column-gap: 0.5rem !important;
         column-gap: 0.5rem !important;
  }

  .gt-sm\:gap-x-3 {
    grid-column-gap: 0.75rem !important;
    -moz-column-gap: 0.75rem !important;
         column-gap: 0.75rem !important;
  }

  .gt-sm\:gap-x-4 {
    grid-column-gap: 1rem !important;
    -moz-column-gap: 1rem !important;
         column-gap: 1rem !important;
  }

  .gt-sm\:gap-x-5 {
    grid-column-gap: 1.25rem !important;
    -moz-column-gap: 1.25rem !important;
         column-gap: 1.25rem !important;
  }

  .gt-sm\:gap-x-6 {
    grid-column-gap: 1.5rem !important;
    -moz-column-gap: 1.5rem !important;
         column-gap: 1.5rem !important;
  }

  .gt-sm\:gap-x-8 {
    grid-column-gap: 2rem !important;
    -moz-column-gap: 2rem !important;
         column-gap: 2rem !important;
  }

  .gt-sm\:gap-x-10 {
    grid-column-gap: 2.5rem !important;
    -moz-column-gap: 2.5rem !important;
         column-gap: 2.5rem !important;
  }

  .gt-sm\:gap-x-12 {
    grid-column-gap: 3rem !important;
    -moz-column-gap: 3rem !important;
         column-gap: 3rem !important;
  }

  .gt-sm\:gap-x-14 {
    grid-column-gap: 3.5rem !important;
    -moz-column-gap: 3.5rem !important;
         column-gap: 3.5rem !important;
  }

  .gt-sm\:gap-x-16 {
    grid-column-gap: 4rem !important;
    -moz-column-gap: 4rem !important;
         column-gap: 4rem !important;
  }

  .gt-sm\:gap-x-18 {
    grid-column-gap: 4.5rem !important;
    -moz-column-gap: 4.5rem !important;
         column-gap: 4.5rem !important;
  }

  .gt-sm\:gap-x-20 {
    grid-column-gap: 5rem !important;
    -moz-column-gap: 5rem !important;
         column-gap: 5rem !important;
  }

  .gt-sm\:gap-x-22 {
    grid-column-gap: 5.5rem !important;
    -moz-column-gap: 5.5rem !important;
         column-gap: 5.5rem !important;
  }

  .gt-sm\:gap-x-24 {
    grid-column-gap: 6rem !important;
    -moz-column-gap: 6rem !important;
         column-gap: 6rem !important;
  }

  .gt-sm\:gap-x-26 {
    grid-column-gap: 6.5rem !important;
    -moz-column-gap: 6.5rem !important;
         column-gap: 6.5rem !important;
  }

  .gt-sm\:gap-x-28 {
    grid-column-gap: 7rem !important;
    -moz-column-gap: 7rem !important;
         column-gap: 7rem !important;
  }

  .gt-sm\:gap-x-30 {
    grid-column-gap: 7.5rem !important;
    -moz-column-gap: 7.5rem !important;
         column-gap: 7.5rem !important;
  }

  .gt-sm\:gap-x-32 {
    grid-column-gap: 8rem !important;
    -moz-column-gap: 8rem !important;
         column-gap: 8rem !important;
  }

  .gt-sm\:gap-x-36 {
    grid-column-gap: 9rem !important;
    -moz-column-gap: 9rem !important;
         column-gap: 9rem !important;
  }

  .gt-sm\:gap-x-40 {
    grid-column-gap: 10rem !important;
    -moz-column-gap: 10rem !important;
         column-gap: 10rem !important;
  }

  .gt-sm\:gap-x-48 {
    grid-column-gap: 12rem !important;
    -moz-column-gap: 12rem !important;
         column-gap: 12rem !important;
  }

  .gt-sm\:gap-x-56 {
    grid-column-gap: 14rem !important;
    -moz-column-gap: 14rem !important;
         column-gap: 14rem !important;
  }

  .gt-sm\:gap-x-64 {
    grid-column-gap: 16rem !important;
    -moz-column-gap: 16rem !important;
         column-gap: 16rem !important;
  }

  .gt-sm\:gap-x-px {
    grid-column-gap: 1px !important;
    -moz-column-gap: 1px !important;
         column-gap: 1px !important;
  }

  .gt-sm\:gap-x-2px {
    grid-column-gap: 2px !important;
    -moz-column-gap: 2px !important;
         column-gap: 2px !important;
  }

  .gt-sm\:gap-y-0 {
    grid-row-gap: 0 !important;
    row-gap: 0 !important;
  }

  .gt-sm\:gap-y-1 {
    grid-row-gap: 0.25rem !important;
    row-gap: 0.25rem !important;
  }

  .gt-sm\:gap-y-2 {
    grid-row-gap: 0.5rem !important;
    row-gap: 0.5rem !important;
  }

  .gt-sm\:gap-y-3 {
    grid-row-gap: 0.75rem !important;
    row-gap: 0.75rem !important;
  }

  .gt-sm\:gap-y-4 {
    grid-row-gap: 1rem !important;
    row-gap: 1rem !important;
  }

  .gt-sm\:gap-y-5 {
    grid-row-gap: 1.25rem !important;
    row-gap: 1.25rem !important;
  }

  .gt-sm\:gap-y-6 {
    grid-row-gap: 1.5rem !important;
    row-gap: 1.5rem !important;
  }

  .gt-sm\:gap-y-8 {
    grid-row-gap: 2rem !important;
    row-gap: 2rem !important;
  }

  .gt-sm\:gap-y-10 {
    grid-row-gap: 2.5rem !important;
    row-gap: 2.5rem !important;
  }

  .gt-sm\:gap-y-12 {
    grid-row-gap: 3rem !important;
    row-gap: 3rem !important;
  }

  .gt-sm\:gap-y-14 {
    grid-row-gap: 3.5rem !important;
    row-gap: 3.5rem !important;
  }

  .gt-sm\:gap-y-16 {
    grid-row-gap: 4rem !important;
    row-gap: 4rem !important;
  }

  .gt-sm\:gap-y-18 {
    grid-row-gap: 4.5rem !important;
    row-gap: 4.5rem !important;
  }

  .gt-sm\:gap-y-20 {
    grid-row-gap: 5rem !important;
    row-gap: 5rem !important;
  }

  .gt-sm\:gap-y-22 {
    grid-row-gap: 5.5rem !important;
    row-gap: 5.5rem !important;
  }

  .gt-sm\:gap-y-24 {
    grid-row-gap: 6rem !important;
    row-gap: 6rem !important;
  }

  .gt-sm\:gap-y-26 {
    grid-row-gap: 6.5rem !important;
    row-gap: 6.5rem !important;
  }

  .gt-sm\:gap-y-28 {
    grid-row-gap: 7rem !important;
    row-gap: 7rem !important;
  }

  .gt-sm\:gap-y-30 {
    grid-row-gap: 7.5rem !important;
    row-gap: 7.5rem !important;
  }

  .gt-sm\:gap-y-32 {
    grid-row-gap: 8rem !important;
    row-gap: 8rem !important;
  }

  .gt-sm\:gap-y-36 {
    grid-row-gap: 9rem !important;
    row-gap: 9rem !important;
  }

  .gt-sm\:gap-y-40 {
    grid-row-gap: 10rem !important;
    row-gap: 10rem !important;
  }

  .gt-sm\:gap-y-48 {
    grid-row-gap: 12rem !important;
    row-gap: 12rem !important;
  }

  .gt-sm\:gap-y-56 {
    grid-row-gap: 14rem !important;
    row-gap: 14rem !important;
  }

  .gt-sm\:gap-y-64 {
    grid-row-gap: 16rem !important;
    row-gap: 16rem !important;
  }

  .gt-sm\:gap-y-px {
    grid-row-gap: 1px !important;
    row-gap: 1px !important;
  }

  .gt-sm\:gap-y-2px {
    grid-row-gap: 2px !important;
    row-gap: 2px !important;
  }

  .gt-sm\:grid-flow-row {
    grid-auto-flow: row !important;
  }

  .gt-sm\:grid-flow-col {
    grid-auto-flow: column !important;
  }

  .gt-sm\:grid-flow-row-dense {
    grid-auto-flow: row dense !important;
  }

  .gt-sm\:grid-flow-col-dense {
    grid-auto-flow: column dense !important;
  }

  .gt-sm\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }

  .gt-sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }

  .gt-sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
  }

  .gt-sm\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
  }

  .gt-sm\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr)) !important;
  }

  .gt-sm\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr)) !important;
  }

  .gt-sm\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr)) !important;
  }

  .gt-sm\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr)) !important;
  }

  .gt-sm\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr)) !important;
  }

  .gt-sm\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr)) !important;
  }

  .gt-sm\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr)) !important;
  }

  .gt-sm\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr)) !important;
  }

  .gt-sm\:grid-cols-none {
    grid-template-columns: none !important;
  }

  .gt-sm\:col-auto {
    grid-column: auto !important;
  }

  .gt-sm\:col-span-1 {
    grid-column: span 1 / span 1 !important;
  }

  .gt-sm\:col-span-2 {
    grid-column: span 2 / span 2 !important;
  }

  .gt-sm\:col-span-3 {
    grid-column: span 3 / span 3 !important;
  }

  .gt-sm\:col-span-4 {
    grid-column: span 4 / span 4 !important;
  }

  .gt-sm\:col-span-5 {
    grid-column: span 5 / span 5 !important;
  }

  .gt-sm\:col-span-6 {
    grid-column: span 6 / span 6 !important;
  }

  .gt-sm\:col-span-7 {
    grid-column: span 7 / span 7 !important;
  }

  .gt-sm\:col-span-8 {
    grid-column: span 8 / span 8 !important;
  }

  .gt-sm\:col-span-9 {
    grid-column: span 9 / span 9 !important;
  }

  .gt-sm\:col-span-10 {
    grid-column: span 10 / span 10 !important;
  }

  .gt-sm\:col-span-11 {
    grid-column: span 11 / span 11 !important;
  }

  .gt-sm\:col-span-12 {
    grid-column: span 12 / span 12 !important;
  }

  .gt-sm\:col-start-1 {
    grid-column-start: 1 !important;
  }

  .gt-sm\:col-start-2 {
    grid-column-start: 2 !important;
  }

  .gt-sm\:col-start-3 {
    grid-column-start: 3 !important;
  }

  .gt-sm\:col-start-4 {
    grid-column-start: 4 !important;
  }

  .gt-sm\:col-start-5 {
    grid-column-start: 5 !important;
  }

  .gt-sm\:col-start-6 {
    grid-column-start: 6 !important;
  }

  .gt-sm\:col-start-7 {
    grid-column-start: 7 !important;
  }

  .gt-sm\:col-start-8 {
    grid-column-start: 8 !important;
  }

  .gt-sm\:col-start-9 {
    grid-column-start: 9 !important;
  }

  .gt-sm\:col-start-10 {
    grid-column-start: 10 !important;
  }

  .gt-sm\:col-start-11 {
    grid-column-start: 11 !important;
  }

  .gt-sm\:col-start-12 {
    grid-column-start: 12 !important;
  }

  .gt-sm\:col-start-13 {
    grid-column-start: 13 !important;
  }

  .gt-sm\:col-start-auto {
    grid-column-start: auto !important;
  }

  .gt-sm\:col-end-1 {
    grid-column-end: 1 !important;
  }

  .gt-sm\:col-end-2 {
    grid-column-end: 2 !important;
  }

  .gt-sm\:col-end-3 {
    grid-column-end: 3 !important;
  }

  .gt-sm\:col-end-4 {
    grid-column-end: 4 !important;
  }

  .gt-sm\:col-end-5 {
    grid-column-end: 5 !important;
  }

  .gt-sm\:col-end-6 {
    grid-column-end: 6 !important;
  }

  .gt-sm\:col-end-7 {
    grid-column-end: 7 !important;
  }

  .gt-sm\:col-end-8 {
    grid-column-end: 8 !important;
  }

  .gt-sm\:col-end-9 {
    grid-column-end: 9 !important;
  }

  .gt-sm\:col-end-10 {
    grid-column-end: 10 !important;
  }

  .gt-sm\:col-end-11 {
    grid-column-end: 11 !important;
  }

  .gt-sm\:col-end-12 {
    grid-column-end: 12 !important;
  }

  .gt-sm\:col-end-13 {
    grid-column-end: 13 !important;
  }

  .gt-sm\:col-end-auto {
    grid-column-end: auto !important;
  }

  .gt-sm\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr)) !important;
  }

  .gt-sm\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr)) !important;
  }

  .gt-sm\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr)) !important;
  }

  .gt-sm\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr)) !important;
  }

  .gt-sm\:grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr)) !important;
  }

  .gt-sm\:grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr)) !important;
  }

  .gt-sm\:grid-rows-none {
    grid-template-rows: none !important;
  }

  .gt-sm\:row-auto {
    grid-row: auto !important;
  }

  .gt-sm\:row-span-1 {
    grid-row: span 1 / span 1 !important;
  }

  .gt-sm\:row-span-2 {
    grid-row: span 2 / span 2 !important;
  }

  .gt-sm\:row-span-3 {
    grid-row: span 3 / span 3 !important;
  }

  .gt-sm\:row-span-4 {
    grid-row: span 4 / span 4 !important;
  }

  .gt-sm\:row-span-5 {
    grid-row: span 5 / span 5 !important;
  }

  .gt-sm\:row-span-6 {
    grid-row: span 6 / span 6 !important;
  }

  .gt-sm\:row-start-1 {
    grid-row-start: 1 !important;
  }

  .gt-sm\:row-start-2 {
    grid-row-start: 2 !important;
  }

  .gt-sm\:row-start-3 {
    grid-row-start: 3 !important;
  }

  .gt-sm\:row-start-4 {
    grid-row-start: 4 !important;
  }

  .gt-sm\:row-start-5 {
    grid-row-start: 5 !important;
  }

  .gt-sm\:row-start-6 {
    grid-row-start: 6 !important;
  }

  .gt-sm\:row-start-7 {
    grid-row-start: 7 !important;
  }

  .gt-sm\:row-start-auto {
    grid-row-start: auto !important;
  }

  .gt-sm\:row-end-1 {
    grid-row-end: 1 !important;
  }

  .gt-sm\:row-end-2 {
    grid-row-end: 2 !important;
  }

  .gt-sm\:row-end-3 {
    grid-row-end: 3 !important;
  }

  .gt-sm\:row-end-4 {
    grid-row-end: 4 !important;
  }

  .gt-sm\:row-end-5 {
    grid-row-end: 5 !important;
  }

  .gt-sm\:row-end-6 {
    grid-row-end: 6 !important;
  }

  .gt-sm\:row-end-7 {
    grid-row-end: 7 !important;
  }

  .gt-sm\:row-end-auto {
    grid-row-end: auto !important;
  }

  .gt-sm\:transform {
    --transform-translate-x: 0 !important;
    --transform-translate-y: 0 !important;
    --transform-rotate: 0 !important;
    --transform-skew-x: 0 !important;
    --transform-skew-y: 0 !important;
    --transform-scale-x: 1 !important;
    --transform-scale-y: 1 !important;
    transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y)) !important;
  }

  .gt-sm\:transform-none {
    transform: none !important;
  }

  .gt-sm\:origin-center {
    transform-origin: center !important;
  }

  .gt-sm\:origin-top {
    transform-origin: top !important;
  }

  .gt-sm\:origin-top-right {
    transform-origin: top right !important;
  }

  .gt-sm\:origin-right {
    transform-origin: right !important;
  }

  .gt-sm\:origin-bottom-right {
    transform-origin: bottom right !important;
  }

  .gt-sm\:origin-bottom {
    transform-origin: bottom !important;
  }

  .gt-sm\:origin-bottom-left {
    transform-origin: bottom left !important;
  }

  .gt-sm\:origin-left {
    transform-origin: left !important;
  }

  .gt-sm\:origin-top-left {
    transform-origin: top left !important;
  }

  .gt-sm\:icon-size-12 {
    width: 12px !important;
    height: 12px !important;
    min-width: 12px !important;
    min-height: 12px !important;
    font-size: 12px !important;
    line-height: 12px !important;
  }

  .gt-sm\:icon-size-12 svg {
    width: 12px !important;
    height: 12px !important;
  }

  .gt-sm\:icon-size-14 {
    width: 14px !important;
    height: 14px !important;
    min-width: 14px !important;
    min-height: 14px !important;
    font-size: 14px !important;
    line-height: 14px !important;
  }

  .gt-sm\:icon-size-14 svg {
    width: 14px !important;
    height: 14px !important;
  }

  .gt-sm\:icon-size-16 {
    width: 16px !important;
    height: 16px !important;
    min-width: 16px !important;
    min-height: 16px !important;
    font-size: 16px !important;
    line-height: 16px !important;
  }

  .gt-sm\:icon-size-16 svg {
    width: 16px !important;
    height: 16px !important;
  }

  .gt-sm\:icon-size-18 {
    width: 18px !important;
    height: 18px !important;
    min-width: 18px !important;
    min-height: 18px !important;
    font-size: 18px !important;
    line-height: 18px !important;
  }

  .gt-sm\:icon-size-18 svg {
    width: 18px !important;
    height: 18px !important;
  }

  .gt-sm\:icon-size-20 {
    width: 20px !important;
    height: 20px !important;
    min-width: 20px !important;
    min-height: 20px !important;
    font-size: 20px !important;
    line-height: 20px !important;
  }

  .gt-sm\:icon-size-20 svg {
    width: 20px !important;
    height: 20px !important;
  }

  .gt-sm\:icon-size-24 {
    width: 24px !important;
    height: 24px !important;
    min-width: 24px !important;
    min-height: 24px !important;
    font-size: 24px !important;
    line-height: 24px !important;
  }

  .gt-sm\:icon-size-24 svg {
    width: 24px !important;
    height: 24px !important;
  }

  .gt-sm\:icon-size-32 {
    width: 32px !important;
    height: 32px !important;
    min-width: 32px !important;
    min-height: 32px !important;
    font-size: 32px !important;
    line-height: 32px !important;
  }

  .gt-sm\:icon-size-32 svg {
    width: 32px !important;
    height: 32px !important;
  }

  .gt-sm\:icon-size-40 {
    width: 40px !important;
    height: 40px !important;
    min-width: 40px !important;
    min-height: 40px !important;
    font-size: 40px !important;
    line-height: 40px !important;
  }

  .gt-sm\:icon-size-40 svg {
    width: 40px !important;
    height: 40px !important;
  }

  .gt-sm\:icon-size-48 {
    width: 48px !important;
    height: 48px !important;
    min-width: 48px !important;
    min-height: 48px !important;
    font-size: 48px !important;
    line-height: 48px !important;
  }

  .gt-sm\:icon-size-48 svg {
    width: 48px !important;
    height: 48px !important;
  }

  .gt-sm\:icon-size-56 {
    width: 56px !important;
    height: 56px !important;
    min-width: 56px !important;
    min-height: 56px !important;
    font-size: 56px !important;
    line-height: 56px !important;
  }

  .gt-sm\:icon-size-56 svg {
    width: 56px !important;
    height: 56px !important;
  }

  .gt-sm\:icon-size-64 {
    width: 64px !important;
    height: 64px !important;
    min-width: 64px !important;
    min-height: 64px !important;
    font-size: 64px !important;
    line-height: 64px !important;
  }

  .gt-sm\:icon-size-64 svg {
    width: 64px !important;
    height: 64px !important;
  }

  .gt-sm\:icon-size-72 {
    width: 72px !important;
    height: 72px !important;
    min-width: 72px !important;
    min-height: 72px !important;
    font-size: 72px !important;
    line-height: 72px !important;
  }

  .gt-sm\:icon-size-72 svg {
    width: 72px !important;
    height: 72px !important;
  }

  .gt-sm\:icon-size-80 {
    width: 80px !important;
    height: 80px !important;
    min-width: 80px !important;
    min-height: 80px !important;
    font-size: 80px !important;
    line-height: 80px !important;
  }

  .gt-sm\:icon-size-80 svg {
    width: 80px !important;
    height: 80px !important;
  }

  .gt-sm\:icon-size-88 {
    width: 88px !important;
    height: 88px !important;
    min-width: 88px !important;
    min-height: 88px !important;
    font-size: 88px !important;
    line-height: 88px !important;
  }

  .gt-sm\:icon-size-88 svg {
    width: 88px !important;
    height: 88px !important;
  }

  .gt-sm\:icon-size-96 {
    width: 96px !important;
    height: 96px !important;
    min-width: 96px !important;
    min-height: 96px !important;
    font-size: 96px !important;
    line-height: 96px !important;
  }

  .gt-sm\:icon-size-96 svg {
    width: 96px !important;
    height: 96px !important;
  }
}

@media (min-width: 1280px) {
  .gt-md\:space-y-0 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(0px * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(0px * var(--space-y-reverse)) !important;
  }

  .gt-md\:space-x-0 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(0px * var(--space-x-reverse)) !important;
    margin-left: calc(0px * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-md\:space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(0.25rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(0.25rem * var(--space-y-reverse)) !important;
  }

  .gt-md\:space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(0.25rem * var(--space-x-reverse)) !important;
    margin-left: calc(0.25rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-md\:space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(0.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(0.5rem * var(--space-y-reverse)) !important;
  }

  .gt-md\:space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(0.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(0.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-md\:space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(0.75rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(0.75rem * var(--space-y-reverse)) !important;
  }

  .gt-md\:space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(0.75rem * var(--space-x-reverse)) !important;
    margin-left: calc(0.75rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-md\:space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(1rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(1rem * var(--space-y-reverse)) !important;
  }

  .gt-md\:space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(1rem * var(--space-x-reverse)) !important;
    margin-left: calc(1rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-md\:space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(1.25rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(1.25rem * var(--space-y-reverse)) !important;
  }

  .gt-md\:space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(1.25rem * var(--space-x-reverse)) !important;
    margin-left: calc(1.25rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-md\:space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(1.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(1.5rem * var(--space-y-reverse)) !important;
  }

  .gt-md\:space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(1.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(1.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-md\:space-y-8 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(2rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(2rem * var(--space-y-reverse)) !important;
  }

  .gt-md\:space-x-8 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(2rem * var(--space-x-reverse)) !important;
    margin-left: calc(2rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-md\:space-y-10 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(2.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(2.5rem * var(--space-y-reverse)) !important;
  }

  .gt-md\:space-x-10 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(2.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(2.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-md\:space-y-12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(3rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(3rem * var(--space-y-reverse)) !important;
  }

  .gt-md\:space-x-12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(3rem * var(--space-x-reverse)) !important;
    margin-left: calc(3rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-md\:space-y-14 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(3.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(3.5rem * var(--space-y-reverse)) !important;
  }

  .gt-md\:space-x-14 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(3.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(3.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-md\:space-y-16 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(4rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(4rem * var(--space-y-reverse)) !important;
  }

  .gt-md\:space-x-16 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(4rem * var(--space-x-reverse)) !important;
    margin-left: calc(4rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-md\:space-y-18 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(4.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(4.5rem * var(--space-y-reverse)) !important;
  }

  .gt-md\:space-x-18 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(4.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(4.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-md\:space-y-20 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(5rem * var(--space-y-reverse)) !important;
  }

  .gt-md\:space-x-20 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(5rem * var(--space-x-reverse)) !important;
    margin-left: calc(5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-md\:space-y-22 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(5.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(5.5rem * var(--space-y-reverse)) !important;
  }

  .gt-md\:space-x-22 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(5.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(5.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-md\:space-y-24 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(6rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(6rem * var(--space-y-reverse)) !important;
  }

  .gt-md\:space-x-24 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(6rem * var(--space-x-reverse)) !important;
    margin-left: calc(6rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-md\:space-y-26 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(6.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(6.5rem * var(--space-y-reverse)) !important;
  }

  .gt-md\:space-x-26 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(6.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(6.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-md\:space-y-28 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(7rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(7rem * var(--space-y-reverse)) !important;
  }

  .gt-md\:space-x-28 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(7rem * var(--space-x-reverse)) !important;
    margin-left: calc(7rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-md\:space-y-30 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(7.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(7.5rem * var(--space-y-reverse)) !important;
  }

  .gt-md\:space-x-30 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(7.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(7.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-md\:space-y-32 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(8rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(8rem * var(--space-y-reverse)) !important;
  }

  .gt-md\:space-x-32 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(8rem * var(--space-x-reverse)) !important;
    margin-left: calc(8rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-md\:space-y-36 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(9rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(9rem * var(--space-y-reverse)) !important;
  }

  .gt-md\:space-x-36 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(9rem * var(--space-x-reverse)) !important;
    margin-left: calc(9rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-md\:space-y-40 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(10rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(10rem * var(--space-y-reverse)) !important;
  }

  .gt-md\:space-x-40 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(10rem * var(--space-x-reverse)) !important;
    margin-left: calc(10rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-md\:space-y-48 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(12rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(12rem * var(--space-y-reverse)) !important;
  }

  .gt-md\:space-x-48 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(12rem * var(--space-x-reverse)) !important;
    margin-left: calc(12rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-md\:space-y-56 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(14rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(14rem * var(--space-y-reverse)) !important;
  }

  .gt-md\:space-x-56 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(14rem * var(--space-x-reverse)) !important;
    margin-left: calc(14rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-md\:space-y-64 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(16rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(16rem * var(--space-y-reverse)) !important;
  }

  .gt-md\:space-x-64 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(16rem * var(--space-x-reverse)) !important;
    margin-left: calc(16rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-md\:space-y-px > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(1px * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(1px * var(--space-y-reverse)) !important;
  }

  .gt-md\:space-x-px > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(1px * var(--space-x-reverse)) !important;
    margin-left: calc(1px * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-md\:space-y-2px > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(2px * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(2px * var(--space-y-reverse)) !important;
  }

  .gt-md\:space-x-2px > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(2px * var(--space-x-reverse)) !important;
    margin-left: calc(2px * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-md\:-space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-0.25rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-0.25rem * var(--space-y-reverse)) !important;
  }

  .gt-md\:-space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-0.25rem * var(--space-x-reverse)) !important;
    margin-left: calc(-0.25rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-md\:-space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-0.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-0.5rem * var(--space-y-reverse)) !important;
  }

  .gt-md\:-space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-0.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-0.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-md\:-space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-0.75rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-0.75rem * var(--space-y-reverse)) !important;
  }

  .gt-md\:-space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-0.75rem * var(--space-x-reverse)) !important;
    margin-left: calc(-0.75rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-md\:-space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-1rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-1rem * var(--space-y-reverse)) !important;
  }

  .gt-md\:-space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-1rem * var(--space-x-reverse)) !important;
    margin-left: calc(-1rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-md\:-space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-1.25rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-1.25rem * var(--space-y-reverse)) !important;
  }

  .gt-md\:-space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-1.25rem * var(--space-x-reverse)) !important;
    margin-left: calc(-1.25rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-md\:-space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-1.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-1.5rem * var(--space-y-reverse)) !important;
  }

  .gt-md\:-space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-1.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-1.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-md\:-space-y-8 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-2rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-2rem * var(--space-y-reverse)) !important;
  }

  .gt-md\:-space-x-8 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-2rem * var(--space-x-reverse)) !important;
    margin-left: calc(-2rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-md\:-space-y-10 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-2.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-2.5rem * var(--space-y-reverse)) !important;
  }

  .gt-md\:-space-x-10 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-2.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-2.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-md\:-space-y-12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-3rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-3rem * var(--space-y-reverse)) !important;
  }

  .gt-md\:-space-x-12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-3rem * var(--space-x-reverse)) !important;
    margin-left: calc(-3rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-md\:-space-y-14 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-3.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-3.5rem * var(--space-y-reverse)) !important;
  }

  .gt-md\:-space-x-14 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-3.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-3.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-md\:-space-y-16 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-4rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-4rem * var(--space-y-reverse)) !important;
  }

  .gt-md\:-space-x-16 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-4rem * var(--space-x-reverse)) !important;
    margin-left: calc(-4rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-md\:-space-y-18 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-4.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-4.5rem * var(--space-y-reverse)) !important;
  }

  .gt-md\:-space-x-18 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-4.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-4.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-md\:-space-y-20 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-5rem * var(--space-y-reverse)) !important;
  }

  .gt-md\:-space-x-20 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-md\:-space-y-22 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-5.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-5.5rem * var(--space-y-reverse)) !important;
  }

  .gt-md\:-space-x-22 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-5.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-5.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-md\:-space-y-24 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-6rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-6rem * var(--space-y-reverse)) !important;
  }

  .gt-md\:-space-x-24 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-6rem * var(--space-x-reverse)) !important;
    margin-left: calc(-6rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-md\:-space-y-26 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-6.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-6.5rem * var(--space-y-reverse)) !important;
  }

  .gt-md\:-space-x-26 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-6.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-6.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-md\:-space-y-28 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-7rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-7rem * var(--space-y-reverse)) !important;
  }

  .gt-md\:-space-x-28 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-7rem * var(--space-x-reverse)) !important;
    margin-left: calc(-7rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-md\:-space-y-30 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-7.5rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-7.5rem * var(--space-y-reverse)) !important;
  }

  .gt-md\:-space-x-30 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-7.5rem * var(--space-x-reverse)) !important;
    margin-left: calc(-7.5rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-md\:-space-y-32 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-8rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-8rem * var(--space-y-reverse)) !important;
  }

  .gt-md\:-space-x-32 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-8rem * var(--space-x-reverse)) !important;
    margin-left: calc(-8rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-md\:-space-y-36 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-9rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-9rem * var(--space-y-reverse)) !important;
  }

  .gt-md\:-space-x-36 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-9rem * var(--space-x-reverse)) !important;
    margin-left: calc(-9rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-md\:-space-y-40 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-10rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-10rem * var(--space-y-reverse)) !important;
  }

  .gt-md\:-space-x-40 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-10rem * var(--space-x-reverse)) !important;
    margin-left: calc(-10rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-md\:-space-y-48 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-12rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-12rem * var(--space-y-reverse)) !important;
  }

  .gt-md\:-space-x-48 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-12rem * var(--space-x-reverse)) !important;
    margin-left: calc(-12rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-md\:-space-y-56 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-14rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-14rem * var(--space-y-reverse)) !important;
  }

  .gt-md\:-space-x-56 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-14rem * var(--space-x-reverse)) !important;
    margin-left: calc(-14rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-md\:-space-y-64 > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-16rem * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-16rem * var(--space-y-reverse)) !important;
  }

  .gt-md\:-space-x-64 > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-16rem * var(--space-x-reverse)) !important;
    margin-left: calc(-16rem * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-md\:-space-y-px > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-1px * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-1px * var(--space-y-reverse)) !important;
  }

  .gt-md\:-space-x-px > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-1px * var(--space-x-reverse)) !important;
    margin-left: calc(-1px * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-md\:-space-y-2px > :not(template) ~ :not(template) {
    --space-y-reverse: 0 !important;
    margin-top: calc(-2px * calc(1 - var(--space-y-reverse))) !important;
    margin-bottom: calc(-2px * var(--space-y-reverse)) !important;
  }

  .gt-md\:-space-x-2px > :not(template) ~ :not(template) {
    --space-x-reverse: 0 !important;
    margin-right: calc(-2px * var(--space-x-reverse)) !important;
    margin-left: calc(-2px * calc(1 - var(--space-x-reverse))) !important;
  }

  .gt-md\:space-y-reverse > :not(template) ~ :not(template) {
    --space-y-reverse: 1 !important;
  }

  .gt-md\:space-x-reverse > :not(template) ~ :not(template) {
    --space-x-reverse: 1 !important;
  }

  .gt-md\:divide-y-0 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(0px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(0px * var(--divide-y-reverse)) !important;
  }

  .gt-md\:divide-x-0 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(0px * var(--divide-x-reverse)) !important;
    border-left-width: calc(0px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .gt-md\:divide-y-2 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(2px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(2px * var(--divide-y-reverse)) !important;
  }

  .gt-md\:divide-x-2 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(2px * var(--divide-x-reverse)) !important;
    border-left-width: calc(2px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .gt-md\:divide-y-4 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(4px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(4px * var(--divide-y-reverse)) !important;
  }

  .gt-md\:divide-x-4 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(4px * var(--divide-x-reverse)) !important;
    border-left-width: calc(4px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .gt-md\:divide-y-8 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(8px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(8px * var(--divide-y-reverse)) !important;
  }

  .gt-md\:divide-x-8 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(8px * var(--divide-x-reverse)) !important;
    border-left-width: calc(8px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .gt-md\:divide-y > :not(template) ~ :not(template) {
    --divide-y-reverse: 0 !important;
    border-top-width: calc(1px * calc(1 - var(--divide-y-reverse))) !important;
    border-bottom-width: calc(1px * var(--divide-y-reverse)) !important;
  }

  .gt-md\:divide-x > :not(template) ~ :not(template) {
    --divide-x-reverse: 0 !important;
    border-right-width: calc(1px * var(--divide-x-reverse)) !important;
    border-left-width: calc(1px * calc(1 - var(--divide-x-reverse))) !important;
  }

  .gt-md\:divide-y-reverse > :not(template) ~ :not(template) {
    --divide-y-reverse: 1 !important;
  }

  .gt-md\:divide-x-reverse > :not(template) ~ :not(template) {
    --divide-x-reverse: 1 !important;
  }

  .gt-md\:divide-current > :not(template) ~ :not(template) {
    border-color: currentColor !important;
  }

  .gt-md\:divide-transparent > :not(template) ~ :not(template) {
    border-color: transparent !important;
  }

  .gt-md\:divide-white > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FFFFFF !important;
    border-color: rgba(255, 255, 255, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-black > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #000000 !important;
    border-color: rgba(0, 0, 0, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-gray-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F9FAFB !important;
    border-color: rgba(249, 250, 251, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-gray-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F4F5F7 !important;
    border-color: rgba(244, 245, 247, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-gray-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E5E7EB !important;
    border-color: rgba(229, 231, 235, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-gray-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #D2D6DC !important;
    border-color: rgba(210, 214, 220, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-gray-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9FA6B2 !important;
    border-color: rgba(159, 166, 178, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-gray-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #6B7280 !important;
    border-color: rgba(107, 114, 128, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-gray-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #4B5563 !important;
    border-color: rgba(75, 85, 99, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-gray-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #374151 !important;
    border-color: rgba(55, 65, 81, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-gray-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #252F3F !important;
    border-color: rgba(37, 47, 63, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-gray-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #161E2E !important;
    border-color: rgba(22, 30, 46, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-gray > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #6B7280 !important;
    border-color: rgba(107, 114, 128, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-cool-gray-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FBFDFE !important;
    border-color: rgba(251, 253, 254, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-cool-gray-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F1F5F9 !important;
    border-color: rgba(241, 245, 249, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-cool-gray-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E2E8F0 !important;
    border-color: rgba(226, 232, 240, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-cool-gray-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #CFD8E3 !important;
    border-color: rgba(207, 216, 227, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-cool-gray-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #97A6BA !important;
    border-color: rgba(151, 166, 186, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-cool-gray-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #64748B !important;
    border-color: rgba(100, 116, 139, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-cool-gray-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #475569 !important;
    border-color: rgba(71, 85, 105, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-cool-gray-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #364152 !important;
    border-color: rgba(54, 65, 82, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-cool-gray-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #27303F !important;
    border-color: rgba(39, 48, 63, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-cool-gray-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #1A202E !important;
    border-color: rgba(26, 32, 46, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-cool-gray > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #64748B !important;
    border-color: rgba(100, 116, 139, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-red-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FDF2F2 !important;
    border-color: rgba(253, 242, 242, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-red-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FDE8E8 !important;
    border-color: rgba(253, 232, 232, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-red-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FBD5D5 !important;
    border-color: rgba(251, 213, 213, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-red-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F8B4B4 !important;
    border-color: rgba(248, 180, 180, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-red-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F98080 !important;
    border-color: rgba(249, 128, 128, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-red-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F05252 !important;
    border-color: rgba(240, 82, 82, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-red-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E02424 !important;
    border-color: rgba(224, 36, 36, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-red-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #C81E1E !important;
    border-color: rgba(200, 30, 30, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-red-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9B1C1C !important;
    border-color: rgba(155, 28, 28, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-red-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #771D1D !important;
    border-color: rgba(119, 29, 29, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-red > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F05252 !important;
    border-color: rgba(240, 82, 82, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-orange-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FFF8F1 !important;
    border-color: rgba(255, 248, 241, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-orange-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FEECDC !important;
    border-color: rgba(254, 236, 220, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-orange-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FCD9BD !important;
    border-color: rgba(252, 217, 189, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-orange-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FDBA8C !important;
    border-color: rgba(253, 186, 140, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-orange-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FF8A4C !important;
    border-color: rgba(255, 138, 76, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-orange-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FF5A1F !important;
    border-color: rgba(255, 90, 31, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-orange-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #ff6700 !important;
    border-color: rgba(255, 103, 0, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-orange-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #B43403 !important;
    border-color: rgba(180, 52, 3, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-orange-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #8A2C0D !important;
    border-color: rgba(138, 44, 13, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-orange-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #771D1D !important;
    border-color: rgba(119, 29, 29, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-orange > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FF5A1F !important;
    border-color: rgba(255, 90, 31, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-yellow-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FDFDEA !important;
    border-color: rgba(253, 253, 234, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-yellow-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FDF6B2 !important;
    border-color: rgba(253, 246, 178, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-yellow-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FCE96A !important;
    border-color: rgba(252, 233, 106, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-yellow-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FACA15 !important;
    border-color: rgba(250, 202, 21, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-yellow-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E3A008 !important;
    border-color: rgba(227, 160, 8, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-yellow-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #C27803 !important;
    border-color: rgba(194, 120, 3, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-yellow-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9F580A !important;
    border-color: rgba(159, 88, 10, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-yellow-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #8E4B10 !important;
    border-color: rgba(142, 75, 16, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-yellow-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #723B13 !important;
    border-color: rgba(114, 59, 19, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-yellow-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #633112 !important;
    border-color: rgba(99, 49, 18, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-yellow > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #C27803 !important;
    border-color: rgba(194, 120, 3, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-green-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F3FAF7 !important;
    border-color: rgba(243, 250, 247, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-green-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #DEF7EC !important;
    border-color: rgba(222, 247, 236, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-green-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #BCF0DA !important;
    border-color: rgba(188, 240, 218, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-green-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #84E1BC !important;
    border-color: rgba(132, 225, 188, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-green-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #31C48D !important;
    border-color: rgba(49, 196, 141, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-green-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #0E9F6E !important;
    border-color: rgba(14, 159, 110, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-green-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #057A55 !important;
    border-color: rgba(5, 122, 85, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-green-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #046C4E !important;
    border-color: rgba(4, 108, 78, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-green-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #03543F !important;
    border-color: rgba(3, 84, 63, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-green-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #014737 !important;
    border-color: rgba(1, 71, 55, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-green > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #0E9F6E !important;
    border-color: rgba(14, 159, 110, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-teal-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #EDFAFA !important;
    border-color: rgba(237, 250, 250, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-teal-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #D5F5F6 !important;
    border-color: rgba(213, 245, 246, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-teal-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #AFECEF !important;
    border-color: rgba(175, 236, 239, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-teal-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #7EDCE2 !important;
    border-color: rgba(126, 220, 226, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-teal-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #16BDCA !important;
    border-color: rgba(22, 189, 202, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-teal-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #0694A2 !important;
    border-color: rgba(6, 148, 162, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-teal-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #047481 !important;
    border-color: rgba(4, 116, 129, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-teal-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #036672 !important;
    border-color: rgba(3, 102, 114, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-teal-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #05505C !important;
    border-color: rgba(5, 80, 92, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-teal-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #014451 !important;
    border-color: rgba(1, 68, 81, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-teal > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #0694A2 !important;
    border-color: rgba(6, 148, 162, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-blue-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #EBF5FF !important;
    border-color: rgba(235, 245, 255, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-blue-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E1EFFE !important;
    border-color: rgba(225, 239, 254, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-blue-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #C3DDFD !important;
    border-color: rgba(195, 221, 253, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-blue-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #A4CAFE !important;
    border-color: rgba(164, 202, 254, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-blue-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #76A9FA !important;
    border-color: rgba(118, 169, 250, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-blue-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #3F83F8 !important;
    border-color: rgba(63, 131, 248, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-blue-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #1C64F2 !important;
    border-color: rgba(28, 100, 242, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-blue-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #1A56DB !important;
    border-color: rgba(26, 86, 219, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-blue-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #1E429F !important;
    border-color: rgba(30, 66, 159, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-blue-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #233876 !important;
    border-color: rgba(35, 56, 118, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-blue > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #3F83F8 !important;
    border-color: rgba(63, 131, 248, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-indigo-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F0F5FF !important;
    border-color: rgba(240, 245, 255, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-indigo-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E5EDFF !important;
    border-color: rgba(229, 237, 255, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-indigo-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #CDDBFE !important;
    border-color: rgba(205, 219, 254, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-indigo-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #B4C6FC !important;
    border-color: rgba(180, 198, 252, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-indigo-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #8DA2FB !important;
    border-color: rgba(141, 162, 251, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-indigo-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #6875F5 !important;
    border-color: rgba(104, 117, 245, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-indigo-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #5850EC !important;
    border-color: rgba(88, 80, 236, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-indigo-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #5145CD !important;
    border-color: rgba(81, 69, 205, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-indigo-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #42389D !important;
    border-color: rgba(66, 56, 157, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-indigo-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #362F78 !important;
    border-color: rgba(54, 47, 120, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-indigo > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #6875F5 !important;
    border-color: rgba(104, 117, 245, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-purple-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F6F5FF !important;
    border-color: rgba(246, 245, 255, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-purple-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #EDEBFE !important;
    border-color: rgba(237, 235, 254, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-purple-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #DCD7FE !important;
    border-color: rgba(220, 215, 254, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-purple-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #CABFFD !important;
    border-color: rgba(202, 191, 253, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-purple-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #AC94FA !important;
    border-color: rgba(172, 148, 250, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-purple-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9061F9 !important;
    border-color: rgba(144, 97, 249, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-purple-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #7E3AF2 !important;
    border-color: rgba(126, 58, 242, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-purple-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #6C2BD9 !important;
    border-color: rgba(108, 43, 217, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-purple-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #5521B5 !important;
    border-color: rgba(85, 33, 181, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-purple-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #4A1D96 !important;
    border-color: rgba(74, 29, 150, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-purple > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #9061F9 !important;
    border-color: rgba(144, 97, 249, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-pink-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FDF2F8 !important;
    border-color: rgba(253, 242, 248, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-pink-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FCE8F3 !important;
    border-color: rgba(252, 232, 243, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-pink-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #FAD1E8 !important;
    border-color: rgba(250, 209, 232, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-pink-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F8B4D9 !important;
    border-color: rgba(248, 180, 217, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-pink-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #F17EB8 !important;
    border-color: rgba(241, 126, 184, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-pink-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E74694 !important;
    border-color: rgba(231, 70, 148, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-pink-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #D61F69 !important;
    border-color: rgba(214, 31, 105, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-pink-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #BF125D !important;
    border-color: rgba(191, 18, 93, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-pink-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #99154B !important;
    border-color: rgba(153, 21, 75, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-pink-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #751A3D !important;
    border-color: rgba(117, 26, 61, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-pink > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
    border-color: #E74694 !important;
    border-color: rgba(231, 70, 148, var(--divide-opacity)) !important;
  }

  .gt-md\:divide-opacity-0 > :not(template) ~ :not(template) {
    --divide-opacity: 0 !important;
  }

  .gt-md\:divide-opacity-12 > :not(template) ~ :not(template) {
    --divide-opacity: 0.12 !important;
  }

  .gt-md\:divide-opacity-25 > :not(template) ~ :not(template) {
    --divide-opacity: 0.25 !important;
  }

  .gt-md\:divide-opacity-38 > :not(template) ~ :not(template) {
    --divide-opacity: 0.38 !important;
  }

  .gt-md\:divide-opacity-50 > :not(template) ~ :not(template) {
    --divide-opacity: 0.5 !important;
  }

  .gt-md\:divide-opacity-54 > :not(template) ~ :not(template) {
    --divide-opacity: 0.54 !important;
  }

  .gt-md\:divide-opacity-70 > :not(template) ~ :not(template) {
    --divide-opacity: 0.70 !important;
  }

  .gt-md\:divide-opacity-75 > :not(template) ~ :not(template) {
    --divide-opacity: 0.75 !important;
  }

  .gt-md\:divide-opacity-84 > :not(template) ~ :not(template) {
    --divide-opacity: 0.84 !important;
  }

  .gt-md\:divide-opacity-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1 !important;
  }

  .gt-md\:sr-only {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border-width: 0 !important;
  }

  .gt-md\:not-sr-only {
    position: static !important;
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: visible !important;
    clip: auto !important;
    white-space: normal !important;
  }

  .gt-md\:focus\:sr-only:focus {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border-width: 0 !important;
  }

  .gt-md\:focus\:not-sr-only:focus {
    position: static !important;
    width: auto !important;
    height: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: visible !important;
    clip: auto !important;
    white-space: normal !important;
  }

  .gt-md\:appearance-none {
    -webkit-appearance: none !important;
       -moz-appearance: none !important;
            appearance: none !important;
  }

  .gt-md\:bg-fixed {
    background-attachment: fixed !important;
  }

  .gt-md\:bg-local {
    background-attachment: local !important;
  }

  .gt-md\:bg-scroll {
    background-attachment: scroll !important;
  }

  .gt-md\:bg-opacity-0 {
    --bg-opacity: 0 !important;
  }

  .gt-md\:bg-opacity-12 {
    --bg-opacity: 0.12 !important;
  }

  .gt-md\:bg-opacity-25 {
    --bg-opacity: 0.25 !important;
  }

  .gt-md\:bg-opacity-38 {
    --bg-opacity: 0.38 !important;
  }

  .gt-md\:bg-opacity-50 {
    --bg-opacity: 0.5 !important;
  }

  .gt-md\:bg-opacity-54 {
    --bg-opacity: 0.54 !important;
  }

  .gt-md\:bg-opacity-70 {
    --bg-opacity: 0.70 !important;
  }

  .gt-md\:bg-opacity-75 {
    --bg-opacity: 0.75 !important;
  }

  .gt-md\:bg-opacity-84 {
    --bg-opacity: 0.84 !important;
  }

  .gt-md\:bg-opacity-100 {
    --bg-opacity: 1 !important;
  }

  .gt-md\:hover\:bg-opacity-0:hover {
    --bg-opacity: 0 !important;
  }

  .gt-md\:hover\:bg-opacity-12:hover {
    --bg-opacity: 0.12 !important;
  }

  .gt-md\:hover\:bg-opacity-25:hover {
    --bg-opacity: 0.25 !important;
  }

  .gt-md\:hover\:bg-opacity-38:hover {
    --bg-opacity: 0.38 !important;
  }

  .gt-md\:hover\:bg-opacity-50:hover {
    --bg-opacity: 0.5 !important;
  }

  .gt-md\:hover\:bg-opacity-54:hover {
    --bg-opacity: 0.54 !important;
  }

  .gt-md\:hover\:bg-opacity-70:hover {
    --bg-opacity: 0.70 !important;
  }

  .gt-md\:hover\:bg-opacity-75:hover {
    --bg-opacity: 0.75 !important;
  }

  .gt-md\:hover\:bg-opacity-84:hover {
    --bg-opacity: 0.84 !important;
  }

  .gt-md\:hover\:bg-opacity-100:hover {
    --bg-opacity: 1 !important;
  }

  .gt-md\:focus\:bg-opacity-0:focus {
    --bg-opacity: 0 !important;
  }

  .gt-md\:focus\:bg-opacity-12:focus {
    --bg-opacity: 0.12 !important;
  }

  .gt-md\:focus\:bg-opacity-25:focus {
    --bg-opacity: 0.25 !important;
  }

  .gt-md\:focus\:bg-opacity-38:focus {
    --bg-opacity: 0.38 !important;
  }

  .gt-md\:focus\:bg-opacity-50:focus {
    --bg-opacity: 0.5 !important;
  }

  .gt-md\:focus\:bg-opacity-54:focus {
    --bg-opacity: 0.54 !important;
  }

  .gt-md\:focus\:bg-opacity-70:focus {
    --bg-opacity: 0.70 !important;
  }

  .gt-md\:focus\:bg-opacity-75:focus {
    --bg-opacity: 0.75 !important;
  }

  .gt-md\:focus\:bg-opacity-84:focus {
    --bg-opacity: 0.84 !important;
  }

  .gt-md\:focus\:bg-opacity-100:focus {
    --bg-opacity: 1 !important;
  }

  .gt-md\:bg-bottom {
    background-position: bottom !important;
  }

  .gt-md\:bg-center {
    background-position: center !important;
  }

  .gt-md\:bg-left {
    background-position: left !important;
  }

  .gt-md\:bg-left-bottom {
    background-position: left bottom !important;
  }

  .gt-md\:bg-left-top {
    background-position: left top !important;
  }

  .gt-md\:bg-right {
    background-position: right !important;
  }

  .gt-md\:bg-right-bottom {
    background-position: right bottom !important;
  }

  .gt-md\:bg-right-top {
    background-position: right top !important;
  }

  .gt-md\:bg-top {
    background-position: top !important;
  }

  .gt-md\:bg-repeat {
    background-repeat: repeat !important;
  }

  .gt-md\:bg-no-repeat {
    background-repeat: no-repeat !important;
  }

  .gt-md\:bg-repeat-x {
    background-repeat: repeat-x !important;
  }

  .gt-md\:bg-repeat-y {
    background-repeat: repeat-y !important;
  }

  .gt-md\:bg-repeat-round {
    background-repeat: round !important;
  }

  .gt-md\:bg-repeat-space {
    background-repeat: space !important;
  }

  .gt-md\:bg-auto {
    background-size: auto !important;
  }

  .gt-md\:bg-cover {
    background-size: cover !important;
  }

  .gt-md\:bg-contain {
    background-size: contain !important;
  }

  .gt-md\:border-collapse {
    border-collapse: collapse !important;
  }

  .gt-md\:border-separate {
    border-collapse: separate !important;
  }

  .gt-md\:border-opacity-0 {
    --border-opacity: 0 !important;
  }

  .gt-md\:border-opacity-12 {
    --border-opacity: 0.12 !important;
  }

  .gt-md\:border-opacity-25 {
    --border-opacity: 0.25 !important;
  }

  .gt-md\:border-opacity-38 {
    --border-opacity: 0.38 !important;
  }

  .gt-md\:border-opacity-50 {
    --border-opacity: 0.5 !important;
  }

  .gt-md\:border-opacity-54 {
    --border-opacity: 0.54 !important;
  }

  .gt-md\:border-opacity-70 {
    --border-opacity: 0.70 !important;
  }

  .gt-md\:border-opacity-75 {
    --border-opacity: 0.75 !important;
  }

  .gt-md\:border-opacity-84 {
    --border-opacity: 0.84 !important;
  }

  .gt-md\:border-opacity-100 {
    --border-opacity: 1 !important;
  }

  .gt-md\:hover\:border-opacity-0:hover {
    --border-opacity: 0 !important;
  }

  .gt-md\:hover\:border-opacity-12:hover {
    --border-opacity: 0.12 !important;
  }

  .gt-md\:hover\:border-opacity-25:hover {
    --border-opacity: 0.25 !important;
  }

  .gt-md\:hover\:border-opacity-38:hover {
    --border-opacity: 0.38 !important;
  }

  .gt-md\:hover\:border-opacity-50:hover {
    --border-opacity: 0.5 !important;
  }

  .gt-md\:hover\:border-opacity-54:hover {
    --border-opacity: 0.54 !important;
  }

  .gt-md\:hover\:border-opacity-70:hover {
    --border-opacity: 0.70 !important;
  }

  .gt-md\:hover\:border-opacity-75:hover {
    --border-opacity: 0.75 !important;
  }

  .gt-md\:hover\:border-opacity-84:hover {
    --border-opacity: 0.84 !important;
  }

  .gt-md\:hover\:border-opacity-100:hover {
    --border-opacity: 1 !important;
  }

  .gt-md\:focus\:border-opacity-0:focus {
    --border-opacity: 0 !important;
  }

  .gt-md\:focus\:border-opacity-12:focus {
    --border-opacity: 0.12 !important;
  }

  .gt-md\:focus\:border-opacity-25:focus {
    --border-opacity: 0.25 !important;
  }

  .gt-md\:focus\:border-opacity-38:focus {
    --border-opacity: 0.38 !important;
  }

  .gt-md\:focus\:border-opacity-50:focus {
    --border-opacity: 0.5 !important;
  }

  .gt-md\:focus\:border-opacity-54:focus {
    --border-opacity: 0.54 !important;
  }

  .gt-md\:focus\:border-opacity-70:focus {
    --border-opacity: 0.70 !important;
  }

  .gt-md\:focus\:border-opacity-75:focus {
    --border-opacity: 0.75 !important;
  }

  .gt-md\:focus\:border-opacity-84:focus {
    --border-opacity: 0.84 !important;
  }

  .gt-md\:focus\:border-opacity-100:focus {
    --border-opacity: 1 !important;
  }

  .gt-md\:rounded-none {
    border-radius: 0 !important;
  }

  .gt-md\:rounded-sm {
    border-radius: 0.125rem !important;
  }

  .gt-md\:rounded {
    border-radius: 0.25rem !important;
  }

  .gt-md\:rounded-md {
    border-radius: 0.375rem !important;
  }

  .gt-md\:rounded-lg {
    border-radius: 0.5rem !important;
  }

  .gt-md\:rounded-full {
    border-radius: 9999px !important;
  }

  .gt-md\:rounded-t-none {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .gt-md\:rounded-r-none {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .gt-md\:rounded-b-none {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .gt-md\:rounded-l-none {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .gt-md\:rounded-t-sm {
    border-top-left-radius: 0.125rem !important;
    border-top-right-radius: 0.125rem !important;
  }

  .gt-md\:rounded-r-sm {
    border-top-right-radius: 0.125rem !important;
    border-bottom-right-radius: 0.125rem !important;
  }

  .gt-md\:rounded-b-sm {
    border-bottom-right-radius: 0.125rem !important;
    border-bottom-left-radius: 0.125rem !important;
  }

  .gt-md\:rounded-l-sm {
    border-top-left-radius: 0.125rem !important;
    border-bottom-left-radius: 0.125rem !important;
  }

  .gt-md\:rounded-t {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }

  .gt-md\:rounded-r {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }

  .gt-md\:rounded-b {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }

  .gt-md\:rounded-l {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }

  .gt-md\:rounded-t-md {
    border-top-left-radius: 0.375rem !important;
    border-top-right-radius: 0.375rem !important;
  }

  .gt-md\:rounded-r-md {
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }

  .gt-md\:rounded-b-md {
    border-bottom-right-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }

  .gt-md\:rounded-l-md {
    border-top-left-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }

  .gt-md\:rounded-t-lg {
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
  }

  .gt-md\:rounded-r-lg {
    border-top-right-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }

  .gt-md\:rounded-b-lg {
    border-bottom-right-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }

  .gt-md\:rounded-l-lg {
    border-top-left-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }

  .gt-md\:rounded-t-full {
    border-top-left-radius: 9999px !important;
    border-top-right-radius: 9999px !important;
  }

  .gt-md\:rounded-r-full {
    border-top-right-radius: 9999px !important;
    border-bottom-right-radius: 9999px !important;
  }

  .gt-md\:rounded-b-full {
    border-bottom-right-radius: 9999px !important;
    border-bottom-left-radius: 9999px !important;
  }

  .gt-md\:rounded-l-full {
    border-top-left-radius: 9999px !important;
    border-bottom-left-radius: 9999px !important;
  }

  .gt-md\:rounded-tl-none {
    border-top-left-radius: 0 !important;
  }

  .gt-md\:rounded-tr-none {
    border-top-right-radius: 0 !important;
  }

  .gt-md\:rounded-br-none {
    border-bottom-right-radius: 0 !important;
  }

  .gt-md\:rounded-bl-none {
    border-bottom-left-radius: 0 !important;
  }

  .gt-md\:rounded-tl-sm {
    border-top-left-radius: 0.125rem !important;
  }

  .gt-md\:rounded-tr-sm {
    border-top-right-radius: 0.125rem !important;
  }

  .gt-md\:rounded-br-sm {
    border-bottom-right-radius: 0.125rem !important;
  }

  .gt-md\:rounded-bl-sm {
    border-bottom-left-radius: 0.125rem !important;
  }

  .gt-md\:rounded-tl {
    border-top-left-radius: 0.25rem !important;
  }

  .gt-md\:rounded-tr {
    border-top-right-radius: 0.25rem !important;
  }

  .gt-md\:rounded-br {
    border-bottom-right-radius: 0.25rem !important;
  }

  .gt-md\:rounded-bl {
    border-bottom-left-radius: 0.25rem !important;
  }

  .gt-md\:rounded-tl-md {
    border-top-left-radius: 0.375rem !important;
  }

  .gt-md\:rounded-tr-md {
    border-top-right-radius: 0.375rem !important;
  }

  .gt-md\:rounded-br-md {
    border-bottom-right-radius: 0.375rem !important;
  }

  .gt-md\:rounded-bl-md {
    border-bottom-left-radius: 0.375rem !important;
  }

  .gt-md\:rounded-tl-lg {
    border-top-left-radius: 0.5rem !important;
  }

  .gt-md\:rounded-tr-lg {
    border-top-right-radius: 0.5rem !important;
  }

  .gt-md\:rounded-br-lg {
    border-bottom-right-radius: 0.5rem !important;
  }

  .gt-md\:rounded-bl-lg {
    border-bottom-left-radius: 0.5rem !important;
  }

  .gt-md\:rounded-tl-full {
    border-top-left-radius: 9999px !important;
  }

  .gt-md\:rounded-tr-full {
    border-top-right-radius: 9999px !important;
  }

  .gt-md\:rounded-br-full {
    border-bottom-right-radius: 9999px !important;
  }

  .gt-md\:rounded-bl-full {
    border-bottom-left-radius: 9999px !important;
  }

  .gt-md\:border-solid {
    border-style: solid !important;
  }

  .gt-md\:border-dashed {
    border-style: dashed !important;
  }

  .gt-md\:border-dotted {
    border-style: dotted !important;
  }

  .gt-md\:border-double {
    border-style: double !important;
  }

  .gt-md\:border-none {
    border-style: none !important;
  }

  .gt-md\:border-0 {
    border-width: 0 !important;
  }

  .gt-md\:border-2 {
    border-width: 2px !important;
  }

  .gt-md\:border-4 {
    border-width: 4px !important;
  }

  .gt-md\:border-8 {
    border-width: 8px !important;
  }

  .gt-md\:border {
    border-width: 1px !important;
  }

  .gt-md\:border-t-0 {
    border-top-width: 0 !important;
  }

  .gt-md\:border-r-0 {
    border-right-width: 0 !important;
  }

  .gt-md\:border-b-0 {
    border-bottom-width: 0 !important;
  }

  .gt-md\:border-l-0 {
    border-left-width: 0 !important;
  }

  .gt-md\:border-t-2 {
    border-top-width: 2px !important;
  }

  .gt-md\:border-r-2 {
    border-right-width: 2px !important;
  }

  .gt-md\:border-b-2 {
    border-bottom-width: 2px !important;
  }

  .gt-md\:border-l-2 {
    border-left-width: 2px !important;
  }

  .gt-md\:border-t-4 {
    border-top-width: 4px !important;
  }

  .gt-md\:border-r-4 {
    border-right-width: 4px !important;
  }

  .gt-md\:border-b-4 {
    border-bottom-width: 4px !important;
  }

  .gt-md\:border-l-4 {
    border-left-width: 4px !important;
  }

  .gt-md\:border-t-8 {
    border-top-width: 8px !important;
  }

  .gt-md\:border-r-8 {
    border-right-width: 8px !important;
  }

  .gt-md\:border-b-8 {
    border-bottom-width: 8px !important;
  }

  .gt-md\:border-l-8 {
    border-left-width: 8px !important;
  }

  .gt-md\:border-t {
    border-top-width: 1px !important;
  }

  .gt-md\:border-r {
    border-right-width: 1px !important;
  }

  .gt-md\:border-b {
    border-bottom-width: 1px !important;
  }

  .gt-md\:border-l {
    border-left-width: 1px !important;
  }

  .gt-md\:first\:border-0:first-child {
    border-width: 0 !important;
  }

  .gt-md\:first\:border-2:first-child {
    border-width: 2px !important;
  }

  .gt-md\:first\:border-4:first-child {
    border-width: 4px !important;
  }

  .gt-md\:first\:border-8:first-child {
    border-width: 8px !important;
  }

  .gt-md\:first\:border:first-child {
    border-width: 1px !important;
  }

  .gt-md\:first\:border-t-0:first-child {
    border-top-width: 0 !important;
  }

  .gt-md\:first\:border-r-0:first-child {
    border-right-width: 0 !important;
  }

  .gt-md\:first\:border-b-0:first-child {
    border-bottom-width: 0 !important;
  }

  .gt-md\:first\:border-l-0:first-child {
    border-left-width: 0 !important;
  }

  .gt-md\:first\:border-t-2:first-child {
    border-top-width: 2px !important;
  }

  .gt-md\:first\:border-r-2:first-child {
    border-right-width: 2px !important;
  }

  .gt-md\:first\:border-b-2:first-child {
    border-bottom-width: 2px !important;
  }

  .gt-md\:first\:border-l-2:first-child {
    border-left-width: 2px !important;
  }

  .gt-md\:first\:border-t-4:first-child {
    border-top-width: 4px !important;
  }

  .gt-md\:first\:border-r-4:first-child {
    border-right-width: 4px !important;
  }

  .gt-md\:first\:border-b-4:first-child {
    border-bottom-width: 4px !important;
  }

  .gt-md\:first\:border-l-4:first-child {
    border-left-width: 4px !important;
  }

  .gt-md\:first\:border-t-8:first-child {
    border-top-width: 8px !important;
  }

  .gt-md\:first\:border-r-8:first-child {
    border-right-width: 8px !important;
  }

  .gt-md\:first\:border-b-8:first-child {
    border-bottom-width: 8px !important;
  }

  .gt-md\:first\:border-l-8:first-child {
    border-left-width: 8px !important;
  }

  .gt-md\:first\:border-t:first-child {
    border-top-width: 1px !important;
  }

  .gt-md\:first\:border-r:first-child {
    border-right-width: 1px !important;
  }

  .gt-md\:first\:border-b:first-child {
    border-bottom-width: 1px !important;
  }

  .gt-md\:first\:border-l:first-child {
    border-left-width: 1px !important;
  }

  .gt-md\:last\:border-0:last-child {
    border-width: 0 !important;
  }

  .gt-md\:last\:border-2:last-child {
    border-width: 2px !important;
  }

  .gt-md\:last\:border-4:last-child {
    border-width: 4px !important;
  }

  .gt-md\:last\:border-8:last-child {
    border-width: 8px !important;
  }

  .gt-md\:last\:border:last-child {
    border-width: 1px !important;
  }

  .gt-md\:last\:border-t-0:last-child {
    border-top-width: 0 !important;
  }

  .gt-md\:last\:border-r-0:last-child {
    border-right-width: 0 !important;
  }

  .gt-md\:last\:border-b-0:last-child {
    border-bottom-width: 0 !important;
  }

  .gt-md\:last\:border-l-0:last-child {
    border-left-width: 0 !important;
  }

  .gt-md\:last\:border-t-2:last-child {
    border-top-width: 2px !important;
  }

  .gt-md\:last\:border-r-2:last-child {
    border-right-width: 2px !important;
  }

  .gt-md\:last\:border-b-2:last-child {
    border-bottom-width: 2px !important;
  }

  .gt-md\:last\:border-l-2:last-child {
    border-left-width: 2px !important;
  }

  .gt-md\:last\:border-t-4:last-child {
    border-top-width: 4px !important;
  }

  .gt-md\:last\:border-r-4:last-child {
    border-right-width: 4px !important;
  }

  .gt-md\:last\:border-b-4:last-child {
    border-bottom-width: 4px !important;
  }

  .gt-md\:last\:border-l-4:last-child {
    border-left-width: 4px !important;
  }

  .gt-md\:last\:border-t-8:last-child {
    border-top-width: 8px !important;
  }

  .gt-md\:last\:border-r-8:last-child {
    border-right-width: 8px !important;
  }

  .gt-md\:last\:border-b-8:last-child {
    border-bottom-width: 8px !important;
  }

  .gt-md\:last\:border-l-8:last-child {
    border-left-width: 8px !important;
  }

  .gt-md\:last\:border-t:last-child {
    border-top-width: 1px !important;
  }

  .gt-md\:last\:border-r:last-child {
    border-right-width: 1px !important;
  }

  .gt-md\:last\:border-b:last-child {
    border-bottom-width: 1px !important;
  }

  .gt-md\:last\:border-l:last-child {
    border-left-width: 1px !important;
  }

  .gt-md\:box-border {
    box-sizing: border-box !important;
  }

  .gt-md\:box-content {
    box-sizing: content-box !important;
  }

  .gt-md\:block {
    display: block !important;
  }

  .gt-md\:inline-block {
    display: inline-block !important;
  }

  .gt-md\:inline {
    display: inline !important;
  }

  .gt-md\:flex {
    display: flex !important;
  }

  .gt-md\:inline-flex {
    display: inline-flex !important;
  }

  .gt-md\:table {
    display: table !important;
  }

  .gt-md\:table-caption {
    display: table-caption !important;
  }

  .gt-md\:table-cell {
    display: table-cell !important;
  }

  .gt-md\:table-column {
    display: table-column !important;
  }

  .gt-md\:table-column-group {
    display: table-column-group !important;
  }

  .gt-md\:table-footer-group {
    display: table-footer-group !important;
  }

  .gt-md\:table-header-group {
    display: table-header-group !important;
  }

  .gt-md\:table-row-group {
    display: table-row-group !important;
  }

  .gt-md\:table-row {
    display: table-row !important;
  }

  .gt-md\:flow-root {
    display: flow-root !important;
  }

  .gt-md\:grid {
    display: grid !important;
  }

  .gt-md\:inline-grid {
    display: inline-grid !important;
  }

  .gt-md\:hidden {
    display: none !important;
  }

  .gt-md\:flex-row {
    flex-direction: row !important;
  }

  .gt-md\:flex-row-reverse {
    flex-direction: row-reverse !important;
  }

  .gt-md\:flex-col {
    flex-direction: column !important;
  }

  .gt-md\:flex-col-reverse {
    flex-direction: column-reverse !important;
  }

  .gt-md\:flex-wrap {
    flex-wrap: wrap !important;
  }

  .gt-md\:flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gt-md\:flex-nowrap {
    flex-wrap: nowrap !important;
  }

  .gt-md\:items-start {
    align-items: flex-start !important;
  }

  .gt-md\:items-end {
    align-items: flex-end !important;
  }

  .gt-md\:items-center {
    align-items: center !important;
  }

  .gt-md\:items-baseline {
    align-items: baseline !important;
  }

  .gt-md\:items-stretch {
    align-items: stretch !important;
  }

  .gt-md\:self-auto {
    align-self: auto !important;
  }

  .gt-md\:self-start {
    align-self: flex-start !important;
  }

  .gt-md\:self-end {
    align-self: flex-end !important;
  }

  .gt-md\:self-center {
    align-self: center !important;
  }

  .gt-md\:self-stretch {
    align-self: stretch !important;
  }

  .gt-md\:justify-start {
    justify-content: flex-start !important;
  }

  .gt-md\:justify-end {
    justify-content: flex-end !important;
  }

  .gt-md\:justify-center {
    justify-content: center !important;
  }

  .gt-md\:justify-between {
    justify-content: space-between !important;
  }

  .gt-md\:justify-around {
    justify-content: space-around !important;
  }

  .gt-md\:justify-evenly {
    justify-content: space-evenly !important;
  }

  .gt-md\:content-center {
    align-content: center !important;
  }

  .gt-md\:content-start {
    align-content: flex-start !important;
  }

  .gt-md\:content-end {
    align-content: flex-end !important;
  }

  .gt-md\:content-between {
    align-content: space-between !important;
  }

  .gt-md\:content-around {
    align-content: space-around !important;
  }

  .gt-md\:flex-0 {
    flex: 0 0 auto !important;
  }

  .gt-md\:flex-1 {
    flex: 1 1 0% !important;
  }

  .gt-md\:flex-auto {
    flex: 1 1 auto !important;
  }

  .gt-md\:flex-initial {
    flex: 0 1 auto !important;
  }

  .gt-md\:flex-none {
    flex: none !important;
  }

  .gt-md\:flex-grow-0 {
    flex-grow: 0 !important;
  }

  .gt-md\:flex-grow {
    flex-grow: 1 !important;
  }

  .gt-md\:flex-shrink-0 {
    flex-shrink: 0 !important;
  }

  .gt-md\:flex-shrink {
    flex-shrink: 1 !important;
  }

  .gt-md\:order-1 {
    order: 1 !important;
  }

  .gt-md\:order-2 {
    order: 2 !important;
  }

  .gt-md\:order-3 {
    order: 3 !important;
  }

  .gt-md\:order-4 {
    order: 4 !important;
  }

  .gt-md\:order-5 {
    order: 5 !important;
  }

  .gt-md\:order-6 {
    order: 6 !important;
  }

  .gt-md\:order-7 {
    order: 7 !important;
  }

  .gt-md\:order-8 {
    order: 8 !important;
  }

  .gt-md\:order-9 {
    order: 9 !important;
  }

  .gt-md\:order-10 {
    order: 10 !important;
  }

  .gt-md\:order-11 {
    order: 11 !important;
  }

  .gt-md\:order-12 {
    order: 12 !important;
  }

  .gt-md\:order-first {
    order: -9999 !important;
  }

  .gt-md\:order-last {
    order: 9999 !important;
  }

  .gt-md\:order-none {
    order: 0 !important;
  }

  .gt-md\:font-thin {
    font-weight: 100 !important;
  }

  .gt-md\:font-extralight {
    font-weight: 200 !important;
  }

  .gt-md\:font-light {
    font-weight: 300 !important;
  }

  .gt-md\:font-normal {
    font-weight: 400 !important;
  }

  .gt-md\:font-medium {
    font-weight: 500 !important;
  }

  .gt-md\:font-semibold {
    font-weight: 600 !important;
  }

  .gt-md\:font-bold {
    font-weight: 700 !important;
  }

  .gt-md\:font-extrabold {
    font-weight: 800 !important;
  }

  .gt-md\:font-black {
    font-weight: 900 !important;
  }

  .gt-md\:h-0 {
    height: 0 !important;
  }

  .gt-md\:h-1 {
    height: 0.25rem !important;
  }

  .gt-md\:h-2 {
    height: 0.5rem !important;
  }

  .gt-md\:h-3 {
    height: 0.75rem !important;
  }

  .gt-md\:h-4 {
    height: 1rem !important;
  }

  .gt-md\:h-5 {
    height: 1.25rem !important;
  }

  .gt-md\:h-6 {
    height: 1.5rem !important;
  }

  .gt-md\:h-8 {
    height: 2rem !important;
  }

  .gt-md\:h-10 {
    height: 2.5rem !important;
  }

  .gt-md\:h-12 {
    height: 3rem !important;
  }

  .gt-md\:h-14 {
    height: 3.5rem !important;
  }

  .gt-md\:h-16 {
    height: 4rem !important;
  }

  .gt-md\:h-18 {
    height: 4.5rem !important;
  }

  .gt-md\:h-20 {
    height: 5rem !important;
  }

  .gt-md\:h-22 {
    height: 5.5rem !important;
  }

  .gt-md\:h-24 {
    height: 6rem !important;
  }

  .gt-md\:h-26 {
    height: 6.5rem !important;
  }

  .gt-md\:h-28 {
    height: 7rem !important;
  }

  .gt-md\:h-30 {
    height: 7.5rem !important;
  }

  .gt-md\:h-32 {
    height: 8rem !important;
  }

  .gt-md\:h-36 {
    height: 9rem !important;
  }

  .gt-md\:h-40 {
    height: 10rem !important;
  }

  .gt-md\:h-48 {
    height: 12rem !important;
  }

  .gt-md\:h-50 {
    height: 12.5rem !important;
  }

  .gt-md\:h-56 {
    height: 14rem !important;
  }

  .gt-md\:h-60 {
    height: 15rem !important;
  }

  .gt-md\:h-64 {
    height: 16rem !important;
  }

  .gt-md\:h-80 {
    height: 20rem !important;
  }

  .gt-md\:h-90 {
    height: 24rem !important;
  }

  .gt-md\:h-100 {
    height: 25rem !important;
  }

  .gt-md\:h-120 {
    height: 30rem !important;
  }

  .gt-md\:h-128 {
    height: 32rem !important;
  }

  .gt-md\:h-140 {
    height: 35rem !important;
  }

  .gt-md\:h-160 {
    height: 40rem !important;
  }

  .gt-md\:h-180 {
    height: 45rem !important;
  }

  .gt-md\:h-192 {
    height: 48rem !important;
  }

  .gt-md\:h-200 {
    height: 50rem !important;
  }

  .gt-md\:h-240 {
    height: 60rem !important;
  }

  .gt-md\:h-256 {
    height: 64rem !important;
  }

  .gt-md\:h-280 {
    height: 70rem !important;
  }

  .gt-md\:h-320 {
    height: 80rem !important;
  }

  .gt-md\:h-360 {
    height: 90rem !important;
  }

  .gt-md\:h-400 {
    height: 100rem !important;
  }

  .gt-md\:h-480 {
    height: 120rem !important;
  }

  .gt-md\:h-auto {
    height: auto !important;
  }

  .gt-md\:h-px {
    height: 1px !important;
  }

  .gt-md\:h-2px {
    height: 2px !important;
  }

  .gt-md\:h-full {
    height: 100% !important;
  }

  .gt-md\:h-screen {
    height: 100vh !important;
  }

  .gt-md\:h-1\/2 {
    height: 50% !important;
  }

  .gt-md\:h-1\/3 {
    height: 33.33333% !important;
  }

  .gt-md\:h-2\/3 {
    height: 66.66667% !important;
  }

  .gt-md\:h-1\/4 {
    height: 25% !important;
  }

  .gt-md\:h-2\/4 {
    height: 50% !important;
  }

  .gt-md\:h-3\/4 {
    height: 75% !important;
  }

  .gt-md\:h-1\/5 {
    height: 20% !important;
  }

  .gt-md\:h-2\/5 {
    height: 40% !important;
  }

  .gt-md\:h-3\/5 {
    height: 60% !important;
  }

  .gt-md\:h-4\/5 {
    height: 80% !important;
  }

  .gt-md\:h-1\/12 {
    height: 8.33333% !important;
  }

  .gt-md\:h-2\/12 {
    height: 16.66667% !important;
  }

  .gt-md\:h-3\/12 {
    height: 25% !important;
  }

  .gt-md\:h-4\/12 {
    height: 33.33333% !important;
  }

  .gt-md\:h-5\/12 {
    height: 41.66667% !important;
  }

  .gt-md\:h-6\/12 {
    height: 50% !important;
  }

  .gt-md\:h-7\/12 {
    height: 58.33333% !important;
  }

  .gt-md\:h-8\/12 {
    height: 66.66667% !important;
  }

  .gt-md\:h-9\/12 {
    height: 75% !important;
  }

  .gt-md\:h-10\/12 {
    height: 83.33333% !important;
  }

  .gt-md\:h-11\/12 {
    height: 91.66667% !important;
  }

  .gt-md\:text-xs {
    font-size: 0.625rem !important;
  }

  .gt-md\:text-sm {
    font-size: 0.75rem !important;
  }

  .gt-md\:text-md {
    font-size: 0.8125rem !important;
  }

  .gt-md\:text-base {
    font-size: 0.875rem !important;
  }

  .gt-md\:text-lg {
    font-size: 1rem !important;
  }

  .gt-md\:text-xl {
    font-size: 1.125rem !important;
  }

  .gt-md\:text-2xl {
    font-size: 1.25rem !important;
  }

  .gt-md\:text-3xl {
    font-size: 1.5rem !important;
  }

  .gt-md\:text-4xl {
    font-size: 2rem !important;
  }

  .gt-md\:text-5xl {
    font-size: 2.25rem !important;
  }

  .gt-md\:text-6xl {
    font-size: 2.5rem !important;
  }

  .gt-md\:text-7xl {
    font-size: 3rem !important;
  }

  .gt-md\:text-8xl {
    font-size: 4rem !important;
  }

  .gt-md\:text-9xl {
    font-size: 6rem !important;
  }

  .gt-md\:text-10xl {
    font-size: 8rem !important;
  }

  .gt-md\:leading-3 {
    line-height: .75rem !important;
  }

  .gt-md\:leading-4 {
    line-height: 1rem !important;
  }

  .gt-md\:leading-5 {
    line-height: 1.25rem !important;
  }

  .gt-md\:leading-6 {
    line-height: 1.5rem !important;
  }

  .gt-md\:leading-7 {
    line-height: 1.75rem !important;
  }

  .gt-md\:leading-8 {
    line-height: 2rem !important;
  }

  .gt-md\:leading-9 {
    line-height: 2.25rem !important;
  }

  .gt-md\:leading-10 {
    line-height: 2.5rem !important;
  }

  .gt-md\:leading-none {
    line-height: 1 !important;
  }

  .gt-md\:leading-tight {
    line-height: 1.25 !important;
  }

  .gt-md\:leading-snug {
    line-height: 1.375 !important;
  }

  .gt-md\:leading-normal {
    line-height: 1.5 !important;
  }

  .gt-md\:leading-relaxed {
    line-height: 1.625 !important;
  }

  .gt-md\:leading-loose {
    line-height: 2 !important;
  }

  .gt-md\:list-inside {
    list-style-position: inside !important;
  }

  .gt-md\:list-outside {
    list-style-position: outside !important;
  }

  .gt-md\:list-none {
    list-style-type: none !important;
  }

  .gt-md\:list-disc {
    list-style-type: disc !important;
  }

  .gt-md\:list-decimal {
    list-style-type: decimal !important;
  }

  .gt-md\:m-0 {
    margin: 0 !important;
  }

  .gt-md\:m-1 {
    margin: 0.25rem !important;
  }

  .gt-md\:m-2 {
    margin: 0.5rem !important;
  }

  .gt-md\:m-3 {
    margin: 0.75rem !important;
  }

  .gt-md\:m-4 {
    margin: 1rem !important;
  }

  .gt-md\:m-5 {
    margin: 1.25rem !important;
  }

  .gt-md\:m-6 {
    margin: 1.5rem !important;
  }

  .gt-md\:m-8 {
    margin: 2rem !important;
  }

  .gt-md\:m-10 {
    margin: 2.5rem !important;
  }

  .gt-md\:m-12 {
    margin: 3rem !important;
  }

  .gt-md\:m-14 {
    margin: 3.5rem !important;
  }

  .gt-md\:m-16 {
    margin: 4rem !important;
  }

  .gt-md\:m-18 {
    margin: 4.5rem !important;
  }

  .gt-md\:m-20 {
    margin: 5rem !important;
  }

  .gt-md\:m-22 {
    margin: 5.5rem !important;
  }

  .gt-md\:m-24 {
    margin: 6rem !important;
  }

  .gt-md\:m-26 {
    margin: 6.5rem !important;
  }

  .gt-md\:m-28 {
    margin: 7rem !important;
  }

  .gt-md\:m-30 {
    margin: 7.5rem !important;
  }

  .gt-md\:m-32 {
    margin: 8rem !important;
  }

  .gt-md\:m-36 {
    margin: 9rem !important;
  }

  .gt-md\:m-40 {
    margin: 10rem !important;
  }

  .gt-md\:m-48 {
    margin: 12rem !important;
  }

  .gt-md\:m-56 {
    margin: 14rem !important;
  }

  .gt-md\:m-64 {
    margin: 16rem !important;
  }

  .gt-md\:m-auto {
    margin: auto !important;
  }

  .gt-md\:m-px {
    margin: 1px !important;
  }

  .gt-md\:m-2px {
    margin: 2px !important;
  }

  .gt-md\:-m-1 {
    margin: -0.25rem !important;
  }

  .gt-md\:-m-2 {
    margin: -0.5rem !important;
  }

  .gt-md\:-m-3 {
    margin: -0.75rem !important;
  }

  .gt-md\:-m-4 {
    margin: -1rem !important;
  }

  .gt-md\:-m-5 {
    margin: -1.25rem !important;
  }

  .gt-md\:-m-6 {
    margin: -1.5rem !important;
  }

  .gt-md\:-m-8 {
    margin: -2rem !important;
  }

  .gt-md\:-m-10 {
    margin: -2.5rem !important;
  }

  .gt-md\:-m-12 {
    margin: -3rem !important;
  }

  .gt-md\:-m-14 {
    margin: -3.5rem !important;
  }

  .gt-md\:-m-16 {
    margin: -4rem !important;
  }

  .gt-md\:-m-18 {
    margin: -4.5rem !important;
  }

  .gt-md\:-m-20 {
    margin: -5rem !important;
  }

  .gt-md\:-m-22 {
    margin: -5.5rem !important;
  }

  .gt-md\:-m-24 {
    margin: -6rem !important;
  }

  .gt-md\:-m-26 {
    margin: -6.5rem !important;
  }

  .gt-md\:-m-28 {
    margin: -7rem !important;
  }

  .gt-md\:-m-30 {
    margin: -7.5rem !important;
  }

  .gt-md\:-m-32 {
    margin: -8rem !important;
  }

  .gt-md\:-m-36 {
    margin: -9rem !important;
  }

  .gt-md\:-m-40 {
    margin: -10rem !important;
  }

  .gt-md\:-m-48 {
    margin: -12rem !important;
  }

  .gt-md\:-m-56 {
    margin: -14rem !important;
  }

  .gt-md\:-m-64 {
    margin: -16rem !important;
  }

  .gt-md\:-m-px {
    margin: -1px !important;
  }

  .gt-md\:-m-2px {
    margin: -2px !important;
  }

  .gt-md\:my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .gt-md\:mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .gt-md\:my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .gt-md\:mx-1 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }

  .gt-md\:my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .gt-md\:mx-2 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .gt-md\:my-3 {
    margin-top: 0.75rem !important;
    margin-bottom: 0.75rem !important;
  }

  .gt-md\:mx-3 {
    margin-left: 0.75rem !important;
    margin-right: 0.75rem !important;
  }

  .gt-md\:my-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .gt-md\:mx-4 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  .gt-md\:my-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }

  .gt-md\:mx-5 {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
  }

  .gt-md\:my-6 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .gt-md\:mx-6 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .gt-md\:my-8 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

  .gt-md\:mx-8 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .gt-md\:my-10 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }

  .gt-md\:mx-10 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }

  .gt-md\:my-12 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .gt-md\:mx-12 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .gt-md\:my-14 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }

  .gt-md\:mx-14 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }

  .gt-md\:my-16 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }

  .gt-md\:mx-16 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }

  .gt-md\:my-18 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .gt-md\:mx-18 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }

  .gt-md\:my-20 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .gt-md\:mx-20 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }

  .gt-md\:my-22 {
    margin-top: 5.5rem !important;
    margin-bottom: 5.5rem !important;
  }

  .gt-md\:mx-22 {
    margin-left: 5.5rem !important;
    margin-right: 5.5rem !important;
  }

  .gt-md\:my-24 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important;
  }

  .gt-md\:mx-24 {
    margin-left: 6rem !important;
    margin-right: 6rem !important;
  }

  .gt-md\:my-26 {
    margin-top: 6.5rem !important;
    margin-bottom: 6.5rem !important;
  }

  .gt-md\:mx-26 {
    margin-left: 6.5rem !important;
    margin-right: 6.5rem !important;
  }

  .gt-md\:my-28 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important;
  }

  .gt-md\:mx-28 {
    margin-left: 7rem !important;
    margin-right: 7rem !important;
  }

  .gt-md\:my-30 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }

  .gt-md\:mx-30 {
    margin-left: 7.5rem !important;
    margin-right: 7.5rem !important;
  }

  .gt-md\:my-32 {
    margin-top: 8rem !important;
    margin-bottom: 8rem !important;
  }

  .gt-md\:mx-32 {
    margin-left: 8rem !important;
    margin-right: 8rem !important;
  }

  .gt-md\:my-36 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important;
  }

  .gt-md\:mx-36 {
    margin-left: 9rem !important;
    margin-right: 9rem !important;
  }

  .gt-md\:my-40 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }

  .gt-md\:mx-40 {
    margin-left: 10rem !important;
    margin-right: 10rem !important;
  }

  .gt-md\:my-48 {
    margin-top: 12rem !important;
    margin-bottom: 12rem !important;
  }

  .gt-md\:mx-48 {
    margin-left: 12rem !important;
    margin-right: 12rem !important;
  }

  .gt-md\:my-56 {
    margin-top: 14rem !important;
    margin-bottom: 14rem !important;
  }

  .gt-md\:mx-56 {
    margin-left: 14rem !important;
    margin-right: 14rem !important;
  }

  .gt-md\:my-64 {
    margin-top: 16rem !important;
    margin-bottom: 16rem !important;
  }

  .gt-md\:mx-64 {
    margin-left: 16rem !important;
    margin-right: 16rem !important;
  }

  .gt-md\:my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .gt-md\:mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .gt-md\:my-px {
    margin-top: 1px !important;
    margin-bottom: 1px !important;
  }

  .gt-md\:mx-px {
    margin-left: 1px !important;
    margin-right: 1px !important;
  }

  .gt-md\:my-2px {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }

  .gt-md\:mx-2px {
    margin-left: 2px !important;
    margin-right: 2px !important;
  }

  .gt-md\:-my-1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .gt-md\:-mx-1 {
    margin-left: -0.25rem !important;
    margin-right: -0.25rem !important;
  }

  .gt-md\:-my-2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .gt-md\:-mx-2 {
    margin-left: -0.5rem !important;
    margin-right: -0.5rem !important;
  }

  .gt-md\:-my-3 {
    margin-top: -0.75rem !important;
    margin-bottom: -0.75rem !important;
  }

  .gt-md\:-mx-3 {
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important;
  }

  .gt-md\:-my-4 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .gt-md\:-mx-4 {
    margin-left: -1rem !important;
    margin-right: -1rem !important;
  }

  .gt-md\:-my-5 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }

  .gt-md\:-mx-5 {
    margin-left: -1.25rem !important;
    margin-right: -1.25rem !important;
  }

  .gt-md\:-my-6 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .gt-md\:-mx-6 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .gt-md\:-my-8 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important;
  }

  .gt-md\:-mx-8 {
    margin-left: -2rem !important;
    margin-right: -2rem !important;
  }

  .gt-md\:-my-10 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }

  .gt-md\:-mx-10 {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;
  }

  .gt-md\:-my-12 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .gt-md\:-mx-12 {
    margin-left: -3rem !important;
    margin-right: -3rem !important;
  }

  .gt-md\:-my-14 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important;
  }

  .gt-md\:-mx-14 {
    margin-left: -3.5rem !important;
    margin-right: -3.5rem !important;
  }

  .gt-md\:-my-16 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important;
  }

  .gt-md\:-mx-16 {
    margin-left: -4rem !important;
    margin-right: -4rem !important;
  }

  .gt-md\:-my-18 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .gt-md\:-mx-18 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }

  .gt-md\:-my-20 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }

  .gt-md\:-mx-20 {
    margin-left: -5rem !important;
    margin-right: -5rem !important;
  }

  .gt-md\:-my-22 {
    margin-top: -5.5rem !important;
    margin-bottom: -5.5rem !important;
  }

  .gt-md\:-mx-22 {
    margin-left: -5.5rem !important;
    margin-right: -5.5rem !important;
  }

  .gt-md\:-my-24 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important;
  }

  .gt-md\:-mx-24 {
    margin-left: -6rem !important;
    margin-right: -6rem !important;
  }

  .gt-md\:-my-26 {
    margin-top: -6.5rem !important;
    margin-bottom: -6.5rem !important;
  }

  .gt-md\:-mx-26 {
    margin-left: -6.5rem !important;
    margin-right: -6.5rem !important;
  }

  .gt-md\:-my-28 {
    margin-top: -7rem !important;
    margin-bottom: -7rem !important;
  }

  .gt-md\:-mx-28 {
    margin-left: -7rem !important;
    margin-right: -7rem !important;
  }

  .gt-md\:-my-30 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important;
  }

  .gt-md\:-mx-30 {
    margin-left: -7.5rem !important;
    margin-right: -7.5rem !important;
  }

  .gt-md\:-my-32 {
    margin-top: -8rem !important;
    margin-bottom: -8rem !important;
  }

  .gt-md\:-mx-32 {
    margin-left: -8rem !important;
    margin-right: -8rem !important;
  }

  .gt-md\:-my-36 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important;
  }

  .gt-md\:-mx-36 {
    margin-left: -9rem !important;
    margin-right: -9rem !important;
  }

  .gt-md\:-my-40 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }

  .gt-md\:-mx-40 {
    margin-left: -10rem !important;
    margin-right: -10rem !important;
  }

  .gt-md\:-my-48 {
    margin-top: -12rem !important;
    margin-bottom: -12rem !important;
  }

  .gt-md\:-mx-48 {
    margin-left: -12rem !important;
    margin-right: -12rem !important;
  }

  .gt-md\:-my-56 {
    margin-top: -14rem !important;
    margin-bottom: -14rem !important;
  }

  .gt-md\:-mx-56 {
    margin-left: -14rem !important;
    margin-right: -14rem !important;
  }

  .gt-md\:-my-64 {
    margin-top: -16rem !important;
    margin-bottom: -16rem !important;
  }

  .gt-md\:-mx-64 {
    margin-left: -16rem !important;
    margin-right: -16rem !important;
  }

  .gt-md\:-my-px {
    margin-top: -1px !important;
    margin-bottom: -1px !important;
  }

  .gt-md\:-mx-px {
    margin-left: -1px !important;
    margin-right: -1px !important;
  }

  .gt-md\:-my-2px {
    margin-top: -2px !important;
    margin-bottom: -2px !important;
  }

  .gt-md\:-mx-2px {
    margin-left: -2px !important;
    margin-right: -2px !important;
  }

  .gt-md\:mt-0 {
    margin-top: 0 !important;
  }

  .gt-md\:mr-0 {
    margin-right: 0 !important;
  }

  .gt-md\:mb-0 {
    margin-bottom: 0 !important;
  }

  .gt-md\:ml-0 {
    margin-left: 0 !important;
  }

  .gt-md\:mt-1 {
    margin-top: 0.25rem !important;
  }

  .gt-md\:mr-1 {
    margin-right: 0.25rem !important;
  }

  .gt-md\:mb-1 {
    margin-bottom: 0.25rem !important;
  }

  .gt-md\:ml-1 {
    margin-left: 0.25rem !important;
  }

  .gt-md\:mt-2 {
    margin-top: 0.5rem !important;
  }

  .gt-md\:mr-2 {
    margin-right: 0.5rem !important;
  }

  .gt-md\:mb-2 {
    margin-bottom: 0.5rem !important;
  }

  .gt-md\:ml-2 {
    margin-left: 0.5rem !important;
  }

  .gt-md\:mt-3 {
    margin-top: 0.75rem !important;
  }

  .gt-md\:mr-3 {
    margin-right: 0.75rem !important;
  }

  .gt-md\:mb-3 {
    margin-bottom: 0.75rem !important;
  }

  .gt-md\:ml-3 {
    margin-left: 0.75rem !important;
  }

  .gt-md\:mt-4 {
    margin-top: 1rem !important;
  }

  .gt-md\:mr-4 {
    margin-right: 1rem !important;
  }

  .gt-md\:mb-4 {
    margin-bottom: 1rem !important;
  }

  .gt-md\:ml-4 {
    margin-left: 1rem !important;
  }

  .gt-md\:mt-5 {
    margin-top: 1.25rem !important;
  }

  .gt-md\:mr-5 {
    margin-right: 1.25rem !important;
  }

  .gt-md\:mb-5 {
    margin-bottom: 1.25rem !important;
  }

  .gt-md\:ml-5 {
    margin-left: 1.25rem !important;
  }

  .gt-md\:mt-6 {
    margin-top: 1.5rem !important;
  }

  .gt-md\:mr-6 {
    margin-right: 1.5rem !important;
  }

  .gt-md\:mb-6 {
    margin-bottom: 1.5rem !important;
  }

  .gt-md\:ml-6 {
    margin-left: 1.5rem !important;
  }

  .gt-md\:mt-8 {
    margin-top: 2rem !important;
  }

  .gt-md\:mr-8 {
    margin-right: 2rem !important;
  }

  .gt-md\:mb-8 {
    margin-bottom: 2rem !important;
  }

  .gt-md\:ml-8 {
    margin-left: 2rem !important;
  }

  .gt-md\:mt-10 {
    margin-top: 2.5rem !important;
  }

  .gt-md\:mr-10 {
    margin-right: 2.5rem !important;
  }

  .gt-md\:mb-10 {
    margin-bottom: 2.5rem !important;
  }

  .gt-md\:ml-10 {
    margin-left: 2.5rem !important;
  }

  .gt-md\:mt-12 {
    margin-top: 3rem !important;
  }

  .gt-md\:mr-12 {
    margin-right: 3rem !important;
  }

  .gt-md\:mb-12 {
    margin-bottom: 3rem !important;
  }

  .gt-md\:ml-12 {
    margin-left: 3rem !important;
  }

  .gt-md\:mt-14 {
    margin-top: 3.5rem !important;
  }

  .gt-md\:mr-14 {
    margin-right: 3.5rem !important;
  }

  .gt-md\:mb-14 {
    margin-bottom: 3.5rem !important;
  }

  .gt-md\:ml-14 {
    margin-left: 3.5rem !important;
  }

  .gt-md\:mt-16 {
    margin-top: 4rem !important;
  }

  .gt-md\:mr-16 {
    margin-right: 4rem !important;
  }

  .gt-md\:mb-16 {
    margin-bottom: 4rem !important;
  }

  .gt-md\:ml-16 {
    margin-left: 4rem !important;
  }

  .gt-md\:mt-18 {
    margin-top: 4.5rem !important;
  }

  .gt-md\:mr-18 {
    margin-right: 4.5rem !important;
  }

  .gt-md\:mb-18 {
    margin-bottom: 4.5rem !important;
  }

  .gt-md\:ml-18 {
    margin-left: 4.5rem !important;
  }

  .gt-md\:mt-20 {
    margin-top: 5rem !important;
  }

  .gt-md\:mr-20 {
    margin-right: 5rem !important;
  }

  .gt-md\:mb-20 {
    margin-bottom: 5rem !important;
  }

  .gt-md\:ml-20 {
    margin-left: 5rem !important;
  }

  .gt-md\:mt-22 {
    margin-top: 5.5rem !important;
  }

  .gt-md\:mr-22 {
    margin-right: 5.5rem !important;
  }

  .gt-md\:mb-22 {
    margin-bottom: 5.5rem !important;
  }

  .gt-md\:ml-22 {
    margin-left: 5.5rem !important;
  }

  .gt-md\:mt-24 {
    margin-top: 6rem !important;
  }

  .gt-md\:mr-24 {
    margin-right: 6rem !important;
  }

  .gt-md\:mb-24 {
    margin-bottom: 6rem !important;
  }

  .gt-md\:ml-24 {
    margin-left: 6rem !important;
  }

  .gt-md\:mt-26 {
    margin-top: 6.5rem !important;
  }

  .gt-md\:mr-26 {
    margin-right: 6.5rem !important;
  }

  .gt-md\:mb-26 {
    margin-bottom: 6.5rem !important;
  }

  .gt-md\:ml-26 {
    margin-left: 6.5rem !important;
  }

  .gt-md\:mt-28 {
    margin-top: 7rem !important;
  }

  .gt-md\:mr-28 {
    margin-right: 7rem !important;
  }

  .gt-md\:mb-28 {
    margin-bottom: 7rem !important;
  }

  .gt-md\:ml-28 {
    margin-left: 7rem !important;
  }

  .gt-md\:mt-30 {
    margin-top: 7.5rem !important;
  }

  .gt-md\:mr-30 {
    margin-right: 7.5rem !important;
  }

  .gt-md\:mb-30 {
    margin-bottom: 7.5rem !important;
  }

  .gt-md\:ml-30 {
    margin-left: 7.5rem !important;
  }

  .gt-md\:mt-32 {
    margin-top: 8rem !important;
  }

  .gt-md\:mr-32 {
    margin-right: 8rem !important;
  }

  .gt-md\:mb-32 {
    margin-bottom: 8rem !important;
  }

  .gt-md\:ml-32 {
    margin-left: 8rem !important;
  }

  .gt-md\:mt-36 {
    margin-top: 9rem !important;
  }

  .gt-md\:mr-36 {
    margin-right: 9rem !important;
  }

  .gt-md\:mb-36 {
    margin-bottom: 9rem !important;
  }

  .gt-md\:ml-36 {
    margin-left: 9rem !important;
  }

  .gt-md\:mt-40 {
    margin-top: 10rem !important;
  }

  .gt-md\:mr-40 {
    margin-right: 10rem !important;
  }

  .gt-md\:mb-40 {
    margin-bottom: 10rem !important;
  }

  .gt-md\:ml-40 {
    margin-left: 10rem !important;
  }

  .gt-md\:mt-48 {
    margin-top: 12rem !important;
  }

  .gt-md\:mr-48 {
    margin-right: 12rem !important;
  }

  .gt-md\:mb-48 {
    margin-bottom: 12rem !important;
  }

  .gt-md\:ml-48 {
    margin-left: 12rem !important;
  }

  .gt-md\:mt-56 {
    margin-top: 14rem !important;
  }

  .gt-md\:mr-56 {
    margin-right: 14rem !important;
  }

  .gt-md\:mb-56 {
    margin-bottom: 14rem !important;
  }

  .gt-md\:ml-56 {
    margin-left: 14rem !important;
  }

  .gt-md\:mt-64 {
    margin-top: 16rem !important;
  }

  .gt-md\:mr-64 {
    margin-right: 16rem !important;
  }

  .gt-md\:mb-64 {
    margin-bottom: 16rem !important;
  }

  .gt-md\:ml-64 {
    margin-left: 16rem !important;
  }

  .gt-md\:mt-auto {
    margin-top: auto !important;
  }

  .gt-md\:mr-auto {
    margin-right: auto !important;
  }

  .gt-md\:mb-auto {
    margin-bottom: auto !important;
  }

  .gt-md\:ml-auto {
    margin-left: auto !important;
  }

  .gt-md\:mt-px {
    margin-top: 1px !important;
  }

  .gt-md\:mr-px {
    margin-right: 1px !important;
  }

  .gt-md\:mb-px {
    margin-bottom: 1px !important;
  }

  .gt-md\:ml-px {
    margin-left: 1px !important;
  }

  .gt-md\:mt-2px {
    margin-top: 2px !important;
  }

  .gt-md\:mr-2px {
    margin-right: 2px !important;
  }

  .gt-md\:mb-2px {
    margin-bottom: 2px !important;
  }

  .gt-md\:ml-2px {
    margin-left: 2px !important;
  }

  .gt-md\:-mt-1 {
    margin-top: -0.25rem !important;
  }

  .gt-md\:-mr-1 {
    margin-right: -0.25rem !important;
  }

  .gt-md\:-mb-1 {
    margin-bottom: -0.25rem !important;
  }

  .gt-md\:-ml-1 {
    margin-left: -0.25rem !important;
  }

  .gt-md\:-mt-2 {
    margin-top: -0.5rem !important;
  }

  .gt-md\:-mr-2 {
    margin-right: -0.5rem !important;
  }

  .gt-md\:-mb-2 {
    margin-bottom: -0.5rem !important;
  }

  .gt-md\:-ml-2 {
    margin-left: -0.5rem !important;
  }

  .gt-md\:-mt-3 {
    margin-top: -0.75rem !important;
  }

  .gt-md\:-mr-3 {
    margin-right: -0.75rem !important;
  }

  .gt-md\:-mb-3 {
    margin-bottom: -0.75rem !important;
  }

  .gt-md\:-ml-3 {
    margin-left: -0.75rem !important;
  }

  .gt-md\:-mt-4 {
    margin-top: -1rem !important;
  }

  .gt-md\:-mr-4 {
    margin-right: -1rem !important;
  }

  .gt-md\:-mb-4 {
    margin-bottom: -1rem !important;
  }

  .gt-md\:-ml-4 {
    margin-left: -1rem !important;
  }

  .gt-md\:-mt-5 {
    margin-top: -1.25rem !important;
  }

  .gt-md\:-mr-5 {
    margin-right: -1.25rem !important;
  }

  .gt-md\:-mb-5 {
    margin-bottom: -1.25rem !important;
  }

  .gt-md\:-ml-5 {
    margin-left: -1.25rem !important;
  }

  .gt-md\:-mt-6 {
    margin-top: -1.5rem !important;
  }

  .gt-md\:-mr-6 {
    margin-right: -1.5rem !important;
  }

  .gt-md\:-mb-6 {
    margin-bottom: -1.5rem !important;
  }

  .gt-md\:-ml-6 {
    margin-left: -1.5rem !important;
  }

  .gt-md\:-mt-8 {
    margin-top: -2rem !important;
  }

  .gt-md\:-mr-8 {
    margin-right: -2rem !important;
  }

  .gt-md\:-mb-8 {
    margin-bottom: -2rem !important;
  }

  .gt-md\:-ml-8 {
    margin-left: -2rem !important;
  }

  .gt-md\:-mt-10 {
    margin-top: -2.5rem !important;
  }

  .gt-md\:-mr-10 {
    margin-right: -2.5rem !important;
  }

  .gt-md\:-mb-10 {
    margin-bottom: -2.5rem !important;
  }

  .gt-md\:-ml-10 {
    margin-left: -2.5rem !important;
  }

  .gt-md\:-mt-12 {
    margin-top: -3rem !important;
  }

  .gt-md\:-mr-12 {
    margin-right: -3rem !important;
  }

  .gt-md\:-mb-12 {
    margin-bottom: -3rem !important;
  }

  .gt-md\:-ml-12 {
    margin-left: -3rem !important;
  }

  .gt-md\:-mt-14 {
    margin-top: -3.5rem !important;
  }

  .gt-md\:-mr-14 {
    margin-right: -3.5rem !important;
  }

  .gt-md\:-mb-14 {
    margin-bottom: -3.5rem !important;
  }

  .gt-md\:-ml-14 {
    margin-left: -3.5rem !important;
  }

  .gt-md\:-mt-16 {
    margin-top: -4rem !important;
  }

  .gt-md\:-mr-16 {
    margin-right: -4rem !important;
  }

  .gt-md\:-mb-16 {
    margin-bottom: -4rem !important;
  }

  .gt-md\:-ml-16 {
    margin-left: -4rem !important;
  }

  .gt-md\:-mt-18 {
    margin-top: -4.5rem !important;
  }

  .gt-md\:-mr-18 {
    margin-right: -4.5rem !important;
  }

  .gt-md\:-mb-18 {
    margin-bottom: -4.5rem !important;
  }

  .gt-md\:-ml-18 {
    margin-left: -4.5rem !important;
  }

  .gt-md\:-mt-20 {
    margin-top: -5rem !important;
  }

  .gt-md\:-mr-20 {
    margin-right: -5rem !important;
  }

  .gt-md\:-mb-20 {
    margin-bottom: -5rem !important;
  }

  .gt-md\:-ml-20 {
    margin-left: -5rem !important;
  }

  .gt-md\:-mt-22 {
    margin-top: -5.5rem !important;
  }

  .gt-md\:-mr-22 {
    margin-right: -5.5rem !important;
  }

  .gt-md\:-mb-22 {
    margin-bottom: -5.5rem !important;
  }

  .gt-md\:-ml-22 {
    margin-left: -5.5rem !important;
  }

  .gt-md\:-mt-24 {
    margin-top: -6rem !important;
  }

  .gt-md\:-mr-24 {
    margin-right: -6rem !important;
  }

  .gt-md\:-mb-24 {
    margin-bottom: -6rem !important;
  }

  .gt-md\:-ml-24 {
    margin-left: -6rem !important;
  }

  .gt-md\:-mt-26 {
    margin-top: -6.5rem !important;
  }

  .gt-md\:-mr-26 {
    margin-right: -6.5rem !important;
  }

  .gt-md\:-mb-26 {
    margin-bottom: -6.5rem !important;
  }

  .gt-md\:-ml-26 {
    margin-left: -6.5rem !important;
  }

  .gt-md\:-mt-28 {
    margin-top: -7rem !important;
  }

  .gt-md\:-mr-28 {
    margin-right: -7rem !important;
  }

  .gt-md\:-mb-28 {
    margin-bottom: -7rem !important;
  }

  .gt-md\:-ml-28 {
    margin-left: -7rem !important;
  }

  .gt-md\:-mt-30 {
    margin-top: -7.5rem !important;
  }

  .gt-md\:-mr-30 {
    margin-right: -7.5rem !important;
  }

  .gt-md\:-mb-30 {
    margin-bottom: -7.5rem !important;
  }

  .gt-md\:-ml-30 {
    margin-left: -7.5rem !important;
  }

  .gt-md\:-mt-32 {
    margin-top: -8rem !important;
  }

  .gt-md\:-mr-32 {
    margin-right: -8rem !important;
  }

  .gt-md\:-mb-32 {
    margin-bottom: -8rem !important;
  }

  .gt-md\:-ml-32 {
    margin-left: -8rem !important;
  }

  .gt-md\:-mt-36 {
    margin-top: -9rem !important;
  }

  .gt-md\:-mr-36 {
    margin-right: -9rem !important;
  }

  .gt-md\:-mb-36 {
    margin-bottom: -9rem !important;
  }

  .gt-md\:-ml-36 {
    margin-left: -9rem !important;
  }

  .gt-md\:-mt-40 {
    margin-top: -10rem !important;
  }

  .gt-md\:-mr-40 {
    margin-right: -10rem !important;
  }

  .gt-md\:-mb-40 {
    margin-bottom: -10rem !important;
  }

  .gt-md\:-ml-40 {
    margin-left: -10rem !important;
  }

  .gt-md\:-mt-48 {
    margin-top: -12rem !important;
  }

  .gt-md\:-mr-48 {
    margin-right: -12rem !important;
  }

  .gt-md\:-mb-48 {
    margin-bottom: -12rem !important;
  }

  .gt-md\:-ml-48 {
    margin-left: -12rem !important;
  }

  .gt-md\:-mt-56 {
    margin-top: -14rem !important;
  }

  .gt-md\:-mr-56 {
    margin-right: -14rem !important;
  }

  .gt-md\:-mb-56 {
    margin-bottom: -14rem !important;
  }

  .gt-md\:-ml-56 {
    margin-left: -14rem !important;
  }

  .gt-md\:-mt-64 {
    margin-top: -16rem !important;
  }

  .gt-md\:-mr-64 {
    margin-right: -16rem !important;
  }

  .gt-md\:-mb-64 {
    margin-bottom: -16rem !important;
  }

  .gt-md\:-ml-64 {
    margin-left: -16rem !important;
  }

  .gt-md\:-mt-px {
    margin-top: -1px !important;
  }

  .gt-md\:-mr-px {
    margin-right: -1px !important;
  }

  .gt-md\:-mb-px {
    margin-bottom: -1px !important;
  }

  .gt-md\:-ml-px {
    margin-left: -1px !important;
  }

  .gt-md\:-mt-2px {
    margin-top: -2px !important;
  }

  .gt-md\:-mr-2px {
    margin-right: -2px !important;
  }

  .gt-md\:-mb-2px {
    margin-bottom: -2px !important;
  }

  .gt-md\:-ml-2px {
    margin-left: -2px !important;
  }

  .gt-md\:max-h-0 {
    max-height: 0 !important;
  }

  .gt-md\:max-h-1 {
    max-height: 0.25rem !important;
  }

  .gt-md\:max-h-2 {
    max-height: 0.5rem !important;
  }

  .gt-md\:max-h-3 {
    max-height: 0.75rem !important;
  }

  .gt-md\:max-h-4 {
    max-height: 1rem !important;
  }

  .gt-md\:max-h-5 {
    max-height: 1.25rem !important;
  }

  .gt-md\:max-h-6 {
    max-height: 1.5rem !important;
  }

  .gt-md\:max-h-8 {
    max-height: 2rem !important;
  }

  .gt-md\:max-h-10 {
    max-height: 2.5rem !important;
  }

  .gt-md\:max-h-12 {
    max-height: 3rem !important;
  }

  .gt-md\:max-h-14 {
    max-height: 3.5rem !important;
  }

  .gt-md\:max-h-16 {
    max-height: 4rem !important;
  }

  .gt-md\:max-h-18 {
    max-height: 4.5rem !important;
  }

  .gt-md\:max-h-20 {
    max-height: 5rem !important;
  }

  .gt-md\:max-h-22 {
    max-height: 5.5rem !important;
  }

  .gt-md\:max-h-24 {
    max-height: 6rem !important;
  }

  .gt-md\:max-h-26 {
    max-height: 6.5rem !important;
  }

  .gt-md\:max-h-28 {
    max-height: 7rem !important;
  }

  .gt-md\:max-h-30 {
    max-height: 7.5rem !important;
  }

  .gt-md\:max-h-32 {
    max-height: 8rem !important;
  }

  .gt-md\:max-h-36 {
    max-height: 9rem !important;
  }

  .gt-md\:max-h-40 {
    max-height: 10rem !important;
  }

  .gt-md\:max-h-48 {
    max-height: 12rem !important;
  }

  .gt-md\:max-h-50 {
    max-height: 12.5rem !important;
  }

  .gt-md\:max-h-56 {
    max-height: 14rem !important;
  }

  .gt-md\:max-h-60 {
    max-height: 15rem !important;
  }

  .gt-md\:max-h-64 {
    max-height: 16rem !important;
  }

  .gt-md\:max-h-80 {
    max-height: 20rem !important;
  }

  .gt-md\:max-h-90 {
    max-height: 24rem !important;
  }

  .gt-md\:max-h-100 {
    max-height: 25rem !important;
  }

  .gt-md\:max-h-120 {
    max-height: 30rem !important;
  }

  .gt-md\:max-h-128 {
    max-height: 32rem !important;
  }

  .gt-md\:max-h-140 {
    max-height: 35rem !important;
  }

  .gt-md\:max-h-160 {
    max-height: 40rem !important;
  }

  .gt-md\:max-h-180 {
    max-height: 45rem !important;
  }

  .gt-md\:max-h-192 {
    max-height: 48rem !important;
  }

  .gt-md\:max-h-200 {
    max-height: 50rem !important;
  }

  .gt-md\:max-h-240 {
    max-height: 60rem !important;
  }

  .gt-md\:max-h-256 {
    max-height: 64rem !important;
  }

  .gt-md\:max-h-280 {
    max-height: 70rem !important;
  }

  .gt-md\:max-h-320 {
    max-height: 80rem !important;
  }

  .gt-md\:max-h-360 {
    max-height: 90rem !important;
  }

  .gt-md\:max-h-400 {
    max-height: 100rem !important;
  }

  .gt-md\:max-h-480 {
    max-height: 120rem !important;
  }

  .gt-md\:max-h-full {
    max-height: 100% !important;
  }

  .gt-md\:max-h-screen {
    max-height: 100vh !important;
  }

  .gt-md\:max-h-none {
    max-height: none !important;
  }

  .gt-md\:max-h-px {
    max-height: 1px !important;
  }

  .gt-md\:max-h-2px {
    max-height: 2px !important;
  }

  .gt-md\:max-h-1\/2 {
    max-height: 50% !important;
  }

  .gt-md\:max-h-1\/3 {
    max-height: 33.33333% !important;
  }

  .gt-md\:max-h-2\/3 {
    max-height: 66.66667% !important;
  }

  .gt-md\:max-h-1\/4 {
    max-height: 25% !important;
  }

  .gt-md\:max-h-2\/4 {
    max-height: 50% !important;
  }

  .gt-md\:max-h-3\/4 {
    max-height: 75% !important;
  }

  .gt-md\:max-h-1\/5 {
    max-height: 20% !important;
  }

  .gt-md\:max-h-2\/5 {
    max-height: 40% !important;
  }

  .gt-md\:max-h-3\/5 {
    max-height: 60% !important;
  }

  .gt-md\:max-h-4\/5 {
    max-height: 80% !important;
  }

  .gt-md\:max-h-1\/12 {
    max-height: 8.33333% !important;
  }

  .gt-md\:max-h-2\/12 {
    max-height: 16.66667% !important;
  }

  .gt-md\:max-h-3\/12 {
    max-height: 25% !important;
  }

  .gt-md\:max-h-4\/12 {
    max-height: 33.33333% !important;
  }

  .gt-md\:max-h-5\/12 {
    max-height: 41.66667% !important;
  }

  .gt-md\:max-h-6\/12 {
    max-height: 50% !important;
  }

  .gt-md\:max-h-7\/12 {
    max-height: 58.33333% !important;
  }

  .gt-md\:max-h-8\/12 {
    max-height: 66.66667% !important;
  }

  .gt-md\:max-h-9\/12 {
    max-height: 75% !important;
  }

  .gt-md\:max-h-10\/12 {
    max-height: 83.33333% !important;
  }

  .gt-md\:max-h-11\/12 {
    max-height: 91.66667% !important;
  }

  .gt-md\:max-w-0 {
    max-width: 0 !important;
  }

  .gt-md\:max-w-1 {
    max-width: 0.25rem !important;
  }

  .gt-md\:max-w-2 {
    max-width: 0.5rem !important;
  }

  .gt-md\:max-w-3 {
    max-width: 0.75rem !important;
  }

  .gt-md\:max-w-4 {
    max-width: 1rem !important;
  }

  .gt-md\:max-w-5 {
    max-width: 1.25rem !important;
  }

  .gt-md\:max-w-6 {
    max-width: 1.5rem !important;
  }

  .gt-md\:max-w-8 {
    max-width: 2rem !important;
  }

  .gt-md\:max-w-10 {
    max-width: 2.5rem !important;
  }

  .gt-md\:max-w-12 {
    max-width: 3rem !important;
  }

  .gt-md\:max-w-14 {
    max-width: 3.5rem !important;
  }

  .gt-md\:max-w-16 {
    max-width: 4rem !important;
  }

  .gt-md\:max-w-18 {
    max-width: 4.5rem !important;
  }

  .gt-md\:max-w-20 {
    max-width: 5rem !important;
  }

  .gt-md\:max-w-22 {
    max-width: 5.5rem !important;
  }

  .gt-md\:max-w-24 {
    max-width: 6rem !important;
  }

  .gt-md\:max-w-26 {
    max-width: 6.5rem !important;
  }

  .gt-md\:max-w-28 {
    max-width: 7rem !important;
  }

  .gt-md\:max-w-30 {
    max-width: 7.5rem !important;
  }

  .gt-md\:max-w-32 {
    max-width: 8rem !important;
  }

  .gt-md\:max-w-36 {
    max-width: 9rem !important;
  }

  .gt-md\:max-w-40 {
    max-width: 10rem !important;
  }

  .gt-md\:max-w-48 {
    max-width: 12rem !important;
  }

  .gt-md\:max-w-50 {
    max-width: 12.5rem !important;
  }

  .gt-md\:max-w-56 {
    max-width: 14rem !important;
  }

  .gt-md\:max-w-60 {
    max-width: 15rem !important;
  }

  .gt-md\:max-w-64 {
    max-width: 16rem !important;
  }

  .gt-md\:max-w-80 {
    max-width: 20rem !important;
  }

  .gt-md\:max-w-90 {
    max-width: 24rem !important;
  }

  .gt-md\:max-w-100 {
    max-width: 25rem !important;
  }

  .gt-md\:max-w-120 {
    max-width: 30rem !important;
  }

  .gt-md\:max-w-128 {
    max-width: 32rem !important;
  }

  .gt-md\:max-w-140 {
    max-width: 35rem !important;
  }

  .gt-md\:max-w-160 {
    max-width: 40rem !important;
  }

  .gt-md\:max-w-180 {
    max-width: 45rem !important;
  }

  .gt-md\:max-w-192 {
    max-width: 48rem !important;
  }

  .gt-md\:max-w-200 {
    max-width: 50rem !important;
  }

  .gt-md\:max-w-240 {
    max-width: 60rem !important;
  }

  .gt-md\:max-w-256 {
    max-width: 64rem !important;
  }

  .gt-md\:max-w-280 {
    max-width: 70rem !important;
  }

  .gt-md\:max-w-320 {
    max-width: 80rem !important;
  }

  .gt-md\:max-w-360 {
    max-width: 90rem !important;
  }

  .gt-md\:max-w-400 {
    max-width: 100rem !important;
  }

  .gt-md\:max-w-480 {
    max-width: 120rem !important;
  }

  .gt-md\:max-w-none {
    max-width: none !important;
  }

  .gt-md\:max-w-xs {
    max-width: 20rem !important;
  }

  .gt-md\:max-w-sm {
    max-width: 24rem !important;
  }

  .gt-md\:max-w-md {
    max-width: 28rem !important;
  }

  .gt-md\:max-w-lg {
    max-width: 32rem !important;
  }

  .gt-md\:max-w-xl {
    max-width: 36rem !important;
  }

  .gt-md\:max-w-2xl {
    max-width: 42rem !important;
  }

  .gt-md\:max-w-3xl {
    max-width: 48rem !important;
  }

  .gt-md\:max-w-4xl {
    max-width: 56rem !important;
  }

  .gt-md\:max-w-5xl {
    max-width: 64rem !important;
  }

  .gt-md\:max-w-6xl {
    max-width: 72rem !important;
  }

  .gt-md\:max-w-full {
    max-width: 100% !important;
  }

  .gt-md\:max-w-screen {
    max-width: 100vw !important;
  }

  .gt-md\:max-w-px {
    max-width: 1px !important;
  }

  .gt-md\:max-w-2px {
    max-width: 2px !important;
  }

  .gt-md\:max-w-1\/2 {
    max-width: 50% !important;
  }

  .gt-md\:max-w-1\/3 {
    max-width: 33.33333% !important;
  }

  .gt-md\:max-w-2\/3 {
    max-width: 66.66667% !important;
  }

  .gt-md\:max-w-1\/4 {
    max-width: 25% !important;
  }

  .gt-md\:max-w-2\/4 {
    max-width: 50% !important;
  }

  .gt-md\:max-w-3\/4 {
    max-width: 75% !important;
  }

  .gt-md\:max-w-1\/5 {
    max-width: 20% !important;
  }

  .gt-md\:max-w-2\/5 {
    max-width: 40% !important;
  }

  .gt-md\:max-w-3\/5 {
    max-width: 60% !important;
  }

  .gt-md\:max-w-4\/5 {
    max-width: 80% !important;
  }

  .gt-md\:max-w-1\/12 {
    max-width: 8.33333% !important;
  }

  .gt-md\:max-w-2\/12 {
    max-width: 16.66667% !important;
  }

  .gt-md\:max-w-3\/12 {
    max-width: 25% !important;
  }

  .gt-md\:max-w-4\/12 {
    max-width: 33.33333% !important;
  }

  .gt-md\:max-w-5\/12 {
    max-width: 41.66667% !important;
  }

  .gt-md\:max-w-6\/12 {
    max-width: 50% !important;
  }

  .gt-md\:max-w-7\/12 {
    max-width: 58.33333% !important;
  }

  .gt-md\:max-w-8\/12 {
    max-width: 66.66667% !important;
  }

  .gt-md\:max-w-9\/12 {
    max-width: 75% !important;
  }

  .gt-md\:max-w-10\/12 {
    max-width: 83.33333% !important;
  }

  .gt-md\:max-w-11\/12 {
    max-width: 91.66667% !important;
  }

  .gt-md\:min-h-0 {
    min-height: 0 !important;
  }

  .gt-md\:min-h-1 {
    min-height: 0.25rem !important;
  }

  .gt-md\:min-h-2 {
    min-height: 0.5rem !important;
  }

  .gt-md\:min-h-3 {
    min-height: 0.75rem !important;
  }

  .gt-md\:min-h-4 {
    min-height: 1rem !important;
  }

  .gt-md\:min-h-5 {
    min-height: 1.25rem !important;
  }

  .gt-md\:min-h-6 {
    min-height: 1.5rem !important;
  }

  .gt-md\:min-h-8 {
    min-height: 2rem !important;
  }

  .gt-md\:min-h-10 {
    min-height: 2.5rem !important;
  }

  .gt-md\:min-h-12 {
    min-height: 3rem !important;
  }

  .gt-md\:min-h-14 {
    min-height: 3.5rem !important;
  }

  .gt-md\:min-h-16 {
    min-height: 4rem !important;
  }

  .gt-md\:min-h-18 {
    min-height: 4.5rem !important;
  }

  .gt-md\:min-h-20 {
    min-height: 5rem !important;
  }

  .gt-md\:min-h-22 {
    min-height: 5.5rem !important;
  }

  .gt-md\:min-h-24 {
    min-height: 6rem !important;
  }

  .gt-md\:min-h-26 {
    min-height: 6.5rem !important;
  }

  .gt-md\:min-h-28 {
    min-height: 7rem !important;
  }

  .gt-md\:min-h-30 {
    min-height: 7.5rem !important;
  }

  .gt-md\:min-h-32 {
    min-height: 8rem !important;
  }

  .gt-md\:min-h-36 {
    min-height: 9rem !important;
  }

  .gt-md\:min-h-40 {
    min-height: 10rem !important;
  }

  .gt-md\:min-h-48 {
    min-height: 12rem !important;
  }

  .gt-md\:min-h-50 {
    min-height: 12.5rem !important;
  }

  .gt-md\:min-h-56 {
    min-height: 14rem !important;
  }

  .gt-md\:min-h-60 {
    min-height: 15rem !important;
  }

  .gt-md\:min-h-64 {
    min-height: 16rem !important;
  }

  .gt-md\:min-h-80 {
    min-height: 20rem !important;
  }

  .gt-md\:min-h-90 {
    min-height: 24rem !important;
  }

  .gt-md\:min-h-100 {
    min-height: 25rem !important;
  }

  .gt-md\:min-h-120 {
    min-height: 30rem !important;
  }

  .gt-md\:min-h-128 {
    min-height: 32rem !important;
  }

  .gt-md\:min-h-140 {
    min-height: 35rem !important;
  }

  .gt-md\:min-h-160 {
    min-height: 40rem !important;
  }

  .gt-md\:min-h-180 {
    min-height: 45rem !important;
  }

  .gt-md\:min-h-192 {
    min-height: 48rem !important;
  }

  .gt-md\:min-h-200 {
    min-height: 50rem !important;
  }

  .gt-md\:min-h-240 {
    min-height: 60rem !important;
  }

  .gt-md\:min-h-256 {
    min-height: 64rem !important;
  }

  .gt-md\:min-h-280 {
    min-height: 70rem !important;
  }

  .gt-md\:min-h-320 {
    min-height: 80rem !important;
  }

  .gt-md\:min-h-360 {
    min-height: 90rem !important;
  }

  .gt-md\:min-h-400 {
    min-height: 100rem !important;
  }

  .gt-md\:min-h-480 {
    min-height: 120rem !important;
  }

  .gt-md\:min-h-full {
    min-height: 100% !important;
  }

  .gt-md\:min-h-screen {
    min-height: 100vh !important;
  }

  .gt-md\:min-h-px {
    min-height: 1px !important;
  }

  .gt-md\:min-h-2px {
    min-height: 2px !important;
  }

  .gt-md\:min-h-1\/2 {
    min-height: 50% !important;
  }

  .gt-md\:min-h-1\/3 {
    min-height: 33.33333% !important;
  }

  .gt-md\:min-h-2\/3 {
    min-height: 66.66667% !important;
  }

  .gt-md\:min-h-1\/4 {
    min-height: 25% !important;
  }

  .gt-md\:min-h-2\/4 {
    min-height: 50% !important;
  }

  .gt-md\:min-h-3\/4 {
    min-height: 75% !important;
  }

  .gt-md\:min-h-1\/5 {
    min-height: 20% !important;
  }

  .gt-md\:min-h-2\/5 {
    min-height: 40% !important;
  }

  .gt-md\:min-h-3\/5 {
    min-height: 60% !important;
  }

  .gt-md\:min-h-4\/5 {
    min-height: 80% !important;
  }

  .gt-md\:min-h-1\/12 {
    min-height: 8.33333% !important;
  }

  .gt-md\:min-h-2\/12 {
    min-height: 16.66667% !important;
  }

  .gt-md\:min-h-3\/12 {
    min-height: 25% !important;
  }

  .gt-md\:min-h-4\/12 {
    min-height: 33.33333% !important;
  }

  .gt-md\:min-h-5\/12 {
    min-height: 41.66667% !important;
  }

  .gt-md\:min-h-6\/12 {
    min-height: 50% !important;
  }

  .gt-md\:min-h-7\/12 {
    min-height: 58.33333% !important;
  }

  .gt-md\:min-h-8\/12 {
    min-height: 66.66667% !important;
  }

  .gt-md\:min-h-9\/12 {
    min-height: 75% !important;
  }

  .gt-md\:min-h-10\/12 {
    min-height: 83.33333% !important;
  }

  .gt-md\:min-h-11\/12 {
    min-height: 91.66667% !important;
  }

  .gt-md\:min-w-0 {
    min-width: 0 !important;
  }

  .gt-md\:min-w-1 {
    min-width: 0.25rem !important;
  }

  .gt-md\:min-w-2 {
    min-width: 0.5rem !important;
  }

  .gt-md\:min-w-3 {
    min-width: 0.75rem !important;
  }

  .gt-md\:min-w-4 {
    min-width: 1rem !important;
  }

  .gt-md\:min-w-5 {
    min-width: 1.25rem !important;
  }

  .gt-md\:min-w-6 {
    min-width: 1.5rem !important;
  }

  .gt-md\:min-w-8 {
    min-width: 2rem !important;
  }

  .gt-md\:min-w-10 {
    min-width: 2.5rem !important;
  }

  .gt-md\:min-w-12 {
    min-width: 3rem !important;
  }

  .gt-md\:min-w-14 {
    min-width: 3.5rem !important;
  }

  .gt-md\:min-w-16 {
    min-width: 4rem !important;
  }

  .gt-md\:min-w-18 {
    min-width: 4.5rem !important;
  }

  .gt-md\:min-w-20 {
    min-width: 5rem !important;
  }

  .gt-md\:min-w-22 {
    min-width: 5.5rem !important;
  }

  .gt-md\:min-w-24 {
    min-width: 6rem !important;
  }

  .gt-md\:min-w-26 {
    min-width: 6.5rem !important;
  }

  .gt-md\:min-w-28 {
    min-width: 7rem !important;
  }

  .gt-md\:min-w-30 {
    min-width: 7.5rem !important;
  }

  .gt-md\:min-w-32 {
    min-width: 8rem !important;
  }

  .gt-md\:min-w-36 {
    min-width: 9rem !important;
  }

  .gt-md\:min-w-40 {
    min-width: 10rem !important;
  }

  .gt-md\:min-w-48 {
    min-width: 12rem !important;
  }

  .gt-md\:min-w-50 {
    min-width: 12.5rem !important;
  }

  .gt-md\:min-w-56 {
    min-width: 14rem !important;
  }

  .gt-md\:min-w-60 {
    min-width: 15rem !important;
  }

  .gt-md\:min-w-64 {
    min-width: 16rem !important;
  }

  .gt-md\:min-w-80 {
    min-width: 20rem !important;
  }

  .gt-md\:min-w-90 {
    min-width: 24rem !important;
  }

  .gt-md\:min-w-100 {
    min-width: 25rem !important;
  }

  .gt-md\:min-w-120 {
    min-width: 30rem !important;
  }

  .gt-md\:min-w-128 {
    min-width: 32rem !important;
  }

  .gt-md\:min-w-140 {
    min-width: 35rem !important;
  }

  .gt-md\:min-w-160 {
    min-width: 40rem !important;
  }

  .gt-md\:min-w-180 {
    min-width: 45rem !important;
  }

  .gt-md\:min-w-192 {
    min-width: 48rem !important;
  }

  .gt-md\:min-w-200 {
    min-width: 50rem !important;
  }

  .gt-md\:min-w-240 {
    min-width: 60rem !important;
  }

  .gt-md\:min-w-256 {
    min-width: 64rem !important;
  }

  .gt-md\:min-w-280 {
    min-width: 70rem !important;
  }

  .gt-md\:min-w-320 {
    min-width: 80rem !important;
  }

  .gt-md\:min-w-360 {
    min-width: 90rem !important;
  }

  .gt-md\:min-w-400 {
    min-width: 100rem !important;
  }

  .gt-md\:min-w-480 {
    min-width: 120rem !important;
  }

  .gt-md\:min-w-full {
    min-width: 100% !important;
  }

  .gt-md\:min-w-screen {
    min-width: 100vw !important;
  }

  .gt-md\:min-w-px {
    min-width: 1px !important;
  }

  .gt-md\:min-w-2px {
    min-width: 2px !important;
  }

  .gt-md\:min-w-1\/2 {
    min-width: 50% !important;
  }

  .gt-md\:min-w-1\/3 {
    min-width: 33.33333% !important;
  }

  .gt-md\:min-w-2\/3 {
    min-width: 66.66667% !important;
  }

  .gt-md\:min-w-1\/4 {
    min-width: 25% !important;
  }

  .gt-md\:min-w-2\/4 {
    min-width: 50% !important;
  }

  .gt-md\:min-w-3\/4 {
    min-width: 75% !important;
  }

  .gt-md\:min-w-1\/5 {
    min-width: 20% !important;
  }

  .gt-md\:min-w-2\/5 {
    min-width: 40% !important;
  }

  .gt-md\:min-w-3\/5 {
    min-width: 60% !important;
  }

  .gt-md\:min-w-4\/5 {
    min-width: 80% !important;
  }

  .gt-md\:min-w-1\/12 {
    min-width: 8.33333% !important;
  }

  .gt-md\:min-w-2\/12 {
    min-width: 16.66667% !important;
  }

  .gt-md\:min-w-3\/12 {
    min-width: 25% !important;
  }

  .gt-md\:min-w-4\/12 {
    min-width: 33.33333% !important;
  }

  .gt-md\:min-w-5\/12 {
    min-width: 41.66667% !important;
  }

  .gt-md\:min-w-6\/12 {
    min-width: 50% !important;
  }

  .gt-md\:min-w-7\/12 {
    min-width: 58.33333% !important;
  }

  .gt-md\:min-w-8\/12 {
    min-width: 66.66667% !important;
  }

  .gt-md\:min-w-9\/12 {
    min-width: 75% !important;
  }

  .gt-md\:min-w-10\/12 {
    min-width: 83.33333% !important;
  }

  .gt-md\:min-w-11\/12 {
    min-width: 91.66667% !important;
  }

  .gt-md\:object-contain {
    -o-object-fit: contain !important;
       object-fit: contain !important;
  }

  .gt-md\:object-cover {
    -o-object-fit: cover !important;
       object-fit: cover !important;
  }

  .gt-md\:object-fill {
    -o-object-fit: fill !important;
       object-fit: fill !important;
  }

  .gt-md\:object-none {
    -o-object-fit: none !important;
       object-fit: none !important;
  }

  .gt-md\:object-scale-down {
    -o-object-fit: scale-down !important;
       object-fit: scale-down !important;
  }

  .gt-md\:object-bottom {
    -o-object-position: bottom !important;
       object-position: bottom !important;
  }

  .gt-md\:object-center {
    -o-object-position: center !important;
       object-position: center !important;
  }

  .gt-md\:object-left {
    -o-object-position: left !important;
       object-position: left !important;
  }

  .gt-md\:object-left-bottom {
    -o-object-position: left bottom !important;
       object-position: left bottom !important;
  }

  .gt-md\:object-left-top {
    -o-object-position: left top !important;
       object-position: left top !important;
  }

  .gt-md\:object-right {
    -o-object-position: right !important;
       object-position: right !important;
  }

  .gt-md\:object-right-bottom {
    -o-object-position: right bottom !important;
       object-position: right bottom !important;
  }

  .gt-md\:object-right-top {
    -o-object-position: right top !important;
       object-position: right top !important;
  }

  .gt-md\:object-top {
    -o-object-position: top !important;
       object-position: top !important;
  }

  .gt-md\:opacity-0 {
    opacity: 0 !important;
  }

  .gt-md\:opacity-12 {
    opacity: 0.12 !important;
  }

  .gt-md\:opacity-25 {
    opacity: 0.25 !important;
  }

  .gt-md\:opacity-38 {
    opacity: 0.38 !important;
  }

  .gt-md\:opacity-50 {
    opacity: 0.5 !important;
  }

  .gt-md\:opacity-54 {
    opacity: 0.54 !important;
  }

  .gt-md\:opacity-70 {
    opacity: 0.70 !important;
  }

  .gt-md\:opacity-75 {
    opacity: 0.75 !important;
  }

  .gt-md\:opacity-84 {
    opacity: 0.84 !important;
  }

  .gt-md\:opacity-100 {
    opacity: 1 !important;
  }

  .gt-md\:hover\:opacity-0:hover {
    opacity: 0 !important;
  }

  .gt-md\:hover\:opacity-12:hover {
    opacity: 0.12 !important;
  }

  .gt-md\:hover\:opacity-25:hover {
    opacity: 0.25 !important;
  }

  .gt-md\:hover\:opacity-38:hover {
    opacity: 0.38 !important;
  }

  .gt-md\:hover\:opacity-50:hover {
    opacity: 0.5 !important;
  }

  .gt-md\:hover\:opacity-54:hover {
    opacity: 0.54 !important;
  }

  .gt-md\:hover\:opacity-70:hover {
    opacity: 0.70 !important;
  }

  .gt-md\:hover\:opacity-75:hover {
    opacity: 0.75 !important;
  }

  .gt-md\:hover\:opacity-84:hover {
    opacity: 0.84 !important;
  }

  .gt-md\:hover\:opacity-100:hover {
    opacity: 1 !important;
  }

  .gt-md\:focus\:opacity-0:focus {
    opacity: 0 !important;
  }

  .gt-md\:focus\:opacity-12:focus {
    opacity: 0.12 !important;
  }

  .gt-md\:focus\:opacity-25:focus {
    opacity: 0.25 !important;
  }

  .gt-md\:focus\:opacity-38:focus {
    opacity: 0.38 !important;
  }

  .gt-md\:focus\:opacity-50:focus {
    opacity: 0.5 !important;
  }

  .gt-md\:focus\:opacity-54:focus {
    opacity: 0.54 !important;
  }

  .gt-md\:focus\:opacity-70:focus {
    opacity: 0.70 !important;
  }

  .gt-md\:focus\:opacity-75:focus {
    opacity: 0.75 !important;
  }

  .gt-md\:focus\:opacity-84:focus {
    opacity: 0.84 !important;
  }

  .gt-md\:focus\:opacity-100:focus {
    opacity: 1 !important;
  }

  .gt-md\:outline-none {
    outline: 0 !important;
  }

  .gt-md\:focus\:outline-none:focus {
    outline: 0 !important;
  }

  .gt-md\:overflow-auto {
    overflow: auto !important;
  }

  .gt-md\:overflow-hidden {
    overflow: hidden !important;
  }

  .gt-md\:overflow-visible {
    overflow: visible !important;
  }

  .gt-md\:overflow-scroll {
    overflow: scroll !important;
  }

  .gt-md\:overflow-x-auto {
    overflow-x: auto !important;
  }

  .gt-md\:overflow-y-auto {
    overflow-y: auto !important;
  }

  .gt-md\:overflow-x-hidden {
    overflow-x: hidden !important;
  }

  .gt-md\:overflow-y-hidden {
    overflow-y: hidden !important;
  }

  .gt-md\:overflow-x-visible {
    overflow-x: visible !important;
  }

  .gt-md\:overflow-y-visible {
    overflow-y: visible !important;
  }

  .gt-md\:overflow-x-scroll {
    overflow-x: scroll !important;
  }

  .gt-md\:overflow-y-scroll {
    overflow-y: scroll !important;
  }

  .gt-md\:scrolling-touch {
    -webkit-overflow-scrolling: touch !important;
  }

  .gt-md\:scrolling-auto {
    -webkit-overflow-scrolling: auto !important;
  }

  .gt-md\:p-0 {
    padding: 0 !important;
  }

  .gt-md\:p-1 {
    padding: 0.25rem !important;
  }

  .gt-md\:p-2 {
    padding: 0.5rem !important;
  }

  .gt-md\:p-3 {
    padding: 0.75rem !important;
  }

  .gt-md\:p-4 {
    padding: 1rem !important;
  }

  .gt-md\:p-5 {
    padding: 1.25rem !important;
  }

  .gt-md\:p-6 {
    padding: 1.5rem !important;
  }

  .gt-md\:p-8 {
    padding: 2rem !important;
  }

  .gt-md\:p-10 {
    padding: 2.5rem !important;
  }

  .gt-md\:p-12 {
    padding: 3rem !important;
  }

  .gt-md\:p-14 {
    padding: 3.5rem !important;
  }

  .gt-md\:p-16 {
    padding: 4rem !important;
  }

  .gt-md\:p-18 {
    padding: 4.5rem !important;
  }

  .gt-md\:p-20 {
    padding: 5rem !important;
  }

  .gt-md\:p-22 {
    padding: 5.5rem !important;
  }

  .gt-md\:p-24 {
    padding: 6rem !important;
  }

  .gt-md\:p-26 {
    padding: 6.5rem !important;
  }

  .gt-md\:p-28 {
    padding: 7rem !important;
  }

  .gt-md\:p-30 {
    padding: 7.5rem !important;
  }

  .gt-md\:p-32 {
    padding: 8rem !important;
  }

  .gt-md\:p-36 {
    padding: 9rem !important;
  }

  .gt-md\:p-40 {
    padding: 10rem !important;
  }

  .gt-md\:p-48 {
    padding: 12rem !important;
  }

  .gt-md\:p-56 {
    padding: 14rem !important;
  }

  .gt-md\:p-64 {
    padding: 16rem !important;
  }

  .gt-md\:p-px {
    padding: 1px !important;
  }

  .gt-md\:p-2px {
    padding: 2px !important;
  }

  .gt-md\:py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .gt-md\:px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .gt-md\:py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .gt-md\:px-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }

  .gt-md\:py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .gt-md\:px-2 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .gt-md\:py-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }

  .gt-md\:px-3 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }

  .gt-md\:py-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .gt-md\:px-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .gt-md\:py-5 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }

  .gt-md\:px-5 {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }

  .gt-md\:py-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .gt-md\:px-6 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .gt-md\:py-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .gt-md\:px-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .gt-md\:py-10 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }

  .gt-md\:px-10 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }

  .gt-md\:py-12 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .gt-md\:px-12 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .gt-md\:py-14 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }

  .gt-md\:px-14 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }

  .gt-md\:py-16 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }

  .gt-md\:px-16 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .gt-md\:py-18 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .gt-md\:px-18 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }

  .gt-md\:py-20 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .gt-md\:px-20 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }

  .gt-md\:py-22 {
    padding-top: 5.5rem !important;
    padding-bottom: 5.5rem !important;
  }

  .gt-md\:px-22 {
    padding-left: 5.5rem !important;
    padding-right: 5.5rem !important;
  }

  .gt-md\:py-24 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
  }

  .gt-md\:px-24 {
    padding-left: 6rem !important;
    padding-right: 6rem !important;
  }

  .gt-md\:py-26 {
    padding-top: 6.5rem !important;
    padding-bottom: 6.5rem !important;
  }

  .gt-md\:px-26 {
    padding-left: 6.5rem !important;
    padding-right: 6.5rem !important;
  }

  .gt-md\:py-28 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important;
  }

  .gt-md\:px-28 {
    padding-left: 7rem !important;
    padding-right: 7rem !important;
  }

  .gt-md\:py-30 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }

  .gt-md\:px-30 {
    padding-left: 7.5rem !important;
    padding-right: 7.5rem !important;
  }

  .gt-md\:py-32 {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }

  .gt-md\:px-32 {
    padding-left: 8rem !important;
    padding-right: 8rem !important;
  }

  .gt-md\:py-36 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important;
  }

  .gt-md\:px-36 {
    padding-left: 9rem !important;
    padding-right: 9rem !important;
  }

  .gt-md\:py-40 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }

  .gt-md\:px-40 {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
  }

  .gt-md\:py-48 {
    padding-top: 12rem !important;
    padding-bottom: 12rem !important;
  }

  .gt-md\:px-48 {
    padding-left: 12rem !important;
    padding-right: 12rem !important;
  }

  .gt-md\:py-56 {
    padding-top: 14rem !important;
    padding-bottom: 14rem !important;
  }

  .gt-md\:px-56 {
    padding-left: 14rem !important;
    padding-right: 14rem !important;
  }

  .gt-md\:py-64 {
    padding-top: 16rem !important;
    padding-bottom: 16rem !important;
  }

  .gt-md\:px-64 {
    padding-left: 16rem !important;
    padding-right: 16rem !important;
  }

  .gt-md\:py-px {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }

  .gt-md\:px-px {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }

  .gt-md\:py-2px {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }

  .gt-md\:px-2px {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }

  .gt-md\:pt-0 {
    padding-top: 0 !important;
  }

  .gt-md\:pr-0 {
    padding-right: 0 !important;
  }

  .gt-md\:pb-0 {
    padding-bottom: 0 !important;
  }

  .gt-md\:pl-0 {
    padding-left: 0 !important;
  }

  .gt-md\:pt-1 {
    padding-top: 0.25rem !important;
  }

  .gt-md\:pr-1 {
    padding-right: 0.25rem !important;
  }

  .gt-md\:pb-1 {
    padding-bottom: 0.25rem !important;
  }

  .gt-md\:pl-1 {
    padding-left: 0.25rem !important;
  }

  .gt-md\:pt-2 {
    padding-top: 0.5rem !important;
  }

  .gt-md\:pr-2 {
    padding-right: 0.5rem !important;
  }

  .gt-md\:pb-2 {
    padding-bottom: 0.5rem !important;
  }

  .gt-md\:pl-2 {
    padding-left: 0.5rem !important;
  }

  .gt-md\:pt-3 {
    padding-top: 0.75rem !important;
  }

  .gt-md\:pr-3 {
    padding-right: 0.75rem !important;
  }

  .gt-md\:pb-3 {
    padding-bottom: 0.75rem !important;
  }

  .gt-md\:pl-3 {
    padding-left: 0.75rem !important;
  }

  .gt-md\:pt-4 {
    padding-top: 1rem !important;
  }

  .gt-md\:pr-4 {
    padding-right: 1rem !important;
  }

  .gt-md\:pb-4 {
    padding-bottom: 1rem !important;
  }

  .gt-md\:pl-4 {
    padding-left: 1rem !important;
  }

  .gt-md\:pt-5 {
    padding-top: 1.25rem !important;
  }

  .gt-md\:pr-5 {
    padding-right: 1.25rem !important;
  }

  .gt-md\:pb-5 {
    padding-bottom: 1.25rem !important;
  }

  .gt-md\:pl-5 {
    padding-left: 1.25rem !important;
  }

  .gt-md\:pt-6 {
    padding-top: 1.5rem !important;
  }

  .gt-md\:pr-6 {
    padding-right: 1.5rem !important;
  }

  .gt-md\:pb-6 {
    padding-bottom: 1.5rem !important;
  }

  .gt-md\:pl-6 {
    padding-left: 1.5rem !important;
  }

  .gt-md\:pt-8 {
    padding-top: 2rem !important;
  }

  .gt-md\:pr-8 {
    padding-right: 2rem !important;
  }

  .gt-md\:pb-8 {
    padding-bottom: 2rem !important;
  }

  .gt-md\:pl-8 {
    padding-left: 2rem !important;
  }

  .gt-md\:pt-10 {
    padding-top: 2.5rem !important;
  }

  .gt-md\:pr-10 {
    padding-right: 2.5rem !important;
  }

  .gt-md\:pb-10 {
    padding-bottom: 2.5rem !important;
  }

  .gt-md\:pl-10 {
    padding-left: 2.5rem !important;
  }

  .gt-md\:pt-12 {
    padding-top: 3rem !important;
  }

  .gt-md\:pr-12 {
    padding-right: 3rem !important;
  }

  .gt-md\:pb-12 {
    padding-bottom: 3rem !important;
  }

  .gt-md\:pl-12 {
    padding-left: 3rem !important;
  }

  .gt-md\:pt-14 {
    padding-top: 3.5rem !important;
  }

  .gt-md\:pr-14 {
    padding-right: 3.5rem !important;
  }

  .gt-md\:pb-14 {
    padding-bottom: 3.5rem !important;
  }

  .gt-md\:pl-14 {
    padding-left: 3.5rem !important;
  }

  .gt-md\:pt-16 {
    padding-top: 4rem !important;
  }

  .gt-md\:pr-16 {
    padding-right: 4rem !important;
  }

  .gt-md\:pb-16 {
    padding-bottom: 4rem !important;
  }

  .gt-md\:pl-16 {
    padding-left: 4rem !important;
  }

  .gt-md\:pt-18 {
    padding-top: 4.5rem !important;
  }

  .gt-md\:pr-18 {
    padding-right: 4.5rem !important;
  }

  .gt-md\:pb-18 {
    padding-bottom: 4.5rem !important;
  }

  .gt-md\:pl-18 {
    padding-left: 4.5rem !important;
  }

  .gt-md\:pt-20 {
    padding-top: 5rem !important;
  }

  .gt-md\:pr-20 {
    padding-right: 5rem !important;
  }

  .gt-md\:pb-20 {
    padding-bottom: 5rem !important;
  }

  .gt-md\:pl-20 {
    padding-left: 5rem !important;
  }

  .gt-md\:pt-22 {
    padding-top: 5.5rem !important;
  }

  .gt-md\:pr-22 {
    padding-right: 5.5rem !important;
  }

  .gt-md\:pb-22 {
    padding-bottom: 5.5rem !important;
  }

  .gt-md\:pl-22 {
    padding-left: 5.5rem !important;
  }

  .gt-md\:pt-24 {
    padding-top: 6rem !important;
  }

  .gt-md\:pr-24 {
    padding-right: 6rem !important;
  }

  .gt-md\:pb-24 {
    padding-bottom: 6rem !important;
  }

  .gt-md\:pl-24 {
    padding-left: 6rem !important;
  }

  .gt-md\:pt-26 {
    padding-top: 6.5rem !important;
  }

  .gt-md\:pr-26 {
    padding-right: 6.5rem !important;
  }

  .gt-md\:pb-26 {
    padding-bottom: 6.5rem !important;
  }

  .gt-md\:pl-26 {
    padding-left: 6.5rem !important;
  }

  .gt-md\:pt-28 {
    padding-top: 7rem !important;
  }

  .gt-md\:pr-28 {
    padding-right: 7rem !important;
  }

  .gt-md\:pb-28 {
    padding-bottom: 7rem !important;
  }

  .gt-md\:pl-28 {
    padding-left: 7rem !important;
  }

  .gt-md\:pt-30 {
    padding-top: 7.5rem !important;
  }

  .gt-md\:pr-30 {
    padding-right: 7.5rem !important;
  }

  .gt-md\:pb-30 {
    padding-bottom: 7.5rem !important;
  }

  .gt-md\:pl-30 {
    padding-left: 7.5rem !important;
  }

  .gt-md\:pt-32 {
    padding-top: 8rem !important;
  }

  .gt-md\:pr-32 {
    padding-right: 8rem !important;
  }

  .gt-md\:pb-32 {
    padding-bottom: 8rem !important;
  }

  .gt-md\:pl-32 {
    padding-left: 8rem !important;
  }

  .gt-md\:pt-36 {
    padding-top: 9rem !important;
  }

  .gt-md\:pr-36 {
    padding-right: 9rem !important;
  }

  .gt-md\:pb-36 {
    padding-bottom: 9rem !important;
  }

  .gt-md\:pl-36 {
    padding-left: 9rem !important;
  }

  .gt-md\:pt-40 {
    padding-top: 10rem !important;
  }

  .gt-md\:pr-40 {
    padding-right: 10rem !important;
  }

  .gt-md\:pb-40 {
    padding-bottom: 10rem !important;
  }

  .gt-md\:pl-40 {
    padding-left: 10rem !important;
  }

  .gt-md\:pt-48 {
    padding-top: 12rem !important;
  }

  .gt-md\:pr-48 {
    padding-right: 12rem !important;
  }

  .gt-md\:pb-48 {
    padding-bottom: 12rem !important;
  }

  .gt-md\:pl-48 {
    padding-left: 12rem !important;
  }

  .gt-md\:pt-56 {
    padding-top: 14rem !important;
  }

  .gt-md\:pr-56 {
    padding-right: 14rem !important;
  }

  .gt-md\:pb-56 {
    padding-bottom: 14rem !important;
  }

  .gt-md\:pl-56 {
    padding-left: 14rem !important;
  }

  .gt-md\:pt-64 {
    padding-top: 16rem !important;
  }

  .gt-md\:pr-64 {
    padding-right: 16rem !important;
  }

  .gt-md\:pb-64 {
    padding-bottom: 16rem !important;
  }

  .gt-md\:pl-64 {
    padding-left: 16rem !important;
  }

  .gt-md\:pt-px {
    padding-top: 1px !important;
  }

  .gt-md\:pr-px {
    padding-right: 1px !important;
  }

  .gt-md\:pb-px {
    padding-bottom: 1px !important;
  }

  .gt-md\:pl-px {
    padding-left: 1px !important;
  }

  .gt-md\:pt-2px {
    padding-top: 2px !important;
  }

  .gt-md\:pr-2px {
    padding-right: 2px !important;
  }

  .gt-md\:pb-2px {
    padding-bottom: 2px !important;
  }

  .gt-md\:pl-2px {
    padding-left: 2px !important;
  }

  .gt-md\:placeholder-opacity-0::-webkit-input-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .gt-md\:placeholder-opacity-0::-moz-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .gt-md\:placeholder-opacity-0::-ms-input-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .gt-md\:placeholder-opacity-0::placeholder {
    --placeholder-opacity: 0 !important;
  }

  .gt-md\:placeholder-opacity-12::-webkit-input-placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .gt-md\:placeholder-opacity-12::-moz-placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .gt-md\:placeholder-opacity-12::-ms-input-placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .gt-md\:placeholder-opacity-12::placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .gt-md\:placeholder-opacity-25::-webkit-input-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .gt-md\:placeholder-opacity-25::-moz-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .gt-md\:placeholder-opacity-25::-ms-input-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .gt-md\:placeholder-opacity-25::placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .gt-md\:placeholder-opacity-38::-webkit-input-placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .gt-md\:placeholder-opacity-38::-moz-placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .gt-md\:placeholder-opacity-38::-ms-input-placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .gt-md\:placeholder-opacity-38::placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .gt-md\:placeholder-opacity-50::-webkit-input-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .gt-md\:placeholder-opacity-50::-moz-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .gt-md\:placeholder-opacity-50::-ms-input-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .gt-md\:placeholder-opacity-50::placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .gt-md\:placeholder-opacity-54::-webkit-input-placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .gt-md\:placeholder-opacity-54::-moz-placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .gt-md\:placeholder-opacity-54::-ms-input-placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .gt-md\:placeholder-opacity-54::placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .gt-md\:placeholder-opacity-70::-webkit-input-placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .gt-md\:placeholder-opacity-70::-moz-placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .gt-md\:placeholder-opacity-70::-ms-input-placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .gt-md\:placeholder-opacity-70::placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .gt-md\:placeholder-opacity-75::-webkit-input-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .gt-md\:placeholder-opacity-75::-moz-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .gt-md\:placeholder-opacity-75::-ms-input-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .gt-md\:placeholder-opacity-75::placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .gt-md\:placeholder-opacity-84::-webkit-input-placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .gt-md\:placeholder-opacity-84::-moz-placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .gt-md\:placeholder-opacity-84::-ms-input-placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .gt-md\:placeholder-opacity-84::placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .gt-md\:placeholder-opacity-100::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .gt-md\:placeholder-opacity-100::-moz-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .gt-md\:placeholder-opacity-100::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .gt-md\:placeholder-opacity-100::placeholder {
    --placeholder-opacity: 1 !important;
  }

  .gt-md\:focus\:placeholder-opacity-0:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .gt-md\:focus\:placeholder-opacity-0:focus::-moz-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .gt-md\:focus\:placeholder-opacity-0:focus::-ms-input-placeholder {
    --placeholder-opacity: 0 !important;
  }

  .gt-md\:focus\:placeholder-opacity-0:focus::placeholder {
    --placeholder-opacity: 0 !important;
  }

  .gt-md\:focus\:placeholder-opacity-12:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .gt-md\:focus\:placeholder-opacity-12:focus::-moz-placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .gt-md\:focus\:placeholder-opacity-12:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .gt-md\:focus\:placeholder-opacity-12:focus::placeholder {
    --placeholder-opacity: 0.12 !important;
  }

  .gt-md\:focus\:placeholder-opacity-25:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .gt-md\:focus\:placeholder-opacity-25:focus::-moz-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .gt-md\:focus\:placeholder-opacity-25:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .gt-md\:focus\:placeholder-opacity-25:focus::placeholder {
    --placeholder-opacity: 0.25 !important;
  }

  .gt-md\:focus\:placeholder-opacity-38:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .gt-md\:focus\:placeholder-opacity-38:focus::-moz-placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .gt-md\:focus\:placeholder-opacity-38:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .gt-md\:focus\:placeholder-opacity-38:focus::placeholder {
    --placeholder-opacity: 0.38 !important;
  }

  .gt-md\:focus\:placeholder-opacity-50:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .gt-md\:focus\:placeholder-opacity-50:focus::-moz-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .gt-md\:focus\:placeholder-opacity-50:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .gt-md\:focus\:placeholder-opacity-50:focus::placeholder {
    --placeholder-opacity: 0.5 !important;
  }

  .gt-md\:focus\:placeholder-opacity-54:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .gt-md\:focus\:placeholder-opacity-54:focus::-moz-placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .gt-md\:focus\:placeholder-opacity-54:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .gt-md\:focus\:placeholder-opacity-54:focus::placeholder {
    --placeholder-opacity: 0.54 !important;
  }

  .gt-md\:focus\:placeholder-opacity-70:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .gt-md\:focus\:placeholder-opacity-70:focus::-moz-placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .gt-md\:focus\:placeholder-opacity-70:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .gt-md\:focus\:placeholder-opacity-70:focus::placeholder {
    --placeholder-opacity: 0.70 !important;
  }

  .gt-md\:focus\:placeholder-opacity-75:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .gt-md\:focus\:placeholder-opacity-75:focus::-moz-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .gt-md\:focus\:placeholder-opacity-75:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .gt-md\:focus\:placeholder-opacity-75:focus::placeholder {
    --placeholder-opacity: 0.75 !important;
  }

  .gt-md\:focus\:placeholder-opacity-84:focus::-webkit-input-placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .gt-md\:focus\:placeholder-opacity-84:focus::-moz-placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .gt-md\:focus\:placeholder-opacity-84:focus::-ms-input-placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .gt-md\:focus\:placeholder-opacity-84:focus::placeholder {
    --placeholder-opacity: 0.84 !important;
  }

  .gt-md\:focus\:placeholder-opacity-100:focus::-webkit-input-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .gt-md\:focus\:placeholder-opacity-100:focus::-moz-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .gt-md\:focus\:placeholder-opacity-100:focus::-ms-input-placeholder {
    --placeholder-opacity: 1 !important;
  }

  .gt-md\:focus\:placeholder-opacity-100:focus::placeholder {
    --placeholder-opacity: 1 !important;
  }

  .gt-md\:pointer-events-none {
    pointer-events: none !important;
  }

  .gt-md\:pointer-events-auto {
    pointer-events: auto !important;
  }

  .gt-md\:static {
    position: static !important;
  }

  .gt-md\:fixed {
    position: fixed !important;
  }

  .gt-md\:absolute {
    position: absolute !important;
  }

  .gt-md\:relative {
    position: relative !important;
  }

  .gt-md\:sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }

  .gt-md\:inset-0 {
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
  }

  .gt-md\:inset-auto {
    top: auto !important;
    right: auto !important;
    bottom: auto !important;
    left: auto !important;
  }

  .gt-md\:inset-y-0 {
    top: 0 !important;
    bottom: 0 !important;
  }

  .gt-md\:inset-x-0 {
    right: 0 !important;
    left: 0 !important;
  }

  .gt-md\:inset-y-auto {
    top: auto !important;
    bottom: auto !important;
  }

  .gt-md\:inset-x-auto {
    right: auto !important;
    left: auto !important;
  }

  .gt-md\:top-0 {
    top: 0 !important;
  }

  .gt-md\:right-0 {
    right: 0 !important;
  }

  .gt-md\:bottom-0 {
    bottom: 0 !important;
  }

  .gt-md\:left-0 {
    left: 0 !important;
  }

  .gt-md\:top-auto {
    top: auto !important;
  }

  .gt-md\:right-auto {
    right: auto !important;
  }

  .gt-md\:bottom-auto {
    bottom: auto !important;
  }

  .gt-md\:left-auto {
    left: auto !important;
  }

  .gt-md\:shadow-xs {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05) !important;
  }

  .gt-md\:shadow-sm {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  }

  .gt-md\:shadow {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .gt-md\:shadow-md {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  }

  .gt-md\:shadow-lg {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  }

  .gt-md\:shadow-xl {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
  }

  .gt-md\:shadow-2xl {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
  }

  .gt-md\:shadow-inner {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .gt-md\:ring {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }

  .gt-md\:shadow-none {
    box-shadow: none !important;
  }

  .gt-md\:shadow-solid {
    box-shadow: 0 0 0 2px currentColor !important;
  }

  .gt-md\:hover\:shadow-xs:hover {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05) !important;
  }

  .gt-md\:hover\:shadow-sm:hover {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  }

  .gt-md\:hover\:shadow:hover {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .gt-md\:hover\:shadow-md:hover {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  }

  .gt-md\:hover\:shadow-lg:hover {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  }

  .gt-md\:hover\:shadow-xl:hover {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
  }

  .gt-md\:hover\:shadow-2xl:hover {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
  }

  .gt-md\:hover\:shadow-inner:hover {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .gt-md\:hover\:ring:hover {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }

  .gt-md\:hover\:shadow-none:hover {
    box-shadow: none !important;
  }

  .gt-md\:hover\:shadow-solid:hover {
    box-shadow: 0 0 0 2px currentColor !important;
  }

  .gt-md\:focus\:shadow-xs:focus {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05) !important;
  }

  .gt-md\:focus\:shadow-sm:focus {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  }

  .gt-md\:focus\:shadow:focus {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .gt-md\:focus\:shadow-md:focus {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  }

  .gt-md\:focus\:shadow-lg:focus {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  }

  .gt-md\:focus\:shadow-xl:focus {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
  }

  .gt-md\:focus\:shadow-2xl:focus {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
  }

  .gt-md\:focus\:shadow-inner:focus {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !important;
  }

  .gt-md\:focus\:ring:focus {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5) !important;
  }

  .gt-md\:focus\:shadow-none:focus {
    box-shadow: none !important;
  }

  .gt-md\:focus\:shadow-solid:focus {
    box-shadow: 0 0 0 2px currentColor !important;
  }

  .gt-md\:fill-current {
    fill: currentColor !important;
  }

  .gt-md\:stroke-current {
    stroke: currentColor !important;
  }

  .gt-md\:stroke-0 {
    stroke-width: 0 !important;
  }

  .gt-md\:stroke-1 {
    stroke-width: 1 !important;
  }

  .gt-md\:stroke-2 {
    stroke-width: 2 !important;
  }

  .gt-md\:table-auto {
    table-layout: auto !important;
  }

  .gt-md\:table-fixed {
    table-layout: fixed !important;
  }

  .gt-md\:text-left {
    text-align: left !important;
  }

  .gt-md\:text-center {
    text-align: center !important;
  }

  .gt-md\:text-right {
    text-align: right !important;
  }

  .gt-md\:text-justify {
    text-align: justify !important;
  }

  .gt-md\:text-opacity-0 {
    --text-opacity: 0 !important;
  }

  .gt-md\:text-opacity-12 {
    --text-opacity: 0.12 !important;
  }

  .gt-md\:text-opacity-25 {
    --text-opacity: 0.25 !important;
  }

  .gt-md\:text-opacity-38 {
    --text-opacity: 0.38 !important;
  }

  .gt-md\:text-opacity-50 {
    --text-opacity: 0.5 !important;
  }

  .gt-md\:text-opacity-54 {
    --text-opacity: 0.54 !important;
  }

  .gt-md\:text-opacity-70 {
    --text-opacity: 0.70 !important;
  }

  .gt-md\:text-opacity-75 {
    --text-opacity: 0.75 !important;
  }

  .gt-md\:text-opacity-84 {
    --text-opacity: 0.84 !important;
  }

  .gt-md\:text-opacity-100 {
    --text-opacity: 1 !important;
  }

  .gt-md\:hover\:text-opacity-0:hover {
    --text-opacity: 0 !important;
  }

  .gt-md\:hover\:text-opacity-12:hover {
    --text-opacity: 0.12 !important;
  }

  .gt-md\:hover\:text-opacity-25:hover {
    --text-opacity: 0.25 !important;
  }

  .gt-md\:hover\:text-opacity-38:hover {
    --text-opacity: 0.38 !important;
  }

  .gt-md\:hover\:text-opacity-50:hover {
    --text-opacity: 0.5 !important;
  }

  .gt-md\:hover\:text-opacity-54:hover {
    --text-opacity: 0.54 !important;
  }

  .gt-md\:hover\:text-opacity-70:hover {
    --text-opacity: 0.70 !important;
  }

  .gt-md\:hover\:text-opacity-75:hover {
    --text-opacity: 0.75 !important;
  }

  .gt-md\:hover\:text-opacity-84:hover {
    --text-opacity: 0.84 !important;
  }

  .gt-md\:hover\:text-opacity-100:hover {
    --text-opacity: 1 !important;
  }

  .gt-md\:focus\:text-opacity-0:focus {
    --text-opacity: 0 !important;
  }

  .gt-md\:focus\:text-opacity-12:focus {
    --text-opacity: 0.12 !important;
  }

  .gt-md\:focus\:text-opacity-25:focus {
    --text-opacity: 0.25 !important;
  }

  .gt-md\:focus\:text-opacity-38:focus {
    --text-opacity: 0.38 !important;
  }

  .gt-md\:focus\:text-opacity-50:focus {
    --text-opacity: 0.5 !important;
  }

  .gt-md\:focus\:text-opacity-54:focus {
    --text-opacity: 0.54 !important;
  }

  .gt-md\:focus\:text-opacity-70:focus {
    --text-opacity: 0.70 !important;
  }

  .gt-md\:focus\:text-opacity-75:focus {
    --text-opacity: 0.75 !important;
  }

  .gt-md\:focus\:text-opacity-84:focus {
    --text-opacity: 0.84 !important;
  }

  .gt-md\:focus\:text-opacity-100:focus {
    --text-opacity: 1 !important;
  }

  .gt-md\:italic {
    font-style: italic !important;
  }

  .gt-md\:not-italic {
    font-style: normal !important;
  }

  .gt-md\:uppercase {
    text-transform: uppercase !important;
  }

  .gt-md\:lowercase {
    text-transform: lowercase !important;
  }

  .gt-md\:capitalize {
    text-transform: capitalize !important;
  }

  .gt-md\:normal-case {
    text-transform: none !important;
  }

  .gt-md\:underline {
    text-decoration: underline !important;
  }

  .gt-md\:line-through {
    text-decoration: line-through !important;
  }

  .gt-md\:no-underline {
    text-decoration: none !important;
  }

  .gt-md\:hover\:underline:hover {
    text-decoration: underline !important;
  }

  .gt-md\:hover\:line-through:hover {
    text-decoration: line-through !important;
  }

  .gt-md\:hover\:no-underline:hover {
    text-decoration: none !important;
  }

  .gt-md\:focus\:underline:focus {
    text-decoration: underline !important;
  }

  .gt-md\:focus\:line-through:focus {
    text-decoration: line-through !important;
  }

  .gt-md\:focus\:no-underline:focus {
    text-decoration: none !important;
  }

  .gt-md\:tracking-tighter {
    letter-spacing: -0.05em !important;
  }

  .gt-md\:tracking-tight {
    letter-spacing: -0.025em !important;
  }

  .gt-md\:tracking-normal {
    letter-spacing: 0 !important;
  }

  .gt-md\:tracking-wide {
    letter-spacing: 0.025em !important;
  }

  .gt-md\:tracking-wider {
    letter-spacing: 0.05em !important;
  }

  .gt-md\:tracking-widest {
    letter-spacing: 0.1em !important;
  }

  .gt-md\:select-none {
    -webkit-user-select: none !important;
       -moz-user-select: none !important;
        -ms-user-select: none !important;
            user-select: none !important;
  }

  .gt-md\:select-text {
    -webkit-user-select: text !important;
       -moz-user-select: text !important;
        -ms-user-select: text !important;
            user-select: text !important;
  }

  .gt-md\:select-all {
    -webkit-user-select: all !important;
       -moz-user-select: all !important;
        -ms-user-select: all !important;
            user-select: all !important;
  }

  .gt-md\:select-auto {
    -webkit-user-select: auto !important;
       -moz-user-select: auto !important;
        -ms-user-select: auto !important;
            user-select: auto !important;
  }

  .gt-md\:align-baseline {
    vertical-align: baseline !important;
  }

  .gt-md\:align-top {
    vertical-align: top !important;
  }

  .gt-md\:align-middle {
    vertical-align: middle !important;
  }

  .gt-md\:align-bottom {
    vertical-align: bottom !important;
  }

  .gt-md\:align-text-top {
    vertical-align: text-top !important;
  }

  .gt-md\:align-text-bottom {
    vertical-align: text-bottom !important;
  }

  .gt-md\:visible {
    visibility: visible !important;
  }

  .gt-md\:invisible {
    visibility: hidden !important;
  }

  .gt-md\:whitespace-normal {
    white-space: normal !important;
  }

  .gt-md\:whitespace-nowrap {
    white-space: nowrap !important;
  }

  .gt-md\:whitespace-pre {
    white-space: pre !important;
  }

  .gt-md\:whitespace-pre-line {
    white-space: pre-line !important;
  }

  .gt-md\:whitespace-pre-wrap {
    white-space: pre-wrap !important;
  }

  .gt-md\:break-normal {
    overflow-wrap: normal !important;
    word-break: normal !important;
  }

  .gt-md\:break-words {
    overflow-wrap: break-word !important;
  }

  .gt-md\:break-all {
    word-break: break-all !important;
  }

  .gt-md\:truncate {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
  }

  .gt-md\:w-0 {
    width: 0 !important;
  }

  .gt-md\:w-1 {
    width: 0.25rem !important;
  }

  .gt-md\:w-2 {
    width: 0.5rem !important;
  }

  .gt-md\:w-3 {
    width: 0.75rem !important;
  }

  .gt-md\:w-4 {
    width: 1rem !important;
  }

  .gt-md\:w-5 {
    width: 1.25rem !important;
  }

  .gt-md\:w-6 {
    width: 1.5rem !important;
  }

  .gt-md\:w-8 {
    width: 2rem !important;
  }

  .gt-md\:w-10 {
    width: 2.5rem !important;
  }

  .gt-md\:w-12 {
    width: 3rem !important;
  }

  .gt-md\:w-14 {
    width: 3.5rem !important;
  }

  .gt-md\:w-16 {
    width: 4rem !important;
  }

  .gt-md\:w-18 {
    width: 4.5rem !important;
  }

  .gt-md\:w-20 {
    width: 5rem !important;
  }

  .gt-md\:w-22 {
    width: 5.5rem !important;
  }

  .gt-md\:w-24 {
    width: 6rem !important;
  }

  .gt-md\:w-26 {
    width: 6.5rem !important;
  }

  .gt-md\:w-28 {
    width: 7rem !important;
  }

  .gt-md\:w-30 {
    width: 7.5rem !important;
  }

  .gt-md\:w-32 {
    width: 8rem !important;
  }

  .gt-md\:w-36 {
    width: 9rem !important;
  }

  .gt-md\:w-40 {
    width: 10rem !important;
  }

  .gt-md\:w-48 {
    width: 12rem !important;
  }

  .gt-md\:w-50 {
    width: 12.5rem !important;
  }

  .gt-md\:w-56 {
    width: 14rem !important;
  }

  .gt-md\:w-60 {
    width: 15rem !important;
  }

  .gt-md\:w-64 {
    width: 16rem !important;
  }

  .gt-md\:w-80 {
    width: 20rem !important;
  }

  .gt-md\:w-90 {
    width: 24rem !important;
  }

  .gt-md\:w-100 {
    width: 25rem !important;
  }

  .gt-md\:w-120 {
    width: 30rem !important;
  }

  .gt-md\:w-128 {
    width: 32rem !important;
  }

  .gt-md\:w-140 {
    width: 35rem !important;
  }

  .gt-md\:w-160 {
    width: 40rem !important;
  }

  .gt-md\:w-180 {
    width: 45rem !important;
  }

  .gt-md\:w-192 {
    width: 48rem !important;
  }

  .gt-md\:w-200 {
    width: 50rem !important;
  }

  .gt-md\:w-240 {
    width: 60rem !important;
  }

  .gt-md\:w-256 {
    width: 64rem !important;
  }

  .gt-md\:w-280 {
    width: 70rem !important;
  }

  .gt-md\:w-320 {
    width: 80rem !important;
  }

  .gt-md\:w-360 {
    width: 90rem !important;
  }

  .gt-md\:w-400 {
    width: 100rem !important;
  }

  .gt-md\:w-480 {
    width: 120rem !important;
  }

  .gt-md\:w-auto {
    width: auto !important;
  }

  .gt-md\:w-px {
    width: 1px !important;
  }

  .gt-md\:w-2px {
    width: 2px !important;
  }

  .gt-md\:w-1\/2 {
    width: 50% !important;
  }

  .gt-md\:w-1\/3 {
    width: 33.33333% !important;
  }

  .gt-md\:w-2\/3 {
    width: 66.66667% !important;
  }

  .gt-md\:w-1\/4 {
    width: 25% !important;
  }

  .gt-md\:w-2\/4 {
    width: 50% !important;
  }

  .gt-md\:w-3\/4 {
    width: 75% !important;
  }

  .gt-md\:w-1\/5 {
    width: 20% !important;
  }

  .gt-md\:w-2\/5 {
    width: 40% !important;
  }

  .gt-md\:w-3\/5 {
    width: 60% !important;
  }

  .gt-md\:w-4\/5 {
    width: 80% !important;
  }

  .gt-md\:w-1\/6 {
    width: 16.666667% !important;
  }

  .gt-md\:w-2\/6 {
    width: 33.333333% !important;
  }

  .gt-md\:w-3\/6 {
    width: 50% !important;
  }

  .gt-md\:w-4\/6 {
    width: 66.666667% !important;
  }

  .gt-md\:w-5\/6 {
    width: 83.333333% !important;
  }

  .gt-md\:w-1\/12 {
    width: 8.33333% !important;
  }

  .gt-md\:w-2\/12 {
    width: 16.66667% !important;
  }

  .gt-md\:w-3\/12 {
    width: 25% !important;
  }

  .gt-md\:w-4\/12 {
    width: 33.33333% !important;
  }

  .gt-md\:w-5\/12 {
    width: 41.66667% !important;
  }

  .gt-md\:w-6\/12 {
    width: 50% !important;
  }

  .gt-md\:w-7\/12 {
    width: 58.33333% !important;
  }

  .gt-md\:w-8\/12 {
    width: 66.66667% !important;
  }

  .gt-md\:w-9\/12 {
    width: 75% !important;
  }

  .gt-md\:w-10\/12 {
    width: 83.33333% !important;
  }

  .gt-md\:w-11\/12 {
    width: 91.66667% !important;
  }

  .gt-md\:w-full {
    width: 100% !important;
  }

  .gt-md\:w-screen {
    width: 100vw !important;
  }

  .gt-md\:z-0 {
    z-index: 0 !important;
  }

  .gt-md\:z-10 {
    z-index: 10 !important;
  }

  .gt-md\:z-20 {
    z-index: 20 !important;
  }

  .gt-md\:z-30 {
    z-index: 30 !important;
  }

  .gt-md\:z-40 {
    z-index: 40 !important;
  }

  .gt-md\:z-50 {
    z-index: 50 !important;
  }

  .gt-md\:z-60 {
    z-index: 60 !important;
  }

  .gt-md\:z-70 {
    z-index: 70 !important;
  }

  .gt-md\:z-80 {
    z-index: 80 !important;
  }

  .gt-md\:z-90 {
    z-index: 90 !important;
  }

  .gt-md\:z-99 {
    z-index: 99 !important;
  }

  .gt-md\:z-999 {
    z-index: 999 !important;
  }

  .gt-md\:z-9999 {
    z-index: 9999 !important;
  }

  .gt-md\:z-99999 {
    z-index: 99999 !important;
  }

  .gt-md\:z-auto {
    z-index: auto !important;
  }

  .gt-md\:-z-1 {
    z-index: -1 !important;
  }

  .gt-md\:gap-0 {
    grid-gap: 0 !important;
    gap: 0 !important;
  }

  .gt-md\:gap-1 {
    grid-gap: 0.25rem !important;
    gap: 0.25rem !important;
  }

  .gt-md\:gap-2 {
    grid-gap: 0.5rem !important;
    gap: 0.5rem !important;
  }

  .gt-md\:gap-3 {
    grid-gap: 0.75rem !important;
    gap: 0.75rem !important;
  }

  .gt-md\:gap-4 {
    grid-gap: 1rem !important;
    gap: 1rem !important;
  }

  .gt-md\:gap-5 {
    grid-gap: 1.25rem !important;
    gap: 1.25rem !important;
  }

  .gt-md\:gap-6 {
    grid-gap: 1.5rem !important;
    gap: 1.5rem !important;
  }

  .gt-md\:gap-8 {
    grid-gap: 2rem !important;
    gap: 2rem !important;
  }

  .gt-md\:gap-10 {
    grid-gap: 2.5rem !important;
    gap: 2.5rem !important;
  }

  .gt-md\:gap-12 {
    grid-gap: 3rem !important;
    gap: 3rem !important;
  }

  .gt-md\:gap-14 {
    grid-gap: 3.5rem !important;
    gap: 3.5rem !important;
  }

  .gt-md\:gap-16 {
    grid-gap: 4rem !important;
    gap: 4rem !important;
  }

  .gt-md\:gap-18 {
    grid-gap: 4.5rem !important;
    gap: 4.5rem !important;
  }

  .gt-md\:gap-20 {
    grid-gap: 5rem !important;
    gap: 5rem !important;
  }

  .gt-md\:gap-22 {
    grid-gap: 5.5rem !important;
    gap: 5.5rem !important;
  }

  .gt-md\:gap-24 {
    grid-gap: 6rem !important;
    gap: 6rem !important;
  }

  .gt-md\:gap-26 {
    grid-gap: 6.5rem !important;
    gap: 6.5rem !important;
  }

  .gt-md\:gap-28 {
    grid-gap: 7rem !important;
    gap: 7rem !important;
  }

  .gt-md\:gap-30 {
    grid-gap: 7.5rem !important;
    gap: 7.5rem !important;
  }

  .gt-md\:gap-32 {
    grid-gap: 8rem !important;
    gap: 8rem !important;
  }

  .gt-md\:gap-36 {
    grid-gap: 9rem !important;
    gap: 9rem !important;
  }

  .gt-md\:gap-40 {
    grid-gap: 10rem !important;
    gap: 10rem !important;
  }

  .gt-md\:gap-48 {
    grid-gap: 12rem !important;
    gap: 12rem !important;
  }

  .gt-md\:gap-56 {
    grid-gap: 14rem !important;
    gap: 14rem !important;
  }

  .gt-md\:gap-64 {
    grid-gap: 16rem !important;
    gap: 16rem !important;
  }

  .gt-md\:gap-px {
    grid-gap: 1px !important;
    gap: 1px !important;
  }

  .gt-md\:gap-2px {
    grid-gap: 2px !important;
    gap: 2px !important;
  }

  .gt-md\:gap-x-0 {
    grid-column-gap: 0 !important;
    -moz-column-gap: 0 !important;
         column-gap: 0 !important;
  }

  .gt-md\:gap-x-1 {
    grid-column-gap: 0.25rem !important;
    -moz-column-gap: 0.25rem !important;
         column-gap: 0.25rem !important;
  }

  .gt-md\:gap-x-2 {
    grid-column-gap: 0.5rem !important;
    -moz-column-gap: 0.5rem !important;
         column-gap: 0.5rem !important;
  }

  .gt-md\:gap-x-3 {
    grid-column-gap: 0.75rem !important;
    -moz-column-gap: 0.75rem !important;
         column-gap: 0.75rem !important;
  }

  .gt-md\:gap-x-4 {
    grid-column-gap: 1rem !important;
    -moz-column-gap: 1rem !important;
         column-gap: 1rem !important;
  }

  .gt-md\:gap-x-5 {
    grid-column-gap: 1.25rem !important;
    -moz-column-gap: 1.25rem !important;
         column-gap: 1.25rem !important;
  }

  .gt-md\:gap-x-6 {
    grid-column-gap: 1.5rem !important;
    -moz-column-gap: 1.5rem !important;
         column-gap: 1.5rem !important;
  }

  .gt-md\:gap-x-8 {
    grid-column-gap: 2rem !important;
    -moz-column-gap: 2rem !important;
         column-gap: 2rem !important;
  }

  .gt-md\:gap-x-10 {
    grid-column-gap: 2.5rem !important;
    -moz-column-gap: 2.5rem !important;
         column-gap: 2.5rem !important;
  }

  .gt-md\:gap-x-12 {
    grid-column-gap: 3rem !important;
    -moz-column-gap: 3rem !important;
         column-gap: 3rem !important;
  }

  .gt-md\:gap-x-14 {
    grid-column-gap: 3.5rem !important;
    -moz-column-gap: 3.5rem !important;
         column-gap: 3.5rem !important;
  }

  .gt-md\:gap-x-16 {
    grid-column-gap: 4rem !important;
    -moz-column-gap: 4rem !important;
         column-gap: 4rem !important;
  }

  .gt-md\:gap-x-18 {
    grid-column-gap: 4.5rem !important;
    -moz-column-gap: 4.5rem !important;
         column-gap: 4.5rem !important;
  }

  .gt-md\:gap-x-20 {
    grid-column-gap: 5rem !important;
    -moz-column-gap: 5rem !important;
         column-gap: 5rem !important;
  }

  .gt-md\:gap-x-22 {
    grid-column-gap: 5.5rem !important;
    -moz-column-gap: 5.5rem !important;
         column-gap: 5.5rem !important;
  }

  .gt-md\:gap-x-24 {
    grid-column-gap: 6rem !important;
    -moz-column-gap: 6rem !important;
         column-gap: 6rem !important;
  }

  .gt-md\:gap-x-26 {
    grid-column-gap: 6.5rem !important;
    -moz-column-gap: 6.5rem !important;
         column-gap: 6.5rem !important;
  }

  .gt-md\:gap-x-28 {
    grid-column-gap: 7rem !important;
    -moz-column-gap: 7rem !important;
         column-gap: 7rem !important;
  }

  .gt-md\:gap-x-30 {
    grid-column-gap: 7.5rem !important;
    -moz-column-gap: 7.5rem !important;
         column-gap: 7.5rem !important;
  }

  .gt-md\:gap-x-32 {
    grid-column-gap: 8rem !important;
    -moz-column-gap: 8rem !important;
         column-gap: 8rem !important;
  }

  .gt-md\:gap-x-36 {
    grid-column-gap: 9rem !important;
    -moz-column-gap: 9rem !important;
         column-gap: 9rem !important;
  }

  .gt-md\:gap-x-40 {
    grid-column-gap: 10rem !important;
    -moz-column-gap: 10rem !important;
         column-gap: 10rem !important;
  }

  .gt-md\:gap-x-48 {
    grid-column-gap: 12rem !important;
    -moz-column-gap: 12rem !important;
         column-gap: 12rem !important;
  }

  .gt-md\:gap-x-56 {
    grid-column-gap: 14rem !important;
    -moz-column-gap: 14rem !important;
         column-gap: 14rem !important;
  }

  .gt-md\:gap-x-64 {
    grid-column-gap: 16rem !important;
    -moz-column-gap: 16rem !important;
         column-gap: 16rem !important;
  }

  .gt-md\:gap-x-px {
    grid-column-gap: 1px !important;
    -moz-column-gap: 1px !important;
         column-gap: 1px !important;
  }

  .gt-md\:gap-x-2px {
    grid-column-gap: 2px !important;
    -moz-column-gap: 2px !important;
         column-gap: 2px !important;
  }

  .gt-md\:gap-y-0 {
    grid-row-gap: 0 !important;
    row-gap: 0 !important;
  }

  .gt-md\:gap-y-1 {
    grid-row-gap: 0.25rem !important;
    row-gap: 0.25rem !important;
  }

  .gt-md\:gap-y-2 {
    grid-row-gap: 0.5rem !important;
    row-gap: 0.5rem !important;
  }

  .gt-md\:gap-y-3 {
    grid-row-gap: 0.75rem !important;
    row-gap: 0.75rem !important;
  }

  .gt-md\:gap-y-4 {
    grid-row-gap: 1rem !important;
    row-gap: 1rem !important;
  }

  .gt-md\:gap-y-5 {
    grid-row-gap: 1.25rem !important;
    row-gap: 1.25rem !important;
  }

  .gt-md\:gap-y-6 {
    grid-row-gap: 1.5rem !important;
    row-gap: 1.5rem !important;
  }

  .gt-md\:gap-y-8 {
    grid-row-gap: 2rem !important;
    row-gap: 2rem !important;
  }

  .gt-md\:gap-y-10 {
    grid-row-gap: 2.5rem !important;
    row-gap: 2.5rem !important;
  }

  .gt-md\:gap-y-12 {
    grid-row-gap: 3rem !important;
    row-gap: 3rem !important;
  }

  .gt-md\:gap-y-14 {
    grid-row-gap: 3.5rem !important;
    row-gap: 3.5rem !important;
  }

  .gt-md\:gap-y-16 {
    grid-row-gap: 4rem !important;
    row-gap: 4rem !important;
  }

  .gt-md\:gap-y-18 {
    grid-row-gap: 4.5rem !important;
    row-gap: 4.5rem !important;
  }

  .gt-md\:gap-y-20 {
    grid-row-gap: 5rem !important;
    row-gap: 5rem !important;
  }

  .gt-md\:gap-y-22 {
    grid-row-gap: 5.5rem !important;
    row-gap: 5.5rem !important;
  }

  .gt-md\:gap-y-24 {
    grid-row-gap: 6rem !important;
    row-gap: 6rem !important;
  }

  .gt-md\:gap-y-26 {
    grid-row-gap: 6.5rem !important;
    row-gap: 6.5rem !important;
  }

  .gt-md\:gap-y-28 {
    grid-row-gap: 7rem !important;
    row-gap: 7rem !important;
  }

  .gt-md\:gap-y-30 {
    grid-row-gap: 7.5rem !important;
    row-gap: 7.5rem !important;
  }

  .gt-md\:gap-y-32 {
    grid-row-gap: 8rem !important;
    row-gap: 8rem !important;
  }

  .gt-md\:gap-y-36 {
    grid-row-gap: 9rem !important;
    row-gap: 9rem !important;
  }

  .gt-md\:gap-y-40 {
    grid-row-gap: 10rem !important;
    row-gap: 10rem !important;
  }

  .gt-md\:gap-y-48 {
    grid-row-gap: 12rem !important;
    row-gap: 12rem !important;
  }

  .gt-md\:gap-y-56 {
    grid-row-gap: 14rem !important;
    row-gap: 14rem !important;
  }

  .gt-md\:gap-y-64 {
    grid-row-gap: 16rem !important;
    row-gap: 16rem !important;
  }

  .gt-md\:gap-y-px {
    grid-row-gap: 1px !important;
    row-gap: 1px !important;
  }

  .gt-md\:gap-y-2px {
    grid-row-gap: 2px !important;
    row-gap: 2px !important;
  }

  .gt-md\:grid-flow-row {
    grid-auto-flow: row !important;
  }

  .gt-md\:grid-flow-col {
    grid-auto-flow: column !important;
  }

  .gt-md\:grid-flow-row-dense {
    grid-auto-flow: row dense !important;
  }

  .gt-md\:grid-flow-col-dense {
    grid-auto-flow: column dense !important;
  }

  .gt-md\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }

  .gt-md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }

  .gt-md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
  }

  .gt-md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
  }

  .gt-md\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr)) !important;
  }

  .gt-md\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr)) !important;
  }

  .gt-md\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr)) !important;
  }

  .gt-md\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr)) !important;
  }

  .gt-md\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr)) !important;
  }

  .gt-md\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr)) !important;
  }

  .gt-md\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr)) !important;
  }

  .gt-md\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr)) !important;
  }

  .gt-md\:grid-cols-none {
    grid-template-columns: none !important;
  }

  .gt-md\:col-auto {
    grid-column: auto !important;
  }

  .gt-md\:col-span-1 {
    grid-column: span 1 / span 1 !important;
  }

  .gt-md\:col-span-2 {
    grid-column: span 2 / span 2 !important;
  }

  .gt-md\:col-span-3 {
    grid-column: span 3 / span 3 !important;
  }

  .gt-md\:col-span-4 {
    grid-column: span 4 / span 4 !important;
  }

  .gt-md\:col-span-5 {
    grid-column: span 5 / span 5 !important;
  }

  .gt-md\:col-span-6 {
    grid-column: span 6 / span 6 !important;
  }

  .gt-md\:col-span-7 {
    grid-column: span 7 / span 7 !important;
  }

  .gt-md\:col-span-8 {
    grid-column: span 8 / span 8 !important;
  }

  .gt-md\:col-span-9 {
    grid-column: span 9 / span 9 !important;
  }

  .gt-md\:col-span-10 {
    grid-column: span 10 / span 10 !important;
  }

  .gt-md\:col-span-11 {
    grid-column: span 11 / span 11 !important;
  }

  .gt-md\:col-span-12 {
    grid-column: span 12 / span 12 !important;
  }

  .gt-md\:col-start-1 {
    grid-column-start: 1 !important;
  }

  .gt-md\:col-start-2 {
    grid-column-start: 2 !important;
  }

  .gt-md\:col-start-3 {
    grid-column-start: 3 !important;
  }

  .gt-md\:col-start-4 {
    grid-column-start: 4 !important;
  }

  .gt-md\:col-start-5 {
    grid-column-start: 5 !important;
  }

  .gt-md\:col-start-6 {
    grid-column-start: 6 !important;
  }

  .gt-md\:col-start-7 {
    grid-column-start: 7 !important;
  }

  .gt-md\:col-start-8 {
    grid-column-start: 8 !important;
  }

  .gt-md\:col-start-9 {
    grid-column-start: 9 !important;
  }

  .gt-md\:col-start-10 {
    grid-column-start: 10 !important;
  }

  .gt-md\:col-start-11 {
    grid-column-start: 11 !important;
  }

  .gt-md\:col-start-12 {
    grid-column-start: 12 !important;
  }

  .gt-md\:col-start-13 {
    grid-column-start: 13 !important;
  }

  .gt-md\:col-start-auto {
    grid-column-start: auto !important;
  }

  .gt-md\:col-end-1 {
    grid-column-end: 1 !important;
  }

  .gt-md\:col-end-2 {
    grid-column-end: 2 !important;
  }

  .gt-md\:col-end-3 {
    grid-column-end: 3 !important;
  }

  .gt-md\:col-end-4 {
    grid-column-end: 4 !important;
  }

  .gt-md\:col-end-5 {
    grid-column-end: 5 !important;
  }

  .gt-md\:col-end-6 {
    grid-column-end: 6 !important;
  }

  .gt-md\:col-end-7 {
    grid-column-end: 7 !important;
  }

  .gt-md\:col-end-8 {
    grid-column-end: 8 !important;
  }

  .gt-md\:col-end-9 {
    grid-column-end: 9 !important;
  }

  .gt-md\:col-end-10 {
    grid-column-end: 10 !important;
  }

  .gt-md\:col-end-11 {
    grid-column-end: 11 !important;
  }

  .gt-md\:col-end-12 {
    grid-column-end: 12 !important;
  }

  .gt-md\:col-end-13 {
    grid-column-end: 13 !important;
  }

  .gt-md\:col-end-auto {
    grid-column-end: auto !important;
  }

  .gt-md\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr)) !important;
  }

  .gt-md\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr)) !important;
  }

  .gt-md\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr)) !important;
  }

  .gt-md\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr)) !important;
  }

  .gt-md\:grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr)) !important;
  }

  .gt-md\:grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr)) !important;
  }

  .gt-md\:grid-rows-none {
    grid-template-rows: none !important;
  }

  .gt-md\:row-auto {
    grid-row: auto !important;
  }

  .gt-md\:row-span-1 {
    grid-row: span 1 / span 1 !important;
  }

  .gt-md\:row-span-2 {
    grid-row: span 2 / span 2 !important;
  }

  .gt-md\:row-span-3 {
    grid-row: span 3 / span 3 !important;
  }

  .gt-md\:row-span-4 {
    grid-row: span 4 / span 4 !important;
  }

  .gt-md\:row-span-5 {
    grid-row: span 5 / span 5 !important;
  }

  .gt-md\:row-span-6 {
    grid-row: span 6 / span 6 !important;
  }

  .gt-md\:row-start-1 {
    grid-row-start: 1 !important;
  }

  .gt-md\:row-start-2 {
    grid-row-start: 2 !important;
  }

  .gt-md\:row-start-3 {
    grid-row-start: 3 !important;
  }

  .gt-md\:row-start-4 {
    grid-row-start: 4 !important;
  }

  .gt-md\:row-start-5 {
    grid-row-start: 5 !important;
  }

  .gt-md\:row-start-6 {
    grid-row-start: 6 !important;
  }

  .gt-md\:row-start-7 {
    grid-row-start: 7 !important;
  }

  .gt-md\:row-start-auto {
    grid-row-start: auto !important;
  }

  .gt-md\:row-end-1 {
    grid-row-end: 1 !important;
  }

  .gt-md\:row-end-2 {
    grid-row-end: 2 !important;
  }

  .gt-md\:row-end-3 {
    grid-row-end: 3 !important;
  }

  .gt-md\:row-end-4 {
    grid-row-end: 4 !important;
  }

  .gt-md\:row-end-5 {
    grid-row-end: 5 !important;
  }

  .gt-md\:row-end-6 {
    grid-row-end: 6 !important;
  }

  .gt-md\:row-end-7 {
    grid-row-end: 7 !important;
  }

  .gt-md\:row-end-auto {
    grid-row-end: auto !important;
  }

  .gt-md\:transform {
    --transform-translate-x: 0 !important;
    --transform-translate-y: 0 !important;
    --transform-rotate: 0 !important;
    --transform-skew-x: 0 !important;
    --transform-skew-y: 0 !important;
    --transform-scale-x: 1 !important;
    --transform-scale-y: 1 !important;
    transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y)) !important;
  }

  .gt-md\:transform-none {
    transform: none !important;
  }

  .gt-md\:origin-center {
    transform-origin: center !important;
  }

  .gt-md\:origin-top {
    transform-origin: top !important;
  }

  .gt-md\:origin-top-right {
    transform-origin: top right !important;
  }

  .gt-md\:origin-right {
    transform-origin: right !important;
  }

  .gt-md\:origin-bottom-right {
    transform-origin: bottom right !important;
  }

  .gt-md\:origin-bottom {
    transform-origin: bottom !important;
  }

  .gt-md\:origin-bottom-left {
    transform-origin: bottom left !important;
  }

  .gt-md\:origin-left {
    transform-origin: left !important;
  }

  .gt-md\:origin-top-left {
    transform-origin: top left !important;
  }

  .gt-md\:icon-size-12 {
    width: 12px !important;
    height: 12px !important;
    min-width: 12px !important;
    min-height: 12px !important;
    font-size: 12px !important;
    line-height: 12px !important;
  }

  .gt-md\:icon-size-12 svg {
    width: 12px !important;
    height: 12px !important;
  }

  .gt-md\:icon-size-14 {
    width: 14px !important;
    height: 14px !important;
    min-width: 14px !important;
    min-height: 14px !important;
    font-size: 14px !important;
    line-height: 14px !important;
  }

  .gt-md\:icon-size-14 svg {
    width: 14px !important;
    height: 14px !important;
  }

  .gt-md\:icon-size-16 {
    width: 16px !important;
    height: 16px !important;
    min-width: 16px !important;
    min-height: 16px !important;
    font-size: 16px !important;
    line-height: 16px !important;
  }

  .gt-md\:icon-size-16 svg {
    width: 16px !important;
    height: 16px !important;
  }

  .gt-md\:icon-size-18 {
    width: 18px !important;
    height: 18px !important;
    min-width: 18px !important;
    min-height: 18px !important;
    font-size: 18px !important;
    line-height: 18px !important;
  }

  .gt-md\:icon-size-18 svg {
    width: 18px !important;
    height: 18px !important;
  }

  .gt-md\:icon-size-20 {
    width: 20px !important;
    height: 20px !important;
    min-width: 20px !important;
    min-height: 20px !important;
    font-size: 20px !important;
    line-height: 20px !important;
  }

  .gt-md\:icon-size-20 svg {
    width: 20px !important;
    height: 20px !important;
  }

  .gt-md\:icon-size-24 {
    width: 24px !important;
    height: 24px !important;
    min-width: 24px !important;
    min-height: 24px !important;
    font-size: 24px !important;
    line-height: 24px !important;
  }

  .gt-md\:icon-size-24 svg {
    width: 24px !important;
    height: 24px !important;
  }

  .gt-md\:icon-size-32 {
    width: 32px !important;
    height: 32px !important;
    min-width: 32px !important;
    min-height: 32px !important;
    font-size: 32px !important;
    line-height: 32px !important;
  }

  .gt-md\:icon-size-32 svg {
    width: 32px !important;
    height: 32px !important;
  }

  .gt-md\:icon-size-40 {
    width: 40px !important;
    height: 40px !important;
    min-width: 40px !important;
    min-height: 40px !important;
    font-size: 40px !important;
    line-height: 40px !important;
  }

  .gt-md\:icon-size-40 svg {
    width: 40px !important;
    height: 40px !important;
  }

  .gt-md\:icon-size-48 {
    width: 48px !important;
    height: 48px !important;
    min-width: 48px !important;
    min-height: 48px !important;
    font-size: 48px !important;
    line-height: 48px !important;
  }

  .gt-md\:icon-size-48 svg {
    width: 48px !important;
    height: 48px !important;
  }

  .gt-md\:icon-size-56 {
    width: 56px !important;
    height: 56px !important;
    min-width: 56px !important;
    min-height: 56px !important;
    font-size: 56px !important;
    line-height: 56px !important;
  }

  .gt-md\:icon-size-56 svg {
    width: 56px !important;
    height: 56px !important;
  }

  .gt-md\:icon-size-64 {
    width: 64px !important;
    height: 64px !important;
    min-width: 64px !important;
    min-height: 64px !important;
    font-size: 64px !important;
    line-height: 64px !important;
  }

  .gt-md\:icon-size-64 svg {
    width: 64px !important;
    height: 64px !important;
  }

  .gt-md\:icon-size-72 {
    width: 72px !important;
    height: 72px !important;
    min-width: 72px !important;
    min-height: 72px !important;
    font-size: 72px !important;
    line-height: 72px !important;
  }

  .gt-md\:icon-size-72 svg {
    width: 72px !important;
    height: 72px !important;
  }

  .gt-md\:icon-size-80 {
    width: 80px !important;
    height: 80px !important;
    min-width: 80px !important;
    min-height: 80px !important;
    font-size: 80px !important;
    line-height: 80px !important;
  }

  .gt-md\:icon-size-80 svg {
    width: 80px !important;
    height: 80px !important;
  }

  .gt-md\:icon-size-88 {
    width: 88px !important;
    height: 88px !important;
    min-width: 88px !important;
    min-height: 88px !important;
    font-size: 88px !important;
    line-height: 88px !important;
  }

  .gt-md\:icon-size-88 svg {
    width: 88px !important;
    height: 88px !important;
  }

  .gt-md\:icon-size-96 {
    width: 96px !important;
    height: 96px !important;
    min-width: 96px !important;
    min-height: 96px !important;
    font-size: 96px !important;
    line-height: 96px !important;
  }

  .gt-md\:icon-size-96 svg {
    width: 96px !important;
    height: 96px !important;
  }
}
